import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import TWB1 from "../HelpPopups/images/TWB1.jpg";
import TWB2 from "../HelpPopups/images/TWB2.jpg";
import TWB3 from "../HelpPopups/images/TWB3.jpg";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const TaskwiseBufferConsumptionhelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h4 style={{ textDecoration: "underline" }}>Task Wise Buffer Consumption Report</h4>
              <br />
              <li className="no-bullets">
                This report is created for checking how much buffer is consumed by each task. This is used to review the project for project manager.
              </li>
              <br />

              <li className="no-bullets">Session Path `{'>>'}` CCPM `{'>>'}` Reports `{'>>'}` Task Wise Buffer Consumption Report
              </li>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TWB1} alt="TWB1" />
              </Stack>
              <br />
              <li className="no-bullets">
                Select instance from drop down provided. Once instance is selected further filter will be appeared.
              </li>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TWB2} alt="TWB2" />
              </Stack>
              <br />
              <li className="no-bullets">
                Here filters for Attributes, project manager, Task manager are provided along with he task duration for the particular periods. User can use the different filters.
              </li>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TWB3} alt="TWB3" />
              </Stack>
              <br />
              <li>
                Project Name - Here project which user has selected in the filters will appear. All task for that project will appear here.


              </li>
              <li>
                Project Manager - Here name of project manager is displayed.


              </li>
              <li>
                Task Name - Here all tasks are displayed for that project.


              </li>
              <li>
                Task Name - Here name of the task manager is displayed.


              </li>
              <li>
                Planned Duration with Safety(After CCPM) -  This is original task duration after applying CCPM. It means it is a cut duration of the tasks compared to original task duration.


              </li>
              <li>
                Actual Duration - This indicates that the time taken to complete the task. IT is actual duration for the task. Actual Duration = Actual end date - Actual start date.


              </li>
              <li>
                Days buffer consumed more than buffer - This indicates how much buffer is consumed for the that task.


              </li>
              <li>
                Reason for Delay - This column shows the reasons for delay in completion of the task. These reasons are maintained by the task manager while updating task by the task managers. Those values are from master.


              </li>
              <li>
                Secondary Reason for Delay - This is additional input on delay reason for the task. This is also maintained by the task managers.


              </li>

              <br />
              <br />

            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TaskwiseBufferConsumptionhelpPopup;
