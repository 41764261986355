import { Grade } from "@mui/icons-material";
import modules from "./Admin.module.css";
import Home from "../../home/Home";
import { Grid } from "@mui/material";
import bread from "../../Assets/bread.png";
import { useRef, useState, useEffect } from "react";
import { ChangeNewPassword } from "../../Services/Admin";
import SideBar from "../../Landing/Navbar";
import EyeIcon from "../../Assets/eye.png";
import EyeIconcross from "../../Assets/eyecross.png";
import SampleSidebar from "../../Landing/SampleSidebar";

export default function ChangePassword() {
  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
  });

  const [password, setPassword] = useState({
    oldPass: "",
    newPass: "",
    confirmnewPass: "",
  });

  const [error, setError] = useState({
    confirmErr: false,
    msg: "",
  });

  const [eyeIcon, setEyeIcon] = useState({
    old: false,
    new: false,
    cfn: false,
  });

  function confirmPassHandler(e) {
    setPassword({
      ...password,
      confirmnewPass: e.target.value,
    });
    const value = e.target.value;
    if (value) {
      value != password.newPass
        ? setError({ ...error, confirmErr: true })
        : setError({ ...error, confirmErr: false });
      return;
    }
    setError({ ...error, confirmErr: false });
  }

  function resetHandler() {
    setPassword({
      oldPass: "",
      newPass: "",
      confirmnewPass: "",
    });

    setError({
      confirmErr: false,
      msg: "",
    });
  }

  function changePasswordHandler() {
    let noEmptyValues = false;
    !password.oldPass.trim()
      ? setError({
          ...error,
          msg: "Enter Old password",
        })
      : !password.newPass.trim()
      ? setError({
          ...error,
          msg: "Enter new password",
        })
      : !password.confirmnewPass.trim()
      ? setError({
          ...error,
          msg: "Enter Confirm password",
        })
      : (noEmptyValues = true);

    if (!noEmptyValues) return;

    if (password.oldPass === password.newPass) {
      setError({
        ...error,
        msg: "old Password and NewPassword should not be same",
      });
      return;
    }

    const consecutiveDigitsRegex = /(.)\1\1/;
    const orderedDigitsRegex = /(012|123|234|345|456|567|678|789|890)/;

    if (consecutiveDigitsRegex.test(password.newPass)) {
      setError({
        ...error,
        msg: "Password should not have 3 or more consecutive digits",
      });
      return;
    }

    if (orderedDigitsRegex.test(password.newPass)) {
      setError({
        ...error,
        msg: "Password should not have 3 or more consecutive digits",
      });
      return;
    }

    const spaceRegex = /\s/;
    if (spaceRegex.test(password.newPass)) {
      setError({
        ...error,
        msg: "Pattern Not Followed, Space not allowed",
      });
      return;
    }

    const passwordRegex =
      /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;
    if (noEmptyValues) {
      if (passwordRegex.test(password.newPass)) {
        const body = {
          loginCode: window.sessionStorage.getItem("loginId"),
          username: window.sessionStorage.getItem("Username"),
          lastModifiedBy: window.sessionStorage.getItem("roleid"),
          //   loginCode: "1",
          //   username: "pwc",
          //   lastModifiedBy: 1,
          old_pwd: password.oldPass,
          new_pwd: password.newPass,

          // {
          //     "loginCode": "string",
          //     "old_pwd": "string",
          //     "new_pwd": "string",
          //     "username": "string",
          //     "lastModifiedBy": 0
          //   }
        };
        ChangeNewPassword(body)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              setError({ ...error, msg: "Password changed Succesfully" });
            } else {
              setError({
                ...error,
                msg:
                  res?.response?.data?.error?.message !== undefined
                    ? res?.response?.data?.error?.message
                    : res?.response?.data,
              });
            }
          })
          .catch((err) => {
            console.log("Error while fetching data:", err);
          });
      } else {
        setError({
          ...error,
          msg: "Pattern Not Followed",
        });
      }
    } else {
      return;
    }
  }

  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  function focusHandler(num) {
    switch (num) {
      case 1:
        ref1.current.focus();
        break;
      case 2:
        ref2.current.focus();
        break;
      case 3:
        ref3.current.focus();
        break;
    }
  }
  return (
    <>
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          {/* <SideBar /> */}
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"password"} />
          </Grid>
          <Grid
            sx={{
              border: "2px solid #addc21d0",
              width: "73%",
              padding: "10px",
              marginLeft: "6rem",
              minWidth: "70%",
            }}
          >
            <table className={modules["customTbl"]}>
              <tbody>
                <tr>
                  <td style={{ position: "relative", top: "4px" }}>
                    Old Password:
                  </td>
                  <td style={{ width: "260px" }}>
                    <div onClick={() => focusHandler(1)}>
                      <div style={{ position: "relative" }}>
                        <input
                          ref={ref1}
                          type={!eyeIcon.old ? "password" : "text"}
                          value={password.oldPass}
                          className={modules["customInpDiv"]}
                          onChange={(e) => {
                            setPassword({
                              ...password,
                              oldPass: e.target.value,
                            });
                          }}
                          style={{
                            paddingRight: "30px",
                            // width: "230px" // Add padding to ensure the text does not overlap with the eye icon
                          }}
                        />
                        <span
                          className={modules["customImg"]}
                          style={{
                            position: "absolute",
                            left: "55px", // Adjusted to right side
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer", // Optional: Add pointer cursor for better UX
                          }}
                        >
                          {password.oldPass && (
                            <img
                              src={eyeIcon.old ? EyeIconcross : EyeIcon}
                              onClick={() => {
                                setEyeIcon({ ...eyeIcon, old: !eyeIcon.old });
                              }}
                              style={{
                                width: "15px", // Set the width of the icon to 15px
                                height: "15px", // Set the height of the icon to 15px
                              }}
                              alt="Toggle visibility" // Add alt text for accessibility
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ position: "relative", top: "4px" }}>
                    New Password:
                  </td>
                  <td style={{ width: "260px" }}>
                    <div onClick={() => focusHandler(2)}>
                      <div style={{ position: "relative" }}>
                        <input
                          ref={ref2}
                          type={!eyeIcon.new ? "password" : "text"}
                          className={modules["customInpDiv"]}
                          value={password.newPass}
                          onChange={(e) => {
                            setPassword({
                              ...password,
                              newPass: e.target.value,
                              confirmnewPass: "",
                            });
                            setError({ confirmErr: false, msg: "" });
                          }}
                          style={{
                            paddingRight: "30px", // Add padding to ensure the text does not overlap with the eye icon
                          }}
                        />
                        <span
                          className={modules["customImg"]}
                          style={{
                            position: "absolute",
                            left: "55px", // Adjusted to right side
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer", // Optional: Add pointer cursor for better UX
                          }}
                        >
                          {password.newPass && (
                            <img
                              src={eyeIcon.new ? EyeIconcross : EyeIcon}
                              onClick={() => {
                                setEyeIcon({ ...eyeIcon, new: !eyeIcon.new });
                              }}
                              style={{
                                width: "15px", // Set the width of the icon to 15px
                                height: "15px", // Set the height of the icon to 15px
                              }}
                              alt="Toggle visibility" // Add alt text for accessibility
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </td>
                  <p
                    className={modules["errorMsg"]}
                    style={{ margin: 0, left: "90px" }}
                  >
                    {error.msg}
                  </p>
                </tr>
                <tr>
                  <td style={{ position: "relative", top: "4px" }}>
                    Confirm Password:
                  </td>
                  <td style={{ width: "260px" }}>
                    {" "}
                    {/* Set a fixed width for the td */}
                    <div onClick={() => focusHandler(3)}>
                      <div style={{ position: "relative" }}>
                        <input
                          type={!eyeIcon.cfn ? "password" : "text"}
                          ref={ref3}
                          className={modules["customInpDiv"]}
                          value={password.confirmnewPass}
                          onChange={(e) => confirmPassHandler(e)}
                          style={{
                            paddingRight: "30px", // Add padding to ensure the text does not overlap with the eye icon
                          }}
                        />
                        <span
                          className={modules["customImg"]}
                          style={{
                            position: "absolute",
                            left: "55px", // Adjusted to right side
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer", // Optional: Add pointer cursor for better UX
                          }}
                        >
                          {password.confirmnewPass && (
                            <img
                              src={eyeIcon.cfn ? EyeIconcross : EyeIcon}
                              onClick={() => {
                                setEyeIcon({ ...eyeIcon, cfn: !eyeIcon.cfn });
                              }}
                              style={{
                                width: "15px", // Set the width of the icon to 15px
                                height: "15px", // Set the height of the icon to 15px
                              }}
                              alt="Toggle visibility" // Add alt text for accessibility
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    {error.confirmErr && (
                      <p className={modules["errorMsg"]} style={{ margin: 0 }}>
                        Confirm Password does not match with new password
                      </p>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className={modules["customDiv"]}>
              <p>
                <span className={modules["customSpan"]}>*</span>Minimum 8
                characters,Atleast 1 Special Character
              </p>
              <p>
                <span className={modules["customSpan"]}>*</span>Atleast 1 Upper
                Alphabet and a Number
              </p>
              <p>
                <span className={modules["customSpan"]}>*</span>Atleast 1
                Smaller Alphabet
              </p>
              <p>
                <span className={modules["customSpan"]}>*</span>Company
                Name,Username not Allowed
              </p>
              <p>
                <span className={modules["customSpan"]}>*</span>Order for 3 or
                more digits Eg:-123456 not allowed
              </p>
              <p>
                <span className={modules["customSpan"]}>*</span>Space not
                Allowed
              </p>
            </div>
            <Grid
              container
              sx={{
                marginLeft: "7rem",
                marginTop: "1rem",
              }}
            >
              <button
                className={modules["customBtn"]}
                onClick={!error.confirmErr ? changePasswordHandler : () => {}}
              >
                Change Password
              </button>
              <button className={modules["customBtn"]} onClick={resetHandler}>
                Cancel
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
