import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/CM1.jpg";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import LAR1 from "../HelpPopups/images/LAR1.jpg";
import LAR2 from "../HelpPopups/images/LAR2.jpg";
import { Link } from "react-router-dom";
import LoginAuditReport from "../CCPM/Reports/LoginAuditReport";

const LoginAuditReporthelpPopup = () => {
    return (
        <>
        <div>
            <Stack
              direction="row"
              spacing={4}
              sx={{ paddingTop: "1rem" }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack sx={{ paddingLeft: "1rem" }}>
                <img src={logo} alt="Godrej" style={{width:"120px"}} />
                <span style={{ paddingTop: "2rem" }}>
                  Expand it...
                  <Link
                    style={{
                      color: "#0000EE",
                      marginRight: "0.6rem",
                      textDecoration: "underline",
                      fontWeight:'bold',
                      fontFamily:'TimesNewRoman'
                    }}
                  >
                    User Manual Index
                  </Link>
                </span>
              </Stack>
    
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={prostream} alt="Prostream" />
              </Stack>
            </Stack>
            <Stack></Stack>
          </div>
    
          <Grid>
            <Grid container className="parent-container">
              <Grid item xs={12} className="child-container">
                
                  <h3 style={{ textDecoration: "underline", fontFamily:'Cablibri,serif',fontSize:'26px'}}>LOGIN AUDIT REPORT</h3>
                  <br></br>
                  This session report is developed to monitor how many users are logged in the system. User can check with different filters and get the desired data.
                  <br />
                  <p style={{fontSize:'18px',fontFamily:"sans-serif"}}> <strong>Session Path {'>>'}CCPM {'>>'} Reports {'>>'} Login Audit Report. </strong></p>
                  
                  <br></br>
                  <Stack sx={{ paddingRight: "1rem" }}>
                    <img src={LAR1} alt="IC1" />
                  </Stack>
                  <ul>
                    <li>
                    Instance – Select instance from the drop down provided.
                    </li>
                    <li>
                    User Role – In this filter users roles are given. User can select the role he wants to check. This is non mandatory filter.
                    </li>
                    <ul>
                        <li> Project Mgr / Alternate Project Mgr.</li>
                        <li> Task Mgr </li>
                    </ul>
                    <li>    User Name – In this filter user names who are authorized to use the selected instance are provided. User can select 1 name from the filter. This is non mandatory filter.
                    </li>
                    <li> Log in Last – Here 4 options are given for selection. All options are calculated from today. Default days is 7 days. User can change and select the date which he wants to.</li>
                    <ul>
                        <li>7</li>
                        <li>14</li>
                        <li>21</li>
                        <li>28</li>
                  </ul>
                  <li>  Session 1 – Here user can select one particular session for which he wants to check the report. In total user can check specifically 2 sessions.</li>

                    <li>Session 2 – Here user can select one particular session for which he wants to check the report. In total user can check specifically 2 sessions.</li>

<li>Once user apply desired filter and click on search. Report will be generated.</li>
</ul>
                  <Stack sx={{ paddingRight: "1rem" }}>
                    <img src={LAR2} alt="IC1" />
                  </Stack>
                    <br></br>

                  <ul>
                    <li>
                    Row 1 – 7 days period is mentioned here. If report is more than 7 days then rows added as 1 row for 7 days.
                    </li>
                    <li>
                    Row 2 – Date range for the second row is provided here.
                    </li>
                    <li>
                    User Name – A selected name of user is shown here. If report is not taken for any particular user then all user names are shown in this column.
                    </li>
                    
                    <li>
                    Login History – Under this field dots are provided. 7 dots in one row and multiple rows are shown for more days. A count is also provided. This count is for Prostream log in done by the user.
                    </li>
                    <ul>
                        <li>
                        Red dot – Red dot indicates the not login date


</li>

<li>  Green Dot – Green dot indicates login day.
</li>
                        
                    </ul>
                    <li>
                    Session 1 – Under this field dots are provided. 7 dots in one row and multiple rows are shown for more days. A count is also provided. This count is for Prostream log in done by the user. This details for the session 1 which is selected.
                    </li>
                    <li>
                    Session 2 – Under this field dots are provided. 7 dots in one row and multiple rows are shown for more days. A count is also provided. This count is for Prostream log in done by the user. This details for the session 2 which is selected.
                    </li>
                  </ul>
                  <br></br>

                 

              </Grid>
            </Grid>
          </Grid>
        </>
      );
};

export default LoginAuditReporthelpPopup;
