import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SPIS1 from "../HelpPopups/images/SPIS1.png";
import SPIS2 from "../HelpPopups/images/SPIS2.png";
import SPIS3 from "../HelpPopups/images/SPIS3.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointItemSupplierConfigurationhelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h2 style={{ marginLeft: "0rem", textAlign: "left" }}>
                                Stock Point Item Supplier Configuration
                            </h2>
                            <br />
                            <br />


                            <li className="no-bullets">
                                Session Path {">>"} MTA {">>"} MTA Parameter {">>"} Stock Point Item Supplier Configuration
                            </li>
                            <br />
                            <li className="no-bullets">
                                Item supplier configuration can be maintained from front end. This field indicates the relation of item and Supplier. For each item, supplier is maintained in master. This is required to generate direct Purchase Requisitions from LN.

                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPIS1} alt="SPIS1" />
                            </Stack>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPIS2} alt="SPIS2" />
                            </Stack>
                            <br />
                            <li >
                                Select MTA Instance
                            </li>
                            <li >
                                Action to be taken is
                                <ul>
                                    <li>
                                        Add new Record – For addition of the new record
                                    </li>
                                    <li>
                                        Update Record – One can update record.
                                    </li>
                                </ul>
                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPIS3} alt="SPIS3" />
                            </Stack>
                            <br />

                            <br />


                            <li>
                                MTA Instance – Select your MTA Instance.
                            </li>
                            <li>
                                Stock Point – Select Stock point from dropdown provided for the field.
                            </li>
                            <li>
                                Company Code – Maintain Company Code for that stock point.


                            </li>
                            <li>
                                Item Code – Put item code required to update in the system and click search. If that item code is already maintained for above combination in system, then pop up message is thrown. Else next fields will appear.
                            </li>
                            <li>
                                To maintain new record
                                <ul>
                                    <li>
                                        Supplier Code – Enter Valid Supplier Code (As per LN) for that item. (Drop Down is available for this field where list of suppliers is appeared.
                                    </li>
                                    <li>
                                        Supplier Name – Supplier Name Will appear in front of that. Just confirm Supplier name.
                                    </li>
                                    <li>
                                        Business Share – This field indicates that how much percentage of business is shared by the supplier if there are multiple suppliers available for that item. Accordingly requisitions will be raised depending upon the required qty from the buffer penetration.


                                    </li>
                                    <li>
                                        Replenishment days - This field indicates that the no of days required for replenishing the item at stock point. Value for this field is a valid integer.
                                    </li>
                                    <li>
                                        Batch Quantity – This field indicates that the item is replenished in the batch of defined nos if value is maintained here. If value is not maintained, then system will consider default value of 1.


                                    </li>
                                    <li className="no-bullets">
                                        Default Value – 1


                                    </li>
                                    <li>
                                        Minimum Order Quantity – This field indicates that the item needs minimum Order Quantity for replenishing the material. If value is no maintained in this field the system will consider default value of 1.



                                    </li>
                                    <li className="no-bullets">
                                        Default Value – 1


                                    </li>
                                </ul>
                            </li>
                            <br />
                            <li> Save record.</li>

                            <br />

                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StockPointItemSupplierConfigurationhelpPopup;
