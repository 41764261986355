import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Stack,
} from "@mui/material";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import loading from "../../../Assets/Loading_image.gif";
import {
  getInstanceList,
  getInstanceAttribute,
  getAttributeValueList,
  createNewAttributeValue,
  updateAttributeValueData,
} from "../../../Services/Admin";

const AttributeMaster = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [instanceDropdownData, setInstanceDropdownData] = useState([]);
  const [instanceAttributeData, setInstanceAttributeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]); // New state for original data
  const [instanceName, setInstanceName] = useState("");
  const [instanceAttributeName, setInstanceAttributeName] = useState("Select");
  const [isAddRowVisible, setIsAddRowVisible] = useState(false);
  const [editing, setEditing] = useState(null);
  const [editedAttributeValue, setEditedAttributeValue] = useState("");
  const [attributeValueActive, setAttributeValueActive] = useState("");
  const [attributeValue, setAttributeValue] = useState("");
  const [isEditedAttributeValueTag, setIsEditedAttributeValueTag] =
    useState(false);
  const [dataTag, setDataTag] = useState(false);
  const [noRec, setNoRec] = useState(false);

  var format = /[`!@#$%^*+\=\[\]{};':"\\|,<>/?~]/;

  useEffect(() => {
    checkLoginAndRedirect();
    getInstanceData();
  }, []);

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };

  const getInstanceData = () => {
    setIsLoading(true);
    const body = { userId: sessionStorage.getItem("loginId") };
    setInstanceAttributeName("Select");
    getInstanceList(body)
      .then((response) => {
        if (response.status === 200 && response?.data?.success?.data) {
          // console.log(response.data.success.data, "instances");
          setIsLoading(false);
          setInstanceDropdownData(response.data.success.data);
          setIsAddRowVisible(true);
          const firstId = response.data.success.data[0].id;
          getInstanceAttributeData(firstId);
        } else {
          setIsLoading(false);
          setInstanceName("");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  function isEmpty(value) {
    return (
      value == null || (typeof value === "string" && value.trim().length === 0)
    );
  }

  const addNewAttributeValue = () => {
    if (!isEmpty(attributeValue)) {
      let res = format.test(attributeValue);
      if (res !== true) {
        const names = filteredData.map((item) => item.attributevalue);
        const value = names.find(
          (obj) =>
            obj.replace(/\s/g, "").toLowerCase() ===
            attributeValue.replace(/\s/g, "").toLowerCase()
        );
        if (!value) {
          let attributeValueBody = {
            instanceHierarchyId: instanceAttributeName,
            attributeValue: attributeValue,
            createdBy: sessionStorage.getItem("loginId"),
          };
          createNewAttributeValue(attributeValueBody)
            .then((res) => {
              if (res?.status === 200) {
                alert("Details Inserted Successfully");
                setDataTag(false);
                setAttributeValue("");
                let attributeListBody = {
                  instanceHierarchyId: instanceAttributeName,
                };
                getAttributeValueList(attributeListBody)
                  .then((result) => {
                    if (result?.data?.success?.data.length === 0) {
                      setDataTag(true);
                      setNoRec(true);
                    } else if (result?.data?.success?.data) {
                      setFilteredData(result?.data?.success?.data);
                      setDataTag(true);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                alert("Error");
                setAttributeValue("");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          alert("Attribute Value Already Exists");
        }
      } else {
        alert("No Special Characters Allowed");
      }
    } else {
      alert("Please Select New Attribute Value To Be Added");
      setAttributeValue("");
    }
  };

  const handleEditSingleEntry = (id, attributeValue) => {
    setEditing(id);
    setEditedAttributeValue(attributeValue);
    // Store the original data when editing starts
    const original = filteredData.find((item) => item.id === id);
    if (original) {
      setOriginalData([original]);
    }
  };

  const handleCancelEdit = () => {
    setEditing(null);
    setIsEditedAttributeValueTag(false);
    // Revert to original data on cancel
    if (originalData.length > 0) {
      const revertedData = filteredData.map((item) => {
        const originalItem = originalData.find(
          (original) => original.id === item.id
        );
        return originalItem ? originalItem : item;
      });
      setFilteredData(revertedData);
    }
  };

  const getInstanceAttributeData = (id) => {
    setInstanceName(id);
    setIsLoading(true);
    setInstanceAttributeName("Select");
    setAttributeValue("");
    let body = {
      instanceId: id,
      activeOnly: 1,
    };
    getInstanceAttribute(body)
      .then((res) => {
        setIsLoading(false);
        if (res.data) {
          setInstanceAttributeData(res.data.success.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleUpdate = (I_id, attributeValue, active) => {
    let lastModifiedBy = sessionStorage.getItem("loginId");
    let checkTag = attributeValue !== editedAttributeValue;
    let activeTag = !isEmpty(attributeValueActive);
    if (checkTag || activeTag) {
      const body = {
        id: I_id,
        attributeValue: editedAttributeValue,
        isActive: active,
        modifiedBy: Number(lastModifiedBy),
      };
      if (!isEmpty(editedAttributeValue)) {
        let res = format.test(editedAttributeValue);
        if (res !== true) {
          const names = filteredData.map((item) => item.attributevalue);
          if (isEditedAttributeValueTag) {
            var value = names.find(
              (obj) =>
                obj.replace(/\s/g, "").toLowerCase() ===
                editedAttributeValue.replace(/\s/g, "").toLowerCase()
            );
          }
          if (!value) {
            updateAttributeValueData(body)
              .then((response) => {
                // console.log(response?.data?.success?.data[0]?.result, "update r");

                if (response?.data?.success?.data[0]?.result === 1) {
                  setDataTag(false);
                  setEditing(null);
                  let attributeListBody = {
                    instanceHierarchyId: instanceAttributeName,
                  };
                  alert("Details Updated Successfully");
                  setIsEditedAttributeValueTag(false);
                  setAttributeValueActive("");
                  getAttributeValueList(attributeListBody).then((result) => {
                    if (result?.data?.success?.data.length === 0) {
                      setDataTag(true);
                      setNoRec(true);
                    } else if (result?.data?.success?.data) {
                      setFilteredData(result?.data?.success?.data);
                      setDataTag(true);
                    }
                  });
                } else if (response?.data?.success?.data[0]?.result === -1) {
                  alert(
                    "Attribute Value is already linked to Project(s) hence cannot be de-activated."
                  );
                } else if (response?.data?.success?.data[0]?.result === -2) {
                  alert("No Update:Attribute Value already exists.");
                } else if (response?.data?.success?.data[0]?.result === -3) {
                  alert("Error While Updating Data..");
                } else {
                  setDataTag(false);
                  setEditing(null);
                  let attributeListBody = {
                    instanceHierarchyId: instanceAttributeName,
                  };
                  alert(response?.data?.message);
                  setAttributeValueActive("");
                  getAttributeValueList(attributeListBody).then((result) => {
                    if (result?.data?.success?.data.length === 0) {
                      setDataTag(true);
                      setNoRec(true);
                    } else if (result?.data?.success?.data) {
                      setFilteredData(result?.data?.success?.data);
                      setDataTag(true);
                    }
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            alert("Attribute Value Already Exists");
            setEditing(null);
            setIsEditedAttributeValueTag(false);
          }
        } else {
          alert("No Special Characters Allowed");
          setEditing(null);
          setIsEditedAttributeValueTag(false);
        }
      } else {
        alert("Enter Attribute Value");
        setEditing(null);
        setIsEditedAttributeValueTag(false);
      }
    } else {
      alert("No Change to Update");
      setEditing(null);
      setIsEditedAttributeValueTag(false);
    }
  };

  function getAttributeTableData(e) {
    setIsLoading(true);
    setInstanceAttributeName(e.target.value);
    let attributeListBody = {
      instanceHierarchyId: e.target.value,
    };
    getAttributeValueList(attributeListBody)
      .then((result) => {
        setIsLoading(false);
        if (result?.data?.success?.data.length === 0) {
          setNoRec(true);
          setDataTag(true);
        } else if (result?.data?.success?.data) {
          setDataTag(true);
          setNoRec(false);
          setFilteredData(result?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  function handleCheckboxChange(e) {
    const updatedItems = filteredData.map((item) =>
      item.id === e ? { ...item, isactive: item.isactive === 1 ? 0 : 1 } : item
    );
    setFilteredData(updatedItems);
    const active = updatedItems.find((item) => {
      if (item.id === e) {
        return item;
      }
    });
    setAttributeValueActive(active.isactive);
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"InstanceAttributeValueMaster"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Instance Attribute Value Master</a>
              </div>
            </div>

            <Grid container className={modules["parent-container"]}>
              <Grid container className={modules["mid-contAM"]}>
                <Grid item sx={{ marginTop: "0rem" }}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <label className={modules["label-style"]}>
                        Instance :<span style={{ color: "red" }}>*</span>
                      </label>
                    </Grid>
                    <Grid item xs={6}>
                      <select
                        name="instanceSelect"
                        id="instanceSelect"
                        value={instanceName}
                        onChange={(e) => {
                          // console.log("Selected value:", e.target.value);
                          getInstanceAttributeData(e.target.value);
                        }}
                        className={modules["dropdownSelect"]}
                        style={{
                          fontSize: "12px",
                          width: "18rem",
                          marginLeft: "20px",
                          // zIndex: "100000",
                        }}
                      >
                        {instanceDropdownData.length !== 0 ? (
                          <>
                            {instanceDropdownData.map((i) => (
                              <option
                                key={i.id}
                                value={i.id}
                                style={{ fontSize: "12px", color: "#373736" }}
                              >
                                {i.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value="Select">Select</option>
                        )}
                      </select>
                    </Grid>

                    {/* <Grid item xs={3.0}></Grid> */}

                    <Grid item xs={2.0}>
                      <label className={modules["label-style"]}>
                        Instance Attribute:
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </Grid>
                    <Grid item xs={2.5}>
                      <select
                        name="attributeSelect"
                        id="attributeSelect"
                        value={instanceAttributeName}
                        onChange={(e) => {
                          if (instanceAttributeData.length !== 0) {
                            getAttributeTableData(e);
                          } else if (
                            instanceName === "" ||
                            instanceName === "Select"
                          ) {
                            alert("Select Instance");
                          }
                        }}
                        className={modules["dropdownSelect"]}
                        style={{
                          fontSize: "12px",
                          width: "18rem",
                          marginLeft: "20px",
                        }}
                      >
                        {instanceAttributeData.length !== 0 ? (
                          <>
                            <option value="Select" key="Select">
                              SELECT
                            </option>
                            {instanceAttributeData
                              .filter((i) => i.isactive === 1)
                              .map((i) => (
                                <option
                                  value={i.attributenumber}
                                  key={i.attributename}
                                  style={{ fontSize: "12px", color: "#373736" }}
                                >
                                  {i.attributename}
                                </option>
                              ))}
                          </>
                        ) : (
                          <option value="Select">Select</option>
                        )}
                      </select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ display: dataTag === false ? "none" : "" }}
                className={modules["mid-contAM"]}
              >
                <Grid item lg={12} sx={{ marginTop: "0rem" }}>
                  <Grid container sx={{ alignItems: "center" }}>
                    <Grid item>
                      <label className={modules["label-style"]}>
                        Attribute Value :
                      </label>
                    </Grid>
                    <Grid item xs={2.5}>
                      <FormControl className={modules["form-control"]}>
                        <span>
                          <input
                            value={attributeValue}
                            onChange={(e) => setAttributeValue(e.target.value)}
                            className={modules["inputText"]}
                            style={{ paddingLeft: "5px" }}
                          />
                        </span>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2.0}>
                      <div className={modules["attr-btn"]}>
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={addNewAttributeValue}
                        >
                          Add Attribute Value
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  display: dataTag === false ? "none" : "",
                  marginTop: "0px",
                }}
                className={modules["table-gridAM"]}
              >
                <div
                  className={modules["table-container"]}
                  style={{ width: "70%" }}
                >
                  <Table className={modules["custom-table"]}>
                    {noRec ? (
                      <>
                        <tr class="norecord">
                          <td style={{ width: "20%" }}>No Record Found.</td>
                          <td colspan="5"></td>
                          <td colspan="5"></td>
                          <td colspan="5"></td>
                          <td colspan="5"></td>
                          <td colspan="5"></td>
                          <td colspan="5"></td>
                          <td colspan="5"></td>
                          <td colspan="5"></td>
                          <td colspan="5"></td>
                          <td colspan="5"></td>
                          <td colspan="5"></td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                textAlign: "left !important",
                                width: "10%",
                              }}
                              className={modules["table-cell"]}
                            >
                              Sr. No.
                            </TableCell>
                            <TableCell
                              sx={{ textAlign: "left !important" }}
                              className={modules["table-cell"]}
                            >
                              AttributeValue
                            </TableCell>
                            <TableCell
                              sx={{ textAlign: "left !important" }}
                              className={modules["table-cell"]}
                            >
                              Active{" "}
                            </TableCell>
                            <TableCell
                              sx={{ textAlign: "left !important" }}
                              className={modules["table-cell"]}
                            >
                              Edit
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredData?.map((item, index) => (
                            <TableRow
                              key={index}
                              className={modules["table-row"]}
                            >
                              <TableCell
                                sx={{
                                  textAlign: "left !important",
                                  color: "#414141",
                                }}
                                className={modules["table-cell"]}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "left !important",
                                  color: "#414141",
                                }}
                                className={modules["table-cell"]}
                              >
                                {editing === item.id ? (
                                  <input
                                    type="text"
                                    value={editedAttributeValue}
                                    onChange={(e) => {
                                      setIsEditedAttributeValueTag(true);
                                      setEditedAttributeValue(e.target.value);
                                    }}
                                  />
                                ) : (
                                  <div>{item.attributevalue}</div>
                                )}
                              </TableCell>

                              <TableCell
                                sx={{ textAlign: "left !important" }}
                                className={modules["table-cell"]}
                              >
                                <div>
                                  <Checkbox
                                    sx={{
                                      padding: "0px",
                                      "&.Mui-checked": {
                                        color: "grey",
                                      },
                                    }}
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;
                                      handleCheckboxChange(item.id);
                                      setAttributeValueActive(isChecked);
                                    }}
                                    size="13px"
                                    checked={
                                      attributeValue === ""
                                        ? item.isactive
                                        : attributeValue
                                    }
                                    disabled={
                                      editing === item.id
                                        ? editing == null
                                          ? true
                                          : false
                                        : true
                                    }
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                sx={{ textAlign: "left !important" }}
                                className={modules["table-cell"]}
                              >
                                <div
                                  className={modules["btn2-cont"]}
                                  style={{ alignContent: "center" }}
                                >
                                  {editing === item.id ? (
                                    <>
                                      <Stack
                                        direction="row"
                                        style={{ alignContent: "center" }}
                                        spacing={2}
                                      >
                                        <button
                                          className={modules["updateBtn"]}
                                          style={{
                                            paddingRight: "1rem",
                                            cursor: "pointer",
                                            width: "40%",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleUpdate(
                                              item.id,
                                              item.attributevalue,
                                              item.isactive
                                            )
                                          }
                                        >
                                          Update
                                        </button>
                                        <button
                                          className={modules["updateBtn"]}
                                          style={{
                                            cursor: "pointer",
                                            width: "40%",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleCancelEdit}
                                        >
                                          Cancel
                                        </button>
                                      </Stack>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className={modules["updateBtn"]}
                                        onClick={() =>
                                          handleEditSingleEntry(
                                            item.id,
                                            item.attributevalue
                                          )
                                        }
                                        style={{
                                          width:
                                            editing == null ? "75%" : "40%",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Edit
                                      </button>
                                    </>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow></TableRow>
                        </TableBody>
                      </>
                    )}
                  </Table>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AttributeMaster;
