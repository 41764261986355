import React, { useState, useEffect, useRef } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moduless from "./AlternateProjectManager.module.css";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetInstancesListt, AtmGetAccessType, AtmGetAtm, AtmGetProjects, AtmGetSelectionGrid, AtmGetTaskManager, AtmGetExistingAtm,
  ExistingPMgrid, SubmitAlternatePM, AtmSaveClick, AtmDeleteClick, AtmGetTasks, SubmitAlternateTM, AtmGetAtm2, imgSave_ClickTMATM
} from "../../../Services/CCPM";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import "./CalendarStyles.css";
import { styled } from "@mui/system";
import loading from "../../../Assets/Loading_image.gif";
import { Popper, Paper, Popover } from "@mui/material";
import saveIcon from "../../../Assets/Save.jpg";
import deleteicon from "../../../Assets/i6.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiTypography-root": {
    fontSize: "13px",
  },
});

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const AlternateTaskManager = () => {
  //ADD ALTERNATE MANAGER VARIABLES
  const [instance, setInstance] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState(
    instance?.[0] || "Select"
  );

  const [accesstype, setAccesstype] = useState([]);
  const [selectedAccesstype, setSelectedAccesstype] = useState("");
  const [alternatePM, setAlternatePM] = useState([]);
  const [selectedAlternatePM, setSelectedAlternatePM] = useState("");
  const [selectiongrid, setSelectiongrid] = useState([]);
  //const [finalgrid, setFinalgrid] = useState(selectiongrid)
  const [PM, setPM] = useState([]);
  const [selectedPM, setSelectedPM] = useState("");
  const [selectedTM, setSelectedTM] = useState("");

  //EDIT ALTERNATE MANAGER VARIABLES
  const [existingPM, setExistingPM] = useState([]);
  const [selectedExistingPM, setSelectedExistingPM] = useState("");
  const [detailgrid, setDetailgrid] = useState([]);

  //CALENDAR VARIABLES
  const [displaydate1, setDisplaydate1] = useState("");
  const [displaydate2, setDisplaydate2] = useState("");
  const [passdate1, setPassdate1] = useState(""); //formatted date (for passing to api)
  const [passdate2, setPassdate2] = useState("");
  const [calendarOpen1, setCalendarOpen1] = useState(false);
  const [calendarOpen2, setCalendarOpen2] = useState(false);
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [selectedTMid, setSelectedTMid] = useState("");
  const [selectedTMitentityid, setSelectedTMitentityid] = useState("");

  // for calendar popup days representation (Mo Tu We....)

  const toggleCalendar1 = () => {
    setCalendarOpen1((prevOpen) => !prevOpen);
    setCalendarOpen2(false);
  };
  const toggleCalendar2 = () => {
    setCalendarOpen2((prevOpen) => !prevOpen);
    setCalendarOpen1(false);
  };

  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the clicked element is outside the Popper and TextField
      if (anchorRef1.current && !anchorRef1.current.contains(event.target)) {
        setAnchorEl1(null); // Close the calendar
      }
      if (anchorRef2.current && !anchorRef2.current.contains(event.target)) {
        setAnchorEl2(null);
      }
    }

    // Add event listener to document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick1 = (i) => {
    setAnchorEl1(anchorEl1 ? null : i);
    setAnchorEl2(null);
  };
  const handleClick2 = (i) => {
    setAnchorEl2(anchorEl2 ? null : i);
    setAnchorEl1(null);
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    let body = {
      userId: sessionStorage.getItem("loginId"), // Fetch user ID from session storage
    };

    // Fetch the instance list based on the userId
    GetInstancesListt(body)
      .then((response) => {
        if (response.status === 200) {
          const instanceData = response.data.success.data;

          setIsLoading(false);
          setInstance(instanceData);

          // Set default instance and fetch alternate PM for the first instance
          if (instanceData.length > 0) {
            const defaultInstanceId = instanceData[0].id;
            console.log("Default instance ID:", defaultInstanceId);
            setSelectedInstance(defaultInstanceId); // Set default instance ID

            // Fetch Alternate PM based on the default instance
            let atmBody = { divId: defaultInstanceId };
            AtmGetAtm(atmBody)
              .then((res) => {
                if (res.status === 200 && res.data.success.data.length > 0) {
                  setAlternatePM(res.data.success.data); // Populate alternate PM
                } else {
                  setAlternatePM([]); // Clear alternate PM if no data
                }
              })
              .catch((err) => {
                console.log("Error fetching alternate PM:", err);
              });
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

    // Fetch access type list
    AtmGetAccessType()
      .then((res) => {
        if (res.status === 200) {
          setAccesstype(res.data.success.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  console.log("alternatePM:", alternatePM);

  const GreyCheckbox = styled(Checkbox)({
    color: "grey",
    padding: "0px!important",
    "&.Mui-checked": {
      color: "grey",
    },
    "& .MuiSvgIcon-root": {
      width: "13px",
      height: "13px",
    },
  });

  //function called when date is selected from calendar
  const handleCalendar1 = (date) => {
    setAnchorEl1(null);
    console.log(date.toString());
    setFromdate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    console.log(`${day}/${month}/${year}`);
    setDisplaydate1(formatDate(`${day}/${month}/${year}`, "f")); //formatted date (for displaying in input field)
    console.log('displaydate1:', displaydate1);
    setPassdate1(`${year}-${month}-${day}`); //formatted date (for passing to api)
    setCalendarOpen1(false);
  };

  const handleCalendar2 = (date) => {
    setAnchorEl2(null);
    console.log(date.toString());
    setTodate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    console.log(`${day}/${month}/${year}`);
    setDisplaydate2(formatDate(`${day}/${month}/${year}`));
    setPassdate2(`${year}-${month}-${day}`);
    setCalendarOpen2(false);
  };
  const [selectedValue, setSelectedValue] = useState(""); //radio button index

  //function to handle radio button index change
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (instance.length > 0) {
      if (event.target.value === "option1") {
        handleInstance(instance[0].id);
      } else {
        handleInstance2(instance[0].id);
      }
    }
    setSelectedInstance("Select");
    setSelectedAccesstype("");
    setAlternatePM([]);
    setSelectedAlternatePM("");
    setPM([]);
    setSelectedPM("");
    setSelectedTM("");
    setSelectiongrid([]);
    setExistingPM([]);
    setSelectedExistingPM("");
    setDetailgrid([]);
    setDisplaydate1("");
    setDisplaydate2("");
    setPassdate1("");
    setPassdate2("");
    setIsChecked(0);
    setCalendarOpen1(false);
    setCalendarOpen2(false);
    setFromdate("");
    setTodate("");
  };

  //triggered when submit button is clicked (Add Alternate Manager)
  const handleSearch = () => {
    if (!passdate1 || !passdate2) {
      alert("Please Select both Access From Date And To Date.");
      return; // Exit the function if validation fails
    }
    if (passdate1 < cformattedDate || passdate2 < cformattedDate) {
      alert(
        "Access From Date and Access To Date should be greater than or equal to Today"
      );
      return; // Exit the function if validation fails
    }

    const checkedRows = getCheckedRows();
    if (selectedAccesstype !== "3" && checkedRows.length === 0) {
      alert("Select Project(s) in the Grid for Access.");
      return; // Exit the function if validation fails
    }

    // Determine the body based on whether there are checked rows or not
    const body =
      checkedRows.length === 0
        ? [
          {
            divId: parseInt(selectedInstance, 10), // Ensure it's an integer
            alternateTMId: parseInt(selectedAlternatePM, 10), // Updated field name and type
            accessTypeId: parseInt(selectedAccesstype, 10), // Ensure it's an integer
            projectId: parseInt(
              selectedPM !== "" && selectedPM !== "Select" ? selectedPM : 0,
              10
            ), // Updated field name and type
            taskManagerId: 0, // Default value when no rows are checked
            taskIdentityId: "0", // Default value when no rows are checked
            accessFromDate: passdate1,
            accessToDate: passdate2,
            isViewOnly: isChecked ? 1 : 0, // Ensure it’s an integer (0 or 1)
            createdBy: parseInt(sessionStorage.getItem("loginId"), 10), // Ensure it's an integer
          },
        ]
        : checkedRows.map((row) => ({
          divId: parseInt(row.divId, 10), // Ensure it's an integer
          alternateTMId: parseInt(row.alternatePMId, 10), // Updated field name and type
          accessTypeId: parseInt(row.accessTypeId, 10), // Ensure it's an integer
          projectId: parseInt(row.projectid, 10), // Updated field name and type
          taskManagerId: parseInt(row.taskManagerId, 10), // Ensure it's an integer
          taskIdentityId: row.taskIdentityId.toString(),
          accessFromDate: row.accessFromDate,
          accessToDate: row.accessToDate,
          isViewOnly: parseInt(row.isViewOnly, 10), // Ensure it’s an integer (0 or 1)
          createdBy: parseInt(row.createdBy, 10), // Ensure it's an integer
        }));

    // Log the body to verify
    console.log(body);

    // Perform your API call with the constructed body
    SubmitAlternateTM(body)
      .then((response) => {
        if (response.status === 200) {
          // Access the first message from the data array
          const firstMessage = response?.data?.success?.data?.[0]?.message;

          if (firstMessage) {
            alert(firstMessage);
            setDisplaydate1("");
            setDisplaydate2("");
            setIsChecked(0);

            setSelectedAlternatePM("");
            setSelectedAccesstype("");
          } else {
            alert("No message available.");
          }
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  //triggered when reset button is clicked
  const handleReset = () => {
    setSelectedInstance("Select");
    setSelectedAccesstype("");
    setAlternatePM([]);
    setSelectedAlternatePM("");
    setPM([]);
    setSelectedPM("");
    setSelectedTM("");
    setSelectiongrid([]);
    setSstate({
      checkedRows: new Set(),
      allChecked: false,
    });
    setDisplaydate1("");
    setDisplaydate2("");
    setPassdate1("");
    setPassdate2("");
    setIsChecked(0);
    setCalendarOpen1(false);
    setCalendarOpen2(false);
    setFromdate("");
    setTodate("");
  };

  //function to handle instance change
  //get list of alternate project managers based on instance id
  const handleInstance = (i) => {
    setIsLoading(true);
    setSelectedInstance(i);
    setSelectedAlternatePM("Select");
    setSelectedAccesstype("");
    setDisplaydate1("");
    setDisplaydate2("");
    setPassdate1("");
    setPassdate2("");
    setCalendarOpen1(false);
    setCalendarOpen2(false);
    setFromdate("");
    setTodate("");
    setIsChecked(0);
    let body = {
      divId: i, // Use selected instance ID
    };

    // Fetch Alternate PM for the selected instance
    AtmGetAtm(body)
      .then((response) => {
        if (response.status === 200 && response.data.success.data.length > 0) {
          setAlternatePM(response.data.success.data); // Set new data
        } else {
          setAlternatePM([]); // Clear if no data
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching alternate PM:", error);
        setIsLoading(false);
      });
  };

  //function to handle instance change
  //get list of Existing project managers based on instance id
  const handleInstance2 = (i) => {
    setIsLoading(true);
    setSelectedInstance(i);
    setSelectedExistingPM("");
    let body = {
      divId: i,
    };
    AtmGetExistingAtm(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          console.log(response);
          setExistingPM(response.data.success.data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //function to handle access type change
  const handleAccesstype = (i) => {
    setSelectedAccesstype(i);

    if (i === "1") {
      setPM([]);
      setSstate({
        checkedRows: new Set(),
        allChecked: false,
      });
      setSelectedPM("");
      setSelectedTM("");
      setIsLoading(true);
      let body = {
        divId: selectedInstance,
        alternateTMId:
          selectedAlternatePM !== "" && selectedAlternatePM !== "Select"
            ? selectedAlternatePM
            : 0,
        accessType: 1,
        taskManagerId: 0,
      };
      AtmGetProjects(body) //get selection table data
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            console.log("ResponseData", response);
            setSelectiongrid(response?.data?.success?.data);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (i === "2") {
      setSstate({
        checkedRows: new Set(),
        allChecked: false,
      });
      let body = {
        divId: selectedInstance,
        alternateTMId:
          selectedAlternatePM !== "" && selectedAlternatePM !== "Select"
            ? selectedAlternatePM
            : 0,
        accessType: 2,
        taskManagerId: 0,
      };
      AtmGetProjects(body)
        .then((response) => {
          console.log(response);

          setPM(
            response?.data?.success?.data.map((item) => {
              return { id: item.projectid, username: item.projectname };
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (i === "3") {
      setSelectedTM("Select");
      setIsLoading(true);

      let body = {
        divId: selectedInstance,
        alternateTMId:
          selectedAlternatePM !== "" && selectedAlternatePM !== "Select"
            ? selectedAlternatePM
            : 0,
        accessType: 3,
      };
      AtmGetTaskManager(body) //get list of project managers
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            console.log(response);
            setPM(response?.data?.value?.success?.data);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (i === "4") {
      setSelectedTM("Select");
      setIsLoading(true);
      setSelectiongrid([]);
      setSstate({
        checkedRows: new Set(),
        allChecked: false,
      });
      let body = {
        divId: selectedInstance,
        alternateTMId:
          selectedAlternatePM !== "" && selectedAlternatePM !== "Select"
            ? selectedAlternatePM
            : 0,
        accessType: 4,
      };
      AtmGetTaskManager(body) //get list of project managers
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            console.log(response);
            setPM(response?.data?.value?.success?.data);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSelectiongrid([]);
      setSstate({
        checkedRows: new Set(),
        allChecked: false,
      });
      setPM([]);
      setSelectedTM("");
    }
  };

  //function to handle existing project manager change (EDIT ALTERNATE MANAGER)
  const handleExistingPM = (i) => {
    setIsLoading(true);
    setSelectedExistingPM(i);

    // Prepare the request body
    let body = {
      divId: selectedInstance,
      alternateTMId: i !== "" && i !== "Select" ? i : 0,
    };

    AtmGetAtm2(body) // Get detail table data
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          console.log(response);

          // Check if the response contains data and if it's not empty
          if (response?.data?.success?.data && response?.data?.success?.data.length > 0) {
            setDetailgrid(response?.data?.success?.data);
            console.log("DetailGrid:", response?.data?.success?.data);
          } else {
            // No data found, clear the grid
            setDetailgrid([]);
            console.log("No data found, DetailGrid set to empty array.");
          }
        } else {
          setIsLoading(false);
          // Handle unexpected status codes if needed
          setDetailgrid([]);
          console.log("Unexpected response status, DetailGrid set to empty array.");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setDetailgrid([]);
        console.log("Error occurred, DetailGrid set to empty array.");
      });
  };


  useEffect(() => {
    if (detailgrid.length > 0) {
      const initialChecked = detailgrid[0]?.IsViewOnly === 1 ? 1 : 0;
      setIsChecked(initialChecked);
    }
  }, [detailgrid]);

  const accessTypeLabels = {
    1: "All tasks in selected project",
    2: "Selected tasks of a project",
    3: "All tasks of a selected task Manager",
    4: "All tasks of a selected task manager of a selected projects",
  };

  //TABLE CHECKBOX SELECTION PART
  const [sstate, setSstate] = useState({
    checkedRows: new Set(),
    allChecked: false,
  });

  const handleCheckboxChange = (index) => {
    setSstate((prevState) => {
      const checkedRows = new Set(prevState.checkedRows);
      if (checkedRows.has(index)) {
        checkedRows.delete(index);
      } else {
        checkedRows.add(index);
      }
      const allChecked = checkedRows.size === selectiongrid.length;
      return { checkedRows, allChecked };
    });
  };

  const handleTableHeadCheckboxChange = () => {
    setSstate((prevState) => {
      const allChecked = !prevState.allChecked;
      const checkedRows = new Set();
      if (allChecked) {
        selectiongrid.forEach((_, index) => checkedRows.add(index));
      }
      return { checkedRows, allChecked };
    });
  };

  const getCheckedRows = () => {
    return selectiongrid
      .filter((_, index) => sstate.checkedRows.has(index))
      .map((item) => ({
        divId: selectedInstance,
        alternatePMId: selectedAlternatePM,
        accessTypeId: selectedAccesstype,
        projectid: item.projectid || 0,
        taskManagerId: selectedTM || 0,
        taskIdentityId: item.taskidentityid || 0,
        accessFromDate: passdate1,
        accessToDate: passdate2,
        isViewOnly: isChecked,
        createdBy: parseInt(sessionStorage.getItem("loginId")),
      }));
  };
  const [checkedRows, setCheckedRows] = useState(new Set());

  //VIEW PROJECT ACCESS CHECKBOX PART
  const [isChecked, setIsChecked] = useState(0);
  const handleViewProjAccessCheck = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };

  //function to handle alternate project manager change
  const handleAlternatePM = (i) => {
    setSelectedAlternatePM(i);
    setSelectedAccesstype("Select");
  };

  //function to handle save button click
  const handleSave = (id, accestypeid, from, to,itemId) => {
    setIsLoading(true);
    let body = {
      id: id,
      accessTypeId: accestypeid,
      accessFromDate: passdate1 !== "" ? passdate1 : from,
      accessToDate: passdate2 !== "" ? passdate2 : to,
      isViewOnly: rowCheckedStatus[itemId],
      isDelete: 0,
      modifiedBy: sessionStorage.getItem("loginId"),
    };
    imgSave_ClickTMATM(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          alert(response.data.message);
          handleExistingPM(selectedExistingPM); // Pass the appropriate parameter here
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //function to handle delete button click
  const handleDelete = (id, accestypeid, from, to) => {
    setIsLoading(true);
    let body = {
      id: id,
      accessTypeId: accestypeid,
      accessFromDate: passdate1 !== "" ? passdate1 : from,
      accessToDate: passdate2 !== "" ? passdate2 : to,
      isViewOnly: isChecked,
      isDelete: 1,
      modifiedBy: sessionStorage.getItem("loginId"),
    };
    AtmDeleteClick(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          alert(response.data.message);
          handleExistingPM(selectedExistingPM); // Pass the appropriate parameter here
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //for option 4 (when task manager is selected)
  const handleTaskManager = (i) => {
    setSelectedTM(i);
    if (selectedAccesstype === "4") {
      let body = {
        divId: selectedInstance,
        alternateTMId:
          selectedAlternatePM !== "" && selectedAlternatePM !== "Select"
            ? selectedAlternatePM
            : 0,
        accessType: 4,
        taskManagerId: i,
      };
      AtmGetSelectionGrid(body) //get selection table data
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            console.log(response);
            setSelectiongrid(response?.data?.value?.success?.data);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //for option 2 (when project is selected)
  const handleProject = (i) => {
    setSelectedPM(i);
    if (selectedAccesstype === "2") {
      let body = {
        divId: selectedInstance,
        alternateTMId:
          selectedAlternatePM !== "" && selectedAlternatePM !== "Select"
            ? selectedAlternatePM
            : 0,
        projectId: i,
      };
      AtmGetTasks(body) //get selection table data
        .then((response) => {
          if (response.status === 200) {
            // Check if the response indicates no data
            if (
              response?.data?.success?.message === null &&
              response?.data?.success?.data.length === 0
            ) {
              alert("No Tasks Data found for selection to given Project.");
              setSelectiongrid([]); // Clear the selection grid
            } else {
              setSelectiongrid(response?.data?.success?.data); // Set the grid with data
            }
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function formatDate(inputDate, i) {
    if (i === "b") {
      const [year, month, day] = inputDate.split("/");
      const date = new Date(year, month - 1, day);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-GB", options);
    } else {
      const [day, month, year] = inputDate.split("/");
      const date = new Date(year, month - 1, day);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-GB", options);
    }
  }




  console.log(PM)

  //for calendar footer
  const today = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedToday = today.toLocaleDateString("en-US", options);
  //for comparing entered date with current date
  const cyear = today.getFullYear();
  const cmonth = String(today.getMonth() + 1).padStart(2, "0");
  const cday = String(today.getDate()).padStart(2, "0");
  const cformattedDate = `${cyear}-${cmonth}-${cday}`;

  //edit alternate manager calendar and date states
  const [anchorEl, setAnchorEl] = useState({});
  const [dates, setDates] = useState({});
  const handleClick = (rowIndex, field, event) => {
    setAnchorEl({ ...anchorEl, [`${rowIndex}-${field}`]: event.currentTarget });
  };
  const handleClose = (rowIndex, field) => {
    setAnchorEl({ ...anchorEl, [`${rowIndex}-${field}`]: null });
  };
  const handleDateChange = (rowIndex, field, date) => {
    //display properly in input field
    const displayDateOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date
      .toLocaleDateString("en-GB", displayDateOptions)
      .replace(/ /g, " ");
    setDates({ ...dates, [`${rowIndex}-${field}`]: formattedDate });
    handleClose(rowIndex, field);
  };

  //add alternate manager calendar and date states
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? "simple-popover" : undefined;
  const id2 = open2 ? "simple-popover" : undefined;

  //for calendar popup days representation (Mo Tu We....)
  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: "short" }).slice(0, 2);
  };


  const [rowCheckedStatus, setRowCheckedStatus] = useState({}); // Initialize as an empty object

  useEffect(() => {
    const initialCheckedState = {};
    detailgrid.forEach(item => {
      initialCheckedState[item.Id] = item.IsViewOnly; // Set each checkbox based on IsViewOnly
    });
    setRowCheckedStatus(initialCheckedState);
  }, [detailgrid]);

  const toggleRowCheckStatus = (event, itemId) => {
    setRowCheckedStatus((prevState) => ({
      ...prevState,
      [itemId]: event.target.checked ? 1 : 0, // Toggle checked status for the specific item by Id
    }));
  };


  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"AlternateTM"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>Project Plan</a>
                <img src={bread} alt="" />
                <a>Alternate Task Manager</a>
              </div>
            </div>
          </Grid>

          <Grid item lg={12} className={modules["radio-buttons"]}>
            <RadioGroup row value={selectedValue} onChange={handleChange}>
              <CustomFormControlLabel
                value="option1"
                control={<Radio className={modules["radio-scale"]} />}
                label="Add Alternate Manager"
                className={modules["radio-label"]}
              />
              <CustomFormControlLabel
                value="option2"
                control={<Radio className={modules["radio-scale"]} />}
                label="Edit Alternate Manager"
                className={modules["radio-label"]}
              />
            </RadioGroup>
          </Grid>

          {selectedValue === "option1" && (
            <Grid container className={modules["parent-container-apm"]}>
              <div className={modules["bottom-grid-heading-apm"]}>
                Add Alternate Manager
              </div>
              <Stack
                spacing={2}
                direction="row"
                className={modules["each-entry-apm"]}
              >
                <div className={modules["input-labels-apm"]}>
                  Instance:<span className={modules["asterisk"]}>*</span>{" "}
                </div>
                <select
                  className={modules["dropdownSelect1-apm"]}
                  value={selectedInstance}
                  style={{ width: "18.75rem", height: "2.1rem" }}
                  onChange={(e) => handleInstance(e.target.value)}
                >
                  {instance.length > 0 &&
                    instance?.map((i) => (
                      <option value={i.id} key={i}>
                        {i.name}
                      </option>
                    ))}
                </select>
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                className={modules["each-entry-apm"]}
              >
                <div className={modules["input-labels-apm"]}>
                  Alternate Task Manager(To Be):
                  <span className={modules["asterisk"]}>*</span>{" "}
                </div>

                <select
                  className={modules["dropdownSelect1-apm"]}
                  value={selectedAlternatePM}
                  style={{ width: "18.75rem", height: "2.1rem" }}
                  onChange={(e) => handleAlternatePM(e.target.value)}
                >
                  <option value="Select">SELECT</option>
                  {alternatePM.length > 0 &&
                    alternatePM.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.username}
                      </option>
                    ))}
                </select>
              </Stack>

              {selectedAlternatePM !== "" &&
                selectedAlternatePM !== "Select" && (
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry-apm"]}
                  >
                    <div className={modules["input-labels-apm"]}>
                      Access Type:<span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1-apm"]}
                      value={selectedAccesstype}
                      style={{ width: "18.75rem", height: "2.1rem" }}
                      onChange={(e) => handleAccesstype(e.target.value)}
                    >
                      {accesstype?.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {accesstype?.length > 0 &&
                        accesstype?.map((i) => (
                          <option value={i.id} key={i.id}>
                            {i.accesstype}
                          </option>
                        ))}
                    </select>
                  </Stack>
                )}
              {selectedAccesstype === "1" && selectiongrid?.length > 0 && (
                <Grid
                  container
                  style={{ width: "70%" }}
                  className={[
                    modules["child-container"],
                    modules["bottom-grid"],
                  ].join(" ")}
                >
                  <Grid container className={modules["selection-grid"]}>
                    <div
                      className={modules["table-container"]}
                      style={{
                        width: "90%",
                        paddingTop: "0.5rem",
                        paddingLeft: " 0.3rem",
                        paddingBottom: "0.5rem",
                      }}
                    >
                      <Table
                        className={[
                          modules["custom-table"],
                          modules["custom-table-th"],
                        ].join(" ")}
                      >
                        <TableHead
                          style={{ textAlign: "center", height: "35px" }}
                        >
                          <TableRow>
                            <TableCell>
                              <input
                                type="checkbox"
                                checked={sstate.allChecked}
                                onChange={handleTableHeadCheckboxChange}
                                className={modules["custom-checkboxnew"]}
                                style={{ textAlign: "center !important" }}
                              />
                            </TableCell>
                            <TableCell
                              style={{ textAlign: "center", width: "75px" }}
                            >
                              Sr. No.
                            </TableCell>
                            <TableCell
                              style={{ textAlign: "center", width: "170px" }}
                            >
                              Project Name
                            </TableCell>
                            <TableCell
                              style={{ textAlign: "center", width: "140px" }}
                            >
                              Customer Name
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              ERP ProjectCompany
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              ERP ProjectNo
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectiongrid.map((item, index) => (
                            <TableRow
                              key={index}
                              className={modules["table-row"]}
                            >
                              <TableCell>
                                <input
                                  type="checkbox"
                                  checked={sstate.checkedRows.has(index)}
                                  onChange={() => handleCheckboxChange(index)}
                                  className={modules["custom-checkbox"]}
                                />
                              </TableCell>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{item.projectname}</TableCell>
                              <TableCell>{item.customername}</TableCell>
                              <TableCell>{item.erpprojectcompany}</TableCell>
                              <TableCell>{item.erpprojectno}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                </Grid>
              )}
              {selectedAccesstype === "2" && (
                <>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry-apm"]}
                  >
                    <div className={modules["input-labels-apm"]}>
                      Project:<span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1-apm"]}
                      style={{ width: "18.75rem", height: "2.1rem" }}
                      value={selectedPM}
                      onChange={(e) => handleProject(e.target.value)}
                    >
                      <option value="Select">SELECT</option>
                      {PM.map((i) => (
                        <option value={i.id} key={i}>
                          {i.username}
                        </option>
                      ))}
                    </select>
                  </Stack>
                  {selectedPM &&
                    selectedPM !== "Select" &&
                    selectiongrid?.length > 0 && (
                      <Grid
                        container
                        className={[
                          modules["child-container"],
                          modules["bottom-grid"],
                        ].join(" ")}
                      >
                        <Grid container className={modules["selection-grid"]}>
                          <div
                            className={modules["table-container"]}
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: " 0.3rem",
                              paddingBottom: "0.5rem",
                              width: { width: "49%" },
                            }}
                          >
                            <Table
                              className={[
                                modules["custom-table"],
                                modules["custom-table-th"],
                              ].join(" ")}
                            >
                              <TableHead
                                style={{ textAlign: "center", height: "35px" }}
                              >
                                <TableRow>
                                  <TableCell>
                                    <input
                                      type="checkbox"
                                      checked={sstate.allChecked}
                                      onChange={handleTableHeadCheckboxChange}
                                      className={modules["custom-checkboxnew"]}
                                    />
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Sr. No.
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Task Name
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Task Manager
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectiongrid.map((item, index) => (
                                  <TableRow
                                    key={index}
                                    className={modules["table-row"]}
                                  >
                                    <TableCell>
                                      <input
                                        type="checkbox"
                                        checked={sstate.checkedRows.has(index)}
                                        onChange={() =>
                                          handleCheckboxChange(index)
                                        }
                                        className={modules["custom-checkbox"]}
                                      />
                                    </TableCell>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{item.taskname}</TableCell>
                                    <TableCell>{item.taskmanager}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                </>
              )}
              {(selectedAccesstype === "4" || selectedAccesstype === "3") && (
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-apm"]}
                >
                  <div className={modules["input-labels-apm"]}>
                    Task Manager:<span className={modules["asterisk"]}>*</span>{" "}
                  </div>
                  <select
                    className={modules["dropdownSelect1-apm"]}
                    style={{ width: "18.75rem", height: "2.1rem" }}
                    value={selectedTM}
                    onChange={(e) => handleTaskManager(e.target.value)}
                  >
                    {selectedTM === "Select" && (
                      <option value="Select">SELECT</option>
                    )}
                    {PM.map((i) => (
                      <option value={i.id} key={i}>
                        {i.username}
                      </option>
                    ))}
                  </select>
                </Stack>
              )}
              {selectedAccesstype === "4" && selectiongrid?.length > 0 && (
                <Grid
                  container
                  className={[
                    modules["child-container"],
                    modules["bottom-grid"],
                  ].join(" ")}
                >
                  <Grid container className={modules["selection-grid"]}>
                    <div className={modules["table-container"]}>
                      <Table
                        className={[
                          modules["custom-table"],
                          modules["custom-table-th"],
                        ].join(" ")}
                      >
                        <TableHead style={{ textAlign: "center" }}>
                          <TableRow>
                            <TableCell>
                              <input
                                type="checkbox"
                                checked={sstate.allChecked}
                                onChange={handleTableHeadCheckboxChange}
                                className={modules["custom-checkboxnew"]}
                              />
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              Sr. No.
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              Project Name
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              Customer Name
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              ERP ProjectCompany
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              ERP ProjectNo
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectiongrid.map((item, index) => (
                            <TableRow
                              key={index}
                              className={modules["table-row"]}
                            >
                              <TableCell>
                                <input
                                  type="checkbox"
                                  checked={sstate.checkedRows.has(index)}
                                  onChange={() => handleCheckboxChange(index)}
                                  className={modules["custom-checkbox"]}
                                />
                              </TableCell>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{item.projectname}</TableCell>
                              <TableCell>{item.customername}</TableCell>
                              <TableCell>{item.erpprojectcompany}</TableCell>
                              <TableCell>{item.erpprojectno}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                </Grid>
              )}
              {selectedAccesstype !== "" &&
                selectedAccesstype !== "Select" &&
                (selectedAccesstype === "3" || selectedAccesstype === "4"
                  ? selectedTM !== "Select"
                  : true) && (
                  <>
                    <Stack
                      spacing={2}
                      direction="row"
                      className={[
                        modules["each-entry-apm"],
                        modules["input-field-container"],
                      ].join(" ")}
                    >
                      <div className={modules["input-labels-apm"]}>
                        Access From Date:
                        <span className={modules["asterisk"]}>*</span>{" "}
                      </div>
                      <input
                        value={displaydate1}
                        onChange={(e) => setDisplaydate1(e.target.value)}
                        className={
                          open1
                            ? modules["input-field-apm-focused"]
                            : modules["input-field-apm"]
                        }
                        onClick={(event) => setAnchorEl1(event.currentTarget)}
                      />
                      <Popover
                        id={id1}
                        open={open1}
                        anchorEl={anchorEl1}
                        onClose={() => setAnchorEl1(null)}
                        placement="bottom-start"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Paper>
                          <Calendar
                            onChange={handleCalendar1}
                            value={fromdate}
                            locale="en-US"
                            formatShortWeekday={formatShortWeekday}
                          />
                          <div className={modules["calendar-footer"]}>
                            Today: {formattedToday}
                          </div>
                        </Paper>
                      </Popover>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      className={[
                        modules["each-entry-apm"],
                        modules["input-field-container"],
                      ].join(" ")}
                    >
                      <div className={modules["input-labels-apm"]}>
                        Access To Date:
                        <span className={modules["asterisk"]}>*</span>{" "}
                      </div>
                      <input
                        value={displaydate2}
                        onChange={(e) => setDisplaydate2(e.target.value)}
                        className={modules["input-field-apm"]}
                        onClick={(event) => setAnchorEl2(event.currentTarget)}
                      />
                      <Popover
                        id={id2}
                        open={open2}
                        anchorEl={anchorEl2}
                        onClose={() => setAnchorEl2(null)}
                        placement="bottom-start"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Paper>
                          <Calendar
                            onChange={handleCalendar2}
                            value={todate}
                            locale="en-US"
                            formatShortWeekday={formatShortWeekday}
                          />
                          <div className={modules["calendar-footer"]}>
                            Today: {formattedToday}
                          </div>
                        </Paper>
                      </Popover>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      className={modules["check-box-container"]}
                    >
                      <input
                        type="checkbox"
                        className={modules["custom-checkbox"]}
                        style={{
                          cursor: "pointer",

                          display: "grid",
                        }}
                        checked={isChecked === 1}
                        onChange={handleViewProjAccessCheck}
                      />
                      <div
                        className={[
                          modules["check-box-container"],
                          modules["input-labels-apm-check"],
                        ].join(" ")}
                      >
                        View Access Only
                      </div>
                    </Stack>
                    <Grid
                      container
                      className={[
                        modules["btn1-cont_atm"],
                        modules["button-container"],
                      ].join(" ")}
                    >
                      <button
                        className={[
                          modules["btn1"],
                          modules["button-container-button"],
                        ].join(" ")}
                        onClick={handleSearch}
                      >
                        Submit
                      </button>
                      <button className={modules["btn2"]} onClick={handleReset}>
                        Reset
                      </button>
                    </Grid>
                  </>
                )}
            </Grid>
          )}
          {selectedValue === "option2" && (
            <Grid container className={modules["parent-container-apm-edit"]}>
              <div className={modules["bottom-grid-heading-apm"]}>
                Edit Alternate Manager
              </div>
              <Stack direction="row">
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-apm"]}
                >
                  <div className={modules["input-labels-apm-check"]}>
                    Instance:{" "}
                  </div>
                  <select
                    className={modules["dropdownSelect1-apm"]}
                    value={selectedInstance}
                    style={{ width: "18.75rem", height: "2.1rem" }}
                    onChange={(e) => handleInstance2(e.target.value)}
                  >
                    {instance.length > 0 &&
                      instance?.map((i) => (
                        <option value={i.id} key={i}>
                          {i.name}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={[
                    modules["each-entry-apm"],
                    modules["span-text"],
                  ].join(" ")}
                >
                  <div className={modules["input-labels-apm-check"]}>
                    Alternate Task Manager:{" "}
                  </div>
                  <select
                    className={modules["dropdownSelect1-apm"]}
                    style={{ width: "18.75rem", height: "2.1rem" }}
                    value={selectedExistingPM}
                    onChange={(e) => handleExistingPM(e.target.value)}
                  >
                    <option value="Select">SELECT</option>
                    {existingPM.map((i) => (
                      <option value={i.id} key={i}>
                        {i.username}
                      </option>
                    ))}
                  </select>
                </Stack>
              </Stack>
              {selectedExistingPM !== "" && (
                <Grid
                  container
                  className={modules[("table-grid", "table-grid-marginATM")]}
                >
                  {detailgrid.length > 0 ? (
                  <div className={modules["table-containerATM"]}>
                    <Table
                      className={[
                        modules["custom-tableATM"],
                        modules["custom-tableATM-th"],
                      ].join(" ")}
                    >
                      <TableHead style={{ textAlign: "" }}>
                        <TableRow>
                          <TableCell style={{ minWidth: "50px" }}>
                            Sr. No
                          </TableCell>
                          <TableCell style={{ minWidth: "70px" }}>
                            Instance
                          </TableCell>
                          <TableCell style={{ minWidth: "100px" }}>
                            AlternateTM
                          </TableCell>
                          <TableCell style={{ minWidth: "100px" }}>
                            Access Type
                          </TableCell>
                          <TableCell style={{ minWidth: "70px" }}>
                            Project
                          </TableCell>
                          <TableCell style={{ minWidth: "100px" }}>
                            Task
                          </TableCell>
                          <TableCell style={{ minWidth: "130px" }}>
                            Task Manager
                          </TableCell>
                          <TableCell>AccessFromDate</TableCell>
                          <TableCell>AccessToDate</TableCell>
                          <TableCell
                            style={{
                              minWidth: "55px",
                              maxWidth: "60px",
                              whiteSpace: "normal",
                              wordBreak: "break-word",
                            }}
                          >
                            View
                            <br />
                            Only
                            <br />
                            Access
                          </TableCell>
                          <TableCell style={{ minWidth: "50px" }}>
                            Save
                          </TableCell>
                          <TableCell style={{ minWidth: "50px" }}>
                            Delete
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {detailgrid.map((item, index) => (
                          <TableRow key={index} className={modules["table-row"]}>
                            <TableCell>
                              {index + 1}
                            </TableCell>
                            <TableCell>
                              {item.InstanceName}
                            </TableCell>
                            <TableCell>{item.ATMName}</TableCell>
                            <TableCell style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{item.Accesstype}</TableCell>
                            <TableCell>{item.Project}</TableCell>
                            <TableCell>
                              <textarea
                                style={{
                                  width: "5rem",
                                  height: "1.3rem",
                                  border: "1px solid #414141",
                                }}
                                readOnly
                              />
                            </TableCell>
                            <TableCell>
                              {item.TaskManager}
                            </TableCell>

                            <TableCell>
                              <TextField
                                value={displaydate1 || formatDate(item.AccessFromDate, 'b')} // Default to API date if displaydate1 is empty
                                onChange={(e) => setDisplaydate1(e.target.value)}
                                onClick={(event) => handleClick1(event.currentTarget)}
                                // Remove the placeholder prop
                                InputProps={{
                                  style: {
                                    height: "21px",
                                    width: "141px",
                                    borderRadius: "0px",
                                    fontSize: "12px",
                                    color: "#414141",
                                    fontFamily: "'Work Sans', sans-serif",
                                    border: "1px solid #414141",
                                  },
                                }}
                                sx={{
                                  color: "#414141",
                                  "& .MuiInputBase-input::placeholder": {
                                    color: "#414141",
                                    opacity: 1,
                                    fontSize: "12px",
                                    fontFamily: "'Work Sans', sans-serif",
                                  },
                                  "& .MuiOutlinedInput-input": {
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    borderRadius: "0px",
                                    color: "#414141",
                                    fontSize: "12px",
                                    fontFamily: "'Work Sans', sans-serif",
                                    border: "none!important",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "transparent",
                                    },
                                  },
                                }}
                              />
                              <Popper
                                id={id1}
                                open={open1}
                                anchorEl={anchorEl1}
                                onClose={() => setAnchorEl1(null)}
                                placement="bottom"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                style={{ borderRadius: "0px" }}
                                ref={anchorRef1}
                              >
                                <Paper sx={{ borderRadius: "0px" }}>
                                  <Calendar
                                    onChange={handleCalendar1}
                                    value={fromdate} // This should be managed to reflect the selected date
                                    locale="en-US"
                                    formatShortWeekday={formatShortWeekday}
                                  />
                                </Paper>
                              </Popper>
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={displaydate2 || formatDate(item.AccessToDate, 'b')} // Default to API date if displaydate2 is empty
                                onChange={(e) => setDisplaydate2(e.target.value)}
                                onClick={(event) => handleClick2(event.currentTarget)}
                                // Remove the placeholder prop
                                InputProps={{
                                  style: {
                                    height: "21px",
                                    width: "141px",
                                    borderRadius: "0px",
                                    fontSize: "12px",
                                    color: "#414141",
                                    fontFamily: "'Work Sans', sans-serif",
                                    border: "1px solid #414141",
                                  },
                                }}
                                sx={{
                                  color: "#414141",
                                  "& .MuiInputBase-input::placeholder": {
                                    color: "#414141",
                                    opacity: 1,
                                    fontSize: "12px",
                                    fontFamily: "'Work Sans', sans-serif",
                                  },
                                  "& .MuiOutlinedInput-input": {
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    borderRadius: "0px",
                                    color: "#414141",
                                    fontSize: "12px",
                                    fontFamily: "'Work Sans', sans-serif",
                                    border: "none!important",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "transparent",
                                    },
                                  },
                                }}
                              />
                              <Popper
                                id={id2}
                                open={open2}
                                anchorEl={anchorEl2}
                                onClose={() => setAnchorEl2(null)}
                                placement="bottom"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                style={{ borderRadius: "0px" }}
                                ref={anchorRef2}
                              >
                                <Paper sx={{ borderRadius: "0px" }}>
                                  <Calendar
                                    onChange={handleCalendar2}
                                    value={todate} // This should also reflect the selected date
                                    locale="en-US"
                                    formatShortWeekday={formatShortWeekday}
                                  />
                                </Paper>
                              </Popper>
                            </TableCell>
                            <TableCell key={item.Id}>
                              <input
                                checked={rowCheckedStatus[item.Id] === 1}
                                type="checkbox"
                                onChange={(event) => toggleRowCheckStatus(event, item.Id)}
                                style={{
                                  border: "1px solid #414141",
                                  borderRadius: "2px",
                                }}
                                className={modules["custom-checkbox"]}
                              />
                            </TableCell>
                            <TableCell>
                              <img
                                src={saveIcon}
                                alt="saveIcon"
                                className={modules["custom-img-size"]}
                                onClick={() =>
                                  handleSave(
                                    item.Id,
                                    item.AccesstypeId,
                                    item.AccessFromDate,
                                    item.AccessToDate,
                                    item.Id 
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <img
                                src={deleteicon}
                                alt="deleteIcon"
                                className={modules["custom-img-size"]}
                                onClick={() =>
                                  handleDelete(
                                    item.Id,
                                    item.AccesstypeId,
                                    item.AccessFromDate,
                                    item.AccessToDate
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                  ):(
                    <div style={{ marginTop: '1rem', marginLeft: '1rem', width: '100%' }}>
                      <div style={{ marginBottom: '0.5rem', paddingLeft: '0.5rem' }}>
                        No Record Found.
                      </div>
                      <hr style={{ border: '1px solid #a4bf47', transform: 'scaleY(0.2)', width: '49%' }} />
                    </div>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AlternateTaskManager;
