import React from "react";
import { Grade } from "@mui/icons-material";
import Home from "../../../home/Home";
import { Grid, colors, Stack, TextField } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Sidebar from "../../../Landing/Navbar";
import bread from "../../../Assets/bread.png";
import calender from "../../../Assets/calendar.png";
import Calendar from 'react-calendar';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { GetInstanceDetails, TMF_GetTRDLostFactorHandler, TMF_BtnClickSaveClickHandler, TMF_BtnClickUpdateHandler, TMF_BtnClickDeleteHandler } from "../../../Services/Dss";
import {
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import modules from "./TRDMaintainFactor.module.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SampleSidebar from "../../../Landing/SampleSidebar";

export default function MaintainFactor() {
  const formatDay = (locale, date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const [dropdownData, setDropdownData] = useState([]);





  const [firstDropdown, setFirstDropDown] = useState('');

  const [Insert, setInsert] = useState(false);

  const [pageStates, setPageStates] = useState({
    recordId: '',
    ThroughPut: '',
    trdloss: '',
    EffectiveFrom: '',
    EffectiveTo: '',
    InternalSales: '',
    planTrd: ''
  })

  const [updateStates, setUpdateStates] = useState({
    recordId: '',
    ThroughPut: '',
    trdloss: '',
    EffectiveFrom: '',
    EffectiveTo: '',
    InternalSales: '',
    planTrd: ''
  })

  const parseDateString = (dateString) => {
    // Split the date string into day, month, and year
    const [day, month, year] = dateString.split('/').map(Number);
    // Return the formatted date string "dd/mm/yyyy"
    return `${day}/${month}/${year}`;
  }

  function EffectiveFromHandler(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();


    if (Insert) {
      setUpdateStates({
        ...updateStates,
        EffectiveFrom: `${year}/${month}/${day}`
      })
    } else {
      setPageStates({
        ...pageStates,
        EffectiveFrom: `${year}/${month}/${day}`
      })
    }
    setCalendar({
      ...calendar,
      from:false
    })
  }

  function EffectiveToHandler(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    if (Insert) {
      setUpdateStates({
        ...updateStates,
        EffectiveTo: `${year}/${month}/${day}`
      })
    } else {
      setPageStates({
        ...pageStates,
        EffectiveTo: `${year}/${month}/${day}`
      })
    }

    setCalendar({
      ...calendar,
      to:false
    })

  }


  const [calendar, setCalendar] = useState({
    from: false,
    to: false
  })




  useEffect(() => {
    GetInstanceDetails('pritpwc').then(res => {
      if (res.status === 200) {
        setDropdownData(res.data);
        getTrdLossFactor(res.data[0]);
      }
    }).catch(err => {
      console.log('Error fetching data:', err);
    })
  }, [])


  function getTrdLossFactor(instanceid) {
    setFirstDropDown(instanceid);
    TMF_GetTRDLostFactorHandler(instanceid, 'pwc').then(res => {
      if (res.status === 200) {
        let data = res.data.success.data[0];
        setPageStates({
          recordId: data.recordid,
          ThroughPut: data.throughputpercentage?data.throughputpercentage.toFixed(6):'',
          trdloss: data.trdlosspercentage? data.trdlosspercentage.toFixed(6):'',
          EffectiveFrom: data.effectivedatefrom,
          EffectiveTo: data.effectivedateto,
          InternalSales: data.throughputpercentageintsales?data.throughputpercentageintsales.toFixed(6):'',
          planTrd: data.plantrdlosspercentage?data.plantrdlosspercentage.toFixed(6):''
        })
      }
    }).catch(err => {
      console.log('Error fetching data:', err);
    })
  }



  function saveHandler() {

    const body = {
      instanceUId: firstDropdown,
      userID: "pwc",
      recordId: pageStates.recordId ? pageStates.recordId : '',
      throughput: updateStates.ThroughPut,
      trdLost: updateStates.trdloss,
      effectiveFDate: updateStates.EffectiveFrom,
      effectiveTDate: updateStates.EffectiveTo,
      hostname: "Host",
      ipAdd: "Ip",
      throughputInternalSales: updateStates.InternalSales,
      planTRDLossPer: updateStates.planTrd
    }

    !updateStates.ThroughPut?
      alert('Enter Valid Throughput in Percentage'):
      !updateStates.trdloss?
      alert('Enter Valid Trd Loss Percentage'):
      !updateStates.InternalSales?
      alert('Enter Valid Throughput(Internal Sales)'):
      !updateStates.planTrd?
      alert('Enter Valid Plan TRD Loss percentage'):(()=>{
        TMF_BtnClickSaveClickHandler(body).then(res => {
      
          if (res.status === 200) {
            if(res?.data?.statusCode===400){
              alert(res.data?.error?.message);
            }
            else{
            resetHandler();
            alert(res?.data?.success?.message);
            }
          } else if (res.response.status === 400) {
            alert(res?.data?.error?.message);
          }
    
        }).catch(err => {
          console.log('Error fetching data:', err);
        })
      })()
   
  }

  function UpdateHandler() {
    const body = {
      instanceUId: firstDropdown,
      userID: "pwc",
      recordId: pageStates.recordId,
      throughput: pageStates.ThroughPut.toString(),
      trdLost: pageStates.trdloss.toString(),
      effectiveFDate: pageStates.EffectiveFrom,
      effectiveTDate: pageStates.EffectiveTo,
      hostname: "Host",
      ipAdd: "Ip",
      throughputInternalSales: pageStates.InternalSales.toString(),
      planTRDLossPer: pageStates.planTrd.toString()
    }
    !pageStates.ThroughPut?
    alert('Enter Throughput'):
    TMF_BtnClickUpdateHandler(body).then(res => {
       
      if (res.status === 200) {
        if(res?.data?.statusCode===400){
          alert(res.data.error.message);
        }else{
        resetHandler();
        alert(res.data.success.message);
        }
      }
        
    }).catch(err => {
      console.log('Error fetching data:', err);
    })
  }

  function resetHandler() {
    setInsert(false);
    setUpdateStates({
      recordId: '',
      ThroughPut: '',
      trdloss: '',
      EffectiveFrom: '',
      EffectiveTo: '',
      InternalSales: '',
      planTrd: ''
    });
    getTrdLossFactor(firstDropdown);
  }

  function DeleteHandler() {
    const body = {
      userID: "pwc",
      recordId: pageStates.recordId,
      hostname: "Host",
      ipAdd: "Ip",
      throughputInternalSales: pageStates.InternalSales.toString(),
      planTRDLossPer: pageStates.planTrd.toString()
    }
    if (window.confirm('do you want to delete')) {
      !pageStates.ThroughPut?
      alert('Enter Throughput'):
      TMF_BtnClickDeleteHandler(body).then(res => {
        if (res.status === 200) {
           resetHandler();
          alert(res.data.success.message);

        } else if (res.status === 400) {
          alert(res.data.error.message);
        }
      }).catch(err => {
        console.log('Error fetching data:', err);
      })
    }

  }





  function ArrowLine() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "2px",
            height: "30px",
            backgroundColor: "grey",
            marginRight: "2px",
          }}
        />
        <ArrowDropDownIcon sx={{ marginRight: "15px", color: "#9d9d9c" }} />
      </div>
    );
  }

  function dateFromHandler() {
    setCalendar({
      from: !calendar.from,
      to: false
    })
  }
  function dateToHandler() {
    setCalendar({
      from: false,
      to: !calendar.to
    })
  }
  return (

    <>
      <style>{
        `.react-calendar{
        width:15rem !important,
        height:5rem !important
      }`}</style>
      <Grid>
        <Grid
          item
          lg={0.6}
          sx={{ position: "fixed", zIndex: 2000 }}
          className="main-cont"
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className="home-cont">
            <Home />
          </Grid>
        </Grid>
        <Grid item lg={9} className={modules["maintainfactor_div"]}>
          <div className={modules["pro-bread"]} >
            <p>
              <a>DSS TRD Loss</a>
              <img src={bread} alt="" />
              <a>Maintain Factor</a>
            </p>
          </div>
        </Grid>

        <Grid sx={{ marginLeft: "6rem", marginTop: "2rem" }}>
          <table className={modules["customTable"]}>
            <tbody>
              <tr>
                <td>DSS Instance</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={firstDropdown}
                      onChange={(e) => getTrdLossFactor(e.target.value)}
                    >
                      {dropdownData.map((i) => {
                        return (
                          <MenuItem value={i} key={i}>
                            {i}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </td>
              </tr>

              <tr>
                <td>ThroughPut in Percentage</td>
                <td> : </td>
                <td>
                  <input type="text" value={Insert ? updateStates.ThroughPut : pageStates.ThroughPut}
                    onChange={(e) => {
                      (Insert && !isNaN(e.target.value)) && setUpdateStates({
                        ...updateStates,
                        ThroughPut: e.target.value
                      })

                    }} />
                </td>
              </tr>

              <tr>
                <td>TRD Loss Percentage</td>
                <td> : </td>
                <td>
                  <input type="text" value={Insert ? updateStates.trdloss : pageStates.trdloss} onChange={(e) => {
                    (Insert && !isNaN(e.target.value)) &&
                      setUpdateStates({
                        ...updateStates,
                        trdloss: e.target.value
                      })
                  }} />


                </td>
              </tr>

              <tr>
                <td>Effective From Date</td>
                <td> : </td>
                <td>
                  <input
                    className={modules["datepicker"]}
                    value={Insert ? updateStates.EffectiveFrom : pageStates.EffectiveFrom}
                  />
                  <img
                    className={modules["calender"]}
                    src={calender}
                    alt="calendar missing"
                    onClick={dateFromHandler}
                  />
                  {
                    calendar.from && (
                      <div style={{ position: 'absolute', zIndex: '999 !important', marginLeft: '5rem', height: '5rem !important', width: '20rem' }} >
                        <Calendar
                          value={Insert ? updateStates.EffectiveFrom : pageStates.EffectiveFrom} onChange={EffectiveFromHandler}
                          className={modules['customPosition']}
                        />
                      </div>
                    )
                  }
                </td>
              </tr>

              <tr>
                <td>Effective To Date</td>
                <td> : </td>
                <td>
                  <input
                    className={modules["datepicker"]}
                    value={Insert ? updateStates.EffectiveTo : pageStates.EffectiveTo}
                  />
                  <img
                    className={modules["calender"]}
                    src={calender}
                    alt="calendar missing"
                    onClick={dateToHandler}
                  />
                  {
                    calendar.to && (
                      <div style={{ position: 'absolute', zIndex: '999 !important', marginLeft: '5rem', height: '5rem !important', width: '20rem' }} >
                        <Calendar
                          value={Insert ? updateStates.EffectiveTo : pageStates.EffectiveTo} onChange={EffectiveToHandler}
                          className={modules['customPosition']}

                        />
                      </div>
                    )
                  }
                </td>
              </tr>

              <tr>
                <td>ThroughPut Percentage(Internal Sales)</td>
                <td> : </td>
                <td>
                  <input tpye="text" value={Insert ? updateStates.InternalSales : pageStates.InternalSales} onChange={(e) => {
                    (Insert && !isNaN(e.target.value)) && setUpdateStates({
                      ...updateStates,
                      InternalSales: e.target.value
                    })
                  }} />
                </td>
              </tr>

              <tr>
                <td>Plan TRD Loss Percentage</td>
                <td> : </td>
                <td>
                  <input type="text" value={Insert ? updateStates.planTrd : pageStates.planTrd} onChange={(e) => {
                    (Insert && !isNaN(e.target.value)) && setUpdateStates({
                      ...updateStates,
                      planTrd: e.target.value
                    })
                  }} />
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>

        <div style={{ marginTop: "1.2rem", marginBottom: "2rem" }}>
          <Stack direction="row" spacing={3}>
            {
              !Insert ?
                <>
                  <Stack>
                    <button
                      style={{
                        marginLeft: "23.7rem",
                        padding: "0.5cm",
                      }}
                      type="button"
                      className={modules["btn1"]}
                      onClick={() => setInsert(!Insert)}
                    >
                      Insert
                    </button>{" "}
                  </Stack>

                  <Stack>
                    <button
                      style={{
                        marginLeft: "0rem",

                        padding: "0.5cm",
                      }}
                      type="button"
                      className={modules["btn1"]}
                      onClick={UpdateHandler}
                      Table
                    >
                      Update
                    </button>
                  </Stack>
                  <Stack>
                    <button
                      style={{
                        marginLeft: "0rem",
                        padding: "0.5cm",
                      }}
                      type="button"
                      className={modules["btn1"]}
                      onClick={DeleteHandler}
                      Table
                    >
                      Delete
                    </button>
                  </Stack>
                </> : <>
                  <Stack>
                    <button
                      style={{
                        marginLeft: "23.7rem",
                        padding: "0.5cm",
                      }}
                      type="button"
                      className={modules["btn1"]}
                      onClick={saveHandler}
                      Table
                    >
                      Save
                    </button>
                  </Stack>
                  <Stack>
                    <button
                      style={{
                        marginLeft: "0rem",
                        padding: "0.5cm",
                      }}
                      type="button"
                      className={modules["btn1"]}
                      // onClick={handleSubmit}
                      Table
                      onClick={() => setInsert(!Insert)}
                    >
                      Cancel
                    </button>
                  </Stack>
                </>
            }
          </Stack>
        </div>
      </Grid>
    </>
  );
}
