import React, { useEffect, useState } from "react";
import bread from "../../Assets/bread.png";
import Home from "../../home/Home";
import modules from "./Admin.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import classes from "./Admin.module.css";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Grid, SvgIcon } from "@mui/material";
import Sidebar from "../../Landing/Navbar";
import {
  GetRolesList,
  GetRolesDetails,
  InseertNewRole,
  DeleteSingleRole,
  ChangeRole,
  BulkDeleteRole,
} from "../../Services/Admin";
import SampleSidebar from "../../Landing/SampleSidebar";
import { SiTrueup } from "react-icons/si";
import loading from "../../Assets/Loading_image.gif";

const CheckBoxBlankIcon = () => (
  <SvgIcon>
    <svg focusable="false" aria-hidden="true" data-testid="CheckBoxBlankIcon">
      <path
        d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5"
        fill="white"
      />
    </svg>
  </SvgIcon>
);

const RoleMaster = () => {
  const [data, setData] = useState([]);
  const [serialNumber, setSerialNumber] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [rolesData, setrolesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newRoleName, setNewRoleName] = useState("");
  const [isAddRowVisible, setIsAddRowVisible] = useState(false);
  const [editing, setEditing] = useState(null);
  const [editedRoleName, setEditedRoleName] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getRoles();
  }, []);

  function handleNavigate(Id) {
    window.location.href = `/admin/RolePermission?roleid=${Id}`;
    window.sessionStorage.setItem("roleid", Id);
  }

  function getRoles() {
    setIsLoading(true);
    GetRolesList()
      .then((response) => {
        //console.log(response);
        setIsLoading(false);
        setData(response?.data?.success?.data);
        setrolesData(response?.data?.success?.data);
        //console.log(response?.data?.success?.data);
        setSearchData(response?.data?.success?.data);
        setSerialNumber(1);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  function getDetails(rolename) {
    setIsLoading(true);
    const body = {
      roleName: `${rolename}`,
    };
    GetRolesDetails(body)
      .then((response) => {
        // console.log(response?.data);
        setrolesData(response?.data);
        setIsLoading(false);
        // setSearchData(response?.data);
        // console.log(response?.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  const handleSave = (element) => {
    element.style.color = "red";

    setTimeout(() => {
      const N_role = newRoleName.trim();

      if (!N_role) {
        alert("Please enter Role name");
        element.style.color = "#0000EE";
        setErrorMessage("*Enter Value");
        return;
      }

      const roleExists = rolesData.some(
        (role) => role.rolename.toLowerCase() === N_role.toLowerCase()
      );

      if (roleExists) {
        alert("Role name already exists.");
        element.style.color = "#0000EE";
        return;
      }

      const createdBy = sessionStorage.getItem("roleid");
      if (newRoleName) {
        InseertNewRole(newRoleName, createdBy)
          .then((response) => {
            // console.log(response);
            if (response?.data?.success === true) {
              setIsAddRowVisible(false);
              element.style.color = "#0000EE";
              setNewRoleName("");
              getRoles();
            } else {
              alert("Please enter Role Name");
              element.style.color = "#0000EE";
              setErrorMessage("*Enter Value");
            }
          })
          .catch((error) => {
            if (error.response?.status === 400) {
              alert("Role name already exists.");
              element.style.color = "#0000EE";
            } else {
              console.error("Error inserting role:", error);
              alert("An error occurred. Please try again.");
              element.style.color = "#0000EE";
            }
          });
      } else {
        alert("Please enter Role Name");
        setErrorMessage("*Enter Value");
        element.style.color = "#0000EE";
      }
    }, 100);
  };

  function handleClose(element) {
    element.style.color = "red";
    setTimeout(() => {
      setIsAddRowVisible(false);
      setNewRoleName("");
      element.style.color = "#0000EE";
    }, 50);
    setErrorMessage("");
  }

  function handleInsert() {
    setIsAddRowVisible(true);
  }

  function handleDeleteSingleEntry(roleid, element) {
    element.style.color = "red";

    setTimeout(() => {
      if (window.confirm("Are you sure you want to delete ...?")) {
        let lastModifiedBy = sessionStorage.getItem("roleid");
        if (!lastModifiedBy) {
          console.error("Role ID not found in session storage");
          element.style.color = "#0000EE";
          return;
        }
        // console.log(lastModifiedBy);
        DeleteSingleRole(roleid, lastModifiedBy)
          .then((response) => {
            element.style.color = "#0000EE";
            getRoles();
          })
          .catch((error) => {
            console.log(error);
            element.style.color = "#0000EE";
          });
      } else {
        element.style.color = "#0000EE";
      }
    }, 100);
  }

  function handleEditSingleEntry(roleid, rolename, element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(roleid);
      setEditedRoleName(rolename);
      element.style.color = "#0000EE";
    }, 50);
  }

  function handleSaveEdit(roleid, rolename, element) {
    let lastModifiedBy = sessionStorage.getItem("roleid");
    element.style.color = "red";
    setTimeout(() => {
      element.style.color = "#0000EE";
      setTimeout(() => {
        if (editedRoleName && editedRoleName !== rolename) {
          ChangeRole(roleid, editedRoleName, lastModifiedBy)
            .then((response) => {
              //console.log(response);
              // console.log("Role updated successfully:", response);
              getRoles();
              setEditing(null);
              window.location.href = "/admin/rolemaster";
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          alert("No Update :Role Name already Exists.");
        }
      }, 50);
    }, 50);
  }

  function handleCancelEdit(element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(null);
      element.style.color = "#0000EE";
    }, 50);
  }

  function handleCheckboxChange(event, roleId) {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, roleId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== roleId));
    }
    // console.log(selectedRows);
  }

  const toggleSelectAll = () => {
    if (selectAll) {
      // Deselect all rows
      setSelectedRows([]);
    } else {
      // Select all rows from the searchData state
      //console.log(searchData);

      const allRowIds = rolesData.map((item) => item.id);
      setSelectedRows(allRowIds, "defcecf");
      // console.log(rolesData);
    }

    // Toggle the selectAll state
    setSelectAll(!selectAll);
  };

  function handleBulkDelete() {
    if (selectedRows.length > 0) {
      if (window.confirm("Are you sure you want to delete ...?")) {
        const selectedData = selectedRows
          .map((roleId) => {
            const role = data.find((item) => item.id === roleId);
            if (!role) {
              console.error(`Role with id ${roleId} not found`);
              return null;
            }
            const modifiedby = sessionStorage.getItem("roleid");
            return {
              id: role.id,
              lastModifiedBy: parseInt(modifiedby),
              roleName: role.roleName,
            };
          })
          .filter((item) => item !== null); // Filter out any null values

        BulkDeleteRole(selectedData)
          .then((response) => {
            getRoles();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      alert("Please select atleast one checkbox");
    }
  }

  function searchHandler() {
    if (selectedRole) {
      getDetails(selectedRole);
      // console.log(rolesData);
      // const filteredData = rolesData.filter(
      //   (item) => item.roleName === selectedRole
      // );
      // setSearchData(filteredData);
    }
  }

  function resetHandler() {
    // console.log(searchData);
    setrolesData(searchData);
    setSelectedRole("");
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.7} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"RoleMaster"} />
          </Grid>

          <Grid item xs={12}>
            <div className={modules["usermaster_div"]}>
              <div className={modules["pro-bread"]}>
                <p>
                  <a>Admin</a>
                  <img src={bread} alt="" />
                  <a>Role Master</a>
                </p>
              </div>
            </div>
            <Grid container className={modules["mid-contRM"]}>
              <div>
                <label className={modules["bodyFont"]} htmlFor="rolecode">
                  Role Name:
                </label>

                <select
                  name="cars"
                  id="cars"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                  className={modules["dropdownSelect1"]}
                  style={{
                    marginLeft: "3rem",
                  }}
                >
                  <option value="select">--Select--</option>
                  {data?.length > 0 &&
                    data?.map((i) => (
                      <option
                        key={i.rolename}
                        value={i.rolename}
                        style={{ color: "#373736" }}
                      >
                        {i.rolename}
                      </option>
                    ))}
                </select>
              </div>
            </Grid>
            <Stack className={modules["RM_Sep"]} spacing={2} direction="column">
              <Stack direction="row" spacing={1}>
                <button
                  type="button"
                  className={modules["btn-normal"]}
                  onClick={() => searchHandler()}
                >
                  Search
                </button>
                <button
                  type="button"
                  className={modules["btn-normal"]}
                  onClick={() => resetHandler()}
                >
                  Reset
                </button>
              </Stack>
              <TableContainer>
                {rolesData && rolesData.length > 0 && (
                  <Table className={modules["RM_table"]}>
                    <TableHead className={modules["table_head"]}>
                      <TableRow
                        sx={{
                          "& th": {
                            color: "#fff",
                          },
                        }}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                            sx={{
                              padding: 0,
                              marginLeft: "10px",
                              "& .MuiSvgIcon-root": {
                                border: "2px solid #000", // Change the border thickness and color here
                                borderRadius: "4px", // Optional: to make the border corners rounded
                              },
                            }}
                            size="15px"
                            checked={selectAll}
                            onChange={toggleSelectAll}
                          /> */}

                          <input
                            style={{
                              marginLeft: "10px",
                            }}
                            className={classes["custom-checkbox"]}
                            type="checkbox"
                            inputProps={{
                              "aria-label": "select all desserts",
                            }}
                            // icon={<CheckBoxBlankIcon />}
                            checked={selectAll}
                            onChange={toggleSelectAll}
                            sx={{
                              "&.Mui-checked": {
                                color: "grey",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ width: "50px" }}>Sr. No.</TableCell>
                        <TableCell align="center" sx={{ width: "15%" }}>
                          Role Name
                        </TableCell>
                        <TableCell align="left" className={modules["RM_RD"]}>
                          Role Description
                        </TableCell>
                        <TableCell align="left" sx={{ width: "30%" }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className={modules["RM_tableBody"]}>
                      {rolesData?.map((i, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            key={i.id}
                            // sx={{
                            //   "&:last-child td, &:last-child th": { border: 0 },
                            // }}
                          >
                            <TableCell padding="checkbox">
                              {editing !== i.id && (
                                <input
                                  style={{ marginLeft: "10px" }}
                                  type="checkbox"
                                  className={classes["custom-checkbox"]}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                  checked={selectedRows.includes(i.id)}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, i.id)
                                  }
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "grey",
                                    },
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                // fontFamily: "WorkSans !important",
                                color: "#414141",
                                fontSize: "12px",
                              }}
                              // component="th"
                              // scope="row"
                            >
                              {index + 1}
                            </TableCell>

                            <TableCell align="center">
                              {editing === i.id ? (
                                <input
                                  style={{ paddingLeft: "0.4rem" }}
                                  type="text"
                                  value={editedRoleName}
                                  onChange={(e) => {
                                    setEditedRoleName(e.target.value);
                                    //alert(e.target.value)
                                  }}
                                />
                              ) : (
                                <div>
                                  <a
                                    style={{
                                      color: "#0000EE",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleNavigate(i.id)}
                                  >
                                    {i.rolename}
                                  </a>
                                </div>
                              )}
                            </TableCell>

                            <TableCell align="left">{i.roledesc}</TableCell>
                            <TableCell
                              className={modules["editlink"]}
                              align="left"
                              sx={{ color: "#0000EE", padding: "0 !important" }}
                            >
                              {editing === i.id ? (
                                <>
                                  <a
                                    style={{
                                      paddingRight: "1rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      handleSaveEdit(
                                        i.id,
                                        i.rolename,
                                        e.currentTarget
                                      )
                                    }
                                  >
                                    Update
                                  </a>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      handleCancelEdit(e.currentTarget)
                                    }
                                  >
                                    Cancel
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    style={{
                                      paddingRight: "1rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      handleEditSingleEntry(
                                        i.id,
                                        i.rolename,
                                        e.currentTarget
                                      )
                                    }
                                  >
                                    Edit
                                  </a>
                                  <a
                                    style={{
                                      paddingRight: "1rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      handleDeleteSingleEntry(
                                        i.id,
                                        e.currentTarget
                                      );
                                    }}
                                  >
                                    Delete
                                  </a>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}

                      {isAddRowVisible && (
                        <TableRow>
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell></TableCell>
                          <TableCell colSpan={2}>
                            <input
                              style={{ marginLeft: "-1.5rem" }}
                              type="text"
                              value={newRoleName}
                              className={modules["rowinput_css"]}
                              onChange={(e) => setNewRoleName(e.target.value)}
                            />
                            {errorMessage && (
                              <div
                                style={{ color: "red", marginLeft: "-1.5rem" }}
                              >
                                {errorMessage}
                              </div>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <a
                              style={{
                                color: "#0000EE",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={(e) => handleSave(e.currentTarget)}
                            >
                              Save
                            </a>

                            <a
                              style={{
                                color: "#0000EE",
                                textDecoration: "underline",
                                marginLeft: "0.6rem",
                                cursor: "pointer",
                              }}
                              onClick={(e) => handleClose(e.currentTarget)}
                            >
                              Cancel
                            </a>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
              <Stack direction="row" spacing={2} sx={{ paddingBottom: "1%" }}>
                <button
                  type="button"
                  className={modules["btn-normal"]}
                  onClick={handleInsert}
                >
                  Add
                </button>
                <button
                  type="button"
                  className={modules["btn-normal"]}
                  onClick={handleBulkDelete}
                >
                  Delete
                </button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RoleMaster;
