import React, { useEffect, useState } from "react";
import { Options } from "vis-network/standalone/esm/vis-network";
import { LoadNetworkChart } from "../../../../Services/CCPM";
import useVisNetwork from "./useVisNetwork";
import { useNavigate, useLocation } from "react-router-dom";

// Define network options
const options = {
  interaction: {
    zoomView: true, // Allow zooming
  },
  interaction: {
    minZoom: 0.5, // Minimum zoom level
    maxZoom: 2, // Maximum zoom level
  },
  layout: {
    hierarchical: {
      direction: "UD",
      levelSeparation: 200,
      nodeSpacing: 30,
      treeSpacing: 100,
      blockShifting: false,
      edgeMinimization: true,
      parentCentralization: true,
      sortMethod: "directed",
    },
  },
  nodes: {
    shape: "box",
    widthConstraint: { minimum: 50 },
    heightConstraint: { minimum: 40 },
    borderWidth: 1,
  },
  edges: {
    length: 400,
    color: {
      color: "black", // Default color of edges
    },
    arrows: {
      to: { enabled: true, scaleFactor: 1, type: "arrow" },
    },
  },
  physics: {
    stabilization: false, // Optional: disable physics if not needed
  },
};

const NetworkChart = () => {
  const [nodesData, setNodesData] = useState([]);
  const [edgesData, setEdgesData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projId = parseInt(searchParams.get("projId"));

  useEffect(() => {
    const fetchNetworkData = async () => {
      const response = await LoadNetworkChart({ projectId: parseInt(projId) });
      const networkData = response.data.success.data;

      // Transform network data into the format required by vis-network
      // const nodes = networkData.NetworkDaigram.map(item => ({
      //   id: item.key,
      //   label: item.text,
      // }));
      const nodes = networkData.NetworkDaigram.map((item) => ({
        id: item.key,
        label: item.text,
        // startDate:item.earlystart,
        // endDate:item.latefinish,
        title: `Start Date: ${item.earlystart}| Duration:${item.length} | End Date: ${item.latefinish}`,
        color: {
          background: item.critical === 1 ? "#F8BBD0" : "#B3E5FC", // Example color
          border: "#2B7CE9",
        },
      }));
      const edges = networkData.Networks.map((item, index) => ({
        from: item.from,
        to: item.to,
        id: index + 1,
        color: {
          background: item.critical === 1 ? "#F8BBD0" : "#B3E5FC", // Example color
          border: "#2B7CE9",
        },
      }));

      setNodesData(nodes);
      setEdgesData(edges);
    };

    fetchNetworkData();
  }, []);

  const { ref } = useVisNetwork({
    options,
    nodes: nodesData,
    edges: edgesData,
  });

  // Render the network only if data is available
  return (
    <div style={{ height: 700, width: "100%" }}>
      {Array.isArray(nodesData) &&
      Array.isArray(edgesData) &&
      nodesData.length > 0 &&
      edgesData.length > 0 ? (
        <div
          ref={ref}
          style={{
            height: "100vh",
            width: "100%",
            border: "1px solid lightgray",
          }}
        />
      ) : (
        <p>Loading or no data available...</p>
      )}
    </div>
  );
};

export default NetworkChart;
