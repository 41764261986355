import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
} from "@mui/material";
import bread from "../../../Assets/bread.png";
import window_popup from "../../../Assets/window_popup.png";
import {
  Get_MtaInstances,
  SP_MtaStockpointtype,
  MTA_SPCGetModeBased,
  MTA_SPCInsert,
  MTA_SPCUpdate,
  MTA_SPCDelete,
  SPC_GetConfigValues,
  MTA_SPCCheckStockPointExists,
} from "../../../Services/MTA";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";

export default function StockPointConfig() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInstanceId, setSelectedInstanceId] = useState("");
  const [dropdownData, setDropdownData] = useState([]);
  const [rationingLogicData, setRationingLogicData] = useState([]);
  const [consumptionData, setConsumptionData] = useState([]);
  const [intransitData, setIntransitData] = useState([]);
  const [bufferPenetrationData, setBufferPenetrationData] = useState([]);

  const [selectedStockPointTypes, setSelectedStockPointTypes] = useState([]);
  const [actionType, setActionType] = useState("");
  const [selectedStockPoint, setSelectedStockPoint] = useState("");
  const [selectedReplenishment, setSelectedReplenishment] = useState("");
  const [selectedYellowP, setSelectedYellowP] = useState("");
  const [selectedRedP, setSelectedRedP] = useState("");
  const [selectedPIncreasebyDBM, setSelectedPIncreasebyDBM] = useState("");
  const [selectedPDecreasebyDBM, setSelectedPDecreasebyDBM] = useState("");
  const [selectedRationLogic, setSelectedRationLogic] = useState("0");
  const [selectedConsumption, setSelectedConsumption] = useState("0");
  const [selectedIntransit, setSelectedIntransit] = useState("0");
  const [selectedBuffer, setSelectedBuffer] = useState("0");
  const [selectedCyclesIncrease, setSelectedCyclesIncrease] = useState("");
  const [selectedCyclesDecrease, setSelectedCyclesDecrease] = useState("");
  const [selectedSpikeP, setSelectedSpikeP] = useState("");
  const [selectedRegularWarehouse, setSelectedRegularWarehouse] = useState("");
  const [selectedSpikeWarehouse, setSelectedSpikeWarehouse] = useState("");
  const [selectedSourceStockP, setSelectedSourceStockP] = useState("");
  const [selectedAreaCode, setSelectedAreaCode] = useState("");
  const [BPBlockedQuantityEnabled, setBPBlockedQuantityEnabled] = useState("");
  const [selectedRPLCycles, setSelectedRPLCycles] = useState("");
  const [selectedBufferDecrease, setSelectedBufferDecrease] = useState("");
  const [selectedRunnerItem, setSelectedRunnerItem] = useState("");
  const [selectedStrangerItem, setSelectedStrangerItem] = useState("");
  const [selectedWalkerItem, setSelectedWalkerItem] = useState("");
  const [selectedMinimumBuffer, setSelectedMinimumBuffer] = useState("");
  const [selectedMaximumBuffer, setSelectedMaximumBuffer] = useState("");
  const [selectedMaximumStock, setSelectedMaximumStock] = useState("");
  const [selectedSpcRed, setSelectedSpcRed] = useState("");
  const [selectedSpcBlack, setSelectedSpcBlack] = useState("");
  const [selectedSpcWhite, setSelectedSpcWhite] = useState("");
  const [popupData, setPopupData] = useState({});

  const [searched, setSearched] = useState(false);
  const [disabledInstance, setDisabledInstance] = useState(false);
  const [hidePopup, setHidePopup] = useState(false);
  const ChannelSp = new BroadcastChannel("stockPointChannel");

  ChannelSp.onmessage = (e) => {
    setPopupData({
      [e.data.mainrole]: e.data.selectedData,
      mainroleid: e.data.mainrole,
      roleidslist: e.data.selectedData.roleIds,
    });
    setSelectedStockPoint(e.data.selectedData.roleIds);
  };

  const handleRadioChange = (event) => {
    setActionType(event.target.value);
    setDisabledInstance(false);
    setHidePopup(false);
    setSearched(false);
    setSelectedStockPoint("");
    setSelectedReplenishment("");
    setSelectedYellowP("");
    setSelectedRedP("");
    setSelectedPIncreasebyDBM("");
    setSelectedPDecreasebyDBM("");
    setSelectedRationLogic("0");
    setSelectedConsumption("0");
    setSelectedIntransit("0");
    setSelectedBuffer("0");
    setSelectedCyclesIncrease("");
    setSelectedCyclesDecrease("");
    setSelectedSpikeP("");
    setSelectedRegularWarehouse("");
    setSelectedSpikeWarehouse("");
    setSelectedSourceStockP("");
    setSelectedAreaCode("");
    setBPBlockedQuantityEnabled("");
    setSelectedRPLCycles("");
    setSelectedBufferDecrease("");
    setSelectedRunnerItem("");
    setSelectedStrangerItem("");
    setSelectedWalkerItem("");
    setSelectedMinimumBuffer("");
    setSelectedMaximumBuffer("");
    setSelectedMaximumStock("");
    setSelectedSpcWhite("");
    setSelectedSpcBlack("");
    setSelectedSpcRed("");
  };

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getInstances();
  }, []);

  const getInstances = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      miuid_instance_uid: "",
      miuid_last_modified_by: sessionStorage
        .getItem("loginUsername")
        .toLowerCase(),
    };
    if (loginId) {
      Get_MtaInstances(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setDropdownData(response?.data?.success?.data);
            setIsLoading(false);
            if (response?.data?.success?.data.length > 0) {
              const defaultInstanceId =
                response?.data?.success?.data[0]?.mta_instance_uid;
              getStockPointType(defaultInstanceId);
              setSelectedInstanceId(defaultInstanceId);
              getRationingLogic(defaultInstanceId);
              getConsumption(defaultInstanceId);
              getIntransit(defaultInstanceId);
              getBufferPenetration(defaultInstanceId);
            } else if (response?.data?.success?.data.length === 0) {
              alert("You do not have access to any Active Instance in MTA");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const getRationingLogic = (searchValue) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      spcc_control_parameter: "Rationing_Logic_to_be_Used",
      instance_id: searchValue,
      spcc_last_modified_by: sessionStorage
        .getItem("loginUsername")
        .toLowerCase(),
    };
    if (loginId) {
      MTA_SPCGetModeBased(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setRationingLogicData(response?.data?.success?.data);
            setIsLoading(false);
            // console.log(response?.data?.success?.data.length, "length");
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const getConsumption = (searchValue) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      spcc_control_parameter: "Consumption_to_be_taken_From",
      instance_id: searchValue,
      spcc_last_modified_by: sessionStorage
        .getItem("loginUsername")
        .toLowerCase(),
    };
    if (loginId) {
      MTA_SPCGetModeBased(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setConsumptionData(response?.data?.success?.data);
            setIsLoading(false);
            // console.log(response?.data?.success?.data.length, "length");
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const getIntransit = (searchValue) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      spcc_control_parameter: "InTransit_to_be_taken_From",
      instance_id: searchValue,
      spcc_last_modified_by: sessionStorage
        .getItem("loginUsername")
        .toLowerCase(),
    };
    if (loginId) {
      MTA_SPCGetModeBased(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setIntransitData(response?.data?.success?.data);
            setIsLoading(false);
            // console.log(response?.data?.success?.data.length, "length");
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const getBufferPenetration = (searchValue) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      spcc_control_parameter: "Buffer_Penetration_Parameter",
      instance_id: searchValue,
      spcc_last_modified_by: sessionStorage
        .getItem("loginUsername")
        .toLowerCase(),
    };
    if (loginId) {
      MTA_SPCGetModeBased(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setBufferPenetrationData(response?.data?.success?.data);
            setIsLoading(false);
            // console.log(response?.data?.success?.data.length, "length");
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const validateStockPointConfig = () => {
    let commonMessage = "";
    const newLine = `\n`;

    try {
      if (selectedStockPoint.trim() === "") {
        commonMessage += "Select Stock Point" + newLine;
      }
      // else if (!selectedStockPointTypes.includes(selectedStockPoint)) {
      //   commonMessage +=
      //     "mta_mta_stockpoint_configuration_aspx : Input string was not in a correct format." +
      //     newLine;
      // }
      else {
        if (selectedReplenishment.trim() === "") {
          commonMessage += "Enter Replenishment days" + newLine;
        }

        if (selectedYellowP.trim() === "") {
          commonMessage += "Enter Top Of Yellow Percentage" + newLine;
        } else {
          const yellowPValue = parseFloat(selectedYellowP);
          if (isNaN(yellowPValue) || yellowPValue <= 0 || yellowPValue >= 100) {
            commonMessage +=
              "Enter Yellow Percentage between 0 and 100" + newLine;
          }
        }

        if (selectedRedP.trim() === "") {
          commonMessage += "Enter Top of Red Percentage" + newLine;
        } else {
          const redPValue = parseFloat(selectedRedP);
          if (isNaN(redPValue) || redPValue <= 0 || redPValue >= 100) {
            commonMessage += "Enter Red Percentage between 0 and 100" + newLine;
          } else if (parseFloat(selectedYellowP) < redPValue) {
            commonMessage +=
              "Percentage of Top of Yellow must be greater than or equal to Percentage of Top of Red" +
              newLine;
          }
        }

        if (selectedPIncreasebyDBM.trim() === "") {
          commonMessage += "Enter percentage increase by DBM" + newLine;
        } else {
          const pIncreaseValue = parseFloat(selectedPIncreasebyDBM);
          if (isNaN(pIncreaseValue) || pIncreaseValue < 0) {
            commonMessage +=
              "Percentage Increase in DBM should be greater than or equal to Zero and less than or equal to 100" +
              newLine;
          }
        }

        if (selectedPDecreasebyDBM.trim() === "") {
          commonMessage += "Enter percentage decrease by DBM" + newLine;
        } else {
          const pDecreaseValue = parseFloat(selectedPDecreasebyDBM);
          if (isNaN(pDecreaseValue) || pDecreaseValue < 0) {
            commonMessage +=
              "Percentage Decrease in DBM should be greater than or equal to Zero and less than or equal to 100" +
              newLine;
          }
        }

        if (selectedRationLogic <= "0") {
          commonMessage += "Select Rationing Logic" + newLine;
        }

        if (selectedConsumption <= "0") {
          commonMessage += "Select Consumption to be taken" + newLine;
        }

        if (selectedIntransit <= "0") {
          commonMessage += "Select Intransit to be taken" + newLine;
        }

        if (selectedBuffer <= "0") {
          commonMessage += "Select Buffer Penetration parameter" + newLine;
        }

        if (BPBlockedQuantityEnabled <= "0") {
          commonMessage +=
            "Select Buffer Penetration Blocked Quantity Enabled" + newLine;
        }

        if (selectedCyclesIncrease.trim() === "") {
          commonMessage += "Enter number of RPL cycles for increase" + newLine;
        }

        if (selectedCyclesDecrease.trim() === "") {
          commonMessage += "Enter number of RPL cycles for decrease" + newLine;
        }

        if (selectedMinimumBuffer.trim() === "") {
          commonMessage +=
            "Enter Minimum Buffer - No of Days Consumption" + newLine;
        }

        if (selectedMaximumBuffer.trim() === "") {
          commonMessage +=
            "Enter Maximum Buffer - No of Days Consumption" + newLine;
        }

        if (selectedMaximumStock.trim() === "") {
          commonMessage +=
            "Enter Maximum Stock Value for Stock Point" + newLine;
        }

        if (selectedSpikeP.trim() === "") {
          commonMessage += "Enter Spike Percentage" + newLine;
        } else {
          const spikePValue = parseFloat(selectedSpikeP);
          if (isNaN(spikePValue) || spikePValue < 0 || spikePValue >= 101) {
            commonMessage +=
              "Spike Percentage should be greater than or equal to Zero and less than or equal to 100" +
              newLine;
          }
        }

        if (selectedSourceStockP.trim() === "") {
          commonMessage += "Enter Source Stock Percentage" + newLine;
        } else {
          const sourceStockValue = parseFloat(selectedSourceStockP);
          if (
            isNaN(sourceStockValue) ||
            sourceStockValue < 0 ||
            sourceStockValue >= 101
          ) {
            commonMessage +=
              "Source Stock Percentage should be greater than or equal to Zero and less than or equal to 100" +
              newLine;
          }
        }

        if (selectedRPLCycles.trim() === "") {
          commonMessage +=
            "Enter Number of RPL Cycles For Phase Out Of Item" + newLine;
        } else {
          const rplCyclesValue = parseFloat(selectedRPLCycles);
          if (isNaN(rplCyclesValue) || rplCyclesValue < 0) {
            commonMessage +=
              "Number of RPL Cycles For Phase Out Of Item should be greater than or equal to Zero" +
              newLine;
          }
        }

        if (selectedBufferDecrease.trim() === "") {
          commonMessage +=
            "Number of Buffer Decrease For Phase Out Of Item" + newLine;
        } else {
          const bufferDecreaseValue = parseFloat(selectedBufferDecrease);
          if (isNaN(bufferDecreaseValue) || bufferDecreaseValue < 0) {
            commonMessage +=
              "Number of Buffer Decrease For Phase Out Of Item should be greater than or equal to Zero" +
              newLine;
          }
        }

        if (selectedRunnerItem.trim() === "") {
          commonMessage += "Enter Runner Item Perc (Top ‘N’ Items)" + newLine;
        } else {
          const runnerItemValue = parseFloat(selectedRunnerItem);
          if (
            isNaN(runnerItemValue) ||
            runnerItemValue <= 0 ||
            runnerItemValue >= 101
          ) {
            commonMessage +=
              "Enter Runner Item Perc (Top ‘N’ Items) Percentage between 0 and 100" +
              newLine;
          }
        }

        if (selectedStrangerItem.trim() === "") {
          commonMessage +=
            "Enter Stranger Item Perc (Bottom ‘N’ Items)" + newLine;
        } else {
          const strangerItemValue = parseFloat(selectedStrangerItem);
          if (
            isNaN(strangerItemValue) ||
            strangerItemValue <= 0 ||
            strangerItemValue >= 101
          ) {
            commonMessage +=
              "Enter Stranger Item Perc (Bottom ‘N’ Items) between 0 and 100" +
              newLine;
          }
        }

        if (
          parseFloat(selectedRunnerItem) + parseFloat(selectedStrangerItem) >
          100
        ) {
          commonMessage +=
            "Addition of Runner Item Perc and Stranger Item Perc is more than 100%" +
            newLine;
        }

        if (selectedSpcRed.trim() === "") {
          commonMessage += "Enter Target Perc Red  " + newLine;
        } else {
          const spcValue = parseFloat(selectedSpcRed);
          if (isNaN(spcValue) || spcValue < 0 || spcValue >= 101) {
            commonMessage +=
              "Target Perc Red should be greater than or equal to Zero and less than or equal to 100" +
              newLine;
          }
        }

        if (selectedSpcBlack.trim() === "") {
          commonMessage += "Enter Target Perc Black  " + newLine;
        } else {
          const spcValue = parseFloat(selectedSpcBlack);
          if (isNaN(spcValue) || spcValue < 0 || spcValue >= 101) {
            commonMessage +=
              "Target Perc Black should be greater than or equal to Zero and less than or equal to 100" +
              newLine;
          }
        }

        if (selectedSpcWhite.trim() === "") {
          commonMessage += "Enter Target Perc White  " + newLine;
        } else {
          const spcValue = parseFloat(selectedSpcWhite);
          if (isNaN(spcValue) || spcValue < 0 || spcValue >= 101) {
            commonMessage +=
              "Target Perc White should be greater than or equal to Zero and less than or equal to 100" +
              newLine;
          }
        }
      }
    } catch (error) {
      console.error(error);
      // Log your error here
    }
    return commonMessage;
  };

  const submitHandler = () => {
    const validationMessage = validateStockPointConfig();
    if (validationMessage) {
      alert(validationMessage.trim());
    } else {
      let body = {
        spc_instance_uid: selectedInstanceId,
        spc_stock_point_code: selectedStockPoint,
        spc_replenishment_days: selectedReplenishment,
        spc_top_of_yellow_perc: parseInt(selectedYellowP),
        spc_top_of_red_perc: parseInt(selectedRedP),
        spc_perc_increase_by_DBM: parseInt(selectedPIncreasebyDBM),
        spc_perc_decrease_by_DBM: parseInt(selectedPDecreasebyDBM),
        spc_rationing_logic_to_be_used: selectedRationLogic,
        spc_consumption_to_be_taken_from: selectedConsumption,
        spc_intransit_to_be_taken_from: selectedIntransit,
        spc_buffer_penetration_parameter: selectedBuffer,
        spc_no_of_rpl_cycles_for_increase_in_buffer: parseInt(
          selectedCyclesIncrease
        ),
        spc_no_of_rpl_cycles_for_decrease_in_buffer: parseInt(
          selectedCyclesDecrease
        ),
        spc_spike_perc: parseInt(selectedSpikeP),
        spc_regular_warehouse: selectedRegularWarehouse,
        spc_spike_warehouse: selectedSpikeWarehouse,
        spc_source_stock_point_perc: parseInt(selectedSourceStockP),
        spc_area_code: selectedAreaCode,
        spc_last_modified_by: sessionStorage.getItem("loginUsername"),
        spc_buffer_penetration_blocked_quantity_enabled:
          BPBlockedQuantityEnabled,
        spc_no_of_rpl_cycles_for_phase_out_of_item: selectedRPLCycles,
        spc_no_of_buffer_decrease_for_phase_out_of_item: selectedBufferDecrease,
        spc_runner_item_perc: parseInt(selectedRunnerItem),
        spc_stranger_item_perc: parseInt(selectedStrangerItem),
        spc_minimum_buffer_no_of_days_consumption: parseInt(
          selectedMinimumBuffer
        ),
        spc_maximum_buffer_no_of_days_consumption: parseInt(
          selectedMaximumBuffer
        ),
        spc_maximum_stock_value_for_stock_point: parseInt(selectedMaximumStock),
        spc_target_perc_red: parseInt(selectedSpcRed),
        spc_target_perc_black: parseInt(selectedSpcBlack),
        spc_target_perc_white: parseInt(selectedSpcWhite),
      };
      if (actionType === "1") {
        addStockPointConfig(body);
      } else if (actionType === "2") {
        updateStockPointConfig(body);
      }
    }
  };

  const addStockPointConfig = (body) => {
    setIsLoading(true);
    let bodyCheck = {
      instance_uid: selectedInstanceId,
      spic_stock_point_code: selectedStockPoint,
    };
    MTA_SPCCheckStockPointExists(bodyCheck)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          MTA_SPCInsert(body)
            .then((response) => {
              if (response?.data?.statusCode === 200) {
                setIsLoading(false);
                alert("Details Succesfully Inserted");
                onReset();
              } else if (response?.response?.data?.statusCode === 400) {
                setIsLoading(false);
                alert(response?.response?.data?.error?.message);
                onReset();
              }
            })
            .catch((error) => {
              setIsLoading(false);
              console.error("Error adding Stock points:", error);
              alert(error);
            });
          // console.log(response);
          // if (response?.data?.success?.data === 1) {
          //   setIsLoading(false);
          //   alert("Record Already Exist");
          // } else {
        } else if (response?.response?.data?.statusCode === 400) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
          onReset();
        }
      })
      .catch((error) => {
        console.error("Error fetching Stock points:", error);
        setIsLoading(false);
      });
  };

  const updateStockPointConfig = (body) => {
    setIsLoading(true);
    MTA_SPCUpdate(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(false);
          alert("Stock Point Configuration Updated Successfully");
          onReset();
        }
      })
      .catch((error) => {
        console.error("Error updating Stock points:", error);
      });
  };

  const deleteStockPointConfig = () => {
    let body = {
      spc_instance_uid: selectedInstanceId,
      spc_stock_point_code: selectedStockPoint,
      deleted_by: sessionStorage.getItem("loginUsername"),
    };

    setIsLoading(true);
    MTA_SPCDelete(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(false);
          alert("Details Succesfully Deleted");
          onReset();
        }
      })
      .catch((error) => {
        console.error("Error deleting Stock points:", error);
      });
  };

  const strangerBlur = () => {
    console.log(typeof selectedRunnerItem, selectedStrangerItem, "RR");
    if (selectedRunnerItem !== "" && selectedStrangerItem !== "") {
      const runnerValue = parseFloat(selectedRunnerItem) + 0.01;
      const strangerValue = 100 - parseFloat(selectedStrangerItem) - 0.01;
      const walkerText = `From :${runnerValue} - TO :${strangerValue}`;

      setSelectedWalkerItem(walkerText);
    }
  };

  const searchHandler = () => {
    if (selectedStockPoint.trim() === "") {
      alert("Select Stock Point");
    } else {
      GetConfigValues();
    }
  };

  const getStockPointType = (instance) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      spt_instance_uid: instance,
      userid: loginId,
    };
    if (loginId) {
      SP_MtaStockpointtype(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            // setDropdownData(response?.data?.success?.data);
            setIsLoading(false);
            // console.log(response?.data?.success?.data.length, "length");

            if (response?.data?.success?.data.length > 0) {
              const types = response.success.data.map(
                (item) => item.spt_stock_point_type_code
              );
              setSelectedStockPointTypes(types);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching Stock points:", error);
        });
    }
  };

  const GetConfigValues = () => {
    setIsLoading(true);
    const body = {
      spc_instance_uid: selectedInstanceId,
      spc_stock_point_code: selectedStockPoint,
    };
    SPC_GetConfigValues(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const resData = response?.data?.success?.data[0];
          const checkLength = response?.data?.success?.data;
          if (checkLength.length === 0) {
            setIsLoading(false);
            alert("Data Not Found");
          } else {
            setSelectedReplenishment(resData?.spc_replenishment_days);
            setSelectedYellowP(resData?.spc_top_of_yellow_perc.toFixed(2));
            setSelectedRedP(resData?.spc_top_of_red_perc.toFixed(2));
            setSelectedPIncreasebyDBM(resData?.spc_perc_increase_by_dbm);
            setSelectedPDecreasebyDBM(resData?.spc_perc_decrease_by_dbm);
            setSelectedRationLogic(resData?.spc_rationing_logic_to_be_used);
            setSelectedConsumption(resData?.spc_consumption_to_be_taken_from);
            setSelectedIntransit(resData?.spc_intransit_to_be_taken_from);
            setSelectedBuffer(resData?.spc_buffer_penetration_parameter);
            setSelectedCyclesIncrease(
              resData?.spc_no_of_rpl_cycles_for_increase_in_buffer
            );
            setSelectedCyclesDecrease(
              resData?.spc_no_of_rpl_cycles_for_decrease_in_buffer
            );
            setSelectedSpikeP(resData?.spc_spike_perc);
            setSelectedRegularWarehouse(resData?.spc_regular_warehouse);
            setSelectedSpikeWarehouse(resData?.spc_spike_warehouse);
            setSelectedSourceStockP(resData?.spc_source_stock_point_perc);
            setSelectedAreaCode(resData?.spc_area_code);
            setBPBlockedQuantityEnabled(
              resData?.spc_buffer_penetration_blocked_quantity_enabled
            );
            setSelectedRPLCycles(
              resData?.spc_no_of_rpl_cycles_for_phase_out_of_item
            );
            setSelectedBufferDecrease(
              resData?.spc_no_of_buffer_decrease_for_phase_out_of_item
            );
            setSelectedRunnerItem(resData?.spc_runner_item_perc.toFixed(2));
            setSelectedStrangerItem(resData?.spc_stranger_item_perc.toFixed(2));
            const runnerValue =
              parseFloat(resData?.spc_runner_item_perc) + 0.01;
            const strangerValue =
              100 - parseFloat(resData?.spc_stranger_item_perc) - 0.01;
            const walkerText = `From :${runnerValue} - TO :${strangerValue}`;

            setSelectedWalkerItem(walkerText);
            setSelectedMinimumBuffer(
              resData?.spc_minimum_buffer_no_of_days_consumption
            );
            setSelectedMaximumBuffer(
              resData?.spc_maximum_buffer_no_of_days_consumption
            );
            setSelectedMaximumStock(
              resData?.spc_maximum_stock_value_for_stock_point
            );
            setSelectedSpcRed(resData?.spc_target_perc_red);
            setSelectedSpcBlack(resData?.spc_target_perc_black);
            setSelectedSpcWhite(resData?.spc_target_perc_white);
            setIsLoading(false);
            setDisabledInstance(true);
            setHidePopup(true);
            setSearched(true);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching Stock points:", error);
      });
  };

  const selectedInstanceChange = (e) => {
    setSearched(false);
    setDisabledInstance(false);
    setHidePopup(false);
    setActionType("");
    getStockPointType(e.target.value);
    setSelectedInstanceId(e.target.value);
    getRationingLogic(e.target.value);
    getConsumption(e.target.value);
    getIntransit(e.target.value);
    getBufferPenetration(e.target.value);
    setSelectedStockPoint("");
    setSelectedReplenishment("");
    setSelectedYellowP("");
    setSelectedRedP("");
    setSelectedPIncreasebyDBM("");
    setSelectedPDecreasebyDBM("");
    setSelectedRationLogic("0");
    setSelectedConsumption("0");
    setSelectedIntransit("0");
    setSelectedBuffer("0");
    setSelectedCyclesIncrease("");
    setSelectedCyclesDecrease("");
    setSelectedSpikeP("");
    setSelectedRegularWarehouse("");
    setSelectedSpikeWarehouse("");
    setSelectedSourceStockP("");
    setSelectedAreaCode("");
    setBPBlockedQuantityEnabled("");
    setSelectedRPLCycles("");
    setSelectedBufferDecrease("");
    setSelectedRunnerItem("");
    setSelectedStrangerItem("");
    setSelectedWalkerItem("");
    setSelectedMinimumBuffer("");
    setSelectedMaximumBuffer("");
    setSelectedMaximumStock("");
    setPopupData({});
  };

  const onReset = () => {
    setIsLoading(false);
    // setSelectedInstanceId("");
    // setRationingLogicData([]);
    // setConsumptionData([]);
    // setIntransitData([]);
    // setBufferPenetrationData([]);
    // setSelectedStockPointTypes([]);
    setActionType("");
    setSelectedStockPoint("");
    setSelectedReplenishment("");
    setSelectedYellowP("");
    setSelectedRedP("");
    setSelectedPIncreasebyDBM("");
    setSelectedPDecreasebyDBM("");
    setSelectedRationLogic("0");
    setSelectedConsumption("0");
    setSelectedIntransit("0");
    setSelectedBuffer("0");
    setSelectedCyclesIncrease("");
    setSelectedCyclesDecrease("");
    setSelectedSpikeP("");
    setSelectedRegularWarehouse("");
    setSelectedSpikeWarehouse("");
    setSelectedSourceStockP("");
    setSelectedAreaCode("");
    setBPBlockedQuantityEnabled("");
    setSelectedRPLCycles("");
    setSelectedBufferDecrease("");
    setSelectedRunnerItem("");
    setSelectedStrangerItem("");
    setSelectedWalkerItem("");
    setSelectedMinimumBuffer("");
    setSelectedMaximumBuffer("");
    setSelectedMaximumStock("");
    // setPopupData({});
    setSelectedSpcWhite("");
    setSelectedSpcBlack("");
    setSelectedSpcRed("");

    setSearched(false);
    setDisabledInstance(false);
    setHidePopup(false);
    // getInstances();
  };

  const onClose = () => {
    window.location.reload();
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"StockPointConfig"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>Stock Point Configuration</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table
                className={modules["customTableSPC"]}
                style={{ marginLeft: "3rem" }}
              >
                <tbody>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "20rem", fontWeight: "bold" }}>
                      MTA Instance <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedInstanceId}
                          onChange={(e) => selectedInstanceChange(e)}
                          className={modules["d_InstanceSelect1"]}
                          style={{ width: "250px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          disabled={disabledInstance}
                        >
                          {/* <option value={"SELECT"}>SELECT</option> */}
                          {dropdownData.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mta_instance_uid}
                                key={i.id}
                                data-id={i.id}
                              >
                                {i.mta_instance_uid}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "20rem", fontWeight: "bold" }}>
                      Action <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl
                        component="fieldset"
                        sx={{ marginLeft: "1rem" }}
                      >
                        <RadioGroup
                          row
                          aria-label="successorstartafterpredecessorstart"
                          name="successorstartafterpredecessorstart"
                          value={actionType}
                          onChange={handleRadioChange}
                          sx={{
                            "& svg": {
                              width: "13px",
                              height: "13px",
                            },
                          }}
                        >
                          <FormControlLabel
                            value="1"
                            sx={{
                              "& .MuiTypography-root ": {
                                fontSize: "12px",
                                fontFamily: "'WorkSans', sans-serif",
                              },
                            }}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: "blue",
                                  },
                                }}
                              />
                            }
                            label="Add New Record"
                          />
                          <FormControlLabel
                            value="2"
                            sx={{
                              "& .MuiTypography-root ": {
                                fontSize: "12px",
                                fontFamily: "'WorkSans', sans-serif",
                              },
                            }}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: "blue",
                                  },
                                }}
                              />
                            }
                            label="Update Record"
                          />
                          <FormControlLabel
                            value="3"
                            sx={{
                              "& .MuiTypography-root ": {
                                fontSize: "12px",
                                fontFamily: "'WorkSans', sans-serif",
                              },
                            }}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: "blue",
                                  },
                                }}
                              />
                            }
                            label="Delete Record"
                          />
                        </RadioGroup>
                      </FormControl>
                      {/* <Stack
                        direction="row"
                        spacing={4}
                        sx={{ marginLeft: "1rem" }}
                      >
                        <Stack direction="row">
                          <input
                            type="radio"
                            value="1"
                            style={{ height: "13px" }}
                          />
                          <label
                            className={modules["inputCCPMSet"]}
                            style={{ marginLeft: "3px" }}
                          >
                            Add New Record
                          </label>
                        </Stack>

                        <Stack direction="row">
                          <input
                            type="radio"
                            value="2"
                            style={{ height: "13px" }}
                          />
                          <label
                            className={modules["inputCCPMSet"]}
                            style={{ marginLeft: "3px" }}
                          >
                            Update Record
                          </label>
                        </Stack>

                        <Stack direction="row">
                          <input
                            type="radio"
                            value="3"
                            style={{ height: "13px" }}
                          />
                          <label
                            className={modules["inputCCPMSet"]}
                            style={{ marginLeft: "3px" }}
                          >
                            Delete Record
                          </label>
                        </Stack>
                      </Stack> */}
                    </td>
                  </tr>

                  {(actionType === "1" ||
                    actionType === "2" ||
                    actionType === "3") && (
                    <tr
                      style={{
                        paddingLeft: "0rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <td style={{ width: "20rem", fontWeight: "bold" }}>
                        Stock Point <label style={{ color: "Red" }}>*</label>
                      </td>
                      <td>:</td>
                      <td>
                        <input
                          value={selectedStockPoint}
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex = /^[a-zA-Z0-9&()[\].\/\-_=+ ]*$/;
                            if (regex.test(value) && value.length <= 50) {
                              setSelectedStockPoint(value);
                            }
                          }}
                          onBlur={(e) => {
                            const value = e.target.value;
                            const invalidCharsRegex = /[[\]\/=+.]/;
                            if (invalidCharsRegex.test(value)) {
                              alert("No Special Characters Allowed");
                              setSelectedStockPoint("");
                            }
                          }}
                          onPaste={(e) => e.preventDefault()}
                          className={modules["input_css"]}
                          style={{ marginLeft: "20px", width: "253px" }}
                        />
                        {!hidePopup && (
                          <img
                            src={window_popup}
                            alt=""
                            title="Click to Select Stock Point"
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                              height: "10px",
                            }}
                            onClick={() => {
                              window.open(
                                `/ccpm/StockPointPopup?instance=${selectedInstanceId}`,
                                "mypopuptitle",
                                "width=600,height=500"
                              );
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  )}

                  {actionType === "1" ||
                  (actionType === "2" && searched) ||
                  (actionType === "3" && searched) ? (
                    <>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          paddingTop: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Replenishment Days{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedReplenishment}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedReplenishment(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Top of Yellow Percentage{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedYellowP}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,3}$/.test(value)) {
                                setSelectedYellowP(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Top of Red Percentage{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedRedP}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,3}$/.test(value)) {
                                setSelectedRedP(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Percentage increase by DBM{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedPIncreasebyDBM}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedPIncreasebyDBM(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Percentage decrease by DBM{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedPDecreasebyDBM}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedPDecreasebyDBM(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Rationing Logic to be Used{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <FormControl sx={{ marginLeft: "20px" }}>
                            <select
                              value={selectedRationLogic}
                              onChange={(e) =>
                                setSelectedRationLogic(e.target.value)
                              }
                              className={modules["d_InstanceSelect"]}
                              style={{ width: "268px" }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                            >
                              <option value={"0"}>SELECT</option>
                              {rationingLogicData.map((i) => {
                                return (
                                  <option
                                    className={
                                      modules[
                                        "instanceAttMap_dropdownSelect_menu"
                                      ]
                                    }
                                    value={i.spcc_control_value}
                                    key={i.id}
                                  >
                                    {i.spcc_control_value}
                                  </option>
                                );
                              })}
                            </select>
                          </FormControl>
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Consumption to taken from{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <FormControl sx={{ marginLeft: "20px" }}>
                            <select
                              value={selectedConsumption}
                              onChange={(e) =>
                                setSelectedConsumption(e.target.value)
                              }
                              className={modules["d_InstanceSelect"]}
                              style={{ width: "268px" }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                            >
                              <option value={"0"}>SELECT</option>
                              {consumptionData.map((i) => {
                                return (
                                  <option
                                    className={
                                      modules[
                                        "instanceAttMap_dropdownSelect_menu"
                                      ]
                                    }
                                    value={i.spcc_control_value}
                                    key={i.id}
                                    data-id={i.id}
                                  >
                                    {i.spcc_control_value}
                                  </option>
                                );
                              })}
                            </select>
                          </FormControl>
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Intransit to be taken from{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <FormControl sx={{ marginLeft: "20px" }}>
                            <select
                              value={selectedIntransit}
                              onChange={(e) =>
                                setSelectedIntransit(e.target.value)
                              }
                              className={modules["d_InstanceSelect"]}
                              style={{ width: "268px" }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                            >
                              <option value={"0"}>SELECT</option>
                              {intransitData.map((i) => {
                                return (
                                  <option
                                    className={
                                      modules[
                                        "instanceAttMap_dropdownSelect_menu"
                                      ]
                                    }
                                    value={i.spcc_control_value}
                                    key={i.id}
                                    data-id={i.id}
                                  >
                                    {i.spcc_control_value}
                                  </option>
                                );
                              })}
                            </select>
                          </FormControl>
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Buffer Penetration Parameter{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <FormControl sx={{ marginLeft: "20px" }}>
                            <select
                              value={selectedBuffer}
                              onChange={(e) =>
                                setSelectedBuffer(e.target.value)
                              }
                              className={modules["d_InstanceSelect"]}
                              style={{ width: "268px" }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                            >
                              <option value={"0"}>SELECT</option>
                              {bufferPenetrationData.map((i) => {
                                return (
                                  <option
                                    className={
                                      modules[
                                        "instanceAttMap_dropdownSelect_menu"
                                      ]
                                    }
                                    value={i.spcc_control_value}
                                    key={i.id}
                                    data-id={i.id}
                                  >
                                    {i.spcc_control_value}
                                  </option>
                                );
                              })}
                            </select>
                          </FormControl>
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Number of RPL Cycles for Increase in buffer{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedCyclesIncrease}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedCyclesIncrease(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Number of RPL Cycles for decrease in buffer{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedCyclesDecrease}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedCyclesDecrease(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Spike Percentage{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedSpikeP}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedSpikeP(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Regular Warehouse
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedRegularWarehouse}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^[a-zA-Z0-9&()[\]./-_=+]*$/;
                              if (regex.test(value) && value.length <= 50) {
                                setSelectedRegularWarehouse(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Spike Warehouse
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedSpikeWarehouse}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^[a-zA-Z0-9&()[\]./-_=+]*$/;
                              if (regex.test(value) && value.length <= 50) {
                                setSelectedSpikeWarehouse(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Source Stock Point Percentage{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedSourceStockP}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedSourceStockP(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Area Code
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedAreaCode}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^[a-zA-Z0-9&()[\]./-_=+]*$/;
                              if (regex.test(value) && value.length <= 50) {
                                setSelectedAreaCode(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Buffer Penetration Blocked Quantity Enabled{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <FormControl sx={{ marginLeft: "20px" }}>
                            <select
                              className={modules["d_InstanceSelect"]}
                              style={{ width: "268px" }}
                              value={BPBlockedQuantityEnabled}
                              onChange={(e) =>
                                setBPBlockedQuantityEnabled(e.target.value)
                              }
                            >
                              <option value="0">SELECT</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </FormControl>
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Number of RPL Cycles For Phase Out Of Item{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedRPLCycles}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedRPLCycles(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Number of Buffer Decrease For Phase Out Of Item{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedBufferDecrease}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedBufferDecrease(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Runner Item Perc (Top ‘N’ Items){" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedRunnerItem}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,3}$/.test(value)) {
                                setSelectedRunnerItem(value);
                              }
                            }}
                            onBlur={(e) => {
                              strangerBlur(e);
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Stranger Item Perc (Bottom ‘N’ Items){" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedStrangerItem}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,3}$/.test(value)) {
                                setSelectedStrangerItem(value);
                              }
                            }}
                            onBlur={(e) => {
                              strangerBlur(e);
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Walker Item Perc (From - To)
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedWalkerItem}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Minimum Buffer - No of Days Consumption{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedMinimumBuffer}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedMinimumBuffer(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Maximum Buffer - No of Days Consumption{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedMaximumBuffer}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedMaximumBuffer(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Maximum Stock Value for Stock Point{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedMaximumStock}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                setSelectedMaximumStock(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Target Perc Red
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedSpcRed}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,3}$/.test(value)) {
                                setSelectedSpcRed(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Target Perc Black
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedSpcBlack}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,3}$/.test(value)) {
                                setSelectedSpcBlack(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "21rem", fontWeight: "bold" }}>
                          Target Perc White
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={selectedSpcWhite}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,3}$/.test(value)) {
                                setSelectedSpcWhite(value);
                              }
                            }}
                            onPaste={(e) => e.preventDefault()}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "254px" }}
                          />
                        </td>
                      </tr>
                    </>
                  ) : null}
                </tbody>
              </table>

              {actionType === "1" ||
              (actionType === "2" && searched) ||
              (actionType === "3" && searched) ? (
                <Grid
                  container
                  className={modules["mid-cont"]}
                  style={{
                    marginLeft: "26rem",
                    marginTop: "0rem",
                    marginBottom: "5%",
                  }}
                >
                  {actionType === "3" ? (
                    <button
                      className={modules["btn1"]}
                      onClick={deleteStockPointConfig}
                    >
                      Delete
                    </button>
                  ) : (
                    <button className={modules["btn1"]} onClick={submitHandler}>
                      Submit
                    </button>
                  )}

                  <button
                    className={modules["btn1"]}
                    style={{ marginLeft: "20px" }}
                    onClick={onClose}
                  >
                    Close
                  </button>
                  <button
                    className={modules["btn1"]}
                    style={{ marginLeft: "20px" }}
                    onClick={onReset}
                  >
                    Reset
                  </button>
                </Grid>
              ) : null}

              {(actionType === "2" || actionType === "3") && !searched && (
                <Grid
                  container
                  className={modules["mid-cont"]}
                  style={{ marginLeft: "25.1rem", marginTop: "0rem" }}
                >
                  <button className={modules["btn1"]} onClick={searchHandler}>
                    Search
                  </button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
