import React, { useState, useEffect, useRef } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import { Grid, Popover } from "@mui/material";
import modules from "./../ccpm.module.css";
import Chart from "chart.js/auto";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Calendar from "react-calendar";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetParetoSearchResult,
  LoadHistogramChart,
} from "../../../Services/CCPM.js";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

const DelayReportforPareto = () => {
  const [selectedInstance, setSelectedInstance] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("1");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");
  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());
  const [displayFromDate, setDisplayFromDate] = useState("");
  const [displayToDate, setDisplayToDate] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  const handleSelectChange = (attributenumber, id) => {
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const [instances, setInstances] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [accessTypes, setAccessTypes] = useState([
    { value: "null", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ]);

  const canvasRef = useRef(null);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
    }
  }, [instances]);

  useEffect(() => {
    if (chartData.labels.length === 0) return;

    const ctx = canvasRef.current.getContext("2d");

    const myChart = new Chart(ctx, {
      type: "bar",
      data: chartData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false, // Hide the legend
          },
        },
        scales: {
          "left-y-axis": {
            type: "linear",
            position: "left",
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: "No of Occurrences",
            },
          },
          "right-y-axis": {
            type: "linear",
            position: "right",
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: "Cumulative Percentage",
            },
          },
          x: {
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: "Reason For Delay",
            },
          },
        },
      },
    });

    return () => {
      myChart.destroy();
    };
  }, [chartData]);

  const handleCalendar1n = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    setFromdate(date);
    setAnchorEl(null);
    setDisplayFromDate(formattedDate);
  };

  const handleCalendar2n = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    setTodate(date);
    setAnchorEl2(null);
    setDisplayToDate(formattedDate);
  };

  const getProjectDetails = (I_id) => {
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    GetPCBindProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      accessType: selectedProjectAccessType,
      fromDate: "",
      toDate: "",
    };
    setIsLoading(true);
    GetParetoSearchResult(body)
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.statusCode === 200) {
          const successData = response?.data?.success?.data[0];
          if (Array.isArray(successData) && successData.length === 0) {
            alert("No Data To Display !!");
            return;
          }
          setChartData(response?.data?.success?.data[0]);
          getChart(
            response?.data?.success?.data[0],
            response?.data?.success?.data[1]
          );
          setTabData(response?.data?.success?.data[1]);
        } else if (response?.data?.statusCode === 400) {
          alert("No Data To Display !!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getChart = (C_data, P_data) => {
    const occurrences = P_data;
    const cumulativePercentage = C_data;
    const labels = occurrences.map((item) => item.reasonfordelay);
    const occurrencesData = occurrences.map((item) => item.taskid); // Assuming taskid as count for demo
    const percentageData = cumulativePercentage.map(
      (item) => item.cumulativeprecentage
    );

    setChartData({
      labels,
      datasets: [
        {
          type: "line",
          label: "Cumulative Percentage",
          data: percentageData,
          backgroundColor: "orange",
          borderColor: "orange",
          borderWidth: 1,
          yAxisID: "right-y-axis",
        },
        {
          type: "bar",
          label: "No of Occurrences",
          data: occurrencesData,
          backgroundColor: ["red", "grey"],
          borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
          borderWidth: 1,
          yAxisID: "left-y-axis",
        },
      ],
    });
  };

  // const getProjectCount = (
  //   attr1,
  //   attr2,
  //   attr3,
  //   attr4,
  //   attr5,
  //   attr6,
  //   attr7,
  //   attr8,
  //   attr9,
  //   attr10
  // ) => {
  //   const loginId = sessionStorage.getItem("loginId");
  //   const body = {
  //     instanceId: selectedInstance,
  //     userId: loginId,
  //     attribute1Id: attr1,
  //     attribute2Id: attr2,
  //     attribute3Id: attr3,
  //     attribute4Id: attr4,
  //     attribute5Id: attr5,
  //     attribute6Id: attr6,
  //     attribute7Id: attr7,
  //     attribute8Id: attr8,
  //     attribute9Id: attr9,
  //     attribute10Id: attr10,
  //     projId: selectedProjectName,
  //     projMgrId: selectedProjectManager,
  //     accessType: selectedProjectAccessType,
  //     projStatus: selectedProjectStatus,
  //   };
  //   GetPCProjectCount(body)
  //     .then((response) => {
  //       if (response?.data?.statusCode === 200) {
  //         console.log(response?.data?.success?.data, "project detail");
  //         const [greenData, yellowData, redData] =
  //           response?.data?.success?.data;
  //         // const flattenedData = response?.data?.success?.data.flat();
  //         setGreenTableData(greenData);
  //         setYellowTableData(yellowData);
  //         setRedTableData(redData);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const getallprojectdetails = (
  //   attr1,
  //   attr2,
  //   attr3,
  //   attr4,
  //   attr5,
  //   attr6,
  //   attr7,
  //   attr8,
  //   attr9,
  //   attr10
  // ) => {
  //   const loginId = sessionStorage.getItem("loginId");
  //   const body = {
  //     instanceId: selectedInstance,
  //     userId: loginId,
  //     attribute1Id: attr1,
  //     attribute2Id: attr2,
  //     attribute3Id: attr3,
  //     attribute4Id: attr4,
  //     attribute5Id: attr5,
  //     attribute6Id: attr6,
  //     attribute7Id: attr7,
  //     attribute8Id: attr8,
  //     attribute9Id: attr9,
  //     attribute10Id: attr10,
  //     projId: selectedProjectName,
  //     projMgrId: selectedProjectManager,
  //     accessType: selectedProjectAccessType,
  //     projStatus: selectedProjectStatus,
  //   };
  //   GetPCallprojectdetails(body)
  //     .then((response) => {
  //       if (response?.data?.statusCode === 200) {
  //         console.log(response?.data?.success?.data, "project all detail");
  //         const tabsVal = response?.data?.success?.data;
  //         const greenCount = tabsVal[0][0]?.greenprjcnt || 0;
  //         const yellowCount = tabsVal[1][0]?.yellowprjcnt || 0;
  //         const redCount = tabsVal[2][0]?.redprjcnt || 0;
  //         setTabData([redCount, yellowCount, greenCount]);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // Handle form submission logic
  //   console.log({
  //     selectedInstance,
  //     selectedZone,
  //     selectedProject,
  //     selectedManager,
  //     selectedAccessType,
  //     selectedProjectStatus,
  //   });
  // };

  const handleSearch = () => {
    setShowResult(true);
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    getSearchResult(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  const toggleCalendar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleToCalendar = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleReset = () => {
    const initialInstance = instances[0].id;
    setSelectedInstance(initialInstance);
    getProjectsname(initialInstance);
    getProjectsManager(initialInstance);
    getHierarchyOption(initialInstance);
    getValueFilterOption(initialInstance);
    setDisplayFromDate("");
    setDisplayToDate("");
    setShowResult(false);
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"DelayReportforPareto"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "5px" }}
            >
              <div className={modules["pro-bread"]}>
                <a>Reports</a>
                <img src={bread} alt="" />
                <a>Delay Report For PARETO</a>
              </div>
            </div>
            <div className={modules["border_contDelay"]}>
              <table className={modules["PCtable"]}>
                <tbody>
                  <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                    <td className={modules["table_dataDelay"]}>
                      <span style={{ width: "115px" }}>
                        Instance:<label style={{ color: "Red" }}>*</label>
                      </span>

                      <select
                        value={selectedInstance}
                        onChange={(e) => {
                          setSelectedInstance(e.target.value);
                          getProjectDetails(e.target.value);
                        }}
                        className={modules["dropdownSelect"]}
                        // style={{ width: "200px" }}
                      >
                        {instances?.map((instance) => (
                          <option key={instance.name} value={instance.id}>
                            {instance.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    {hierarchyOptions
                      ?.filter((option) => option.isactive === 1)
                      .map((option, index) => (
                        <React.Fragment key={option.attributename}>
                          <td className={modules["table_dataDelay"]}>
                            <span style={{ width: "115px" }}>
                              {option.attributename} :
                            </span>
                            <select
                              value={
                                selectedAttributes[
                                  `attr${option.attributenumber}`
                                ] || ""
                              }
                              onChange={(e) =>
                                handleSelectChange(
                                  option.attributenumber,
                                  e.target.value
                                )
                              }
                              className={modules["dropdownSelect"]}
                              // style={{ width: "200px" }}
                            >
                              <option value="">SELECT</option>
                              {filteredValueData[index]?.map((item) => (
                                <option
                                  key={item.attributevalue}
                                  value={item.id}
                                >
                                  {item.attributevalue}
                                </option>
                              ))}
                            </select>
                          </td>
                        </React.Fragment>
                      ))}
                  </tr>
                  <tr
                    style={{
                      paddingBottom: "10px",
                      paddingLeft: "0px",
                    }}
                  >
                    <td className={modules["table_dataDelay"]}>
                      <span style={{ width: "115px" }}>Project Name:</span>
                      <select
                        value={selectedProjectName}
                        onChange={(e) => setSelectedProjectName(e.target.value)}
                        className={modules["dropdownSelect"]}
                        // style={{ width: "200px" }}
                      >
                        <option value={0}>SELECT</option>
                        {projects?.map((p_name) => (
                          <option key={p_name.projectname} value={p_name.id}>
                            {p_name.projectname}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_dataDelay"]}>
                      <span style={{ width: "115px" }}>Project Manager:</span>
                      <select
                        value={selectedProjectManager}
                        onChange={(e) =>
                          setSelectedProjectManager(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        <option value={0}>SELECT</option>
                        {managers?.map((m_name) => (
                          <option key={m_name.username} value={m_name.id}>
                            {m_name.username}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_dataDelay"]}>
                      <span style={{ width: "115px" }}>
                        Project AccessType:
                      </span>
                      <select
                        value={selectedProjectAccessType}
                        onChange={(e) =>
                          setSelectedProjectAccessType(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        {accessTypes?.map((access) => (
                          <option key={access.label} value={access.value}>
                            {access.label}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_dataDelay"]}>
                      <span style={{ width: "110px", marginRight: "0.5rem" }}>
                        Task Completion From Date
                      </span>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "3px",
                        }}
                      >
                        <>
                          <input
                            className={modules["datepicker"]}
                            style={{
                              borderRadius: "10px",
                              border: "1px solid #9d9d9c",
                              fontFamily: "WorkSans, sans-serif",
                              color: "#373736",
                              fontSize: "12px",
                              padding: "2px",
                            }}
                            maxLength="20"
                            value={displayFromDate}
                            onClick={(event) => toggleCalendar(event)}
                            readOnly
                          />
                          <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            placement="bottom-start"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Calendar
                              value={fromdate}
                              onChange={handleCalendar1n}
                            />
                          </Popover>
                        </>
                      </div>
                    </td>
                    <td className={modules["table_dataDelay"]}>
                      <span style={{ width: "110px", marginRight: "0.5rem" }}>
                        To Date:
                      </span>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "3px",
                        }}
                      >
                        <>
                          <input
                            className={modules["datepicker"]}
                            style={{
                              borderRadius: "10px",
                              border: "1px solid #9d9d9c",
                              fontFamily: "WorkSans, sans-serif",
                              color: "#373736",
                              fontSize: "12px",
                              padding: "2px",
                            }}
                            maxLength="20"
                            value={displayToDate}
                            onClick={(event) => toggleToCalendar(event)}
                            readOnly
                          />
                          <Popover
                            open={open2}
                            anchorEl={anchorEl2}
                            onClose={() => setAnchorEl2(null)}
                            placement="bottom-start"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Calendar
                              value={todate}
                              onChange={handleCalendar2n}
                            />
                          </Popover>
                        </>
                      </div>
                    </td>
                  </tr>
                  <tr
                    style={{
                      justifyContent: "center",
                      gap: "10px",
                      paddingLeft: "0px",
                      paddingBottom: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      className={modules["PCbtn"]}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["PCbtn"]}
                      type="button"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>

            {showResult && tabData.length > 0 && (
              <>
                <div style={{ width: "50%", margin: "50px 0px 50px 120px" }}>
                  <canvas ref={canvasRef}></canvas>
                </div>
              </>
            )}

            {showResult && tabData.length > 0 && (
              <>
                <Grid
                  container
                  sx={{
                    marginLeft: "6.5rem",
                    padding: "8px",
                    width: "129%",
                  }}
                >
                  <Table
                    className={modules["DR_custom-table"]}
                    sx={{ paddingRight: "20px", width: "65%" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "60px" }} align="center">
                          Sr No
                        </TableCell>
                        <TableCell align="center">Project Name</TableCell>
                        <TableCell align="center" sx={{ width: "70px" }}>Project Manager</TableCell>
                        <TableCell align="center" sx={{ width: "40px" }}>Task ID</TableCell>
                        <TableCell align="center" sx={{ width: "60px" }}>Task Name</TableCell>
                        <TableCell align="center" sx={{ width: "70px" }}>Task Manager</TableCell>
                        <TableCell align="center" sx={{ width: "130px" }}>
                          Reason For Delay
                        </TableCell>
                        <TableCell align="center">
                          Secondary Reason For Delay
                        </TableCell>
                        <TableCell align="center">
                          Delay Reason Raised On
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* {
                    "srno": 1,
                    "projectsid": 8582,
                    "projectname": "Prashant_Project",
                    "projectmanager": "Subrata",
                    "taskname": "list type",
                    "taskid": 2,
                    "taskmanager": "Nishant Bhagwat",
                    "reasonfordelay": "Waiting for Customer clerance test",
                    "secondarydelay": "",
                    "reasondate": "2018/08/11"
                }, */}
                    <TableBody>
                      {tabData?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.srno}</TableCell>
                          <TableCell>{item.projectname}</TableCell>
                          <TableCell>{item.projectmanager} </TableCell>
                          <TableCell>{item.taskid}</TableCell>
                          <TableCell>{item.taskname}</TableCell>
                          <TableCell>{item.taskmanager}</TableCell>
                          <TableCell>{item.reasonfordelay}</TableCell>
                          <TableCell>{item.secondarydelay}</TableCell>
                          <TableCell style={{textAlign:"center"}}>
                            {new Date(item.reasondate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )}{" "}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DelayReportforPareto;
