import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import logo from "../Assets/godrej_logo.gif";
import prostream from "../Assets/prostream_logo.png";
import probag from "../Assets/prostream_bag.png";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { To_Login } from "../Services/Login";
import { GetMenuBar } from "../Services/Admin";
import downArrowImage from "../Assets/downarrow.png";
import shadow from "../Assets/shadow.png";
import lock_bg from "../Assets/lock_bg.png";
import user_bg from "../Assets/user_bg.png";
import modules from "./login.module.css";
import loading from "../Assets/Loading_image.gif";
import { useMenu } from "../Landing/MenuContext";

const Login = () => {
  const navigate = useNavigate();
  const { setMenuVisibility } = useMenu();
  const [loginUserName, setloginUserName] = useState("");
  const [loginPassword, setloginPassword] = useState("");
  const [loginUserNameShow, setloginUserNameShow] = useState(false);
  const [loginPasswordShow, setloginPasswordShow] = useState(false);
  const [Loginbuttonstatus, setLoginbuttonstatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [usernameFocused, setUsernameFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [usernameHasText, setUsernameHasText] = useState(false);
  const [passwordHasText, setPasswordHasText] = useState(false);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = () => {
    if (loginUserName === "" && loginPassword === "") {
      alert("Please enter Username");
    } else if (loginUserName === "") {
      alert("Please enter Username");
    } else if (loginPassword === "") {
      alert("Please enter Password");
    } else {
      setIsLoading(true);
      To_Login(loginUserName, loginPassword)
        .then((response) => {
          if (response.status === 200) {
            const userData =
              response.data.success.data[response.data.success.data.length - 1];
            const roleData = response.data.success.data;
            const roleIds = roleData.map((user) => user.roleid);
            const roleIdsString = roleIds.join(",");
            window.sessionStorage.setItem("roleIds", roleIdsString);
            window.sessionStorage.setItem("loginId", userData.id);
            window.sessionStorage.setItem("Username", userData.username);
            window.sessionStorage.setItem(
              "lastpwdupddate",
              userData.lastpwdupddate
            );
            window.sessionStorage.setItem("emailid", userData.emailid);
            window.sessionStorage.setItem("roleid", userData.roleid);
            window.sessionStorage.setItem("loginUsername", loginUserName);
            // window.sessionStorage.setItem("rolename", userData.rolename);
            const body = { userId: userData.id };
            GetMenuBar(body)
              .then((response) => {
                const responseData = response.data;
                if (responseData.statusCode === 200 && responseData.success) {
                  const data = responseData.success.data;
                  if (Array.isArray(data)) {
                    const visibility = {};
                    data.forEach((item) => {
                      const parentMenuName = item.parentmenuname?.trim();
                      if (parentMenuName) {
                        visibility[parentMenuName] = {
                          visibility: true,
                          subMenus: {},
                        };
                        item.pagenames.forEach((subItem) => {
                          const subMenuName = subItem.submenuname?.trim();
                          if (subMenuName) {
                            visibility[parentMenuName].subMenus[subMenuName] = {
                              visibility: true,
                              pages: subItem.pagename.reduce((acc, page) => {
                                acc[page.trim()] = true;
                                return acc;
                              }, {}),
                            };
                          }
                        });
                      }
                    });
                    if (visibility["CCPM"]?.subMenus["Masters"]) {
                      visibility["CCPM"].subMenus["Masters"].pages[
                        "Department Master"
                      ] = true;
                    }

                    if (visibility["CCPM"]?.subMenus["Reports"]) {
                      visibility["CCPM"].subMenus["Reports"].pages[
                        "Department Load Report"
                      ] = true;
                      visibility["CCPM"].subMenus["Reports"].pages[
                        "Planned Vs Actual"
                      ] = true;
                    }

                    sessionStorage.setItem(
                      "menuData",
                      JSON.stringify(visibility)
                    );
                    setMenuVisibility(visibility);
                    navigate("/home", { state: { response: userData } });
                  } else {
                    console.error("API response 'data' is not an array:", data);
                  }
                } else {
                  console.error(
                    "Unexpected API response format:",
                    responseData
                  );
                }
              })
              .catch((error) => {
                console.error("Error fetching menu data:", error);
              })
              .finally(() => {
                setIsLoading(false);
              });
            
          } else {
            setError("Invalid Login Id or Password");
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const handleFocus = () => {
    setUsernameFocused(true);
  };

  const handleBlur = () => {
    setUsernameFocused(false);
    // Set hasText based on the value of loginUserName
    setUsernameHasText(loginUserName !== "");
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setloginUserName(value);
    setUsernameHasText(value !== "");
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Box>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "5rem" }}>
            <img src={logo} alt="Godrej" />
          </Stack>
          <Stack sx={{ paddingRight: "5rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack
          sx={{ backgroundColor: "#eee", marginTop: "1.5rem", height: "70vh" }}
          direction="row"
          spacing={4}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <img src={probag} alt="Probag" />
          </Stack>
          <Stack sx={{ paddingRight: "5rem" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "100%",
                marginTop: "5rem",
              }}
            >
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Typography
                  sx={{
                    fontFamily: "WorkSans",
                    color: "red",
                    marginBottom: "0.5vh",
                  }}
                >
                  {error}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <img
                  src={user_bg}
                  alt="User Icon"
                  style={{ marginTop: "0px", marginBottom: "8px" }}
                />
                <TextField
                  inputProps={{
                    maxLength: 25,
                  }}
                  type="text" // No need to toggle visibility for username
                  label={loginUserName === "" ? "Username" : ""}
                  value={loginUserName}
                  onChange={(e) => setloginUserName(e.target.value)}
                  size="small"
                  sx={{
                    fontFamily: "WorkSans",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "14px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    width: "100%",
                    borderRadius: "5px",
                    marginBottom: "1.5vh",
                    color: " #00000080",
                    "& .MuiOutlinedInput-root": {
                      background: "#FFFFFF",
                      borderRadius: "0px",
                      "& fieldset": {
                        borderColor: "#c4c4c4",
                      },
                      "&:hover fieldset": {
                        borderColor: "#0B76BC",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0B76BC",
                      },
                      "& input": {
                        padding: "10px",
                        border: "0px !important",
                      },
                    },
                    "& .Mui-focused": {
                      "& .MuiOutlinedInput-input": {
                        borderRadius: "5px",
                      },
                    },
                  }}
                  variant="outlined"
                />
                <Typography
                  sx={{
                    color: "#FF0000 ",
                    margin: "-0.5vh 0 1vh 1vw ",
                    fontFamily: "WorkSans",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "14px",
                    letterSpacing: "0em",
                  }}
                >
                  {Loginbuttonstatus === "error" && !loginUserName
                    ? "Please enter username"
                    : ""}
                  {Loginbuttonstatus === "error" && loginUserName
                    ? "Please enter valid username"
                    : ""}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <img
                  src={lock_bg}
                  alt="User Icon"
                  style={{ marginTop: "0px", marginBottom: "8px" }}
                />
                <TextField
                  inputProps={{
                    maxLength: 25,
                  }}
                  type={loginPasswordShow ? "text" : "password"}
                  InputProps={{
                    endAdornment:
                      loginPassword.length > 0 ? ( // Conditionally render based on if loginPassword has value
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setloginPasswordShow(!loginPasswordShow)
                            }
                            edge="end"
                          >
                            {loginPasswordShow ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment
                          position="end"
                          sx={{ visibility: "hidden" }}
                        >
                          <IconButton edge="end">
                            <VisibilityOff />
                          </IconButton>
                        </InputAdornment>
                      ),
                  }}
                  label={loginPassword === "" ? "Password" : ""}
                  value={loginPassword}
                  onChange={(e) => setloginPassword(e.target.value)}
                  size="small"
                  sx={{
                    fontFamily: "WorkSans",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "14px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    width: "100%",
                    borderRadius: "5px",
                    marginBottom: "1.5vh",
                    color: " #00000080",
                    "& .MuiOutlinedInput-root": {
                      background: "#FFFFFF",
                      borderRadius: "0px",
                      "& fieldset": {
                        borderColor: "#c4c4c4",
                      },
                      "&:hover fieldset": {
                        borderColor: "#0B76BC",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0B76BC",
                      },

                      "& input": {
                        padding: "10px",
                        border: "0px !important",
                      },
                    },

                    "& .Mui-focused": {
                      "& .MuiOutlinedInput-input": {
                        borderRadius: "5px",
                      },
                    },
                  }}
                  variant="outlined"
                />
                <Typography
                  sx={{
                    color: "#FF0000 ",
                    margin: "-0.5vh 0 1vh 1vw ",
                    fontFamily: "WorkSans",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "14px",
                    letterSpacing: "0em",
                  }}
                >
                  {Loginbuttonstatus === "error" && !loginPassword
                    ? "Please enter password"
                    : ""}
                </Typography>
              </Box>
              <Button
                onClick={handleLogin}
                sx={{
                  width: "97%",
                  height: "7vh",
                  backgroundColor: "#0B76BC",
                  color: "#FFFFFF",
                  margin: "2.5vh 0 -2.5vh 0",
                  "&:hover": {
                    backgroundColor: "#c22165",
                  },
                }}
              >
                Login
              </Button>
            </Box>
            <Typography
              sx={{
                fontFamily: "WorkSans",
                textAlign: "center",
                color: "#551A8B",
                marginTop: "1rem",
              }}
            >
              <Link
                to="/forgotpassword"
                style={{
                  color: "#551A8B",
                  textDecoration: "none",
                  "&:hover": { color: "#000000" },
                }}
              >
                Forgot Password? Click here
              </Link>
            </Typography>
            <Box
              sx={{
                width: 20,
                height: 17,
                color: "#000000",
                backgroundImage: `url(${downArrowImage})`,
                margin: "1rem auto",
              }}
            />

            <Box
              sx={{
                width: "175px",
                height: "2px",
                boxShadow: "0 -24px 8px rgba(0, 0, 0, 0.4)",
                backgroundImage: `url(${shadow})`,
                margin: "1rem auto",
              }}
            />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Login;
