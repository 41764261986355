import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
import modules from "./../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetIRExport,
  GetIRIssueOwner,
  GetIRSearchResult,
} from "../../../Services/CCPM.js";

// function ArrowLine() {
//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       <div
//         style={{
//           width: "1px",
//           height: "18px",
//           backgroundColor: "grey",
//           marginRight: "2px",
//         }}
//       />
//       <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
//     </div>
//   );
// }

const IssueReport = () => {
  const [selectedInstance, setSelectedInstance] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("1");
  const [selectedAssignedTo, setselectedAssignedTo] = useState("0");
  const [assignedOwner, setAssignedOwner] = useState([]);
  const [selectedissueStatus, setSelectedIssueStatus] = useState("0");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  const handleSelectChange = (attributenumber, id) => {
    // console.log(attributenumber, id, "attributenumber value");
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [instances, setInstances] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);

  const accessTypes = [
    { value: "", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ];

  const projectStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Active" },
    { value: "5", label: "Hold" },
    { value: "7", label: "Completed" },
    { value: "10", label: "CDDCheckedOut" },
    { value: "11", label: "ModifiedCheckedOut" },
  ];

  const issueStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Pending" },
    { value: "5", label: "Closed" },
  ];

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getAssinedOwner();
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    GetPCBindProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssinedOwner = () => {
    const body = {
      userId: 0,
    };
    GetIRIssueOwner(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "Assigned Owner");
          setAssignedOwner(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      issueOwner: selectedAssignedTo,
      projectType: 0,
      accessType: selectedProjectAccessType,
      issueStatusId: selectedissueStatus,
    };

    GetIRSearchResult(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "table data");
          setTableData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExcel = async (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: parseInt(selectedInstance),
      userId: parseInt(loginId),
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      issueOwner: parseInt(selectedAssignedTo),
      projectType: parseInt(selectedProjectAccessType) || 0,
      accessType: "",
      issueStatusId: parseInt(selectedissueStatus),
    };

    // if (selectedRoleId !== -1) {
    try {
      setIsLoading(true);
      const response = await GetIRExport(body);

      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // const contentDisposition = response.headers["content-disposition"];
        const filename = "IssueReport.xlsx";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoading(false);
        console.error("No Data found");
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error downloading file:", err);
    }
  };

  const handleSearch = () => {
    setShowResult(true);
    console.log(selectedAttributes, "selectedAttributes");
    console.log(selectedProjectStatus, "status");
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getSearchResult(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  const DownloadHandler = () => {
    setShowResult(true);
    console.log(selectedAttributes, "selectedAttributes");
    console.log(selectedProjectStatus, "status");
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getExcel(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  return (
    <>
      <div
        style={{
          overflowX: showResult && tableData.length > 0 ? "auto" : "hidden",
          height: "100vh",
        }}
      >
        {isLoading && (
          <img
            src={loading}
            className={modules["loading_gif"]}
            alt="Loading..."
          />
        )}
        <Grid container>
          <Grid item lg={0.6} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>

          <Grid item lg={12} sx={{ display: "block" }}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"IssueReport"} />
            </Grid>

            <Grid item lg={12}>
              <div
                className={modules["instancemaster_div"]}
                style={{ marginBottom: "5px" }}
              >
                <div
                  className={modules["pro-bread"]}
                  style={{ marginLeft: "-2.5rem" }}
                >
                  <a>Reports</a>
                  <img src={bread} alt="" />
                  <a>Issue Open Report</a>
                </div>
              </div>
              <div className={modules["border_cont"]}>
                <table className={modules["PCtable"]}>
                  <tbody>
                    <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Instance:<label style={{ color: "Red" }}>*</label>
                        </span>

                        <select
                          value={selectedInstance}
                          onChange={(e) => {
                            setSelectedInstance(e.target.value);
                            getProjectDetails(e.target.value);
                          }}
                          className={modules["dropdownSelect"]}
                          // style={{ width: "200px" }}
                        >
                          {instances?.map((instance) => (
                            <option key={instance.name} value={instance.id}>
                              {instance.name}
                            </option>
                          ))}
                        </select>
                      </td>

                      {hierarchyOptions
                        ?.filter((option) => option.isactive === 1)
                        .map((option, index) => (
                          <React.Fragment key={option.attributename}>
                            <td className={modules["table_data1"]}>
                              <span
                                style={{ width: "115px", color: "#414141" }}
                              >
                                {option.attributename} :
                              </span>
                              <select
                                value={
                                  selectedAttributes[
                                    `attr${option.attributenumber}`
                                  ] || ""
                                }
                                onChange={(e) =>
                                  handleSelectChange(
                                    option.attributenumber,
                                    e.target.value
                                  )
                                }
                                className={modules["dropdownSelect"]}
                                // style={{ width: "200px" }}
                              >
                                <option value="">SELECT</option>
                                {filteredValueData[index]?.map((item) => (
                                  <option
                                    key={item.attributevalue}
                                    value={item.id}
                                  >
                                    {item.attributevalue}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </React.Fragment>
                        ))}
                    </tr>
                    <tr
                      style={{
                        paddingBottom: "10px",
                        paddingLeft: "0px",
                      }}
                    >
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Project Name :
                        </span>
                        <select
                          value={selectedProjectName}
                          onChange={(e) =>
                            setSelectedProjectName(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          // style={{ width: "200px" }}
                        >
                          <option value={0}>SELECT</option>
                          {projects?.map((p_name) => (
                            <option key={p_name.projectname} value={p_name.id}>
                              {p_name.projectname}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Project Manager :
                        </span>
                        <select
                          value={selectedProjectManager}
                          onChange={(e) =>
                            setSelectedProjectManager(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={0}>SELECT</option>
                          {managers?.map((m_name) => (
                            <option key={m_name.username} value={m_name.id}>
                              {m_name.username}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Project AccessType:
                        </span>
                        <select
                          value={selectedProjectAccessType}
                          onChange={(e) => {
                            setSelectedProjectAccessType(e.target.value);
                          }}
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          {accessTypes?.map((access) => (
                            <option key={access.label} value={access.value}>
                              {access.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Assigned To :
                        </span>
                        <select
                          value={selectedAssignedTo}
                          onChange={(e) =>
                            setselectedAssignedTo(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={0}>All</option>
                          {assignedOwner?.map((assign) => (
                            <option key={assign.issuemanager} value={assign.id}>
                              {assign.issuemanager}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Project Type :
                        </span>
                        <select
                          value={selectedProjectStatus}
                          onChange={(e) =>
                            setSelectedProjectStatus(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          {projectStatuses?.map((status) => (
                            <option key={status.label} value={status.value}>
                              {status.label}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Issue Status :
                        </span>
                        <select
                          value={selectedissueStatus}
                          onChange={(e) =>
                            setSelectedIssueStatus(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          {issueStatuses?.map((status) => (
                            <option key={status.label} value={status.value}>
                              {status.label}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr
                      style={{
                        justifyContent: "center",
                        gap: "10px",
                        paddingLeft: "0px",
                        paddingBottom: "10px",
                      }}
                    >
                      <button
                        type="submit"
                        className={modules["PCbtn"]}
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                      <button
                        className={modules["PCbtn"]}
                        type="button"
                        onClick={() => {
                          window.location.reload();
                          setShowResult(false);
                        }}
                      >
                        Reset
                      </button>

                      {showResult && tableData.length > 0 && (
                        <button
                          type="submit"
                          className={modules["PCbtn"]}
                          onClick={DownloadHandler}
                        >
                          Export to Excel
                        </button>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>

              {showResult && tableData.length > 0 && (
                <>
                  <Grid
                    container
                    sx={{
                      marginLeft: "6.5rem",
                      padding: "8px",
                      width: "129%",
                    }}
                  >
                    <Table
                      className={modules["IR_custom-table"]}
                      sx={{ paddingRight: "20px" }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: "60px" }}>Sr No</TableCell>
                          <TableCell>Project Name</TableCell>
                          <TableCell>Department</TableCell>
                          <TableCell>Task Id</TableCell>
                          <TableCell>Task Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Raised By</TableCell>
                          <TableCell>Raised On</TableCell>
                          <TableCell>Assigned To</TableCell>
                          <TableCell>Resolution</TableCell>
                          <TableCell>Resolved On</TableCell>
                          <TableCell>Resolve Action</TableCell>
                          <TableCell>Issue Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell className={modules["IRtable-row"]}>
                              {index + 1}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.projectname}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.department}
                            </TableCell>
                            <TableCell
                              sx={{
                                background: `${item.taskcolour}`,
                                color: "#ffffff",
                              }}
                            >
                              {item.taskid}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.taskname}
                            </TableCell>
                            <TableCell>
                              <textarea
                                value={item.description}
                                style={{
                                  height: "65px",
                                  width: "295px",
                                  outline: "none",
                                  border: "1px solid #ccc",
                                  padding: "5px",
                                }}
                              />
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.raisedby}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.raisedon
                                ? (() => {
                                    const date = new Date(item.raisedon);
                                    const year = date.getFullYear();
                                    const month = String(
                                      date.getMonth() + 1
                                    ).padStart(2, "0"); // Months are 0-indexed
                                    const day = String(date.getDate()).padStart(
                                      2,
                                      "0"
                                    );
                                    const hours = String(
                                      date.getHours()
                                    ).padStart(2, "0");
                                    const minutes = String(
                                      date.getMinutes()
                                    ).padStart(2, "0");
                                    const seconds = String(
                                      date.getSeconds()
                                    ).padStart(2, "0");
                                    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
                                  })()
                                : ""}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.issueowner}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              <textarea
                                value={item.resolveaction}
                                style={{
                                  height: "65px",
                                  width: "295px",
                                  outline: "none",
                                  border: "1px solid #ccc",
                                  padding: "5px",
                                }}
                              />
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.resolvedon
                                ? (() => {
                                    const date = new Date(item.resolvedon);
                                    const year = date.getFullYear();
                                    const month = String(
                                      date.getMonth() + 1
                                    ).padStart(2, "0"); // Months are 0-indexed
                                    const day = String(date.getDate()).padStart(
                                      2,
                                      "0"
                                    );
                                    const hours = String(
                                      date.getHours()
                                    ).padStart(2, "0");
                                    const minutes = String(
                                      date.getMinutes()
                                    ).padStart(2, "0");
                                    const seconds = String(
                                      date.getSeconds()
                                    ).padStart(2, "0");
                                    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
                                  })()
                                : ""}
                            </TableCell>

                            <TableCell className={modules["IRtable-row"]}>
                              {item.resolveaction}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.issuestatustext}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default IssueReport;
