import React from "react";
import { Grade } from "@mui/icons-material";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Sidebar from "../../../Landing/Navbar";
import bread from "../../../Assets/bread.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList,
  } from "react-icons/fa";
import modules from "./Hierarchies.module.css";
import {
  HierachiesGetAttribute,
  HierachiesGetInstance,
  HierachiesGetMasterTables,
  HierachiesGetOtherMasterTables,
  HierachiesRowUpdating,
  HierachiesShowMasterTablesOnDataBound,
  HierachiesSubmitClick,
  HierachiesRowDelete
} from "../../../Services/Dss";
import SampleSidebar from "../../../Landing/SampleSidebar";

export default function Hierarchies() {
  const [dropdownData, setDropdownData] = useState([
    "Tooling",
    "Instance 2",
    "Instance 3",
  ]);

  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);

  const [displayTable, setDisplayTable] = useState(false);
  const [othermast, setOthermast] = useState(false);

  const [submitData, setSubmitdata] = useState([]);

  const [mastertabledrpsubmit, setMastertabledrpsubmit] = useState([]);

  const [mastertabledrpsubmitvalue, setMastertabledrpsubmitvalue] = useState(
    []
  );

  //   const [selectedmast, setSelectedMast] = useState('Master');

  const [pageStates, setPageStates] = useState({
    instancedrop: [],
    masterdrop: [],
    othermasterdrop: [],
    attributedrop: [],
    submitdata: [],
  });

  const [mainDropdowns, setMainDropdowns] = useState({
    instancevalue: "select",
    mastervalue: "select",
    othermastervalue: "select",
    attributevalue: "select",
  });

  const [tableData, setTableData] = useState({
    editStates: [],
    updateStates: [],
  });

  const [submittable,setSubmittable]=useState({
    dropvalues:[]
  })

  const [tableeditstates,setTableEditstates]=useState([])

  const handleSubmit = () => {
    if (mainDropdowns.instancevalue === "select") {
      alert("Select Instance UID");
    } else if (mainDropdowns.mastervalue === "select") {
      alert("Select Master");
    } else if (mainDropdowns.attributevalue === "select") {
      alert("Select Attribute");
    } else if (mainDropdowns.mastervalue === "Others") {
      if (
        mainDropdowns.othermastervalue === "select" ||
        mainDropdowns.othermastervalue === ""
      ) {
        alert("Select Other Master");
      } else {
        console.log("called");
        
        let selectmastbool = true;
        let selectedValue = selectmastbool
          ? mainDropdowns.othermastervalue
          : mainDropdowns.mastervalue;
        HierachiesSubmitClick(
          mainDropdowns.instancevalue,
          selectmastbool ? "Others" : "Master",
          selectedValue,
          mainDropdowns.attributevalue
        )
          .then((res) => {
            if (res.status === 200) {
              setDisplayTable(true);
              console.log(res);
              // setSubmitdata(res.data[0]);
              setSubmitdata(res?.data);
                setTableEditstates([...Array(10)]?.map((i,index)=>{
                  return{
                    editstate:false,
                    recordid:(res.data[index]?.record_id?res.data[index]?.record_id:''),
                    attributetablename:(res.data[index]?.attributetablename?res.data[index]?.attributetablename:''),
                    attributecolumnname1:(res.data[index]?.attributecolumnname1?res.data[index]?.attributecolumnname1:''),
                    attributecolumnname2:(res.data[index]?.attributecolumnname2?res.data[index]?.attributecolumnname2:''),
                    attributecolumnname3:(res.data[index]?.attributecolumnname3?res.data[index]?.attributecolumnname3:''),
                    attributecolumnname4:(res.data[index]?.attributecolumnname4?res.data[index]?.attributecolumnname4:''),
                    attributecolumnname5:(res.data[index]?.attributecolumnname5?res.data[index]?.attributecolumnname5:''),
                    attributecolumnname6:(res.data[index]?.attributecolumnname6?res.data[index]?.attributecolumnname6:''),
                    attributecolumnname7:(res.data[index]?.attributecolumnname7?res.data[index]?.attributecolumnname7:''),
                    attributecolumnname8:(res.data[index]?.attributecolumnname8?res.data[index]?.attributecolumnname8:''),
                    attributecolumnname9:(res.data[index]?.attributecolumnname9?res.data[index]?.attributecolumnname9:''),
                  }
                }))
              
              // HierachiesShowMasterTablesOnDataBound(
              //   mainDropdowns.instancevalue,
              //   selectmastbool ? "Others" : "Master",
              //   selectedValue,
              //   mainDropdowns.attributevalue
              // )
              //   .then((res) => {
              //     if (res.status === 200) {
              //       console.log(res);
              //       setMastertabledrpsubmit(res.data[1]);
              //       setTableData({
              //         ...tableData,
              //         editStates: res?.data[1].map((i) => {
              //           return false;
              //         }),
              //         updateStates: res?.data[1],
              //       });
              //     }
              //   })
              //   .catch((err) => {
              //     console.log("Error fetching data:", err);
              //   });
            }
          })
          .catch((err) => {
            console.log("Error fetching data:", err);
          });
      }
    } else {
      console.log("called");
     
      let selectmastbool = false;
      let selectedValue = selectmastbool
        ? mainDropdowns.othermastervalue
        : mainDropdowns.mastervalue;
      HierachiesSubmitClick(
        mainDropdowns.instancevalue,
        selectmastbool ? "Others" : "Master",
        selectedValue,
        mainDropdowns.attributevalue
      )
        .then((res) => {
          if (res.status === 200) {
            setDisplayTable(true);
            console.log(res.data);
            // setSubmitdata(res.data[0]);
            setSubmitdata(res?.data)
            HierachiesShowMasterTablesOnDataBound(
              mainDropdowns.instancevalue,
              selectmastbool ? "Others" : "Master",
              selectedValue,
              mainDropdowns.attributevalue
            )
              .then((res) => {
                if (res.status === 200) {
                  console.log(res);
                  setMastertabledrpsubmit(res?.data[1]);
                  setTableData({
                    ...tableData,
                    editStates: res?.data[1]?.map((i) => {
                      return false;
                    }),
                    updateStates: res?.data[1],
                  });

                  GetAttributesForTable()
                }
              })
              .catch((err) => {
                console.log("Error fetching data:", err);
              });
      // for(let j=0;j<10;j++){
            setTableEditstates([...Array(10)]?.map((i,index)=>{
              return{
                editstate:false,
                recordid:(res.data[index]?.record_id?res.data[index]?.record_id:''),
                attributetablename:(res.data[index]?.attributetablename?res.data[index]?.attributetablename:''),
                attributecolumnname1:(res.data[index]?.attributecolumnname1?res.data[index]?.attributecolumnname1:''),
                attributecolumnname2:(res.data[index]?.attributecolumnname2?res.data[index]?.attributecolumnname2:''),
                attributecolumnname3:(res.data[index]?.attributecolumnname3?res.data[index]?.attributecolumnname3:''),
                attributecolumnname4:(res.data[index]?.attributecolumnname4?res.data[index]?.attributecolumnname4:''),
                attributecolumnname5:(res.data[index]?.attributecolumnname5?res.data[index]?.attributecolumnname5:''),
                attributecolumnname6:(res.data[index]?.attributecolumnname6?res.data[index]?.attributecolumnname6:''),
                attributecolumnname7:(res.data[index]?.attributecolumnname7?res.data[index]?.attributecolumnname7:''),
                attributecolumnname8:(res.data[index]?.attributecolumnname8?res.data[index]?.attributecolumnname8:''),
                attributecolumnname9:(res.data[index]?.attributecolumnname9?res.data[index]?.attributecolumnname9:''),
                attributedropdown:[],

              }
            }))        
        }
        })
        .catch((err) => {
          console.log("Error fetching data:", err);
        });
    }
  };

  useEffect(() => {
    HierachiesGetInstance("pritpwc")
      .then((res) => {
        if (res.status === 200) {
          setPageStates({
            ...pageStates,
            instancedrop: res.data,
          });
          setMainDropdowns({
            ...mainDropdowns,
            instancevalue: res.data[0].instanceuid,
          });
        }
      })
      .catch((err) => {
        alert("Error fetching data:", err);
      });
  }, []);

  function GetMasterHandler(value) {
    if (value !== "select") {
      HierachiesGetMasterTables(value)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setPageStates({
              ...pageStates,
              masterdrop: res.data,
            });
          }
        })
        .catch((err) => {
          alert("Error while fetching data:", err);
        });
    }
  }

  function GetOtherMasterHandler(value) {
    // setOthermast(true)
    if (value !== "select") {
      HierachiesGetOtherMasterTables(mainDropdowns.instancevalue)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setPageStates({
              ...pageStates,
              othermasterdrop: res.data,
            });
          }
        })
        .catch((err) => {
          alert("Error while fetching data:", err);
        });
    }
  }

  // function GetAttributeHandler(value) {
  //   // let selectmastbool = mainDropdowns.mastervalue === "Others" ? true : false;
  //   let selectmastbool = (value === "Others") ? true : false;
  //   if (value !== "select") {
  //     HierachiesGetAttribute(
  //       mainDropdowns.instancevalue,
  //       selectmastbool ? "Others" : "Master",
  //       selectmastbool ? value : mainDropdowns.mastervalue
  //     )
  //       .then((res) => {
  //         if (res.status === 200) {
  //           console.log(res);
  //           setPageStates({
  //             ...pageStates,
  //             attributedrop: res?.data,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         alert("Error while fetching data:", err);
  //       });
  //   }
  // }

  function GetAttributeHandler(value,drpname) {
    if (value !== "select" && value!=='Others') {
      HierachiesGetAttribute(
        mainDropdowns.instancevalue,
        drpname===1 ? "Master" : "Others",
        value
      )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            setPageStates({
              ...pageStates,
              attributedrop: res?.data,
            });
          }
        })
        .catch((err) => {
          alert("Error while fetching data:", err);
        });
    }
  }


  function GetAttributesForTable(value) {
    let selectmastbool = mainDropdowns?.mastervalue === "Others" ? true : false;
    if (value !== "select") {
      HierachiesGetAttribute(
        mainDropdowns.instancevalue,
        selectmastbool ? "Others" : "Master",
        mastertabledrpsubmitvalue
      )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            setSubmittable({
              dropvalues: res.data,
            });
          }
        })
        .catch((err) => {
          alert("Error while fetching data:", err);
        });
    }
  }

  function ArrowLine() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "2px",
            height: "30px",
            backgroundColor: "grey",
            marginRight: "2px",
          }}
        />
        <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
      </div>
    );
  }

  // function EditHandler(idx) {
  //   setTableData({
  //     ...tableData,
  //     editStates: tableData?.editStates.map((i, index) => {
  //       return idx === index ? !tableData.editStates[index] : i;
  //     }),
  //   });
  // }

  function EditHandler(key){
    // GetAttributesForSelectedMasterOnEdit(tableeditstates[key].attributetablename,key)
    setTableEditstates(
      tableeditstates.map((i, index) => {
        if (index === key) {
          return { ...i, editstate: true };
        }
        return i;
      })
    );
  }

  function GetAttributesForSelectedMasterOnEdit(value,index) {
    if (value !== "select") {
      HierachiesGetAttribute(
        mainDropdowns.instancevalue,
        "Master",
        value
      )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            setTableEditstates(
              tableeditstates.map((i, idx) => {
                if (index === idx) {
                  return { ...i, attributedropdown: res.data};
                }
                return i;
              })
            );


          }
        })
        .catch((err) => {
          alert("Error while fetching data:", err);
        });
    }
  }

  function CancelHandler(key){
    setTableEditstates(
      tableeditstates.map((i, index) => {
        if (index === key) {
          return { ...i, editstate: false };
        }
        return i;
      })
    );
  }

  function UpdateRowHandler(ele, index) {
    const body = {
      instanceId: mainDropdowns.instancevalue,
      recordId: tableeditstates[index].recordid?tableeditstates[index].recordid:'',
      masterTableName: mainDropdowns.mastervalue,
      attribute: mainDropdowns.attributevalue,
      userId: "pritpwc",
      tableName: tableeditstates[index].attributetablename,
      attributeColumnName1: tableeditstates[index].attributecolumnname1?tableeditstates[index].attributecolumnname1:'',
      attributeColumnName2: tableeditstates[index].attributecolumnname2?tableeditstates[index].attributecolumnname2:'',
      attributeColumnName3: tableeditstates[index].attributecolumnname3?tableeditstates[index].attributecolumnname3:'',
      attributeColumnName4: tableeditstates[index].attributecolumnname4?tableeditstates[index].attributecolumnname4:'',
      attributeColumnName5: tableeditstates[index].attributecolumnname5?tableeditstates[index].attributecolumnname5:'',
      attributeColumnName6: tableeditstates[index].attributecolumnname6?tableeditstates[index].attributecolumnname6:'',
      attributeColumnName7: tableeditstates[index].attributecolumnname7?tableeditstates[index].attributecolumnname7:'',
      attributeColumnName8: tableeditstates[index].attributecolumnname8?tableeditstates[index].attributecolumnname8:'',
      attributeColumnName9: tableeditstates[index].attributecolumnname9?tableeditstates[index].attributecolumnname9:'',
      hostName: "Host",
      ipAddress: "IP",
    };

    HierachiesRowUpdating(body)
      .then((res) => {
        if (res.status === 200) {
          console.log(res)
          alert(res.data.SuccessMessage);
          CancelHandler([index])
          handleSubmit();
        }

        if(res.response.status === 400){
          console.log(res)
          alert(res.response.data);
        }
      })
      .catch((err) => {
        console.log("Error while updating data:", err);
      });
  }

  function DeleteHandler(ele,index){
    const body = { 
      instanceuid: mainDropdowns.instancevalue,
      record_id: tableeditstates[index].recordid,
      tableName: tableeditstates[index].attributetablename,
      sourceName: mainDropdowns.mastervalue,
      linkto: "string",
      sourceCol: "string"
    };

    console.log('bodyy'+body)

    HierachiesRowDelete(body)
    .then((res) => {
      if (res.status === 200) {
        console.log(res)
        alert(res.data.SuccessMessage);
        CancelHandler([index])
        handleSubmit();
      }

      if(res?.response?.status === 400){
        console.log(res)
        alert(res.response.data);
      }
    })
    .catch((err) => {
      console.log("Error while updating data:", err);
    });

  }

  // function handleDropChange(index,key,e){
  //   // setTableEditstates((prevSubmitStates) => {
  //   //   const updatedUpdateStates = prevSubmitStates.tableeditstates.map((item, idx) => {
  //   //     return index === idx ? { ...item, [key]: e.target.value } : item;
  //   //   });

  //   //   return {
  //   //     ...prevSubmitStates,
  //   //     tableeditstates: updatedUpdateStates,
  //   //   };
  //   // });



  //   const updatedStates=[...tableeditstates];
  //    console.log('tabbllee',updatedStates)
   
  //   setTableEditstates(updatedStates.splice(index,1,{
  //     ...tableeditstates[index],
  //     [key]:e.target.value
  //   }))
    
    

  // }

  function GetAttributesForSelectedMaster(value,index,b,val) {
    if (value !== "select") {
      HierachiesGetAttribute(
        mainDropdowns.instancevalue,
        "Master",
        value
      )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            setTableEditstates(
              tableeditstates.map((i, idx) => {
                if (index === idx) {
                  return { ...i, attributedropdown: res.data, [b]:val};
                }
                return i;
              })
            );


          }
        })
        .catch((err) => {
          alert("Error while fetching data:", err);
        });
    }
  }

  function handleMasterDropChange(index, key, e) {
    // const updatedStates = [...tableeditstates];
    // const b = {
    //   ...updatedStates[index],
    //   [key]: e.target.value
    // };
    // setTableEditstates();

    GetAttributesForSelectedMaster(e.target.value,index,key,e.target.value)

  }

  function handleDropChange(index, key, e) {
    const updatedStates = [...tableeditstates];
    updatedStates[index] = {
      ...updatedStates[index],
      [key]: e.target.value
    };
    setTableEditstates(updatedStates);
  }

  const myArray = Array.from({ length: 10 }, (_, index) => index);
  return (
    <>
      <Grid container>
        <Grid
          item
          lg={0.6}
          sx={{ position: "fixed", zIndex: 2000 }}
          className={modules["main-cont"]}
        >
          {/* <Sidebar /> */}
          <SampleSidebar/>
        </Grid>
        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home />
          </Grid>
        </Grid>
        <Grid item lg={9} className={modules["hierarchies_div"]}>
          <div className={modules["pro-bread"]}>
            <p>
              <a>DSS Masters</a>
              <img src={bread} alt="" />
              <a>Hierarchies</a>
            </p>
          </div>
        </Grid>

        <Grid container sx={{ marginLeft: "5rem", marginTop: "2rem" }}>
          <table className={modules["customTable1"]}>
            <tbody>
              <tr>
                <td>DSS Instance</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={mainDropdowns.instancevalue}
                      onChange={(e) => {
                        setMainDropdowns({
                          ...mainDropdowns,
                          instancevalue: e.target.value,
                        });
                        GetMasterHandler(e.target.value);
                      }}
                    >
                      <MenuItem value="select" sx={{ display: "none" }}>
                        select
                      </MenuItem>
                      {pageStates?.instancedrop?.map((i) => {
                        return (
                          <MenuItem value={i.instanceuid} key={i.instanceuid}>
                            {i.instanceuid}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>{" "}
                </td>
              </tr>

              <tr>
                <td>Select Master</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={mainDropdowns.mastervalue}
                      onChange={(e) => {
                        setMainDropdowns({
                          ...mainDropdowns,
                          mastervalue: e.target.value,
                        });
                        // (e.target.value==='Others'?(setOthermast(true) && GetOtherMasterHandler(e.target.value)):(setOthermast(false) && GetAttributeHandler(e.target.value)))
                        if (e.target.value === "Others") {
                          setOthermast(true);
                          GetOtherMasterHandler(e.target.value);
                        } else {
                          setOthermast(false);
                          GetAttributeHandler(e.target.value,1);
                        }
                      }}
                    >
                      {pageStates?.masterdrop?.map((i) => {
                        return (
                          <MenuItem value={i.columnvalue} key={i.columnvalue}>
                            {i.displayvalue}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </td>

                {othermast && (
                  <>
                    <td>Other Master</td>
                    <td> : </td>
                    <td>
                      <FormControl className={modules["form-control"]}>
                        <Select
                          IconComponent={ArrowLine}
                          className={modules["dropdownSelect"]}
                          value={mainDropdowns.othermastervalue}
                          onChange={(e) => {
                            setMainDropdowns({
                              ...mainDropdowns,
                              othermastervalue: e.target.value,
                            });
                            GetAttributeHandler(e.target.value,2);
                          }}
                        >
                          {pageStates?.othermasterdrop?.map((i) => {
                            return (
                              <MenuItem value={i.tablename} key={i.tablename}>
                                {i.masterdisplayname}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </td>
                  </>
                )}
              </tr>

              <tr>
                <td>Attribute</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={mainDropdowns.attributevalue}
                      onChange={(e) =>
                        setMainDropdowns({
                          ...mainDropdowns,
                          attributevalue: e.target.value,
                        })
                      }
                    >
                      {pageStates?.attributedrop?.map((i) => {
                        return (
                          <MenuItem
                            value={i.attributecolumnname}
                            key={i.attributecolumnname}
                          >
                            {i.attributedisplayname}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>

        <div>
          <button
            style={{
              marginLeft: "13.5rem",
              marginTop: "1.2rem",
              padding: "0.5cm",
              marginBottom: "1.6rem",
            }}
            type="button"
            className={modules["btn1"]}
            onClick={handleSubmit}
            Table
          >
            Submit
          </button>
        </div>
      </Grid>

      {/* user table */}
      {displayTable && (
        <Grid container className={modules["table-grid"]}>
          <div className={modules["table-container"]}>
            <Table className={modules["custom-table"]}>
              <TableHead>
                <TableRow>
                  <th>Prioity Number</th>
                  <th>Masters</th>
                  <th>Attribute1</th>
                  <th>Attribute2</th>
                  <th>Attribute3</th>
                  <th>Attribute4</th>
                  <th>Attribute5</th>
                  <th>Attribute6</th>
                  <th>Attribute7</th>
                  <th>Attribute8</th>
                  <th>Attribute9</th>
                  <th>Edit</th>
                </TableRow>
              </TableHead>

              <TableBody>
                {console.log(tableeditstates)}
                {myArray?.map((item, index) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className={modules["table-cell"]}>
                          {index + 1}
                        </TableCell>

                        <TableCell className={modules["table-cell"]}>
                         {
                          tableeditstates[index]?.editstate? 

                         (<FormControl className={modules["form-control"]}>
                         <Select
                           IconComponent={ArrowLine}
                           className={modules["dropdownSelect2"]}
                         //   value={mastertabledrpsubmitvalue}
                         // value={'DSS_t_invoice_data'}
                        //  value={submitData[index]?.attributetablename?submitData[index]?.attributetablename:''}
                        value={tableeditstates[index].attributetablename}
                         onChange={(e) => handleMasterDropChange(index,'attributetablename',e)}
                           disabled={false}
                         >
                     
                           {mastertabledrpsubmit?.map((i) => {
                             return (
                               <MenuItem
                                 value={i.tablename}
                                 key={i.tablename}
                               >
                                 {i.masterdisplayname}
                               </MenuItem>
                             );
                           })}
                         </Select>
                         </FormControl>) :

                         (<FormControl className={modules["form-control"]}>
                         <Select
                           IconComponent={ArrowLine}
                           className={modules["dropdownSelect2"]}
                         //   value={mastertabledrpsubmitvalue}
                         // value={'DSS_t_invoice_data'}
                         value={submitData[index]?.attributetablename?submitData[index]?.attributetablename:''}
                         onChange={(e) => setDisplayValue(e.target.value)}
                           disabled={true}
                         >
                     
                           {mastertabledrpsubmit?.map((i) => {
                             return (
                               <MenuItem
                                 value={i.tablename}
                                 key={i.tablename}
                               >
                                 {i.masterdisplayname}
                               </MenuItem>
                             );
                           })}
                         </Select>
                         </FormControl>)
                         }
                        </TableCell>

                        <TableCell className={modules["table-cell"]}>
                          {
                            tableeditstates[index]?.editstate? 
                            <FormControl className={modules["form-control"]}>
                            <Select
                              IconComponent={ArrowLine}
                              className={modules["dropdownSelect2"]}
                              value={tableeditstates[index].attributecolumnname1}
                              onChange={(e) => handleDropChange(index,'attributecolumnname1',e)}
                              disabled={false}
                              
                            >
                                <MenuItem value={tableeditstates[index].attributecolumnname1}>{tableeditstates[index].attributecolumnname1}</MenuItem>
                                {console.log('tab;eeee',tableeditstates)}
                              {tableeditstates[index]?.attributedropdown?.map((i) => {
                                return (
                                    <MenuItem
                                    value={i.attributecolumnname}
                                    key={i.attributecolumnname}
                                  >
                                    {i.attributedisplayname}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl> :
                          <FormControl className={modules["form-control"]}>
                          <Select
                            IconComponent={ArrowLine}
                            className={modules["dropdownSelect2"]}
                            value={submitData[index]?.attributecolumnname1?submitData[index]?.attributecolumnname1:''}
                            onChange={(e) => setDisplayValue(e.target.value)}
                            disabled={true}
                          >
                              <MenuItem value={submitData[index]?.attributecolumnname1?submitData[index]?.attributecolumnname1:''}>{submitData[index]?.attributecolumnname1?submitData[index]?.attributecolumnname1:''}</MenuItem>
                            {submittable?.dropvalues.map((i) => {
                              return (
                                  <MenuItem
                                  value={i.attributecolumnname}
                                  key={i.attributecolumnname}
                                >
                                  {i.attributedisplayname}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                          

                          }
                          
                        </TableCell>

                        <TableCell className={modules["table-cell"]}>
                          {
                            tableeditstates[index]?.editstate? 
                            <FormControl className={modules["form-control"]}>
                            <Select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelect2"]}
                                value={tableeditstates[index].attributecolumnname2}
                                onChange={(e) => handleDropChange(index,'attributecolumnname2',e)}
                                disabled={false}
                              >
                            <MenuItem value={tableeditstates[index].attributecolumnname2}>{tableeditstates[index].attributecolumnname2}</MenuItem>                                {tableeditstates[index]?.attributedropdown?.map((i) => {
                                  return (
                                      <MenuItem
                                      value={i.attributecolumnname}
                                      key={i.attributecolumnname}
                                    >
                                      {i.attributedisplayname}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            :
                            <FormControl className={modules["form-control"]}>
                            <Select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelect2"]}
                                value={submitData[index]?.attributecolumnname2?submitData[index]?.attributecolumnname2:''}
                                onChange={(e) => setDisplayValue(e.target.value)}
                                disabled={true}
                              >
                                  <MenuItem value={submitData[index]?.attributecolumnname2?submitData[index]?.attributecolumnname2:''}>{submitData[index]?.attributecolumnname2?submitData[index]?.attributecolumnname2:''}</MenuItem>
                                {submittable?.dropvalues.map((i) => {
                                  return (
                                      <MenuItem
                                      value={i.attributecolumnname}
                                      key={i.attributecolumnname}
                                    >
                                      {i.attributedisplayname}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          }
                         
                        </TableCell>

                        <TableCell className={modules["table-cell"]}>
                          {
                            tableeditstates[index]?.editstate? 
                            <FormControl className={modules["form-control"]}>
                            <Select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelect2"]}
                                value={tableeditstates[index].attributecolumnname3}
                                onChange={(e) => handleDropChange(index,'attributecolumnname3',e)}
                                disabled={false}
                              >
<MenuItem value={tableeditstates[index].attributecolumnname3}>{tableeditstates[index].attributecolumnname3}</MenuItem>                                {tableeditstates[index]?.attributedropdown?.map((i) => {
                                  return (
                                      <MenuItem
                                      value={i.attributecolumnname}
                                      key={i.attributecolumnname}
                                    >
                                      {i.attributedisplayname}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            :
                            <FormControl className={modules["form-control"]}>
                          <Select
                              IconComponent={ArrowLine}
                              className={modules["dropdownSelect2"]}
                              value={submitData[index]?.attributecolumnname3?submitData[index]?.attributecolumnname3:''}
                              disabled={true}
                              onChange={(e) => setDisplayValue(e.target.value)}
                            >
                                <MenuItem value={submitData[index]?.attributecolumnname3?submitData[index]?.attributecolumnname3:''}>{submitData[index]?.attributecolumnname3?submitData[index]?.attributecolumnname3:''}</MenuItem>
                              {submittable?.dropvalues.map((i) => {
                                return (
                                    <MenuItem
                                    value={i.attributecolumnname}
                                    key={i.attributecolumnname}
                                  >
                                    {i.attributedisplayname}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          }
                          
                        </TableCell>

                        <TableCell className={modules["table-cell"]}>
                          {
                            tableeditstates[index]?.editstate? 

                            <FormControl className={modules["form-control"]}>
                          <Select
                              IconComponent={ArrowLine}
                              className={modules["dropdownSelect2"]}
                              value={tableeditstates[index].attributecolumnname4}
                              onChange={(e) => handleDropChange(index,'attributecolumnname4',e)}
                              disabled={false}
                            >
                                <MenuItem value={tableeditstates[index].attributecolumnname4}>{tableeditstates[index].attributecolumnname4}</MenuItem>
                              {tableeditstates[index]?.attributedropdown?.map((i) => {
                                return (
                                    <MenuItem
                                    value={i.attributecolumnname}
                                    key={i.attributecolumnname}
                                  >
                                    {i.attributedisplayname}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                            :

                            <FormControl className={modules["form-control"]}>
                          <Select
                              IconComponent={ArrowLine}
                              className={modules["dropdownSelect2"]}
                              value={submitData[index]?.attributecolumnname4?submitData[index]?.attributecolumnname4:''}
                              onChange={(e) => setDisplayValue(e.target.value)}
                              disabled={true}
                            >
                                <MenuItem value={submitData[index]?.attributecolumnname4?submitData[index]?.attributecolumnname4:''}>{submitData[index]?.attributecolumnname4?submitData[index]?.attributecolumnname4:''}</MenuItem>
                              {submittable?.dropvalues.map((i) => {
                                return (
                                    <MenuItem
                                    value={i.attributecolumnname}
                                    key={i.attributecolumnname}
                                  >
                                    {i.attributedisplayname}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          }
                          
                        </TableCell>

                        <TableCell className={modules["table-cell"]}>
                          {
                            tableeditstates[index]?.editstate? 

                            <FormControl className={modules["form-control"]}>
                            <Select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelect2"]}
                                value={tableeditstates[index].attributecolumnname5}
                              onChange={(e) => handleDropChange(index,'attributecolumnname5',e)}
                                disabled={false}
                              >
<MenuItem value={tableeditstates[index].attributecolumnname5}>{tableeditstates[index].attributecolumnname5}</MenuItem>                                {tableeditstates[index]?.attributedropdown?.map((i) => {
                                  return (
                                      <MenuItem
                                      value={i.attributecolumnname}
                                      key={i.attributecolumnname}
                                    >
                                      {i.attributedisplayname}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            :
                            <FormControl className={modules["form-control"]}>
                            <Select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelect2"]}
                                value={submitData[index]?.attributecolumnname5?submitData[index]?.attributecolumnname5:''}
                                onChange={(e) => setDisplayValue(e.target.value)}
                                disabled={true}
                              >
                                  <MenuItem value={submitData[index]?.attributecolumnname5?submitData[index]?.attributecolumnname5:''}>{submitData[index]?.attributecolumnname5?submitData[index]?.attributecolumnname5:''}</MenuItem>
                                {submittable?.dropvalues.map((i) => {
                                  return (
                                      <MenuItem
                                      value={i.attributecolumnname}
                                      key={i.attributecolumnname}
                                    >
                                      {i.attributedisplayname}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          }

                         
                        </TableCell>

                        <TableCell className={modules["table-cell"]}>
                          {
                            tableeditstates[index]?.editstate? 
                            <FormControl className={modules["form-control"]}>
                          <Select
                              IconComponent={ArrowLine}
                              className={modules["dropdownSelect2"]}
                              value={tableeditstates[index].attributecolumnname6}
                              onChange={(e) => handleDropChange(index,'attributecolumnname6',e)}
                              disabled={false}
                            >
<MenuItem value={tableeditstates[index].attributecolumnname6}>{tableeditstates[index].attributecolumnname6}</MenuItem>
                              {tableeditstates[index]?.attributedropdown?.map((i) => {
                                return (
                                    <MenuItem
                                    value={i.attributecolumnname}
                                    key={i.attributecolumnname}
                                  >
                                    {i.attributedisplayname}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                            :

                            <FormControl className={modules["form-control"]}>
                          <Select
                              IconComponent={ArrowLine}
                              className={modules["dropdownSelect2"]}
                              value={submitData[index]?.attributecolumnname6?submitData[index]?.attributecolumnname6:''}
                              onChange={(e) => setDisplayValue(e.target.value)}
                              disabled={true}
                            >
                                <MenuItem value={submitData[index]?.attributecolumnname6?submitData[index]?.attributecolumnname6:''}>{submitData[index]?.attributecolumnname6?submitData[index]?.attributecolumnname6:''}</MenuItem>
                              {submittable?.dropvalues.map((i) => {
                                return (
                                    <MenuItem
                                    value={i.attributecolumnname}
                                    key={i.attributecolumnname}
                                  >
                                    {i.attributedisplayname}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          }
                          
                        </TableCell>

                        <TableCell className={modules["table-cell"]}>
                          {
                            tableeditstates[index]?.editstate? 
                            <FormControl className={modules["form-control"]}>
                            <Select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelect2"]}
                                value={tableeditstates[index].attributecolumnname7}
                              onChange={(e) => handleDropChange(index,'attributecolumnname7',e)}
                                disabled={false}
                              >
<MenuItem value={tableeditstates[index].attributecolumnname7}>{tableeditstates[index].attributecolumnname7}</MenuItem>                                
{tableeditstates[index]?.attributedropdown?.map((i) => {
                                  return (
                                      <MenuItem
                                      value={i.attributecolumnname}
                                      key={i.attributecolumnname}
                                    >
                                      {i.attributedisplayname}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            :

                            <FormControl className={modules["form-control"]}>
                            <Select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelect2"]}
                                value={submitData[index]?.attributecolumnname7?submitData[index]?.attributecolumnname7:''}
                                onChange={(e) => setDisplayValue(e.target.value)}
                                disabled={true}
                              >
                                  <MenuItem value={submitData[index]?.attributecolumnname7?submitData[index]?.attributecolumnname7:''}>{submitData[index]?.attributecolumnname7?submitData[index]?.attributecolumnname7:''}</MenuItem>
                                {submittable?.dropvalues.map((i) => {
                                  return (
                                      <MenuItem
                                      value={i.attributecolumnname}
                                      key={i.attributecolumnname}
                                    >
                                      {i.attributedisplayname}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          }
                         
                        </TableCell>

                        <TableCell className={modules["table-cell"]}>
                          {
                            tableeditstates[index]?.editstate? 

                            <FormControl className={modules["form-control"]}>
                            <Select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelect2"]}
                                value={tableeditstates[index].attributecolumnname8}
                                onChange={(e) => handleDropChange(index,'attributecolumnname8',e)}
                                disabled={false}
                              >
                                  <MenuItem value={tableeditstates[index].attributecolumnname8}>{tableeditstates[index].attributecolumnname8}</MenuItem>
                                {tableeditstates[index]?.attributedropdown?.map((i) => {
                                  return (
                                      <MenuItem
                                      value={i.attributecolumnname}
                                      key={i.attributecolumnname}
                                    >
                                      {i.attributedisplayname}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            :

                            <FormControl className={modules["form-control"]}>
                            <Select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelect2"]}
                                value={submitData[index]?.attributecolumnname8?submitData[index]?.attributecolumnname8:''}
                                onChange={(e) => setDisplayValue(e.target.value)}
                                disabled={true}
                              >
                                  <MenuItem value={submitData[index]?.attributecolumnname8?submitData[index]?.attributecolumnname8:''}>{submitData[index]?.attributecolumnname8?submitData[index]?.attributecolumnname8:''}</MenuItem>
                                {submittable?.dropvalues.map((i) => {
                                  return (
                                      <MenuItem
                                      value={i.attributecolumnname}
                                      key={i.attributecolumnname}
                                    >
                                      {i.attributedisplayname}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          }
                         
                        </TableCell>

                        <TableCell className={modules["table-cell"]}>
                          {
                            tableeditstates[index]?.editstate? 
                            <FormControl className={modules["form-control"]}>
                          <Select
                              IconComponent={ArrowLine}
                              className={modules["dropdownSelect2"]}
                              value={tableeditstates[index].attributecolumnname9}
                              onChange={(e) => handleDropChange(index,'attributecolumnname9',e)}
                              disabled={false}
                            >
                               <MenuItem value={tableeditstates[index].attributecolumnname9}>{tableeditstates[index].attributecolumnname9}</MenuItem>
                              {tableeditstates[index]?.attributedropdown?.map((i) => {
                                return (
                                    <MenuItem
                                    value={i.attributecolumnname}
                                    key={i.attributecolumnname}
                                  >
                                    {i.attributedisplayname}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                            :
                            <FormControl className={modules["form-control"]}>
                          <Select
                              IconComponent={ArrowLine}
                              className={modules["dropdownSelect2"]}
                              value={submitData[index]?.attributecolumnname9?submitData[index]?.attributecolumnname9:''}
                              onChange={(e) => setDisplayValue(e.target.value)}
                              disabled={true}
                            >
                                <MenuItem value={submitData[index]?.attributecolumnname9?submitData[index]?.attributecolumnname9:''}>{submitData[index]?.attributecolumnname9?submitData[index]?.attributecolumnname9:''}</MenuItem>
                              {submittable?.dropvalues.map((i) => {
                                return (
                                    <MenuItem
                                    value={i.attributecolumnname}
                                    key={i.attributecolumnname}
                                  >
                                    {i.attributedisplayname}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          }
                          
                        </TableCell>

                        <TableCell className={modules["table-cell"]}>
  {tableeditstates[index]?.editstate ? (
    <div>
      <div>
        <a onClick={() => UpdateRowHandler(item, index)}>Update</a>
      </div>
      <div>
        <a onClick={() => CancelHandler(index)}>Cancel</a>
      </div>
      <div>
        <a onClick={() => DeleteHandler(item, index)}>Delete</a>
      </div>
    </div>
  ) : (
    <a onClick={() => EditHandler(index)}>Edit</a>
  )}
</TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </Grid>
      )}
    </>
  );
}
