import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import USP1 from "../HelpPopups/images/USP1.png";
import USP2 from "../HelpPopups/images/USP2.png";
import USP3 from "../HelpPopups/images/USP3.png";
// import NI4 from "../HelpPopups/images/NI4.png";
import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const UserStockPointLinkinghelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h2 style={{ marginLeft: "5rem", textAlign: "center" }}>
                                USER STOCK POINT LINKING


                            </h2>
                            <br />
                            <br />
                            <li className="no-bullets">
                                Session Path `{">>"}` MTA `{">>"}` MTA Parameter `{">>"}` User Stock Point Linking.
                            </li>
                            <br />
                            <br />
                            <li className="no-bullets">
                                User Stock Point Linking is used for assigning Stock Point to User for a particular instance ID.
                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={USP1} alt="USP1" />
                            </Stack>
                            <br />

                            <br />


                            <li className="no-bullets">
                                ·         Select Instance UID

                                ·         Select User Id

                                ·         There are two options available, namely;

                                o   Access to all Stock Points

                                o   Access to selected Stock Points.
                                Access to all Stock Points
                                If you select first option Access to all Stock Points - userid is assigned with all Stock Points available for that instance.
                            </li>



                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={USP2} alt="USP2" />
                            </Stack>
                            <br />

                            <li className="no-bullets">
                                o   Access to selected Stock Points.
                                In second option only stock points for that instance is shown and you can assign selected stock point to the user id.
                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={USP3} alt="USP3" />
                            </Stack>
                            <br />
                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default UserStockPointLinkinghelpPopup;
