import React, { useEffect } from "react";
import bread from "../../Assets/bread.png";
import Home from "../../home/Home";
import { Grid, Stack, Typography } from "@mui/material";
import PlusIcon from "../../Assets/plus.png";
import MinusIcon from "../../Assets/minus.png";
import classes from "./Admin.module.css";
import { useState } from "react";
import Navbar from "../../Landing/Navbar";
import {
  RP_GetDropDownData,
  RP_GetHomePageData,
  RP_GetRoleDropdown,
  RP_GetSubList,
  RP_GetSubList2,
  RP_UpdateHandler,
  RP_ExportExcel,
} from "../../Services/Admin";
import Sidebar from "../../Landing/Navbar";
import { API_KEY, ExportToExcel } from "../../Constants";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import SampleSidebar from "../../Landing/SampleSidebar";
import loading from "../../Assets/Loading_image.gif";

export default function RolePermission() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [DropData, setDropDown] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [roleId, setRoleId] = useState(-1);
  const [sublist1, setSublist1] = useState([]);
  const [subMunu2, setSubMunu2] = useState([]);
  const [subMenuItems, setSubMenuItems] = useState({});
  const [checkedIds, setCheckedIds] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [expandedMenu, setExpandedMenu] = useState([]);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [expandedLast, setExpandedLast] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tempName, setTempName] = useState("");
  const checkId = searchParams.get("roleid");

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();

    setIsLoading(true);
    RP_GetDropDownData().then((res) => {
      if (res?.data?.statusCode === 200) {
        setIsLoading(false);
        const roles = res?.data?.success?.data;
        setDropDown(res?.data?.success?.data);
        if (checkId) {
          const matchedRole = roles.find(
            (role) => role.id === parseInt(checkId, 10)
          );
          if (matchedRole) {
            setRoleId(matchedRole.id);
            setTempName(matchedRole.rolename);
            getRoleHandler(matchedRole.id);
          }
        }
      }
    });

    RP_GetHomePageData().then((res) => {
      if (res?.data?.statusCode === 200) {
        // console.log(res?.data?.success?.data, "homepage data");
      }
    });
  }, []);

  function getFilenameFromHeader(header) {
    if (header) {
      const disposition = header.split(";");
      for (let part of disposition) {
        if (part.trim().startsWith("filename=")) {
          return part.split("=")[1].replace(/"/g, "");
        }
      }
    }
    return null;
  }

  const DownloadHandler = async () => {
    const body = {
      roleid: selectedRoleId,
    };

    if (selectedRoleId !== -1) {
      try {
        setIsLoading(true);
        const response = await RP_ExportExcel(body);

        if (response && response.data) {
          setIsLoading(false);
          let data = response.data;
          if (data instanceof ArrayBuffer) {
            data = new Uint8Array(data);
          }

          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const contentDisposition = response.headers["content-disposition"];
          const filename =
            getFilenameFromHeader(contentDisposition) || "Rolepermission.xlsx";

          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          setIsLoading(false);
          console.error("No Data found");
        }
      } catch (err) {
        setIsLoading(false);
        console.error("Error downloading file:", err);
      }
    }
  };

  function getRoleHandler(val) {
    setIsLoading(true);
    if (roleId !== -1 && val !== -1) {
      NavigateHandler(val);
    }
    setSelectedRoleId(val);
    if (val !== -1) {
      setRoleId(val);
      const body = {
        roleId: `${val}`,
      };
      RP_GetRoleDropdown(body)
        .then((res) => {
          setSublist1(res?.data?.success?.data);
          const jsonData = res?.data?.success?.data;
          const headingIds = jsonData.map((item) => item.headingid);
          const body = {
            roleID: `${val}`,
            mainMenuID: headingIds,
          };
          RP_GetSubList(body)
            .then((res) => {
              if (res.status === 200) {
                const apiResponse = res?.data?.success?.data;
                const initialCheckedIds = apiResponse
                  .flat()
                  .filter((item) => item.view === 1)
                  .map((item) => item.id);
                setCheckedIds(initialCheckedIds);
                setSubMenuItems(apiResponse);
                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log("err", err);
              setIsLoading(false);
            });
        })
        .catch((err) => {
          alert("Error While fetching data", err);
          setIsLoading(false);
        });
    }
  }

  function SaveHandler() {
    setIsLoading(true);
    const roleID = selectedRoleId;
    const userID = sessionStorage.getItem("loginId");
    const homePage = 0;

    const subMenuLookup = subMenuItems.reduce((acc, currArray) => {
      currArray.forEach((item) => {
        if (!acc[item.headingid]) {
          acc[item.headingid] = [];
        }
        acc[item.headingid].push(item);
      });
      return acc;
    }, {});

    const subMenu2Lookup = subMunu2.reduce((acc, item) => {
      if (!acc[item.headingid]) {
        acc[item.headingid] = [];
      }
      acc[item.headingid].push(item);
      return acc;
    }, {});

    const temp = new Set(
      Object.keys(checkedItems).filter((id) => checkedItems[id] === true)
    );
    const checkedItemsLookup2 = Array.from(temp).map(Number);

    const transformData = () => {
      return {
        roleID: roleID,
        userID: userID,
        homePage: homePage,
        mod: sublist1.map((menuItem) => {
          const menuIdL1 = menuItem.headingid;

          const mod2 =
            subMenuLookup[menuIdL1]?.map((subMenuItem) => {
              const menuId2 = subMenuItem.id;
              const isViewRights = checkedIds.includes(menuId2) ? 1 : 0;

              const mod3 =
                subMenu2Lookup[menuId2]?.map((subSubMenuItem) => {
                  return {
                    menuId3: subSubMenuItem.id,
                    isViewRights: checkedItemsLookup2.includes(
                      subSubMenuItem.id
                    )
                      ? 1
                      : 0,
                  };
                }) || [];

              return {
                menuId2: menuId2,
                isViewRights: isViewRights,
                mod3: mod3,
              };
            }) || [];

          return {
            menuIdL1: menuIdL1,
            mod2: mod2,
          };
        }),
      };
    };

    const body = transformData();

    RP_UpdateHandler(body)
      .then((res) => {
        if (res.status === 200) {
          alert("Data saved successfully!");
          NavigateHandler(roleID);
          setIsLoading(false);
        } else {
          alert(res.response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        alert("Error while saving data:", err);
        setIsLoading(false);
      });
  }

  function NavigateHandler(id) {
    setExpandedMenu([]);
    setExpandedItemId(null);
    setTempName("");
  }

  const onSubMenuClick2 = (s_id) => {
    if (expandedLast === s_id) {
      setExpandedLast(null);
    } else {
      setExpandedLast(s_id);
    }
  };

  const onClickHandler = (headingId) => {
    if (expandedMenu.includes(headingId)) {
      setExpandedMenu(expandedMenu.filter((id) => id !== headingId));
    } else {
      setExpandedMenu([...expandedMenu, headingId]);
    }
  };

  const onSubMenuClick = (s_id) => {
    const body = {
      menuIdList: [
        {
          menuid: [s_id],
        },
      ],
      roleID: selectedRoleId,
    };
    RP_GetSubList2(body)
      .then((res) => {
        setSubMunu2(res?.data?.success?.data[0][0]);
        const fetchedData = res?.data?.success?.data[0][0] || [];
        const updatedCheckedItems = fetchedData.reduce((acc, item) => {
          acc[item.id] = item.view === 1;
          return acc;
        }, {});
        setCheckedItems(updatedCheckedItems);
      })
      .catch((err) => {
        alert("Error fetching data", err);
      });

    if (expandedItemId === s_id) {
      setExpandedItemId(null);
    } else {
      setExpandedItemId(s_id);
    }
  };

  const handleCheckboxChange = (itemId, isChecked) => {
    setCheckedItems((prev) => ({
      ...prev,
      [itemId]: isChecked,
    }));
  };

  const handleCheckboxChange1 = (id, isSelected) => {
    setCheckedIds((prevCheckedIds) => {
      if (isSelected) {
        return [...prevCheckedIds, id];
      } else {
        return prevCheckedIds.filter((checkedId) => checkedId !== id);
      }
    });
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={classes["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} sx={{ position: "fixed", zIndex: 2000 }}>
          {/* <Sidebar /> */}
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className="home-cont">
            <Home page={"rolePermission"} />

            <Grid
              sx={{
                width: "70%",
                marginLeft: "9.3rem",
                marginTop: "0.2rem",
              }}
            >
              <div className={classes["pro-bread"]}>
                <p>
                  <a>Admin</a>
                  <img src={bread} alt="" />
                  <a>Role Permissions</a>
                </p>
              </div>
              <Stack
                spacing={2}
                sx={{
                  marginTop: "1rem",
                }}
                direction="row"
                alignItems="center"
              >
                <Grid item lg={1} className={classes["bodyFont"]}>
                  Filters:
                </Grid>
                <Grid alignItems="center" container sx={{ width: "56%" }}>
                  <Grid className={classes["bodyFont"]}>Role Name :</Grid>
                  <Grid sx={{ marginLeft: "5px" }}>
                    <select
                      onChange={(e) => getRoleHandler(e.target.value)}
                      className={classes["dropdownSelect1"]}
                      value={roleId}
                    >
                      {checkId && <option value={-2}>{tempName}</option>}
                      <option value={checkId ? -2 : -1} hidden>
                        SELECT
                      </option>
                      {DropData?.map((ele) => {
                        if (ele.id === parseInt(checkId, 10)) {
                          return null;
                        }
                        return (
                          <option
                            key={ele.id}
                            value={ele.id}
                            selected={
                              ele.id === sessionStorage.getItem("roleId")
                                ? "selected"
                                : ""
                            }
                          >
                            {ele.rolename}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                </Grid>
                <Grid alignItems="center" container>
                  <Typography className={classes["bodyFont"]}>
                    Set as Home page :
                  </Typography>
                  <input className={classes["disableInput"]} disabled />{" "}
                </Grid>
              </Stack>
              {sublist1.length > 0 ? (
                <>
                  <Grid
                    sx={{
                      maxWidth: "100%",
                      marginTop: "1cm",
                    }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        width: "80%",
                        marginLeft: "4rem",
                      }}
                    >
                      <li className={classes["list-header"]}>Permissions</li>
                      {sublist1?.map((ele, index) => {
                        return (
                          <>
                            <li className={classes["list-item"]}>
                              <Stack direction="row">
                                <img
                                  src={
                                    expandedMenu.includes(ele.headingid)
                                      ? MinusIcon
                                      : PlusIcon
                                  }
                                  alt="icon"
                                  onClick={() => {
                                    onClickHandler(ele.headingid);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                                <span className={classes["bodyFont2"]}>
                                  {ele.menuname}
                                </span>
                              </Stack>
                            </li>
                            {expandedMenu.includes(ele.headingid) && (
                              <>
                                <li className={classes["drop-down-list"]}>
                                  MenuName{" "}
                                  <span style={{ marginLeft: "5cm" }}>
                                    Enable Access
                                  </span>
                                </li>

                                {subMenuItems[index].map((i, idx) => {
                                  const isChecked = checkedIds.includes(i.id);
                                  const isLastSubItem = idx === subMenuItems[index].length - 1;
                                  return (
                                    <>
                                      <li
                                         className={`${classes["list-item"]} ${isLastSubItem ? classes["list-item-last"] : ""}`}
                                        key={idx}
                                      >
                                        <Stack direction="row">
                                          <img
                                            src={
                                              expandedItemId === i.id
                                                ? MinusIcon
                                                : PlusIcon
                                            }
                                            alt="icon"
                                            onClick={() => {
                                              onSubMenuClick(i.id);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          />
                                          <span className={classes["bodyFont2"]}>
                                            {i.menuname}
                                          </span>
                                        </Stack>

                                        {ele.headingid !== 72 && (
                                          <input
                                            style={{ marginLeft: "65px" }}
                                            type="checkbox"
                                            className={
                                              classes["custom-checkbox"]
                                            }
                                            value={i.id}
                                            onChange={(e) =>
                                              handleCheckboxChange1(
                                                i.id,
                                                e.target.checked
                                              )
                                            }
                                            checked={isChecked}
                                            disabled={i.isenabled === 0}
                                          />
                                        )}
                                      </li>
                                      {expandedItemId === i.id && (
                                        <>
                                          <li
                                            className={
                                              classes["drop-down-list"]
                                            }
                                          >
                                            MenuName{" "}
                                            <span style={{ marginLeft: "5cm" }}>
                                              Enable access
                                            </span>
                                          </li>
                                          {subMunu2.length > 0 &&
                                            subMunu2.map((itm, idx) => {
                                              const isLastSubSubItem = idx === subMunu2.length - 1;
                                              return (
                                                <>
                                                  <li
                                                    className={`${classes["list-item"]} ${isLastSubSubItem ? classes["list-item-last"] : ""}`}
                                                    // key={itm.id}
                                                  >
                                                    <Stack direction="row">
                                                      <img
                                                        src={
                                                          expandedLast ===
                                                          itm.id
                                                            ? MinusIcon
                                                            : PlusIcon
                                                        }
                                                        alt="icon"
                                                        onClick={() => {
                                                          onSubMenuClick2(
                                                            itm.id
                                                          );
                                                        }}
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                      <span
                                                        className={
                                                          classes["bodyFont2"]
                                                        }
                                                      >
                                                        {itm.menuname}
                                                      </span>
                                                    </Stack>

                                                    <input
                                                      type="checkbox"
                                                      style={{
                                                        marginLeft: "65px",
                                                      }}
                                                      className={
                                                        classes[
                                                          "custom-checkbox"
                                                        ]
                                                      }
                                                      value={itm.id}
                                                      onChange={(e) =>
                                                        handleCheckboxChange(
                                                          itm.id,
                                                          e.target.checked
                                                        )
                                                      }
                                                      checked={
                                                        checkedItems[itm.id] ||
                                                        false
                                                      }
                                                      disabled={
                                                        itm.isenabled === 0
                                                      }
                                                    />
                                                  </li>
                                                  {expandedLast === itm.id && (
                                                    <>
                                                      <li
                                                        className={
                                                          classes["list-item"]
                                                        }
                                                        style={{
                                                          minHeight: "1cm",
                                                          color: "red",
                                                          fontSize: "12px",
                                                          fontWeight: "bold",
                                                          paddingLeft: "49%",
                                                        }}
                                                      >
                                                        No Records Found
                                                      </li>
                                                    </>
                                                  )}
                                                </>
                                              );
                                            })}
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </Grid>
                  <Stack
                    direction="row"
                    sx={{
                      marginTop: "1rem",
                      marginLeft: "4rem",
                      marginBottom: "2rem",
                    }}
                    spacing={2}
                  >
                    <button
                      className={classes["btn-normal"]}
                      onClick={SaveHandler}
                    >
                      Save
                    </button>
                    <button
                      className={classes["btn-normal"]}
                      onClick={() => NavigateHandler(roleId)}
                    >
                      Cancel
                    </button>
                    <button
                      className={classes["btn-normal"]}
                      onClick={DownloadHandler}
                    >
                      Export to Excel
                    </button>
                  </Stack>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* </Grid> */}
      </Grid>
    </>
  );
}
