// MenuContext.js
import React, { createContext, useState, useEffect } from "react";
import { GetMenuBar } from "../Services/Admin";

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [menuVisibility, setMenuVisibility] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userId = sessionStorage.getItem("loginId");
    if (userId !== null) {
      const cachedMenuData = sessionStorage.getItem("menuData");
      if (cachedMenuData) {
        setMenuVisibility(JSON.parse(cachedMenuData));
        setIsLoading(false);
      } else {
        const body = { userId: userId };

        GetMenuBar(body)
          .then((response) => {
            const responseData = response.data;
            if (responseData.statusCode === 200 && responseData.success) {
              const data = responseData.success.data;
              if (Array.isArray(data)) {
                const visibility = {};
                data.forEach((item) => {
                  const parentMenuName = item.parentmenuname?.trim();
                  if (parentMenuName) {
                    visibility[parentMenuName] = {
                      visibility: true,
                      subMenus: {},
                    };
                    item.pagenames.forEach((subItem) => {
                      const subMenuName = subItem.submenuname?.trim();
                      if (subMenuName) {
                        visibility[parentMenuName].subMenus[subMenuName] = {
                          visibility: true,
                          pages: subItem.pagename.reduce((acc, page) => {
                            acc[page.trim()] = true;
                            return acc;
                          }, {}),
                        };
                      }
                    });
                  }
                });
                if (visibility["CCPM"]?.subMenus["Masters"]) {
                  visibility["CCPM"].subMenus["Masters"].pages[
                    "Department Master"
                  ] = true;
                }

                if (visibility["CCPM"]?.subMenus["Reports"]) {
                  visibility["CCPM"].subMenus["Reports"].pages[
                    "Department Load Report"
                  ] = true;
                  visibility["CCPM"].subMenus["Reports"].pages[
                    "Planned Vs Actual"
                  ] = true;
                }

                setMenuVisibility(visibility);
                
                sessionStorage.setItem("menuData", JSON.stringify(visibility));
              } else {
                console.error("API response 'data' is not an array:", data);
              }
            } else {
              console.error("Unexpected API response format:", responseData);
            }
          })
          .catch((error) => {
            console.error("Error fetching menu data:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, []);

  return (
    <MenuContext.Provider value={{ menuVisibility, setMenuVisibility, isLoading }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => {
  return React.useContext(MenuContext);
};
