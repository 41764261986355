import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/CM1.jpg";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";
import RiskMonitorreport from "../CCPM/RiskManagement/RiskMonitorreport";
import RIMP1 from "../HelpPopups/images/RIMP1.jpg";
import RAMP3 from "../HelpPopups/images/RAMP3.jpg";
import RMR1 from "../HelpPopups/images/RMR1.jpg";
import RMR2 from "../HelpPopups/images/RMR2.jpg";
import RMR3 from "../HelpPopups/images/RMR3.jpg";
import RMR44 from "../HelpPopups/images/RMR44.jpg";
import RMR5 from "../HelpPopups/images/RMR5.jpg";
import RMR6 from "../HelpPopups/images/RMR6.jpg";
import RMR45 from "../HelpPopups/images/RMR45.jpg";
import HelpIndex from "./HelpIndex";
import TM1 from "../HelpPopups/images/TM1.jpg";
import TM2 from "../HelpPopups/images/TM2.jpg";
import TM3 from "../HelpPopups/images/TM3.jpg";
import TM4 from "../HelpPopups/images/TM4.jpg";
import TM5 from "../HelpPopups/images/TM5.jpg";
import TM6 from "../HelpPopups/images/TM6.jpg";
import TM7 from "../HelpPopups/images/TM7.jpg";
import TM8 from "../HelpPopups/images/TM8.jpg";
import TM9 from "../HelpPopups/images/TM9.jpg";
import TM10 from "../HelpPopups/images/TM10.jpg";
import TM11 from "../HelpPopups/images/TM11.jpg";
import TM12 from "../HelpPopups/images/TM12.jpg";
import TM13 from "../HelpPopups/images/TM13.png";
import TM14 from "../HelpPopups/images/TM14.png";
import TM15 from "../HelpPopups/images/TM15.png";
import TM16 from "../HelpPopups/images/TM16.png";
import TM17 from "../HelpPopups/images/TM17.jpg";
import TM18 from "../HelpPopups/images/TM18.png";
import TM19 from "../HelpPopups/images/TM19.jpg";
import TM20 from "../HelpPopups/images/TM20.png";
import TM21 from "../HelpPopups/images/TM21.png";
import TM22 from "../HelpPopups/images/TM22.jpg";
import TM23 from "../HelpPopups/images/TM23.jpg";
import TM24 from "../HelpPopups/images/TM24.jpg";
import TM25 from "../HelpPopups/images/TM25.jpg";
import TM26 from "../HelpPopups/images/TM26.jpg";
import TM27 from "../HelpPopups/images/TM27.jpg";
import TM28 from "../HelpPopups/images/TM28.jpg";





const TaskManagementModulehelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
    return (
        <>
        <div>
            <Stack
              direction="row"
              spacing={4}
              sx={{ paddingTop: "1rem" }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack sx={{ paddingLeft: "1rem", fontFamily: "'Times New Roman', Times, serif" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline", fontWeight: "bold" }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>
    
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={prostream} alt="Prostream" />
              </Stack>
            </Stack>
            {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
          </div>
    
          <Grid>
            <Grid container className="parent-container">
              <Grid item xs={12} className="child-container">
                
                  <h3 style={{ textDecoration: "underline", fontFamily:'Cablibri,serif',fontSize:'26px'}}>TaskManagement</h3>
                  <br />

                  <p>Task management is the process of Pre-Planning task as per the system priority to complete it in the shortest possible time.</p>
                  <br />

                 <p style={{fontSize:'18px',fontFamily:"sans-serif"}}> <strong>Session Path {'>>'}CCPM {'>>'} Task Management {'>>'} Task Management. </strong></p>
                 <br />

                 ❖  <span style={{textDecoration:'underline'}} >For the tasks to be appear in “Task Management” screen, following steps should be followed:-</span>
                  <ol>
                    <li>
                    Validating the created project and Network plan.
                    </li>
                    <li>
                    Applying the CCPM Settings.
                    </li>
                    <li>
                    Staggering / Scheduling the project.
                    </li>
                    <li>
                    Once the scheduling is done, user should make the project active by clicking the “Active” button present in the “My Project” screen.
                    </li>
                    <li>
                    Once the above process is completed, the tasks related to the projects available in the “Active Projects” screen can be seen in the task management screen.
                    </li>
                  </ol>
                  <br />

                  <Stack style={{ width: '623px', height: '285px' }} sx={{ paddingRight: "1rem" }}>
                    <img src={TM1} alt="TM1" />
                  </Stack>
                    
                  <br />

                    ❖ <span style={{textDecoration:'underline'}} > Following are the description of the functionalities present on the screen.</span>
                    <br />

                    <br />

                    
                   
                   
                          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               (TaskProcessNotes) :-when task manager clicks this link, software should show the process notes for task as maintained during network preparation.
                           </span>
                           <br />

                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               (UserNotes) :-User can use this for adding the notes regarding the selected tasks.<br></br>
For adding the notes, user has to enter the description in the “User Notes” text box and click on Save. The notes will get added to the grid with the details
                           </span>
                           <br />

                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               (Predecessor/Successor) :-With these buttons user can see the predecessor & successor tasks in the pop up window with all the relevant information.

 
                           </span>
                           <br />

                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               (Raise Issue) :-With this button user can raise the issue against any task.(More info can be added & screenshot to be changed due to error)
                           </span>
                           <br />

                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               (Task Update History) :-With this button user can see the list the changes done to the tasks. Eg. Changing task notes, updating task duration etc.
                           </span>
                           <br />

                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               () :-  The colors in the task name indicates the priorities of the task.
                               <br />

There are Eight different colors present for defining the priority of the tasks.
                           </span>
                           <br />


                            <li> Dark colors denotes the tasks are in critical chain.
                            </li>
                            <br />

                            <li>Light colors denotes that the tasks are in feeding chain.
                            </li>
                            <br />

                          <span>
                          Project Name -Name of the project is shown here in this column.
                          <br />


Department – Name of Department is shown here in this column
<br />


Task Manager – Name of the task manager is shown in this column. This colum is TOC
<br />

                           Color  coded based on the color coding logic
                           <br />


Task Name – In this column task name is shown.
                          </span>
                          <br />

                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               () :-This is the duration allotted to the task, which user can change or update according to the work done.
                               <br />

The changes done to the duration also changes the status of the task, after saving the changes done to the duration.
                           </span>
                           <br />

                            PO No – In  this production order no is shown.

                            <br />


Original Duration – This is task duration after applying CCPM. It mean it is a cut duration of the task.
<br />

 

Planned Start date – This indicate the planned start date of task
<br />

 

Planned End date – This indicates the planned End date of Task
<br />

 

Actual Start date - This indicates the Actual start date of Task
<br />

 

Projected Start date – This indicates the tentative start date of the task.
<br />

 

Projected / Actual End date – This indicates the Actual cum projected end date of the task.
<br />
          
                            
<span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               () :-This is the duration allotted to the task, which user can change or update according to the work done.
                               <br />

The changes done to the duration also changes the status of the task, after saving the changes done to the duration.

 
                           </span>
                           <br />


                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               () :-   Using this button user can save the changes done to the tasks.

 
                           </span>
                           <br />


                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               () :-    By ticking this checkbox, the task is marked as completed and the remaining duration changes to “0” also the status of the task changes to “Completed”.

 
                           </span>
                           <br />


                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               () :-        User can use this to maintain the status of the task and the initial status of the task will be “Planned”
                               <br />

If any changes done in the duration then status changes to “In Process”
<br />

If the duration of the task is changed to “0” or the chekbox of “Completed” is ticked, then the status changes to the “Completed”
<br />

 
                           </span>
                           <br />

                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '35px', height: '34px', aspectRatio: 'auto 39/41' }}>
                                    <img src={TM2} alt="TM2" />
                                </Stack>
                            </span>
                            <span>
                               () :-   User can select the reason for the delay if there is the delay done in the task.

 
                           </span>
                           <br />

                          
                        
                         
                          
                 
                   
              </Grid>
            </Grid>
          </Grid>
        </>
      );
};

export default TaskManagementModulehelpPopup;
