import React, { useEffect, useState } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import moduless from "./ChangeProjectManager.module.css";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import loading from "../../../Assets/Loading_image.gif";
import {
  GetProjectss,
  GetProjectManagerss,
  GetInstancesListt,
  GetTableDetailss,
  ChangePM,
} from "../../../Services/CCPM";

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const ChangeProjectManager = () => {

  const [isLoading, setIsLoading] = useState(false); //loading gif

  //display format for table
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  };

  useEffect(() => {
    setIsLoading(true);
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
    };
    GetInstancesListt(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setInstance(response.data.success.data);
          handleInstance(response.data.success.data[0].id); //select 1st instance by default
        }
        else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        }
        else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //WHEN INSTANCE SELECTED
  const handleInstance = (i) => {
    setIsLoading(true);
    setSelectedInstance(i);
    setSelectedProjectManager("Select");
    setSelectedProjectName("Select");
    setSelectedPM("Select");
    setTemp([]);
    setShowTable(false);
    setSstate({
      checkedRows: new Set(),
      allChecked: false,
    });

    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: i,
    };
    let body1 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: i,
      includeViewAccess: 0,
      isSpecificProjStatus: 0,
    };
    
    //GET MANAGERS AND MANAGER TO BE LIST
    GetProjectManagerss(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setProjectManager(response.data.success.data.ExistingProjectManager);
          setPM(response.data.success.data.ActiveUsre);
        }
        else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
          setProjectManager([])
          setPM([])
        }
        else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
          setProjectManager([])
          setPM([])
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
          setProjectManager([])
          setPM([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //GET PROJECT NAMES LIST
    GetProjectss(body1)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setProjectName(response.data.success.data);
        }
        else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
          setProjectName([])
        }
        else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
          setProjectName([])
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
          setProjectName([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECTED PROJECT NAME
  const handleProject = (i) => {
    setSelectedProjectName(i);
  };

  //SELECTED PROJECT MANAGER
  const handleProjectManager = (i) => {
    setSelectedProjectManager(i);
  };

  const [temp, setTemp] = useState([]); //detail table data
  const [showTable, setShowTable] = useState(false);

  //HANDLING CHECKED ROWS PART
  const [sstate, setSstate] = useState({
    checkedRows: new Set(),
    allChecked: false,
  });
  const handleCheckboxChange = (index) => {
    setSstate((prevState) => {
      const checkedRows = new Set(prevState.checkedRows);
      if (checkedRows.has(index)) {
        checkedRows.delete(index);
      } else {
        checkedRows.add(index);
      }
      const allChecked = checkedRows.size === temp.length;
      return { checkedRows, allChecked };
    });
  };
  const handleTableHeadCheckboxChange = () => {
    setSstate((prevState) => {
      const allChecked = !prevState.allChecked;
      const checkedRows = new Set();
      if (allChecked) {
        temp.forEach((_, index) => checkedRows.add(index));
      }
      return { checkedRows, allChecked };
    });
  };

  //TO PASS IDLIST TO API REQUEST BODY
  const getCheckedRows = () => {
    return temp
      .filter((_, index) => sstate.checkedRows.has(index))
      .map((item) => ({
        projectId: item.projectid,
        pmToBe: selectedPM,
        modifiedBy: parseInt(sessionStorage.getItem("loginId")),
      }));
  };

  //DROP DOWN VALUES
  const [instance, setInstance] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [projectManager, setProjectManager] = useState([]);
  const [PM, setPM] = useState([]);

  //SELECTED VALUES
  const [selectedInstance, setSelectedInstance] = useState("Select");
  const [selectedProjectName, setSelectedProjectName] = useState("Select");
  const [selectedProjectManager, setSelectedProjectManager] = useState("Select");
  const [selectedPM, setSelectedPM] = useState("Select");

  const handleSearch = () => {
    setIsLoading(true);
    setShowTable(true);
    setSstate({
      checkedRows: new Set(),
      allChecked: false,
    });
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: selectedInstance,
      projectId:
        selectedProjectName === "" || selectedProjectName === "Select"
          ? 0
          : selectedProjectName,
      pmId:
        selectedProjectManager === "" || selectedProjectManager === "Select"
          ? 0
          : selectedProjectManager,
    };
    GetTableDetailss(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setTemp(response?.data?.success?.data);
        }
        else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
          setTemp([])
        }
        else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
          setTemp([])
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
          setTemp([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReset = () => {
    handleInstance(instance[0].id);
  };

  const handleChangePM = () => {
    const confirmChange = window.confirm(
      `Are you sure you want to change Project Manager?`
    );
    if (confirmChange) {
      if (getCheckedRows().length === 0) {
        alert("Please select atleast one checkbox(Grid).");
        return;
      }
      else {
        setIsLoading(true);
        let body = {
          idList: getCheckedRows(),
        };
        ChangePM(body)
          .then((response) => {
            if (response.status === 200) {
              alert(response.data[0].message);
              setIsLoading(false);
              handleInstance(instance[0].id);
            }
            else if (response?.response?.data !== undefined) {
              alert(response?.response?.data);
              setIsLoading(false);
              handleInstance(instance[0].id);
            }
            else if (response?.response?.data?.message !== undefined) {
              setIsLoading(false);
              alert(response?.response?.data?.message);
              handleInstance(instance[0].id);
            }
            else if (response?.response?.data?.error?.message !== undefined) {
              setIsLoading(false);
              alert(response?.response?.data?.error?.message);
              handleInstance(instance[0].id);
            }
            else {
              setIsLoading(false);
              alert(response?.response?.data?.success?.message);
              handleInstance(instance[0].id);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      return;
    }
  };

  return (
    <div
      style={{ overflowX: showTable && temp.length > 0 ? "auto" : "hidden" }}
    >
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
          <Home page={"ChangeProjectManager"}/>
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>Project Plan</a>
                <img src={bread} alt="" />
                <a>Change Project Manager</a>
              </div>
            </div>

            <div container className={modules["parent-container-cpm"]}>
              <table className={modules["table-cpm"]}>
                <tbody style={{ width: "100%" }}>
                  <tr>
                    <td>
                      <span style={{ marginRight: "5px" }}>
                        <strong>Instance :</strong>
                      </span>
                      <select
                        className={modules["dropdownSelect1-apm"]}
                        value={selectedInstance}
                        onChange={(e) => handleInstance(e.target.value)}
                      >
                        {instance.length > 0 && instance?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <span style={{ marginRight: "5px" }}>
                        <strong>Project Name :</strong>
                      </span>
                      <select
                        IconComponent={ArrowLine}
                        className={modules["dropdownSelect1-apm"]}
                        value={selectedProjectName}
                        onChange={(e) => handleProject(e.target.value)}
                      >
                        {projectName.length > 0 &&
                          <option value="Select">SELECT</option>
                        }
                        {projectName.length > 0 && projectName?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.projectname}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <span style={{ marginRight: "5px" }}>
                        <strong>Project Manager :</strong>
                      </span>
                      <select
                        IconComponent={ArrowLine}
                        className={modules["dropdownSelect1-apm"]}
                        value={selectedProjectManager}
                        onChange={(e) => handleProjectManager(e.target.value)}
                      >
                        {projectManager.length > 0 &&
                          <option value="Select">SELECT</option>
                        }
                        {projectManager.length > 0 && projectManager?.map((i) => (
                          <option value={i.id} key={i.id}>
                            {i.username}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr
                    className={[
                      modules["btn1-cont-cpm"],
                      modules["button-container-cpm"],
                    ].join(" ")}
                  >
                    <button
                      className={[
                        modules["btn1-cpm"],
                        modules["button-container-button"],
                      ].join(" ")}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["btn2-cpm"]}
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>
            {showTable && temp.length > 0 && (
              <Grid
                container
                className={modules[("table-grid-cpm", "table-grid-margin")]}
              >
                <div className={modules["table-container-cp"]}>
                  <Table
                    className={[
                      modules["custom-table-cpm"],
                      modules["custom-table-th"],
                    ].join(" ")}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <input
                            type="checkbox"
                            checked={sstate.allChecked}
                            onChange={handleTableHeadCheckboxChange}
                            className={modules["custom-checkbox"]}
                          />
                        </TableCell>
                        <TableCell>Sr. No.</TableCell>
                        <TableCell>
                          Project
                          <br />
                          Manager
                        </TableCell>
                        <TableCell>
                          Project
                          <br />
                          Name
                        </TableCell>
                        <TableCell>
                          Customer
                          <br />
                          Name
                        </TableCell>
                        <TableCell>
                          Calendar
                          <br />
                          Name
                        </TableCell>
                        <TableCell>ERP No</TableCell>
                        <TableCell>
                          Start
                          <br />
                          Date
                        </TableCell>
                        <TableCell>
                          Requested
                          <br />
                          Due Date
                        </TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>
                          Created
                          <br />
                          On
                        </TableCell>
                        <TableCell>
                          Last
                          <br />
                          Updated On
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {temp.length > 0 && temp?.map((item, index) => (
                        <TableRow key={index} className={modules["table-row"]}>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={sstate.checkedRows.has(index)}
                              onChange={() => handleCheckboxChange(index)}
                              className={modules["custom-checkbox"]}
                            />
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {index + 1}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {item.projectmanager}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {item.projectname}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {item.customername}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {item.calendarname}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {item.erpno}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {formatDate(item.startdate)}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {formatDate(item.duedate)}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {item.status}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {formatDate(item.createdon)}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {formatDate(item.lastmodifiedon)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            )}
            {showTable && temp.length > 0 && (
              <Grid
                container
                className={[
                  modules["parent-container-cpm"],
                  modules["bottom-grid-cpm"],
                ].join(" ")}
              >
                <div className={modules["bottom-grid-heading-cpm"]}>
                  Change Project Manager
                </div>
                <Stack direction="row" alignItems="center">
                  <div>
                    <strong>Project Manager To Be: </strong>
                  </div>
                  <select
                    className={modules["dropdownSelect-bottom"]}
                    value={selectedPM}
                    onChange={(e) => setSelectedPM(e.target.value)}
                    style={{}}
                  >
                    {PM.length > 0 &&
                      <option value="Select">SELECT</option>
                    }
                    {PM.length > 0 && PM?.map((i) => (
                      <option value={i.id} key={i}>
                        {i.username}
                      </option>
                    ))}
                  </select>
                  <button
                    className={modules["btn1-bottom"]}
                    onClick={handleChangePM}
                  >
                    Change PM
                  </button>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChangeProjectManager;
