import React, { useEffect, useState } from "react";
import "../../../HelpPopups/HelpPopup.css";
import Stack from "@mui/material/Stack";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import modules from '../../ccpm.module.css'
import { Grid } from "@mui/material";
import bread from "../../../../Assets/bread.png";
import { PendingTaskPopup, ViewIssuePopupbox } from "../../../../Services/CCPM";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";
import loading from "../../../../Assets/Loading_image.gif";

const ViewIssuePopup = () => {
    const [data, setData] = useState([])
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = parseInt(searchParams.get("projId"));
    const [isLoading, setIsLoading] = useState(false);
    const [showpage, setShowpage] = useState(false)
    const [showtable, setShowtable] = useState(false)
    const [showlogo, setShowlogo] = useState(false)
    useEffect(() => {
        setIsLoading(true)
        let body = {
            // projectsId: 4244
            projectsId: projectId
        }
        ViewIssuePopupbox(body)
            .then((response) => {
                if (response?.status === 200) {
                    console.log(response)
                    if (response?.data?.success?.data?.length === 0) {
                        setShowlogo(false)
                        setShowtable(false)
                        alert('No Data To Display')
                        setShowlogo(true)
                    }
                    else {
                        setData(response?.data?.success?.data)
                        setShowlogo(true)
                        setShowtable(true)
                    }
                    setIsLoading(false)
                }
                else if (response?.response?.data?.message !== undefined) {
                    setIsLoading(false);
                    alert(response?.response?.data?.message);
                    setShowlogo(false)
                    setShowtable(false)
                } else if (response?.response?.data?.error?.message !== undefined) {
                    setIsLoading(false);
                    alert(response?.response?.data?.error?.message);
                    setShowlogo(false)
                    setShowtable(false)
                } else {
                    setIsLoading(false);
                    alert(response?.response?.data?.success?.message);
                    setShowlogo(false)
                    setShowtable(false)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const strTime = `${hours}:${minutes}:${seconds}`;  // Use hours as is in 24-hour format
        return `${day}/${month}/${year} ${strTime}`;
    };
    

    return (
        <div>
            {isLoading && (
                <img
                    src={loading}
                    className={modules["loading_gif"]}
                    alt="Loading..."
                />
            )}
            <div style={{ display: showlogo ? '' : 'none', overflowX: 'auto' }}>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                        <img src={logo} alt="Godrej" />
                    </Stack>
                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                {showtable &&
                    <table border="1" style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }} className={modules["custom-table-Viewissuepopup"]}>
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Project<br />Name</th>
                                <th>Task<br />Id</th>
                                <th>Task<br />Name</th>
                                <th>Description</th>
                                <th>Raised<br />By</th>
                                <th>Raised On</th>
                                <th>IssueOwner</th>
                                <th>Resolution</th>
                                <th>Resolved On</th>
                                <th>Resolve<br />Action</th>
                                <th>Issue<br />Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.projectname}</td>
                                    <td>{item.taskid}</td>
                                    <td>{item.taskname}</td>
                                    <td>
                                        <textarea readOnly rows="3" cols="40" value={item.description} />
                                    </td>
                                    <td>{item.raisedby}</td>
                                    <td>{formatDate(item.raisedon)}</td>
                                    <td>{item.issueowner}</td>
                                    <td>
                                        <textarea rows="3" cols="40" value={item.resolution} />
                                    </td>
                                    <td>{item.resolvedon}</td>
                                    <td>{item.resolveaction}</td>
                                    <td>{item.issuestatustext}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    );
};

export default ViewIssuePopup;
