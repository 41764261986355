import React, { useState, useEffect } from "react";
import bread from "../../Assets/bread.png";
import Home from "../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Stack,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../Landing/Navbar";
import SampleSidebar from "../../Landing/SampleSidebar";
import save from "../../Assets/save.png";
import {
  ExportExcelInactive,
  ExportExcelInstanceExp,
  US_GetDropdown,
  US_TableDetails,
  US_RowDelete,
  US_RowUpdate,
  US_BulkDelete,
  US_L1ModifyReplace,
  US_AddRow,
  US_ExportToExcel,
} from "../../Services/Admin";
import modules from "./Admin.module.css";
import loading from "../../Assets/Loading_image.gif";

const UserMaster = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownData, setDropdownData] = useState([]);
  const [mainDropdownData, setMainDropdownData] = useState([]);
  const [dropdownData2, setDropdownData2] = useState([]);
  const [mainDropdownData2, setMainDropdownData2] = useState([]);
  const [dropdownData3, setDropdownData3] = useState([]);
  const [mainDropdownData3, setMainDropdownData3] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [tempId, setTempId] = useState("");
  const [displayvalue, setDisplayValue] = useState("");
  const [displayvalue2, setDisplayValue2] = useState("");
  const [displayvalue3, setDisplayValue3] = useState("");
  const [displayvalue4, setDisplayValue4] = useState("");
  const [displayvalue5, setDisplayValue5] = useState("");
  const [displayvalue6, setDisplayValue6] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedId2, setSelectedId2] = useState("");
  const [selectedId3, setSelectedId3] = useState("");
  const [selectedId4, setSelectedId4] = useState("");
  const [selectedId5, setSelectedId5] = useState("");
  const [selectedId6, setSelectedId6] = useState("");
  const [loginCode, setLoginCode] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkedRows, setCheckedRows] = useState({});
  const [checkedRowsL1, setCheckedRowsL1] = useState({});
  const [editing, setEditing] = useState(null);
  const [editedUserName, setEditedUserName] = useState("");
  const [editedEmailId, setEditedEmailId] = useState("");
  const [activeCheck, setActiveCheck] = useState(false);
  const [ccpmUserCheck, setCcpmUserCheck] = useState(false);
  const [mtamtouserCheck, setMtamtouserCheck] = useState(false);
  const [financeUserCheck, setFinanceUserCheck] = useState(false);
  const [adminCheck, setAdminCheck] = useState(false);
  const [concurrentLoginCheck, setConcurrentLoginCheck] = useState(false);
  const [authCheck, setAuthCheck] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [checkUName, setCheckUName] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const RowsPerPage = 5;
  const MaxVisiblePages = 10;
  const startIndex = (currentPage - 1) * RowsPerPage;
  const endIndex = startIndex + RowsPerPage;
  const totalPages = Math.ceil(tableData.length / RowsPerPage);
  const visibleData = tableData.slice(startIndex, endIndex);
  const [isAddRowVisible, setIsAddRowVisible] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [newLoginId, setNewLoginId] = useState("");
  const [newPersonCode, setNewPersonCode] = useState("");
  const [newEmailId, setNewEmailId] = useState("");
  const [activeCheckNew, setActiveCheckNew] = useState(true);
  const [ccpmUserCheckNew, setCcpmUserCheckNew] = useState(false);
  const [mtamtouserCheckNew, setMtamtouserCheckNew] = useState(false);
  const [financeUserCheckNew, setFinanceUserCheckNew] = useState(false);
  const [adminCheckNew, setAdminCheckNew] = useState(false);
  const [concurrentLoginCheckNew, setConcurrentLoginCheckNew] = useState(false);
  const [authCheckNew, setAuthCheckNew] = useState(true);
  const [newUserEmpt, setNewUserEmpt] = useState(false);
  const [newLoginEmpt, setNewLoginEmpt] = useState(false);
  const [newEmailEmpt, setNewEmailEmpt] = useState(false);
  const [selectedUserCheck, setSelectedUserCheck] = useState(false);
  const [selectedL1Check, setSelectedL1Check] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (loginId, L1Id) => {
    setIsLoading(true);
    checkLoginAndRedirect();
    await getTable(loginId, L1Id);
    const body = {
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0,
      level6: 0,
      level7: 0,
      level8: 0,
      level9: 0,
      level10: 0,
      mode: "",
      userID: sessionStorage.getItem("loginId"),
    };
    try {
      const response = await US_GetDropdown(body);
      if (response.status === 200) {
        setIsLoading(false);
        const apiData = response.data;
        const data = apiData.success.data[0];

        const selectOption = {
          id: 0,
          username: "SELECT",
        };
        const modifiedData = [selectOption, ...data];
        const names = modifiedData.map((item) => item.username);

        setDropdownData(names);
        setMainDropdownData(modifiedData);
        if (names.length > 0) {
          setDisplayValue(names[0]);
          setSelectedId(modifiedData[0].id);
          setDisplayValue4(names[0]);
          setSelectedId4(modifiedData[0].id);
          setDisplayValue5(names[0]);
          setSelectedId5(modifiedData[0].id);
          setDisplayValue6(names[0]);
          setSelectedId6(modifiedData[0].id);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData("", 0);
  }, []);

  useEffect(() => {
    const allChecked =
      visibleData.length > 0 &&
      visibleData.every((item) => checkedRows[item.userid]);
    setSelectAllChecked(allChecked);
  }, [checkedRows, visibleData]);

  const getTable = async (loginI, lId) => {
    const body = {
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0,
      level6: 0,
      level7: 0,
      level8: 0,
      level9: 0,
      level10: 0,
      loginId: loginI,
      l1: lId,
    };
    try {
      const response = await US_TableDetails(body);
      if (response.status === 200) {
        const apiData = response.data.success.data.ViewUsers;
        setTableData(apiData);
        setCurrentPage(1);
        setShowTable(true);
      } else {
        setShowTable(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };

  const searchHandler = () => {
    getTable(loginCode, selectedId);
  };

  const resetHandler = () => {
    fetchData("", 0);
    setLoginCode("");
  };

  const handleChange = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.username === selectedName
    );
    setDisplayValue(selectedName);
    setSelectedId(selectedItem.id);
  };

  const handleChange2 = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData2.find(
      (item) => item.username === selectedName
    );
    setDisplayValue2(selectedName);
    setSelectedId2(selectedItem.id);
  };

  const handleChange3 = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData3.find(
      (item) => item.username === selectedName
    );
    setDisplayValue3(selectedName);
    setSelectedId3(selectedItem.id);
  };

  const handleChange4 = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.username === selectedName
    );
    setDisplayValue4(selectedName);
    setSelectedId4(selectedItem.id);
  };

  const handleChange5 = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.username === selectedName
    );
    setDisplayValue5(selectedName);
    setSelectedId5(selectedItem.id);
  };

  const handleChange6 = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.username === selectedName
    );
    setDisplayValue6(selectedName);
    setSelectedId6(selectedItem.id);
  };

  const handlePageChange = (page) => {
    setSelectAllChecked(false);
    setCheckedRows([]);
    if (page === "prev") {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    } else if (page === "next") {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    } else {
      setCurrentPage(page);
    }
  };

  const getPageNumbers = () => {
    const totalPages = Math.ceil(tableData.length / RowsPerPage);
    const pageNumbers = [];

    if (totalPages <= MaxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= MaxVisiblePages - 3) {
        for (let i = 1; i <= MaxVisiblePages - 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");

        const remainingPages = totalPages - currentPage;

        if (remainingPages < MaxVisiblePages - 3) {
          for (
            let i = currentPage - (MaxVisiblePages - 4);
            i <= totalPages;
            i++
          ) {
            pageNumbers.push(i);
          }
        } else {
          for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pageNumbers.push(i);
          }

          pageNumbers.push("...");
          pageNumbers.push(totalPages);
        }
      }
    }

    return pageNumbers;
  };

  function getFilenameFromHeader(header) {
    if (header) {
      const disposition = header.split(";");
      for (let part of disposition) {
        if (part.trim().startsWith("filename=")) {
          return part.split("=")[1].replace(/"/g, "");
        }
      }
    }
    return null;
  }

  function getFilenameFromHeader2(contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      return decodeURIComponent(matches[1].replace(/['"]/g, ""));
    }
    return null;
  }

  const ExcelInactive = async () => {
    setIsLoading(true);
    try {
      const response = await ExportExcelInactive();
      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        const contentDisposition = response.headers["content-disposition"];
        const baseFilename =
          getFilenameFromHeader(contentDisposition) ||
          "Inactive user from Email system Report";
        const now = new Date();
        const day = String(now.getDate()).padStart(2, "0");
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");

        const timestamp = `${day}_${month}_${year} ${hours}_${minutes}_${seconds}`;
        const filename = `${baseFilename}_${timestamp}.xlsx`;

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No Data found");
      }
    } catch (err) {
      console.error("Error downloading file:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const ExportExcelInstanceHandler = async () => {
    setIsLoading(true);
    try {
      const response = await ExportExcelInstanceExp();
      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        const contentDisposition = response.headers["content-disposition"];
        const baseFilename =
          getFilenameFromHeader(contentDisposition) ||
          "User Instance Exception Report";
        const now = new Date();
        const day = String(now.getDate()).padStart(2, "0");
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");

        const timestamp = `${day}_${month}_${year} ${hours}_${minutes}_${seconds}`;
        const filename = `${baseFilename}_${timestamp}.xlsx`;

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No Data found");
      }
    } catch (err) {
      console.error("Error downloading file:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    const newCheckedItems = {};
    visibleData.forEach((item) => {
      newCheckedItems[item.userid] = isChecked;
    });
    setCheckedRows(newCheckedItems);
  };

  const handleCheckboxChange = (event, id, l1) => {
    setCheckedRows((prev) => ({
      ...prev,
      [id]: event.target.checked,
    }));
    setCheckedRowsL1((prev) => ({
      ...prev,
      [l1]: event.target.checked,
    }));
  };

  const handleEditSingleEntry = async (item) => {
    handleAddCancel();
    const body1 = {
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0,
      level6: 0,
      level7: 0,
      level8: 0,
      level9: 0,
      level10: 0,
      mode: "E",
      userID: sessionStorage.getItem("loginId"),
    };
    try {
      const response = await US_GetDropdown(body1);
      if (response.status === 200) {
        const apiData = response.data;
        const data = apiData.success.data[0];

        const selectOption = {
          id: 0,
          username: "----Select----",
        };
        const modifiedData = [selectOption, ...data];
        const names = modifiedData.map((item) => item.username);

        setDropdownData2(names);
        setMainDropdownData2(modifiedData);
        const selectedData = modifiedData.find((entry) => entry.id === item.l1);
        console.log(selectedData);
        if (selectedData !== undefined) {
          setDisplayValue2(selectedData.username);
          setSelectedId2(selectedData.id);
        } else {
          setDisplayValue2(names[0]);
          setSelectedId2(modifiedData[0].id);
        }
      }
    } catch (error) {
      console.log(error);
    }

    if (loginCode === "" && selectedId === 0) {
      setEditing(item.userid);
      setAuthCheck(item.isemailauthentication === 1);
      setEditedEmailId(item.emailid);
      setEditedUserName(item.username);
      setActiveCheck(item.isactive === 1);
      setCcpmUserCheck(item.isccpmuser === 1);
      setMtamtouserCheck(item.ismtamtouser === 1);
      setFinanceUserCheck(item.isfinanceuser === 1);
      setAdminCheck(item.isadmin === 1);
      setConcurrentLoginCheck(item.concurrentlogin === 1);
    } else {
      const body = {
        level2: 0,
        level3: 0,
        level4: 0,
        level5: 0,
        level6: 0,
        level7: 0,
        level8: 0,
        level9: 0,
        level10: 0,
        loginId: loginCode,
        l1: selectedId,
      };
      try {
        const response = await US_TableDetails(body);
        if (response.status === 200) {
          const apiData = response.data.success.data.ViewUsers;
          setTableData(apiData);
          setCurrentPage(1);
          setShowTable(true);
          setEditOpen(true);
          setEditing(item.userid);
          setAuthCheck(item.isemailauthentication === 1);
          setEditedEmailId(item.emailid);
          setEditedUserName(item.username);
          setActiveCheck(item.isactive === 1);
          setCcpmUserCheck(item.isccpmuser === 1);
          setMtamtouserCheck(item.ismtamtouser === 1);
          setFinanceUserCheck(item.isfinanceuser === 1);
          setAdminCheck(item.isadmin === 1);
          setConcurrentLoginCheck(item.concurrentlogin === 1);
        } else {
          setShowTable(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAdd = () => {
    setIsAddRowVisible(true);
    const body1 = {
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0,
      level6: 0,
      level7: 0,
      level8: 0,
      level9: 0,
      level10: 0,
      mode: "E",
      userID: sessionStorage.getItem("loginId"),
    };
    US_GetDropdown(body1)
      .then((response) => {
        if (response.status === 200) {
          const apiData = response.data;
          const data = apiData.success.data[0];

          const selectOption = {
            id: 0,
            username: "----Select----",
          };
          const modifiedData = [selectOption, ...data];
          const names = modifiedData.map((item) => item.username);

          setDropdownData3(names);
          setMainDropdownData3(modifiedData);
          if (names.length > 0) {
            setDisplayValue3(names[0]);
            setSelectedId3(modifiedData[0].id);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddCancel = () => {
    setIsAddRowVisible(false);
    setNewUserName("");
    setNewLoginId("");
    setNewEmailId("");
    setNewPersonCode("");
    setActiveCheckNew(false);
    setCcpmUserCheckNew(false);
    setMtamtouserCheckNew(false);
    setFinanceUserCheckNew(false);
    setAdminCheckNew(false);
    setConcurrentLoginCheckNew(false);
    setAuthCheckNew(true);
    setNewUserEmpt(false);
    setNewLoginEmpt(false);
    setNewEmailEmpt(false);
    setCheckUName(false);
    setCheckEmail(false);
  };

  function handleCancel() {
    setEditOpen(false);
    setEditing(null);
    setAuthCheck(true);
    setEditedUserName("");
    setEditedEmailId("");
    setActiveCheck(false);
    setCcpmUserCheck(false);
    setMtamtouserCheck(false);
    setFinanceUserCheck(false);
    setAdminCheck(false);
    setConcurrentLoginCheck(false);
  }

  const handleUpdate = async (item) => {
    if (editedUserName === "" && editedEmailId === "") {
      setCheckUName(true);
      setCheckEmail(true);
      return;
    } else if (editedUserName === "") {
      setCheckUName(true);
      return;
    } else if (editedEmailId === "") {
      setCheckEmail(true);
      return;
    } else {
      setCheckUName(false);
      setCheckEmail(false);
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(editedEmailId)) {
      alert("Please enter a valid email id");
    }
    const body = {
      id: item.userid,
      l1: selectedId2 ? selectedId2 : item.l1,
      isCCPMUser: ccpmUserCheck ? 1 : 0,
      isMTAMTOUser: mtamtouserCheck ? 1 : 0,
      isFinanceUser: financeUserCheck ? 1 : 0,
      isActive: activeCheck ? 1 : 0,
      createdBy: sessionStorage.getItem("loginId"),
      isEmailAuthentication: authCheck ? 1 : 0,
      userName: editedUserName,
      emailId: editedEmailId,
      isAdmin: adminCheck ? 1 : 0,
      allowConcurrentLogin: concurrentLoginCheck ? 1 : 0,
    };

    try {
      const response = await US_RowUpdate(body);
      console.log(response);
      if (response.status === 200) {
        alert(response?.data?.message);
        setEditOpen(false);
        setEditing(null);
        fetchData(loginCode, selectedId);
      } else {
        alert(response?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (item, element) => {
    element.style.color = "red";

    setTimeout(() => {
      const confirmDelete = window.confirm(
        `Are you sure you want to delete this record?`
      );
      if (confirmDelete) {
        let body = {
          userId: item.userid,
        };
        US_RowDelete(body)
          .then((response) => {
            if (response.status === 200) {
              alert(response?.data?.message);
              fetchData(loginCode, selectedId);
              element.style.color = "#0000ee";
            } else {
              alert(response?.response?.data?.message);
              element.style.color = "#0000ee";
            }
          })
          .catch((error) => {
            console.log(error);
            element.style.color = "#0000ee";
          });
      } else {
        element.style.color = "#0000ee";
      }
    }, 100);
  };

  const handleAddRow = async () => {
    if (newUserName === "" && newLoginId === "" && newEmailId === "") {
      setNewUserEmpt(true);
      setNewLoginEmpt(true);
      setNewEmailEmpt(true);
      return;
    } else if (newUserName === "") {
      setNewUserEmpt(true);
      return;
    } else if (newLoginId === "") {
      setNewLoginEmpt(true);
      return;
    } else if (newEmailId === "") {
      setNewEmailEmpt(true);
      return;
    } else {
      setNewUserEmpt(false);
      setNewLoginEmpt(false);
      setNewEmailEmpt(false);
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(newEmailId)) {
      alert("Please enter a valid email id");
      return;
    }
    if (activeCheckNew === false) {
      alert("Please check IsActive checkbox for User");
      return;
    }
    if (selectedId3 === 0) {
      return;
    }
    let body = {
      userName: newUserName,
      emailId: newEmailId,
      l1: selectedId3,
      firstTymUser: 1,
      isCCPM: ccpmUserCheckNew ? 1 : 0,
      isMTAMTO: mtamtouserCheckNew ? 1 : 0,
      isFinance: financeUserCheckNew ? 1 : 0,
      isActive: activeCheckNew ? 1 : 0,
      createdBy: sessionStorage.getItem("loginId"),
      loginCode: newLoginId,
      password: "J+JOF9lBC+Pu6RrWKWi/2w==",
      personCode: newPersonCode,
      isErpLNUser: 0,
      isEmailAuth: authCheckNew ? 1 : 0,
      isAdmin: adminCheckNew ? 1 : 0,
      concurrentLogin: concurrentLoginCheckNew ? 1 : 0,
    };
    try {
      const response = await US_AddRow(body);
      if (response.status === 200) {
        alert(response?.data);
        fetchData(newLoginId, 0);
        handleAddCancel();
      } else {
        alert(response?.response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBulkDelete = async () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this record?`
    );
    if (confirmDelete) {
      const selectedIds = Object.keys(checkedRows).filter(
        (key) => checkedRows[key]
      );

      if (selectedIds.length === 0) {
        alert("Please select atleast one row from grid to delete");
        return;
      }
      const body = {
        idList: selectedIds.map((id) => ({ userId: id })),
      };
      try {
        const response = await US_BulkDelete(body);
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            const errorMessages = response.data
              .filter((item) => !item.success)
              .map((item) => item.message)
              .join("\n");

            if (errorMessages) {
              alert(errorMessages);
            }

            const successMessages = response.data
              .filter((item) => item.success)
              .map((item) => item.message)
              .join("\n");

            if (successMessages) {
              alert(successMessages);
            }
            setLoginCode("");
            fetchData("", 0);
          } else {
            alert(response?.data?.message);
            fetchData("", 0);
          }
        } else {
          alert(response?.response?.data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      return;
    }
  };

  const ExportToExcelHandler = async () => {
    setIsLoading(true);
    const body = {
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0,
      level6: 0,
      level7: 0,
      level8: 0,
      level9: 0,
      level10: 0,
      loginId: loginCode,
      l1: selectedId,
    };
    try {
      const response = await US_ExportToExcel(body);
      if (response.status === 200) {
        alert(response?.data?.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const ModifyUser = async () => {
    if (checkedRowsL1.length === 0) {
      return;
    }
    const modifyList = Object.keys(checkedRows)
      .filter((id) => checkedRows[id])
      .map((id) => ({
        userId: id,
        l1: Object.keys(checkedRowsL1).find((l1) => checkedRowsL1[l1]),
        l1ToBe: selectedId4,
        modifiedBy: sessionStorage.getItem("loginId"),
      }));

    let body = {
      modifyList: modifyList,
    };
    try {
      const response = await US_L1ModifyReplace(body);
      if (response.status === 200) {
        alert(response?.data?.message);
        fetchData(loginCode, selectedId);
      } else {
        alert(response?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReplace = async () => {
    let body = {
      modifyList: [
        {
          userId: 0,
          l1: selectedId5,
          l1ToBe: selectedId6,
          modifiedBy: sessionStorage.getItem("loginId"),
        },
      ],
    };
    try {
      const response = await US_L1ModifyReplace(body);
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.length > 0 && responseData[0].success === false) {
          alert(responseData[0].message);
        } else {
          alert(response?.data?.message);
          fetchData(loginCode, selectedId);
        }
      } else {
        alert(response?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // check

  return (
    <div style={{ overflowX: "auto", height: "100vh" }}>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.7} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"userMaster"} />
          </Grid>

          <Grid item xs={12}>
            <div className={modules["usermaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Admin</a>
                <img src={bread} alt="" />
                <a>User Master</a>
              </div>
            </div>
            <Grid container className={modules["mid-cont1"]}>
              <Grid container alignItems="center" columnSpacing={{ xs: 1 }}>
                <Grid item>
                  <Typography className={modules["bodyFontBold"]}>
                    Login ID
                  </Typography>
                </Grid>
                <Grid item>:</Grid>

                <Grid item sx={{ marginLeft: "5px" }}>
                  <span>
                    <input
                      value={loginCode}
                      onChange={(e) => setLoginCode(e.target.value)}
                      className={modules["dropdownSelect"]}
                    />
                  </span>
                </Grid>
                <Grid
                  sx={{ marginLeft: "1rem" }}
                  className={modules["bodyFontBold"]}
                >
                  L+1
                </Grid>
                <Grid item>:</Grid>
                <Grid item>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      className={modules["dropdownSelect1"]}
                      inputProps={{ IconComponent: () => null }}
                      value={displayvalue}
                      onChange={handleChange}
                    >
                      {dropdownData.map((i) => (
                        <MenuItem value={i} key={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={modules["btn1-cont"]}>
              <button className={modules["btn1"]} onClick={searchHandler}>
                Search
              </button>
              <button className={modules["btn2"]} onClick={resetHandler}>
                Reset
              </button>
            </Grid>
            {showTable && (
              <Grid container className={modules["table-grid"]}>
                <div>
                  <Table className={modules["custom-table"]}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <input
                            type="checkbox"
                            checked={selectAllChecked}
                            onChange={handleSelectAll}
                            className={modules["custom-checkbox"]}
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          Sr. No.
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          UserName
                        </TableCell>
                        <TableCell>Login id</TableCell>
                        <TableCell>PersonCode</TableCell>
                        <TableCell>Email id</TableCell>
                        <TableCell>L + 1</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Email
                          <br />
                          Authentication
                        </TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>CCPM</TableCell>
                        <TableCell>MTAMTO</TableCell>
                        <TableCell>DSS</TableCell>
                        <TableCell>ADMIN</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Restrict
                          <br />
                          Concurrent
                          <br />
                          Login
                        </TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          User
                          <br />
                          Level
                          <br />
                          Mapping
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visibleData.map((item, index) => (
                        <TableRow key={index} className={modules["table-row"]}>
                          <TableCell>
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckboxChange(e, item.userid, item.l1)
                              }
                              checked={!!checkedRows[item.userid]}
                              className={modules["custom-checkbox"]}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {(currentPage - 1) * RowsPerPage + index + 1}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {editing === item.userid ? (
                              <>
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <input
                                    type="text"
                                    value={editedUserName}
                                    className={modules["inputField1"]}
                                    onBlur={() => {
                                      if (editedUserName !== "") {
                                        setCheckUName(false);
                                      }
                                    }}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const regex = /[^a-zA-Z0-9 _-]/g;
                                      const sanitizedValue = value.replace(
                                        regex,
                                        ""
                                      );
                                      setEditedUserName(sanitizedValue);
                                    }}
                                  />
                                  {checkUName && (
                                    <Typography
                                      className={modules["error-msg"]}
                                    >
                                      Enter User Name
                                    </Typography>
                                  )}
                                </Stack>
                              </>
                            ) : (
                              <>{item.username}</>
                            )}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {item.loginid}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {item.p_person_code}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {editing === item.userid ? (
                              <>
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <input
                                    type="text"
                                    value={editedEmailId}
                                    onBlur={() => {
                                      if (editedEmailId !== "") {
                                        setCheckEmail(false);
                                      }
                                      const domain =
                                        editedEmailId.split("@")[1];
                                      if (domain) {
                                        if (
                                          domain.toLowerCase() === "godrej.com"
                                        ) {
                                          setAuthCheck(true);
                                        } else if (domain) {
                                          setAuthCheck(false);
                                        }
                                      }
                                    }}
                                    className={modules["inputField1"]}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setEditedEmailId(value);
                                    }}
                                  />
                                  {checkEmail && (
                                    <Typography
                                      className={modules["error-msg"]}
                                    >
                                      Enter Valid Email Id
                                    </Typography>
                                  )}
                                </Stack>
                              </>
                            ) : (
                              <>{item.emailid}</>
                            )}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {editing === item.userid ? (
                              <FormControl className={modules["form-control"]}>
                                <Select
                                  className={modules["dropdownSelect2"]}
                                  inputProps={{ IconComponent: () => null }}
                                  value={displayvalue2}
                                  onChange={handleChange2}
                                >
                                  {dropdownData2.map((i) => (
                                    <MenuItem value={i} key={i}>
                                      {i}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : (
                              <>{item.l1name}</>
                            )}
                          </TableCell>
                          <TableCell>
                            {editing === item.userid ? (
                              <input
                                type="checkbox"
                                checked={authCheck}
                                readOnly
                                className={modules["custom-checkbox"]}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                checked={item.isemailauthentication === 1}
                                readOnly
                                className={modules["custom-checkbox"]}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {editing === item.userid ? (
                              <>
                                <input
                                  type="checkbox"
                                  checked={activeCheck}
                                  onChange={(e) => {
                                    setActiveCheck(e.target.checked);
                                  }}
                                  className={modules["custom-checkbox"]}
                                />
                              </>
                            ) : (
                              <>
                                <input
                                  type="checkbox"
                                  checked={item.isactive === 1}
                                  readOnly
                                  className={modules["custom-checkbox"]}
                                />
                              </>
                            )}
                          </TableCell>
                          <TableCell>
                            {editing === item.userid ? (
                              <>
                                <input
                                  type="checkbox"
                                  checked={ccpmUserCheck}
                                  onChange={(e) => {
                                    setCcpmUserCheck(e.target.checked);
                                  }}
                                  className={modules["custom-checkbox"]}
                                />
                              </>
                            ) : (
                              <input
                                type="checkbox"
                                checked={item.isccpmuser === 1}
                                readOnly
                                className={modules["custom-checkbox"]}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {editing === item.userid ? (
                              <>
                                <input
                                  type="checkbox"
                                  checked={mtamtouserCheck}
                                  onChange={(e) => {
                                    setMtamtouserCheck(e.target.checked);
                                  }}
                                  className={modules["custom-checkbox"]}
                                />
                              </>
                            ) : (
                              <input
                                type="checkbox"
                                checked={item.ismtamtouser === 1}
                                readOnly
                                className={modules["custom-checkbox"]}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {editing === item.userid ? (
                              <>
                                <input
                                  type="checkbox"
                                  checked={financeUserCheck}
                                  onChange={(e) => {
                                    setFinanceUserCheck(e.target.checked);
                                  }}
                                  className={modules["custom-checkbox"]}
                                />
                              </>
                            ) : (
                              <input
                                type="checkbox"
                                checked={item.isfinanceuser === 1}
                                readOnly
                                className={modules["custom-checkbox"]}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {editing === item.userid ? (
                              <>
                                <input
                                  type="checkbox"
                                  checked={adminCheck}
                                  onChange={(e) => {
                                    setAdminCheck(e.target.checked);
                                  }}
                                  className={modules["custom-checkbox"]}
                                />
                              </>
                            ) : (
                              <input
                                type="checkbox"
                                checked={item.isadmin === 1}
                                readOnly
                                className={modules["custom-checkbox"]}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {editing === item.userid ? (
                              <>
                                <input
                                  type="checkbox"
                                  checked={concurrentLoginCheck}
                                  onChange={(e) => {
                                    setConcurrentLoginCheck(e.target.checked);
                                  }}
                                  className={modules["custom-checkbox"]}
                                />
                              </>
                            ) : (
                              <input
                                type="checkbox"
                                checked={item.concurrentlogin === 1}
                                readOnly
                                className={modules["custom-checkbox"]}
                              />
                            )}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {editing === item.userid ? (
                              <div>
                                <a
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() => handleUpdate(item)}
                                >
                                  Update
                                </a>
                                <br />
                                <a
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={handleCancel}
                                >
                                  Cancel
                                </a>
                              </div>
                            ) : (
                              <div>
                                <a
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() => handleEditSingleEntry(item)}
                                >
                                  Edit
                                </a>
                                <br />
                                <a
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={(e) =>
                                    handleDelete(item, e.currentTarget)
                                  }
                                >
                                  Delete
                                </a>
                              </div>
                            )}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <div
                              className={modules["userRoleMap"]}
                              onClick={() => {
                                window.open(
                                  `/admin/usermastermapping?userId=${item.userid}&userName=${item.username}&loginId=${item.loginid}`,
                                  "sharer",
                                  "toolbar=0,status=0,width=800,height=600"
                                );
                              }}
                            >
                              <a>User Role</a>
                              <br />
                              <a>Mapping</a>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                      {isAddRowVisible && (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Stack direction="column" alignItems="flex-start">
                              <Typography className={modules["error-msg"]}>
                                *
                              </Typography>
                              <input
                                type="text"
                                value={newUserName}
                                className={modules["inputField1"]}
                                onBlur={() => {
                                  if (newUserName !== "") {
                                    setNewUserEmpt(false);
                                  }
                                }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /[^a-zA-Z0-9 _-]/g;
                                  const sanitizedValue = value.replace(
                                    regex,
                                    ""
                                  );
                                  setNewUserName(sanitizedValue);
                                }}
                              />
                              {newUserEmpt && (
                                <Typography className={modules["error-msg"]}>
                                  Enter User Name
                                </Typography>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Stack direction="column" alignItems="flex-start">
                              <Typography className={modules["error-msg"]}>
                                *
                              </Typography>
                              <input
                                type="text"
                                value={newLoginId}
                                className={modules["inputField1"]}
                                onBlur={() => {
                                  if (newLoginId !== "") {
                                    setNewLoginEmpt(false);
                                  }
                                }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /[^a-zA-Z0-9 _-]/g;
                                  const sanitizedValue = value.replace(
                                    regex,
                                    ""
                                  );
                                  setNewLoginId(sanitizedValue);
                                }}
                              />
                              {newLoginEmpt && (
                                <Typography className={modules["error-msg"]}>
                                  Enter User Name
                                </Typography>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Stack direction="column" alignItems="flex-start">
                              <input
                                type="text"
                                value={newPersonCode}
                                className={modules["inputField1"]}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /[^a-zA-Z0-9 _-]/g;
                                  const sanitizedValue = value.replace(
                                    regex,
                                    ""
                                  );
                                  setNewPersonCode(sanitizedValue);
                                }}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Stack direction="column" alignItems="flex-start">
                              <Typography className={modules["error-msg"]}>
                                *
                              </Typography>
                              <input
                                type="text"
                                value={newEmailId}
                                onBlur={() => {
                                  if (newEmailId !== "") {
                                    setNewEmailEmpt(false);
                                  }
                                  const domain = newEmailId.split("@")[1];
                                  if (domain) {
                                    if (domain.toLowerCase() === "godrej.com") {
                                      setAuthCheckNew(true);
                                    } else if (domain) {
                                      setAuthCheckNew(false);
                                    }
                                  }
                                }}
                                className={modules["inputField1"]}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setNewEmailId(value);
                                }}
                              />
                              {newEmailEmpt && (
                                <Typography className={modules["error-msg"]}>
                                  Enter Valid Email Id
                                </Typography>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <FormControl className={modules["form-control"]}>
                              <Select
                                className={modules["dropdownSelect2"]}
                                inputProps={{ IconComponent: () => null }}
                                value={displayvalue3}
                                onChange={handleChange3}
                              >
                                {dropdownData3.map((i) => (
                                  <MenuItem value={i} key={i}>
                                    {i}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={authCheckNew}
                              readOnly
                              className={modules["custom-checkbox"]}
                            />
                          </TableCell>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={activeCheckNew}
                              onChange={(e) => {
                                setActiveCheckNew(e.target.checked);
                              }}
                              className={modules["custom-checkbox"]}
                            />
                          </TableCell>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={ccpmUserCheckNew}
                              onChange={(e) => {
                                setCcpmUserCheckNew(e.target.checked);
                              }}
                              className={modules["custom-checkbox"]}
                            />
                          </TableCell>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={mtamtouserCheckNew}
                              onChange={(e) => {
                                setMtamtouserCheckNew(e.target.checked);
                              }}
                              className={modules["custom-checkbox"]}
                            />
                          </TableCell>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={financeUserCheckNew}
                              onChange={(e) => {
                                setFinanceUserCheckNew(e.target.checked);
                              }}
                              className={modules["custom-checkbox"]}
                            />
                          </TableCell>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={adminCheckNew}
                              onChange={(e) => {
                                setAdminCheckNew(e.target.checked);
                              }}
                              className={modules["custom-checkbox"]}
                            />
                          </TableCell>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={concurrentLoginCheckNew}
                              onChange={(e) => {
                                setConcurrentLoginCheckNew(e.target.checked);
                              }}
                              className={modules["custom-checkbox"]}
                            />
                          </TableCell>
                          <TableCell>
                            <div>
                              <a
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={handleAddRow}
                              >
                                Save
                              </a>
                              <br />
                              <a
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={handleAddCancel}
                              >
                                Cancel
                              </a>
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}
                      {totalPages <= 1 ? null : (
                        <TableRow>
                          <TableCell colSpan={16}>
                            <div className={modules["pagination"]}>
                              {getPageNumbers().map((pageNumber, index) => (
                                <span
                                  key={index}
                                  className={
                                    pageNumber === currentPage
                                      ? `${modules["active-page"]}`
                                      : `${modules["page-number"]}`
                                  }
                                  onClick={() =>
                                    typeof pageNumber === "number"
                                      ? handlePageChange(pageNumber)
                                      : null
                                  }
                                >
                                  {pageNumber}
                                </span>
                              ))}
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <Grid container className={modules["btn2-cont"]}>
                  <button onClick={handleAdd}>Add</button>
                  <button onClick={handleBulkDelete}>Delete</button>
                  <button onClick={ExportToExcelHandler}>
                    Export To Excel
                  </button>
                  <button onClick={ExcelInactive}>
                    Export inactive user from Email system Report
                  </button>
                  <button onClick={ExportExcelInstanceHandler}>
                    Export User Instance Exception Report
                  </button>
                </Grid>

                <div className={modules["box-class"]}>
                  <label>Change L+1</label>

                  <div className={modules["radio-class"]}>
                    <label>
                      <input
                        type="checkbox"
                        className={modules["radio-classCheck"]}
                        checked={selectedUserCheck}
                        onChange={(e) => {
                          setSelectedUserCheck(e.target.checked);
                          if (e.target.checked) {
                            setSelectedL1Check(false);
                          }
                        }}
                      />
                      <span>For selected User (Grid checkbox)</span>
                    </label>

                    <label style={{ marginLeft: "1.5rem" }}>
                      <input
                        type="checkbox"
                        className={modules["radio-classCheck"]}
                        checked={selectedL1Check}
                        onChange={(e) => {
                          setSelectedL1Check(e.target.checked);
                          if (e.target.checked) {
                            setSelectedUserCheck(false);
                          }
                        }}
                      />
                      <span>For selected L+1</span>
                    </label>
                  </div>
                  {selectedUserCheck && (
                    <Stack
                      direction="row"
                      sx={{ marginTop: "2%" }}
                      spacing={2}
                      alignItems="center"
                    >
                      <label style={{ fontWeight: "bold" }}>L+1 To Be :</label>
                      <FormControl className={modules["form-control"]}>
                        <Select
                          className={modules["dropdownSelect1"]}
                          inputProps={{ IconComponent: () => null }}
                          value={displayvalue4}
                          onChange={handleChange4}
                        >
                          {dropdownData.map((i) => (
                            <MenuItem value={i} key={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <img
                        src={save}
                        alt=""
                        style={{
                          cursor: "pointer",
                          marginLeft: "1.5%",
                          width: "20px",
                          height: "20px",
                          marginTop: "-5px",
                        }}
                        onClick={ModifyUser}
                      />
                    </Stack>
                  )}

                  {selectedL1Check && (
                    <Stack direction="row" sx={{ marginTop: "2%" }}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <label style={{ fontWeight: "bold", width: "77px" }}>
                          Existing L+1 :
                        </label>
                        <FormControl className={modules["form-control"]}>
                          <Select
                            className={modules["dropdownSelect1"]}
                            inputProps={{ IconComponent: () => null }}
                            value={displayvalue5}
                            onChange={handleChange5}
                          >
                            {dropdownData.map((i) => (
                              <MenuItem value={i} key={i}>
                                {i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        sx={{ marginLeft: "20%" }}
                      >
                        <label style={{ fontWeight: "bold", width: "116px" }}>
                          L+1 to Replace with:
                        </label>
                        <FormControl className={modules["form-control"]}>
                          <Select
                            className={modules["dropdownSelect1"]}
                            inputProps={{ IconComponent: () => null }}
                            value={displayvalue6}
                            onChange={handleChange6}
                          >
                            {dropdownData.map((i) => (
                              <MenuItem value={i} key={i}>
                                {i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <img
                          src={save}
                          alt=""
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            height: "20px",
                          }}
                          onClick={handleReplace}
                        />
                      </Stack>
                    </Stack>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserMaster;
