import React, { useState } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import { Grid, FormControl, Select, MenuItem } from "@mui/material";
import SideBar from "../../../Landing/Navbar";
import window_popup from "../../../Assets/window_popup.png";
import modules from "./MTOReports.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const DummyData = [
  {
    id: 1,
    col2: "Aerospace",
    col3: "Aerospace",
  },
  {
    id: 2,
    col2: "Innovation and Design Center",
    col3: "IDC",
  },
  {
    id: 3,
    col2: "GB Corporate Projects",
    col3: "GB_CORP",
  },
  {
    id: 4,
    col2: "PSS Gogrej Security Solutions",
    col3: "SSD_PSS",
  },
  {
    id: 5,
    col2: "G&B Finance",
    col3: "G&B Finance",
  },
];

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const BMandFutureOrderReport = () => {
  const [showPlus, setShowPlus] = useState(true);

  const handleIconToggle = () => {
    setShowPlus(!showPlus);
  };

  const [searchValue, setSearchValue] = useState("");
  const [dropdownData, setDropdownData] = useState([
    "Tooling",
    "Instance 2",
    "Instance 3",
  ]);
  const [filteredData, setFilteredData] = useState(DummyData);
  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);
  const handleSearch = () => {
    const filtered = DummyData.filter((item) =>
      item.col2.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleReset = () => {
    setSearchValue("");
    setFilteredData(DummyData);
  };

  const [radioValue, setRadioValue] = useState("yes");

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>MTA Reports</a>
                <img src={bread} alt="" />
                <a>BM & Future Order Report</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <Grid container columnSpacing={{ xs: 2 }}>
                <Grid
                  item
                  sx={{
                    fontSize: "14px",
                    fontWeight: "550",
                    paddingTop: "0.5rem",
                    width: "14rem",
                  }}
                >
                  MTA Instance<span style={{ color: "red" }}>*</span>
                </Grid>
                <Grid item>:</Grid>

                <Grid item>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={displayvalue}
                      onChange={(e) => setDisplayValue(e.target.value)}
                    >
                      {dropdownData.map((i) => {
                        return (
                          <MenuItem value={i} key={i}>
                            {i}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={modules["mid-cont"]}>
              <Grid container columnSpacing={{ xs: 2 }}>
                <Grid
                  item
                  sx={{
                    fontSize: "14px",
                    fontWeight: "550",
                    paddingTop: "0.5rem",
                    width: "14rem",
                  }}
                >
                  Report<span style={{ color: "red" }}>*</span>
                  </Grid>
                <Grid item>:</Grid>

                <Grid item>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={displayvalue}
                      onChange={(e) => setDisplayValue(e.target.value)}
                    >
                      {dropdownData.map((i) => {
                        return (
                          <MenuItem value={i} key={i}>
                            {i}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                </Grid>

            </Grid>

            <Grid container className={modules["mid-cont"]}>
              <Grid container columnSpacing={{ xs: 2 }}>
                <Grid
                  item
                  sx={{
                    fontSize: "14px",
                    fontWeight: "550",
                    paddingTop: "0.5rem",
                    width: "14rem",
                  }}
                >
                  Report Type<span style={{ color: "red" }}>*</span>
                  </Grid>
                <Grid item>:</Grid>

                <Grid item>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={displayvalue}
                      onChange={(e) => setDisplayValue(e.target.value)}
                    >
                      {dropdownData.map((i) => {
                        return (
                          <MenuItem value={i} key={i}>
                            {i}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                </Grid>

            </Grid>

            <Grid
              container
              className={modules["btn1-cont"]}
            >
              <button className={modules["btn1"]} onClick={handleSearch}>
                Submit
              </button>
              <button className={modules["btn2"]} onClick={handleReset}>
                Reset
              </button>
            </Grid>
          </Grid>
          </Grid>
          </Grid>
    </>
  );
};

export default BMandFutureOrderReport;
