import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import playIcon from "../../../Assets/playicon.png";
import graphicon from "../../../Assets/graphicon.png";
import pencilicon from "../../../Assets/pencilicon.png";
import saveIcon from "../../../Assets/Save.jpg";
import modules from "../ActiveProjects/ActiveProjects.module.css";
import { useNavigate } from "react-router-dom";
import {
  RunBm,
  searchActiveProjectDetails,
  GetProjectStatus,
  GetProjectStatusChange,
  RunBmAll,
} from "../../../Services/Activeproject";
import loading from "../../../Assets/Loading_image.gif";

const ActiveProjectTable = (props) => { 
  const { object, setFilteredData, filteredData } = props;
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkedRows, setCheckedRows] = useState({});
  const [checkedRowsL1, setCheckedRowsL1] = useState({});
  const [statusDropdown, setstatusDropdown] = useState([]);
  const [statusChangeDropdown, setstatusChangeDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    const newCheckedItems = {};
    filteredData.forEach((item) => {
      newCheckedItems[item.projectid] = isChecked;
    });
    setCheckedRows(newCheckedItems);
  };

  useEffect(() => {
    getStatus();
    getStatusChange();
  }, []);
  const getStatus = async () => {
    const body = {
      userId: sessionStorage.getItem("loginId"),
      privilegeFunctionPage: "ccpm_execution_viewactiveprojects_aspx",
    };
    try {
      setIsLoading(true);
      let response = await GetProjectStatus(body);
      setIsLoading(false);
      if (response.statusCode == 200) {
        setstatusDropdown(response?.success?.data);
      } else {
        console.log(response?.error?.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const getStatusChange = async () => {
    const body = {
      instanceId: props.instanceId,
    };
    try {
      setIsLoading(true);
      let response = await GetProjectStatusChange(body);
      setIsLoading(false);
      if (response.statusCode == 200) {
        setstatusChangeDropdown(response?.success?.data);
      } else {
        console.log(response?.error?.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const handleRunBM = async (id) => {
    const body = {
      projectId: id,
      userId: sessionStorage.getItem("loginId"),
    };
    try {
      setIsLoading(true);
      let response = await RunBm(body);
      setIsLoading(false);
      if (response.statusCode == 200) {
        alert(response?.success?.data);

        let data = await searchActiveProjectDetails(object);
        let res = data.success.data;
        if (data.statusCode === 200) {
          setIsLoading(false);
          setFilteredData(res);
        } else {
          setIsLoading(false);
        }
      } else {
        console.log(response?.error?.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const handleCheckboxChange = (event, id, l1) => {
    setCheckedRows((prev) => ({
      ...prev,
      [id]: event.target.checked,
    }));
    // setCheckedRowsL1((prev) => ({
    //   ...prev,
    //   [l1]: event.target.checked,
    // }));
  };

  const handleRunBMAll = async () => {
    const selectedIds=[]
    for (let key in checkedRows) {
      if(checkedRows[key])
        selectedIds.push(key)
    }
    if (selectedIds.length === 0) {
      alert("Select project(below grid) for BM Run");
      return;
    }
    const body = {
      projectIds: selectedIds,
      userId: sessionStorage.getItem("loginId"),
    };
     try{
      setIsLoading(true);
      let response = await RunBmAll(body);
      setIsLoading(false);
        if(response.statusCode==200){
        alert(response?.success?.message)

          let data = await searchActiveProjectDetails(object);
          let res = data.success.data;
          if (data.statusCode === 200) {
            setIsLoading(false);
            setFilteredData(res);
          } else {
            setIsLoading(false);
          }  
      }
      else{
        console.log(response?.error?.message)
      }
    }
    catch(error){
      setIsLoading(false)
        console.log(error);
      };
  };

  console.log(filteredData, "dwdwdwdwdwdwdwdwd");
  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      {filteredData?.length && (
        <div
          style={{
            float: "right",
            border: "1px solid #575656",
            display: "flex",
            flexDirection: "row",
            background: "#E4E4E4",
            borderRadius: "5px",
            color: "#575656",
            fontSize: "12px",
            padding: "10px",
            marginRight: "130px",
            marginBottom: "20px",
          }}
        >
           <div
            style={{ width: "auto", fontWeight: "600", marginRight: "20px",cursor:"pointer" }}
          >
            <img src={saveIcon} style={{ width: "30px", height: "30px" }} />
            <p>Save</p>
          </div>
          <div
            style={{ width: "auto", fontWeight: "600", marginRight: "20px",cursor:"pointer" }}
          >
            <img onClick={handleRunBMAll} src={playIcon} style={{ width: "30px", height: "30px" }} />
            <p>RunBM</p>
          </div>
          <div style={{ width: "auto", fontWeight: "600" }}>
            <img src={graphicon} style={{ width: "30px", height: "30px",cursor:"pointer" }} />
            <p>PortfolioChart</p>
          </div>
        </div>
      )}
      <br />
      <div>
        {!props.isLoading &&
          (filteredData?.length ? (
            <Table
              style={{ marginLeft: "3%", marginRight: "40px" }}
              className={modules["custom-table"]}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={selectAllChecked}
                      onChange={handleSelectAll}
                      className={modules["custom-checkbox"]}
                    />
                  </TableCell>
                  <TableCell>
                    Modif.
                    <br /> No
                  </TableCell>
                  <TableCell>Run BM</TableCell>
                  <TableCell>Events</TableCell>
                  <TableCell>Fever Chart</TableCell>
                  <TableCell>
                    Planned
                    <br />
                    Billing
                    <br />
                    Projection
                  </TableCell>
                  <TableCell>
                    View
                    <br />
                    Gantt
                    <br /> Chart
                  </TableCell>
                  <TableCell>
                    History
                    <br />
                  </TableCell>
                  <TableCell>
                    Pending
                    <br />
                    Tasks
                  </TableCell>
                  <TableCell>
                    Most
                    <br />
                    Penetrating
                    <br />
                    Task
                  </TableCell>
                  <TableCell>
                    Buffer
                    <br /> Recovery
                  </TableCell>
                  <TableCell>
                    Schedule
                    <br />
                    As on
                    <br /> Date
                  </TableCell>
                  <TableCell>
                    Project <br />
                    Id
                  </TableCell>
                  <TableCell>
                    Project
                    <br /> Name
                  </TableCell>
                  <TableCell>
                    Original
                    <br /> Buffer
                    <br /> Days
                  </TableCell>
                  <TableCell>
                    Buffer
                    <br /> Consumed
                    <br /> Days
                  </TableCell>
                  <TableCell>
                    % Buffer
                    <br /> consumed
                  </TableCell>
                  <TableCell>
                    % CC <br />
                    Completed
                  </TableCell>
                  <TableCell>
                    Original <br />
                    CC
                    <br /> Days
                  </TableCell>
                  <TableCell>
                    Project <br />
                    Manager
                  </TableCell>
                  <TableCell>
                    Project
                    <br /> Duration
                  </TableCell>
                  <TableCell>
                    Scheduled
                    <br /> Start Date
                  </TableCell>
                  <TableCell>
                    Scheduled
                    <br /> End Date
                  </TableCell>
                  <TableCell>
                    Expected <br />
                    Completion
                  </TableCell>
                  <TableCell>
                    Customer <br />
                    Due Date
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>
                    <pre>
                      Active Project
                      <br />
                      Status Change
                      <br />
                      Reason
                    </pre>
                  </TableCell>
                  <TableCell>Remarks</TableCell>
                  <TableCell>
                    Last <br />
                    BM Run
                  </TableCell>
                  <TableCell>
                    Task <br />
                    Last
                    <br /> Updated
                    <br /> On
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((item, index) => (
                  <TableRow key={index} className={modules["table-row"]}>
                    <TableCell>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleCheckboxChange(e, item.projectid)
                        }
                        checked={!!checkedRows[item.projectid]}
                        className={modules["custom-checkbox"]}
                      />
                    </TableCell>
                    <TableCell>{item["Modif Version"]}</TableCell>
                    <TableCell onClick={() => handleRunBM(item.projectid)}>
                      <img
                        src={playIcon}
                        style={{
                          width: "2rem",
                          height: "auto",
                          cursor: "pointer",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        window.open(
                          `/ccpm/EventPopup?projId=${item.projectid}`,
                          "sharer",
                          "toolbar=0,status=0,width=1050,height=600"
                        )
                      }
                    >
                      <img
                        src={pencilicon}
                        style={{
                          width: "2rem",
                          height: "auto",
                          cursor: "pointer",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <img
                        src={graphicon}
                        style={{
                          width: "3rem",
                          height: "auto",
                          cursor: "pointer",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        navigate(
                          `/ccpm/PlannedBillingProjection?projId=${item.projectid}`
                        )
                      }
                    >
                      <a
                        style={{ color: "#0000EE" }}
                        href="#"
                        className={modules["planned-a-hover"]}
                      >
                        Planned Billing Projection
                      </a>
                    </TableCell>
                    <TableCell onClick={() => navigate("")}>
                      <a
                        style={{ color: "#0000EE" }}
                        href="#"
                        className={modules["planned-a-hover"]}
                      >
                        Show Gantt Chart
                      </a>
                    </TableCell>

                    <TableCell
                      onClick={() => {
                        window.open(
                          `/ccpm/ViewActiveProjectsHistory?projId=${item.projectid}`,
                          "sharer",
                          "toolbar=0,status=0,width=1050,height=600"
                        );
                      }}
                    >
                      <a
                        style={{ color: "#0000EE", cursor: "pointer" }}
                        className={modules["planned-a-hover"]}
                      >
                        View History
                      </a>
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        window.open(
                          `/ccpm/PendingTaskView?projId=${item.projectid}`,
                          "sharer",
                          "toolbar=0,status=0,width=1050,height=600"
                        )
                      }
                    >
                      <a
                        href="#"
                        style={{ color: "#0000EE" }}
                        className={modules["planned-a-hover"]}
                      >
                        Pending Tasks
                      </a>
                    </TableCell>
                    <TableCell onClick={() => navigate("/")}>
                      <a
                        href="#"
                        style={{ color: "#0000EE" }}
                        className={modules["planned-a-hover"]}
                      >
                        Task-4
                      </a>
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        navigate("/CCPM/ActiveProjects/BufferRecovery")
                      }
                    >
                      <a
                        href="#"
                        style={{ color: "#0000EE" }}
                        className={modules["planned-a-hover"]}
                      >
                        Buffer Recovery
                      </a>
                    </TableCell>
                    <TableCell onClick={() => navigate("/")}>
                      <a
                        href="#"
                        style={{ color: "#0000EE" }}
                        className={modules["planned-a-hover"]}
                      >
                        Project Schedule as on Date
                      </a>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: `${item.color}` }}>
                      {item.projectid}
                    </TableCell>
                    <TableCell>{item.projectname}</TableCell>
                    <TableCell>{item.projectbuffer.toFixed(2)}</TableCell>
                    <TableCell>{item.projectbufferconsumed}</TableCell>
                    <TableCell>{item.projectbufferremaining}</TableCell>
                    <TableCell>{item.cccompleted.toFixed(2)}</TableCell>
                    <TableCell>{item.ccdaysoriginal.toFixed(2)}</TableCell>
                    <TableCell>{item.projectmanager}</TableCell>
                    <TableCell>{item.projectduration.toFixed(2)}</TableCell>
                    <TableCell>{item.projectstartdate}</TableCell>
                    <TableCell>{item.projectenddate}</TableCell>
                    <TableCell>{item.expectedprojectcompletiondate}</TableCell>
                    <TableCell>{item.customerduedate}</TableCell>
                    <TableCell>
                      <select
                        className={modules["dropdownSelect1"]}
                        //value={selectedProjectName}
                        // onChange={(e) => setSelectedProjectName(e.target.value)}
                      >
                        {statusDropdown.map((item) => (
                          <option value={item.projectstatus}>
                            {item.projectstatus}
                          </option>
                        ))}
                      </select>
                    </TableCell>
                    <TableCell>
                      <select
                        className={[
                          modules["dropdownSelect1"],
                          modules["select-width"],
                        ].join(" ")}
                        //value={selectedProjectName}
                        // onChange={(e) => setSelectedProjectName(e.target.value)}
                      >
                        {statusChangeDropdown.map((item) => (
                          <option value={item.reason}>{item.reason}</option>
                        ))}
                      </select>
                    </TableCell>
                    <TableCell>
                      <textarea
                        type="text"
                        style={{ width: "160px", height: "44px" ,paddingLeft:'1.8px',paddingTop:'1.8px'}}
                      />
                    </TableCell>
                    <TableCell>{item.lastbmrundate}</TableCell>
                    <TableCell>{item.lasttaskupdationdate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : filteredData?.length ? (
            ""
          ) : (
            <div
              style={{
                marginLeft: "3rem",
                marginTop: "1rem",
                marginBottom: "3rem",
              }}
              className={modules["bodyFont"]}
            >
              No Record Found.
            </div>
          ))}
      </div>
    </>
  );
};
export default ActiveProjectTable;
