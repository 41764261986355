import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SPBU1 from "../HelpPopups/images/SPBU1.png";
import SPBU2 from "../HelpPopups/images/SPBU2.png";
import SPBU3 from "../HelpPopups/images/SPBU3.png";
import SPBU4 from "../HelpPopups/images/SPBU4.png";
import SPBU5 from "../HelpPopups/images/SPBU5.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointBufferUploadhelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h2 style={{ marginLeft: "5rem", textAlign: "left" }}>
                                Stock-Point-Buffer – Upload
                            </h2>
                            <br />
                            <br />
                            <li className="no-bullets">
                                This field indicate buffer to be maintained for each item for stock point. User need to maintain Buffer for every item. User can upload and/or insert buffer from front end. Also, provision of uploading excel (.csv Format) is also given. User can do this from local machine. One file is uploaded then message of record updation will pop up. There is tab for downloading of the result file which give record for each and every item in the file including successful and unsuccessful record.
                            </li>
                            <br />
                            <li className="no-bullets">
                                If user is using downloaded file for updating and modifying then, before uploading the file he needs to match it with the format provided in sample format down load. User need to ensure columns are matching in both sheet else it will show error.
                            </li>
                            <br />
                            <li className="no-bullets">
                                Processing logic for uploading buffer –
                            </li>
                            <br />
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                In old process, while updating stock point buffer the uploaded file was replacing the old record and new records are saved. This means old records are replaced with new one. Now this logic
                            </li>
                            <br />
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                When buffer for any item is updated the firstly system will look in the existing record. If record is found, then the buffer for that item is updated.
                            </li>
                            <br />
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                If that item does not exist in the current list then item is added in the system.
                            </li>
                            <br />
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                If user maintain buffer as 0 (zero) then that record will get deleted from the system. But prior to deleting the record system will give pop message for the same. IF user select yes then only it will delete the record. If user select no then there will not be any change.
                            </li>
                            <br />
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                While updating from excel, user need to be careful. If zero is maintain in the buffer, then record will be deleted. There will not be any popup message for the same.
                            </li>
                            <br />
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                User can do insert and update from front end and as well as from excel report from local machine.
                            </li>
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPBU1} alt="SPBU1" />
                            </Stack>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPBU2} alt="SPBU2" />
                            </Stack>
                            <br />
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                When user select upload option above screen will appeared.                            </li>
                            <br />

                            <li className="no-bullets">
                                There are three options for user in front end.

                            </li>

                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                Add New record – User can add new item code from this option.
                            </li>
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                Update Record – User can update existing record from this option.                            </li>
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                User can upload from excel file from here for multiple records.</li>
                            <br />

                            <li className="no-bullets">
                                Add New Record -
                            </li>
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPBU3} alt="SPBU3" />
                            </Stack>
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                MTA Instance – A drop down is provided for the same. Once user select instance and select option of Add New record the details will be appeared.
                            </li>
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                Stock Point Code – Select Stock Point Code from drop down provided.
                            </li>
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                Company Code – Select Company Code from drop down provided.
                            </li>
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                Item Code – Select Item Code from drop down provided. If this record is existing for the combination of stock point code and company code, then a pop message will appear as record exist
                            </li>
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                If it is new record, then following screen will be appeared.
                            </li>
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPBU4} alt="SPBU4" />
                            </Stack>
                            <br />

                            <li style={{ marginLeft: "1.5rem" }}>
                                Buffer – Buffer is norm qty for that item. This field indicates for a particular item how much stock to be maintained at that stock point.
                            </li>
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                Minimum buffer Qty – This field indicates that the minimum qty to be stored as buffer. System will not allow to decrease buffer below this value. (For detail refer help of Stock point configuration). If value is not maintained the system will consider it as zero. This point is only applicable when user maintain DBM control parameter
                            </li>
                            <br />
                            <li className="no-bullets">
                                Suspension - New Buffer less than Minimum Buffer Qty – parameter is maintained as Yes. Else this point is not applicable.

                            </li>
                            <li style={{ marginLeft: "1.5rem" }}>
                                Maximum buffer Qty - This field indicates that the maximum qty to be stored as buffer. System will not allow to increase buffer above this value. (For detail refer help of Stock point configuration). If value is not maintained the system will consider it as zero. This point is only applicable when user maintain DBM control parameter
                            </li>
                            <br />
                            <li className="no-bullets">
                                DBM Suspension - New Buffer greater than Maximum Buffer Qty – parameter is maintained as yes. Else this point is not applicable.
                            </li>
                            <br />

                            <li style={{ marginLeft: "1.5rem" }}>
                                Minimum Buffer Value - This field indicates that the minimum value of Buffer to kept. System will not allow to decrease buffer below this value. (For detail refer help of Stock point configuration). If value is not maintained the system will consider is as zero. This point is only applicable when user maintain DBM control parameter                            </li>
                            <br />
                            <li style={{ marginLeft: "1.5rem" }}>
                                Maximum Buffer Value - This field indicates that the maximum value of Buffer to kept. System will not allow to increase buffer above this value. (For detail refer help of Stock point configuration). If value is not maintained the system will consider is as zero. This point is only applicable when user maintain DBM control parameter
                            </li>
                            <br />
                            <li className="no-bullets">
                                DBM Suspension - New Buffer less than Minimum Buffer Value – Parameter is maintained as Yes. Else this point is not applicable.

                            </li>
                            <br />

                            <br />
                            <li className="no-bullets">
                                Update Record -
                            </li>
                            <br />
                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPBU5} alt="SPBU5" />
                            </Stack>
                            <br />

                            <li className="no-bullets">
                                If data for the item is already available, then user can edit the values for all the field. If user is user maintain the buffer value as Zero then that Item will be deleted.


                            </li>
                            <br />
                            <li className="no-bullets">
                                User can update and edit record one by one from front end. But if user need to update or edit bulk records an excel upload facility if given. User can download the format put details is in the excel (.csv format only) and upload from local machine. Once excel file is uploaded then the confirmation message will be shown with all details. Upload result file is generated immediately once the file is uploaded. User can download and check the result file.
                            </li>
                            <br />
                            <li className="no-bullets">
                                If user maintain the zero buffer, then system will ask for confirmation for the deleting the records.


                            </li>
                            <br />
                            <li className="no-bullets" style={{ fontWeight: "bold" }}>
                                Calculation of Red and Yellow buffer qty: -


                            </li>
                            <br />
                            <li className="no-bullets">
                                When stock point buffer is being uploaded, system will first check if the Color Buffer Qty master is maintained or not. If buffers are maintained for the instance, then only for those buffers system will update Buffer red and Buffer yellow qty from the master.
                            </li>
                            <br />
                            <li className="no-bullets">
                                For rest of the buffers system will continue to update Red and Yellow buffer as per the percentage set in stock point configuration master.


                            </li>
                            <br />
                            <li className="no-bullets">
                                Also, if rounding will be done to the lower side while calculating all the buffers which are not maintained in the master. This will ensure correct calculation of color with respect to the buffer penetration percentage.

                            </li>
                            <br />




                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StockPointBufferUploadhelpPopup;
