import React, { useEffect, useState } from "react";
import "../../../HelpPopups/HelpPopup.css";
import Stack from "@mui/material/Stack";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import modules from '../../ccpm.module.css'
import { Grid } from "@mui/material";
import bread from "../../../../Assets/bread.png";
import { PendingTaskPopup, ViewPredecessorPopup, ViewRiskPopup, ViewSuccessorTable } from "../../../../Services/CCPM";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";

const RiskPopup = () => {
    const [data, setData] = useState([])
    const [riskDetails, setRiskDetails] = useState([]);
    const [successorNavData, setSuccessorNavData] = useState([])
    const [succssorTableData, setSuccssorTableData] = useState([])
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const projectId = parseInt(searchParams.get("projId"));
    // const selectedInstance = searchParams.get("selectedInstance");
    const [isLoading, setIsLoading] = useState(false);


    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
    const query = useQuery();
   const projectId = query.get('projId'); // Replace 'yourQueryKey' with your actual query key
   const taskId = query.get('taskId'); // Replace 'yourQueryKey' with your actual query key
  
    useEffect(() => {
        setIsLoading(true)
        let body = {
            instanceId: 18,
            projectId: 4244,
            userId: 2160
        }

        let body2 = {
            projectId: projectId,
            taskId: taskId
        }
        // let body = {
        //     instanceId: selectedInstance === '' || selectedInstance === 'Select' ? 0 : parseInt(selectedInstance),
        //     projectId: projectId,
        //     userId: parseInt(sessionStorage.getItem("loginId"))
        // }
        ViewRiskPopup(body)
            .then((response) => {
                console.log(response)
                if (response?.status === 200) {
                    setIsLoading(false)
                    setData(response?.data?.success?.data)
                    setRiskDetails(response?.data?.success?.data?.RiskDetails)
                }
                else if (response?.response?.data?.message !== undefined) {
                    setIsLoading(false);
                    alert(response?.response?.data?.message);
                } else if (response?.response?.data?.error?.message !== undefined) {
                    setIsLoading(false);
                    alert(response?.response?.data?.error?.message);
                } else {
                    setIsLoading(false);
                    alert(response?.response?.data?.success?.message);
                }
            })
            .catch((error) => {
                console.log(error)
            })

            ViewPredecessorPopup(body2)
            .then((response) => {
                console.log(response?.data?.success,'dwdc')
                if (response?.status === 200) {
                    setIsLoading(false)
                    setSuccessorNavData(response?.data?.success)
                }
            })
            .catch((error) => {
                console.log(error)
            })

            ViewSuccessorTable(body2)
            .then((response) => {
                console.log(response?.data?.success,'dwdc1212')
                if (response?.status === 200) {
                    setIsLoading(false)
                    setSuccssorTableData(response?.data?.success)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    return (
        <div style={{ overflowX: 'auto', height: '100vh' }}>
        {/* <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              paddingLeft: "1rem",
              fontWeight: "bold",
              fontFamily: "Times New Roman",
            }}
          >
            <img src={logo} alt="Godrej" />
          </Stack>
          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack> */}
         <div className={modules["centre-div"]}>
                <div>Predecessor</div>
         </div>
            <div style={{border:'2px solid black', marginLeft:'1%', padding:'2%', width:'94%'}}>
                <table className={modules["table-cpm"]} style={{width:'50%'}}>
                    <tbody  style={{ width: "100%" }}>
                        <tr style={{padding:'0px'}}>
                            <td>
                                <span className={modules["dateTextHeaderC"]} style={{ marginRight: "5px",color:'#000000' }}>
                                    Project Name: 
                                </span>
                                <span className={modules["dateTextHeaderC"]} style={{background:'#87CEEB',color:'#000000'}}>{successorNavData?.data?.[0]?.projectname  || "none"}</span>
                            </td>
                            <td>
                                <span className={modules["dateTextHeaderC"]} style={{ marginRight: "5px" ,color:'#000000'}}>
                                    Task Id:
                                </span>
                                <span className={modules["dateTextHeaderC"]} style={{background:'#87CEEB',color:'#000000'}}>{taskId}</span>
                            </td>
                            <td>
                                <span className={modules["dateTextHeaderC"]} style={{ marginRight: "5px",color:'#000000' }}>
                                    Task Name:
                                </span>
                                <span className={modules["dateTextHeaderC"]} style={{background:'#87CEEB',color:'#000000'}}>{successorNavData?.data?.[0]?.taskname  || "none"}</span>
                            </td>
                            <td>
                                <span className={modules["dateTextHeaderC"]} style={{ marginRight: "5px",color:'#000000' }}>
                                   Task Duration:
                                </span>
                                <span className={modules["dateTextHeaderC"]} style={{background:'#87CEEB',color:'#000000'}}>{successorNavData?.data?.[0]?.duration  || "none"}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* <div
                className={modules["table-overflow"]}
                style={{ marginLeft: "1%", width: '98%', marginTop:'0.3%' }}
            >
                <Table
                    className={[
                        modules["custom-table_viewpending"],
                        modules["custom-table-mp-th"]
                    ].join(" ")}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell >Task Manager</TableCell>
                            <TableCell >Task Name</TableCell>
                            <TableCell >Status</TableCell>
                            <TableCell>Color</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {succssorTableData?.data?.map((item) => {
                        return (
                        <TableRow className={modules["table-row"]} style={{ backgroundColor: '#DEDFDE' }}>
                        
                        <TableCell>{item?.taskmanager}</TableCell>
                        <TableCell >{item?.taskname}</TableCell>
                        <TableCell >planned</TableCell>
                        <TableCell sx={{backgroundColor:'black'}}></TableCell>
                    </TableRow>
                        )
                    })}

                    </TableBody>
                </Table>
            </div> */}
        </div>
    );
};

export default RiskPopup;