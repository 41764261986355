import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/UMA1.jpg";
import IC2 from "../HelpPopups/images/UMA2.jpg";
import IC3 from "../HelpPopups/images/UMA3.png";
import IC4 from "../HelpPopups/images/UMA4.png";
import { Link } from "react-router-dom";

const UserMasterhelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <div className="WordSection1">
              <p className="MsoNormal">
                <b>
                  <span
                    lang="EN-US"
                    style={{
                      fontSize: "16.0pt",
                      lineHeight: "107%",
                      fontFamily: '"Calibri Light", sans-serif',
                    }}
                  >
                    User Master
                  </span>
                </b>
              </p>

              <p style={{textDecoration:"underline"}}>
                <i>
                  <u>
                    <span
                      lang="EN-US"
                      style={{
                        fontSize: "16.0pt",
                        lineHeight: "107%",
                        fontFamily: '"Calibri Light", sans-serif',
                      }}
                    >
                      Session Path
                    </span>
                  </u>
                </i>
                <i>
                  <u>
                    <span
                      lang="EN-US"
                      style={{
                        fontSize: "16.0pt",
                        lineHeight: "107%",
                        fontFamily: "Wingdings",
                      }}
                    >
                      à
                    </span>
                  </u>
                </i>
                <i>
                  <u>
                    <span
                      lang="EN-US"
                      style={{
                        fontSize: "16.0pt",
                        lineHeight: "107%",
                        fontFamily: '"Calibri Light", sans-serif',
                      }}
                    >
                      Admin
                    </span>
                  </u>
                </i>
                <i>
                  <u>
                    <span
                      lang="EN-US"
                      style={{
                        fontSize: "16.0pt",
                        lineHeight: "107%",
                        fontFamily: "Wingdings",
                      }}
                    >
                      à
                    </span>
                  </u>
                </i>
                <i>
                  <u>
                    <span
                      lang="EN-US"
                      style={{
                        fontSize: "16.0pt",
                        lineHeight: "107%",
                        fontFamily: '"Calibri Light", sans-serif',
                      }}
                    >
                      User Master
                    </span>
                  </u>
                </i>
              </p>
                      <br/>
              <p
                className="MsoNormal"
                align="center"
                style={{ textAlign: "center" }}
              >
                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={IC1} alt="UMA1" style={{width:"80%", marginLeft:"22%"}} />
                </Stack>
              </p>

              <p className="MsoListParagraph" style={{ textIndent: "-12.0pt" }}>
                <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
                  ·
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  We can create multiple users needed in Prostream using the
                  “User Master” screen.
                </span>
              </p>

              <p className="MsoNormal">
                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={IC2} alt="UMA1" />
                </Stack>
              </p>

              <p
                className="MsoListParagraphCxSpFirst"
                style={{ textIndent: "-12.0pt" }}
              >
                <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
                  ·
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  For creating/Adding the new user, click on the “Add” button
                  and enter the details (User name, Login ID, Person Code, Email
                  ID, L+1, Active, required modules) and click on the “Save”
                  hyperlink. To undo the changes done or to terminate the
                  creating process we must click the “Cancel” hyperlink.
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ textIndent: "-12.0pt" }}
              >
               <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
                  ·
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">Explanation of Check box fields:</span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
              >
                <span lang="EN-US">
                  1.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  Email Authentication: It is checked for Godrej user and
                  un-checked for other. In case of checked value i.e. for Godrej
                  users, password will be same as system login password. Whilst
                  for un-checked values i.e. Non-Godrej users will have default
                  password as ‘Godrej@123’ and will need to change after first
                  login.
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
              >
                <span lang="EN-US">
                  2.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  Active: used for making the created user Active / Inactive
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
              >
                <span lang="EN-US">
                  3.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">CCPM: To Provide access to CCPM Module</span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
              >
                <span lang="EN-US">
                  4.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  MTAMTO: To Provide access to MTAMTO Module
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
              >
                <span lang="EN-US">
                  5.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">DSS: To Provide access to DSS Module</span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
              >
                <span lang="EN-US">
                  6.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  ADMIN: To provide admin access for user
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt", textIndent: "-18.0pt" }}
              >
                <span lang="EN-US">
                  7.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  Restrict Concurrent Login: If checked, it will restrict user
                  for multiple login at any given point of time.
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginLeft: "72.0pt" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "39.75pt",
                  textIndent: "-18.0pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
                  ·
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  For making any updates to the created users, click on “Edit”
                  hyperlink, do the needed changes and click on the “Update”
                  hyperlink.
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "39.75pt",
                  textIndent: "-18.0pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
                  ·
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  Once the user has been created, click on “User Level Mapping”.
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "39.75pt",
                  textIndent: "-18.0pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
                  ·
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  User Level Mapping is used for assigning the role and define
                  hierarchy levels of the organization to the user.
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "39.75pt",
                  textIndent: "-18.0pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
                  ·
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  Once the roles and hierarchy levels has been selected for the
                  user click on the “Save” button to save the changes.
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "39.75pt",
                  textIndent: "-18.0pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
                  ·
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  For deleting any user, click on the “Delete” hyperlink of
                  respective record. And for deleting multiple records at once
                  select the check boxes of respective users and click on the
                  “Delete” button, selected records will get deleted. A user
                  will not be deleted if he’s assigned to any active project.
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "39.75pt",
                  textIndent: "-18.0pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
                  ·
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  Search filter also provided for the user convenience for
                  searching any particular user easily, for removing the Search
                  criteria, click on “Reset” button and all the records will get
                  displayed.
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "39.75pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>

              <p
                className="MsoListParagraphCxSpLast"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "39.75pt",
                  textIndent: "-18.0pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ fontFamily: "Symbol" }}>
                  ·
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">Validations on the screen:</span>
              </p>

              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>

              <p
                className="MsoListParagraphCxSpFirst"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "63.8pt",
                  textIndent: "-18.0pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">
                  1.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  Duplication of existing record should not be allowed i.e.
                  re-adding of the existing record again.
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "63.8pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                align="center"
                style={{
                  margin: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={IC3} alt="UMA1" style={{width:"50%", marginLeft:"20%"}} />
                </Stack>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "63.8pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>

              <p
                className="MsoListParagraphCxSpLast"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "63.8pt",
                  textIndent: "-18.0pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">
                  2.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  User should not leave any mandatory input field blank, i.e.
                  fields marked *, before saving the data.
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>

              <p
                className="MsoListParagraphCxSpFirst"
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "63.8pt",
                  textIndent: "-18.0pt",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">
                  3.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span lang="EN-US">
                  User should make selection of the data before the click of
                  “Delete” button, as without selection of data the popup window
                  appears.
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>

              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={IC4} alt="UMA1" style={{width:"50%", marginLeft:"20%"}} />
                </Stack>
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserMasterhelpPopup;
