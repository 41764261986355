import React, { useState, useEffect, useRef, useCallback } from "react";
import { FaBars, FaTimes, FaAngleRight } from "react-icons/fa";

import admin from "../Assets/admin.png";
import CCPM from "../Assets/CCPM.png";
import MTA from "../Assets/MTA.png";
import MTO from "../Assets/MTO.png";
import DSS from "../Assets/DSS.png";
import { BiArrowBack } from "react-icons/bi";
import "./SampleSidebar.css";
import { useMenu } from "./MenuContext";
import { useNavigate } from "react-router-dom";

const SampleSidebar = () => {
  const [activeMenu, setActiveMenu] = useState("");
  const [activeSubMenu, setActiveSubMenu] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { menuVisibility, isLoading } = useMenu();
  const sidebarRef = useRef(null);

  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(url);
  };

  const toggleSidebar = useCallback(() => {
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
    setActiveMenu("");
    setActiveSubMenu("");
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        if (!isCollapsed) {
          toggleSidebar();
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCollapsed, toggleSidebar]);

  const handleSubMenuClick = (subMenuName) => {
    setActiveSubMenu(activeSubMenu === subMenuName ? "" : subMenuName);
  };

  const MenuItem = ({ name, children, icon }) => {
    const isActive = activeMenu === name;
    const handleMenuClick = () => {
      setActiveMenu(isActive ? "" : name);
      setActiveSubMenu("");
    };

    return (
      <div className={`menuItemContainer ${isActive ? "active" : ""}`}>
        <div className="menuItem" onClick={handleMenuClick}>
          {isCollapsed ? (
            <img src={icon} style={{ width: "19px" }} alt={`${name} icon`} />
          ) : (
            <>
              <span className="iconSS">
                <img
                  style={{ width: "19px" }}
                  src={icon}
                  alt={`${name} icon`}
                />
              </span>
              <span style={{ marginLeft: "20px", width: "80px" }}>{name}</span>
              <span
                style={{
                  float: "right",
                  position: "relative",
                  marginLeft: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FaAngleRight></FaAngleRight>
              </span>
            </>
          )}
        </div>
        {/* Render children (submenus) if current menu item is active */}
        {isActive &&
          (name === "DSS" ? (
            <div className="subMenu" style={{ top: "-102px" }}>
              {children}
            </div>
          ) : (
            <div className="subMenu">{children}</div>
          ))}
      </div>
    );
  };

  const SubMenuItem = ({ name, children, menuIndex, parentName }) => {
    const isActive = activeSubMenu === name;
    const pName = parentName;
    const isMTA = pName === "MTA";
const isMTO = pName === "MTO";
const isWindowHeightGreaterThan552 = window.innerHeight > 552;

const menuTopOffset = isCollapsed
  ? // Collapsed case
    isMTA
      ? isWindowHeightGreaterThan552
        ? -65 - menuIndex * 30   // MTA with window height > 552
        : -100 - menuIndex * 30  // MTA with window height <= 552
      : isMTO
      ? isWindowHeightGreaterThan552
        ? -85 - menuIndex * 30   // MTO with window height > 552
        : -110 - menuIndex * 30  // MTO with window height <= 552
      : isWindowHeightGreaterThan552
      ? -70 - 40 * menuIndex     // Default menu with window height > 552
      : -70 - 40 * menuIndex     // Default menu with window height <= 552
  : // Expanded case
    isMTA
      ? isWindowHeightGreaterThan552
        ? -70 - menuIndex * 30   // MTA with window height > 552
        : -150 - menuIndex * 30  // MTA with window height <= 552
      : isMTO
      ? isWindowHeightGreaterThan552
        ? -80 - menuIndex * 30   // MTO with window height > 552
        : -160 - menuIndex * 30  // MTO with window height <= 552
      : isWindowHeightGreaterThan552
      ? -70 - 40 * menuIndex     // Default menu with window height > 552
      : -70 - 40 * menuIndex;    // Default menu with window height <= 552


    const menuClass = isCollapsed ? "subSubMenuC" : "subSubMenu";

    return (
      <div className={`subMenuItemContainer ${isActive ? "active" : ""}`}>
        <div className="subMenuItem" onClick={() => handleSubMenuClick(name)}>
          {name}
          <span>
            <FaAngleRight></FaAngleRight>
          </span>
        </div>
        {isActive ? (
          <div className={menuClass} style={{ top: `${menuTopOffset}px` }}>
            {children}
          </div>
        ) : null}
      </div>
    );
  };

  const mastersSubMenus = [
    { name: "Instance Master", url: "/ccpm/instancemaster" },
    {
      name: "Instance Attribute Linking",
      url: "/ccpm/InstanceAttributeMapping",
    },
    { name: "Calendar", url: "/ccpm/Calendar" },
    { name: "Attribute Master", url: "/ccpm/AttributeMaster" },
    { name: "User Instance Linking", url: "/ccpm/UserInstaceLinking" },
    { name: "Customer Master", url: "/ccpm/CustomerMaster" },
    { name: "CCPM CONFIG SETTINGS", url: "/ccpm/ccpmconfigmaster" },
    { name: "Delay Master", url: "/ccpm/DelayMaster" },
    { name: "Task Mgmt Config", url: "/ccpm/TaskMgmtConfig" },
    { name: "Risk Type", url: "/ccpm/RiskTypeMaster" },
    { name: "Unit Master", url: "/ccpm/UnitMaster" },
    { name: "Procurement Category", url: "/ccpm/ProcurementCategory" },
    { name: "Product Config Settings", url: "/ccpm/productconfig" },
    { name: "Buffer Scheduler", url: "/ccpm/BufferScheduler" },
    { name: "Fever Chart Master", url: "/ccpm/FeverChartMaster" },
    { name: "WIP & Project Limit Master", url: "/ccpm/WIPLimitmaster" },
    { name: "Issue Category", url: "/ccpm/IssueCategory" },
    {
      name: "Active Project Status Change Reason Master",
      url: "/ccpm/activeprojectstatuschange",
    },
    {
      name: "Project Task Mgmt Email Config",
      url: "/ccpm/ProjectTaskMgmtEmailConfig",
    },
    {
      name: "Instance Division Department Linking",
      url: "/ccpm/instancedivisiondepartment",
    },
    { name: "Department Master", url: "/ccpm/DepartmentMaster" },
  ];

  const visibleItemsMasters = mastersSubMenus.filter(
    (report) => menuVisibility["CCPM"]?.subMenus["Masters"]?.pages[report.name]
  );

  const projectPlanSubMenus = [
    { name: "My Projects", url: "/ccpm/myprojects" },
    { name: "Create New Plan", url: "/ccpm/createnewplan" },
    { name: "Change Project Manager", url: "/ccpm/changeprojectmanager" },
    { name: "Alternate Project Manager", url: "/ccpm/alternateprojectmanager" },
    {
      name: "Alternate Task Manager",
      url: "/CCPM/ProjectPlan/AlternateTaskManager",
    },
    { name: "View Projects", url: "/ccpm/viewprojects" },
  ];

  const visibleItemsProject = projectPlanSubMenus.filter(
    (report) =>
      menuVisibility["CCPM"]?.subMenus["Project Plan"]?.pages[report.name]
  );

  const reports = [
    { name: "Issue Report", url: "/CCPM/Reports/IssueReport" },
    {
      name: "Delay Report for Pareto",
      url: "/CCPM/Reports/DelayReportforPareto",
    },
    {
      name: "Project wise % critical chain completed",
      url: "/CCPM/Reports/Projectwisecriticalchain",
    },
    { name: "Portfolio Chart", url: "/CCPM/Reports/Portfoliochart" },
    { name: "Project lead time", url: "/CCPM/Reports/ProjectLeadTime" },
    {
      name: "Task Wise Buffer Consumption",
      url: "/CCPM/Reports/TaskwiseBufferConsumption",
    },
    { name: "Load on Task Manager", url: "/CCPM/Reports/LoadOnTaskManager" },
    {
      name: "Project Schedule As On Current Date",
      url: "/CCPM/Reports/ProjectScheduleCurrentdate",
    },
    { name: "BM Report", url: "/CCPM/Reports/BMReport" },
    { name: "Daily Task Flow Trend", url: "/ccpm/dailytaskflowtrend" },
    { name: "Daily Sub Task Flow Trend", url: "/ccpm/dailysubtaskflowtrend" },
    { name: "Login Audit Report", url: "/ccpm/loginauditreport" },
    { name: "Project Count With Status", url: "/ccpm/projectcountwithstatus" },
    {
      name: "Project Completion On Time Report",
      url: "/ccpm/projectcompletionontimereport",
    },
    { name: "Task Taking More Time", url: "/ccpm/tasktakingmoretime" },
    { name: "Closed Project Report", url: "/ccpm/closedprojectreport" },
    { name: "PortFolio Chart Report", url: "/ccpm/portfoliochartreport" },
  ];

  // console.log(menuVisibility["CCPM"]?.subMenus["Reports"])

  const visibleItemsReports = reports.filter(
    (report) => menuVisibility["CCPM"]?.subMenus["Reports"]?.pages[report.name]
  );

  const additionalPages = [
    {
      name: "Department Load Report",
      url: "/CCPM/Reports/DepartmentLoadReport",
    },
    { name: "Planned Vs Actual", url: "/CCPM/Reports/PlannedVsActual" },
  ];

  const updatedVisibleItemsReports = [
    ...visibleItemsReports,
    ...additionalPages,
  ];

  const mtaParams = [
    { name: "New Instance", url: "/mtaparameter/newinstance" },
    {
      name: "Instance Active/InActive",
      url: "/mtaparameter/mtainstanceactive",
    },
    { name: "User Instance Linking", url: "/mtaparameter/userinstancelinking" },
    { name: "Stock Point Type", url: "/mtaparameter/StockPointType" },
    {
      name: "User Stock Point Linking",
      url: "/mtaparameter/UserStockPointLinking",
    },
    { name: "Stock Point", url: "/mtaparameter/StockPoint" },
    { name: "Settings Upload", url: "/mtaparameter/SettingsUpload" },
    {
      name: "Stock Point Configuration",
      url: "/mtaparameter/StockPointConfig",
    },
    { name: "Settings Download", url: "/mtaparameter/SettingsDownload" },
    { name: "Control", url: "/mtaparameter/Control" },
    { name: "Admin Control", url: "/mtaparameter/AdminControl" },
    { name: "Stock Point Warehouse", url: "/mtaparameter/StockPointWarehouse" },
    { name: "DBM", url: "/mtaparameter/DBM" },
    {
      name: "Stock Point Warehouse for Consumption",
      url: "/mtaparameter/StockPointWarehouseConsumption",
    },
    {
      name: "Inbound Order Advice - GnB CR",
      url: "/mtaparameter/InboundOrderAdvice",
    },
    {
      name: "Stock-Point-Buffer-Upload",
      url: "/mtaparameter/StockPointBufferUpload",
    },
    {
      name: "Stock-Point-Buffer-Download",
      url: "/mtaparameter/StockPointBufferDownload",
    },
    {
      name: "Stock Point Warehouse For InTransit",
      url: "/mtaparameter/StockPointWarehouseInTransit",
    },
    {
      name: "Stock Point Item Configuration Incremental",
      url: "/mtaparameter/StockPointItemConfigurationIncremental",
    },
    {
      name: "Stock Point Product Class Configuration Incremental",
      url: "/mtaparameter/StockPointProductClassConfigurationIncremental",
    },
    {
      name: "Stock Point Sales Statistical Group Configuration",
      url: "/mtaparameter/StockPointSalesStatisticalGroupConfiguration",
    },
    {
      name: "Stock Point Item Supplier Configuration",
      url: "/mtaparameter/StockPointItemSupplierConfiguration",
    },
    {
      name: "Stock Point Source Destination Warehouse Mapping",
      url: "/mtaparameter/StockPointSourceDestinationWarehouseMapping",
    },
    { name: "DBM Suspension", url: "/mtaparameter/DBMSuspension" },
    {
      name: "Stock Point Item Phase In Phase Out Seasonality",
      url: "/mtaparameter/StockPointItemPhaseInPhaseOutSeasonality",
    },
    {
      name: "Processing Automation",
      url: "/mtaparameter/ProcessingAutomation",
    },
  ];

  const visibleItemsMtaPar = mtaParams.filter(
    (item) => menuVisibility["MTA"]?.subMenus["MTA Parameter"]?.pages[item.name]
  );

  const mtaReports = [
    { name: "Reports", url: "/mtareports/Reports" },
    {
      name: "Items to be kept as MTA Report",
      url: "/mtareports/ItemskeptasMTAReport",
    },
    {
      name: "Buffer Penetration Report",
      url: "/mtareports/BufferPenetrationReport",
    },
    {
      name: "TOC-Color-Status Log-Report",
      url: "/mtareports/TOCColorStatusLogReport",
    },
    {
      name: "Daily Buffer Trend Report",
      url: "/mtareports/DailyBufferTrendReport",
    },
    { name: "MTA fILL Rate Report", url: "/mtareports/MTAfILLRateReport" },
    {
      name: "Pending Production Order Color Status Report",
      url: "/mtareports/PendingProductionOrderColorStatusReport",
    },
    {
      name: "Pending Purchase Order Color Status Report",
      url: "/mtareports/PendingPurchaseOrderColorStatusReport",
    },
    {
      name: "Spike Pattern Analysis Report",
      url: "/mtareports/SpikePatternAnalysisReport",
    },
    { name: "TOC Item Stock Graph", url: "/mtareports/TOCItemStockGraph" },
    {
      name: "Pending Production Order Operation Color Status Report",
      url: "/mtareports/PendingProductionOrderOperationColorStatusReport",
    },
    {
      name: "Work Center Summary Report",
      url: "/mtareports/WorkCenterSummaryReport",
    },
    {
      name: "MTD Inventory Transaction Report",
      url: "/mtareports/MTDInventoryTransactionReport",
    },
    {
      name: "Stock Availability Report for Top SKUs Report",
      url: "/mtareports/StockAvailabilityReportforTopSKUsReport",
    },
    {
      name: "Pending Sales Order having Spike Order Qty",
      url: "/mtareports/PendingSalesOrderhavingSpikeOrderQty",
    },
    { name: "Processing Log", url: "/mtareports/ProcessingLog" },
    {
      name: "Inound Order Status Report",
      url: "/mtareports/InoundOrderStatusReport",
    },
    { name: "Session History Report", url: "/mtareports/SessionHistoryReport" },
    {
      name: "Inventory Valuation Report",
      url: "/mtareports/InventoryValuationReport",
    },
  ];

  const visibleItemsMtaR = mtaReports.filter(
    (item) => menuVisibility["MTA"]?.subMenus["MTA Reports"]?.pages[item.name]
  );

  const mtoParams = [
    { name: "User CCR Linking", description: "User CCR Linking" },
    { name: "New Instance", description: "New Instance" },
    {
      name: "Instance Active/Inactive",
      description: "Instance Active/Inactive",
    },
    { name: "User Instance Linking", description: "User Instance Linking" },
    {
      name: "User Company Code Linking",
      description: "User Company Code Linking",
    },
    { name: "Buffer Category", description: "Buffer Category" },
    { name: "BUffer", description: "BUffer" },
    { name: "Location", description: "Location" },
    { name: "CCR", description: "CCR" },
    { name: "Category", description: "Category" },
    { name: "Non Working Date", description: "Non Working Date" },
    {
      name: "Allocation Priority based on Order Origin",
      description: "Allocation Priority based on Order Origin",
    },
    { name: "Settings Upload", description: "Settings Upload" },
    { name: "Settings Download", description: "Settings Download" },
    { name: "Control", description: "Control" },
    { name: "Admin Control", description: "Admin Control" },
    { name: "E-Commerce SO-TO Status", description: "E-Commerce SO-TO Status" },
    {
      name: "Department Authorization",
      description: "Department Authorization",
    },
    { name: "Department", description: "Department" },
    {
      name: "Instance Division Department Linking",
      description: "Instance Division Department Linking",
    },
  ];

  const visibleItemsMtoPar = mtoParams.filter(
    (item) => menuVisibility["MTO"]?.subMenus["MTO Parameter"]?.pages[item.name]
  );

  const mtoTrans = [
    { name: "Transaction Upload", url: "/MTOTransaction/TransactionUpload" },
    {
      name: "Transaction Download",
      url: "/MTOTransaction/TransactionDownload",
    },
    { name: "Order Booking Form", url: "/MTOTransaction/OrderBookingForm" },
    {
      name: "FG-Order-Booking-Form-Exception",
      url: "/MTOTransaction/FGOrderBookingFormException",
    },
    { name: "NO CCR Project", url: "/MTOTransaction/NOCCRProject" },
    {
      name: "NO CCR Project Details",
      url: "/MTOTransaction/NOCCRProjectDetails",
    },
    {
      name: "Department Clearance",
      url: "/MTOTransaction/DepartmentClearance",
    },
    { name: "Overall BM Report", url: "/MTOTransaction/OverallBMReport" },
    { name: "Dispatch Clearance", url: "/MTOTransaction/DispatchClearance" },
    {
      name: "Fullkit Poo Execution",
      url: "/MTOTransaction/FullkitPooExecution",
    },
    { name: "Monthly Billing Plan", url: "/MTOTransaction/MonthlyBillingPlan" },
    { name: "Logistics Planning", url: "/MTOTransaction/LogisticsPlanning" },
  ];

  const visibleItemsMtoTrans = mtoTrans.filter(
    (item) =>
      menuVisibility["MTO"]?.subMenus["MTO Transaction"]?.pages[item.name]
  );

  const mtoReports = [
    { name: "Reports", url: "/MTOReports/Reports" },
    {
      name: "OPOS - Overall BM Report",
      url: "/MTOReports/OPOSOverallBMReport",
    },
    { name: "Overall BM Report", url: "/MTOReports/OverallBMReport" },
    { name: "RM Report", url: "/MTOReports/RMReport" },
    {
      name: "NO CCR Project Colour Status Report",
      url: "/MTOReports/NOCCRProjectColourStatusReport",
    },
    {
      name: "NO-CCR-Project Colour Status Report",
      url: "/MTOReports/NOCCRProjectColourStatusReport",
    },
    { name: "NO CCR DDP Report", url: "/MTOReports/NOCCRDDPReport" },
    { name: "Lead Time Report", url: "/MTOReports/LeadTimeReport" },
    {
      name: "Overall BM Summary Report",
      url: "/MTOReports/OverallBMSummaryReport",
    },
    { name: "FOL Report", url: "/MTOReports/FQLReport" },
    {
      name: "Department Clearance Report",
      url: "/MTOReports/DepartmentClearanceReport",
    },
    {
      name: "Production OTIF Reports",
      url: "/MTOReports/ProductionOTIFReports",
    },
    { name: "Sales OTIF Reports", url: "/MTOReports/SalesOTIFReports" },
    {
      name: "OTIF Due Date Performance Reports",
      url: "/MTOReports/OTIFDueDatePerformanceReports",
    },
    {
      name: "BM and Future Order Report",
      url: "/MTOReports/BMandFutureOrderReport",
    },
    {
      name: "Department Clearance History Report",
      url: "/MTOReports/DepartmentClearanceHistoryReport",
    },
    {
      name: "Department Clearance Aggregate Report",
      url: "/MTOReports/DepartmentClearanceAggregateReport",
    },
    {
      name: "FullKit Po Status Report",
      url: "/MTOReports/FullKitPoStatusReport",
    },
    { name: "Processing Log", url: "/MTOReports/ProcessingLog" },
    {
      name: "Session History Report",
      url: "/MTOReports/MTOSessionHistoryReport",
    },
    { name: "Month Bill plan Report", url: "/MTOReports/MonthBillPlanReport" },
  ];

  const visibleItemsMtoRep = mtoReports.filter(
    (item) => menuVisibility["MTO"]?.subMenus["MTO Reports"]?.pages[item.name]
  );

  const dssReports = [
    { name: "Masters" },
    { name: "TVC" },
    { name: "Profitability Report" },
    { name: "Throughput" },
    { name: "TRD Loss" },
    { name: "TRD Loss Summary Report", url: "/reports/trdlosssummaryreport" },
    { name: "Dynamic Report" },
    { name: "Session History Report" },
  ];

  const visibleItemsDssRep = dssReports.filter(
    (item) => menuVisibility["DSS"]?.subMenus["Reports"]?.pages[item.name]
  );

  return (
    <div
      className={`sidebar ${isCollapsed ? "collapsed" : ""}`}
      ref={sidebarRef}
    >
      <div className="hamburger" onClick={toggleSidebar}>
        {isCollapsed ? (
          <span
            style={{
              display: "flex",
              width: "72%",
              justifyContent: "flex-end",
            }}
          >
            <span style={{ float: "right", position: "relative" }}>
              <FaBars style={{ width: "37px", height: "37px" }} />
            </span>
          </span>
        ) : (
          <span
            style={{
              display: "flex",
              paddingRight: "1rem",
              justifyContent: "flex-end",
            }}
          >
            <span style={{ float: "right", position: "relative" }}>
              <BiArrowBack style={{ width: "37px", height: "37px" }} />
            </span>
          </span>
        )}
      </div>
      {menuVisibility.Admin && (
        <MenuItem className="menuItemM" name="Admin" icon={admin}>
          {menuVisibility["Admin"].subMenus["Role Master"] && (
            <div
              className="menuItemLink"
              onClick={() => handleNavigation("/admin/rolemaster")}
              style={{ cursor: "pointer" }}
            >
              <a href="/admin/rolemaster">Role Master</a>
            </div>
          )}
          {menuVisibility["Admin"].subMenus["Role Permissions"] && (
            <div
              className="menuItemLink"
              onClick={() => handleNavigation("/admin/RolePermission")}
              style={{ cursor: "pointer" }}
            >
              <a href="/admin/RolePermission">Role Permission</a>
            </div>
          )}
          {menuVisibility["Admin"].subMenus["User Master"] && (
            <div
              className="menuItemLink"
              onClick={() => handleNavigation("/admin/usermaster")}
              style={{ cursor: "pointer" }}
            >
              <a href="/admin/usermaster">User Master</a>
            </div>
          )}
          {menuVisibility["Admin"].subMenus["Parent Role Permissions"] && (
            <div
              className="menuItemLink"
              onClick={() => handleNavigation("/admin/parentrolepermission")}
              style={{ cursor: "pointer" }}
            >
              <a href="/admin/parentrolepermission">Parent Role Permission</a>
            </div>
          )}
          {menuVisibility["Admin"].subMenus["Assign Parent Roles"] && (
            <div
              className="menuItemLink"
              onClick={() => handleNavigation("/admin/assignParentRole")}
              style={{ cursor: "pointer" }}
            >
              <a href="/admin/assignParentRole">Assign Parent Roles</a>
            </div>
          )}
          {menuVisibility["Admin"].subMenus["Change Password"] && (
            <div
              className="menuItemLink"
              onClick={() => handleNavigation("/admin/changepassword")}
              style={{ cursor: "pointer" }}
            >
              <a href="/admin/changepassword">Change Password</a>
            </div>
          )}
        </MenuItem>
      )}
      {menuVisibility.CCPM && (
        <MenuItem className="menuItemM" name="CCPM" icon={CCPM}>
          {menuVisibility["CCPM"].subMenus["Masters"] && (
            <SubMenuItem name="Masters" menuIndex={0}>
              <table className="ccpmmasterstable">
                {Array.from(
                  { length: Math.ceil(visibleItemsMasters.length / 5) },
                  (_, i) => (
                    <tr key={i}>
                      {visibleItemsMasters.slice(i * 5, i * 5 + 5).map(
                        (report) =>
                          menuVisibility["CCPM"]?.subMenus["Masters"]?.pages[
                            report.name
                          ] && (
                            <td
                              key={report.name}
                              onClick={() => handleNavigation(report.url)}
                              style={{ cursor: "pointer" }}
                            >
                              <a href={report.url}>{report.name}</a>
                            </td>
                          )
                      )}
                    </tr>
                  )
                )}
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["CCPM"].subMenus["Project Plan"] && (
            <SubMenuItem name="Project Plan" menuIndex={0.88}>
              <table className="ccpmmasterstable">
                {Array.from(
                  { length: Math.ceil(visibleItemsProject.length / 5) },
                  (_, i) => (
                    <tr key={i}>
                      {visibleItemsProject.slice(i * 5, i * 5 + 5).map(
                        (report) =>
                          menuVisibility["CCPM"]?.subMenus["Project Plan"]
                            ?.pages[report.name] && (
                            <td
                              key={report.name}
                              onClick={() => handleNavigation(report.url)}
                              style={{ cursor: "pointer" }}
                            >
                              <a href={report.url}>{report.name}</a>
                            </td>
                          )
                      )}
                    </tr>
                  )
                )}
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["CCPM"].subMenus["Active Projects"] && (
            <SubMenuItem name="Active Projects" menuIndex={1.76}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["CCPM"].subMenus["Active Projects"].pages[
                    "Active Projects"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/ccpm/ActiveProjects/ActiveProjects")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/ccpm/ActiveProjects/ActiveProjects">
                        Active Projects
                      </a>
                    </td>
                  )}
                  {menuVisibility["CCPM"].subMenus["Active Projects"].pages[
                    "Buffer Recovery"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/ccpm/ActiveProjects/BufferRecovery")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/ccpm/ActiveProjects/BufferRecovery">
                        Buffer Recovery
                      </a>
                    </td>
                  )}
                  {menuVisibility["CCPM"].subMenus["Active Projects"].pages[
                    "Modify Task Attribute"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation(
                          "/ccpm/ActiveProjects/ModifyTaskAttribute"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/ccpm/ActiveProjects/ModifyTaskAttribute">
                        Modify Task Attribute
                      </a>
                    </td>
                  )}
                  {menuVisibility["CCPM"].subMenus["Active Projects"].pages[
                    "Modify SubTask Attribute"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation(
                          "/ccpm/ActiveProjects/ModifySubTaskAttribute"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/ccpm/ActiveProjects/ModifySubTaskAttribute">
                        Modify SubTask Attribute
                      </a>
                    </td>
                  )}
                  {menuVisibility["CCPM"].subMenus["Active Projects"].pages[
                    "Complete Projects"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation(
                          "/ccpm/ActiveProjects/CompleteProjects"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/ccpm/ActiveProjects/CompleteProjects">
                        Complete Projects
                      </a>
                    </td>
                  )}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["CCPM"].subMenus["Task Management"] && (
            <SubMenuItem name="Task Management" menuIndex={2.65}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["CCPM"].subMenus["Task Management"].pages[
                    "Task Management"
                  ] && (
                    <td
                      onClick={() => handleNavigation("/ccpm/taskmanagement")}
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/ccpm/taskmanagement">Task Management</a>
                    </td>
                  )}
                  {menuVisibility["CCPM"].subMenus["Task Management"].pages[
                    "View Tasks"
                  ] && (
                    <td onClick={() => handleNavigation("/ccpm/viewtasks")} style={{ cursor: "pointer" }}>
                      <a href="/ccpm/viewtasks">View Tasks</a>
                    </td>
                  )}
                  {menuVisibility["CCPM"].subMenus["Task Management"].pages[
                    "Issue List"
                  ] && (
                    <td onClick={() => handleNavigation("/ccpm/issuelist")} style={{ cursor: "pointer" }}>
                      <a href="/ccpm/issuelist">IssueList</a>
                    </td>
                  )}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["CCPM"].subMenus["Risk Management"] && (
            <SubMenuItem name="Risk Management" menuIndex={3.53}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["CCPM"].subMenus["Risk Management"].pages[
                    "Risk And Mitigation Plan"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/ccpm/riskandmitigationplan")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/ccpm/riskandmitigationplan">
                        Risk And Mitigation Plan
                      </a>
                    </td>
                  )}
                  {menuVisibility["CCPM"].subMenus["Risk Management"].pages[
                    "Risk Action Owner"
                  ] && (
                    <td
                      onClick={() => handleNavigation("/ccpm/riskactionowner")}
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/ccpm/riskactionowner"> Risk Action Owner</a>
                    </td>
                  )}
                  {menuVisibility["CCPM"].subMenus["Risk Management"].pages[
                    "Risk Review"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/CCPM/RiskManagement/RiskReview")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/CCPM/RiskManagement/RiskReview">Risk Review</a>
                    </td>
                  )}
                  {menuVisibility["CCPM"].subMenus["Risk Management"].pages[
                    "Risk Monitor Report"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation(
                          "/CCPM/RiskManagement/RiskMonitorReport"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/CCPM/RiskManagement/RiskMonitorReport">
                        Risk Monitor Report
                      </a>
                    </td>
                  )}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["CCPM"].subMenus["Reports"] && (
            <SubMenuItem name="Reports" menuIndex={4.41}>
              <table className="ccpmmasterstable">
                {Array.from(
                  { length: Math.ceil(updatedVisibleItemsReports.length / 5) },
                  (_, i) => (
                    <tr key={i}>
                      {updatedVisibleItemsReports.slice(i * 5, i * 5 + 5).map(
                        (report) =>
                          menuVisibility["CCPM"]?.subMenus["Reports"]?.pages[
                            report.name
                          ] && (
                            <td
                              key={report.name}
                              onClick={() => handleNavigation(report.url)}
                              style={{ cursor: "pointer" }}
                            >
                              <a href={report.url}>{report.name}</a>
                            </td>
                          )
                      )}
                    </tr>
                  )
                )}
              </table>
            </SubMenuItem>
          )}
        </MenuItem>
      )}
      {menuVisibility.MTA && (
        <MenuItem className="menuItemM" name="MTA" icon={MTA}>
          {menuVisibility["MTA"].subMenus["MTA Parameter"] && (
            <SubMenuItem parentName="MTA" name="MTA Parameter" menuIndex={0}>
              <table className="ccpmmasterstable">
                {Array.from(
                  { length: Math.ceil(visibleItemsMtaPar.length / 5) },
                  (_, i) => (
                    <tr key={i}>
                      {visibleItemsMtaPar.slice(i * 5, i * 5 + 5).map(
                        (report) =>
                          menuVisibility["MTA"]?.subMenus["MTA Parameter"]
                            ?.pages[report.name] && (
                            <td
                              key={report.name}
                              onClick={() => handleNavigation(report.url)}
                              style={{ cursor: "pointer" }}
                            >
                              <a href={report.url}>{report.name}</a>
                            </td>
                          )
                      )}
                    </tr>
                  )
                )}
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["MTA"].subMenus["MTA Master"] && (
            <SubMenuItem parentName="MTA" name="MTA Master" menuIndex={1.18}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["MTA"].subMenus["MTA Master"].pages[
                    "Master Upload"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/mtamaster/MasterUpload")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/mtamaster/MasterUpload">Master Upload</a>
                    </td>
                  )}
                  {menuVisibility["MTA"].subMenus["MTA Master"].pages[
                    "Instance Division Department Linking"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation(
                          "/mtamaster/InstanceDivisionDepartmentLinking"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/mtamaster/InstanceDivisionDepartmentLinking">
                        Instance Division Department Linking
                      </a>
                    </td>
                  )}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["MTA"].subMenus["MTA Transaction"] && (
            <SubMenuItem parentName="MTA" name="MTA Transaction" menuIndex={2.36}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["MTA"].subMenus["MTA Transaction"].pages[
                    "Transaction Upload"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/mtatransaction/MTATransactionUpload")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/mtatransaction/MTATransactionUpload">
                        Transaction Upload
                      </a>
                    </td>
                  )}
                  {menuVisibility["MTA"].subMenus["MTA Transaction"].pages[
                    "Transaction Download"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation(
                          "/mtatransaction/MTATransactionDownload"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/mtatransaction/MTATransactionDownload">
                        Transaction Download
                      </a>
                    </td>
                  )}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["MTA"].subMenus["MTA Process"] && (
            <SubMenuItem parentName="MTA" name="MTA Process" menuIndex={3.54}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["MTA"].subMenus["MTA Process"].pages[
                    "Processing"
                  ] && <td>Processing</td>}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["MTA"].subMenus["MTA Reports"] && (
            <SubMenuItem parentName="MTA" name="MTA Reports" menuIndex={4.72}>
              <table className="ccpmmasterstable">
                {Array.from(
                  { length: Math.ceil(visibleItemsMtaR.length / 5) },
                  (_, i) => (
                    <tr key={i}>
                      {visibleItemsMtaR.slice(i * 5, i * 5 + 5).map(
                        (report) =>
                          menuVisibility["CCPM"]?.subMenus["MTA Parameter"]
                            ?.pages[report.name] && (
                            <td
                              key={report.name}
                              onClick={() => handleNavigation(report.url)}
                              style={{ cursor: "pointer" }}
                            >
                              <a href={report.url}>{report.name}</a>
                            </td>
                          )
                      )}
                    </tr>
                  )
                )}
              </table>
            </SubMenuItem>
          )}
        </MenuItem>
      )}
      {menuVisibility.MTO && (
        <MenuItem className="menuItemM" name="MTO" icon={MTO}>
          {menuVisibility["MTO"].subMenus["MTO Parameter"] && (
            <SubMenuItem parentName="MTO" name="MTO Parameter" menuIndex={0}>
              <table className="ccpmmasterstable">
                {Array.from(
                  { length: Math.ceil(visibleItemsMtoPar.length / 5) },
                  (_, i) => (
                    <tr key={i}>
                      {visibleItemsMtoPar.slice(i * 5, i * 5 + 5).map(
                        (report) =>
                          menuVisibility["MTO"]?.subMenus["MTO Parameter"]
                            ?.pages[report.name] && (
                            <td
                              key={report.name}
                              onClick={() => handleNavigation(report.url)}
                              style={{ cursor: "pointer" }}
                            >
                              <a href={report.url}>{report.name}</a>
                            </td>
                          )
                      )}
                    </tr>
                  )
                )}
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["MTO"].subMenus["MTO Transaction"] && (
            <SubMenuItem parentName="MTO" name="MTO Transaction" menuIndex={1.18}>
              <table className="ccpmmasterstable">
                {Array.from(
                  { length: Math.ceil(visibleItemsMtoTrans.length / 5) },
                  (_, i) => (
                    <tr key={i}>
                      {visibleItemsMtoTrans.slice(i * 5, i * 5 + 5).map(
                        (report) =>
                          menuVisibility["MTO"]?.subMenus["MTO Transaction"]
                            ?.pages[report.name] && (
                            <td
                              key={report.name}
                              onClick={() => handleNavigation(report.url)}
                              style={{ cursor: "pointer" }}
                            >
                              <a href={report.url}>{report.name}</a>
                            </td>
                          )
                      )}
                    </tr>
                  )
                )}
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["MTO"].subMenus["MTO Process"] && (
            <SubMenuItem parentName="MTO" name="MTO Process" menuIndex={2.36}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["MTO"].subMenus["MTO Process"].pages[
                    "Processing"
                  ] && (
                    <td
                      onClick={() => handleNavigation("/MTOProcess/Processing")}
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/MTOProcess/Processing">Processing</a>
                    </td>
                  )}
                  {menuVisibility["MTO"].subMenus["MTO Process"].pages[
                    "OPOS - Release Control"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/MTOProcess/OPOSReleaseControl")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/MTOProcess/OPOSReleaseControl">
                        OPOSReleaseControl
                      </a>
                    </td>
                  )}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["MTO"].subMenus["MTO Reports"] && (
            <SubMenuItem parentName="MTO" name="MTO Reports" menuIndex={3.54}>
              <table className="ccpmmasterstable">
                {Array.from(
                  { length: Math.ceil(visibleItemsMtoRep.length / 5) },
                  (_, i) => (
                    <tr key={i}>
                      {visibleItemsMtoRep.slice(i * 5, i * 5 + 5).map(
                        (report) =>
                          menuVisibility["MTO"]?.subMenus["MTO Reports"]?.pages[
                            report.name
                          ] && (
                            <td
                              key={report.name}
                              onClick={() => handleNavigation(report.url)}
                              style={{ cursor: "pointer" }}
                            >
                              <a href={report.url}>{report.name}</a>
                            </td>
                          )
                      )}
                    </tr>
                  )
                )}
              </table>
            </SubMenuItem>
          )}
        </MenuItem>
      )}
      {menuVisibility.DSS && (
        <MenuItem className="menuItemM" name="DSS" icon={DSS} menuIndex={4}>
          {menuVisibility["DSS"].subMenus["Admin"] && (
            <SubMenuItem name="Admin" menuIndex={0}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["DSS"].subMenus["Admin"].pages[
                    "New Instance"
                  ] && (
                    <td
                      onClick={() => handleNavigation("/dssadmin/newinstance")}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <a href="/dssadmin/newinstance">New Instance</a>
                    </td>
                  )}
                  {menuVisibility["DSS"].subMenus["Admin"].pages[
                    "Instance Active/InActive"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/dssadmin/InstanceActiveInActive")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <a href="/dssadmin/InstanceActiveInActive">
                        Instance Active/InActive
                      </a>
                    </td>
                  )}
                  {menuVisibility["DSS"].subMenus["Admin"].pages[
                    "User Instance Linking"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/admin/userinstancelinking")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/admin/userinstancelinking">
                        User Instance Linking
                      </a>
                    </td>
                  )}
                  {menuVisibility["DSS"].subMenus["Admin"].pages[
                    "Instance Wise Job Scheduling"
                  ] && <td>Instance Wise Job Scheduling</td>}
                  {menuVisibility["DSS"].subMenus["Admin"].pages[
                    "User Settings"
                  ] && (
                    <td onClick={() => handleNavigation("/dssadmin/settings")} style={{ cursor: "pointer" }}>
                      <a href="/dssadmin/settings">User Settings</a>
                    </td>
                  )}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["DSS"].subMenus["Masters"] && (
            <SubMenuItem name="Masters" menuIndex={0.88}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["DSS"].subMenus["Masters"].pages[
                    "Master Definition"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/master/masterdefinition")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/master/masterdefinition">Master Definition</a>
                    </td>
                  )}
                  {menuVisibility["DSS"].subMenus["Masters"].pages[
                    "Hierarchies"
                  ] && (
                    <td onClick={() => handleNavigation("/master/hierarchies")} style={{ cursor: "pointer" }}>
                      <a href="/master/hierarchies">Hierarchies</a>
                    </td>
                  )}
                  {menuVisibility["DSS"].subMenus["Masters"].pages[
                    "Master Linking"
                  ] && (
                    <td
                      onClick={() => handleNavigation("/master/masterlinking")}
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/master/masterlinking">Master Linking</a>
                    </td>
                  )}
                  {menuVisibility["DSS"].subMenus["Masters"].pages[
                    "Data Source"
                  ] && (
                    <td onClick={() => handleNavigation("/master/datasource")} style={{ cursor: "pointer" }}>
                      <a href="/master/datasource">Data Source</a>
                    </td>
                  )}
                  {menuVisibility["DSS"].subMenus["Masters"].pages[
                    "Instance Division Department Linking"
                  ] && (
                    <td onClick={() => handleNavigation("/master/datasource")} style={{ cursor: "pointer" }}>
                      <a href="/master/datasource">
                        Instance Division Department Linking
                      </a>
                    </td>
                  )}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["DSS"].subMenus["Maintain Data"] && (
            <SubMenuItem name="Maintain Data" menuIndex={1.77}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["DSS"].subMenus["Maintain Data"].pages[
                    "Upload Data"
                  ] && <td>Upload Data</td>}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["DSS"].subMenus["TVC"] && (
            <SubMenuItem name="TVC" menuIndex={2.64}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["DSS"].subMenus["TVC"].pages[
                    "Calculate TVC"
                  ] && <td>Calculate TVC</td>}
                  {menuVisibility["DSS"].subMenus["TVC"].pages[
                    "Freeze TVC"
                  ] && <td>Freeze TVC</td>}
                  {menuVisibility["DSS"].subMenus["TVC"].pages[
                    "Unfreeze TVC"
                  ] && <td>Unfreeze TVC</td>}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["DSS"].subMenus["COGS"] && (
            <SubMenuItem name="COGS" menuIndex={3.52}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["DSS"].subMenus["COGS"].pages[
                    "Process Profitability Report"
                  ] && <td>Process Profitability Report</td>}
                  {menuVisibility["DSS"].subMenus["COGS"].pages[
                    "Freeze Profitability Report"
                  ] && <td>Freeze Profitability Report</td>}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["DSS"].subMenus["MAUC"] && (
            <SubMenuItem name="MAUC" menuIndex={4.4}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["DSS"].subMenus["MAUC"].pages[
                    "Process MAUC Tracker Report"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/dssmauc/processtrackerreport")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/dssmauc/processtrackerreport">
                        Process MAUC Tracker Report
                      </a>
                    </td>
                  )}
                  {menuVisibility["DSS"].subMenus["MAUC"].pages[
                    "MAUC Tracker Report"
                  ] && (
                    <td
                      onClick={() => handleNavigation("/dssmauc/reporttracker")}
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/dssmauc/reporttracker">MAUC Tracker Report</a>
                    </td>
                  )}
                  {menuVisibility["DSS"].subMenus["MAUC"].pages[
                    "Material Category wise Threshold"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/dssmauc/materialthreshold")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/dssmauc/materialthreshold">
                        Material Category wise Threshold
                      </a>
                    </td>
                  )}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["DSS"].subMenus["Throughput"] && (
            <SubMenuItem name="Throughput" menuIndex={5.28}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["DSS"].subMenus["Throughput"].pages[
                    "Calculate Throughput"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/dssthroughput/calculatethroughput")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/dssthroughput/calculatethroughput">
                        Calculate Throughput
                      </a>
                    </td>
                  )}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["DSS"].subMenus["TRD Loss"] && (
            <SubMenuItem name="TRD Loss" menuIndex={6.16}>
              <table className="ccpmmasterstable">
                <tr>
                  {menuVisibility["DSS"].subMenus["TRD Loss"].pages[
                    "Maintain Factor"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/trdloss/maintainfactor")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/trdloss/maintainfactor">Maintain Factor</a>
                    </td>
                  )}
                  {menuVisibility["DSS"].subMenus["TRD Loss"].pages[
                    "Project Short Close"
                  ] && <td>Project Short Close</td>}
                  {menuVisibility["DSS"].subMenus["TRD Loss"].pages[
                    "Calculate TRD Loss"
                  ] && <td>Calculate TRD Loss</td>}
                  {menuVisibility["DSS"].subMenus["TRD Loss"].pages[
                    "Calculate TRD Loss Summary"
                  ] && (
                    <td
                      onClick={() =>
                        handleNavigation("/trdloss/calculatetrdlosssummary")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <a href="/trdloss/calculatetrdlosssummary">
                        Calculate TRD Loss Summary
                      </a>
                    </td>
                  )}
                </tr>
              </table>
            </SubMenuItem>
          )}
          {menuVisibility["DSS"].subMenus["Reports"] && (
            <SubMenuItem name="Reports" menuIndex={7.04 }>
              <table className="ccpmmasterstable">
                {Array.from(
                  { length: Math.ceil(visibleItemsDssRep.length / 5) },
                  (_, i) => (
                    <tr key={i}>
                      {visibleItemsDssRep.slice(i * 5, i * 5 + 5).map(
                        (report) =>
                          menuVisibility["DSS"]?.subMenus["Reports"]?.pages[
                            report.name
                          ] && (
                            <td
                              key={report.name}
                              onClick={() => handleNavigation(report.url)}
                              style={{ cursor: "pointer" }}
                            >
                              <a href={report.url}>{report.name}</a>
                            </td>
                          )
                      )}
                    </tr>
                  )
                )}
              </table>
            </SubMenuItem>
          )}
        </MenuItem>
      )}
    </div>
  );
};

export default SampleSidebar;
