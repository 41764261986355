import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// Utility function to format the date as DD/MM/YYYY
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1 is needed
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
};

const ProjectTable = ({ data }) => {
  const customCellStyle = {
    
    height: '10px', // Reduce height
  
  };

  return (
    <>
      <Table className="custom-table">
        <TableHead className="custom-table-head">
          <TableRow>
            <TableCell style={customCellStyle}>BM Run<br />Date</TableCell>
            <TableCell style={customCellStyle}>%PB<br />Consumed</TableCell>
            <TableCell style={customCellStyle}>%CC<br />Completion</TableCell>
            <TableCell style={customCellStyle}>Most<br />Penetrating<br />Task</TableCell>
            <TableCell style={customCellStyle}>Task Manager</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            // Condition to check if project buffer consumption is more than 100
            const isBufferConsumedHigh = row.projectbuffercosnsumption > 100;

            return (
              <TableRow key={index}>
                {/* Apply black background if project buffer consumption is more than 100 */}
                <TableCell
                  sx={{
                    ...customCellStyle,
                    backgroundColor: isBufferConsumedHigh ? 'black' : 'inherit',
                    color: isBufferConsumedHigh ? 'white' : 'inherit',
                  }}
                >
                  {formatDate(row.lastbmrundate)}
                </TableCell>
                <TableCell sx={customCellStyle}>{row.projectbuffercosnsumption}</TableCell>
                <TableCell sx={customCellStyle}>{row.criticalchaincompletion}</TableCell>
                <TableCell sx={customCellStyle}>{row.mostpentask}</TableCell>
                <TableCell sx={customCellStyle}>{row.taskmgrname}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default ProjectTable;
