import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import { Grid, FormControl } from "@mui/material";
import bread from "../../../Assets/bread.png";
import { Set_NewInstance } from "../../../Services/Dss";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";

export default function SettingsUpload() {
  const [data, setData] = useState({
    instname: "",
    instdesc: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInstanceId, setSelectedInstanceId] = useState("");
  const [dropdownData, setDropdownData] = useState([]);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
  }, []);

  function submitHandler() {
    const body = {
      instanceUID: data.instname,
      userID: sessionStorage.getItem("loginId"),
      recordType: "Insert",
      instanceDescription: data.instdesc,
    };
    setIsLoading(true);
    if (data.instname) {
      Set_NewInstance(body)
        .then((response) => {
          console.log(response?.data?.success?.data);
          alert("Instance Inserted");
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Enter Instance");
    }
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"SettingsUpload"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>Settings Upload</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table
                className={modules["customTable"]}
                style={{ marginLeft: "3rem" }}
              >
                <tbody>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "6rem", fontWeight: "bold" }}>
                      MTA Instance
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          // value={selectedInstanceId}
                          // onChange={(e) => {
                          //   const selectedId =
                          //     e.target.options[
                          //       e.target.selectedIndex
                          //     ].getAttribute("data-id");
                          //   setSelectedInstanceId(e.target.value);
                          //   setSelectedInstance(selectedId);
                          //   getTableData(e.target.value);
                          // }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          // disabled={editing}
                        >
                          {/* <option value={"SELECT"}>SELECT</option> */}
                          {dropdownData.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mta_instance_uid}
                                key={i.id}
                                data-id={i.id}
                              >
                                {i.mta_instance_uid}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "6rem", fontWeight: "bold" }}>
                      Parameter
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          // value={selectedInstanceId}
                          // onChange={(e) => {
                          //   const selectedId =
                          //     e.target.options[
                          //       e.target.selectedIndex
                          //     ].getAttribute("data-id");
                          //   setSelectedInstanceId(e.target.value);
                          //   setSelectedInstance(selectedId);
                          //   getTableData(e.target.value);
                          // }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          // disabled={editing}
                        >
                          {/* <option value={"SELECT"}>SELECT</option> */}
                          {dropdownData.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mta_instance_uid}
                                key={i.id}
                                data-id={i.id}
                              >
                                {i.mta_instance_uid}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "6rem", fontWeight: "bold" }}>
                      Upload
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          // value={selectedInstanceId}
                          // onChange={(e) => {
                          //   const selectedId =
                          //     e.target.options[
                          //       e.target.selectedIndex
                          //     ].getAttribute("data-id");
                          //   setSelectedInstanceId(e.target.value);
                          //   setSelectedInstance(selectedId);
                          //   getTableData(e.target.value);
                          // }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          // disabled={editing}
                        >
                          {/* <option value={"SELECT"}>SELECT</option> */}
                          {dropdownData.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mta_instance_uid}
                                key={i.id}
                                data-id={i.id}
                              >
                                {i.mta_instance_uid}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Grid
                container
                className={modules["mid-cont"]}
                style={{ marginLeft: "11rem", marginTop: "0rem" }}
              >
                <button className={modules["btn1"]} onClick={submitHandler}>
                  Upload
                </button>
                <button
                  className={modules["btn1"]}
                  style={{ marginLeft: "20px" }}
                  // onClick={submitHandler}
                >
                  Close
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
