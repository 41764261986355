import React2, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { LoadFeverChartProjectDetails, LoadFeverChart } from "../../../../Services/CCPM";
import "./feverchart.module.css";
import DataTable from "./feverchartTable";
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';

const FeverChart = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projId = parseInt(searchParams.get("projId"));
  const [data, setData] = useState([]);
  const [projectdata,setProjectData]=useState([])
  const [maxYAxis,setMaxYAxis]=useState(100);
  let responseData;

  useEffect(() => {
    handleFeverChart();
  }, []);

  const handleFeverChart = async () => {
    let obj = {
      projectId: projId
    };

    let feverchartdata = await LoadFeverChart(obj);
    const getResponse = feverchartdata?.data?.success?.data;
    responseData = getResponse;
    LoadFeverChartProjectData();
    if (window.Chartist) {
      loadChartist();
    } else {
      window.addEventListener("load", loadChartist);
    }
  };

  const loadChartist = () => {
    if (window.Chartist) {
      const Chartist = window.Chartist;

      const options = {
        fullWidth: false,
        axisX: {
          high: 60,
          low: 0,
          type: Chartist.AutoScaleAxis,
          onlyInteger: true,
          scaleMinSpace: 10,
          step: 10, 
        },
        axisY: {
          high: maxYAxis,
          low: 0,
          scaleMinSpace: 5,
          onlyInteger: true,
        },
        series: {
          "bg-green": {
            showArea: true,
            showLine: false,
            showPoint: false,
          },
          "bg-yellow": {
            showArea: true,
            showLine: false,
            showPoint: false,
          },
          "bg-red": {
            showArea: true,
            showLine: false,
            showPoint: false,
          },
          "bg-black": {
            showArea: true,
            showLine: false,
            showPoint: false,
          },
          fever: {
            showArea: false,
            showLine: true,
            showPoint: true,
          },
        },
      };

      const data = {
        series: [
          {
            name: "bg-black",
            data: [
              { x: 0, y: maxYAxis },
              { x: 60, y: maxYAxis },
            ],
          },
          {
            name: "bg-red",
            data: [
              { x: 0, y: 100 },
              { x: 60, y: 100},
            ],
          },
          {
            name: "bg-yellow",
            data: [
              { x: 0, y: responseData.y[0].warningstartpoint },
              { x: 60, y: responseData.y[0].warningendpoint },
            ],
          },
          {
            name: "bg-green",
            data: [
              { x: 0, y: responseData.y[0].safestartpoint },
              { x: 60, y: responseData.y[0].safeendpoint },
            ],
          },
          {
            name: "fever",
            data: responseData.x.map((data) => ({
              x: data.criticalchaincompletion,
              y: data.projectbufferconsumed,
            })),
          },
        ],
      };

      const chart = new Chartist.Line("#bufferChart", data, options);

      const tooltip = document.createElement("div");
      tooltip.className = "chart-tooltip";
      document.body.appendChild(tooltip);

      chart.on("draw", (data) => {
        if (data.type === "point") {
          data.element._node.addEventListener("mouseover", (e) => {
            tooltip.style.display = "block";
            tooltip.style.left = `${e.pageX + 10}px`;
            tooltip.style.top = `${e.pageY + 10}px`;
            tooltip.innerHTML = `X: ${data.value.x}, Y: ${data.value.y}`;
          });

          data.element._node.addEventListener("mouseout", () => {
            tooltip.style.display = "none";
          });
        }
      });
    } else {
      console.error("Chartist is not available");
    }
  };
 
  const LoadFeverChartProjectData = async () => {
    let obj = {
      projectId: projId
    };
    
    try {
      let res = await LoadFeverChartProjectDetails(obj);
      const fetchedData = res?.data?.success?.data?.y;
      const projectfetcheddata= res?.data?.success?.data?.x;
      setProjectData(projectfetcheddata || [])
      setData(fetchedData || []);  // Update state with the fetched data
      setMaxYAxis(fetchedData?.[0]?.maximumy || 100);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleYAxisChange = (e) => {
    setMaxYAxis(e.target.value); 
    handleFeverChart() 
    // Update Y-Axis based on input
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1 is needed
    const year = date.getFullYear();
    
    return `${day}/${month}/${year}`;
  };
  let showprojectdetails= projectdata.map((item)=>{
    return(
      <>
      <Container>
      <Grid container spacing={2}>
      
        {/* Chart Section */}
        <Grid item xs={12} md={4}>
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <div className="header-title">
          Project Name
          </div>
          <div className='header-title-data'>
          : {item.projectname}
          </div>
          </div>
          <div style={{display:"flex"}}>
          <div className="header-title">
          Project Status
          </div>
          <div style={{marginLeft:"35px"}} className='header-title-data'>
             : {item.projectstatus}
          </div>
          </div>
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <div className="header-title">
          Maximum Y -Axis
          </div>
          <div>
          :<input type="text" value={maxYAxis} onChange={handleYAxisChange}/>&nbsp;<button onClick={loadChartist}>Set Axis</button>
          </div>
          </div>
          {/* <div>
        projectName:{item.projectname}</div> */}
       {/* <div>Project status:{item.projectstatus}</div>  */}
       {/* <div>Maximum Y -Axis:<input type="text" value={maxYAxis} onChange={handleYAxisChange}/>&nbsp;<button onClick={loadChartist}>setAxis</button></div> */}
        
        </Grid>

        {/* Table Section */}
        <Grid item xs={12} md={3}>
        <div style={{display:"flex",justifyContent:"space-between"}}>
          <div className="header-title">
          Project manager
          </div>
          <div className='header-title-data'>
          : {item.projectmgrname}
          </div>
          </div>
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <div className="header-title">
          Actual/Expected End Date
          </div>
          <div className='header-title-data' style={{marginLeft:"10px"}}>
          : {formatDate(item.actualorexpectedenddate)}
          </div>
          </div>
        </Grid>
      </Grid>
    </Container>
      
      </>
    )
  })

  return (
    <>
    
      {showprojectdetails}
    <Container sx={{marginTop:"40px"}}>
      <Grid container spacing={2}>
      
        {/* Chart Section */}
        <Grid item xs={12} md={6}>
          <div style={{fontWeight:"bold",textAlign:"center"}}>Fever Chart</div>
          <div className="bufferchart" style={{ width: "100%" }}>
            <div id="bufferChart" className="ct-chart ct-minor-seventh"></div>
          </div>
        </Grid>

        {/* Table Section */}
        <Grid item xs={12} md={6}>
          <DataTable data={data} projectdata={projectdata}/>
        </Grid>
      </Grid>
    </Container>

    </>
  );
};

export default FeverChart;
