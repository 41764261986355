import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IR1 from "../HelpPopups/images/IR1.jpg";
import IR2 from "../HelpPopups/images/IR2.jpg";
import IR3 from "../HelpPopups/images/IR3.jpg";
// import IR4 from "../HelpPopups/images/ICM1 (4).jpg";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const IssueReporthelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{fontSize: "14px"}} >
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>
                Issue Report
              </h3>
              <br />
              <li>
                Issue Report is the report where task manager of a particular
                task assigns issue to its corresponding
              </li>
              <li>
                Path : CCPM \ Task Management \ Task Management.\Raise Issue
              </li>
              <li className="no-bullets">
                After Clicking on Raise Issue following screen gets opened.
              </li>
              <br />

              {/* <Stack sx={{ paddingRight: "1rem" }}>
                <img src={ICM11} alt="ICM1" />
              </Stack> */}
              <br />

              <li className="no-bullets">
                In which task manager can select task issue category ,Enter
                issue description and assign it to the desired user. This raised
                issue is seen in the issue list of that particular user who, the
                issue is raised.
              </li>
              <br />

              <li className="no-bullets">
                Path : CCPM\Risk Management\Issue List
              </li>
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={IR1} alt="IR1" />
              </Stack>
              <br />

              <li className="no-bullets">
                After clicking on Resolve issue following screen gets open to
                enter the details.
              </li>
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={IR2} alt="IR2" />
              </Stack>
              <br />

              <li className="no-bullets">
                Here user can enter the resolution and add the action as
                Resolved or Not resolved. then task manager can either reassign
                or close the issue depending upon the resolution. Assign another
                issue is the link for assigning another issue.
              </li>
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={IR3} alt="IR3" />
              </Stack>
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default IssueReporthelpPopup;
