import React, { useEffect, useState } from "react";
import logo from "../../Assets/godrej_logo.gif";
import {
  Stack,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import prostream from "../../Assets/prostream_logo.png";
import { USM_GetTableData, US_SaveTable } from "../../Services/Admin";
import modules from "./Admin.module.css";
import loading from "../../Assets/Loading_image.gif";

const UserMasterMapping = () => {
  const [tableData, setTableData] = useState([]);
  const [mainTableData, setMainTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const Id = searchParams.get("userId");
  const Name = searchParams.get("userName");
  const LID = searchParams.get("loginId");

  const fetchData = async () => {
    setIsLoading(true);
    const body = {
      userId: Id,
      activeuserid: sessionStorage.getItem("loginId"),
    };
    try {
      const response = await USM_GetTableData(body);
      if (response.status === 200) {
        setIsLoading(false);
        const apiData = response.data;
        setTableData(
          apiData.map((row) => ({ ...row, existactive: row.existactive || 0 }))
        );
        setMainTableData(
          apiData.map((row) => ({ ...row, existactive: row.existactive || 0 }))
        );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCheckboxChange = (index) => {
    setTableData((prevData) =>
      prevData.map((row, i) =>
        i === index
          ? { ...row, existactive: row.existactive === 1 ? 0 : 1 }
          : row
      )
    );
  };

  const submit = async () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to Save User Role Mapping Data?`
    );
    if (confirmDelete) {
      const selectedRoles = tableData
        .filter(
          (row, index) => row.existactive !== mainTableData[index].existactive
        )
        .map((row) => ({
          userId: Id,
          roleID: row.roleid,
          createdBy: sessionStorage.getItem("loginId"),
          userRoleMappingID: row.userrolemappingid,
        }));

      const body = {
        uRuList: selectedRoles,
      };

      try {
        const response = await US_SaveTable(body);
        if (response.status === 200) {
          alert("Details Updated Succesfully");
        }
      } catch (error) {
        console.error("Save failed", error);
      }
    }
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Stack
        direction="row"
        spacing={4}
        sx={{ paddingTop: "1rem" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <img src={prostream} alt="Prostream" />
        </Stack>
        <Stack sx={{ paddingRight: "5rem" }}>
          <img src={logo} alt="Godrej" />
        </Stack>
      </Stack>
      <Stack direction="column" sx={{ padding: "1% 15% 1% 1%" }}>
        <Typography
          sx={{ fontWeight: "bold" }}
          className={modules["grey_text"]}
        >
          User Role Mapping
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography className={modules["grey_text"]}>
            Username: {Name}
          </Typography>
          <Typography className={modules["grey_text"]}>
            User LoginId: {LID}
          </Typography>
        </Stack>
      </Stack>
      <Box className={modules["grey_box"]}>
        <TableContainer>
          <Table stickyHeader className={modules["grey_table"]}>
            <TableHead className={modules["usm-head"]}>
              <TableRow>
                <TableCell>RoleName</TableCell>
                <TableCell>RoleDesc</TableCell>
                <TableCell>Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={modules["usm-body"]}>
              {tableData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: row.editable === 0 ? "#e0e0e0" : "inherit",
                  }}
                >
                  <TableCell>{row.rolename}</TableCell>
                  <TableCell>{row.roledesc}</TableCell>
                  <TableCell align="center">
                    <input
                      type="checkbox"
                      checked={row.existactive === 1}
                      disabled={row.editable === 0}
                      onChange={() => handleCheckboxChange(index)}
                      className={modules["custom-checkbox"]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Stack direction="row" justifyContent="center" sx={{ padding: "1rem" }}>
        <button type="button" className={modules["btn-grey"]} onClick={submit}>
          Submit
        </button>
      </Stack>
    </>
  );
};

export default UserMasterMapping;
