import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import PSOC1 from "../HelpPopups/images/PSOC1.png";
import PSOC2 from "../HelpPopups/images/PSOC2.png";
import IC1 from "../HelpPopups/images/CM1.jpg";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";
import ProjectCompletiononTimeReport from "../CCPM/Reports/ProjectCompletiononTimeReport";

const ProjectCompletionOnTimeReporthelpPopup = () => {
  return (
    <>
    <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "#0000EE",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight:'bold',
                  fontFamily:'TimesNewRoman'
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            
              <h3 style={{ textDecoration: "underline", fontFamily:'Cablibri,serif',fontSize:'26px'}}>PROJECT SCHEDULE AS ON DATE</h3>
              <br />
              <p>This report shows Project wise task completion and status. This report is generated as per project. User need to select project status and project name.

              </p>
              <br></br>
              <p style={{fontSize:'18px',fontFamily:"sans-serif"}}> <strong>Session Path {'>>'}CCPM {'>>'} Reports {'>>'} Project Schedule As On Date </strong></p>
              <ul>
              <br></br>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={PSOC1} alt="IC1" />
              </Stack>
              <li> Instance – Select instance from drop down available.</li>
              <li>Project Status – User need to select project status. This is non mandatory filter</li>
              <li> Project Name – Select project name from drop down provided. This is a mandatory field. Once project is selected then following screen is appeared.</li>
              <br></br>
              </ul>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={PSOC2} alt="IC1" />
              </Stack>
              <ul>

              <li>Once user select project name and search then following details for the projects are hown.</li>
              <ul>
                <li> Project Name</li>
                <li>Project Status</li>
                <li>Project Manager.</li>
              </ul>
              <li> Task id – In this column task id for the task is shown.</li>
              <li>    Task Name – In this column task name as maintained is shown.</li>
              <li>    Planned Duration Day(s) – In this column task duration original with CCPM applied is shown.</li>
              <li>Remaining Duration Day(S) – In this column remaining duration for the task is shown. This is updated by the task manager.</li>
              <li>  Planned Start Date – In this column planned start date and time for the task is shown. This date is shown when CCPM is applied. </li>
              <li>    Planned End Date -In this column planned end date and time for the task is shown. System takes date after staggering is done.        </li>
              <li>  Expected / Actual Start Date – If task is in planned stage then it will show as the expected start date and time for the task. If project is not active, then it will show the same date as planned start date. But when project is made active it will change to the date of activation and all further dated will change accordingly. Once Task is complssseted this date automatically deleted and shows blank in the column.</li>
              <li>Expected / Actual End Date – In this column End date for the task is shown.</li>
              <ul>
                <li>  If task is completed, then it is Actual end date and time for the task.</li>
                <li> If task is not completed, then it is a planned or expected task completion date with time.</li>
              </ul>
              <li> Predecessor – In this column predecessor task for the current task are shown.</li>
              <li> Successor – In this column successor task for the current task are shown.</li>
              <li>  Task Status – In this column current status of the task is updated.</li>
              <li>   Task Manager – In this column Name of task manager is uploaded.</li>
              <li>Process Note – In this column if process notes are updated then it will show. If process notes not updated, then it will show as blank.</li>
              <li>    User Note – If user has updated any notes then it will show in this column.</li>
             <br></br>
             
    
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectCompletionOnTimeReporthelpPopup;
