import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../../Landing/Navbar";
// import modules from "./WIPLimitMaster.module.css";
import modules from "../ccpm.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SampleSidebar from "../../../Landing/SampleSidebar";
import {
  WL_GetDropdown,
  WL_GetTableData,
  WL_UpdateRow,
  WL_RowAdd,
} from "../../../Services/CCPM";
import Divider from "@mui/material/Divider";

// function ArrowLine() {
//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       <div
//         style={{
//           width: "2px",
//           height: "30px",
//           backgroundColor: "grey",
//           marginRight: "2px",
//         }}
//       />
//       <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
//     </div>
//   );
// }

const WIPLimitMaster = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [mainDropdownData, setMainDropdownData] = useState([]);
  const [dropdownData2, setDropdownData2] = useState([]);
  const [mainDropdownData2, setMainDropdownData2] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedId2, setSelectedId2] = useState("");
  const [tempId, setTempId] = useState("");
  const [displayvalue, setDisplayValue] = useState("");
  const [displayvalue2, setDisplayValue2] = useState("");
  const [editing, setEditing] = useState(null);
  const [totalWipL, setTotalWipL] = useState("");
  const [projectLimit, setProjectLimit] = useState("");
  const [isAddRowVisible, setIsAddRowVisible] = useState(false);
  const [newTotalWip, setNewTotalWip] = useState("");
  const [newProjectLimit, setNewProjectLimit] = useState("");
  const [checkEmpty, setCheckEmpty] = useState(false);
  const [checkWIP, setCheckWIP] = useState(false);
  const [checkLimit, setCheckLimit] = useState(false);
  const [checkWIP1, setCheckWIP1] = useState(false);
  const [checkLimit1, setCheckLimit1] = useState(false);

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    checkLoginAndRedirect();
    let body = {
      userId: sessionStorage.getItem("loginId"),
    };
    WL_GetDropdown(body)
      .then((response) => {
        if (response.status === 200) {
          const apiData = response.data;
          const data = apiData.success.data;
          const names = data.map((item) => item.name);
          setDropdownData(names);
          setMainDropdownData(data);
          setDropdownData2(names);
          setMainDropdownData2(data);
          if (names.length > 0) {
            setDisplayValue(names[0]);
            setSelectedId(data[0].id);
            setDisplayValue2(names[0]);
            setSelectedId2(data[0].id);
            setTempId("0");
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
    tableData(0);
  }, []);

  const tableData = (instance) => {
    let body2 = {
      instanceId: instance,
      userId: sessionStorage.getItem("loginId"),
    };

    WL_GetTableData(body2)
      .then((response) => {
        if (response.status === 200) {
          const apiData = response.data;
          const data = apiData.success.data;
          if (data.length === 0) {
            setCheckEmpty(true);
          } else {
            setCheckEmpty(false);
            setFilteredData(data);
          }
        } else {
          setCheckEmpty(true);
        }
      })
      .catch((error) => {
        setCheckEmpty(true);
        console.log(error);
      });
  };

  const handleChange = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.name === selectedName
    );
    setDisplayValue(selectedName);
    setSelectedId(selectedItem.id);
  };

  const handleChange2 = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData2.find(
      (item) => item.name === selectedName
    );
    setDisplayValue2(selectedName);
    setSelectedId2(selectedItem.id);
  };

  const handleSearch = () => {
    if (editing === null && isAddRowVisible === false) {
      setTempId(selectedId);
      tableData(selectedId);
    }
  };

  const handleReset = () => {
    tableData(0);
    setDisplayValue(dropdownData[0]);
    setSelectedId(mainDropdownData[0].id);
  };

  function handleEditSingleEntry(item, element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(item.id);
      setTotalWipL(item.totalwiplimit);
      setProjectLimit(item.projectlimitperweek);
      element.style.color = "#0000EE";
    }, 50);
  }

  function handleCancelEdit(element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(null);
      setCheckLimit1(false);
      setCheckWIP1(false);
      element.style.color = "#0000EE";
    }, 50);
  }

  function handleUpdate(item) {
    if (totalWipL === "" && projectLimit === "") {
      setCheckWIP1(true);
      setCheckLimit1(true);
      return;
    } else if (totalWipL === "") {
      setCheckWIP1(true);
      setCheckLimit1(false);
      return;
    } else if (projectLimit === "") {
      setCheckLimit1(true);
      setCheckWIP1(false);
      return;
    }

    setCheckWIP1(false);
    setCheckLimit1(false);
    let body = {
      totalWIPLimit: totalWipL,
      projectLimitPerWeek: projectLimit,
      id: item.id,
    };
    WL_UpdateRow(body)
      .then((response) => {
        if (response.status === 200) {
          alert("Details Updated Succesfully");
          const apiData = response.data;
          if (apiData.success) {
            setEditing(null);
            tableData(tempId);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleClose(element) {
    element.style.color = "red";
    setTimeout(() => {
      setIsAddRowVisible(false);
      setNewTotalWip("");
      setNewProjectLimit("");
      setCheckWIP(false);
      setCheckLimit(false);
      element.style.color = "#0000EE";
    }, 50);
  }

  function handleInsert() {
    setIsAddRowVisible(true);
    setDisplayValue2(dropdownData2[0]);
    setSelectedId2(mainDropdownData2[0].id);
  }

  function handleSave(element) {
    element.style.color = "red";
    setTimeout(() => {
      setTimeout(() => {
        element.style.color = "#0000EE";
        if (newTotalWip === "" && newProjectLimit === "") {
          setCheckWIP(true);
          setCheckLimit(true);
          return;
        } else if (newTotalWip === "") {
          setCheckWIP(true);
          setCheckLimit(false);
          return;
        } else if (newProjectLimit === "") {
          setCheckLimit(true);
          setCheckWIP(false);
          return;
        }

        setCheckWIP(false);
        setCheckLimit(false);
        const body = {
          instanceId: selectedId2,
          totalWIPLimit: newTotalWip,
          projectLimitPerWeek: newProjectLimit,
          createdBy: sessionStorage.getItem("loginId"),
        };
        WL_RowAdd(body)
          .then((response) => {
            if (response.status === 200) {
              alert("Details Inserted Succesfully");
              const apiData = response.data;
              if (apiData.success) {
                setIsAddRowVisible(false);
                setNewTotalWip("");
                setNewProjectLimit("");
                tableData(0);
              }
            } else if (response.response.status === 400) {
              alert(
                "WIPLimit Master Already Present for the selected Instance."
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
        element.style.color = "#0000EE";
      }, 50);
    }, 50);
  }

  return (
    <>
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          {/* <SideBar /> */}
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"WIPLimitMaster"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]} style={{ marginBottom: "15px" }}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>Admin</a>
                <img src={bread} alt="" />
                <a>WIP & Project Limit Master</a>
              </div>
            </div>

            <Grid container className={modules["wip_parent-container"]}>
              <Grid
                container
                className={modules["mid-cont"]}
              >
                <Grid container lg={6}>
                  <Grid container sx={{ alignItems: "baseline", marginBottom: "15px" }}>
                    <Typography
                      className={modules["label-style"]}
                      style={{ fontWeight: "bold"}}
                    >
                      Instance :
                    </Typography>
                    <FormControl
                      className={modules["form-control"]}
                      sx={{
                        "& .MuiSelect-select": { fontSize: "12px" },
                      }}
                    >
                      <select
                        IconComponent={ArrowDropDownIcon}
                        className={modules["input_css"]}
                        value={displayvalue}
                        onChange={handleChange}
                        style={{ marginLeft: "1rem", padding: "10px", width: "200px" }}
                      >
                        {dropdownData.map((i) => (
                          <option sx={{ fontSize: "12px" }} value={i} key={i}>
                            {i}
                          </option>
                        ))}
                      </select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                className={modules["btn1-cont"]}
                style={{ marginLeft: "0rem", marginTop: "0rem" }}
              >
                <button className={modules["wip_btn1"]} onClick={handleSearch}>
                  Search
                </button>
                <button
                  className={modules["wip_btn1"]}
                  onClick={handleReset}
                  style={{ marginLeft: "1rem" }}
                >
                  Reset
                </button>
              </Grid>
              {checkEmpty ? (
                <>
                  <Box sx={{ marginLeft: "4.85rem", width: "70%" }}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "grey",
                        marginBottom: "5px",
                      }}
                    >
                      No Record Found.
                    </Typography>
                    <Divider
                      sx={{ marginBottom: "10px", background: "#a4bf47" }}
                    />
                    <Grid
                      container
                      style={{ marginBottom: "1rem" }}
                      className={modules["btn2-cont"]}
                    >
                      <button className={modules["btn3"]}>Add</button>
                    </Grid>
                  </Box>
                </>
              ) : (
                <Grid container className={modules["table-grid"]}>
                  <div
                    className={modules["table-container"]}
                    style={{ width: "80%" }}
                  >
                    <Table className={modules["custom-table"]}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell>Instance</TableCell>
                          <TableCell>Total WIP Limit </TableCell>
                          <TableCell>Project Limit Per Week</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredData.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell className={modules["table-cell"]} style={{ color: "#414141" }}>
                              {index + 1}
                            </TableCell>
                            <TableCell className={modules["table-cell2"]} style={{ color: "#414141" }}>
                              {editing === item.id ? (
                                <></>
                              ) : (
                                <div>{item.instancename}</div>
                              )}
                            </TableCell>
                            <TableCell className={modules["table-cell3"]} style={{ color: "#414141" }}>
                              {editing === item.id ? (
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <input
                                    type="text"
                                    className={modules["dropdownSelect3"]}
                                    value={totalWipL}
                                    onBlur={() => {
                                      if (totalWipL !== "") {
                                        setCheckLimit1(false);
                                      }
                                    }}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const regex = /^\d{0,5}$/;
                                      if (regex.test(value)) {
                                        setTotalWipL(value);
                                      }
                                    }}
                                  />
                                  {checkWIP1 && (
                                    <Typography
                                      sx={{ color: "red", fontSize: "12px" }}
                                    >
                                      *Enter Value
                                    </Typography>
                                  )}
                                </Stack>
                              ) : (
                                <div>{item.totalwiplimit}</div>
                              )}
                            </TableCell>
                            <TableCell className={modules["table-cell4"]} style={{ color: "#414141" }}>
                              {editing === item.id ? (
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <input
                                    type="text"
                                    className={modules["dropdownSelect3"]}
                                    value={projectLimit}
                                    onBlur={() => {
                                      if (projectLimit !== "") {
                                        setCheckLimit1(false);
                                      }
                                    }}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const regex = /^\d{0,5}$/;
                                      if (regex.test(value)) {
                                        setProjectLimit(value);
                                      }
                                    }}
                                  />
                                  {checkLimit1 && (
                                    <Typography
                                      sx={{ color: "red", fontSize: "12px" }}
                                    >
                                      *Enter Value
                                    </Typography>
                                  )}
                                </Stack>
                              ) : (
                                <div>{item.projectlimitperweek}</div>
                              )}
                            </TableCell>
                            <TableCell className={modules["table-cell"]}>
                              {editing === item.id ? (
                                <>
                                  <a
                                    style={{
                                      cursor: "pointer",
                                      paddingRight: "1rem",
                                    }}
                                    onClick={(e) =>
                                      handleUpdate(item, e.currentTarget)
                                    }
                                  >
                                    Update
                                  </a>
                                  <br />
                                  <a
                                    style={{
                                      cursor: "pointer",
                                      paddingRight: "1rem",
                                    }}
                                    onClick={(e) =>
                                      handleCancelEdit(e.currentTarget)
                                    }
                                  >
                                    Cancel
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    style={{
                                      paddingRight: "1rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      handleEditSingleEntry(
                                        item,
                                        e.currentTarget
                                      )
                                    }
                                  >
                                    Edit
                                  </a>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow></TableRow>

                        {isAddRowVisible && (
                          <TableRow>
                            <TableCell scope="row">
                              {filteredData?.length + 1}
                            </TableCell>
                            <TableCell>
                              <FormControl
                                sx={{
                                  "& .MuiSelect-select": { fontSize: "12px" },
                                }}
                              >
                                <Select
                                  IconComponent={ArrowDropDownIcon}
                                  className={modules["dropdownSelect3"]}
                                  value={displayvalue2}
                                  onChange={handleChange2}
                                  style={{
                                    width: "10rem",
                                    height: "20px",
                                  }}
                                >
                                  {dropdownData2.map((i) => (
                                    <MenuItem
                                      sx={{ fontSize: "12px" }}
                                      value={i}
                                      key={i}
                                    >
                                      {i}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Stack direction="column" alignItems="flex-start">
                                <input
                                  type="text"
                                  value={newTotalWip}
                                  className={modules["dropdownSelect3"]}
                                  onBlur={() => {
                                    if (newTotalWip !== "") {
                                      setCheckWIP(false);
                                    }
                                  }}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d{0,5}$/;
                                    if (regex.test(value)) {
                                      setNewTotalWip(value);
                                    }
                                  }}
                                />
                                {checkWIP && (
                                  <Typography
                                    sx={{ color: "red", fontSize: "12px" }}
                                  >
                                    *Enter Value
                                  </Typography>
                                )}
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack direction="column" alignItems="flex-start">
                                <input
                                  type="text"
                                  value={newProjectLimit}
                                  className={modules["dropdownSelect3"]}
                                  onBlur={() => {
                                    if (newProjectLimit !== "") {
                                      setCheckLimit(false);
                                    }
                                  }}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d{0,5}$/;
                                    if (regex.test(value)) {
                                      setNewProjectLimit(value);
                                    }
                                  }}
                                />
                                {checkLimit && (
                                  <Typography
                                    sx={{ color: "red", fontSize: "12px" }}
                                  >
                                    *Enter Value
                                  </Typography>
                                )}
                              </Stack>
                            </TableCell>

                            <TableCell align="left">
                              <a
                                style={{
                                  color: "#0000EE",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handleSave(e.currentTarget)}
                              >
                                Save
                              </a>
                              <br />
                              <a
                                style={{
                                  color: "#0000EE",
                                  textDecoration: "underline",
                                  marginLeft: "0.6rem",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handleClose(e.currentTarget)}
                              >
                                Cancel
                              </a>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>

                    <Grid container className={modules["btn2-cont"]}>
                      <button
                        className={modules["btn3"]}
                        onClick={handleInsert}
                        style={{ marginBottom: "1rem", marginLeft: "0rem" }}
                      >
                        Add
                      </button>
                    </Grid>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WIPLimitMaster;
