import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Home from "../../../home/Home";
import { Grid, FormControl } from "@mui/material";
import bread from "../../../Assets/bread.png";
import {
  SP_MtaInstances,
  SP_MtaStockpointtype,
  DBM_GetProposedAction,
  DBM_LastRunn,
} from "../../../Services/MTA";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import window_popup from "../../../Assets/window_popup.png";

export default function DBM() {
  const location = useLocation();
  const [nData, setNData] = useState(location.state?.nData || '');
  const [stockPointTypes, setStockPointTypes] = useState([]);
  const [proposedActionData, setProposedActionData] = useState([]);
  const [selectedCompanyCode, setSelectedCompanyCode] = useState("");
  const [selectedItemCode, setSelectedItemCode] = useState("");
  const [selectedSSG, setSelectedSSG] = useState("");
  const [selectedItemCategory, setSelectedItemCategory] = useState("");
  const [selectedProposedAction, setSelectedProposedAction] = useState("");
  const [selectedStockPoint, setSelectedStockPoint] = useState("");
  const [popupData, setPopupData] = useState({});
  const [popupData2, setPopupData2] = useState({});
  const [popupData3, setPopupData3] = useState({});
  const [BMRun, setBMRun] = useState("");
  const [BMRunExists, setBMRunExists] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [selectedInstanceId, setSelectedInstanceId] = useState("");
  const [dropdownData, setDropdownData] = useState([]);
  const [selectedStockPointType, setSelectedStockPointType] = useState("");

  const ChannelSp = new BroadcastChannel("stockPointChannel");
  const ChannelSSG = new BroadcastChannel("SSGChannel");
  const Channelcategory = new BroadcastChannel("categoryChannel");

  ChannelSp.onmessage = (e) => {
    setPopupData({
      [e.data.mainrole]: e.data.selectedData,
      mainroleid: e.data.mainrole,
      roleidslist: e.data.selectedData.roleIds,
    });
    setSelectedStockPoint(e.data.selectedData.roleIds);
  };

  ChannelSSG.onmessage = (e) => {
    setPopupData2({
      [e.data.mainrole]: e.data.selectedData,
      mainroleid: e.data.mainrole,
      roleidslist: e.data.selectedData.roleIds,
    });
    setSelectedSSG(e.data.selectedData.ssg);
  };

  Channelcategory.onmessage = (e) => {
    setPopupData3({
      [e.data.mainrole]: e.data.selectedData,
      mainroleid: e.data.mainrole,
      roleidslist: e.data.selectedData.roleIds,
    });
    setSelectedItemCategory(e.data.selectedData.Item);
  };

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getInstances();
    if (nData) {
      setSelectedInstanceId(nData.instanceid);
      setSelectedStockPoint(nData.stock_point);
      setSelectedCompanyCode(nData.company_code);
      setSelectedItemCode(nData.item_code);
      setSelectedStockPointType(nData.stock_point_type);
      setSelectedProposedAction(nData.proposed_action);
      setSelectedSSG(nData.statisticalGroup);
      setSelectedItemCategory(nData.itemCategory);
      setTimeout(() => {
        setNData('');
      }, 100);
    }
    console.log(nData);
  }, []);

  useEffect(() => {
    GetProposedAction();
    getLastBMrun();
  }, [selectedInstanceId]);


  const getLastBMrun = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      insatnce: selectedInstanceId,
    };
    if (loginId) {
      DBM_LastRunn(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setIsLoading(false);
            // alert();
            // console.log(response?.data?.success?.data[0].last_dbmrun_date);

            let dateStr = response?.data?.success?.data[0].last_dbmrun_date;
            if (dateStr) {
              let parts = dateStr.split("/");
              let formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
              console.log(formattedDate); // Output: 09/11/2020
              setBMRun(formattedDate);
            }

            setBMRunExists(response?.data?.success?.data[0].dbmdataexists);
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const getInstances = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      instance_uid: "",
      muiuid_last_modified_by: sessionStorage
        .getItem("loginUsername")
        .toLowerCase(),
    };
    if (loginId) {
      SP_MtaInstances(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setDropdownData(response?.data?.success?.data);
            setIsLoading(false);

            if (response?.data?.success?.data.length > 0) {
              const defaultInstanceId =
                response?.data?.success?.data[0]?.mta_instance_uid;
              // console.log(defaultInstanceId, "fcewf");
              if (nData) {
                setSelectedInstanceId(nData.instanceid);
              } else {
                setSelectedInstanceId(defaultInstanceId);
              }
              getStockPointType(defaultInstanceId);
            } else if (response?.data?.success?.data.length === 0) {
              alert("You do not have access to any Active Instance in MTA");
              // console.log(response?.data?.success?.data.length, "WWWW");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const GetProposedAction = () => {
    setIsLoading(true);
    const body = {
      sessionName_par: "DBM",
      controlParameter_par: "Proposed Action",
    };
    DBM_GetProposedAction(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setProposedActionData(response?.data?.success?.data);
          setIsLoading(false);
          if (response?.data?.success?.data.length > 0) {
          } else if (response?.data?.success?.data.length === 0) {
            alert("No Proposed Action found");
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching instance names:", error);
      });
  };

  const getStockPointType = (instance) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      spt_instance_uid: instance,
      userid: loginId,
    };
    if (loginId) {
      SP_MtaStockpointtype(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setIsLoading(false);
            if (response?.data?.success?.data.length > 0) {
              setStockPointTypes(response?.data?.success?.data);
            } else if (response?.data?.success?.data.length === 0) {
              setStockPointTypes(response?.data?.success?.data);
              // alert("You do not have access to any Active Instance in MTA");
              // console.log(response?.data?.success?.data.length, "WWWW");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching Stock points:", error);
        });
    }
  };

  const navigate = useNavigate();

  function submitHandler() {
    const nData = {
      instanceid: selectedInstanceId,
      stock_point: selectedStockPoint,
      company_code: selectedCompanyCode,
      item_code: selectedItemCode,
      stock_point_type: selectedStockPointType,
      proposed_action: selectedProposedAction,
      statisticalGroup: selectedSSG,
      itemCategory: selectedItemCategory,
    };

    navigate("/ccpm/DBMdata", { state: { nData } });
  }

  function resetHandler() {
    setSelectedCompanyCode("");
    setSelectedInstanceId(dropdownData[0]?.mta_instance_uid);
    setSelectedItemCode("");
    setSelectedSSG("");
    setSelectedItemCategory("");
    setSelectedProposedAction("");
    setSelectedStockPoint("");
    setSelectedStockPointType("");
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container className={modules["primary_cont"]}>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"DBM"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>DBM</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table
                className={modules["customTable"]}
                style={{ marginLeft: "3rem" }}
              >
                <tbody>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "180px", fontWeight: "bold" }}>
                      MTA Instance
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedInstanceId}
                          onChange={(e) => {
                            setSelectedInstanceId(e.target.value);
                            setSelectedStockPointType("");
                          }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          // disabled={editing}
                        >
                          {/* <option value={"SELECT"}>SELECT</option> */}
                          {dropdownData.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mta_instance_uid}
                                key={i.id}
                                data-id={i.id}
                              >
                                {i.mta_instance_uid}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                    <td
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        marginLeft: "100px",
                      }}
                    >
                      {BMRunExists === "Yes" ? (
                        <span>
                          DBM Run enabled in Automation - Yes and Last DBM Run
                          taken on {BMRun}
                        </span>
                      ) : (
                        <span>
                          DBM Run was not enabled in Automation and No DBM Run
                          taken
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "180px", fontWeight: "bold" }}>
                      Stock Point Type
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedStockPointType}
                          onChange={(e) => {
                            setSelectedStockPointType(e.target.value);
                          }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                        >
                          <option value={""}>SELECT</option>
                          {stockPointTypes.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.spt_stock_point_type_code}
                                key={i.spt_stock_point_type_code}
                                // data-id={i.id}
                              >
                                {i.spt_stock_point_type_code}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                    <td
                      style={{
                        width: "180px",
                        fontWeight: "bold",
                        marginLeft: "100px",
                      }}
                    >
                      Stock Point
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={selectedStockPoint}
                        onClick={() => {
                          window.open(
                            `/ccpm/StockPointPopup?instance=${selectedInstanceId}`,
                            "mypopuptitle",
                            "width=600,height=500"
                          );
                        }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px", width: "250px" }}
                      />
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "180px", fontWeight: "bold" }}>
                      Company Code
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={selectedCompanyCode}
                        onChange={(e) => setSelectedCompanyCode(e.target.value)}
                        onInput={(e) => {
                          const regex = /^[a-zA-Z0-9()&_\-+=\[\]/. ]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9()&_\-+=\[\]/. ]/g,
                              ""
                            );
                            setSelectedCompanyCode(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          const regex2 = /^[a-zA-Z0-9_-]*$/;
                          if (!regex2.test(e.target.value)) {
                            alert("No Special Characters Allowed.");
                            setSelectedCompanyCode("");
                          }
                        }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px", width: "250px" }}
                        maxLength={10}
                      />
                    </td>
                    <td
                      style={{
                        width: "180px",
                        fontWeight: "bold",
                        marginLeft: "102px",
                      }}
                    >
                      Item Code
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={selectedItemCode}
                        onChange={(e) => setSelectedItemCode(e.target.value)}
                        onInput={(e) => {
                          const regex = /^[a-zA-Z0-9()&_\-+=\[\]/?. ]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9()&_\-+=\[\]/?. ]/g,
                              ""
                            );
                            setSelectedItemCode(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          const regex2 = /^[a-zA-Z0-9_-]*$/;
                          if (!regex2.test(e.target.value)) {
                            alert("No Special Characters Allowed.");
                            setSelectedItemCode("");
                          }
                        }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px", width: "250px" }}
                        maxLength={30}
                      />
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "180px", fontWeight: "bold" }}>SSG</td>
                    <td>:</td>
                    <td>
                      <input
                        value={selectedSSG}
                        onChange={(e) => setSelectedSSG(e.target.value)}
                        onInput={(e) => {
                          const regex = /^[a-zA-Z0-9()&_\-+=\[\]/. ]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9()&_\-+=\[\]/. ]/g,
                              ""
                            );
                            setSelectedSSG(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          const regex2 = /^[a-zA-Z0-9_-]*$/;
                          if (!regex2.test(e.target.value)) {
                            alert("No Special Characters Allowed.");
                            setSelectedSSG("");
                          }
                        }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px", width: "250px" }}
                        maxLength={50}
                      />
                      <img
                        src={window_popup}
                        alt=""
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          height: "10px",
                        }}
                        onClick={() => {
                          window.open(
                            `/ccpm/SSGpopup?instance=${selectedInstanceId}`,
                            "mypopuptitle",
                            "width=600,height=500"
                          );
                        }}
                      />
                    </td>
                    <td
                      style={{
                        width: "180px",
                        fontWeight: "bold",
                        marginLeft: "82px",
                      }}
                    >
                      Item Category
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={selectedItemCategory}
                        onChange={(e) =>
                          setSelectedItemCategory(e.target.value)
                        }
                        onInput={(e) => {
                          const regex = /^[a-zA-Z0-9()&_\-+=\[\]/. ]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9()&_\-+=\[\]/. ]/g,
                              ""
                            );
                            setSelectedItemCategory(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          const regex2 = /^[a-zA-Z0-9_-]*$/;
                          if (!regex2.test(e.target.value)) {
                            alert("No Special Characters Allowed.");
                            setSelectedItemCategory("");
                          }
                        }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px", width: "250px" }}
                        maxLength={50}
                      />
                      <img
                        src={window_popup}
                        alt=""
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          height: "10px",
                        }} // Adjust spacing as needed
                        onClick={() => {
                          window.open(
                            `/ccpm/ItemCategoryPopup?instance=${selectedInstanceId}`,
                            "mypopuptitle",
                            "width=600,height=500"
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "180px", fontWeight: "bold" }}>
                      Proposed Action
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedProposedAction}
                          onChange={(e) => {
                            setSelectedProposedAction(e.target.value);
                          }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          // disabled={editing}
                        >
                          <option value={"SELECT"}>SELECT</option>
                          {proposedActionData.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mscg_control_value}
                                key={i.id}
                              >
                                {i.mscg_control_value}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Grid
                container
                align="center"
                style={{
                  marginTop: "0rem",
                  justifyContent: "center",
                  marginRight: "6rem",
                }}
              >
                <button className={modules["btn1"]} onClick={submitHandler}>
                  Submit
                </button>
                <button
                  className={modules["btn1"]}
                  style={{ marginLeft: "20px" }}
                  onClick={resetHandler}
                >
                  Reset
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
