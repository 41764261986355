import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
import modules from "./../ccpm.module.css";
import Box from "@mui/material/Box";
import { Tab, Tabs } from "@mui/material";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  Get_TMDetailedSearchResult,
  Get_TMSummerySearchResult,
  GetLTMBindTaskManager,
} from "../../../Services/CCPM.js";

const DummyData = [
  {
    id: 1,
    col2: "Aerospace",
    col3: "Aerospace",
  },
  {
    id: 2,
    col2: "Innovation and Design Center",
    col3: "IDC",
  },
  {
    id: 3,
    col2: "GB Corporate Projects",
    col3: "GB_CORP",
  },
  {
    id: 4,
    col2: "PSS Gogrej Security Solutions",
    col3: "SSD_PSS",
  },
  {
    id: 5,
    col2: "G&B Finance",
    col3: "G&B Finance",
  },
];

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const LoadOnTaskManager = () => {
  const [searchValue, setSearchValue] = useState("");
  // State for selected values
  const [selectedInstance, setSelectedInstance] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [summeryTableData, setSummeryTableData] = useState([]);
  const [detailedTableData, setDetailedTableData] = useState([]);
  const [selectedAccessType, setSelectedAccessType] = useState("SELECT");
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("1");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedNumberOfWeeks, setSelectedNumberOfWeeks] = useState(1);
  const [dateKeys, setdateKeys] = useState([]);

  const [selectedRadioValue, setSelectedValue] = useState("Detail");

  const [dropdownData, setDropdownData] = useState([
    "Select",
    "Instance 2",
    "Instance 3",
  ]);
  const [filteredData, setFilteredData] = useState(DummyData);
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [dataChart, setDataChart] = useState({});
  const [dataOptions, setDataOptions] = useState({});

  const handleSelectChange = (attributenumber, id) => {
    // console.log(attributenumber, id, "attributenumber value");
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  // const [radioValue, setRadioValue] = useState("yes");

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [instances, setInstances] = useState([]);
  const [zones, setZones] = useState([]);
  const [projects, setProjects] = useState([]);
  const [Tmanagers, setTManagers] = useState([]);

  const noOfWeeks = [
    { value: "1" },
    { value: "2" },
    { value: "3" },
    { value: "4" },
    { value: "5" },
    { value: "6" },
    { value: "7" },
    { value: "8" },
    { value: "9" },
    { value: "10" },
    { value: "11" },
    { value: "12" },
  ];

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances?.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getTasksManager(initialInstance);
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
    getTasksManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTasksManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetLTMBindTaskManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "task manager");
          setTManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSummerySearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      taskMgrId: selectedProjectManager,
      noOfWeeks: selectedNumberOfWeeks,
    };

    Get_TMSummerySearchResult(body)
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "search result");
          const dates = Object.keys(response?.data?.success?.data[0]).filter(
            (key) => key.match(/^\d{4}\/\d{2}\/\d{2}$/)
          );
          // const fixedColumns = 5; // Number of fixed columns (Id, UserName, Task Name, Task Manager, Remaining Duration)
          // const dynamicColumns = dateKeys.length; // Number of dynamic date columns
          // const totalColumns = fixedColumns + dynamicColumns;

          setdateKeys(dates);
          setSummeryTableData(response?.data?.success?.data);
          // const dateKeys = response?.data?.success?.data.keys(data).filter(key => key.match(/^\d{4}\/\d{2}\/\d{2}$/));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDetailedSearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    setIsLoading(true);
    const loginId = parseInt(sessionStorage.getItem("loginId"));
    const body = {
      instanceId: parseInt(selectedInstance),
      userId: parseInt(loginId),
      attribute1Id: parseInt(attr1),
      attribute2Id: parseInt(attr2),
      attribute3Id: parseInt(attr3),
      attribute4Id: parseInt(attr4),
      attribute5Id: parseInt(attr5),
      attribute6Id: parseInt(attr6),
      attribute7Id: parseInt(attr7),
      attribute8Id: parseInt(attr8),
      attribute9Id: parseInt(attr9),
      attribute10Id: parseInt(attr10),
      projId: parseInt(selectedProjectName),
      taskMgrId: parseInt(selectedProjectManager),
      noOfWeeks: parseInt(selectedNumberOfWeeks),
    };
    Get_TMDetailedSearchResult(body)
      .then((response) => {
        setIsLoading(true);
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data[0], "search result");
          setDetailedTableData(response?.data?.success?.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic
    console.log({
      selectedInstance,
      selectedZone,
      selectedProject,
      selectedManager,
      selectedAccessType,
      selectedProjectStatus,
    });
  };

  const handleSearch = () => {
    setShowResult(true);
    console.log(selectedRadioValue, "selectedRadioValue");
    // console.log(selectedAttributes, "selectedAttributes");
    // console.log(selectedProjectStatus, "status");
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    // console.log(
    //   attr1,
    //   attr2,
    //   attr3,
    //   attr4,
    //   attr5,
    //   attr6,
    //   attr7,
    //   attr8,
    //   attr9,
    //   attr10
    // );

    if (selectedRadioValue === "summary") {
      getSummerySearchResult(
        attr1,
        attr2,
        attr3,
        attr4,
        attr5,
        attr6,
        attr7,
        attr8,
        attr9,
        attr10
      );
    } else if (selectedRadioValue === "Detail") {
      getDetailedSearchResult(
        attr1,
        attr2,
        attr3,
        attr4,
        attr5,
        attr6,
        attr7,
        attr8,
        attr9,
        attr10
      );
      getSummerySearchResult(
        attr1,
        attr2,
        attr3,
        attr4,
        attr5,
        attr6,
        attr7,
        attr8,
        attr9,
        attr10
      );
    }
  };

  const handleReset = () => {
    setSearchValue("");
    setFilteredData(DummyData);
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"LoadOnTaskManager"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "5px" }}
            >
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>Reports</a>
                <img src={bread} alt="" />
                <a>Load On Task Manager</a>
              </div>
            </div>
            <div className={modules["border_cont"]}>
              <table className={modules["PCtable"]}>
                <tbody>
                  <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "115px", color: "#414141" }}>
                        Instance:<label style={{ color: "Red" }}>*</label>
                      </span>

                      <select
                        value={selectedInstance}
                        onChange={(e) => {
                          setSelectedInstance(e.target.value);
                          getProjectDetails(e.target.value);
                        }}
                        className={modules["dropdownSelect"]}
                        // style={{ width: "200px" }}
                      >
                        {instances?.map((instance) => (
                          <option key={instance.name} value={instance.id}>
                            {instance.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    {hierarchyOptions
                      ?.filter((option) => option.isactive === 1)
                      .map((option, index) => (
                        <React.Fragment key={option.attributename}>
                          <td className={modules["table_data1"]}>
                            <span style={{ width: "115px", color: "#414141" }}>
                              {option.attributename} :
                            </span>
                            <select
                              value={
                                selectedAttributes[
                                  `attr${option.attributenumber}`
                                ] || ""
                              }
                              onChange={(e) =>
                                handleSelectChange(
                                  option.attributenumber,
                                  e.target.value
                                )
                              }
                              className={modules["dropdownSelect"]}
                              // style={{ width: "200px" }}
                            >
                              <option value="">SELECT</option>
                              {filteredValueData[index]?.map((item) => (
                                <option
                                  key={item.attributevalue}
                                  value={item.id}
                                >
                                  {item.attributevalue}
                                </option>
                              ))}
                            </select>
                          </td>
                        </React.Fragment>
                      ))}
                  </tr>
                  <tr
                    style={{
                      paddingBottom: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "115px", color: "#414141" }}>
                        Project Name :
                      </span>
                      <select
                        value={selectedProjectName}
                        onChange={(e) => setSelectedProjectName(e.target.value)}
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        <option value={0}>SELECT</option>
                        {projects?.map((p_name) => (
                          <option key={p_name.projectname} value={p_name.id}>
                            {p_name.projectname}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "115px", color: "#414141" }}>
                        Task Manager :
                      </span>
                      <select
                        value={selectedProjectManager}
                        onChange={(e) =>
                          setSelectedProjectManager(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        <option value={0}>SELECT</option>
                        {Tmanagers?.map((m_name) => (
                          <option key={m_name.taskmanager} value={m_name.id}>
                            {m_name.taskmanager}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "115px", color: "#414141" }}>
                        Number of Weeks :
                      </span>
                      <select
                        value={selectedNumberOfWeeks}
                        onChange={(e) =>
                          setSelectedNumberOfWeeks(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "30px", flex: "none" }}
                      >
                        {noOfWeeks?.map((i_n) => (
                          <option key={i_n.value} value={i_n.value}>
                            {i_n.value}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>

                  <tr
                    style={{
                      paddingBottom: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    <td
                      className={modules["table_data1"]}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <span style={{ width: "115px", color: "#414141" }}>
                        Report Type :
                      </span>
                      <FormControl
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={selectedRadioValue}
                          onChange={handleRadioChange}
                        >
                          <FormControlLabel
                            value="Detail"
                            control={
                              <Radio
                                color="default"
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 15,
                                    width: 15,
                                    height: 15,
                                    color: "blue",
                                    fontFamily: '"WorkSans", sans-serif',
                                  },
                                }}
                              />
                            }
                            label="Detail"
                            disableRipple
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "12px",
                                color: "#414141",
                                fontFamily: '"WorkSans", sans-serif',
                              },
                            }} // Adjust the font size of the label
                          />
                          <FormControlLabel
                            value="summary"
                            control={
                              <Radio
                                color="default"
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 15,
                                    width: 15,
                                    height: 15,
                                    color: "blue",
                                    fontFamily: '"WorkSans", sans-serif',
                                  },
                                }}
                              />
                            }
                            label="Summary"
                            disableRipple
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "12px",
                                color: "#414141",
                                fontFamily: '"WorkSans", sans-serif',
                              },
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </td>
                  </tr>

                  <tr
                    style={{
                      justifyContent: "center",
                      gap: "10px",
                      paddingLeft: "0px",
                      paddingBottom: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      className={modules["PCbtn"]}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["PCbtn"]}
                      type="button"
                      onClick={() => {
                        window.location.reload();
                        // setSelectedInstance("");
                        // setSelectedZone("");
                        // setSelectedProject("");
                        // setSelectedManager("");
                        // setSelectedAccessType("SELECT");
                        // setSelectedProjectStatus("0");
                        // setDetailedTableData([]);
                        // setSummeryTableData([]);
                        // setShowResult(false);
                      }}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>

            {showResult &&
              selectedRadioValue === "Detail" &&
              detailedTableData?.length > 0 && (
                <>
                  <Grid container sx={{ marginLeft: "6.5rem", padding: "8px" }}>
                    <Table
                      className={modules["custom_table_blue"]}
                      sx={{ width: "auto" }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            sx={{ backgroundColor: "lightgray !important" }}
                          >
                            Task Count
                          </TableCell>
                          {dateKeys?.map((date) => (
                            <TableCell
                              key={date}
                              sx={{ backgroundColor: "lightgreen !important" }}
                            >
                              {summeryTableData[0]
                                ? summeryTableData[0][date]
                                : null}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Project Name</TableCell>
                          <TableCell sx={{ width: "110px" }}>
                            Task Name
                          </TableCell>
                          <TableCell sx={{ width: "140px !important" }}>
                            Task Manager
                          </TableCell>
                          <TableCell sx={{ width: "125px!important" }}>
                            Remaining Duration
                          </TableCell>
                          {dateKeys?.map((date) => (
                            <TableCell key={date}>
                              {date.split("/").reverse().join("/")}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {detailedTableData?.map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor:
                                index % 2 === 0 ? "#E3EAEB" : "#ffffff",
                            }}
                          >
                            <TableCell className={modules["IRtable-row"]}>
                              {item.id}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.projectname}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.taskname}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.taskmanager}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {Number(item.remainingduration).toFixed(2)}{" "}
                            </TableCell>
                            {dateKeys?.map((date) => (
                              <TableCell
                                key={date}
                                className={modules["IRtable-row"]}
                                sx={{ backgroundColor: `${item.color}` }}
                              >
                                {item[date]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </>
              )}

            {showResult &&
              selectedRadioValue === "summary" &&
              summeryTableData?.length > 0 && (
                <>
                  <Grid container sx={{ marginLeft: "6.5rem", padding: "8px" }}>
                    <Table
                      className={modules["custom_table_blue"]}
                      sx={{ width: "auto" }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            sx={{ backgroundColor: "lightgray !important" }}
                          ></TableCell>
                          <TableCell
                            colSpan={dateKeys?.length}
                            sx={{ backgroundColor: "lightgreen !important" }}
                          >
                            Task Count
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>UserName</TableCell>
                          {/* <TableCell>Task Name</TableCell> */}
                          {dateKeys?.map((date) => (
                            <TableCell key={date}>
                              {date.split("/").reverse().join("/")}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {summeryTableData?.map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor:
                                index % 2 === 0 ? "#E3EAEB" : "#ffffff",
                            }}
                          >
                            <TableCell className={modules["IRtable-row"]}>
                              {item.id}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.username}
                            </TableCell>
                            {dateKeys?.map((date) => (
                              <TableCell
                                key={date}
                                className={modules["IRtable-row"]}
                              >
                                {item[date]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </>
              )}

            {/* {showResult && <>
            
            </>} */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LoadOnTaskManager;
