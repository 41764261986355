import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material";
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/TMT1.jpg";
import IC2 from "../HelpPopups/images/TMT2.jpg";
import IC3 from "../HelpPopups/images/TMT3.jpg";
import { Link } from "react-router-dom";

const TaskManagementhelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "10px", textDecoration: "underline" }}>
                Task Mgmt Config
              </h3>
              <br />
              <li>
                This master is used for the setting of the task. User can define
                the start and end of the predecessor and successor tasks.
              </li>
              <li style={{ fontWeight: "bold" }}>
                Session Path – CCPM {">>"} Master {">>"} Task Mgmt Config
              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC1} alt="IC1" />
              </Stack>
              Select instance from the drop down provided.
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC2} alt="IC2" />
              </Stack>
              <br />
              <li>
                Successor Task To be Started Only After Immediate Predecessor(s)
                is/are Started –
                <br /> This is a mandatory filter. This indicates that the start
                of successor task. User can select form YES / No.
                <br /> If user selects Yes then it is indicates that the
                successor task can start immediately after the predecessor tasks
                are started.
                <br /> If user selects No then the there is no binding for the
                successor task to start.
              </li>
              <li>
                Successor Task To be Completed Only After Immediate
                Predecessor(s) is/are Completed – This is also a mandatory
                filter. This indicates that the successor task be completed only
                after predecessor tasks are completed. This is linked with the
                first value. If first value I maintained as Yes the default
                value for this option is Yes. If first one is set as No then
                user can define Value yes or No.
              </li>
              <li>Click on save button for saving the values.</li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC3} alt="IC3" />
              </Stack>
              <br />
              <li>
                There is option of viewing config History. Here user can check
                who and what setting are done in past and by whom and on which
                date. The details are self-explanatory.
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TaskManagementhelpPopup;
