import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  TextField,
  Divider,
  Stack
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../../Landing/Navbar";
// import modules from "./ProjectTaskMgmtEmailConfig.module.css";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { getInstanceData, getInstanceBasedData, saveProjectConfig, getProjectConfigHistory } from "../../../Services/CCPM";
import loading from "../../../Assets/Loading_image.gif";
const DummyData = [
  {
    id: 1,
    col2: "Aerospace",
    col3: "Aerospace",
  },
  {
    id: 2,
    col2: "Innovation and Design Center",
    col3: "IDC",
  },
  {
    id: 3,
    col2: "GB Corporate Projects",
    col3: "GB_CORP",
  },
  {
    id: 4,
    col2: "PSS Gogrej Security Solutions",
    col3: "SSD_PSS",
  },
  {
    id: 5,
    col2: "G&B Finance",
    col3: "G&B Finance",
  },
];

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const ProjectTaskMgmtEmailConfig = () => {

  const [showPlus, setShowPlus] = useState(true);
  const [historyTableData, setHistoryTableData] = useState([]);
  const [serialNumber, setSerialNumber] = useState(1);
  const [historyTag, setHistoryTag] = useState(false);
  const handleIconToggle = () => {
    setShowPlus(!showPlus);
    let body = {
      "instanceId": selectedId
    }
    getProjectConfigHistory(body).then((res) => {
      console.log("res", res);
      if (res.status === 200) {
        let resData = res.data.success.data;
        console.log("resData", resData);
        setHistoryTableData(resData);
      }
    }).catch((error) => {
      console.log("Error", error);
    });
  };

  const [searchValue, setSearchValue] = useState("");
  const [dropdownData, setDropdownData] = useState([]);
  const [filteredData, setFilteredData] = useState(DummyData);
  const [displayvalue, setDisplayValue] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [mainDropdownData, setMainDropdownData] = useState([]);
  const [allTaskManager, setAllTaskManager] = useState("");
  const [firstTaskManager, setFirstTaskManager] = useState("");
  const [ccNofification, setCCNotification] = useState("");
  const [overDueTaskTM, setOverDueTaskTM] = useState("");
  const [overDueTaskL1, setOverDueTaskL1] = useState("");
  const [overDueTaskPM, setOverDueTaskPM] = useState("");
  const [overDueTaskINC, setOverDueTaskINC] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = () => {
    setSearchValue("");
    setFilteredData(DummyData);
  };

  const [radioValue, setRadioValue] = useState("yes");

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };
  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem('loginId');
    if (!loginId) {
      window.location.href = '/';
    }
  };

  function isEmpty(value) {
    console.log(value == null || (typeof value === "string" && value.trim().length === 0));
    return (value == null || (typeof value === "string" && value.trim().length === 0));
  }

  useEffect(() => {
    checkLoginAndRedirect();

    const fetchData = async () => {
      let body = { userId: Number(sessionStorage.getItem('loginId')) }
      setIsLoading(true);
      getInstanceData(body).then((res) => {
        if (res?.data) {
          let data = res.data.success.data;
          let temp = data[0].name;
          console.log("data[0].name", data[0].name);
          setDisplayValue(data[0].name);
          setSelectedId(data[0].id);
          const names = data.map((item) => item.name);
          console.log("names", names);
          setDropdownData(names);
          setMainDropdownData(data);
          setIsLoading(false);
          let body = {
            instanceId: data[0].id
          }

          getInstanceBasedData(body).then((res) => {
            if (res.status === 200) {
              if (res.data.success.data !== null) {
                console.log("res.data.success.data", res.data.success.data.length);
                if (res.data.success.data.length === 1) {
                  setHistoryTag(true)
                }
                let resultData = res.data.success.data[0];
                setAllTaskManager(resultData.mailforsucctaskmanager);
                setFirstTaskManager(resultData.mailforinitialtaskmanager);
                setCCNotification(resultData.cctoprojectmanager);
                setOverDueTaskTM(resultData.mailforoverduetasktotm);
                setOverDueTaskL1(resultData.mailforoverduetasktol1);
                setOverDueTaskPM(resultData.mailforoverduetasktopm);
                setOverDueTaskINC(resultData.mailforoverduetasktoic);
              }
            }
          }).catch((error) => {
            console.log(error);
          });
        } else {
          console.log("Error", res);
        }
      }).catch((error) => {
        console.log("Error", error);
      });
    };

    fetchData();
  }, []);

  function handleChangeInstance(event) {
    setHistoryTag(false);
    setShowPlus(true);
    setIsLoading(true);
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.name === selectedName
    );
    console.log("selectedItem.id", selectedItem.id);
    setDisplayValue(selectedName);
    setSelectedId(selectedItem.id);
    setAllTaskManager("");
    setFirstTaskManager("");
    setCCNotification("");
    setOverDueTaskTM("");
    setOverDueTaskL1("");
    setOverDueTaskPM("");
    setOverDueTaskINC("");
    let body = {
      instanceId: selectedItem.id
    }
    console.log("instanceName", displayvalue, selectedItem.id, selectedId);

    getInstanceBasedData(body).then((res) => {
      if (res.status === 200) {
        setIsLoading(false);
        if (res.data.success.data !== null) {
          console.log("res.data.success.data", res.data.success.data.length);
          if (res.data.success.data.length === 1) {
            setHistoryTag(true)
          }
          let resultData = res.data.success.data[0];
          setAllTaskManager(resultData.mailforsucctaskmanager);
          setFirstTaskManager(resultData.mailforinitialtaskmanager);
          setCCNotification(resultData.cctoprojectmanager);
          setOverDueTaskTM(resultData.mailforoverduetasktotm);
          setOverDueTaskL1(resultData.mailforoverduetasktol1);
          setOverDueTaskPM(resultData.mailforoverduetasktopm);
          setOverDueTaskINC(resultData.mailforoverduetasktoic);
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const handleSave = () => {
    if (isEmpty(allTaskManager) || isEmpty(firstTaskManager) || isEmpty(ccNofification) || isEmpty(overDueTaskTM) || isEmpty(overDueTaskL1) || isEmpty(overDueTaskPM)) {
      if (isEmpty(allTaskManager)) { alert("Choose Predeccessor Task Completion notification mail to be sent (to all Successors task managers)"); }
      else if (isEmpty(firstTaskManager)) { alert("Choosee Project start notification mail to be sent (to first task's manager(s))"); }
      else if (isEmpty(ccNofification)) { alert("Choose Project Manager should be kept in CC in notification mails to task managers"); }
      else if (isEmpty(overDueTaskTM)) { alert("Choose Notification of overdue taks to Task Managers"); }
      else if (isEmpty(overDueTaskL1)) { alert("Choose Notification of Overdue Tasks to L+1 of the Task Managers"); }
      else if (isEmpty(overDueTaskPM)) { alert("Choose Notification of Overdue Tasks to Project Manager"); }
    }
    let saveDataObj = {
      "instanceId": selectedId,
      "mailforSuccTaskManager": allTaskManager,
      "mailforInitialTaskManager": firstTaskManager,
      "cCtoProjectManager": ccNofification,
      "mailforOverdueTasktoTM": overDueTaskTM,
      "mailforOverdueTasktoL1": overDueTaskL1,
      "mailforOverdueTasktoPM": overDueTaskPM,
      "mailforOverdueTasktoIC": overDueTaskINC,
      "userId": sessionStorage.getItem("loginId")
    }
    saveProjectConfig(saveDataObj).then((res) => {
      if (res.status === 200) {
        alert("Details Updated Succesfully");
        let body = {
          instanceId: selectedId
        }
        getInstanceBasedData(body).then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            if (res.data.success.data !== null) {
              console.log("res.data.success.data", res.data.success.data.length);
              if (res.data.success.data.length === 1) {
                setHistoryTag(true)
              }
              let resultData = res.data.success.data[0];
              setAllTaskManager(resultData.mailforsucctaskmanager);
              setFirstTaskManager(resultData.mailforinitialtaskmanager);
              setCCNotification(resultData.cctoprojectmanager);
              setOverDueTaskTM(resultData.mailforoverduetasktotm);
              setOverDueTaskL1(resultData.mailforoverduetasktol1);
              setOverDueTaskPM(resultData.mailforoverduetasktopm);
              setOverDueTaskINC(resultData.mailforoverduetasktoic);
            }
          }
        }).catch((error) => {
          console.log(error);
        });
      }

    })
  };

  return (
    <>
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"ProjectTaskMgmtEmailConfig"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Project Task Email Config</a>
              </div>
            </div>
            {isLoading && (
              <img
                src={loading}
                className={modules["loading_gif"]}
                alt="Loading..."
              />
            )}
            <Grid container className={modules["mid-cont"]} style={{ marginLeft: "8rem" }}>
              <Grid
                item
                lg={12}
                sx={{ marginTop: "2rem" }}
                className={modules["mid-cont"]}
              >
                <Stack direction="row" spacing={5}>
                  <label className={modules["email_label-style"]} >
                    Instance:<span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl component="fieldset" >
                    <Select
                      IconComponent={ArrowDropDownIcon}
                      className={modules["emailc_dropdownSelect"]}
                      value={displayvalue}
                      style={{ fontSize: '12px' }}
                      onChange={(e) => { handleChangeInstance(e); }}
                    >
                      {dropdownData.map((i) => {
                        return (
                          <MenuItem style={{ fontSize: '12px' }} value={i} key={i}>
                            {i}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid container className={modules["mid-cont"]} style={{ marginLeft: "8rem" }}>
              <Grid
                item
                lg={12}
                sx={{ marginTop: "2rem" }}
                className={modules["mid-cont"]}
                container
                direction="row"
                alignItems="center"
              >
                <Stack direction="row" spacing={5}>
                  <label className={modules["email_label-style"]}>
                    Predeccessor Task Completion notification mail to be sent (to all Successors task managers) : <span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl component="fieldset" >
                    <RadioGroup
                      row
                      aria-label="instance"
                      name="instance"
                      value={allTaskManager}
                      onChange={(e) => { setAllTaskManager(e.target.value) }}>
                      <FormControlLabel
                        value="1"
                        control={<Radio color="default" size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio color="default" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid container className={modules["mid-cont"]} style={{ marginLeft: "8rem" }}>
              <Grid
                item
                lg={12}
                sx={{ marginTop: "2rem" }}
                className={modules["mid-cont"]}
                container
                direction="row"
                alignItems="center"
              >
                <Stack direction="row" spacing={5}>
                  <label className={modules["email_label-style"]} >
                    Project start notification mail to be sent (to first task's manager(s)):<span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl component="fieldset" >
                    <RadioGroup
                      row
                      aria-label="instance"
                      name="instance"
                      value={firstTaskManager}
                      onChange={(e) => { setFirstTaskManager(e.target.value) }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="default" size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio color="default" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid container className={modules["mid-cont"]} style={{ marginLeft: "8rem" }}>
              <Grid
                item
                lg={12}
                sx={{ marginTop: "2rem" }}
                className={modules["mid-cont"]}
                container
                direction="row"
                alignItems="center"
              >
                <Stack direction="row" spacing={5}>
                  <label className={modules["email_label-style"]} >
                    Project Manager should be kept in CC in notification mails to task managers<span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl component="fieldset" >
                    <RadioGroup
                      row
                      aria-label="instance"
                      name="instance"
                      value={ccNofification}
                      onChange={(e) => { setCCNotification(e.target.value) }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="default" size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio color="default" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid container className={modules["mid-cont"]} style={{ marginLeft: "8rem", marginTop: '1rem' }}>
              <Divider sx={{ height: "20px !important", width: "40%", borderStyle: 'dashed', borderColor: 'gray !important', borderBottomWidth: "2px" }} />

            </Grid>

            <Grid container className={modules["mid-cont"]} style={{ marginLeft: "8rem" }}>
              <Grid
                item
                lg={12}
                sx={{ marginTop: "1rem" }}
                className={modules["mid-cont"]}
                container
                direction="row"
                alignItems="center"
              >
                <Stack direction="row" spacing={5}>
                  <label className={modules["email_label-style"]} >
                    Notification of overdue taks to Task Managers: <span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl component="fieldset" >
                    <RadioGroup
                      row
                      aria-label="instance"
                      name="instance"
                      value={overDueTaskTM}
                      onChange={(e) => { setOverDueTaskTM(e.target.value) }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="default" size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio color="default" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid container className={modules["mid-cont"]} style={{ marginLeft: "8rem" }}>
              <Grid
                item
                lg={12}
                sx={{ marginTop: "1rem" }}
                className={modules["mid-cont"]}
                container
                direction="row"
                alignItems="center"
              >
                <Stack direction="row" spacing={5}>
                  <label className={modules["email_label-style"]} >
                    Notification of Overdue Tasks to L+1 of the Task Managers: <span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl component="fieldset" >
                    <RadioGroup
                      row
                      aria-label="instance"
                      name="instance"
                      value={overDueTaskL1}
                      onChange={(e) => { setOverDueTaskL1(e.target.value) }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="default" size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio color="default" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid container className={modules["mid-cont"]} style={{ marginLeft: "8rem" }}>
              <Grid
                item
                lg={12}
                sx={{ marginTop: "1rem" }}
                className={modules["mid-cont"]}
                container
                direction="row"
                alignItems="center"
              >
                <Stack direction="row" spacing={5}>
                  <label className={modules["email_label-style"]} >
                    Notification of Overdue Tasks to Project Manager: <span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl component="fieldset" >
                    <RadioGroup
                      row
                      aria-label="instance"
                      name="instance"
                      value={overDueTaskPM}
                      onChange={(e) => { setOverDueTaskPM(e.target.value) }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="default" size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio color="default" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>

              </Grid>
            </Grid>

            <Grid container className={modules["mid-cont"]} style={{ marginLeft: "8rem" }}>
              <Grid
                item
                lg={12}
                sx={{ marginTop: "1rem" }}
                className={modules["mid-cont"]}
                container
                direction="row"
                alignItems="center"
              >
                <Stack direction="row" spacing={5}>
                  <label className={modules["email_label-style"]} >
                    Notification of Overdue Tasks to Instance Coordinators: <span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl component="fieldset" >
                    <Grid container direction="row" alignItems="center">
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Set"
                        sx={{ marginRight: "1rem" }}
                      />
                      <textarea
                        disabled
                        placeholder="Enter text"
                        rows="3"
                        cols="20"
                        style={{
                          resize: "none",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid rgba(0, 0, 0, 0.23)"
                        }}
                        value={overDueTaskINC}
                        onChange={(e) => { setOverDueTaskINC(e.target.value) }}

                      ></textarea>
                    </Grid>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid container className={modules["btn1-cont"]} style={{ marginLeft: "34rem" }}>
              <Grid >

                <button className={modules["btn1"]} onClick={handleSave}>
                  Save
                </button>
              </Grid>

              {/* <button className={modules["btn2"]} onClick={handleReset}>
                Reset
              </button> */}
            </Grid>

            {historyTag ?

              <a
                href="#"
                className={modules["vch"]}
                onClick={handleIconToggle}
              >
                {showPlus ? (
                  <AddIcon sx={{ color: "0066ff", marginBottom: "-6px" }} />
                ) : (
                  <RemoveIcon sx={{ color: "0066ff", marginBottom: "-5px" }} />
                )}{" "}
                View Config History
              </a>
              : ""}


            <Grid container style={{ display: showPlus == false ? "" : "none" }}>
              <Grid container sx={{ marginLeft: "6rem", }}>
                <div className={modules["ccpm_table-container"]} style={{ marginTop: "1.5%", marginBottom: '2rem' }}>
                  <Table className={modules["ptmem_custom-table"]}>
                    <TableHead>
                      <TableRow >
                        <th>Sr.No</th>
                        <th>Setting For Mail to Successor Task Manager</th>
                        <th>Setting For Mail to First Task Manager</th>
                        <th>Setting For Project Manager to be Kept in CC</th>
                        <th>Setting For Notification of Overdue Tasks to Task Manager</th>
                        <th>Setting For Notification of Overdue Tasks to L+1 of Task Manager</th>
                        <th>Setting For Notification of Overdue Tasks to Project Manager</th>
                        <th>Setting For Notification of Overdue Tasks to Instance Coordinators</th>
                        <th>Created By</th>
                        <th>Created On</th>
                      </TableRow>

                    </TableHead>
                    <TableBody className={modules["table-prop"]}>
                      {historyTableData.map((item, index) => (
                        <TableRow key={index} className={modules["table-row"]}>
                          <TableCell >
                            <Typography className={modules["ccpm_label-style"]}>
                              {serialNumber + index}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={modules["ccpm_label-style"]}>
                              {item.actiononmailforsucctaskmanager}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={modules["ccpm_label-style"]}>
                              {item.actiononmailforinitialtaskmanager}
                            </Typography>

                          </TableCell>
                          <TableCell>
                            <Typography className={modules["ccpm_label-style"]}>
                              {item.actiononcctoprojectmanager}
                            </Typography>

                          </TableCell>
                          <TableCell>
                            <Typography className={modules["ccpm_label-style"]}>
                              {item.actiononmailforoverduetasktotm}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={modules["ccpm_label-style"]}>
                              {item.actiononmailforoverduetasktol1}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={modules["ccpm_label-style"]}>
                              {item.actiononmailforoverduetasktopm}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={modules["ccpm_label-style"]}>
                              {item.actiononmailforoverduetasktoic}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={modules["ccpm_label-style"]}>
                              {item.username}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={modules["ccpm_label-style"]}>
                              {new Date(item.createdon).toLocaleDateString('en-GB')}<br />
                              {new Date(item.createdon).getHours()}:{new Date(item.createdon).getMinutes()}:{new Date(item.createdon).getSeconds()}
                              {/* {item.createdon} */}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>

              </Grid>

            </Grid>
          </Grid>



        </Grid>
      </Grid >
    </>
  );
};

export default ProjectTaskMgmtEmailConfig;
