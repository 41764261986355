import React, { useEffect, useState } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Stack,
} from "@mui/material";

import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import loading from "../../../Assets/Loading_image.gif";
import {
  GetProjectss,
  GetProjectManagerss,
  GetFirstTaskManager,
  GetLastTaskManager,
  GetInstancesListt,
  GetTableDetailss,
  ChangePM,
  Save_Detail,
} from "../../../Services/CCPM";


function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const ModifyTaskAttribute = () => {

  const [isLoading, setIsLoading] = useState(false); //loading gif
  const [checkNoRec, setCheckNoRec] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
    };
    GetInstancesListt(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setInstance(response.data.success.data);
          handleInstance(response.data.success.data[0].id); //select 1st instance by default
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //WHEN INSTANCE SELECTED
  const handleInstance = (i) => {
    setCheckNoRec(false);
    setIsLoading(true);
    setSelectedInstance(i);
    setSelectedProjectManager("Select");
    setSelectedTaskInitialManager("Select");
    setSelectedProjectName("Select");
    setSelectedPM("Select");
    setTemp([]);
    setShowTable(false);
    setSstate({
      checkedRows: new Set(),
      allChecked: false,
    });

    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: i,
    };
    let body1 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: i,
      includeViewAccess: 0,
      isSpecificProjStatus: 0,
    };
    let body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: i,
      projId: selectedProjectName === "" || selectedProjectName === "Select"
        ? 0
        : selectedProjectName,
    };

    let body3 = {
      level2Id: i
    }

    //GET LAST TASK MANAGER
    GetLastTaskManager(body3)
      .then((response) => {
        setIsLoading(false); // Ensure loader is stopped in every case

        // Check if the response status is 200
        if (response.status === 200) {
          const successData = response?.data?.success?.data;
          if (successData) {
            setTaskLastManager(successData); // Only set data if it's not null
          } else {

            setTaskLastManager([]); // Set an empty array to avoid null references
          }
        }
      });

    //GET MANAGERS AND MANAGER TO BE LIST
    GetFirstTaskManager(body2)
      .then((response) => {
        setIsLoading(false); // Ensure loader is stopped in every case

        // Check if the response status is 200
        if (response.status === 200) {
          const successData = response?.data?.success?.data;
          if (successData) {
            setTaskInitialManager(successData); // Only set data if it's not null
          } else {

            setTaskInitialManager([]); // Set an empty array to avoid null references
          }
        }
        // Check if there is an error message in the response body
        else if (response?.response?.data?.error?.message) {
          alert(response.response.data.error.message);
          setTaskInitialManager([]);
        }
        // Handle cases where the message is in the "success" object but is `null`
        else if (response?.response?.data?.success?.message !== null) {
          alert(response.response.data.success.message);
          setTaskInitialManager([]);
        }
        // Handle cases where no message or data is available
        else {
          alert("Unknown error occurred.");
          setTaskInitialManager([]);
        }
      })
      .catch((error) => {
        setIsLoading(false); // Ensure loader is stopped in the error case
        console.log(error); // Log the actual error for debugging
        alert("An error occurred. Please try again later.");
      });


    GetProjectManagerss(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setProjectManager(response.data.success.data.ExistingProjectManager);
          setPM(response.data.success.data.ActiveUsre);
        }
        else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
          setProjectManager([])
          setPM([])
        }
        else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
          setProjectManager([])
          setPM([])
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
          setProjectManager([])
          setPM([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //GET PROJECT NAMES LIST
    GetProjectss(body1)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setProjectName(response.data.success.data);
        }
        else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
          setProjectName([])
        }
        else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
          setProjectName([])
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
          setProjectName([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchTaskInitialManager = () => {
    const body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: selectedInstance,
      projId: selectedProjectName === "" || selectedProjectName === "Select" ? 0 : selectedProjectName,
    };
  
    return GetFirstTaskManager(body2)
      .then((response) => {
        if (response.status === 200) {
          const successData = response?.data?.success?.data;
          if (successData) {
            setTaskInitialManager(successData);
          } else {
            setTaskInitialManager([]);
          }
        } else if (response?.response?.data?.error?.message) {
          alert(response.response.data.error.message);
          setTaskInitialManager([]);
        } else {
          alert("Unknown error occurred.");
          setTaskInitialManager([]);
        }
      })
      .catch((error) => {
        console.log(error);
        alert("An error occurred while fetching Task Managers.");
      });
  };
  
  //SELECTED PROJECT NAME
  // SELECTED PROJECT NAME
  const handleProject = (i) => {
    // Directly update selected project before making the API call
    setSelectedProjectName(i);
  
    // Call the fetchTaskManagerData function if a valid project is selected
    if (i !== "Select" && i !== "") {
      fetchTaskManagerData(i); // Call the new function to handle the API call
    }
  };

  const fetchTaskManagerData = (selectedProject) => {
    // Prepare the body for the API call
    const body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: selectedInstance, // Use the selected instance
      projId: selectedProject === "Select" ? 0 : selectedProject, // Handle default 'Select'
    };
  
    // Set loading state while the API is fetching data
    setIsLoading(true);
  
    // Fetch the updated Task Manager data
    GetFirstTaskManager(body2)
      .then((response) => {
        setIsLoading(false); // Stop loader after API response
  
        if (response.status === 200) {
          const successData = response?.data?.success?.data;
          if (successData) {
            setTaskInitialManager(successData); // Set the fetched Task Managers
          } else {
            setTaskInitialManager([]); // Reset Task Manager if no data
          }
        } else {
          // Handle non-200 status responses
          const errorMessage =
            response?.response?.data?.error?.message ||
            response?.response?.data?.message ||
            "Unknown error occurred.";
          alert(errorMessage);
          setTaskInitialManager([]); // Reset Task Manager in case of error
        }
      })
      .catch((error) => {
        setIsLoading(false); // Stop loader in case of error
        console.error(error); // Log the error
        alert("An error occurred. Please try again later.");
      });
  };

  const handleTaskInitialManager = (i) => {
    setSelectedTaskInitialManager(i);
  };

  //SELECTED PROJECT MANAGER
  const handleProjectManager = (i) => {
    setSelectedProjectManager(i);
  };

 


  const [temp, setTemp] = useState([]); //detail table data
  const [showTable, setShowTable] = useState(false);

  //HANDLING CHECKED ROWS PART
  const [sstate, setSstate] = useState({
    checkedRows: new Set(),
    allChecked: false,
  });
  const handleCheckboxChange = (index) => {
    setSstate((prevState) => {
      const checkedRows = new Set(prevState.checkedRows);
      if (checkedRows.has(index)) {
        checkedRows.delete(index);
      } else {
        checkedRows.add(index);
      }
      const allChecked = checkedRows.size === temp.length;
      return { checkedRows, allChecked };
    });
  };
  const handleTableHeadCheckboxChange = () => {
    setSstate((prevState) => {
      const allChecked = !prevState.allChecked;
      const checkedRows = new Set();
      if (allChecked) {
        temp.forEach((_, index) => checkedRows.add(index));
      }
      return { checkedRows, allChecked };
    });
  };

  //TO PASS IDLIST TO API REQUEST BODY
  const getCheckedRows = () => {
    return editableTasks
      .filter((_, index) => sstate.checkedRows.has(index))
      .map((item) => ({
        id: String(item.id),
        taskName: item.taskname,
        taskProcessNotes: item.taskprocessnotes,
        taskManagerId: showReplaceManager ? String(selectedPM) : String(item.taskmanagerid), 
        projectId: String(item.projectsid),  
        projectName: String(item.projectname),
        taskIdentityId: String(item.taskstatusid),  
        userId: parseInt(sessionStorage.getItem("loginId")),
        department: String(item.department) 
      }));
  };

  //DROP DOWN VALUES
  const [instance, setInstance] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [projectManager, setProjectManager] = useState([]);
  const [taskInitialManager, setTaskInitialManager] = useState([]);
  const [taskLastManager, setTaskLastManager] = useState([]);
  const [PM, setPM] = useState([]);


  //SELECTED VALUES
  const [selectedInstance, setSelectedInstance] = useState("Select");
  const [selectedProjectName, setSelectedProjectName] = useState("Select");
  const [selectedProjectManager, setSelectedProjectManager] = useState("Select");
  const [selectedTaskInitialManager, setSelectedTaskInitialManager] = useState("Select");
  const [selectedPM, setSelectedPM] = useState("Select");
  const [showReplaceManager, setShowReplaceManager] = useState(false);

  const handleReplacedCheckboxChange = () => {
    setShowReplaceManager((prev) => !prev);
  };

  const handleSearch = () => {
    setShowReplaceManager(false);
    setCheckNoRec(false);
    setIsLoading(true);
    setShowTable(true);
    setSstate({
      checkedRows: new Set(),
      allChecked: false,
    });
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: selectedInstance,
      projId:
        selectedProjectName === "" || selectedProjectName === "Select"
          ? 0
          : selectedProjectName,
      TaskManagerId:
        selectedTaskInitialManager === "" || selectedTaskInitialManager === "Select"

          ? 0
          : selectedTaskInitialManager,
      isModifySubtaskAttribute: 0,
    };
    GetTableDetailss(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          const data = response?.data?.success?.data || []; // Default to empty array if data is null
          setTemp(data);
          setEditableTasks(data.map(item => ({
            ...item,
            taskname: item.taskname || '',
            taskprocessnotes: item.taskprocessnotes || '',
            department: item.department || '',
          })));

          if (data.length === 0) {
            setCheckNoRec(true); // Set to false if data is empty
          }
        }
        else if (response.data.statusCode === 403) {
          setIsLoading(false);
          alert(response?.data?.success?.message);
          setTemp([])

        }
        else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
          setTemp([])
        }
        else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
          setTemp([])
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
          setTemp([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [editableTasks, setEditableTasks] = useState([]);
  const handleEditFieldChange = (index, field, value) => {
    setEditableTasks((prev) => {
      const updatedTasks = [...prev];
      updatedTasks[index] = { ...updatedTasks[index], [field]: value };
      return updatedTasks;
    });
  };


  const handleReset = () => {
    handleInstance(instance[0].id);
    setCheckNoRec(false);
  };



  const handleSaveAllMTA = () => {

    if (getCheckedRows().length === 0) {
      alert("Please select atleast one checkbox(Grid).");
      return;
    }
    else {
      setIsLoading(true);
      let body = {
        allTaskAttributes: getCheckedRows(),
      };

      Save_Detail(body)
        .then((response) => {
          if (response.data.statusCode === 200) {
            fetchTaskInitialManager();
            setIsLoading(false);
            const successData = response.data.success.data;

            if (successData !== null) {
              alert(successData);
            }
            handleReplacedCheckboxChange();
          
            handleSearch();
            setSelectedPM("Select");
          }
          else if (response.data.statusCode === 500) {
            setIsLoading(false);
            const successData = response.data.success.data;

            if (successData !== null) {
              alert(successData);
            }
            handleSearch();
          }
          else {
            setIsLoading(false);
            console.log('Error', response);
            alert(response?.response?.data?.success?.message);
            handleInstance(instance[0].id);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }

  };


  useEffect(() => {
    // Reset to the first page when chartData changes
    setCurrentPage(1);
  }, [temp]);


  const RowsPerPage = 10;
  const MaxVisiblePages = 10;

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total pages based on chartData length
  const totalPages = Math.ceil(temp.length / RowsPerPage);
  const startIndex = (currentPage - 1) * RowsPerPage;
  const endIndex = startIndex + RowsPerPage;
  const displayedData = temp.slice(startIndex, endIndex);


  const handlePageChange = (page) => {
    if (page === "prev") {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    } else if (page === "next") {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    } else {
      setCurrentPage(page);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= MaxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= MaxVisiblePages - 3) {
        for (let i = 1; i <= MaxVisiblePages - 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");

        const remainingPages = totalPages - currentPage;

        if (remainingPages < MaxVisiblePages - 3) {
          for (let i = currentPage - (MaxVisiblePages - 4); i <= totalPages; i++) {
            pageNumbers.push(i);
          }
        } else {
          for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pageNumbers.push(i);
          }

          pageNumbers.push("...");
          pageNumbers.push(totalPages);
        }
      }
    }
    return pageNumbers;
  };

  const handleBlur = (index, field) => {
    // Define a regular expression for the special characters
    const specialChars = /[=+\[\]\/]/g;

    // Get the current value of the field
    const value = editableTasks[index][field];

    if (specialChars.test(value)) {
      alert("No special characters allowed");
      // Set the value to empty if disallowed characters are present
      handleEditFieldChange(index, field, ""); // Set to empty
    } else {
      // If no special characters, update the value
      handleEditFieldChange(index, field, value);
    }
  };

  const handleKeyDown = (e) => {
    
    // Allowed characters: letters, numbers, spaces, and specific special characters
    const allowedChars = /^[a-zA-Z0-9&()_.?=+\[\]\/-]*$/;

  
    // If the key pressed does not match the allowed characters, prevent the input
    if (!allowedChars.test(e.key)) {
      e.preventDefault(); // Block the keypress
     
    }
  };

  const handleKeyDownNew = (e) => {
    
    // Allowed characters: letters, numbers, spaces, and specific special characters
    const allowedChars = /^[a-zA-Z0-9&()_.=+\[\]\/-]*$/;

  
    // If the key pressed does not match the allowed characters, prevent the input
    if (!allowedChars.test(e.key)) {
      e.preventDefault(); // Block the keypress
     
    }
  };
  




  return (
    <div
      style={{ overflowX: showTable && temp.length > 0 ? "auto" : "hidden" }}
    >
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"ModifyTaskAttribute"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>Project Plan</a>
                <img src={bread} alt="" />
                <a>Modify Task Attribute</a>
              </div>
            </div>

            <div container className={modules["parent-container-mta"]}>
              <table className={modules["table-cpm"]}>
                <tbody style={{ width: "100%" }}>
                  <tr>
                    <td>
                      <span style={{ marginRight: "5px" }}>
                        <strong>Instance :</strong>
                      </span>
                      <select
                        className={modules["dropdownSelect1-apm"]}
                        value={selectedInstance}
                        onChange={(e) => handleInstance(e.target.value)}
                      >
                        {instance.length > 0 && instance?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <span style={{ marginRight: "5px" }}>
                        <strong>Project Name :</strong>
                      </span>
                      <select
                        IconComponent={ArrowLine}
                        className={modules["dropdownSelect1-apm"]}
                        value={selectedProjectName}
                        onChange={(e) => handleProject(e.target.value)}
                      >
                        {projectName.length > 0 &&
                          <option value="Select">SELECT</option>
                        }
                        {projectName.length > 0 && projectName?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.projectname}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <span style={{ marginRight: "5px" }}>
                        <strong>Task Manager :</strong>
                      </span>
                      <select
                        IconComponent={ArrowLine}
                        className={modules["dropdownSelect1-apm"]}
                        value={selectedTaskInitialManager}
                        onChange={(e) => handleTaskInitialManager(e.target.value)}
                      >
                        {taskInitialManager.length > 0 &&
                          <option value="Select">SELECT</option>
                        }
                        {taskInitialManager.length > 0 && taskInitialManager?.map((i) => (
                          <option value={i.id} key={i.id}>
                            {i.username}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr
                    className={[
                      modules["btn1-cont-cpm"],
                      modules["button-container-cpm"],
                    ].join(" ")}
                  >
                    <button
                      style={{ marginLeft: '1rem' }}
                      className={[
                        modules["btn1-cpm"],

                      ].join(" ")}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["btn2-cpm"]}
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>
            {checkNoRec && (
              <Stack className={modules["pbp_bottom_stack"]}>
                <p className={modules["pbp_no_record"]}>No Record Found.</p>
              </Stack>
            )}


            {showTable && temp.length > 0 && (

              <Grid container className={modules["info-row"]} style={{ marginBottom: '0.5rem' }}>
                <Grid item xs={12}>
                  <Grid container spacing={0.5} style={{ height: '0.65cm' }} className={modules["info-containerPSCD"]}>
                    <Grid item xs={1.3} style={{ paddingLeft: '8px' }} className={modules["label-style"]} >
                      {!showReplaceManager && (
                        <button className={modules["PCbtn"]} onClick={handleSaveAllMTA}>
                          Save all
                        </button>
                      )}
                    </Grid>

                    <Grid item xs={3} className={modules["label-style"]} style={{ fontSize: '12px', color: '#414141' }}>
                      <input type="checkbox"
                        checked={showReplaceManager}
                        onChange={handleReplacedCheckboxChange}
                        style={{ verticalAlign: 'middle', fontWeight: 'bold' }}
                      />
                      <span style={{ verticalAlign: 'middle', paddingLeft: '3.5px', fontWeight: 'normal' }}>Replace Task Manager Against Selected Task</span>
                    </Grid>




                  </Grid>
                </Grid>
              </Grid>

            )}


            {showTable && temp.length > 0 && (
              <Grid
                container
                className={modules[("table-grid-cpm", "table-grid-margin")]}
              >
                <div style={{ marginBottom: '2rem' }} className={modules["table-container-mta"]}>
                  <Table
                    className={[
                      modules["custom-table-cpm"],
                      modules["custom-table-th"],
                    ].join(" ")}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <input
                            type="checkbox"
                            checked={sstate.allChecked}
                            onChange={handleTableHeadCheckboxChange}
                            className={modules["custom-checkbox"]}
                          />
                        </TableCell>
                        <TableCell>
                          Project
                          <br />
                          Name
                        </TableCell>
                        <TableCell>
                          Project
                          <br />
                          Manager
                        </TableCell>
                        <TableCell>
                          Task <br />Id
                        </TableCell>
                        <TableCell>Department</TableCell>
                        <TableCell>
                          Task Name
                        </TableCell>
                        <TableCell> Task Status</TableCell>
                        <TableCell>
                          Task Manager
                        </TableCell>
                        <TableCell>
                          Task Process
                          <br />
                          Notes
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {temp.length > 0 && displayedData?.map((item, index) => (
                        <TableRow key={index} className={modules["table-row"]}>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={sstate.checkedRows.has(index)}
                              onChange={() => handleCheckboxChange(index)}
                              className={modules["custom-checkbox"]}
                            />
                          </TableCell>


                          <TableCell className={modules["table-row"]}>
                            {item.projectname}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {item.projmanager}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {item.taskid}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            <textarea
                              className={modules["text-area"]}
                              type="text"
                              value={editableTasks[index]?.department || ''}
                              onChange={(e) => handleEditFieldChange(index, 'department', e.target.value)}
                              onBlur={() => handleBlur(index, 'department')} // Add the onBlur event
                              onKeyDown={handleKeyDownNew} 
                            />
                          </TableCell>

                          <TableCell className={modules["table-row"]}>
                            <textarea
                              className={modules["text-area"]}
                              type="text"
                              disabled={showReplaceManager}
                              value={editableTasks[index]?.taskname || ''}
                              onChange={(e) => handleEditFieldChange(index, 'taskname', e.target.value)}
                              onBlur={() => handleBlur(index, 'taskname')} // Add the onBlur event
                              onKeyDown={handleKeyDownNew} 
                            />
                          </TableCell>


                          <TableCell className={modules["table-row"]}>
                            {item.status}
                          </TableCell>

                          <TableCell className={modules["table-row"]}>

                            <select
                              value={editableTasks[index]?.taskmanagerid || ''}
                              className={modules["dropdownSelect1-apm"]}
                              disabled={showReplaceManager}
                              onChange={(e) => handleEditFieldChange(index, 'taskmanagerid', e.target.value)}
                            >
                              {taskInitialManager.length > 0 &&
                                <option value="Select">SELECT</option>
                              }

                              {taskInitialManager.map((manager) => (
                                <option value={manager.id} key={manager.id}>
                                  {manager.username}
                                </option>
                              ))}
                            </select>
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            <textarea
                              className={modules["text-area"]}
                              type="text"
                              value={editableTasks[index]?.taskprocessnotes || ''}
                              disabled={showReplaceManager}
                              onChange={(e) => handleEditFieldChange(index, 'taskprocessnotes', e.target.value)}
                              onBlur={() => handleBlur(index, 'taskprocessnotes')} // Add the onBlur event
                              onKeyDown={handleKeyDown} 
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                      {totalPages > 1 && (
                        <TableRow>
                          <TableCell colSpan={16}>
                            <div className={modules["pagination"]}>
                              <span
                                className={currentPage === 1 ? `${modules["disabled"]}` : ""}
                                onClick={() => handlePageChange("prev")}
                              >
                                Prev
                              </span>
                              {getPageNumbers().map((pageNumber, index) => (
                                <span
                                  key={index}
                                  className={
                                    pageNumber === currentPage
                                      ? `${modules["active-page"]}`
                                      : `${modules["page-number"]}`
                                  }
                                  onClick={() =>
                                    typeof pageNumber === "number"
                                      ? handlePageChange(pageNumber)
                                      : null
                                  }
                                >
                                  {pageNumber}
                                </span>
                              ))}
                              <span
                                className={currentPage === totalPages ? `${modules["disabled"]}` : ""}
                                onClick={() => handlePageChange("next")}
                              >
                                Next
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            )}
            {showReplaceManager && showTable && temp.length > 0 && (
              <Grid
                container
                className={[
                  modules["parent-container-mta"],
                  modules["bottom-grid-mta"],
                ].join(" ")}
              >
                <div className={modules["bottom-grid-heading-cpm"]}>
                  Replace Project Manager
                </div>
                <Stack direction="row" alignItems="center">
                  <div>
                    <strong>Task Manager To Be: </strong>
                  </div>
                  <select
                    className={modules["dropdownSelect-bottom"]}
                    value={selectedPM}
                    onChange={(e) => setSelectedPM(e.target.value)}
                    style={{}}
                  >
                    {taskLastManager.length > 0 &&
                      <option value="Select">SELECT</option>
                    }
                    {taskLastManager.length > 0 && PM?.map((i) => (
                      <option value={i.id} key={i}>
                        {i.username}
                      </option>
                    ))}
                  </select>
                  <button
                    className={modules["btn1-bottom"]}
                    onClick={handleSaveAllMTA}
                  >
                    Change TM
                  </button>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ModifyTaskAttribute;
