import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/WL1.jpg";
import IC2 from "../HelpPopups/images/WL2.jpg";
import IC3 from "../HelpPopups/images/WL3.jpg";
import IC4 from "../HelpPopups/images/WL4.jpg";
import { Link } from "react-router-dom";

const WIPLimitMasterhelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight:"bold"
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>
                WIP and Project Limit Master
              </h3>
              <br />
              In ”WIP and Project Limit Master” screen, the user can specify the
              limit for the number of projects to be started in a week and also
              the maximum number of projects that can be handled by a division.
              <br />
              <li>PATH : CCPM \ Masters \ WIP and Project limit master</li>
              <br />
              <li>
                for selecting the values for “Total WIP Limit” & “Project limit
                per week”, click on “Add” button and enter / Select the values
                in the input fields and click on the “Save” link, the record
                will get saved.
              </li>
              <br />
              <li>
                We can edit the saved data, by clicking on the “Edit” hyperlink
                and change / edit the data and click on the “Save” hyperlink.
              </li>
              <br />
              <li>
                For deleting any particular record click on “Delete” link and
                for deleting multiple records select the particular records and
                click on “Delete” button
              </li>
              <br />
              <li>
                Search filter provided for the user convenience for faster
                access of data, and for searching any data in the defined levels
                (data mapping fields are defined in the “Product Configuration
                settings” screen)
              </li>
              <li>
                Select the data in the search fields and click on “Submit”
                button.
              </li>
              <li>
                Records related to searched data will be appear and “Reset”
                button can be used to clear the search criteria
              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC1} alt="IC1" />
              </Stack>
              <br />
              <br />
              <br />
              <br />
              <li
                style={{ listStyleType: "square", textDecoration: "underline" }}
              >
                Validations on the screen:-
              </li>
              <br />
              <li>
              Duplication of existing record should not be allowed i.e re-adding of the existing record again
              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC2} alt="IC2" />
              </Stack>
              <li>
              User should not leave any input field blank before saving the data, as click of “Save” link keeping the fields empty makes the error popup window to appear
 
              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC3} alt="IC3" />
              </Stack>
              <li>
              User should make selection of the data before the click of “Delete” button, as without selection of data the popup window appears
              </li>
              <Stack sx={{ paddingRight: "1rem", marginBottom:"10%"}}>
                <img src={IC4} alt="IC4" />
              </Stack>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WIPLimitMasterhelpPopup;
