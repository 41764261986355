import React from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import CSS1 from "../HelpPopups/images/CSS1.jpg";
import CSS2 from "../HelpPopups/images/CSS2.jpg";
import CSS3 from "../HelpPopups/images/CSS3.jpg";
import { Link } from "react-router-dom";

const CCPMSettingParameterPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "5rem" }}>CCPM Config setting</h3>
              <br />
              <p style={{}}>
                <p style={{}}>
                  In this master user can define the CCPM Setting Percentage Parameter. User can define this for the instance level or at attribute level. Default percentage is 50 % for all. User can define different vales for the task, project buffer and Feeding buffer.                </p>
              </p>
              <br />

              <p style={{ fontWeight: "bold" }}>Session Path – CCPM / Master/ CCPM Config Setting (
                CCPM Setting Percentage Parameter Master)</p>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={CSS1} alt="AM1" />
              </Stack>
              <br />

              <li>Instance Name – Select instance name from the dropdown provided.</li>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={CSS2} alt="AM1" />
              </Stack>
              <br />

              <p>Once instance is selected the then above screen is appeared.</p>
              <p>Default Percentage Parameter Setting – This defines at which level user need to define the values of CCPM is maintained.</p>
              <br />
              <li>Instance – If instance is selected then user can define or input the percentage which is applicable for the all projects of that instance.</li>
              <li>Attribute level – If attribute level is selected then user can define or input the percentage which is applicable for the all projects of that attribute.</li>
              <li>Not Required – If this option is selected then the default percentage will be applied for the instance.</li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={CSS3} alt="AM2" />
              </Stack>

              <br />
              <li>Task Reduction Percentage – This percentage indicated that by this percent task duration will be reduced. When CCPM is applied for the project. Default value for the task reduction is 50%. This means if one task duration is supposed 4 days, after applying CCPM it will become 2 days.</li>
              <li>Project Buffer – This percentage indicates that how much of the cut lead time to be added to the project buffer. This is calculated in the critical chain of the project. Default parameter is 50%. It means that when CCPM is applied for the project then the all task durations will be reduced by the defined percentage. Then the cut duration I taken together. After this that duration is reduced by the percentage defined here. The portion is added to the project buffer and remaining duration is not considered. </li>
              <li>Feeding buffer % - This percentage is used for the nodes which are not on critical path of the project. When CCPM is applied for the project then duration for the tasks is reduced. The cut duration of tasks on critical path are added as project buffer. But the cut duration of the other tasks is added wherever the tasks are feeding to the critical path. The percentage applied as maintained in the system.</li>
              <li>Once values are maintained then press save button to freeze values.</li>
              <li>By pushing delete all user can delete all values for the instance.</li>
              <br />
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CCPMSettingParameterPopup;
