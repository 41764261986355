import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../Assets/godrej_logo.gif";
import prostream from "../Assets/prostream_logo.png";
import SampleSidebar from "../Landing/SampleSidebar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";

const Home = ({ page }) => {
  const location = useLocation();
  const { response } = location.state || {};
  const [isClicked, setIsClicked] = useState(false);
  const [welcome, setWelcome] = useState(false);
  const [linkColor, setLinkColor] = useState("#0000EE");

  const handleLogoutClick = () => {
    setIsClicked(true);
    sessionStorage.clear();
    localStorage.clear();
  };

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
  }, []);

  useEffect(() => {
    if (location.pathname === "/home") {
      setWelcome(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (response) {
      console.log("Login Response:", response);
    }
  }, [response]);

  return (
    <div>
      <Stack>
        <SampleSidebar />
      </Stack>
      <Box sx={{ marginLeft: "1.5%", marginRight: "40px" }}>
        <Stack
          direction="row"
          spacing={4}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "5rem", paddingTop: "10px" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
          <Stack>
            <img
              style={{
                width: "96px",
                height: "46.39px !important",
                alignSelf: "end",
                paddingTop: "10px",
              }}
              src={logo}
              alt="Godrej"
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            paddingLeft: "5.7rem",
            paddingTop: "1%",
            paddingBottom: "0.5%",
          }}
          direction="row"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: "#1061b0",
              fontFamily: "WorkSans, sans-serif !important",
            }}
          >
            Welcome {sessionStorage.getItem("Username")}
          </Typography>
          <Typography align="right" sx={{ fontFamily: "WorkSans" }}>
            {page !== "" ? (
              <Link
                style={{
                  color: linkColor,
                  marginRight: "0.6rem",
                  fontSize: "14px",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  setLinkColor("red");
                  setTimeout(() => {
                    window.open(
                      `/ccpm/${page}helpPopup`,
                      "mypopuptitle",
                      "width=800,height=600"
                    );
                    setLinkColor("#0000EE");
                  }, 100);
                }}
              >
                Help
              </Link>
            ) : (
              <span
                style={{
                  color: linkColor,
                  marginRight: "0.6rem",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
              >
                Help
              </span>
            )}

            <Link
              style={{
                color: "blue",
                fontSize: "14px",
                borderLeft: "1.5px solid #0000EE",
              }}
              to="/"
            >
              <span
                style={{ color: "#666", marginLeft: "0.7rem" }}
                onClick={handleLogoutClick}
              >
                Logout
              </span>
            </Link>
          </Typography>
        </Stack>
        <Divider sx={{ marginBottom: "1%", width: "100vw" }} />
        {welcome && (
          <Typography
            sx={{
              fontSize: "10px",
              color: "grey",
              paddingLeft: "6rem",
              fontFamily: "'WorkSans-Regular', sans-serif",
            }}
          >
            Welcome to Home page
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default Home;
