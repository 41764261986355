import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SPC1 from "../HelpPopups/images/SPC1.png";
import SPC2 from "../HelpPopups/images/SPC2.png";
import SPC3 from "../HelpPopups/images/SPC3.png";
import SPC4 from "../HelpPopups/images/SPC4.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointConfighelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{ fontSize: "14px" }}>
              <h1 style={{ textAlign: "left" }}>
                MTA STOCK POINT CONFIGURATION
              </h1>
              <br />
              <br />
              <li className="no-bullets">
                Path `{">>"}` MTA `{">>"}` MTA Parameter `{">>"}` MTA STOCK
                POINT CONFIGURATION
              </li>
              <br />
              <br />
              <li className="no-bullets">
                This option is used for configuration of the stock point for MTA
                Instance. The records maintained here are used to publish Order
                Advice and Buffer Penetration for that Stock point. It also
                publishes Dynamic Buffer Management for that stock point using
                few records updated here (using some of the parameters).
              </li>
              <br />
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SPC1} alt="SPC1" />
              </Stack>
              <li className="no-bullets">To Add New Record –</li>
              <li style={{ marginLeft: "2rem" }}>Select MTA Instance</li>
              <li style={{ marginLeft: "2rem" }}>
                Select Add New Record Option. Then you need to fill the related
                information in the sub sequent fields.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                Similarly, there is update record option where one can edit and
                update the record.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                Delete Option – Here we can delete the records.
              </li>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SPC2} alt="SPC2" />
              </Stack>
              <br />
              <br />
              <li>
                Stock Point – Give Stock point name from the predefined list.
                (Dropdown available for this field)
              </li>
              <li>
                Replenishment Days – Enter valid Integer. This is will indicate
                the no. of days required replenish the stock in the stock point.
                ( E.g. – 30 - This will indicate replenishment time is 30 days)
              </li>
              <li>
                Top Of Yellow (TOY) Percentage – Enter pecentage of Yellow
                colour defined by the business. Normally this is 66.66 %. Value
                should be a numeric only. (E.g – 66.66 - This will indicate that
                TOY is 66.66%)
              </li>
              <li>
                Top Of Red (TOR) Percentage – Enter pecentage of Red colour
                defined by the business. Normally this is 33.33 %. Value should
                be a numeric only. (E.g – 33.33 - This will indicate that TOR is
                33.33%)
              </li>
              <li>
                Percentage Increase by DBM - Enter pecentage of Increase in
                buffer sugested as per DBM decided by the business. Normally
                this is 33 % ( 1/3 of the total Buffer). Value should be a
                numeric only. (E.g – 33 - This will indicate that Buffer will be
                increased by 33% if suggested by DBM.)
              </li>
              <li>
                Percentage Decrease by DBM - Enter pecentage of Decrease in
                buffer sugested as per DBM decided by the business. Normally
                this is 33 % ( 1/3 of the total Buffer). Value should be a
                numeric only. (E.g – 33 - This will indicate that Buffer will be
                decreased by 33% if suggested by DBM.)
              </li>
              <li>
                Rationing Logic to be Used – Rationing logic is the replenishing
                logic for the buffer qty at the stock point. Select from the
                drop down provided for the field.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                NA – If there is only one stock point the the rationing logic is
                not applicable. Then select NA.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                If there are multiple stock point the use following options from
                the dropdown -
              </li>
              <li style={{ marginLeft: "4rem" }}>
                Top_of_Color – This indicate that the replenishment will be done
                till the top of color for each stock point. Like first it will
                replenish till TOR for all stock point and the it will start
                again from first stock point and replenish till TOY. This will
                cintinue till all stock points reached to to TOG value.
              </li>
              <li style={{ marginLeft: "4rem" }}>
                Batch _Quantity – This option indicate that the replenishment
                will be done on batch Qty irrespective of the colours. For
                example If TOR for one item is 10 and current stock is 8 and
                Batch Qty for that item is 5 the it will replenish 5 to the
                stock point and the stock will become 13. Like this stock will
                be replenished till it is achived TOG. But as mentioned it is
                batch wise replenishment it may exceed TOG but it will not
                exceed ( TOG + Batch Qty -1)
              </li>
              <br />
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SPC3} alt="SPC3" />
              </Stack>
              <br />
              <li>
                Consumption to be Taken from – This indicate that from where the
                consumption of an item is to be taken for the stock point.
                Select option from the dropdown provided.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                Billed_Sales – This option indicates Qty consumption to be taken
                only from billed sales
              </li>
              <li style={{ marginLeft: "2rem" }}>
                Order_Booking – This option indicate Qty consumption to be taken
                only from billed Order booked.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                RPL_Delivery – This option indicates Qty consumption to be taken
                only from delivery against RPL.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                Producion_Issues – This option indicates Qty consumption to be
                taken only from Qty issued against production issue
              </li>
              <li style={{ marginLeft: "2rem" }}>
                Inventory_Transfer_Out – This option indicates Qty consumption
                to be taken only from inventory out from the stock point.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                All_Warehouse_Out_Transaction – This option indicates Qty
                consumption to be taken only from all out transactions from that
                stock point. This may include all above transaction.
              </li>
              <li>
                Intransit to be taken from – This field indicates that, from
                where intransit qty to be taken from. Select option from the
                dropdown provided.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                Stock_Point_Warehouse – This option indicates that the intransit
                qty to be taken from stock point warehouse.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                Stock_Point_Warehouse_For_InTransit – This option indicates that
                the intransit qty to be taken from stock point warehouse
                identified for Intransit transactions.
              </li>
              <li>
                Buffer Penetration Parameter – This field indicates that from
                where the buffer penetration if to be taken for the
                consideration of the buffer penetration which subsequently used
                for the replenishment. Select field value from the dropdown
                provided for the field.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                Physical Stock – If this option is selected then the qty is
                taken from actual physical stock (Available in System).
              </li>
              <li style={{ marginLeft: "2rem" }}>
                QAPL – If this option is selected then the qty if taken from
                Quantity available for Picking List (QAPL) which net available
                stock after allocation and committed stock.
              </li>
              <li>
                No of RPL Cycle to Increase in Buffer – In this field we define
                no of RPL cycles to increase buffer in DBM. In DBM system will
                consider cycles defined here as the monitoring period. Value
                should be integer. (E.g. if we put value 2, Then system will
                monitor for 2 replenishment cycles before giving DBM suggestion.
              </li>
              <li>
                No of RPL Cycle to decrease in Buffer – In this field we define
                no of RPL cycles to decrease buffer in DBM. In DBM system will
                consider cycles defined here as the monitoring period. Value
                should be integer. (E.g. if we put value 2, Then system will
                monitor for 2 replenishment cycles before giving DBM suggestion.
              </li>
              <li>
                Spike Percentage – This field indicate the spike percentage.
                Spike order means an order with qty more than percentage of TOG
                defined in this field. Standard practice is 33% of Buffer is
                considered as a Spike. Here value should be valid integer. **
                Currently this field is not in use. **
              </li>
              <li>
                Regular Warehouse – This field indicates that the replenishment
                to be done from warehouse. Default value is blank. Which
                indicate that the replenishment will be done from all warehouse
                where stock is available. If we mention any warehouse here, then
                replenishment will be done from that warehouse only. **
                Currently this field is not in use. **
              </li>
              <li>
                Spike Warehouse – This field indicates replenishment of the
                spike orders to be done from which warehouse. ** Currently this
                field is not in use.**
              </li>
              <li>
                Source Stock Point Percentage - This field indicates Maximum
                quantity to be booked at source in regular warehouse in terms of
                percentage of buffer
              </li>
              <li>
                Area code – This field indicates area to identify the stock
                point for picking up the warehouse code ·
              </li>
              <li>
                Buffer Penetration Blocked Quantity Enabled – This field
                indicate that system should consider the stock which under block
                for inspection. Select value from drop down available for the
                field.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                Yes – If it is set as Yes then system will not consider the
                blocked Qty for buffer penetration.
              </li>
              <li style={{ marginLeft: "2rem" }}>
                No – If it set as No then system will consider the block Qty for
                Buffer penetration report.
              </li>
              <li>
                No of RPL cycles for Phase out Item – This field indicates that
                the no of RPL cycles to be monitored for item. If any item is
                continuously in white for the defined RPL cycle, then system
                will give message as Item to considered for Phase out instead of
                decrease in Buffer. Value for this field is valid integer.
              </li>
              <li>
                No of Buffer decrease for Phase out item – This indicates no of
                time buffer is decreased for any item. This means if buffer is
                decreased more than the defined field through DBM then system
                will give message as Item to be phase out.
              </li>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SPC4} alt="SPC4" />
              </Stack>
              <br />
              <li>
                Runner Item Perc (Top ‘N’ Items) – This field is defining the
                percentage of consumption of the any item with respect of all
                items (Moving Average) if value is greater than the defined
                value then item is tagged as runner item
              </li>
              <li>
                Stranger Item Perc (Bottom ‘N’ Items) - This field is defining
                the percentage of consumption of the any item with respect of
                all items (Moving Average) if value is lesser than the defined
                value then item is tagged as Stranger item
              </li>
              <li>
                Walker Item Perc (From – To) – This field indicate that if
                consumption percentage is in between the defined range then item
                is tagged as walker item.
              </li>
              <li>
                Walker Item Perc (From – To) – This field indicate that if
                consumption percentage is in between the defined range then item
                is tagged as walker item.
              </li>
              <li>
                Maximum Buffer - No of Days Consumption – This field is defined
                maximum no of days consumption as Maximum buffer. Value must be
                an integer.
              </li>
              <li>
                Maximum Stock Value for Stock Point – This field indicate the
                maximum stock value for stock point. This means system will not
                allow to increase buffer beyond the value defined in this field.
              </li>
              <br />
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StockPointConfighelpPopup;
