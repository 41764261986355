import { React, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import modules from "../project.module.css";
import { useNavigate } from "react-router-dom";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import loading from "../../../../Assets/Loading_image.gif";
import { useLocation } from "react-router-dom";
import {
  Grid,
  SvgIcon,
  Stack,
  Checkbox,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { GetViewHistory } from "../../../../Services/CCPM";

const ViewHistory = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = parseInt(searchParams.get("projId"));
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    let body = {
      projectid: projectId,
    };
    GetViewHistory(body)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setTableData(response?.data?.success?.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Box sx={{ padding: "5px" }}>
        <Typography sx={{ fontSize: "16px", fontFamily: "times new roman" }}>
          Active Projects History
        </Typography>
        <Table className={modules["tableViewHisProj"]}>
          <TableHead>
            <TableRow style={{ fontWeight: "bold" }}>
              <TableCell align="center" sx={{ width: "17%" }}>
                Sr.No
              </TableCell>
              <TableCell align="center">Project Name</TableCell>
              <TableCell align="center">Action</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">ActionBy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, index) => (
              <TableRow
                key={index}
                sx={{ color: "Black", backgroundColor: "#DEDFDE" }}
              >
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{item.projectname}</TableCell>
                <TableCell align="center">{item.action}</TableCell>
                <TableCell align="center">{item.historydate}</TableCell>
                <TableCell align="center">{item.logincode}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default ViewHistory;
