import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import { TextField, Typography, Grid } from "@mui/material";
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import CP1 from "../HelpPopups/images/CP1.png";
import CP2 from "../HelpPopups/images/CP2.png";
import CP3 from "../HelpPopups/images/CP3.png";
import CP4 from "../HelpPopups/images/CP4.png";
import CP5 from "../HelpPopups/images/CP5.png";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const CompleteProjectshelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <h3 className="title">Complete Projects</h3>
            <br />
            <ul>
              <li className="no-bullets">
                This session is used for completing the projects. Here project
                manager can check the status of the project and if project tasks
                and completed then he can complete the project.
              </li>
              <br />
              <li className="no-bullets">
                <strong>
                  Session Path – CCPM {">>"} Active Projects {">>"} Complete
                  Projects
                </strong>
              </li>
              <br />
              <br />
              <li className="no-bullets">
                <div className="image-container">
                  <img src={CP1} alt="CP1" />
                </div>
              </li>
              <li className="no-bullets">
                Select Instance from drop down provided.
              </li>
              <br />
              <br />
              <li className="no-bullets">
                <div className="image-container">
                  <img src={CP2} alt="CP2" />
                </div>
              </li>
              User can see various filter here. User can apply filter and check
              the project. Only instance filter is the mandatory one other
              filter are optional.
              <br />
              <br />
              <li className="no-bullets">
                <div className="image-container">
                  <img src={CP3} alt="CP3" />
                </div>
              </li>
              Once user sear for the project the list of projects is appeared as
              per the filters provided. Main thing here is a redirect link for
              pending task is provided. Here project manager can check the
              pending tasks on critical chain. If there are no tasks are pending
              then project manager can complete the project.
              <br />
              <br />
              If tasks are pending to complete and still user tried to complete
              the project, then an error message is shown for completing the
              tasks.
              <br />
              <br />
              If task are completed then the following scree will be displayed.
              <br />
              <li className="no-bullets">
                <div className="image-container">
                  <img src={CP4} alt="CP4" />
                </div>
              </li>
              <br />
              Closure date – User can put any date from last task updated to
              current date. For any other date error message will be shown.
              <br />
              <br />
              Customer feedback – Here user can add any feedback / rating given
              by the customer to the service provider. User can select only one
              rating at a time. To cancel the selection user need to cancel
              total form and reopen complete project format.
              <br />
              <br />
              Approved Execution Cost – This is free entry field and
              non-mandatory field. User can put any numeric value in this field.
              No negative value is allowed also no text allowed.
              <br />
              <br />
              Actual Execution Cost – This is free entry field and non-mandatory
              field. User can put any numeric value in this field. No negative
              value is allowed also no text allowed.
              <br />
              <br />
              Project Value - This is free entry field and non-mandatory field.
              User can put any numeric value in this field. No negative value is
              allowed also no text allowed.
              <br />
              <br />
              Project Documents – User can add 3 attachment under this. File
              size will be max 1 Mb. And the desired format is
              xlsx/xls/doc/docx/pdf/ppt/pptx/txt files only.
              <br />
              <br />
              Project Learning Documents – User can add 1 attachment under this.
              File size will be max 1 Mb. And the desired format is
              xlsx/xls/doc/docx/pdf/ppt/pptx/txt files only.
              <br />
              <br />
              All above fields are not mandatory field and all values are taken
              to View projects page where user can see the actual values put by
              the user.
              <br />
              <br />
              Closure Remark – User can put project remarks in this field.
              <br />
              <br />
              Once all data is uploaded then user need to click save documents.
              A pop up message will be shown as below before saving the
              documents. Editing is not allowed once save button is clicked.
              User can reenter all values if he clicked cancel button.
              <br />
              <br />
              <div className="image-container-small">
                <img src={CP5} alt="CP5" />
              </div>
              <br />
              Once u click on OK then BM run takes place and projects is
              completed.
              <br />
              <br />
              <br />
              <br />
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CompleteProjectshelpPopup;
