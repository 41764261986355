import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
// import modules from "./Portfoliochart.module.css";
import modules from "./../ccpm.module.css";
import Box from "@mui/material/Box";
import { Tab, Tabs, TextField } from "@mui/material";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetBMSearchResult,
} from "../../../Services/CCPM.js";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";

import { styled } from "@mui/system";
import { enUS } from "date-fns/locale";
import { Stack, Popover, Paper } from "@mui/material";

// import { Scatter } from "react-chartjs-2";

const DummyData = [
  {
    id: 1,
    col2: "Aerospace",
    col3: "Aerospace",
  },
  {
    id: 2,
    col2: "Innovation and Design Center",
    col3: "IDC",
  },
  {
    id: 3,
    col2: "GB Corporate Projects",
    col3: "GB_CORP",
  },
  {
    id: 4,
    col2: "PSS Gogrej Security Solutions",
    col3: "SSD_PSS",
  },
  {
    id: 5,
    col2: "G&B Finance",
    col3: "G&B Finance",
  },
];

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const BMReport = () => {
  const [searchValue, setSearchValue] = useState("");
  // State for selected values
  const [selectedInstance, setSelectedInstance] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [resultTableData, setResultTableData] = useState([]);
  const [selectedAccessType, setSelectedAccessType] = useState("SELECT");

  const [selectedmaximumYAxis, setselectedmaximumYAxis] = useState("100");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");

  const [dropdownData, setDropdownData] = useState([
    "Select",
    "Instance 2",
    "Instance 3",
  ]);
  const [filteredData, setFilteredData] = useState(DummyData);
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [chartData, setChartData] = useState([]);

  const handleCalendarChangeFrom = (date) => {
    setFromDate(date);
    setAnchorElFrom(null);
  };
  const handleCalendarChangeTo = (date) => {
    setToDate(date);
    setAnchorElTo(null);
  };
  const formattedToday = new Date().toLocaleDateString();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [anchorElFrom, setAnchorElFrom] = useState(null);
  const [anchorElTo, setAnchorElTo] = useState(null);

  const HiddenCalendarInput = styled(TextField)({
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      display: "none",
    },
    "& .MuiInputBase-input": {
      height: "25px",
      padding: "0px 0px 0px 5px",
    },
  });

  const handleSelectChange = (attributenumber, id) => {
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [radioValue, setRadioValue] = useState("yes");

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const [instances, setInstances] = useState([]);
  const [zones, setZones] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [accessTypes, setAccessTypes] = useState([
    { value: "", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ]);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    setIsLoading(true);

    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
         setIsLoading(false);

          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    setIsLoading(true);

    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    GetPCBindProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
         setIsLoading(false);

          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    setIsLoading(true);

    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
         setIsLoading(false);

          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    setIsLoading(true);

    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
         setIsLoading(false);

          console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error);
      });
  };

  const getSearchResult = (
    
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      accessType: selectedProjectAccessType,
      fromDate: displayfromdate,
      toDate: displaytodate,
    };
    GetBMSearchResult(body)
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "search result");
          const responseData = response.data.success.data;
          if (responseData.length === 0) {
            setShowTable(false);
            alert("No data To Display");
          }

          setChartData(responseData);
          setShowTable(true);
        
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const [tabValue, setTabValue] = useState(0); // State to track the active tab

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearch = () => {
    setIsLoading(true);
    setShowResult(true);
    console.log(selectedAttributes, "selectedAttributes");

    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getSearchResult(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  const handleReset = () => {
    setSearchValue("");
    setFilteredData(DummyData);
  };

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarToOpen, setCalendarToOpen] = useState(false);
  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());
  const [displayfromdate, setdisplayfromdate] = useState("");
  const [displaytodate, setdisplaytodate] = useState("");

  const handleFromDateChange = (date) => {
    setFromdate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplayfromdate(`${day}/${month}/${year}`);
    setCalendarOpen(false);
  };

  const handleToDateChange = (date) => {
    setTodate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplaytodate(`${day}/${month}/${year}`);
    setCalendarToOpen(false);
  };

  const toggleCalendar = () => {
    setCalendarOpen((prevOpen) => !prevOpen);
  };

  const toggleToCalendar = () => {
    setCalendarToOpen((prevOpen) => !prevOpen);
  };

  const RowsPerPage = 10;
  const MaxVisiblePages = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentGroup, setCurrentGroup] = useState(1); // Track the current page group

  // Reset to the first page and group when chartData changes
  useEffect(() => {
    setCurrentPage(1);
    setCurrentGroup(1);
  }, [chartData]);

  // Calculate total pages and page group range
  const totalPages = Math.ceil(chartData.length / RowsPerPage);
  const totalGroups = Math.ceil(totalPages / MaxVisiblePages);

  // Calculate the current page's displayed data range
  const startIndex = (currentPage - 1) * RowsPerPage;
  const endIndex = startIndex + RowsPerPage;
  const displayedData = chartData.slice(startIndex, endIndex);

  // Navigate to next or previous group of pages
  const handleGroupChange = (direction) => {
    if (direction === "prev" && currentGroup > 1) {
      setCurrentGroup(currentGroup - 1);
      setCurrentPage((currentGroup - 2) * MaxVisiblePages + 1);
    } else if (direction === "next" && currentGroup < totalGroups) {
      setCurrentGroup(currentGroup + 1);
      setCurrentPage(currentGroup * MaxVisiblePages + 1);
    }
  };

  // Update the current page within the current group
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the page numbers for the current group
  const getPageNumbers = () => {
    const pageNumbers = [];
    const startPage = (currentGroup - 1) * MaxVisiblePages + 1;
    const endPage = Math.min(currentGroup * MaxVisiblePages, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  

  function formatDate(dateString) {
    // Convert the dateString (ISO format) to a Date object.
    const date = new Date(dateString);
  
    // Format the date using toLocaleString with specific options for formatting.
    return date.toLocaleString("en-GB", {
      year: "numeric",    // Use 4-digit year
      month: "2-digit",   // Use 2-digit month (e.g., "01" for January)
      day: "2-digit",     // Use 2-digit day of the month (e.g., "09")
      hour: "2-digit",    // Use 2-digit hour in 24-hour format (e.g., "08" or "18")
      minute: "2-digit",  // Use 2-digit minutes (e.g., "05")
      second: "2-digit",  // Use 2-digit seconds (e.g., "09")
    }).replace(",", "");  // Remove the comma between the date and time
  }
  
 

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"BMReport"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "21px" }}
            >
              <div className={modules["pro-bread"]}>
                <a>Reports</a>
                <img src={bread} alt="" />
                <a>Buffer Management Run Details</a>
              </div>
            </div>
            <div className={modules["border_cont"]}>
              <table className={modules["PCtable"]}>
                <tbody>
                  <tr style={{ paddingBottom: "0px" }}>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>
                        Instance:<label style={{ color: "Red" }}>*</label>
                      </span>

                      <select
                        value={selectedInstance}
                        onChange={(e) => {
                          setSelectedInstance(e.target.value);
                          getProjectDetails(e.target.value);
                        }}
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        {instances?.map((instance) => (
                          <option key={instance.name} value={instance.id}>
                            {instance.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    {hierarchyOptions
                      ?.filter((option) => option.isactive === 1)
                      .map((option, index) => (
                        <React.Fragment key={option.attributename}>
                          <td className={modules["table_data1"]}>
                            <span style={{ width: "110px" }}>
                              {option.attributename} :
                            </span>
                            <select
                              value={
                                selectedAttributes[
                                  `attr${option.attributenumber}`
                                ] || ""
                              }
                              onChange={(e) =>
                                handleSelectChange(
                                  option.attributenumber,
                                  e.target.value
                                )
                              }
                              className={modules["dropdownSelect"]}
                            >
                              <option value="">SELECT</option>
                              {filteredValueData[index]?.map((item) => (
                                <option
                                  key={item.attributevalue}
                                  value={item.id}
                                >
                                  {item.attributevalue}
                                </option>
                              ))}
                            </select>
                          </td>
                        </React.Fragment>
                      ))}
                  </tr>
                  <tr
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>Project Name:</span>
                      <select
                        value={selectedZone}
                        onChange={(e) => setSelectedProjectName(e.target.value)}
                        className={modules["dropdownSelect"]}
                      >
                        <option>SELECT</option>
                        {projects?.map((p_name) => (
                          <option key={p_name.projectname} value={p_name.id}>
                            {p_name.projectname}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>Project Manager:</span>
                      <select
                        value={selectedZone}
                        onChange={(e) =>
                          setSelectedProjectManager(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                      >
                        <option>SELECT</option>
                        {managers?.map((m_name) => (
                          <option key={m_name.username} value={m_name.id}>
                            {m_name.username}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>
                        Project AccessType:
                      </span>
                      <select
                        value={selectedZone}
                        onChange={(e) =>
                          setSelectedProjectAccessType(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                      >
                        {accessTypes.map((access) => (
                          <option key={access.label} value={access.value}>
                            {access.label}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr style={{ paddingBottom: "10px" }}>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px", marginRight: "0.5rem" }}>
                        From Date:
                      </span>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <input
                          className={modules["datepicker"]}
                          type="text"
                          readOnly
                          value={displayfromdate}
                          placeholder="dd/MM/yyyy"
                          onClick={toggleCalendar}
                        />
                        {calendarOpen && (
                          <div
                            className={modules["fromcalendar"]}
                            style={{ position: "absolute", zIndex: 2 }}
                          >
                            <Calendar
                              onChange={handleFromDateChange}
                              value={fromdate}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px", marginRight: "0.5rem" }}>
                        To Date:
                      </span>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <input
                          className={modules["datepicker"]}
                          type="text"
                          readOnly
                          value={displaytodate}
                          placeholder="dd/MM/yyyy"
                          onClick={toggleToCalendar}
                        />
                        {calendarToOpen && (
                          <div
                            className={modules["fromcalendar"]}
                            style={{ position: "absolute", zIndex: 2 }}
                          >
                            <Calendar
                              onChange={handleToDateChange}
                              value={todate}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr
                    style={{
                      justifyContent: "center",
                      gap: "10px",
                      paddingLeft: "0px",
                      paddingBottom: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      className={modules["PCbtn"]}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["PCbtn"]}
                      type="button"
                      onClick={() => {
                        if (instances && instances.length > 0) {
                          const firstInstance = instances[0].id; // Use the first instance directly
                          setSelectedInstance(firstInstance);
                          getProjectDetails(firstInstance);
                        }
                        setSelectedZone("");
                        setSelectedProject("");
                        setSelectedManager("");
                        setSelectedAccessType("SELECT");
                        setSelectedProjectName(0);
                        setSelectedProjectManager(0);
                        setSelectedProjectAccessType("");
                        setFromDate("");
                        setToDate("");
                        setDisplayValue(dropdownData[0]);
                        setSelectedAttributes({});
                        setChartData([]);
                        setShowTable(false);

                        
                      }}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>

            {showTable && (
               displayedData.length > 0 ? (
              <>
          
                <Grid container className={modules["instanceAttMap_table-grid"]}  style={{ marginLeft:'6.5rem',marginTop:'1.5rem',marginBottom:'1.5rem'}}>
                <div className={modules["ccpm_table-containerIAMBM"]}>
                    <Table  className={modules["custom-table"]}>
                      <TableHead className="table_head">
                        <TableRow >
                          <TableCell  className="fixed-width-cell" sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '70px' }}>
                            Sr No
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px' ,width: '100px',justifyContent:'flex-start',}}>
                          Projects Id
                          </TableCell>
                          <TableCell  sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '150px'}}>
                          Project Name
                          </TableCell>
                          <TableCell  sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '100px' }}>
                          BM Taken By
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '100px' }}>
                          BM Start Date
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px' ,width: '100px'}}>
                          BM End Date
                          </TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                      {displayedData.map((row,index) => (
                          <TableRow key={row.id} className={modules["table-row"]}>
                            <TableCell className={modules["table-cell"]}>{startIndex + index + 1}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.projectsid}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.projectname}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.logincode}</TableCell>
                            <TableCell className={modules["table-cell"]}>{formatDate(row.bmstartdate)}</TableCell>
                            <TableCell className={modules["table-cell"]}>{formatDate(row.bmenddate)}</TableCell>
                          </TableRow>
                        ))}


{totalPages > 1 && (
  <TableRow>
    <TableCell colSpan={16}>
      <div className={modules["pagination"]}>
        {/* Group-based "Prev" button */}
        <span
          className={currentGroup === 1 ? modules["disabled"] : modules["page-number"]}
          onClick={() => handleGroupChange("prev")}
        >
          Prev
        </span>

        {/* Display page numbers for the current group */}
        {getPageNumbers().map((pageNumber, index) => (
          <span
            key={index}
            className={
              pageNumber === currentPage
                ? modules["active-page"]
                : modules["page-number"]
            }
            onClick={() =>
              typeof pageNumber === "number" ? handlePageChange(pageNumber) : null
            }
          >
            {pageNumber}
          </span>
        ))}

        {/* Group-based "Next" button */}
        <span
          className={currentGroup === totalGroups ? modules["disabled"] : modules["page-number"]}
          onClick={() => handleGroupChange("next")}
        >
          Next
        </span>
      </div>
    </TableCell>
  </TableRow>
)}
                      </TableBody>
                    </Table>
                    
                    <br></br>
                    
                  </div>
                </Grid>
              </>
               ) : (
                <TableRow
                  className={modules["table-cell"]}
                  sx={{
                    textAlign: "left !important",
                    display:"flex",
                    borderBottom: "1px solid #a4bf47",
                    marginLeft: "6rem",
                    marginTop: "1.2rem",
                    fontSize: "12px",
                    color: "#414141",
                    padding: "8px",
                    width: "110px",
                  }}
                >
                  No Record Found.
                </TableRow>
              )

            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BMReport;
