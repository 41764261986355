import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Grid, FormControl, Select, MenuItem, InputLabel } from "@mui/material";

import { Link } from "react-router-dom";
// import modules from "./Calender.module.css";
import modules from "../../ccpm.module.css";
import bread from "../../../../Assets/bread.png";
import loading from "../../../../Assets/Loading_image.gif";
import {
  C_getAllCalenderNames,
  C_getCalenderReports,
} from "../../../../Services/CCPM";

const CalenderHistoryLog = () => {
  //loading gif
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("SELECT");
  const [allCalenderNames, setAllCalenderNames] = useState([]);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const getAllCalenderNames = () => {
      setIsLoading(true);
      C_getAllCalenderNames()
        .then((response) => {
          console.log(response);
          if (response?.data?.statusCode === 200) {
            setAllCalenderNames(response?.data?.success?.data);
          } else {
            //   alert(response?.error?.message);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    getAllCalenderNames();
  }, []);

  const getReports = () => {
    setIsLoading(true);
    const body = {
      calendarId: inputValue,
    };
    C_getCalenderReports(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data);
          setReports(response?.data?.success?.data);
        } else {
          // alert(response?.error?.message)
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const LogsGeneratedUI = () => {
    return (
      <>
        {reports.length > 0 && (
          <table
            style={{
              alignItems: "left",
              marginLeft: "3.5rem",
              width: "421px",
              borderCollapse: "collapse",
              fontFamily: "Times New Roman !important",
            }}
            className={modules["historyTable"]}
          >
            <thead style={{ textAlign: "left" }}>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "3px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Logs Generated
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "3px",
                    fontWeight: "bold",
                  }}
                >
                  User Name
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "3px",
                  }}
                >
                  <a
                    style={{
                      fontWeight: "bold",
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    Created On{" "}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "left" }}>
              {reports.map((itm, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid black", padding: "3px" }}>
                    {itm.logmessages}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px" }}>
                    {itm.username}
                  </td>
                  <td style={{ border: "1px solid black", padding: "3px" }}>
                    {(() => {
                      const createdOn = itm.createdon; // Assuming itm.createdon is your date string
                      const date = new Date(createdOn);

                      // Use local time methods to format the date
                      const day = String(date.getDate()).padStart(2, "0");
                      const month = String(date.getMonth() + 1).padStart(
                        2,
                        "0"
                      ); // Months are zero-based
                      const year = date.getFullYear();
                      const hours = String(date.getHours()).padStart(2, "0");
                      const minutes = String(date.getMinutes()).padStart(
                        2,
                        "0"
                      );
                      const seconds = String(date.getSeconds()).padStart(
                        2,
                        "0"
                      );

                      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
                    })()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </>
    );
  };
  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div
        className={modules["pro-bread"]}
        style={{ marginTop: "3rem", marginLeft: "1rem" }}
      >
        <a style={{ color: "blue", textDecoration: "underline", cursor:"pointer" }}>Calender</a>
        <img src={bread} alt="" />
        <a style={{ color: "blue", textDecoration: "underline", cursor:"pointer" }}>
          Calendar Log Report
        </a>
      </div>

      <Grid container style={{ margin: "3rem 0rem 1rem 3.5rem" }}>
        <Grid className={modules["history-label"]}>Calender Name :</Grid>
        <Grid item>
          <FormControl>
            <select
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              style={{ width: "15rem", marginLeft: "1rem" }}
            >
              <option value="SELECT" disabled>
                SELECT
              </option>
              {allCalenderNames.map((i) => (
                <option key={i.calendarname} value={i.calendarid}>
                  {i.calendarname}
                </option>
              ))}
            </select>
          </FormControl>
        </Grid>
        <button
          className={modules["history-label"]}
          onClick={getReports}
          style={{ marginLeft: "1rem", padding: "2px 4px 2px 4px" }}
        >
          Show Report
        </button>
        <button
          className={modules["history-label"]}
          onClick={() => {
            window.location.href = "/ccpm/Calendar";
          }}
          style={{ marginLeft: "1rem", padding: "2px 4px 2px 4px" }}
        >
          Back to Calendar
        </button>
      </Grid>

      {LogsGeneratedUI()}
    </>
  );
};

export default CalenderHistoryLog;
