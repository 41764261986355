import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import MNI1 from "../HelpPopups/images/MNI1.png";
import MNI2 from "../HelpPopups/images/MNI2.png";
import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const MTANewInstancehelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid className="cambria-font">
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{ fontSize: "14px" }}>
              <h2
                style={{
                  marginLeft: "5rem",
                  textAlign: "center",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontSize: "36px",
                }}
              >
                Instance creation in MTA
              </h2>
              <br />
              <br />
              <br />
              <li
                className="no-bullets"
                style={{
                  textDecoration: "underline",
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                Session Path {">>"} MTA {">>"} MTA Parameter {">>"} New Instance
              </li>
              <br />
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={MNI1} alt="MNI1" />
              </Stack>
              <br />
              <li className="no-bullets">
                Use above session to create new instance in MTA. If the instance
                already exists then system will populate message ‘Record Already
                Exists’ (refer below screenshot for more reference). Further
                instances are linked to the user (using User instance linking
                session) so that data visibility can be restricted.{" "}
              </li>
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={MNI2} alt="MNI2" />
              </Stack>

              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MTANewInstancehelpPopup;
