import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/CM1.jpg";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";
import RiskMonitorreport from "../CCPM/RiskManagement/RiskMonitorreport";
import RIMP1 from "../HelpPopups/images/RIMP1.jpg";
import RAMP3 from "../HelpPopups/images/RAMP3.jpg";
import RMR1 from "../HelpPopups/images/RMR1.jpg";
import RMR2 from "../HelpPopups/images/RMR2.jpg";
import RMR3 from "../HelpPopups/images/RMR3.jpg";
import RMR44 from "../HelpPopups/images/RMR44.jpg";
import RMR5 from "../HelpPopups/images/RMR5.jpg";
import RMR6 from "../HelpPopups/images/RMR6.jpg";
import RMR45 from "../HelpPopups/images/RMR45.jpg";
import HelpIndex from "./HelpIndex";



const RiskMonitorreporthelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
    return (
        <>
        <div>
            <Stack
              direction="row"
              spacing={4}
              sx={{ paddingTop: "1rem" }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack sx={{ paddingLeft: "1rem", fontFamily: "'Times New Roman', Times, serif" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline", fontWeight: "bold" }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>
    
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={prostream} alt="Prostream" />
              </Stack>
            </Stack>
            {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
          </div>
    
          <Grid>
            <Grid container className="parent-container">
              <Grid item xs={12} className="child-container">
                
                  <h3 style={{ textDecoration: "underline", fontFamily:'Cablibri,serif',fontSize:'26px'}}>Risk Monitor Report</h3>
                  <br></br>
                 <p style={{fontSize:'18px',fontFamily:"sans-serif"}}> <strong>Session Path {'>>'}CCPM {'>>'} Risk Management {'>>'}  Risk Monitor Report. </strong></p>
                 <br></br>
                  <ul>
                    <li>
                    For the risk to appear in the risk monitor report, Risk should be uploaded in the project after the network plan for that particular project has been created.
                    </li>
                    <li>
                    Risk should be loaded in the “Risk Identification & Mitigation plan” screen which is available in the click of button “Risk” in the “My Projects” screen.
                    </li>
                    
                  </ul>
                  <br></br>
                  <Stack style={{ width: '702px', height: '273px' }} sx={{ paddingRight: "1rem" }}>
                    <img src={RMR1} alt="RMR1" />
                  </Stack>
                    <br></br>
                    
                    <span >❖  following are the list of editable fields present in the screen</span>
                    <br></br>
                    <br></br>
                    
                   <ul>
                          <li><span style={{textDecoration:'underline'}}> “Risk Type”</span> :- Risk type is dropdown field with the list of risks defined in the masters “Risk Type”.
                            </li>
                            <br></br>
                            <li> <span style={{textDecoration:'underline'}}> Risk</span> :- Risk is a text box in which user can enter the description regarding the risk identified.
                            </li>
                            <br></br>
                            <li><span style={{textDecoration:'underline'}}>“Risk Category”</span> :- Risk Category is an dropdown field with predefined values, which the user can use to categorise the risk.
                            </li>
                            <br></br>
                            <li><span style={{textDecoration:'underline'}}> Probability</span> :- Probability is an dropdown field which ranks the probability of a risk which will occur during the course of project and its the numerical values ranging from 0-5(5 is the highest).
                            </li>
                            <br></br>
                            <li> <span style={{textDecoration:'underline'}}>  Impact</span>:- Impact is the dropdown field which ranks the imapct of the risk in the course of project and its the numerical value ranging from 0-5(5 is the highest)
                            </li>
                            <br></br>
                            <li><span style={{textDecoration:'underline'}}>Priority Ranking</span>:- Priority Ranking is the value of the product of “Probability” & “Impact”(Priority Ranking= Probability * Impact).
                            </li>
                            <br></br>
                            <li> <span style={{textDecoration:'underline'}}>Likely Date for risk to occur as per plan</span>:- This is the date field in which the date will be selected on which the risk can occur.
                            </li>
                            <br></br>
                            <li><span style={{textDecoration:'underline'}}> Mitigation Action to control risk</span>:- In this field description regarding how the risk can be mitigated or controlled is described by the user.
                            </li>
                            <br></br>
                            <li><span style={{textDecoration:'underline'}}> Action By Date</span>:- user should define the date till when the action to be taken against risk.</li>
                            <br></br>
                            <li><span style={{textDecoration:'underline'}}>Risk Category after resolution</span>:- Its a dropdown field with predefined values, where User should select the category of risk after it has been resolved.</li>
                            <br></br>
                            <li> <span style={{textDecoration:'underline'}}>  Trigger Status</span>:- Trigger status shows the every status of the risk from the step it has been opened till it close which will be updated by the user.</li>
                            <br></br>
                            <li> 
                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '101px', height: '21px', aspectRatio: 'auto 39/41' }}>
                                    <img src={RMR2} alt="RMR2" />
                                </Stack>
                            </span>
                            <span>
                                :-Edit button is used for the updating the details of the risk, and for updating the details click on “Update” button.
                            </span>
                            </li>
                            <br></br>
                            <li> 
                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '91px', height: '36px', aspectRatio: 'auto 39/41' }}>
                                    <img src={RMR3} alt="RMR3" />
                                </Stack>
                            </span>
                            <span>
                                :-On clicking this button, user can add new record to the “Risk Identification & Mitigation plan” screen.
                            </span>
                            </li>
                            <br></br>
                            <li> 
                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '91px', height: '36px', aspectRatio: 'auto 39/41' }}>
                                    <img src={RMR44} alt="RMR44" />
                                </Stack>
                            </span>
                            <span>
                                :-User should initially select the records and click on the “Delete” button to delete the record.
                            </span>
                            </li>
                            <br></br>
                            <li> 
                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <Stack style={{ width: '91px', height: '36px', aspectRatio: 'auto 39/41' }}>
                                    <img src={RMR5} alt="RMR5" />
                                </Stack>
                            </span>
                            <span>
                                :-on click of this button user will exit from the “Risk Identification & Mitigation plan” screen.
                            </span>
                            </li>
                            <br></br>
                            <li> The data provided in the “Risk Identification & Mitigation” screen for individual project is provided in the “Risk Monitor Report” screen.</li>
                            <Stack style={{ width: '702px', height: '173px' }} sx={{ paddingRight: "1rem" }}>
                                <img src={RMR6} alt ="RMR6" />
                            </Stack>

                            <br></br>
                            <li>The data provided here is non-editable and this data can be exported to excel, using the “Export to excel” button.</li>
                            <br></br>
                            <li>
                            Four filters namely Project manager, Project, Trigger Status, Risk Category for searching of the data in the grid.
                            </li>
                            <br></br>
                            <li>
                            For searching of data, we have to enter data in any one filter and click on “Submit” button.
                            </li>
                            <br></br>
                            <Stack style={{ width: '703px', height: '221.99px' }} sx={{ paddingRight: "1rem" }}>
                                <img src={RMR45} alt ="RMR45" />
                            </Stack>
                            <br></br>
                            </ul>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>

                 
                   
              </Grid>
            </Grid>
          </Grid>
        </>
      );
};

export default RiskMonitorreporthelpPopup;
