import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import bread from "../../../Assets/bread.png";
import { Set_MtaNewInstance } from "../../../Services/MTA";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";

export default function MTANewInstance() {
  const [isLoading, setIsLoading] = useState(false);
  const [mtaInstance, setMtaInstance] = useState("");

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
  }, []);

  function submitHandler() {
    const body = {
      miuid_instance_uid: mtaInstance.trim(),
      miuid_last_modified_by: sessionStorage.getItem("Username"),
    };
    setIsLoading(true);
    const specialCharPattern = /[^a-zA-Z0-9&().\-_ ]/;
    if (!mtaInstance) {
      alert("Enter MTA Instance");
      setMtaInstance("");
      setIsLoading(false);
    } else if (specialCharPattern.test(mtaInstance)) {
      alert("No special characters are allowed");
      setMtaInstance("");
      setIsLoading(false);
    } else {
      Set_MtaNewInstance(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            if (response?.data?.success?.data[0]?.["?column?"] === 1) {
              alert(
                response?.data?.success?.message ||
                  "Details Successfully Inserted"
              );
              setIsLoading(false);
              setMtaInstance("");
            } else if (response?.data?.success?.data[0]?.["?column?"] === 0) {
              alert(response?.data?.error?.message || "Record Already Exist");
              setIsLoading(false);
              setMtaInstance("");
            }
          } else {
            alert(response?.data?.error?.message || "Something went wrong");
            setIsLoading(false);
            setMtaInstance("");
          }
        })
        .catch((error) => {
          console.log(error);
          setMtaInstance("");
        });
    }
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block", overflowX: "hidden" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"MTANewInstance"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>New Instance</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table
                className={modules["customTable"]}
                style={{ marginLeft: "3rem" }}
              >
                <tbody>
                  <tr style={{ paddingLeft: "5rem" }}>
                    <td style={{ width: "7rem", fontWeight: "bold" }}>
                      MTA Instance :
                    </td>
                    <td>
                      <input
                        value={mtaInstance}
                        onChange={(e) => setMtaInstance(e.target.value)}
                        onInput={(e) => {
                          const regex = /^[a-zA-Z0-9&()\-_=\+\[\]\.\/ ]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9&()\-_=\+\[\]\.\/ ]/g,
                              ""
                            );
                          }
                          setMtaInstance(e.target.value);
                        }}
                        // onBlur={(e) => {
                        //   const regex2 = /^[a-zA-Z0-9_-]*$/;
                        //   if (!regex2.test(e.target.value)) {
                        //     alert(
                        //       "Only alphanumeric characters are valid in this field."
                        //     );
                        //     setMtaInstance(e.target.value);
                        //   }
                        // }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px", width: "250px" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Grid
                container
                className={modules["mid-cont"]}
                style={{ marginLeft: "12rem" }}
              >
                <button className={modules["btn1"]} onClick={submitHandler}>
                  Submit
                </button>
                <button
                  className={modules["btn1"]}
                  style={{ marginLeft: "20px" }}
                  // onClick={submitHandler}
                >
                  Close
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
