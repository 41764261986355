import axios from "axios";
import {
  API_KEY,
  MenuBar,
  ChangePassWord,
  GetAllRoles,
  GetDetails,
  InsertRole,
  UpdateRole,
  DeleteRole,
  DeleteRoles,
  GetDropDownRoles,
  GetHomePage,
  GetValuesForRoles,
  GetSubmenuList,
  GetSubmenuList1,
  GetSubmenuList2,
  GetParentSubList,
  GetPrivilageFunction,
  GetSaveApi,
  // UpdateRoleAndSession,
  ExportToExcel,
  PR_GetMenuList,
  PR_GetSubMenuList,
  PR_GetSessionPrivilage,
  PR_GetRoleList,
  PR_AddRole,
  APR_GetRoleNames,
  APR_GetRoleRoleandParentRoles,
  APR_SaveRole,
  IC_tableData,
  IC_SaveData,
  IC_DeleteData,
  IC_UpdateData,
  IC_BulkDelete,
  UM_tableData,
  UM_SaveData,
  UM_DeleteData,
  UM_UpdateData,
  GetCName,
  IM_RowSearch,
  IM_Update,
  IM_RowDelete,
  IM_Rowsave,
  InstanceAttribute,
  BufferSchedulerGrid,
  BufferRowDelete,
  InsertBuffer,
  InstanceList,
  AttributeValueTableData,
  NewAttributeValue,
  updateAttributeValue,
  PC_DDL,
  PC_tableData,
  PC_CAT_Update,
  PC_CAT_Delete,
  PC_CAT_Add,
  PC_subTableData,
  PC_Sub_Delete,
  PC_Sub_Update,
  PC_Sub_Add,
  PC_Sub_BulkDelete,
  PC_imgBtnSave,
  getInstanceListSetting,
  getInstanceBasedAttributeList,
  getInstanceParameter,
  getAttributeValueOnAttributeNumber,
  insertConfigSetting,
  insertApplyParameter,
  getHierarchyNumber,
  deleteInstance,
  US_Inactive,
  US_InstanceExp,
  US_GetLPlusOne,
  US_GetUserD,
  US_GVRowDeleting,
  US_GVRowUpdating,
  US_UsersBulkDelete,
  US_Modify,
  US_GVAddRow,
  US_BtnExportExcel,
  USM_GetRole,
  USM_SaveRole,
} from "../Constants";
import { Menu } from "@mui/material";

//Change Password

export async function ChangeNewPassword(body) {
  // ;
  console.log(API_KEY);
  console.log(ChangePassWord);
  return await axios
    .post(`${API_KEY}${ChangePassWord}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// MENU BAR THINGS

export async function GetMenuBar(body) {
  return await axios
    .post(`${API_KEY}${MenuBar}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//Role Master

export async function GetRolesList() {
  return await axios
    .post(`${API_KEY}${GetAllRoles}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}



export async function GetRolesDetails(body) {
  return await axios
    .post(`${API_KEY}${GetDetails}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function InseertNewRole(rolename, createdby) {
  const requestBody = {
    roleName: rolename,
    createdBy: parseInt(createdby),
  };

  return await axios
    .post(`${API_KEY}${InsertRole}`, requestBody)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ChangeRole(roleid, rolename, modifiedby) {
  const requestBody = {
    id: roleid,
    roleName: rolename,
    lastModifiedBy: parseInt(modifiedby),
  };

  return await axios
    .post(`${API_KEY}${UpdateRole}`, requestBody)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DeleteSingleRole(roleid, modifiedby) {
  const requestBody = {
    id: roleid,
    lastModifiedBy: parseInt(modifiedby),
  };
  return await axios
    .post(`${API_KEY}${DeleteRole}`, requestBody)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function BulkDeleteRole(body) {
  try {
    const response = await axios.post(`${API_KEY}${DeleteRoles}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

//Role Permission

export async function RP_GetDropDownData() {
  return await axios
    .post(`${API_KEY}${GetDropDownRoles}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function RP_GetHomePageData() {
  return await axios
    .post(`${API_KEY}${GetHomePage}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RP_GetRoleDropdown(val) {
  return await axios
    .post(`${API_KEY}${GetValuesForRoles}`, val)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RP_GetSubList(body) {
  return await axios
    .post(`${API_KEY}${GetSubmenuList1}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RP_GetParentSubList(body) {
  return await axios
    .post(`${API_KEY}${GetSubmenuList1}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function RP_GetSubList2(body) {
  return await axios
    .post(`${API_KEY}${GetSubmenuList2}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function RP_GetfunctionPrivilages(roleId, menuId) {
  return await axios
    .post(`${API_KEY}${GetPrivilageFunction}?RoleId=${roleId}&MenuId=${menuId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RP_UpdateHandler(body) {
  return await axios
    .post(`${API_KEY}${GetSaveApi}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// export async function RP_UpdateHandler(
//   roleId,
//   menuRights,
//   sessionPri,
//   userId,
//   homePage
// ) {
//   return await axios
//     .post(
//       `${API_KEY}${UpdateRoleAndSession}?RoleId=${roleId}&menuWithRights=${menuRights}&SessPriFunIdWithStatus=${sessionPri}&UserId=${userId}&HomePage=${homePage}`
//     )
//     .then((res) => {
//       return res;
//     })
//     .catch((err) => {
//       return err;
//     });
// }

export async function RP_ExportExcel(body) {
  try {
    const response = await axios.post(`${API_KEY}${ExportToExcel}`, body, {
      responseType: 'arraybuffer'
    });
    return response;
  } catch (error) {
    console.error('Error in RP_ExportExcel:', error);
    throw error;
  }
}

//parent role permission

export async function PR_GetMenuListHandler() {
  return await axios
    .post(`${API_KEY}${PR_GetMenuList}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PR_GetSubMenuListHandler(body) {
  return await axios
    .post(`${API_KEY}${PR_GetSubMenuList}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("Error in API call:", err);
      return err;
    });
}

export const saveDataApi = async (menuId) => {
  try {
    const url = `${API_KEY}${PR_GetSubMenuList}`;
    const response = await axios.post(url, { menuId });
    return response;
  } catch (error) {
    console.error("Error saving data:", error);
    throw error;
  }
};

export async function PR_GetSessionPrivilageHandler(body) {
  return await axios
    .post(`${API_KEY}${PR_GetSessionPrivilage}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PR_GetRoleListHandler() {
  return await axios
    .post(`${API_KEY}${PR_GetRoleList}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PR_InsertRoleHandler(body) {
  return await axios
    .post(`${API_KEY}${PR_AddRole}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//Assign Parent Role
export async function APR_GetRoleName() {
  return await axios
    .post(`${API_KEY}${APR_GetRoleNames}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function APR_GetRolesForParent() {
  return await axios
    .post(`${API_KEY}${APR_GetRoleRoleandParentRoles}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function APR_SaveRoles(roleid, listrole, userid) {
  const payload = [
    {
      roleid: roleid,
      parentroleids: listrole,
      userid: userid,
    },
  ];

  try {
    const response = await axios.post(`${API_KEY}${APR_SaveRole}`, payload);
    return response;
  } catch (err) {
    return err;
  }
}

// CCPM -> Master -> Instance Master

export async function getCompanyName() {
  return await axios
    .post(`${API_KEY}${GetCName}`)
    .then((res) => {
      return res?.data?.success?.data;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetTableDetails(body) {
  return await axios
    .post(`${API_KEY}${IM_RowSearch}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function UpdateDetail(body) {
  return await axios
    .post(`${API_KEY}${IM_Update}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DeleteInstance(body) {
  return await axios
    .post(`${API_KEY}${IM_RowDelete}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function InseertNewInstance(body) {
  return await axios
    .post(`${API_KEY}${IM_Rowsave}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// CCPM -> Master -> Unit Master

export async function UM_tableRowDetails() {
  return await axios
    .post(`${API_KEY}${UM_tableData}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export async function UM_saveRow(body) {
  return await axios
    .post(`${API_KEY}${UM_SaveData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function UM_deleteRow(body) {
  return await axios
    .post(`${API_KEY}${UM_DeleteData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function UM_UpdateRow(body) {
  return await axios
    .post(`${API_KEY}${UM_UpdateData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// CCPM -> Master -> Issue Category

export async function IC_tableRowDetails() {
  return await axios
    .post(`${API_KEY}${IC_tableData}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export async function IC_saveRow(body) {
  return await axios
    .post(`${API_KEY}${IC_SaveData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IC_deleteRow(body) {
  return await axios
    .post(`${API_KEY}${IC_DeleteData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IC_UpdateRow(body) {
  return await axios
    .post(`${API_KEY}${IC_UpdateData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IC_BulkDeleteRole(body) {
  try {
    const response = await axios.post(`${API_KEY}${IC_BulkDelete}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getInstanceAttribute(body) {
  try {
    const response = await axios.post(`${API_KEY}${InstanceAttribute}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getBufferSchedulersData() {
  try {
    const response = await axios.post(`${API_KEY}${BufferSchedulerGrid}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function removeBufferRowData(body) {
  try {
    const response = await axios.post(`${API_KEY}${BufferRowDelete}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function insertBuffer(body) {
  try {
    const response = await axios.post(`${API_KEY}${InsertBuffer}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getInstanceList(body) {
  try {
    const response = await axios.post(`${API_KEY}${InstanceList}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getAttributeValueList(body) {
  try {
    const response = await axios.post(
      `${API_KEY}${AttributeValueTableData}`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function createNewAttributeValue(body) {
  try {
    const response = await axios.post(`${API_KEY}${NewAttributeValue}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateAttributeValueData(body) {
  try {
    const response = await axios.post(
      `${API_KEY}${updateAttributeValue}`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function DDL_fetch(body) {
  return await axios
    .post(`${API_KEY}${PC_DDL}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Cat_tableData(body) {
  return await axios
    .post(`${API_KEY}${PC_tableData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Cat_Main_Update(instanceID, CatName, CatID, userId) {
  return await axios
    .post(
      `${API_KEY}${PC_CAT_Update}${instanceID}&CategoryNm=${CatName}&Id=${CatID}&CategoryName=${CatName}&LevelId=${instanceID}&LastModifiedBy=${userId}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Cat_Main_Delete(body) {
  return await axios
    .post(`${API_KEY}${PC_CAT_Delete}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Cat_Main_Add(instanceID, CatName, userId) {
  return await axios
    .post(
      `${API_KEY}${PC_CAT_Add}${instanceID}&CategoryNm=${CatName}&CategoryName=${CatName}&LevelId=${instanceID}&CreatedBy=${userId}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SubCat_tableData(body) {
  return await axios
    .post(`${API_KEY}${PC_subTableData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SubCat_delete(body) {
  return await axios
    .post(`${API_KEY}${PC_Sub_Delete}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SubCat_update(catId, catSubName, subCatID, userId) {
  return await axios
    .post(
      `${API_KEY}${PC_Sub_Update}${catId}&SubCategoryName=${catSubName}&ID=${subCatID}&CreatedBy=${userId}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SubCat_Add(catId, catSubName, subCatID, userId) {
  return await axios
    .post(
      `${API_KEY}${PC_Sub_Add}${catId}&SubCategoryName=${catSubName}&ID=${subCatID}&CreatedBy=${userId}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SubCat_BulkDelete(body) {
  return await axios
    .post(`${API_KEY}${PC_Sub_BulkDelete}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SubCat_ImgSave(body) {
  return await axios
    .post(`${API_KEY}${PC_imgBtnSave}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function getInstanceData(body) {
  try {
    const response = await axios.post(
      `${API_KEY}${getInstanceListSetting}`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getInstanceBasedAttListDate(body) {
  try {
    const response = await axios.post(
      `${API_KEY}${getInstanceBasedAttributeList}`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getInstanceParameterData(body) {
  try {
    const response = await axios.post(
      `${API_KEY}${getInstanceParameter}`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getAttributeValueOnAttributeNumberData(body) {
  try {
    const response = await axios.post(
      `${API_KEY}${getAttributeValueOnAttributeNumber}`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function insertNewConfigSetting(body) {
  try {
    const response = await axios.post(`${API_KEY}${insertConfigSetting}`, body);
    return response;
  } catch (error) {
    return error;
  }
}
export async function insertNewApplyParameter(body) {
  try {
    const response = await axios.post(
      `${API_KEY}${insertApplyParameter}`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getHierarchyId(body) {
  try {
    const response = await axios.post(`${API_KEY}${getHierarchyNumber}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteInstanceParameter(body) {
  try {
    const response = await axios.post(`${API_KEY}${deleteInstance}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function ExportExcelInactive(body) {
  try {
    const response = await axios.post(`${API_KEY}${US_Inactive}`, body, {
      responseType: 'arraybuffer'
    });
    return response;
  } catch (error) { 
    console.error('Error in RP_ExportExcel:', error);
    throw error;
  }
}

export async function ExportExcelInstanceExp() {
  try {
    const response = await axios.post(`${API_KEY}${US_InstanceExp}`, null, {
      responseType: 'arraybuffer'
    });
    return response;
  } catch (error) { 
    console.error('Error in RP_ExportExcel:', error);
    throw error;
  }
}

export async function US_GetDropdown(body) {
  try {
    const response = await axios.post(`${API_KEY}${US_GetLPlusOne}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function US_TableDetails(body) {
  try {
    const response = await axios.post(`${API_KEY}${US_GetUserD}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function US_RowDelete(body) {
  try {
    const response = await axios.post(`${API_KEY}${US_GVRowDeleting}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function US_RowUpdate(body) {
  try {
    const response = await axios.post(`${API_KEY}${US_GVRowUpdating}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function US_BulkDelete(body) {
  try {
    const response = await axios.post(`${API_KEY}${US_UsersBulkDelete}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function US_L1ModifyReplace(body) {
  try {
    const response = await axios.post(`${API_KEY}${US_Modify}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function US_AddRow(body) {
  try {
    const response = await axios.post(`${API_KEY}${US_GVAddRow}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function US_ExportToExcel(body) {
  try {
    const response = await axios.post(`${API_KEY}${US_BtnExportExcel}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function USM_GetTableData(body) {
  try {
    const response = await axios.post(`${API_KEY}${USM_GetRole}`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function US_SaveTable(body) {
  try {
    const response = await axios.post(`${API_KEY}${USM_SaveRole}`, body);
    return response;
  } catch (error) {
    return error;
  }
}
