import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import changepm1 from './images/changepm1.png'
import changepm2 from './images/changepm2.png'
import changepm3 from './images/changepm3.png'
import changepm4 from './images/changepm4.png'
import view6 from './images/view6.png'
import view7 from './images/view7.png'
import { Link } from "react-router-dom";

const ChangeProjectManagerhelpPopup = () => {
    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            Expand it...
                            <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>
            </div>

            <Grid sx={{fontFamily: '"Calibri", sans-serif', fontSize:'14.6px', color:'#000000'}}>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul>
                            <h3 style={{ fontWeight: "bold" }}>CHANGE PROJECT MANAGER</h3>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                                This facility is given for changing the project manager for the current project which are in active stage, if by any chance business want to change project manager he can do the same with this option. If current project manager is left the organization, If business allocate current manage with some other project, Any transfer to other location due to company priority this option can be used.
                            </li>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                                Session Path – CCPM &gt;&gt; Project Plan Change Project Manager
                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={changepm1} alt="myproject1" />
                            </Stack>
                            <br />
                            <ul style={{ listStyleType: 'disc', marginLeft: '5rem' }}>
                                <br />
                                <li>
                                    Instance – Select instance from the dropdown provided.
                                </li>
                                <li>
                                    Project Name – Select the required project from the dropdown provided.
                                </li>
                                <li>
                                    Project Manager – Select the current project manager.
                                </li>
                                <li>
                                    Search – Press on search button for finding out further details.
                                </li>
                                <li>
                                    Reset – To reset all information.
                                </li>
                            </ul>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={changepm2} alt="myproject2" />
                            </Stack>
                            <div>Once you search information the further fields will appear.</div>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={changepm3} alt="myproject2" />
                            </Stack>
                            <div>
                                Select the record line which you want to update. Once you verify the details the add new project manager you want to replace with the existing manager.  Then the project manger will be changed for all projects  where  project is  the selected one.
                            </div>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={changepm4} alt="myproject2" />
                            </Stack>
                            <br />
                            <div>You can see that project  manager is changed.</div>
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ChangeProjectManagerhelpPopup;
