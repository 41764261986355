import React from "react";
import { Grade } from "@mui/icons-material";
import Home from "../../../home/Home";
import { Grid, colors, Stack, TextField } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Sidebar from "../../../Landing/Navbar";
import bread from "../../../Assets/bread.png";
import calender from "../../../Assets/calendar.png";
import Calendar from 'react-calendar';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CalculateThroughputSubmitClick } from "../../../Services/Dss";
import loading from "../../../Assets/loadingGreen.gif";
import loadingdots from "../../../Assets/LoadingDotsWhiteBlack.gif";

import { DS_GetAllInstanceHandler} from "../../../Services/Dss";
import {
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import modules from "./CalculateThroughput.module.css";

import DatePicker from "react-datepicker";
import 'react-calendar/dist/Calendar.css';
import SampleSidebar from "../../../Landing/SampleSidebar";

export default function CalculateThroughput() {

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarToOpen, setCalendarToOpen] = useState(false);

  const [fromdate,setFromdate] =useState(new Date());
  const [todate,setTodate] =useState(new Date());

  
  const [baanco,setBaanco]=useState('')
  const [displayfromdate,setdisplayfromdate]=useState('')
  const [displaytodate,setdisplaytodate]=useState('')

  const [isLoading, setIsLoading] = useState(false);

  const [dropOne, setDropOne] = useState({
    value: 'select',
    dropDown: []
})


const handleFromDateChange = date => {
  console.log(date.toString())
  setFromdate(date)
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  console.log(`${year}/${month}/${day}`)
  setdisplayfromdate(`${year}/${month}/${day}`)
  setCalendarOpen(false)
};

const handleToDateChange = date => {
  console.log(date.toString())
  setTodate(date)
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  console.log(`${year}/${month}/${day}`)
  setdisplaytodate(`${year}/${month}/${day}`)
  setCalendarToOpen(false)
};

const toggleCalendar = () => {
  setCalendarOpen((prevOpen) => !prevOpen);
};

const toggleToCalendar = () => {
  setCalendarToOpen((prevOpen) => !prevOpen);
};


useEffect(() => {
  DS_GetAllInstanceHandler('pritpwc').then(res => {
    if(res.status === 200){

      setDropOne({
        dropDown: res.data,
        value: res.data[0].instanceuid
    })
    }
      
     
    }).catch(err => {
      console.log('Error while fetching data:', err)
  })
}, [])

function InstanceChangeHandler(value) {
  setDropOne({
      ...dropOne,
      value: value
  });
}


function SubmitHandler(){
  !dropOne.value ? alert('Select Instance uid') :
  !baanco ? alert('Enter BaaN Company') :
  !displayfromdate ? alert('Select Bill From Date') :
  !displaytodate ? alert('Select Bill To Date') :
  (() => {
    setIsLoading(true)
  

    CalculateThroughputSubmitClick(dropOne.value,'pritpwc',baanco,displayfromdate,displaytodate).then(res => {
       
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          console.log(res)
          alert(res?.data?.success?.message)
          setIsLoading(false)
            
        }
        if (res?.status === 200 && res?.data?.statusCode === 400) {
          console.log(res)
          alert(res?.data?.error?.message)
          setIsLoading(false)
            
        }
    }).catch(err => {
        console.log('Error while fetching data:', err);
        setIsLoading(false)
    })
})()




}

const handleBaanCoChange = (e) => {
  const value = e.target.value;
  const pattern = /^[a-zA-Z0-9]*$/; // Regex pattern to allow only letters and numbers
  if (pattern.test(value) || value === '') {
    setBaanco(value);
  }
};

  function ArrowLine() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "2px",
            height: "30px",
            backgroundColor: "grey",
            marginRight: "2px",
          }}
        />
        <ArrowDropDownIcon sx={{ marginRight: "15px", color: "#9d9d9c" }} />
      </div>
    );
  }

  return (

    <>
      <style>{
        `.react-calendar{
        width:15rem !important,
        height:5rem !important
      }`}</style>
      <Grid>
        <Grid
          item
          lg={0.6}
          sx={{ position: "fixed", zIndex: 2000 }}
          className="main-cont"
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className="home-cont">
            <Home />
          </Grid>
        </Grid>
        <Grid item lg={9} className={modules["maintainfactor_div"]}>
          <div className={modules["pro-bread"]} >
            <p>
              <a>DSS Throughput</a>
              <img src={bread} alt="" />
              <a>Calculate Throughput</a>
            </p>
          </div>
        </Grid>

        <Grid sx={{ marginLeft: "6rem", marginTop: "2rem" }}>
          <table className={modules["customTable"]}>
            <tbody>
              <tr>
                <td>DSS Instance</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={dropOne.value}
                      onChange={(e) => InstanceChangeHandler(e.target.value)}
                    >
                      {dropOne?.dropDown?.map((i) => {
                        return (
                          <MenuItem value={i.instanceuid} key={i.instanceuid}>
                            {i.instanceuid}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </td>
              </tr>

              <tr>
                <td>BaaN Company</td>
                <td> : </td>
                <td>
                  <input type="text"
                  value={baanco}
                    // onChange={(e) => {
                    //  setBaanco(e.target.value)
                    // }}
                    onChange={handleBaanCoChange}
                     />
                </td>
              </tr>

             

              <tr>
                <td>Bill From Date</td>
                <td> : </td>
                <td>
                  <input
                    className={modules["datepicker"]}
                    type="text"
                    readOnly
                    placeholder="YYYY/MM/DD"
                    value={displayfromdate}
                    onChange={(e) => setdisplayfromdate(e.target.value)}

                  />
                  <img
                    className={modules["calender"]}
                    src={calender}
                    alt="calendar missing"
                    onClick={toggleCalendar}
                  />
                  {
                    calendarOpen && (
                      <div style={{ position: 'absolute', zIndex: '999 !important', marginLeft: '5rem', height: '5rem !important', width: '20rem' }} >
                        <Calendar
                          value={fromdate}
                          onChange={handleFromDateChange}
                          className={modules['customPosition']}
                        />
                      </div>
                    )
                  }
                </td>
                <td>
                {isLoading && (
            <div className={modules["loading-clipper"]}>
          
              <p style={{marginLeft:'5rem'}}>
                <img src={loading} alt="picture missing" />
                Please wait
                <img src={loadingdots} alt="dots missing" />
              </p>
            </div>
          )}
                </td>
               
              </tr>

              <tr>
                <td>Bill To Date</td>
                <td> : </td>
                <td>
                  <input
                    className={modules["datepicker"]}
                    placeholder="YYYY/MM/DD"
                    type="text"
                      value={displaytodate}
                      onChange={(e) => setdisplaytodate(e.target.value)}
                      readOnly
                  />
                  <img
                    className={modules["calender"]}
                    src={calender}
                    alt="calendar missing"
                    onClick={toggleToCalendar}                   />
                  {
                    calendarToOpen && (
                      <div style={{ position: 'absolute', zIndex: '999 !important', marginLeft: '5rem', height: '5rem !important', width: '20rem' }} >
                        <Calendar
                          onChange={handleToDateChange}
                          value={todate}
                          className={modules['customPosition']}

                        />
                      </div>
                    )
                  }
                </td>
                
              </tr>

              
              
            </tbody>
          </table>
        </Grid>

        <div style={{ marginTop: "1.2rem", marginBottom: "2rem" }}>
          <Stack direction="row" spacing={3}>
          
                <>
                  <Stack>
                    <button
                      style={{
                        marginLeft: "23.7rem",
                        padding: "0.5cm",
                      }}
                      type="button"
                      className={modules["btn1"]}
                      onClick={() => SubmitHandler()}
                    >
                      Submit
                    </button>{" "}
                  </Stack>         
                </>
          </Stack>
        </div>
      </Grid>
    </>
  );
}
