import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";

import bread from "../../../Assets/bread.png";
import loading from "../../../Assets/Loading_image.gif";
import SampleSidebar from "../../../Landing/SampleSidebar";
import {
  Get_MtaInstances,
  CheckIsUser,
  UserStockPointAccess,
  getUserStockPointSelectionData,
  userStockPointLinkingData,
} from "../../../Services/MTA";

export default function UserStockPointLinking() {
  const [data, setData] = useState({
    instname: "",
    instdesc: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [tableShow, setTableShow] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [selectedInstanceId, setSelectedInstanceId] = useState("");
  const [selectedInstance, setSelectedInstance] = useState("");
  const [isAccesstoSelectedStockpoint, setIsAccessToSelectedStockpoint] =
    useState(false);
  const [isAccessToAllPoints, setIsAccessToAllPoints] = useState(false);
  const [showRadioBtn, setShowRadioBtn] = useState(false);
  const [tabledata, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // State to track selected rows
  const [selectAll, setSelectAll] = useState(false); // State for 'select all' checkbox

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getInstances();
  }, []);

  const SearchHandler = () => {
    checkUserAvailablity();
  };

  // const CheckIsUserAvailable = (e) => {
  //   const regex = /^[a-zA-Z0-9 &()_\-+=\[\]\/.]*$/;
  //   if (regex.test(e.target.value) || e.target.value === "") {
  //     setUserId(e.target.value);
  //   }
  // };

  const checkUserAvailablity = async () => {
    if (userId) {
      try {
        const obj = {
          userID: userId.toLowerCase(),
        };
        let CheckisUserResponse = await CheckIsUser(obj);
        if (CheckisUserResponse.status === 200) {
          console.log("Response data:", CheckisUserResponse.data);

          if (CheckisUserResponse.data?.success?.data === 1) {
            let body = {
              userid: userId.toLowerCase(),
              instance_uid: selectedInstanceId,
            };
            let apires = await UserStockPointAccess(body);
            console.log(apires?.data?.statusCode, "fef");

            if (apires?.data?.statusCode === 200) {
              setShowRadioBtn(true);
              console.log(
                apires?.data?.success?.data[0]?.stockpointcodeaccess,
                "aa"
              );

              if (apires?.data?.success?.data[0]?.stockpointcodeaccess === 2) {
                setIsAccessToSelectedStockpoint(true);
                setTableShow(true);
                setIsAccessToAllPoints(false);
                handleAccessStockType();
              } else {
                setIsAccessToAllPoints(true);
                setIsAccessToSelectedStockpoint(false);
              }
            } else {
              setIsAccessToAllPoints(false);
              setIsAccessToSelectedStockpoint(false);
              setShowRadioBtn(true);
              // setShowRadioBtn(false);
              // console.log(
              //   "Failed to check Access to user Selected stock point"
              // );
            }
          } else {
            alert("User does not exists");
          }
        } else {
          alert("User does not exists");
        }
      } catch (error) {
        console.error("Error checking user availability:", error);
        alert("Error occurred while checking user.");
      }
    } else {
      alert("User ID cannot be blank");
    }
  };

  const getInstances = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      miuid_instance_uid: "",
      miuid_last_modified_by: sessionStorage
        .getItem("loginUsername")
        .toLowerCase(),
    };
    if (loginId) {
      Get_MtaInstances(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setDropdownData(response?.data?.success?.data);
            setIsLoading(false);

            if (response?.data?.success?.data.length > 0) {
              const defaultInstanceId =
                response?.data?.success?.data[0]?.mta_instance_uid;
              setSelectedInstanceId(defaultInstanceId);
            } else if (response?.data?.success?.data.length === 0) {
              alert("You do not have access to any Active Instance in MTA");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const handleAccessStockType = async () => {
    let obj = {
      useriD: userId.toLowerCase(),
      instance_uid: selectedInstanceId,
    };
    let result = await getUserStockPointSelectionData(obj);
    // console.log("result?.data?.success?.data", result?.data?.success?.data);
    setTableData(result?.data?.success?.data);
    setSelectedRows(
      result?.data?.success?.data?.map(
        (item) => item?.isuserstockpointlinked === 1
      )
    );
  };

  const handleUpdateStockPoint = async (obj) => {
    try {
      let result = await userStockPointLinkingData(obj);
      console.log("Submit result", result);

      if (result?.data?.statusCode === 200) {
        handleAccessStockType();
        // alert(`Stock point linked successfully to User Id : ${userId}`);
        window.location.reload();
      } else {
        alert("Failed to link stock points.");
      }
    } catch (error) {
      console.error("Error during stock point linking:", error);
      alert("Error occurred during submission.");
    }
  };

  const submitHandler = async () => {
    const selectedStockPoints = tabledata
      ?.filter((item, index) => selectedRows[index])
      ?.map((item) => item.sp_stock_point_code); // Get stock point codes

    if (isAccessToAllPoints === true) {
      const proceed = window.confirm(
        "Access to all stock points will be provided. Do you want to proceed?"
      );

      if (proceed) {
        const selectedStockPoints = tabledata?.map(
          (item) => item.sp_stock_point_code
        );

        let obj = {
          p_user_id: userId.toLowerCase(),
          p_instance_uid: selectedInstanceId,
          input_list: "All", // If Access to All, empty input_list
          p_last_modified_by: sessionStorage
            .getItem("loginUsername")
            .toLowerCase(),
        };
        // User clicked 'OK', proceed with the API call
        handleUpdateStockPoint(obj);
        alert(`Stock point linked successfully to User Id : ${userId}`);
      } else {
        // User clicked 'Cancel', do nothing
        return;
      }
    } else if (isAccesstoSelectedStockpoint === true) {
      // ;
      if (tabledata.length === 0) {
        alert("Please Click on Search button for List of Stock Points");
        return;
      } else if (selectedStockPoints.length === 0) {
        if (window.confirm("No record selected. Do you want to proceed?")) {
          let obj = {
            p_user_id: userId.toLowerCase(),
            p_instance_uid: selectedInstanceId,
            input_list:
              isAccessToAllPoints === true ? "" : selectedStockPoints.join(","), // If Access to All, empty input_list
            p_last_modified_by: sessionStorage
              .getItem("loginUsername")
              .toLowerCase(),
          };
          if (tabledata.length !== 0) {
            handleUpdateStockPoint(obj);
            alert(
              `Stock point linking removed successfully for User Id : ${userId}`
            );
          } else {
            alert("Please Click on Search button for List of Stock Points");
          }
        } else {
        }
      } else {
        let obj = {
          p_user_id: userId.toLowerCase(),
          p_instance_uid: selectedInstanceId,
          input_list:
            isAccessToAllPoints === true ? "" : selectedStockPoints.join(","), // If Access to All, empty input_list
          p_last_modified_by: sessionStorage.getItem("loginUsername"),
        };
        if (tabledata.length !== 0) {
          handleUpdateStockPoint(obj);
          alert(`Stock point linked successfully to User Id : ${userId}`);
        } else {
          alert("Please Click on Search button for List of Stock Points");
        }
      }
    }
  };

  // Handle individual checkbox click
  const handleCheckboxChange = (index) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = !updatedSelectedRows[index];
    console.log("updatedSelectedRows", updatedSelectedRows);
    setSelectedRows(updatedSelectedRows);

    // Uncheck "Select All" if any checkbox is deselected
    if (!updatedSelectedRows[index]) {
      setSelectAll(false);
    } else {
      // Check "Select All" if all checkboxes are selected
      if (updatedSelectedRows.every((isSelected) => isSelected)) {
        setSelectAll(true);
      }
    }
  };
  // Handle "Select All" checkbox click
  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    console.log("newSelectAll", newSelectAll);
    setSelectedRows(tabledata?.map(() => newSelectAll));
  };
  const showTableUi = () => {
    return (
      <Grid
        container
        className={modules["mid-cont"]}
        sx={{ marginLeft: "3rem", marginBottom: "3rem" }}
      >
        {tabledata?.length !== 0 && (
          <Table
            style={{ width: "18.5rem" }}
            className={modules["custom_table"]}
          >
            <TableHead>
              <TableRow
                className={modules["header_text"]}
                sx={{
                  "& th": {
                    color: "#fff",
                  },
                }}
              >
                <TableCell
                  padding="checkbox"
                  align="center"
                  // sx={{
                  //   width: "85px",
                  //   fontWeight: "bold",
                  //   fontSize: "16px",
                  //   padding: "5px 10px",
                  // }}
                  // align="left"
                >
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    className={modules["custom-checkbox"]}
                  />
                  {/* //   <Checkbox
                  //     inputProps={{
                  //       "aria-label": "select all linked stock points",
                  //     }}
                  //     className={modules["custom-checkbox"]}
                  //     checked={selectAll}
                  // onChange={handleSelectAllChange}
                  //   /> */}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "5px 10px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Sr. No.
                </TableCell>
                <TableCell
                  sx={{
                    padding: "5px 10px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Stock Point Code
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tabledata?.map((i, index) => {
                return (
                  <TableRow
                    key={i.sp_stock_point_code}
                    className={modules["UIL_TR"]}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell padding="checkbox" align="center">
                      {/* <Checkbox
                          // checked={i.isuserstockpointlinked === 1}
                          inputProps={{
                            "aria-label": `select stock point ${i.sp_stock_point_code}`,
                          }}
                          className={modules["custom-checkbox"]}
                          checked={selectedRows[index]}
                          onChange={() => handleCheckboxChange(index)}
                        /> */}
                      <input
                        type="checkbox"
                        checked={selectedRows[index]}
                        onChange={() => handleCheckboxChange(index)}
                        className={modules["custom-checkbox"]}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        color: "#414141",
                      }}
                      align="left"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        color: "#414141",
                      }}
                      align="left"
                    >
                      {i.sp_stock_point_code}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Grid>
    );
  };
  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block", overflowX: "hidden" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"UserStockPointLinking"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>User Stock Point Linking</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table
                className={modules["customTable"]}
                style={{ marginLeft: "1rem" }}
              >
                <tbody>
                  <tr style={{ display: "flex", alignItems: "center" }}>
                    <td style={{ width: "7rem", fontWeight: "bold" }}>
                      Instance UID <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          onChange={(e) => {
                            const selectedId =
                              e.target.options[
                                e.target.selectedIndex
                              ].getAttribute("data-id");
                            setSelectedInstanceId(e.target.value);
                            setSelectedInstance(selectedId);
                          }}
                          className={modules["d_InstanceSelect"]}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                        >
                          {dropdownData?.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mta_instance_uid}
                                key={i.id}
                                data-id={i.id}
                              >
                                {i.mta_instance_uid}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr style={{ display: "flex", alignItems: "center" }}>
                    <td style={{ width: "7rem", fontWeight: "bold" }}>
                      User ID <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={userId}
                        onChange={(e) => {
                          const regex = /[^a-zA-Z0-9 /&().\-_=\+\[\]]/g;
                          const cleanedValue = e.target.value.replace(
                            regex,
                            ""
                          );
                          setUserId(cleanedValue);
                        }}
                        onBlur={(e) => {
                          const alphanumericRegex = /^[a-zA-Z0-9 &()_.-]*$/;
                          if (!e.target.value) {
                          } else if (!alphanumericRegex.test(e.target.value)) {
                            alert("No Special Characters Allowed.");
                            setUserId("");
                          }
                        }}
                        className={modules["input_css"]}
                        style={{
                          border: "1px solid #9d9d9c !important",
                          marginLeft: "20px",
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Grid container style={{ marginLeft: "16rem" }}>
                <button className={modules["btn1"]} onClick={SearchHandler}>
                  Search
                </button>
              </Grid>
            </Grid>

            {showRadioBtn && (
              <>
                <Grid container className={modules["mid-cont"]}>
                  <FormControl component="fieldset" sx={{ marginLeft: "3rem" }}>
                    <RadioGroup
                      row
                      aria-label="successorstartafterpredecessorstart"
                      name="successorstartafterpredecessorstart"
                      sx={{
                        "& svg": {
                          width: "15px",
                          height: "15px",
                        },
                      }}
                    >
                      <FormControlLabel
                        value="1"
                        sx={{
                          "& .MuiTypography-root ": {
                            fontSize: "13px",
                            fontFamily: "'WorkSans', sans-serif",
                            color: "#414141",
                          },
                        }}
                        control={
                          <Radio
                            sx={{ "&.Mui-checked": { color: "blue" } }}
                            checked={isAccessToAllPoints}
                            onChange={() => {
                              setIsAccessToAllPoints(true);
                              setIsAccessToSelectedStockpoint(false);
                            }}
                          />
                        }
                        label="Access to all Stock Points"
                      />
                      <FormControlLabel
                        value="2"
                        sx={{
                          "& .MuiTypography-root ": {
                            fontSize: "13px",
                            fontFamily: "'WorkSans', sans-serif",
                            color: "#414141",
                          },
                        }}
                        control={
                          <Radio
                            sx={{ "&.Mui-checked": { color: "blue" } }}
                            checked={isAccesstoSelectedStockpoint}
                            onChange={() => {
                              setIsAccessToSelectedStockpoint(true);
                              setIsAccessToAllPoints(false);
                              if (!isAccesstoSelectedStockpoint) {
                                setTableShow(true);
                                handleAccessStockType();
                              }
                            }}
                          />
                        }
                        label="Access to selected Stock Points"
                      />
                    </RadioGroup>
                  </FormControl>

                  <Grid
                    container
                    className={modules["mid-cont"]}
                    style={{ marginLeft: "5rem", gap: "8rem" }}
                  >
                    <button className={modules["btn1"]} onClick={submitHandler}>
                      Submit
                    </button>
                    <button
                      className={modules["btn1"]}
                      onClick={() => window.location.reload()}
                    >
                      Close
                    </button>
                  </Grid>
                  {showRadioBtn && tableShow && <>{showTableUi()}</>}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
