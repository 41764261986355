import React, { useEffect } from "react";
import { Grade } from "@mui/icons-material";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import { useRef, useState } from "react";
import Sidebar from "../../../Landing/Navbar";
import bread from "../../../Assets/bread.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import modules from "./MasterDefinition.module.css";
import {
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  MD_GetMasterDropDownHandler,
  MD_GetAttributeHandler,
  MD_SubmitHandler,
  MD_MasterNameUpdateHandler,
  MD_RowDropdownHandler,
  MD_UpdateRowHandler,
  MD_GetOtherMasterDropDownHandler,
  DS_GetAllInstanceHandler
} from '../../../Services/Dss';
import SampleSidebar from "../../../Landing/SampleSidebar";



const MasterDefinition = () => {

  const [newMasterName, setNewMasterName] = useState('')

  const [submitRender,setSubmitRender]=useState(false);

  const [dropOne, setDropOne] = useState({
    value: 'select',
    dropDown: []
  })
  const [dropTwo, setDropTwo] = useState({
    value: 'select',
    dropDown: []
  })
  const [dropThree, setDropThree] = useState({
    value: 'select',
    dropDown: []
  })
  const [dropFour, setDropFour] = useState({
    value: 'select',
    dropDown: []
  })
  const [submitStates, setSubmitStates] = useState({
    NameofMaster: {},
    tableData: [],
    editState: [],
    updateStates: []

  })
  const [editDropDowns, setEditDropDowns] = useState({
    dataTableOne: [],
    dataTableTwo: [],
    calculated: []
  });
  const [tableData, setTableData] = useState([])


  useEffect(() => {
    DS_GetAllInstanceHandler('pritpwc').then(res => {
      setDropOne({
        dropDown: res.data,
        value: res.data[0].instanceuid
      })
      return res;
    }).then(res => {
      MD_GetMasterDropDownHandler(res.data[0].instanceuid).then(response => {
        setDropTwo({
          ...dropTwo,
          dropDown: res.data,
        })
      }).catch(err => {
        console.log('Error while fetching data:', err);
      })
      // GetMasterDropDownHandler(res);
    }).catch(err => {
      console.log('Error while fetching data:', err);
    })
  }, [])

  function GetMasterDropDownHandler(value) {
    if (value !== 'select') {
      MD_GetMasterDropDownHandler(value).then(res => {
        setDropTwo({
          ...dropTwo,
          dropDown: res.data
        })
      }).catch(err => {
        console.log('Error while fetching data:', err);
      })
    }
  }

  function GetAttributeDropdownHandler(value) {
    if (value !== 'select') {
      MD_GetAttributeHandler(value).then(res => {
        setDropThree({
          ...dropThree,
          dropDown: res.data
        })
      }).catch(err => {
        console.log('Error while fetching data:', err);
      })
    }
  }

  function GetOtherDropDownHandler(value) {
    if (value !== 'select') {
      MD_GetOtherMasterDropDownHandler(value).then(res => {
        setDropFour({
          ...dropFour,
          dropDown: res.data
        })
      }).catch(err => {
        console.log('Error while fetching data:', err);
      })
    }
  }

  function SubmitHandler() {
   (dropTwo.value==='Others' && dropFour.value==='select')?
   alert('Select Other Master'):
   dropTwo.value==='select'?
   alert('Select Master'):
   dropThree.value==='select'?
   alert('Select Type of Attribute'):
   (()=>{
    MD_SubmitHandler(dropOne.value, dropTwo.value !== 'Others' ? dropTwo.value : dropFour.value, dropThree.value).then(res => {
      // MD_SubmitHandler('Lockss_LN', 'DSS_t_invoice_data', 'Index').then(res => {
      if (res.status === 200) {
        console.log(res)
        setSubmitRender(true);
        setSubmitStates({
          ...submitStates,
          NameofMaster: res.data.TableName[0],
          tableData: res.data.GridData,
          editState: res.data.GridData.map(i => { return false }),
          updateStates: res.data.GridData.map((i) => {
            return {
              recordId: i.recordid,
              AttributeDisplay: i.attributedisplayname,
              AttributeDefault: i.attributedefaultvalue,
              Validation: i.validationrequiredind,
              ValidationMaster: i.validationmaster?i.validationmaster:'select',
              Calculated: i.calculatedparameterind
            }
          })
        });
        GetTableDropDownHandler();
        setTableData(res.data.TableName)
        setNewMasterName(res.data.TableName[0].masterdisplayname)
      }
    }).catch(err => {
      console.log('Error while fetching data:', err);
    })
   })()
  }

  function MasterNameUpdateHandler() {
    if(newMasterName){
      const body = {
        instanceUID: dropOne.value,
        recordId: tableData[0].recordid,
        masterDisplayName: newMasterName,
        userID: "pritpwc",
        host: "Host",
        ip: "IP"
      }
      MD_MasterNameUpdateHandler(body).then(res => {
        if (res.status === 200) {
          alert(res.data);
        }
      }).catch(err => {
        console.log('Error while fetching data:', err);
      })
    }else{
      alert('Enter Name of Master')
    }
  }

  function GetTableDropDownHandler() {
    MD_RowDropdownHandler(dropOne.value, dropTwo.value !== 'Others' ? dropTwo.value : dropFour.value, dropThree.value).then(res => {
      //MD_RowDropdownHandler('Lockss_LN', 'DSS_t_instance_BC_linking_GnB_CR', 'Index').then(res => {
      if (res.status === 200) {
        setEditDropDowns({
          ...editDropDowns,
          dataTableOne: res.data['DataTable 1'],
          dataTableTwo: res.data['DataTable 2'],
          calculated: res.data.Calculated
        })
      }
    }).catch(err => {
      console.log('Error while fetching data:', err);
    })
  }

  function UpdateRowHandler(index) {
    !submitStates.updateStates[index].AttributeDisplay?
    alert('Enter Display Name'):
    !submitStates.updateStates[index].AttributeDefault?
    alert('Enter Default value'):
    submitStates.updateStates[index].Validation==='select'?
    alert('Select Validation Required'):
    (submitStates.updateStates[index].ValidationMaster==='select' && submitStates.updateStates[index].Validation==='Yes')?
    alert('Select Validation Master'):
    submitStates.updateStates[index].Calculated==='select'?
    alert('Select Calculated'):
    (()=>{
      const body = {
        instanceUID: dropOne.value,
        tableName: dropTwo.value !== 'Others' ? dropTwo.value : dropFour.value,
        typeOfAttr: dropThree.value,
        recordId: submitStates.updateStates[index].recordId,
        attributeDisplayName: submitStates.updateStates[index].AttributeDisplay,
        userID: "pritpwc",
        attributeDefaultValue: submitStates.updateStates[index].AttributeDefault,
        validationRequiredInd: submitStates.updateStates[index].Validation,
        validationMaster: submitStates.updateStates[index].ValidationMaster,
        calculatedParameterInd: submitStates.updateStates[index].Calculated,
        host: "Host",
        ip: "IP"
      }
      MD_UpdateRowHandler(body).then(res => {
        if (res.status === 200) {
          alert(res.data.SuccessMessage);
          SubmitHandler();
        }
      }).catch(err => {
        console.log('Error while fetching data:', err)
      })
    })()

   
  }

  function InstanceChangeHandler(value) {
    setDropOne({
      ...dropOne,
      value: value
    });
    GetMasterDropDownHandler(value);
    GetOtherDropDownHandler(value);
    GetAttributeDropdownHandler(value);
  }

  function ArrowLine() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "2px",
            height: "30px",
            backgroundColor: "grey",
            marginRight: "2px",
          }}
        />
        <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
      </div>
    );
  }

  function UpdatedValuesHandler(index, key, e) {
    setSubmitStates((prevSubmitStates) => {
      const updatedUpdateStates = prevSubmitStates.updateStates.map((item, idx) => {
        return index === idx ? { ...item, [key]: e.target.value } : item;
      });

      return {
        ...prevSubmitStates,
        updateStates: updatedUpdateStates,
      };
    });
  }

  return (
    <>
      <Grid >
        <Grid
          item
          lg={0.6}
          sx={{ position: "fixed", zIndex: 2000 }}
          className="main-cont"
        >
          {/* <Sidebar /> */}
          <SampleSidebar/>
        </Grid>
        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className="home-cont">
            <Home />
          </Grid>
        </Grid>

        <Grid item lg={9} className={modules["masterdefinition_div"]}>
          <div className="pro-bread" style={{ marginLeft: "5rem" }}>
            <p>
              <a>DSS Master</a>
              <img src={bread} alt="" />
              <a>Master Definition</a>
            </p>
          </div>
        </Grid>

        {/* Dropdown */}
        <Grid container sx={{ marginLeft: "5rem", marginTop: "2rem" }} >
          <table className={modules["topTable"]}>
            <tbody>
              <tr>
                <td>DSS Instance</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={dropOne.value}
                      onChange={(e) => InstanceChangeHandler(e.target.value)}
                    >
                      <MenuItem value='select' sx={{ display: 'none' }}>Select</MenuItem>
                      {dropOne?.dropDown?.map((i) => {
                        return (
                          <MenuItem value={i.instanceuid} key={i.instanceuid}>
                            {i.instanceuid}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>{" "}
                </td>
              </tr>

              <tr>
                <td>Select Master</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={dropTwo.value}
                      onChange={(e) => setDropTwo({
                        ...dropTwo,
                        value: e.target.value
                      })}
                    >
                      <MenuItem value='select' sx={{ display: 'none' }}>Select</MenuItem>
                      {dropTwo.dropDown.map((i) => {
                        return (
                          <MenuItem value={i.columnvalue} key={i.columnvalue}>
                            {i.displayvalue}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </td>
               {
                dropTwo.value ==='Others' && (
                  <>
                   <td>Select Other</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={dropFour.value}
                      onChange={(e) => setDropFour({
                        ...dropFour,
                        value: e.target.value
                      })}
                    >
                      <MenuItem value='select' sx={{ display: 'none' }}>Select</MenuItem>
                      {dropFour.dropDown.map((i) => {
                        return (
                          <MenuItem value={i.tablename} key={i.tablename}>
                            {i.masterdisplayname}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </td>
                  </>
                )
               }
              </tr>

              <tr>
                <td>Attribute</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={dropThree.value}
                      onChange={(e) => setDropThree({
                        ...dropThree,
                        value: e.target.value
                      })}
                    >
                      <MenuItem value='select' sx={{ display: 'none' }}>Select</MenuItem>
                      {dropThree.dropDown.map((i) => {
                        return (
                          <MenuItem value={i.columnvalue} key={i.columnvalue}>
                            {i.displayvalue}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <div>
          <button style={{ marginLeft: "18rem", marginTop: "1rem", padding: "0.5cm" }} type="button" className={modules["btn1"]} onClick={() => SubmitHandler()}>
            Submit
          </button>
        </div>
       {
        submitRender && (
          <div>
          <table className={modules['customTable']}>
            <tbody>
              <tr>
                <td>Name Of Master</td>
                <td>:</td>
                <td><input type="text" value={newMasterName }  onChange={(e) => setNewMasterName(e.target.value)} className={modules['customInp']}/></td>
              </tr>
            </tbody>
          </table>
          <button style={{ marginLeft: "18rem", marginTop: "1.2rem", padding: "0.5cm" }} type="button" className={modules["btn1"]} onClick={() => MasterNameUpdateHandler()}>
            Update
          </button>

        </div>
        )
       }

      </Grid>
      {
        submitRender && (
          <Grid sx={{ marginLeft: "5rem", overflowX: 'scroll' }} >
        <table className={modules['btmTable']}>
          <thead>
            <th>Sr No</th>
            <th>Display Name</th>
            <th>Data Type</th>
            <th>Default Value</th>
            <th>Validation Required</th>
            <th>Validation on Master</th>
            <th>Calculated</th>
            <th></th>
          </thead>
          <tbody>
            {
              submitStates?.tableData?.map((i, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{!submitStates.editState[index] ? i.attributedisplayname : <input value={submitStates.updateStates[index].AttributeDisplay} onChange={(e) =>
                      UpdatedValuesHandler(index, 'AttributeDisplay', e)
                    } className={modules['tableInp']}/>}</td>
                    <td>{i.attributedatatype}</td>
                    <td>{!submitStates.editState[index] ? i.attributedefaultvalue : <input value={submitStates.updateStates[index].AttributeDefault} onChange={(e) => {
                      UpdatedValuesHandler(index, 'AttributeDefault', e)
                    }} className={modules['tableInp']}/>}</td>
                    <td>{!submitStates.editState[index] ? i.validationrequiredind :
                      <FormControl className={modules["form-control1"]}>
                        <Select
                          IconComponent={ArrowLine}
                          className={modules["dropdownSelect2"]}
                          value={submitStates.updateStates[index].Validation}
                          onChange={(e) => UpdatedValuesHandler(index, 'Validation', e)}
                        // onChange={(e) => setSubmitStates({
                        //   ...submitStates,
                        //   updateStates: ((a) => {
                        //     return a.map((i, idx) => {
                        //       return index === idx ? {
                        //         ...i,
                        //         Validation: e.target.value
                        //       } : i;
                        //     })
                        //   })([...submitStates.updateStates])
                        // })}
                        >
                          <MenuItem value='select' >Select</MenuItem>
                          {editDropDowns?.dataTableOne?.map((i) => {
                            return (
                              <MenuItem value={i.columnvalue} key={i.columnvalue}>
                                {i.displayvalue}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>}
                    </td>
                    <td>{!submitStates.editState[index] ? i.validationmaster :
                      submitStates.updateStates[index].Validation === 'Yes' && (
                        <FormControl className={modules["form-control1"]}>
                          <Select
                            IconComponent={ArrowLine}
                            className={modules["dropdownSelect2"]}
                            value={submitStates.updateStates[index].ValidationMaster}
                            onChange={(e) => UpdatedValuesHandler(index, 'ValidationMaster', e)}
                          >
                            <MenuItem value='select' >Select</MenuItem>
                            {editDropDowns?.dataTableTwo?.map((i) => {
                              return (
                                <MenuItem value={i.masterdisplayname} key={i.tablename}>
                                  {i.masterdisplayname}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )
                    }</td>
                    <td>{!submitStates.editState[index] ? i.calculatedparameterind : <FormControl className={modules["form-control1"]}>
                      <Select
                        IconComponent={ArrowLine}
                        className={modules["dropdownSelect2"]}
                        value={submitStates.updateStates[index].Calculated}
                        onChange={(e) => UpdatedValuesHandler(index, 'Calculated', e)}
                      // onChange={(e) => setSubmitStates({
                      //   ...submitStates,
                      //   updateStates: ((a) => {
                      //     return a.map((i, idx) => {
                      //       return index === idx ? {
                      //         ...i,
                      //         Calculated: e.target.value
                      //       } : i;
                      //     })
                      //   })([...submitStates.updateStates])
                      // })}
                      >
                        <MenuItem value='select' >Select</MenuItem>
                        {editDropDowns.calculated.map((i) => {
                          return (
                            <MenuItem value={i.columnvalue} key={i.columnvalue}>
                              {i.displayvalue}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>}</td>
                    <td>{
                      !submitStates.editState[index] ? <a onClick={() => {
                        const updatedEditState = [...submitStates.editState];
                        updatedEditState.splice(index, 1, !submitStates.editState[index]);
                        setSubmitStates({
                          ...submitStates,
                          editState: updatedEditState
                        })
                      }}>Edit</a> : <>
                        <a onClick={() => UpdateRowHandler(index)}>Update</a><br/>
                        <a onClick={() => {
                          const updatedEditState = [...submitStates.editState];
                          updatedEditState.splice(index, 1, !submitStates.editState[index]);
                          setSubmitStates({
                            ...submitStates,
                            editState: updatedEditState
                          })
                        }}>Cancel</a>
                      </>
                    }</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </Grid>
        )
      }
    </>
  );
};

export default MasterDefinition;
