import React, { useEffect, useState } from "react";
import "../../../HelpPopups/HelpPopup.css";
import Stack from "@mui/material/Stack";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import modules from '../../ccpm.module.css'
import { Grid } from "@mui/material";
import bread from "../../../../Assets/bread.png";
import { PendingTaskPopup, ViewBufferRecoveryPopup } from "../../../../Services/CCPM";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";

const ViewBufferRecovery = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [isLoading, setIsLoading] = useState(false);
    const data = searchParams.get("data")

    return (
        <div style={{ overflowX: 'auto' }}>
            <Stack
                direction="row"
                spacing={4}
                sx={{ paddingTop: "1rem" }}
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                    <img src={logo} alt="Godrej" />
                </Stack>
                <Stack sx={{ paddingRight: "1rem" }}>
                    <img src={prostream} alt="Prostream" />
                </Stack>
            </Stack>
            <table border="1" style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }} className={modules["custom-table-Viewriskpopup"]}>
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Recovery<br />Planning<br />Date</th>
                        <th>Project<br />Color</th>
                        <th>Buffer<br />Consumption<br />(%)</th>
                        <th>Critical<br />Chain<br />Completion<br />(%)</th>
                        <th>Buffer<br />Penetration<br />Days</th>
                        <th>Planned Recovery Action</th>
                        <th>Assign To</th>
                        <th>Expected<br />Action Date</th>
                        <th>Expected<br />Buffer<br />Recovery<br />Days</th>
                        <th>Status</th>
                        <th>Actual<br />Action Date</th>
                        <th>Closure Remark (By PM)</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data?.length>0 && data?.map((item, index) => (
                        <tr>
                            <td>{index}</td>
                            <td>{item.recoveryplanningdate}</td>
                            <td>{item.projectcolor}</td>
                            <td>{item.percentagebufferconsumption}</td>
                            <td>{item.percentagecccompletion}</td>
                            <td>{item.bufferconsumptiondays}</td>
                            <td>
                                <textarea style={{
                                    fontFamily: "WorkSans, sans-serif",
                                    fontSize: '12px',
                                    color: '#414141'
                                }}
                                    readOnly rows="2" cols="50" value={item.plannedrecoveryaction} />
                            </td>
                            <td>{item.assignedto}</td>
                            <td>{item.expectedrecoveryactionby}</td>
                            <td>{item.expectedbufferrecoverydays}</td>
                            <td>{item.status}</td>
                            <td>{item.actualrecoveryactionon}</td>
                            <td>
                                <textarea style={{
                                    fontFamily: "WorkSans, sans-serif",
                                    fontSize: '12px',
                                    color: '#414141'
                                }}
                                    readOnly rows="2" cols="50" value={item.pmremark} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ViewBufferRecovery;
