import axios from "axios";
import { API_KEY, CTLS_CalculateClick,CTLS_GetTocWeek,CTLS_GetYear} from "../Constants";
import { DRTLS_CalculateClick,DRTLS_GetTocWeek,DRTLS_GetFinancialYear } from "../Constants";

export async function CalculateTrdLossSummary_CalculateHandler(instanceId,userId,finaceYear,tocWeek){
    return await axios.get(`${API_KEY}${CTLS_CalculateClick}${instanceId}&userID=${userId}&FinanceYr=${finaceYear}&TocWeek=${tocWeek}`).then(res=>{
        return res;
    }).catch(err=>{
        return err;
    })
}

export async function CalculateTrdLossSummary_GetYearHandler(){
    return await axios.get(`${API_KEY}${CTLS_GetYear}`).then(res=>{
        return res;
    }).catch(err=>{
        return err;
    })
}

export async function CalculateTrdLossSummary_GetTocHandler(instanceId,finaceYear){
    return await axios.get(`${API_KEY}${CTLS_GetTocWeek}${instanceId}&Finance_yr=${finaceYear}`).then(res=>{
        return res;
    }).catch(err=>{
        return err;
    })
}


//Dss report trd loss summary 

export async function DRTLS_CalculateClickHandler(instanceId,userId,Finance_Year,tocWeek){
    return await axios.get(`${API_KEY}${DRTLS_CalculateClick}?instanceUId=${instanceId}&userID=${userId}&Finance_Year=${Finance_Year}&Toc_Week=${tocWeek}`).then(res=>{
        return res;
    }).catch(err=>{
        return err;
    })
}

export async function DRTLS_GetFinancialYearHandler(){
    return await axios.get(`${API_KEY}${DRTLS_GetFinancialYear}`).then(res=>{
        return res;
    }).catch(err=>{
        return err;
    })
}

export async function DRTLS_GetTocWeekHandler(instanceId,Finance_Year){
    return await axios.get(`${API_KEY}${DRTLS_GetTocWeek}?instanceUId=${instanceId}&Finance_yr=${Finance_Year}`).then(res=>{
        return res;
    }).catch(err=>{
        return err;
    })
}