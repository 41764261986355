import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  TextField,
  Stack,
  Paper,
  TableContainer,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../../Landing/Navbar";
// import modules from "./TaskMgmtConfig.module.css";
import loading from "../../../Assets/Loading_image.gif";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  TMT_InstanceData,
  TMT_RadioOptions,
  TMT_GetTableData,
  TMT_SaveBtn,
} from "../../../Services/CCPM";

const TaskMgmtConfig = () => {
  const [showPlus, setShowPlus] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [dropdownData, setDropdownData] = useState([]);
  const [mainDropdownData, setMainDropdownData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [displayvalue, setDisplayValue] = useState("");
  const [radioValueStart, setRadioValueStart] = useState("");
  const [radioValueComp, setRadioValueComp] = useState("");
  const [radioValueStartIn, setRadioValueStartIn] = useState("");
  const [radioValueCompIn, setRadioValueCompIn] = useState("");
  const [condition, setCondition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disableR, setDisableR] = useState(false);
  const handleIconToggle = () => {
    setShowPlus(!showPlus);
  };

  useEffect(() => {
    checkLoginAndRedirect();
    let body = {
      userId: sessionStorage.getItem("loginId"),
    };
    setIsLoading(true);
    TMT_InstanceData(body)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          const apiData = response.data;
          const data = apiData.success.data;
          const names = data.map((item) => item.name);
          setDropdownData(names);
          setMainDropdownData(data);
          if (names.length > 0) {
            setDisplayValue(names[0]);
            setSelectedId(data[0].id);
          }
        } else {
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    checkLoginAndRedirect();
    if (selectedId) {
      let body = {
        instanceId: selectedId,
      };
      TMT_RadioOptions(body)
        .then((response) => {
          if (response.status === 200) {
            const apiData = response.data;
            const check = apiData.success.data;
            const options = apiData.success.data[0];
            if (check.length === 0) {
              setRadioValueStart("");
              setRadioValueComp("");
              setRadioValueStartIn("");
              setRadioValueCompIn("");
              setCondition(false);
            } else {
              setCondition(true);
              if (options.issuccessorstartafterpredecessorstart === 1) {
                setRadioValueStart("1");
                setRadioValueStartIn("1");
                setRadioValueComp("1");
                setRadioValueCompIn("1");
                setDisableR(true);
              } else {
                setRadioValueStart("0");
                setRadioValueStartIn("0");
                setRadioValueComp(
                  options.issuccessorcompleteafterpredecessorcomplete === 1
                    ? "1"
                    : "0"
                );
                setRadioValueCompIn(
                  options.issuccessorcompleteafterpredecessorcomplete === 1
                    ? "1"
                    : "0"
                );
                setDisableR(false);
              }
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
      let body2 = {
        instanceId: selectedId,
      };
      TMT_GetTableData(body2)
        .then((response) => {
          if (response.status === 200) {
            const apiData = response.data;
            const options = apiData.success.data;

            const formatDate = (isoDateString) => {
              const date = new Date(isoDateString);
              const day = date.getDate().toString().padStart(2, "0");
              const month = (date.getMonth() + 1).toString().padStart(2, "0");
              const year = date.getFullYear();
              const hours = date.getHours().toString().padStart(2, "0");
              const minutes = date.getMinutes().toString().padStart(2, "0");
              const seconds = date.getSeconds().toString().padStart(2, "0");
              return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
            };

            options.forEach((item) => {
              item.createdon = formatDate(item.createdon);
            });

            if (options.length === 0) {
              return;
            } else {
              setFilteredData(options);
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedId]);

  useEffect(() => {
    checkLoginAndRedirect();
    if (selectedId) {
    }
  }, [selectedId]);

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };

  const handleRadioChangeStart = (event) => {
    setRadioValueStart(event.target.value);
    if (event.target.value === "1") {
      setDisableR(true);
      setRadioValueComp("1");
      setRadioValueCompIn("1");
    } else {
      setRadioValueComp("");
      setRadioValueCompIn("");
      setDisableR(false);
    }
  };

  const handleRadioChangeComp = (event) => {
    setRadioValueComp(event.target.value);
  };

  const handleChange = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.name === selectedName
    );
    setDisplayValue(selectedName);
    setSelectedId(selectedItem.id);
  };

  const handleSave = () => {
    if (
      dropdownData.length > 0 &&
      radioValueStart !== "" &&
      radioValueComp !== ""
    ) {
      if (
        radioValueStart === radioValueStartIn &&
        radioValueComp === radioValueCompIn
      ) {
        alert("No Change to Update.");
        return;
      } else {
        setIsLoading(true);
        let body = {
          instanceId: selectedId,
          isSuccessorStartAfterPredecessorStart: radioValueStart,
          isSuccessorCompleteAfterPredecessorComplete: radioValueComp,
          userId: sessionStorage.getItem("loginId"),
        };
        TMT_SaveBtn(body)
          .then((response) => {
            console.log(response, "data 3");
            setIsLoading(false);
            if (response.status === 200) {
              alert("Details Updated Succesfully");
              let body1 = {
                instanceId: selectedId,
              };
              TMT_RadioOptions(body1)
                .then((response) => {
                  if (response.status === 200) {
                    const apiData = response.data;
                    const check = apiData.success.data;
                    const options = apiData.success.data[0];
                    if (check.length === 0) {
                      setRadioValueStart("");
                      setRadioValueComp("");
                      setRadioValueStartIn("");
                      setRadioValueCompIn("");
                      setCondition(false);
                    } else {
                      setCondition(true);
                      if (options.issuccessorstartafterpredecessorstart === 1) {
                        setRadioValueStart("1");
                        setRadioValueStartIn("1");
                        setRadioValueComp("1");
                        setRadioValueCompIn("1");
                        setDisableR(true);
                      } else {
                        setRadioValueStart("0");
                        setRadioValueStartIn("0");
                        setRadioValueComp(
                          options.issuccessorcompleteafterpredecessorcomplete ===
                            1
                            ? "1"
                            : "0"
                        );
                        setRadioValueCompIn(
                          options.issuccessorcompleteafterpredecessorcomplete ===
                            1
                            ? "1"
                            : "0"
                        );
                        setDisableR(false);
                      }
                    }
                  } else {
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              let body = {
                instanceId: selectedId,
              };
              TMT_GetTableData(body)
                .then((response) => {
                  if (response.status === 200) {
                    const apiData = response.data;
                    const options = apiData.success.data;

                    const formatDate = (isoDateString) => {
                      const date = new Date(isoDateString);
                      const day = date.getDate().toString().padStart(2, "0");
                      const month = (date.getMonth() + 1)
                        .toString()
                        .padStart(2, "0");
                      const year = date.getFullYear();
                      const hours = date.getHours().toString().padStart(2, "0");
                      const minutes = date
                        .getMinutes()
                        .toString()
                        .padStart(2, "0");
                      const seconds = date
                        .getSeconds()
                        .toString()
                        .padStart(2, "0");
                      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
                    };

                    options.forEach((item) => {
                      item.createdon = formatDate(item.createdon);
                    });

                    if (options.length === 0) {
                      return;
                    } else {
                      setFilteredData(options);
                    }
                  } else {
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      }
    } else {
      alert("Select All Mandatory Field Values");
    }
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_contTMG"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-contTMG"]}>
            <Home page={"TaskManMaster"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_divTMG"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Task Mgmt Config</a>
              </div>
            </div>

            <Stack
              direction="row"
              sx={{ marginTop: "2rem" }}
              className={modules["mid-contTMG"]}
            >
              <Typography
                style={{
                  fontFamily: "'WorkSans', sans-serif",
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#414141",
                }}
              >
                Instance:<span style={{ color: "red" }}>*</span>
              </Typography>
              <select
                name="cars"
                id="cars"
                value={displayvalue}
                onChange={handleChange}
                className={modules["dropdownSelect1"]}
                style={{
                  marginLeft: "28rem",
                }}
              >
                {dropdownData?.length > 0 &&
                  dropdownData?.map((i) => (
                    <option key={i} value={i} style={{ color: "#414141" }}>
                      {i}
                    </option>
                  ))}
              </select>
              {/* <FormControl
                className={modules["form-controlTMG"]}
                sx={{
                  paddingLeft: "27rem",
                  "&.MuiOutlinedInput-root": {},
                }}
              >

                <Select
                  IconComponent={ArrowDropDownIcon}
                  className={modules["dropdownSelectTMG"]}
                  value={displayvalue}
                  onChange={handleChange}
                  style={{
                    width: "14rem",
                  }}
                >
                  {dropdownData.map((i) => {
                    return (
                      <MenuItem sx={{ fontSize: "12px" }} value={i} key={i}>
                        {i}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}
            </Stack>

            <Grid container className={modules["mid-contTMG"]}>
              <Grid
                item
                lg={12}
                className={modules["mid-contTMG"]}
                container
                direction="row"
                alignItems="center"
              >
                <FormLabel
                  sx={{
                    marginRight: "1rem",
                    width: "30rem",
                    fontSize: "12px",
                    color: "#414141",
                    fontFamily: "'WorkSans', sans-serif",
                    fontWeight: "600",
                  }}
                >
                  Successor Task To be Started Only After Immediate
                  Predecessor(s) is/are Started :{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <FormControl component="fieldset" sx={{ marginLeft: " 2.5%" }}>
                  <RadioGroup
                    row
                    aria-label="successorstartafterpredecessorstart"
                    name="successorstartafterpredecessorstart"
                    value={radioValueStart}
                    onChange={handleRadioChangeStart}
                    sx={{
                      "& svg": {
                        width: "15px",
                        height: "15px",
                      },
                    }}
                  >
                    <FormControlLabel
                      value="1"
                      sx={{
                        "& .MuiTypography-root ": {
                          fontSize: "12px",
                          fontFamily: "'WorkSans', sans-serif",
                        },
                      }}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      sx={{
                        "& .MuiTypography-root ": {
                          fontSize: "12px",
                          fontFamily: "'WorkSans', sans-serif",
                        },
                      }}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container className={modules["mid-contTMG"]}>
              <Grid
                item
                lg={12}
                className={modules["mid-contTMG"]}
                container
                direction="row"
                alignItems="center"
              >
                <FormLabel
                  sx={{
                    marginRight: "1rem",
                    width: "31rem",
                    fontSize: "12px",
                    color: "#414141",
                    fontFamily: "'WorkSans', sans-serif",
                    fontWeight: "600",
                  }}
                >
                  Successor Task To be Completed Only After Immediate
                  Predecessor(s) is/are Completed :
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <FormControl component="fieldset" sx={{ marginLeft: "1.3%" }}>
                  <RadioGroup
                    row
                    aria-label="successorcompleteafterpredecessorcomplete"
                    name="successorcompleteafterpredecessorcomplete"
                    value={radioValueComp}
                    onChange={handleRadioChangeComp}
                    sx={{
                      "& svg": {
                        width: "15px",
                        height: "15px",
                      },
                    }}
                  >
                    <FormControlLabel
                      value="1"
                      sx={{
                        "& .MuiTypography-root ": {
                          fontSize: "12px",
                          fontFamily: "'WorkSans', sans-serif",
                        },
                      }}
                      control={
                        <Radio
                          disabled={disableR}
                          sx={{
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      sx={{
                        "& .MuiTypography-root ": {
                          fontSize: "12px",
                          fontFamily: "'WorkSans', sans-serif",
                        },
                      }}
                      control={
                        <Radio
                          disabled={disableR}
                          sx={{
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              className={modules["btn1-contTMG"]}
              style={{
                marginLeft: "29rem",
                marginTop: "0rem",
              }}
            >
              <button className={modules["btn1TMG"]} onClick={handleSave}>
                Save
              </button>
            </Grid>

            {condition && (
              <>
                <a
                  href="#"
                  className={modules["vchTMG"]}
                  onClick={handleIconToggle}
                >
                  {showPlus ? (
                    <AddIcon sx={{ color: "grey", marginBottom: "-6px" }} />
                  ) : (
                    <RemoveIcon sx={{ color: "grey", marginBottom: "-5px" }} />
                  )}{" "}
                  View Config History
                </a>
                {!showPlus && (
                  <div
                    style={{
                      width: "70%",
                      marginLeft: "10%",
                      marginBottom: "2%",
                    }}
                  >
                    <TableContainer sx={{ marginTop: "5px" }}>
                      <Table className={modules["custom-table2TMG"]}>
                        <TableHead className={modules["table_headTMG"]}>
                          <TableRow
                            sx={{
                              "& th": {
                                color: "#fff",
                              },
                            }}
                          >
                            <TableCell align="center">Sr.No.</TableCell>
                            <TableCell align="left">
                              Setting For SuccessorStartAfterPredecessorStart
                            </TableCell>
                            <TableCell align="left">
                              Setting For
                              SuccessorCompleteAfterPredecessorComplete
                            </TableCell>
                            <TableCell align="left">
                              Created <br /> By
                            </TableCell>
                            <TableCell align="left">
                              Created <br /> On
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredData?.map((item, index) => (
                            <TableRow
                              key={index + 1}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell align="left">
                                <div>
                                  {
                                    item.actiononissuccessorstartafterpredecessorstart
                                  }
                                </div>
                              </TableCell>
                              <TableCell align="left">
                                <div>
                                  {
                                    item.actiononissuccessorcompleteafterpredecessorcomplete
                                  }
                                </div>
                              </TableCell>
                              <TableCell className="editlink" align="left">
                                {item.username}
                              </TableCell>
                              <TableCell className="editlink" align="left">
                                {item.createdon}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TaskMgmtConfig;
