import React, { useEffect } from "react";
import bread from "../../Assets/bread.png";
import Home from "../../home/Home";
import { Grid, Stack, Typography } from "@mui/material";
import window_popup from "../../Assets/window_popup.png";
import PlusIcon from "../../Assets/plus.png";
import MinusIcon from "../../Assets/minus.png";
import classes from "./Admin.module.css";
import { useState } from "react";
import {
  RP_UpdateHandler,
  PR_GetMenuListHandler,
  PR_GetSubMenuListHandler,
  PR_GetSessionPrivilageHandler,
  PR_InsertRoleHandler,
} from "../../Services/Admin";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import SampleSidebar from "../../Landing/SampleSidebar";
import loading from "../../Assets/Loading_image.gif";

export default function ParentRolePermission() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ParamId = searchParams.get("roleid");
  const nav = useNavigate();
  const [sublist1, setSublist1] = useState([]);
  const [subMunu2, setSubMunu2] = useState([]);
  const [subMenuItems, setSubMenuItems] = useState({});
  const [expandedMenu, setExpandedMenu] = useState([]);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [expandedLast, setExpandedLast] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const menuListResponse = await PR_GetMenuListHandler();
        const jsonData = menuListResponse?.data?.success?.data;
        setSublist1(jsonData);

        if (jsonData) {
          const headingIds = jsonData.map((item) => item.headingid);
          const promises = headingIds.map((menuId) =>
            PR_GetSubMenuListHandler({ menuId }).then((res) => ({
              menuId,
              data: res,
            }))
          );

          const responses = await Promise.all(promises);
          const consolidatedData = responses.reduce((acc, { menuId, data }) => {
            if (data.status === 200) {
              acc[menuId] = data?.data?.success?.data || [];
            }
            return acc;
          }, {});
          setSubMenuItems(consolidatedData);
        }
        setIsLoading(false);
      } catch (err) {
        alert("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const channel1 = new BroadcastChannel("menuFirst");
    const channel2 = new BroadcastChannel("menuSecond");

    const updateConsolidatedData = (id, parentName, parentId) => {
      const idInt = parseInt(id, 10);
      setSubMenuItems((prevSubMenuItems) => {
        const updatedSubMenuItems = JSON.parse(
          JSON.stringify(prevSubMenuItems)
        );
        for (const [menuId, items] of Object.entries(updatedSubMenuItems)) {
          const itemToUpdate = items.find((item) => item.id === idInt);
          if (itemToUpdate) {
            itemToUpdate.parentname = parentName;
            itemToUpdate.roleid = parentId;
            break;
          }
        }

        return updatedSubMenuItems;
      });
    };

    const updateConsolidatedData2 = (id, parentName, parentId) => {
      const idInt = parseInt(id, 10);
      setSubMunu2((prevSubMenuItems) => {
        return prevSubMenuItems.map((item) => {
          if (item.id === idInt) {
            return { ...item, parentname: parentName, roleid: parentId };
          }
          return item;
        });
      });
    };

    channel1.onmessage = (event) => {
      const { id, parentname, parentId } = event.data;
      updateConsolidatedData(id, parentname, parentId);
    };

    channel2.onmessage = (event) => {
      const { id, parentname, parentId } = event.data;
      updateConsolidatedData2(id, parentname, parentId);
    };

    return () => {
      channel1.close();
      channel2.close();
    };
  }, []);

  function SaveHandler() {
    const userID = sessionStorage.getItem("loginId");

    const subMenuLookup = Object.entries(subMenuItems).reduce(
      (acc, [key, items]) => {
        acc[key] = items.reduce((innerAcc, item) => {
          innerAcc[item.id] = item;
          return innerAcc;
        }, {});
        return acc;
      },
      {}
    );

    const subMenu2Lookup = subMunu2.reduce((acc, item) => {
      if (!acc[item.headingid]) {
        acc[item.headingid] = [];
      }
      acc[item.headingid].push(item);
      return acc;
    }, {});

    const transformData = () => {
      return {
        userID: userID,
        mod: Object.entries(subMenuLookup).map(([menuIdL1, subMenuItems]) => {
          const mod2 = Object.values(subMenuItems).map((subMenuItem) => {
            const menuId2 = subMenuItem.id;

            const mod3 = Object.values(subMenu2Lookup[menuId2] || {}).map(
              (subSubMenuItem) => ({
                menuId3: subSubMenuItem.id,
                roleID: subSubMenuItem.roleid,
              })
            );

            return {
              menuId2: menuId2,
              roleID: subMenuItem.roleid,
              mod3: mod3,
            };
          });

          return {
            menuIdL1: Number(menuIdL1),
            mod2: mod2,
          };
        }),
      };
    };

    const body = transformData();

    PR_InsertRoleHandler(body)
      .then((res) => {
        if (res.status === 200) {
          alert("Data saved successfully!");
          // sessionStorage.removeItem("menuData");
          NavigateHandler();
        }
        if (res?.response?.status === 400) alert(res.response.data);
      })
      .catch((err) => {
        alert("Error while saving data:", err);
      });
  }

  function NavigateHandler() {
    window.location.reload();
  }

  const onClickHandler = (headingId) => {
    if (expandedMenu.includes(headingId)) {
      setExpandedMenu(expandedMenu.filter((id) => id !== headingId));
    } else {
      setExpandedMenu([...expandedMenu, headingId]);
    }
  };

  const onSubMenuClick = (s_id) => {
    if (expandedItemId === s_id) {
      setExpandedItemId(null);
    } else {
      setIsLoading(true);
      setExpandedItemId(s_id);
      const body = {
        menuid: s_id,
      };
      PR_GetSubMenuListHandler(body)
        .then((res) => {
          setIsLoading(false);
          setSubMunu2(res?.data?.success?.data);
        })
        .catch((err) => {
          alert("Error fetching data", err);
        });
    }
  };

  const onSubMenuClick2 = (s_id) => {
    if (expandedLast === s_id) {
      setExpandedLast(null);
    } else {
      setExpandedLast(s_id);
    }
  };

  const handlePopupOpen = (menuName, menuId) => {
    if (menuName === "menu1") {
      window.open(
        `/admin/parentrolepopup?menuName=menu1&menuId=${menuId}`,
        "mypopuptitle",
        "width=600,height=500"
      );
    } else {
      window.open(
        `/admin/parentrolepopup?menuName=menu2&menuId=${menuId}`,
        "mypopuptitle",
        "width=600,height=500"
      );
    }
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={classes["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} sx={{ position: "fixed", zIndex: 2000 }}>
          {/* <Sidebar /> */}
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className="home-cont">
            <Home />

            <Grid
              sx={{
                width: "70%",
                marginLeft: "9.3rem",
                marginTop: "0.2rem",
              }}
            >
              <div className={classes["pro-bread"]}>
                <p>
                  <a>Admin</a>
                  <img src={bread} alt="" />
                  <a>Restrict Role Permissions</a>
                </p>
              </div>
              {sublist1.length > 0 ? (
                <>
                  <Grid
                    sx={{
                      maxWidth: "100%",
                      marginTop: "1cm",
                    }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        width: "80%",
                        marginLeft: "4rem",
                      }}
                    >
                      <li className={classes["list-header"]}>Permissions</li>
                      {sublist1?.map((ele, index) => {
                        return (
                          <>
                            <li className={classes["list-item"]}>
                              <span className={classes["bodyFont"]}>
                                <img
                                  src={
                                    expandedMenu.includes(ele.headingid)
                                      ? MinusIcon
                                      : PlusIcon
                                  }
                                  alt="icon"
                                  onClick={() => {
                                    onClickHandler(ele.headingid);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                                {ele.menuname}
                              </span>
                            </li>
                            {expandedMenu.includes(ele.headingid) && (
                              <>
                                <li className={classes["list-header"]}>
                                  MenuName
                                </li>
                                {subMenuItems[ele.headingid].map((i, idx) => {
                                  const isLastSubItem =
                                    idx === subMenuItems[index]?.length - 1;
                                  return (
                                    <>
                                      <li
                                        className={`${classes["list-item"]} ${
                                          isLastSubItem
                                            ? classes["list-item-last"]
                                            : ""
                                        }`}
                                        key={idx}
                                      >
                                        <Stack direction="row">
                                          <img
                                            src={
                                              expandedItemId === i.id
                                                ? MinusIcon
                                                : PlusIcon
                                            }
                                            alt="icon"
                                            onClick={() => {
                                              onSubMenuClick(i.id);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          />
                                          <span
                                            className={classes["bodyFont2"]}
                                          >
                                            {i.menuname}
                                          </span>
                                        </Stack>
                                        <span style={{paddingTop:"3px"}}>
                                          <textarea
                                            readOnly
                                            rows="2"
                                            cols="30"
                                            value={i.parentname}
                                            style={{ height: "1.2cm" }}
                                            className={classes["textarea"]}
                                          />

                                          <img
                                            src={window_popup}
                                            alt="Open Popup"
                                            style={{
                                              width: "10px",
                                              height: "10px",
                                              cursor: "pointer",
                                              marginLeft: "2px",
                                            }}
                                            onClick={() => {
                                              handlePopupOpen("menu1", i.id);
                                            }}
                                          />
                                        </span>
                                      </li>
                                      {expandedItemId === i.id && (
                                        <>
                                          <li
                                            className={classes["list-header"]}
                                          >
                                            MenuName
                                          </li>
                                          {subMunu2.length > 0 &&
                                            subMunu2.map((itm, idx2) => {
                                              const isLastSubSubItem =
                                                idx === subMunu2.length - 1;
                                              return (
                                                <>
                                                  <li
                                                    className={`${
                                                      classes["list-item"]
                                                    } ${
                                                      isLastSubSubItem
                                                        ? classes[
                                                            "list-item-last"
                                                          ]
                                                        : ""
                                                    }`}
                                                    // key={itm.id}
                                                  >
                                                    <Stack direction="row">
                                                      <img
                                                        src={
                                                          expandedLast ===
                                                          itm.id
                                                            ? MinusIcon
                                                            : PlusIcon
                                                        }
                                                        alt="icon"
                                                        onClick={() => {
                                                          onSubMenuClick2(
                                                            itm.id
                                                          );
                                                        }}
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                      <span
                                                        className={
                                                          classes["bodyFont2"]
                                                        }
                                                      >
                                                        {itm.menuname}
                                                      </span>
                                                    </Stack>

                                                    <span style={{paddingTop:"3px"}}>
                                                      <textarea
                                                        readOnly
                                                        rows="2"
                                                        cols="30"
                                                        value={itm.parentname}
                                                        className={
                                                          classes["textarea"]
                                                        }
                                                        style={{
                                                          height: "1.2cm",
                                                        }}
                                                      />
                                                      <img
                                                        src={window_popup}
                                                        alt="Open Popup"
                                                        style={{
                                                          width: "10px",
                                                          height: "10px",
                                                          cursor: "pointer",
                                                          marginLeft: "2px",
                                                        }}
                                                        onClick={() => {
                                                          handlePopupOpen(
                                                            "menu2",
                                                            itm.id
                                                          );
                                                        }}
                                                      />
                                                    </span>
                                                  </li>
                                                  {expandedLast === itm.id && (
                                                    <>
                                                      <li
                                                        className={
                                                          classes["list-item"]
                                                        }
                                                        style={{
                                                          minHeight: "1cm",
                                                          color: "red",
                                                          fontSize: "12px",
                                                          fontWeight: "bold",
                                                          paddingLeft: "40%",
                                                        }}
                                                      >
                                                        No Records Found
                                                      </li>
                                                    </>
                                                  )}
                                                </>
                                              );
                                            })}
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </Grid>
                  <Stack
                    direction="row"
                    sx={{
                      marginTop: "1rem",
                      marginLeft: "4rem",
                      marginBottom: "2rem",
                    }}
                    spacing={2}
                  >
                    <button
                      className={classes["btn-normal"]}
                      onClick={SaveHandler}
                    >
                      Save
                    </button>
                    <button
                      className={classes["btn-normal"]}
                      onClick={() => NavigateHandler()}
                    >
                      Cancel
                    </button>
                  </Stack>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* </Grid> */}
      </Grid>
    </>
  );
}
