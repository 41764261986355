

import React, { useEffect, useState } from "react";
import "../../../HelpPopups/HelpPopup.css";
import Stack from "@mui/material/Stack";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import modules from '../../ccpm.module.css'
import { Grid } from "@mui/material";
import bread from "../../../../Assets/bread.png";
import { GetTaskNotes, PendingTaskPopup, SaveTasks, ViewRiskPopup, ViewSuccessorPopup, ViewSuccessorTable, ViewuccessorTable } from "../../../../Services/CCPM";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";

const TaskProcess = () => {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
      const query = useQuery();
      const projectId = query.get("projId");
      const taskId = query.get("taskId");
      const viewOnly = query.get("view");
      console.log(viewOnly,"dwwdwd")
      console.log(projectId,"wce")
      console.log(taskId,"ddcce")
  const [isLoading, setIsLoading] = useState(false)
   const [tableData, setTableData] = useState([])
   const [notes, setNotes] = useState("")
    useEffect(() => {
        setIsLoading(true);
        let body = {
          projectId: projectId,
          taskId: taskId,
        };
        GetTaskNotes(body)
          .then((response) => {
            console.log(response,"qw1")
            setIsLoading(false);

            if (response.status === 200) {
              setTableData(response?.data?.success?.data);
              setNotes(response?.data?.success?.data?.[0]?.tasknotes)
            } else {
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
       
      }, []);

 const handleSubmit = () => {
    const userId = sessionStorage.getItem("loginId");
    const body = {
        projectId: projectId,
        taskId: taskId,
        notes: notes,
        userId: userId
      }
      SaveTasks(body)
    .then((response) => {
      console.log(response,"qw1")
      setIsLoading(false);
      GetTaskNotes(body)
      .then((response) => {
        console.log(response,"qw1")
        setIsLoading(false);
        
        if (response.status === 200) {
          setTableData(response?.data?.success?.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
 }

  return (
    <div>
    <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              paddingLeft: "1rem",
              fontWeight: "bold",
              fontFamily: "Times New Roman",
            }}
          >
            <img src={logo} alt="Godrej" />
          </Stack>
          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <div style={{marginLeft:"5vw",marginTop:"20px"}}>
         <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>View Project Task</a>
                <img src={bread} alt="" />
                <a>Task Process Notes</a>
              </div>
         </div>
         <Stack style={{ padding: "15px" }}>
            { viewOnly === "false" && <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <label
                style={{
                  marginRight: "1rem",
                  width: "10%",
                  fontSize: "larger",
                  fontWeight: "bold",
                }}
              >
                Task Process Notes:
              </label>
              <textarea
                style={{ width: "40%", fontSize: "larger", fontWeight: "" }}
                rows={6}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              >
                Hello
              </textarea>
            </div>}
            { viewOnly === "false" && <div
              style={{
                display: "flex",
                marginLeft: "30%",
                marginTop: "2rem",
              }}
            >
              <button
                style={{ marginRight: "1rem", padding: "15px" }}
                className={modules["btn1_vpm"]}
                onClick={handleSubmit}
              >
                Save
              </button>
              <button
                className={modules["btn1_vpm"]}
                style={{ padding: "15px" }}
                onClick={() => setNotes("")}
              >
                Reset
              </button>
            </div> }
            <Grid
              container
              className={modules[("table-grid_vpm", "table-grid-margin")]}
              style={{ marginLeft: "0"}}
            >
              <Table
                className={[
                  modules["custom-table_vpm"],
                  modules["custom-table-th"],
                ].join(" ")}
               
              >
                <TableHead>
                  <TableRow>
                    <TableCell  >Sr.No.</TableCell>
                    <TableCell  >Project</TableCell>
                    <TableCell  >Task</TableCell>
                    <TableCell  >Task Notes</TableCell>
                    <TableCell  >Created By</TableCell>
                    <TableCell  >Created On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {tableData.map((item,index) => {
                    return (
                    <TableRow className={modules["table-row"]}>
                    <TableCell  >{index + 1}</TableCell>
                    <TableCell  >{item.projectname}</TableCell>
                    <TableCell  >{item.taskname}</TableCell>
                    <TableCell  >
                      <textarea style={{ width: "80%" }} rows={3} value={item.tasknotes}>
                        
                      </textarea>
                    </TableCell>
                    <TableCell  >{item.username}</TableCell>
                    <TableCell  >{item.createdon}</TableCell>
                  </TableRow>
                    )
                })}
                  
                </TableBody>
              </Table>
            </Grid>
          </Stack>

    </div>
  )
}

export default TaskProcess






