import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import Login from "./Login/login";
import ForgotPassword from "./Login/ForgotPassword";
import Navbar from "./Landing/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RoleMaster from "./pages/Admin/RoleMaster";
import UserMaster from "./pages/Admin/UserMaster";
import InstanceMaster from "./pages/CCPM/Masters/InstanceMaster";
import CalenderHistoryLog from "./pages/CCPM/Masters/Calendar/CalendarHistoryLog";

import HelpIndex from "./pages/HelpPopups/HelpIndex";

import InstanceMasterhelpPopup from "./pages/HelpPopups/InstanceMasterhelpPopup";
import RoleMasterhelpPopup from "./pages/HelpPopups/RoleMasterhelpPopup";
import UnitMasterhelpPopup from "./pages/HelpPopups/UnitMasterhelpPopup";
import IssueCategoryhelpPopup from "./pages/HelpPopups/IssueCategoryhelpPopup";
import UserInstanceLinkinghelpPopup from "./pages/HelpPopups/UserInstanceLinkinghelpPopup";
import InstanceAttributeMappinghelpPopup from "./pages/HelpPopups/InstanceAttributeMappinghelpPopup";
import DelayMasterhelpPopup from "./pages/HelpPopups/DelayMasterhelpPopup";
import ActiveProjectStatusChangeReasonhelpPopup from "./pages/HelpPopups/ActiveProjectStatusChangeReasonhelpPopup";
import FeverChartMasterhelpPopup from "./pages/HelpPopups/FeverChartMasterhelpPopup";
import CalendarhelpPopup from "./pages/HelpPopups/CalendarhelpPopup";
import CalendarWorkPopup from "./pages/CCPM/Masters/Calendar/CalendarWorkPopup";
import DepartmentMasterhelpPopup from "./pages/HelpPopups/DepartmentMasterhelpPopup";

import ProjectwisecriticalchainhelpPopup from "./pages/HelpPopups/ProjectwisecriticalchainhelpPopup";
import BMReporthelpPopup from "./pages/HelpPopups/BMReporthelpPopup";
import DailySubTaskFlowTrendhelpPopup from "./pages/HelpPopups/DailySubTaskFlowTrendhelpPopup";
import DailyTaskFlowTrendhelpPopup from "./pages/HelpPopups/DailyTaskFlowTrendhelpPopup";
import LoginAuditReporthelpPopup from "./pages/HelpPopups/LoginAuditReporthelpPopup";
import ProjectCompletionOnTimeReporthelpPopup from "./pages/HelpPopups/ProjectCompletionOnTimeReporthelpPopup";
import ProjectCountWithStatushelpPopup from "./pages/HelpPopups/ProjectCountWithStatushelpPopup";
import DepartmentLoadReporthelpPopup from "./pages/HelpPopups/DepartmentLoadReporthelpPopup";
import PortfoliocharthelpPopup from "./pages/HelpPopups/PortfoliocharthelpPopup";

import IssueReporthelpPopup from "./pages/HelpPopups/IssueReporthelpPopup";
import DelayReportforParetohelpPopup from "./pages/HelpPopups/DelayReportforParetohelpPopup";
import ProjectLeadTimehelpPopup from "./pages/HelpPopups/ProjectLeadTimehelpPopup";
import TaskwiseBufferConsumptionhelpPopup from "./pages/HelpPopups/TaskwiseBufferConsumptionhelpPopup";
import LoadOnTaskManagerhelpPopup from "./pages/HelpPopups/LoadOnTaskManagerhelpPopup";
import ClosedProjectReporthelpPopup from "./pages/HelpPopups/ClosedProjectReporthelpPopup";
import TaskTakingMoreTimehelpPopup from "./pages/HelpPopups/TaskTakingMoreTimehelpPopup";
import PlannedVsActualhelpPopup from "./pages/HelpPopups/PlannedVsActualhelpPopup";
import PortfolioChartReporthelpPopup from "./pages/HelpPopups/PortfolioChartReporthelpPopup";

import RiskActionOwnerhelpPopup from "./pages/HelpPopups/RiskActionOwnerhelpPopup";
import RiskAndMitigationPlanhelpPopup from "./pages/HelpPopups/RiskAndMitigationPlanhelpPopup";
import RiskMonitorreporthelpPopup from "./pages/HelpPopups/RiskMonitorreporthelpPopup";
import RiskReviewhelpPopup from "./pages/HelpPopups/RiskReviewhelpPopup";

import BufferRecoveryhelpPopup from "./pages/HelpPopups/BufferRecoveryhelpPopup";

import TaskManagementModulehelpPopup from "./pages/HelpPopups/TaskManagementModulehelpPopup";

import NewInstancehelpPopup from "./pages/DSS/HelpPopups/NewInstancehelpPopup";
import InstanceActiveInActivehelpPopup from "./pages/DSS/HelpPopups/InstanceActiveInActivehelpPopup";

import MTANewInstancehelpPopup from "./pages/MTA/HelpPopups/MTANewInstancehelpPopup";
import MTAInstanceActivehelpPopup from "./pages/MTA/HelpPopups/MTAInstanceActivehelpPopup";
import MTAUserInstanceLinkinghelpPopup from "./pages/MTA/HelpPopups/MTAUserInstanceLinkinghelpPopup";
import StockPointTypehelpPopup from "./pages/MTA/HelpPopups/StockPointTypehelpPopup";
import StockPointPopup from "./pages/MTA/MTAParam/StockPointPopup";
import CompanyCodePopup from "./pages/MTA/MTAParam/CompanyCodePopup";
import CompanyCodePopup2 from "./pages/MTA/MTAParam/CompanyCodePopup2";
import ItemCodePopup from "./pages/MTA/MTAParam/ItemCodePopup";
import InTransitPopup from "./pages/MTA/MTAParam/InTransitPopup";
import WarehouseCodePopup from "./pages/MTA/MTAParam/WarehouseCodePopup";
import SSGpopup from "./pages/MTA/MTAParam/SSGpopup";
import ItemCategoryPopup from "./pages/MTA/MTAParam/ItemCategoryPopup";
import DBMdata from "./pages/MTA/MTAParam/DBMdata";
import InboundOrderAdviceData from "./pages/MTA/MTAParam/InboundOrderAdviceData";
import MTA_TOC_Item_Stock_Graph from "./pages/MTA/MTAParam/MTA_TOC_Item_Stock_Graph ";
import UserStockPointLinkinghelpPopup from "./pages/MTA/HelpPopups/UserStockPointLinkinghelpPopup";
import StockPointhelpPopup from "./pages/MTA/HelpPopups/StockPointhelpPopup";
import SettingsUploadhelpPopup from "./pages/MTA/HelpPopups/SettingsUploadhelpPopup";
import StockPointConfighelpPopup from "./pages/MTA/HelpPopups/StockPointConfighelpPopup";
import SettingsDownloadhelpPopup from "./pages/MTA/HelpPopups/SettingsDownloadhelpPopup";
import ControlhelpPopup from "./pages/MTA/HelpPopups/ControlhelpPopup";
import AdminControlhelpPopup from "./pages/MTA/HelpPopups/AdminControlhelpPopup";
import StockPointWarehousehelpPopup from "./pages/MTA/HelpPopups/StockPointWarehousehelpPopup";
import DBMhelpPopup from "./pages/MTA/HelpPopups/DBMhelpPopup";
import StockPointWarehouseConsumptionhelpPopup from "./pages/MTA/HelpPopups/StockPointWarehouseConsumptionhelpPopup";
import InboundOrderAdvicehelpPopup from "./pages/MTA/HelpPopups/InboundOrderAdvicehelpPopup";
import StockPointBufferUploadhelpPopup from "./pages/MTA/HelpPopups/StockPointBufferUploadhelpPopup";
import StockPointBufferDownloadhelpPopup from "./pages/MTA/HelpPopups/StockPointBufferDownloadhelpPopup";
import StockPointWarehouseInTransithelpPopup from "./pages/MTA/HelpPopups/StockPointWarehouseInTransithelpPopup";
import StockPointItemConfigurationIncrementalhelpPopup from "./pages/MTA/HelpPopups/StockPointItemConfigurationIncrementalhelpPopup";
import StockPointProductClassConfigurationIncrementalhelpPopup from "./pages/MTA/HelpPopups/StockPointProductClassConfigurationIncrementalhelpPopup";
import StockPointSalesStatisticalGroupConfigurationhelpPopup from "./pages/MTA/HelpPopups/StockPointSalesStatisticalGroupConfigurationhelpPopup";
import StockPointItemSupplierConfigurationhelpPopup from "./pages/MTA/HelpPopups/StockPointItemSupplierConfigurationhelpPopup";
import StockPointSourceDestinationWarehouseMappinghelpPopup from "./pages/MTA/HelpPopups/StockPointSourceDestinationWarehouseMappinghelpPopup";
import StockPointItemPhaseInPhaseOutSeasonalityhelpPopup from "./pages/MTA/HelpPopups/StockPointItemPhaseInPhaseOutSeasonalityhelpPopup";
import ProcessingAutomationhelpPopup from "./pages/MTA/HelpPopups/ProcessingAutomationhelpPopup";

import FeverChartMaster from "./pages/CCPM/Masters/FeverChartMaster";
import IssueCategory from "./pages/CCPM/Masters/IssueCategory";
import DepartmentMaster from "./pages/CCPM/Masters/DepartmentMaster";
import ProjectTaskMgmtEmailConfig from "./pages/CCPM/Masters/ProjectTaskMgmtEmailConfig";
import SettingPercentage from "./pages/CCPM/Masters/SettingPercentage";
import ProductConfig from "./pages/CCPM/Masters/ProductConfig";
import DelayMaster from "./pages/CCPM/Masters/DelayMaster";

import TaskMgmtConfig from "./pages/CCPM/Masters/TaskMgmtConfig";
import RiskTypeMaster from "./pages/CCPM/Masters/RiskTypeMaster";
import UnitMaster from "./pages/CCPM/Masters/UnitMaster";
import ProcurementCategory from "./pages/CCPM/Masters/ProcurementCategory";
import BufferScheduler from "./pages/CCPM/Masters/BufferScheduler";

import Home from "./home/Home";
import ChangePassword from "./pages/Admin/ChangePassword";
import UserInstanceLinking from "./pages/DSS/Admin/UserInstanceLinking";
import NewInstance from "./pages/DSS/Admin/NewInstance";
import InstanceActiveInActive from "./pages/DSS/Admin/InstanceActiveInActive";
import Settings from "./pages/DSS/Admin/Settings";
import InstanceWiseJobScheduling from "./pages/DSS/Admin/InstanceWiseJobScheduling";
// import InstanceActive from "./pages/DSS/Admin/InstanceActive";

import RolePermission from "./pages/Admin/RolePermission";

import AssignParentRole from "./pages/Admin/AssignParentRole";
import AssignParentPopUp from "./pages/Admin/AssignParentPopUp";
import ParentRolePermission from "./pages/Admin/ParentRolePermission";
import ParentRolePopUp from "./pages/Admin/ParentRolePopUp";
import DataSource from "./pages/DSS/Masters/DataSource";
import Hierarchies from "./pages/DSS/Masters/Hierarchies";
import MasterDefinition from "./pages/DSS/Masters/MasterDefiniton";
import MasterLinking from "./pages/DSS/Masters/MasterLinking";
import SampleSidebar from "./Landing/SampleSidebar";
import ProcessMAUCTrackerReport from "./pages/DSS/MAUC/ProcessMAUCTrackerReport";
import MAUCReportTracker from "./pages/DSS/MAUC/MAUCReportTracker";
import MaterialThreshold from "./pages/DSS/MAUC/MAUCMaterialThreshold";
import MaintainFactor from "./pages/DSS/TRDLoss/TRDMaintainFactor";
import CalculateThroughput from "./pages/DSS/Throughput/CalculateThroughput";
import CalculateTRDLoss from "./pages/DSS/TRDLoss/CalculateTRDLoss";
import LossReportSummary from "./pages/DSS/Reports/TRDLossReportSummary";
import TRDLossSummaryTable from "./pages/DSS/Reports/TRDLossSummaryTable";
import WIPLimitMaster from "./pages/CCPM/Masters/WIPLimitMaster";
import ActiveProjectStatusChange from "./pages/CCPM/Masters/ActiveProjectStatusChange";
import InstanceDivisionDepartment from "./pages/CCPM/Masters/InstanceDivisionDepartment";
import MyProjects from "./pages/CCPM/ProjectPlan/MyProjects";
import ChangeProjectManager from "./pages/CCPM/ProjectPlan/ChangeProjectManager";
import ViewProjects from "./pages/CCPM/ProjectPlan/ViewProjects";
import ViewMyProjects from "./pages/CCPM/ProjectPlan/ViewMyProjects";
import CreateNewPlan from "./pages/CCPM/ProjectPlan/CreateNewPlan";
import AlternateProjectManager from "./pages/CCPM/ProjectPlan/AlternateProjectManager";
import IssueList from "./pages/CCPM/TaskManagement/IssueList";
import TaskManagement from "./pages/CCPM/TaskManagement/TaskManagement";
import ViewTasks from "./pages/CCPM/TaskManagement/ViewTasks";
import RaiseIssue from "./pages/CCPM/TaskManagement/popups/RaiseIssue";
import AddAssignIssue from "./pages/CCPM/TaskManagement/popups/AddAssignIssue";
import DurationHistory from "./pages/CCPM/TaskManagement/popups/DurationHistory";
import TaskProcess from "./pages/CCPM/TaskManagement/popups/TaskProcess";

import RiskActionOwner from "./pages/CCPM/RiskManagement/RiskActionOwner";
import RiskAndMitigationPlan from "./pages/CCPM/RiskManagement/RiskAndMitigationPlan";
import RiskAndMitigationPlanPop from "./pages/CCPM/RiskManagement/RiskAndMitigationPlanPop";
import RiskMonitorreport from "./pages/CCPM/RiskManagement/RiskMonitorreport";
// import RiskReview from "./pages/CCPM/RiskManagement/RiskReview";
// import RiskActionOwner from "./pages/CCPM/RiskManagement/RiskActionOwner";
import ClosedProjectReport from "./pages/CCPM/Reports/ClosedProjectReport";
import DailySubTaskFlowTrend from "./pages/CCPM/Reports/DailySubTaskFlowTrend";
import DailyTaskFlowTrend from "./pages/CCPM/Reports/DailyTaskFlowTrend";
import LoginAuditReport from "./pages/CCPM/Reports/LoginAuditReport";
import PortfolioChartReport from "./pages/CCPM/Reports/PortfolioChartReport";
import ProjectCompletiononTimeReport from "./pages/CCPM/Reports/ProjectCompletiononTimeReport";
import ProjectCountWithStatus from "./pages/CCPM/Reports/ProjectCountWithStatus";
import TaskTakingMoreTime from "./pages/CCPM/Reports/TaskTakingMoreTime";
import MTANewInstance from "./pages/MTA/MTAParam/MTANewInstance";
import MTAInstanceActive from "./pages/MTA/MTAParam/MTAInstanceActive";
import MTAUserInstanceLinking from "./pages/MTA/MTAParam/MTAUserInstanceLinking";
import StockPointType from "./pages/MTA/MTAParam/StockPointType";
import UserStockPointLinking from "./pages/MTA/MTAParam/UserStockPointLinking";

import CustomerMaster from "./pages/CCPM/Masters/CustomerMaster";
import UserInstaceLinking from "./pages/CCPM/Masters/UserInstanceLinking";
import CalendarMaster from "./pages/CCPM/Masters/Calendar/Calendar";
import AddRulePopup from "./pages/CCPM/Masters/Calendar/AddRulePopup";
import AttributeMaster from "./pages/CCPM/Masters/AttributeMaster";
import InstanceAttributeMapping from "./pages/CCPM/Masters/InstanceAttributeMapping";
import ActiveProjects from "./pages/CCPM/ActiveProjects/ActiveProjects";
import BufferRecovery from "./pages/CCPM/ActiveProjects/BufferRecovery";
import FeverChart from "./pages/CCPM/ActiveProjects/FeverChartFolder/feverchart";
import ModifyTaskAttribute from "./pages/CCPM/ActiveProjects/ModifyTaskAttribute";
import ModifySubTaskAttribute from "./pages/CCPM/ActiveProjects/ModifySubTaskAttribute";
import CompleteProjects from "./pages/CCPM/ActiveProjects/CompleteProjects";
import ProjectClosureForm from "./pages/CCPM/ActiveProjects/ProjectClosureForm";
import AlternateTaskManager from "./pages/CCPM/ProjectPlan/AlternateTaskManager";
import RiskReview from "./pages/CCPM/RiskManagement/RiskReview";
import RiskMonitorReport from "./pages/CCPM/RiskManagement/RiskMonitorreport";
import IssueReport from "./pages/CCPM/Reports/IssueReport";
import DepartmentLoadReport from "./pages/CCPM/Reports/DepartmentLoadReport";
import PlannedVsActual from "./pages/CCPM/Reports/PlannedVsActual";
import DelayReportforPareto from "./pages/CCPM/Reports/DelayReportforPareto";
import Projectwisecriticalchain from "./pages/CCPM/Reports/Projectwisecriticalchain";
import Portfoliochart from "./pages/CCPM/Reports/Portfoliochart";
import ProjectLeadTime from "./pages/CCPM/Reports/ProjectLeadTime";
import TaskwiseBufferConsumption from "./pages/CCPM/Reports/TaskwiseBufferConsumption";
import LoadOnTaskManager from "./pages/CCPM/Reports/LoadOnTaskManager";
import ProjectScheduleCurrentdate from "./pages/CCPM/Reports/ProjectScheduleCurrentdate";
import BMReport from "./pages/CCPM/Reports/BMReport";
import StockPoint from "./pages/MTA/MTAParam/StockPoint";
import SettingsUpload from "./pages/MTA/MTAParam/SettingsUpload";
import SettingsDownload from "./pages/MTA/MTAParam/SettingsDownload";
import StockPointConfig from "./pages/MTA/MTAParam/StockPointConfig";
import AdminControl from "./pages/MTA/MTAParam/AdminControl";
import StockPointWarehouse from "./pages/MTA/MTAParam/StockPointWarehouse";
import DBM from "./pages/MTA/MTAParam/DBM";
import StockPointWarehouseConsumption from "./pages/MTA/MTAParam/StockPointWarehouseConsumption";
import InboundOrderAdvice from "./pages/MTA/MTAParam/InboundOrderAdvice";
import StockPointBufferUpload from "./pages/MTA/MTAParam/StockPointBufferUpload";
import StockPointBufferDownload from "./pages/MTA/MTAParam/StockPointBufferDownload";
import StockPointWarehouseInTransit from "./pages/MTA/MTAParam/StockPointWarehouseInTransit";
import StockPointItemConfigurationIncremental from "./pages/MTA/MTAParam/StockPointItemConfigurationIncremental";
import StockPointProductClassConfigurationIncremental from "./pages/MTA/MTAParam/StockPointProductClassConfigurationIncremental";
import Control from "./pages/MTA/MTAParam/Control";
import StockPointSalesStatisticalGroupConfiguration from "./pages/MTA/MTAParam/StockPointSalesStatisticalGroupConfiguration";
import StockPointItemSupplierConfiguration from "./pages/MTA/MTAParam/StockPointItemSupplierConfiguration";
import StockPointSourceDestinationWarehouseMapping from "./pages/MTA/MTAParam/StockPointSourceDestinationWarehouseMapping";
import StockPointItemPhaseInPhaseOutSeasonality from "./pages/MTA/MTAParam/StockPointItemPhaseInPhaseOutSeasonality";
import ProcessingAutomation from "./pages/MTA/MTAParam/ProcessingAutomation";
import DBMSuspension from "./pages/MTA/MTAParam/DBMSuspension";
import DBMSuspensionhelpPopup from "./pages/MTA/HelpPopups/DBMSuspensionhelpPopup";

import MasterUpload from "./pages/MTA/MTAMaster/MasterUpload";
import InstanceDivisionDepartmentLinking from "./pages/MTA/MTAMaster/InstanceDivisionDepartmentLinking";
import MTATransactionUpload from "./pages/MTA/MTATransaction/MTATransactionUpload";
import MTATransactionDownload from "./pages/MTA/MTATransaction/MTATransactionDownload";

import BufferPenetrationReport from "./pages/MTA/MTAReports/BufferPenetrationReport";
import PendingPurchaseOrderColorStatusReport from "./pages/MTA/MTAReports/PendingPurchaseOrderColorStatusReport";
import TOCColorStatusLogReport from "./pages/MTA/MTAReports/TOCColorStatusLogReport";
import DailyBufferTrendReport from "./pages/MTA/MTAReports/DailyBufferTrendReport";
import Reports from "./pages/MTA/MTAReports/Reports";
import ItemskeptasMTAReport from "./pages/MTA/MTAReports/ItemskeptasMTAReport";
import MTAfILLRateReport from "./pages/MTA/MTAReports/MTAfILLRateReport";
import PendingProductionOrderColorStatusReport from "./pages/MTA/MTAReports/PendingProductionOrderColorStatusReport";
import SpikePatternAnalysisReport from "./pages/MTA/MTAReports/SpikePatternAnalysisReport";
import TOCItemStockGraph from "./pages/MTA/MTAReports/TOCItemStockGraph";
import PendingProductionOrderOperationColorStatusReport from "./pages/MTA/MTAReports/PendingProductionOrderOperationColorStatusReport";
import WorkCenterSummaryReport from "./pages/MTA/MTAReports/WorkCenterSummaryReport";
import MTDInventoryTransactionReport from "./pages/MTA/MTAReports/MTDInventoryTransactionReport";
import StockAvailabilityReportforTopSKUsReport from "./pages/MTA/MTAReports/StockAvailabilityReportforTopSKUsReport";
import PendingSalesOrderhavingSpikeOrderQty from "./pages/MTA/MTAReports/PendingSalesOrderhavingSpikeOrderQty";
import InventoryValuationReport from "./pages/MTA/MTAReports/InventoryValuationReport";
import InoundOrderStatusReport from "./pages/MTA/MTAReports/InoundOrderStatusReport";
import SessionHistoryReport from "./pages/MTA/MTAReports/SessionHistoryReport";

import TransactionUpload from "./pages/MTO/MTOTransaction/TransactionUpload";
import TransactionDownload from "./pages/MTO/MTOTransaction/TransactionDownload";
import OrderBookingForm from "./pages/MTO/MTOTransaction/OrderBookingForm";
import FGOrderBookingFormException from "./pages/MTO/MTOTransaction/FGOrderBookingFormException";
import NOCCRProject from "./pages/MTO/MTOTransaction/NOCCRProject";
import NOCCRProjectDetails from "./pages/MTO/MTOTransaction/NOCCRProjectDetails";
import DepartmentClearance from "./pages/MTO/MTOTransaction/DepartmentClearance";
import OverallBMReport from "./pages/MTO/MTOTransaction/OverallBMReport";
import DispatchClearance from "./pages/MTO/MTOTransaction/DispatchClearance";
import FullkitPooExecution from "./pages/MTO/MTOTransaction/FullkitPooExecution";
import MonthlyBillingPlan from "./pages/MTO/MTOTransaction/MonthlyBillingPlan";
import LogisticsPlanning from "./pages/MTO/MTOTransaction/LogisticsPlanning";

import Processing from "./pages/MTO/MTOProcess/Processing";
import OPOSReleaseControl from "./pages/MTO/MTOProcess/OPOSReleaseControl";

import DepartmentClearanceReport from "./pages/MTO/MTOReports/DepartmentClearanceReport";
import ProductionOTIFReports from "./pages/MTO/MTOReports/ProductionOTIFReports";
import SalesOTIFReports from "./pages/MTO/MTOReports/SalesOTIFReports";
import OTIFDueDatePerformanceReports from "./pages/MTO/MTOReports/OTIFDueDatePerformanceReports";
import BMandFutureOrderReport from "./pages/MTO/MTOReports/BMandFutureOrderReport";
import DepartmentClearanceHistoryReport from "./pages/MTO/MTOReports/DepartmentClearanceHistoryReport";
import DepartmentClearanceAggregateReport from "./pages/MTO/MTOReports/DepartmentClearanceAggregateReport";
import FullKitPoStatusReport from "./pages/MTO/MTOReports/FullKitPoStatusReport";
import MTOSessionHistoryReport from "./pages/MTO/MTOReports/MTOSessionHistoryReport";

import ScheduleBufferManagementRun from "./pages/HelpPopups/ScheduleBufferManagementRunPopup";
import RiskTypeMasterhelpPopup from "./pages/HelpPopups/RiskTypeMasterHelpPopup";
import ProcurementhelpPopup from "./pages/HelpPopups/ProcrumentCatHelpPopup";
import ProductConfighelpPopup from "./pages/HelpPopups/ProductConfighelpPopup";
import InstanceAttributeValuMaster from "./pages/HelpPopups/InstanceAttributeValueMasterPopup";
import CustomerMasterhelpPopup from "./pages/HelpPopups/CustomerMasterhelpPopup";
import TaskManagementhelpPopup from "./pages/HelpPopups/TaskManagementhelpPopup";
import WIPLimitMasterhelpPopup from "./pages/HelpPopups/WIPLimitMasterhelpPopup";
import CCPMSettingParameterPopup from "./pages/HelpPopups/CCPMSettingParameterPopup";
import UserMasterMapping from "./pages/Admin/userMasterMapping";
import UserMasterhelpPopup from "./pages/HelpPopups/UserMasterhelpPopup";
import RolePermissionhelpPopup from "./pages/HelpPopups/RolePermissionhelpPopup";
import PasswordhelpPopup from "./pages/HelpPopups/PasswordhelpPopup";
import AssignParentRolehelpPopup from "./pages/HelpPopups/AssignParentRolehelpPopup";
import CompleteProjectshelpPopup from "./pages/HelpPopups/CompleteProjectshelpPopup";
import IssueListhelpPopup from "./pages/HelpPopups/IssueListhelpPopup";
import { MenuProvider } from "../src/Landing/MenuContext";
import ProjectTaskMgmtEmailConfigHelpPopup from "./pages/HelpPopups/ProjectTaskMgmtEmailConfigPopup";
import ProjectScheduleCurrentdatehelpPopup from "./pages/HelpPopups/ProjectScheduleCurrentdatehelpPopup";
import ProjectTasks from "./pages/CCPM/ProjectPlan/Popups/ProjectTasks";
import ViewHistory from "./pages/CCPM/ProjectPlan/Popups/ViewHistory";
import ProjectTasksHelpPopup from "./pages/CCPM/ProjectPlan/Popups/CCPMSettingsPopup";
import MyProjectsHelpPopup from "./pages/HelpPopups/MyProjectsHelpPopup";
import PlannedBillingProjection from "./pages/CCPM/ProjectPlan/Popups/PlannedBillingProjection";
import Staggering from "./pages/CCPM/ProjectPlan/Popups/Staggering";
import PlannedBillingProjectionView from "./pages/CCPM/ProjectPlan/ViewPopups/PlannedBillingProjection";
import RiskHistory from "./pages/CCPM/RiskManagement/RiskHistory";
import EventPopup from "./pages/CCPM/ProjectPlan/ViewPopups/EventPopup";
import PendingTask from "./pages/CCPM/ProjectPlan/ViewPopups/PendingTask";
import PendingTask2 from "./pages/CCPM/ProjectPlan/ViewPopups/PendingTask2";
import RiskPopup from "./pages/CCPM/ProjectPlan/ViewPopups/RiskPopup";
import ViewIssuePopup from "./pages/CCPM/ProjectPlan/ViewPopups/ViewIssuePopup";
import ViewBufferRecovery from "./pages/CCPM/ProjectPlan/ViewPopups/ViewBufferRecovery";
import ViewProjProp from "./pages/CCPM/ProjectPlan/ViewPopups/ViewProjProp";
import ProjectProp from "./pages/CCPM/ProjectPlan/Popups/ProjectProperties";
import AddEditTaskError from "./pages/CCPM/ProjectPlan/Popups/AddEditTaskError";
import ViewProjectsHelpPopup from "./pages/HelpPopups/ViewProjectsHelpPopup";
import SubTasks from "./pages/CCPM/ProjectPlan/Popups/SubTasksAll";
import SubTasksAllPending from "./pages/CCPM/ProjectPlan/Popups/SubTaskAllPending";
import AlternatePMhelpPopup from "./pages/HelpPopups/AlternatePMhelpPopup";
import AlternateTMhelpPopup from "./pages/HelpPopups/AlternateTMhelpPopup";
import ChangeProjectManagerhelpPopup from "./pages/HelpPopups/ChangeProjectManagerhelpPopup";
import ModifySubTaskAttributehelpPopup from "./pages/HelpPopups/ModifySubTaskAttributehelpPopup";
import PlannedBillingProjectionhelpPopup from "./pages/HelpPopups/PlannedBillingProjectionhelpPopup";
 import ModifyTaskAttributehelpPopup from "./pages/HelpPopups/ModifyTaskAttributehelpPopup";
import CreateNewhelpPopup from "./pages/HelpPopups/CreateNewhelpPopup";
import GanttChart from "./pages/CCPM/ActiveProjects/GanttChart/GanttChart";
import NetworkChart from "./pages/CCPM/ActiveProjects/NetworkChart/networkchart";
import Predecessor from "./pages/CCPM/TaskManagement/popups/Predecessor";
import Successor from "./pages/CCPM/TaskManagement/popups/Successor";

function App() {
  const timeoutRef = useRef(null);
  const LOGOUT_TIME = 2 * 60 * 1000;

  const resetTimer = () => {
    if (window.location.pathname === "/") {
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/";
    }, LOGOUT_TIME);
  };

  // useEffect(() => {
  //   if (window.location.pathname !== "/") {
  //     window.addEventListener("mousemove", resetTimer);
  //     window.addEventListener("keydown", resetTimer);
  //     window.addEventListener("click", resetTimer);
  //     resetTimer();
  //   }

  //   return () => {
  //     clearTimeout(timeoutRef.current);
  //     window.removeEventListener("mousemove", resetTimer);
  //     window.removeEventListener("keydown", resetTimer);
  //     window.removeEventListener("click", resetTimer);
  //   };
  // }, []);

  return (
    <MenuProvider>
      <Router>
        {/* <div className="App"> */}
        <Routes>
          {/* <Login/> */}
          {/* <Landing/> */}
          <Route exact path="/" element={<Login />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/admin/rolemaster" element={<RoleMaster />} />
          <Route exact path="/admin/usermaster" element={<UserMaster />} />
          <Route
            exact
            path="/ccpm/CustomerMaster"
            element={<CustomerMaster />}
          />

          <Route
            exact
            path="/ccpm/UserInstaceLinking"
            element={<UserInstaceLinking />}
          />
          <Route exact path="/ccpm/Calendar" element={<CalendarMaster />} />
          <Route
            exact
            path="/ccpm/Calendar/WorkingNonworkingHrsPopup"
            element={<CalendarWorkPopup />}
          />
          <Route exact path="/ccpm/AddRule" element={<AddRulePopup />} />
          <Route
            exact
            path="/ccpm/ApplyCCPMPopup"
            element={<ProjectTasksHelpPopup />}
          />
          <Route exact path="/ccpm/Staggering" element={<Staggering />} />
          <Route
            exact
            path="/ccpm/PlannedBillingProjection"
            element={<PlannedBillingProjection />}
          />
          <Route
            exact
            path="/ccpm/InstanceAttributeMapping"
            element={<InstanceAttributeMapping />}
          />
          <Route
            exact
            path="/ccpm/AttributeMaster"
            element={<AttributeMaster />}
          />
          <Route
            exact
            path="/ccpm/FeverChartMaster"
            element={<FeverChartMaster />}
          />
          <Route
          exact
          path="/ccpm/activeproject/FeverChart"
          element={<FeverChart/>}/>

          <Route exact path="/ccpm/DelayMaster" element={<DelayMaster />} />
          <Route
            exact
            path="/ccpm/TaskMgmtConfig"
            element={<TaskMgmtConfig />}
          />
          <Route
            exact
            path="/ccpm/ProcurementCategory"
            element={<ProcurementCategory />}
          />

          <Route exact path="/ccpm/IssueCategory" element={<IssueCategory />} />
          <Route
            exact
            path="/ccpm/DepartmentMaster"
            element={<DepartmentMaster />}
          />
          <Route
            exact
            path="/ccpm/ProjectTaskMgmtEmailConfig"
            element={<ProjectTaskMgmtEmailConfig />}
          />
          <Route
            exact
            path="/ccpm/instancemaster"
            element={<InstanceMaster />}
          />

          <Route
            exact
            path="/ccpm/CalendarHistoryLog"
            element={<CalenderHistoryLog />}
          />

          <Route exact path="/HelpIndex" element={<HelpIndex />} />
          <Route
            exact
            path="/ccpm/InstanceMasterhelpPopup"
            element={<InstanceMasterhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/RoleMasterhelpPopup"
            element={<RoleMasterhelpPopup />}
          />
          <Route
            exact
            path="ccpm/BufferRecoveryhelpPopup"
            element={<BufferRecoveryhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/IssueCategoryhelpPopup"
            element={<IssueCategoryhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/UnitMasterhelpPopup"
            element={<UnitMasterhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/UserInstanceLinkinghelpPopup"
            element={<UserInstanceLinkinghelpPopup />}
          />
          <Route
            exact
            path="/ccpm/InstanceAttributeMappinghelpPopup"
            element={<InstanceAttributeMappinghelpPopup />}
          />
          <Route
            exact
            path="/ccpm/DelayMasterhelpPopup"
            element={<DelayMasterhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/ActiveProjectStatusChangeReasonhelpPopup"
            element={<ActiveProjectStatusChangeReasonhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/FeverChartMasterhelpPopup"
            element={<FeverChartMasterhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/DepartmentMasterhelpPopup"
            element={<DepartmentMasterhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/RiskActionOwnerhelpPopup"
            element={<RiskActionOwnerhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/RiskAndMitigationPlanhelpPopup"
            element={<RiskAndMitigationPlanhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/RiskMonitorreporthelpPopup"
            element={<RiskMonitorreporthelpPopup />}
          />

          <Route
            exact
            path="/ccpm/RiskReviewhelpPopup"
            element={<RiskReviewhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/TaskManagementModulehelpPopup"
            element={<TaskManagementModulehelpPopup />}
          />

          <Route
            exact
            path="/ccpm/NewInstancehelpPopup"
            element={<NewInstancehelpPopup />}
          />
          <Route
            exact
            path="/ccpm/InstanceActiveInActivehelpPopup"
            element={<InstanceActiveInActivehelpPopup />}
          />
          <Route
            exact
            path="/ccpm/StockPointTypehelpPopup"
            element={<StockPointTypehelpPopup />}
          />
          <Route
            exact
            path="/ccpm/StockPointPopup"
            element={<StockPointPopup />}
          />
          <Route
            exact
            path="/ccpm/CompanyCodePopup"
            element={<CompanyCodePopup />}
          />
          <Route
            exact
            path="/ccpm/CompanyCodePopup2"
            element={<CompanyCodePopup2 />}
          />
          <Route exact path="/ccpm/ItemCodePopup" element={<ItemCodePopup />} />
          <Route
            exact
            path="/ccpm/WarehouseCodePopup"
            element={<WarehouseCodePopup />}
          />
          <Route exact path="/ccpm/SSGpopup" element={<SSGpopup />} />
          <Route
            exact
            path="/ccpm/ItemCategoryPopup"
            element={<ItemCategoryPopup />}
          />
          <Route
            exact
            path="/ccpm/InTransitPopup"
            element={<InTransitPopup />}
          />
          <Route exact path="/ccpm/DBMdata" element={<DBMdata />} />
          <Route
            exact
            path="/ccpm/InboundOrderAdviceData"
            element={<InboundOrderAdviceData />}
          />
          <Route
            exact
            path="/MTA/MTA_TOC_Item_Stock_Graph"
            element={<MTA_TOC_Item_Stock_Graph />}
          />
          <Route
            exact
            path="/ccpm/UserStockPointLinkinghelpPopup"
            element={<UserStockPointLinkinghelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointhelpPopup"
            element={<StockPointhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/SettingsUploadhelpPopup"
            element={<SettingsUploadhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointWarehouseConsumptionhelpPopup"
            element={<StockPointWarehouseConsumptionhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointItemSupplierConfigurationhelpPopup"
            element={<StockPointItemSupplierConfigurationhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointItemPhaseInPhaseOutSeasonalityhelpPopup"
            element={<StockPointItemPhaseInPhaseOutSeasonalityhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/ProcessingAutomationhelpPopup"
            element={<ProcessingAutomationhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointSourceDestinationWarehouseMappinghelpPopup"
            element={<StockPointSourceDestinationWarehouseMappinghelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointProductClassConfigurationIncrementalhelpPopup"
            element={<StockPointProductClassConfigurationIncrementalhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointSalesStatisticalGroupConfigurationhelpPopup"
            element={<StockPointSalesStatisticalGroupConfigurationhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointItemConfigurationIncrementalhelpPopup"
            element={<StockPointItemConfigurationIncrementalhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointWarehouseInTransithelpPopup"
            element={<StockPointWarehouseInTransithelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointBufferDownloadhelpPopup"
            element={<StockPointBufferDownloadhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/InboundOrderAdvicehelpPopup"
            element={<InboundOrderAdvicehelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointBufferUploadhelpPopup"
            element={<StockPointBufferUploadhelpPopup />}
          />

          <Route exact path="/ccpm/DBMhelpPopup" element={<DBMhelpPopup />} />

          <Route
            exact
            path="/ccpm/AdminControlhelpPopup"
            element={<AdminControlhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointWarehousehelpPopup"
            element={<StockPointWarehousehelpPopup />}
          />

          <Route
            exact
            path="/ccpm/SettingsDownloadhelpPopup"
            element={<SettingsDownloadhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/ControlhelpPopup"
            element={<ControlhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/StockPointConfighelpPopup"
            element={<StockPointConfighelpPopup />}
          />

          <Route
            exact
            path="/ccpm/MTANewInstancehelpPopup"
            element={<MTANewInstancehelpPopup />}
          />
          <Route
            exact
            path="/ccpm/MTAInstanceActivehelpPopup"
            element={<MTAInstanceActivehelpPopup />}
          />
          <Route
            exact
            path="/ccpm/MTAUserInstanceLinkinghelpPopup"
            element={<MTAUserInstanceLinkinghelpPopup />}
          />

          <Route
            exact
            path="/ccpm/WIPLimitmaster"
            element={<WIPLimitMaster />}
          />
          <Route
            exact
            path="/ccpm/activeprojectstatuschange"
            element={<ActiveProjectStatusChange />}
          />
          <Route
            exact
            path="/ccpm/instancedivisiondepartment"
            element={<InstanceDivisionDepartment />}
          />
          <Route
            exact
            path="/ccpm/ccpmconfigmaster"
            element={<SettingPercentage />}
          />
          <Route exact path="/ccpm/productconfig" element={<ProductConfig />} />
          <Route exact path="/ccpm/myprojects" element={<MyProjects />} />
          <Route
            exact
            path="/ccpm/changeprojectmanager"
            element={<ChangeProjectManager />}
          />
          <Route exact path="/ccpm/viewprojects" element={<ViewProjects />} />
          <Route exact path="/ccpm/viewmyprojects" element={<ViewMyProjects />} />
          <Route exact path="/ccpm/createnewplan" element={<CreateNewPlan />} />
          <Route
            exact
            path="/ccpm/alternateprojectmanager"
            element={<AlternateProjectManager />}
          />
          <Route exact path="/ccpm/issuelist" element={<IssueList />} />
          <Route
            exact
            path="/ccpm/taskmanagement"
            element={<TaskManagement />}
          />
      

          <Route exact path="/ccpm/viewtasks" element={<ViewTasks />} />
          <Route exact path="/ccpm/raiseissue" element={<RaiseIssue/>} />
          <Route exact path="/ccpm/addanother-issue" element={<AddAssignIssue/>} />
          <Route exact path="/ccpm/usernotes" element={<TaskProcess />} />
          <Route exact path="/ccpm/ViewDurationProjectsHistory" element={<DurationHistory />} />

          <Route
            exact
            path="/ccpm/riskandmitigationplan"
            element={<RiskAndMitigationPlan />}
          />
          <Route
            exact
            path="/ccpm/riskandmitigationplanPopup"
            element={<RiskAndMitigationPlanPop />}
          />
          <Route
            exact
            path="/ccpm/RiskActionOwner"
            element={<RiskActionOwner />}
          />
          {/* <Route exact path="/ccpm/RiskReview" element={<RiskReview />} /> */}
          <Route
            exact
            path="/ccpm/RiskMonitorreport"
            element={<RiskMonitorreport />}
          />

          <Route
            exact
            path="/ccpm/closedprojectreport"
            element={<ClosedProjectReport />}
          />
          <Route
            exact
            path="/ccpm/dailysubtaskflowtrend"
            element={<DailySubTaskFlowTrend />}
          />
          <Route
            exact
            path="/ccpm/dailytaskflowtrend"
            element={<DailyTaskFlowTrend />}
          />
          <Route
            exact
            path="/ccpm/loginauditreport"
            element={<LoginAuditReport />}
          />
          <Route
            exact
            path="/ccpm/ProjectCompletionOnTimeReporthelpPopup"
            element={<ProjectCompletionOnTimeReporthelpPopup />}
          />
          <Route
            exact
            path="/ccpm/ProjectCountWithStatushelpPopup"
            element={<ProjectCountWithStatushelpPopup />}
          />
          <Route
            exact
            path="/ccpm/DepartmentLoadReporthelpPopup"
            element={<DepartmentLoadReporthelpPopup />}
          />

          <Route
            exact
            path="/ccpm/portfoliochartreport"
            element={<PortfolioChartReport />}
          />
          <Route
            exact
            path="/ccpm/projectcompletionontimereport"
            element={<ProjectCompletiononTimeReport />}
          />
          <Route
            exact
            path="/ccpm/projectcountwithstatus"
            element={<ProjectCountWithStatus />}
          />
          <Route
            exact
            path="/ccpm/tasktakingmoretime"
            element={<TaskTakingMoreTime />}
          />
          <Route
            exact
            path="/ccpm/RiskTypeMaster"
            element={<RiskTypeMaster />}
          />
          <Route
            exact
            path="/ccpm/RiskTypeMasterhelpPopup"
            element={<RiskTypeMasterhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/ProcurementCathelpPopup"
            element={<ProcurementhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/CalendarhelpPopup"
            element={<CalendarhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/PortfoliocharthelpPopup"
            element={<PortfoliocharthelpPopup />}
          />
          <Route
            exact
            path="/ccpm/BMReporthelpPopup"
            element={<BMReporthelpPopup />}
          />
          <Route
            exact
            path="/ccpm/ProjectwisecriticalchainhelpPopup"
            element={<ProjectwisecriticalchainhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/ProjectScheduleCurrentdatehelpPopup"
            element={<ProjectScheduleCurrentdatehelpPopup />}
          />
          <Route
            exact
            path="/ccpm/DailyTaskFlowTrendhelpPopup"
            element={<DailyTaskFlowTrendhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/DailySubTaskFlowTrendhelpPopup"
            element={<DailySubTaskFlowTrendhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/LoginAuditReporthelpPopup"
            element={<LoginAuditReporthelpPopup />}
          />
          <Route
            exact
            path="/ccpm/IssueReporthelpPopup"
            element={<IssueReporthelpPopup />}
          />
          <Route
            exact
            path="/CCPM/LoadOnTaskManagerhelpPopup"
            element={<LoadOnTaskManagerhelpPopup />}
          />
          <Route
            exact
            path="/CCPM/ClosedProjectReporthelpPopup"
            element={<ClosedProjectReporthelpPopup />}
          />
          <Route
            exact
            path="/CCPM/TaskTakingMoreTimehelpPopup"
            element={<TaskTakingMoreTimehelpPopup />}
          />
          <Route
            exact
            path="/CCPM/PlannedVsActualhelpPopup"
            element={<PlannedVsActualhelpPopup />}
          />
          <Route
            exact
            path="/CCPM/PortfolioChartReporthelpPopup"
            element={<PortfolioChartReporthelpPopup />}
          />

          <Route
            exact
            path="/ccpm/DelayReportforParetohelpPopup"
            element={<DelayReportforParetohelpPopup />}
          />
          <Route
            exact
            path="/ccpm/ProjectLeadTimehelpPopup"
            element={<ProjectLeadTimehelpPopup />}
          />

          <Route
            exact
            path="/ccpm/TaskwiseBufferConsumptionhelpPopup"
            element={<TaskwiseBufferConsumptionhelpPopup />}
          />

          <Route
            exact
            path="/ccpm/ProductConfighelpPopup"
            element={<ProductConfighelpPopup />}
          />
          <Route
            exact
            path="/ccpm/CustomerMasterhelpPopup"
            element={<CustomerMasterhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/TaskManMasterhelpPopup"
            element={<TaskManagementhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/WIPLimitMasterhelpPopup"
            element={<WIPLimitMasterhelpPopup />}
          />

          <Route exact path="/ccpm/UnitMaster" element={<UnitMaster />} />
          <Route
            exact
            path="/ccpm/BufferScheduler"
            element={<BufferScheduler />}
          />
          <Route
            exact
            path="/ccpm/userMasterhelpPopup"
            element={<UserMasterhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/rolePermissionhelpPopup"
            element={<RolePermissionhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/passwordhelpPopup"
            element={<PasswordhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/AssignParentRolehelpPopup"
            element={<AssignParentRolehelpPopup />}
          />
          <Route
            exact
            path="/ccpm/CompleteProjectshelpPopup"
            element={<CompleteProjectshelpPopup />}
          />
          <Route
            exact
            path="/ccpm/IssueListhelpPopup"
            element={<IssueListhelpPopup />}
          />

          <Route
            exact
            path="/admin/changepassword"
            element={<ChangePassword />}
          />
          <Route
            exact
            path="/admin/userinstancelinking"
            element={<UserInstanceLinking />}
          />
          <Route exact path="/dssadmin/newinstance" element={<NewInstance />} />
          <Route
            exact
            path="/dssadmin/InstanceActiveInActive"
            element={<InstanceActiveInActive />}
          />
          <Route exact path="/dssadmin/settings" element={<Settings />} />
          <Route
            exact
            path="/admin/RolePermission"
            element={<RolePermission />}
          />
          <Route
            exact
            path="/dssadmin/InstanceWiseJobScheduling"
            element={<InstanceWiseJobScheduling />}
          />
          <Route
            exact
            path="/admin/assignParentRole"
            element={<AssignParentRole />}
          />
          <Route
            exact
            path="/admin/assignParentPopUp"
            element={<AssignParentPopUp />}
          />
          <Route
            exact
            path="/admin/parentrolepermission"
            element={<ParentRolePermission />}
          />
          <Route
            exact
            path="/admin/parentrolepopup"
            element={<ParentRolePopUp />}
          />
          <Route exact path="/master/datasource" element={<DataSource />} />
          <Route exact path="/master/hierarchies" element={<Hierarchies />} />
          <Route
            exact
            path="/master/masterdefinition"
            element={<MasterDefinition />}
          />
          <Route
            exact
            path="/master/masterlinking"
            element={<MasterLinking />}
          />
          <Route
            exact
            path="/admin/usermastermapping"
            element={<UserMasterMapping />}
          />

          <Route
            exact
            path="/dssmauc/materialthreshold"
            element={<MaterialThreshold />}
          />
          <Route
            exact
            path="/dssmauc/reporttracker"
            element={<MAUCReportTracker />}
          />
          <Route
            exact
            path="/dssmauc/processtrackerreport"
            element={<ProcessMAUCTrackerReport />}
          />

          <Route
            exact
            path="/dssthroughput/calculatethroughput"
            element={<CalculateThroughput />}
          />

          <Route
            exact
            path="/trdloss/maintainfactor"
            element={<MaintainFactor />}
          />
          <Route
            exact
            path="/trdloss/calculatetrdlosssummary"
            element={<CalculateTRDLoss />}
          />
          <Route
            exact
            path="/reports/trdlosssummaryreport"
            element={<LossReportSummary />}
          />
          <Route
            exact
            path="/reports/losssummarytable"
            element={<TRDLossSummaryTable />}
          />

          <Route
            exact
            path="/mtaparameter/newinstance"
            element={<MTANewInstance />}
          />
          <Route
            exact
            path="/mtaparameter/mtainstanceactive"
            element={<MTAInstanceActive />}
          />
          <Route
            exact
            path="/mtaparameter/userinstancelinking"
            element={<MTAUserInstanceLinking />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointType"
            element={<StockPointType />}
          />
          <Route
            exact
            path="/mtaparameter/UserStockPointLinking"
            element={<UserStockPointLinking />}
          />
          <Route exact path="/mtaparameter/Control" element={<Control />} />
          <Route
            exact
            path="/mtaparameter/StockPoint"
            element={<StockPoint />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointConfig"
            element={<StockPointConfig />}
          />
          <Route
            exact
            path="/mtaparameter/SettingsUpload"
            element={<SettingsUpload />}
          />
          <Route
            exact
            path="/mtaparameter/SettingsDownload"
            element={<SettingsDownload />}
          />
          <Route
            exact
            path="/mtaparameter/AdminControl"
            element={<AdminControl />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointWarehouse"
            element={<StockPointWarehouse />}
          />
          <Route exact path="/mtaparameter/DBM" element={<DBM />} />
          <Route
            exact
            path="/mtaparameter/StockPointWarehouseConsumption"
            element={<StockPointWarehouseConsumption />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointBufferUpload"
            element={<StockPointBufferUpload />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointBufferDownload"
            element={<StockPointBufferDownload />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointWarehouseInTransit"
            element={<StockPointWarehouseInTransit />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointItemConfigurationIncremental"
            element={<StockPointItemConfigurationIncremental />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointProductClassConfigurationIncremental"
            element={<StockPointProductClassConfigurationIncremental />}
          />
          <Route
            exact
            path="/mtaparameter/InboundOrderAdvice"
            element={<InboundOrderAdvice />}
          />
          <Route
            exact
            path="/mtaparameter/mtainstanceactive"
            element={<MTAInstanceActive />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointSalesStatisticalGroupConfiguration"
            element={<StockPointSalesStatisticalGroupConfiguration />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointItemSupplierConfiguration"
            element={<StockPointItemSupplierConfiguration />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointSourceDestinationWarehouseMapping"
            element={<StockPointSourceDestinationWarehouseMapping />}
          />
          <Route
            exact
            path="/mtaparameter/DBMSuspension"
            element={<DBMSuspension />}
          />
          <Route
            exact
            path="/ccpm/DBMSuspensionhelpPopup"
            element={<DBMSuspensionhelpPopup />}
          />
          <Route
            exact
            path="/mtaparameter/StockPointItemPhaseInPhaseOutSeasonality"
            element={<StockPointItemPhaseInPhaseOutSeasonality />}
          />
          <Route
            exact
            path="/mtaparameter/ProcessingAutomation"
            element={<ProcessingAutomation />}
          />

          <Route
            exact
            path="/mtamaster/MasterUpload"
            element={<MasterUpload />}
          />
          <Route
            exact
            path="/mtamaster/InstanceDivisionDepartmentLinking"
            element={<InstanceDivisionDepartmentLinking />}
          />
          <Route
            exact
            path="/mtatransaction/MTATransactionUpload"
            element={<MTATransactionUpload />}
          />
          <Route
            exact
            path="/mtatransaction/MTATransactionDownload"
            element={<MTATransactionDownload />}
          />

          <Route
            exact
            path="/mtareports/BufferPenetrationReport"
            element={<BufferPenetrationReport />}
          />
          <Route
            exact
            path="/mtareports/PendingPurchaseOrderColorStatusReport"
            element={<PendingPurchaseOrderColorStatusReport />}
          />
          <Route
            exact
            path="/mtareports/TOCColorStatusLogReport"
            element={<TOCColorStatusLogReport />}
          />
          <Route
            exact
            path="/mtareports/DailyBufferTrendReport"
            element={<DailyBufferTrendReport />}
          />
          <Route exact path="/mtareports/Reports" element={<Reports />} />
          <Route
            exact
            path="/mtareports/ItemskeptasMTAReport"
            element={<ItemskeptasMTAReport />}
          />
          <Route
            exact
            path="/mtareports/MTAfILLRateReport"
            element={<MTAfILLRateReport />}
          />
          <Route
            exact
            path="/mtareports/PendingProductionOrderColorStatusReport"
            element={<PendingProductionOrderColorStatusReport />}
          />
          <Route
            exact
            path="/mtareports/SpikePatternAnalysisReport"
            element={<SpikePatternAnalysisReport />}
          />
          <Route
            exact
            path="/mtareports/TOCItemStockGraph"
            element={<TOCItemStockGraph />}
          />
          <Route
            exact
            path="/mtareports/PendingProductionOrderOperationColorStatusReport"
            element={<PendingProductionOrderOperationColorStatusReport />}
          />
          <Route
            exact
            path="/mtareports/WorkCenterSummaryReport"
            element={<WorkCenterSummaryReport />}
          />
          <Route
            exact
            path="/mtareports/MTDInventoryTransactionReport"
            element={<MTDInventoryTransactionReport />}
          />
          <Route
            exact
            path="/mtareports/StockAvailabilityReportforTopSKUsReport"
            element={<StockAvailabilityReportforTopSKUsReport />}
          />
          <Route
            exact
            path="/mtareports/PendingSalesOrderhavingSpikeOrderQty"
            element={<PendingSalesOrderhavingSpikeOrderQty />}
          />
          <Route
            exact
            path="/mtareports/InventoryValuationReport"
            element={<InventoryValuationReport />}
          />
          <Route
            exact
            path="/mtareports/InoundOrderStatusReport"
            element={<InoundOrderStatusReport />}
          />
          <Route
            exact
            path="/mtareports/SessionHistoryReport"
            element={<SessionHistoryReport />}
          />

          <Route
            exact
            path="/MTOTransaction/TransactionUpload"
            element={<TransactionUpload />}
          />
          <Route
            exact
            path="/MTOTransaction/TransactionDownload"
            element={<TransactionDownload />}
          />
          <Route
            exact
            path="/MTOTransaction/OrderBookingForm"
            element={<OrderBookingForm />}
          />
          <Route
            exact
            path="/MTOTransaction/FGOrderBookingFormException"
            element={<FGOrderBookingFormException />}
          />
          <Route
            exact
            path="/MTOTransaction/NOCCRProject"
            element={<NOCCRProject />}
          />
          <Route
            exact
            path="/MTOTransaction/NOCCRProjectDetails"
            element={<NOCCRProjectDetails />}
          />
          <Route
            exact
            path="/MTOTransaction/DepartmentClearance"
            element={<DepartmentClearance />}
          />
          <Route
            exact
            path="/MTOTransaction/OverallBMReport"
            element={<OverallBMReport />}
          />
          <Route
            exact
            path="/MTOTransaction/DispatchClearance"
            element={<DispatchClearance />}
          />
          <Route
            exact
            path="/MTOTransaction/FullkitPooExecution"
            element={<FullkitPooExecution />}
          />
          <Route
            exact
            path="/MTOTransaction/MonthlyBillingPlan"
            element={<MonthlyBillingPlan />}
          />
          <Route
            exact
            path="/MTOTransaction/LogisticsPlanning"
            element={<LogisticsPlanning />}
          />

          <Route exact path="/MTOProcess/Processing" element={<Processing />} />
          <Route
            exact
            path="/MTOProcess/OPOSReleaseControl"
            element={<OPOSReleaseControl />}
          />

          <Route
            exact
            path="/MTOReports/DepartmentClearanceReport"
            element={<DepartmentClearanceReport />}
          />
          <Route
            exact
            path="/MTOReports/ProductionOTIFReports"
            element={<ProductionOTIFReports />}
          />
          <Route
            exact
            path="/MTOReports/SalesOTIFReports"
            element={<SalesOTIFReports />}
          />
          <Route
            exact
            path="/MTOReports/OTIFDueDatePerformanceReports"
            element={<OTIFDueDatePerformanceReports />}
          />
          <Route
            exact
            path="/MTOReports/BMandFutureOrderReport"
            element={<BMandFutureOrderReport />}
          />
          <Route
            exact
            path="/MTOReports/DepartmentClearanceHistoryReport"
            element={<DepartmentClearanceHistoryReport />}
          />
          <Route
            exact
            path="/MTOReports/DepartmentClearanceAggregateReport"
            element={<DepartmentClearanceAggregateReport />}
          />
          <Route
            exact
            path="/MTOReports/FullKitPoStatusReport"
            element={<FullKitPoStatusReport />}
          />
          <Route
            exact
            path="/MTOReports/MTOSessionHistoryReport"
            element={<MTOSessionHistoryReport />}
          />

          <Route
            exact
            path="/CCPM/ActiveProjects/ActiveProjects"
            element={<ActiveProjects />}
          />
          <Route
            exact
            path="/CCPM/ActiveProjects/BufferRecovery"
            element={<BufferRecovery />}
          />
          <Route
            exact
            path="/CCPM/ActiveProjects/ModifyTaskAttribute"
            element={<ModifyTaskAttribute />}
          />
          <Route
            exact
            path="/CCPM/ActiveProjects/ModifySubTaskAttribute"
            element={<ModifySubTaskAttribute />}
          />
          <Route
            exact
            path="/CCPM/ActiveProjects/CompleteProjects"
            element={<CompleteProjects />}
          />
          <Route
            exact
            path="/CCPM/ActiveProjects/ProjectClosureForm"
            element={<ProjectClosureForm />}
          />
          <Route
            exact
            path="/CCPM/ProjectPlan/AlternateTaskManager"
            element={<AlternateTaskManager />}
          />
          <Route exact path="/ccpm/predecessor" element={<Predecessor />} />

          <Route exact path="/ccpm/successor" element={<Successor />} />
          <Route
            exact
            path="/CCPM/RiskManagement/RiskReview"
            element={<RiskReview />}
          />
          <Route
            exact
            path="/CCPM/RiskManagement/RiskMonitorReport"
            element={<RiskMonitorReport />}
          />
          <Route
            exact
            path="/CCPM/Reports/IssueReport"
            element={<IssueReport />}
          />
          <Route
            exact
            path="/CCPM/Reports/PlannedVsActual"
            element={<PlannedVsActual />}
          />
          <Route
            exact
            path="/CCPM/Reports/DepartmentLoadReport"
            element={<DepartmentLoadReport />}
          />

          <Route
            exact
            path="/CCPM/Reports/DelayReportforPareto"
            element={<DelayReportforPareto />}
          />
          <Route
            exact
            path="/CCPM/Reports/Projectwisecriticalchain"
            element={<Projectwisecriticalchain />}
          />
          <Route
            exact
            path="/CCPM/Reports/Portfoliochart"
            element={<Portfoliochart />}
          />
          <Route
            exact
            path="/CCPM/Reports/ProjectLeadTime"
            element={<ProjectLeadTime />}
          />
          <Route
            exact
            path="/CCPM/Reports/TaskwiseBufferConsumption"
            element={<TaskwiseBufferConsumption />}
          />
          <Route
            exact
            path="/CCPM/Reports/LoadOnTaskManager"
            element={<LoadOnTaskManager />}
          />
          <Route
            exact
            path="/CCPM/Reports/ProjectScheduleCurrentdate"
            element={<ProjectScheduleCurrentdate />}
          />
          <Route exact path="/CCPM/Reports/BMReport" element={<BMReport />} />

          <Route
            exact
            path="/ccpm/ScheduleBufferManagementRunhelpPopup"
            element={<ScheduleBufferManagementRun />}
          />
          <Route
            exact
            path="/ccpm/InstanceAttributeValueMasterhelpPopup"
            element={<InstanceAttributeValuMaster />}
          />
          <Route
            exact
            path="/ccpm/CCPMSettingPercentageParameterMasterhelpPopup"
            element={<CCPMSettingParameterPopup />}
          />
          <Route
            exact
            path="/ccpm/ProjectTaskMgmtEmailConfigHelpPopup"
            element={<ProjectTaskMgmtEmailConfigHelpPopup />}
          />
          <Route exact path="/ccpm/addEditTasks" element={<ProjectTasks />} />
          <Route
            exact
            path="/ccpm/ViewActiveProjectsHistory"
            element={<ViewHistory />}
          />
          <Route
            exact
            path="/ccpm/MyProjectshelpPopup"
            element={<MyProjectsHelpPopup />}
          />
          <Route
            exact
            path="/ccpm/PlannedBillingProjectionview"
            element={<PlannedBillingProjectionView />}
          />
          <Route
            exact
            path="/ccpm/RiskHistoryPopup"
            element={<RiskHistory />}
          />
          <Route exact path="/ccpm/EventPopup" element={<EventPopup />} />
          <Route exact path="/ccpm/PendingTaskView" element={<PendingTask />} />

          <Route
            exact
            path="/ccpm/PendingTaskView2"
            element={<PendingTask2 />}
          />

          <Route exact path="/ccpm/RiskPopup" element={<RiskPopup />} />

          <Route
            exact
            path="/ccpm/ViewIsuePopup"
            element={<ViewIssuePopup />}
          />
          <Route
            exact
            path="/ccpm/ViewBufferRecovery"
            element={<ViewBufferRecovery />}
          />

          <Route exact path="/ccpm/ViewProjProp" element={<ViewProjProp />} />
          <Route
            exact
            path="/ccpm/projectProperties"
            element={<ProjectProp />}
          />
          <Route
            exact
            path="/ccpm/addEditTaskError"
            element={<AddEditTaskError />}
          />
          <Route
            exact
            path="/ccpm/ViewProjectshelpPopup"
            element={<ViewProjectsHelpPopup />}
          />
          <Route
            exact
            path="/ccpm/AlternatePMhelpPopup"
            element={<AlternatePMhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/AlternateTMhelpPopup"
            element={<AlternateTMhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/ChangeProjectManagerhelpPopup"
            element={<ChangeProjectManagerhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/ModifySubTaskAttributehelpPopup"
            element={<ModifySubTaskAttributehelpPopup/>}
          />
           <Route
            exact
            path="/ccpm/PlannedBillingProjectionhelpPopup"
            element={<PlannedBillingProjectionhelpPopup/>}
          />
          
          <Route
            exact
            path="/ccpm/ModifyTaskAttributehelpPopup"
            element={<ModifyTaskAttributehelpPopup/>}
          />
          <Route exact path="/ccpm/Tasks/subTasks" element={<SubTasks />} />
          <Route exact path="/ccpm/Tasks/SubTasksAll" element={<SubTasksAllPending />} />
          <Route
            exact
            path="/ccpm/CreateNewhelpPopup"
            element={<CreateNewhelpPopup />}
          />
          <Route
            exact
            path="/ccpm/activeproject/GanttChart"
            element={<GanttChart />}
          />
           <Route 
          exact 
          path="/ccpm/activeproject/Networkchart"
          element={<NetworkChart/>}/>
        </Routes>
        {/* </div> */}
      </Router>
    </MenuProvider>
  );
}

export default App;
