import React, { useEffect, useState } from "react";
import bread from "../../../Assets/bread.png";
import i1 from "../../../Assets/i1.jpg";
import i2 from "../../../Assets/i2.jpg";
import i3 from "../../../Assets/i3.jpg";
import i4 from "../../../Assets/i4.jpg";
import i5 from "../../../Assets/i5.jpg";
import i6 from "../../../Assets/i6.png";
import viewp1 from "../../../Assets/viewp1.png";
import viewp2 from "../../../Assets/viewp2.png";
import viewp3 from "../../../Assets/viewp3.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Stack,
  Popover,
  Paper,
} from "@mui/material";
import moduless from "./ViewProjects.module.css";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import loading from "../../../Assets/Loading_image.gif";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import "./CalendarStyles.css";
import {
  MyProjGetInstance,
  MyProjGetAttributes,
  ViewProjSubmit,
  ViewProjGetStatus,
  ViewProjGetManager,
  ViewProjGetColor,
  ViewProjGetCustomer,
  ViewProjGetProject,
  NewProjectPlanExcel,
  OldProjectPlanExcel,
  ViewBufferRecoveryPopup
} from "../../../Services/CCPM";
import { useNavigate } from "react-router-dom";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const ViewProjects = () => {
  const navigate = useNavigate();

  const [showTable, setShowTable] = useState(false);
  const [headers, setHeaders] = useState([]); //attribute labels

  const [displaydate1, setDisplaydate1] = useState("");
  const [displaydate2, setDisplaydate2] = useState("");

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);

  const id1 = open1 ? "simple-popover" : undefined;
  const id2 = open2 ? "simple-popover" : undefined;

  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: "short" }).slice(0, 2);
  };

  //loading gif
  const [isLoading, setIsLoading] = useState(false);

  //LIST OF DROPDOWN DATA
  const [instance, setInstance] = useState([]);
  const [ProjectName, setProjectName] = useState([]);
  const [ProjectManager, setProjectManager] = useState([]);
  const [ProjectAccessType, setProjectAccessType] = useState([]);
  const [ProjectStatus, setProjectStatus] = useState([]);
  const [ProjectColor, setProjectColor] = useState([]);
  const [Customer, setCustomer] = useState([]);
  const [dateType, setDateType] = useState([]);

  //SELECTED VALUES
  const [selectedInstance, setSelectedInstance] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedProjectManager, setSelectedProjectManager] = useState("");
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("");
  const [selectedProjectColor, setSelectedProjectColor] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedDateType, setSelectedDateType] = useState("");

  const today = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedToday = today.toLocaleDateString("en-US", options);

  useEffect(() => {
    setIsLoading(true);
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      // userId: 2160
    };
    MyProjGetInstance(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setInstance(response.data.success.data);
          if ((response?.data?.success?.data).length > 0) {
            const savedInstance = localStorage.getItem('selectedInstance');
            if (savedInstance) {
              handleInstance(savedInstance);
              handleSubmit(savedInstance);
              localStorage.removeItem('selectedInstance');
            }
            else {
              handleInstance(response.data.success.data[0].id);
            }
          }
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [passdate1, setPassdate1] = useState("");
  const [passdate2, setPassdate2] = useState("");
  const [calendarOpen1, setCalendarOpen1] = useState(false);
  const [calendarOpen2, setCalendarOpen2] = useState(false);

  const [passdate1submit, setPassdate1submit] = useState("");
  const [passdate2submit, setPassdate2submit] = useState("");
  const handleCalendar1n = (date) => {
    setAnchorEl1(null);
    console.log(date.toString());
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");
    console.log(formattedDate);
    setFromdate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    console.log(`${day}/${month}/${year}`);
    setDisplaydate1(formattedDate);
    setPassdate1(`${month}/${day}/${year}`);
    setPassdate1submit(`${year}-${month}-${day}`);
    setCalendarOpen1(false);

    if (passdate2 !== "") {
      if (passdate2 < `${month}/${day}/${year}`) {
        alert("From Date Cannot be Greater than to Date");
        setFromdate("");
        setDisplaydate1("");
        setPassdate1("");
        setPassdate1submit("");
      } else {
        let body = {
          instanceId:
            selectedInstance !== "" && selectedInstance !== "Select"
              ? parseInt(selectedInstance)
              : 0,
          userId: parseInt(sessionStorage.getItem("loginId")),
          includeViewAccess: 1,
          isSpecificProjStatus: -1,
          dateType: selectedDateType,
          fromDate: `${month}/${day}/${year}`,
          toDate: passdate2,
        };
        ViewProjGetProject(body)
          .then((response) => {
            if (response.status === 200) {
              setIsLoading(false);
              setProjectName(response.data.success.data);
              setSelectedProjectName("Select");
            } else if (response?.response?.data?.message !== undefined) {
              setIsLoading(false);
              alert(response?.response?.data?.message);
            } else if (response?.response?.data?.error?.message !== undefined) {
              setIsLoading(false);
              alert(response?.response?.data?.error?.message);
            } else {
              setIsLoading(false);
              alert(response?.response?.data?.success?.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleCalendar2n = (date) => {
    setAnchorEl2(null);
    console.log(date.toString());
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");
    console.log(formattedDate);
    setTodate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    console.log(`${day}/${month}/${year}`);
    setDisplaydate2(formattedDate);
    setPassdate2(`${month}/${day}/${year}`);
    setPassdate2submit(`${year}-${month}-${day}`);
    setCalendarOpen2(false);

    if (passdate1 !== "") {
      if (passdate1 > `${month}/${day}/${year}`) {
        alert("To Date Cannot be Less than From Date");
        setTodate("");
        setDisplaydate2("");
        setPassdate2("");
        setPassdate2submit("");
      }
      let body = {
        instanceId:
          selectedInstance !== "" && selectedInstance !== "Select"
            ? parseInt(selectedInstance)
            : 0,
        userId: parseInt(sessionStorage.getItem("loginId")),
        includeViewAccess: 1,
        isSpecificProjStatus: -1,
        dateType: selectedDateType,
        fromDate: passdate1,
        toDate: `${month}/${day}/${year}`,
      };
      ViewProjGetProject(body)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            setProjectName(response.data.success.data);
            setSelectedProjectName("Select");
          } else if (response?.response?.data?.message !== undefined) {
            setIsLoading(false);
            alert(response?.response?.data?.message);
          } else if (response?.response?.data?.error?.message !== undefined) {
            setIsLoading(false);
            alert(response?.response?.data?.error?.message);
          } else {
            setIsLoading(false);
            alert(response?.response?.data?.success?.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getattributes = (i) => {
    setIsLoading(true);
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: parseInt(i),
    };
    MyProjGetAttributes(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          const dropdowns = response?.data?.success?.data;
          const activeLabels = dropdowns.labels?.filter(
            (label) => label.isactive === 1
          );
          setHeaders(activeLabels);
          console.log(activeLabels);
          const activeDropdowns = activeLabels?.map((label) => {
            const correspondingValues =
              dropdowns.values[label.attributenumber - 1];
            return {
              label: label.attributename,
              values: correspondingValues,
            };
          });
          setDropdownss(activeDropdowns);
          setValue({});
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDateType = (i) => {
    console.log(i);
    setSelectedDateType(i);
    setShowTable(false)
    setDummy([]);
    if (i === "" || i === "Select") {
      let body = {
        instanceId:
          selectedInstance !== "" && selectedInstance !== "Select"
            ? parseInt(selectedInstance)
            : 0,
        userId: parseInt(sessionStorage.getItem("loginId")),
        includeViewAccess: 1,
        isSpecificProjStatus: -1,
        dateType: "",
        fromDate: "",
        toDate: "",
      };
      ViewProjGetProject(body)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            setProjectName(response.data.success.data);
            setSelectedProjectName("Select");
          } else if (response?.response?.data?.message !== undefined) {
            setIsLoading(false);
            alert(response?.response?.data?.message);
          } else if (response?.response?.data?.error?.message !== undefined) {
            setIsLoading(false);
            alert(response?.response?.data?.error?.message);
          } else {
            setIsLoading(false);
            alert(response?.response?.data?.success?.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setPassdate1('')
    setPassdate2('')
    setPassdate1submit('')
    setPassdate2submit('')
    setDisplaydate1('')
    setDisplaydate2('')
  };

  const handleProjectStatus = (i) => {
    setSelectedProjectStatus(i);
    let body1 = {
      instanceId:
        selectedInstance !== "" && selectedInstance !== "Select"
          ? parseInt(selectedInstance)
          : 0,
      userId: parseInt(sessionStorage.getItem("loginId")),
      projStatusId: i === "" || i === "Select" ? 0 : parseInt(i),
    };
    let body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      level2Id:
        selectedInstance !== "" && selectedInstance !== "Select"
          ? parseInt(selectedInstance)
          : 0,
      level3Id: 0,
      level4Id: 0,
      level5Id: 0,
      level6Id: 0,
      level7Id: 0,
      level8Id: 0,
      level9Id: 0,
      level10Id: 0,
      projectsId:
        selectedProjectName === "" || selectedProjectName === "Select"
          ? 0
          : parseInt(selectedProjectName),
      projectManagerId:
        selectedProjectManager === "" || selectedProjectManager === "Select"
          ? 0
          : parseInt(selectedProjectManager),
      projectStatusId: i === "" || i === "Select" ? 0 : parseInt(i),
      projectColorId:
        selectedProjectColor === "" || selectedProjectColor === "Select"
          ? 0
          : parseInt(selectedProjectColor),
      customerId:
        selectedCustomer === "" || selectedCustomer === "Select"
          ? 0
          : parseInt(selectedCustomer),
    };
    ViewProjGetManager(body1)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setProjectManager(response.data.success.data);
          setSelectedProjectManager("Select");
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    ViewProjGetCustomer(body2)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setCustomer(response.data.success.data);
          setSelectedCustomer("Select");
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleProjectName = (i) => {
    setSelectedProjectName(i);
    let body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      level2Id:
        selectedInstance !== "" && selectedInstance !== "Select"
          ? parseInt(selectedInstance)
          : 0,
      level3Id: 0,
      level4Id: 0,
      level5Id: 0,
      level6Id: 0,
      level7Id: 0,
      level8Id: 0,
      level9Id: 0,
      level10Id: 0,
      projectsId: i === "" || i === "Select" ? 0 : parseInt(i),
      projectManagerId:
        selectedProjectManager === "" || selectedProjectManager === "Select"
          ? 0
          : parseInt(selectedProjectManager),
      projectStatusId:
        selectedProjectStatus === "" || selectedProjectStatus === "Select"
          ? 0
          : parseInt(selectedProjectStatus),
      projectColorId:
        selectedProjectColor === "" || selectedProjectColor === "Select"
          ? 0
          : parseInt(selectedProjectColor),
      customerId:
        selectedCustomer === "" || selectedCustomer === "Select"
          ? 0
          : parseInt(selectedCustomer),
    };
    ViewProjGetCustomer(body2)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setCustomer(response.data.success.data);
          setSelectedCustomer("Select");
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleProManager = (i) => {
    setSelectedProjectManager(i);
    let body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      level2Id:
        selectedInstance !== "" && selectedInstance !== "Select"
          ? parseInt(selectedInstance)
          : 0,
      level3Id: 0,
      level4Id: 0,
      level5Id: 0,
      level6Id: 0,
      level7Id: 0,
      level8Id: 0,
      level9Id: 0,
      level10Id: 0,
      projectsId:
        selectedProjectName === "" || selectedProjectName === "Select"
          ? 0
          : parseInt(selectedProjectName),
      projectManagerId: i === "" || i === "Select" ? 0 : parseInt(i),
      projectStatusId:
        selectedProjectStatus === "" || selectedProjectStatus === "Select"
          ? 0
          : parseInt(selectedProjectStatus),
      projectColorId:
        selectedProjectColor === "" || selectedProjectColor === "Select"
          ? 0
          : parseInt(selectedProjectColor),
      customerId:
        selectedCustomer === "" || selectedCustomer === "Select"
          ? 0
          : parseInt(selectedCustomer),
    };
    ViewProjGetCustomer(body2)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setCustomer(response.data.success.data);
          setSelectedCustomer("Select");
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleColor = (i) => {
    setSelectedProjectColor(i);
    let body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      level2Id:
        selectedInstance !== "" && selectedInstance !== "Select"
          ? parseInt(selectedInstance)
          : 0,
      level3Id: 0,
      level4Id: 0,
      level5Id: 0,
      level6Id: 0,
      level7Id: 0,
      level8Id: 0,
      level9Id: 0,
      level10Id: 0,
      projectsId:
        selectedProjectName === "" || selectedProjectName === "Select"
          ? 0
          : parseInt(selectedProjectName),
      projectManagerId:
        selectedProjectManager === "" || selectedProjectManager === "Select"
          ? 0
          : parseInt(selectedProjectManager),
      projectStatusId:
        selectedProjectStatus === "" || selectedProjectStatus === "Select"
          ? 0
          : parseInt(selectedProjectStatus),
      projectColorId: i === "" || i === "Select" ? 0 : parseInt(i),
      customerId:
        selectedCustomer === "" || selectedCustomer === "Select"
          ? 0
          : parseInt(selectedCustomer),
    };
    ViewProjGetCustomer(body2)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setCustomer(response.data.success.data);
          setSelectedCustomer("Select");
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInstance = (i) => {
    console.log(i);
    setIsLoading(true);
    setSelectedInstance(i);
    getattributes(i);
    let body = {
      instanceId: parseInt(i),
      userId: parseInt(sessionStorage.getItem("loginId")),
      includeViewAccess: 1,
      isSpecificProjStatus: -1,
      dateType: "",
      fromDate: "",
      toDate: "",
    };
    let body1 = {
      instanceId: parseInt(i),
      userId: parseInt(sessionStorage.getItem("loginId")),
      projStatusId: 0,
    };
    let body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      level2Id: parseInt(i),
      level3Id: 0,
      level4Id: 0,
      level5Id: 0,
      level6Id: 0,
      level7Id: 0,
      level8Id: 0,
      level9Id: 0,
      level10Id: 0,
      projectsId: 0,
      projectManagerId: 0,
      projectStatusId: 0,
      projectColorId: 0,
      customerId: 0,
    };
    ViewProjGetProject(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setProjectName(response.data.success.data);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setProjectAccessType([
      {
        id: "PM",
        name: "Where I am Project Manager",
      },
      {
        id: "APM",
        name: "Where I am Alternate PM",
      },
      {
        id: "LPM",
        name: "Where My L is  Project Manager",
      },
      {
        id: "LAPM",
        name: "Where My L is Alternate  PM",
      },
    ]);
    setDateType([
      {
        id: "1",
        name: "Exp/Actual End Date",
      },
      {
        id: "2",
        name: "Customer Due date",
      },
      {
        id: "3",
        name: "Scheduled End Date",
      },
    ]);
    ViewProjGetStatus()
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setProjectStatus(response.data.success.data);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    ViewProjGetColor()
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setProjectColor(response.data.success.data);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    ViewProjGetManager(body1)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setProjectManager(response.data.success.data);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    ViewProjGetCustomer(body2)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setCustomer(response.data.success.data);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    handleDateType('Select')
    setSelectedProjectName("Select");
    setSelectedProjectManager("Select");
    setSelectedProjectAccessType("Select");
    setSelectedProjectStatus("Select");
    setSelectedProjectColor("Select");
    setSelectedCustomer("Select");
    setShowTable(false);
    setDummy([]);
  };

  //Reset button handle
  const handleReset = () => {
    handleInstance(instance[0].id);
  };

  const DownloadHandler = (id) => {
    getExcel(id);
  };

  const DownloadHandler2 = (id) => {
    getExcel2(id);
  };

  const getExcel = async (id) => {
    const body = {
      projectId: id,
    };

    try {
      setIsLoading(true);
      const response = await NewProjectPlanExcel(body);

      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // const contentDisposition = response.headers["content-disposition"];
        const filename = "ProjectPlan.xlsx";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoading(false);
        console.error("No Data found");
      }
    } catch (err) {
      setIsLoading(false);
      alert('Error While Exporting Or No Related Data Found');
      console.error("Error downloading file:", err);
    }
  };

  const getExcel2 = async (id) => {
    const body = {
      projectId: id,
      instance:
        selectedInstance === "" || selectedInstance === "Select"
          ? 0
          : parseInt(selectedInstance),
    };

    try {
      setIsLoading(true);
      const response = await OldProjectPlanExcel(body);

      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // const contentDisposition = response.headers["content-disposition"];
        const filename = "OldProjectPlan.xlsx";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoading(false);
        console.error("No Data found");
      }
    } catch (err) {
      setIsLoading(false);
      alert('Error While Exporting Or No Related Data Found');
      console.error("Error downloading file:", err);
    }
  };

  const [dummy, setDummy] = useState([]); //table data usestate

  const [dropdownss, setDropdownss] = useState([]); //attributes dropdowns
  const [value, setValue] = useState({}); //selected attributes

  //attribute dropdown selection
  const handleDropdownSelection = (e, i) => {
    const { value } = e.target;
    setValue((prevValue) => ({
      ...prevValue,
      [`attribute${i + 1}Id`]: value,
    }));
  };

  //Submit button handle
  const handleSubmit = (instanceI) => {
    if (selectedDateType !== '' && selectedDateType !== 'Select' && passdate1 === '') {
      alert('Please Enter From Date')
    }
    else if (selectedDateType !== '' && selectedDateType !== 'Select' && passdate2 === '') {
      alert('Please Enter To Date')
    }
    else {
      setIsLoading(true);
      setShowTable(true);
      let body = {
        instanceId:
        instanceI === "" || instanceI === "Select"
            ? 0
            : parseInt(instanceI),
        userId: parseInt(sessionStorage.getItem("loginId")),
        attribute1Id:
          value.attribute1Id !== undefined && value.attribute1Id !== "Select"
            ? parseInt(value.attribute1Id)
            : 0,
        attribute2Id:
          value.attribute2Id !== undefined && value.attribute2Id !== "Select"
            ? parseInt(value.attribute2Id)
            : 0,
        attribute3Id:
          value.attribute3Id !== undefined && value.attribute3Id !== "Select"
            ? parseInt(value.attribute3Id)
            : 0,
        attribute4Id:
          value.attribute4Id !== undefined && value.attribute4Id !== "Select"
            ? parseInt(value.attribute4Id)
            : 0,
        attribute5Id:
          value.attribute5Id !== undefined && value.attribute5Id !== "Select"
            ? parseInt(value.attribute5Id)
            : 0,
        attribute6Id:
          value.attribute6Id !== undefined && value.attribute6Id !== "Select"
            ? parseInt(value.attribute6Id)
            : 0,
        attribute7Id:
          value.attribute7Id !== undefined && value.attribute7Id !== "Select"
            ? parseInt(value.attribute7Id)
            : 0,
        attribute8Id:
          value.attribute8Id !== undefined && value.attribute8Id !== "Select"
            ? parseInt(value.attribute8Id)
            : 0,
        attribute9Id:
          value.attribute9Id !== undefined && value.attribute9Id !== "Select"
            ? parseInt(value.attribute9Id)
            : 0,
        attribute10Id:
          value.attribute10Id !== undefined && value.attribute10Id !== "Select"
            ? parseInt(value.attribute10Id)
            : 0,
        projId:
          selectedProjectName === "" || selectedProjectName === "Select"
            ? 0
            : parseInt(selectedProjectName),
        projMgrId:
          selectedProjectManager === "" || selectedProjectManager === "Select"
            ? 0
            : parseInt(selectedProjectManager),
        accessType:
          selectedProjectAccessType === "" ||
            selectedProjectAccessType === "Select"
            ? ""
            : selectedProjectAccessType,
        // projectStatusId: (selectedProjectStatus === '' || selectedProjectStatus === 'Select') ? 1 : parseInt(selectedProjectStatus),
        projectStatusId:
          selectedProjectStatus === "" || selectedProjectStatus === "Select"
            ? 0
            : parseInt(selectedProjectStatus),
        projectColorId:
          selectedProjectColor === "" || selectedProjectColor === "Select"
            ? 0
            : parseInt(selectedProjectColor),
        customerId:
          selectedCustomer === "" || selectedCustomer === "Select"
            ? 0
            : parseInt(selectedCustomer),
        dateType:
          selectedDateType === "" || selectedDateType === "Select"
            ? "-1"
            : selectedDateType,
        fromDate: passdate1submit !== "" ? passdate1submit : "",
        toDate: passdate2submit !== "" ? passdate2submit : "",
      };
      console.log(body);
      ViewProjSubmit(body)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            console.log(response);
            setDummy(response.data.success.data);
          } else if (response?.response?.data?.message !== undefined) {
            setIsLoading(false);
            alert(response?.response?.data?.message);
          } else if (response?.response?.data?.error?.message !== undefined) {
            setIsLoading(false);
            alert(response?.response?.data?.error?.message);
          } else {
            setIsLoading(false);
            alert(response?.response?.data?.success?.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePlannedBilling = (projId) => {
    localStorage.setItem('selectedInstance', selectedInstance);
    navigate(`/ccpm/PlannedBillingProjectionview?projId=${projId}`);
  };

  const handleViewTask = (instance) => {
    localStorage.setItem('selectedInstance', selectedInstance);
    navigate(`/ccpm/viewtasks?instance=${instance}`)
  }

  const RowsPerPage = 10;
  const MaxVisiblePages = 5;

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total pages based on chartData length
  const totalPages = Math.ceil(dummy.length / RowsPerPage);
  const startIndex = (currentPage - 1) * RowsPerPage;
  const endIndex = startIndex + RowsPerPage;
  const displayedData = dummy.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    if (page === "prev") {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    } else if (page === "next") {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    } else {
      setCurrentPage(page);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= MaxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= MaxVisiblePages - 3) {
        for (let i = 1; i <= MaxVisiblePages - 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");

        const remainingPages = totalPages - currentPage;

        if (remainingPages < MaxVisiblePages - 3) {
          for (
            let i = currentPage - (MaxVisiblePages - 4);
            i <= totalPages;
            i++
          ) {
            pageNumbers.push(i);
          }
        } else {
          for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pageNumbers.push(i);
          }

          pageNumbers.push("...");
          pageNumbers.push(totalPages);
        }
      }
    }
    return pageNumbers;
  };

  const prepareDataForExport = (displayedData, headers) => {
    return displayedData.map((item) => {
      const row = {
        "Modif. No": item["Modif Version"],
        "Project Name": item.projectname,
        "Project Manager": item.projectmanagername,
        "Project Status": item.projectstatus,
        "Customer Name": item.customername,
        "Calendar": item.calendarname,
        "ERP Company": item.erpcompany,
        "ERP Project No": item.erpprojectno,
        "Project Duration": item.projectduration,
        "% Buffer Consumed": item.perbufferconsumed,
        "% CC Completed": item.percccompleted,
        "Scheduled Start Date": item.scheduledstartdate && new Date(item.scheduledstartdate).toLocaleDateString('en-GB'),
        "Scheduled End Date": item.scheduledenddate && new Date(item.scheduledenddate).toLocaleDateString('en-GB'),
        "Actual Start Date": item.actualstartdate && new Date(item.actualstartdate).toLocaleDateString('en-GB'),
        "Exp/Actual End Date": item.actualenddate && new Date(item.actualenddate).toLocaleDateString('en-GB'),
        "Customer Due Date": item.customerduedate && new Date(item.customerduedate).toLocaleDateString('en-GB'),
        "Completion Status": item.completionstatus,
        "Delay(days)": item.delaydays,
        "Delay(%)": item.delayper,
        "Lead Time(days)": item.leadtimedays,
        "Task Causing Maximum Delay": item.taskname,
        "Delay Caused By Task(Days)": item.maxdelay,
        "Last BM Run": item.lastbmrundate && formatDate(item.lastbmrundate),
        "Customer Feedback": item.customerfeedback,
        "Approved Execution Cost(in INR Lakhs)": item.approvedexecutioncost,
        "Actual Execution Cost(in INR Lakhs)": item.actualexecutioncost,
        "Project value(in INR Lakhs)": item.projectvalue,
        "Instance": item.instance,
      };

      // Add dynamic headers
      headers.forEach((header) => {
        row[header.attributename] = item[`a${header.attributenumber}`];
      });

      row["Project Creation Date"] = item.createdon && formatDate(item.createdon);
      row["Project Last Updated On"] = item.lastmodifiedon && formatDate(item.lastmodifiedon);
      row["Task Last Updated On"] = item.lasttaskupdationdate && formatDate(item.lasttaskupdationdate);
      return row;
    });
  };

  const handleExport = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const data = prepareDataForExport(dummy, headers);
    const ws = XLSX.utils.json_to_sheet(data);

    // Apply styles to the header row
    const headerStyle = {
      font: { bold: true, sz: 10, name: "Aptos Narrow" }, // font size and family
      fill: { fgColor: { rgb: "F0F0F0" } }, // gray background
      alignment: { horizontal: "center", vertical: "center", wrapText: true },
    };
  
    const rowStyle = {
      font: { color: { rgb: "000000" }, sz: 10, name: "Aptos Narrow" }, // font size and family
      alignment: { horizontal: "center", vertical: "center", wrapText: true },
    };

    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!ws[cellAddress]) continue;
      if (!ws[cellAddress].s) ws[cellAddress].s = {};
      ws[cellAddress].s = { ...ws[cellAddress].s, ...headerStyle };
    }

    for (let R = 1; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!ws[cellAddress]) continue;
        if (!ws[cellAddress].s) ws[cellAddress].s = {};
        ws[cellAddress].s = { ...ws[cellAddress].s, ...rowStyle };
      }
    }

    // Adjust row height
    ws["!rows"] = [];
    for (let R = range.s.r; R <= range.e.r; ++R) {
      ws["!rows"][R] = { hpt: 25 }; // Row height in points
    }

    // Adjust column width
    ws["!cols"] = [];
    for (let C = range.s.c; C <= range.e.c; ++C) {
      ws["!cols"][C] = { wch: 15 }; // Column width in characters
    }

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataf = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataf, "ViewProjects" + fileExtension);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  const handleBufferRecovery = (pid) => {
    setIsLoading(true)
    let body = {
      // projectId: 4244
      projectId: parseInt(pid)
    }
    ViewBufferRecoveryPopup(body)
      .then((response) => {
        if (response?.status === 200) {
          console.log(response)
          if (response?.data?.success?.data?.length === 0) {
            alert('No Buffer Recovery Data Exists')
          }
          else {
            window.open(
              `/ccpm/ViewBufferRecovery?data=${response?.data?.success?.data}`,
              "sharer",
              "toolbar=0,status=0,width=1050,height=600"
            )
          }
          setIsLoading(false)
        }
        else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div
      style={{
        overflowX: dummy?.length > 0 ? "auto" : "hidden",
        height: "100vh",
      }}
    >
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"ViewProjects"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div_vp"]}>
              <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>Project Plan</a>
                <img src={bread} alt="" />
                <a>View My Projects Plans</a>
              </div>
            </div>
            <Grid
              container
              className={[
                modules["parent-container_vp"],
                modules["parent-container-new"],
              ].join(" ")}
            >
              <div className={modules["grid-container_vpm"]}>
                <Stack
                  spacing={2}
                  direction="row"
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                  className={modules["each-entry-mp"]}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Instance:<span className={modules["asterisk"]}>*</span>{" "}
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedInstance}
                    onChange={(e) => handleInstance(e.target.value)}
                  >
                    {instance &&
                      instance.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.name}
                        </option>
                      ))}
                  </select>
                </Stack>

                {/* Attribute Dropdown part */}
                {dropdownss &&
                  dropdownss?.map((obj, i) => {
                    console.log(obj.values?.length);
                    return (
                      <Stack
                        spacing={2}
                        direction="row"
                        style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                        className={modules["each-entry-mp"]}
                      >
                        <div className={modules["input-labels_vpm"]}>
                          {obj.label}:
                        </div>

                        <select
                          className={modules["dropdownSelect1_vpm"]}
                          value={value[`attribute${i + 1}Id`] || "Select"}
                          onChange={(event) =>
                            handleDropdownSelection(event, i)
                          }
                        >
                          {/* {obj.values?.length > 0 &&
                          <option value="Select">
                            SELECT
                          </option>
                        } */}
                          <option value="Select">SELECT</option>
                          {obj.values &&
                            obj.values.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.attributevalue}
                              </option>
                            ))}
                        </select>
                      </Stack>
                    );
                  })}
              </div>
              <div className={modules["grid-container2_vpm"]}>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>Date Type:</div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedDateType}
                    onChange={(e) => handleDateType(e.target.value)}
                  // onChange={(e) => setSelectedDateType(e.target.value)}
                  >
                    {dateType.length > 0 && (
                      <option value="Select">SELECT</option>
                    )}
                    {dateType &&
                      dateType.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.name}
                        </option>
                      ))}
                  </select>
                </Stack>
                {selectedDateType !== "" && selectedDateType !== "Select" && (
                  <>
                    <Stack
                      spacing={2}
                      direction="row"
                      className={modules["each-entry-mp"]}
                      style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                    >
                      <div className={modules["input-labels_vpm"]}>
                        From Date:<span className={modules["asterisk"]}>*</span>{" "}
                      </div>
                      <input
                        value={displaydate1}
                        onChange={(e) => setDisplaydate1(e.target.value)}
                        className={modules["input-field"]}
                        onClick={(event) => setAnchorEl1(event.currentTarget)}
                      />
                      <Popover
                        id={id1}
                        open={open1}
                        anchorEl={anchorEl1}
                        onClose={() => setAnchorEl1(null)}
                        placement="bottom-start"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        style={{ borderRadius: "0px" }}
                      >
                        <Paper sx={{ borderRadius: "0px" }}>
                          <Calendar
                            onChange={handleCalendar1n}
                            value={fromdate}
                            locale="en-US"
                            formatShortWeekday={formatShortWeekday}
                          />
                          <div className={modules["calendar-footer"]}>
                            Today: {formattedToday}
                          </div>
                        </Paper>
                      </Popover>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      className={modules["each-entry-mp"]}
                      style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                    >
                      <div className={modules["input-labels_vpm"]}>
                        To Date:<span className={modules["asterisk"]}>*</span>{" "}
                      </div>
                      <input
                        // style={{
                        //   width: '20%'
                        // }}
                        value={displaydate2}
                        onChange={(e) => setDisplaydate2(e.target.value)}
                        className={modules["input-field"]}
                        onClick={(event) => setAnchorEl2(event.currentTarget)}
                      />
                      <Popover
                        id={id2}
                        open={open2}
                        anchorEl={anchorEl2}
                        onClose={() => setAnchorEl2(null)}
                        placement="bottom-start"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        style={{ borderRadius: "0px" }}
                      >
                        <Paper sx={{ borderRadius: "0px" }}>
                          <Calendar
                            onChange={handleCalendar2n}
                            value={todate}
                            locale="en-US"
                            formatShortWeekday={formatShortWeekday}
                          />
                          <div className={modules["calendar-footer"]}>
                            Today: {formattedToday}
                          </div>
                        </Paper>
                      </Popover>
                    </Stack>
                  </>
                )}
              </div>
              <div className={modules["grid-container2_vpm"]}>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Project Name:
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedProjectName}
                    // onChange={(e) => setSelectedProjectName(e.target.value)}
                    onChange={(e) => handleProjectName(e.target.value)}
                  >
                    {ProjectName.length > 0 && (
                      <option value="Select">SELECT</option>
                    )}
                    {ProjectName &&
                      ProjectName.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.projectname}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Project Manager:
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedProjectManager}
                    // onChange={(e) => setSelectedProjectManager(e.target.value)}
                    onChange={(e) => handleProManager(e.target.value)}
                  >
                    {ProjectManager.length > 0 && (
                      <option value="Select">SELECT</option>
                    )}
                    {ProjectManager &&
                      ProjectManager.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.username}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Project Access Type:
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedProjectAccessType}
                    onChange={(e) =>
                      setSelectedProjectAccessType(e.target.value)
                    }
                  >
                    {ProjectAccessType.length > 0 && (
                      <option value="Select">SELECT</option>
                    )}
                    {ProjectAccessType &&
                      ProjectAccessType.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.name}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Project Status:
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedProjectStatus}
                    // onChange={(e) => setSelectedProjectStatus(e.target.value)}
                    onChange={(e) => handleProjectStatus(e.target.value)}
                  >
                    {ProjectStatus.length > 0 && (
                      <option value="Select">All</option>
                    )}
                    {ProjectStatus &&
                      ProjectStatus.map((i) => (
                        <option value={i.projectstatusid} key={i.id}>
                          {i.projectstatus}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Project Color:
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedProjectColor}
                    // onChange={(e) => setSelectedProjectColor(e.target.value)}
                    onChange={(e) => handleColor(e.target.value)}
                  >
                    {ProjectColor.length > 0 && (
                      <option value="Select">All</option>
                    )}
                    {ProjectColor &&
                      ProjectColor.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.color}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>Customer:</div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedCustomer}
                    onChange={(e) => setSelectedCustomer(e.target.value)}
                  >
                    {Customer.length > 0 && <option value="Select">All</option>}
                    {Customer &&
                      Customer.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.customername}
                        </option>
                      ))}
                  </select>
                </Stack>
              </div>

              <Grid container className={modules["btn1-cont_vpm"]}>
                <button className={modules["btn1_vpm"]} onClick={() => handleSubmit(selectedInstance)}>
                  Search
                </button>
                <button className={modules["btn2_vpm"]} onClick={handleReset}>
                  Reset
                </button>
                <button
                  className={modules["btn2_vpm"]}
                  onClick={() =>
                    dummy?.length === 0
                      ? alert("No Record Found.")
                      : handleExport()
                  }
                >
                  Export To Excel
                </button>
              </Grid>
            </Grid>
            {showTable && (
              <Grid
                container
                style={{ marginLeft: "6rem" }}
                className={modules[("table-grid_vpm", "table-grid-margin")]}
              >
                {dummy?.length > 0 ? (
                  <div className={modules["table-container_vpm"]}>
                    <Table
                      className={[
                        modules["custom-table_vpm"],
                        modules["custom-table-th"],
                      ].join(" ")}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Modif. No</TableCell>
                          <TableCell>Project Name</TableCell>
                          <TableCell>
                            Project
                            <br />
                            Manager
                          </TableCell>
                          <TableCell>
                            Project
                            <br />
                            Status
                          </TableCell>
                          <TableCell>Customer Name</TableCell>
                          <TableCell>Calendar</TableCell>
                          <TableCell>
                            ERP
                            <br />
                            Company
                          </TableCell>
                          <TableCell>
                            ERP
                            <br />
                            Project
                            <br />
                            No
                          </TableCell>
                          <TableCell>
                            Project
                            <br />
                            Duration
                          </TableCell>
                          <TableCell>
                            % Buffer
                            <br />
                            Consumed
                          </TableCell>
                          <TableCell>
                            % CC
                            <br />
                            Completed
                          </TableCell>
                          <TableCell>
                            Scheduled
                            <br />
                            Start Date
                          </TableCell>
                          <TableCell>
                            Scheduled
                            <br />
                            End Date
                          </TableCell>
                          <TableCell>
                            Actual
                            <br />
                            Start
                            <br />
                            Date
                          </TableCell>
                          <TableCell>
                            Exp/Actual
                            <br />
                            End Date
                          </TableCell>
                          <TableCell>
                            Customer
                            <br />
                            Due Date
                          </TableCell>
                          <TableCell>
                            Completion
                            <br />
                            Status
                          </TableCell>
                          <TableCell>Delay(days)</TableCell>
                          <TableCell>Delay(%)</TableCell>
                          <TableCell>
                            Lead
                            <br />
                            Time(dayS)
                          </TableCell>
                          <TableCell>
                            Task
                            <br />
                            Causing
                            <br />
                            Maximum
                            <br />
                            Delay
                          </TableCell>
                          <TableCell>
                            Delay
                            <br />
                            Caused By
                            <br />
                            Task(Days)
                          </TableCell>
                          <TableCell>
                            Last
                            <br />
                            BM
                            <br />
                            Run
                          </TableCell>
                          <TableCell>
                            Customer
                            <br />
                            Feedback
                          </TableCell>
                          <TableCell>
                            Approved
                            <br />
                            Execution
                            <br />
                            Cost(in INR Lakhs)
                          </TableCell>
                          <TableCell>
                            Actual
                            <br />
                            Execution
                            <br />
                            Cost(in INR Lakhs)
                          </TableCell>
                          <TableCell>
                            Project
                            <br />
                            value
                            <br />
                            (in INR Lakhs)
                          </TableCell>
                          <TableCell>
                            View
                            <br />
                            Tasks
                          </TableCell>
                          <TableCell>
                            View
                            <br />
                            Billing
                            <br />
                            Projection
                          </TableCell>
                          <TableCell>Risk</TableCell>
                          <TableCell>Issues</TableCell>
                          <TableCell>
                            View
                            <br />
                            History
                          </TableCell>
                          <TableCell>
                            Fever
                            <br />
                            Chart
                          </TableCell>
                          <TableCell>
                            Buffer
                            <br />
                            Recovery
                          </TableCell>
                          <TableCell>
                            Project
                            <br />
                            Properties
                          </TableCell>
                          <TableCell>
                            Pending
                            <br />
                            Tasks
                            <br />
                            On CC
                            <br />
                            Properties
                          </TableCell>
                          <TableCell>
                            Gantt
                            <br />
                            Chart
                          </TableCell>
                          <TableCell>
                            Network
                            <br />
                            Diagram
                          </TableCell>
                          <TableCell>
                            New
                            <br />
                            Project
                            <br />
                            Plan
                            <br />
                            File
                          </TableCell>
                          <TableCell>
                            Old
                            <br />
                            Project
                            <br />
                            Plan
                            <br />
                            File
                          </TableCell>
                          <TableCell>Attachments</TableCell>
                          <TableCell>Instance</TableCell>
                          {headers.map((item) => {
                            return <TableCell>{item.attributename}</TableCell>;
                          })}
                          <TableCell>
                            Project
                            <br />
                            Creation
                            <br />
                            Date
                          </TableCell>
                          <TableCell>
                            Project
                            <br />
                            Last
                            <br />
                            Updated On
                          </TableCell>
                          <TableCell>
                            Task Last
                            <br />
                            Updated On
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dummy &&
                          displayedData &&
                          displayedData.map((item, index) => (
                            <TableRow
                              key={index}
                              className={modules["table-row"]}
                            >
                              <TableCell>{item["Modif Version"]}</TableCell>
                              <TableCell
                                style={{ backgroundColor: item.color }}
                              >
                                {item.projectname}
                              </TableCell>
                              <TableCell>{item.projectmanagername}</TableCell>
                              <TableCell>{item.projectstatus}</TableCell>
                              <TableCell>{item.customername}</TableCell>
                              <TableCell>{item.calendarname}</TableCell>
                              <TableCell>{item.erpcompany}</TableCell>
                              <TableCell>{item.erpprojectno}</TableCell>
                              <TableCell>{item.projectduration.toFixed(2)}</TableCell>
                              <TableCell>{item.perbufferconsumed}</TableCell>
                              <TableCell>{item.percccompleted.toFixed(2)}</TableCell>
                              <TableCell>{item.scheduledstartdate && new Date(item.scheduledstartdate).toLocaleDateString('en-GB')}</TableCell>
                              <TableCell>{item.scheduledenddate && new Date(item.scheduledenddate).toLocaleDateString('en-GB')}</TableCell>
                              <TableCell>{item.actualstartdate && new Date(item.actualstartdate).toLocaleDateString('en-GB')}</TableCell>
                              <TableCell>{item.actualenddate && new Date(item.actualenddate).toLocaleDateString('en-GB')}</TableCell>
                              <TableCell>{item.customerduedate && new Date(item.customerduedate).toLocaleDateString('en-GB')}</TableCell>
                              <TableCell>{item.completionstatus}</TableCell>
                              <TableCell>{item.delaydays}</TableCell>
                              <TableCell>{item.delayper}</TableCell>
                              <TableCell>{item.leadtimedays}</TableCell>
                              <TableCell>{item.taskname}</TableCell>
                              <TableCell>{item.maxdelay}</TableCell>
                              <TableCell>{item.lastbmrundate && formatDate(item.lastbmrundate)}</TableCell>
                              <TableCell>{item.customerfeedback}</TableCell>
                              <TableCell>
                                {item.approvedexecutioncost}
                              </TableCell>
                              <TableCell>{item.actualexecutioncost}</TableCell>
                              <TableCell>{item.projectvalue}</TableCell>
                              <TableCell onClick={() => handleViewTask(selectedInstance)}>
                                <a href="#">View Task</a>
                              </TableCell>
                              {item.projectstatus === 'Active' || item.projectstatus === 'Completed' ?
                                <TableCell
                                  onClick={() =>
                                    handlePlannedBilling(item.projectsid)
                                  }
                                >
                                  <a href="#">View Billing Projection</a>
                                </TableCell>
                                :
                                <TableCell>
                                  View Billing Projection
                                </TableCell>
                              }
                              <TableCell>
                                <img
                                  onClick={() => {
                                    window.open(
                                      `/ccpm/RiskPopup?projId=${item.projectsid}&selectedInstance=${selectedInstance}`,
                                      "sharer",
                                      "toolbar=0,status=0,width=1050,height=600"
                                    );
                                  }}
                                  src={i5}
                                  alt=""
                                  className={modules["custom-img-size"]}
                                />
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  window.open(
                                    `/ccpm/ViewIsuePopup?projId=${item.projectsid}`,
                                    "sharer",
                                    "toolbar=0,status=0,width=1050,height=600"
                                  );
                                }}
                              >
                                <a href="#">Issues</a>
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  window.open(
                                    `/ccpm/ViewActiveProjectsHistory?projId=${item.projectsid}`,
                                    "sharer",
                                    "toolbar=0,status=0,width=1050,height=600"
                                  );
                                }}
                              >
                                <a href="#">View History</a>
                              </TableCell>
                              <TableCell>
                                <img
                                  src={viewp1}
                                  alt=""
                                  className={modules["custom-img-size"]}
                                  onClick={() => {
                                    window.open(
                                      `/ccpm/activeproject/FeverChart?projId=${item.projectsid}`,
                                      "sharer",
                                      "toolbar=0,status=0,width=1050,height=600"
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell onClick={() => handleBufferRecovery(item.projectsid)}>
                                <a href="#">View Buffer Recovery</a>
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  window.open(
                                    `/ccpm/projectProperties?projId=${item.projectsid}`,
                                    "sharer",
                                    "toolbar=0,status=0,width=1050,height=600"
                                  );
                                }}
                              >
                                <a href="#">View Project Properties</a>
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  window.open(
                                    `/ccpm/PendingTaskView?projId=${item.projectsid}`,
                                    "sharer",
                                    "toolbar=0,status=0,width=1050,height=600"
                                  );
                                }}
                              >
                                <a href="#">Pending Tasks on CC</a>
                              </TableCell>
                              <TableCell>
                                <a href="#">Show Gantt Chart</a>
                              </TableCell>
                              <TableCell>
                                <img
                                  src={viewp2}
                                  alt=""
                                  className={modules["custom-img-size"]}
                                  onClick={() => {
                                    window.open(
                                      `/ccpm/activeproject/Networkchart?projId=${item.projectsid}`,
                                      "sharer",
                                      "toolbar=0,status=0,width=1050,height=600"
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <img
                                  src={viewp3}
                                  alt=""
                                  className={modules["custom-img-size"]}
                                  onClick={() =>
                                    DownloadHandler(item.projectsid)
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <img
                                  src={viewp3}
                                  alt=""
                                  className={modules["custom-img-size"]}
                                  onClick={() =>
                                    DownloadHandler2(item.projectsid)
                                  }
                                />
                              </TableCell>
                              <TableCell>{item.file1}</TableCell>
                              <TableCell>{item.instance}</TableCell>
                              {headers.map((i) => {
                                return (
                                  <TableCell>
                                    {item[`a${i.attributenumber}`]}
                                  </TableCell>
                                );
                              })}
                              <TableCell>
                                {item.createdon && formatDate(item.createdon)}
                                {/* {item.createdon &&
                                  new Date(item.createdon).toLocaleDateString(
                                    "en-GB"
                                  )} */}
                              </TableCell>
                              <TableCell>
                                {item.lastmodifiedon && formatDate(item.lastmodifiedon)}
                                {/* {item.lastmodifiedon &&
                                  new Date(
                                    item.lastmodifiedon
                                  ).toLocaleDateString("en-GB")} */}
                              </TableCell>
                              <TableCell>
                                {item.lasttaskupdationdate && formatDate(item.lasttaskupdationdate)}
                                {/* {item.lasttaskupdationdate &&
                                  new Date(
                                    item.lasttaskupdationdate
                                  ).toLocaleDateString("en-GB")} */}
                              </TableCell>
                            </TableRow>
                          ))}

                        {totalPages > 1 && (
                          <TableRow>
                            <TableCell colSpan={16}>
                              <div className={modules["pagination"]}>
                                <span
                                  className={
                                    currentPage === 1
                                      ? `${modules["disabled"]}`
                                      : ""
                                  }
                                  onClick={() => handlePageChange("prev")}
                                >
                                  Prev
                                </span>
                                {getPageNumbers().map((pageNumber, index) => (
                                  <span
                                    key={index}
                                    className={
                                      pageNumber === currentPage
                                        ? `${modules["active-page"]}`
                                        : `${modules["page-number"]}`
                                    }
                                    onClick={() =>
                                      typeof pageNumber === "number"
                                        ? handlePageChange(pageNumber)
                                        : null
                                    }
                                  >
                                    {pageNumber}
                                  </span>
                                ))}
                                <span
                                  className={
                                    currentPage === totalPages
                                      ? `${modules["disabled"]}`
                                      : ""
                                  }
                                  onClick={() => handlePageChange("next")}
                                >
                                  Next
                                </span>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                ) : isLoading ? "" : (
                  <div
                    style={{ marginLeft: "1rem", marginTop: "1rem" }}
                    className={modules["bodyFont"]}
                  >
                    No Record Found
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewProjects;
