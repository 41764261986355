import "gantt-task-react/dist/index.css"

import React from "react"
import { ViewMode } from "gantt-task-react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export const ViewSwitcher = ({ onViewModeChange, onViewListChange, isChecked }) => {
  return (
    <div className='ViewContainer'>
        <FormControl>
      {/* <FormLabel id="demo-row-radio-buttons-group-label">Day</FormLabel> */}
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="day" control={<Radio  onClick={() => onViewModeChange(ViewMode.Day)}/>} label="Day" />
        <FormControlLabel value="week" control={<Radio onClick={() => onViewModeChange(ViewMode.Week)} />} label="week" />
        <FormControlLabel value="month" control={<Radio  onClick={() => onViewModeChange(ViewMode.Month)}/>} label="month" />
        <FormControlLabel value="quarter" control={<Radio  onClick={() => onViewModeChange(ViewMode.QuarterDay)}/>} label=" Quarter of Day" />
      </RadioGroup>
    </FormControl>

      {/* <button className='Button' onClick={() => onViewModeChange(ViewMode.Hour)}>
        Hour
      </button>
      <button className='Button' onClick={() => onViewModeChange(ViewMode.QuarterDay)}>
        Quarter of Day
      </button>
      <button className='Button' onClick={() => onViewModeChange(ViewMode.HalfDay)}>
        Half of Day
      </button>
      <button className='Button' onClick={() => onViewModeChange(ViewMode.Day)}>
        Day
      </button>
      <button className='Button' onClick={() => onViewModeChange(ViewMode.Week)}>
        Week
      </button>
      <button className='Button' onClick={() => onViewModeChange(ViewMode.Month)}>
        Month
      </button> */}

      {/* <div className='Switch'>
        <label className='Switch_Toggle'>
          <input
            type='checkbox'
            defaultChecked={isChecked}
            onClick={() => onViewListChange(!isChecked)}
          />
          <span className='Slider' />
        </label>
        Show Task List
      </div> */}
    </div>
  )
}
