import React, { useEffect, useState } from "react";
import window_popup from "../../Assets/window_popup.png";
import bread from "../../Assets/bread.png";
import Home from "../../home/Home";
import modules from "./Admin.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";
import SideBar from "../../Landing/Navbar";
import { APR_GetRolesForParent, APR_SaveRoles } from "../../Services/Admin";
import SampleSidebar from "../../Landing/SampleSidebar";
import loading from "../../Assets/Loading_image.gif";

const AssignParentRole = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [popupData, setPopupData] = useState({});
  const ChannelOne = new BroadcastChannel("roleChannel");

  ChannelOne.onmessage = (e) => {
    setPopupData({
      [e.data.mainrole]: e.data.selectedData,
      mainroleid: e.data.mainrole,
      roleidslist: e.data.selectedData.roleIds,
    });
  };

  useEffect(() => {
    getRoleValues();
  }, []);

  function getRoleValues() {
    setIsLoading(true);
    APR_GetRolesForParent()
      .then((response) => {
        if (response?.status === 200) {
          setData(response?.data);
          setIsLoading(false);
        } else{
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  function saveHandler() {
    if (popupData.mainroleid) {
      setIsLoading(true);
      APR_SaveRoles(popupData.mainroleid, popupData.roleidslist, 1)
        .then((response) => {
          if (response.status === 200) {
            alert(response.data.message);
            setIsLoading(false);
            setPopupData({});
            getRoleValues();
          } else if (response.response.status === 400) {
            alert(response.response.data);
            setIsLoading(false);
          } else {
            alert(response.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div>
        <Grid container>
          <Grid item xs={1} className={modules["main_cont"]}>
            {/* <SideBar /> */}
            <SampleSidebar />
          </Grid>

          <Grid item xs={12}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"AssignParentRole"}/>
            </Grid>
            <div className={modules["usermaster_div"]}>
              <div className={modules["pro-bread"]}>
                <p>
                  <a>Admin</a>
                  <img src={bread} alt="" />
                  <a>Restrict User Roles Mapping</a>
                </p>
              </div>
            </div>
            <div className={modules["usermaster2_div"]}>
              <TableContainer style={{ marginTop: "20px" }}>
                <Table className={modules["RM_table"]}>
                  <TableHead className={modules["table_head"]}>
                    <TableRow
                      sx={{
                        "& th": {
                          color: "#fff",
                        },
                      }}
                    >
                      <TableCell align="left">Sr. <br/> No.</TableCell>
                      <TableCell align="left">Role Name</TableCell>
                      <TableCell align="left">Role Description</TableCell>
                      <TableCell align="left">Parent Roles</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={modules["RM_tableBody"]}>
                    {data?.map((i) => {
                      // const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          key={i.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{i.id}</TableCell>
                          <TableCell align="left">{i.rolename}</TableCell>
                          <TableCell align="left">{i.roledesc}</TableCell>
                          <TableCell align="left" sx={{ color: "#0000EE" }}>
                            <textarea
                              readOnly
                              className={modules["textarea"]}
                              rows="2"
                              cols="30"
                              value={
                                popupData[i.roleid]?.empty === "false" ||
                                popupData[i.roleid]?.empty === "true"
                                  ? popupData[i.roleid]?.roleNames
                                  : i.parentroles
                              }
                            />
                            <br/>
                            <img
                              src={window_popup}
                              alt=""
                              onClick={() => {
                                window.open(
                                  `/admin/assignParentPopUp?roleId=${i.roleid}`,
                                  "mypopuptitle",
                                  "width=600,height=500"
                                );
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack
                direction="row"
                spacing={2}
                sx={{ marginTop: "18px", marginBottom: "20px" }}
              >
                <button
                  type="button"
                  className={modules["customBtn"]}
                  onClick={() => saveHandler()}
                >
                  Save
                </button>
              </Stack>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AssignParentRole;
