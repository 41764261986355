import React, { useState, useEffect, useRef } from "react";
import bread from "../../../../Assets/bread.png";
import Home from "../../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  Popover,
  Stack,
} from "@mui/material";
import modules from "../../ccpm.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SampleSidebar from "../../../../Landing/SampleSidebar";
import loading from "../../../../Assets/Loading_image.gif";
import Box from "@mui/material/Box";
import {
  C_getCalenderRules,
  WL_GetDropdown,
  getExistingCalender,
  AddCalenderDetails,
  RemoveCalenderDetails,
  C_DeleteRules,
  CopyCalenderDetails,
  addWorkingHours,
  getWorkingHoursDs,
  C_GetMainCalHours,
  C_GetMainCalDiffHours,
} from "../../../../Services/CCPM";

const CalendarMaster = () => {
  //loading gif
  const [isLoading, setIsLoading] = useState(false);

  const [Instancevalue, setInstanceValue] = useState(null);
  const [Instanaceoptions, setInstanceOptions] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [existingCalander, setExistingCalander] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkedRows, setCheckedRows] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [inputTemplateName, setInputTemplateName] = useState("");
  const [workinghour, setWorkinghour] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDayP, setSelectedDayP] = useState(null);
  const [selectedDayN, setSelectedDayN] = useState(null);
  const [existingCalanderDisplay, setExistingCalanderDisplay] = useState("");
  const [existingCalenderNames, setExistingCalenderNames] = useState([]);
  const [existingCalenderRules, setExistingCalenderRules] = useState([]);
  const [mainWorkingHours, setMainWorkingHours] = useState("");
  const [workinghrData, setWorkingHrData] = useState([]);
  const [restrictAddRule, setRestrictAddRule] = useState(false);
  const [hours, setHours] = useState([
    { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
    { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
    { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
    { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
    { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
    { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
    { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
  ]);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/"; // Replace '/login' with the actual path to your login page
      }
    };
    checkLoginAndRedirect();
    getInstances();
  }, []);

  useEffect(() => {
    const allChecked =
      existingCalenderRules?.length > 0 &&
      existingCalenderRules?.every((item) => checkedRows[item.id]);
    setSelectAllChecked(allChecked);
  }, [checkedRows, existingCalenderRules]);

  //main page

  const handleInputChange = (index, field, value) => {
    const validValue = value.replace(/[^0-9:]/g, "");
    const newHours = [...hours];
    if (field === "fromHours" || field === "toHours") {
      newHours[index][field] = validValue;
    } else {
      newHours[index][field] = value;
    }
    setHours(newHours);
  };

  const TableFromAndToHours = () => {
    return (
      <table border="0" className={modules["cTableHours"]}>
        <tbody>
          <tr>
            <td>
              <strong>From Hours</strong>
            </td>
            <td>
              <strong>&nbsp;</strong>
            </td>
            <td>
              <strong>To Hours</strong>
            </td>
          </tr>
          {hours.map((hour, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  maxLength="5"
                  value={hour.fromHours}
                  className={modules["inputCpop"]}
                  onChange={(e) =>
                    handleInputChange(index, "fromHours", e.target.value)
                  }
                  style={{ width: "65px" }}
                />
              </td>
              <td>
                <select
                  className={modules["cSelectT"]}
                  value={hour.fromPeriod}
                  onChange={(e) =>
                    handleInputChange(index, "fromPeriod", e.target.value)
                  }
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  maxLength="5"
                  value={hour.toHours}
                  onChange={(e) =>
                    handleInputChange(index, "toHours", e.target.value)
                  }
                  style={{ width: "65px" }}
                />
              </td>
              <td>
                <select
                  className={modules["cSelectT"]}
                  value={hour.toPeriod}
                  onChange={(e) =>
                    handleInputChange(index, "toPeriod", e.target.value)
                  }
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const getInstances = async () => {
    setIsLoading(true);
    try {
      let body = {
        userId: sessionStorage.getItem("loginId"),
      };
      let response = await WL_GetDropdown(body);
      if (response.status === 200) {
        const apiData = response.data.success.data;
        setInstanceValue(
          response.data.success.data.length
            ? response.data.success.data[0].id
            : null
        );
        getExistingCalenderDetails(response.data.success.data[0].id);
        const results = [];
        apiData.forEach((value) => {
          results.push({
            key: value.name,
            value: value.id,
          });
        });
        setInstanceOptions([...results]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInstance = (id) => {
    setInstanceValue(id);
    getExistingCalenderDetails(id);
  };

  const handleExChange = (e) => {
    setInputValue("");
    if (e.target.value === "-1") {
      setExistingCalander(false);
      setExistingCalenderRules([]);
      setExistingCalanderDisplay(e.target.value);
      setRestrictAddRule(true);
      alert("Please Add Working Hrs.");
      setRestrictAddRule(true);
      return;
    }
    setExistingCalanderDisplay(e.target.value);
    GetCalenderRules(e.target.value);
    getWorkingHrsDs(e.target.value);
    getMainHoursCal(e.target.value);
    setExistingCalander(true);
    setRestrictAddRule(false);
  };

  const getExistingCalenderDetails = async (instanceId) => {
    try {
      let obj = {
        instaceId: instanceId,
      };
      let response = await getExistingCalender(obj);
      if (response?.data?.statusCode === 200) {
        setExistingCalenderNames(response?.data?.success?.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const CopyCalender = async () => {
    if (inputTemplateName === "" || existingCalanderDisplay === "") {
      alert("Calendar Name already Exists");
      return;
    }
    const createdBy = sessionStorage.getItem("loginId");
    try {
      setIsLoading(true);
      const body = {
        calendarId: parseInt(existingCalanderDisplay),
        calendarName: inputTemplateName,
        userId: parseInt(createdBy),
      };

      let response = await CopyCalenderDetails(body);

      if (response?.data?.statusCode === 200) {
        const successData = response?.data?.success?.data;
        const calenderModifications =
          successData?.["Existing Calender modification"];

        if (calenderModifications && calenderModifications.length > 0) {
          setExistingCalenderNames(calenderModifications);
          const foundCalendar = calenderModifications.find(
            (calendar) => calendar.calendarname === inputTemplateName
          );
          setExistingCalanderDisplay(foundCalendar.calendarid);
          alert("Template Created Successfully");
          GetCalenderRules(foundCalendar.calendarid);
          getWorkingHrsDs(foundCalendar.calendarid);
          getMainHoursCal(foundCalendar.calendarid);
          setInputTemplateName("");
        } else {
          alert(successData?.["true_Alert"]);
        }
      } else {
        alert(response?.error?.message || "An error occurred.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWorkingHrsDs = async (c_id) => {
    try {
      setIsLoading(true);
      let obj = {
        calendarId: parseInt(c_id),
      };
      let response = await getWorkingHoursDs(obj);
      if (response?.data?.statusCode === 200) {
        setWorkinghour(response?.data?.success?.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidTimeFormat = (time) => {
    const timeFormatRegex = /^(\d{1,2}):(\d{2})\s(AM|PM)$/;
    return timeFormatRegex.test(time);
  };

  const convertTo24HourFormat = (time) => {
    const [timePart, period] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  };

  const addHoursClick = async (hours) => {
    const createdBy = sessionStorage.getItem("loginId");
    const filteredHours = hours.filter(
      (hour) => hour.fromHours && hour.toHours
    );

    if (filteredHours.length === 0) {
      alert("Working Hours cannot be blank.");
      return;
    }

    const firstHour = hours[0];
    if (!firstHour.fromHours || !firstHour.toHours) {
      alert("Working Hours cannot be blank.");
      return;
    }

    for (const hour of filteredHours) {
      if (
        !isValidTimeFormat(`${hour.fromHours} ${hour.fromPeriod}`) ||
        !isValidTimeFormat(`${hour.toHours} ${hour.toPeriod}`)
      ) {
        alert("Invalid time. Time format should be HH:MM AM/PM.");
        return;
      }

      const [fromHour, fromMinute] = hour.fromHours.split(":").map(Number);
      const [toHour, toMinute] = hour.toHours.split(":").map(Number);

      if (fromHour < 1 || fromHour > 12) {
        alert("Invalid time. Hour cannot be greater than 12.");
        return;
      }
      if (fromMinute < 0 || fromMinute > 59) {
        alert("Invalid time. Minute cannot be more than 59.");
        return;
      }
      if (toHour < 1 || toHour > 12) {
        alert("Invalid time. Hour cannot be greater than 12.");
        return;
      }
      if (toMinute < 0 || toMinute > 59) {
        alert("Invalid time. Minute cannot be more than 59.");
        return;
      }

      const fromTime = convertTo24HourFormat(
        `${hour.fromHours} ${hour.fromPeriod}`
      );
      const toTime = convertTo24HourFormat(`${hour.toHours} ${hour.toPeriod}`);

      if (fromTime > toTime) {
        alert("Working From Time Can not be Greater Than To Time.");
        return;
      }
    }

    if (existingCalanderDisplay === "") {
      alert("Please Select Calendar");
      return;
    }

    try {
      setIsLoading(true);
      const body = filteredHours.map((hour) => ({
        calendarId: existingCalanderDisplay,
        hoursFrom: `${hour.fromHours} ${hour.fromPeriod}`,
        hoursTo: `${hour.toHours} ${hour.toPeriod}`,
        uId: createdBy,
      }));
      const response = await addWorkingHours(body);
      if (response?.data?.statusCode === 200) {
        alert(response?.data?.success?.data["Alert-true"]);
        setIsChecked(false);
        getWorkingHrsDs(existingCalanderDisplay);
        getMainHoursCal(existingCalanderDisplay);
        setExistingCalander(true);
        setHours([
          { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
          { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
          { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
          { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
          { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
          { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
          { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
        ]);
      } else {
        alert(response?.error?.message || "An error occurred");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const AddCalenderData = async () => {
    if (inputValue === "") {
      alert("Please Enter Calender Name");
      return;
    }
    try {
      setIsLoading(true);
      let obj = {
        calendarName: inputValue,
        createdBy: sessionStorage.getItem("loginId"),
        isDefault: isChecked === false ? 0 : 1,
        level2Id: parseInt(Instancevalue),
      };
      let res = await AddCalenderDetails(obj);
      if (res?.data?.statusCode === 200) {
        alert(res?.data?.success?.data?.Aert);
        setInputValue("");
        setExistingCalenderNames(res?.data?.success?.data?.CalenderNameList);
        const dummyWorkingHour = [
          {
            hoursfrom: "6:00 AM",
            hoursto: "2:00 PM",
          },
        ];
        setWorkinghour(dummyWorkingHour);
        setMainWorkingHours("8.00");
        setExistingCalanderDisplay("-1");
        setExistingCalander(true);
      } else {
        alert(res?.response?.data?.error?.message);
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const RemoveCalenderData = async (existingCalanderId) => {
    console.log("existingCalanderId", existingCalanderId);
    if (existingCalanderId === -1) {
      alert("Please Select Calender Name");
      return;
    } else {
      setIsLoading(true);
      try {
        let obj = { calendarId: existingCalanderId };
        let response = await RemoveCalenderDetails(obj);

        if (response?.data?.statusCode === 200) {
          const successData = response?.data?.success?.data;
          setInputValue("");
          const calenderModifications =
            successData?.["Existing Calender modification"];

          if (calenderModifications && calenderModifications.length > 0) {
            setExistingCalenderNames(calenderModifications);
          }
          const alertMessage =
            successData?.true_Alert || "Details Deleted Succesfully";
          alert(alertMessage);
          setExistingCalander(false);
        } else {
          alert(response?.error?.message || "Please Select Calender Name.");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleReset = () => {
    setIsChecked(false);
    getInstances();
    setExistingCalander(false);
    setSelectAllChecked(false);
    setCheckedRows({});
    setInputValue("");
    setInputTemplateName("");
    setWorkinghour([]);
    setCurrentDate(new Date());
    setSelectedDay(null);
    setSelectedDayP(null);
    setSelectedDayN(null);
    setExistingCalanderDisplay("-1");
    setExistingCalenderRules([]);
    setMainWorkingHours("");
    setHours([
      { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
      { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
      { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
      { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
      { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
      { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
      { fromHours: "", fromPeriod: "AM", toHours: "", toPeriod: "AM" },
    ]);
    setDifferentWorkingHoursDates([]);
  };

  const handleHistoryClick = (target) => {
    target.style.color = "red";
    setTimeout(() => {
      window.location.href = "/ccpm/CalendarHistoryLog";
      target.style.color = "#0000EE";
    }, 50);
  };

  //delete toggle
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    const newCheckedItems = {};
    existingCalenderRules?.forEach((item) => {
      newCheckedItems[item.id] = isChecked;
    });
    setCheckedRows(newCheckedItems);
  };

  const handleCheckboxChange = (event, id) => {
    setCheckedRows((prev) => ({
      ...prev,
      [id]: event.target.checked,
    }));
  };

  // rules part
  const GetCalenderRules = (c_id) => {
    setIsLoading(true);
    const body = {
      calendarId: c_id,
    };
    C_getCalenderRules(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          if (response?.data?.success?.data !== null) {
            setExistingCalenderRules(response?.data?.success?.data);
          } else {
            setExistingCalenderRules([]);
          }
        } else {
          setExistingCalenderRules([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteRule = (e) => {
    e.preventDefault();
    const selectedRules = Object.keys(checkedRows).filter(
      (key) => checkedRows[key]
    );
    if (selectedRules.length === 0) {
      return;
    } else {
      setIsLoading(true);
      const body = {
        ruleIds: selectedRules,
      };
      C_DeleteRules(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            alert("Details Deleted Succesfully");
            GetCalenderRules(existingCalanderDisplay);
            getMainHoursDiff(existingCalanderDisplay, workinghrData);
            setCheckedRows({});
            // window.location.reload();
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const addRuleClick = () => {
    if (
      existingCalanderDisplay === "" ||
      existingCalanderDisplay === "-1" ||
      restrictAddRule === true
    ) {
      return;
    } else {
      window.open(
        `/ccpm/AddRule?CalenderID=${existingCalanderDisplay}`,
        "mypopuptitle",
        "width=1050,height=600"
      );
    }
  };

  //main calendar and table
  const [differentWorkingHoursDates, setDifferentWorkingHoursDates] = useState(
    []
  );

  const getMainHoursDiff = async (c_id, prevData) => {
    try {
      setIsLoading(true);
      let obj = {
        calendarId: parseInt(c_id),
      };
      let response = await C_GetMainCalDiffHours(obj);
      if (response?.data?.statusCode === 200) {
        // console.log(response.data.success.data, "response");

        const filteredDates = response.data.success.data.map((item) => {
          const totalMinutes = item.hoursfrom * 60; // Convert hours to minutes
          const hours = Math.floor(totalMinutes / 60); // Get hours
          const minutes = totalMinutes % 60; // Get remaining minutes

          // Format hours and minutes to "h:mm" format
          const formattedHours = `${hours}.${String(minutes).padStart(2, "0")}`;

          return {
            date: item.dates.split("T")[0], // Format the date
            hours: formattedHours, // Use formatted h:mm
          };
        });
        // console.log("filteredDates", filteredDates);
        setMainWorkingHours(prevData?.workinghours?.toFixed(2));
        setDifferentWorkingHoursDates(filteredDates);
      } else {
        setMainWorkingHours(prevData?.workinghours?.toFixed(2));
        setDifferentWorkingHoursDates([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMainHoursCal = async (c_id) => {
    try {
      setIsLoading(true);
      let obj = {
        calendarId: parseInt(c_id),
      };
      let response = await C_GetMainCalHours(obj);
      if (response?.data?.statusCode === 200) {
        if (response?.data?.success?.data === null) {
          setMainWorkingHours("");
          alert("Please Add Working Hrs.");
          setRestrictAddRule(true);
          return;
        } else {
          const resData = response?.data?.success?.data[0];
          setWorkingHrData(resData);
          getMainHoursDiff(c_id, resData);
          setRestrictAddRule(false);
        }
      } else {
        setMainWorkingHours("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const changeMonth = (increment) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + increment);
    setCurrentDate(newDate);
  };

  const renderCalendar = () => {
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const firstDay = new Date(year, month - 1, 1).getDay(); // Get the first day (0 = Sunday)
    const daysInMonth = new Date(year, month, 0).getDate();
    const daysInPreviousMonth = new Date(year, month - 1, 0).getDate();

    const days = [];

    // Adjust firstDay to align with your week starting on Monday
    const adjustedFirstDay = firstDay === 0 ? 6 : firstDay - 1; // Sunday becomes 6, others are shifted by -1

    // Empty cells for the previous month
    for (let i = adjustedFirstDay; i > 0; i--) {
      days.push(
        <td
          key={`prev-${i}`}
          onClick={() => {
            setSelectedDayN(null);
            setSelectedDay(null);
            setSelectedDayP(daysInPreviousMonth - i + 1);
          }}
          style={{
            backgroundColor:
              selectedDayP === daysInPreviousMonth - i + 1
                ? "silver"
                : "transparent",
            color:
              selectedDayP === daysInPreviousMonth - i + 1 ? "white" : "black",
          }}
        >
          <span
            style={{
              color:
                selectedDayP === daysInPreviousMonth - i + 1
                  ? "white"
                  : "black",
            }}
          >
            {daysInPreviousMonth - i + 1}
          </span>
        </td>
      );
    }

    // Days of the current month
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(
        <td
          key={i}
          onClick={() => {
            setSelectedDayN(null);
            setSelectedDay(i);
            setSelectedDayP(null);
          }}
          style={{
            backgroundColor: selectedDay === i ? "silver" : "transparent",
            color: selectedDay === i ? "white" : "black",
          }}
        >
          <a style={{ color: selectedDay === i ? "white" : "black" }}>{i}</a>
        </td>
      );
    }

    const totalCells = 42; // 6 weeks * 7 days
    const nextMonthDaysNeeded = totalCells - days.length;

    // Days for the next month
    for (let i = 1; i <= nextMonthDaysNeeded; i++) {
      days.push(
        <td
          key={`next-${i}`}
          onClick={() => {
            setSelectedDayN(i);
            setSelectedDay(null);
            setSelectedDayP(null);
          }}
          style={{
            backgroundColor: selectedDayN === i ? "silver" : "transparent",
            color: selectedDayN === i ? "white" : "black",
          }}
        >
          <span style={{ color: selectedDayN === i ? "white" : "black" }}>
            {i}
          </span>
        </td>
      );
    }

    const weeks = [];
    for (let i = 0; i < days.length; i += 7) {
      weeks.push(<tr key={i}>{days.slice(i, i + 7)}</tr>);
    }

    return (
      <table className={modules["calendarMainTable"]}>
        <thead>
          <tr>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
            <th>Sun</th>
          </tr>
        </thead>
        <tbody>{weeks}</tbody>
      </table>
    );
  };

  const prepareDates = () => {
    console.log(currentDate, "currentDate");

    const month = currentDate.getMonth() + 1; // Correct (returns 1-based month)
    const year = currentDate.getFullYear(); // Correct (returns year)
    let firstDay = new Date(year, month - 1, 1).getDay(); // First day of the month
    console.log(firstDay, "firstDay klnnkl");
    const dayMapping = [7, 1, 2, 3, 4, 5, 6];
    firstDay = dayMapping[firstDay];
    // firstDay = firstDay === 0 ? 6 : firstDay; // Handle Sunday (0) as 6 (Saturday)
    const daysInMonth = new Date(year, month, 0).getDate(); // Number of days in month
    const dates = [];

    console.log(firstDay, "firstDay");
    console.log(month, "month");
    console.log(year, "year");
    console.log(daysInMonth, "daysInMonth");

    // debugger;

    // Empty cells for days before the start of the month
    for (let i = 1; i < firstDay; i++) {
      dates.push({ date: "", time: "" });
    }

    // Actual days of the month
    for (let i = 1; i <= daysInMonth; i++) {
      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        i < 10 ? "0" + i : i
      }`;
      const differentDay = differentWorkingHoursDates.find(
        (d) => d.date === formattedDate
      );
      // console.log(differentWorkingHoursDates, "differentWorkingHoursDates");
      // console.log(formattedDate, "formattedDate");
      // console.log(differentDay, "ddd");
      dates.push({
        date: i,
        time: differentDay
          ? differentDay.hours !== null
            ? differentDay.hours
            : "0.00"
          : mainWorkingHours,
        isDifferent: !!differentDay,
      });
    }

    // Empty cells for days before the end of the month
    const totalCells = 37;
    for (let i = dates.length; i < totalCells; i++) {
      dates.push({ date: "", time: "" });
    }

    return dates;
  };

  const dates = prepareDates();

  const getFormattedDate = (monthYear, day) => {
    const monthNames = {
      January: "1",
      February: "2",
      March: "3",
      April: "4",
      May: "5",
      June: "6",
      July: "7",
      August: "8",
      September: "9",
      October: "10",
      November: "11",
      December: "12",
    };
    const [month, year] = monthYear.split(" ");
    const monthNumber = monthNames[month];
    return monthNumber ? `${year}-${monthNumber}-${day}` : null;
  };

  const handleCalendarHoursClick = (target, date) => {
    target.style.color = "red";
    const monthYear = currentDate.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
    const formattedDate = getFormattedDate(monthYear, date.date);

    setTimeout(() => {
      if (date.isDifferent) {
        window.open(
          `/ccpm/Calendar/WorkingNonworkingHrsPopup?date=${formattedDate}&calendarId=${existingCalanderDisplay}&status=1`,
          "sharer",
          "toolbar=0,status=0,width=1050,height=600"
        );
      } else {
        window.open(
          `/ccpm/Calendar/WorkingNonworkingHrsPopup?date=${formattedDate}&calendarId=${existingCalanderDisplay}&status=0`,
          "sharer",
          "toolbar=0,status=0,width=1050,height=600"
        );
      }
      target.style.color = "#0000EE";
    }, 50);
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"Calendar"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Project Plan</a>
                <img src={bread} alt="" />
                <a>Calendar</a>
              </div>
            </div>
            <Stack direction="row" className={modules["Cparent-container"]}>
              <Stack direction="column" spacing={1} sx={{ marginLeft: "1px" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Typography className={modules["Cmid-cont"]}>
                    Instance
                  </Typography>
                  <span>: </span>
                  <select
                    className={modules["dropdownSelect_cal"]}
                    value={Instancevalue}
                    style={{ width: "235px" }}
                    onChange={(e) => handleInstance(e.target.value)}
                  >
                    {Instanaceoptions.map((i) => (
                      <option value={i.value} key={i.value}>
                        {i.key}
                      </option>
                    ))}
                  </select>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Typography className={modules["Cmid-cont"]}>
                    Calendar Name
                  </Typography>

                  <span>: </span>
                  <Stack sx={{ width: "20rem" }}>
                    <input
                      type="text"
                      style={{ width: "210px" }}
                      value={inputValue}
                      className={modules["cInput"]}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[a-zA-Z0-9&()[\].\/\-_=+ ]*$/;
                        if (regex.test(value) && value.length <= 50) {
                          setInputValue(value);
                        }
                      }}
                      onBlur={(e) => {
                        const value = e.target.value;
                        const invalidCharsRegex = /[[\]\/=+.]/;
                        if (invalidCharsRegex.test(value)) {
                          alert("No Special Characters Allowed");
                          setInputValue("");
                        }
                      }}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ width: "200px" }}>
                    <button
                      className={modules["Cbtn"]}
                      onClick={AddCalenderData}
                    >
                      Add
                    </button>
                    <span>&nbsp;</span>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Typography className={modules["Cmid-cont"]}>
                    Set As Default
                  </Typography>
                  <span>: </span>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    className={modules["custom-checkbox"]}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Typography className={modules["Cmid-cont"]}>
                    Existing Calendar
                  </Typography>
                  <span>: </span>
                  <Stack sx={{ width: "20rem" }}>
                    <select
                      className={modules["dropdownSelect_cal"]}
                      style={{ width: "210px" }}
                      value={existingCalanderDisplay}
                      onChange={(e) => handleExChange(e)}
                    >
                      <option value={-1}> SELECT </option>
                      {existingCalenderNames?.map((i) => (
                        <option value={i.calendarid} key={i.calendarname}>
                          {i.calendarname}
                        </option>
                      ))}
                    </select>
                  </Stack>
                  <Stack direction="row" sx={{ width: "200px" }}>
                    <button
                      onClick={() => {
                        RemoveCalenderData(existingCalanderDisplay);
                      }}
                      className={modules["Cbtn"]}
                    >
                      Remove Calendar
                    </button>
                    <span>&nbsp;</span>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Typography className={modules["Cmid-cont"]}>
                    Calendar Template Name
                  </Typography>
                  <span>: </span>
                  <Stack sx={{ width: "20rem" }}>
                    <input
                      type="text"
                      style={{ width: "210px" }}
                      value={inputTemplateName}
                      className={modules["cInput"]}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[a-zA-Z0-9&()[\].\/\-_=+ ]*$/;
                        if (regex.test(value) && value.length <= 50) {
                          setInputTemplateName(value);
                        }
                      }}
                      onBlur={(e) => {
                        const value = e.target.value;
                        const invalidCharsRegex = /[[\]\/=+.]/;
                        if (invalidCharsRegex.test(value)) {
                          alert("No Special Characters Allowed");
                          setInputTemplateName("");
                        }
                      }}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ width: "200px" }}>
                    <button
                      className={modules["Cbtn"]}
                      onClick={() => {
                        CopyCalender(inputTemplateName);
                      }}
                    >
                      Create a Copy
                    </button>
                    <span>&nbsp;</span>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Typography
                    className={modules["Cmid-cont"]}
                    sx={{ marginTop: "6px !important" }}
                  >
                    Working Hour
                  </Typography>
                  <span style={{ marginTop: "6px" }}>: </span>
                  <Box
                    sx={{
                      border: "1px solid #000",
                      padding: "3px",
                      width: "312px",
                    }}
                  >
                    {TableFromAndToHours()}
                  </Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    // sx={{ marginLeft: "2px !important" }}
                  >
                    <button
                      className={modules["Cbtn"]}
                      onClick={() => {
                        addHoursClick(hours);
                      }}
                    >
                      Add Hrs
                    </button>
                    <button className={modules["Cbtn"]} onClick={handleReset}>
                      Reset
                    </button>
                  </Stack>
                </Stack>
                <span className={modules["Cnote"]}>
                  <span style={{ fontWeight: "bold" }}>* Note -</span> To Create
                  Template of New or Existing Calender, Kindly Enter Calendar
                  Template Name and Click on Create Copy.
                </span>
              </Stack>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <button onClick={addRuleClick} className={modules["Cbtn"]}>
                    Add Rule
                  </button>
                  <button
                    className={modules["Cbtn"]}
                    onClick={(e) => handleDeleteRule(e)}
                  >
                    Delete Rule
                  </button>
                  <span
                    style={{
                      color: "#0000EE",
                      textDecoration: "underline",
                      fontSize: "12px",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={(e) => handleHistoryClick(e.currentTarget)}
                  >
                    History
                  </span>
                </Stack>
                {existingCalenderRules.length > 0 && (
                  <Grid container sx={{ marginTop: "1rem", width: "424px" }}>
                    <Table className={modules["customC_tableRules"]}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={selectAllChecked}
                              onChange={handleSelectAll}
                              className={modules["custom-checkbox"]}
                            ></input>
                          </TableCell>
                          <TableCell align="left">Rules</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {existingCalenderRules?.map((i, index) => {
                          return (
                            <TableRow
                              key={i.id}
                              className={
                                modules[
                                  index % 2 !== 0 ? "customC_tableRulesE" : ""
                                ]
                              }
                            >
                              <TableCell align="center">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, i.id)
                                  }
                                  checked={!!checkedRows[i.id]}
                                  className={modules["custom-checkbox"]}
                                ></input>
                              </TableCell>
                              <TableCell align="center">{i.dates}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                )}
              </Stack>
            </Stack>
          </Grid>

          <Grid item lg={12} style={{ marginTop: "2rem" }}>
            {existingCalander && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "2rem",
                  marginLeft: "7.5rem",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  <div
                    style={{
                      border: "1px solid black",
                      width: "266px",
                      height: "216px",
                    }}
                  >
                    <div className={modules["calendarMainTableHeader"]}>
                      <a
                        onClick={() => {
                          changeMonth(-1);
                          setSelectedDay(null);
                          setSelectedDayP(null);
                          setSelectedDayN(null);
                        }}
                      >
                        &le;
                      </a>
                      <a>
                        {currentDate.toLocaleString("default", {
                          month: "long",
                          year: "numeric",
                        })}
                      </a>
                      <a
                        onClick={() => {
                          changeMonth(1);
                          setSelectedDay(null);
                          setSelectedDayP(null);
                          setSelectedDayN(null);
                        }}
                      >
                        &ge;
                      </a>
                    </div>
                    {renderCalendar()}
                  </div>
                  {/* <p>Selected Date: {date.toDateString()}</p> */}

                  <Grid container sx={{ width: "190px", marginTop: "4%" }}>
                    {workinghour && workinghour.length > 0 && (
                      <TableContainer
                        sx={{ width: "100%", borderRadius: "0px" }}
                      >
                        <Table className={modules["customC_table"]}>
                          <TableHead>
                            <TableRow>
                              <TableCell>From Hours</TableCell>
                              <TableCell>To Hours</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {workinghour?.map((hour, index) => (
                              <TableRow key={index}>
                                <TableCell scope="row" align="center">
                                  {hour.hoursfrom}
                                </TableCell>
                                <TableCell scope="row" align="center">
                                  {hour.hoursto}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                </div>

                <Grid container sx={{ width: "762px", marginTop: "10px" }}>
                  <p className={modules["bodyFontBold"]}>
                    {currentDate.toLocaleString("default", {
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                  <Table className={modules["customC_table"]}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Monday</TableCell>
                        <TableCell>Tuesday</TableCell>
                        <TableCell>Wednesday</TableCell>
                        <TableCell>Thursday</TableCell>
                        <TableCell>Friday</TableCell>
                        <TableCell>Saturday</TableCell>
                        <TableCell>Sunday</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.from({ length: 6 }).map((_, weekIndex) => (
                        <TableRow key={weekIndex}>
                          {console.log(dates, "dates")}
                          {dates
                            .slice(weekIndex * 7, weekIndex * 7 + 7)
                            .map((dateObj, dateIndex) => (
                              <TableCell
                                scope="row"
                                key={dateIndex}
                                align="center"
                              >
                                {dateObj.date === "" ? (
                                  <a></a>
                                ) : dateObj.time === "0.00" ? (
                                  <a style={{ backgroundColor: "red" }}>
                                    {dateObj.date} ({dateObj.time})
                                  </a>
                                ) : (
                                  <a
                                    onClick={(e) =>
                                      handleCalendarHoursClick(
                                        e.currentTarget,
                                        dateObj
                                      )
                                    }
                                    style={{
                                      backgroundColor: dateObj.isDifferent
                                        ? "gray"
                                        : "transparent",
                                    }}
                                  >
                                    {dateObj.date} | {dateObj.time}
                                  </a>
                                )}
                              </TableCell>
                            ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* {CalenderAndTableUi()} */}
    </>
  );
};

export default CalendarMaster;
