import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import view1 from './images/view1.png'
import view2 from './images/view2.png'
import view3 from './images/view3.png'
import view4 from './images/view4.png'
import view5 from './images/view5.png'
import view6 from './images/view6.png'
import view7 from './images/view7.png'
import { Link } from "react-router-dom";

const ViewProjectsHelpPopup = () => {
    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            Expand it...
                            <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>
            </div>

            <Grid sx={{fontFamily: '"Calibri", sans-serif', fontSize:'14.6px', color:'#000000'}}>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul>
                            <h3 style={{ textDecoration: "underline", fontWeight: "bold" }}>View Projects</h3>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                                This session is created to view all projects at a glance for the instance.
                            </li>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                                <span style={{ textDecoration: 'underline' }}>Session Path</span> - CCPM &gt;&gt; Project Plan &gt;&gt; View Project
                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={view1} alt="myproject1" />
                            </Stack>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                                Instance – Select Instance from drop down provided.
                            </li>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                                Here many filters are given for drill down. But most important thing is only project manager or alternate project manger can handle this session. There are filters for Attributes, projects, Project Manager and many others. Also there is option of export to excel with front end display
                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={view2} alt="myproject2" />
                            </Stack>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                                A Date filter is added in the view project field. Where user can select one of the dates from following three dates
                                <br />
                                <ul style={{ listStyleType: 'circle', marginLeft: '5rem' }}>
                                    <br />
                                    <li>
                                        Expected /Actual End date – This date is one, which is shows the date on which project is supposed to be closed. It shows actual end date of the project if the project is already completed.
                                    </li>
                                    <li>
                                        Customer Due date – This date shows the customer due date which is maintained in the project while booking the project.
                                    </li>
                                    <li>
                                        Scheduled End Date – This is original scheduled end date which is arrived after staggering the project.
                                    </li>
                                </ul>
                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={view3} alt="myproject2" />
                            </Stack>
                            <br /><br />
                            <div style={{ textDecoration: 'underline' }}>
                                Below is the columns displayed on screen :-
                            </div>
                            <br />
                            <table className="view_inner_table">
                                <tbody>
                                    <tr>
                                        <td >Sr.No.</td>
                                    </tr>
                                    <tr>
                                        <td >TaskId</td>
                                    </tr>
                                    <tr>
                                        <td >Raise Issue</td>
                                    </tr>
                                    <tr>
                                        <td >Sub Task</td>
                                    </tr>
                                    <tr>
                                        <td>Project Name</td>
                                    </tr>
                                    <tr>
                                        <td>Department</td>
                                    </tr>
                                    <tr>
                                        <td>Task Name</td>
                                    </tr>
                                    <tr>
                                        <td>Task Manager</td>
                                    </tr>
                                    <tr>
                                        <td>Original Duration</td>
                                    </tr>
                                    <tr>
                                        <td>Projected Start</td>
                                    </tr>
                                    <tr>
                                        <td>Projected End</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                    </tr>
                                    <tr>
                                        <td>Task Process Notes</td>
                                    </tr>
                                    <tr>
                                        <td>User Notes</td>
                                    </tr>
                                    <tr>
                                        <td>Predecessor / Successor</td>
                                    </tr>
                                    <tr>
                                        <td>Task Update History</td>
                                    </tr>
                                    <tr>
                                        <td>Remaining Duration History</td>
                                    </tr>
                                    <tr>
                                        <td>Remaining Duration</td>
                                    </tr>
                                    <tr>
                                        <td>PO No</td>
                                    </tr>
                                    <tr>
                                        <td>Availability Status</td>
                                    </tr>
                                    <tr>
                                        <td>% Buffer Penetration</td>
                                    </tr>
                                    <tr>
                                        <td>Reason For Delay</td>
                                    </tr>
                                    <tr>
                                        <td>Secondary Reason For Delay</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={view4} alt="myproject2" />
                            </Stack>
                            <li style={{ listStyleType: 'none' }}>
                                When we search, report is displayed on the front end.
                                <br />
                                <ul style={{ marginLeft: '3rem', listStyleType: 'disc' }}>
                                    <br />
                                    <li>Modif No – This is indicating the modification no of the project. When project manager modifies the project then each time a modification no is generated for the same. This no will be displayed in this field.</li>
                                    <li>Project Name – This field indicated the project name. This field will be color coded based on Project/ Task color . The color on this field is similar to color shown in Task Management / Active projects.</li>
                                    <br />
                                    <li>Project Manager – This field indicates the project manger for the project.</li>
                                    <li>Project Status – This field indicates the current status of the project. Closed projects are not shown here.</li>
                                    <li>Customer Name – In this field customer name is displayed.</li>
                                    <li>Calendar – This field indicate which calendar is used while maintaining project. For each instance user hs maintained the calendar master. This is one of that master.</li>
                                    <li>ERP Company – This field indicates that the if ERP company maintained or linked to the project.</li>
                                    <li>ERP Project No – This field indicates the related project no maintained in the ERP.</li>
                                    <li>Project Duration – This field indicates the project duration of the project after applying CCPM. Project duration shown here is including project buffer after applying CCPM.</li>
                                    <li>% Buffer Consumed – In this field how much buffer in consumed from the project buffer is shown. There is project buffer for each project. When tasks are exceeded the planned duration then it uses the days from project buffer.</li>
                                    <li>% CC Completed – This field indicates that the how much critical chain is completed. When user updates task on critical chain, percentage of critical chain is calculated.</li>
                                    <li>Scheduled Start Date – This field is indicating the scheduled start date for the project. On this date it is supposed to start the project activities. The date here is shown after modification if it modification is done.</li>
                                    <li>Scheduled End Date – This field indicates that the scheduled End date. Project is supposed to  complete on this date.</li>
                                    <li>Actual Start Date -This date indicates that the actual start date of the project when it is marked in the project.</li>
                                    <li>Exp /Actual End Date – In this field End date of the project is maintained. If the end date is in future then it is expected. And project is completed then it will be the actual end date of the project.</li>
                                    <li>Customer Due Date – This date indicates the customer due date which is maintained in the project while maintaining.</li>
                                    <li>Completion Status – In this field completion status of the project is shown.</li>
                                    <li>Delay (Days) – This field indicated the delay in no of days as on today. Delay is calculated on basis of actual completion days and expected or planned end date.</li>
                                </ul>
                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={view5} alt="myproject2" />
                            </Stack>
                            <br />
                            <ul style={{ marginLeft: '3rem', listStyleType: 'disc' }}>
                                <li>Delay (%) – This field shows delay in % of the project duration.</li>
                                <li>Lead Time – This field indicates the lead time of the project.</li>
                                <br />
                                <li>Project Manager – This field indicates the project manger for the project.</li>
                                <li>Project Status – This field indicates the current status of the project. Closed projects are not shown here.</li>
                                <li>Task Causing Maximum Delay – In this the most delayed task is shown. The logic used for the delayed task is system will calculate the delay for each task and then consider the task which has maximum delay in the days. IF delay days are same in any case then it will check delay percentage and the task with max delay percentage will be shown.</li>
                                <li>Delay Caused By Task(Days) – In this field no of day by which the task is delayed is shown.</li>
                                <li>Last BM Run – This field indicates date and time of BM (Buffer Management) run taken for the project</li>
                                <li>Customer Feedback – in this field the customer feedback which is entered while completing the project is shown.</li>
                                <li>Approved Execution Cost (In INR Lakhs) – In this field approved execution cost which is put while completing project is shown. This cost is converted in lakh Rupee.</li>
                                <li>Actual Execution Cost (In INR Lakhs) – In this field actual execution cost which is put while completing project is shown. This cost is converted in lakh Rupee.</li>
                                <li>Project value (In INR Lakhs) – In this field project value execution cost which is put while completing project is shown. This cost is converted in lakh Rupee.</li>
                                <li>View Task – There is redirect link provided for the project. If user clicked on the link then user is redirected to the View Task Page and task list. (Please check user help of View project task for more details.)</li>
                                <li>View Billing Projection – This is redirect link for the billing projection. If project is linked with the ERP then user can get details. Once the linked is clicked the it is redirected to the View Billing Projection. (Please check help of the View Billing Projection for the details)</li>
                                <li>Risk – This is redirect link to the project Risk page. IF this project risk is maintained for the project then is will show else blank page will appear.</li>
                                <li>Issues – This is redirect link to the issue page. If issues are maintained for the project then is will show else blank page will appear.</li>
                                <li>View History – This is a redirect link for the project where user can see the project history.</li>
                                <li>Fever Chart – this chart is indicate progress of the project against each BM run. Here user can see the how much project buffer is consumed and how much critical chain is completed. Also which task is penetrating most buffer is shown with task manager so that Project manager can take further action if required.</li>
                                <li>Buffer Recovery – This is redirect link which is showing if any buffer recovery is done. If recovery is not done then no data found message is shown.</li>
                                <li>Project Properties – This is redirect link provided for the page where project details are shown. Here project manager can see various details like project start date, project duration, CCPM setting applied.</li>
                                <li>Pending Task on CC – This field indicates that which tasks are pending on CC that is critical chain path.</li>
                                <li>Gantt Chart – This field indicates gantt chart for the project. This is redirect link for the opening a new page. Gantt chart is showing date wise plan and dependence path. This is showing current status of the project.</li>
                                <li>Network Diagram -  CCPM Project Diagram dispay for the mentioned project, where project task and its attributes can be viewed .</li>
                                <li>Project Plan – Its is the Plan which is created for specific project with all values. Same can be downloaded in excel when  clicked , on local machine</li>
                            </ul>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={view6} alt="myproject2" />
                            </Stack>
                            <br /><br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={view7} alt="myproject2" />
                            </Stack>
                            <br />
                            <ul style={{ marginLeft: '3rem', listStyleType: 'disc' }}>
                                <li>Attachments – In this field all attachments which are uploaded while completing the project are shown here. User can download the attachment from this page. </li>
                                <li>Instance – Instance is shown here in the field.</li>
                                <br />
                                <li>After instance there are columns showing different attributes of the projects. Like in above sample case BU, Zone and Segment are showing.</li>
                                <li>Project Creation Date – This date indicates that when project is created in the system.</li>
                                <li>Project Last updated on – This field shows that when project is updated last time. This shows that continuity or discipline of the user. How frequently and effectively he is using the system can be checked here.</li>
                                <li>Task Last Updated On - This field shows that when any task is updated last time. This shows that continuity or discipline of the user. How frequently and effectively he is using the system can be checked here.</li>
                            </ul>
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ViewProjectsHelpPopup;
