import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import MUIL1 from "../HelpPopups/images/MUIL1.png";
import MUIL2 from "../HelpPopups/images/MUIL2.png";
import MUIL3 from "../HelpPopups/images/MUIL3.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const ProcessingAutomationhelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <span>HELP NOT AVAILABLE</span>
                </Grid>
            </Grid>
        </>
    );
};

export default ProcessingAutomationhelpPopup;
