import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import changepm1 from './images/changepm1.png'
import changepm2 from './images/changepm2.png'
import changepm3 from './images/changepm3.png'
import changepm4 from './images/changepm4.png'
import view6 from './images/view6.png'
import view7 from './images/view7.png'
import { Link } from "react-router-dom";
import MSTA1 from './images/MSTA-1.jpg'
import MSTA2 from './images/MSTA-2.jpg'
import MSTA3 from './images/MSTA-3.jpg'

const ModifySubTaskAttributehelpPopup = () => {
    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            Expand it...
                            <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>
            </div>

            <Grid sx={{fontFamily: '"Calibri", sans-serif', fontSize:'14.6px', color:'#000000'}}>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul>
                            <h3 style={{ fontWeight: "bold" }}>MODIFY SUB TASK ATTRIBUTE</h3>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                            This is field where sub task attributes can be changed and edited. Project manager can change any information related to the sub tasks.
                            </li>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                                Session Path – CCPM {'>>'}Project Plan {'>>'}Modify Sub Task Attribute
                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={MSTA1} alt="MSTA1" />
                            </Stack>
                            <br />
                            <span>Once instance is selected then following screen is appeared. If any sub tasks are maintained in the project then project manager can edit the sub tasks maintained.</span>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={MSTA2} alt="MSTA2" />
                            </Stack>
                             <br />
                           <span>In this screen user can see List + icon in sub task column. If you click, then it will take you to subtask page.</span>
                            <br />
                           
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={MSTA3} alt="MSTA3" />
                            </Stack>
                            <br />
                            
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ModifySubTaskAttributehelpPopup;
