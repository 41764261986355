import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import changepm1 from './images/changepm1.png'
import changepm2 from './images/changepm2.png'
import changepm3 from './images/changepm3.png'
import changepm4 from './images/changepm4.png'
import view6 from './images/view6.png'
import view7 from './images/view7.png'
import MTA1 from './images/MTA-1.jpg'
import MTA2 from './images/MTA-2.jpg'
import { Link } from "react-router-dom";

const ModifyTaskAttributehelpPopup = () => {
    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            Expand it...
                            <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>
            </div>

            <Grid sx={{fontFamily: '"Calibri", sans-serif', fontSize:'14.6px', color:'#000000'}}>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul>
                            <h3 style={{ fontWeight: "bold" }}>MODIFY TASK ATTRIBUTE</h3>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                            This is field where task attributes can be changed and edited. Project manager can change any information related to the tasks.
                            </li>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                                Session Path – CCPM {'>>'}Project Plan {'>>'}Modify Task Attribute
                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={MTA1} alt="MTA2" />
                            </Stack>
                            <br />
                            <ul style={{ listStyleType: 'disc', marginLeft: '5rem' }}>
                                <br />
                                <li>
                                    Instance – Select instance from the dropdown provided.
                                </li>
                                <li>
                                    Project Name – Select the required project from the dropdown provided.
                                </li>
                                <li>
                                Task Manager – This field is use if user want to check the task related to particular task manager. In this case task for the manager across all project will be shown.
                                </li>
                               
                            </ul>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={MTA2} alt="MTA2" />
                            </Stack>
                            
                           
                            <br />
                            <ul style={{ listStyleType: 'disc', marginLeft: '5rem' }}>
                                <br />
                                <li>
                                Project manager can modify the task related information here. He can change the task name, user can change task manager and also he can add task process note for the tasks.
                                </li>
                                <li>
                                There is option of the Replace task manager against selected tasks here manager can change task manager in bulk by selecting project manager.
</li>
                                
                               
                            </ul>
                            <br />
                           
                            
                            
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ModifyTaskAttributehelpPopup;
