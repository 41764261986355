import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import PSOC1 from "../HelpPopups/images/PSOC1.png";
import PSOC2 from "../HelpPopups/images/PSOC2.png";
import IC1 from "../HelpPopups/images/CM1.jpg";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";

const DepartmentLoadReporthelpPopup = () => {
  return (
    <>
    <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "#0000EE",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight:'bold',
                  fontFamily:'TimesNewRoman'
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            
              <h3 style={{ textDecoration: "underline", fontFamily:'Cablibri,serif',fontSize:'26px'}}>DEPARTMENT LOAD REPORT</h3>
              <br />
              <p>Department Load Report - is graphical type report, which shows the Month wise Load on Department under specific Instance, for the project Task, which are planned under specific selected Department.

              </p>
              <br></br>
              <p>All Active Projects department Data will be considered for calculation and display of report.

</p>
                <br></br>
                <p>Department Masters is  should be mandatorily applied by user for fetching the report putput.

i.e Department and capacity of Department under that instance to be maintained by user.
                    </p>
                    <br></br>
              <p > <strong>❖ Report Filters : - </strong></p>
              <ul>
              <br></br>

              <li> Instance – User linked Instance dropdown Mandatory mono selection</li>
              <li> Department –specific selection from Drop down list of all Departments maintained by user,</li>
              <li>  Attributes : - Instance Linked Attributes – selection</li>
              <li>   Period – specific selection of Period – 1 month / 3 months / 6months</li>
              <li>  Task Manager – selection list of Task Manager, which are associated to the department task under that instance.</li>
              <br></br>
              </ul>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={PSOC2} alt="IC1" />
              </Stack>

              <br></br>

              <p > <strong>❖ Report displayed :- </strong></p>
              <ul>
               <li>a.{')'} Department Load Graph</li>
               <li>b.{')'} Department Load Data</li>
             </ul>

                <br></br>

                <p > Based on the selection dropdown, consider the month / date of ’ projected Start date’  and ’projected End date’ – Range and All ‘Active ‘projects task falling under that period and display sum of ‘remaining duration ‘ for in process / planned Task.

</p>

<br></br>
<Stack sx={{ paddingRight: "1rem" }}>
                <img src={PSOC2} alt="IC1" />
              </Stack>
              <br></br>
              <br></br>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={PSOC2} alt="IC1" />
              </Stack><br></br>

              <ul>
                <li>  sMax. planned work of 6 months  data- cumulative / Month wise , can be displayed.</li>
                <li>  If only ‘Instance’ and ‘department’ selected in Department capacity report, Capacity line to be shown on graph.</li>
                <li>  Range / Grouping/ scaling – 0 to 10.i.e 10,20…..etc. If duration is split in 2 months then on graph also it should be spilt accordingly. </li>
              </ul>



          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DepartmentLoadReporthelpPopup;
