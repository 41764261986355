import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import { Grid, FormControl } from "@mui/material";
import bread from "../../../Assets/bread.png";
import { Set_NewInstance } from "../../../Services/Dss";
import {
  SP_MtaInstances,
  SP_MtaAdminControl,
  SP_MtaAdminControl_update,
} from "../../../Services/MTA";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function AdminControl() {
  const [data, setData] = useState({
    instname: "",
    instdesc: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInstanceId, setSelectedInstanceId] = useState("");
  const [newControlP, setNewControlP] = useState("");
  const [dropdownData, setDropdownData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getInstances();
  }, []);

  const getInstances = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      instance_uid: "",
      muiuid_last_modified_by: sessionStorage
        .getItem("loginUsername")
        .toLowerCase(),
    };
    if (loginId) {
      SP_MtaInstances(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setDropdownData(response?.data?.success?.data);
            setIsLoading(false);
            console.log(response?.data?.success?.data.length, "length");

            if (response?.data?.success?.data.length > 0) {
              const defaultInstanceId =
                response?.data?.success?.data[0]?.mta_instance_uid;
              console.log(defaultInstanceId, "fcewf");
              setSelectedInstanceId(defaultInstanceId);
              getTableData(defaultInstanceId);
            } else if (response?.data?.success?.data.length === 0) {
              alert("You do not have access to any Active Instance in MTA");
              console.log(response?.data?.success?.data.length, "WWWW");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const getTableData = (searchValue) => {
    setIsLoading(true);
    const body = {
      bc_instance_uid: searchValue,
      userid: sessionStorage.getItem("loginUsername").toLowerCase(),
    };
    SP_MtaAdminControl(body)
      .then((response) => {
        let Ndata = response?.data?.success?.data;
        if (Ndata) {
          Ndata = Ndata.sort((a, b) =>
            a.controlparameter.localeCompare(b.controlparameter)
          );
        }
        setFilteredData(Ndata);
        console.log(Ndata);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  function handleEditSingleEntry(id, controlValue, element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(controlValue);
      // setEditedInstanceName(InstanceName);
      // setEditedInstanceCode(InstanceCode);
      element.style.color = "#0000EE";
    }, 100);
  }

  function handleSaveEdit(contP, contV, element) {
    element.style.color = "red";

    setTimeout(() => {
      element.style.color = "#0000EE";

      setTimeout(() => {
        setIsLoading(true);
        if (contV === newControlP) {
          // alert("No change to Update.");
          setEditing(null);
          setIsLoading(false);
          return;
        } else if (contV !== newControlP) {
          let lastModifiedBy = sessionStorage
            .getItem("loginUsername")
            .toLowerCase();
          const body = {
            bc_instance_uid: selectedInstanceId,
            controlParameter: contP,
            controlValue: newControlP,
            bc_last_modified_by: sessionStorage
              .getItem("loginUsername")
              .toLowerCase(),
          };

          if (lastModifiedBy) {
            SP_MtaAdminControl_update(body)
              .then((response) => {
                if (response?.data?.success === true) {
                  setEditing(null);
                  getTableData(selectedInstanceId);
                  setIsLoading(false);
                } else if (response?.response?.data?.statusCode === 400) {
                  alert(
                    response?.response?.data?.error?.message ||
                      " Please try again."
                  );
                  setEditing(null);
                  getTableData(selectedInstanceId);
                  setIsLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
                setIsLoading(false);
              });
          }
        }
      }, 50);
    }, 100);
  }

  function handleCancelEdit(element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(null);
      element.style.color = "#0000EE";
    }, 50);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + rowsPerPage);

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"AdminControl"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>Admin Control</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table
                className={modules["customTable"]}
                style={{ marginLeft: "3rem" }}
              >
                <tbody>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "7rem", fontWeight: "bold" }}>
                      MTA Instance <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedInstanceId}
                          onChange={(e) => {
                            setSelectedInstanceId(e.target.value);
                            // setSelectedInstance(selectedId);
                            // getTableData(e.target.value);
                            getTableData(e.target.value);
                          }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          // disabled={editing}
                        >
                          {/* <option value={"SELECT"}>SELECT</option> */}
                          {dropdownData.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mta_instance_uid}
                                key={i.id}
                                data-id={i.id}
                              >
                                {i.mta_instance_uid}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                </tbody>
              </table>

              {!isLoading && (
                <>
                  {filteredData && filteredData.length > 0 ? (
                    <Table
                      className={modules["custom_table"]}
                      style={{
                        marginLeft: "3rem",
                        width: "1020px",
                        marginBottom: "3rem",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" style={{ width: "90px", padding:"10px 10px" }}>
                            Sr. No.
                          </TableCell>
                          <TableCell align="left">Control Parameter</TableCell>
                          <TableCell align="left">Control Value</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentData.map((item, index) => (
                          <TableRow
                            key={index}
                            className={modules["table-row"]}
                          >
                            <TableCell
                              className={modules["table-cell"]}
                              align="left"
                            >
                              <div>
                                <span
                                  style={{
                                    cursor: "text",
                                    color: "#414141",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {startIndex + index + 1}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell
                              className={modules["table-cell"]}
                              align="left"
                            >
                              <div>
                                <span
                                  style={{ cursor: "text", color: "#414141" }}
                                >
                                  {item.controlparameter}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell
                              className={modules["table-cell"]}
                              align="left"
                            >
                              {editing === item.controlparameter ? (
                                <input
                                  value={newControlP}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 50) {
                                      setNewControlP(e.target.value);
                                    }
                                  }}
                                  onInput={(e) => {
                                    const regex =
                                      /^[a-zA-Z0-9()&_\-+=\[\]/. ]*$/;
                                    if (!regex.test(e.target.value)) {
                                      e.target.value = e.target.value.replace(
                                        /[^a-zA-Z0-9()&_\-+=\[\]/. ]/g,
                                        ""
                                      );
                                      setNewControlP(e.target.value);
                                    }
                                  }}
                                  onBlur={(e) => {
                                    const regex2 = /^[a-zA-Z0-9_ &()-]*$/;
                                    if (!regex2.test(e.target.value)) {
                                      alert("No Special Characters Allowed.");
                                      setNewControlP("");
                                    }
                                  }}
                                  maxLength="50"
                                  className={modules["input_css"]}
                                  style={{ padding: "5px", width: "213px" }}
                                />
                              ) : (
                                <div>
                                  <span
                                    style={{ cursor: "text", color: "#414141" }}
                                  >
                                    {item.controlvalue}
                                  </span>
                                </div>
                              )}
                            </TableCell>
                            <TableCell
                              className={modules["table-cell"]}
                              align="center"
                            >
                              {editing === item.controlparameter ? (
                                <>
                                  <a
                                    style={{
                                      paddingRight: "1rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      handleSaveEdit(
                                        item.controlparameter,
                                        item.controlvalue,
                                        e.currentTarget
                                      )
                                    }
                                  >
                                    Update
                                  </a>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      handleCancelEdit(e.currentTarget)
                                    }
                                  >
                                    Cancel
                                  </a>
                                </>
                              ) : (
                                <a
                                  style={{ cursor: "pointer" }}
                                  className={modules["actionCol"]}
                                  onClick={(e) => {
                                    handleEditSingleEntry(
                                      startIndex + index + 1,
                                      item.controlparameter,
                                      e.currentTarget
                                    );
                                    setNewControlP(item.controlvalue);
                                  }}
                                >
                                  Edit
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colspan={4}
                            align="left"
                            className={modules["whiteBackgroundPR"]}
                          >
                            <div
                              style={{
                                marginTop: "0.5rem",
                              }}
                            >
                              {currentPage > 10 && (
                                <button
                                  onClick={() =>
                                    handlePageClick(
                                      Math.floor((currentPage - 1) / 10) * 10
                                    )
                                  }
                                  style={{
                                    padding: "0px 10px",
                                    fontSize: "12px",
                                    backgroundColor: "#fff",
                                    color: "#0000ee",
                                    border: "transparent",
                                    cursor: "pointer",
                                    textAlign: "left",
                                    textDecoration: "underline",
                                  }}
                                >
                                  ...
                                </button>
                              )}
                              {Array.from(
                                {
                                  length: Math.min(
                                    10,
                                    totalPages -
                                      Math.floor((currentPage - 1) / 10) * 10
                                  ),
                                },
                                (_, index) => {
                                  const startPage =
                                    Math.floor((currentPage - 1) / 10) * 10;
                                  const pageIndex = startPage + index;
                                  return (
                                    <button
                                      key={pageIndex}
                                      onClick={() =>
                                        handlePageClick(pageIndex + 1)
                                      }
                                      style={{
                                        padding: "5px 10px",
                                        fontSize: "12px",
                                        backgroundColor: "#fff",
                                        color:
                                          currentPage === pageIndex + 1
                                            ? "#000"
                                            : "#0000ee",
                                        border: "transparent",
                                        borderBottom: "1px solid #a4bf47",
                                        cursor: "pointer",
                                        textAlign: "left",
                                        textDecoration:
                                          currentPage === pageIndex + 1
                                            ? "none"
                                            : "underline",
                                      }}
                                    >
                                      {pageIndex + 1}
                                    </button>
                                  );
                                }
                              )}
                              {totalPages >
                                Math.floor((currentPage - 1) / 10) * 10 +
                                  10 && (
                                <button
                                  onClick={() =>
                                    handlePageClick(
                                      Math.floor((currentPage - 1) / 10) * 10 +
                                        11
                                    )
                                  }
                                  style={{
                                    padding: "0px 10px",
                                    fontSize: "12px",
                                    backgroundColor: "#fff",
                                    color: "#0000ee",
                                    border: "transparent",
                                    cursor: "pointer",
                                    textAlign: "left",
                                    textDecoration: "underline",
                                  }}
                                >
                                  ...
                                </button>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ) : (
                    <Table>
                      <TableCell
                        style={{ marginLeft: "6rem", fontSize: "12px" }}
                      >
                        No Data Found
                      </TableCell>
                    </Table>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
