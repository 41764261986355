import React, { useState, useEffect } from "react";

import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import modules from "./BufferRecovery.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import calender from "../../../Assets/calendar.png";
import Button from "@mui/material/Button";
import loading from "../../../Assets/Loading_image.gif";
import { getProjectName } from "../../../Services/Activeproject";
import { WL_GetDropdown } from "../../../Services/CCPM";
import {
  Searchbufferrecovery,
  InsertBufferRecovery,
  UpdateBufferRecovery,
} from "../../../Services/CCPM";
import { Popover } from "@mui/material";
import Calendar from "react-calendar";
//import 'react-calendar/dist/Calendar.css';

const BufferRecovery = () => {
  // const [displayvalue, setDisplayValue] = useState(dropdownData[0]);

  const [Instancevalue, setInstanceValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [Instanaceoptions, setInstanceOptions] = useState([]);
  const [projectnamevalue, setProjectNameValue] = useState(null);

  const [options, setOptions] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [bufferRecoveryData, setBufferRecoveryData] = useState(false);
  const [currentBufferConsumption, setCurrentBufferConsumption] =
    useState(false);
  const [isAddRowVisible, setIsAddRowVisible] = useState(false);
  const [inputPlannedRecovery, setInputPlannedRecovery] = useState("");
  const [inputAssignTo, setInputAssignTo] = useState("");
  const [inputExpectedActionDate, setInputExpectedActionDate] = useState("");
  const [inputBufferRecoveryDays, setInputBufferRecoveryDays] = useState("");
  const [inputstatus, setInputStatus] = useState("");
  const [iseditclicked, setiseditclicked] = useState(null);
  const [newUserEmpt, setNewUserEmpt] = useState(false);
  const [editing, setEditing] = useState(null);
  const [isInputRemark, setIsInputRemark] = useState("");
  const [isInputActualActionDate, setIsInputActualActionDate] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [calendarOpen1, setCalendarOpen1] = useState(false);
  const [calendarOpen2, setCalendarOpen2] = useState(false);
  const [fromdateOne, setFromdateOne] = useState(new Date());
  const [fromdateTwo, setFromdateTwo] = useState(new Date());
  const [instancemsg, setInstancemsg] = useState("");
  const [showAsterisk, setShowAsterisk] = useState(false);

  const [anchorEl3, setAnchorEl3] = useState(null);
  const [anchorEl4, setAnchorEl4] = useState(null);
  const [anchorEl5, setAnchorEl5] = useState(null);
  const [upLikelyDate, setUpLikelyDate] = useState("");
  const [upLikelyDate2, setUpLikelyDate2] = useState("");
  const [upLikelyDate3, setUpLikelyDate3] = useState("");
  // const [isdisable,setIsDisable]=useState(false);
  const [msg, setMsg] = useState("");

  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const open5 = Boolean(anchorEl5);

  const validate = () => {
    if (!Instancevalue) {
      setInstancemsg("please select Instance");
      alert("Select both Instance And Project");
      return false;
    } else if (!projectnamevalue) {
      // setProjectNameMsg("please select project name");
      alert("Select both Instance And Project");
      return false;
    } else {
      return true;
    }
  };
  const handleSearch = async () => {
    if (validate() === true) {
      try {
        let obj = {
          instanceId: Instancevalue,
          projectId: projectnamevalue,
        };
        let res = await Searchbufferrecovery(obj);
        console.log(
          "res.data.BufferRecoveryActionPlan",
          res.data.BufferRecoveryActionPlan
        );
        if (res.status === 200) {
          if (res.data.BufferRecoveryActionPlan?.length) {
            setBufferRecoveryData(res.data.BufferRecoveryActionPlan);
            setCurrentBufferConsumption(
              res.data.CurrentBufferConsumptionDetail
            );
            setShowTable(true);
          } else {
            setShowTable(false);
            setBufferRecoveryData([]);
            setCurrentBufferConsumption([]);
            setMsg("No Records Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const validateInsert = () => {
    if (!inputBufferRecoveryDays) {
      setShowAsterisk(true);
      return false;
    }
    if (!inputExpectedActionDate) {
      setShowAsterisk(true);
      setAnchorEl5(true);
      return false;
    } else {
      setShowAsterisk(false);
      return true;
    }
  };
  const handleInsertBufferRecovery = async () => {
    if (validateInsert() === true) {
      try {
        let obj = {
          projectId: parseInt(projectnamevalue),
          projectColorId: currentBufferConsumption.length
            ? currentBufferConsumption[0].projectcolorid
            : null,
          percentageBufferConsumption: currentBufferConsumption.length
            ? currentBufferConsumption[0].percentagebufferconsumption
            : null,
          bufferConsumptionDays: currentBufferConsumption.length
            ? currentBufferConsumption[0].bufferconsumptiondays
            : null,
          percentageCCCompletion: currentBufferConsumption.length
            ? currentBufferConsumption[0].percentagecccompletion
            : null,
          plannedRecoveryAction: inputPlannedRecovery,
          assignedTo: inputAssignTo,
          expectedRecoveryActionBy: inputExpectedActionDate,
          expectedBufferRecoveryDays: parseInt(inputBufferRecoveryDays),
          expectedBufferRecoveryDays: parseInt(inputBufferRecoveryDays),
          status: "open",
          createdBy: 0,
        };
        let response = await InsertBufferRecovery(obj);
        // .then((response) => {
        if (response.status === 200) {
          const apiData = response.data.success.data;
          console.log(apiData);
          alert("Details Inserted Successfully");
          setIsAddRowVisible(false);
          setEditing(null);
          handleSearch();
          // Store results in the results array
        }
        console.log(bufferRecoveryData);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleInstance = (i) => {
    setInstanceValue(i);
    setIsLoading(true);
    getProjectNameDropdownDetails(i);
  };
  useEffect(() => {
    setIsLoading(true);
    getInstanceDropdownDetails();
  }, []);
  //api call to get instance details
  const getInstanceDropdownDetails = async () => {
    try {
      let body = {
        userId: sessionStorage.getItem("loginId"),
      };
      let response = await WL_GetDropdown(body);
      // .then((response) => {
      if (response.status === 200) {
        const apiData = response.data.success.data;
        setInstanceValue(
          response.data.success.data.length
            ? response.data.success.data[0].id
            : null
        );
        setIsLoading(false);
        console.log(apiData);
        const results = [];
        // Store results in the results array
        apiData.forEach((value) => {
          results.push({
            key: value.name,
            value: value.id,
          });
        });
        console.log(results);
        // Update the options state
        setInstanceOptions([
          // {key: 'Select a company', value: ''},
          ...results,
        ]);
      }
      // })
    } catch (error) {
      console.log(error);
    }
  };

  //api call to get projectname details
  const getProjectNameDropdownDetails = async (i) => {
    try {
      let body = {
        userId: sessionStorage.getItem("loginId"),
        instanceId: i,
      };
      let response = await getProjectName(body);
      if (response.statusCode === 200) {
        const apiData = response.success.data;
        setIsLoading(false);
        const results = [];
        // Store results in the results array
        apiData.forEach((value) => {
          results.push({
            key: value.projectname,
            value: value.id,
          });
        });
        console.log(results);
        // Update the options state
        setOptions([
          // {key: 'Select a company', value: ''},
          ...results,
        ]);
      } else {
        setProjectNameValue(null);
        setOptions([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleAddRecovery = () => {
    setEditing(null);
    setInputPlannedRecovery("");
    setInputAssignTo("");
    setInputExpectedActionDate("");
    setInputBufferRecoveryDays("");
    setInputStatus("");
    setIsInputActualActionDate("");
    setIsInputRemark("");
    setIsAddRowVisible(true);
  };
  const handleEditBtn = (id, item) => {
    setIsAddRowVisible(false);
    setEditing(id);
    setInputPlannedRecovery(item.plannedrecoveryaction);
    setInputAssignTo(item.assignedto);
    setInputExpectedActionDate(formatDate(item.expectedrecoveryactionby));
    setInputBufferRecoveryDays(item.expectedbufferrecoverydays);
    setInputStatus(item.status);
    setIsInputActualActionDate(
      item.actualrecoveryactionon == null
        ? ""
        : formatDate(item.actualrecoveryactionon)
    );
    setIsInputRemark(
      item.pmremark === "" || item.pmremark === null ? "" : item.pmremark
    );
  };

  const handleInputAssignBlur = () => {
    const specialCharPattern = /[\/\[\]\+=:"{}|<>]/g;
    if (specialCharPattern.test(inputAssignTo)) {
      alert("No Special Characters Allowed.");
      setInputAssignTo("");
    }
  };

  const handleInputPlannedRemarBlur = () => {
    const specialCharPattern = /[\/\[\]\+=:"{}|<>]/g;
    if (specialCharPattern.test(isInputRemark)) {
      alert("No Special Characters Allowed.");
      setIsInputRemark("");
    }
  };

  const validateInputRemark = () => {
    var regex = /^[a-zA-Z0-9._&()-?]+$/;
    var isValid = regex.test(isInputRemark);
    if (isValid) {
      return true;
    } else {
      return false;
    }
  };

  const handleUpdateBufferRecovery = async (id) => {
    if (validateInputRemark() === true || isInputRemark === "") {
      try {
        let obj = {
          id: id,
          plannedRecoveryAction: inputPlannedRecovery,
          assignedTo: inputAssignTo,
          expectedRecoveryActionBy: inputExpectedActionDate,
          expectedBufferRecoveryDays: inputBufferRecoveryDays,
          status: inputstatus,
          actualRecoveryActionOn:
            isInputActualActionDate === "" ? "" : isInputActualActionDate,
          pmRemark: isInputRemark === "" ? "" : isInputRemark,
          modifiedBy: sessionStorage.getItem("loginId"),
        };
        let response = await UpdateBufferRecovery(obj);
        if (response.status === 200) {
          setEditing(null);
          handleSearch();
          alert("Details Updated Successfully");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("No Special Characters are Allowed");
    }
  };

  const handleInputPlannedRecBlur = () => {
    const specialCharPattern = /[\/\[\]\+=:"{}|<>]/g;
    if (specialCharPattern.test(inputPlannedRecovery)) {
      alert("No Special Characters Allowed.");
      setInputPlannedRecovery("");
    }
  };

  const handleCancelEdit = () => {
    setEditing(null);
  };

  const handleCalendar3n = (date) => {
    setAnchorEl3(null);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      ?.toLocaleDateString("en-GB", options)
      .replace(/,/g, "");
    setInputExpectedActionDate(formattedDate);
    setUpLikelyDate(date);
  };
  const handleCalendar4n = (date) => {
    setAnchorEl4(null);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      ?.toLocaleDateString("en-GB", options)
      .replace(/,/g, "");
    setIsInputActualActionDate(formattedDate);
    setUpLikelyDate2(date);
  };
  const handleCalendar5n = (date) => {
    console.log("date", date);
    setAnchorEl5(null);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      ?.toLocaleDateString("en-GB", options)
      .replace(/,/g, "");
    setInputExpectedActionDate(formattedDate);
    setUpLikelyDate3(date);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  // Function to get today's date formatted
  const getTodayDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };

  function validateNumber(e) {
    const value = e.target.value;
    // ^([1-9][0-9]{0,2}|1000)$
    // const numberPattern = /^[0-9]*$/;
    const numberPattern = /^([0-9]|[1-9][0-9]|[1-9][0-9]{2})$/;
    if (value === "" || numberPattern.test(value)) {
      setInputBufferRecoveryDays(value);
    } else {
      return;
    }
  }
  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: "short" }).slice(0, 2);
  };

  const showCurrentBufferGrid = currentBufferConsumption.length
    ? currentBufferConsumption.map((item) => {
        return (
          <Grid container style={{ marginLeft: "6rem" }} spacing={2}>
            <Grid
              item
              xs={3}
              style={{
                textAlign: "start",
                fontFamily: "WorkSans",
                fontSize: "12px",
                color: "#414141",
                fontWeight: "bold",
              }}
            >
              Current project color :
            </Grid>
            <Grid item xs={3}>
              <span
                style={{
                  backgroundColor: `${item.projectcolor}`,
                  color: "black",
                  fontFamily: "WorkSans",
                  fontSize: "12px",
                }}
              >
                {item.projectcolor}
              </span>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                fontFamily: "WorkSans",
                fontSize: "12px",
                color: "#414141",
                fontWeight: "bold",
              }}
            >
              Current %CC Completed :
            </Grid>
            <Grid item xs={3}>
              <span
                style={{
                  fontFamily: "WorkSans",
                  fontSize: "12px",
                  color: "#414141",
                }}
              >
                {" "}
                {item.percentagecccompletion}
              </span>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                textAlign: "start",
                fontFamily: "WorkSans",
                fontSize: "12px",
                color: "#414141",
                fontWeight: "bold",
              }}
            >
              Current %BC :
            </Grid>
            <Grid item xs={3}>
              <span
                style={{
                  fontFamily: "WorkSans",
                  fontSize: "12px",
                  color: "#414141",
                }}
              >
                {item.percentagebufferconsumption}
              </span>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                fontFamily: "WorkSans",
                fontSize: "12px",
                color: "#414141",
                fontWeight: "bold",
              }}
            >
              Current Buffer Penetration Days :
            </Grid>
            <Grid item xs={3}>
              <span
                style={{
                  fontFamily: "WorkSans",
                  fontSize: "12px",
                  color: "#414141",
                }}
              >
                {item.bufferconsumptiondays}
              </span>
            </Grid>
          </Grid>
        );
      })
    : null;

  const TableUI = () => {
    return (
      <>
        {isLoading && (
          <img
            src={loading}
            className={modules["loading_gif"]}
            alt="Loading..."
          />
        )}

        <Grid
          container
          className={modules[("table-grid", "table-grid-margin")]}
        >
          <div className={modules[("table-container", "table-overflow")]}>
            <Table
              className={[
                modules["custom-table"],
                modules["custom-table-th"],
              ].join(" ")}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={modules["custom-table-header1"]}
                    sx={{
                      "&.MuiTableCell-root": {
                        marginLeft: "40px",
                        padding: "0px",
                      },
                    }}
                    colSpan={14}
                  >
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>

                    <TableCell
                      colSpan={5}
                      style={{
                        backgroundColor: "#90EE90",
                        fontSize: "12px",
                        fontFamily: "WorkSans",
                        width: "74%",
                        textAlign: "center",
                      }}
                      sx={{
                        "&.MuiTableCell-root": {
                          marginLeft: "40px",
                          padding: "5px",
                        },
                      }}
                    >
                      Project Status When Recovery Action Planned
                    </TableCell>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead className={modules["custom-table-header2"]}>
                {/* <span>Project Status When Recovery Action Planned</span> */}
                <TableRow>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Recovery Planning Date
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Project Color
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Buffer Consumption (%)
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Critical Chain Completion(%)
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Buffer Penetration Days
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Planned Recovery Action
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    AssignTo
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Expected Action Date
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Expected Buffer Recovery Days
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "200px",
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Actual Action&nbsp;Date
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Closure Remarks(By PM)
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        color: "white",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  >
                    Edit/Insert
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bufferRecoveryData.length
                  ? bufferRecoveryData.map((item, index) => (
                      <TableRow key={index} className={modules["table-row"]}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {formatDate(item.recoveryplanningdate)}
                        </TableCell>
                        <TableCell>{item.projectcolor}</TableCell>
                        <TableCell>
                          {item.percentagebufferconsumption}
                        </TableCell>
                        <TableCell>{item.percentagecccompletion}</TableCell>
                        <TableCell>{item.bufferconsumptiondays}</TableCell>
                        <TableCell>
                          {editing === item.id ? (
                            <>
                              <Stack direction="column" alignItems="flex-start">
                                {/* <Typography className={modules["error-msg"]}>
                                *
                              </Typography> */}
                                <textarea
                                  style={{
                                    color: "#000000",
                                    font: "13px",
                                    fontFamily: "WorkSans",
                                    backgroundColor: "#FFFFFF",
                                    padding: "2px",
                                    width: "210px",
                                  }}
                                  value={inputPlannedRecovery}
                                  className={modules["inputField1"]}
                                  onBlur={handleInputPlannedRecBlur}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const disallowedCharPattern =
                                      /[^a-zA-Z0-9&.+_=\-/?()\[\]\s]/g;
                                    if (disallowedCharPattern.test(value)) {
                                      return;
                                    } else {
                                      setInputPlannedRecovery(value);
                                    }
                                  }}
                                />
                              </Stack>
                            </>
                          ) : (
                            <textarea
                              style={{
                                color: "#000000",
                                font: "13px",
                                fontFamily: "WorkSans",
                                backgroundColor: "#FFFFFF",
                                padding: "2px",
                                width: "210px",
                              }}
                              value={item.plannedrecoveryaction}
                              readOnly
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {editing === item.id ? (
                            <>
                              {" "}
                              <input
                                type="text"
                                value={inputAssignTo}
                                className={modules["inputField1"]}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const disallowedCharPattern =
                                    /[^a-zA-Z0-9&.+_=\-/()\[\]\s]/g;
                                  if (disallowedCharPattern.test(value)) {
                                    return;
                                  } else {
                                    setInputAssignTo(value);
                                  }
                                }}
                                style={{width:"100px"}}
                                onBlur={handleInputAssignBlur}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleInsertBufferRecovery();
                                  }
                                }}
                              />
                            </>
                          ) : (
                            item.assignedto
                          )}
                        </TableCell>
                        <TableCell>
                          {editing === item.id ? (
                            <>
                              {/* <div>
                        <input
                        className={modules["datepicker"]}
                        type="text"
                        value={inputExpectedActionDate}
                        placeholder="dd/MM/yyyy"
                        onChange={(e) => setInputExpectedActionDate(e.target.value)}
                        onClick={toggleCalendarOne}
                      />
                      {calendarOpen1 && (
                        <div className={modules["fromcalendar"]}>
                          <Calendar onChange={handleFromDateChange} value={fromdateOne} />
                        </div>
                      )}
                      </div> */}
                              <input
                                style={{ width: "100px" }}
                                maxLength="20"
                                value={inputExpectedActionDate}
                                onChange={(e) =>
                                  setInputExpectedActionDate(e.target.value)
                                }
                                className={modules["input-fieldRMP"]}
                                //  onClick={toggleCalendarOne}
                                onClick={(event) =>
                                  setAnchorEl3(event.currentTarget)
                                }
                              />
                              <Popover
                                open={open3}
                                anchorEl={anchorEl3}
                                onClose={() => setAnchorEl3(null)}
                                placement="bottom-start"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <Calendar
                                  onChange={handleCalendar3n}
                                  value={upLikelyDate}
                                  locale="en-US"
                                  formatShortWeekday={formatShortWeekday}
                                />
                                <div className={modules["calendar-footer"]}>
                                  {/* Today: {formattedToday} */}
                                </div>
                              </Popover>
                            </>
                          ) : (
                            formatDate(item.expectedrecoveryactionby)
                          )}
                          {/* // item.expectedrecoveryactionby} */}
                        </TableCell>
                        <TableCell>
                          {editing === item.id ? (
                            <input
                              type="text"
                              value={inputBufferRecoveryDays}
                              className={modules["inputField1"]}
                              onChange={(e) => validateNumber(e)}
                              style={{width:"40px"}}
                              required
                            />
                          ) : (
                            item.expectedbufferrecoverydays
                          )}
                        </TableCell>
                        <TableCell>
                          {editing === item.id ? (
                            <select
                              style={{ borderRadius: "10px", width: "6rem" }}
                              className={modules["dropdownSelectStatus"]}
                              value={inputstatus}
                              onChange={(e) => setInputStatus(e.target.value)}
                              required
                            >
                              <option value="open">Open</option>
                              <option value="closed">Closed</option>
                            </select>
                          ) : (
                            item.status
                          )}
                        </TableCell>
                        <TableCell>
                          {editing === item.id ? (
                            <>
                              <input
                                style={{ width: "100px" }}
                                maxLength="20"
                                value={isInputActualActionDate}
                                onChange={(e) =>
                                  setIsInputActualActionDate(e.target.value)
                                }
                                className={modules["input-fieldRMP"]}
                                //  onClick={toggleCalendarOne}
                                onClick={(event) =>
                                  setAnchorEl4(event.currentTarget)
                                }
                              />
                              <Popover
                                open={open4}
                                anchorEl={anchorEl4}
                                onClose={() => setAnchorEl4(null)}
                                placement="bottom-start"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <Calendar
                                  onChange={handleCalendar4n}
                                  value={upLikelyDate2}
                                  locale="en-US"
                                  formatShortWeekday={formatShortWeekday}
                                />
                                <div className={modules["calendar-footer"]}>
                                  {/* Today: {formattedToday} */}
                                </div>
                              </Popover>
                            </>
                          ) : // <Stack direction="column" alignItems="flex-start">
                          //       <div>
                          //   <input
                          //   className={modules["datepicker"]}
                          //   type="text"
                          //   value={isInputActualActionDate}
                          //   placeholder="dd/MM/yyyy"
                          //   onChange={(e) => setIsInputActualActionDate(e.target.value)}
                          //   onClick={toggleCalendarTwo}
                          // />
                          // {calendarOpen2&& (
                          //   <div className={modules["fromcalendar"]}>
                          //     <Calendar onChange={handleFromDateChange2} value={fromdateTwo} />
                          //   </div>
                          // )}
                          // </div>

                          item.actualrecoveryactionon === null ? (
                            ""
                          ) : (
                            formatDate(item.actualrecoveryactionon)
                          )}
                        </TableCell>
                        <TableCell>
                          {editing === item.id ? (
                            <Stack direction="column" alignItems="flex-start">
                              {/* <Typography className={modules["error-msg"]}>
                                *
                              </Typography> */}
                              <textarea
                                style={{
                                  color: "#000000",
                                  font: "13px",
                                  fontFamily: "WorkSans",
                                  backgroundColor: "#FFFFFF",
                                  padding: "2px",
                                  width: "210px",
                                }}
                                value={isInputRemark}
                                className={modules["inputField1"]}
                                onBlur={handleInputPlannedRemarBlur}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const disallowedCharPattern =
                                    /[^a-zA-Z0-9&.+_=\-/?()\[\]\s]/g;
                                  if (disallowedCharPattern.test(value)) {
                                    return;
                                  } else {
                                    setIsInputRemark(value);
                                  }
                                }}
                              />
                            </Stack>
                          ) : (
                            <textarea
                              style={{
                                color: "#000000",
                                font: "13px",
                                fontFamily: "WorkSans",
                                backgroundColor: "#FFFFFF",
                                padding: "2px",
                                width: "210px",
                              }}
                              value={
                                item.pmremark === null ? "" : item.pmremark
                              }
                              readOnly
                            />
                          )}
                        </TableCell>

                        <TableCell>
                          <>
                            {editing === item.id ? (
                              <div>
                                <button
                                  className={modules["update-btn-active"]}
                                  onClick={(e) => {
                                    handleUpdateBufferRecovery(item.id);
                                  }}
                                >
                                  Update
                                </button>
                                <br />
                                <button
                                  className={modules["cancel-btn-active"]}
                                  onClick={handleCancelEdit}
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <button
                                className={modules["edit-btn-active"]}
                                onClick={(e) => handleEditBtn(item.id, item)}
                              >
                                Edit
                              </button>
                            )}
                          </>
                        </TableCell>
                        {/* <TableCell>
                          <button style={{cursor: "pointer",width:"90px",height:"24px",backgroundColor:"#1976d2",color:"white",border:"none"}} onClick={()=>{handleEditBtn(item.id)}}>Edit</button>
                          </TableCell> */}
                      </TableRow>
                    ))
                  : null}
                {isAddRowVisible && (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{getTodayDate()}</TableCell>
                    <TableCell>
                      {currentBufferConsumption.length
                        ? currentBufferConsumption[0].projectcolor
                        : null}
                    </TableCell>
                    <TableCell>
                      {currentBufferConsumption.length
                        ? currentBufferConsumption[0]
                            .percentagebufferconsumption
                        : null}
                    </TableCell>
                    <TableCell>
                      {currentBufferConsumption.length
                        ? currentBufferConsumption[0].percentagecccompletion
                        : null}
                    </TableCell>
                    <TableCell>
                      {currentBufferConsumption.length
                        ? currentBufferConsumption[0].bufferconsumptiondays
                        : null}
                    </TableCell>
                    <TableCell>
                      <Stack direction="column" alignItems="flex-start">
                        {/* <Typography className={modules["error-msg"]}>
                                *
                              </Typography> */}
                        <textarea
                          style={{
                            color: "#000000",
                            font: "13px",
                            fontFamily: "WorkSans",
                            backgroundColor: "#FFFFFF",
                            padding: "2px",
                            width: "210px",
                          }}
                          value={inputPlannedRecovery}
                          className={modules["inputField1"]}
                          onBlur={handleInputPlannedRecBlur}
                          onChange={(e) => {
                            const value = e.target.value;
                            const disallowedCharPattern =
                              /[^a-zA-Z0-9&.+_=\-/?()\[\]\s]/g;
                            if (disallowedCharPattern.test(value)) {
                              return;
                            } else {
                              setInputPlannedRecovery(value);
                            }
                          }}
                        />
                      </Stack>
                    </TableCell>

                    <TableCell>
                      <Stack direction="column" alignItems="flex-start">
                        {/* <Typography className={modules["error-msg"]}>
                                *
                              </Typography> */}
                        <input
                          type="text"
                          value={inputAssignTo}
                          className={modules["inputField1"]}
                          onChange={(e) => {
                            const value = e.target.value;
                            const disallowedCharPattern =
                              /[^a-zA-Z0-9&.+_=\-/()\[\]\s]/g;
                            if (disallowedCharPattern.test(value)) {
                              return;
                            } else {
                              setInputAssignTo(value);
                            }
                          }}
                          onBlur={handleInputAssignBlur}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleInsertBufferRecovery();
                            }
                          }}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {/* <Stack direction="column" alignItems="flex-start">
                              <input
                                type="date"
                                value={inputExpectedActionDate}
                                className={modules["inputField1"]}
                                onChange={(e) => {
                                  setInputExpectedActionDate(e.target.value);
                                }}
                              /> */}
                      <>
                        {/* <input
                          style={{ width: "100px" }}
                          maxLength="20"
                          value={inputExpectedActionDate}
                          onChange={(e) =>
                            setInputExpectedActionDate(e.target.value)
                          }
                          className={
                            modules["input-fieldRMP"]
                          }
                        //  onClick={toggleCalendarOne}
                          onClick={(event) =>
                            setAnchorEl5(
                              event.currentTarget
                            )
                          }
                        />
                        <Popover
                          open={open5}
                          anchorEl={anchorEl5}
                          onClose={() => setAnchorEl5(null)}
                          placement="bottom-start"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Calendar
                            onChange={handleCalendar5n}
                            value={upLikelyDate3}
                            locale="en-US"
                            formatShortWeekday={
                              formatShortWeekday
                            }
                          />
                          <div
                            className={
                              modules["calendar-footer"]
                            }
                          >
                          </div>
                        </Popover> */}
                      </>
                      {/* </Stack> */}
                      <>
                        <div>
                          <input
                            style={{ width: "100px" }}
                            maxLength="20"
                            value={inputExpectedActionDate}
                            onChange={(e) =>
                              setInputExpectedActionDate(e.target.value)
                            }
                            className={modules["input-fieldRMP"]}
                            //  onClick={toggleCalendarOne}
                            onClick={(event) =>
                              setAnchorEl5(event.currentTarget)
                            }
                          />
                          <Popover
                            open={open5}
                            anchorEl={anchorEl5}
                            onClose={() => setAnchorEl5(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Calendar
                              onChange={handleCalendar5n}
                              value={upLikelyDate3}
                              locale="en-US"
                              formatShortWeekday={formatShortWeekday}
                            />
                            <div className={modules["calendar-footer"]}></div>
                          </Popover>
                        </div>
                      </>
                    </TableCell>
                    <TableCell>
                      <Stack direction="column" alignItems="flex-start">
                        {/* <Typography className={modules["error-msg"]}>
                                *
                              </Typography> */}
                        <div style={{ display: "flex" }}>
                          <input
                            type="text"
                            value={inputBufferRecoveryDays}
                            className={modules["inputField1"]}
                            // onChange={(e) => {
                            //   setInputBufferRecoveryDays(e.target.value);
                            // }}
                            style={{ width: "60px" }}
                            onChange={(e) => validateNumber(e)}
                          />
                          {showAsterisk && (
                            <span className={modules["asterisk"]}>*</span>
                          )}
                        </div>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack direction="column" alignItems="flex-start">
                        <select
                          style={{
                            width: "5rem",
                            height: "1.8rem",
                            borderRadius: "5px",
                            appearance: "none",
                            MozAppearance: "none",
                            WebkitAppearance: "none",
                            paddingLeft: "1rem",
                            borderColor: "blue",
                          }}
                          className={modules["dropdownSelectStatus4"]}
                          value="open" // Set the value to "open" so it's always displayed as "Open"
                          onChange={() => {}} // No-op function to prevent changes
                          disabled // Disable the dropdown to make it read-only
                        >
                          <option value="open">Open</option>
                        </select>
                      </Stack>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {/* <Stack direction="column" alignItems="flex-start">
                              <input
                                type="text"
                                value={isInputRemark}
                                className={modules["inputField1"]}
                                onChange={(e) => {
                                  setIsInputRemark(e.target.value);
                                }}
                              />
                              </Stack> */}
                    </TableCell>
                    <TableCell>
                      <button
                        className={modules["insert-btn-active"]}
                        onClick={handleInsertBufferRecovery}
                      >
                        Insert
                      </button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <button
              onClick={handleAddRecovery}
              className={modules["btn-add-btn-recovery-active"]}
            >
              Add Recovery ActionPlan
            </button>
          </div>
        </Grid>
      </>
    );
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div
        style={{
          overflowX:
            showTable && bufferRecoveryData.length > 0 ? "auto" : "hidden",
          height: "100vh",
          zIndex: "1501",
        }}
      >
        {/* // style={{ overflowX: showTable && bufferRecoveryData.length > 0 ? "auto" : "hidden" ,overflowY:showTable && bufferRecoveryData.length > 0 ? "hidden" : "hidden"}} */}

        <Grid container>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"BufferRecovery"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Active Projects</a>
                <img src={bread} alt="" />
                <a>Buffer Recovery Plan</a>
              </div>
            </div>

            <Grid
              container
              className={modules["parent-container"]}
              sx={{ width: showTable ? "100%" : "84%" }}
            >
              <Stack direction="column" sx={{ width: "100%" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ width: "92%", paddingLeft: "1%" }}
                >
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                    sx={{ width: "40%" }}
                  >
                    <div className={modules["input-labels"]}>
                      Instance:<span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1"]}
                      value={Instancevalue}
                      onChange={(e) => handleInstance(e.target.value)}
                      required
                    >
                      {Instanaceoptions.map((i) => (
                        <option value={i.value} key={i.value}>
                          {i.key}
                        </option>
                      ))}
                    </select>
                    <br />
                    {/* {instancemsg} */}
                  </Stack>

                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                    sx={{ width: "40%", paddingLeft: "10%" }}
                  >
                    <div className={modules["input-labels"]}>
                      Project Name:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1"]}
                      value={projectnamevalue}
                      onChange={(e) => setProjectNameValue(e.target.value)}
                    >
                      <option value="Select">SELECT</option>
                      {options.map((i) => (
                        <option value={i.value} key={i.value}>
                          {i.key}
                        </option>
                      ))}
                    </select>
                    {/* {projectNameMsg} */}
                  </Stack>
                </Stack>

                <Stack alignItems="center">
                  <button className={modules["btn1"]} onClick={handleSearch}>
                    Search
                  </button>
                </Stack>
              </Stack>
            </Grid>

            {showCurrentBufferGrid}
            {/* {showTable && ( */}

            {showTable ? (
              TableUI()
            ) : (
              <div
                style={{
                  color: "black",
                  // border: "1px solid black",
                  padding: "8px",
                  fontSize: "15px",
                  marginLeft: "6rem",
                }}
              >
                {msg}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BufferRecovery;
