import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import window_popup from "../../../Assets/window_popup.png";
import Home from "../../../home/Home";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  TextField,
  Stack,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../../Landing/Navbar";
// import modules from "./DelayMaster.module.css";
import modules from "../ccpm.module.css";

import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DatePicker from "react-datepicker";
import { getBufferSchedulersData, removeBufferRowData, insertBuffer } from "../../../Services/Admin";
const DummyData = [
  {
    id: 1,
    col2: "Aerospace",
    col3: "Aerospace",
  },
  {
    id: 2,
    col2: "Innovation and Design Center",
    col3: "IDC",
  },
  {
    id: 3,
    col2: "GB Corporate Projects",
    col3: "GB_CORP",
  },
  {
    id: 4,
    col2: "PSS Gogrej Security Solutions",
    col3: "SSD_PSS",
  },
  {
    id: 5,
    col2: "G&B Finance",
    col3: "G&B Finance",
  },
];

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const BufferScheduler = () => {
  const [showPlus, setShowPlus] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [initialRun, setInitialRun] = useState(false);

  function removeRow(item) {
    var answer = window.confirm("Do you want to Remove the data");
    if (answer) {
      let rowData = {
        "id": item,
        "lastModifiedBy": sessionStorage.getItem('loginId')
      }
      removeBufferRowData(rowData).then((res) => {
        console.log("res", res);
        if (res.status == 200) {
          window.location.href = "/ccpm/BufferScheduler";
        } else {
          console.log("res", res);
        }
      });
    }
  }


  useEffect(() => {
    checkLoginAndRedirect();
    if (initialRun == false) {
      getBufferSchedulersData().then((res) => {
        if (res.data) {
          if (res.data.success.data.length == 0) setInitialRun(true);
          setTableData(res.data.success.data);
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [initialRun]);

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem('loginId');
    if (!loginId) {
      window.location.href = '/';
    }
  };

  function isEmpty(value) {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
  }
  function getBufferData() {
    if (tableData.length == 0) {
      getBufferSchedulersData().then((res) => {
        if (res.data) {
          if (res.data.success.data.length == 0) setInitialRun(true);
          setTableData(res.data.success.data);
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }
  const handleIconToggle = () => {
    setShowPlus(!showPlus);
  };

  const [searchValue, setSearchValue] = useState("");
  const [startHours, setStartHours] = useState("00");
  const [startMints, setStartMints] = useState("00");
  const [effectivefrom, SetEffectiveFrom] = useState("");
  const [dropdownData, setDropdownData] = useState([
    "Tooling",
    "Instance 2",
    "Instance 3",
  ]);
  const [filteredData, setFilteredData] = useState(DummyData);
  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);
  const [frequency, setFrequency] = useState("Select");
  const handleAdd = () => {
    if (isEmpty(effectivefrom) || frequency == "Select") {
      if (isEmpty(effectivefrom)) { alert("Please enter Effective From"); }
      else if (frequency == "Select") { alert("Please Choose Frequency"); }
    } else {
      let bufferBody = {
        startHours: startHours,
        startMin: startMints,
        effectiveFrom: new Date(effectivefrom).toLocaleDateString(),
        frequency: frequency,
        createdBy: sessionStorage.getItem('loginId')
      };
      insertBuffer(bufferBody).then((res) => {
        if (res.status == 200) {
          alert("Updated present date");
          window.location.href = "/ccpm/BufferScheduler";
        }
      })
    }
  };

  const handleReset = () => {
    setSearchValue("");
    setFilteredData(DummyData);
  };

  const [radioValue, setRadioValue] = useState("yes");

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"ScheduleBufferManagementRun"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Schedule Buffer Management Run</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <Grid container columnSpacing={{ xs: 2 }} style={{alignItems:'center'}}>
                <Grid
                  item
                  sx={{ width: "9rem" }}
                >
                  <Typography className={modules["ccpm_label-styles"]}>
                    Add Start Time
                  </Typography>
                </Grid>
                <Grid item>:</Grid>
                <Grid item>

                  <Stack direction="row" spacing={1}>
                    <span>
                      <select className={modules["selectClassBF "]} style={{ width: "60px", height: "32px", padding: "1.5px" ,border:'2px solid blue',fontFamily: "WorkSans, sans-serif",paddingLeft:'12px',borderRadius:'4px',
                          appearance: 'none', // Remove default dropdown icon
                          WebkitAppearance: 'none', // Remove default dropdown icon for Safari
                          MozAppearance: 'none', // Remove default dropdown icon for Firefox
                          background: 'none', // Remove background so that there's no dropdown icon background
                          outline: 'none'}} value={startHours}
                        onChange={(e) => setStartHours(e.target.value)} name="cars" id="cars">
                        <option value="00">00</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                      </select>
                    </span>
                    <span style={{alignSelf:'center'}}>:</span>
                    <span>
                      {/* <input
                      value={startMints}
                      onChange={(e) => setStartMints(e.target.value)}
                      style={{ width: "4rem" }}
                    /> */}
                      <select className={modules["selectClassBF "]} style={{ width: "60px", height: "32px", padding: "1.5px" ,border:'2px solid blue',fontFamily: "WorkSans, sans-serif",paddingLeft:'12px',borderRadius:'4px',
                         appearance: 'none', // Remove default dropdown icon
                         WebkitAppearance: 'none', // Remove default dropdown icon for Safari
                         MozAppearance: 'none', // Remove default dropdown icon for Firefox
                         background: 'none', // Remove background so that there's no dropdown icon background
                         outline: 'none'
                      }} value={startMints}
                        onChange={(e) => setStartMints(e.target.value)} name="cars" id="cars">
                        <option value="00">00</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                      </select>
                    </span>
                  </Stack>

                </Grid>
                {/* <Grid item>

                </Grid> */}

              </Grid>
            </Grid>

            <Grid container className={modules["mid-cont"]}>
              <Grid container columnSpacing={{ xs: 2 }}>
                <Grid
                  item
                  sx={{ width: "9rem" }}
                >
                  <Typography className={modules["ccpm_label-styles"]}>
                    Effective From
                  </Typography>
                </Grid>
                <Grid item>:</Grid>

                <Grid item>
                  <span>
                    <DatePicker style={{width:'5rem !important',height:'18px'}} className={modules["Buff_datePicker"]} dateFormat="dd/MM/YYYY" selected={effectivefrom} onChange={(date) => SetEffectiveFrom(date)} />
                  </span>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={modules["mid-cont"]}>
              <Grid container columnSpacing={{ xs: 2 }}>
                <Grid
                  item
                  sx={{ width: "9rem", }}
                >
                  <Typography className={modules["ccpm_label-styles"]}>
                    Frequency
                  </Typography>

                </Grid>
                <Grid item>:</Grid>
                <Grid item>
                  <FormControl className={modules["form-control"]} style={{ borderRadius: "15px !important", paddingRight: '0' }}>
                    <select
                      IconComponent={ArrowDropDownIcon}
                      className={modules["buff_dropdownSelect"]}
                      value={frequency}
                      onChange={(e) => setFrequency(e.target.value)}
                      style={{ fontSize: '12px', width: "15rem", borderRadius: "15px !important" }}
                      
                    >
                      <option style={{ fontSize: '12px' }} value="Select">--Select--</option>
                      <option style={{ fontSize: '12px' }} value="Daily">Daily</option>
                    </select>
                  </FormControl>
                  <Grid
                    container
                  // className={modules["buff_btn1-cont"]}
                  >
                    <button style={{ marginTop: "0.8rem", marginLeft: "0rem", marginBottom: "0.5rem !important" }} className={modules["btn1"]} onClick={handleAdd}>
                      ADD
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            {tableData.length != 0 ?
              <Grid container className={modules["table-grid"]} style={{ marginTop: "1.5rem", }}>
                <div className={modules["table-container"]} style={{ width: "40%" }}>
                  <Table className={modules["custom-table"]}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={modules["table-cell"]}>Sr. No.</TableCell>
                        <TableCell className={modules["table-cell"]}>Start Time</TableCell>
                        <TableCell className={modules["table-cell"]}>Effective From </TableCell>
                        <TableCell className={modules["table-cell"]}>Frequency</TableCell>
                        <TableCell className={modules["table-cell"]}>Remove</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((item, index) => (
                        <TableRow key={index} className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            <Typography className={modules["ccpm_label-styled"]}>
                              {item.id}
                            </Typography>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <Typography className={modules["ccpm_label-styled"]}>
                              {item.starthours}:{item.startmin}
                            </Typography>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <Typography className={modules["ccpm_label-styled"]}>
                              {new Date(item.effectivefrom).toLocaleDateString('en-GB')}
                            </Typography>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <Typography className={modules["ccpm_label-styled"]}>
                              {item.frequency}
                            </Typography>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <div className={modules["btn2-cont"]} style={{ justifyContent: "center", cursor: "pointer", hover: { color: "red" } }}>
                              <a style={{ fontSize: '14px' }} onClick={() => removeRow(item.id)}>Remove</a>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                      </TableRow>
                    </TableBody>
                  </Table>

                  {/* <Grid container className={modules["btn2-cont"]}>
                    <button >Add</button>
                  </Grid> */}
                </div>
              </Grid> : " "}

          </Grid>
        </Grid>
      </Grid >
    </>
  );
};

export default BufferScheduler;
