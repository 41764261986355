import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import UM1 from "../HelpPopups/images/UM1.jpg";
import UM2 from "../HelpPopups/images/UM2.jpg";
import UM3 from "../HelpPopups/images/UM3.jpg";
import UM4 from "../HelpPopups/images/UM4.jpg";
import { Link } from "react-router-dom";

const UnitMasterhelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link style={{ color: "blue", marginRight: "0.6rem" }}>
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
            <h3 style={{ marginLeft: "5rem" }}>Unit Master</h3>
            <br />
              <li>
                Unit Master screen is used for creating and maintaining the different types of units.
              </li>
              <li>PATH : CCPM \ Masters \ Unit Master.</li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={UM1} alt="UM1" />
              </Stack>
              <li>
                For adding the new Unit, Click on “Add” button and enter the text in the available input box and click on “Save” link for saving the record or we can use the “Cancel” link for Canceling the transaction.
              </li>
              <li>
                For deleting any individual record, we can use the “Delete” link of the particular record, or we can delete multiple records at once by selecting the records using the check boxes and click on the “Delete” button, selected records will get deleted.
              </li>
              <li>
                For doing any updations to the data, click on Edit link and do the required changes and click on Save link to save the changes or click on Cancel link to cancel the changes done to the data.
              </li>
              <br />

              <li style={{ listStyleType: "square", textDecoration: "underline" }}>Validations on the screen:-</li>

              <ul>

                <li>
                  Duplication of existing record should not be allowed i.e re-adding of the existing record again
                </li>
                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={UM2} alt="UM2" />
                </Stack>
                <li>User should not leave any input field blank before saving the data, as click of “Save” link keeping the fields empty makes the error popup window to appear</li>
                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={UM3} alt="UM3" />
                </Stack>
                <li>
                  User should make selection of the data before the click of “Delete” button, as without selection of data the popup window appears
                </li>
                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={UM4} alt="UM4" />
                </Stack>
              </ul>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UnitMasterhelpPopup;
