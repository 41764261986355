import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import {
  IOA_GetImosData,
  IOA_GetECommerceData,
  IOA_GetBPPAndColorData,
  IOA_GetPurchaseData,
  IOA_GetSalesAllocationData,
  IOA_GetProductionData,
  IOA_GetRPLData,
  IOA_Resend,
} from "../../../Services/MTA.js";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation, useNavigate } from "react-router-dom";

export default function InboundOrderAdviceData() {
  const location = useLocation();
  const nData = location.state?.nData;
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [changed, setChanged] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getTableData(page);
    console.log(nData);
  }, []);

  const getTableData = (C_page) => {
    if (nData?.inboundOrder === "RPL") {
      DisplayRPLData(C_page);
    } else if (nData?.inboundOrder === "Production") {
      DisplayProductionData(C_page);
    } else if (nData?.inboundOrder === "Purchase") {
      DisplayPurchaseData(C_page);
    } else if (nData?.inboundOrder === "Sales Allocation") {
      DisplaySalesData(C_page);
    } else if (nData?.inboundOrder === "WH - BPP and Color") {
      DisplayColorData(C_page);
    } else if (nData?.inboundOrder === "eCom") {
      DisplayeComData(C_page);
    } else if (nData?.inboundOrder === "Imos") {
      DisplayImosData(C_page);
    }
  };

  const DisplayRPLData = async (C_page) => {
    const cd = convertDateToISO(nData.currentDate);
    const pn = C_page;
    console.log(pn, "cd, pn");
    const body = {
      currentDate: cd,
      instance_uid: nData.instanceid,
      orderStatus: nData.orderStatus,
      rploal_sent_to_erp_on: convertDateToISO2(nData.sentToERP),
      uniqueReference: nData.uniqueReference,
    };
    setIsLoading(true);
    IOA_GetRPLData(body, nData.reportType, pn)
      .then((response) => {
        console.log(response?.data?.data, "rpl data");
        setTableData(response?.data?.data);
        setDataCount(response?.data?.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DisplayProductionData = async (C_page) => {
    const cd = convertDateToISO(nData.currentDate);
    const body = {
      currentDate: cd,
      instance_uid: nData.instanceid,
      orderStatus: nData.orderStatus,
      prodoal_sent_to_erp_on: convertDateToISO2(nData.sentToERP),
      uniqueReference: nData.uniqueReference,
    };
    setIsLoading(true);
    IOA_GetProductionData(body, nData.reportType, C_page)
      .then((response) => {
        console.log(response?.data?.data, "rpl data");
        setTableData(response?.data?.data);
        setDataCount(response?.data?.totalCount);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DisplayPurchaseData = async (C_page) => {
    const cd = convertDateToISO(nData.currentDate);
    const body = {
      currentDate: cd,
      instance_uid: nData.instanceid,
      orderStatus: nData.orderStatus,
      prodoal_sent_to_erp_on: convertDateToISO2(nData.sentToERP),
      uniqueReference: nData.uniqueReference,
    };
    setIsLoading(true);
    IOA_GetPurchaseData(body, nData.reportType, C_page)
      .then((response) => {
        console.log(response?.data?.data, "rpl data");
        setTableData(response?.data?.data);
        setDataCount(response?.data?.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DisplaySalesData = async (C_page) => {
    const cd = convertDateToISO(nData.currentDate);
    const body = {
      instance_uid: nData.instanceid,
      soaa_sales_order_allocation_status: nData.orderStatus,
    };
    setIsLoading(true);
    IOA_GetSalesAllocationData(body, nData.reportType, C_page)
      .then((response) => {
        console.log(response?.data?.data, "rpl data");
        setTableData(response?.data?.data);
        setDataCount(response?.data?.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DisplayColorData = async (C_page) => {
    const cd = convertDateToISO(nData.currentDate);
    const body = {
      currentDate: cd,
      instance_uid: nData.instanceid,
      orderStatus: nData.orderStatus,
      puroal_sent_to_erp_on: convertDateToISO2(nData.sentToERP),
      uniqueReference: nData.uniqueReference,
    };
    setIsLoading(true);
    IOA_GetBPPAndColorData(body, nData.reportType, C_page)
      .then((response) => {
        console.log(response?.data?.data, "rpl data");
        setTableData(response?.data?.data);
        setDataCount(response?.data?.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DisplayeComData = async (C_page) => {
    const cd = convertDateToISO(nData.currentDate);
    const body = {
      currentDate: cd,
      instance_uid: nData.instanceid,
      orderStatus: nData.orderStatus,
      sent_to_ecom_on: convertDateToISO2(nData.sentToERP),
      uniqueReference: nData.uniqueReference,
    };
    setIsLoading(true);
    IOA_GetECommerceData(body, nData.reportType, C_page)
      .then((response) => {
        console.log(response?.data?.data, "rpl data");
        setTableData(response?.data?.data);
        setDataCount(response?.data?.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DisplayImosData = async (C_page) => {
    const cd = convertDateToISO(nData.currentDate);
    const body = {
      currentDate: cd,
      instance_uid: nData.instanceid,
      orderStatus: nData.orderStatus,
      sent_to_imos_on: convertDateToISO2(nData.sentToERP),
      uniqueReference: nData.uniqueReference,
    };
    setIsLoading(true);
    IOA_GetImosData(body, nData.reportType, C_page)
      .then((response) => {
        console.log(response?.data?.data, "rpl data");
        setTableData(response?.data?.data);
        setDataCount(response?.data?.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ResendHandler = async () => {
    if (selectedItems.length === 0) {
      alert("No records selected");
      return;
    }
    const today = new Date().toISOString().split("T")[0];
    const body = {
      inboundOrder: nData.inboundOrder,
      orderStatus: nData.orderStatus,
      senttoERPOnDate: today,
      gridViewRows: selectedItems,
    };
    // gridViewRows: [
    //   {
    //     uniqueReference: "string",
    //     orderStatus: "string",
    //     isChecked: true,
    //   },
    // ],
    console.log(body, "body");
    setIsLoading(true);
    IOA_Resend(body, nData.reportType)
      .then((response) => {
        window.location.reload();
        // if (response?.data?.statusCode === 200) {
        alert("Record updated successfully");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const navigate = useNavigate();

  const backHandler = () => {
    navigate("/mtaparameter/InboundOrderAdvice", { state: { nData } });
  };

  function convertDateToISO(dateString) {
    const date = new Date(dateString);
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return utcDate.toISOString().split("T")[0];
  }

  function convertDateToISO2(dateString) {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  }

  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (rowIndex, isChecked) => {
    setCheckedItems({
      ...checkedItems,
      [rowIndex]: isChecked,
    });

    if (isChecked) {
      const newItem = {
        instance_uid: nData.instanceid,
        uniqueReference: tableData[rowIndex]["Unique Reference"],
        orderStatus: tableData[rowIndex]["Order Status"],
        isChecked: isChecked,
      };
      setSelectedItems([...selectedItems, newItem]);
    } else {
      setSelectedItems(
        selectedItems.filter(
          (item) =>
            item.uniqueReference !== tableData[rowIndex]["Unique Reference"]
        )
      );
    }

    console.log(selectedItems, "selected items");
  };

  function previousPageHandler() {
    if (page > 1) {
      setpage(page - 1);
      getTableData(page - 1);
    }
  }

  function nextPageHandler() {
    setpage(page + 1);
    getTableData(page + 1);
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid
        container
        // style={{
        //   overflowY: "auto",
        //   overflowX: "auto",
        // }}
      >
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12}>
            <Grid>
              <Grid container className={modules["mid-cont"]}>
                <TableContainer>
                  <Table
                    className={modules["custom_table"]}
                    style={{ width: "80%" }}
                  >
                    <TableHead>
                      <TableRow style={{ borderRadius: "0px 5px" }}>
                        <TableCell
                          align="left"
                          colSpan={3}
                          style={{
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                          }}
                        >
                          MTA Parameter {">>"} Inbound Order Advice - GnB CR
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                          }}
                        >
                          <span
                            style={{ padding: "0px 4px", cursor: "pointer" }}
                            onClick={toggleExpand}
                          >
                            {isExpanded
                              ? "Collapse Filters [-]"
                              : "Expand Filters [+]"}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {isExpanded && (
                      <tbody
                        className={`${modules["expand-collapse"]} ${
                          isExpanded ? modules["expanded"] : ""
                        }`}
                        style={{ border: "1px solid #a4bf47", width: "200%" }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>MTA Instance</strong>
                          </td>
                          <td colSpan="3" style={{ textAlign: "left" }}>
                            <span id="ContentPlaceHolder1_lblFilterMTA">
                              {nData.instanceid}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Inbound Order</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {nData.inboundOrder ? nData.inboundOrder : "-"}
                            </span>
                          </td>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Order Status</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {nData.orderStatus ? nData.orderStatus : "-"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Sent to ERP On Date</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                            colSpan="3"
                          >
                            <span>
                              {nData.sentToERP ? nData.sentToERP : "-"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Unique Reference</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                            colSpan="3"
                          >
                            <span>
                              {nData.uniqueReference
                                ? nData.uniqueReference
                                : "-"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Report Type</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                            colSpan="3"
                          >
                            <span>
                              {nData.reportType ? nData.reportType : "-"}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </TableContainer>
                <Grid
                  container
                  align="left"
                  style={{
                    marginTop: "10px",
                    justifyContent: "left",
                    marginRight: "6rem",
                    width: "100px",
                  }}
                >
                  <button
                    className={modules["btn1"]}
                    style={{ width: "100px" }}
                    onClick={backHandler}
                  >
                    Back
                  </button>
                </Grid>
              </Grid>
              <Grid container className={modules["mid-cont"]}>
                {tableData?.length > 0 ? (
                  <>
                    <TableContainer style={{ overflow: "unset" }}>
                      <Table className={modules["custom_table"]}>
                        <TableHead
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            backgroundColor: "white",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{ padding: "12px", width: "150px" }}
                            >
                              Resend
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ padding: "12px", width: "150px" }}
                            >
                              Sr.No.{" "}
                            </TableCell>
                            {Object.keys(tableData[0]).map((header, index) => (
                              <TableCell
                                key={index}
                                align="center"
                                style={{
                                  padding: "12px",
                                  width: "150px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {header.replace(/_/g, " ")}{" "}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableData.map((row, rowIndex) => (
                            <TableRow
                              key={rowIndex}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{ borderBottom: "1px solid #a4bf47" }}
                            >
                              <TableCell
                                align="center"
                                style={{ padding: "12px", width: "150px" }}
                              >
                                {row["Order Status"] &&
                                  !row["Order Status"].includes(
                                    "To be resent"
                                  ) && (
                                    <input
                                      type="checkbox"
                                      className={modules["custom-checkbox"]}
                                      checked={checkedItems[rowIndex] || false}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          rowIndex,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  )}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ padding: "12px", width: "150px" }}
                              >
                                {rowIndex + 1 + (page - 1) * 800}
                              </TableCell>
                              {Object.values(row).map((value, colIndex) => (
                                <TableCell key={colIndex} align="center">
                                  {/* Handle empty values or null fields */}
                                  {value !== null && value !== "" ? value : "-"}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                          <TableRow>
                            {dataCount > 800 && (
                              <>
                                <TableCell colSpan={3}>
                                  {page > 1 && (
                                    <span
                                      style={{
                                        color: "#0000EE",
                                        // marginLeft: "30px",
                                        marginRight: "25px",
                                        cursor: "pointer",
                                      }}
                                      onClick={previousPageHandler}
                                    >
                                      {"\u2190"} Previous Page
                                      {/* {"<"} */}
                                    </span>
                                  )}

                                  {tableData?.length >= 800 && (
                                    <span
                                      style={{
                                        color: "#0000EE",
                                        cursor: "pointer",
                                      }}
                                      onClick={nextPageHandler}
                                    >
                                      Next Page {"\u2192"}
                                      {/* {">"} */}
                                    </span>
                                  )}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br />
                    <button
                      className={modules["btn1"]}
                      style={{
                        width: "100px",
                        marginTop: "1rem",
                        marginBottom: "2rem",
                        marginLeft: "35rem",
                        alignSelf: "center",
                      }}
                      onClick={ResendHandler}
                    >
                      Resend
                    </button>
                    <br />
                  </>
                ) : (
                  <div style={{ height: "80vh" }}>
                    <div
                      style={{
                        color: "#414141",
                        fontSize: "12px",
                        padding: "8px",
                        borderBottom: "1px solid #a4bf47",
                      }}
                    >
                      No Records Found
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
