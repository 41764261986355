import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControlLabel,
  RadioGroup,
  Stack,
  Popover,
  Paper,
} from "@mui/material";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import Radio from "@mui/material/Radio";
import {
  GetInstancesListt,
  CreatePlanInstanceChange,
  SubmitCreatePlan,
  GetAttributes,
  CreatePlanSubmitClick2,
  getExcelFormatFile,
  CreatePlanSubmitClick3,
  getDownloadResultFile
} from "../../../Services/CCPM";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import "./CalendarStyles.css";
import loading from "../../../Assets/Loading_image.gif";
import { styled } from "@mui/system";

//custom font size for radio button label
const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiTypography-root": {
    fontSize: "12px",
    fontFamily: "'Work Sans', sans-serif",
    color: "#414141",
    height: "14px",
  },
});

const CreateNewPlan = () => {
  // Allow only letters, numbers, _, -, and space
  const handleProjectNameChange = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z0-9 _-]/g, "");
    setProjectName(filteredValue);
  };

  const [showbutton, setShowbutton] = useState(true); //to show submit button

  //handle calendar states
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const id1 = open1 ? "simple-popover" : undefined;
  const id2 = open2 ? "simple-popover" : undefined;
  const id3 = open3 ? "simple-popover" : undefined;

  //for calendar popup days representation (Mo Tu We....)
  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: "short" }).slice(0, 2);
  };

  // for storing attributes
  const [dropdowns, setDropdowns] = useState([]);
  const [value, setValue] = useState({});

  //handles attributes dropdown selection
  const handleDropdownSelection = (e, i) => {
    const { value } = e.target;
    setValue((prevValue) => ({
      ...prevValue,
      [`attribute${i + 1}Id`]: value,
    }));
  };

  //loading gif
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
    };
    GetInstancesListt(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setInstance(response.data.success.data);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const today = new Date();

  //converts current date to yyyy-mm-dd format (for comparing enetered dates with current date)
  const cyear = today.getFullYear();
  const cmonth = String(today.getMonth() + 1).padStart(2, "0");
  const cday = String(today.getDate()).padStart(2, "0");
  const cformattedDate = `${cyear}-${cmonth}-${cday}`;

  //for calendar bottom text
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedToday = today.toLocaleDateString("en-US", options);

  //CALENDAR PART (3 CALENDARS)
  const [displaydate1, setDisplaydate1] = useState("");
  const [displaydate2, setDisplaydate2] = useState("");
  const [displaydate3, setDisplaydate3] = useState("");
  const [passdate1, setPassdate1] = useState("");
  const [passdate2, setPassdate2] = useState("");
  const [passdate3, setPassdate3] = useState("");
  const [custumerduedate, setCustomerduedate] = useState("");
  const [projstartdate, setProjstartdate] = useState("");
  const [reqduedate, setReqduedate] = useState("");

  //customer due date selected
  const handleCalendar1n = (date) => {
    setAnchorEl1(null);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-"); //to display
    setCustomerduedate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setDisplaydate1(formattedDate); //display
    setPassdate1(`${year}-${month}-${day}`); //pass to api format
  };

  //project start date selected
  const handleCalendar2n = (date) => {
    setAnchorEl2(null);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");
    setProjstartdate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setDisplaydate2(formattedDate);
    setPassdate2(`${year}-${month}-${day}`);
  };

  //requested due date selected
  const handleCalendar3n = (date) => {
    setAnchorEl3(null);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");
    setReqduedate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setDisplaydate3(formattedDate);
    setPassdate3(`${year}-${month}-${day}`);
  };

  const [selectedValue, setSelectedValue] = useState("option1"); //radio button index selection
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "The page that you're looking for used information that you entered. Returning to that page might cause any action you took to be repeated. Do you want to continue?";
  };
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     console.log("selectedvalue",selectedValue)
  //     if (selectedValue === "option3") {
  //       event.preventDefault();
  //       const message =
  //         "The page that you're looking for used information that you entered. Returning to that page might cause any action you took to be repeated. Do you want to continue?";

  //       event.returnValue = message;
  //       return message;
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [selectedValue]);

  //RADIO BUTTON SELECTION
  const handleChange = (event) => {
    setShowbutton(false);
    setSelectedValue(event.target.value);
    localStorage.setItem('selectedOption', event.target.value);
    setProjectName("");
    setProjectAddDetails("");
    setSelectedInstance("Select");
    setAttachCalendar([]);
    setCustomer([]);
    setProjectManager([]);
    setSelectedAttachCalendar("");
    setSelectedCustomer("");
    setSelectedProjectManager("");
    setProjectTemplate([]);
    setSelectedProjectTemplate("");

    setPassdate1("");
    setPassdate2("");
    setPassdate3("");
    setDisplaydate1("");
    setDisplaydate2("");
    setDisplaydate3("");
    setCustomerduedate("");
    setProjstartdate("");
    setReqduedate("");

    setDropdowns([]);
    setHeaders([]);
    setValue({});
    setSelectedInputFormatFile("");
    setErpCompany("");
    setErpProject("");
  };

  //PROJECT NAME USESTATE
  const [projectName, setProjectName] = useState("");

  //INSTANCE USESTATE
  const [instance, setInstance] = useState([]);
  //SELECTED INSTANCE USESTATE
  const [selectedInstance, setSelectedInstance] = useState("Select");

  //ATTACH CALENDAR USESTATE
  const [attachCalendar, setAttachCalendar] = useState([]);
  //SELECTED ATTACH CALENDAR USESTATE
  const [selectedAttachCalendar, setSelectedAttachCalendar] = useState("");

  //CUSTOMER USESTATE
  const [customer, setCustomer] = useState([]);
  //SELECTED CUSTOMER USESTATE
  const [selectedCustomer, setSelectedCustomer] = useState("");

  //PROJECT MANAGER USESTATE
  const [projectManager, setProjectManager] = useState([]);
  //SELECTED PROJECT MANAGER USESTATE
  const [selectedProjectManager, setSelectedProjectManager] = useState("");

  //PROJECT ADDITIONAL DETAILS USESTATE
  const [projectAddDetails, setProjectAddDetails] = useState("");

  //PROJECT TEMPLATE USESTATE
  const [projectTemplate, setProjectTemplate] = useState([]);
  //SELECTED PROJECT TEMPLATE USESTATE
  const [selectedProjectTemplate, setSelectedProjectTemplate] = useState("");

  //INPUT FORMAT FILE USESTATE
  const [inputFormatFile, setInputFormatFile] = useState([
    {
      id: 1,
      filename: "Existing Project Task Format",
    },
    {
      id: 2,
      filename: "New Single Sheet Format",
    },
    {
      id: 3,
      filename: "New Single Sheet With Dependency",
    },
  ]);
  //SELECTED INPUT FORMAT FILE USESTATE
  const [selectedInputFormatFile, setSelectedInputFormatFile] = useState("");

  //ERP COMPANY USESTATE
  const [erpCompany, setErpCompany] = useState("");

  //ERP PROJECT USESTATE
  const [erpProject, setErpProject] = useState("");

  const [headers, setHeaders] = useState([]); //stores attribute labels

  const [selectedFile, setSelectedFile] = useState(null); // State to store the selected file

  const [excelbinaryData, setExcelBinaryData] = useState("");

  const [importExcelShowBtn, SetimportExcelShowBtn] = useState(false);
  const [showResetBtn,setShowResetBtn]=useState(false);
  const [downloadResultBtn,setdownloadResultBtn]=useState(false)

  const handleFileSelect = (file) => {
    if (file) {
      setSelectedFile(file);
    }
  };

  //WHEN INSTANCE SELECTED
  const handleInstance = (i, radioindex) => {
    setShowbutton(true);
    setIsLoading(true);
    setSelectedInstance(i);
    setSelectedAttachCalendar("Select");
    setSelectedCustomer("Select");
    setSelectedProjectManager("Select");
    setSelectedProjectTemplate("Select");
    setDropdowns([]);
    setHeaders([]);
    setValue({});
    let body = {
      instanceIndex: i === "Select" ? 0 : i,
      rblOptions: radioindex,
      userid: sessionStorage.getItem("loginId"),
      customerName: "",
    };
    CreatePlanInstanceChange(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setAttachCalendar(response.data.success.data.BindCalendarNames);
          setCustomer(response.data.success.data.BindCustomerNames);
          setProjectManager(
            response.data.success.data.GetDivisionWiseActiveUser
          );
          if (
            response.data.success.data.BindIsTemplatePrjtNames !== undefined
          ) {
            setProjectTemplate(
              response.data.success.data.BindIsTemplatePrjtNames
            );
          }
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
          setAttachCalendar([]);
          setCustomer([]);
          setProjectManager([]);
          setProjectTemplate([]);
          setSelectedInstance("Select");
        } else if (response?.response?.data?.error?.message !== undefined) {
          alert(response?.response?.data?.error?.message);
          setIsLoading(false);
          setAttachCalendar([]);
          setCustomer([]);
          setProjectManager([]);
          setProjectTemplate([]);
          setSelectedInstance("Select");
        } else if (response?.response?.data?.success?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
          setAttachCalendar([]);
          setCustomer([]);
          setProjectManager([]);
          setProjectTemplate([]);
          setSelectedInstance("Select");
        } else {
          alert(response?.response?.data);
          setIsLoading(false);
          setSelectedInstance("Select");
          setAttachCalendar([]);
          setCustomer([]);
          setProjectManager([]);
          setProjectTemplate([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleProjectManager = (i) => {
    setSelectedProjectManager(i);
    setIsLoading(true);
    setValue({});
    let body = {
      userId: i,
      instanceId: selectedInstance,
    };
    GetAttributes(body)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          if (res.data.success === undefined) {
            alert(res.data.message);
            setDropdowns([]);
            setHeaders([]);
          } else {
            const filteredData = res.data.success.data.filter((subArray) => {
              return !subArray.every((obj) => obj.hasOwnProperty("na"));
            });
            setDropdowns(filteredData);
            setHeaders(
              filteredData.map((item) => {
                return item[0].attributename;
              })
            );
          }
        } else if (res?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(res?.response?.data?.message);
          setDropdowns([]);
          setHeaders([]);
        } else if (res?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(res?.response?.data?.error?.message);
          setDropdowns([]);
          setHeaders([]);
        } else {
          setIsLoading(false);
          alert(res?.response?.data?.success?.message);
          setDropdowns([]);
          setHeaders([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitReset = () => {
    setSelectedInstance("Select");
    setSelectedAttachCalendar("");
    setSelectedCustomer("");
    setSelectedProjectManager("");
    setAttachCalendar([]);
    setCustomer([]);
    setProjectManager([]);
    setProjectAddDetails("");
    setDisplaydate1("");
    setPassdate1("");
    setCustomerduedate("");
    setDisplaydate2("");
    setPassdate2("");
    setProjstartdate("");
    setDisplaydate3("");
    setPassdate3("");
    setReqduedate("");
    setProjectName("");
    setValue({});
    setDropdowns([]);
    setHeaders([]);
    setProjectTemplate([]);
    setSelectedProjectTemplate("");
    setSelectedInputFormatFile("");
    localStorage.removeItem('selectedOption');
  };

  const handleSubmit = (sv) => {
    setShowResetBtn(true)
    if (
      projectName === "" ||
      passdate1 === "" ||
      passdate2 === "" ||
      passdate3 === "" ||
      selectedInstance === "Select" ||
      selectedInstance === "" ||
      selectedCustomer === "Select" ||
      selectedCustomer === "" ||
      selectedProjectManager === "Select" ||
      selectedProjectManager === "" ||
      selectedAttachCalendar === "Select" ||
      selectedAttachCalendar === "" ||
      displaydate1 === "" ||
      displaydate2 === "" ||
      displaydate3 === ""
    ) {
      alert("Please Enter/Select Values for All Mandatory Fields");
    } else if (passdate2 < cformattedDate) {
      alert("Please select valid Project Start Date(greater than today).");
    } else if (passdate1 < cformattedDate || passdate1 < passdate2) {
      alert(
        "Please select valid Customer Due Date(greater than today & greater than Project Start Date)."
      );
    } 
   else if(sv === "option3"&& !selectedFile){
      alert("Please select/browse a valid excel file and import")
      }
    else if (
      passdate3 < cformattedDate ||
      passdate3 < passdate2 ||
      passdate3 > passdate1
    ) {
      alert(
        "Please select valid Requested Due Date(greater than today & less than Customer Due Date)."
      );
    } else if (Object.keys(value).length !== dropdowns.length) {
      for (let i = 0; i < dropdowns.length; i++) {
        if (value[`attribute${i + 1}Id`] === undefined) {
          alert(`Please select ${headers[i]}`);
          break;
        }
      }
    } else {
      setIsLoading(true);
      if (sv === "option1") {
        let body = {
          projectName: projectName,
          erpProjectNo: "",
          projectManager: selectedProjectManager,
          instanceId: selectedInstance,
          attribute1Id:
            value.attribute1Id !== undefined ? value.attribute1Id : 0,
          attribute2Id:
            value.attribute2Id !== undefined ? value.attribute2Id : 0,
          attribute3Id:
            value.attribute3Id !== undefined ? value.attribute3Id : 0,
          attribute4Id:
            value.attribute4Id !== undefined ? value.attribute4Id : 0,
          attribute5Id:
            value.attribute5Id !== undefined ? value.attribute5Id : 0,
          attribute6Id:
            value.attribute6Id !== undefined ? value.attribute6Id : 0,
          attribute7Id:
            value.attribute7Id !== undefined ? value.attribute7Id : 0,
          attribute8Id:
            value.attribute8Id !== undefined ? value.attribute8Id : 0,
          attribute9Id:
            value.attribute9Id !== undefined ? value.attribute9Id : 0,
          attribute10Id:
            value.attribute10Id !== undefined ? value.attribute10Id : 0,
          customerId: selectedCustomer,
          customerDueDate: passdate1,
          requestedDueDate: passdate3,
          taktUnit: 0,
          createdFrom:
            selectedValue === "option1"
              ? 1
              : selectedValue === "option2"
              ? 2
              : selectedValue === "option3"
              ? 3
              : 4,
          userID: parseInt(sessionStorage.getItem("loginId")),
          calendarId: selectedAttachCalendar,
          projectStartDate: passdate2,
          erpCompany: "",
          projectDetails: projectAddDetails,
        };
        SubmitCreatePlan(body)
          .then((response) => {
            if (response.status === 200) {
              setIsLoading(false);
              alert(response.data.message);
            } else if (response?.response?.data?.message !== undefined) {
              setIsLoading(false);
              alert(response?.response?.data?.message);
            } else if (response?.response?.data?.error?.message !== undefined) {
              setIsLoading(false);
              alert(response?.response?.data?.error?.message);
            } else {
              setIsLoading(false);
              alert(response?.response?.data?.success?.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        handleSubmitReset();
      } 
      else if (sv === "option2") {
        let body = {
          projectName: projectName,
          erpProjectNo: "",
          projectManager: selectedProjectManager || 0,
          instanceId: selectedInstance || 0,
          attribute1Id:
            value.attribute1Id !== undefined ? value.attribute1Id : 0,
          attribute2Id:
            value.attribute2Id !== undefined ? value.attribute2Id : 0,
          attribute3Id:
            value.attribute3Id !== undefined ? value.attribute3Id : 0,
          attribute4Id:
            value.attribute4Id !== undefined ? value.attribute4Id : 0,
          attribute5Id:
            value.attribute5Id !== undefined ? value.attribute5Id : 0,
          attribute6Id:
            value.attribute6Id !== undefined ? value.attribute6Id : 0,
          attribute7Id:
            value.attribute7Id !== undefined ? value.attribute7Id : 0,
          attribute8Id:
            value.attribute8Id !== undefined ? value.attribute8Id : 0,
          attribute9Id:
            value.attribute9Id !== undefined ? value.attribute9Id : 0,
          attribute10Id:
            value.attribute10Id !== undefined ? value.attribute10Id : 0,
          customerId: selectedCustomer || 0,
          customerDueDate: passdate1,
          requestedDueDate: passdate3,
          taktUnit: 0,
          createdFrom:
            selectedValue === "option1"
              ? 1
              : selectedValue === "option2"
              ? 2
              : selectedValue === "option3"
              ? 3
              : 4,
          userID: parseInt(sessionStorage.getItem("loginId")),
          calendarId: selectedAttachCalendar || 0,
          projectStartDate: passdate2,
          erpCompany: "",
          projectDetails: projectAddDetails,
          templateId: selectedProjectTemplate || 0,
        };
        CreatePlanSubmitClick2(body)
          .then((response) => {
            if (response.status === 200) {
              setIsLoading(false);
              console.log(response);
              alert(response.data.Message);
            } else if (response?.response?.data?.message !== undefined) {
              setIsLoading(false);
              alert(response?.response?.data?.message);
            } else if (response?.response?.data?.error?.message !== undefined) {
              setIsLoading(false);
              alert(response?.response?.data?.error?.message);
            } else {
              setIsLoading(false);
              alert(response?.response?.data?.success?.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        handleSubmitReset();
      } 
      else if (sv === "option3") {
        let body = {
            projectName: projectName,
            erpProjectNo: "",
            projectManager: selectedProjectManager || 0,
            instanceId: selectedInstance || 0,
            attribute1Id: value.attribute1Id !== undefined ? value.attribute1Id : 0,
            attribute2Id: value.attribute2Id !== undefined ? value.attribute2Id : 0,
            attribute3Id: value.attribute3Id !== undefined ? value.attribute3Id : 0,
            attribute4Id: value.attribute4Id !== undefined ? value.attribute4Id : 0,
            attribute5Id: value.attribute5Id !== undefined ? value.attribute5Id : 0,
            attribute6Id: value.attribute6Id !== undefined ? value.attribute6Id : 0,
            attribute7Id: value.attribute7Id !== undefined ? value.attribute7Id : 0,
            attribute8Id: value.attribute8Id !== undefined ? value.attribute8Id : 0,
            attribute9Id: value.attribute9Id !== undefined ? value.attribute9Id : 0,
            attribute10Id: value.attribute10Id !== undefined ? value.attribute10Id : 0,
            customerId: selectedCustomer || 0,
            customerDueDate: passdate1,
            requestedDueDate: passdate3,
            taktUnit: 0,
            createdFrom: selectedValue === "option1" ? 1 : selectedValue === "option2" ? 2 : selectedValue === "option3" ? 3 : 4,
            userID: parseInt(sessionStorage.getItem("loginId")),
            calendarId: selectedAttachCalendar || 0,
            projectStartDate: passdate2,
            erpCompany: "",
            projectDetails: projectAddDetails,
            templateId: selectedInputFormatFile || 0,
            file: selectedFile, // Note: If you need to handle file uploads differently, adjust accordingly
        };
    
        CreatePlanSubmitClick3(body)
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false);
                    alert(response.data.message);
                    console.log(downloadResultBtn);
                    console.log("selectedInputFormatFile",selectedInputFormatFile) 
                     if(selectedInputFormatFile==2){
                      setdownloadResultBtn(true);
                      console.log("response createnewplan3", response);
                    }                  
                } else if (response?.response?.data?.message !== undefined) {
                    setIsLoading(false);
                    alert(response?.response?.data?.message);
                } else if (response?.response?.data?.error?.message !== undefined) {
                    setIsLoading(false);
                    alert(response?.response?.data?.error?.message);
                } else {
                    setIsLoading(false);
                    alert(response?.response?.data?.success?.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
            //setdownloadResultBtn(true);
        //handleSubmitReset();
    }
    }
  };
  const getDownloadResultBtn = async () => {
    try {
      let obj = {
        inputFormateFile: parseInt(selectedInputFormatFile),
        userID: parseInt(sessionStorage.getItem("loginId")),
      };
      // Ensure getDownloadResultFile is properly defined and returns a promise
      let res = await getDownloadResultFile(obj);
       console.log("hdjdndkd",res);
      // Check if the response is valid
      if (res && res.success) {
        console.log("Download result received:", res.data);
        // Handle successful response (e.g., trigger download, update UI)
      } else {
        console.error("Error fetching download result:", res.message);
        // Handle error appropriately
      }
    } catch (error) {
      console.error("An error occurred while fetching the download result:", error);
      // Handle the error (e.g., show a notification to the user)
    }
  };
  
//   const getDownloadResultBtn=async()=>{
//     let obj={
//       inputFormateFile: 0,
//       userID:  parseInt(sessionStorage.getItem("loginId")),
//       flag: 0
//     }
//     HET_DownloadFile(obj)
//     .then((res) => {
//       if (res?.status === 200) {
//         alert("File downloaded successfully!");

//         const fileName = "ProjectTasks.xlsx";
//         const downloadUrl = URL.createObjectURL(new Blob([res.data]));

//         const link = document.createElement("a");
//         link.href = downloadUrl;
//         link.setAttribute("download", fileName);

//         document.body.appendChild(link);
//         link.click();

//         document.body.removeChild(link);
//         URL.revokeObjectURL(downloadUrl);
//       } else if (res?.response?.status === 500) {
//         alert(res?.response?.data || "Internal Server Error");
//       }
//     })
//     .catch((err) => {
//       console.log("Error downloading file:", err);
//       alert("An error occurred while downloading the file.");
//     });
// }
//   const DownloadResultFile = async (obj) => {
//     try {
//       const response = await getDownloadResultFile(obj);
//       return response;
//     } catch (error) {
//       console.error("Error downloading file:", error);
//       throw error;
//     }
//   };
  const getExcelFormatFileDetails = () => {
    let obj = {
      filetemplate: selectedInputFormatFile,
    };

    if (selectedInputFormatFile) {
      HET_DownloadFile(obj)
        .then((res) => {
          if (res?.status === 200) {
            alert("File downloaded successfully!");

            const fileName = "ProjectTasks.xlsx";
            const downloadUrl = URL.createObjectURL(new Blob([res.data]));

            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", fileName);

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          } else if (res?.response?.status === 500) {
            alert(res?.response?.data || "Internal Server Error");
          }
        })
        .catch((err) => {
          console.log("Error downloading file:", err);
          alert("An error occurred while downloading the file.");
        });
    } else {
      alert("Please select a valid input format file.");
    }
  };

  const HET_DownloadFile = async (obj) => {
    try {
      const response = await getExcelFormatFile(obj);
      return response;
    } catch (error) {
      console.error("Error downloading file:", error);
      throw error;
    }
  };
  

  useEffect(() => {
    const savedOption = localStorage.getItem('selectedOption');
    if (savedOption) {
      setSelectedValue(savedOption);
    }
  }, []);
  const handleInputFormatFile=(e)=>{
    console.log("e",e.target.value);
    setSelectedInputFormatFile(e.target.value)
  }
  return (
    <div>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"CreateNew"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Project Plan</a>
                <img src={bread} alt="" />
                <a>Create New Plan</a>
              </div>
            </div>
          </Grid>
          {/* <Grid item lg={12} className={modules["radio-buttons"]}>
            <RadioGroup row value={selectedValue} onChange={handleChange}>
              <CustomFormControlLabel
                value="option1"
                control={<Radio className={modules["radio-scale"]} />}
                label="Create an Empty Project"
                className={modules["radiolabel"]}
              />
              <CustomFormControlLabel
                value="option2"
                control={<Radio className={modules["radio-scale"]} />}
                label="Create New Project from Template"
                className={modules["radiolabel"]}
              />
              <CustomFormControlLabel
                value="option3"
                control={<Radio className={modules["radio-scale"]} />}
                label="Create New Project from File"
                className={modules["radiolabel"]}
              />
              <CustomFormControlLabel
                value="option4"
                control={<Radio className={modules["radio-scale"]} />}
                label="Create New Project ERP LN"
                className={modules["radiolabel"]}
              />
            </RadioGroup>
          </Grid> */}
          <Stack
            direction="row"
            spacing={3}
            className={modules["radio-buttons"]}
          >
            <Stack direction="row">
              <input
                type="radio"
                value="option1"
                id="createEmptProj"
                checked={selectedValue === "option1"}
                onChange={handleChange}
                className={modules["radioBtnMain"]}
              />
              <label htmlFor="createEmptProj" className={modules["radiolabel"]}>
                Create an Empty Project
              </label>
            </Stack>
            <Stack direction="row">
              <input
                type="radio"
                id="createProjTemp"
                value="option2"
                checked={selectedValue === "option2"}
                onChange={handleChange}
                className={modules["radioBtnMain"]}
              />
              <label htmlFor="createProjTemp" className={modules["radiolabel"]}>
                Create New Project from Template
              </label>
            </Stack>
            <Stack direction="row">
              <input
                type="radio"
                id="Createfromfile"
                value="option3"
                checked={selectedValue === "option3"}
                onChange={handleChange}
                className={modules["radioBtnMain"]}
              />
              <label htmlFor="Createfromfile" className={modules["radiolabel"]}>
                Create New Project from File
              </label>
            </Stack>
            <Stack direction="row">
              <input
                type="radio"
                id="CreateNewErp"
                value="option4"
                checked={selectedValue === "option4"}
                onChange={handleChange}
                className={modules["radioBtnMain"]}
              />
              <label htmlFor="CreateNewErp" className={modules["radiolabel"]}>
                Create New Project ERP LN
              </label>
            </Stack>
          </Stack>

          {selectedValue === "option1" && (
            <>
              <Stack
                spacing={2}
                direction="row"
                className={modules["parent-stack"]}
              >
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Project Name:{" "}
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      style={{
                        width: "60%",
                      }}
                      onChange={handleProjectNameChange}
                      value={projectName}
                      className={modules["input-field"]}
                    />
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Customer Due Date:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      style={{
                        width: "20%",
                      }}
                      value={displaydate1}
                      onChange={(e) => setDisplaydate1(e.target.value)}
                      className={modules["input-field"]}
                      onClick={(event) => setAnchorEl1(event.currentTarget)}
                    />
                    <Popover
                      id={id1}
                      open={open1}
                      anchorEl={anchorEl1}
                      onClose={() => setAnchorEl1(null)}
                      placement="bottom-start"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      style={{ borderRadius: "0px" }}
                    >
                      <Calendar
                        onChange={handleCalendar1n}
                        value={custumerduedate}
                        locale="en-US"
                        formatShortWeekday={formatShortWeekday}
                      />
                      <div className={modules["calendar-footer"]}>
                        Today: {formattedToday}
                      </div>
                    </Popover>
                  </Stack>
                </Stack>
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Project Start Date:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      style={{
                        width: "20%",
                      }}
                      value={displaydate2}
                      onChange={(e) => setDisplaydate2(e.target.value)}
                      className={modules["input-field"]}
                      onClick={(event) => setAnchorEl2(event.currentTarget)}
                    />
                    <Popover
                      id={id2}
                      open={open2}
                      anchorEl={anchorEl2}
                      onClose={() => setAnchorEl2(null)}
                      placement="bottom-start"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Calendar
                        onChange={handleCalendar2n}
                        value={projstartdate}
                        locale="en-US"
                        formatShortWeekday={formatShortWeekday}
                      />

                      <div className={modules["calendar-footer"]}>
                        Today: {formattedToday}
                      </div>
                    </Popover>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry-exception"]}
                  >
                    <div className={modules["input-labels"]}>
                      Requested Due Date:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      style={{
                        width: "20%",
                      }}
                      value={displaydate3}
                      onChange={(e) => setDisplaydate3(e.target.value)}
                      className={modules["input-field"]}
                      onClick={(event) => setAnchorEl3(event.currentTarget)}
                    />
                    <Popover
                      id={id3}
                      open={open3}
                      anchorEl={anchorEl3}
                      onClose={() => setAnchorEl3(null)}
                      placement="bottom-start"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Calendar
                        onChange={handleCalendar3n}
                        value={reqduedate}
                        locale="en-US"
                        formatShortWeekday={formatShortWeekday}
                      />

                      <div className={modules["calendar-footer"]}>
                        Today: {formattedToday}
                      </div>
                    </Popover>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                sx={{ alignItems: "center", marginLeft: "7rem" }}
              >
                <div
                  className={modules["input-labels"]}
                  style={{ width: "15%" }}
                >
                  Project Additional Details:{" "}
                </div>
                <textarea
                  id="customTextarea"
                  className={modules["custom-textarea"]}
                  rows="3"
                  value={projectAddDetails}
                  onChange={(e) => setProjectAddDetails(e.target.value)}
                />
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                className={modules["parent-stack"]}
              >
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Instance: <span className={modules["asterisk"]}>*</span>
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedInstance}
                      onChange={(e) => handleInstance(e.target.value, 1)}
                    >
                      <option value="Select">SELECT</option>
                      {instance.length > 0 &&
                        instance?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Customer:<span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedCustomer}
                      onChange={(e) => setSelectedCustomer(e.target.value)}
                    >
                      {customer.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {customer.length > 0 &&
                        customer?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.customername}
                          </option>
                        ))}
                    </select>
                  </Stack>
                </Stack>
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Attach Calendar:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedAttachCalendar}
                      onChange={(e) =>
                        setSelectedAttachCalendar(e.target.value)
                      }
                    >
                      {attachCalendar.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {attachCalendar.length > 0 &&
                        attachCalendar?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.calendarname}
                          </option>
                        ))}
                    </select>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Project Manager:{" "}
                      <span className={modules["asterisk"]}>*</span>
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedProjectManager}
                      onChange={(e) => handleProjectManager(e.target.value)}
                    >
                      {projectManager.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {projectManager.length > 0 &&
                        projectManager?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.username}
                          </option>
                        ))}
                    </select>
                  </Stack>
                </Stack>
              </Stack>
            </>
          )}

          {selectedValue === "option1" &&
            selectedProjectManager !== "" &&
            selectedProjectManager !== "Select" && (
              <div className={modules["grid-container"]}>
                {dropdowns.length > 0 &&
                  dropdowns?.map((li, i) => {
                    return (
                      <Stack
                        spacing={2}
                        direction="row"
                        className={modules["each-entry"]}
                      >
                        <div className={modules["input-labels"]}>
                          {li[0].attributename}:
                          <span className={modules["asterisk"]}>*</span>{" "}
                        </div>
                        <select
                          className={modules["dropdownSelect1_cnp"]}
                          value={value[`attribute${i + 1}Id`] || "Select"}
                          onChange={(event) =>
                            handleDropdownSelection(event, i)
                          }
                        >
                          {li.length > 0 && (
                            <option value="Select">SELECT</option>
                          )}
                          {li.map((obj, i) => (
                            <option value={obj.id} key={obj}>
                              {obj.attributevalue}
                            </option>
                          ))}
                        </select>
                      </Stack>
                    );
                  })}
              </div>
            )}

          {selectedValue === "option2" && (
            <>
              <Stack
                spacing={2}
                direction="row"
                className={modules["parent-stack"]}
              >
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Project Name:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      value={projectName}
                      onChange={handleProjectNameChange}
                      style={{
                        width: "60%",
                      }}
                      className={modules["input-field"]}
                    />
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Customer Due Date:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      style={{
                        width: "20%",
                      }}
                      value={displaydate1}
                      onChange={(e) => setDisplaydate1(e.target.value)}
                      className={modules["input-field"]}
                      onClick={(event) => setAnchorEl1(event.currentTarget)}
                    />
                    <Popover
                      id={id1}
                      open={open1}
                      anchorEl={anchorEl1}
                      onClose={() => setAnchorEl1(null)}
                      placement="bottom-start"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Calendar
                        onChange={handleCalendar1n}
                        value={custumerduedate}
                        locale="en-US"
                        formatShortWeekday={formatShortWeekday}
                      />
                      <div className={modules["calendar-footer"]}>
                        Today: {formattedToday}
                      </div>
                    </Popover>
                  </Stack>
                </Stack>
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Project Start Date:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      style={{
                        width: "20%",
                      }}
                      value={displaydate2}
                      onChange={(e) => setDisplaydate2(e.target.value)}
                      className={modules["input-field"]}
                      onClick={(event) => setAnchorEl2(event.currentTarget)}
                    />
                    <Popover
                      id={id2}
                      open={open2}
                      anchorEl={anchorEl2}
                      onClose={() => setAnchorEl2(null)}
                      placement="bottom-start"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Calendar
                        onChange={handleCalendar2n}
                        value={projstartdate}
                        locale="en-US"
                        formatShortWeekday={formatShortWeekday}
                      />

                      <div className={modules["calendar-footer"]}>
                        Today: {formattedToday}
                      </div>
                    </Popover>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry-exception"]}
                  >
                    <div className={modules["input-labels"]}>
                      Requested Due Date:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      style={{
                        width: "20%",
                      }}
                      value={displaydate3}
                      onChange={(e) => setDisplaydate3(e.target.value)}
                      className={modules["input-field"]}
                      onClick={(event) => setAnchorEl3(event.currentTarget)}
                    />
                    <Popover
                      id={id3}
                      open={open3}
                      anchorEl={anchorEl3}
                      onClose={() => setAnchorEl3(null)}
                      placement="bottom-start"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Calendar
                        onChange={handleCalendar3n}
                        value={reqduedate}
                        locale="en-US"
                        formatShortWeekday={formatShortWeekday}
                      />

                      <div className={modules["calendar-footer"]}>
                        Today: {formattedToday}
                      </div>
                    </Popover>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                sx={{ alignItems: "center", marginLeft: "7rem" }}
              >
                <div
                  className={modules["input-labels"]}
                  style={{ width: "15%" }}
                >
                  Project Additional Details:{" "}
                </div>
                <textarea
                  id="customTextarea"
                  className={modules["custom-textarea"]}
                  rows="3"
                  value={projectAddDetails}
                  onChange={(e) => setProjectAddDetails(e.target.value)}
                />
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                className={modules["parent-stack"]}
              >
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Instance:<span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedInstance}
                      onChange={(e) => handleInstance(e.target.value, 2)}
                    >
                      <option value="Select">SELECT</option>
                      {instance.length > 0 &&
                        instance?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Customer: <span className={modules["asterisk"]}>*</span>
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedCustomer}
                      onChange={(e) => setSelectedCustomer(e.target.value)}
                    >
                      {customer.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {customer.length > 0 &&
                        customer?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.customername}
                          </option>
                        ))}
                    </select>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Project Template:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedProjectTemplate}
                      onChange={(e) =>
                        setSelectedProjectTemplate(e.target.value)
                      }
                    >
                      {projectTemplate.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {projectTemplate.length > 0 &&
                        projectTemplate?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.projectname}
                          </option>
                        ))}
                    </select>
                  </Stack>
                </Stack>
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Attach Calendar:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedAttachCalendar}
                      onChange={(e) =>
                        setSelectedAttachCalendar(e.target.value)
                      }
                    >
                      {attachCalendar.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {attachCalendar.length > 0 &&
                        attachCalendar?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.calendarname}
                          </option>
                        ))}
                    </select>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Project Manager:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedProjectManager}
                      onChange={(e) => handleProjectManager(e.target.value)}
                    >
                      {projectManager.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {projectManager.length > 0 &&
                        projectManager?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.username}
                          </option>
                        ))}
                    </select>
                  </Stack>
                </Stack>
              </Stack>
            </>
          )}

          {selectedValue === "option2" &&
            selectedProjectManager !== "" &&
            selectedProjectManager !== "Select" && (
              <div className={modules["grid-container"]}>
                {dropdowns.length > 0 &&
                  dropdowns?.map((li, i) => {
                    return (
                      <Stack
                        spacing={2}
                        direction="row"
                        className={modules["each-entry"]}
                      >
                        <div className={modules["input-labels"]}>
                          {li[0].attributename}:
                          <span className={modules["asterisk"]}>*</span>{" "}
                        </div>
                        <select
                          className={modules["dropdownSelect1_cnp"]}
                          value={value[`attribute${i + 1}Id`] || "Select"}
                          onChange={(event) =>
                            handleDropdownSelection(event, i)
                          }
                        >
                          {li.length > 0 && (
                            <option value="Select">SELECT</option>
                          )}
                          {li.length > 0 &&
                            li.map((obj, i) => (
                              <option value={obj.id} key={obj}>
                                {obj.attributevalue}
                              </option>
                            ))}
                        </select>
                      </Stack>
                    );
                  })}
              </div>
            )}

          {selectedValue === "option3" && (
            <>
              <Stack
                spacing={2}
                direction="row"
                className={modules["parent-stack"]}
              >
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Project Name:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      value={projectName}
                      onChange={handleProjectNameChange}
                      style={{
                        width: "60%",
                      }}
                      className={modules["input-field"]}
                    />
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Customer Due Date:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      style={{
                        width: "30%",
                      }}
                      value={displaydate1}
                      className={modules["input-field"]}
                      onClick={(event) => setAnchorEl1(event.currentTarget)}
                    />
                    <Popover
                      id={id1}
                      open={open1}
                      anchorEl={anchorEl1}
                      onClose={() => setAnchorEl1(null)}
                      placement="bottom-start"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Calendar
                        onChange={handleCalendar1n}
                        value={custumerduedate}
                        locale="en-US"
                        formatShortWeekday={formatShortWeekday}
                        style={{border:"none"}}
                      />
                      <div className={modules["calendar-footer"]}>
                        Today: {formattedToday}
                      </div>
                    </Popover>
                  </Stack>
                </Stack>
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Project Start Date:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      style={{
                        width: "30%",
                      }}
                      value={displaydate2}
                      className={modules["input-field"]}
                      onClick={(event) => setAnchorEl2(event.currentTarget)}
                    />
                    <Popover
                      id={id2}
                      open={open2}
                      anchorEl={anchorEl2}
                      onClose={() => setAnchorEl2(null)}
                      placement="bottom-start"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Calendar
                        onChange={handleCalendar2n}
                        value={projstartdate}
                        locale="en-US"
                        formatShortWeekday={formatShortWeekday}
                      />

                      <div className={modules["calendar-footer"]}>
                        Today: {formattedToday}
                      </div>
                    </Popover>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry-exception"]}
                  >
                    <div className={modules["input-labels"]}>
                      Requested Due Date:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      style={{
                        width: "30%",
                      }}
                      value={displaydate3}
                      o
                      className={modules["input-field"]}
                      onClick={(event) => setAnchorEl3(event.currentTarget)}
                    />
                    <Popover
                      id={id3}
                      open={open3}
                      anchorEl={anchorEl3}
                      onClose={() => setAnchorEl3(null)}
                      placement="bottom-start"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Calendar
                        onChange={handleCalendar3n}
                        value={reqduedate}
                        locale="en-US"
                        formatShortWeekday={formatShortWeekday}
                      />

                      <div className={modules["calendar-footer"]}>
                        Today: {formattedToday}
                      </div>
                    </Popover>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                sx={{ alignItems: "center", marginLeft: "7rem" }}
              >
                <div
                  className={modules["input-labels"]}
                  style={{ width: "15%" }}
                >
                  Project Additional Details:{" "}
                </div>
                <textarea
                  id="customTextarea"
                  className={modules["custom-textarea"]}
                  rows="3"
                  value={projectAddDetails}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^[a-zA-Z0-9&()[\].\/\-_=+? ]*$/;
                    if (regex.test(value) && value.length <= 50) {
                      setProjectAddDetails(value);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    const invalidCharsRegex = /[[\]\/=+?.]/;
                    if (invalidCharsRegex.test(value)) {
                      alert("No Special Characters Allowed");
                      setProjectAddDetails("");
                    }
                  }}
                />
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                className={modules["parent-stack"]}
              >
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Instance:<span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedInstance}
                      onChange={(e) => handleInstance(e.target.value, 2)}
                    >
                      <option value="Select">SELECT</option>
                      {instance.length > 0 &&
                        instance?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Customer: <span className={modules["asterisk"]}>*</span>
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedCustomer}
                      onChange={(e) => setSelectedCustomer(e.target.value)}
                    >
                      {customer.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {customer.length > 0 &&
                        customer?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.customername}
                          </option>
                        ))}
                    </select>
                  </Stack>
                </Stack>
                <Stack direction="column" className={modules["column-stack"]}>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Attach Calendar:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedAttachCalendar}
                      onChange={(e) =>
                        setSelectedAttachCalendar(e.target.value)
                      }
                    >
                      {attachCalendar.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {attachCalendar.length > 0 &&
                        attachCalendar?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.calendarname}
                          </option>
                        ))}
                    </select>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry"]}
                  >
                    <div className={modules["input-labels"]}>
                      Project Manager:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1_cnp"]}
                      value={selectedProjectManager}
                      onChange={(e) => handleProjectManager(e.target.value)}
                    >
                      {projectManager.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {projectManager.length > 0 &&
                        projectManager?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.username}
                          </option>
                        ))}
                    </select>
                  </Stack>
                </Stack>
              </Stack>
            </>
          )}

          {selectedValue === "option3" &&
            selectedProjectManager !== "" &&
            selectedProjectManager !== "Select" && (
              <div className={modules["grid-container"]}>
                {dropdowns.length > 0 &&
                  dropdowns?.map((li, i) => {
                    return (
                      <Stack
                        spacing={2}
                        direction="row"
                        className={modules["each-entry"]}
                      >
                        <div className={modules["input-labels"]}>
                          {li[0].attributename}:
                          <span className={modules["asterisk"]}>*</span>{" "}
                        </div>
                        <select
                          className={modules["dropdownSelect1_cnp"]}
                          value={value[`attribute${i + 1}Id`] || "Select"}
                          onChange={(event) =>
                            handleDropdownSelection(event, i)
                          }
                        >
                          {li.length > 0 && (
                            <option value="Select">SELECT</option>
                          )}
                          {li.length > 0 &&
                            li.map((obj, i) => (
                              <option value={obj.id} key={obj}>
                                {obj.attributevalue}
                              </option>
                            ))}
                        </select>
                      </Stack>
                    );
                  })}
              </div>
            )}

          {selectedValue === "option3" && (
            <>
              <Stack
                spacing={2}
                direction="column"
                className={modules["parent-stack"]}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className={modules["input-labelsC"]}>
                    Input Format File:{" "}
                    <span className={modules["asterisk"]}>*</span>
                  </div>
                  <select
                    className={modules["dropdownSelect2_cnp"]}
                    value={selectedInputFormatFile}
                    onChange={handleInputFormatFile}
                  >
                    <option value="Select">SELECT</option>
                    {inputFormatFile?.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.filename}
                      </option>
                    ))}
                  </select>
                </Stack>

                {selectedInputFormatFile && selectedInstance !== "Select" && (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        width: "100%",
                        marginLeft: "6px !important",
                        marginTop: "32px !important",
                      }}
                      spacing={8}
                    >
                      <div className={modules["input-labels"]}>
                        Select File to Upload:{" "}
                        <span className={modules["asterisk"]}>*</span>
                      </div>

                      <Stack direction="column" spacing={4}>
                        <button
                          className={modules["custom-export-button"]}
                          onClick={getExcelFormatFileDetails}
                        >
                          Click here to download Excel format
                        </button>

                        <input
                          type="file"
                          className={modules["fileInput_cnp"]}
                          accept=".xlsx, .xls"
                          onChange={(e) => handleFileSelect(e.target.files[0])}
                        />

                        {selectedInputFormatFile === "1" && (
                          <button
                            className={modules["custom-export-button"]}
                            onClick={getExcelFormatFileDetails}
                            style={{width:"60%"}}
                          >
                            Import from Excel
                          </button>
                        )}
                      </Stack>
                    </Stack>
                  </>
                )}
              </Stack>
            </>
          )}

          {selectedValue === "option4" && (
            <Stack
              spacing={2}
              direction="row"
              className={modules["parent-stack"]}
            >
              <Stack direction="column" className={modules["column-stack"]}>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    ERP Company No:{" "}
                  </div>
                  <input
                    style={{
                      width: "20%",
                    }}
                    className={modules["input-field"]}
                  />
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>ERP Customer: </div>
                  <div className={modules["slash-entry"]}>/</div>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Project Name: <span className={modules["asterisk"]}>*</span>
                  </div>
                  <input
                    value={projectName}
                    onChange={handleProjectNameChange}
                    style={{
                      width: "60%",
                    }}
                    className={modules["input-field"]}
                  />
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Customer Due Date:
                    <span className={modules["asterisk"]}>*</span>{" "}
                  </div>
                  <input
                    style={{
                      width: "20%",
                    }}
                    value={displaydate1}
                    onChange={(e) => setDisplaydate1(e.target.value)}
                    className={modules["input-field"]}
                    onClick={(event) => setAnchorEl1(event.currentTarget)}
                  />
                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={() => setAnchorEl1(null)}
                    placement="bottom-start"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Calendar
                      onChange={handleCalendar1n}
                      value={custumerduedate}
                      locale="en-US"
                      formatShortWeekday={formatShortWeekday}
                    />
                    <div className={modules["calendar-footer"]}>
                      Today: {formattedToday}
                    </div>
                  </Popover>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Project Additional Details:{" "}
                  </div>
                  <textarea
                    id="customTextarea"
                    className={modules["custom-textarea"]}
                    rows="3"
                    value={projectAddDetails}
                    onChange={(e) => setProjectAddDetails(e.target.value)}
                  />
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Instance:<span className={modules["asterisk"]}>*</span>{" "}
                  </div>
                  <select
                    className={modules["dropdownSelect1_cnp"]}
                    value={selectedInstance}
                    onChange={(e) => handleInstance(e.target.value, 4)}
                  >
                    <option value="Select">SELECT</option>
                    {instance?.map((i) => (
                      <option value={i.id} key={i}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </Stack>
              </Stack>
              <Stack direction="column" className={modules["column-stack"]}>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    ERP Project No:{" "}
                  </div>
                  <input
                    style={{
                      width: "30%",
                    }}
                    className={modules["input-field"]}
                  />
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    ERP Project Manager:{" "}
                  </div>
                  <div className={modules["slash-entry"]}>/</div>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Project Start Date:
                    <span className={modules["asterisk"]}>*</span>{" "}
                  </div>
                  <input
                    style={{
                      width: "20%",
                    }}
                    value={displaydate2}
                    onChange={(e) => setDisplaydate2(e.target.value)}
                    className={modules["input-field"]}
                    onClick={(event) => setAnchorEl2(event.currentTarget)}
                  />
                  <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={() => setAnchorEl2(null)}
                    placement="bottom-start"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Calendar
                      onChange={handleCalendar2n}
                      value={projstartdate}
                      locale="en-US"
                      formatShortWeekday={formatShortWeekday}
                    />

                    <div className={modules["calendar-footer"]}>
                      Today: {formattedToday}
                    </div>
                  </Popover>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-exception"]}
                >
                  <div className={modules["input-labels"]}>
                    Requested Due Date:
                    <span className={modules["asterisk"]}>*</span>{" "}
                  </div>
                  <input
                    style={{
                      width: "20%",
                    }}
                    value={displaydate3}
                    onChange={(e) => setDisplaydate3(e.target.value)}
                    className={modules["input-field"]}
                    onClick={(event) => setAnchorEl3(event.currentTarget)}
                  />
                  <Popover
                    id={id3}
                    open={open3}
                    anchorEl={anchorEl3}
                    onClose={() => setAnchorEl3(null)}
                    placement="bottom-start"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Calendar
                      onChange={handleCalendar3n}
                      value={reqduedate}
                      locale="en-US"
                      formatShortWeekday={formatShortWeekday}
                    />

                    <div className={modules["calendar-footer"]}>
                      Today: {formattedToday}
                    </div>
                  </Popover>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                ></Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Attach Calendar:
                    <span className={modules["asterisk"]}>*</span>{" "}
                  </div>
                  <select
                    className={modules["dropdownSelect1_cnp"]}
                    value={selectedAttachCalendar}
                    onChange={(e) => setSelectedAttachCalendar(e.target.value)}
                  >
                    {selectedInstance === "Select" ? (
                      <option></option>
                    ) : (
                      <option value="Select">SELECT</option>
                    )}
                    {attachCalendar?.map((i) => (
                      <option value={i.id} key={i}>
                        {i.calendarname}
                      </option>
                    ))}
                  </select>
                </Stack>
              </Stack>
            </Stack>
          )}

          {showbutton && (
            <div
              className={modules["custom-submit-button-cont"]}
              style={{ width: "100%" }}
            >
              <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                <button
                  className={modules["custom-submit-button"]}
                  onClick={() => handleSubmit(selectedValue)}
                >
                  Submit
                </button>
                {selectedValue === "option3" &&
                  selectedInputFormatFile !== "Select" &&
                  selectedInputFormatFile !== "" && (
                    <>
                      {downloadResultBtn ?<button className={modules["custom-submit-button"]} onClick={getDownloadResultBtn}>
                        Download Result File
                      </button>:""}
                      {showResetBtn? <button className={modules["custom-submit-button"]} onClick={handleSubmitReset}>
                        Reset
                      </button>:""}
                    </>
                  )}
              </Stack>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default CreateNewPlan;
