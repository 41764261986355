import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../../Landing/Navbar";
import loading from "../../../Assets/Loading_image.gif";
// import modules from "./SettingPercentage.module.css";
import modules from "../ccpm.module.css";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SampleSidebar from "../../../Landing/SampleSidebar";
import {
  getInstanceData,
  getInstanceBasedAttListDate,
  getInstanceParameterData,
  getAttributeValueOnAttributeNumberData,
  insertNewConfigSetting,
  insertNewApplyParameter,
  getHierarchyId,
  deleteInstanceParameter,
} from "../../../Services/Admin";

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "2px",
          height: "30px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const DummyData = {
  instancename: "Tooling",
  taskred: 33.33,
  projectbuff: 50.02,
  feedingbuff: 50.03,
};

const SettingPercentage = () => {
  const [dropdownData, setDropdownData] = useState([]);
  const [displayvalue, setDisplayValue] = useState("");
  const [probuff, setProbuff] = useState("");
  const [taskred, setTaskred] = useState("");
  const [feedbuff, setFeedbuff] = useState("");
  const [attTaskReduction, setAttTaskReduction] = useState("");
  const [attrProjectBuff, setAttrProjectBuff] = useState("");
  const [attrFeedingBuff, setAttrFeedingBuff] = useState("");
  const [initialRun, setInitialRun] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [parameter, setParameter] = useState("Instance level");
  const [attributeLevelTag, setAttributeLevelTag] = useState(false);
  const [attributeNameData, setAttributeNameData] = useState([]);
  const [attributeName, setAttributeName] = useState("Select");
  const [instanceLevelTableData, setInstanceLevelTableData] = useState([]);
  const [attributeLevelTableTag, setAttributeLevelTableTag] = useState(false);
  const [attributeTableData, setAttributeTableData] = useState([]);
  const [newTag, setNewTag] = useState(false);
  const [initialInstance, setInitialInstance] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [mainDropdownData, setMainDropdownData] = useState([]);

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };

  function isEmpty(value) {
    console.log(
      value == null || (typeof value === "string" && value.trim().length === 0)
    );
    return (
      value == null || (typeof value === "string" && value.trim().length === 0)
    );
  }

  useEffect(() => {
    if (parameter === "Attribute level") {
      setAttributeName("Select");
    }
  }, [parameter]);

  useEffect(() => {
    if (initialInstance === true) {
      setDisplayValue(displayvalue);
    }
  }, [initialInstance]);

  useEffect(() => {
    checkLoginAndRedirect();
    setIsLoading(true);
    const fetchData = async () => {
      let body = { id: Number(sessionStorage.getItem("loginId")) };
      getInstanceData(body)
        .then((res) => {
          if (res?.data) {
            setIsLoading(false);
            let data = res.data.success.data;
            let temp = data[0].name;
            setDisplayValue(data[0].name);
            setSelectedId(data[0].id);
            setInitialInstance(true);
            const names = data.map((item) => item.name);
            setDropdownData(names);
            setMainDropdownData(data);
            let body = {
              instanceId: data[0].id,
            };

            getInstanceParameterData(body)
              .then((res) => {
                if (res.status === 200) {
                  if (res.data.success.data !== null) {
                    setInstanceLevelTableData(res.data.success.data);
                    setTaskred(
                      res.data.success.data[0].taskreductionper.toLocaleString(
                        "en",
                        { useGrouping: false, minimumFractionDigits: 2 }
                      )
                    );
                    setProbuff(
                      res.data.success.data[0].projectbufferreductionper.toLocaleString(
                        "en",
                        { useGrouping: false, minimumFractionDigits: 2 }
                      )
                    );
                    setFeedbuff(
                      res.data.success.data[0].feedingbufferreductionper.toLocaleString(
                        "en",
                        { useGrouping: false, minimumFractionDigits: 2 }
                      )
                    );
                    if (res.data.success.data[0].isattributelevel === 1) {
                      setParameter("Attribute level");
                      let body = { instanceId: data[0].id };
                      getInstanceBasedAttListDate(body).then((res) => {
                        if (res?.data) {
                          setAttributeNameData(res.data.success.data);
                        }
                      });
                      setAttributeLevelTag(true);
                      setAttributeLevelTableTag(false);
                    } else if (res.data.success.data[0].isinstancelevel === 1) {
                      setParameter("Instance level");
                    } else if (res.data.success.data[0].isnotrequired === 1) {
                      setParameter("Not Required");
                    }
                  } else {
                    setParameter("Not Required");
                    setInstanceLevelTableData([{ instance: temp }]);
                    setNewTag(true);
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log("Error", res);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    };

    fetchData();
  }, []);

  function changeTheParameter(e) {
    setTaskred("");
    setProbuff("");
    setFeedbuff("");
    setParameter(e.target.value);
    if (e.target.value == "Attribute level") {
      let body = { instanceId: selectedId };
      getInstanceBasedAttListDate(body).then((res) => {
        if (res?.data) {
          setAttributeNameData(res.data.success.data);
        }
      });
      setAttributeLevelTag(true);
      setAttributeLevelTableTag(false);
    } else {
      setAttributeLevelTag(false);
      setAttributeNameData([]);
      setAttributeName("Select");
    }
  }

  function getAttributebasedTabledata(e) {
    setIsLoading(true);
    setAttributeName(e.target.value);
    if (e.target.value !== "Select") {
      let hierarchyBody = {
        instanceId: selectedId,
        attributeNumber: e.target.value,
      };
      setAttributeLevelTableTag(false);
      getHierarchyId(hierarchyBody).then((res) => {
        if (res.status === 200) {
          let instanceHierarchyId = res.data.success.data[0];
          let hierarchyData = {
            instanceHierarchyId: instanceHierarchyId.instancehierarchyid,
          };
          getAttributeValueOnAttributeNumberData(hierarchyData).then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              setAttributeLevelTableTag(true);
              setAttributeTableData(res.data.success.data);
            } else {
              alert("No Data To Display");
            }
          });
        }
      });
    } else {
      setAttributeTableData([]);
      // attributeLevelTableTag
      setAttributeLevelTableTag(false);
      alert("No Data to display!!");
    }
  }

  function handleChangeInstance(event) {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.name === selectedName
    );
    setDisplayValue(selectedName);
    setSelectedId(selectedItem.id);
    let body = {
      instanceId: selectedItem.id,
    };
    setAttributeLevelTag(false);
    setNewTag(false);

    setInstanceLevelTableData([]);
    // setAttributeName("select");
    setTaskred("");
    setProbuff("");
    setFeedbuff("");
    setAttributeName("Select");
    setAttributeNameData([]);
    getInstanceParameterData(body).then(async (res) => {
      if (res.status === 200) {
        if (res.data.success.data !== null) {
          if (res.data.success.data[0].isattributelevel === 1) {
            setParameter("Attribute level");
            let body = { instanceId: selectedItem.id };
            getInstanceBasedAttListDate(body).then((res) => {
              if (res?.data) {
                setAttributeNameData(res.data.success.data);
              }
            });
            setAttributeLevelTag(true);
            setAttributeLevelTableTag(false);
          } else if (res.data.success.data[0].isinstancelevel === 1) {
            setParameter("Instance level");
          } else if (res.data.success.data[0].isnotrequired === 1) {
            setParameter("Not Required");
          }
          setInstanceLevelTableData(res.data.success.data);
          setTaskred(
            res.data.success.data[0].taskreductionper.toLocaleString("en", {
              useGrouping: false,
              minimumFractionDigits: 2,
            })
          );
          setProbuff(
            res.data.success.data[0].projectbufferreductionper.toLocaleString(
              "en",
              { useGrouping: false, minimumFractionDigits: 2 }
            )
          );
          setFeedbuff(
            res.data.success.data[0].feedingbufferreductionper.toLocaleString(
              "en",
              { useGrouping: false, minimumFractionDigits: 2 }
            )
          );
        } else {
          let body = { id: Number(sessionStorage.getItem("loginId")) };
          let arr = await getInstanceData(body).then((res) => {
            if (res?.data) {
              return res.data.success.data;
            } else {
              console.log("Error", res);
            }
          });
          let name = arr.filter((Obj) => {
            return Obj.id === selectedItem.id;
          });
          if (res.data.success.data === null) {
            setParameter("Instance level");
            setNewTag(true);
            setInstanceLevelTableData([{ instance: name[0].name }]);
          }
        }
      }
    });
  }

  async function saveTheInstanceLevelData(item) {
    let instanceBody = { id: Number(sessionStorage.getItem("loginId")) };
    let arr = await getInstanceData(instanceBody).then((res) => {
      if (res?.data) {
        return res.data.success.data;
      } else {
        console.log("Error", res);
      }
    });
    let resultTag = false;
    if (parameter === "Attribute level") {
      resultTag =
        !isEmpty(item.taskreductionper) &&
        !isEmpty(item.projectbufferreductionper) &&
        !isEmpty(item.feedingbufferreductionper);
    } else {
      console.log(
        "isEmpty(taskred) && isEmpty(probuff) && isEmpty(feedbuff)",
        isEmpty(taskred) && isEmpty(probuff) && isEmpty(feedbuff)
      );
      resultTag = !isEmpty(taskred) && !isEmpty(probuff) && !isEmpty(feedbuff);
    }
    if (resultTag === true) {
      let name = arr.filter((Obj) => {
        return Obj.name === item.instance;
      });
      let body = {};
      if (parameter === "Attribute level") {
        var newobj = Object.assign(body, {
          instanceId: item.instanceid,
          attributeNumber: attributeName,
          isInstanceLevel: 0,
          isAttributeLevel: 1,
          isNotRequired: 0,
        });
      } else {
        var newobj = Object.assign(body, {
          instanceId: item.instanceid ? item.instanceid : name[0].id,
          attributeNumber: 0,
          isInstanceLevel: parameter === "Instance level" ? 1 : 0,
          isAttributeLevel: 0,
          isNotRequired: parameter === "Not Required" ? 1 : 0,
        });
      }
      console.log("Attribute level", body);
      insertNewConfigSetting(body)
        .then(async (res) => {
          if (res?.status === 200) {
            let instanceNewData = {};
            if (parameter === "Attribute level") {
              var newobj = Object.assign(instanceNewData, {
                instanceId: item.instanceid ? item.instanceid : name[0].id,
                attributeId: item.atrributeid,
                taskReductionPer: Number(item.taskreductionper),
                projectBufferReductionPer: Number(
                  item.projectbufferreductionper
                ),
                feedingBufferReductionPer: Number(
                  item.feedingbufferreductionper
                ),
              });
            } else {
              var newobj = Object.assign(instanceNewData, {
                instanceId: item.instanceid ? item.instanceid : name[0].id,
                attributeId: 0,
                taskReductionPer: Number(taskred),
                projectBufferReductionPer: Number(probuff),
                feedingBufferReductionPer: Number(feedbuff),
              });
            }
            insertNewApplyParameter(instanceNewData)
              .then((res) => {
                if (res?.status === 200) {
                  let body = {
                    instanceId: item.instanceid ? item.instanceid : name[0].id,
                  };
                  alert("Record Saved Successfully!!");
                  if (parameter === "Attribute level") {
                    setAttributeLevelTableTag(false);
                    let hierarchyBody = {
                      instanceId: selectedId,
                      attributeNumber: attributeName,
                    };
                    getHierarchyId(hierarchyBody).then((res) => {
                      if (res.status === 200) {
                        setAttributeLevelTableTag(true);
                        let instanceHierarchyId = res.data.success.data[0];
                        let hierarchyData = {
                          instanceHierarchyId:
                            instanceHierarchyId.instancehierarchyid,
                        };
                        let body = {
                          instanceId: selectedId,
                        };
                        getInstanceParameterData(body)
                          .then((res) => {
                            if (res.status === 200) {
                              if (res.data.success.data !== null) {
                                setNewTag(false);
                              }
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });

                        getAttributeValueOnAttributeNumberData(
                          hierarchyData
                        ).then((res) => {
                          if (res.status === 200) {
                            setAttributeTableData(res.data.success.data);
                          }
                        });
                      } else {
                        setAttributeLevelTableTag(false);
                      }
                    });
                  } else {
                    getInstanceParameterData(body)
                      .then((res) => {
                        if (res.status === 200) {
                          if (res.data.success.data !== null) {
                            setInstanceLevelTableData(res.data.success.data);
                            setTaskred(
                              res.data.success.data[0].taskreductionper.toLocaleString(
                                "en",
                                { useGrouping: false, minimumFractionDigits: 2 }
                              )
                            );
                            setProbuff(
                              res.data.success.data[0].projectbufferreductionper.toLocaleString(
                                "en",
                                { useGrouping: false, minimumFractionDigits: 2 }
                              )
                            );
                            setFeedbuff(
                              res.data.success.data[0].feedingbufferreductionper.toLocaleString(
                                "en",
                                { useGrouping: false, minimumFractionDigits: 2 }
                              )
                            );
                            setNewTag(false);
                          } else {
                            setInstanceLevelTableData([
                              { instance: item.instance },
                            ]);
                          }
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log("res", res);
            console.log("insertNewConfigSetting", res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // else {
    //     alert("Data not to be empty");
    //     setAttributeLevelTableTag(false);
    //     setAttributeTableData([]);
    //     let hierarchyBody = {
    //         "instanceId": selectedId,
    //         "attributeNumber": attributeName
    //     }
    //     console.log("hierarchyBody", selectedId, hierarchyBody);
    //     getHierarchyId(hierarchyBody).then((res) => {
    //         if (res.status === 200) {
    //             setAttributeLevelTableTag(true);
    //             let instanceHierarchyId = res.data.success.data[0];
    //             let hierarchyData = {
    //                 "instanceHierarchyId": instanceHierarchyId.instancehierarchyid
    //             }
    //             getAttributeValueOnAttributeNumberData(hierarchyData).then((res) => {
    //                 if (res.status === 200) {
    //                     console.log("Attribute Value", res.data.success.data);
    //                     setAttributeTableData(res.data.success.data);
    //                 }
    //             })
    //         } else {

    //         }
    //     })
    // }
  }

  function deleteInstance(tableName) {
    if (tableName === "InstanceTable") {
      let deleteBody = { instanceId: selectedId };
      var answer = window.confirm("Are you sure want to delete ?");
      if (answer) {
        deleteInstanceParameter(deleteBody).then((res) => {
          let body = {
            instanceId: selectedId,
          };
          alert("Record Deleted Successfully!!");
          getInstanceParameterData(body)
            .then((res) => {
              if (res.status === 200) {
                if (res.data.success.data !== null) {
                  setDisplayValue(displayvalue);
                  setInstanceLevelTableData(res.data.success.data);
                  setTaskred(res.data.success.data[0].taskreductionper);
                  setProbuff(
                    res.data.success.data[0].projectbufferreductionper
                  );
                  setFeedbuff(
                    res.data.success.data[0].feedingbufferreductionper
                  );
                } else {
                  setInstanceLevelTableData([
                    { instance: instanceLevelTableData[0].instance },
                  ]);
                  setTaskred("");
                  setProbuff("");
                  setFeedbuff("");
                  setNewTag(true);
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }
    } else {
      let deleteBody = { instanceId: selectedId };
      var answer = window.confirm("Are you sure want to delete ?");
      if (answer) {
        deleteInstanceParameter(deleteBody).then((res) => {
          let body = {
            instanceId: selectedId,
          };
          alert("Record Deleted Successfully!!");

          let hierarchyBody = {
            instanceId: selectedId,
            attributeNumber: attributeName,
          };
          console.log("hierarchyBody", selectedId, hierarchyBody);
          getHierarchyId(hierarchyBody).then((res) => {
            if (res.status === 200) {
              setAttributeLevelTableTag(false);
              let instanceHierarchyId = res.data.success.data[0];
              let hierarchyData = {
                instanceHierarchyId: instanceHierarchyId.instancehierarchyid,
              };
              setAttributeTableData([]);
              getAttributeValueOnAttributeNumberData(hierarchyData).then(
                (res) => {
                  if (res.status === 200) {
                    setAttributeTableData(res.data.success.data);
                    setNewTag(true);
                    setAttributeLevelTableTag(true);
                  }
                }
              );
            } else {
            }
          });
        });
      }
    }
  }

  function handleRowChangeTaskReduction(item, e) {
    const updatedItems = attributeTableData.map((data) =>
      data.atrributeid === item.atrributeid
        ? { ...data, taskreductionper: e.target.value }
        : data
    );
    setAttributeTableData(updatedItems);
  }

  function handleRowChangeAttrProjectBuff(item, e) {
    const updatedItems = attributeTableData.map((data) =>
      data.atrributeid === item.atrributeid
        ? { ...data, projectbufferreductionper: e.target.value }
        : data
    );
    setAttributeTableData(updatedItems);
  }

  function handleRowChangeAttrFeedingBuff(item, e) {
    const updatedItems = attributeTableData.map((data) =>
      data.atrributeid === item.atrributeid
        ? { ...data, feedingbufferreductionper: e.target.value }
        : data
    );
    setAttributeTableData(updatedItems);
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.3} className={modules["main_cont"]}>
          {/* <SideBar /> */}
          <SampleSidebar />
        </Grid>

        <Grid item lg={12}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"CCPMSettingPercentageParameterMaster"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["settingmaster-div"]}>
              <div className={modules["pro-bread"]}>
                <a>Masters</a>
                <img src={bread} alt="" />
                <a>CCPM Setting Percentage Parameter Master</a>
              </div>
            </div>

            <Grid container sx={{ marginLeft: "18rem", marginTop: "0.5rem" }}>
              <Grid item lg={3}>
                <Typography className={modules["ccpm_label-styleSP"]}>
                  Instance Name:
                </Typography>
              </Grid>

              <Grid item lg={9}>
                <select
                  name="cars"
                  id="cars"
                  value={displayvalue}
                  onChange={(e) => {
                    handleChangeInstance(e);
                  }}
                  className={modules["dropdownSelect1"]}
                  style={{
                    marginLeft: "1rem",
                  }}
                >
                  {dropdownData?.length > 0 &&
                    dropdownData?.map((i) => (
                      <option key={i} value={i} style={{ color: "#373736" }}>
                        {i}
                      </option>
                    ))}
                </select>
                {/* <FormControl className={modules["form-control"]}>
                  <Select
                    IconComponent={ArrowDropDownIcon}
                    className={modules["ccpm_dropdownSelectS1"]}
                    value={displayvalue}
                    onChange={(e) => {
                      handleChangeInstance(e);
                    }}
                    MenuProps={{ PaperProps: { style: { maxHeight: "30%" } } }}
                  >
                    {dropdownData.map((i) => {
                      return (
                        <MenuItem
                          style={{ fontSize: "12px" }}
                          value={i}
                          key={i}
                        >
                          {i}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
              </Grid>
            </Grid>

            <Grid container sx={{ marginLeft: "12.5rem", marginTop: "2rem" }}>
              <Grid item>
                <Typography className={modules["ccpm_label-styleSP"]}>
                  Default percentage parameters settings:
                </Typography>
              </Grid>
              <Grid item sx={{ marginLeft: "11rem" }}>
                <FormControl sx={{ marginTop: "-0.5rem" }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={parameter}
                    onChange={(e) => {
                      console.log("newTag", newTag);
                      if (newTag === true) {
                        changeTheParameter(e);
                      }
                    }}
                  >
                    <FormControlLabel
                      value="Instance level"
                      control={
                        <Radio
                          color="default"
                          className={modules["radio-scale"]}
                          style={{padding: "0px"}}
                        />
                      }
                      label={
                        <span className={modules["SP_Text"]}>
                          Instance level
                        </span>
                      }
                      disableRipple
                    />
                    <FormControlLabel
                      value="Attribute level"
                      control={
                        <Radio
                          color="default"
                          className={modules["radio-scale"]}
                          style={{padding: "0px"}}
                        />
                      }
                      label={
                        <span className={modules["SP_Text"]}>
                          Attribute level
                        </span>
                      }
                      disableRipple
                    />
                    <FormControlLabel
                      value="Not Required"
                      control={
                        <Radio
                          color="default"
                          className={modules["radio-scale"]}
                          style={{padding: "0px"}}
                        />
                      }
                      label={
                        <span className={modules["SP_Text"]}>Not Required</span>
                      }
                      disableRipple
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                container
                style={{ display: attributeLevelTag === false ? "" : "none" }}
              >
                <Grid xs={8} sx={{ marginLeft: "9%" }}>
                  <div className={modules["ccpm_table-containerSP"]}>
                    <Table className={modules["custom-tableSP"]}>
                      <TableHead sx={{ backgroundColor: "#a4bf47" }}>
                        <TableRow>
                          <th>
                            Instance <br />
                          </th>
                          <th style={{ width: "21%" }}>
                            Task Reduction <br /> %
                          </th>
                          <th style={{ width: "21%" }}>
                            Project Buffer <br /> %
                          </th>
                          <th style={{ width: "21%" }}>
                            Feeding Buffer <br /> %
                          </th>
                          <th
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "70%",
                            }}
                          >
                            Set
                            <br />
                            &nbsp;
                          </th>
                        </TableRow>
                      </TableHead>
                      <TableBody className={modules["table-prop"]}>
                        {instanceLevelTableData.map((item, index) => (
                          <TableRow
                            key={index}
                            className={modules["table-row"]}
                          >
                            <TableCell>{item.instance}</TableCell>
                            <TableCell>
                              <input
                                type="number"
                                value={taskred}
                                className={modules["input1SP"]}
                                onChange={(e) => setTaskred(e.target.value)}
                              />
                              %
                            </TableCell>
                            <TableCell>
                              <input
                                type="number"
                                value={probuff}
                                className={modules["input1SP"]}
                                onChange={(e) => setProbuff(e.target.value)}
                              />
                              %
                            </TableCell>
                            <TableCell>
                              <input
                                type="number"
                                value={feedbuff}
                                className={modules["input1SP"]}
                                onChange={(e) => setFeedbuff(e.target.value)}
                              />
                              %
                            </TableCell>
                            <TableCell sx={{ width: "10%" }}>
                              <button
                                onClick={() => saveTheInstanceLevelData(item)}
                                className={modules["ccpm_btnSP"]}
                              >
                                Save
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        onClick={() => deleteInstance("InstanceTable")}
                        className={modules["ccpm_btn2"]}
                      >
                        Delete All
                      </button>
                    </div>
                  </div>
                </Grid>
                <Grid xs={3}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: attributeLevelTag == true ? "" : "none",
            marginTop: "5%",
            marginBottom: "25px",
            height: "100%",
          }}
        >
          <Grid item xs={2.7}></Grid>

          <Grid item lg={2.1}>
            <Typography className={modules["ccpm_label-styleSP"]}>
              Attribute Name :
            </Typography>
          </Grid>
          <Grid item lg={2.7} sx={{ marginLeft: "11%" }}>
            {console.log("attributeNameData", attributeNameData)}
            {attributeNameData.length !== 0 ? (
              <select
                name="cars"
                id="cars"
                value={attributeName}
                onChange={(e) => {
                  getAttributebasedTabledata(e);
                }}
                className={modules["dropdownSelect1"]}
                style={{
                  fontSize: "12px",
                  width: "18rem",
                  height: "1cm !important",
                }}
              >
                <option style={{ color: "#373736" }}>Select</option>
                {attributeNameData?.length > 0 &&
                  attributeNameData?.map((i) => (
                    <option key={i.attributename} value={i.attributenumber} style={{ color: "#373736" }}>
                      {i.attributename}
                    </option>
                  ))}
              </select>
            ) : (
              <select
                name="cars"
                id="cars"
                value={displayvalue}
                onChange={(e) => {
                  setDisplayValue(e.target.value);
                }}
                className={modules["dropdownSelect1"]}
                style={{
                  marginLeft: "1rem",
                }}
              >
                <option style={{ color: "#373736" }}>Select</option>
              </select>
            )}
            {/* <FormControl className={modules["form-control"]}>
              {attributeNameData.length !== 0 ? (
                <Select
                  IconComponent={ArrowDropDownIcon}
                  className={modules["ccpm_dropdownSelect"]}
                  value={attributeName}
                  onChange={(e) => {
                    getAttributebasedTabledata(e);
                  }}
                  MenuProps={{ PaperProps: { style: { maxHeight: "30%" } } }}
                  style={{
                    fontSize: "12px",
                    width: "18rem",
                    height: "1cm !important",
                  }}
                >
                  <MenuItem
                    style={{ fontSize: "12px" }}
                    value="Select"
                    key="Select"
                  >
                    Select
                  </MenuItem>

                  {attributeNameData?.map((i) => (
                    <MenuItem
                      style={{ fontSize: "12px" }}
                      key={i.attributenumber}
                      value={i.attributenumber}
                    >
                      {i.attributename}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Select
                  IconComponent={ArrowDropDownIcon}
                  className={modules["ccpm_dropdownSelect"]}
                  value={displayvalue}
                  onChange={(e) => {
                    setDisplayValue(e.target.value);
                  }}
                  MenuProps={{ PaperProps: { style: { maxHeight: "30%" } } }}
                  style={{ fontSize: "12px", width: "18rem" }}
                >
                  <MenuItem style={{ fontSize: "12px" }}>Select</MenuItem>
                </Select>
              )}
            </FormControl> */}
          </Grid>
          <Grid container>
            <Grid xs={2}></Grid>
            <Grid xs={10}>
              <div
                className={modules["ccpm_table-container"]}
                style={{
                  display: attributeLevelTableTag === true ? "" : "none",
                }}
              >
                <Table className={modules["custom-tableSP"]}>
                  <TableHead
                    sx={{
                      textAlign: "center !important",
                      backgroundColor: "#a4bf47",
                    }}
                  >
                    <TableRow>
                      <th>Select</th>
                      <th>Instance</th>
                      <th style={{ width: "15%" }}>Attribute Value</th>
                      <th style={{ width: "15%" }}>
                        Task Reduction <br /> %
                      </th>
                      <th style={{ width: "15%" }}>
                        Project Buffer <br />%
                      </th>
                      <th style={{ width: "15%" }}>
                        Feeding Buffer <br /> %
                      </th>
                      <th style={{ width: "6%" }}>
                        {" "}
                        Set <br /> &nbsp;{" "}
                      </th>
                    </TableRow>
                  </TableHead>
                  {attributeTableData?.length != 0 ? (
                    <>
                      {attributeTableData?.map((item, index) => (
                        <TableBody className={modules["table-prop"]}>
                          <TableCell>
                            <div>
                              <Checkbox
                                sx={{
                                  padding: "0px",
                                  "& .MuiSvgIcon-root": {
                                    width: "15px",
                                    height: "15px",
                                  },
                                }}
                                defaultChecked
                                size="small"
                                disabled
                              />
                            </div>
                          </TableCell>
                          <TableCell>{item.instance}</TableCell>
                          <TableCell>{item.attributevalue}</TableCell>
                          <TableCell>
                            <input
                              type="number"
                              value={
                                item.taskreductionper === null
                                  ? item.taskreductionper
                                  : item.taskreductionper.toLocaleString("en", {
                                      useGrouping: false,
                                      minimumFractionDigits: 2,
                                    })
                              }
                              className={modules["input1SP"]}
                              onChange={(e) => {
                                handleRowChangeTaskReduction(item, e);
                              }}
                            />
                            <br />%
                          </TableCell>
                          <TableCell>
                            <input
                              type="number"
                              value={
                                item.projectbufferreductionper === null
                                  ? item.projectbufferreductionper
                                  : item.projectbufferreductionper.toLocaleString(
                                      "en",
                                      {
                                        useGrouping: false,
                                        minimumFractionDigits: 2,
                                      }
                                    )
                              }
                              className={modules["input1SP"]}
                              onChange={(e) =>
                                handleRowChangeAttrProjectBuff(item, e)
                              }
                            />
                            <br />%
                          </TableCell>
                          <TableCell>
                            <input
                              type="number"
                              value={
                                item.feedingbufferreductionper === null
                                  ? item.feedingbufferreductionper
                                  : item.feedingbufferreductionper.toLocaleString(
                                      "en",
                                      {
                                        useGrouping: false,
                                        minimumFractionDigits: 2,
                                      }
                                    )
                              }
                              className={modules["input1SP"]}
                              onChange={(e) =>
                                handleRowChangeAttrFeedingBuff(item, e)
                              }
                            />
                            <br />%
                          </TableCell>
                          <TableCell>
                            <button
                              onClick={(e) => saveTheInstanceLevelData(item)}
                              className={modules["ccpm_btnSP"]}
                            >
                              Save
                            </button>
                          </TableCell>
                        </TableBody>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </Table>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2%",
                  marginRight: "9.5%",
                }}
              >
                <button
                  onClick={() => deleteInstance("AttributeTable")}
                  className={modules["ccpm_btn2"]}
                >
                  Delete All
                </button>
              </div>
            </Grid>
            <Grid xs={1}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SettingPercentage;
