import React, { useEffect, useState } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import { Grid, FormControl, Select, MenuItem, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SampleSidebar from "../../../Landing/SampleSidebar";
import modules from "../ccpm.module.css";
// import modules from "./InstanceAttributeMapping.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  IAL_InstanceNames,
  fetchAttributeHierarchy,
  updateAttributeHierarchy,
  insertInstanceWiseAttributeHierarchy,
} from "../../../Services/CCPM"; // Import your update service function
import loading from "../../../Assets/Loading_image.gif";

const CustomCheckbox = ({ checked, onChange, disabled }) => {
  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

const InstanceAttributeMapping = () => {
  const [searchValue, setSearchValue] = useState("");
  const [checked, setChecked] = useState(false);

  const [dropdownData, setDropdownData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [displayvalue, setDisplayValue] = useState("");
  const [instanceId, setInstanceId] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const [editingText, setEditingText] = useState("");
  const [instanceData, setInstanceData] = useState([]);
  const [newAttributeName, setNewAttributeName] = useState("");
  const [loginId, setLoginId] = useState(null);
  const [isAddAttributeChecked, setIsAddAttributeChecked] = useState(true);
  const [originalCheckboxStates, setOriginalCheckboxStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    setIsLoading(true);
    checkLoginAndRedirect();
    const storedLoginId = sessionStorage.getItem("loginId");
    console.log("Stored Login ID:", storedLoginId);
    if (storedLoginId) {
      setLoginId(storedLoginId);
    }
  }, []);

  useEffect(() => {
    if (loginId) {
      console.log("Fetching data with loginId:", loginId);

      IAL_InstanceNames(loginId)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            console.log(
              "Instance names response:",
              response?.data?.success?.data
            );
            setInstanceData(response?.data?.success?.data);
            setDropdownData(response?.data?.success?.data);
            setDisplayValue(response?.data?.success?.data[1]?.name || "");
            setInstanceId(response?.data?.success?.data[0]?.id || null);
            if (response?.data?.success?.data.length > 0) {
              const defaultInstanceId = response?.data?.success?.data[0]?.id;
              fetchAttributeHierarchyData(defaultInstanceId);
            }
          } else {
            console.log(
              `Unexpected response status: ${response?.data?.status}`
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
          setFilteredData(DummyData);
        });
    }
  }, [loginId]);

  const fetchAttributeHierarchyData = async (id) => {
    console.log("Fetching attribute hierarchy data for instance ID:", id);
    try {
      const response = await fetchAttributeHierarchy(id, 1);

      if (response?.data?.statusCode === 200) {
        const apidata = response?.data?.success?.data;
        const updatedData = apidata.map((item) => ({
          ...item,
          isActive: item.isactive,
          originalIsActive: item.isactive,
        }));
        setFilteredData(updatedData);
        setIsLoading(false);
        console.log("FilteredData:", response?.data?.success?.data);
      } else {
        // console.error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to fetch attribute hierarchy:", error);
      setFilteredData(DummyData);
    }
  };

  const handleChange = (event) => {
    const selectedName = event.target.value;
    console.log("Selected Name:", selectedName);
    setDisplayValue(selectedName);

    // Find the instance data based on the selected name
    // const instance = instanceData.find((item) => item.id === selectedName);
    // console.log("Selected Instance:", instance);
    if (selectedName) {
      setInstanceId(selectedName); // Set the selected instance's ID
      fetchAttributeHierarchyData(selectedName); // Fetch hierarchy data for the selected instance
    }
  };

  const handleCheckboxChange = (id) => (e) => {
    // if(e.target.checked){
    //   setChecked(true);
    // }
    setFilteredData((prevData) =>
      prevData.map((item) =>
        item.id === id
          ? { ...item, originalIsActive: e.target.checked ? 1 : 0 }
          : item
      )
    );
  };

  const handleReset = () => {
    setSearchValue("");
    // Reset filteredData or refetch original data if needed
    fetchAttributeHierarchyData(instanceId); // Refetch hierarchy data for the current instance
  };

  const handleEdit = (row) => {
    setEditingRow(row.id);
    setEditingText(row.attributename);
    setOriginalCheckboxStates((prevData) =>
      prevData.map((item) =>
        item.id === row.id ? { ...item, originalIsActive: item.isactive } : item
      )
    );
  };

  const handleUpdate = async (id) => {
    setFilteredData((prevData) =>
      prevData.map((item) =>
        item.id === id
          ? {
              ...item,
              attributename: editingText,
              isactive: item.originalIsActive ? 1 : 0,
            }
          : item
      )
    );

    const itemToUpdate = filteredData.find((item) => item.id === id);
    console.log("Item to update:", itemToUpdate);

    if (!itemToUpdate) {
      alert(`Item with id ${id} not found.`);
    }

    const payload = {
      id,
      attributeName: editingText.trim(),
      isActive: itemToUpdate.originalIsActive ? 1 : 0,
      modifiedBy: loginId,
    };

    console.log("Update payload:", payload);

    const hasChanges =
      itemToUpdate.attributename !== payload.attributeName ||
      itemToUpdate.isactive !== payload.isActive;

    if (editingText.trim() === "") {
      alert("Attribute Name Cannot be Blank.");
      return;
    }

    if (!hasChanges) {
      alert("No changes to update.");
      return;
    }

    // const response = await

    updateAttributeHierarchy(
      payload.id,
      payload.attributeName,
      payload.isActive,
      payload.modifiedBy
    )
      .then((response) => {
        console.log("Update response:", response?.data?.success);
        if (response?.data?.success === true) {
          // const updatedData = response?.data?.success?.data;
          // setFilteredData((prevData) =>
          //   prevData.map((item) =>
          //     item.id === updatedData.id
          //       ? { ...updatedData, originalIsActive: updatedData.isactive }
          //       : item
          //   )
          // );
          setEditingRow(null);
          fetchAttributeHierarchyData(instanceId);
          alert("Details updated successfully");
        } else if (response?.data?.success === false) {
          alert(response?.data?.message);
        } else {
          console.log(`Unexpected response status: ${response.response}`);
        }
      })
      .catch((response) => {
        console.error("Failed to update attribute hierarchy:", response);
        alert(response.message);
        console.log("Error:", response);
      });
  };

  const handleCancel = () => {
    // setFilteredData((prevData) =>
    //   prevData.map((item) =>
    //     item.id === editingRow
    //       ? {
    //           ...item,
    //           attributename: item.attributename,
    //           isactive: item.originalIsActive,
    //         }
    //       : item
    //   )
    // );
    fetchAttributeHierarchyData(instanceId);
    setEditingRow(null);
  };

  const DummyData = [
    { id: 1, col2: "Aerospace", col3: "Aerospace", isActive: true },
    {
      id: 2,
      col2: "Innovation and Design Center",
      col3: "IDC",
      isActive: false,
    },
    { id: 3, col2: "GB Corporate Projects", col3: "GB_CORP", isActive: true },
    {
      id: 4,
      col2: "PSS Gogrej Security Solutions",
      col3: "SSD_PSS",
      isActive: false,
    },
    { id: 5, col2: "G&B Finance", col3: "G&B Finance", isActive: true },
  ];

  const handleAddAttribute = async (item) => {
    console.log("Adding new attribute:", newAttributeName);

    if (newAttributeName.trim() === "") {
      alert("Please select a new attribute name to be added.");
      return;
    }

    const specialCharPattern = /[^a-zA-Z0-9()_\-&]/;
    if (specialCharPattern.test(newAttributeName.trim())) {
      alert("No Special Characters Allowed.");
      return;
    }

    const lowerCaseNewAttributeName = newAttributeName.trim().toLowerCase();
    const isDuplicate = filteredData.some((existingItem) => {
      const normalizedExistingAttributeName = existingItem.attributename
        .trim()
        .toLowerCase();

      return normalizedExistingAttributeName === lowerCaseNewAttributeName;
    });

    if (isDuplicate) {
      alert("Attribute name already exists.");
      return;
    }
    const payload = {
      instanceId,
      attributeName: newAttributeName.trim(),
      createdBy: loginId,
    };

    insertInstanceWiseAttributeHierarchy(
      payload.instanceId,
      payload.attributeName,
      payload.createdBy
    )
      .then((response) => {
        console.log("Insert response:", response);
        if (response?.data?.success === true) {
          const newAttribute = response.data;
          setFilteredData((prevData) => [
            ...prevData,
            {
              id: newAttribute.id,
              attributename: newAttribute.attributeName,
              isActive: newAttribute.isActive,
            },
          ]);
          setNewAttributeName("");
          alert(response?.data?.message);
          fetchAttributeHierarchyData(instanceId);
        } else if (response?.data?.success === false) {
          alert(response?.data?.message);
          fetchAttributeHierarchyData(instanceId);
        } else {
          console.error(`Unexpected response status: ${response.status}`);
          alert("Failed to insert new attribute. Please try again.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddAttributeCheckboxChange = (e) => {
    setIsAddAttributeChecked(e.target.checked);
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"InstanceAttributeMapping"} />
          </Grid>
          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "0rem" }}
            >
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem", marginBottom: "25px" }}
              >
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Instance Attribute Mapping</a>
              </div>
            </div>
            <Grid
              container
              className={modules["parent-container"]}
              sx={{ marginLeft: "7.5rem !important" }}
            >
              <Grid
                container
                className={modules["instanceAttMap_mid-cont"]}
                sx={{ height: "15px" }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <span
                      style={{
                        fontSize: "12px",
                        marginLeft: "-8px",
                        color: "#414141",
                      }}
                    >
                      Instance Attribute Hierarchy [One Instance Can Define
                      Maximum 10 Attributes]
                    </span>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                className={modules["instanceAttMap_mid-cont"]}
                sx={{ marginBottom: "2rem" }}
              >
                <Grid item lg={6}>
                  <Grid
                    container
                    spacing={0.5}
                    sx={{ alignItems: "end", marginTop: "15px" }}
                  >
                    <Grid
                      item
                      xs={1.8}
                      className={modules["label-style"]}
                      style={{ fontWeight: "bold" }}
                    >
                      Instance :<span className={modules["span_star"]}>*</span>
                    </Grid>
                    <Grid item xs={2.5}>
                      <FormControl className={modules["form-control"]}>
                        <select
                          IconComponent={ArrowDropDownIcon}
                          className={modules["InstanceSelect"]}
                          value={displayvalue}
                          onChange={(e) => {
                            setDisplayValue(e.target.value);
                            handleChange(e); // Call handleChange to update instanceId
                          }}
                        >
                          {dropdownData.map((i) => {
                            // console.log("DropdownData:", dropdownData);
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.id}
                                key={i.name}
                              >
                                {i.name}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                className={modules["instanceAttMap_mid-cont"]}
                style={{
                  display: filteredData.length >= 10 ? "none" : "block",
                }}
              >
                <Grid item lg={12} style={{ height: "7rem" }}>
                  <Grid container style={{ alignItems: "center" }}>
                    <Grid
                      item
                      xs={12}
                      style={{ width: "100%", alignItems: "center" }}
                    >
                      <span style={{ alignSelf: "top" }}>
                        <input
                          type="checkbox"
                          className={modules["custom-checkbox"]}
                          checked={isAddAttributeChecked}
                          onChange={handleAddAttributeCheckboxChange}
                          disabled={filteredData.length >= 10}
                        />
                      </span>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "blue",
                          marginLeft: "8px",
                        }}
                      >
                        Add New Attribute [Maximum 10 Attribute can be added]
                      </span>
                      {isAddAttributeChecked && (
                        <span className="blockItem">
                          <span className="menuIcon"></span>
                          <span
                            className={modules["label-style"]}
                            style={{
                              marginTop: "13rem",
                              lineHeight: "1.5rem",
                              marginRight: "3px",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            Attribute Name:{" "}
                            <span style={{ color: "red" }}></span>
                          </span>
                          <span>
                            <input
                              value={newAttributeName}
                              onChange={(e) => {
                                const value = e.target.value;
                                // Regex to keep only alphanumeric characters and specific special characters
                                const allowedCharacters = value.replace(
                                  /[^a-zA-Z0-9&()_\-+=\[\]\/]/g,
                                  ""
                                );
                                setNewAttributeName(allowedCharacters);
                              }}
                              className={modules["inputTextIAM"]}
                              style={{ paddingLeft: "5px" }}
                            />
                          </span>
                        </span>
                      )}

                      {isAddAttributeChecked && (
                        <span
                          className={modules["attr-btnIAM"]}
                          style={{ justifyContent: "left !important" }}
                        >
                          <button
                            variant="contained"
                            onClick={handleAddAttribute}
                          >
                            Add New Attribute
                          </button>
                        </span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={modules["instanceAttMap_table-grid"]}>
                {filteredData && filteredData.length > 0 ? (
                  <div className={modules["ccpm_table-containerIAM"]}>
                    <Table className={modules["custom-table"]}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontFamily: "work-sans, sans-serif",
                              fontSize: "15px",
                              width: "60px",
                            }}
                          >
                            Sr. No.
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "work-sans, sans-serif",
                              fontSize: "15px",
                              textAlign: "left !important",
                            }}
                          >
                            Attribute Name
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "work-sans, sans-serif",
                              fontSize: "15px",
                              textAlign: "left !important",
                              width: "60px",
                            }}
                          >
                            Active
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "work-sans, sans-serif",
                              fontSize: "15px",
                              textAlign: "left !important",
                            }}
                          >
                            Edit
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredData.map((item, index) => (
                          <TableRow
                            key={index}
                            className={modules["table-row"]}
                          >
                            <TableCell
                              className={modules["table-cell"]}
                              sx={{ textAlign: "left !important" }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              className={modules["table-cell"]}
                              sx={{ textAlign: "left !important" }}
                            >
                              {editingRow === item.id ? (
                                <input
                                  value={editingText}
                                  onChange={(e) =>
                                    setEditingText(e.target.value)
                                  }
                                  variant="outlined"
                                />
                              ) : (
                                item.attributename
                              )}
                            </TableCell>

                            <TableCell className={modules["table-cell"]}>
                              {editingRow === item.id ? (
                                <input
                                  type="checkbox"
                                  className={modules["custom-checkbox"]}
                                  style={{
                                    cursor: "pointer",
                                    width: "13px",
                                    display: "grid",
                                  }}
                                  checked={item.originalIsActive === 1}
                                  onChange={handleCheckboxChange(item.id)}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className={modules["custom-checkbox"]}
                                  style={{
                                    width: "13px",
                                    display: "grid",
                                    opacity: "0.5",
                                  }}
                                  checked={item.originalIsActive === 1}
                                  disabled={true}
                                />
                              )}
                            </TableCell>

                            <TableCell
                              className={modules["table-cell"]}
                              sx={{ textalign: " left !important" }}
                            >
                              {editingRow === item.id ? (
                                <div
                                  direction="column"
                                  spacing={0.01}
                                  alignItems="center"
                                  marginLeft="5px"
                                >
                                  <button
                                    className={modules["btn3"]}
                                    style={{
                                      cursor: "pointer",
                                      width: "100%",
                                      margin: "0px",
                                    }}
                                    onClick={() => handleUpdate(item.id)}
                                  >
                                    Update
                                  </button>
                                  <button
                                    className={modules["btn3"]}
                                    style={{
                                      cursor: "pointer",
                                      width: "100%",
                                      margin: "0px",
                                    }}
                                    onClick={() => handleCancel()}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              ) : (
                                <button
                                  className={modules["btn3"]}
                                  style={{
                                    width: "100%",
                                    cursor: "pointer",
                                    margin: "0px",
                                  }}
                                  onClick={() => handleEdit(item)}
                                >
                                  Edit
                                </button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Grid container className={modules["btn2-cont"]}>
                      <span>
                        <br></br>
                        <br></br>
                      </span>
                    </Grid>
                  </div>
                ) : (
                  <TableRow
                    className={modules["table-cell"]}
                    sx={{
                      textAlign: "left !important",
                      borderBottom: "1px solid #a4bf47",
                      fontSize: "12px",
                      color: "#414141",
                      padding: "8px",
                      width: "500px"
                    }}
                  >
                    No Record Found
                  </TableRow>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InstanceAttributeMapping;
