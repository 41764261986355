import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import changepm1 from './images/changepm1.png'
import changepm2 from './images/changepm2.png'
import changepm3 from './images/changepm3.png'
import changepm4 from './images/changepm4.png'
import view6 from './images/view6.png'
import view7 from './images/view7.png';
import BRR1 from '../HelpPopups/images/BRR1.jpg';
import { Link } from "react-router-dom";

const BufferRecoveryhelpPopup = () => {
    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            Expand it...
                            <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>
            </div>

            <Grid sx={{fontFamily: '"Calibri", sans-serif', fontSize:'14.6px', color:'#000000'}}>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul>
                            <h3 style={{ fontWeight: "bold",textDecoration: "underline" }}>Buffer Recovery Report</h3>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                            In this report user can check the Project wise buffer recovery information, which includes project colour, current percentage of critical chain completed, current percentage of buffer consumed and current buffer penetration days.
                            </li>
                            <br />
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                            ‘Current Project color’ is color coded based on Buffer consumption and same color coded at  Project and Task level progress.  
                            </li>
                           
                            <br />
                            <Stack style={{ width: '626px', height: '203px' }} sx={{ paddingRight: "1rem" }}>
                                <img src={BRR1} alt="BRR1" />
                            </Stack>
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default BufferRecoveryhelpPopup;
