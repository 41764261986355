import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material";
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import RM1 from "../HelpPopups/images/RUMA1.jpg";
import { Link } from "react-router-dom";

const AssignParentRolehelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3
                style={{
                  marginLeft: "14.5rem",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Restrict User Roles Mapping
              </h3>
              <br />

              <li>
                The roles which are maintained in this session, will determine
                which users will be able to assign roles to other users. For eg.
                As seen in the screenshot below, only users with IT admin roles
                can assign role of IT admin or Project manager to other users.
              </li>
              <li>Path: Admin &rarr; Restrict User Roles Mapping</li>

              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={RM1} alt="RM1" />
              </Stack>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AssignParentRolehelpPopup;
