import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import bread from "../../../Assets/bread.png";
import window_popup from "../../../Assets/window_popup.png";
import { Set_NewInstance } from "../../../Services/Dss";
import {
  SP_MtaInstances,
  SP_MtaStockpointtype,
  SPBU_UploadMTAStockPointBuffer,
  SPBU_Get,
  SPBU_DownloadSampleFormat,
  SPBU_Merge,
} from "../../../Services/MTA";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";

export default function StockPointBufferDownload() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInstanceId, setSelectedInstanceId] = useState("");
  const [stockPointName, setStockPointName] = useState("");

  const [dropdownData, setDropdownData] = useState([]);
  const [stockPointTypes, setStockPointTypes] = useState([]);

  const [actionType, setActionType] = useState("");
  const [stockPoint, setStockPoint] = useState("");
  const [warehouseCode, setWarehouseCode] = useState("");
  const [newWarehouseCode, setNewWarehouseCode] = useState("");
  const [dispatchPriority, setDispatchPriority] = useState("");
  const [newDispatchPriority, setNewDispatchPriority] = useState("");
  const [stockPointDesc, setstockPointDesc] = useState("");
  const [selectedStockPointType, setSelectedStockPointType] = useState("");
  const [diffCode, setDiffCode] = useState("");
  const [diffDes, setdiffDes] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [popupData, setPopupData] = useState({});
  const [popup2Data, setPopup2Data] = useState({});
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [Ndisable, setNdisable] = useState(false);

  const ChannelSp = new BroadcastChannel("stockPointChannel");
  const ChannelWc = new BroadcastChannel("warehouseCodeChannel");

  ChannelSp.onmessage = (e) => {
    setPopupData({
      [e.data.mainrole]: e.data.selectedData,
      mainroleid: e.data.mainrole,
      roleidslist: e.data.selectedData.roleIds,
    });
    setStockPoint(e.data.selectedData.roleIds);
    setStockPointName(e.data.selectedData.roleNames);
  };

  ChannelWc.onmessage = (e) => {
    setPopup2Data({
      [e.data.mainrole]: e.data.selectedData,
      mainroleid: e.data.mainrole,
      roleidslist: e.data.selectedData.roleIds,
    });
    console.log(e.data.selectedData);
    setCompanyCode(e.data.selectedData.companyCode);
    setWarehouseCode(e.data.selectedData.WarehouseCode);
    setNewWarehouseCode(e.data.selectedData.WarehouseCode);
  };

  const handleRadioChange = (event) => {
    setActionType(event.target.value);
    setStockPoint("");
    setCompanyCode("");
    setNewWarehouseCode("");
    setWarehouseCode("");
    // setResetted(false);
  };

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getInstances();
    setHasRunOnce(false);
  }, []);

  const getInstances = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      instance_uid: "",
      muiuid_last_modified_by: sessionStorage
        .getItem("loginUsername")
        .toLowerCase(),
    };
    if (loginId) {
      SP_MtaInstances(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setDropdownData(response?.data?.success?.data);
            setIsLoading(false);
            // console.log(response?.data?.success?.data.length, "length");

            if (response?.data?.success?.data.length > 0) {
              const defaultInstanceId =
                response?.data?.success?.data[0]?.mta_instance_uid;
              // console.log(defaultInstanceId, "fcewf");
              setSelectedInstanceId(defaultInstanceId);
              getStockPointType(defaultInstanceId);
            } else if (response?.data?.success?.data.length === 0) {
              alert("You do not have access to any Active Instance in MTA");
              // console.log(response?.data?.success?.data.length, "WWWW");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const getStockPointType = (instance) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      spt_instance_uid: instance,
      userid: loginId,
    };
    if (loginId) {
      SP_MtaStockpointtype(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setIsLoading(false);
            if (response?.data?.success?.data.length > 0) {
              setStockPointTypes(response?.data?.success?.data);
            } else if (response?.data?.success?.data.length === 0) {
              setStockPointTypes(response?.data?.success?.data);
              // alert("You do not have access to any Active Instance in MTA");
              // console.log(response?.data?.success?.data.length, "WWWW");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching Stock points:", error);
        });
    }
  };

  function submitHandler() {}

  function resetHandler() {
    setSelectedStockPointType("");
    setStockPoint("");
    setActionType("");
    setCompanyCode("");
    setWarehouseCode("");
    setNewWarehouseCode("");
    setHasRunOnce(false);
    setNdisable(false);
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"StockPointBufferDownload"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>Stock-Point-Buffer-Download</a>
              </div>
            </div>

            <Grid
              container
              className={modules["mid-cont"]}
              sx={{ marginBottom: "2rem" }}
            >
              <table
                className={modules["customTable"]}
                style={{ marginLeft: "3rem" }}
              >
                <tbody>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "12rem", fontWeight: "bold" }}>
                      MTA Instance <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedInstanceId}
                          onChange={(e) => {
                            setSelectedInstanceId(e.target.value);
                          }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          disabled={actionType}
                        >
                          {dropdownData.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mta_instance_uid}
                                key={i.id}
                                data-id={i.id}
                              >
                                {i.mta_instance_uid}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "12rem", fontWeight: "bold" }}>
                      Stock Point
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={stockPoint}
                        onChange={(e) => {
                          setStockPoint(e.target.value);
                        }}
                        onInput={(e) => {
                          const regex = /^[a-zA-Z0-9()&_\-+=\[\]/. ]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9()&_\-+=\[\]/. ]/g,
                              ""
                            );
                            setStockPoint(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          const regex2 = /^[a-zA-Z0-9_ &()-]*$/;
                          if (!regex2.test(e.target.value)) {
                            alert("No Special Characters Allowed.");
                            setStockPoint("");
                            setNdisable(false);
                          }
                        }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px", width: "250px" }}
                        // disabled={actionType === "2" || actionType === "3"}
                        maxLength={50}
                      />
                      <img
                        src={window_popup}
                        alt=""
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          height: "10px",
                        }} // Adjust spacing as needed
                        title="Click To Select Stock Point"
                        onClick={() => {
                          window.open(
                            `/ccpm/StockPointPopup?instance=${selectedInstanceId}`,
                            "mypopuptitle",
                            "width=600,height=500"
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "12rem", fontWeight: "bold" }}>
                      Company Code
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={companyCode}
                        onChange={(e) => {
                          setCompanyCode(e.target.value);
                          setNdisable(true);
                          if (e.target.value === "") {
                            setNdisable(false);
                          }
                        }}
                        onInput={(e) => {
                          const regex = /^[a-zA-Z0-9()&_\-+=\[\]/. ]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9()&_\-+=\[\]/. ]/g,
                              ""
                            );
                            setCompanyCode(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          const regex2 = /^[a-zA-Z0-9_ &()-]*$/;
                          if (!regex2.test(e.target.value)) {
                            alert("No Special Characters Allowed.");
                            setCompanyCode("");
                            setNdisable(false);
                          }
                        }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px", width: "250px" }}
                        disabled={actionType === "2" || actionType === "3"}
                        maxLength={50}
                      />
                      <img
                        src={window_popup}
                        alt=""
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          height: "10px",
                        }} // Adjust spacing as needed
                        title="Click To Select Record"
                        onClick={() => {
                          if (stockPoint === "") {
                            alert("Select Stock Point");
                            return;
                          } else {
                            window.open(
                              `/ccpm/CompanyCodePopup2?instance=${selectedInstanceId}&stock_point=${stockPoint}`,
                              "mypopuptitle",
                              "width=600,height=500"
                            );
                          }
                        }}
                      />
                    </td>

                    <td
                      style={{
                        width: "12rem",
                        fontWeight: "bold",
                        marginLeft: "65px",
                      }}
                    >
                      Item Code
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={newWarehouseCode}
                        onChange={(e) => {
                          setNewWarehouseCode(e.target.value);
                          setNdisable(true);
                          if (e.target.value === "") {
                            setNdisable(false);
                          }
                        }}
                        onInput={(e) => {
                          const regex = /^[a-zA-Z0-9()&_\-+=\[\]/. ]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9()&_\-+=\[\]/. ]/g,
                              ""
                            );
                            setNewWarehouseCode(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          const regex2 = /^[a-zA-Z0-9_ &()-]*$/;
                          if (!regex2.test(e.target.value)) {
                            alert("No Special Characters Allowed.");
                            setNewWarehouseCode("");
                            setNdisable(false);
                          }
                        }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px", width: "250px" }}
                        // disabled={actionType === "2" || actionType === "3"}
                        maxLength={50}
                      />
                      <img
                        src={window_popup}
                        alt=""
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          height: "10px",
                        }} // Adjust spacing as needed
                        title="Click To Select Record"
                        onClick={() => {
                          if (stockPoint === "") {
                            alert("Select Stock Point");
                            return;
                          } else {
                            window.open(
                              `/ccpm/ItemCodePopup?instance=${selectedInstanceId}&stock_point=${stockPoint}`,
                              "mypopuptitle",
                              "width=600,height=500"
                            );
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "12rem", fontWeight: "bold" }}>
                      Download Type <span style={{ color: "red" }}>*</span>
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedInstanceId}
                          onChange={(e) => {
                            setSelectedInstanceId(e.target.value);
                          }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          disabled={actionType}
                        >
                          {dropdownData.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mta_instance_uid}
                                key={i.id}
                                data-id={i.id}
                              >
                                {i.mta_instance_uid}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                    <a
                      href="/path-to-download-sample"
                      style={{
                        marginLeft: "55px",
                        color: "#0000EE",
                        textDecoration: "underline",
                        cursor: "pointer",
                        paddingLeft: "150px",
                      }}
                    >
                      Download Sample Format
                    </a>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // marginLeft: "218px",
                      marginBottom: "20px",
                    }}
                  >
                    <button className={modules["btn1"]} onClick={submitHandler}>
                      Search
                    </button>
                    <button
                      className={modules["btn1"]}
                      style={{ marginLeft: "20px" }}
                      onClick={resetHandler}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>

              {/* {actionType === "3" && (
                <>
                  <Grid
                    container
                    className={modules["mid-cont"]}
                    style={{ marginLeft: "14rem", marginTop: "0rem" }}
                  >
                    <button className={modules["btn1"]} onClick={submitHandler}>
                      Delete
                    </button>
                    <button
                      className={modules["btn1"]}
                      style={{ marginLeft: "20px" }}
                      // onClick={resetHandler}
                      onClick={() => window.location.reload()}
                    >
                      Close
                    </button>
                    <button
                      className={modules["btn1"]}
                      style={{ marginLeft: "20px" }}
                      onClick={() => window.location.reload()}
                    >
                      Reset
                    </button>
                  </Grid>
                </>
              )} */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
