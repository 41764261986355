import React, { useState, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  RadioGroup,
  Stack,
  Popper,
  Popover,
  Paper,
  Typography,
  Box,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import modules from "../project.module.css";
import { TbRuler } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import {getProjectInfodetails,getProjectRecommendedStartAndFinishDate,getsimulatedDetails,getShowWeeks,AcceptSimulateData} from "../../../../Services/CCPM";
import Calendar from "react-calendar";
import { useLocation } from "react-router-dom";

const Staggering = () => {
  const [value, setValue] = React.useState("1"); // Default selected value
  const [showSimulateUi, setShowSimulateUi] = useState(false);
  const [projectDetailsData, setProjectDetailsData] = useState([]);
  const [weekShowInp, setWeekShowInp] = useState("");
  const [recommendedStartAndFinshDate, setRecommendedStartAndFinishDate] =
    useState([]);
  const [simulateInputDate, setSimulateInputDate] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [upLikelyDate, setUpLikelyDate] = useState("");
  const [simulateFinishDate, setSimulateFinishDate] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [upLikelyDate2, setUpLikelyDate2] = useState("");
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projId");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setValue(event.target.value);
    setSimulateInputDate("");
    setSimulateFinishDate("");
    setShowSimulateUi(false);
  };
  useEffect(()=>{
    ShowWeeks()
  },[])
  const ShowWeeks=async()=>{
    let res=await getShowWeeks();
    console.log(res?.data?.success?.data);
  }
  const validateSimulate = () => {
    const currentDate = new Date();

    // Check if the value is 1 or 3, and validate the start date
    if ((value === "1" || value === "3") && !simulateInputDate) {
        alert("Please select SimulatedStartDate");
        return false;
    }
    if ((value === "1" || value === "3") && new Date(simulateInputDate) <= currentDate) {
        alert("SimulatedStartDate should be greater than the current date");
        return false;
    }

    // Check if the value is 2 or 3, and validate the finish date
    if ((value === "2" || value === "3") && !simulateFinishDate) {
        alert("Please select SimulatedFinishDate");
        return false;
    }
    if ((value === "2" || value === "3") && new Date(simulateFinishDate) <= currentDate) {
        alert("SimulatedFinishDate should be greater than the current date");
        return false;
    }

    // Additional check for when value is 3, ensuring finish date is after start date
    if (value === "3" && new Date(simulateFinishDate) <= new Date(simulateInputDate)) {
        alert("SimulatedFinishDate should be greater than SimulatedStartDate");
        return false;
    }

    // If all validations pass
    return true;
};

  
  const handleSimulate = async() => {
    if(validateSimulate()===true){
      setShowSimulateUi(true);
    try{
      let obj={
        rblSimulation:parseInt(value),
        projectid:parseInt(projectId),
        txtSimulatedStartDate:simulateInputDate!==null?formatDate(simulateInputDate):null,
        txtSimulatedFinishDate:simulateFinishDate!==null?formatDate(simulateFinishDate):null,
      }
      let res=await getsimulatedDetails(obj)
      const project = res?.data.success?.data;
      console.log("simulate",project)
    }
    catch(error){
      console.log("error")
    }
  }
  };
  useEffect(()=>{
    getProjectInfoDetailsByProjectId()
    getRecommendedStartAndFinishDateDetails();
  }, []);
  // Format date if needed (You can customize it based on your requirement)
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Returns YYYY-MM-DD format
  };
  const handleCalendar1n = (date) => {
    setAnchorEl1(null);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      ?.toLocaleDateString("en-GB", options)
      .replace(/,/g, "");
    setSimulateInputDate(formattedDate);
    setUpLikelyDate(date);
  };
  const handleCalendar2n = (date) => {
    setAnchorEl2(null);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      ?.toLocaleDateString("en-GB", options)
      .replace(/,/g, "");
    setSimulateFinishDate(formattedDate);
    setUpLikelyDate2(date);
  };
  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: "short" }).slice(0, 2);
  };
  const getProjectInfoDetailsByProjectId = async () => {
    try {
      let obj = {
        // projectId:4281
        projectId: projectId,
      };
      let res = await getProjectInfodetails(obj);
      const project = res?.data?.success?.data;
      setProjectDetailsData(project);
    } catch (error) {
      console.log("error");
    }
  };
  const getRecommendedStartAndFinishDateDetails = async () => {
    try {
      let obj = {
        //projectId:4281
        projectId: projectId,
      };
      let res = await getProjectRecommendedStartAndFinishDate(obj);
      const project = res?.data.success?.data;
      console.log("precom", project);
      setRecommendedStartAndFinishDate(project);
    } catch (error) {
      console.log("error");
    }
  };
  const handleAcceptButton = async() => {
    const proceed = window.confirm("Do you really want Accept changes?");
    if (proceed) {
      // User clicked 'OK', proceed with the API call
      let obj={
        confirmValue:"Yes",
        WIPLimit:"",
        StartLimit:"",
        txtResonforViolation:"",
        projectID:projectId,
        txtNoOfWeekData:"",
        RecommendedStartDate:"",
        ProjectedStartDate:"",
        ProjectedFinishDate:"",
        RequesteddueDate:"",
        CustomerDueDate:"",
        ProjectDuraion:"",
        UserId:sessionStorage.getItem("loginId"),
        rblSimulation:value,
        txtSimulatedStartDate:"",
        txtSimulatedFinishDate:"",
        lblNoofBCDays:"",
        lblBCInPercentage:"",
        pnlViolation:"",
        IsBoth:"",
        ResonforViolation:"",
        pnlBufferConsumptionInfo:""
      }
      let res=await AcceptSimulateData(obj);
      console.log(res)
      alert("Project Successfully Added in Scheduled");
    } else {
      // User clicked 'Cancel', do nothing
      return;
    }
  };
  const handleReset2 = () => {
    setWeekShowInp("");
    setSimulateInputDate("");
    setSimulateFinishDate("");
  };

  const projectDetailsTableUI = () => {
    // Assuming projectDetailsData is an array with at least one item
    const projectStatusId =
      projectDetailsData.length > 0
        ? projectDetailsData[0].projectstatusid
        : null;

    return (
      <>
        <table
          className={modules["tableStag"]}
          cellSpacing={0}
          rules="all"
          border={1}
        >
          <tbody>
            <tr>
              <th scope="col">Project&nbsp;Name</th>
              <th scope="col">Modif&nbsp;No</th>
              <th scope="col">Project&nbsp;Duration</th>
              <th scope="col">Customer&nbsp;Due&nbsp;Date</th>
              <th scope="col">Requested&nbsp;Due&nbsp;Date</th>
              <th scope="col">Required&nbsp;Start&nbsp;Date</th>
              {/* Conditionally render headers based on projectstatusid */}
              {projectStatusId === 9 && (
                <>
                  <th scope="col">Scheduled&nbsp;Start&nbsp;Date</th>
                  <th scope="col">Scheduled&nbsp;End&nbsp;Date</th>
                </>
              )}
              {projectStatusId === 1 && (
                <>
                  <th scope="col">BC&nbsp;No&nbsp;of&nbsp;Days</th>
                  <th scope="col">BC%</th>
                </>
              )}
            </tr>

            {/* Mapping through projectDetailsData */}
            {projectDetailsData.map((item, index) => (
              <tr key={index}>
                <td style={{ whiteSpace: "nowrap" }}>
                  <span
                    style={{
                      backgroundColor: "Yellow",
                      fontSize: "Medium",
                      fontWeight: "bold",
                      color: "#414141",
                    }}
                  >
                    {item.projectname}
                  </span>
                </td>
                <td>
                  <span>{item.projectstatusid}</span>
                </td>
                <td>
                  <input
                    type="text"
                    value="32.00"
                    readOnly
                    style={{ backgroundColor: "LightGrey", width: "120px" }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={new Date(item.customerduedate)
                      .toLocaleDateString("en-GB")
                      .replace(/\//g, "-")}
                    readOnly
                    style={{ backgroundColor: "LightGrey", width: "120px" }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={new Date(item.customerduedateccpm)
                      .toLocaleDateString("en-GB")
                      .replace(/\//g, "-")}
                    readOnly
                    style={{ backgroundColor: "LightGrey", width: "120px" }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={new Date(item.projectstartdate)
                      .toLocaleDateString("en-GB")
                      .replace(/\//g, "-")}
                    readOnly
                    style={{ backgroundColor: "LightGrey", width: "120px" }}
                  />
                </td>
                {/* Conditionally render columns based on projectstatusid */}
                {item.projectstatusid === 9 && (
                  <>
                    <td>
                      <input
                        type="text"
                        value={new Date(item.customerduedate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")}
                        readOnly
                        style={{ backgroundColor: "LightGrey", width: "120px" }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={new Date(item.customerduedate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")}
                        readOnly
                        style={{ backgroundColor: "LightGrey", width: "120px" }}
                      />
                    </td>
                  </>
                )}
                {item.projectstatusid === 1 && (
                  <>
                    <td>
                      <span>{item.projectstatusid}</span>
                    </td>
                    <td>
                      <span>{item.projectstatusid}</span>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };
 
  const simulateUi = () => {
    return (
      <>
        <Box
          sx={{
            border: "1px solid #ccc", // Border for the whole container
            padding: 1, // Padding inside the box
            borderRadius: "4px", // Rounded corners
            width: "100%", // Width of the box
            mt: 2, // Margin from the top
          }}
        >
          <Stack
            direction="row"
            spacing={0} // Reduced spacing between tables
            gap={5}
            alignItems="start" // Align to the top to avoid unnecessary gaps
          >
            {/* First Table with Header */}
            <Box>
              <Typography
                className={modules["stagTextNormal"]}
                gutterBottom
                sx={{ mb: 1 }} // Reduced margin at the bottom for less space between header and table
              >
                Buffer Consumption
              </Typography>

              {/* First Table */}
              <table
                className={modules["tableStagsimulate"]}
                cellSpacing={0}
                rules="all"
                border={1}
                style={{ margin: 0 }} // Ensure no extra margin
              >
                <tbody>
                  <tr>
                    <th scope="col">Sr&nbsp;.No</th>
                    <th scope="col">BC&nbsp;N.o&nbsp;of&nbsp;Days</th>
                    <th scope="col">BC%</th>
                    <th scope="col">Projected&nbsp;Start&nbsp;Date</th>
                    <th scope="col">Projected&nbsp;Finsh&nbsp;Date</th>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <span>1</span>
                    </td>
                    <td>
                      <span>0</span>
                    </td>
                    <td>
                      <span>0</span>
                    </td>
                    <td>
                      <span>NA</span>
                    </td>
                    <td>
                      <span>28-09-2024</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>

            {/* Second Table with Header */}
            <Box>
              <Typography
                className={modules["stagTextNormal"]}
                gutterBottom
                sx={{ mb: 1 }} // Reduced margin at the bottom for less space between header and table
              >
                Violation
              </Typography>

              {/* Second Table */}
              <table
                className={modules["tableStagsimulate"]}
                cellSpacing={0}
                rules="all"
                border={1}
                style={{ margin: 0 }} // Ensure no extra margin
              >
                <tbody>
                  <tr>
                    <th scope="col">Sr&nbsp;.No.</th>
                    <th scope="col">WIP&nbsp;Limit</th>
                    <th scope="col">Start&nbsp;Limit</th>
                    <th scope="col">Reason&nbsp;for&nbsp;Violation</th>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <span>1</span>
                    </td>
                    <td>
                      <span>No</span>
                    </td>
                    <td>
                      <span>No</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        value=""
                        style={{ backgroundColor: "LightGrey", width: "120px" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Stack>
        </Box>
        <Stack direction="row" gap={2}>
          <button
            style={{ marginLeft: "10px" }}
            className={modules["btnSettingsPop"]}
            onClick={handleAcceptButton}
          >
            Accept
          </button>
          <button
            style={{ marginLeft: "10px" }}
            className={modules["btnSettingsPop"]}
            onClick={handleclose}
          >
            Close
          </button>
        </Stack>
      </>
    );
  };

  const TableUi = () => {
    return (
      <>
        <div className={modules["table-scroll"]}>
          <div>
            <table
              class="Grid"
              cellspacing="0"
              cellpadding="0"
              border="0"
              id="gvShowWeeks"
              style={{
                width: "70%",
                borderCollapse: "separate",
                borderSpacing: "10px 5px",
              }}
            >
              <tbody>
                <tr style={{ fontSize: "12px" }}>
                  <td style={{ fontWeight: "bold", color:"#414141"}}>Date</td>
                  <td>24-09-21</td>
                  <td>24-09-22</td>
                  <td>24-09-23</td>
                  <td>24-09-24</td>
                  <td>24-09-25</td>
                  <td>24-09-26</td>
                  <td>24-09-27</td>
                  <td>24-09-28</td>
                  <td>24-09-29</td>
                  <td>24-09-30</td>
                  <td>24-10-01</td>
                  <td>24-10-02</td>
                  <td>24-10-03</td>
                  <td>24-10-04</td>
                  <td>24-10-05</td>
                  <td>24-10-06</td>
                  <td>24-10-07</td>
                  <td>24-10-08</td>
                  <td>24-10-09</td>
                  <td>24-10-10</td>
                  <td>24-10-11</td>
                  <td>24-10-12</td>
                  <td>24-10-13</td>
                  <td>24-10-14</td>
                  <td>24-10-15</td>
                  <td>24-10-16</td>
                  <td>24-10-17</td>
                  <td>24-10-18</td>
                  <td>24-10-19</td>
                  <td>24-10-20</td>
                  <td>24-10-21</td>
                  <td>24-10-22</td>
                  <td>24-10-23</td>
                  <td>24-10-24</td>
                  <td>24-10-25</td>
                  <td>24-10-26</td>
                  <td>24-10-27</td>
                  <td>24-10-28</td>
                  <td>24-10-29</td>
                  <td>24-10-30</td>
                  <td>24-10-31</td>
                  <td>24-11-01</td>
                  <td>24-11-02</td>
                  <td>24-11-03</td>
                  <td>24-11-04</td>
                  <td>24-11-05</td>
                  <td>24-11-06</td>
                  <td>24-11-07</td>
                  <td>24-11-08</td>
                  <td>24-11-09</td>
                  <td>24-11-10</td>
                  <td>24-11-11</td>
                  <td>24-11-12</td>
                  <td>24-11-13</td>
                  <td>24-11-14</td>
                  <td>24-11-15</td>
                  <td>24-11-16</td>
                  <td>24-11-17</td>
                  <td>24-11-18</td>
                  <td>24-11-19</td>
                  <td>24-11-20</td>
                  <td>24-11-21</td>
                  <td>24-11-22</td>
                  <td>24-11-23</td>
                  <td>24-11-24</td>
                  <td>24-11-25</td>
                  <td>24-11-26</td>
                  <td>24-11-27</td>
                  <td>24-11-28</td>
                  <td>24-11-29</td>
                  <td>24-11-30</td>
                  <td>24-12-01</td>
                  <td>24-12-02</td>
                  <td>24-12-03</td>
                  <td>24-12-04</td>
                  <td>24-12-05</td>
                  <td>24-12-06</td>
                  <td>24-12-07</td>
                  <td>24-12-08</td>
                  <td>24-12-09</td>
                  <td>24-12-10</td>
                  <td>24-12-11</td>
                  <td>24-12-12</td>
                  <td>24-12-13</td>
                  <td>24-12-14</td>
                  <td>24-12-15</td>
                  <td>24-12-16</td>
                  <td>24-12-17</td>
                  <td>24-12-18</td>
                  <td>24-12-19</td>
                  <td>24-12-20</td>
                  <td>24-12-21</td>
                  <td>24-12-22</td>
                  <td>24-12-23</td>
                  <td>24-12-24</td>
                  <td>24-12-25</td>
                  <td>24-12-26</td>
                  <td>24-12-27</td>
                  <td>24-12-28</td>
                  <td>24-12-29</td>
                  <td>24-12-30</td>
                  <td>24-12-31</td>
                  <td>25-01-01</td>
                  <td>25-01-02</td>
                  <td>25-01-03</td>
                  <td>25-01-04</td>
                  <td>25-01-05</td>
                  <td>25-01-06</td>
                  <td>25-01-07</td>
                  <td>25-01-08</td>
                  <td>25-01-09</td>
                  <td>25-01-10</td>
                  <td>25-01-11</td>
                  <td>25-01-12</td>
                  <td>25-01-13</td>
                  <td>25-01-14</td>
                  <td>25-01-15</td>
                  <td>25-01-16</td>
                  <td>25-01-17</td>
                  <td>25-01-18</td>
                  <td>25-01-19</td>
                  <td>25-01-20</td>
                  <td>25-01-21</td>
                  <td>25-01-22</td>
                  <td>25-01-23</td>
                  <td>25-01-24</td>
                  <td>25-01-25</td>
                  <td>25-01-26</td>
                  <td>25-01-27</td>
                  <td>25-01-28</td>
                  <td>25-01-29</td>
                  <td>25-01-30</td>
                  <td>25-01-31</td>
                  <td>25-02-01</td>
                  <td>25-02-02</td>
                  <td>25-02-03</td>
                  <td>25-02-04</td>
                  <td>25-02-05</td>
                  <td>25-02-06</td>
                  <td>25-02-07</td>
                </tr>
                <tr style={{ fontSize: "12px" }}>
                  <td style={{ fontWeight: "bold",color:"#414141" }}>WIPLimit</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
                <tr style={{ fontSize: "12px" }}>
                  <td style={{ fontWeight: "bold",color:"#414141" }}>WeekNumber</td>
                  <td>38(0)(0)</td>
                  <td>39(0)(0)</td>
                  <td>39(0)(0)</td>
                  <td>39(0)(0)</td>
                  <td>39(0)(0)</td>
                  <td>39(0)(0)</td>
                  <td>39(0)(0)</td>
                  <td>39(0)(0)</td>
                  <td>40(0)(0)</td>
                  <td>40(0)(0)</td>
                  <td>40(0)(0)</td>
                  <td>40(0)(0)</td>
                  <td>40(0)(0)</td>
                  <td>40(0)(0)</td>
                  <td>40(0)(0)</td>
                  <td>41(0)(0)</td>
                  <td>41(0)(0)</td>
                  <td>41(0)(0)</td>
                  <td>41(0)(0)</td>
                  <td>41(0)(0)</td>
                  <td>41(0)(0)</td>
                  <td>41(0)(0)</td>
                  <td>42(0)(0)</td>
                  <td>42(0)(0)</td>
                  <td>42(0)(0)</td>
                  <td>42(0)(0)</td>
                  <td>42(0)(0)</td>
                  <td>42(0)(0)</td>
                  <td>42(0)(0)</td>
                  <td>43(0)(0)</td>
                  <td>43(0)(0)</td>
                  <td>43(0)(0)</td>
                  <td>43(0)(0)</td>
                  <td>43(0)(0)</td>
                  <td>43(0)(0)</td>
                  <td>43(0)(0)</td>
                  <td>44(0)(0)</td>
                  <td>44(0)(0)</td>
                  <td>44(0)(0)</td>
                  <td>44(0)(0)</td>
                  <td>44(0)(0)</td>
                  <td>44(0)(0)</td>
                  <td>44(0)(0)</td>
                  <td>45(0)(0)</td>
                  <td>45(0)(0)</td>
                  <td>45(0)(0)</td>
                  <td>45(0)(0)</td>
                  <td>45(0)(0)</td>
                  <td>45(0)(0)</td>
                  <td>45(0)(0)</td>
                  <td>46(0)(0)</td>
                  <td>46(0)(0)</td>
                  <td>46(0)(0)</td>
                  <td>46(0)(0)</td>
                  <td>46(0)(0)</td>
                  <td>46(0)(0)</td>
                  <td>46(0)(0)</td>
                  <td>47(0)(0)</td>
                  <td>47(0)(0)</td>
                  <td>47(0)(0)</td>
                  <td>47(0)(0)</td>
                  <td>47(0)(0)</td>
                  <td>47(0)(0)</td>
                  <td>47(0)(0)</td>
                  <td>48(0)(0)</td>
                  <td>48(0)(0)</td>
                  <td>48(0)(0)</td>
                  <td>48(0)(0)</td>
                  <td>48(0)(0)</td>
                  <td>48(0)(0)</td>
                  <td>48(0)(0)</td>
                  <td>49(0)(0)</td>
                  <td>49(0)(0)</td>
                  <td>49(0)(0)</td>
                  <td>49(0)(0)</td>
                  <td>49(0)(0)</td>
                  <td>49(0)(0)</td>
                  <td>49(0)(0)</td>
                  <td>50(0)(0)</td>
                  <td>50(0)(0)</td>
                  <td>50(0)(0)</td>
                  <td>50(0)(0)</td>
                  <td>50(0)(0)</td>
                  <td>50(0)(0)</td>
                  <td>50(0)(0)</td>
                  <td>51(0)(0)</td>
                  <td>51(0)(0)</td>
                  <td>51(0)(0)</td>
                  <td>51(0)(0)</td>
                  <td>51(0)(0)</td>
                  <td>51(0)(0)</td>
                  <td>51(0)(0)</td>
                  <td>52(0)(0)</td>
                  <td>52(0)(0)</td>
                  <td>52(0)(0)</td>
                  <td>52(0)(0)</td>
                  <td>52(0)(0)</td>
                  <td>52(0)(0)</td>
                  <td>52(0)(0)</td>
                  <td>53(0)(0)</td>
                  <td>53(0)(0)</td>
                  <td>53(0)(0)</td>
                  <td>1(0)(0)</td>
                  <td>1(0)(0)</td>
                  <td>1(0)(0)</td>
                  <td>1(0)(0)</td>
                  <td>2(0)(0)</td>
                  <td>2(0)(0)</td>
                  <td>2(0)(0)</td>
                  <td>2(0)(0)</td>
                  <td>2(0)(0)</td>
                  <td>2(0)(0)</td>
                  <td>2(0)(0)</td>
                  <td>3(0)(0)</td>
                  <td>3(0)(0)</td>
                  <td>3(0)(0)</td>
                  <td>3(0)(0)</td>
                  <td>3(0)(0)</td>
                  <td>3(0)(0)</td>
                  <td>3(0)(0)</td>
                  <td>4(0)(0)</td>
                  <td>4(0)(0)</td>
                  <td>4(0)(0)</td>
                  <td>4(0)(0)</td>
                  <td>4(0)(0)</td>
                  <td>4(0)(0)</td>
                  <td>4(0)(0)</td>
                  <td>5(0)(0)</td>
                  <td>5(0)(0)</td>
                  <td>5(0)(0)</td>
                  <td>5(0)(0)</td>
                  <td>5(0)(0)</td>
                  <td>5(0)(0)</td>
                  <td>5(0)(0)</td>
                  <td>6(0)(0)</td>
                  <td>6(0)(0)</td>
                  <td>6(0)(0)</td>
                  <td>6(0)(0)</td>
                  <td>6(0)(0)</td>
                  <td>6(0)(0)</td>
                </tr>
                <tr style={{ fontSize: "12px" }}>
                  <td style={{ fontWeight: "bold",color:"#414141" }}>Weekcount</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };
  const handleclose = () => {
    navigate("/ccpm/myprojects");
  };
  return (
    <>
      <Stack
        direction="column"
        sx={{ padding: "0.1% 4%", overflowX: "hidden" }}
        spacing={3}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "8px", backgroundColor: "#A4BF47" }}
        >
          <Typography className={modules["stagText"]}>
            {" "}
            Staggering Without Resource{" "}
          </Typography>
        </Stack>
        {projectDetailsTableUI()}
        <Stack alignItems="center" direction="row" spacing={3}>
          <Typography className={modules["stagTextNormal"]}>
            Show data for how many Weeks in future :
          </Typography>
          <input
            type="text"
            value={weekShowInp}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[0-9]*$/;
              if (regex.test(value)) {
                setWeekShowInp(parseInt(value));
              }
            }}
            // maxlength="3"
            // onchange="return OnPasteKeycheck(this.value,this);"
            // allowcharacters="0-9,."
            // onkeypress="return userKeyPress(this,event)"
            className={modules["inputSubTasksVol3"]}
            style={{ width: "30px", paddingLeft: "3px" }}
          />
          <Stack direction="row" alignItems="center">
            <button className={modules["btnSettingsPop"]}>Show</button>
            <button
              style={{ marginLeft: "10px" }}
              className={modules["btnSettingsPop"]}
            >
              Reset
            </button>
          </Stack>
        </Stack>
        <Box sx={{ p: 2 }}>
          <Stack
            direction="row"
            spacing={1} // Adjust spacing between fields
            gap={18}
            alignItems="center"
          >
            <Box
              sx={{
                border: "1px solid #ccc", // Border for the whole container
                padding: 1, // Padding inside the box
                borderRadius: "4px", // Rounded corners
                width: "200px", // Width of the box
                margin: "auto", // Center the box horizontally
                mt: 0.2, // Margin from the top
              }}
            >
              <Stack spacing={1}>
                {/* Total WIP Limit */}
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontFamily: "sans-serif",
                      color:"#414141"
                    }}
                  >
                    Total WIP Limit
                  </Typography>
                  <Typography
                    sx={{ fontSize: "12px", fontFamily: "sans-serif", color:"#414141"}}
                  >
                    123444
                  </Typography>
                </Box>

                {/* Project Limit Per Week */}
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontFamily: "sans-serif",
                      color:"#414141"
                    }}
                  >
                    Project Limit Per Week
                  </Typography>
                  <Typography
                    sx={{ fontSize: "12px", fontFamily: "sans-serif", color:"#414141" }}
                  >
                    34
                  </Typography>
                </Box>
              </Stack>
            </Box>

            {recommendedStartAndFinshDate.map((item, index) => (
              <React.Fragment key={index}>
                <Box sx={{marginTop:"50px"}}>
                  <Stack spacing={3}>
                    {/* Available Start Date */}
                    <Box display="flex" justifyContent="space-between" gap={2}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "sans-serif",
                           color:"#414141"
                        }}
                      >
                        Available Start Date:
                      </Typography>
                      <input
                        type="text"
                        value={item.RecommendedStartDate}
                        // value= {
                        //   item.availableStartDate
                        //  ? new Date(item.availableStartDate)
                        //  .toLocaleDateString("en-GB")
                        //    .replace(/\//g, "-") // Convert to dd-mm-yyyy format
                        //     : ""
                        //}
                         className={`${modules["input-field"]}`} 
                        readOnly="readonly"
                        style={{ backgroundColor: "LightGrey", width: "120px" }}
                      />
                    </Box>
                  </Stack>
                </Box>

                <Box>
                  <Stack spacing={3}>
                    {/* Projected Finish Date */}
                    <Box display="flex" justifyContent="space-between" gap={2}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "sans-serif",
                           color:"#414141"
                        }}
                      >
                        Projected Finish Date:
                      </Typography>
                      <input
                        type="text"
                        value={item.ProjectedFinishDate}
                        // value= {
                        //   item.ProjectedFinishDate
                        //  ? new Date(item.ProjectedFinishDate)
                        //  .toLocaleDateString("en-GB")
                        //    .replace(/\//g, "-") // Convert to dd-mm-yyyy format
                        //     : ""
                        //   }
                         className={`${modules["input-fieldRMP"]} input-fieldRMP`} 
                        readOnly="readonly"
                        style={{ backgroundColor: "LightGrey", width: "120px" }}
                      />
                    </Box>
                  </Stack>
                </Box>
              </React.Fragment>
            ))}
          </Stack>
        </Box>
        {TableUi()}
        <Stack
          sx={{
            border: "1px solid #ccc", // Border style
            padding: "16px", // Padding inside the border
            borderRadius: "8px", // Rounded corners
            width: "100%", // Adjusts to the content width
            margin: "20px auto", // Centers the component and adds space
          }}
        >
          <Typography className={modules["stagTextNormal"]} gutterBottom>
            Simulation
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup row  value={value} onChange={handleChange} sx={{
      display: 'block', // Change the display type here
    }}>
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    sx={{
                      transform: "scale(0.8)",
                      color: "#414141",
                      margin: "3px 3px 0px 5px", // margin applied
                    }}
                  />
                }
                label="Simulated Start Date"
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "12px",
                    color: "#414141",
                    fontFamily: "WorkSans",
                  },
                }}
              />
              <FormControlLabel
                value="2"
                control={
                  <Radio
                    sx={{
                      transform: "scale(0.8)",
                      color: "#414141",
                      margin: "3px 3px 0px 5px", // margin applied
                    }}
                  />
                }
                label="Simulated Finish Date"
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "12px",
                    color: "#414141",
                    fontFamily: "WorkSans",
                  },
                }}
              />
              <FormControlLabel
                value="3"
                control={
                  <Radio
                    sx={{
                      transform: "scale(0.8)",
                      color: "#414141",
                      margin: "3px 3px 0px 5px", // margin applied
                    }}
                  />
                }
                label="Both"
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "12px",
                    color: "#414141",
                    fontFamily: "WorkSans",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>

          <Stack
            alignItems="center"
            direction="row"
            spacing={3}
            sx={{ marginTop: "16px" }}
          >
            {value === "1" ? (
              <>
                <Typography className={modules["stagTextNormal"]}>
                  Simulation start date:
                </Typography>
                <input
                  style={{ width: "100px" }}
                  maxLength="20"
                  value={
                    simulateInputDate
                      ? new Date(simulateInputDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-") // Convert to dd-mm-yyyy format
                      : ""
                  }
                  onChange={(e) => setSimulateInputDate(e.target.value)}
                  className={`${modules["input-fieldRMP"]} input-fieldRMP`} 
                  //  onClick={toggleCalendarOne}
                  onClick={(event) => setAnchorEl1(event.currentTarget)}
                />
                <Popover
                  open={open1}
                  anchorEl={anchorEl1}
                  onClose={() => setAnchorEl1(null)}
                  placement="bottom-start"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Calendar
                    onChange={handleCalendar1n}
                    value={upLikelyDate}
                    locale="en-US"
                    formatShortWeekday={formatShortWeekday}
                  />
                  <div className={modules["calendar-footer"]}>
                    {/* Today: {formattedToday} */}
                  </div>
                </Popover>
              </>
            ) : null}
            {value === "2" ? (
              <>
                <Typography className={modules["stagTextNormal"]}>
                  Simulation Finish date:
                </Typography>
                <input
                  style={{ width: "100px" }}
                  maxLength="20"
                  value={
                    simulateFinishDate
                      ? new Date(simulateFinishDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-") // Convert to dd-mm-yyyy format
                      : ""
                  }
                  onChange={(e) => setSimulateFinishDate(e.target.value)}
                   className={`${modules["input-fieldRMP"]} input-fieldRMP`} 
                  //  onClick={toggleCalendarOne}
                  onClick={(event) => setAnchorEl2(event.currentTarget)}
                />
                <Popover
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={() => setAnchorEl2(null)}
                  placement="bottom-start"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Calendar
                    onChange={handleCalendar2n}
                    value={upLikelyDate2}
                    locale="en-US"
                    formatShortWeekday={formatShortWeekday}
                  />
                  <div className={modules["calendar-footer"]}>
                    {/* Today: {formattedToday} */}
                  </div>
                </Popover>
              </>
            ) : null}
            {value === "3" ? (
              <>
                <Typography className={modules["stagTextNormal"]}>
                  Simulation start date:
                </Typography>
                <input
                  style={{ width: "100px" }}
                  maxLength="20"
                  value={
                    simulateInputDate
                      ? new Date(simulateInputDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-") // Convert to dd-mm-yyyy format
                      : ""
                  }
                  onChange={(e) => setSimulateInputDate(e.target.value)}
                  className={`${modules["input-fieldRMP"]} input-fieldRMP`} 
                  //  onClick={toggleCalendarOne}
                  onClick={(event) => setAnchorEl1(event.currentTarget)}
                />
                <Popover
                  open={open1}
                  anchorEl={anchorEl1}
                  onClose={() => setAnchorEl1(null)}
                  placement="bottom-start"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Calendar
                    onChange={handleCalendar1n}
                    value={upLikelyDate}
                    locale="en-US"
                    formatShortWeekday={formatShortWeekday}
                  />
                  <div className={modules["calendar-footer"]}>
                    {/* Today: {formattedToday} */}
                  </div>
                </Popover>
                <Typography className={modules["stagTextNormal"]}>
                  Simulation Finish date:
                </Typography>
                <input
                  style={{ width: "100px" }}
                  maxLength="20"
                  value={
                    simulateFinishDate
                      ? new Date(simulateFinishDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-") // Convert to dd-mm-yyyy format
                      : ""
                  }
                  onChange={(e) => setSimulateFinishDate(e.target.value)}
                  className={`${modules["input-fieldRMP"]} input-fieldRMP`} 
                  //  onClick={toggleCalendarOne}
                  onClick={(event) => setAnchorEl2(event.currentTarget)}
                />
                <Popover
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={() => setAnchorEl2(null)}
                  placement="bottom-start"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Calendar
                    onChange={handleCalendar2n}
                    value={upLikelyDate2}
                    locale="en-US"
                    formatShortWeekday={formatShortWeekday}
                  />
                  <div className={modules["calendar-footer"]}>
                    {/* Today: {formattedToday} */}
                  </div>
                </Popover>
              </>
            ) : null}
            <Stack direction="row" alignItems="center">
              <button
                className={modules["btnSettingsPop"]}
                onClick={handleSimulate}
              >
                Simulate
              </button>
              <button
                style={{ marginLeft: "10px" }}
                className={modules["btnSettingsPop"]}
                onClick={handleReset2}
              >
                Reset
              </button>
            </Stack>
          </Stack>
        </Stack>

        {showSimulateUi ? (
          simulateUi()
        ) : (
          <Stack>
            <button
              style={{ marginLeft: "10px" }}
              className={modules["btnSettingsPop"]}
              onClick={handleclose}
            >
              Close
            </button>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default Staggering;
