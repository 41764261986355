export const API_KEY =
  // "http://gnb-poc-prostream-backend-lb-712406000.ap-south-1.elb.amazonaws.com/api";
  "https://gtocuatcloudapi.godrejenterprises.com/api";
// export const API_KEY='https://prostreamapi.azurewebsites.net/api';

export const API_KEY1 = "prostreamapi.azurewebsites.net/swagger/index.html/api";

//MenuBar

export const MenuBar = "/Admin/MenuBar/GetMenuOrder";

//Change Password

export const ChangePassWord = "/Admin/ChangePassword/Update";

//Role Master
export const GetAllRoles = "/RoleMaster/RoleMaster/GetDropDown";

export const GetDetails = "/RoleMaster/RoleMaster/GetRoleListByRoleName";

export const InsertRole = "/RoleMaster/RoleMaster/lnkSave_Click"; //RoleName=test123&CreatedBy=1

export const UpdateRole = "/RoleMaster/RoleMaster/UpdategvRoleDetails"; //Id=12&RoleName=testing123&LastModifiedBy=1

export const DeleteRole = "/RoleMaster/RoleMaster/DeletegvRoleDetails"; //Id=13&LastModifiedBy=1

export const DeleteRoles = "/RoleMaster/RoleMaster/BulkDelete";

//user master

//DSS Instance Attribute Linking

export const GetUserInstance =
  "/DSSAdmin/DSS_UserInstanceLinking/GetUserInstance?userID=";

export const SaveUserInstance =
  "/DSSAdmin/DSS_UserInstanceLinking/SaveUserInstance";

//DSS Insert Instance
export const InsertInstance = "/DSSAdmin/DSS_Instance/InsertInstance?Instance="; //sample&desc=sampledesc&userID=sankpwc

//DSS Settings
export const GetInstance = "/DSSAdmin/DSS_Settings/GetInstance?userID="; //pritpwc
export const GetSessionName =
  "/DSSAdmin/DSS_Settings/GetSessionName?instanceId="; //Locks&isAdmin=Y
export const GetParameterName =
  "/DSSAdmin/DSS_Settings/GetParameterName?instanceId="; //Locks&sessionName=BaaN-Price-List-File-Upload&isAdmin=Y
export const GetValue = "/DSSAdmin/DSS_Settings/GetValue?instanceId="; //Locks&sessionName=BaaN-Price-List-File-Upload&paramName=Extraction-File-Path&isAdmin=Y
export const DeleteSetting = "/DSSAdmin/DSS_Settings/Delete";
export const UpdateSetting = "/DSSAdmin/DSS_Settings/Update";

//login

export const Login = "/Account/Account/Signin";

//

export const GetAllInstance =
  "/DSSAdmin/DSS_UserInstanceActive_InActive/GetAllInstance?UserId=sankpwc";

export const UpdateAllInstances =
  "/DSSAdmin/DSS_UserInstanceActive_InActive/UpdateAllInstance?UserId=sankpwc&state=";

export const UpdateOneInstance =
  "/DSSAdmin/DSS_UserInstanceActive_InActive/UpdateInstance?UserId=sankpwc&InstanceId=";

//Role Permission

export const GetDropDownRoles = "/Admin/RolePermission/GetRoleNames";

export const GetHomePage = "/Admin/RolePermission/GetHomePage";

export const GetValuesForRoles = `/Admin/RolePermission/GetMainMenus`;

export const GetSubmenuList = `/Admin/RolePermission/GetMainMenus`;

export const GetSubmenuList1 = `/Admin/RolePermission/GetgvRolePermissions`;

export const GetSubmenuList2 = `/Admin/RolePermission/gvSubMenu_RowDataBound`;
export const GetSaveApi = `/Admin/RolePermission/InsertSave_Click`;

export const ExportToExcel = "/Admin/RolePermission/exporttoexcel_Click";

// export const InsertRolePermissions=`/Admin/RolePermission/InsertRolePermissions?MenuId=33&RoleId=72&HomePage=1&IsViewRights=1&UserID=12`;

// export const UpdateRolePermissions=`/Admin/RolePermission/UpdateRolePermissions?MenuId=33&RoleId=72&HomePage=1&IsViewRights=1&UserID=12`;

export const GetParentSubList = "/Admin/RolePermission/GetSubMenuList";

export const GetPrivilageFunction =
  "/Admin/RolePermission/GetRoleSessionPrivilegeMapping";

// export const UpdateRoleAndSession='/Admin/RolePermission/CombinedSaveRoleSessionPrivileges';

//parent role permission

export const PR_GetMenuList =
  "/Admin/ParentRolePermission/GetParentMenuListForParent";

export const PR_GetSubMenuList =
  "/Admin/Parentrolepermission/GetgvRolePermissions";

export const PR_GetSessionPrivilage =
  "/Admin/Parentrolepermission/GetRoleSessionPrivilegeMappingForParent";

export const PR_GetRoleList = "/Admin/RolePermission/GetPopUpList";

export const PR_AddRole =
  "/Admin/ParentRolePermission/InsertParentRolePermissions";

//Assign Parent Roles

export const APR_GetRoleNames = "/Admin/AssignParentRoles/GetRoleNames";

export const APR_GetRoleRoleandParentRoles =
  "/Admin/AssignParentRoles/GetRoleRoleandParentRoles";

export const APR_SaveRole = "/Admin/AssignParentRoles/SaveRoleandParentRoles"; //18&ListRoleIds=62%2C63&UserId=1

//DSS Master

export const DS_GetInstances = "/DSSAdmin/Common/GetInstanceUID";

export const DS_GetDataSourceType = "/DSSAdmin/DSS_DataSource/DataSourceType";

export const DS_GetSourceName = "/DSSAdmin/DSS_DataSource/GetDataSourceName";

export const DS_SubmitClick = "/DSSAdmin/DSS_DataSource/Submit_Click";

export const DS_UdpateOnClick = "/DSSAdmin/DSS_DataSource/Update_OnClick";

export const DS_AddNewRowDropdown =
  "/DSSAdmin/DSS_DataSource/btAddFileGridRow_click";

export const DS_InsertClick = "/DSSAdmin/DSS_DataSource/lbFileInsert_Click";

export const DS_UpdateRow = "/DSSAdmin/DSS_DataSource/btnUpdate";

//Hierachies

export const Hierachies_GetInstanceUID =
  "/DSSAdmin/Common/GetInstanceUID?UserId="; //pritpwc

export const Hierachies_GetMasterTables =
  "/DSSAdmin/DSS_Hierarchies/GetMaster?instanceUId="; //Lockss_LN

export const Hierachies_GetOtherMasterTables =
  "/DSSAdmin/DSS_Hierarchies/GetOtherMaster?instanceUId="; //Lockss_LN

export const Hierachies_GetAttribute =
  "/DSSAdmin/DSS_Hierarchies/GetAttribute?instanceUId="; //Lockss_LN&SelectMaster=Master&tableName=DSS_t_TVC_master

export const Hierachies_SubmitClick =
  "/DSSAdmin/DSS_Hierarchies/Submit_Click?instanceUId="; //Lockss_LN&SelectMaster=Master&TableName=DSS_t_TVC_master&attribute=marketingOHRate

export const Hierachies_ShowMasterTablesOnRowDataBound =
  "/DSSAdmin/DSS_Hierarchies/gvwShowMasterTables_OnRowDataBound?instanceUId="; //Lockss_LN&SelectMaster=Master&TableName=DSS_t_TVC_master

export const Hierachies_RowUpdating =
  "/DSSAdmin/DSS_Hierarchies/gvwShowMasterTables_RowUpdating";

// {
//     "instanceId": "Lockss_LN",
//     "recordId": "b3485846-bb03-e711-80d0-005056b277a7",
//     "masterTableName": "DSS_t_TVC_master",
//     "attribute": "marketingOHRate",
//     "userId": "pritpwc",
//     "tableName": "DSS_t_salesman_master",
//     "attributeColumnName1": "BaaNCompany",
//     "attributeColumnName2": "",
//     "attributeColumnName3": "",
//     "attributeColumnName4": "",
//     "attributeColumnName5": "",
//     "attributeColumnName6": "",
//     "attributeColumnName7": "",
//     "attributeColumnName8": "",
//     "attributeColumnName9": "",
//     "hostName": "string",
//     "ipAddress": "string"
//   }

export const Hierachies_RowDeletion = "/DSSAdmin/DSS_Hierarchies/RowDeleting";
// {
//     "instanceuid": "Lockss_LN",
//     "record_id": "b3485846-bb03-e711-80d0-005056b277a1",
//     "tableName": "DSS_t_salesman_master",
//     "sourceName": "DSS_t_TVvC_master",
//     "linkto": "string",
//     "sourceCol": "string"
//   }

//DSS Master Definition

export const MD_GetMaster = "/DSSAdmin/DSS_MasterDefination/GetMaster";

export const MD_GetOtherMaster =
  "/DSSAdmin/DSS_MasterDefination/GetOtherMaster";

export const MD_GetTypeofAtt = "/DSSAdmin/DSS_MasterDefination/GetTypeofAttr";

export const MD_SubmitClick = "/DSSAdmin/DSS_MasterDefination/btnSubmit_Click";

export const MD_UpdateMasterName =
  "/DSSAdmin/DSS_MasterDefination/MasterTableUpdating";

export const MD_GetDropdownValues =
  "/DSSAdmin/DSS_MasterDefination/DSS_MasterDefination_MasterTables_RowEditing";

export const MD_RowUpdating =
  "/DSSAdmin/DSS_MasterDefination/DSS_MasterDefination_MasterTables_RowUpdating";

//DSS Master Linking

export const ML_GetSource =
  "/DSSAdmin/DSS_MasterLinking/GetSource?instanceUId="; //Lockss_LN

export const ML_GetLinkTo =
  "/DSSAdmin/DSS_MasterLinking/GetLinkto?instanceUId="; //Lockss_LN&sourceName=DSS_t_in

export const ML_GetSourceColumn =
  "/DSSAdmin/DSS_MasterLinking/GetSourcecolumn?instanceUId="; //Lockss_LN&linkTo=DSS_t_invoice_data

export const ML_SubmitClick =
  "/DSSAdmin/DSS_MasterLinking/Submit_Click?instanceUId="; //Lockss_LN&sourceName=DSS_t_profitability_report&Linkto=DSS_t_COGS_ratio_master&sourceCol=transactionType

export const ML_EditClick = "/DSSAdmin/DSS_MasterLinking/Edit_Click";

// {
//     "instanceUId": "Lockss_LN",
//     "sourceName": "DSS_t_profitability_report",
//     "linkto": "DSS_t_COGS_ratio_master",
//     "sourceCol": "mktDistExpFreightPerc"
//   }

export const ML_UpdateClick = "/DSSAdmin/DSS_MasterLinking/Update_Click";

// {
//     "instanceUID": "Lockss_LN",
//     "recordId": "",
//     "sourceName": "DSS_t_profitability_report",
//     "sourceColName": "mktDistExpFreightPerc",
//     "linkingoperator": "<",
//     "linktoName": "DSS_t_COGS_ratio_master",
//     "linkedtoMasterColumn": "retroConsmStoresSparesPerc",
//     "userId": "pritpwc",
//     "hostName": "Host",
//     "ipAddress": "IP"
//   }

//DSS MAUC Process Report

export const PMTR_ProcessReport =
  "/DSSMAUC/DSS_Process_MAUC_Tracker_Report/btnSubmit_Click?instanceUId="; //Lockss_LN&UserID=pritpwc

//DSS MAUC Report Tracker

export const MTR_GetDropdowns =
  "/DSSMAUC/DSS_MAUC_Tracker_Report/BindDropDowns?instanceUId="; //Lockss_LN&UserID=pritpwc

export const MTR_SubmitReportTrack =
  "/DSSMAUC/DSS_MAUC_Tracker_Report/btnSubmit_Click?instanceUId="; //Lockss_LN&userID=pritpwc&MaterialCategory=Traded%20Finished%20Goods&SubSource=COMMON&CostCategory=Material&ReportType=Only%20Stock%20Values&DataFrequency=Weekly&FileType=XLS&Report=-1&DateFrom=2022-02-21&DateTo=2024-03-21

//MAUC

export let MCW_GetThreholdBindGrid =
  "/DSSMAUC/DSS_Material_Category_wise_Threshold/ThresholdBindGrid?instanceUId=";

export let MCW_OnRowUpdatingThreshold =
  "/DSSMAUC/DSS_Material_Category_wise_Threshold/OnRowUpdating_Threshold";

export let MCW_OnRowDeletionThreshold =
  "/DSSMAUC/DSS_Material_Category_wise_Threshold/OnRowDeleting_Threshold";

//DSS Throughput
//Calculate Throughput

export let DSS_CalculateThroughput =
  "/DSSThroughput/DSS_CalculateThroughPut/btnSubmit_Click?instanceUId="; //Lockss_LN&userID=pritpwc&baanCompany=1&FromDate=2022%2F01%2F01&ToDate=2022%2F01%2F02

// TRP Loss maintain factor

export let TMF_GetTRDLostFactor =
  "/DSSTRDLoss/DSS_MaintainTRDLossFactors/GetTRDLostFactor";

export let TMF_BtnSaveClick =
  "/DSSTRDLoss/DSS_MaintainTRDLossFactors/btnSave_Click";

export let TMF_BtnUpdateClick =
  "/DSSTRDLoss/DSS_MaintainTRDLossFactors/btnUpdate_Click";

export let TMF_BtnDeleteClick =
  "/DSSTRDLoss/DSS_MaintainTRDLossFactors/btnDelete_Click";

//Calculate TRD Loss Summary

export let CTLS_GetYear = "/DSSReports/DSS_ReportTRDLossSummary/FinancialYear";

export let CTLS_GetTocWeek =
  "/DSSReports/DSS_ReportTRDLossSummary/TocWeek?instanceUId="; //Lockss_LN&Finance_yr=2018-2019

export let CTLS_CalculateClick =
  "/DSSTRDLoss/CalculateTRDLossSummary/btnCalculate_Click?instanceUId="; //Lockss_LN&userID=pritpwc&FinanceYr=2018-2019&TocWeek=40

//Dss Reports TRD Loss Summary

export let DRTLS_CalculateClick =
  "/DSSReports/DSS_ReportTRDLossSummary/Calculate_Click";

export let DRTLS_GetFinancialYear =
  "/DSSReports/DSS_ReportTRDLossSummary/FinancialYear";

export let DRTLS_GetTocWeek = "/DSSReports/DSS_ReportTRDLossSummary/TocWeek";

//MTA Parameter

//New Instance

export let MTANewInstance_CreateNewInstance =
  "/MTAParameters/MTA_NewInstance/Submit?instance="; //test&UserId=pritpwc

//MTA Active InActive

export let AI_GetBindInstances =
  "/MTAParameters/MTA_User_InstanceActiveInactive/BindInstanceUIDALL?UserId=";

export let AI_ChangedClick =
  "/MTAParameters/MTA_User_InstanceActiveInactive/ChangedClick";

export let AI_ChangedAllClick =
  "/MTAParameters/MTA_User_InstanceActiveInactive/CheckedAll";

//CCPM -> Master -> Instance Master
export const GetCName = "/CCPMMaster/HierarchyMaster/GetCompanyName";
export const IM_Update =
  "/CCPMMaster/HierarchyMaster/DivisionMaster_RowUpdating";
export const IM_RowDelete =
  "/CCPMMaster/HierarchyMaster/DivisionMaster_RowDeleting";
export const IM_Rowsave = "/CCPMMaster/HierarchyMaster/Save_Click";
export const IM_RowSearch = "/CCPMMaster/HierarchyMaster/BindGrid";

export const IAL_BindInstance =
  "/CCPMMaster/InstanceAttributeLinking/BindInstance";

export const IAL_AttributeHierarchyGrid =
  "/CCPMMaster/InstanceAttributeLinking/BindInstanceAttributeHierarchyGrid";

export const IAL_UpdateInstanceAttributeHierarchy =
  "/CCPMMaster/InstanceAttributeLinking/UpdateInstanceAttributeHierarchy";

export const IAL_InsertInstanceWiseAttributeHierarchy =
  "/CCPMMaster/InstanceAttributeLinking/InsertInstanceWiseAttributeHierarchy";

// CCPM -> Master -> ActiveProjects

export let getActiveProjectsProjectName =
  "/CCPMActiveProject/ActiveProject/BindProjectNames";
export let getActiveProjectsProjectManagers =
  "/CCPMActiveProject/ActiveProject/BindProjectManagers";
//export let getAttributesBasedOnInstancceSearch='/CCPMProjectPlan/MyProjects/GetUserInstanceAttributesForSearchFilter';

export let searchActiveProject =
  "/CCPMActiveProject/ActiveProject/btnSearch_Click";
export let ActiveProjectstatus =
  "/CCPMActiveProject/ActiveProject/GetProjectStatus";

export let ActiveProjectstatusChange =
  "/CCPMActiveProject/ActiveProject/GetActiveProjStatusChgReason";
export let ActiveSaveImgClick =
  "/CCPMActiveProject/ActiveProject/imgSave_Click";

// CCPM -> Master -> buffer recovery
export let searchBufferRecovery =
  "/CCPMActiveProject/BufferRecoveryPlan/btn_sumit";
export let insertBufferRecovery =
  "/CCPMActiveProject/BufferRecoveryPlan/gvBufferRecoveryInsert";
export let updateBufferRecovery =
  "/CCPMActiveProject/BufferRecoveryPlan/gvBufferRecoveryOnUpdate";

export let ganttchartGetTasksbyProjectId =
  "/CCPMProjectPlan/GanttChartPOPUp/GetTasksByProjectId?ProjectId=";
export let loadGanttChart =
  "/CCPMProjectPlan/GanttChartPOPUp/LoadGanntChart?ProjectId=";

//CCPM -> Master -> UNIT master

export let UM_tableData = "/CCPMMaster/UnitMaster/GetUnitsNames";
export let UM_SaveData = "/CCPMMaster/UnitMaster/InsertUnitMaster";
export let UM_UpdateData = "/CCPMMaster/UnitMaster/UpdateUnitMaster";
export let UM_DeleteData = "/CCPMMaster/UnitMaster/DeleteUnitsMaster";
export let UM_BulkDelete = "/CCPMMaster/UnitMaster/DeleteAllUnitsMaster";

//CCPM -> Master -> Issue Category

export let IC_tableData = "/CCPMMaster/IssueCategory/BindGriedView";
export let IC_SaveData =
  "/CCPMMaster/IssueCategory/Save_Click_InsertIssueCatMaster";
export let IC_UpdateData = "/CCPMMaster/IssueCategory/IssueCat_RowUpdating";
export let IC_DeleteData = "/CCPMMaster/IssueCategory/IssueCat_RowDeleting";
export let IC_BulkDelete = "/CCPMMaster/IssueCategory/IssueCategoryBulkDelete";

//CCPM -> Master -> Delay Master

export let DM_getInstance = "/CCPMMaster/DelayMaster/GetLevel2Master";
export let DM_getDelayReasons =
  "/CCPMMaster/Delaymaster/GetInstanceWiseDelayReasons";
export let DM_tableData = "/CCPMMaster/DelayMaster/DelayGetCompanyName";
export let DM_SaveData = "/CCPMMaster/DelayMaster/btnAddNew_Click";
export let DM_UpdateData = "/CCPMMaster/DelayMaster/gvDelayMaster_RowUpdating";
export let DM_DeleteData = "/CCPMMaster/DelayMaster/DeleteDelayReason";
export let DM_BulkDelete = "/CCPMMaster/UnitMaster/DeleteAllUnitsMaster";

//CCPM -> Master -> ActiveProjectStatusChange

export let APCR_getInstance =
  "/CCPMMaster/ActiveProjStatusChgReasonMaster/ActiveProjStatusChgReasonMasterGetLevel2Master";
export let APCR_getChangeReasons =
  "/CCPMMaster/ActiveProjStatusChgReasonMaster/GetInstanceWiseActiveProjStatusChgReasons";
export let APCR_SaveData =
  "/CCPMMaster/ActiveProjStatusChgReasonMaster/InsertActiveProjStatusChgReasonMaster";
export let APCR_UpdateData =
  "/CCPMMaster/ActiveProjStatusChgReasonMaster/UpdateActiveProjStatusChgReason";
export let APCR_DeleteData =
  "/CCPMMaster/ActiveProjStatusChgReasonMaster/DeleteActiveProjStatusChgReason";

//ccpm -> Master -> Fever Chart Master

export let FCM_UpdateData = "/CCPMMaster/FeverChart/UpdateFeverChartMaster";
export let FCM_DeleteData = "/CCPMMaster/FeverChart/DeleteFeverChartMaster";
export let FCM_BulkDelete = "/CCPMMaster/FeverChart/MultipleDelete";
export let FCM_Grid = "/CCPMMaster/FeverChart/MakeGrid";
export let FCM_GetLevelNames = "/CCPMMaster/FeverChart/GetLevelNames";
export let FCM_DependencyApi =
  "/CCPMMaster/FeverChart/GetLevelIDnNameFromProductSettings";
export let FCM_Level3 = "/CCPMMaster/FeverChart/GetLevel3Master";
export let FCM_Level4 = "/CCPMMaster/FeverChart/GetLevel4Master";
export let FCM_Level5 = "/CCPMMaster/FeverChart/GetLevel5Master";
export let FCM_Level6 = "/CCPMMaster/FeverChart/GetLevel6Master";
export let FCM_Level7 = "/CCPMMaster/FeverChart/GetLevel7Master";
export let FCM_Level8 = "/CCPMMaster/FeverChart/GetLevel8Master";
export let FCM_Level9 = "/CCPMMaster/FeverChart/GetLevel9Master";
export let FCM_Level10 = "/CCPMMaster/FeverChart/GetLevel10Master";
export let FCM_Level11 = "/CCPMMaster/FeverChart/GetLevel11Master";

// CCPM -> Master -> Attribute Master

export let InstanceAttribute =
  "/CCPMMaster/InstanceattributeHeirarchy/GetInstanceAttributeHierarchy";
export let InstanceList = "/CCPMMaster/DelayMaster/GetLevel2Master";
export let AttributeValueTableData =
  "/CCPMMaster/InstanceattributeHeirarchy/GetInstanceAttributeWiseAttributeValue";
export let NewAttributeValue =
  "/CCPMMaster/InstanceattributeHeirarchy/InsertInstanceAttributeWiseAttribute";
export let updateAttributeValue =
  "/CCPMMaster/InstanceattributeHeirarchy/UpdateInstance";

// CCPm -> Master -> Buffer Scheduler
export let BufferSchedulerGrid =
  "/CCPMMaster/BufferScheduler/GetBufferSchedulersGrid";
export let BufferRowDelete =
  "/CCPMMaster/BufferScheduler/gvBufferScheduler_RowDeleting";
export let InsertBuffer = "/CCPMMaster/BufferScheduler/InsertScheduler";

// CCPM -> Master -> UserInstanceLinking

export let GetValidActiveUserId =
  "/CCPMMaster/UserInstanceLinking/GetValidActiveUserIdAgainstLoginId";
export let GetInstanceForUser =
  "/CCPMMaster/UserInstanceLinking/GetInstanceForUserMapping";
export let UpdateUserInstanceAccess =
  "/CCPMMaster/UserInstanceLinking/UpdateUserInstanceLinking";
export let SetDefaultInstance =
  "/CCPMMaster/UserInstanceLinking/SetDefaultInstance";
export let GetInstanceWiseAttribute =
  "/CCPMMaster/UserInstanceLinking/GetInstanceWiseAttributeHierarchy";
export let GetAttributeHierarchy =
  "/CCPMMaster/UserInstanceLinking/GetInstanceWiseAttributeHierarchy";
export let GetAttributeHierarchyValue =
  "/CCPMMaster/UserInstanceLinking/GetInstanceAttributeForUserMapping";
export let InsertAttributeValueLinking =
  "/CCPMMaster/UserInstanceLinking/UpdateUserInstanceAttributeLinking";

export let UploadInstance =
  "/CCPMMaster/InstanceDivisionDepartmentLinkingBAL/UploadInstanceDivDept";
export let DownloadInstance =
  "/CCPMMaster/InstanceDivisionDepartmentLinkingBAL/DownloadInstanceDivDept";

export let GetDepartmentDropdown =
  "/CCPMReports/DepartmentLoad/GetInstanceDepartments";

export let GetTaskmanagerDropdown =
  "/CCPMReports/LoadOnTaskManager/GetBindTaskManagerList";

//ProcurementCategory
export let PC_DDL = "/CCPMMaster/ProcurementCategory/GetBindInstance";
export let PC_tableData =
  "/CCPMMaster/ProcurementCategory/GridBindProcurementCategory";
export let PC_CAT_Update =
  "/CCPMMaster/ProcurementCategory/gvCategoryMaster_RowUpdating?InstanceId=";
export let PC_CAT_Delete =
  "/CCPMMaster/ProcurementCategory/gvCategoryMaster_RowDeleting";
export let PC_CAT_Add =
  "/CCPMMaster/ProcurementCategory/btnAddNew_Click?InstanceId=";
export let PC_subTableData = "/CCPMMaster/ProcurementCategory/BindSubCategory";
export let PC_Sub_Delete =
  "/CCPMMaster/ProcurementCategory/gvAddsubCategory_RowDeleting";
export let PC_Sub_Update =
  "/CCPMMaster/ProcurementCategory/gvAddsubCategory_RowUpdating?CategoryID=";
export let PC_Sub_Add =
  "/CCPMMaster/ProcurementCategory/AddSubCategory?CategoryID=";
export let PC_Sub_BulkDelete =
  "/CCPMMaster/ProcurementCategory/BulkSubDeleteData";
export let PC_imgBtnSave =
  "/CCPMMaster/ProcurementCategory/imgbtnSaveDurationDistribution_Click";

//Risk Type

export let RT_TableData = "/CCPMMaster/RiskType/GetRiskTypeNames";
export let RT_Add = "/CCPMMaster/RiskType/InsertRiskTypeMaster";
export let RT_Update = "/CCPMMaster/RiskType/UpdateRiskTypeMaster";
export let RT_Delete = "/CCPMMaster/RiskType/DeleteRiskTypeMaster";
export let RT_DeleteAll = "/CCPMMaster/RiskType/DeleteRiskTypeMaster";

// CCPM -> Master -> Setting Percentage Parameter

export let getInstanceListSetting =
  "/CCPMMaster/ApplyCCPMSettingMaster/CCPMSettingMasterGetLevel2Master";
export let getInstanceBasedAttributeList =
  "/CCPMMaster/ApplyCCPMSettingMaster/GetAttributesOnInstance";

//customer master
export let CM_GetInstance = "/CCPMMaster/CustomerMaster/BindInstance";
export let CM_RowUpdating =
  "/CCPMMaster/CustomerMaster/gvCustomerMaster_RowUpdating";
export let CM_RowAdding = "/CCPMMaster/CustomerMaster/btnAddNew_Click";
export let CM_RowDeleting =
  "/CCPMMaster/CustomerMaster/gvCustomerMaster_RowDeleting";
export let CM_GetBind = "/CCPMMaster/CustomerMaster/GridBind";

// CCPM -> Master ->Setting Percentage

export let getInstanceParameter =
  "/CCPMMaster/ApplyCCPMSettingMaster/GetInstanceWiseParameterSetting";
export let getAttributeValueOnAttributeNumber =
  "/CCPMMaster/ApplyCCPMSettingMaster/GetAttributeValueOnAtrributeNumber";
export let insertConfigSetting =
  "/CCPMMaster/ApplyCCPMSettingMaster/InsertCCPMConfigurationSetting";
export let insertApplyParameter =
  "/CCPMMaster/ApplyCCPMSettingMaster/InsertApplyCCPMParamters";
export let getHierarchyNumber =
  "/CCPMMaster/ApplyCCPMSettingMaster/GetInstanceHierarchyIdOnAttributeNumber";
export let deleteInstance =
  "/CCPMMaster/ApplyCCPMSettingMaster/DeleteInstanceCCPMParamters";

// CCPM -> Master ->Setting Percentage

export let PC_getCompName = "/CCPMMaster/ProductConfiguration/GetCompanyNameDS";
export let PC_getRootLevel =
  "/CCPMMaster/ProductConfiguration/GetPopulateRootLevel";
export let PC_getSubRootLevel =
  "/CCPMMaster/ProductConfiguration/PopulateSubLevel";
export let PC_getHierarchyLevelDS =
  "/CCPMMaster/ProductConfiguration/BindDependendLevel";
export let PC_getLevelNamesDS =
  "/CCPMMaster/ProductConfiguration/BindLevelNames";
export let PC_GetDependentLevelDS =
  "/CCPMMaster/ProductConfiguration/BindDependendLevelByLevelName";
export let PC_getLevelDetails =
  "/CCPMMaster/ProductConfiguration/BindLevelsDetails";
export let PC_UpdateRow =
  "/CCPMMaster/ProductConfiguration/UpdateLevelRowDetails";
export let PC_DeleteRow =
  "/CCPMMaster/ProductConfiguration/DeleteHierarchyLevelNameRows";
export let PC_getInstanceName =
  "/CCPMMaster/ProductConfiguration/GetLevelsName";
export let PC_SaveBottom = "/CCPMMaster/ProductConfiguration/BottomSaveAll";

//task management

export let TMT_GetInstance = "/CCPMMaster/TaskMgmtConfig/BindUserInstance";
export let TMT_GetRadio = "/CCPMMaster/TaskMgmtConfig/BindRadioOption";
export let TMT_GetGrid = "/CCPMMaster/TaskMgmtConfig/BindTaskMgmtConfigHistory";
export let TMT_SaveConfig = "/CCPMMaster/TaskMgmtConfig/Savetaskmgmtconfig";

//wip masters
export let WL_GetInstance = "/WIPLimit/WIPLimit/BindUserInstance";
export let WL_GetGrid = "/WIPLimit/WIPLimit/BindWIPLimitMasterGrid";
export let WL_RowUpd = "/WIPLimit/WIPLimit/gvWIPLimitMaster_RowUpdating";
export let WL_SaveBtn = "/WIPLimit/WIPLimit/lnkSave_Click";

//user masters

export let US_Inactive = "/Admin/UserMaster/btnExportInactiveUserToExcel_Click";
export let US_InstanceExp =
  "/Admin/UserMaster/btnExportUserInstanceExceptionReport_Click";
export let US_GetLPlusOne = "/Admin/UserMaster/GetLPlusOne";
export let US_GetUserD = "/Admin/UserMaster/GetUserDetails";
export let US_GVRowDeleting = "/Admin/UserMaster/gvUserMaster_RowDeleting";
export let US_GVRowUpdating = "/Admin/UserMaster/gv_usermaster_RowUpdating";
export let US_UsersBulkDelete = "/Admin/UserMaster/BulkDelete";
export let US_Modify = "/Admin/UserMaster/imgbtnModifyLPlusOne_Click";
export let US_GVAddRow = "/Admin/UserMaster/SaveUserData";
export let US_BtnExportExcel = "/Admin/UserMaster/btnExportToExcel_Click";
export let USM_GetRole = "/Admin/UserRoleMapping/BindRole";
export let USM_SaveRole = "/Admin/UserRoleMapping/UpdateRoleMapping";

//Project Task Email Config
export let InstanceData = "/CCPMMaster/ProjTaskMgmtEmailConfig/GetLevel2Mas";
export let InstanceIdBasedData =
  "/CCPMMaster/ProjTaskMgmtEmailConfig/GetProjTaskEmailConfig";
export let InstanceIdBasedHistory =
  "/CCPMMaster/ProjTaskMgmtEmailConfig/GetProjTaskEmailConfigHistory";
export let SaveData =
  "/CCPMMaster/ProjTaskMgmtEmailConfig/SaveProjTaskEmailConfig";

// Calendar page APIs

export let DeleteSelectedRules = "/CCPMMaster/Calender/DeleteSelectedRules";
export let GetCalendarLog =
  "/CCPMMaster/CalenderWorkingNonWorkingHrs/GetCalendarLog";
export let GetRulesByID = "/CCPMMaster/Calender/GetRulesByID";
export let GetCalenderRules = "/CCPMMaster/Calender/GetCalenderRules";
export let GetWorkingHrsDS = "/CCPMMaster/Calender/GetWorkingHrsDS";
export let GetCalendarDetailsDS = "/CCPMMaster/Calender/GetCalendarDetailsDS";
export let GetCalenderNameList = "/CCPMMaster/Calender/GetCalenderNameList";
export let GetCalendarID = "/CCPMMaster/Calender/GetCalendarID";
export let CheckCalendarName = "/CCPMMaster/Calender/CheckCalendarName";
export let InsertUnitMaster = "/CCPMMaster/Calender/InsertUnitMaster";

//New Calendar
export let ExistingCalender = "/CCPMMaster/Calender/ExistingCalender";
export let AddCalender = "/CCPMMaster/Calender/btnAdd_Click";
export let RemoveCalenderData = "/CCPMMaster/Calender/btnRemove_Click";
export let CopyCalender = "/CCPMMaster/Calender/btnClone_Click";
export let OpenWorkingHours = "/CCPMMaster/Calender/btnOpenWorkingHrs_Click";
export let GetworkingHrsDs = "/CCPMMaster/Calender/GetWorkingHrsDS";
export let C_MainCalHours = "/CCPMMaster/Calender/GetWorkingHrsList";
export let C_MainCalDiffHours = "/CCPMMaster/Calender/DatesForMarking";
export let C_BindHalfHrs =
  "/CCPMMaster/CalenderWorkingNonWorkingHrs/BindHalfDayHrs";
export let C_BindCalHrs =
  "/CCPMMaster/CalenderWorkingNonWorkingHrs/BindCalenderHrs";
export let C_BindHrsSubmit =
  "/CCPMMaster/CalenderWorkingNonWorkingHrs/btnOpenWorkingHrs_Click";
export let AddRule = "/CCPMMaster/Calender/btnOK_Click_AddRule";
export let AddRule3 = "/CCPMMaster/Calender/btnOK_Click_AddRulerb3";

// CCPM -> Reports -> RiskActionOwner

export let GetRiskActionOwnerData =
  "/CCPMRiskManagement/RiskActionOwner/GetRiskActionPlanForOwner";

export let GetissueData = "/CCPMTaskManagement/IssueList/BindIssueList";

export let RAO_saveAndRelease =
  "/CCPMRiskManagement/RiskActionOwner/UpdateMitigationActionTaken";

//CCPM -> Risk Management -> Risk Monitor Report

export let RiskBindData =
  "/CCPMRiskManagement/RiskMonitorReport/BindRiskDetails";
export let RiskSubmit = "/CCPMRiskManagement/RiskMonitorReport/btnSubmit_Click";

// CCPM -> Reports -> Portfoliochart

export let GetPCInctances = "/CCPMReports/PortfolioChart/BindUserInstance";
export let GetPCProjectName = "/CCPMReports/PortfolioChart/BindProjects";
export let GetPCProjectManager = "/CCPMReports/PortfolioChart/BindProjectMgrs";
export let GetPCAttributesValuesForSearchFilter =
  "/CCPMReports/PortfolioChart/GetInstanceUserMappingWiseAttributesValuesForSearchFilter";
export let GetPCAttributeHierarchy =
  "/CCPMReports/PortfolioChart/GetInstancewseAttributeHierarchy";
export let GetPCgetallprojectdetails =
  "/CCPMReports/PortfolioChart/BindTabControl_getallprojectdetails_count";
export let GetPCProjectCountDetails =
  "/CCPMReports/PortfolioChart/BindTabGridControl_DSGetProjectAllProjectCountDetails";
export let GetPCSearchData =
  "/CCPMReports/PortfolioChart/BindPortfolioDetails_btn_search";

export let GetParetoSearchData =
  "/CCPMReports/DelayReportforPareto/btnSearch_Click";

export let GetTWBCProjectManagers =
  "/CCPMReports/TaskwiseBufferConsumption/BindProjectMgrs";
export let GetTWBCSearchData =
  "/CCPMReports/TaskwiseBufferConsumption/btnSearch_Click";

export let GetLTMTaskManager =
  "/CCPMReports/LoadOnTaskManager/GetBindTaskManagers";

// CCPM -> Reports -> TTMT

export let GetFinancialYear =
  "/CCPMReports/TaskTakingMoreTime/GetFinancialYear";
export let GetTTMTProjectNames = "/CCPMReports/TaskTakingMoreTime/BindProjects";
export let GetTTMTResult = "/CCPMReports/TaskTakingMoreTime/btnsearch_Click";

// CCPM -> Reports -> Issue Report

export let GetIRsearchTable = "/CCPMReports/IssueReport/btnSearch_Click";
export let GetIRExportToExcel =
  "/CCPMReports/IssueReport/btnExportToExcel_Click";
export let GetIRAssignedTo = "/CCPMReports/IssueReport/BindIssueOwner";

// CCPM -> Reports -> Project Lead Time

export let GetIRProjectDetail =
  "/CCPMReports/ProjectLeadtime/BindProjectDetails";

// CCPM -> Reports -> closed Project Report

export let GetCPRSearch = "/CCPMReports/ClosedProjectReport/btnsearch_Click";

// CCPM >>>>>> Task Management

export let GetBindInstanceTM =
  "/CCPMTaskManagement/ViewMytask/BindUserInstance";

//ccpm >>>> Project Schedule as on current date

export let CPMGetInstancesList =
  "/CCPMProjectPlan/ChangeProjectManager/BindUserInstance";
export let CPMBindProjects =
  "/CCPMProjectPlan/ChangeProjectManager/BindProjects";
export let CPMBindProjectManagers =
  "/CCPMProjectPlan/ChangeProjectManager/BindProjectManagers";
export let CPMGrid =
  "/CCPMReports/ProjectTaskScheduleAndStatus/BindProjTaskScheduleGrid";
export let CPMBtnClick =
  "/CCPMReports/ProjectTaskScheduleAndStatus/btnExportToExcel_Click";
export let PCSDStatus =
  "/CCPMReports/ProjectTaskScheduleAndStatus/BindProjectStatus";
export let BMReport = "/CCPMReports/BMRUNDetails/btnSearch_Click";

//CCPM >>>> Project count with status

export let PCS_SearchBtn =
  "/CCPMReports/ProjectCountWithStatus/btnsearch_Click";

//CCPM >>>> Project completion report

export let PCRBtnClick =
  "/CCPMReports/ProjectCompletionOnTimeReport/btnsearch_Click";

//CCPM PROJECT PLAN
export let BindUserInstance =
  "/CCPMProjectPlan/ChangeProjectManager/BindUserInstance";
export let BindProjectManager =
  "/CCPMProjectPlan/ChangeProjectManager/BindProjectManagers";
export let BindProject = "/CCPMProjectPlan/ChangeProjectManager/BindProjects";
export let BindProjectDetailGrid =
  "/CCPMProjectPlan/ChangeProjectManager/BindProjectDetailGrid";
export let btnChangePM_Click =
  "/CCPMProjectPlan/ChangeProjectManager/btnChangePM_Click";
export let Instance_SelectedIndexChanged =
  "/CCPMProjectPlan/CreateNewPlan/Instance_SelectedIndexChanged";
export let GetAlternateAccessType =
  "/CCPMProjectPlan/AlternateProjectManager/GetAlternateAccessType";
export let BindAlternateProjectManagers =
  "/CCPMProjectPlan/AlternateProjectManager/BindAlternateProjectManagers";
export let BindProjectSelectionGrid =
  "/CCPMProjectPlan/AlternateProjectManager/BindProjectSelectionGrid";
export let BindProjectManagers =
  "/CCPMProjectPlan/AlternateProjectManager/BindProjectManagers";
export let BindExistingAlternatePM =
  "/CCPMProjectPlan/AlternateProjectManager/BindExistingAlternatePM";
export let BindAlternateMgrAccessDetailGrid =
  "/CCPMProjectPlan/AlternateTaskManager/GetAllAlterternatTaskMangerDetails";
export let btnSubmit_Click =
  "/CCPMProjectPlan/AlternateProjectManager/btnSubmit_Click";
export let imgSave_ClickTM =
  "/CCPMProjectPlan/AlternateTaskManager/imgSave_Click";
export let imgSave_Click =
  "/CCPMProjectPlan/AlternateProjectManager/imgSave_Click";
export let imgbtnDelete_Click =
  "/CCPMProjectPlan/AlternateProjectManager/imgbtnDelete_Click";
export let CreatePlanSubmit =
  "/CCPMProjectPlan/CreateNewPlan/SubmitButtonEmpty";
export let getattribute =
  "/CCPMProjectPlan/CreateNewPlan/PM_SelectedIndexChanged";
export let myprojects_BindUserInstance =
  "/CCPMReports/PortfolioChart/BindUserInstance";
export let myprojects_BindProjects = "/CCPMReports/PortfolioChart/BindProjects";
export let myprojects_BindProjectMgrs =
  "/CCPMReports/PortfolioChart/BindProjectMgrs";
export let myprojects_attributes =
  "/CCPMProjectPlan/MyProjects/GetUserInstanceAttributesForSearchFilter";
export let myprojects_Submit = "/CCPMProjectPlan/MyProjects/btnSearch_Click";
export let atm_BindAccessType =
  "/CCPMProjectPlan/AlternateTaskManager/BindAccessType";
export let atm_BindAlternateTaskManagers =
  "/CCPMProjectPlan/AlternateTaskManager/BindAlternateTaskManagers";
export let atm_BindExistingAlternatePM =
  "/CCPMProjectPlan/AlternateTaskManager/BindExistingAlternatePM";
export let atm_BindProjects =
  "/CCPMProjectPlan/AlternateTaskManager/BindProjects";
export let atm_BindTaskManagers =
  "/CCPMProjectPlan/AlternateTaskManager/BindTaskManagers";
export let atm_BindProjectSelectionGrid =
  "/CCPMProjectPlan/AlternateTaskManager/BindProjectSelectionGrid";
export let atm_BindTasks = "/CCPMProjectPlan/AlternateTaskManager/BindTasks";
export let atm_imgSave_Click =
  "/CCPMProjectPlan/AlternateTaskManager/imgSave_Click";
export let atm_imgbtnDelete_Click =
  "/CCPMProjectPlan/AlternateTaskManager/imgbtnDelete_Click";
export let atm_Submit_Click =
  "/CCPMProjectPlan/AlternateTaskManager/btnSubmit_Click";

//LoginAuditReport

export let LARBindTaskManager =
  "/CCPMReports/RptLoginAuditReport/BindTaskManager";
export let LARManager =
  "/CCPMReports/RptLoginAuditReport/ddlUserRole_SelectedIndexChanged";
export let LARsessionNames =
  "/CCPMReports/RptLoginAuditReport/BindSession1And2";
export let LARsearchClick = "/CCPMReports/RptLoginAuditReport/btnSearch_Click";
export let createPlanSubmit2 =
  "/CCPMProjectPlan/CreateNewPlan/rbl2SubmitButton";
export let createPlanSubmit3 =
  "/CCPMProjectPlan/CreateNewPlan/rbl3SubmitButton";
export let viewproj_BindProjectStatus =
  "/CCPMProjectPlan/viewproject/BindProjectStatus";
export let view_projmanagers = "/CCPMProjectPlan/viewproject/BindProjectMgrs";
export let viewproj_ProjectColor = "/CCPMProjectPlan/viewproject/ProjectColor";
export let viewproj_customers =
  "/CCPMProjectPlan/viewproject/GetCustomersForViewProjectsLayout";

//DailyTaskFlowTrend

export let DTFBindTaskManager =
  "/CCPMReports/rptDailyTaskFlowTrend/BindTaskManagers";
export let DTFSearcClick = "/CCPMReports/rptDailyTaskFlowTrend/btnSearch_Click";
export let DTFExporttoExcel =
  "/CCPMReports/rptDailyTaskFlowTrend/btnExportToExcel_Click";

//DailySubTaskFlowTrend

export let DSTFProjects =
  "/CCPMReports/DailySubTaskFlowTrendReport/BindProjects";
export let DSTFProjectManagers =
  "/CCPMReports/DailySubTaskFlowTrendReport/BindProjectMgrs";
export let DSTFSearchClick =
  "/CCPMReports/DailySubTaskFlowTrendReport/btnSearch_Click";
export let DSTFExporttoExcel =
  "/CCPMReports/DailySubTaskFlowTrendReport/btnExportToExcel_Click1";

//ProjectWiseChainCompleted

export let PWCCBtnSearchClick =
  "/CCPMReports/ProjectwiseCriticalChainCompleted/PjtBindProjectDetails_btn_search";

//my projects popups

export let view_history =
  "/CCPMProjectPlan/ViewActiveProjectsHistory/BindActiveProjectHistoryDetails";
export let myprojects_updateAtrSave =
  "/CCPMProjectPlan/ProjectPlans/UpdateProjectAttribute";

export let viewproj_submit = "/CCPMProjectPlan/viewproject/btnSubmit_click";
export let viewproj_projname = "/CCPMProjectPlan/viewproject/BindProjectNames";
export let myproj_DeleteProjectDetails =
  "/CCPMProjectPlan/ProjectPlans/DeleteProjectDetails";

//plannedprojectionbilling

export let PBP_getERP =
  "/CCPMProjectPlan/PlannedBillingProjection/GetERPProjectDetails";
export let PBP_updateERP =
  "/CCPMProjectPlan/PlannedBillingProjection/UpdateERPProjectDetails";
export let PBP_searchERP =
  "/CCPMProjectPlan/PlannedBillingProjection/SearchERPProjectDetails";
export let PBP_getTaskDet =
  "/CCPMProjectPlan/PlannedBillingProjection/BindTasks";
export let PBP_updateTaskDet =
  "/CCPMProjectPlan/PlannedBillingProjection/UpdateTaskDetailsForBillingProjection";
export let PBP_getInvoiceDet =
  "/CCPMProjectPlan/PlannedBillingProjection/BindInvoice";

//risk and mitigation

export let RMP_getRiskType = "/CCPMProjectPlan/AddEditRisk/GetRiskTypeList";
export let RMP_getRiskCat = "/CCPMProjectPlan/AddEditRisk/GetRiskcategory";
export let RMP_getTriggerStat = "/CCPMProjectPlan/AddEditRisk/GetTriggerStatus";
export let RMP_insertData = "/CCPMProjectPlan/AddEditRisk/InsertProjRisk";
export let RMP_getDetailsPop =
  "/CCPMProjectPlan/AddEditRisk/GetRiskDetailsList";
export let RMP_updateData = "/CCPMProjectPlan/AddEditRisk/UpdateProjRisk";
export let RMP_history = "/CCPMProjectPlan/ViewRiskHistory/GetRiskHistory";
export let RMP_getMitigation =
  "/CCPMProjectPlan/AddEditRisk/GetRiskMitigationAction";
export let RMP_ownerDetails =
  "/CCPMProjectPlan/AddEditRisk/GetIssueOwnerDetails";
export let RMP_InsertProjRiskMit =
  "/CCPMProjectPlan/AddEditRisk/InsertProjRiskMitigationAction";
export let RMP_DeleteEntries = "/CCPMProjectPlan/AddEditRisk/DeleteProjRisk";

export let RR_getSearchDetails =
  "/CCPMRiskManagement/RiskReview/RiskBtn_Submit";
export let RR_OnUpdate = "/CCPMRiskManagement/RiskReview/OnUpdate";

//apply ccpm settings

export let loadCCPMSettings =
  "/CCPMProjectPlan/ApplyCCPMSetting/LoadCCPMSettings";
export let applyCCPMParams =
  "/CCPMProjectPlan/ApplyCCPMSetting/ApplyCCPMParametersOnUserIdAndProject";
export let submitCCPMSettings =
  "/CCPMProjectPlan/ApplyCCPMSetting/btnApplyCCPM_Click";

//view projects popups
export let pendingtaskone =
  "/CCPMActiveProject/CompleteProject/PendingTasksOnCCandFC/BindData";
export let pendingtasktwo =
  "/CCPMActiveProject/CompleteProject/ViewChainDetails/BindData";
export let pendingtaskthree =
  "/CCPMActiveProject/CompleteProject/ViewChainDetails/imgbtnView_Click";
export let viewriskpopup = "/CCPMRiskManagement/RiskReview/Btn_Submit";
export let viewissuepopup =
  "/CCPMProjectPlan/issuepopup/GetOpenCloseIssueListProjectWise";
export let bufferrecoverypopup =
  "/CCPMProjectPlan/MyProjects/BufferRecoveryPop";
export let newprojectplanfile =
  "/CCPMProjectPlan/MyProjects/exporttoexcel_NewProjectplan";
export let oldprojectplanfile =
  "/CCPMProjectPlan/MyProjects/exporttoexcel_OldProjectplan";

//aet tasks
export let AET_getTasksBind =
  "/CCPMProjectPlan/AddEditTask/GetTasksByProjectId";
export let AET_projProp =
  "/CCPMProjectPlan/ProjectSettings/BindProjectSettings";

// Active project -- complete project

export let Get_APProjectName =
  "/CCPMActiveProject/ActiveProject/BindProjectNames";
export let Get_APProjectManagers =
  "/CCPMActiveProject/ActiveProject/BindProjectManagers";

export let Get_APCPSearch = "/CCPMActiveProject/ActiveProject/btnSearch_Click";
export let AET_getProjById = "/CCPMProjectPlan/AddEditTask/GetProjectInfoById";
export let AET_taskMgmt =
  "/CCPMTaskManagement/AddEditTask/TogetTaskMangerNames";
export let AET_saveApi = "/CCPMProjectPlan/AddEditTask/SaveTasks";
export let AET_submitApi = "/CCPMProjectPlan/AddEditTask/SubmitPlan2";
export let AET_deleteApi = "/CCPMProjectPlan/AddEditTask/DeleteTask";
export let AET_searchApi = "/CCPMTaskManagement/AddEditTask/Search";
export let Get_SubType = "/CCPMProjectPlan/AddEditTask/CheckSubTypeID";
export let saveAsTempl = "/CCPMProjectPlan/AddEditTask/btnSaveasTemplate_Click";
export let AETPT_DeleteTasks =
  "/CCPMProjectPlan/AddEditTask/imgbtnDeleteTask_Click";

export let CP_btnComplete_Click =
  "/CCPMActiveProject/CompleteProject/btnComplete_Click";
export let CP_btnSave_Click =
  "/CCPMActiveProject/CompleteProject/btnSave_Click";

// Reports -- Load oN task manager

export let LOTM_detailedSearch =
  "/CCPMReports/LoadOnTaskManager/Details/BindProjectDetails_GetTaskMgrWiseLoadNew";
export let LOTM_summerySearch =
  "/CCPMReports/LoadOnTaskManager/GetTaskMgrWiseTaskCountForNoofWeeks";

// Reports -- Load oN task manager

export let PVA_tableSearch = "/CCPMReports/PlannedVsActual/btnSearch_Click";
export let PVA_TaskManager = "/CCPMReports/PlannedVsActual/BindTaskManager";
export let PVA_TaskStatus = "/CCPMReports/PlannedVsActual/BindTaskStatus";
export let PVA_BindTaskColors = "/CCPMReports/PlannedVsActual/BindTaskColors";
export let PVA_BindProjects = "/CCPMReports/PlannedVsActual/BindProjects";
export let PVA_BindDepartment_pva =
  "/CCPMReports/PlannedVsActual/BindDepartment_pva";

//My Projects -- Sub Tasks

export let ST_CommonSubmit = "/CCPMProjectPlan/SubTypeAll/btnSubmit_Click";
export let ST_GetProjStats = "/CCPMProjectPlan/SubTypeAll/GetProjectStatus";
export let ST_ERPLnCheck = "/CCPMProjectPlan/SubTypeAll/CheckIsERPLNProject";
export let ST_GetUnitNames = "/CCPMProjectPlan/SubTypeAll/GetUnitNames";
export let ST_GetProcList =
  "/CCPMProjectPlan/SubTypeAll/GetProcurementTypeList";
export let ST_ProTaskStart =
  "/CCPMProjectPlan/SubTypeAll/Procurement/chkprocmaintaskstart";
export let ST_ProCloseTask =
  "/CCPMProjectPlan/SubTypeAll/Procurement/chkprocrcmt";
export let ST_ProTaskSave =
  "/CCPMProjectPlan/SubTypeAll/Procurement/btnPlusAdd_Click";
export let ST_SaveTasks =
  "/CCPMProjectPlan/SubTypeAll/Procurement/imgbtnPSave_Click";
export let ST_ProcDelTask =
  "/CCPMProjectPlan/SubTypeAll/Procurement/imgbtnPDelete_Click";
export let ST_ProChangeCategory =
  "/CCPMProjectPlan/SubTypeAll/Procurement/imgbtnSaveChangedCategory";
export let ST_GetAllTasks =
  "/CCPMProjectPlan/SubTypeAll/GetTasksByProjectIdAndTaskId";
export let ST_GetAllListType = "/CCPMProjectPlan/SubTypeAll/GetListTypeList";
export let ST_ListUpd = "/CCPMProjectPlan/SubTypeAll/OnUpdate";
export let ST_ListDel = "/CCPMProjectPlan/SubTypeAll/DeleteProjListType";
export let ST_GetAutoUp =
  "/CCPMProjectPlan/SubTypeAll/GetAutoupdateTypeSubtask";
export let ST_GetVolTasks = "/CCPMProjectPlan/SubTypeAll/GetVolumetricSubtask";
export let ST_TaskStart = "/CCPMProjectPlan/SubTypeAll/chklistmaintaskstart";
export let ST_TaskClose = "/CCPMProjectPlan/SubTypeAll/chklistrcmt";
export let ST_AutoUpdSave =
  "/CCPMProjectPlan/SubTypeAll/AutoUpdate/ImgbtnAutostopFrequencySave_Click";
export let ST_AutoTaskStart =
  "/CCPMProjectPlan/SubTypeAll/AutoUpdate/chkautomaintaskstart";
export let ST_AutoTaskDel =
  "/CCPMProjectPlan/SubTypeAll/AutoUpdate/ImgbtnAutoDelete_Click";
export let ST_VolAddTasks =
  "/CCPMProjectPlan/SubTypeAll/volumetric/imgPlusbtnAddVolSubtask_Click";
export let ST_VolTaskStart =
  "/CCPMProjectPlan/SubTypeAll/volumeric/chkvolmaintask";
export let ST_VolTaskClose =
  "/CCPMProjectPlan/SubTypeAll/volumetric/chkvolrcmt";
export let ST_VolDeleteTask =
  "/CCPMProjectPlan/SubTypeAll/volumetric/imgbtnDelVolSubtask_Click";
export let ST_VolTaskSave =
  "/CCPMProjectPlan/SubTypeAll/volumetric/imgbtnSaveVolSubtaskUpdate_Click";

// My Projects -- Events

export let Events_GetProjectDetails =
  "/CCPMActiveProject/ActiveProject/Notes/BindProjectNotes";
export let Events_SaveNotes =
  "/CCPMActiveProject/ActiveProject/Notes/btnSave_Click";

// Task Management -- Task Management

export let TM_GetTaskManager =
  "/CCPMTaskManagement/ViewMytask/GetTaskManagerListDetails";
export let TM_GetTaskStatus =
  "/CCPMTaskManagement/ViewMytask/GetTaskStatusDetails";
export let TM_GetTaskColors = "/CCPMTaskManagement/ViewMytask/GetTaskColors";
export let TM_GetTaskNotes = "/CCPMTaskManagement/ViewMytask/GetTasksNotes";

export let TM_GetTable =
  "/CCPMTaskManagement/ViewMyTasks/GetTaskMgmtDetailInstanceWise";

// CCPM department master

export let DM_Searchfilter =
  "/CCPMMaster/DepartmentMaster/GetInstanceDepartmentDetails";
export let DM_UpdateDetails =
  "/CCPMMaster/DepartmentMaster/ModifyInstanceDepartmentDetails";

// DSS -- Admin -- New Instance

export let NI_NewInstance = "/DSSAdmin/DSS_Instance/DSSupdateNewInstanceData";

// MTA -- Parameters -- MTA New Instance

export let MNI_MtaNewInstance =
  "/MTAParameter/MTA_NewInstance/MTA_InsertNewInstance";

// MTA -- Parameters -- MTA Instance Active/Inactive

export let MNI_MtaActiveData =
  "/MTAParameter/MTA_InstanceActiveInactive/MTA_GetAllInstance";
export let MNI_MtaActiveUpdateSingle =
  "/MTAParameter/MTA_InstanceActiveInactive/MTA_updateUserInstanceLinkingData";
export let MNI_MtaActiveUpdateAll =
  "/MTAParameter/MTA_InstanceActiveInactive/MTA_updateAllInstanceData";

// MTA -- Parameters -- MTA Stock Point Type

export let MNI_GetInstances = "/MTAParameter/MTA_Instance/Get_MTA_InstanceUID";
export let MNI_Get_StockPoint_Type =
  "/MTAParameter/MTA_Instance/Get_StockPoint_Type";
export let MNI_Update_StockPoint =
  "/MTAParameter/MTA_Instance/Update_StockPoint_Type";

//MTA User StockPoint linking

export let CheckUser = "/MTAParameter/USPL/CheckUser";
export let UserStockPointAccessSelection =
  "/MTAParameter/USPL/StockPointAccessSelection";
export let getUserStockPointSelection = "/MTAParameter/USPL/GetUserStockPoint";
export let userStockPointLinking = "/MTAParameter/USPL/UserStockPointLinking";

//MTA User Instance Linking

export let MNI_GetInstanceslinking =
  "/MTAParameter/MTA_UserInstanceLinking/btnSearch_Click_MTA_GetUserInstance";
export let MNI_UpdateInstanceslinking =
  "/MTAParameter/MTA_UserInstanceLinking/btnSave_Click_MTA_UserInstanceLinking";

//MTA User Stock Point Linking

export let USPL_GetUserStockPoint = "/MTAParameter/USPL/GetUserStockPoint";
export let USPL_CheckUser = "/MTAParameter/USPL/CheckUser";
export let USPL_StockPointAccessSelection =
  "/MTAParameter/USPL/StockPointAccessSelection";

export let MTA_CheckStockPointExists =
  "/MTAParameter/MTA_StockPint_Configuration/MTA_CheckStockPointExists";

//MTA param --  StockPointConfig

export let MTA_InsertStockPointConfiguration =
  "/MTAParameter/MTA_StockPointCongiguration/InsertStockPointConfiguration";
export let MTA_UpdateStockPointConfiguration =
  "/MTAParameter/MTA_StockPointCongiguration/UpdateStockPointConfiguration";
export let MTA_DeleteStockPointConfiguration =
  "/MTAParameter/MTA_StockPointCongiguration/DeleteStockPointConfiguration";
export let MTA_GetModeBasedParameter =
  "/MTAParameter/MTA_StockPointCongiguration/GetModeBasedParameter";
export let GetConfigValues =
  "/MTAParameter/MTA_StockPointCongiguration/GetConfigValues";
export let SPC_CheckStockPointExists =
  "/MTAParameter/MTA_StockPointCongiguration/CheckStockPointExists";

// MTA -- Parameters -- MTA Stock Point Type

export let SP_Instances = "/MTAParameter/MTA_StockPoint/GetMTAInstanceUID";
export let GetMTA_SP_TypeDesc =
  "/MTAParameter/MTA_StockPoint/GetMTA_SP_Type_Desc";
export let MTA_SPInsertStockpoint =
  "/MTAParameter/MTA_StockPoint/MTA_InsertStockpoint";
export let MTA_SPUpdateStockPoint =
  "/MTAParameter/MTA_StockPoint/MTA_UpdateStockPoint";
export let MTA_SPDeleteStockPoint =
  "/MTAParameter/MTA_StockPoint/MTA_DeleteStockPoint";

export let MTA_StockPointPopup =
  "/MTAParameter/MTA_StockPoint/GetStockPointPopup";

//MTA param Control
export let MTA_UserControl = "/MTAParameter/UserControl/UserControl";
export let MTA_UserControl_update =
  "/MTAParameter/UserControl/UserControl_update";

//MTA param Admin Control
export let MTA_AdminControl =
  "/MTAParameter/AdminControl/AdminControl_GridBind";
export let MTA_AdminControl_update =
  "/MTAParameter/UserControl/AdminControl_update";

//Staggering ---myprojects
export let getProjectInfo = "/CCPMProjectPlan/Staggering/GetProjectInfo";
export let recommendedStartAndFinishDate =
  "/CCPMProjectPlan/Staggering/RecommendedStartDateProjectedFinishDate";

//MTA param StockPointWarehouse
export let MTA_DeleteStockPointWarehouse =
  "/MTAParameter/MTA_StockPoint_Warehouse/DeleteStockPointWarehouse";
export let MTA_UpdateStockPointWarehouse =
  "/MTAParameter/MTA_StockPoint_Warehouse/UpdateStockPointWarehouse";
export let MTA_InsertStockPointWarehouse =
  "/MTAParameter/MTA_StockPoint_Warehouse/InsertStockPointWarehouse";

export let MTA_CompanyCodePopup =
  "/MTAParameter/MTA_StockPoint_Warehouse/GetCompanyCodePopUp";

//MTA param StockPointWarehouseConsumption
export let MTA_DConsumption =
  "/MTAParameter/MTA_StockPoint_Warehouse_Consumption/DeleteStockPointWarehouseConsumption";
export let MTA_UConsumption =
  "/MTAParameter/MTA_StockPoint_Warehouse_Consumption/UpdateStockPointWarehouseConsumption";
export let MTA_IConsumption =
  "/MTAParameter/MTA_StockPoint_Warehouse_Consumption/InsertStockPointWarehouseConsumption";

export let MTA_GetWarehouseCode =
  "/MTAParameter/MTA_StockPoint_Warehouse_Consumption/Get_Warehouse_Code";

//MTA param StockPointWarehouseConsumption
export let MTA_DInTransit =
  "/MTAParameter/MTA_StockPoint_Warehouse_InTransit/DeleteStockPointWarehouseInTransit";
export let MTA_UInTransit =
  "/MTAParameter/MTA_StockPoint_Warehouse_InTransit/UpdateStockPointWarehouseInTransit";
export let MTA_IInTransit =
  "/MTAParameter/MTA_StockPoint_Warehouse_InTransit/InsertStockPointWarehouseInTransit";
export let MTA_GetInTransitCode =
  "/MTAParameter/MTA_StockPoint_Warehouse_InTransit/Get_Warehouse_CodeInTransit";

//MTA param DBM
export let MTA_GetDBMData = "/MTAParameter/MTA_DBM/GetDBMData";
export let MTA_GetProposedAction = "/MTAParameter/MTA_DBM/GetProposedAction";
export let MTA_Update_ProposedAction =
  "/MTAParameter/MTA_DBM/Update_ProposedAction";
export let MTA_IgnoredAction = "/MTAParameter/MTA_DBM/IgnoredAction";
export let MTA_GetSSGPopup = "/MTAParameter/MTA_DBM/GetSSGPopup";
export let MTA_GetItemCategoryPopup =
  "/MTAParameter/MTA_DBM/GetItemCategoryPopup";
export let MTA_DBMLastRun = "/MTAParameter/MTA_DBM/GetDBMIndication_LastRun";
export let MTA_TOC_Item_Stock_Graph =
  "/MTAParameter/MTA_TOC_Item_Stock_Graph/GetReportDetails";

//network charts
export let networkChart = "/CCPMProjectPlan/MyProjects/createNetworkDiagram";
export let FeverChartProjectDetails =
  "/CCPMActiveProject/ActiveProject/feverchart/BindProjectDetails";
export let FeverChartData =
  "/CCPMActiveProject/ActiveProject/feverchart/BindChart";
export let histogramChartData =
  "/CCPMReports/DelayReportforPareto/btnSearch_Click";
export let LoadPortfolioChart = "/CCPMReports/PortfolioChart/LoadBarChartData";
export let getExcelFormat =
  "/CCPMProjectPlan/CreateNewPlan/btnExcelFormat_Click";

//MTA param Inbound Order Advice

export let GetImosData =
  "/MTAParameter/MTA_Inbound_Order_Advice_GnB_CRDAL/GetImosData";
export let GetECommerceData =
  "/MTAParameter/MTA_Inbound_Order_Advice_GnB_CRDAL/GetECommerceData";
export let GetBPPAndColorData =
  "/MTAParameter/MTA_Inbound_Order_Advice_GnB_CRDAL/GetBPPAndColorData";
export let GetPurchaseData =
  "/MTAParameter/MTA_Inbound_Order_Advice_GnB_CRDAL/GetPurchaseData";
export let GetSalesAllocationData =
  "/MTAParameter/MTA_Inbound_Order_Advice_GnB_CRDAL/GetSalesAllocationData";
export let GetProductionData =
  "/MTAParameter/MTA_Inbound_Order_Advice_GnB_CRDAL/GetProductionData";
export let GetRPLData =
  "/MTAParameter/MTA_Inbound_Order_Advice_GnB_CRDAL/GetRPLData";
export let GetOrderStatus =
  "/MTAParameter/MTA_Inbound_Order_Advice_GnB_CRDAL/GetOrderStatus";
export let GetInboundOrder =
  "/MTAParameter/MTA_Inbound_Order_Advice_GnB_CRDAL/GetInboundOrder";
export let Resend_Click =
  "/MTAParameter/MTA_Inbound_Order_Advice_GnB_CRDAL/btnResend_Click";

//MTA param Stock-Point-Buffer-Upload

export let Merge_MTA_Stock_Point_Buffer =
  "/MTAParameter/MTA_Stock_Point_Buffer_Upload/Merge_MTA_Stock_Point_Buffer";
export let DownloadSampleFormat =
  "/MTAParameter/MTA_Stock_Point_Buffer_Upload/DownloadSampleFormat";
export let Get_MTA_Stock_Point_Buffer =
  "/MTAParameter/MTA_Stock_Point_Buffer_Upload/Get_MTA_Stock_Point_Buffer";
export let UploadMTAStockPointBuffer =
  "/MTAParameter/MTA_Stock_Point_Buffer_Upload/UploadMTAStockPointBuffer";
//Active Projects -- Modify Task Attribute

export let GetInitialTaskManager =
  "/CCPMActiveProject/ModifyTaskAttribute/GetTaskManagerByProject";
export let GetBottomTaskManager =
  "/CCPMActiveProject/ModifyTaskAttribute/GetAllDivisionWiseTaskManagersList";
export let GetSeachDetail =
  "/CCPMActiveProject/ModifyTaskAttribute/SearchDetail";
export let GetSaveAll =
  "/CCPMActiveProject/ModifyTaskAttribute/UpdateTaskAttributes";

//SubTaskAll

export let GetInsertButton = "/CCPMProjectPlan/SubTypeAll/List/Insert_button";

//My Projects

export let ChangeStatusofProject =
  "/CCPMProjectPlan/ProjectPlan/ChangeStatusOfProject";

// Create New Plan

export let getDownloadResultbtn =
  "/CCPMProjectPlan/CreateNewPlan/btnResult_Click";

//TaskManagementChanges

export let saveTaskNotes =
  "/CCPMTaskManagement/TaskProcessNotes/SaveTaskProcessNotes";
export let Save_taks_raise =
  "/CCPMTaskManagement/raiseIssueList/SaveTaskIssueOnIssueId";
export let getTaskNotes =
  "/CCPMTaskManagement/TaskProcessNotes/GetTaskProcessNotes";
export let closeRaiseIssue =
  "/CCPMTaskManagement/TaskProcessNotes/GetTaskProcessNotes";
export let getRaiseListHistory =
  "/CCPMTaskManagement/raiseIssueList/GetRaiseIssueList";
export let getOwnerCategory =
  "/CCPMProjectPlan/AddEditRisk/GetIssueOwnerDetails";
export let viewTaskDuration =
  "/CCPMTaskManagement/ViewMytask/GetTasksByProjectIdAndTaskId";
export let viewTaskDurationGraph =
  "/CCPMActiveProject/CompleteProject/ViewChainDetails/imgbtnView_Click";
export let getViewHistory = "/CCPMTaskManagement/ViewMytask/GetTasksHistory";
export let getDurationHistory =
  "/CCPMTaskManagement/TaskWiseRemainingDurationPOPUP/GetSubTaskClosureTrendOnProjectAndTask";
export let raiseissuecategory = "/CCPMMaster/IssueCategory/BindGriedView";

export let viewSuccessorrpopup = "/CCPMTaskManagement/ViewMytask/GetSuccessor";

export let viewSuccessorrTable =
  "/CCPMTaskManagement/ViewMytask/GetTasksByProjectIdAndTaskId";

export let viewPredecessorpopup =
  "/CCPMTaskManagement/ViewMytask/GetPredecessor";

//Calender

export let ManualRunBm = "/CCPMActiveProject/ActiveProject/MannualBM_Click";
export let AllRunBm = "/CCPMActiveProject/ActiveProject/AllRunBM_Click";
export let simulateData = "/CCPMProjectPlan/Staggering/btnSimulate_Click";
export let ShowWeeks = "/CCPMProjectPlan/Staggering/ShowWeeks";
export let AcceptSimulate = "/CCPMProjectPlan/Staggering/btnAccept_Click";

export let SearchDepartment = "/CCPMReports/DepartmentLoad/btnSearch_Click";
export let runBMURL = "/CCPMActiveProject/ActiveProject/MannualBM_Click";

//Alternate Task Manager

export let GetAllAlternateTM =
  "/CCPMProjectPlan/AlternateTaskManager/GetAllAlterternatTaskMangerDetails";
export const projectStatus =
  "/CCPMActiveProject/ActiveProject/GetProjectStatus";
export const projectStatusChange =
  "/CCPMActiveProject/ActiveProject/ GetActiveProjStatusChgReason";
export let runBMAllURL = "/CCPMActiveProject/ActiveProject/AllRunBM_Click";
export let TM_NewGetTable =
  "/CCPMTaskManagement/ViewMyTasks/GetTaskMgmtDetailInstanceWiseNew";
export let GetTaskDetails =
  "/CCPMTaskManagement/ViewMytask/GetTaskStatusDetails";
export let GetSubtaskDetails =
  "/CCPMProjectPlan/SubTypeAll/GetAutoupdateTypeSubtask";

export let GetDelayReson =
  "/CCPMTaskManagement/ViewMytask/GetDelayReasonDivWise";
