import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SPSS1 from "../HelpPopups/images/SPSS1.png";
import SPSS2 from "../HelpPopups/images/SPSS2.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointSalesStatisticalGroupConfigurationhelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h2 style={{ marginLeft: "0rem", textAlign: "left" }}>
                                Stock Point Sales Statistical Group Configuration                            </h2>
                            <br />
                            <br />


                            <li className="no-bullets">
                                Session Path {">>"} MTA {">>"} MTA Parameter {">>"}Stock Point Sales Statistical Group Configuration
                            </li>
                            <br />
                            <li className="no-bullets">
                                In this field sales statistical group is defined. Sales Statistical group can be used if the no. of items is very large and the attributes of the items can be defined at its SSG class level. Based on this, auto-population will be done to populate the data on item level.

                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPSS1} alt="SPSS1" />
                            </Stack>
                            <br />
                            <li>
                                Select MTA Instance
                            </li>
                            <br />
                            <li className="no-bullets">
                                Action to be taken is
                                <ul>
                                    <li>
                                        Add new Record – For addition of the new record
                                    </li>
                                    <li>
                                        Update Record – One can update record.
                                    </li>
                                    <li>
                                        Delete Record – One Can delete record for item code.
                                    </li>
                                </ul>                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPSS2} alt="SPSS2" />
                            </Stack>
                            <br />

                            <br />


                            <li className="no-bullets">
                                When you Select add new record following screen is displayed. Select relevant parameter and save.

                            </li>
                            <br />
                            <li>
                                MTA Instance – Select your MTA Instance.                            </li>
                            <li>
                                Stock Point – Select Stock point from dropdown provided for the field.


                            </li>
                            <li>
                                Company Code – Maintain Company Code for that stock point.


                            </li>
                            <li>
                                Sales Statistical Group – Maintain Sales statistical group. This is used for sorting out data. Currently Appliances division is using this parameter.
                            </li>
                            <li>
                                Source Stock Point – There are 3 scenarios in this case. This parameter indicates that from where material to be replenished to this stock point.
                                <ul>
                                    <li>Production – If for this field Production is maintained then it indicates that this item is replenished from plant trough production order. So, system will raise production order for this item.


                                    </li>
                                    <li>
                                        Supplier – If for this field Supply is maintained it indicates that this item is replenished by supplier through Purchase. So, system will raise Purchase order for this item.


                                    </li>
                                    <li>
                                        Stock Point – When replenishment is to be done from Other stock point which are given in drop down for that field. Then RPL order is raised from that stock point to this stock point and item is replenished.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Replenishment Day – This field indicates that the no of days required for replenishing the item at stock point. Value for this field is a valid integer.


                            </li>
                            <li>
                                Batch Quantity – This field indicates that the item is replenished in the batch of defined nos if value is maintained here. If value is not maintained, then system will consider default value of 1.
                            </li>
                            <li className="no-bullets">
                                Default Value – 1


                            </li>
                            <li>
                                Minimum Order Quantity – This field indicates that the item needs minimum Order Quantity for replenishing the material. If value is no maintained in this field the system will consider default value of 1.


                            </li>
                            <li className="no-bullets">
                                Default Value – 1


                            </li>
                            <li>
                                Sub Location – This field indicates Sub location for the item to be store in the stock point. If this is not maintained, then the default value is nil (This mean Sub location is not maintained.)


                            </li>
                            <br />
                            <br />
                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid >
        </>
    );
};

export default StockPointSalesStatisticalGroupConfigurationhelpPopup;
