import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/CM1.jpg";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";

const DailyTaskFlowTrendhelpPopup = () => {
    return (
        <>
        <div>
            <Stack
              direction="row"
              spacing={4}
              sx={{ paddingTop: "1rem" }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack sx={{ paddingLeft: "1rem" }}>
                <img src={logo} alt="Godrej" style={{width:"120px"}} />
                <span style={{ paddingTop: "2rem" }}>
                  Expand it...
                  <Link
                    style={{
                      color: "#0000EE",
                      marginRight: "0.6rem",
                      textDecoration: "underline",
                      fontWeight:'bold',
                      fontFamily:'TimesNewRoman'
                    }}
                  >
                    User Manual Index
                  </Link>
                </span>
              </Stack>
    
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={prostream} alt="Prostream" />
              </Stack>
            </Stack>
            <Stack></Stack>
          </div>
    
          <Grid>
            <Grid container className="parent-container">
              <Grid item xs={12} className="child-container">
                
                  <h3 style={{ textDecoration: "underline", fontFamily:'Cablibri,serif',fontSize:'26px'}}>DAILY TASK FLOW TREND</h3>
                  <br></br>
                  This report is generated for the task where sub tasks are added in the main task. This report shows the record for the last one month. This report is mainly focused on the status of the project. A project manager can monitor task updating and progress of tasks for the last month starting from today. This report only available for the active project only.
                  <br />
                  <p style={{fontSize:'18px',fontFamily:"sans-serif"}}> <strong>Session Path {'>>'}CCPM {'>>'} Reports {'>>'}  Daily Task Flow Trend. </strong></p>
                  <ul>
                  <br></br>
                  <Stack sx={{ paddingRight: "1rem" }}>
                    <img src={IC1} alt="IC1" />
                  </Stack>
                  <p>Select Instance from the dropdown.</p>
                  
                  <br></br>
                  </ul>
                  <Stack sx={{ paddingRight: "1rem" }}>
                    <img src={IC1} alt="IC1" />
                  </Stack>
                  <p>Attribute wise filters are provided for the report. Also project name, project manager and task manager filter are also provided. Once user selects the values for the desired values then a narrow down report is displayed.</p>
                  <br></br>
                 
                  <Stack sx={{ paddingRight: "1rem" }}>
                    <img src={IC1} alt="IC1" />
                  </Stack>

                  <ul>
                    <li>
                    ID – In this column serial no is displayed.
                    </li>
                    <li>
                    Instance – In this column Instance is displayed. Which user is selected at the time of the report.
                    </li>
                    <li>
                    Projects Id – In this column project id is shown for the project.
                    </li>
                    <li>
                    Project Name – In this column project name displayed.
                    </li>
                    <li>
                    Project Manager – In this column Name for the Project manager for that project is displayed.
                    </li>
                    <li>
                    Task Id – In this column task id of the task is shown.

                    </li>
                    <li>
                    Task Name – In this column name of main task is shown. Here only main task where there is sub task are shown. All other tasks are not displayed.
                    </li>
                    <li>
                    Task Manager – In this column task manager for the task is shown.

                    </li>
                    <li>
                    Task Type – In this column task type is shown. In this report default task type is set as Procurement Type.
                    </li>
                    <li>
                    Elapsed Days – This is No of days taken for the task. It depends on the task status.
                    </li>
                    <ul>
                        <li>
                        If task status is completed or closed, then it is time taken for the task to complete.

It is Actual End Date – Actual Start Date.
</li>

<li> If task status is Open the it shows time take for the task from Actual Start date to till date.

It is Current Date – Actual start Date.
</li>
                        
                    </ul>
                    <li>
                    It is Actual End Date – Actual Start Date.
                    </li>
                    <li>
                    It is Current Date – Actual start Date.
                    </li>
                  </ul>
                  <br></br>

                  <Stack sx={{ paddingRight: "1rem" }}>
                    <img src={IC1} alt="IC1" />
                  </Stack>

              </Grid>
            </Grid>
          </Grid>
        </>
      );
};

export default DailyTaskFlowTrendhelpPopup;
