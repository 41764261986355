import { Grid } from "@mui/material";
import SideBar from "../../../Landing/Navbar";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import modules from "./../DSS.module.css";
import {
  GetInstancesHandler,
  SaveInstanceHandler,
} from "../../../Services/Dss";
import { useState } from "react";
import SampleSidebar from "../../../Landing/SampleSidebar";

export default function UserInstanceLinking() {
  const [pageData, setPageData] = useState({
    userid: "",
    searchData: [],
    defaultValue: "",
    selectAll: true,
  });

  const [checkBox, setcheckBox] = useState([]);
  const [linkBox, setLinkBox] = useState([]);

  function searchHandler() {
    if (pageData.userid) {
      GetInstancesHandler(pageData.userid).then((res) => {
        if (res.status === 200) {
          setPageData({
            ...pageData,
            searchData: res.data,
          });
          setcheckBox(
            res.data.map((ele) => {
              return ele.isdefaultinstance === 1 ? true : false;
            })
          );
          setLinkBox(
            res.data.map((ele) => {
              return ele.isselected === 1 ? true : false;
            })
          );
        }
      });
    }
  }

  function defaultHandler(idx) {
    setcheckBox(
      checkBox.map((i, index) => {
        return idx === index ? true : false;
      })
    );
  }

  //    function CheckEmptyLinkHandler(){
  //     let empty=false;
  //     linkBox.map((i)=>{
  //         empty = empty || i;
  //     })
  //     return empty;
  //    }

  function linkHandler(idx) {
    // if(!CheckEmptyLinkHandler()) checkBox[idx]=!checkBox[idx];
    setLinkBox(
      linkBox.map((i, index) => {
        if (idx === index) {
          if (checkBox[index] === true && linkBox[index] === true)
            checkBox[index] = false;
          return !linkBox[index];
        } else {
          return i;
        }
        // return idx === index ? !linkBox[index] : i;
      })
    );
  }

  function selectAllLinkHandler() {
    setLinkBox(
      linkBox.map((i, index) => {
        if (checkBox[index] === true && pageData.selectAll === false)
          checkBox[index] = false;
        return pageData.selectAll;
      })
    );
    setPageData({
      ...pageData,
      selectAll: !pageData.selectAll,
    });
  }

  function resetHandler() {
    setPageData({
      userid: "",
      searchData: [],
      defaultValue: "",
      selectAll: true,
    });
    setLinkBox([]);
    setcheckBox([]);
  }

  function submitHandler() {
    let defaultInstanceId = null;
    let linkedInstances = [];
    checkBox.map((i, index) => {
      if (i === true)
        defaultInstanceId = pageData.searchData[index].instanceuid;
    });
    linkBox.map((i, index) => {
      if (i === true)
        linkedInstances.push(pageData.searchData[index].instanceuid);
    });
    //   let body={
    //     default:defaultInstanceId,
    //     linked:linkedInstances.toString()
    //   }

    SaveInstanceHandler({
      userID: pageData.userid,
      bc_instance_uid: linkedInstances.toString(),
      bc_last_modified_by: "jithendra",
      bc_default_instance_uid: defaultInstanceId,
    })
      .then((res) => {
        if (res.status === 200) {
          alert(res?.data?.success?.message);
          searchHandler();
        } else if (res.response.status === 400) {
          alert(res.response.data);
        }
      })
      .catch((err) => {
        console.log("Error while saving data:", err);
      });
  }

  return (
    <>
      <Grid container>
        <Grid item lg={0.7} className={modules["main_cont"]}>
          {/* <SideBar /> */}
          <SampleSidebar />
        </Grid>

        <Grid item lg={11.3} sx={{}}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home />
          </Grid>

          <Grid item xs={12}>
            <div className={modules["usermaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>DSS Admin</a>
                <img src={bread} alt="" />
                <a>User Instance Linking</a>
              </div>
            </div>
          </Grid>
          <Grid
            sx={{
              marginLeft: "7rem",
              marginTop: "1rem",
            }}
          >
            <Grid container columnSpacing={{ xs: 2 }}>
              <Grid
                item
                sx={{
                  fontSize: "14px",
                  fontWeight: "550",
                }}
              >
                User ID<span className={modules["customSpan"]}>*</span>
              </Grid>
              <Grid item>:</Grid>
              <Grid item>
                <input
                  type="text"
                  value={pageData.userid}
                  onChange={(e) => {
                    setPageData({
                      ...pageData,
                      userid: e.target.value,
                    });
                  }}
                  className={modules["customInp"]}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                marginTop: "1rem",
                marginLeft: "8rem",
              }}
            >
              <div className={modules["btn"]} onClick={searchHandler}>
                Search
              </div>
              <div className={modules["btn"]} onClick={submitHandler}>
                Submit
              </div>
              <div className={modules["btn"]} onClick={resetHandler}>
                Close
              </div>
            </Grid>
            <Grid
              sx={{
                marginTop: "1rem",
              }}
            >
              {pageData.searchData?.length > 0 ? (
                <table className={modules["customTbl"]}>
                  <thead>
                    <th>Sr.No.</th>
                    <th>DSS Instance</th>
                    <th>
                      <input type="checkbox" onClick={selectAllLinkHandler} />
                      IsLinked
                    </th>
                    <th>IsDefault</th>
                  </thead>
                  <tbody>
                    {pageData?.searchData?.map((ele, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{ele.instanceuid}</td>
                          <td>
                            <input
                              type="checkbox"
                              checked={linkBox[index]}
                              onClick={() => linkHandler(index)}
                            />
                          </td>
                          <td>
                            {linkBox[index] ? (
                              <input
                                type="checkbox"
                                checked={checkBox[index]}
                                name="grp1"
                                onClick={() => defaultHandler(index)}
                              />
                            ) : (
                              <input type="checkbox" defaultChecked={false} />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
