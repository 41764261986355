import React, { useState } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button
} from "@mui/material";
import modules from "./InstanceDivisionDepartmentLinking.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';


const DummyData = [
  {
    id: 1,
    col2: "Aerospace",
    col3: "Aerospace",
  },
  {
    id: 2,
    col2: "Innovation and Design Center",
    col3: "IDC",
  },
  {
    id: 3,
    col2: "GB Corporate Projects",
    col3: "GB_CORP",
  },
  {
    id: 4,
    col2: "PSS Gogrej Security Solutions",
    col3: "SSD_PSS",
  },
  {
    id: 5,
    col2: "G&B Finance",
    col3: "G&B Finance",
  },
];

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const InstanceDivisionDepartmentLinking = () => {
  const [searchValue, setSearchValue] = useState("");
  const [dropdownData, setDropdownData] = useState([
    "Select",
    "Instance 2",
    "Instance 3",
  ]);
  const [filteredData, setFilteredData] = useState(DummyData);
  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);

  const handleSearch = () => {
    const filtered = DummyData.filter((item) =>
      item.col2.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleReset = () => {
    setSearchValue("");
    setFilteredData(DummyData);
  };

  const [radioValue, setRadioValue] = useState("yes");

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Active Projects</a>
              </div>
            </div>

            <Grid container className={modules["parent-container"]}>

           

         
            <Grid container className={modules["btn1-cont"]}>
              <span><input type="file" className={modules["input1_css"]} /></span>
                            <button className={modules["btn1"]} onClick={handleSearch}>
                               Upload Master
                            </button>
                            <button className={modules["btn2"]} onClick={handleReset}>
                                Download Master
                            </button>
            </Grid>
            
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InstanceDivisionDepartmentLinking;
