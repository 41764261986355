import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/CM1.jpg";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";
import RiskAndMitigationPlan from "../CCPM/RiskManagement/RiskAndMitigationPlan";
import RIMP1 from "../HelpPopups/images/RIMP1.jpg";
import RAMP3 from "../HelpPopups/images/RAMP3.jpg";
import HelpIndex from "./HelpIndex";

const RiskAndMitigationPlanhelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              paddingLeft: "1rem",
              fontFamily: "'Times New Roman', Times, serif",
            }}
          >
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <h3
              style={{
                textDecoration: "underline",
                fontFamily: "Cablibri,serif",
                fontSize: "26px",
              }}
            >
              {" "}
              Risk Identification & Mitigation plan
            </h3>
            <br></br>
            <p>
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                <Stack
                  style={{
                    width: "38px",
                    height: "41px",
                    aspectRatio: "auto 39/41",
                  }}
                >
                  <img src={RIMP1} alt="RIMP1" />
                </Stack>
              </span>
              <span>
                :- Using ”Risk” user can input the data into the “Risk
                Identification & Mitigation Plan” screen. This screen helps user
                in recording the risks regarding the project.
              </span>
            </p>
            <br></br>
            <span>❖ Description of the fields in the gird is as follows:-</span>

            <ul>
              <li>
                Project Name & Project Manager :- Data in these fields will be
                auto populated on saving the record.
              </li>
              <br></br>
              <li>
                {" "}
                Risk Type:- Risk Type should be selected from the dropdown, Risk
                types are maintained in the masters.
              </li>
              <br></br>
              <li>
                Risk:- It is for the user to mention the Risk descriptively.
              </li>
              <table class="help-tableRM">
                <tr>
                  <th> Field Name</th>
                  <th> Input Validations:-</th>
                </tr>
                <tr>
                  <td>Risk</td>
                  <td>
                    Only alphanumeric text should be allowed to enter in
                    textbox.(A-Z, a-z, 0-999)
                  </td>
                </tr>
              </table>

              <br></br>
              <li>
                Risk Category:- It is to categorize the risk according to the
                values defined in the dropdown.
              </li>
              <br></br>
              <li>
                {" "}
                Probability :- It is the probability of the risk occuring during
                the course of the project.
              </li>
              <br></br>
              <li> Impact :- Impact of the risk in a project.</li>
              <table class="help-tableRM">
                <tr>
                  <th> Field Name</th>
                  <th> Input Validations:-</th>
                </tr>
                <tr>
                  <td>Probability</td>
                  <td>
                    Only whole integer values should be allowed to enter in
                    textbox(0-999)
                  </td>
                </tr>
                <tr>
                  <td>Impact</td>
                  <td>
                    Only whole integer values should be allowed to enter in
                    textbox(0-999)
                  </td>
                </tr>
              </table>
              <br></br>
              <li>
                {" "}
                Priority Ranking:- Priority ranking should be auto calculated by
                multiplying both Probability and Impact.
              </li>
              <br></br>
              <li>
                Likely date for risk to occur as per plan:- To select the
                assumed date on which the risk can occur.
              </li>
              <table class="help-tableRM">
                <tr>
                  <th> Field Name</th>
                  <th> Input Validations:-</th>
                </tr>
                <tr>
                  <td>Likely date for risk to occur as per plan</td>
                  <td>
                    No Characters, special characters, integers should not be
                    allowed to enter manually Date should be selected from the
                    calendar view.
                  </td>
                </tr>
              </table>
              <br></br>
              <li>
                {" "}
                Mitigation action to control risk:- It is to enter the
                mitigation actions regarding the registered risk.
              </li>

              <table class="help-tableRM">
                <tr>
                  <th> Field Name</th>
                  <th> Input Validations:-</th>
                </tr>
                <tr>
                  <td>Mitigation action to control risk</td>
                  <td>
                    Only alphanumeric text should be allowed to enter in
                    textbox.(A-Z, a-z, 0-999)
                  </td>
                </tr>
              </table>
              <br></br>
              <li>
                Action By Date:- Date by which the action regarding the risk
                will be taken.
              </li>
              <table class="help-tableRM">
                <tr>
                  <th> Field Name</th>
                  <th> Input Validations:-</th>
                </tr>
                <tr>
                  <td>Action By Date</td>
                  <td>
                    No Characters, special characters, integers should not be
                    allowed to enter manually Date should be selected from the
                    calendar view.
                  </td>
                </tr>
              </table>
              <br></br>
              <li>
                Risk Category after resolution:- It is the categorization of
                risk after the action has been taken.
              </li>
              <br></br>
              <li> Trigger status:-</li>
              <br></br>
              <li>
                For Adding new records, click on “ADD” button and enter the
                required details in the available text boxes and click on
                “Insert” button.
              </li>
              <br></br>
              <li>
                For deleting any record, we must click in the check box of
                respective record and click on the Delete Button. And for
                deleting multiple records at once select the check boxes and
                click on the “Delete” button.
              </li>
              <br></br>
              <br></br>
              <Stack style={{ width: "580px", height: "313px" }}>
                <img src={RAMP3} alt="RAMP3" />
              </Stack>
              <br></br>
              <li>
                For doing any updations to the data, click on Edit button and do
                the required changes and click on update button to save the
                changes or click on Cancel button to cancel the changes done to
                the data.
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RiskAndMitigationPlanhelpPopup;
