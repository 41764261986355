import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import TTMT1 from "../HelpPopups/images/TTMT1.jpg";
import TTMT2 from "../HelpPopups/images/TTMT2.jpg";
import TTMT3 from "../HelpPopups/images/TTMT3.jpg";
import TTMT4 from "../HelpPopups/images/TTMT4.jpg";
import TTMT5 from "../HelpPopups/images/TTMT5.jpg";
import TTMT6 from "../HelpPopups/images/TTMT6.jpg";
import TTMT7 from "../HelpPopups/images/TTMT7.jpg";
import TTMT8 from "../HelpPopups/images/TTMT8.jpg";
import TTMT9 from "../HelpPopups/images/TTMT9.jpg";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const TaskTakingMoreTimehelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul >
              <h3 style={{ textDecoration: "underline" }}>
                Common Task Taking More Time
              </h3>
              <br />
              <li className="no-bullets">
                This report is basically task-based report. This is showing
                tasked completed on time and tasks delayed for completion. This
                report is available only on financial year wise and there is
                option to see it monthly, Quarterly, Half yearly and Annual.
                Also, project filter is given for the report. So that user can
                see all details or for specific projects only. If task is
                completed during the selected financial year then that task will
                be appeared in the report.
              </li>
              <br />

              <li className="no-bullets">
                Prerequisites for this report - User must be Task manager.
                Report will show only those records where user is task manager.
                If user want to see all records the he must be alternate task
                manager for the all projects.
              </li>
              <br />

              <li className="no-bullets">
                Session Path - CCPM {">>"} Reports {">>"} Common Task Taking More Time
              </li>
              <br />

              <li className="no-bullets">Report Path - This is front end report Only.</li>
              <br />
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TTMT1} alt="PLT1" />
              </Stack>
              <br />

              <li className="no-bullets">
                Report can be fetched either with or without attributes:-
              </li>
              <br />

              <li>Report without attributes (check box not ticked)</li>
              <br />

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TTMT2} alt="TTMT2" />
              </Stack>
              <br />

              <li>Select Instance for which you want to check the report</li>
              <br />

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TTMT3} alt="TTMT3" />
              </Stack>
              <br />

              <li>Select Desired financial year</li>
              <br />

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TTMT4} alt="TTMT4" />
              </Stack>
              <br />

              <li>
                Once you select financial year it will ask to select period
                which need to be select from drop down menu provided for the
                field. In Drop Down Annual, Half-Year, Quarterly & Monthly
                options are available.

                <li>Annually - If Annually is selected the
                  report will show all tasks closed annually and percentage will
                  be calculated against annual tasks closed. </li>
                <li>
                  Half Yearly - If
                  Half Yearly is selected then the report will be divided in two
                  halves and percentage will be calculated on the Half year task
                  closed.
                </li>

                <li>Quarterly - If Quarterly is selected then the report
                  will be divided in 4 quarters and percentage is calculated based
                  on the quarterly tasks closed. </li>
                <li>Monthly - If monthly is
                  selected then the report will be divided in 12 rows month wise
                  and percentage is calculated based on the monthly tasked closed.</li>
              </li>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TTMT5} alt="TTMT5" />
              </Stack>
              <br />


              <li>
                Once user select the period then filter of project name is
                appeared. User can select the project name to find the specific
                project tasks. If no project name is selected, then the report
                will be generated for the all projects for that instance. This
                filter is not mandatory. Project names are sorted as per
                financial years. So user can find easy to access project names.
              </li>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TTMT6} alt="TTMT6" />
              </Stack>
              <br />

              <li className="no-bullets">Report with Attributes:-</li>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TTMT7} alt="TTMT7" />
              </Stack>
              <br />
              <li className="no-bullets">
                If the report with attribute check box is ticked, then the next
                filters for attribute, attribute value open up.
                <br />

                <br />
                These are mandatory filters.
                <br />

                <br />
                Accordingly, the report is populated for the
                projects with the filtered attribute values. The report is
                sorted on Project name.
              </li>
              <br />
              <br />

              <sapan>Columns -</sapan>
              <br />

              <table border="1">
                <tr>
                  <td>Sr. No</td>
                </tr>
                <tr>
                  <td>Instance</td>
                </tr>
                <tr>
                  <td>Attribute</td>
                </tr>
                <tr>
                  <td>Attribute value</td>
                </tr>
                <tr>
                  <td>ProjectName</td>
                </tr>
                <tr>
                  <td>Period</td>
                </tr>
                <tr>
                  <td>Parameter</td>
                </tr>
                <tr>
                  <td>Total</td>
                </tr>
                <tr>
                  <td>Task Completion on Time</td>
                </tr>
                <tr>
                  <td>Task Completed with Delay</td>
                </tr>
              </table>
              <br />

              <li>Sr.No - This field indicates the Serial no for the rows.</li>

              <li>
                Instance - This field indicates the selected Instance by the
                user.
              </li>

              <li>
                Attribute - Attribute on which filter is applied is shown.
              </li>

              <li>Attribute value - Filter on attribute value is shown.</li>

              <li>
                Project Name - This field indicate the project name selected. If
                project name is not selected, then it will create line for each
                projects.
              </li>

              <li>
                Period - This field indicates period which is selected from the
                dropdown menu in the parameter.
              </li>

              <li>
                Parameter - This field indicated two line for each timeline. One
                is count and other is Percentage.

                <li> Count - This is total no of
                  tasks completed fall in the bracket.</li>

                <li>% - This is % of tasks
                  completed. IT is calculated as percentage of Total tasks
                  completed in the slab with total tasks completed for the
                  duration.</li>
              </li>
              <li>
                Total - This field indicates the total no of tasks completed
                during the period selected. This completed tasks are then
                bifurcated on the delay in completion.
              </li>
              <li>
                Task Completion on Time - This field indicates tasks completed
                on or before duration.
              </li>

              <span>
                Task Duration = As mentioned in the latest uploaded/ Modified
                Project
              </span>
              <span>Actual Duration = Task Actual End Date - Max of
                Predecessor Task Actual End Date</span>
              <li>
                Task Completed with Delay - This field indicates tasks completed
                after mentioned duration. Task Duration = As mentioned in the
                latest uploaded/ Modified Project Actual Duration = Task Actual
                End Date - Max of Predecessor Task Actual End Date Delay Days =
                Actual Duration - Task Duration.
              </li>

              <br />
              <br />


              <li className="no-bullets">Excel download option for the report is also provided.</li>
              <br />

              <li className="no-bullets">
                Once report is displayed user can click on the count and system
                will redirect user to View Project tasks where user can check
                which tasks are there in that count.
              </li>
              <br />

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TTMT8} alt="TTMT8" />
              </Stack>
              <br />



              <li className="no-bullets">After Click on count no -</li>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={TTMT9} alt="TTMT9" />
              </Stack>
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TaskTakingMoreTimehelpPopup;
