
import React, { useEffect, useState } from "react";
import modules from "../../ccpm.module.css";
import logo from "../../../../Assets/godrej_logo.gif";
import bread from "../../../../Assets/bread.png";
import prostream from "../../../../Assets/prostream_logo.png";
import { Box, Grid, Stack, Tab, Tabs } from "@mui/material";
import { useLocation } from "react-router-dom";
import loading from "../../../../Assets/Loading_image.gif";
import {
  GetDurationTable,
  TaskGraph,
  ViewtaskDurationHistory,
} from "../../../../Services/CCPM";
import { Line } from "react-chartjs-2";
const DurationHistory = () => {
  const data = [
    { col1: "Data 1", col2: "Data 2" },
    { col1: "Data 3", col2: "Data 4" },
    { col1: "Data 5", col2: "Data 6" },
  ];

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const projectId = query.get("projId");
  const taskId = query.get("taskId");

  const [tabDisplay, setTabDisplay] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [taskDurationHeader, setTaskDurationHeader] = useState([]);
  const [taskGraphdata, setTaskGraphdata] = useState([]);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);
    let body = {
      projectId: projectId,
      taskId: taskId,
    };
    GetDurationTable(body)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setTableData(response?.data?.success?.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    ViewtaskDurationHistory(body)
      .then((response) => {
        console.log(response, "12");
        setIsLoading(false);
        if (response.status === 200) {
          setTaskDurationHeader(response?.data?.success?.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    TaskGraph(body)
      .then((response) => {
        console.log(response, "12");
        setIsLoading(false);
        if (response.status === 200) {
          setTaskGraphdata(response?.data?.success?.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const datachart = {
    labels:
      taskGraphdata &&
      taskGraphdata?.length > 0 &&
      taskGraphdata?.map((item) => item?.date),
    datasets: [
      {
        label: "",
        data:
          taskGraphdata &&
          taskGraphdata?.length > 0 &&
          taskGraphdata?.map((item) => item?.remainingduration),
        fill: false,
        borderColor: "rgba(0, 0, 0, 0)", // Make the line invisible
        backgroundColor: "rgba(75, 192, 192, 1)", // Point color
        pointRadius: 5, // Size of the points
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        pointHoverRadius: 7, // Size of the points on hover
      },
    ],
  };
  const options = {
    elements: {
      line: {
        borderWidth: 0,
      },
      point: {
        radius: 5,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: () => "",
          label: () => "",
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Dates",
        },
      },
      y: {
        title: {
          display: true,
          text: "Remaining Duration",
        },
      },
    },
  };

  return (
    <>
      {IsLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div style={{ overflowX: "hidden", height: "100vh" }}>

      <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              paddingLeft: "1rem",
              fontWeight: "bold",
              fontFamily: "Times New Roman",
            }}
          >
            <img src={logo} alt="Godrej" />
          </Stack>
          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <div style={{marginLeft:"5vw",marginTop:"20px"}}>
         <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>View Project Task</a>
                <img src={bread} alt="" />
                <a>Remaining Duration History</a>
              </div>
         </div>
        <div
          style={{
            marginLeft: "1%",
            padding: "2%",
            width: "94%",
          }}
        >
          <table style={{ width: "50%" }}>
            <tbody style={{ width: "100%" }}>
              {taskDurationHeader?.map((item) => {
                return (
                  <tr style={{ padding: "0px" }}>
                    <td>
                      <span style={{ marginRight: "5px" }}>
                        <strong>Project Name :</strong>
                      </span>
                      <span>{item?.projectname}</span>
                    </td>

                    <td>
                      <span style={{ marginRight: "5px" }}>
                        <strong>Task Name :</strong>
                      </span>
                      <span>{item?.taskname}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {tabDisplay && (
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "2rem",
                padding: "10px",
                // marginLeft: "3rem",
              }}
            >
              <Tabs
                className={modules["tabs_css"]}
                value={tabValue}
                onChange={handleTabChange}
                sx={{
                  "& .MuiTabs-indicator": { display: "none" },
                  "& .Mui-selected": { color: "inherit" },
                }}
              >
                <Tab
                  // sx={{
                  //  color: "tabValue === 0 ? '#fff' : '#000'",
                  // }}

                  className={`${modules["tab_css"]} ${
                    tabValue === 0 ? modules["tab_css_selected"] : ""
                  }`}
                  label="Remaining Duration History"
                />
                <Tab
                  className={`${modules["tab_css"]} ${
                    tabValue === 1 ? modules["tab_css_selected"] : ""
                  }`}
                  label="Sub tasks Closure Trend"
                />
              </Tabs>
            </Box>
            {tabValue === 0 && (

             <Box className={modules["tab-box"]}>
              <Grid
                container
                columnSpacing={{ xs: 2 }}
                sx={{ alignItems: "center" ,padding:"10px"}}
              >
              <div
              
               style={{
                   backgroundColor: "gray",
                   paddingLeft: "60px",
                    paddingRight: "60px",
                    paddingTop:"15px",
                   paddingBottom:"15px",
                  borderRadius: "8px",
                  width: "70vw",
                  height: "70vh",
                  color : "gray",
                   border : "2px solid "
                }}>
               <div
                style={{
                   backgroundColor: "white",
                  // padding: "20px",
                  borderRadius: "8px",
                  width: "70vw",
                  height: "70vh",
                  color : "gray",
                   border : "2px solid"
                }}
              >
                <Line data={datachart} options={options} />
              </div>
              </div>
              </Grid>
            </Box>
             
            )}

            {tabValue === 1 && (
              <div style={{ padding: "10px" }}>
                <table
                  style={{
                    borderCollapse: "collapse",
                    margin: "20px 0",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                        }}
                      >
                        Date
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                        }}
                      >
                        Closed
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                        }}
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{ border: "1px solid black", padding: "8px" }}
                        >
                          {item.date}
                        </td>
                        <td
                          style={{ border: "1px solid black", padding: "8px" }}
                        >
                          {item.closed}
                        </td>
                        <td
                          style={{ border: "1px solid black", padding: "8px" }}
                        >
                          {item.total}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DurationHistory;

