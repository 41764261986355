import React, { useEffect, useState } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import moduless from "./AlternateProjectManager.module.css";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import saveIcon from "../../../Assets/Save.jpg";
import deleteicon from "../../../Assets/i6.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  GetInstancesListt,
  GetAccessType,
  GetAlternatePM,
  SelectionGrid,
  GetPM,
  GetExistingPM,
  ExistingPMgrid,
  SubmitAlternatePM,
  DetailGridSave,
  DetailGridDel,
} from "../../../Services/CCPM";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import "./CalendarStyles.css";
import { styled } from "@mui/system";
import loading from "../../../Assets/Loading_image.gif";
import { Popper, Paper, Popover } from "@mui/material";

//custom font size for radio button label
const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiTypography-root": {
    fontSize: "12px",
    fontFamily: "'Work Sans', sans-serif",
    color: "#414141",
    height: "14px",
    width: "138.79px",
  },
});

const GreyCheckbox = styled(Checkbox)({
  color: "grey",
  padding: "0px!important",
  "&.Mui-checked": {
    color: "grey",
  },
  "& .MuiSvgIcon-root": {
    width: "13px",
    height: "13px",
  },
});

//ArrowLine Component for Select Dropdown
function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const AlternateProjectManager = () => {
  const [showdates, setShowdates] = useState(false);
  const [showbutton, setShowbutton] = useState(false);

  //function to handle no update alert box
  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const [updatedata, setUpdatedata] = useState({}); //handle no update alert boxx

  //for calendar footer
  const today = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedToday = today.toLocaleDateString("en-US", options);
  //for comparing entered date with current date
  const cyear = today.getFullYear();
  const cmonth = String(today.getMonth() + 1).padStart(2, "0");
  const cday = String(today.getDate()).padStart(2, "0");
  const cformattedDate = `${cyear}-${cmonth}-${cday}`;

  //edit alternate manager calendar and date states
  const [anchorEl, setAnchorEl] = useState({});
  const [dates, setDates] = useState({});
  const handleClick = (rowIndex, field, event) => {
    setAnchorEl({ ...anchorEl, [`${rowIndex}-${field}`]: event.currentTarget });
  };
  const handleClose = (rowIndex, field) => {
    setAnchorEl({ ...anchorEl, [`${rowIndex}-${field}`]: null });
  };
  const handleDateChange = (rowIndex, field, date) => {
    //display properly in input field
    const displayDateOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date
      .toLocaleDateString("en-GB", displayDateOptions)
      .replace(/ /g, " ");
    setDates({ ...dates, [`${rowIndex}-${field}`]: formattedDate });
    handleClose(rowIndex, field);
  };

  //add alternate manager calendar and date states
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? "simple-popover" : undefined;
  const id2 = open2 ? "simple-popover" : undefined;

  //for calendar popup days representation (Mo Tu We....)
  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: "short" }).slice(0, 2);
  };

  const [isLoading, setIsLoading] = useState(false); //gif

  useEffect(() => {
    setIsLoading(true);
    let body = {
      userId: sessionStorage.getItem("loginId"),
    };
    GetInstancesListt(body) //to get instance list based on user id
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setInstance(response.data.success.data);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    GetAccessType() //get access type list
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setAccesstype(res.data.success.data);
        } else if (res?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(res?.response?.data?.message);
        } else if (res?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(res?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(res?.response?.data?.success?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //STORE INSTANCE LIST AND SELECTED INSTANCE (COMMON FOR BOTH RADIO OPTIONS)
  const [instance, setInstance] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState("");

  //ADD ALTERNATE MANAGER VARIABLES
  const [accesstype, setAccesstype] = useState([]);
  const [selectedAccesstype, setSelectedAccesstype] = useState("");
  const [alternatePM, setAlternatePM] = useState([]);
  const [selectedAlternatePM, setSelectedAlternatePM] = useState("");
  const [selectiongrid, setSelectiongrid] = useState([]);
  const [PM, setPM] = useState([]);
  const [selectedPM, setSelectedPM] = useState("");

  //EDIT ALTERNATE MANAGER VARIABLES
  const [existingPM, setExistingPM] = useState([]);
  const [selectedExistingPM, setSelectedExistingPM] = useState("");
  const [detailgrid, setDetailgrid] = useState([]);
  const [checkedrows2, setCheckedrows2] = useState([]);

  //CALENDAR VARIABLES FOR RADIO OPTION 1
  const [displaydate1, setDisplaydate1] = useState("");
  const [displaydate2, setDisplaydate2] = useState("");
  const [passdate1, setPassdate1] = useState(""); //formatted date (for passing to api)
  const [passdate2, setPassdate2] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");

  //TO FORMAT THE RECEIVED DATE TO DESIRED FORMAT
  function formatDate(inputDate, i) {
    if (i === "b") {
      const [year, month, day] = inputDate.split("/");
      const date = new Date(year, month - 1, day);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-GB", options);
    } else {
      const [day, month, year] = inputDate.split("/");
      const date = new Date(year, month - 1, day);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-GB", options);
    }
  }

  //functions called when date is selected from both calendars (Add Alternate Manager)
  const handleCalendar1 = (date) => {
    setAnchorEl1(null);
    setFromdate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setDisplaydate1(formatDate(`${day}/${month}/${year}`, "f")); //formatted date (for displaying in input field)
    setPassdate1(`${year}-${month}-${day}`); //formatted date (for passing to api)
  };
  const handleCalendar2 = (date) => {
    setAnchorEl2(null);
    setTodate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setDisplaydate2(formatDate(`${day}/${month}/${year}`));
    setPassdate2(`${year}-${month}-${day}`);
  };

  const [selectedValue, setSelectedValue] = useState(""); //radio button index

  //function to handle radio button index change
  const handleChange = (event) => {
    setDetailgrid([]);
    setShowdates(false);
    setShowbutton(false);
    setSelectedValue(event.target.value);
    if (instance.length > 0) {
      if (event.target.value === "option1") {
        handleInstance(instance[0].id);
      } else {
        handleInstance2(instance[0].id);
      }
    }
  };

  //triggered when submit button is clicked (Add Alternate Manager)
  const handleSearch = () => {
    console.log(getCheckedRows());
    if (
      selectedInstance === "Select" ||
      selectedAlternatePM === "Select" ||
      selectedAlternatePM === "" ||
      selectedAccesstype === "Select" ||
      selectedAccesstype === "" ||
      passdate1 === "" ||
      passdate2 === ""
    ) {
      alert("Please Enter/Select Values for All Mandatory Fields");
    } else if (
      selectedAccesstype === 3 &&
      (selectedPM === "Select" || selectedPM === "")
    ) {
      alert("Please Enter/Select Values for All Mandatory Fields");
    } else if (passdate1 < cformattedDate || passdate2 < cformattedDate) {
      alert(
        "Access From Date and Access To Date should be greater than or equal to Today"
      );
    } else {
      setIsLoading(true);
      let body = {
        accessTypeId:
          selectedAccesstype !== "" && selectedAccesstype !== "Select"
            ? selectedAccesstype
            : 0,
        projectManagerId:
          selectedPM !== "" && selectedPM !== "Select"
            ? parseInt(selectedPM)
            : 0,
        idList:
          getCheckedRows().length === 0
            ? [
              {
                divId: parseInt(selectedInstance) || 0,
                alternatePMId: parseInt(selectedAlternatePM) || 0,
                accessTypeId: selectedAccesstype,
                projectManagerId:
                  selectedPM !== "" && selectedPM !== "Select"
                    ? parseInt(selectedPM)
                    : 0,
                accessFromDate: passdate1,
                accessToDate: passdate2,
                isViewOnly: isChecked,
                createdBy: parseInt(sessionStorage.getItem("loginId")),
              },
            ]
            : getCheckedRows(),
      };
      SubmitAlternatePM(body)
        .then((response) => {
          if (response.status === 200) {
            setShowdates(false);
            setShowbutton(false);
            setIsLoading(false);
            if (response?.data?.message === undefined) {
              alert(response?.data[0]?.message);
            } else {
              alert(response?.data?.message);
            }
            handleInstance(selectedInstance);
          } else if (response?.response?.data?.message !== undefined) {
            setIsLoading(false);
            setShowdates(false);
            setShowbutton(false);
            alert(response?.response?.data?.message);
          } else if (response?.response?.data?.error?.message !== undefined) {
            setShowdates(false);
            setShowbutton(false);
            setIsLoading(false);
            alert(response?.response?.data?.error?.message);
          } else {
            setIsLoading(false);
            setShowdates(false);
            setShowbutton(false);
            alert(response?.response?.data?.success?.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //triggered when reset button is clicked
  const handleReset = () => {
    setShowdates(false);
    setShowbutton(false);
    // setSelectedInstance('Select')
    handleInstance(selectedInstance);
    setSelectedAccesstype("");
    setAlternatePM([]);
    setSelectedAlternatePM("");
    setPM([]);
    setSelectedPM("");
    setSelectiongrid([]);
    setSstate({
      checkedRows: new Set(),
      allChecked: false,
    });
    setDisplaydate1("");
    setDisplaydate2("");
    setPassdate1("");
    setPassdate2("");
    setIsChecked(0);
    setFromdate("");
    setTodate("");
  };

  //HANDLE INSTANCE FOR ADD ALTERNATE MANAGER
  const handleInstance = (i) => {
    setIsLoading(true);
    setShowdates(false);
    setShowbutton(false);
    setSelectedInstance(i);
    let body = {
      divId: i,
    };
    GetAlternatePM(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setAlternatePM(response.data.success.data);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setSelectedAlternatePM("Select");
    setSelectedAccesstype("");
    setDisplaydate1("");
    setDisplaydate2("");
    setPassdate1("");
    setPassdate2("");
    setFromdate("");
    setTodate("");
    setSelectiongrid([]);
    setSstate({
      checkedRows: new Set(),
      allChecked: false,
    });
    setPM([]);
    setSelectedPM("");
    setIsChecked(0);
  };

  //HANDLE INSTANCE FOR EDIT ALTERNATE MANAGER
  const handleInstance2 = (i) => {
    setIsLoading(true);
    setSelectedInstance(i);
    let body = {
      divId: i,
    };
    GetExistingPM(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          console.log(response);
          setExistingPM(response.data.success.data);
          // if (response.data.success.data.length === 0) {
          //   alert('No Alternate ProjectMgr Access Detail Data found')
          // }
          // else {
          //   setExistingPM(response.data.success.data);
          // }
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setSelectedExistingPM("Select");
    setDetailgrid([]);
    setCheckedrows2([]);
    setAnchorEl({});
    setDates({});
  };

  //function to handle access type change
  const handleAccesstype = (i) => {
    setSelectedAccesstype(parseInt(i));
    setShowdates(false);
    if (parseInt(i) === 2) {
      setPM([]);
      setSelectedPM("");
      setIsLoading(true);
      let body = {
        accessType: 2,
        divId: selectedInstance,
        apmId:
          selectedAlternatePM !== "" && selectedAlternatePM !== "Select"
            ? selectedAlternatePM
            : 0,
      };
      SelectionGrid(body) //get selection table data
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            console.log(response);
            setSelectiongrid(response.data.success.data);
            setShowdates(true);
            setShowbutton(true);
          } else if (response?.response?.status === 404) {
            setShowdates(false);
            alert(response?.response?.data?.error?.message);
            setShowdates(true);
            setShowbutton(false);
            setIsLoading(false);
          } else if (response?.response?.data?.message !== undefined) {
            setIsLoading(false);
            setShowdates(false);
            alert(response?.response?.data?.message);
            setShowdates(true);
            setShowbutton(false);
          } else if (response?.response?.data?.error?.message !== undefined) {
            setIsLoading(false);
            setShowdates(false);
            alert(response?.response?.data?.error?.message);
            setShowdates(true);
            setShowbutton(false);
          } else {
            setIsLoading(false);
            setShowdates(false);
            alert(response?.response?.data?.success?.message);
            setShowdates(true);
            setShowbutton(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setSstate({
        checkedRows: new Set(),
        allChecked: false,
      });
    } else if (parseInt(i) === 3) {
      setIsLoading(true);
      setSelectiongrid([]);
      setSstate({
        checkedRows: new Set(),
        allChecked: false,
      });
      let body = {
        divId: selectedInstance,
        alternatePMId:
          selectedAlternatePM !== "" && selectedAlternatePM !== "Select"
            ? selectedAlternatePM
            : 0,
      };
      GetPM(body) //get list of project managers
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            console.log(response);
            if (response.data.success.data.length === 0) {
              // setSelectedAccesstype('Select')
              setShowdates(false);
              alert("No Project Manager found for selection");
              setShowdates(true);
              setShowbutton(false);
            } else {
              setPM(response.data.success.data);
              setShowdates(true);
              setShowbutton(true);
            }
          } else if (response?.response?.data?.message !== undefined) {
            setIsLoading(false);
            setShowdates(false);
            alert(response?.response?.data?.message);
            setShowdates(true);
            setShowbutton(false);
          } else if (response?.response?.data?.error?.message !== undefined) {
            setIsLoading(false);
            setShowdates(false);
            alert(response?.response?.data?.error?.message);
            setShowdates(true);
            setShowbutton(false);
          } else {
            setIsLoading(false);
            setShowdates(false);
            alert(response?.response?.data?.success?.message);
            setShowdates(true);
            setShowbutton(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setSelectedPM("Select");
    } else {
      setShowdates(true);
      setShowbutton(true);
      setSelectiongrid([]);
      setSstate({
        checkedRows: new Set(),
        allChecked: false,
      });
      setPM([]);
      setSelectedPM("");
    }
  };

  //function to handle existing project manager change (EDIT ALTERNATE MANAGER)
  const handleExistingPM = (i) => {
    setIsLoading(true);
    setSelectedExistingPM(i);
    let body = {
      divId: selectedInstance,
      alternatePMId: i !== "" && i !== "Select" ? i : 0,
    };
    ExistingPMgrid(body) //get detail table data
      .then((response) => {
        console.log("detail grid", response);
        if (response.status === 200) {
          setIsLoading(false);
          console.log(response);
          if (response.data.success.data.length === 0) {
            alert("No Alternate ProjectMgr Access Detail Data found");
          } else {
            setDetailgrid(response.data.success.data);
            setCheckedrows2(response.data.success.data);
            setDates({});
            setAnchorEl({});
          }
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //TABLE CHECKBOX SELECTION PART (ADD ALTERNATE MANAGER) - SELECTION GRID
  const [sstate, setSstate] = useState({
    checkedRows: new Set(),
    allChecked: false,
  });
  const handleCheckboxChange = (index) => {
    setSstate((prevState) => {
      const checkedRows = new Set(prevState.checkedRows);
      if (checkedRows.has(index)) {
        checkedRows.delete(index);
      } else {
        checkedRows.add(index);
      }
      const allChecked = checkedRows.size === selectiongrid.length;
      return { checkedRows, allChecked };
    });
  };
  const handleTableHeadCheckboxChange = () => {
    setSstate((prevState) => {
      const allChecked = !prevState.allChecked;
      const checkedRows = new Set();
      if (allChecked) {
        selectiongrid.forEach((_, index) => checkedRows.add(index));
      }
      return { checkedRows, allChecked };
    });
  };
  const getCheckedRows = () => {
    //LIST PASSED TO API
    return selectiongrid
      .filter((_, index) => sstate.checkedRows.has(index))
      .map((item) => ({
        divId: selectedInstance,
        alternatePMId: selectedAlternatePM,
        accessTypeId: selectedAccesstype,
        projectManagerId: item.projectmanagerid || 0,
        accessFromDate: passdate1,
        accessToDate: passdate2,
        isViewOnly: isChecked,
        createdBy: parseInt(sessionStorage.getItem("loginId")),
      }));
  };

  //VIEW PROJECT ACCESS CHECKBOX PART (ADD ALTERNATE MANAGER)
  const [isChecked, setIsChecked] = useState(0);
  const handleViewProjAccessCheck = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };

  //VIEW ONLY ACCESS CHECKBOX PART (EDIT ALTERANTE MANAGER)
  const handleViewProjAccessCheck2 = (id) => {
    setDetailgrid((prevDetailgrid) =>
      prevDetailgrid.map((row) =>
        row.id === id
          ? { ...row, isviewonly: row.isviewonly === 1 ? 0 : 1 }
          : row
      )
    );
  };

  //function to handle alternate project manager change
  const handleAlternatePM = (i) => {
    setSelectedAlternatePM(i);
    setSelectedAccesstype("Select");
  };

  //HANDLE SAVE AND DELETE (CONVERTS CALENDAR INPUT DATE INTO API PASS DATE FORMAT (YYYY-MM-DD))
  const formatDateToYYYYMMDD = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //HANDLE SAVE AND DELETE (CONEVRTS THE DATE RECEIVED FROM RENSPONSE INTO API PASS DATE FORMAT (YYYY-MM-DD))
  const convertDateFormat = (dateString) => {
    return dateString.replace(/\//g, "-");
  };

  //function to handle save button click
  const handleSave = (index, id, accestypeid, from, to, itemfrom, itemto) => {
    let passdate1 = formatDateToYYYYMMDD(from);
    let passdate2 = formatDateToYYYYMMDD(to);
    if (
      checkedrows2[index].isviewonly === detailgrid[index].isviewonly &&
      (passdate1 === convertDateFormat(itemfrom) ||
        passdate1 === "" ||
        passdate1 === "NaN-NaN-NaN") &&
      (passdate2 === convertDateFormat(itemto) ||
        passdate2 === "" ||
        passdate2 === "NaN-NaN-NaN")
    ) {
      alert("No Change To Update");
    } else {
      let body = {
        id: id,
        accessTypeId: accestypeid,
        accessFromDate:
          passdate1 !== "" && passdate1 !== "NaN-NaN-NaN"
            ? passdate1
            : convertDateFormat(itemfrom),
        accessToDate:
          passdate2 !== "" && passdate2 !== "NaN-NaN-NaN"
            ? passdate2
            : convertDateFormat(itemto),
        isViewOnly: detailgrid.find((row) => row.id === id)?.isviewonly ? 1 : 0,
        isDelete: 0,
        modifiedBy: sessionStorage.getItem("loginId"),
      };
      if (deepEqual(body, updatedata)) {
        alert("No Change To Update");
      } else {
        setIsLoading(true);
        DetailGridSave(body)
          .then((response) => {
            if (response.status === 200) {
              setIsLoading(false);
              alert(response.data.message);
              setCheckedrows2(detailgrid);
              setUpdatedata(body);
            } else if (response?.response?.data?.message !== undefined) {
              setIsLoading(false);
              alert(response?.response?.data?.message);
            } else if (response?.response?.data?.error?.message !== undefined) {
              setIsLoading(false);
              alert(response?.response?.data?.error?.message);
            } else {
              setIsLoading(false);
              alert(response?.response?.data?.success?.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  //function to handle delete button click
  const handleDelete = (id, accestypeid, from, to, itemfrom, itemto) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete?`);
    if (confirmDelete) {
      let passdate1 = formatDateToYYYYMMDD(from);
      let passdate2 = formatDateToYYYYMMDD(to);
      setIsLoading(true);
      let body = {
        id: id,
        accessTypeId: accestypeid,
        accessFromDate:
          passdate1 !== "" && passdate1 !== "NaN-NaN-NaN"
            ? passdate1
            : convertDateFormat(itemfrom),
        accessToDate:
          passdate2 !== "" && passdate2 !== "NaN-NaN-NaN"
            ? passdate2
            : convertDateFormat(itemto),
        isViewOnly: detailgrid.find((row) => row.id === id)?.isviewonly ? 1 : 0,
        isDelete: 1,
        modifiedBy: sessionStorage.getItem("loginId"),
      };
      DetailGridDel(body)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            if (response?.data?.message === "Record Deleted Successfully.") {
              alert("Record Saved Successfully");
            }
            setDetailgrid(detailgrid.filter((row) => row.id !== id));
            setCheckedrows2(checkedrows2.filter((row) => row.id !== id));
          } else if (response?.response?.data?.message !== undefined) {
            setIsLoading(false);
            alert(response?.response?.data?.message);
          } else if (response?.response?.data?.error?.message !== undefined) {
            setIsLoading(false);
            alert(response?.response?.data?.error?.message);
          } else {
            setIsLoading(false);
            alert(response?.response?.data?.success?.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  return (
    <div
      style={{
        overflowX:
          selectedExistingPM !== "" && detailgrid.length > 0
            ? "auto"
            : "hidden",
      }}
    >
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
          <Home page={"AlternatePM"}/>
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>Project Plan</a>
                <img src={bread} alt="" />
                <a>Alternate Project Manager</a>
              </div>
            </div>
          </Grid>

          <Grid item lg={12} className={modules["radio-buttons"]}>
            <RadioGroup row value={selectedValue} onChange={handleChange}>
              <CustomFormControlLabel
                value="option1"
                control={<Radio className={modules["radio-scale"]} />}
                label="Add Alternate Manager"
                className={modules["radio-label"]}
              />
              <CustomFormControlLabel
                value="option2"
                control={<Radio className={modules["radio-scale"]} />}
                label="Edit Alternate Manager"
                className={modules["radio-label"]}
              />
            </RadioGroup>
          </Grid>

          {selectedValue === "option1" && (
            <Grid container className={modules["parent-container-apm"]}>
              <div className={modules["bottom-grid-heading-apm"]}>
                Add Alternate Manager
              </div>
              <Stack
                spacing={2}
                direction="row"
                className={modules["each-entry-apm"]}
              >
                <div className={modules["input-labels-apm"]}>
                  Instance:<span className={modules["asterisk"]}>*</span>{" "}
                </div>
                <select
                  className={modules["dropdownSelect1-apm"]}
                  value={selectedInstance}
                  style={{ width: "18.75rem", height: "2.1rem" }}
                  onChange={(e) => handleInstance(e.target.value)}
                >
                  <option value="Select">SELECT</option>
                  {instance.length > 0 &&
                    instance?.map((i) => (
                      <option value={i.id} key={i}>
                        {i.name}
                      </option>
                    ))}
                </select>
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                className={modules["each-entry-apm"]}
              >
                <div className={modules["input-labels-apm"]}>
                  Alternate Project Manager(To Be):
                  <span className={modules["asterisk"]}>*</span>{" "}
                </div>
                <select
                  className={modules["dropdownSelect1-apm"]}
                  value={selectedAlternatePM}
                  style={{ width: "18.75rem", height: "2.1rem" }}
                  onChange={(e) => handleAlternatePM(e.target.value)}
                >
                  {alternatePM.length > 0 && (
                    <option value="Select">SELECT</option>
                  )}
                  {alternatePM.length > 0 &&
                    alternatePM?.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.username}
                      </option>
                    ))}
                </select>
              </Stack>
              {selectedAlternatePM !== "" &&
                selectedAlternatePM !== "Select" && (
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["each-entry-apm"]}
                  >
                    <div className={modules["input-labels-apm"]}>
                      Access Type:<span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <select
                      className={modules["dropdownSelect1-apm"]}
                      value={selectedAccesstype}
                      style={{ width: "18.75rem", height: "2.1rem" }}
                      onChange={(e) => handleAccesstype(e.target.value)}
                    >
                      {accesstype.length > 0 && (
                        <option value="Select">SELECT</option>
                      )}
                      {accesstype.length > 0 &&
                        accesstype?.map((i) => (
                          <option value={i.id} key={i.id}>
                            {i.accesstype}
                          </option>
                        ))}
                    </select>
                  </Stack>
                )}
              {selectedAccesstype === 2 && showdates && (
                <Grid
                  container
                  className={[
                    modules["child-container"],
                    modules["bottom-grid"],
                  ].join(" ")}
                >
                  <div>Select Project</div>
                  <Grid container className={modules["selection-grid"]}>
                    {selectiongrid.length > 0 ? (
                      <div className={modules["table-container-apm"]}>
                        <Table
                          className={[
                            modules["custom-table-apm"],
                            modules["custom-table-th"],
                          ].join(" ")}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <input
                                  type="checkbox"
                                  checked={sstate.allChecked}
                                  onChange={handleTableHeadCheckboxChange}
                                  className={modules["custom-checkbox"]}
                                />
                              </TableCell>
                              <TableCell>Sr. No.</TableCell>
                              <TableCell>Project Manager</TableCell>
                              <TableCell>Project Name</TableCell>
                              <TableCell>Customer Name</TableCell>
                              <TableCell>ERP ProjectCompany</TableCell>
                              <TableCell>ERP Project</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selectiongrid.length > 0 &&
                              selectiongrid?.map((item, index) => (
                                <TableRow
                                  key={index}
                                  className={modules["table-row"]}
                                >
                                  <TableCell>
                                    <input
                                      type="checkbox"
                                      checked={sstate.checkedRows.has(index)}
                                      onChange={() =>
                                        handleCheckboxChange(index)
                                      }
                                      className={modules["custom-checkbox"]}
                                    />
                                  </TableCell>
                                  <TableCell className={modules["table-row"]}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell className={modules["table-row"]}>
                                    {item.projectmanager}
                                  </TableCell>
                                  <TableCell className={modules["table-row"]}>
                                    {item.projectname}
                                  </TableCell>
                                  <TableCell className={modules["table-row"]}>
                                    {item.customername}
                                  </TableCell>
                                  <TableCell className={modules["table-row"]}>
                                    {item.erpprojectcompany}
                                  </TableCell>
                                  <TableCell className={modules["table-row"]}>
                                    {item.erpprojectno}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      <div style={{ marginTop: '-5.5rem', marginLeft: '0.5rem' }}>
                        <div style={{ lineHeight: '0.8rem', marginBottom: '0.5rem', paddingLeft: '0.5rem' }}>
                          No Record <br />Found.
                        </div>
                        <hr style={{ width: '6rem', border: '1px solid #a4bf47', transform: 'scaleY(0.2)' }} />
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}
              {selectedAccesstype === 3 && showdates && (
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-apm"]}
                >
                  <div className={modules["input-labels-apm"]}>
                    Project Manager:
                    <span className={modules["asterisk"]}>*</span>{" "}
                  </div>
                  <select
                    className={modules["dropdownSelect1-apm"]}
                    value={selectedPM}
                    style={{ width: "18.75rem", height: "2.1rem" }}
                    onChange={(e) => setSelectedPM(e.target.value)}
                  >
                    {PM.length > 0 && <option value="Select">SELECT</option>}
                    {PM.length > 0 &&
                      PM?.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.username}
                        </option>
                      ))}
                  </select>
                </Stack>
              )}
              {showdates && (
                <>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={[
                      modules["each-entry-apm"],
                      modules["input-field-container-apm"],
                    ].join(" ")}
                  >
                    <div className={modules["input-labels-apm"]}>
                      Access From Date:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      value={displaydate1}
                      onChange={(e) => setDisplaydate1(e.target.value)}
                      className={
                        open1
                          ? modules["input-field-apm-focused"]
                          : modules["input-field-apm"]
                      }
                      onClick={(event) => setAnchorEl1(event.currentTarget)}
                    />
                    <Popover
                      id={id1}
                      open={open1}
                      anchorEl={anchorEl1}
                      onClose={() => setAnchorEl1(null)}
                      placement="bottom-start"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Paper>
                        <Calendar
                          onChange={handleCalendar1}
                          value={fromdate}
                          locale="en-US"
                          formatShortWeekday={formatShortWeekday}
                        />
                        <div className={modules["calendar-footer"]}>
                          Today: {formattedToday}
                        </div>
                      </Paper>
                    </Popover>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={[
                      modules["each-entry-apm"],
                      modules["input-field-container-apm"],
                    ].join(" ")}
                  >
                    <div className={modules["input-labels-apm"]}>
                      Access To Date:
                      <span className={modules["asterisk"]}>*</span>{" "}
                    </div>
                    <input
                      value={displaydate2}
                      onChange={(e) => setDisplaydate2(e.target.value)}
                      className={modules["input-field-apm"]}
                      onClick={(event) => setAnchorEl2(event.currentTarget)}
                    />
                    <Popover
                      id={id2}
                      open={open2}
                      anchorEl={anchorEl2}
                      onClose={() => setAnchorEl2(null)}
                      placement="bottom-start"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Paper>
                        <Calendar
                          onChange={handleCalendar2}
                          value={todate}
                          locale="en-US"
                          formatShortWeekday={formatShortWeekday}
                        />
                        <div className={modules["calendar-footer"]}>
                          Today: {formattedToday}
                        </div>
                      </Paper>
                    </Popover>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={modules["check-box-container"]}
                  >
                    <GreyCheckbox
                      checked={isChecked === 1}
                      onChange={handleViewProjAccessCheck}
                    />
                    <div
                      className={[
                        modules["check-box-container"],
                        modules["input-labels-apm-check"],
                      ].join(" ")}
                    >
                      View Project Access Only
                    </div>
                  </Stack>
                </>
              )}
              {showdates && (
                <Grid
                  container
                  className={[
                    modules["btn1-apm-cont"],
                    modules["button-container-apm"],
                  ].join(" ")}
                >
                  {showbutton && (
                    <button
                      className={[
                        modules["btn1-apm"],
                        modules["button-container-button"],
                      ].join(" ")}
                      onClick={handleSearch}
                    >
                      Submit
                    </button>
                  )}
                  <button className={modules["btn2-apm"]} onClick={handleReset}>
                    Reset
                  </button>
                </Grid>
              )}
            </Grid>
          )}
          {selectedValue === "option2" && (
            <Grid container className={modules["parent-container-apm-edit"]}>
              <div className={modules["bottom-grid-heading-apm"]}>
                Edit Alternate Manager
              </div>
              <Stack direction="row">
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-apm"]}
                >
                  <div className={modules["input-labels-apm-check"]}>
                    Instance:<span className={modules["asterisk"]}>*</span>{" "}
                  </div>
                  <select
                    className={modules["dropdownSelect1-apm"]}
                    value={selectedInstance}
                    onChange={(e) => handleInstance2(e.target.value)}
                  >
                    <option value="Select">SELECT</option>
                    {instance.length > 0 &&
                      instance?.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.name}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={[
                    modules["each-entry-apm"],
                    modules["span-text-apm"],
                  ].join(" ")}
                >
                  <div className={modules["input-labels-apm-check"]}>
                    Alternate Project Manager:
                    <span className={modules["asterisk"]}>*</span>{" "}
                  </div>
                  <select
                    className={modules["dropdownSelect1-apm"]}
                    value={selectedExistingPM}
                    onChange={(e) => handleExistingPM(e.target.value)}
                  >
                    {existingPM.length > 0 && (
                      <option value="Select">SELECT</option>
                    )}
                    {existingPM.length > 0 &&
                      existingPM?.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.username}
                        </option>
                      ))}
                  </select>
                </Stack>
              </Stack>
              {selectedExistingPM !== "" && selectedExistingPM !== "Select" && (
                <Grid container>
                  {detailgrid.length > 0 ? (
                    <div
                      style={{ marginLeft: "0.3rem" }}
                      className={modules["table-container-apm"]}
                    >
                      <Table
                        className={[
                          modules["custom-table-apm"],
                          modules["custom-table-th"],
                        ].join(" ")}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Sr. No.</TableCell>
                            <TableCell>Instance</TableCell>
                            <TableCell>AlternatePM</TableCell>
                            <TableCell>Project</TableCell>
                            <TableCell>ProjectManager</TableCell>
                            <TableCell>AccessFromDate</TableCell>
                            <TableCell>AccessToDate</TableCell>
                            <TableCell>ViewOnlyAccess</TableCell>
                            <TableCell>Save</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {detailgrid.length > 0 &&
                            detailgrid?.map((item, index) => (
                              <TableRow
                                key={index}
                                className={modules["table-row"]}
                              >
                                <TableCell className={modules["table-row"]}>
                                  {index + 1}
                                </TableCell>
                                <TableCell className={modules["table-row"]}>
                                  {item.instancename}
                                </TableCell>
                                <TableCell className={modules["table-row"]}>
                                  {item.apmname}
                                </TableCell>
                                <TableCell className={modules["table-row"]}>
                                  {item.project}
                                </TableCell>
                                <TableCell className={modules["table-row"]}>
                                  {item.projectmanager}
                                </TableCell>
                                <TableCell className={modules["table-row"]}>
                                  <TextField
                                    value={
                                      dates[`${index}-accessfromdate`] || ""
                                    }
                                    onChange={(e) =>
                                      handleDateChange(
                                        index,
                                        "accessfromdate",
                                        e.target.value
                                      )
                                    }
                                    onClick={(event) =>
                                      handleClick(
                                        index,
                                        "accessfromdate",
                                        event
                                      )
                                    }
                                    placeholder={formatDate(
                                      item.accessfromdate,
                                      "b"
                                    )}
                                    InputProps={{
                                      style: {
                                        height: "21px",
                                        width: "171px",
                                        borderRadius: "0px",
                                        fontSize: "12px",
                                        color: "#414141",
                                        fontFamily: "'Work Sans', sans-serif",
                                        border: "1px solid #414141",
                                      },
                                    }}
                                    sx={{
                                      color: "#414141",
                                      "& .MuiInputBase-input::placeholder": {
                                        color: "#414141",
                                        opacity: 1,
                                        fontSize: "12px",
                                        fontFamily: "'Work Sans', sans-serif",
                                      },
                                      "& .MuiOutlinedInput-input": {
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        borderRadius: "0px",
                                        color: "#414141",
                                        fontSize: "12px",
                                        fontFamily: "'Work Sans', sans-serif",
                                        border: "none!important",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                          borderColor: "transparent",
                                        },
                                      },
                                    }}
                                  />
                                  <Popover
                                    id={`${index}-accessfromdate`}
                                    open={Boolean(
                                      anchorEl[`${index}-accessfromdate`]
                                    )}
                                    anchorEl={
                                      anchorEl[`${index}-accessfromdate`]
                                    }
                                    onClose={() =>
                                      handleClose(index, "accessfromdate")
                                    }
                                    placement="bottom"
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Paper>
                                      <Calendar
                                        onChange={(date) =>
                                          handleDateChange(
                                            index,
                                            "accessfromdate",
                                            date
                                          )
                                        }
                                        value={
                                          dates[`${index}-accessfromdate`] ||
                                          new Date()
                                        }
                                        locale="en-US"
                                        formatShortWeekday={formatShortWeekday}
                                      />
                                      <div
                                        className={modules["calendar-footer"]}
                                      >
                                        Today: {formattedToday}
                                      </div>
                                    </Paper>
                                  </Popover>
                                </TableCell>
                                <TableCell className={modules["table-row"]}>
                                  <TextField
                                    value={dates[`${index}-accesstodate`] || ""}
                                    onChange={(e) =>
                                      handleDateChange(
                                        index,
                                        "accesstodate",
                                        e.target.value
                                      )
                                    }
                                    onClick={(event) =>
                                      handleClick(index, "accesstodate", event)
                                    }
                                    placeholder={formatDate(
                                      item.accesstodate,
                                      "b"
                                    )}
                                    InputProps={{
                                      style: {
                                        height: "21px",
                                        width: "171px",
                                        borderRadius: "0px",
                                        fontSize: "12px",
                                        color: "#414141",
                                        fontFamily: "'Work Sans', sans-serif",
                                        border: "1px solid #414141",
                                      },
                                    }}
                                    sx={{
                                      color: "#414141",
                                      "& .MuiInputBase-input::placeholder": {
                                        color: "#414141",
                                        opacity: 1,
                                        fontSize: "12px",
                                        fontFamily: "'Work Sans', sans-serif",
                                      },
                                      "& .MuiOutlinedInput-input": {
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        borderRadius: "0px",
                                        color: "#414141",
                                        fontSize: "12px",
                                        fontFamily: "'Work Sans', sans-serif",
                                        border: "none!important",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                          borderColor: "transparent",
                                        },
                                      },
                                    }}
                                  />
                                  <Popover
                                    id={`${index}-accesstodate`}
                                    open={Boolean(
                                      anchorEl[`${index}-accesstodate`]
                                    )}
                                    anchorEl={anchorEl[`${index}-accesstodate`]}
                                    onClose={() =>
                                      handleClose(index, "accesstodate")
                                    }
                                    placement="bottom"
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Paper>
                                      <Calendar
                                        onChange={(date) =>
                                          handleDateChange(
                                            index,
                                            "accesstodate",
                                            date
                                          )
                                        }
                                        value={
                                          dates[`${index}-accesstodate`] ||
                                          new Date()
                                        }
                                        locale="en-US"
                                        formatShortWeekday={formatShortWeekday}
                                      />
                                      <div
                                        className={modules["calendar-footer"]}
                                      >
                                        Today: {formattedToday}
                                      </div>
                                    </Paper>
                                  </Popover>
                                </TableCell>
                                <TableCell>
                                  <input
                                    checked={item.isviewonly}
                                    type="checkbox"
                                    onChange={() =>
                                      handleViewProjAccessCheck2(item.id)
                                    }
                                    className={modules["custom-checkbox"]}
                                  />
                                </TableCell>
                                <TableCell>
                                  <img
                                    src={saveIcon}
                                    alt="saveIcon"
                                    className={modules["custom-img-size"]}
                                    onClick={() =>
                                      handleSave(
                                        index,
                                        item.id,
                                        item.accesstypeid,
                                        dates[`${index}-accessfromdate`],
                                        dates[`${index}-accesstodate`],
                                        item.accessfromdate,
                                        item.accesstodate
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <img
                                    src={deleteicon}
                                    alt="deleteIcon"
                                    className={modules["custom-img-size"]}
                                    onClick={() =>
                                      handleDelete(
                                        item.id,
                                        item.accesstypeid,
                                        dates[`${index}-accessfromdate`],
                                        dates[`${index}-accesstodate`],
                                        item.accessfromdate,
                                        item.accesstodate
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    <div style={{ marginTop: '1rem', marginLeft: '1rem', width: '100%' }}>
                      <div style={{ marginBottom: '0.5rem', paddingLeft: '0.5rem' }}>
                        No Record Found.
                      </div>
                      <hr style={{ border: '1px solid #a4bf47', transform: 'scaleY(0.2)', width: '65%' }} />
                    </div>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default AlternateProjectManager;
