import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
// import modules from "./../ccpm.module.css";
import modules from "./ProjectClosureForm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  Get_APBindProjectName,
  Get_APBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetIRExport,
  GetIRIssueOwner,
  Get_CPSearchResult,
  CP_Save_Click,
  Complete_Click,
} from "../../../Services/CCPM.js";

import ProjectClosureForm from "./ProjectClosureForm"; // Import your overlay component

const CompleteProjects = () => {
  const [selectedInstance, setSelectedInstance] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("1");
  const [selectedAssignedTo, setselectedAssignedTo] = useState("0");
  const [assignedOwner, setAssignedOwner] = useState([]);
  const [selectedissueStatus, setSelectedIssueStatus] = useState("0");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  const handleSelectChange = (attributenumber, id) => {
    // console.log(attributenumber, id, "attributenumber value");
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [instances, setInstances] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);

  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");

  const accessTypes = [
    { value: "", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ];

  const projectStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Active" },
    { value: "5", label: "Hold" },
    { value: "7", label: "Completed" },
    { value: "10", label: "CDDCheckedOut" },
    { value: "11", label: "ModifiedCheckedOut" },
  ];

  const issueStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Pending" },
    { value: "5", label: "Closed" },
  ];

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getAssinedOwner();
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    // console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    Get_APBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    Get_APBindProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssinedOwner = () => {
    const body = {
      userId: 0,
    };
    GetIRIssueOwner(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data, "Assigned Owner");
          setAssignedOwner(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      accessType: selectedProjectAccessType,
    };
    setIsLoading(true);

    Get_CPSearchResult(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data, "table data");
          setIsLoading(false);
          setTableData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExcel = async (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      issueOwner: selectedAssignedTo,
      projectType: selectedProjectAccessType,
      accessType: "",
      issueStatusId: selectedissueStatus,
    };

    // if (selectedRoleId !== -1) {
    try {
      setIsLoading(true);
      const response = await GetIRExport(body);

      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // const contentDisposition = response.headers["content-disposition"];
        const filename = "IssueReport.xlsx";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoading(false);
        console.error("No Data found");
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error downloading file:", err);
    }
  };

  const handleSearch = () => {
    setShowResult(true);
    // console.log(selectedAttributes, "selectedAttributes");
    // console.log(selectedProjectStatus, "status");
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    // console.log(
    //   attr1,
    //   attr2,
    //   attr3,
    //   attr4,
    //   attr5,
    //   attr6,
    //   attr7,
    //   attr8,
    //   attr9,
    //   attr10
    // );

    getSearchResult(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  const DownloadHandler = () => {
    setShowResult(true);
    // console.log(selectedAttributes, "selectedAttributes");
    // console.log(selectedProjectStatus, "status");
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getExcel(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function formatDate2(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function handleComplete_Click(p_id, p_name) {
    const body = {
      projectsId: parseInt(p_id),
    };
    setIsLoading(true);

    Complete_Click(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(false);
          setProjectName(p_name);
          setProjectId(p_id);
          setIsOverlayVisible(true); // Show the overlay
        } else if (response?.response?.data?.statusCode === 400) {
          console.log(response?.data?.error?.message, "complete");
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // For the PRojectClosureForm.js file, you can use the following code:

  const [formData, setFormData] = useState({
    closureDate: "",
    customerFeedback: "",
    approvedExecutionCost: "",
    actualExecutionCost: "",
    projectValue: "",
    file1: null,
    file2: null,
    file3: null,
    file4: null,
    learningDocumentFile: null,
    closureRemark: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Add form submission logic here
  };

  const handleSave = (e) => {
    const loginId = sessionStorage.getItem("loginId");
    const projId = projectId;
    const body = {
      userId: loginId,
      projectsId: projId,
      closureDate: formData.closureDate,
      customerFeedback: formData.customerFeedback,
      approvedExecutionCost: formData.approvedExecutionCost,
      actualExecutionCost: formData.actualExecutionCost,
      projectValue: formData.projectValue,
      closureRemark: formData.closureRemark,
    };

    const saveData = {
      CustomerFeedback: formData.customerFeedback,
      UserId: loginId,
      ProjCompletionDate: formData.closureDate,
      projStatusId: 7,
      ApprovedExecutionCost: "50000",
      projectDocFile1: "file1", // Assuming `file1` is a File object
      projectDocFile2: "file2", // Assuming `file2` is a File object
      ActualExecutionCost: "45000",
      projId: "abcd",
      ProjectValue: "100000",
      projectDocFile3: "file3", // Assuming `file3` is a File object
      ProjCompletionRemark: "Project completed successfully",
      projectDocFile: "file4", // Assuming `file4` is a File object
    };

    CP_Save_Click(saveData)
      .then((response) => {
        // if (response?.data?.statusCode === 200) {
        //   setIsLoading(false);
        //   setIsOverlayVisible(true); // Show the overlay
        // } else if (response?.response?.data?.statusCode === 400) {
        //   console.log(response?.data?.error?.message, "complete");
        //   setIsLoading(false);
        //   alert(response?.response?.data?.error?.message);
        // } else {
        //   setIsLoading(false);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div
        style={{
          overflowX: showResult && tableData.length > 0 ? "auto" : "hidden",
        }}
      >
        {isLoading && (
          <img
            src={loading}
            className={modules["loading_gif"]}
            alt="Loading..."
          />
        )}
        <Grid container>
          <Grid item lg={0.6} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>

          <Grid item lg={12} sx={{ display: "block" }}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"CompleteProjects"} />
            </Grid>

            <Grid item lg={12}>
              <div
                className={modules["instancemaster_div"]}
                style={{ marginBottom: "5px" }}
              >
                <div
                  className={modules["pro-bread"]}
                  style={{ marginLeft: "-2.5rem" }}
                >
                  <a>CCPM</a>
                  <img src={bread} alt="" />
                  <a>Complete Projects</a>
                </div>
              </div>
              <div className={modules["border_cont"]}>
                <table className={modules["PCtable"]}>
                  <tbody>
                    <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Instance:<label style={{ color: "Red" }}>*</label>
                        </span>

                        <select
                          value={selectedInstance}
                          onChange={(e) => {
                            setSelectedInstance(e.target.value);
                            getProjectDetails(e.target.value);
                          }}
                          className={modules["dropdownSelect"]}
                          // style={{ width: "200px" }}
                        >
                          {instances?.map((instance) => (
                            <option key={instance.name} value={instance.id}>
                              {instance.name}
                            </option>
                          ))}
                        </select>
                      </td>

                      {hierarchyOptions
                        ?.filter((option) => option.isactive === 1)
                        .map((option, index) => (
                          <React.Fragment key={option.attributename}>
                            <td className={modules["table_data1"]}>
                              <span
                                style={{ width: "115px", color: "#414141" }}
                              >
                                {option.attributename} :
                              </span>
                              <select
                                value={
                                  selectedAttributes[
                                    `attr${option.attributenumber}`
                                  ] || ""
                                }
                                onChange={(e) =>
                                  handleSelectChange(
                                    option.attributenumber,
                                    e.target.value
                                  )
                                }
                                className={modules["dropdownSelect"]}
                                // style={{ width: "200px" }}
                              >
                                <option value="">SELECT</option>
                                {filteredValueData[index]?.map((item) => (
                                  <option
                                    key={item.attributevalue}
                                    value={item.id}
                                  >
                                    {item.attributevalue}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </React.Fragment>
                        ))}
                    </tr>
                    <tr
                      style={{
                        paddingBottom: "10px",
                        paddingLeft: "0px",
                      }}
                    >
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Project Name :
                        </span>
                        <select
                          value={selectedProjectName}
                          onChange={(e) =>
                            setSelectedProjectName(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          // style={{ width: "200px" }}
                        >
                          <option value={0}>SELECT</option>
                          {projects?.map((p_name) => (
                            <option key={p_name.projectname} value={p_name.id}>
                              {p_name.projectname}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Project Manager :
                        </span>
                        <select
                          value={selectedProjectManager}
                          onChange={(e) =>
                            setSelectedProjectManager(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={0}>SELECT</option>
                          {managers?.map((m_name) => (
                            <option key={m_name.username} value={m_name.id}>
                              {m_name.username}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Project AccessType:
                        </span>
                        <select
                          value={selectedProjectAccessType}
                          onChange={(e) => {
                            setSelectedProjectAccessType(e.target.value);
                          }}
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          {accessTypes?.map((access) => (
                            <option key={access.label} value={access.value}>
                              {access.label}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr
                      style={{
                        justifyContent: "center",
                        gap: "10px",
                        paddingLeft: "0px",
                        paddingBottom: "10px",
                      }}
                    >
                      <button
                        type="submit"
                        className={modules["PCbtn"]}
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                      <button
                        className={modules["PCbtn"]}
                        type="button"
                        onClick={() => {
                          window.location.reload();
                          setShowResult(false);
                        }}
                      >
                        Reset
                      </button>
                    </tr>
                  </tbody>
                </table>
              </div>

              {showResult && tableData.length > 0 && (
                <>
                  <Grid
                    container
                    sx={{
                      marginLeft: "6.5rem",
                      padding: "8px",
                      marginTop: "1.5rem",
                    }}
                  >
                    <Table
                      className={modules["IR_custom-table"]}
                      style={{ textAlign: "center" }}
                    >
                      <TableHead style={{ textAlign: "center" }}>
                        <TableRow>
                          <TableCell style={{ textAlign: "center" }}>
                            Modif. No
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Project No
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Project Name
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Original Buffer Days
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Buffer Consumed-Days
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            % Buffer Consumed
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            % CC Completed
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Original CC Days
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Project Manager
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Project Duration
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Scheduled Start Date
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Scheduled End Date
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Expected Completion
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Last BM Run
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Pending tasks
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Complete Project
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ textAlign: "center" }}>
                        {tableData?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ textAlign: "center" }}>
                              {item["Modif Version"]}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.projectid}
                            </TableCell>
                            <TableCell
                              sx={{
                                background: `${item.color}`,
                                // color: "#ffffff",
                              }}
                              style={{ textAlign: "center" }}
                            >
                              {item.projectname}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {/* doubt */}
                              {item.projectbuffer}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.projectbufferconsumed}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.projectbuffer}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.cccompleted}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.ccdaysoriginal}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.projectmanager}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.projectduration}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {formatDate(item.projectstartdate)}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {formatDate(item.projectenddate)}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {formatDate2(item.expectedprojectcompletiondate)}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.lastbmrundate}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <a
                                className={modules["textRMP"]}
                                onClick={() => {
                                  window.open(
                                    `/ccpm/PendingTaskView?projId=${item.projectid}`,
                                    "sharer",
                                    "toolbar=0,status=0,width=1050,height=600"
                                  );
                                }}
                              >
                                Pending Tasks
                              </a>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <button
                                type="submit"
                                style={{
                                  color: "white",
                                  padding: "2px 6px",
                                  cursor: "pointer",
                                  fontSize: "15px",
                                  border: "none",
                                  width: "150px",
                                }}
                                className={modules["PCbtn"]}
                                onClick={() =>
                                  handleComplete_Click(
                                    item.projectid,
                                    item.projectname
                                  )
                                }
                              >
                                Complete Project
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        {isOverlayVisible && (
          <div className={modules["overlay"]}>
            <div className={modules["project-closure-form"]}>
              <div style={{ paddingLeft: "8px", paddingTop: "8px" }}>
                Project Id : {projectId}
              </div>
              {/* <br /> */}
              <div style={{ paddingLeft: "8px" }}>
                Project Name : {projectName}
              </div>
              <form onSubmit={handleSubmit}>
                <div className={modules["form-group"]}>
                  <div style={{ width: "92px" }}>Closure Date:</div>
                  <input
                    type="date"
                    name="closureDate"
                    value={formData.closureDate}
                    onChange={handleInputChange}
                    style={{
                      width: "150px",
                      borderRadius: "10px",
                      border: "1px solid #9d9d9c",
                      padding: "5px",
                    }}
                  />
                  <span
                    className={modules["info-text"]}
                    style={{ padding: "5px" }}
                  >
                    [Selection Range: Between last date of task(s) completion of
                    this project AND Current Date]
                  </span>
                </div>

                <div className={modules["form-group"]}>
                  <div>Customer Feedback:</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "80px",
                    }}
                  >
                    <div style={{ padding: "8px" }}>
                      <input
                        type="radio"
                        name="customerFeedback"
                        value="SE"
                        onChange={handleInputChange}
                        className={modules["radio-button"]}
                      />
                      SE
                    </div>
                    <div style={{ padding: "8px" }}>
                      <input
                        type="radio"
                        name="customerFeedback"
                        value="ME"
                        onChange={handleInputChange}
                        className={modules["radio-button"]}
                      />{" "}
                      ME
                    </div>
                    <div style={{ padding: "8px" }}>
                      <input
                        type="radio"
                        name="customerFeedback"
                        value="CB"
                        onChange={handleInputChange}
                        className={modules["radio-button"]}
                      />{" "}
                      CB
                    </div>
                    <div style={{ padding: "8px" }}>
                      <input
                        type="radio"
                        name="customerFeedback"
                        value="BE"
                        onChange={handleInputChange}
                        className={modules["radio-button"]}
                      />{" "}
                      BE
                    </div>
                  </div>
                </div>

                <div className={modules["form-group"]}>
                  <div style={{ width: "180px" }}>
                    Approved Execution Cost :
                  </div>
                  <input
                    type="number"
                    name="approvedExecutionCost"
                    placeholder="Enter the full amount in INR only Eg:1000.50"
                    value={formData.approvedExecutionCost}
                    onChange={handleInputChange}
                    className={modules["input-group"]}
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #9d9d9c",
                    }}
                  />
                  <span className={modules["info-text"]}>
                    [Enter the full amount in INR only Eg:1000.50]
                  </span>
                </div>

                <div className={modules["form-group"]}>
                  <div style={{ width: "180px" }}>Actual Execution Cost:</div>
                  <input
                    type="number"
                    name="actualExecutionCost"
                    placeholder="Enter the full amount in INR only Eg:1000.50"
                    value={formData.actualExecutionCost}
                    onChange={handleInputChange}
                    className={modules["input-group"]}
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #9d9d9c",
                    }}
                  />
                  <span className={modules["info-text"]}>
                    [Enter the full amount in INR only Eg:1000.50]
                  </span>
                </div>

                <div className={modules["form-group"]}>
                  <div style={{ width: "180px" }}>Project Value:</div>
                  <input
                    type="number"
                    name="projectValue"
                    placeholder="Enter the full amount in INR only Eg:1000.50"
                    value={formData.projectValue}
                    onChange={handleInputChange}
                    className={modules["input-group"]}
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #9d9d9c",
                    }}
                  />
                  <span className={modules["info-text"]}>
                    [Enter the full amount in INR only Eg:1000.50]
                  </span>
                </div>

                <div className={modules["form-group2"]}>
                  <div style={{ width: "180px" }}>Project Documents : </div>
                  <div
                    className={modules["info-text"]}
                    style={{ fontWeight: "bold" }}
                  >
                    [xls/xlsx/doc/docx/pdf/ppt/pptx/txt files only of size not
                    more than 1mb]{" "}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "8px",
                    }}
                  >
                    <span style={{ width: "50px" }}>File1 :</span>{" "}
                    <input
                      type="file"
                      name="file1"
                      onChange={handleFileChange}
                      style={{ border: "none" }}
                    />
                    <button type="button" className={modules["button_css"]}>
                      Upload
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "8px",
                    }}
                  >
                    <span style={{ width: "50px" }}>File2 :</span>{" "}
                    <input
                      type="file"
                      name="file2"
                      onChange={handleFileChange}
                      style={{ border: "none" }}
                    />
                    <button type="button" className={modules["button_css"]}>
                      Upload
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "8px",
                    }}
                  >
                    <span style={{ width: "50px" }}>File3 :</span>{" "}
                    <input
                      type="file"
                      name="file3"
                      onChange={handleFileChange}
                      style={{ border: "none" }}
                    />
                    <button type="button" className={modules["button_css"]}>
                      Upload
                    </button>
                  </div>
                </div>

                <div className={modules["form-group2"]}>
                  <div style={{ width: "180px" }}>
                    Project Learning Document :{" "}
                  </div>
                  <div
                    className={modules["info-text"]}
                    style={{ fontWeight: "bold" }}
                  >
                    [xls/xlsx/doc/docx/pdf/ppt/pptx/txt files only of size not
                    more than 1mb]{" "}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "8px",
                    }}
                  >
                    <span style={{ width: "50px" }}>File1 :</span>{" "}
                    <input
                      type="file"
                      name="file4"
                      onChange={handleFileChange}
                      style={{ border: "none" }}
                    />
                    <button type="button" className={modules["button_css"]}>
                      Upload
                    </button>
                  </div>
                </div>

                <div className={modules["form-group"]}>
                  <div style={{ width: "180px" }}>Closure Remark:</div>
                  <textarea
                    name="closureRemark"
                    rows="4"
                    value={formData.closureRemark}
                    onChange={handleInputChange}
                    style={{ width: "30%", borderRadius: "10px" }}
                  ></textarea>
                </div>

                <div className={modules["form-group"]}>
                  <button
                    type="submit"
                    className={modules["button_css"]}
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className={modules["button_css"]}
                    onClick={() => setIsOverlayVisible(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CompleteProjects;
