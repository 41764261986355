import React, { useState, useEffect, useRef } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
// import modules from "./Portfoliochart.module.css";
import modules from "./../ccpm.module.css";
import Box from "@mui/material/Box";
import { Tab, Tabs, TextField } from "@mui/material";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  LARGetTaskManagers,
  LARGetManagers,
  LARGetSessionNames,
  LARSearchClick

} from "../../../Services/CCPM.js";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

import { styled } from '@mui/system';
import { enUS } from 'date-fns/locale';
import { Stack, Popover, Paper } from '@mui/material';

// import { Scatter } from "react-chartjs-2";

const DummyData = [
  {
    id: 1,
    col2: "Aerospace",
    col3: "Aerospace",
  },
  {
    id: 2,
    col2: "Innovation and Design Center",
    col3: "IDC",
  },
  {
    id: 3,
    col2: "GB Corporate Projects",
    col3: "GB_CORP",
  },
  {
    id: 4,
    col2: "PSS Gogrej Security Solutions",
    col3: "SSD_PSS",
  },
  {
    id: 5,
    col2: "G&B Finance",
    col3: "G&B Finance",
  },
];

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const LoginAuditReport = () => {

  const [selectedInstance, setSelectedInstance] = useState("");
  const [instances, setInstances] = useState([]);
  const [managers, setManagers] = useState([]);
  const [managerIds, setManagerIds] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const roles = [
    { id: 1, name: 'Task Manager' },
    { id: 2, name: 'Project Manager' }
  ];
  const [sessionNames, setSessionNames] = useState({ Session1: [], Session2: [] });
  const [selectedSession1, setSelectedSession1] = useState("");
  const [selectedSession2, setSelectedSession2] = useState("");
  const [loginAuditData, setLoginAuditData] = useState([]);
  const [idToUsername, setIdToUsername] = useState({}); // Mapping user ID to username
  const [failedCheck, setFailedCheck] = useState(false);
  const [showRows,setShowRows] = useState(false);


  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
    GetSessionNames();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      fetchTaskManagers(initialInstance);
    }
  }, [instances]);

  useEffect(() => {
    if (sessionNames && sessionNames.Session1.length > 0 && sessionNames.Session2.length > 0) {
      setSelectedSession1(sessionNames.Session1[39].pagename);
      setSelectedSession2(sessionNames.Session2[21].pagename);
    }
  }, [sessionNames]);

  const GetSessionNames = () => {
    LARGetSessionNames()
      .then((response) => {
        const sessionNamesData = response?.data?.success?.data;
        setSessionNames(sessionNamesData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchTaskManagers = (instanceId) => {
    const body = { divId: instanceId };
    LARGetTaskManagers(body)
      .then((response) => {
        const managersData = response?.data?.success?.data;
        setManagers(managersData);

        // Create a mapping of user IDs to usernames
        const idToUsernameMapping = managersData.reduce((acc, manager) => {
          acc[manager.id] = manager.username; // Assuming `username` is available
          return acc;
        }, {});
        setIdToUsername(idToUsernameMapping);

        const managerIds = managersData.map(manager => manager.id);
        setManagerIds(managerIds);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchTaskManagersByRole = (instanceId, roleAccessType) => {
    const body = { divId: instanceId, roleAccessType: roleAccessType };
    LARGetManagers(body)
      .then((response) => {
        const managersData = response?.data?.success?.data;
        setManagers(managersData);

        // Create a mapping of user IDs to usernames
        const idToUsernameMapping = managersData.reduce((acc, manager) => {
          acc[manager.id] = manager.username; // Assuming `username` is available
          return acc;
        }, {});
        setIdToUsername(idToUsernameMapping);

        const managerIds = managersData.map(manager => manager.id);
        setManagerIds(managerIds);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUsersByInstance = async (instanceId) => {
    try {
      const body = { divId: instanceId };
      const response = await LARGetTaskManagers(body);
      const managersData = response?.data?.success?.data || [];
      return managersData.map(manager => manager.id); // Return an array of user IDs
    } catch (error) {
      console.error('Error fetching users by instance:', error);
      return [];
    }
  };


  const handleInstanceChange = (instanceId) => {
    setSelectedInstance(instanceId);
    fetchTaskManagers(instanceId);
  };






  const getUsersByInstanceAndRole = async (instanceId, roleAccessType) => {
    try {
      const body = { divId: instanceId, roleAccessType: roleAccessType };
      const response = await LARGetManagers(body);
      const managersData = response?.data?.success?.data || [];
      return managersData.map(manager => manager.id); // Return an array of user IDs
    } catch (error) {
      console.error('Error fetching users by instance and role:', error);
      return [];
    }
  };

  const handleRoleChange = (e) => {
    const selectedRoleId = e.target.value;
    setSelectedRole(selectedRoleId);
    fetchTaskManagersByRole(selectedInstance, selectedRoleId);
  };

  const HiddenCalendarInput = styled(TextField)({
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      display: 'none',
    },
    '& .MuiInputBase-input': {
      height: '25px',
      padding: '0px 0px 0px 5px'
    },
  });


  const today = new Date();
  const [daysFromDropdown, setDaysFromDropdown] = useState(7);
  const [selectedDate, setSelectedDate] = useState(today);
  const [displayDate, setDisplayDate] = useState(() => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date().toLocaleDateString('en-GB', options).replace(/ /g, '-');
  });
  const [calendarOpen, setCalendarOpen] = useState(false);
  const calendarRef = useRef(null);

  const handleCalendarChange = (date) => {
    setSelectedDate(date);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    setDisplayDate(date.toLocaleDateString('en-GB', options).replace(/ /g, '-'));
    setCalendarOpen(false);
  };

  const handleDaysFromDropdownChange = (event) => {
    const days = parseInt(event.target.value, 10);
    setDaysFromDropdown(days);
    // Set the selectedDate based on the dropdown value

  };

  const toggleCalendar = () => {
    setCalendarOpen((prevOpen) => !prevOpen);
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarOpen(false); // Close calendar if click is outside
    }
  };

  useEffect(() => {
    if (calendarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarOpen]);




  const [ranges, setRanges] = useState([]);

  const handleRowFunction = () => {
    const numberOfDays = parseInt(daysFromDropdown, 10);
    const calculatedRanges = [];
    let startDate = new Date(selectedDate);
    startDate.setDate(startDate.getDate() - numberOfDays + 1);
  
    const numberOfRows = Math.ceil(numberOfDays / 7);
    for (let i = 0; i < numberOfRows; i++) {
      const fromDate = new Date(startDate);
      let toDate = new Date(fromDate);
  
      if (i === numberOfRows - 1 && numberOfDays % 7 !== 0) {
        toDate = new Date(selectedDate);
      } else {
        toDate.setDate(fromDate.getDate() + 6);
      }
  
      calculatedRanges.push({
        fromDate: fromDate.toLocaleDateString("en-GB"),
        toDate: toDate.toLocaleDateString("en-GB"),
      });
  
      startDate.setDate(startDate.getDate() + 7);
    }
  
    setRanges(calculatedRanges);
  };
  


  const handleSearch = async () => {
   


   


    

    let userIds = [];
    setIsLoading(true);
    try {
      if (selectedManager) {
        // Case 1: Specific User Selected
        userIds = [selectedManager];
        console.log("Case 1 - Selected Manager:", userIds);
      } else if (selectedInstance && !selectedManager) {
        // Case 2: Instance Selected but No User Selected
        userIds = await getUsersByInstance(selectedInstance); // Await the promise
        console.log("Case 2 - Selected Instance, No Manager:", userIds);
      } else if (selectedInstance && selectedRole) {
        // Case 3: Role Selected After Instance
        userIds = await getUsersByInstanceAndRole(selectedInstance, selectedRole); // Await the promise
        console.log("Case 3 - Selected Instance and Role:", userIds);
      }

      // Final output of userIds
      console.log("Final userIds:", userIds);

      const payload = {
        userIds: userIds,
        days: daysFromDropdown || 0,
        date: selectedDate.toISOString().split('T')[0],
        url1: selectedSession1 || "",
        url2: selectedSession2 || ""
      };

      console.log(payload);

      const response = await LARSearchClick(payload); // Await the response from the API

      // Access the data field inside the success object
      const loginAuditData = response.data.success.data;

      // Extract user data (userId and log data)
      const formattedData = loginAuditData.map(item => {

        const userId = Object.keys(item)[0]; // Extract the userId
        const username = idToUsername[userId] || 'Unknown';
        const userLogs = item[userId]; // Get the corresponding log data for that userId
        const itemCounts = item.count || { item1: 0, item2: 0, item3: 0 }; // Extract counts
        return { userId, userLogs, username, ...itemCounts }; // Return an object with userId and userLogs
      });

      // Set the formatted data into the state
      setLoginAuditData(formattedData);
      handleRowFunction();
      setShowTable(true);
      setShowRows(true);
      setFailedCheck(false);
      console.log('Formatted Login Audit Data:', formattedData);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const formatDate = (date) => {
    // Check if the date is in the format DD/MM/YYYY
    const [day, month, year] = date.split('/'); // Split the date into day, month, and year
    
    // Create a new Date object in the format YYYY-MM-DD
    const formattedDateString = `${year}-${month}-${day}`;
    
    const parsedDate = new Date(formattedDateString);
  
    // Check if the date is invalid
    if (isNaN(parsedDate.getTime())) {
      return 'Invalid Date'; // If the date is invalid, return 'Invalid Date'
    }
  
    const options = { year: 'numeric', month: 'short', day: '2-digit' }; // Format: 08-Oct-2024
    const formattedDate = parsedDate.toLocaleDateString('en-GB', options);
  
    return formattedDate.replace(' ', '-'); // Return formatted date
  };
  
  
  


  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"LoginAuditReport"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "5px" }}
            >
              <div className={modules["pro-bread"]}>
                <a>Report</a>
                <img src={bread} alt="" />
                <a>Login Audit Report</a>
              </div>
            </div>
            
            <div style={{marginTop:'1.7rem',marginLeft:'7rem', border: '1px solid blue'}} className={modules["border_cont"]}>
              <table className={modules["PCtable"]}>
                <tbody style={{width:'100%'}}>
                  <tr style={{ paddingBottom: "15px",width:'100%',columnGap:'3rem' }}>
                    <td className={modules["table_data5"]}>
                      <span style={{ width: "110px",fontWeight:'bold'  }}>
                        Instance :
                      </span>

                      <select
                        value={selectedInstance}
                        onChange={(e) => handleInstanceChange(e.target.value)}
                        className={modules["dropdownSelect"]}
                        style={{ width: "125px" }}
                      >
                        {instances?.map((instance) => (
                          <option key={instance.name} value={instance.id}>
                            {instance.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data5"]} >
                      <span style={{ width: "110px",fontWeight:'bold'  }}>User Role :</span>
                      <select
                        value={selectedRole}
                        onChange={handleRoleChange}
                        className={modules["dropdownSelect"]}
                      >
                        <option>SELECT</option>
                        {roles.map((role) => (
                          <option key={role.name} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data5"]}>
                      <span style={{ width: "110px",fontWeight:'bold'  }}>User Name :</span>
                      <select
                        value={selectedManager}
                        onChange={(e) => setSelectedManager(e.target.value)}
                        className={modules["dropdownSelect"]}
                      >
                        <option>SELECT</option>
                        {managers.map((manager) => (
                          <option key={manager.username} value={manager.id}>
                            {manager.username}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr style={{ paddingBottom: '10px', marginLeft: '0.5rem',width:'100%',columnGap:'5rem' }}>
                    <td style={{paddingLeft:'2px'}} className="table_data2">
                      <span style={{ width: '110px', marginRight: '2.7rem',fontWeight:'bold',color:'#414141'  }}>Login In Last</span>
                      <select
                        value={daysFromDropdown}
                        onChange={handleDaysFromDropdownChange}
                        style={{
                          width: '50px',
                          appearance: 'none', // Remove default dropdown icon
                          WebkitAppearance: 'none', // Remove default dropdown icon for Safari
                          MozAppearance: 'none', // Remove default dropdown icon for Firefox
                          background: 'none', // Remove background so that there's no dropdown icon background
                          border: '1px solid #414141',
                          borderRadius: '10px',
                          padding: '5px',

                          textAlign: 'center',
                          outline: 'none'
                        }}
                      >
                        <option value={7}>7</option>
                        <option value={14}>14</option>
                        <option value={28}>28</option>
                        <option value={31}>31</option>
                      </select>
                      <span><strong  style={{fontWeight:'600',color:'#414141'}}> Days From</strong> </span>
                      <div style={{ position: 'relative', display: 'inline-block' }} ref={calendarRef}>
                        <input
                          type="text"
                          readOnly
                          value={displayDate}
                          placeholder="dd/MM/yyyy"
                          onClick={toggleCalendar}
                          style={{ width: '70%' }}
                          className={modules["input-field"]}
                        />
                        {calendarOpen && (
                          <div
                            className="fromcalendar"
                            style={{ position: 'absolute', zIndex: 2 }}
                          >
                            <Calendar
                              onChange={handleCalendarChange}
                              value={selectedDate}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr style={{ paddingBottom: "10px" ,width:'100%',columnGap:'3rem'}}>
                    <td className={modules["table_data5"]}>
                      <span style={{ width: "110px",fontWeight:'bold'  }}>Session 1 :</span>
                      <select
                        value={selectedSession1}
                        onChange={(e) => setSelectedSession1(e.target.value)}
                        className={modules["dropdownSelectLAR"]}
                      >
                        <option value="">SELECT</option>
                        {sessionNames.Session1.map((session, index) => (
                          <option key={index} value={session.pagename}>
                            {session.pagetitle}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data5"]}>
                      <span style={{ width: "110px",fontWeight:'bold'  }}>Session 2 :</span>
                      <select
                        value={selectedSession2}
                        onChange={(e) => setSelectedSession2(e.target.value)}
                        className={modules["dropdownSelectLAR"]}
                      >
                        <option value="">SELECT</option>
                        {sessionNames.Session2.map((session, index) => (
                          <option key={index} value={session.pagename}>
                            {session.pagetitle}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>

                  <tr
                    style={{
                      justifyContent: "center",
                      gap: "10px",
                      paddingLeft: "0px",
                      paddingBottom: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      className={modules["PCbtn"]}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["PCbtn"]}
                      type="button"
                      onClick={() => {
                        setSelectedInstance("");
                        setSelectedRole("SELECT");
                        setSelectedManager("SELECT");
                        setShowTable(false);
                        setSelectedSession1("");
                        setSelectedSession2("");
                        setDaysFromDropdown(7);
                        setSelectedDate(today);
                        setFailedCheck(true);

                        // setSelectedZone("");
                        // setSelectedProject("");
                        // setSelectedManager("");
                        // setSelectedAccessType("SELECT");

                      }}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>

            <Grid container className={modules["info-row"]} style={{ marginBottom: '0.5rem',marginTop: '1rem', marginLeft:'7rem',alignItems: 'center',width:'90%' }} >
            {showRows && (
              <>
  {ranges.map((range, index) => (
    <Grid item key={index} style={{ marginRight: '1.8rem', fontSize: '12px', color: '#414141' }}>
      Row {index + 1} : {formatDate(range.fromDate)} To {formatDate(range.toDate)}
    </Grid>
  ))}
  </>
)}
</Grid>



            {failedCheck ? (
              <div
                style={{
                  color: "#414141",
                  border: "1px solid black",
                  padding: "5px",
                  fontSize: "12px",
                  marginLeft: "7rem",
                  marginTop: "1rem",
                  width: '45%',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                No Data found
              </div>
            ) : (
              showTable && (

                <Grid container className={modules["instanceAttMap_table-gridLAR"]} style={{ marginLeft: '7rem', marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                <div className={modules["ccpm_table-containerLAR"]}>
                  <Table className={modules["custom-tableLAR"]}>
                    <TableHead className="table_head" style={{ backgroundColor: 'whitesmoke' }}>
                      <TableRow>
                        <TableCell
                          className="fixed-width-cell"
                          sx={{
                            fontFamily: 'worksans-semibold,sans-serif',
                            fontSize: '15px',
                            width: '70px',
                            backgroundColor: 'whitesmoke',
                            textAlign: 'center !important',
                            paddingLeft:'10px',
                          }}
                        >
                          UserName
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: 'worksans-semibold,sans-serif',
                            fontSize: '12px',
                            width: '50px',
                          }}
                        >
                          {/* Empty header cell for the count */}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: 'worksans-semibold,sans-serif',
                            fontSize: '12px',
                            width: '125px',
                            justifyContent: 'center',
                             paddingLeft:'3rem'
                          }}
                          
                        >
                          Login History
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: 'worksans-semibold,sans-serif',
                            fontSize: '12px',
                            width: '50px',
                            
                          }}
                        >
                          {/* Empty header cell for the count */}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: 'worksans-semibold,sans-serif',
                            fontSize: '12px',
                            width: '125px',
                            justifyContent: 'center',
                             paddingLeft:'3.5rem'
                           
                          }}
                        >
                          Session 1
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: 'worksans-semibold,sans-serif',
                            fontSize: '12px',
                            width: '50px',
                              justifyContent: 'center',
                          }}
                        >
                          {/* Empty header cell for the count */}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: 'worksans-semibold,sans-serif',
                            fontSize: '12px',
                            width: '100px',
                             justifyContent: 'center',
                             paddingLeft:'3.5rem'
                          
                          }}
                        >
                          Session 2
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
  {loginAuditData.map((data, index) => {
    const { userLogs, username, item1, item2, item3 } = data;

    // Split the userLogs into chunks of 7
    const logChunks = chunkArray(userLogs, 7);
    const totalItems = userLogs.length;

    return (
      <React.Fragment key={index}>
        {logChunks.map((logChunk, chunkIndex) => (
          <TableRow key={`${index}-${chunkIndex}`}>
            {/* Display the username only in the first row of each user */}
            {chunkIndex === 0 && (
              <TableCell
              className={modules["usernamecell"]}
                sx={{
                  fontFamily: 'worksans-semibold,sans-serif',
                  fontSize: '15px',
                  width: '70px',
                  textAlign: 'center',
                  verticalAlign: 'top',

                }}
                rowSpan={logChunks.length}
              >
                {username}
              </TableCell>
            )}

            {/* Display the count for Login History */}
            {chunkIndex === 0 && (
              <TableCell
                sx={{
                  fontFamily: 'worksans-semibold,sans-serif',
                  fontSize: '12px',
                  verticalAlign: 'top',
                  
                  textAlign: 'center',
                }}
                rowSpan={logChunks.length}
              >
                {item1}/{totalItems}
              </TableCell>
            )}

            {/* Display Login History dots */}
            <TableCell
              sx={{
                fontFamily: 'worksans-semibold,sans-serif',
                fontSize: '12px',
                verticalAlign: 'middle',
                justifyContent: 'left',
                display: 'flex',
              }}
            >
              {logChunk.map(log => (
                <span
                  key={log.id}
                  style={{
                    color: log.loginstatus === 'N' ? 'red' : 'green',
                    marginRight: '8px',
                    fontSize: '20px',
                  }}
                >
                  ●
                </span>
              ))}
            </TableCell>

            {/* Display the count for Session 1 */}
            {chunkIndex === 0 && (
              <TableCell
                sx={{
                  fontFamily: 'worksans-semibold,sans-serif',
                  fontSize: '12px',
                  verticalAlign: 'middle',
                 
                  textAlign: 'center',
                }}
                rowSpan={logChunks.length}
              >
                {item2}/{totalItems}
              </TableCell>
            )}

            {/* Display Session 1 dots */}
            <TableCell
              sx={{
                fontFamily: 'worksans-semibold,sans-serif',
                fontSize: '12px',
                verticalAlign: 'middle',
                textAlign:'left',
                
              }}
            >
              {logChunk.map(log => (
                <span
                  key={log.id}
                  style={{
                    color: log.session1status === 'N' ? 'red' : 'green',
                    marginRight: '8px',
                    fontSize: '20px',
                  }}
                >
                  ●
                </span>
              ))}
            </TableCell>

            {/* Display the count for Session 2 */}
            {chunkIndex === 0 && (
              <TableCell
                sx={{
                  fontFamily: 'worksans-semibold,sans-serif',
                  fontSize: '12px',
                  verticalAlign: 'middle',
                
                  textAlign: 'center',
                }}
                rowSpan={logChunks.length}
              >
                {item3}/{totalItems}
              </TableCell>
            )}

            {/* Display Session 2 dots */}
            <TableCell
              sx={{
                fontFamily: 'worksans-semibold,sans-serif',
                fontSize: '12px',
                verticalAlign: 'middle',
                textAlign:'left',
              }}
            >
              {logChunk.map(log => (
                <span
                  key={log.id}
                  style={{
                    color: log.session2status === 'N' ? 'red' : 'green',
                    marginRight: '8px',
                    fontSize: '20px',
                  }}
                >
                  ●
                </span>
              ))}
            </TableCell>
          </TableRow>
        ))}

        {/* Empty row for spacing after each user's data */}
        <TableRow>
          <TableCell colSpan={6} sx={{ padding: '16px 0' }}></TableCell>
        </TableRow>
      </React.Fragment>
    );
  })}
</TableBody>

                  </Table>
                </div>
              </Grid>
              
              
              )
            )}



          </Grid>
        </Grid>
      </Grid>
    </>
  );
};


export default LoginAuditReport;