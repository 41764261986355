import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material";
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import RM1 from "../HelpPopups/images/PASSA1.jpg";
import { Link } from "react-router-dom";

const RolePermissionhelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3
                style={{
                  marginLeft: "5rem",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Change Password
              </h3>
              <br />

              <li>
                To change the password of the logged in user, Change Password
                Screen is used.
              </li>
              <br />
              <li>PATH: Admin\Change Password</li>
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={RM1} alt="RM1" />
              </Stack>
              <li>
                To change the password, user should go to the above mentioned
                path and enter the details required as mentioned by the fields
                name and click on the “Change Password” button.
              </li>
              <br />
              <li>
                “Cancel” button can be used to clear the field details \ inputs.
              </li>
              <br />
              <span style={{ color: "red" }}>
                We may be using EMS login password
              </span>
              <li
                style={{ listStyleType: "square", textDecoration: "underline" }}
              >
                Validations on the screen:-
              </li>
              <br />
              <li>
                While changing the passwords, old and new password should not be
                the same.
              </li>
              <li>
                While changing the passwords, new and confirm password should be
                the same.
              </li>
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RolePermissionhelpPopup;
