import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import PCR1 from "../HelpPopups/images/PCR1.jpg";
import PCR2 from "../HelpPopups/images/PCR2.jpg";
import PCR3 from "../HelpPopups/images/PCR3.jpg";
import PCR4 from "../HelpPopups/images/PCR4.jpg";
import PCR5 from "../HelpPopups/images/PCR5.jpg";
import PCR6 from "../HelpPopups/images/PCR6.jpg";
import PCR7 from "../HelpPopups/images/PCR7.jpg";
import PCR8 from "../HelpPopups/images/PCR8.jpg";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const PortfolioChartReporthelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{ listStyleType: "none" }}>
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>PORTFOLIO CHART REPORT</h3>
              <br />
              <li>
                Portfolio chart report is Data visualization dashboard to view specific or multiple attributes based project details.
              </li>
              <br></br>
              <li>Path to view : CCPM {">>"} Reports {">"} Portfolio Chart Report. Refer screen shot A
              </li>
              <br></br>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "45rem" }}>
                <img src={PCR1} alt="PCR1" />
              </Stack>
              <br />

              <li>
                On selection of ‘portfolio chart report’ Tab below screen will open. refer screenshot B.
              </li>
              <li>
                Here, on Instance selection its different attributes will appear. To shorten the search different attributes can be selected for search and search button needs to be click.
              </li>
              <br></br>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "45rem" }}>
                <img src={PCR2} alt="PCR2" />
              </Stack>
              <br />

              <li>On clicking ‘search’ button,  report with excel download / Display, as below, can be viewed with different attributes information. Refer screenshot C

              </li>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "45rem" }}>
                <img src={PCR3} alt="PCR3" />
              </Stack>
              <br />

              <ol>
                <li><strong>Project Id and Project Name</strong> - Project Id and name generated for respective project, can be viewed under heading.</li>
                <li><strong>% Completed</strong> - It shows data for critical chain completed percentage value for respective project.</li>
                <li><strong>% task completion status in last 7 days</strong> - It shows a 7 day summary in percentage calculated value for last 7 days.</li>
                <li><strong>Current Department</strong> - It shows the information for department which task is currently pending to complete.</li>
                <li><strong>Delay (days)</strong> - Delay = Due date for action minus working days. </li>
                <li><strong>Expected Completion Date (ETC)</strong> - It is expected completion date shown for respective project.</li>
                <li><strong>Due Date</strong> - It is date on which project is supposed to be complete</li>
                <li><strong> Network Diagram:</strong> -   Just by clicking on network diagram for respective project a network diagram can be viewed in a new window.</li>

                <br />
                <Stack sx={{ paddingRight: "1rem", width: "10rem" }}>
                  <img src={PCR4} alt="PCR4" />
                </Stack>
                <br />

                <li><strong>Issues</strong> -   Issues raised by task manager during project implementation for different factors are viewed here.
                </li>
                <li><strong>View Pending task</strong> -   A hyper link to be given to the view pending task.
                </li>
              </ol>

              <li>Column header can be dragged and drop as shown in screenshot D to view the project details referring to selected header.
              </li>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "45rem" }}>
                <img src={PCR5} alt="PCR5" />
              </Stack>
              <br />

              <li> Headers provided with filter have multiple options to narrow the search among bulk data. Refer Screenshot F for it.</li>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "45rem" }}>
                <img src={PCR6} alt="PCR6" />
              </Stack>
              <br />

              <li><strong>Report download for portfolio chart.</strong> -   Report can be extracted in excel worksheet from the option ‘Export to Excel’ by clicking on it excel file get downloaded in downloads option.

              <br/>

                Refer Screenshot F.
              </li>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "45rem" }}>
                <img src={PCR7} alt="PCR7" />
              </Stack>
              <br />

              Below is format of downloaded report in excel format.

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "45rem" }}>
                <img src={PCR8} alt="PCR8" />
              </Stack>
              <br />

            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PortfolioChartReporthelpPopup;
