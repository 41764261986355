import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import bread from "../../../Assets/bread.png";
import i1 from "../../../Assets/i1.jpg";
import i2 from "../../../Assets/i2.jpg";
import i3 from "../../../Assets/i3.jpg";
import i4 from "../../../Assets/i4.jpg";
import i5 from "../../../Assets/i5.jpg";
import i6 from "../../../Assets/i6.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import modules from "./MyProjects.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import loading from "../../../Assets/Loading_image.gif";
import { styled } from "@mui/system";
import {
  MyProjGetInstance,
  MyProjGetProject,
  MyProjGetProjManager,
  MyProjGetAttributes,
  MyProjSubmit,
  MyProjUpdateProjAttribute,
  MyProjDeleteDetail,
  Change_Status,
} from "../../../Services/CCPM";
import { useNavigate } from "react-router-dom";

const MyProjects = () => {
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState(false);
  const [headers, setHeaders] = useState([]); //attribute labels

  //loading gif
  const [isLoading, setIsLoading] = useState(false);

  //LIST OF DROPDOWN DATA
  const [instance, setInstance] = useState([]);
  const [ProjectName, setProjectName] = useState([]);
  const [ProjectManager, setProjectManager] = useState([]);
  const [ProjectAccessType, setProjectAccessType] = useState([]);

  //SELECTED VALUES
  const [selectedInstance, setSelectedInstance] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedProjectManager, setSelectedProjectManager] = useState("");
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");
  const [ProjectStatus, setProjectStatus] = useState("-1");
  const [projDetails, setProjDetails] = useState(false);
  const [dummy, setDummy] = useState([]);
  const [dropdownss, setDropdownss] = useState([]);
  const [value, setValue] = useState({});
  const [updProjId, setUpdProjId] = useState("");
  const [updProjName, setUpdProjName] = useState("");
  const [projectDetailsText, setProjectDetailsText] = useState("");
  const [originalText, setOriginalText] = useState("");
  const [checkNoRec, setCheckNoRec] = useState(false);
  const [isComingFromCCPM, setIsComingFromCCPM] = useState(false); // Track if coming from CCPM

  // const recordsPerPage = 10; // Number of records per page
  // const [currentPage, setCurrentPage] = useState(1);

  // // Calculate the number of total pages
  // const totalPages = Math.ceil(dummy.length / recordsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10); // Show 10 records per page
  const totalPages = Math.ceil(dummy.length / pageSize);

  // Slice the data for the current page
  // Determine the current records to display
  const currentRecords = dummy.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  // Handle First page click
  const handleFirstPage = () => {
    setCurrentPage(1);
  };
  // Handle Previous page click
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Handle Next page click
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle Last page click
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const resetParam = params.get("reset");

    if (resetParam === "true" && instance.length > 0) {
      handleInstance(instance[0].id);
      handleResetDuplicate();
      setShowTable(false); // Hide the table when coming back
    }
  }, []);

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page on load or if data changes
  }, [dummy]);

  useEffect(() => {
    setIsLoading(true);
    let body = {
      // userId: sessionStorage.getItem("loginId"),
      userId: sessionStorage.getItem("loginId"),
    };
    MyProjGetInstance(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setInstance(response.data.success.data);
          if (localStorage.getItem("instanceId") !== null) {
            handleInstance(localStorage.getItem("instanceId"));
            handleSubmit(localStorage.getItem("instanceId"));
            localStorage.removeItem("instanceId");
          } else {
            handleInstance(response.data.success.data[0].id);
          }
        } else {
          alert(response?.response?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const resetParam = params.get("reset");
    const cameFromSettings = localStorage.getItem("cameFromSettings");

    if (resetParam === "true" && instance.length > 0 && cameFromSettings) {
      handleInstance(instance[0].id);
      setShowTable(false); // Hide the table when coming back
      localStorage.removeItem("cameFromSettings"); // Clear the flag
    }
  }, [instance]);

  const getattributes = (i) => {
    setIsLoading(true);
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: i,
    };
    MyProjGetAttributes(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          const dropdowns = response?.data?.success?.data;
          const activeLabels = dropdowns.labels?.filter(
            (label) => label.isactive === 1
          );
          setHeaders(activeLabels);
          console.log(activeLabels);
          const activeDropdowns = activeLabels?.map((label) => {
            const correspondingValues =
              dropdowns.values[label.attributenumber - 1];
            return {
              label: label.attributename,
              values: correspondingValues,
            };
          });
          setDropdownss(activeDropdowns);
        } else {
          alert(response?.response?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInstance = (i) => {
    setSelectedInstance(i);
    if (i === "Select") {
      setProjectName([]);
      setProjectManager([]);
    } else {
      setIsLoading(true);
      getattributes(i);
      let body = {
        userId: parseInt(sessionStorage.getItem("loginId")),
        instanceId: i,
      };
      let body1 = {
        instanceId: i,
        userId: parseInt(sessionStorage.getItem("loginId")),
        projStatusId: 0,
      };
      MyProjGetProject(body)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            setProjectName(response.data.success.data);
          } else {
            alert(response?.response?.data?.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      MyProjGetProjManager(body1)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            console.log(response);
            setProjectManager(response.data.success.data);
          } else {
            alert(response?.response?.data?.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setProjectAccessType([
      {
        id: "PM",
        name: "Where I am Project Manager",
      },
      {
        id: "APM",
        name: "Where I am Alternate PM",
      },
      {
        id: "LPM",
        name: "Where My L is  Project Manager",
      },
      {
        id: "LAPM",
        name: "Where My L is Alternate  PM",
      },
    ]);
    setSelectedProjectName("Select");
    setSelectedProjectManager("Select");
    setSelectedProjectAccessType("Select");
    setValue({});
    setDropdownss([]);
    setDummy([]);
    // setShowTable(false);
  };
  const handleResetDuplicate = () => {
    console.log("reset");
    handleInstance("");
    setSelectedInstance("Select");
    setProjectName([]);
    setProjectManager([]);
    setProjectAccessType([]);
    setSelectedProjectName("Select");
    setSelectedProjectManager("Select");
    setSelectedProjectAccessType("Select");
    setProjectStatus("");
    setCheckNoRec(false);
    setShowTable(false);
    setDummy([]);
    setHeaders([]);
    setDropdownss([]);
    setValue({});
  };
  //Reset button handle
  const handleReset = () => {
    handleInstance(instance[0].id);
    setShowTable(false);
    setCheckNoRec(false);
    setProjectStatus("-1");
    // setSelectedInstance(instance[0].id)
    // setProjectName([])
    // setProjectManager([])
    // setProjectAccessType([])
    // setSelectedProjectName("Select")
    // setSelectedProjectManager("Select")
    // setSelectedProjectAccessType("Select")
    // setProjectStatus("")
  };

  //attribute dropdown selection
  const handleDropdownSelection = (e, i) => {
    const { value } = e.target;
    setValue((prevValue) => ({
      ...prevValue,
      [`attribute${i + 1}Id`]: value,
    }));
  };

  const handleAddEditNav = (projId) => {
    localStorage.setItem("instanceId", selectedInstance);
    navigate(`/ccpm/addEditTasks?projId=${projId}`);
  };

  const handleViewHis = (projId, element) => {
    element.style.color = "red";
    window.open(
      `/ccpm/ViewActiveProjectsHistory?projId=${projId}`,
      "sharer",
      "toolbar=0,status=0,width=1050,height=600"
    );
    setTimeout(() => {
      element.style.color = "#0000EE";
    }, 50);
  };

  const handleStagClick = (projid) => {
    window.open(
      `/ccpm/Staggering?projId=${projid}`,
      "sharer",
      "toolbar=0,status=0,width=1200,height=600"
    );
  };

  const handleCCPMSet = (projId) => {
    localStorage.setItem("instanceId", selectedInstance);
    window.open(
      `/ccpm/ApplyCCPMPopup?projId=${projId}`,
      "sharer",
      "toolbar=0,status=0,width=1050,height=600"
    );
  };

  const handleRiskNav = (projId) => {
    localStorage.setItem("instanceId", selectedInstance);
    navigate(`/ccpm/riskandmitigationplanPopup?projId=${projId}`);
  };

  const handlePlannedBilling = (projId, element) => {
    element.style.color = "red";
    setTimeout(() => {
      localStorage.setItem("instanceId", selectedInstance);
      navigate(`/ccpm/PlannedBillingProjection?projId=${projId}`);
      element.style.color = "#0000EE";
    }, 50);
  };

  const handlePopUpOpen = (projId, projName, projDetails, element) => {
    element.style.color = "red";
    setUpdProjId(projId);
    setUpdProjName(projName);
    setProjectDetailsText(projDetails);
    setOriginalText(projDetails);

    setTimeout(() => {
      setProjDetails(true);
      element.style.color = "#0000EE";
    }, 50);
  };

  const handleUpdateProjAttribute = () => {
    if (projectDetailsText === originalText) {
      alert("No change to update");
      setProjDetails(false);
      return;
    } else {
      setIsLoading(true);
      let body = {
        projectId: updProjId,
        projectDetails: projectDetailsText,
        userId: sessionStorage.getItem("loginId"),
      };
      MyProjUpdateProjAttribute(body)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            setProjDetails(false);
          } else {
            alert(response?.response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const handlePopUpCancel = () => {
    setProjDetails(false);
  };

  const handleDeleteDetails = (i) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      let body = {
        projectId: i,
      };
      MyProjDeleteDetail(body)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            alert("Details Deleted Sucessfully");
            handleSubmit(selectedInstance);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  //Submit button handle
  const handleSubmit = (instanceI) => {
    setIsLoading(true);
    let body = {
      instanceId: instanceI,
      statusId:
        ProjectStatus === "" || ProjectStatus === "-1"
          ? 0
          : parseInt(ProjectStatus),
      userId: parseInt(sessionStorage.getItem("loginId")),
      attribute1Id:
        value.attribute1Id !== undefined && value.attribute1Id !== "Select"
          ? value.attribute1Id
          : 0,
      attribute2Id:
        value.attribute2Id !== undefined && value.attribute2Id !== "Select"
          ? value.attribute2Id
          : 0,
      attribute3Id:
        value.attribute3Id !== undefined && value.attribute3Id !== "Select"
          ? value.attribute3Id
          : 0,
      attribute4Id:
        value.attribute4Id !== undefined && value.attribute4Id !== "Select"
          ? value.attribute4Id
          : 0,
      attribute5Id:
        value.attribute5Id !== undefined && value.attribute5Id !== "Select"
          ? value.attribute5Id
          : 0,
      attribute6Id:
        value.attribute6Id !== undefined && value.attribute6Id !== "Select"
          ? value.attribute6Id
          : 0,
      attribute7Id:
        value.attribute7Id !== undefined && value.attribute7Id !== "Select"
          ? value.attribute7Id
          : 0,
      attribute8Id:
        value.attribute8Id !== undefined && value.attribute8Id !== "Select"
          ? value.attribute8Id
          : 0,
      attribute9Id:
        value.attribute9Id !== undefined && value.attribute9Id !== "Select"
          ? value.attribute9Id
          : 0,
      attribute10Id:
        value.attribute10Id !== undefined && value.attribute10Id !== "Select"
          ? value.attribute10Id
          : 0,
      projId:
        selectedProjectName === "" || selectedProjectName === "Select"
          ? 0
          : selectedProjectName,
      projMgrId:
        selectedProjectManager === "" || selectedProjectManager === "Select"
          ? 0
          : selectedProjectManager,
      accessType:
        selectedProjectAccessType === "" ||
        selectedProjectAccessType === "Select"
          ? ""
          : selectedProjectAccessType,
      privilegeFunctionPage: "ccpm_networkplan_viewprojectplans_aspx",
    };
    MyProjSubmit(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          const check = response?.data?.success?.data?.ProjectDetails;
          setDummy(check);
          if (check.length !== 0) {
            setShowTable(true);
            setCheckNoRec(false);
          } else {
            setCheckNoRec(true);
            setShowTable(false);
          }
        } else {
          alert(response?.response?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleActiveStatus = (projectid, instaceId) => {
    setIsLoading(true);
    let body = {
      projectId: projectid,
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: selectedInstance,
      privilegeFunctionPage: "ccpm_networkplan_viewprojectplans_aspx",
    };

    Change_Status(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          alert(response.data.success.message) ||
            alert(response.data.success.data);
          handleSubmit(selectedInstance);
        } else {
          alert(response?.response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div
      style={{
        overflowX: "auto",
        height: "100vh",
        zIndex: "1501",
      }}
    >
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      {/* <Grid className={modules["main_cont"]}>
        <SampleSidebar />
      </Grid> */}

      <Grid item lg={12} className={modules["home-cont"]}>
        <Home page={"MyProjects"} />
      </Grid>

      <Stack direction="column" sx={{ marginLeft: "6.5rem", width: "100%" }}>
        <div className={modules["instancemaster_div"]}>
          <div className={modules["pro-bread"]}>
            <a>CCPM</a>
            <img src={bread} alt="" />
            <a>Project Plan</a>
            <img src={bread} alt="" />
            <a>My Projects</a>
          </div>
        </div>
        <Stack
          direction="column"
          sx={{
            padding: "26px  16px 16px 0px",
            width: "fit-content",
          }}
        >
          <Grid container className={modules["parent-container"]}>
            <Stack direction="column" sx={{ width: "80rem" }}>
              <div className={modules["grid-container"]}>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Instance:<span className={modules["asterisk"]}>*</span>{" "}
                  </div>
                  <select
                    className={modules["dropdownSelect1"]}
                    value={selectedInstance}
                    onChange={(e) => handleInstance(e.target.value)}
                  >
                    {instance?.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </Stack>

                {/* Attribute Dropdown part */}
                {dropdownss?.map((obj, i) => {
                  return (
                    <Stack
                      spacing={2}
                      direction="row"
                      className={modules["each-entry"]}
                    >
                      <div className={modules["input-labels"]}>
                        {obj.label}:
                      </div>
                      <select
                        className={modules["dropdownSelect1"]}
                        value={value[`attribute${i + 1}Id`] || "Select"}
                        onChange={(event) => handleDropdownSelection(event, i)}
                      >
                        <option value="Select">SELECT</option>
                        {obj.values?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.attributevalue}
                          </option>
                        ))}
                      </select>
                    </Stack>
                  );
                })}
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>Project Name:</div>

                  <select
                    className={modules["dropdownSelect1"]}
                    value={selectedProjectName}
                    onChange={(e) => setSelectedProjectName(e.target.value)}
                  >
                    <option value="Select">SELECT</option>
                    {ProjectName?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.projectname}
                      </option>
                    ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Project Manager:
                  </div>

                  <select
                    className={modules["dropdownSelect1"]}
                    value={selectedProjectManager}
                    onChange={(e) => setSelectedProjectManager(e.target.value)}
                  >
                    <option value="Select">SELECT</option>
                    {ProjectManager?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.username}
                      </option>
                    ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Project Access Type:
                  </div>

                  <select
                    className={modules["dropdownSelect1"]}
                    value={selectedProjectAccessType}
                    onChange={(e) =>
                      setSelectedProjectAccessType(e.target.value)
                    }
                  >
                    <option value="Select">SELECT</option>
                    {ProjectAccessType?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Stack>
              </div>
              <Grid container spacing={2} className={modules["mid-cont"]}>
                <Grid item sx={{ padding: "0px !important" }}>
                  <span>Project Status :</span>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{ marginLeft: "32px" }}
                  className={modules["status-grid"]}
                >
                  <Box onClick={() => setProjectStatus("0")}>
                    <input
                      type="radio"
                      value="1"
                      checked={ProjectStatus === "0"}
                      style={{ margin: "3px 3px 0px 5px" }}
                    />
                    <label className={modules["radio-div"]}>ShowAll</label>
                  </Box>
                </Grid>
                <Grid item xs={1} className={modules["status-grid"]}>
                  <Box onClick={() => setProjectStatus("1")}>
                    <input
                      type="radio"
                      value="1"
                      checked={ProjectStatus === "1"}
                      style={{ margin: "3px 3px 0px 5px" }}
                    />
                    <label className={modules["radio-div"]}>Created</label>
                  </Box>
                </Grid>
                <Grid item xs={1} className={modules["status-grid"]}>
                  <Box onClick={() => setProjectStatus("2")}>
                    <input
                      type="radio"
                      value="1"
                      checked={ProjectStatus === "2"}
                      style={{ margin: "3px 3px 0px 5px" }}
                    />
                    <label className={modules["radio-div"]}>Submitted</label>
                  </Box>
                </Grid>
                <Grid item xs={1} className={modules["status-grid"]}>
                  <Box onClick={() => setProjectStatus("3")}>
                    <input
                      type="radio"
                      value="1"
                      checked={ProjectStatus === "3"}
                      style={{ margin: "3px 3px 0px 5px" }}
                    />
                    <label className={modules["radio-div"]}>Planned</label>
                  </Box>
                </Grid>
                <Grid item xs={1} className={modules["status-grid"]}>
                  <Box onClick={() => setProjectStatus("9")}>
                    <input
                      type="radio"
                      value="1"
                      checked={ProjectStatus === "9"}
                      style={{ margin: "3px 3px 0px 5px" }}
                    />
                    <label className={modules["radio-div"]}>Scheduled</label>
                  </Box>
                </Grid>
                <Grid item xs={1} className={modules["status-grid"]}>
                  <Box onClick={() => setProjectStatus("4")}>
                    <input
                      type="radio"
                      value="1"
                      checked={ProjectStatus === "4"}
                      style={{ margin: "3px 3px 0px 5px" }}
                    />
                    <label className={modules["radio-div"]}>Active</label>
                  </Box>
                </Grid>
                <Grid item xs={1} className={modules["status-grid"]}>
                  <Box onClick={() => setProjectStatus("5")}>
                    <input
                      type="radio"
                      value="1"
                      checked={ProjectStatus === "5"}
                      style={{ margin: "3px 3px 0px 5px" }}
                    />
                    <label className={modules["radio-div"]}>Hold</label>
                  </Box>
                </Grid>
                <Grid item xs={1} className={modules["status-grid"]}>
                  <Box
                    onClick={() => setProjectStatus("10")}
                    sx={{ width: "120px" }}
                  >
                    <input
                      type="radio"
                      value="1"
                      checked={ProjectStatus === "10"}
                      style={{ margin: "3px 3px 0px 5px" }}
                    />
                    <label className={modules["radio-div"]}>
                      CDDCheckedOut
                    </label>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={modules["status-grid"]}
                  sx={{ marginLeft: "25px" }}
                >
                  <Box onClick={() => setProjectStatus("11")}>
                    <input
                      type="radio"
                      value="1"
                      checked={ProjectStatus === "11"}
                      style={{ margin: "3px 3px 0px 5px" }}
                    />
                    <label className={modules["radio-div"]}>ModCheckOut</label>
                  </Box>
                </Grid>
                <Grid item xs={1} className={modules["status-grid"]}>
                  <Box onClick={() => setProjectStatus("7")}>
                    <input
                      type="radio"
                      value="1"
                      checked={ProjectStatus === "7"}
                      style={{ margin: "3px 3px 0px 5px" }}
                    />
                    <label className={modules["radio-div"]}>Completed</label>
                  </Box>
                </Grid>
              </Grid>

              <Grid container className={modules["btn1-contMyP"]}>
                <button
                  className={modules["btn1"]}
                  onClick={() => handleSubmit(selectedInstance)}
                >
                  Search
                </button>
                <button className={modules["btn2"]} onClick={handleReset}>
                  Reset
                </button>
              </Grid>
            </Stack>
          </Grid>
          {showTable && (
            <>
              <Grid
                container
                className={modules[("table-grid", "table-grid-margin")]}
              >
                <div className={modules["table-container"]}>
                  <table
                    className={[
                      modules["custom-table"],
                      modules["custom-table-th"],
                    ].join(" ")}
                  >
                    <thead>
                      <tr>
                        <th align="left" style={{ width: "3%" }}>
                          Modif.&nbsp;No
                        </th>
                        <th align="left" style={{ width: "100px" }}>
                          Project Name
                        </th>
                        <th align="left">Project Manager</th>
                        <th align="left">Customer Name</th>
                        <th align="left">Calendar Name</th>
                        <th align="left">ERP No</th>
                        <th align="left">Original Start Date</th>
                        <th align="left">Original End Date</th>
                        <th align="left">Customer Due Date</th>
                        <th align="left">Requested Due Date</th>
                        <th align="left">Scheduled Start Date</th>
                        <th align="left">Scheduled End Date</th>
                        <th align="center" style={{ width: "100px" }}>
                          Status
                        </th>
                        {/* <th align="center" style={{ width: "100px" }}>
                        Open In New Format
                      </th> */}
                        <th align="center" style={{ width: "100px" }}>
                          Open
                        </th>
                        <th align="center" style={{ width: "100px" }}>
                          CCPM Settings
                        </th>
                        <th align="center" style={{ width: "100px" }}>
                          Staggering
                        </th>
                        <th align="center" style={{ width: "100px" }}>
                          Change Status
                        </th>
                        <th align="center" style={{ width: "100px" }}>
                          Planned Billing Projection
                        </th>
                        <th align="center" style={{ width: "100px" }}>
                          Risk
                        </th>
                        <th align="center" style={{ width: "100px" }}>
                          Delete / Restore
                        </th>
                        <th align="center" style={{ width: "100px" }}>
                          View History
                        </th>
                        <th align="center" style={{ width: "100px" }}>
                          Update Attribute
                        </th>
                        <th align="center">Instance</th>
                        {headers.map((item) => {
                          return <th align="center">{item.attributename}</th>;
                        })}
                        <th align="left">Created On</th>
                        <th align="left">Project Last Updated On</th>
                        <th align="left">Task Last Updated On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentRecords?.map((item, index) => (
                        <tr key={index} className={modules["table-row"]}>
                          <td>{item["Modif Version"]}</td>
                          <td>{item.projectname}</td>
                          <td>{item.projectmanagername}</td>
                          <td>{item.customername}</td>
                          <td>{item.calendarname}</td>
                          <td>{item.erpno}</td>
                          <td>
                            {item.startdate &&
                              new Date(item.startdate).toLocaleDateString(
                                "en-GB"
                              )}
                          </td>
                          <td>
                            {item.enddate &&
                              new Date(item.enddate).toLocaleDateString(
                                "en-GB"
                              )}
                          </td>
                          <td>
                            {item.customerduedate &&
                              new Date(item.customerduedate).toLocaleDateString(
                                "en-GB"
                              )}
                          </td>
                          <td>
                            {item.duedate &&
                              new Date(item.duedate).toLocaleDateString(
                                "en-GB"
                              )}
                          </td>
                          <td>
                            {item.staggeredstartdate &&
                              new Date(
                                item.staggeredstartdate
                              ).toLocaleDateString("en-GB")}
                          </td>
                          <td>
                            {item.staggeredenddate &&
                              new Date(
                                item.staggeredenddate
                              ).toLocaleDateString("en-GB")}
                          </td>
                          <td align="center">{item.status}</td>
                          {/* <td align="center">
                          <img
                            src={i1}
                            alt=""
                            className={modules["custom-img-size"]}
                          />
                        </td> */}
                          <td align="center">
                            <img
                              src={i2}
                              alt=""
                              className={modules["custom-img-size"]}
                              onClick={() => {
                                handleAddEditNav(item.projid);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                          <td align="center">
                            <img
                              src={i3}
                              alt=""
                              onClick={
                                item.status === "Submitted"
                                  ? () => handleCCPMSet(item.projid)
                                  : null
                              }
                              style={{
                                cursor:
                                  item.status === "Submitted"
                                    ? "pointer"
                                    : "default",
                              }}
                              className={modules["custom-img-size"]}
                            />
                          </td>
                          <td
                            align="center"
                            onClick={() => {
                              if (item.status === "Planned") {
                                handleStagClick(item.projid);
                              }
                            }}
                          >
                            <img
                              src={i4}
                              alt=""
                              className={modules["custom-img-size"]}
                              style={{
                                cursor:
                                  item.status === "Planned"
                                    ? "pointer"
                                    : "default",
                              }}
                              title={
                                item.status === "Planned"
                                  ? "StaggeringWithoutResource"
                                  : ""
                              }
                            />
                          </td>
                          <td align="center">
                            <button
                              className={modules["btn1-active"]}
                              onClick={
                                item.status === "Scheduled"
                                  ? () =>
                                      handleActiveStatus(
                                        item.projid,
                                        item.instance
                                      )
                                  : null
                              }
                              style={{
                                cursor:
                                  item.status === "Scheduled"
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              Active
                            </button>
                          </td>

                          <td align="center">
                            {item.status === "Active" ||
                            item.status === "Completed" ? (
                              <a
                                className={modules["planned-a-hover"]}
                                onClick={(e) =>
                                  handlePlannedBilling(
                                    item.projid,
                                    e.currentTarget
                                  )
                                }
                              >
                                Planned Billing Projection
                              </a>
                            ) : (
                              <a className={modules["textRMP2"]}>
                                Planned Billing Projection
                              </a>
                            )}
                          </td>
                          <td align="center">
                            <img
                              src={i5}
                              alt=""
                              className={modules["custom-img-size"]}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRiskNav(item.projid)}
                            />
                          </td>
                          <td align="center">
                            <img
                              src={i6}
                              alt=""
                              onClick={() => handleDeleteDetails(item.projid)}
                              style={{ cursor: "pointer" }}
                              className={modules["custom-img-size"]}
                            />
                          </td>

                          <td align="center">
                            <a
                              onClick={(e) =>
                                handleViewHis(item.projid, e.currentTarget)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              View History
                            </a>
                          </td>
                          <td align="center">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                if (item.status === "Completed") {
                                  alert(
                                    "Update to complete project is not allowed"
                                  );
                                  return;
                                } else {
                                  handlePopUpOpen(
                                    item.projid,
                                    item.projectname,
                                    item.projectdetails,
                                    e.currentTarget
                                  );
                                }
                              }}
                            >
                              Update Project Attribute
                            </a>
                          </td>
                          <td align="center">{item.instance}</td>
                          {headers.map((i) => {
                            return (
                              <td align="center">
                                {item[`a${i.attributenumber}`]}
                              </td>
                            );
                          })}
                          <td align="left">
                            {item.createdon &&
                              new Date(item.createdon).toLocaleDateString(
                                "en-GB"
                              )}
                          </td>
                          <td align="left">
                            {item.lastmodifiedon &&
                              new Date(item.lastmodifiedon).toLocaleDateString(
                                "en-GB"
                              )}
                          </td>
                          <td align="left">
                            {item.lasttaskupdationdate &&
                              new Date(
                                item.lasttaskupdationdate
                              ).toLocaleDateString("en-GB")}
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="100%">
                          {" "}
                          {/* colspan covers entire width of the table */}
                          {/* <div className={modules["pagination-container"]} style={{borderBottom:"1px solid #a4bf47",width:"57px"}}>
                <div style={{marginBottom:"15px"}}>
                {/* <span
                  onClick={handleLastPage}
                  disabled={currentPage === 1}
                  className={modules["pagination-button"]}
                  style={{ cursor:'pointer' }}
                  // style={{ cursor: currentPage === 1 ? 'none' : 'pointer' }}
                >
                  Last
                </span> */}
                          {/* &nbsp;
                <span
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className={modules["pagination-button"]}
                  // style={{ cursor: currentPage === totalPages ?'none' : 'pointer' }}
                  style={{ cursor:'pointer' }}
                >
                  Next
                </span> */}
                          {/* <span
                    onClick={handleFirstPage}
                    disabled={currentPage === 1}
                    className={modules["pagination-button"]}
                  >
                    First
                  </span>
                  &nbsp;
                  <span
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={modules["pagination-button"]}
                  >
                    Previous
                  </span>
                  &nbsp;
                  <span
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={modules["pagination-button"]}
                  >
                    Next
                  </span>
                  &nbsp;
                  <span
                    onClick={handleLastPage}
                    disabled={currentPage === totalPages}
                    className={modules["pagination-button"]}
                  >
                    Last
                  </span>
                </div>
              </div> */}
                          <div className={modules["pagination-container"]}>
                            {currentPage === 1 && totalPages > 1 ? (
                              <>
                                <span
                                  onClick={handleNextPage}
                                  className={modules["pagination-button"]}
                                  style={{
                                    cursor:
                                      currentPage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Next
                                </span>
                                &nbsp;
                                <span
                                  onClick={handleLastPage}
                                  className={modules["pagination-button"]}
                                  style={{
                                    cursor:
                                      currentPage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Last
                                </span>
                              </>
                            ) : null}

                            {currentPage > 1 && currentPage < totalPages ? (
                              <>
                                <span
                                  onClick={handleFirstPage}
                                  className={modules["pagination-button"]}
                                  style={{
                                    cursor:
                                      currentPage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  First
                                </span>
                                &nbsp;
                                <span
                                  onClick={handlePrevPage}
                                  className={modules["pagination-button"]}
                                  style={{
                                    cursor:
                                      currentPage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Previous
                                </span>
                                &nbsp;
                                <span
                                  onClick={handleNextPage}
                                  className={modules["pagination-button"]}
                                  style={{
                                    cursor:
                                      currentPage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Next
                                </span>
                                &nbsp;
                                <span
                                  onClick={handleLastPage}
                                  className={modules["pagination-button"]}
                                  style={{
                                    cursor:
                                      currentPage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Last
                                </span>
                              </>
                            ) : null}

                            {currentPage === totalPages && totalPages > 1 ? (
                              <>
                                <span
                                  onClick={handleFirstPage}
                                  className={modules["pagination-button"]}
                                  style={{
                                    cursor:
                                      currentPage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  First
                                </span>
                                &nbsp;
                                <span
                                  onClick={handlePrevPage}
                                  className={modules["pagination-button"]}
                                  style={{
                                    cursor:
                                      currentPage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Previous
                                </span>
                              </>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  {/* Pagination Controls */}
                </div>
              </Grid>
            </>
          )}
          {checkNoRec && (
            <Stack className={modules["pbp_bottom_stack"]}>
              <p className={modules["pbp_no_record"]}>No Record Found.</p>
            </Stack>
          )}
          {projDetails && (
            <>
              <Box className={modules["popupOnPos"]}>
                <Stack direction="column" className={modules["popupOnPage"]}>
                  <Stack
                    direction="column"
                    sx={{
                      padding: "10px",
                      borderBottom: "1px solid #a4bf47",
                    }}
                  >
                    <Stack direction="row">
                      <Typography className={modules["projectNormalText"]}>
                        Project Id :
                      </Typography>
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        className={modules["projectNormalText"]}
                      >
                        {updProjId}
                      </Typography>
                    </Stack>
                    <Stack direction="row">
                      <Typography className={modules["projectNormalText"]}>
                        Project Name :
                      </Typography>
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        className={modules["projectNormalText"]}
                      >
                        {updProjName}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{ paddingTop: "5px" }}
                      alignItems="flex-end"
                    >
                      <Typography className={modules["projectNormalText"]}>
                        Project Additional Details:
                      </Typography>
                      <textarea
                        rows="2"
                        cols="20"
                        value={projectDetailsText}
                        onChange={(e) => setProjectDetailsText(e.target.value)}
                        onpaste={(e) => e.preventDefault()}
                        className={modules["projectTextArea"]}
                      ></textarea>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <button
                      className={modules["normalButton"]}
                      style={{
                        height: "20px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                      }}
                      onClick={handleUpdateProjAttribute}
                    >
                      Save{" "}
                    </button>
                    <button
                      className={modules["normalButton"]}
                      onClick={handlePopUpCancel}
                      style={{
                        height: "20px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                      }}
                    >
                      Cancel{" "}
                    </button>
                  </Stack>
                </Stack>
              </Box>
            </>
          )}
          {projDetails && <div className={modules["popupBack"]}></div>}
        </Stack>
      </Stack>
    </div>
  );
};

export default MyProjects;
