import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import bread from "../../../Assets/bread.png";
import { Set_NewInstance } from "../../../Services/Dss";
import {
  Set_MtaIntancelinkingData,
  Update_MtaIntancelinkingData,
} from "../../../Services/MTA";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function MTAUserInstanceLinking() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedIsDefault, setSelectedIsDefault] = useState(null);
  const [ShowTable, setShowTable] = useState(false);
  const [userID, setUserID] = useState("");

  const [selectedNewRows, setSelectedNewRows] = useState([]);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
  }, []);

  const getTableData = () => {
    if (userID.length === 0) {
      alert("User Id cannot be blank");
      return;
    } else {
      const body = {
        userID: userID.toLowerCase(),
      };
      setIsLoading(true);
      Set_MtaIntancelinkingData(body)
        .then((response) => {
          setSelectedNewRows([]);
          setFilteredData(response?.data?.success?.data);
          updateInstanceIds(response?.data?.success?.data);
          if (response?.data?.success?.data.length === 0) {
            setShowTable(false);
            setIsLoading(false);
          } else if (response?.data?.success?.data.length > 0) {
            setShowTable(true);
            setIsLoading(false);
          } else {
            alert("User does not exists");
            setShowTable(false);
            setIsLoading(false);
          }
          console.log(response?.data?.success?.data);
          setIsLoading(false);
          setIsLoading(false);
        })
        .catch((error) => {
          alert("User does not exists");
          setShowTable(false);
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  const updateInstanceIds = (instanceData) => {
    const ids = instanceData
      ?.filter((i) => i.isuserinstance === 1)
      .map((i) => i.miuid_instance_uid);
    setSelectedRows(ids);
    // setTabDisplay(true);

    const defaultItems = instanceData
      ?.filter((i) => i.isdefaultinstance === 1)
      .map((i) => i.miuid_instance_uid);

    if (defaultItems.length > 0) {
      const instanceid = defaultItems[0];
      setSelectedIsDefault(instanceid);
    } else {
    }
  };

  function handleCheckboxChange(event, instanceId) {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, instanceId]);
    } else {
      setSelectedRows(selectedRows?.filter((id) => id !== instanceId));
    }
    // console.log(selectedNewRows, "SNR");
  }

  function closeHandler() {
    window.location.reload();
  }

  function handleSelectAll(event) {
    if (event.target.checked) {
      const instanceIds = filteredData?.map((i) => i.miuid_instance_uid);
      setSelectedRows(instanceIds);
      setSelectedNewRows(instanceIds);
    } else if (!event.target.checked) {
      setSelectedRows([]);
      setSelectedNewRows([]);
      setSelectedIsDefault("");
    }
  }

  function submitHandler() {
    console.log(selectedRows, "SNR", selectedIsDefault);
    console.log(userID);

    if (userID === "") {
      alert("User ID cannot be blank");
      return;
    } else {
      if (ShowTable) {
        const joinedString = selectedRows.join(",");
        const body = {
          userID: userID.toLowerCase(),
          input_list: joinedString || "0",
          muiuid_last_modified_by: sessionStorage.getItem("loginUsername").toLowerCase(),
          muiuid_default_instance_uid: selectedIsDefault || "",
        };
        if (joinedString === "") {
          if (window.confirm("No record selected. Do you want to proceed?")) {
            setIsLoading(true);
            if (body) {
              Update_MtaIntancelinkingData(body)
                .then((response) => {
                  console.log(response?.data?.success?.data);
                  alert(
                    `MTA Instance linking removed successfully for User Id: ${userID}`
                  );
                  setIsLoading(false);
                  setSelectedNewRows([]);
                  window.location.reload(); // Reload the page
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              alert("Enter Instance");
            }
          }
        } else {
          setIsLoading(true);
          if (body) {
            Update_MtaIntancelinkingData(body)
              .then((response) => {
                console.log(response?.data?.success?.data);
                alert(
                  `MTA Instance linking successfully to User Id: ${userID}`
                );
                setIsLoading(false);
                setSelectedNewRows([]);
                window.location.reload(); // Reload the page
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            alert("Enter Instance");
          }
        }
      } else {
        alert("Please click on search button for list of Instances");
      }
    }
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"MTAUserInstanceLinking"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>User Instance Linking</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table
                className={modules["customTable"]}
                style={{ marginLeft: "3rem" }}
              >
                <tbody>
                  <tr style={{ paddingLeft: "5rem" }}>
                    <td style={{ width: "7rem", fontWeight: "bold" }}>
                      User ID <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={userID}
                        onChange={(e) => setUserID(e.target.value)}
                        onInput={(e) => {
                          const regex = /^[a-zA-Z0-9()&_\-+=\[\]/?. ]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9()&_\-+=\[\]/?. ]/g,
                              ""
                            );
                            setUserID(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          const regex2 = /^[a-zA-Z0-9_-]*$/;
                          if (!regex2.test(e.target.value)) {
                            alert(
                              "Only alphanumeric characters are valid in this field."
                            );
                            setUserID("");
                          }
                        }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px", width: "250px" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Grid
                container
                className={modules["mid-cont"]}
                style={{ marginLeft: "12rem" }}
              >
                <button className={modules["btn1"]} onClick={getTableData}>
                  Search
                </button>
                <button
                  className={modules["btn1"]}
                  style={{ marginLeft: "20px" }}
                  onClick={submitHandler}
                >
                  Submit
                </button>
                <button
                  className={modules["btn1"]}
                  style={{ marginLeft: "20px" }}
                  onClick={closeHandler}
                >
                  Close
                </button>
              </Grid>

              {filteredData?.length > 0 && ShowTable && (
                <Grid
                  container
                  className={modules["mid-cont"]}
                  sx={{ marginLeft: "3rem" }}
                >
                  <Table
                    style={{ width: "30rem" }}
                    className={modules["custom_table"]}
                  >
                    <TableHead>
                      <TableRow
                        className={modules["header_text"]}
                        sx={{
                          "& th": {
                            color: "#fff",
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            padding: "5px 10px !important",
                            fontWeight: "bold",
                            fontSize: "16px",
                            width:"5rem"
                          }}
                        >
                          Sr. No.
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "5px 10px !important",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          MTA Instance
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "85px !important",
                            fontWeight: "bold",
                            fontSize: "16px",
                            padding: "5px 10px !important",
                          }}
                        >
                          <input
                            type="checkbox"
                            inputProps={{
                              "aria-label": "select all desserts",
                            }}
                            checked={
                              filteredData?.length === selectedRows.length
                            }
                            className={modules["custom-checkbox"]}
                            onChange={handleSelectAll}
                          />
                          <span style={{ padding: "0px 4px" }}>IsLinked</span>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "10% !important",
                            fontWeight: "bold",
                            fontSize: "16px",
                            padding: "5px 10px !important",
                          }}
                        >
                          IsDefault
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData?.map((i, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            className={modules["UIL_TR"]}
                            key={i.miuid_instance_uid}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              sx={{
                                padding: "8px",
                                color: "#414141",
                              }}
                              className={modules["row_text"]}
                              align="left"
                            >
                              <span>{index + 1}</span>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "8px",
                                color: "#414141",
                              }}
                              className={modules["row_text"]}
                              align="left"
                            >
                              <span>{i.miuid_instance_uid}</span>
                            </TableCell>

                            <TableCell align="left" padding="checkbox">
                              <input
                                type="checkbox"
                                inputProps={{
                                  "aria-label": "select all desserts",
                                }}
                                className={modules["custom-checkbox"]}
                                checked={selectedRows.includes(
                                  i.miuid_instance_uid
                                )}
                                onChange={(e) => {
                                  handleCheckboxChange(e, i.miuid_instance_uid);
                                  const isChecked = e.target.checked;
                                  const instanceId = i.miuid_instance_uid;
                                  if (!selectedNewRows.includes(instanceId)) {
                                    setSelectedNewRows([
                                      ...selectedNewRows,
                                      instanceId,
                                    ]);
                                  } else if (
                                    selectedRows.includes(instanceId)
                                  ) {
                                    setSelectedNewRows([
                                      ...selectedNewRows,
                                      instanceId,
                                    ]);
                                    if (selectedIsDefault === instanceId) {
                                      setSelectedIsDefault(0);
                                    }
                                  } else {
                                    setSelectedNewRows(
                                      selectedNewRows?.filter(
                                        (id) => id !== instanceId
                                      )
                                    );
                                    if (selectedIsDefault === instanceId) {
                                      setSelectedIsDefault(0);
                                    }
                                  }
                                }}
                              />
                            </TableCell>

                            <TableCell align="left" padding="checkbox">
                              <input
                                type="checkbox"
                                inputProps={{
                                  "aria-label": "select all desserts",
                                }}
                                className={modules["custom-checkbox"]}
                                checked={
                                  selectedIsDefault === i.miuid_instance_uid
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedIsDefault(i.miuid_instance_uid);
                                  } else if (
                                    !e.target.checked &&
                                    selectedIsDefault === i.miuid_instance_uid
                                  ) {
                                    setSelectedIsDefault(0);
                                  }
                                }}
                                disabled={
                                  !selectedRows.includes(i.miuid_instance_uid)
                                }
                                // disabled={i.isdefaultinstance === 0}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
