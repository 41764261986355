import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import modules from "./../../ccpm.module.css";
import { useLocation } from "react-router-dom";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import bread from "../../../../Assets/bread.png";
import {
  BindHalfHours,
  BindCalHours,
  BindSubmitHrs,
} from "../../../../Services/CCPM";
import loading from "../../../../Assets/Loading_image.gif";

const CalendarNonWorking = () => {
  const location = useLocation();
  //loading gif
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const statusIdFromQuery = parseInt(searchParams.get("status"));
  const calendarIdFromQuery = parseInt(searchParams.get("calendarId"));
  const dateFromQuery = searchParams.get("date");
  const [workinghour, setWorkinghour] = useState([]);
  const [timeEntries, setTimeEntries] = useState(
    Array(7).fill({ from: "", to: "", fromAMPM: "AM", toAMPM: "AM" })
  );

  useEffect(() => {
    if (statusIdFromQuery === 1) {
      GetHalfHours();
    } else if (statusIdFromQuery === 0) {
      GetCalHours();
    }
  }, []);

  const GetHalfHours = async () => {
    let body = {
      calendarId: calendarIdFromQuery,
      dates: dateFromQuery,
    };
    try {
      setIsLoading(true);
      const response = await BindHalfHours(body);
      if (response?.data?.statusCode === 200) {
        setWorkinghour(response?.data?.success?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const GetCalHours = async () => {
    try {
      setIsLoading(true);
      const response = await BindCalHours({ calendarId: calendarIdFromQuery });
      if (response?.data?.statusCode === 200) {
        setWorkinghour(response?.data?.success?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (window.opener) {
      // Refresh the parent window before closing the current window
      window.opener.location.reload();

      // Close the current window (works if it was opened programmatically)
      window.close();
    } else {
      // Fallback: If the window was not opened programmatically, reload the current window itself
      window.location.reload();
    }
  };

  const handleTimeChange = (index, field, value) => {
    const validValue = value.replace(/[^0-9:]/g, "");
    const newHours = [...timeEntries];
    if (field === "from" || field === "to") {
      newHours[index] = { ...newHours[index], [field]: validValue };
    } else {
      newHours[index] = { ...newHours[index], [field]: value };
    }
    setTimeEntries(newHours);
  };

  const isValidTimeFormat = (time) => {
    const timeFormatRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
    return timeFormatRegex.test(time);
  };

  const convertTo24HourFormat = (time) => {
    const [timePart, period] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  };

  const submitHandler = async () => {
    const filteredHours = timeEntries.filter((hour) => hour.from && hour.to);

    if (filteredHours.length === 0) {
      alert("Working Hours Can not be blank.");
      return;
    }

    const firstHour = timeEntries[0];
    if (!firstHour.from || !firstHour.to) {
      alert("Working Hours Can not be blank.");
      return;
    }

    for (const hour of filteredHours) {
      if (
        !isValidTimeFormat(`${hour.from} ${hour.fromAMPM}`) ||
        !isValidTimeFormat(`${hour.to} ${hour.toAMPM}`)
      ) {
        alert("Invalid time. Time format should be HH:MM AM/PM.");
        return;
      }
      const fromTime = convertTo24HourFormat(`${hour.from} ${hour.fromAMPM}`);
      const toTime = convertTo24HourFormat(`${hour.to} ${hour.toAMPM}`);

      if (fromTime > toTime) {
        alert("Working From Time Can not be Greater Than To Time.");
        return;
      }
    }

    try {
      setIsLoading(true);
      const body = filteredHours.map((hour) => ({
        calendarId: calendarIdFromQuery,
        hoursFrom: `${hour.from} ${hour.fromAMPM}`,
        hoursTo: `${hour.to} ${hour.toAMPM}`,
        date: dateFromQuery,
      }));
      const response = await BindSubmitHrs(body);
      if (response?.data?.statusCode === 200) {
        alert(response?.data?.success?.data["Alert-true"]);
        if (statusIdFromQuery === 1) {
          GetHalfHours();
        } else if (statusIdFromQuery === 0) {
          setWorkinghour(response?.data?.success?.data["Grid_data"]);
        }
      } else {
        alert(response?.error?.message || "An error occurred");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDateMain = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div style={{ marginTop: "3rem", marginLeft: "1rem", width: "40%" }}>
        <a
          style={{
            color: "#0000EE",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "16px",
            marginRight: "5px",
          }}
          className={modules["historyTable"]}
        >
          Calender
        </a>
        <img src={bread} alt="" style={{ marginRight: "5px" }} />
        <a
          style={{
            color: "#0000EE",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "16px",
          }}
          className={modules["historyTable"]}
        >
          Calendar Working/NonWorking Hrs
        </a>
      </div>

      <Stack direction="column" sx={{ margin: "5rem 0rem 1rem 1rem" }}>
        <Typography
          className={modules["dateTextC"]}
          sx={{ fontSize: "25px", marginBottom: "1%" }}
        >
          Date : {formatDateMain(dateFromQuery)}
        </Typography>
        {workinghour && workinghour.length > 0 && (
          <table className={modules["tableViewHisProj"]}>
            <thead>
              <tr style={{ fontWeight: "bold" }}>
                <th align="left" style={{ width: "10%" }}>
                  Sr.No.
                </th>
                <th align="left" style={{ width: "30%" }}>
                  From Hours
                </th>
                <th align="left" style={{ width: "30%" }}>
                  To Hours
                </th>
                <th align="left" style={{ width: "30%" }}>
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {workinghour.map((item, index) => (
                <tr
                  key={index}
                  style={{ color: "Black", backgroundColor: "#DEDFDE" }}
                >
                  <td align="center">{index + 1}</td>
                  <td align="center">{item.to_char || item.hoursfrom}</td>
                  <td align="center">{item.to_char1 || item.hoursto}</td>
                  <td align="center">{item.logincode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <Grid container sx={{ marginTop: "1%" }}>
          <table border="0">
            <thead>
              <tr>
                <td>
                  <strong className={modules["dateTextHeaderC"]}>
                    From Hours To Hours
                  </strong>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {timeEntries.map((entry, index) => (
                <tr key={index}>
                  <td style={{ paddingRight: "21px" }}>
                    <input
                      type="text"
                      maxLength="5"
                      value={entry.from}
                      className={modules["inputCpop"]}
                      onChange={(e) =>
                        handleTimeChange(index, "from", e.target.value)
                      }
                      style={{ width: "35px", marginRight: "55px" }}
                    />
                    <select
                      value={entry.fromAMPM}
                      onChange={(e) =>
                        handleTimeChange(index, "fromAMPM", e.target.value)
                      }
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className={modules["inputCpop"]}
                      maxLength="5"
                      value={entry.to}
                      onChange={(e) =>
                        handleTimeChange(index, "to", e.target.value)
                      }
                      style={{ width: "35px", marginRight: "5px" }}
                    />
                    <select
                      value={entry.toAMPM}
                      onChange={(e) =>
                        handleTimeChange(index, "toAMPM", e.target.value)
                      }
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
        <Stack direction="row" sx={{ marginTop: "4px" }}>
          <button
            className={modules["history-label"]}
            style={{
              padding: "2px 4px 2px 4px",
              marginLeft: "3px",
              fontSize: "12px",
            }}
            onClick={submitHandler}
          >
            Submit
          </button>
          <button
            className={modules["history-label"]}
            onClick={() => handleClose()}
            style={{
              marginLeft: "5px",
              padding: "2px 4px 2px 4px",
              fontSize: "12px",
            }}
          >
            Close
          </button>
        </Stack>
      </Stack>
    </>
  );
};

export default CalendarNonWorking;
