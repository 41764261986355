import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import PCS_1 from "../HelpPopups/images/PCS_1.jpg";
import PCS_2 from "../HelpPopups/images/PCS_2.jpg";
import PCS_3 from "../HelpPopups/images/PCS_3.jpg";

import { Link } from "react-router-dom";

const ProductConfighelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>
                Product Configuration Settings
              </h3>
              <br />
              <li>
                Product configuration settings is a screen which acts as a main
                screen for defining the levels in some of the below mentioned
                masters screen.
                <ul style={{ marginTop: "1rem" }}>
                  <li>Fever Chart Master</li>
                  <li>Customer master</li>
                  <li>Procurement Category</li>
                  <li>Delay Master</li>
                  <li>Resource Group master</li>
                  <li>Resource Master</li>
                  <li>WIP & Project Limit Master</li>
                </ul>
              </li>
              <li style={{ marginTop: "1rem" }}>
                PATH : CCPM \ Masters \ Product configuration settings
              </li>
              <li style={{ marginTop: "1rem" }}>
                The company name or the instance name is set in this screen for
                the field “Company Name”
              </li>
              <li>
                Fields in “User Authentication” helps user choose to execute
                transaction on company database or on online database present.
              </li>
              <li style={{ marginTop: "1rem" }}>
                Here user can add Maximum “10 dependency Levels” on this screen.
              </li>
              <li style={{ marginTop: "1rem", textDecoration: "underline" }}>
                Following are the steps to create/Add the levels.
                <ul>
                  <li>For adding the levels click on “Add” button.</li>
                  <li>Enter the name of the level</li>
                  <li>
                    Select the levels on which the creating level will be
                    depended on.
                  </li>
                  <li>
                    Click on the “Save All” button and levels will be created.
                  </li>
                </ul>
              </li>
              <li>
                User can use the “Cancel” button for canceling the process of
                Adding the levels.
              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={PCS_1} alt="PCS_1" />
              </Stack>
              <li>
                Levels can also be deleted using the “Delete” button, user has
                to select the level to Delete and click on the Delete button.
                <ul>
                  <li>
                    Note :- levels cant be deleted in between the sequence.
                  </li>
                  <li>
                    For Example :- if there is sequence of levels from 1- 10,
                    then user has to first delete the 10 th prior to deleting
                    the 9 th level. I.e. For deleting the levels, it should be start deleting from the lowest levels first.
                  </li>
                </ul>
              </li>
              <li>The link “Company Hierarchy” can be used to check the structure and dependency of the levels specified.</li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img style={{height: "15rem", width:"15rem"}} src={PCS_2} alt="PCS_2" />
              </Stack>
              <li>Screens specified under the table “Dependencies and Mappings” are some of the master screens in which we have to define the levels for mapping the the data on that particular screens and for defining the levels, we have to select the levels in the drop down fields and click on “Save All” button for saving the selected levels.</li>
              <Stack sx={{ paddingRight: "1rem" }}>
              <img style={{height: "35rem", width:"35rem"}} src={PCS_3} alt="PCS_3" />
              </Stack>
              <li>Reset button can be used for undo the changes performed on the screen.</li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductConfighelpPopup;
