import { React, useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Stack,
  Typography,
  Popover,
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import { RMP_ViewHistory } from "../../../Services/CCPM";
import loading from "../../../Assets/Loading_image.gif";
import modules from "./../ccpm.module.css";

const RiskHistory = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projId = parseInt(searchParams.get("PrjRiskId"));
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let body = {
      riskId: projId,
    };
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await RMP_ViewHistory(body);
        setTableData(response?.data?.success?.data);
        setIsLoading(false);
      } catch (err) {
        console.log("error", err);
      }
      //   finally {
      //     setLoading(false);
      //   }
    };

    fetchData();
  }, []);

  function formatActivityDate(activity) {
    const datePattern = /(\w{3} \d{2} \d{4} \d{2}:\d{2}[APM]{2})/;
    const dateMatch = activity.match(datePattern);
  
    if (!dateMatch) return activity;
  
    const dateString = dateMatch[0];
    
    const formattedDateString = new Date(dateString.replace(/(\w{3}) (\d{2}) (\d{4}) (\d{2}):(\d{2})([APM]{2})/, 
      (match, month, day, year, hours, minutes, period) => {
        const monthMap = { Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
                           Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12' };
        const hour = period === 'PM' && hours !== '12' ? parseInt(hours, 10) + 12 : (period === 'AM' && hours === '12' ? '00' : hours);
        return `${year}-${monthMap[month]}-${day}T${hour}:${minutes}:00`;
      }
    ));
  
    const options = {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
  
    const formattedDate = formattedDateString
      .toLocaleString("en-US", options)
      .replace(",", "");
  
    return activity.replace(dateString, formattedDate);
  }

  function formatHistoryDate(historydate) {
    const date = new Date(historydate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <>
      {/* Risk History Section */}
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Box>
        <Stack
          direction="row"
          spacing={4}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Stack>
            <img
              style={{
                width: "96px",
                height: "46.39px !important",
                alignSelf: "end",
                paddingTop: "10px",
              }}
              src={logo}
              alt="Godrej"
            />
          </Stack>
          <Stack sx={{ paddingTop: "10px", paddingRight: "10px" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
      </Box>
      <div style={{ textAlign: "left", marginLeft: "1%", marginTop: "2%" }}>
        <div
          style={{
            fontSize: "16px",
          }}
          className={modules["textRiskHis"]}
        >
          Risk History
        </div>
        <table style={{ width: "55%", marginTop: "2%", marginLeft: "1.5%" }}>
          <tbody>
            <tr>
              <td>
                <table border="0" cellPadding="2" cellSpacing="1">
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <div
                          style={{
                            borderColor: "Blue",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            height: "400px",
                            overflowY: "scroll",
                          }}
                        >
                          {tableData.length > 0 && (
                            <table
                              cellSpacing="1"
                              cellPadding="1"
                              rules="all"
                              style={{
                                backgroundColor: "White",
                                borderColor: "Black",
                                borderWidth: "1px",
                                borderStyle: "Solid",
                              }}
                              className={modules["tableRiskHis"]}
                            >
                              <thead>
                                <tr
                                  style={{
                                    color: "#E7E7FF",
                                    backgroundColor: "#A4BF47",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                  }}
                                >
                                  <th
                                    style={{
                                      padding: "1px",
                                    }}
                                  >
                                    Sr. No.
                                  </th>
                                  <th
                                    style={{
                                      padding: "1px",
                                    }}
                                  >
                                    Project Name
                                  </th>
                                  <th
                                    style={{
                                      padding: "1px",
                                    }}
                                  >
                                    Risk
                                  </th>
                                  <th
                                    style={{
                                      padding: "1px",
                                    }}
                                  >
                                    Activity
                                  </th>
                                  <th
                                    style={{
                                      padding: "1px",
                                    }}
                                  >
                                    HistoryDate
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {tableData.map((item, index) => (
                                  <tr
                                    key={index}
                                    style={{
                                      color: "#000000",
                                      backgroundColor: "#DEDFDE",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <td
                                      align="center"
                                      style={{ width: "10%", padding: "1px" }}
                                    >
                                      <span>{index + 1}</span>
                                    </td>
                                    <td
                                      align="center"
                                      style={{ width: "10%", padding: "1px" }}
                                    >
                                      <span>{item.projectname}</span>
                                    </td>
                                    <td
                                      align="center"
                                      style={{ width: "10%", padding: "1px" }}
                                    >
                                      <span>{item.risk}</span>
                                    </td>
                                    <td
                                      align="center"
                                      style={{ width: "10%", padding: "1px" }}
                                    >
                                      <span>
                                        {formatActivityDate(item.activity)}
                                      </span>
                                    </td>
                                    <td
                                      align="center"
                                      style={{ width: "10%", padding: "1px" }}
                                    >
                                      <span>
                                        {formatHistoryDate(item.historydate)}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RiskHistory;
