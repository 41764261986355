import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/CM1.jpg";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";
import RiskActionOwner from "../CCPM/RiskManagement/RiskActionOwner";
import RISKACTIONOWNER from "../HelpPopups/images/RISKACTIONOWNER.jpg";
import HelpIndex from "./HelpIndex";

const RiskActionOwnerhelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              paddingLeft: "1rem",
              fontFamily: "'Times New Roman', Times, serif",
            }}
          >
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <h3
              style={{
                textDecoration: "underline",
                fontFamily: "Cablibri,serif",
                fontSize: "26px",
                paddingLeft: "8rem",
              }}
            >
              {" "}
              RISK Mitigation Owner
            </h3>
            <br></br>
            In Risk mitigation action user enters the action taken again any
            risk which has been assigned to him. when action taken against risk
            can be seen in the reports after appropriate selection of project.
            <br></br>
            <br></br>
            <br></br>
            <Stack
              style={{ width: "631.99px", height: "290px" }}
              sx={{ paddingRight: "1rem" }}
            >
              <img src={RISKACTIONOWNER} alt="RISKACTIONOWNER" />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RiskActionOwnerhelpPopup;
