import axios from "axios";
import {
  API_KEY,
  GetValidActiveUserId,
  GetInstanceForUser,
  SetDefaultInstance,
  UpdateUserInstanceAccess,
  GetInstanceWiseAttribute,
  GetAttributeHierarchy,
  GetAttributeHierarchyValue,
  InsertAttributeValueLinking,
  UploadInstance,
  DownloadInstance,
  RT_TableData,
  RT_Add,
  RT_Update,
  RT_Delete,
  RT_DeleteAll,
  IAL_BindInstance,
  IAL_InsertInstanceWiseAttributeHierarchy,
  IAL_UpdateInstanceAttributeHierarchy,
  IAL_AttributeHierarchyGrid,
  CM_GetInstance,
  CM_RowUpdating,
  CM_RowAdding,
  CM_RowDeleting,
  CM_GetBind,
  DM_DeleteData,
  DM_getInstance,
  DM_UpdateData,
  DM_getDelayReasons,
  DM_SaveData,
  TMT_GetInstance,
  TMT_GetRadio,
  TMT_GetGrid,
  TMT_SaveConfig,
  WL_GetInstance,
  WL_GetGrid,
  WL_RowUpd,
  WL_SaveBtn,
  APCR_getInstance,
  APCR_getChangeReasons,
  APCR_SaveData,
  APCR_UpdateData,
  APCR_DeleteData,
  PC_getCompName,
  PC_getRootLevel,
  PC_getSubRootLevel,
  PC_getHierarchyLevelDS,
  PC_getLevelNamesDS,
  PC_GetDependentLevelDS,
  PC_UpdateRow,
  PC_DeleteRow,
  PC_getInstanceName,
  PC_SaveBottom,
  FCM_UpdateData,
  FCM_DeleteData,
  FCM_BulkDelete,
  FCM_Grid,
  FCM_GetLevelNames,
  FCM_DependencyApi,
  FCM_Level3,
  FCM_Level4,
  FCM_Level5,
  FCM_Level6,
  FCM_Level7,
  FCM_Level8,
  FCM_Level9,
  FCM_Level10,
  FCM_Level11,
  InstanceData,
  InstanceIdBasedData,
  InstanceIdBasedHistory,
  SaveData,
  GetCalenderRules,
  GetCalenderNameList,
  GetCalendarLog,
  GetWorkingHrsDS,
  C_BindHalfHrs,
  C_BindCalHrs,
  DeleteSelectedRules,
  C_BindHrsSubmit,
  GetBindInstanceTM,
  GetPCInctances,
  GetPCProjectName,
  GetPCProjectManager,
  GetPCAttributesValuesForSearchFilter,
  GetPCAttributeHierarchy,
  GetPCSearchData,
  GetPCProjectCountDetails,
  GetPCgetallprojectdetails,
  CPMGetInstancesList,
  CPMBindProjects,
  CPMBindProjectManagers,
  CPMGrid,
  CPMBtnClick,
  PCSDStatus,
  BindProjectManager,
  BindProjectManagers,
  BindUserInstance,
  BindProject,
  BindProjectDetailGrid,
  btnChangePM_Click,
  Instance_SelectedIndexChanged,
  CreatePlanSubmit,
  GetAlternateAccessType,
  BindAlternateProjectManagers,
  BindProjectSelectionGrid,
  BindExistingAlternatePM,
  BindAlternateMgrAccessDetailGrid,
  btnSubmit_Click,
  imgSave_Click,
  imgbtnDelete_Click,
  getattribute,
  myprojects_updateAtrSave,
  myprojects_BindUserInstance,
  myprojects_BindProjects,
  myprojects_BindProjectMgrs,
  myprojects_attributes,
  myprojects_Submit,
  BMReport,
  atm_BindAccessType,
  atm_BindAlternateTaskManagers,
  atm_BindExistingAlternatePM,
  atm_BindProjects,
  atm_BindTaskManagers,
  atm_BindProjectSelectionGrid,
  atm_BindTasks,
  atm_imgSave_Click,
  atm_imgbtnDelete_Click,
  LARBindTaskManager,
  LARManager,
  LARsessionNames,
  LARsearchClick,
  GetIRAssignedTo,
  GetIRExportToExcel,
  GetIRsearchTable,
  GetIRProjectDetail,
  createPlanSubmit2,
  viewproj_BindProjectStatus,
  view_projmanagers,
  viewproj_ProjectColor,
  viewproj_customers,
  DTFBindTaskManager,
  DTFSearcClick,
  DTFExporttoExcel,
  DSTFProjects,
  DSTFProjectManagers,
  DSTFSearchClick,
  DSTFExporttoExcel,
  PWCCBtnSearchClick,
  view_history,
  viewproj_submit,
  viewproj_projname,
  myproj_DeleteProjectDetails,
  searchBufferRecovery,
  insertBufferRecovery,
  updateBufferRecovery,
  GetLTMTaskManager,
  GetFinancialYear,
  GetTTMTProjectNames,
  GetTTMTResult,
  GetTWBCProjectManagers,
  GetTWBCSearchData,
  GetParetoSearchData,
  PBP_getERP,
  PBP_updateERP,
  PBP_searchERP,
  PBP_getTaskDet,
  PBP_updateTaskDet,
  RMP_getRiskType,
  RMP_getRiskCat,
  RMP_getTriggerStat,
  RMP_insertData,
  RMP_getDetailsPop,
  GetRiskActionOwnerData,
  RR_getSearchDetails,
  RMP_updateData,
  RMP_history,
  RR_OnUpdate,
  loadCCPMSettings,
  applyCCPMParams,
  submitCCPMSettings,
  RMP_getMitigation,
  RMP_ownerDetails,
  RMP_InsertProjRiskMit,
  RMP_DeleteEntries,
  pendingtaskone,
  pendingtasktwo,
  pendingtaskthree,
  viewriskpopup,
  viewissuepopup,
  bufferrecoverypopup,
  newprojectplanfile,
  AET_getTasksBind,
  AET_projProp,
  GetissueData,
  oldprojectplanfile,
  AET_getProjById,
  AET_taskMgmt,
  AET_saveApi,
  AET_submitApi,
  AET_deleteApi,
  AET_searchApi,
  Get_APProjectName,
  Get_APProjectManagers,
  Get_APCPSearch,
  Get_SubType,
  saveAsTempl,
  LOTM_summerySearch,
  LOTM_detailedSearch,
  PVA_tableSearch,
  PVA_TaskManager,
  PVA_TaskStatus,
  PVA_BindTaskColors,
  PVA_BindProjects,
  PVA_BindDepartment_pva,
  ST_GetProjStats,
  ST_ERPLnCheck,
  ST_GetUnitNames,
  TM_GetTaskManager,
  TM_GetTaskStatus,
  TM_GetTaskColors,
  TM_GetTaskNotes,
  GetCPRSearch,
  RAO_saveAndRelease,
  ST_GetProcList,
  ST_GetAllTasks,
  ST_GetAllListType,
  TM_GetTable,
  ganttchartGetTasksbyProjectId,
  loadGanttChart,
  ST_ListUpd,
  ST_ListDel,
  ST_GetAutoUp,
  ST_CommonSubmit,
  ST_GetVolTasks,
  PBP_getInvoiceDet,
  DM_UpdateDetails,
  DM_Searchfilter,
  Events_GetProjectDetails,
  Events_SaveNotes,
  RiskBindData,
  RiskSubmit,
  atm_Submit_Click,
  ST_TaskClose,
  ST_TaskStart,
  ST_AutoUpdSave,
  ST_AutoTaskStart,
  ST_AutoTaskDel,
  ST_VolAddTasks,
  ST_VolTaskStart,
  ST_VolTaskClose,
  ST_VolDeleteTask,
  ST_VolTaskSave,
  ST_ProTaskStart,
  ST_ProCloseTask,
  ST_ProTaskSave,
  getProjectInfo,
  ST_ProcDelTask,
  ST_SaveTasks,
  ST_ProChangeCategory,
  recommendedStartAndFinishDate,
  ExistingCalender,
  AddCalender,
  RemoveCalenderData,
  CopyCalender,
  OpenWorkingHours,
  GetworkingHrsDs,
  C_MainCalHours,
  C_MainCalDiffHours,
  PCRBtnClick,
  networkChart,
  FeverChartProjectDetails,
  FeverChartData,
  LoadPortfolioChart,
  histogramChartData,
  getExcelFormat,
  PC_getLevelDetails,
  AddRule,
  AddRule3,
  createPlanSubmit3,
  GetInitialTaskManager,
  GetBottomTaskManager,
  GetInsertButton,
  AETPT_DeleteTasks,
  ChangeStatusofProject,
  GetSeachDetail,
  GetSaveAll,
  getDownloadResultbtn,
  simulateData,
  ShowWeeks,
  ManualRunBm,
  ActiveSaveImgClick,
  CP_btnComplete_Click,
  CP_btnSave_Click,
  PCS_SearchBtn,
  viewSuccessorrpopup,
  viewSuccessorrTable,
  viewPredecessorpopup,
  raiseissuecategory,
  getViewHistory,
  getRaiseListHistory,
  getOwnerCategory,
  getDurationHistory,
  viewTaskDuration,
  viewTaskDurationGraph,
  Save_taks_raise,
  getTaskNotes,
  closeRaiseIssue,
  saveTaskNotes,
  AcceptSimulate,
  GetDepartmentDropdown,
  GetTaskmanagerDropdown,
  SearchDepartment,
  GetAllAlternateTM,
  imgSave_ClickTM,
  TM_NewGetTable,
  GetDelayReson,
  GetTaskDetails,
} from "../Constants/index.js";

// CCPM -> Master -> InstanceDivisionDepartment

export async function UploadInstanceFile(formData) {
  try {
    const response = await axios.post(`${API_KEY}${UploadInstance}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Important: specify content type as multipart/form-data
      },
    });
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}

export async function DownloadInstanceFile() {
  return await axios
    .post(`${API_KEY}${DownloadInstance}?module=CCPM`)
    .then((res) => {
      // downloadCsv(res, 'InstanceDivisionDepartment.csv');
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// CCPM -> Master -> UserInstanceLinking

export async function GetValidUser(body) {
  return await axios
    .post(`${API_KEY}${GetValidActiveUserId}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetInstances(body) {
  return await axios
    .post(`${API_KEY}${GetInstanceForUser}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function UpdateInstanceAccess(body) {
  return await axios
    .post(`${API_KEY}${UpdateUserInstanceAccess}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function DefaultInstance(body) {
  return await axios
    .post(`${API_KEY}${SetDefaultInstance}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function InstanceWiseAttribute(body) {
  return await axios
    .post(`${API_KEY}${GetInstanceWiseAttribute}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetInstanceAttribute(body) {
  return await axios
    .post(`${API_KEY}${GetAttributeHierarchy}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetInstanceAttributeValue(body) {
  return await axios
    .post(`${API_KEY}${GetAttributeHierarchyValue}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function giveAttributeValueAccess(body) {
  return await axios
    .post(`${API_KEY}${InsertAttributeValueLinking}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RT_GetTb() {
  return await axios
    .post(`${API_KEY}${RT_TableData}`, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RT_Insert(body) {
  return await axios
    .post(`${API_KEY}${RT_Add}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RT_Upd(body) {
  return await axios
    .post(`${API_KEY}${RT_Update}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RT_Del(body) {
  return await axios
    .post(`${API_KEY}${RT_Delete}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RT_DelBulk(body) {
  return await axios
    .post(`${API_KEY}${RT_DeleteAll}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// CCPM -> Master -> UserInstanceLinking

export async function GetCompanyNameDS(body) {
  return await axios
    .post(`${API_KEY}${PC_getCompName}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetRootLevel(body) {
  return await axios
    .post(`${API_KEY}${PC_getRootLevel}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function GetSubRootLevel(body) {
  return await axios
    .post(`${API_KEY}${PC_getSubRootLevel}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetHierarchyLevelDS(body) {
  return await axios
    .post(`${API_KEY}${PC_getHierarchyLevelDS}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetLevelNamesDS(body) {
  return await axios
    .post(`${API_KEY}${PC_getLevelNamesDS}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function GetLevelDetails(body) {
  return await axios
    .post(`${API_KEY}${PC_getLevelDetails}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetDependentLevelDS(body) {
  return await axios
    .post(`${API_KEY}${PC_GetDependentLevelDS}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PC_RowUpdate(body) {
  return await axios
    .post(`${API_KEY}${PC_UpdateRow}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PC_RowDelete(body) {
  return await axios
    .post(`${API_KEY}${PC_DeleteRow}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PC_InstanceNames(body) {
  return await axios
    .post(`${API_KEY}${PC_getInstanceName}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PC_SaveAll(body) {
  return await axios
    .post(`${API_KEY}${PC_SaveBottom}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// Fever Chart Master
export async function FCM_UpdData(body) {
  return await axios
    .post(`${API_KEY}${FCM_UpdateData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_DelData(body) {
  return await axios
    .post(`${API_KEY}${FCM_DeleteData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_BulkDelData(body) {
  return await axios
    .post(`${API_KEY}${FCM_BulkDelete}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_GetGrid() {
  return await axios
    .post(`${API_KEY}${FCM_Grid}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_LevelNames(body) {
  return await axios
    .post(`${API_KEY}${FCM_GetLevelNames}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_GetDependency(body) {
  return await axios
    .post(`${API_KEY}${FCM_DependencyApi}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_GetLevel3(body) {
  return await axios
    .post(`${API_KEY}${FCM_Level3}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_GetLevel4(body) {
  return await axios
    .post(`${API_KEY}${FCM_Level4}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_GetLevel5(body) {
  return await axios
    .post(`${API_KEY}${FCM_Level5}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_GetLevel6(body) {
  return await axios
    .post(`${API_KEY}${FCM_Level6}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_GetLevel7(body) {
  return await axios

    .post(`${API_KEY}${FCM_Level7}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_GetLevel8(body) {
  return await axios
    .post(`${API_KEY}${FCM_Level8}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_GetLevel9(body) {
  return await axios

    .post(`${API_KEY}${FCM_Level9}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_GetLevel10(body) {
  return await axios
    .post(`${API_KEY}${FCM_Level10}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function FCM_GetLevel11(body) {
  return await axios
    .post(`${API_KEY}${FCM_Level11}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// ACtive Project Change Reason Master
export async function APCR_InstanceNames(body) {
  return await axios
    .post(`${API_KEY}${APCR_getInstance}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function APCR_GetResons(body) {
  return await axios
    .post(`${API_KEY}${APCR_getChangeReasons}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function APCR_AddReason(body) {
  return await axios
    .post(`${API_KEY}${APCR_SaveData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function APCR_UpdData(body) {
  return await axios
    .post(`${API_KEY}${APCR_UpdateData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function APCR_DelData(body) {
  return await axios
    .post(`${API_KEY}${APCR_DeleteData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// Delay Master
export async function DM_InstanceNames(body) {
  return await axios
    .post(`${API_KEY}${DM_getInstance}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DMUpdData(body) {
  return await axios
    .post(`${API_KEY}${DM_UpdateData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DMDelData(body) {
  return await axios
    .post(`${API_KEY}${DM_DeleteData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DM_GetResons(body) {
  return await axios
    .post(`${API_KEY}${DM_getDelayReasons}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DM_AddReason(body) {
  return await axios
    .post(`${API_KEY}${DM_SaveData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IAL_InstanceNames(loginId) {
  try {
    const response = await axios.post(
      `${API_KEY}${IAL_BindInstance}`,
      {
        userId: loginId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (err) {
    throw new Error(`Failed to fetch instance names: ${err.message}`);
  }
}

export async function fetchAttributeHierarchy(instanceId, activeOnly = 1) {
  try {
    const response = await axios.post(
      `${API_KEY}${IAL_AttributeHierarchyGrid}`,
      {
        instanceId,
        activeOnly,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (err) {
    throw new Error(`Failed to fetch attribute hierarchy: ${err.message}`);
  }
}

export async function updateAttributeHierarchy(
  id,
  attributeName,
  isActive,
  modifiedBy
) {
  try {
    const response = await axios.post(
      `${API_KEY}${IAL_UpdateInstanceAttributeHierarchy}`,
      {
        id,
        attributeName,
        isActive,
        modifiedBy,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response; // Assuming your API returns a success message
  } catch (err) {
    throw new Error(`Failed to update attribute hierarchy: ${err.message}`);
  }
}

export async function insertInstanceWiseAttributeHierarchy(
  instanceId,
  attributeName,
  createdBy
) {
  try {
    const response = await axios.post(
      `${API_KEY}${IAL_InsertInstanceWiseAttributeHierarchy}`,
      {
        instanceId,
        attributeName,
        createdBy,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (err) {
    throw new Error(
      `Failed to insert instance-wise attribute hierarchy: ${err.message}`
    );
  }
}

export async function CM_InstanceData(body) {
  return await axios
    .post(`${API_KEY}${CM_GetInstance}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function CM_UpdRow(body) {
  return await axios
    .post(`${API_KEY}${CM_RowUpdating}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function CM_AddNew(body) {
  return await axios
    .post(`${API_KEY}${CM_RowAdding}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function CM_DelRow(body) {
  return await axios
    .post(`${API_KEY}${CM_RowDeleting}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function CM_GetTableData(body) {
  return await axios
    .post(`${API_KEY}${CM_GetBind}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function TMT_InstanceData(body) {
  return await axios
    .post(`${API_KEY}${TMT_GetInstance}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function TMT_RadioOptions(body) {
  return await axios
    .post(`${API_KEY}${TMT_GetRadio}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function TMT_GetTableData(body) {
  return await axios
    .post(`${API_KEY}${TMT_GetGrid}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function TMT_SaveBtn(body) {
  return await axios
    .post(`${API_KEY}${TMT_SaveConfig}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function WL_GetDropdown(body) {
  return await axios
    .post(`${API_KEY}${WL_GetInstance}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function WL_GetTableData(body) {
  return await axios
    .post(`${API_KEY}${WL_GetGrid}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function WL_UpdateRow(body) {
  return await axios
    .post(`${API_KEY}${WL_RowUpd}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function WL_RowAdd(body) {
  return await axios
    .post(`${API_KEY}${WL_SaveBtn}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function getInstanceData(body) {
  return await axios
    .post(`${API_KEY}${InstanceData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function getInstanceBasedData(body) {
  return await axios
    .post(`${API_KEY}${InstanceIdBasedData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function saveProjectConfig(body) {
  return await axios
    .post(`${API_KEY}${SaveData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function getProjectConfigHistory(body) {
  return await axios
    .post(`${API_KEY}${InstanceIdBasedHistory}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// Calender page APIs

export async function C_getCalenderReports(body) {
  return await axios
    .post(`${API_KEY}${GetCalendarLog}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function C_getAllCalenderNames() {
  return await axios
    .post(`${API_KEY}${GetCalenderNameList}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function C_getCalenderRules(body) {
  return await axios
    .post(`${API_KEY}${GetCalenderRules}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function C_GetWorkingHoursDS(body) {
  return await axios
    .post(`${API_KEY}${GetWorkingHrsDS}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function C_GetMainCalHours(body) {
  return await axios
    .post(`${API_KEY}${C_MainCalHours}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function C_GetMainCalDiffHours(body) {
  return await axios
    .post(`${API_KEY}${C_MainCalDiffHours}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function C_DeleteRules(body) {
  return await axios
    .post(`${API_KEY}${DeleteSelectedRules}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function BindHalfHours(body) {
  return await axios
    .post(`${API_KEY}${C_BindHalfHrs}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function BindCalHours(body) {
  return await axios
    .post(`${API_KEY}${C_BindCalHrs}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function BindSubmitHrs(body) {
  return await axios
    .post(`${API_KEY}${C_BindHrsSubmit}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function getExistingCalender(body) {
  return await axios
    .post(`${API_KEY}${ExistingCalender}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AddCalenderDetails(body) {
  return await axios
    .post(`${API_KEY}${AddCalender}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RemoveCalenderDetails(body) {
  return await axios
    .post(`${API_KEY}${RemoveCalenderData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function CopyCalenderDetails(body) {
  return await axios
    .post(`${API_KEY}${CopyCalender}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function addWorkingHours(body) {
  return await axios
    .post(`${API_KEY}${OpenWorkingHours}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function getWorkingHoursDs(body) {
  return await axios
    .post(`${API_KEY}${GetworkingHrsDs}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//TaskManagement API's

export async function GetTMInstances(body) {
  return await axios
    .post(`${API_KEY}${GetBindInstanceTM}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// CCPM -> Reports -> Issue Report

export async function GetIRSearchResult(body) {
  return await axios
    .post(`${API_KEY}${GetIRsearchTable}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetIRIssueOwner(body) {
  return await axios
    .post(`${API_KEY}${GetIRAssignedTo}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetIRExport(body) {
  try {
    const response = await axios.post(`${API_KEY}${GetIRExportToExcel}`, body, {
      responseType: "arraybuffer",
    });
    return response;
  } catch (error) {
    console.error("Error in RP_ExportExcel:", error);
    throw error;
  }
}

// CCPM -> Reports -> Project Completion Report

export async function PCR_BtnSearchClick(body) {
  return await axios
    .post(`${API_KEY}${PCRBtnClick}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// CCPM -> Reports -> Risk Review

export async function GetRiskReviewSearch(body) {
  return await axios
    .post(`${API_KEY}${RR_getSearchDetails}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetRiskReviewUpdate(body) {
  return await axios
    .post(`${API_KEY}${RR_OnUpdate}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// CCPM -> Reports -> RiskActionOwner

export async function GetActionOwnerData(body) {
  return await axios
    .post(`${API_KEY}${GetRiskActionOwnerData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetIssueListData(body) {
  return await axios
    .post(`${API_KEY}${GetissueData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ToSaveAndRelease(body) {
  return await axios
    .post(`${API_KEY}${RAO_saveAndRelease}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// CCPM -> Reports -> Portfoliochart

export async function GetPCInstances(body) {
  return await axios
    .post(`${API_KEY}${GetPCInctances}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetPCBindProjectName(body) {
  return await axios
    .post(`${API_KEY}${GetPCProjectName}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetPCBindProjectManager(body) {
  return await axios
    .post(`${API_KEY}${GetPCProjectManager}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetTTMTFinancialYear(body) {
  return await axios
    .post(`${API_KEY}${GetFinancialYear}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetTTMTBindProjectName(body) {
  return await axios
    .post(`${API_KEY}${GetTTMTProjectNames}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetLTMBindTaskManager(body) {
  return await axios
    .post(`${API_KEY}${GetLTMTaskManager}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetPCSearchFilter(body) {
  return await axios
    .post(`${API_KEY}${GetPCAttributesValuesForSearchFilter}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetPCHierarchyItems(body) {
  return await axios
    .post(`${API_KEY}${GetPCAttributeHierarchy}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function GetTTMTSearchResult(body) {
  return await axios
    .post(`${API_KEY}${GetTTMTResult}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetPCallprojectdetails(body) {
  return await axios
    .post(`${API_KEY}${GetPCgetallprojectdetails}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetPCProjectCount(body) {
  return await axios
    .post(`${API_KEY}${GetPCProjectCountDetails}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetPCSearchResult(body) {
  return await axios
    .post(`${API_KEY}${GetPCSearchData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetParetoSearchResult(body) {
  return await axios
    .post(`${API_KEY}${GetParetoSearchData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//CCPM --> TaskwiseBufferConsumption

export async function GetTWBCSearchResult(body) {
  return await axios
    .post(`${API_KEY}${GetTWBCSearchData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetTWBCProjectManager(body) {
  return await axios
    .post(`${API_KEY}${GetTWBCProjectManagers}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//CCPM -->Project Lead Time

export async function GetPLTdetails(body) {
  return await axios
    .post(`${API_KEY}${GetIRProjectDetail}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//CCPM -->Project  Stat as on Current Date

export async function GetBMSearchResult(body) {
  return await axios
    .post(`${API_KEY}${BMReport}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetStatus() {
  return await axios
    .post(`${API_KEY}${PCSDStatus}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetInstancesList(body) {
  return await axios
    .post(`${API_KEY}${CPMGetInstancesList}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//-------
export async function GetProjects(body) {
  return await axios
    .post(`${API_KEY}${CPMBindProjects}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
//-------
export async function GetProjectManagers(body) {
  return await axios
    .post(`${API_KEY}${CPMBindProjectManagers}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
//-------------
export async function GetTableDetails(body) {
  return await axios
    .post(`${API_KEY}${CPMGrid}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetExportExcel(body) {
  return await axios
    .post(`${API_KEY}${CPMBtnClick}`, body, {
      responseType: "arraybuffer",
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function CreatePlanInstanceChange(body) {
  return await axios
    .post(`${API_KEY}${Instance_SelectedIndexChanged}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetAccessType() {
  return await axios
    .post(`${API_KEY}${GetAlternateAccessType}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetAlternatePM(body) {
  return await axios
    .post(`${API_KEY}${BindAlternateProjectManagers}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SelectionGrid(body) {
  return await axios
    .post(`${API_KEY}${BindProjectSelectionGrid}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetPM(body) {
  return await axios
    .post(`${API_KEY}${BindProjectManagers}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetExistingPM(body) {
  return await axios
    .post(`${API_KEY}${BindExistingAlternatePM}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ExistingPMgrid(body) {
  return await axios
    .post(`${API_KEY}${BindAlternateMgrAccessDetailGrid}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SubmitAlternatePM(body) {
  return await axios
    .post(`${API_KEY}${btnSubmit_Click}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SubmitAlternateTM(body) {
  return await axios
    .post(`${API_KEY}${atm_Submit_Click}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DetailGridSave(body) {
  return await axios
    .post(`${API_KEY}${imgSave_Click}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DetailGridDel(body) {
  return await axios
    .post(`${API_KEY}${imgbtnDelete_Click}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ChangePM(body) {
  return await axios
    .post(`${API_KEY}${btnChangePM_Click}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetTableDetailss(body) {
  return await axios
    .post(`${API_KEY}${GetSeachDetail}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetProjectss(body) {
  return await axios
    .post(`${API_KEY}${BindProject}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetProjectManagerss(body) {
  return await axios
    .post(`${API_KEY}${BindProjectManager}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetInstancesListt(body) {
  return await axios
    .post(`${API_KEY}${BindUserInstance}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SubmitCreatePlan(body) {
  return await axios
    .post(`${API_KEY}${CreatePlanSubmit}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetAttributes(body) {
  return await axios
    .post(`${API_KEY}${getattribute}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MyProjUpdateProjAttribute(body) {
  return await axios
    .post(`${API_KEY}${myprojects_updateAtrSave}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MyProjGetInstance(body) {
  return await axios
    .post(`${API_KEY}${myprojects_BindUserInstance}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MyProjGetProject(body) {
  return await axios
    .post(`${API_KEY}${myprojects_BindProjects}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MyProjGetProjManager(body) {
  return await axios
    .post(`${API_KEY}${myprojects_BindProjectMgrs}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MyProjGetAttributes(body) {
  return await axios
    .post(`${API_KEY}${myprojects_attributes}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MyProjSubmit(body) {
  return await axios
    .post(`${API_KEY}${myprojects_Submit}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AtmGetAccessType(body) {
  return await axios
    .post(`${API_KEY}${atm_BindAccessType}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function AtmGetAtm(body) {
  return await axios
    .post(`${API_KEY}${atm_BindAlternateTaskManagers}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AtmGetAtm2(body) {
  return await axios
    .post(`${API_KEY}${GetAllAlternateTM}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}



export async function AtmGetExistingAtm(body) {
  return await axios
    .post(`${API_KEY}${atm_BindExistingAlternatePM}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AtmGetProjects(body) {
  return await axios
    .post(`${API_KEY}${atm_BindProjects}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AtmGetTaskManager(body) {
  return await axios
    .post(`${API_KEY}${atm_BindTaskManagers}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AtmGetSelectionGrid(body) {
  return await axios
    .post(`${API_KEY}${atm_BindProjectSelectionGrid}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AtmGetTasks(body) {
  return await axios
    .post(`${API_KEY}${atm_BindTasks}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AtmSaveClick(body) {
  return await axios
    .post(`${API_KEY}${atm_imgSave_Click}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AtmDeleteClick(body) {
  return await axios
    .post(`${API_KEY}${atm_imgbtnDelete_Click}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//ccpm -->lOGIN AUDIT REPORT

export async function LARGetTaskManagers(body) {
  return await axios
    .post(`${API_KEY}${LARBindTaskManager}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function LARGetManagers(body) {
  return await axios
    .post(`${API_KEY}${LARManager}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function LARGetSessionNames() {
  return await axios
    .post(`${API_KEY}${LARsessionNames}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function LARSearchClick(body) {
  return await axios
    .post(`${API_KEY}${LARsearchClick}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//ccpm -->Delay Task Form

export async function DTFGetTaskManagers(body) {
  return await axios
    .post(`${API_KEY}${DTFBindTaskManager}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DTFSearchClick(body) {
  return await axios
    .post(`${API_KEY}${DTFSearcClick}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function DTFExportClick(body) {
  return await axios
    .post(`${API_KEY}${DTFExporttoExcel}`, body, {
      responseType: "arraybuffer",
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//ccpm -->Sub Task  Task Form

export async function STFGetProjects(body) {
  return await axios
    .post(`${API_KEY}${DSTFProjects}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function STFSearchClick(body) {
  return await axios
    .post(`${API_KEY}${DSTFSearchClick}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function STFExportClick(body) {
  return await axios
    .post(`${API_KEY}${DSTFExporttoExcel}`, body, {
      responseType: "arraybuffer",
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function STFGetProjectManagers(body) {
  return await axios
    .post(`${API_KEY}${DSTFProjectManagers}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function CreatePlanSubmitClick2(body) {
  return await axios
    .post(`${API_KEY}${createPlanSubmit2}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function CreatePlanSubmitClick3(body) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios
    .post(`${API_KEY}${createPlanSubmit3}`, body, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewProjGetStatus(body) {
  return await axios
    .post(`${API_KEY}${viewproj_BindProjectStatus}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewProjGetManager(body) {
  return await axios
    .post(`${API_KEY}${view_projmanagers}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewProjGetColor(body) {
  return await axios
    .post(`${API_KEY}${viewproj_ProjectColor}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewProjGetCustomer(body) {
  return await axios
    .post(`${API_KEY}${viewproj_customers}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//CCPM -->ProjectWiseChainCompleted

export async function GetSearchClick(body) {
  return await axios
    .post(`${API_KEY}${PWCCBtnSearchClick}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//project Popups
export async function GetViewHistory(body) {
  return await axios
    .post(`${API_KEY}${view_history}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewProjSubmit(body) {
  return await axios
    .post(`${API_KEY}${viewproj_submit}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewProjGetProject(body) {
  return await axios
    .post(`${API_KEY}${viewproj_projname}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function MyProjDeleteDetail(body) {
  return await axios
    .post(`${API_KEY}${myproj_DeleteProjectDetails}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function Searchbufferrecovery(data) {
  try {
    return await axios
      .post(`${API_KEY}${searchBufferRecovery}`, data)
      .then((res) => {
        return res;
      });
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}
export async function InsertBufferRecovery(data) {
  try {
    return await axios
      .post(`${API_KEY}${insertBufferRecovery}`, data)
      .then((res) => {
        return res;
      });
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}
export async function UpdateBufferRecovery(data) {
  try {
    return await axios
      .post(`${API_KEY}${updateBufferRecovery}`, data)
      .then((res) => {
        return res;
      });
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}

// planned billing projection

export async function PBP_GetTableData(body) {
  return await axios
    .post(`${API_KEY}${PBP_getTaskDet}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PBP_UpdateTableData(body) {
  return await axios
    .post(`${API_KEY}${PBP_updateTaskDet}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PBP_GetInvoiceData(body) {
  return await axios
    .post(`${API_KEY}${PBP_getInvoiceDet}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PBP_ERPData(body) {
  return await axios
    .post(`${API_KEY}${PBP_getERP}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PBP_ERPUpdData(body) {
  return await axios
    .post(`${API_KEY}${PBP_updateERP}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PBP_ERPSearchData(body) {
  return await axios
    .post(`${API_KEY}${PBP_searchERP}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMP_RiskTypeD(body) {
  return await axios
    .post(`${API_KEY}${RMP_getRiskType}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMP_RiskCatD(body) {
  return await axios
    .post(`${API_KEY}${RMP_getRiskCat}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMP_TriggerStatusD(body) {
  return await axios
    .post(`${API_KEY}${RMP_getTriggerStat}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMP_InsertRisk(body) {
  return await axios
    .post(`${API_KEY}${RMP_insertData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMP_FetchDetailsPop(body) {
  return await axios
    .post(`${API_KEY}${RMP_getDetailsPop}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMP_UpdateDetailsPop(body) {
  return await axios
    .post(`${API_KEY}${RMP_updateData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMP_ViewHistory(body) {
  return await axios
    .post(`${API_KEY}${RMP_history}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetCCPMProjDetails(body) {
  return await axios
    .post(`${API_KEY}${loadCCPMSettings}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetCCPMParams(body) {
  return await axios
    .post(`${API_KEY}${applyCCPMParams}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ApplyBtnClick(body) {
  return await axios
    .post(`${API_KEY}${submitCCPMSettings}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMP_GetMitDetails(body) {
  return await axios
    .post(`${API_KEY}${RMP_getMitigation}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMP_Assign(body) {
  return await axios
    .post(`${API_KEY}${RMP_ownerDetails}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMP_InsertMit(body) {
  return await axios
    .post(`${API_KEY}${RMP_InsertProjRiskMit}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMP_DeleteRisk(body) {
  return await axios
    .post(`${API_KEY}${RMP_DeleteEntries}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PendingTaskPopup(body) {
  return await axios
    .post(`${API_KEY}${pendingtaskone}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PendingTaskPopup2(body) {
  return await axios
    .post(`${API_KEY}${pendingtasktwo}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function PendingTaskPopup2graph(body) {
  return await axios
    .post(`${API_KEY}${pendingtaskthree}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewRiskPopup(body) {
  return await axios
    .post(`${API_KEY}${viewriskpopup}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewIssuePopupbox(body) {
  return await axios
    .post(`${API_KEY}${viewissuepopup}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewBufferRecoveryPopup(body) {
  return await axios
    .post(`${API_KEY}${bufferrecoverypopup}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function NewProjectPlanExcel(body) {
  try {
    const response = await axios.post(`${API_KEY}${newprojectplanfile}`, body, {
      responseType: "arraybuffer",
    });
    return response;
  } catch (error) {
    console.error("Error in ExportExcel:", error);
    throw error;
  }
}

export async function AET_GetTable(body) {
  return await axios
    .post(`${API_KEY}${AET_getTasksBind}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AET_GetProjBind(body) {
  return await axios
    .post(`${API_KEY}${AET_projProp}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function OldProjectPlanExcel(body) {
  try {
    const response = await axios.post(`${API_KEY}${oldprojectplanfile}`, body, {
      responseType: "arraybuffer",
    });
    return response;
  } catch (error) {
    console.error("Error in ExportExcel:", error);
    throw error;
  }
}

export async function AET_GetProjInfo(body) {
  return await axios
    .post(`${API_KEY}${AET_getProjById}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AET_GetTaskMgmt() {
  return await axios
    .post(`${API_KEY}${AET_taskMgmt}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AET_SaveTasks(body) {
  return await axios
    .post(`${API_KEY}${AET_saveApi}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AET_SubmitTasks(body) {
  return await axios
    .post(`${API_KEY}${AET_submitApi}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AET_DeleteTasks(body) {
  return await axios
    .post(`${API_KEY}${AET_deleteApi}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AETPT_DeleteAllTasks(body) {
  return await axios
    .post(`${API_KEY}${AETPT_DeleteTasks}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AET_SearchTasks(body) {
  return await axios
    .post(`${API_KEY}${AET_searchApi}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Get_APBindProjectName(body) {
  return await axios
    .post(`${API_KEY}${Get_APProjectName}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Get_APBindProjectManager(body) {
  return await axios
    .post(`${API_KEY}${Get_APProjectManagers}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Get_CPSearchResult(body) {
  return await axios
    .post(`${API_KEY}${Get_APCPSearch}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AET_CheckSubType(body) {
  return await axios
    .post(`${API_KEY}${Get_SubType}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AET_SaveAsTemp(body) {
  return await axios
    .post(`${API_KEY}${saveAsTempl}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// Reports -- Load oN task manager

export async function Get_TMDetailedSearchResult(body) {
  return await axios
    .post(`${API_KEY}${LOTM_detailedSearch}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Get_TMSummerySearchResult(body) {
  return await axios
    .post(`${API_KEY}${LOTM_summerySearch}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// Reports -- planned vs Actual

export async function Get_PVASearchResult(body) {
  return await axios
    .post(`${API_KEY}${PVA_tableSearch}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Get_PVATaskManager(body) {
  return await axios
    .post(`${API_KEY}${PVA_TaskManager}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Get_PVATaskStatus(body) {
  return await axios
    .post(`${API_KEY}${PVA_TaskStatus}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Get_PVABindTaskColors() {
  return await axios
    .post(`${API_KEY}${PVA_BindTaskColors}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Get_PVABindProjects(body) {
  return await axios
    .post(`${API_KEY}${PVA_BindProjects}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Get_PVABindDepartment_pva() {
  return await axios
    .post(`${API_KEY}${PVA_BindDepartment_pva}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_FetchProjStatus(body) {
  return await axios
    .post(`${API_KEY}${ST_GetProjStats}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_FetchERPDetails(body) {
  return await axios
    .post(`${API_KEY}${ST_ERPLnCheck}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_FetchUnitNames() {
  return await axios
    .post(`${API_KEY}${ST_GetUnitNames}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_GetCategory(body) {
  return await axios
    .post(`${API_KEY}${ST_GetProcList}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_GetTableData(body) {
  return await axios
    .post(`${API_KEY}${ST_GetAllTasks}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_GetListTableData(body) {
  return await axios
    .post(`${API_KEY}${ST_GetAllListType}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// Task Management -- Task Management

export async function GetTaskManagerDetails(body) {
  return await axios
    .post(`${API_KEY}${TM_GetTaskManager}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetTaskStatusDetails(body) {
  return await axios
    .post(`${API_KEY}${TM_GetTaskStatus}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetTaskColorDetails(body) {
  return await axios
    .post(`${API_KEY}${TM_GetTaskColors}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetTaskNotesDetails(body) {
  return await axios
    .post(`${API_KEY}${TM_GetTaskNotes}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// Reports -- closed Project Report

export async function GetCPR_SearchResult(body) {
  return await axios
    .post(`${API_KEY}${GetCPRSearch}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetViewTaskTable(body) {
  return await axios
    .post(`${API_KEY}${TM_GetTable}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GanttChartGetTasksByProjectId(projId) {
  return await axios
    .post(`${API_KEY}${ganttchartGetTasksbyProjectId}${projId}`)
    .then((res) => {
      console.log("hhhhhhhhhhhhhhhhh", res);
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function LoadGanttChart(projectId) {
  return await axios
    .post(`${API_KEY}${loadGanttChart}${projectId}`)
    .then((res) => {
      console.log("res");
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_AutoSave(body) {
  return await axios
    .post(`${API_KEY}${ST_AutoUpdSave}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_AutoStartTask(body) {
  return await axios
    .post(`${API_KEY}${ST_AutoTaskStart}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_AutoDeleteTask(body) {
  return await axios
    .post(`${API_KEY}${ST_AutoTaskDel}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_VolTaskAdd(body) {
  return await axios
    .post(`${API_KEY}${ST_VolAddTasks}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_VolStartTask(body) {
  return await axios
    .post(`${API_KEY}${ST_VolTaskStart}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_VolCloseTask(body) {
  return await axios
    .post(`${API_KEY}${ST_VolTaskClose}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_VolTaskDelete(body) {
  return await axios
    .post(`${API_KEY}${ST_VolDeleteTask}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_VolSaveTask(body) {
  return await axios
    .post(`${API_KEY}${ST_VolTaskSave}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_ProStartTask(body) {
  return await axios
    .post(`${API_KEY}${ST_ProTaskStart}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_ProSaveTask(body) {
  return await axios
    .post(`${API_KEY}${ST_ProTaskSave}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_ProSaveAllTasks(body) {
  return await axios
    .post(`${API_KEY}${ST_SaveTasks}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_ProTaskClose(body) {
  return await axios
    .post(`${API_KEY}${ST_ProCloseTask}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_ProTaskDelete(body) {
  return await axios
    .post(`${API_KEY}${ST_ProcDelTask}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_ProSaveChangedCategory(body) {
  return await axios
    .post(`${API_KEY}${ST_ProChangeCategory}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_StartTask(body) {
  return await axios
    .post(`${API_KEY}${ST_TaskStart}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_StartClose(body) {
  return await axios
    .post(`${API_KEY}${ST_TaskClose}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_ListUpdate(body) {
  return await axios
    .post(`${API_KEY}${ST_ListUpd}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_ListDelete(body) {
  return await axios
    .post(`${API_KEY}${ST_ListDel}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_AutoUpdCheck(body) {
  return await axios
    .post(`${API_KEY}${ST_GetAutoUp}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_CommonSubmitBtn(body) {
  return await axios
    .post(`${API_KEY}${ST_CommonSubmit}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ST_GetVolData(body) {
  return await axios
    .post(`${API_KEY}${ST_GetVolTasks}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DM_GetInstances(body) {
  return await axios
    .post(`${API_KEY}${DM_getInstance}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DM_SearchButton(body) {
  return await axios
    .post(`${API_KEY}${DM_Searchfilter}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DM_Modify(body) {
  return await axios
    .post(`${API_KEY}${DM_UpdateDetails}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// ActiveProjects -- Event

export async function Events_Get(body) {
  return await axios
    .post(`${API_KEY}${Events_GetProjectDetails}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Events_Save(body) {
  return await axios
    .post(`${API_KEY}${Events_SaveNotes}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//Risk Management -- Risk Monitor Report

export async function RMR_GET(body) {
  return await axios
    .post(`${API_KEY}${RiskBindData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function RMR_SEARCH(body) {
  return await axios
    .post(`${API_KEY}${RiskSubmit}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function getProjectInfodetails(body) {
  return await axios
    .post(`${API_KEY}${getProjectInfo}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function getProjectRecommendedStartAndFinishDate(body) {
  return await axios
    .post(`${API_KEY}${recommendedStartAndFinishDate}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//charts
export async function LoadNetworkChart(projectId) {
  return await axios
    .post(`${API_KEY}${networkChart}`, projectId)
    .then((res) => {
      console.log("res");
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function LoadFeverChartProjectDetails(projectId) {
  return await axios
    .post(`${API_KEY}${FeverChartProjectDetails}`, projectId)
    .then((res) => {
      console.log("res");
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function LoadFeverChart(projectId) {
  return await axios
    .post(`${API_KEY}${FeverChartData}`, projectId)
    .then((res) => {
      console.log("res");
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function LoadHistogramChart(body) {
  return await axios
    .post(`${API_KEY}${histogramChartData}`, body)
    .then((res) => {
      console.log("res");
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function LoadPortfolioChartconsumed(body) {
  return await axios
    .post(`${API_KEY}${LoadPortfolioChart}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function getExcelFormatFile(body) {
  return await axios
    .post(`${API_KEY}${getExcelFormat}`, body, {
      responseType: "blob",
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AddRuleData(body) {
  return await axios
    .post(`${API_KEY}${AddRule}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AddRule3Data(body) {
  return await axios
    .post(`${API_KEY}${AddRule3}`, body)
    .then((res) => {
      console.log("addrule3res", res);
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//Active Projects -- Modify Task Attribute

export async function GetFirstTaskManager(body) {
  return await axios
    .post(`${API_KEY}${GetInitialTaskManager}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetLastTaskManager(body) {
  return await axios
    .post(`${API_KEY}${GetBottomTaskManager}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//SubTaskAll -- ListType

export async function InsertListType(body) {
  return await axios
    .post(`${API_KEY}${GetInsertButton}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//My Projects -- Change Status

export async function Change_Status(body) {
  return await axios
    .post(`${API_KEY}${ChangeStatusofProject}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Save_Detail(body) {
  return await axios
    .post(`${API_KEY}${GetSaveAll}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function getsimulatedDetails(body) {
  return await axios
    .post(`${API_KEY}${simulateData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function getShowWeeks(body) {
  return await axios
    .post(`${API_KEY}${ShowWeeks}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function getDownloadResultFile(body) {
  return await axios
    .post(`${API_KEY}${getDownloadResultbtn}`, body)
    .then((res) => {
      console.log("gbnm,", res);
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// CompleteProject

export async function Complete_Click(body) {
  return await axios
    .post(`${API_KEY}${CP_btnComplete_Click}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function CP_Save_Click2(body) {
  return await axios
    .post(`${API_KEY}${CP_btnSave_Click}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetDepartmentData(body){
  return await axios
  .post(`${API_KEY}${GetDepartmentDropdown}`, body)
  .then((res) => {
   return res;
  })
  .catch((err) => {
    return err
  }
  );
}

export async function GetTaskManagerData(body){
  return await axios
  .post(`${API_KEY}${GetTaskmanagerDropdown}`, body)
  .then((res) => {
   return res;
  })
  .catch((err) => {
    return err
  }
  );
}

export async function CP_Save_Click(data) {
  const formData = new FormData();
  formData.append("CustomerFeedback", data.CustomerFeedback || "");
  formData.append("UserId", data.UserId || "");
  formData.append("ProjCompletionDate", data.ProjCompletionDate || "");
  formData.append("projStatusId", data.projStatusId || "");
  formData.append("ApprovedExecutionCost", data.ApprovedExecutionCost || "");
  formData.append("projectDocFile1", data.projectDocFile1 || "");
  formData.append("projectDocFile2", data.projectDocFile2 || "");
  formData.append("ActualExecutionCost", data.ActualExecutionCost || "");
  formData.append("projId", data.projId || "");
  formData.append("ProjectValue", data.ProjectValue || "");
  formData.append("projectDocFile3", data.projectDocFile3 || "");
  formData.append("ProjCompletionRemark", data.ProjCompletionRemark || "");
  formData.append("projectDocFile", data.projectDocFile || "");

  try {
    const response = await axios.post(
      `${API_KEY}${CP_btnSave_Click}`, // Ensure `API_KEY` and `CP_btnSave_Click` are correctly defined
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error in CP_Save_Click API call:", error);
    return error.response;
  }
}
//Project Count With Status

export async function PCS_SearchButton(body) {
  return await axios
    .post(`${API_KEY}${PCS_SearchBtn}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}


//TaskManagementPopups

export async function GetFromViewHistory(body) {
  return await axios
    .post(`${API_KEY}${getViewHistory}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
 
export async function GetRaiseIssueList(body) {
  return await axios
    .post(`${API_KEY}${getRaiseListHistory}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
 
export async function SaveTaskraise(body) {
  return await axios
    .post(`${API_KEY}${Save_taks_raise}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
 
export async function GetTaskNotes(body) {
  return await axios
    .post(`${API_KEY}${getTaskNotes}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
 
export async function ResolveIssue(body) {
  return await axios
    .post(`${API_KEY}${closeRaiseIssue}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
 
 
export async function SaveTasks(body) {
  return await axios
    .post(`${API_KEY}${saveTaskNotes}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
 
export async function GetAssignList(body) {
  return await axios
    .post(`${API_KEY}${getOwnerCategory}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
 
export async function GetDurationTable(body) {
  return await axios
    .post(`${API_KEY}${getDurationHistory}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
 
export async function ViewtaskDurationHistory(body) {
  return await axios
    .post(`${API_KEY}${viewTaskDuration}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
 
export async function TaskGraph(body) {
  return await axios
    .post(`${API_KEY}${viewTaskDurationGraph}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
 
export async function RaiseIssueCategory() {
  return await axios
    .post(`${API_KEY}${raiseissuecategory}`,{})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewPredecessorPopup(body) {
  return await axios
    .post(`${API_KEY}${viewPredecessorpopup}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewSuccessorTable(body) {
  return await axios
    .post(`${API_KEY}${viewSuccessorrTable}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function ViewSuccessorPopup(body) {
  return await axios
    .post(`${API_KEY}${viewSuccessorrpopup}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AcceptSimulateData(body) {
  return await axios
    .post(`${API_KEY}${AcceptSimulate}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SearchDepartmentalData(body){
  return await axios
  .post(`${API_KEY}${SearchDepartment}`, body)
  .then((res) => {
   return res;
  })
  .catch((err) => {
    return err
  }
  );
}

export async function imgSave_ClickTMATM(body){
  return await axios
  .post(`${API_KEY}${imgSave_ClickTM}`, body)
  .then((res) => {
   return res;
  })
  .catch((err) => {
    return err
  }
  );
}

export async function GetNewViewTaskTable(body) {
  return await axios
    .post(`${API_KEY}${TM_NewGetTable}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetDelayresonDropdown(body){
  return await axios
  .post(`${API_KEY}${GetDelayReson}`, body)
  .then((res) => {
   return res;
  })
  .catch((err) => {
    return err
  }
  );
}

export async function GetTasksDropdown(){
  return await axios
  .post(`${API_KEY}${GetTaskDetails}`, {})
  .then((res) => {
   return res;
  })
  .catch((err) => {
    return err
  }
  );
}