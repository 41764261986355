
import React, { useEffect, useState } from "react";
import "../../../HelpPopups/HelpPopup.css";
import Stack from "@mui/material/Stack";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import modules from "../../ccpm.module.css";
import loading from "../../../../Assets/Loading_image.gif";
import { Grid } from "@mui/material";
import bread from "../../../../Assets/bread.png";
import {
    GetRaiseIssueList,
  PendingTaskPopup2,
  PendingTaskPopup2graph,
  RaiseIssueCategory,
  ResolveIssue,
} from "../../../../Services/CCPM";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";
// import { BoxPlot } from 'react-chartjs-2';
import { Line } from "react-chartjs-2";
import "chart.js/auto"; // This is required for Chart.js v3

const RaiseIssue = () => {
  const [activeRow, setActiveRow] = useState(null);
  const [data, setData] = useState([]);
  const [displayBox, setDisplayBox] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = parseInt(searchParams.get("projId"));
  const chainid = searchParams.get("chainId");
  const fid = parseInt(searchParams.get("fid"));
  const criticalchaincompletion = searchParams.get("criticalchaincompletion");
  const projectbufferconsumed = searchParams.get("projectbufferconsumed");
  const color = searchParams.get("color");
  const [isLoading, setIsLoading] = useState(false);
  const [label, setLabel] = useState("");
  const [remainingduration, setRemainingDuration] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [raiseIssueList, setRaiseIssueList] = useState([])
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
const query = useQuery();
const project_Id = query.get('projId'); 
const taskId = query.get('taskId')
const viewOnly = query.get('view')
console.log(viewOnly,"dwwdwd")
console.log(project_Id,"wce")
console.log(taskId,"ddcce")
  useEffect(() => {
    setIsLoading(true);
    let body = {
      projectId: projectId,
      fid: fid,
      chainType: chainid,
    };

    let body2 = {
        projectId: project_Id,
        taskId: taskId
      
      };
    PendingTaskPopup2(body)
      .then((response) => {
        if (response?.status === 200) {
          console.log(response);
          setData(response?.data?.success?.data);
          setIsLoading(false);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          // alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });

      GetRaiseIssueList(body2)
      .then((response) => {
        if (response?.status === 200) {
          console.log(response);
          setRaiseIssueList(response?.data?.success?.data);
          setIsLoading(false);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          // alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });

    RaiseIssueCategory().then((response) => {
      console.log(response?.data?.success, "dwdc");
      if (response?.status === 200) {
        setIsLoading(false);
        setCategoryData(response?.data?.success?.data);
        // setData(response?.data?.success?.data)
        // setRiskDetails(response?.data?.success?.data?.RiskDetails)
      }
      // else if (response?.response?.data?.message !== undefined) {
      //     setIsLoading(false);
      //     alert(response?.response?.data?.message);
      // } else if (response?.response?.data?.error?.message !== undefined) {
      //     setIsLoading(false);
      //     alert(response?.response?.data?.error?.message);
      // } else {
      //     setIsLoading(false);
      //     alert(response?.response?.data?.success?.message);
      // }
    });
  }, []);

  const handlePlusClick = (index, task) => {
    setIsLoading(true);
    setActiveRow(activeRow === index ? null : index);
    let body = {
      projectsId: projectId,
      taskId: task,
    };
    PendingTaskPopup2graph(body)
      .then((response) => {
        if (response?.status === 200) {
          console.log(response);
          setIsLoading(false);
          setLabel(response?.data?.success?.data[0].date);
          setRemainingDuration(
            response?.data?.success?.data[0].remainingduration
          );
          setIsLoading(false);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const handleSubmit = () => {
    console.log("hello");
  };



  const handleClose = (item) => {

    const body = {
      projectId: project_Id,
      taskId: taskId,
      issueId: item.id
    }
   console.log(item,"dwwdwd")
   setIsLoading(true)
   ResolveIssue(body)
      .then((response) => {
        alert("Issue Resolved")
        if (response?.status === 200) {
          console.log(response);
          setIsLoading(false);
        
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  const datan = {
    labels: [label],
    datasets: [
      {
        label: "",
        data: [remainingduration],
        fill: false,
        backgroundColor: "#000000",
        borderColor: "#000000",
        borderWidth: 1, // Thinner line
        pointBackgroundColor: "blue", // Color of the points
        pointBorderColor: "blue", // Border color of the points
      },
    ],
  };
  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Date", // X-axis label
          font: {
            size: 12, // Set the font size
          },
          color: "#000000",
        },
        ticks: {
          color: "#000000", // Set the label color to black
        },
        beginAtZero: true,
        grid: {
          color: "black", // Set the grid line color to black
        },
        offset: true,
      },
      y: {
        title: {
          display: true,
          text: "Remaining Duration", // Y-axis label
          font: {
            size: 12, // Set the font size
          },
          color: "#000000",
        },
        ticks: {
          color: "#000000", // Set the label color to black
        },
        beginAtZero: true,
        grid: {
          color: "black", // Set the grid line color to black
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      chartAreaBackground: {
        color: "#ffffff", // Set chart background to white
      },
    },
  };
  const chartAreaBackground = {
    id: "chartAreaBackground",
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;
      ctx.save();
      ctx.fillStyle = options.plugins.chartAreaBackground.color;
      ctx.fillRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top
      );
      ctx.restore();
    },
  };

  return (
    <>
    {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              paddingLeft: "1rem",
              fontWeight: "bold",
              fontFamily: "Times New Roman",
            }}
          >
            <img src={logo} alt="Godrej" />
          </Stack>
          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>

       
        <Stack style={{ padding: "15px" }}>
            { viewOnly == "true" && <a style={{marginLeft:'auto',color:'#0000EE',fontSize:'16px',textDecoration:'underline',fontFamily:'Times New Roman',fontWeight:'bold'}} href="#"    onClick={() => {
                                      window.open(
                                        `/ccpm/addanother-issue?projectId=${project_Id}&taskid=${taskId}`,
                                        "sharer",
                                        "toolbar=0,status=0,width=1050,height=600"
                                      );
                                    }}>Assign Another Issue</a>}
                                    {/* ?projId=${item.projectsid}&taskId=${item.taskid} */}
            <Grid style={{ border:'2px solid black',padding:'5px',overflowX:"scroll"}} >
              <Table
                className={[
                 
                ].join(" ")}
               
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: "16px",fontWeight:'bold', border: 'none',fontFamily:'Times New Roman',color:'#000000' }}>Sr.No.</TableCell>
                    <TableCell style={{fontSize: "16px",fontWeight:'bold', border: 'none' ,fontFamily:'Times New Roman',color:'#000000'}}>Issue Category</TableCell>
                    <TableCell style={{ fontSize: "16px",fontWeight:'bold', border: 'none',fontFamily:'Times New Roman',color:'#000000' }}>IssueDescription</TableCell>
                    <TableCell style={{ fontSize: "16px",fontWeight:'bold', border: 'none',fontFamily:'Times New Roman',color:'#000000' }}>Assigned To</TableCell>
                    <TableCell style={{ fontSize: "16px",fontWeight:'bold', border: 'none',fontFamily:'Times New Roman',color:'#000000' }}>IssueResolution</TableCell>
                    <TableCell style={{ fontSize: "16px",fontWeight:'bold', border: 'none',fontFamily:'Times New Roman',color:'#000000' }}>ResolveAction</TableCell>
                    <TableCell style={{ fontSize: "16px",fontWeight:'bold', border: 'none',fontFamily:'Times New Roman',color:'#000000' }}>IssueStatus</TableCell>
                    {          viewOnly == "true" &&  <TableCell style={{ fontSize: "x-large",fontWeight:'bold', border: 'none' }}></TableCell>}
                    {          viewOnly == "true" &&   <TableCell style={{ fontSize: "x-large",fontWeight:'bold', border: 'none' }}></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                 {raiseIssueList?.map((item,index) => {
                    return (
                    <TableRow className={modules["rowTaskManagement"]}>
                    <TableCell style={{ fontSize: "16px",padding:'none !important', border: 'none',fontFamily:'Times New Roman',color:'#000000' }}>{index + 1}</TableCell>
                    <TableCell style={{ fontSize: "16px",padding:'none !important',width: '60px',height:'60px',textAlign: 'center',background: 'black',color: 'white', border: 'none',backgroundClip: 'content-box',fontFamily:'Times New Roman' }}><span style={{fontFamily:'Times New Roman'}}>{item?.issuecategory}</span></TableCell>
                    <TableCell style={{ fontSize: "16px",padding:'none !important', border: 'none' ,fontFamily:'Times New Roman',color:'#000000'}}><textarea rows={4} disabled={true} style={{width:'100%',border:'2px solid',fontSize:'large',fontWeight:'bold',fontFamily:'Times New Roman'}}>{item?.issuedescription}</textarea></TableCell>
                    <TableCell style={{ fontSize: "16px",padding:'none !important', border: 'none',fontFamily:'Times New Roman',color:'#000000' }}>{item?.issueowner}</TableCell>
                    <TableCell style={{ fontSize: "16px",padding:'none !important', border: 'none',fontFamily:'Times New Roman',color:'#000000' }}><textarea rows={4} disabled={true} style={{width:'100%',border:'2px solid',fontSize:'large',fontWeight:'bold'}}  value={item?.issueresolution} ></textarea></TableCell>
                    <TableCell style={{ fontSize: "16px",padding:'none !important', border: 'none',fontFamily:'Times New Roman',color:'#000000' }}>{item?.resolveaction}</TableCell>
                    <TableCell style={{ fontSize: "16px",padding:'none !important', border: 'none',fontFamily:'Times New Roman',color:'#000000' }}>{item?.issuestatustext}</TableCell>
                    {          viewOnly == "true" &&         <TableCell style={{ fontSize: "large",padding:'none !important',fontWeight:'bold', border: 'none' }}><button style={{padding:'15px',marginTop:'0 !important' }} className={modules["btn1_vpm"]}  onClick={() => {
                                      window.open(
                                        `/ccpm/addanother-issue?projectId=${project_Id}&taskid=${taskId}&id=${item?.id}`,
                                        "sharer",
                                        "toolbar=0,status=0,width=1050,height=600"
                                      );
                                    }}>Reassign/Edit</button></TableCell>
                }
                    {  viewOnly == "true"&&   <TableCell style={{ fontSize: "large",padding:'none !important',fontWeight:'bold', border: 'none', }}><button style={{padding:'15px',width:'8rem',marginTop:'0 !important' }} className={modules["btn1_vpm"]}  onClick={() => handleClose(item)} >Close Issue </button></TableCell>}
                  </TableRow>
                    )
                 })}
                
                </TableBody>
              </Table>
            </Grid>
          </Stack>
     
      </div>
    </>
  );
};

export default RaiseIssue;


