import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { TextField, Typography } from "@mui/material";
import modules from "./Admin.module.css";
import { APR_GetRoleName } from "../../Services/Admin";
import { useLocation } from "react-router-dom";
import loading from "../../Assets/Loading_image.gif";

const AssignParentPopUp = () => {
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const roleId = parseInt(searchParams.get("roleId"), 10);

  const ChannelOne = new BroadcastChannel("roleChannel");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    APR_GetRoleName()
      .then((response) => {
        if (response?.status === 200) {
          setData(response?.data);
          setSearchData(response?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  function selectHandler() {
    if (selectedRows.length > 0) {
      const selectedData = selectedRows.reduce(
        (accumulator, roleId) => {
          const role = data.find((item) => item.id === roleId);
          accumulator.ids.push(role.id);
          accumulator.names.push(role.rolename);
          return accumulator;
        },
        { ids: [], names: [] }
      );

      const roleIds = selectedData.ids.join(",");
      const roleNames = selectedData.names.join(",");

      ChannelOne.postMessage({
        mainrole: roleId,
        selectedData: {
          roleIds,
          roleNames,
          empty: "false",
        },
      });
      ChannelOne.close();
      window.close();
    } else {
      ChannelOne.postMessage({
        mainrole: roleId,
        selectedData: {
          roleIds: "",
          roleNames: "",
          empty: "true",
        },
      });
      ChannelOne.close();
      window.close();
    }
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);

  const pageSize = 10;
  const totalPages = Math.ceil(searchData.length / pageSize);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [id, setId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [msg, setMsg] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleIdChange = (e) => {
    setId(e?.target?.value);
  };

  const handleRoleChange = (e) => {
    setRoleName(e?.target?.value);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const endPage = Math.min(startPage + 3, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span key={i}>
          <a
            className={`${modules["page-link"]} ${
              currentPage === i ? modules["active-select"] : ""
            }`}
            onClick={() => handleClick(i)}
          >
            {i}
          </a>
        </span>
      );
    }
    return pageNumbers;
  };

  function searchHandler(idcode, role) {
    setMsg("");
    let result = data?.filter((ele) => {
      const boolOne = idcode
        ? ele.id
            .toString()
            .toLowerCase()
            .includes(idcode.toString().toLowerCase())
        : true;
      const boolTwo = role
        ? ele.rolename
            .toString()
            .toLowerCase()
            .includes(role.toString().toLowerCase())
        : true;
      return boolOne && boolTwo;
    });
    setSearchData(result);
    if (result.length === 0) setMsg("No Records Found");
  }

  function resetHandler() {
    setSearchData(data);
    setId("");
    setRoleName("");
    setMsg("");
  }

  function handleCheckboxChange(event, roleId) {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, roleId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== roleId));
    }
  }

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allRowIds = searchData.map((item) => item.id);
      setSelectedRows(allRowIds);
    }
    setSelectAll(!selectAll);
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div className={modules["popup_container"]}>
        <Typography
          align="center"
          py={1}
          className={modules["headingFontBold"]}
        >
          Assign Parent Roles
        </Typography>
        {msg ? (
          <Typography align="left" className={modules["error-msgBold"]}>
            {msg}
          </Typography>
        ) : (
          <>
            <table className={modules["popup_table"]}>
              <thead className={modules["popup_head"]}>
                <tr>
                  <th style={{ align: "center", width: "12%" }}>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={toggleSelectAll}
                      className={modules["custom-checkbox"]}
                    />
                    <br />
                    <label>Select All</label>
                  </th>
                  <th>Id</th>
                  <th>RoleName</th>
                </tr>
              </thead>
              <tbody className={modules["popup_body"]}>
                {searchData
                  ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
                  .map((ele, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <tr key={ele.id}>
                        <td>
                          <input
                            aria-labelledby={labelId}
                            type="checkbox"
                            checked={selectedRows.includes(ele.id)}
                            onChange={(e) => handleCheckboxChange(e, ele.id)}
                            className={modules["custom-checkbox"]}
                          />
                        </td>
                        <td>{ele.id}</td>
                        <td>{ele.rolename}</td>
                      </tr>
                    );
                  })}
                <tr style={{ width: "100%" }}>
                <td colspan="1" className={modules["whiteBackgroundPR"]}>
                    <div>{renderPageNumbers()}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Stack
              direction="row"
              spacing={2}
              sx={{ marginTop: "18px", marginBottom: "20px" }}
            >
              <button
                type="button"
                style={{ width: "70px", height: "25px", borderRadius: "4px" }}
                className={modules["btn_popupBtn"]}
                onClick={selectHandler}
              >
                Submit
              </button>
            </Stack>
          </>
        )}

        <Typography className={modules["headingFontBold"]}>Search:</Typography>
        <table
          cellspacing="0"
          rules="all"
          border="1"
          className={modules["popup_table_search"]}
        >
          <tbody className={modules["popup_table_searchB"]}>
            <tr>
              <td>Id</td>
              <td>
                <input value={id} onChange={handleIdChange} />
              </td>
            </tr>
            <tr>
              <td>RoleName</td>
              <td>
                <input value={roleName} onChange={handleRoleChange} />
              </td>
            </tr>
          </tbody>
        </table>
        <Stack
          direction="row"
          spacing={2}
          sx={{ marginTop: "18px", marginBottom: "20px" }}
        >
          <button
            type="button"
            style={{ width: "70px", height: "25px", borderRadius: "4px" }}
            className={modules["btn_popupBtn"]}
            onClick={() => searchHandler(id, roleName)}
          >
            Search
          </button>
          <button
            type="button"
            style={{ width: "70px", height: "25px", borderRadius: "4px" }}
            className={modules["btn_popupBtn"]}
            onClick={resetHandler}
          >
            Reset
          </button>
        </Stack>
      </div>
    </>
  );
};

export default AssignParentPopUp;
