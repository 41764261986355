import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/PC1.jpg";
import IC2 from "../HelpPopups/images/PC2.jpg";
import IC3 from "../HelpPopups/images/PC3.jpg";
import IC4 from "../HelpPopups/images/PC4.jpg";
import IC5 from "../HelpPopups/images/PC5.jpg";
import { Link } from "react-router-dom";

const ProcrumentCatHelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>Procurement Category</h3>
              <br />
              <li>
                Procurement category master is a template which is used while
                adding procurement type subtask to a main task
              </li>
              <li>
                Categories here are created only for the division wise, as this
                screen is independent of any defined levels like hierarchy
                masters, Customer master, etc.{" "}
              </li>
              <li>PATH : CCPM \ Masters \ Procurement Category</li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC1} alt="IC1" />
              </Stack>
              <li>
                For adding the category, click on “Add” button and enter the
                data in the input fields and click on the ”Save” link to save
                the record or we can use the Cancel link to cancel the
                transaction.
              </li>
              <br />

              <li>
                For deleting any individual record, we can use the “Delete” link
                of the particular record, or we can delete multiple records at
                once by selecting the records using the check boxes and click on
                the “Delete” button, selected records will get deleted.
              </li>
              <br />
              <li>
                For doing any updations to the data, click on Edit link and do
                the required changes and click on Save link to save the changes
                or click on Cancel link to cancel the changes done to the data
              </li>
              <br />
              <li>
                For creating the sub-category for the existing / newly created
                category, we should click on the link “Sub Category” and in the
                new window, click on the “Add” button to add the sub-category
                for the selected category and enter the data in the input field
                and click on the ”Save” link to save the record or we can use
                the Cancel link to cancel the transaction.
              </li>
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC2} alt="IC2" />
              </Stack>
              <li>
                {" "}
                For doing any updations to the data in the sub-category, click
                on Edit link and do the required changes and click on Save link
                to save the changes or click on Cancel link to cancel the
                changes done to the data.{" "}
              </li>

              <li>
                {" "}
                For deleting any individual record, we can use the “Delete” link
                of the particular record, or we can delete multiple records at
                once by selecting the records using the check boxes and click on
                the “Delete” button, selected records will get deleted.{" "}
              </li>

              <li>
                {" "}
                For searching the data using the search filter, the user should
                select the division in the “Division” and enter the name of the
                category in the “Category” field and click on the “Search”
                button. The needed data will appear in the grid and “Reset”
                button can be used to clear the search filter.{" "}
              </li>
              <li
                style={{ listStyleType: "square", textDecoration: "underline" }}
              >
                Validations on the screen:-
              </li>
              <br />
              <li>
                Duplication of existing record should not be allowed i.e
                re-adding of the existing record again
              </li>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC3} alt="IC3" />
              </Stack>

              <li>
                User should not leave any input field blank before saving the
                data, as click of “Save” link keeping the fields empty makes the
                error popup window to appear
              </li>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC4} alt="IC4" />
              </Stack>

              <li>
                User should make selection of the data before the click of
                “Delete” button, as without selection of data the popup window
                appears
              </li>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC5} alt="IC5" />
              </Stack>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProcrumentCatHelpPopup;
