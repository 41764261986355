import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
import Box from "@mui/material/Box";
import { Tab, Tabs } from "@mui/material";
import modules from "./../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetIRExport,
  GetIRIssueOwner,
  GetIRSearchResult,
  GetDepartmentData,
  GetTaskManagerData,
  SearchDepartmentalData,
} from "../../../Services/CCPM.js";

import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { GetDepartmentDropdown } from "../../../Constants/index.js";

// Register the required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// function ArrowLine() {
//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       <div
//         style={{
//           width: "1px",
//           height: "18px",
//           backgroundColor: "grey",
//           marginRight: "2px",
//         }}
//       />
//       <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
//     </div>
//   );
// }

const DepartmentLoadReport = () => {
  const [selectedInstance, setSelectedInstance] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("1");
  const [selectedAssignedTo, setselectedAssignedTo] = useState("0");
  const [assignedOwner, setAssignedOwner] = useState([]);
  const [selectedissueStatus, setSelectedIssueStatus] = useState("0");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  const handleSelectChange = (attributenumber, id) => {
    // console.log(attributenumber, id, "attributenumber value");
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [instances, setInstances] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);

  const accessTypes = [
    { value: "null", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ];

  const projectStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Active" },
    { value: "5", label: "Hold" },
    { value: "7", label: "Completed" },
    { value: "10", label: "CDDCheckedOut" },
    { value: "11", label: "ModifiedCheckedOut" },
  ];

  const issueStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Pending" },
    { value: "5", label: "Closed" },
  ];

  const [tabDisplay, setTabDisplay] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getAssinedOwner();
      getHierarchyOption(initialInstance);
      getTasksname(initialInstance);
      getValueFilterOption(initialInstance);
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetDepartmentData(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTasksname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetTaskManagerData(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      // projStatusId: 1,
    };
    GetTaskManagerData(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssinedOwner = () => {
    const body = {
      userId: 0,
    };
    GetIRIssueOwner(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "Assigned Owner");
          setAssignedOwner(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const getSearchResult = (
  //   attr1,
  //   attr2,
  //   attr3,
  //   attr4,
  //   attr5,
  //   attr6,
  //   attr7,
  //   attr8,
  //   attr9,
  //   attr10
  // ) => {
  //   const loginId = sessionStorage.getItem("loginId");
  //   const body = {
  //     instanceId: selectedInstance,
  //     userId: loginId,
  //     attribute1Id: attr1,
  //     attribute2Id: attr2,
  //     attribute3Id: attr3,
  //     attribute4Id: attr4,
  //     attribute5Id: attr5,
  //     attribute6Id: attr6,
  //     attribute7Id: attr7,
  //     attribute8Id: attr8,
  //     attribute9Id: attr9,
  //     attribute10Id: attr10,
  //     projId: selectedProjectName,
  //     projMgrId: selectedProjectManager,
  //     issueOwner: selectedAssignedTo,
  //     projectType: selectedProjectAccessType,
  //     accessType: "",
  //     issueStatusId: selectedissueStatus,
  //   };

  //   GetIRSearchResult(body)
  //     .then((response) => {
  //       if (response?.data?.statusCode === 200) {
  //         console.log(response?.data?.success?.data, "table data");
  //         setTableData(response?.data?.success?.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getExcel = async (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      issueOwner: selectedAssignedTo,
      projectType: selectedProjectAccessType,
      accessType: "",
      issueStatusId: selectedissueStatus,
    };

    // if (selectedRoleId !== -1) {
    try {
      setIsLoading(true);
      const response = await GetIRExport(body);

      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // const contentDisposition = response.headers["content-disposition"];
        const filename = "IssueReport.xlsx";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoading(false);
        console.error("No Data found");
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error downloading file:", err);
    }
  };
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  let endDate = new Date(startDate);
 
  const [selectedOption, setSelectedOption] = useState("1");
  const [dateRange, setDateRange] = useState("");
  const [departmentalData, setDepartmentalData] = useState([]);
  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    calculateDateRange(value);
  };

  useEffect(() => {
    // Call the function to update the date range when the selected option changes
    calculateDateRange(selectedOption);
  }, [selectedOption]);
  const calculateDateRange = (option) => {
    const currentDate = new Date();
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    // Create a copy of the startDate for the end date calculation
    let endDate = new Date(startDate);

    switch (option) {
      case "1":
        endDate.setMonth(startDate.getMonth() + 1);
        break;
      case "3":
        endDate.setMonth(startDate.getMonth() + 3);
        break;
      case "6":
        endDate.setMonth(startDate.getMonth() + 6);
        break;
      default:
        break;
    }

    // Format dates in the format DD-MM-YYYY
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()}`

    // Set the end date to the last day of the month
    const lastDayOfMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      0
    ); // 0 means the last day of the previous month

    const formattedEndDate = `${lastDayOfMonth.getDate().toString().padStart(2, '0')}-${(lastDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfMonth.getFullYear()}`;


    setDateRange(`${formattedStartDate} to ${formattedEndDate}`);
  };

  const DownloadHandler = () => {
    setShowResult(false);
    console.log(selectedAttributes, "selectedAttributes");
    console.log(selectedProjectStatus, "status");
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getExcel(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  // const options = {
  //   elements: {
  //     line: {
  //       borderWidth: 0,
  //     },
  //     point: {
  //       radius: 5,
  //     },
  //   },
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //     tooltip: {
  //       callbacks: {
  //         title: () => "",
  //         label: () => "",
  //       },
  //     },
  //   },
  //   scales: {
  //     x: {
  //       title: {
  //         display: true,
  //         text: "Months",
  //       },
  //     },
  //     y: {
  //       title: {
  //         display: true,
  //         text: "Capacity",
  //       },
  //     },
  //   },
  // };

  const dataLoad = [
    {
      datamonth: "Nov/2024",
      dataload: 12,
      datacapacity: 20,
    },
    {
      datamonth: "Dec/2024",
      dataload: 11,
      datacapacity: 20,
    },
    {
      datamonth: "Jan/2025",
      dataload: 13,
      datacapacity: 20,
    },
  ];

  const months = departmentalData.map((entry) => entry.datamonth);
  const dataload = departmentalData.map((entry) => entry.dataload);
  const datacapacity = departmentalData.map((entry) => entry.datacapacity);
  const maxDataValue = Math.max(...dataload, ...datacapacity);
  const yMax = maxDataValue > 59 ? maxDataValue + 10 : 60;
  const data = {
    labels: months,
    datasets: [
      {
        type: "bar",
        label: "Data Load",
        data: dataload,
        backgroundColor: "#0057D9",
        borderColor: "#0057D9",
        borderWidth: 1,
      },
      {
        type: "line",
        label: "Data Capacity",
        data: datacapacity,
        borderColor: "#D3D3D3",
        backgroundColor: "#D3D3D3",
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    elements: {
      line: {
        borderWidth: 0,
      },
      point: {
        radius: 5,
      },
    },
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
        text: "Combination of Line and Bar Chart",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
        },
      },
      y: {
        min: 0,
        max: yMax,
        title: {
          display: true,
          text: "Capacity",
        },
        ticks: {
          stepSize: 10,
        },
      },
    },
  };

  // const datachart = {
  //   labels:
  //   dataLoad?.length > 0
  //       ? dataLoad?.length === 1
  //         ? ["", ""] // Two empty labels to place the point in the middle
  //         : ["", ...dataLoad?.map((item) => item.datamonth)] // Multiple months, normal labels
  //       : [],

  //   datasets: [
  //     {
  //       label: "",
  //       data:
  //       dataLoad?.length > 0
  //           ? dataLoad?.length === 1
  //             ? [null, dataLoad[0]?.datacapacity] // Only one data point, so place it in the middle
  //             : [null, ...dataLoad?.map((item) => item.datacapacity)] // Multiple data points
  //           : [],

  //       fill: false,
  //       borderColor: "rgba(0, 0, 0, 0)", // Invisible line
  //       backgroundColor: "rgba(75, 192, 192, 1)", // Point color
  //       pointRadius: 5, // Size of the points
  //       pointBackgroundColor: "rgba(75, 192, 192, 1)",
  //       pointHoverRadius: 7, // Size of the points on hover
  //     },
  //   ],
  // };

  console.log(departmentalData, "depapapapa");

  const handleSearch = () => {
    const loginId = sessionStorage.getItem("loginId");
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits
      const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
      return `${year}-${month}-${day}`;
    };
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);

    if (!selectedInstance) {
      alert("Please Select Both Instance and Department");
      return;
    }

    if (!selectedProjectName) {
      alert("Please Select Both Instance and Department");
      return;
    }
    const payload = {
      instanceId: Number(selectedInstance),
      userId: Number(loginId),
      attribute1Id: 0,
      attribute2Id: 0,
      attribute3Id: 0,
      attribute4Id: 0,
      attribute5Id: 0,
      attribute6Id: 0,
      attribute7Id: 0,
      attribute8Id: 0,
      attribute9Id: 0,
      attribute10Id: 0,
      accessType: "",
      projId: 0,
      projMgrId: 0,
      taskMgrId: parseInt(selectedProjectAccessType),
      taskStatusId: 0,
      color: 0,
      taskAvailStatus: 0,
      taskNotMovedSince: 0,
      nextNoOfDays: 0,
      isCCTasks: 0,
      department: selectedProjectName,
      nextNoOfMonths: Number(selectedOption),
      periodAsOn: formattedDate,
    };

    SearchDepartmentalData(payload)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTabDisplay(true);
          setShowResult(false);

          console.log(response?.data?.success?.data, "table 21121");
          setDepartmentalData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div
        style={{
          overflowX: showResult && tableData.length > 0 ? "auto" : "hidden",
        }}
      >
        {isLoading && (
          <img
            src={loading}
            className={modules["loading_gif"]}
            alt="Loading..."
          />
        )}
        <Grid container>
          <Grid item lg={0.6} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>

          <Grid item lg={12} sx={{ display: "block" }}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"Portfoliochart"} />
            </Grid>

            <Grid item lg={12}>
              <div
                className={modules["instancemaster_div"]}
                style={{ marginBottom: "5px" }}
              >
                <div
                  className={modules["pro-bread"]}
                  style={{ marginLeft: "-2.5rem" }}
                >
                  <a>Reports</a>
                  <img src={bread} alt="" />
                  <a>Load On Department</a>
                </div>
              </div>
              <div className={modules["border_cont"]}>
                <table className={modules["PCtable"]}>
                  <tbody>
                    <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Instance:<label style={{ color: "Red" }}>*</label>
                        </span>

                        <select
                          value={selectedInstance}
                          onChange={(e) => {
                            setSelectedInstance(e.target.value);
                            getProjectDetails(e.target.value);
                            setSelectedProjectName("");
                          }}
                          className={modules["dropdownSelect"]}
                          // style={{ width: "200px" }}
                        >
                          {instances?.map((instance) => (
                            <option key={instance.name} value={instance.id}>
                              {instance.name}
                            </option>
                          ))}
                        </select>
                      </td>

                      {hierarchyOptions
                        ?.filter((option) => option.isactive === 1)
                        .map((option, index) => (
                          <React.Fragment key={option.attributename}>
                            <td className={modules["table_data1"]}>
                              <span
                                style={{ width: "115px", color: "#414141" }}
                              >
                                {option.attributename} :
                              </span>
                              <select
                                value={
                                  selectedAttributes[
                                    `attr${option.attributenumber}`
                                  ] || ""
                                }
                                onChange={(e) =>
                                  handleSelectChange(
                                    option.attributenumber,
                                    e.target.value
                                  )
                                }
                                className={modules["dropdownSelect"]}
                                // style={{ width: "200px" }}
                              >
                                <option value="">SELECT</option>
                                {filteredValueData[index]?.map((item) => (
                                  <option
                                    key={item.attributevalue}
                                    value={item.id}
                                  >
                                    {item.attributevalue}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </React.Fragment>
                        ))}
                    </tr>
                    <tr
                      style={{
                        paddingBottom: "10px",
                        paddingLeft: "0px",
                      }}
                    >
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Department <label style={{ color: "Red" }}>*</label>:
                        </span>
                        <select
                          value={selectedProjectName}
                          onChange={(e) =>
                            setSelectedProjectName(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={0}>SELECT</option>
                          {projects?.map((p_name) => (
                            <option
                              key={p_name.department}
                              value={p_name.department}
                            >
                              {p_name.department}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Period :
                        </span>
                        <div
                          className="subscription-container"
                          style={{ width: "500px" }}
                        >
                          <label className="subscription-option">
                            <input
                              type="radio"
                              value="1"
                              checked={selectedOption === "1"}
                              onChange={handleOptionChange}
                              style={{ marginRight: "3px" }}
                            />
                            1 Month
                          </label>
                          <label
                            className="subscription-option"
                            style={{ marginLeft: "5px" }}
                          >
                            <input
                              type="radio"
                              value="3"
                              checked={selectedOption === "3"}
                              onChange={handleOptionChange}
                              style={{ marginRight: "3px" }}
                            />
                            3 Months
                          </label>
                          <label
                            className="subscription-option"
                            style={{ marginLeft: "5px" }}
                          >
                            <input
                              type="radio"
                              value="6"
                              checked={selectedOption === "6"}
                              onChange={handleOptionChange}
                              style={{ marginRight: "3px" }}
                            />
                            6 Months
                          </label>
                          <div>
                            {dateRange && (
                              <p
                                style={{
                                  backgroundColor: "#D3D3D3",
                                  display: "inline-block",
                                }}
                              >
                                {dateRange}
                              </p>
                            )}
                          </div>{" "}
                        </div>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Task Manager:
                        </span>
                        <select
                          value={selectedProjectAccessType}
                          onChange={(e) => {
                            setSelectedProjectAccessType(e.target.value);
                          }}
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={0}>SELECT</option>
                          {managers?.map((m_name) => {
                            return (
                              <option key={m_name.username} value={m_name.id}>
                                {m_name.username}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                    </tr>
                    <tr
                      style={{
                        justifyContent: "center",
                        gap: "10px",
                        paddingLeft: "0px",
                        paddingBottom: "10px",
                      }}
                    >
                      <button
                        type="submit"
                        className={modules["PCbtn"]}
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                      <button
                        className={modules["PCbtn"]}
                        type="button"
                        onClick={() => {
                          window.location.reload();
                          setShowResult(false);
                        }}
                      >
                        Reset
                      </button>
                    </tr>
                  </tbody>
                </table>
              </div>

              {showResult && tableData.length > 0 && (
                <>
                  <Grid container sx={{ marginLeft: "6.5rem", padding: "8px" }}>
                    <Table className={modules["IM_custom-table"]}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr No</TableCell>
                          <TableCell>Project Name</TableCell>
                          <TableCell>Department</TableCell>
                          <TableCell>Task Id</TableCell>
                          <TableCell>Task Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Raised By</TableCell>
                          <TableCell>Raised On</TableCell>
                          <TableCell>Assigned To</TableCell>
                          <TableCell>Resolution</TableCell>
                          <TableCell>Resolved On</TableCell>
                          <TableCell>Resolve Action</TableCell>
                          <TableCell>Issue Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell className={modules["IRtable-row"]}>
                              {index + 1}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.projectname}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.department}
                            </TableCell>
                            <TableCell
                              sx={{ background: "black", color: "#ffffff" }}
                            >
                              {item.taskid}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.taskname}
                            </TableCell>
                            <TableCell>
                              <textarea
                                value={item.description}
                                style={{ height: "65px", width: "295px" }}
                              />
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.raisedby}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.raisedon}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.issueowner}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              <textarea
                                value={item.resolveaction}
                                style={{ height: "65px", width: "295px" }}
                              />
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.resolvedon}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.resolveaction}
                            </TableCell>
                            <TableCell className={modules["IRtable-row"]}>
                              {item.issuestatustext}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </>
              )}

              <div className={modules["mid-contDLR"]}>
                {tabDisplay && (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "2rem",
                        // marginLeft: "3rem",
                      }}
                    >
                      <Tabs
                        className={modules["tabs_css"]}
                        value={tabValue}
                        onChange={handleTabChange}
                        sx={{
                          "& .MuiTabs-indicator": { display: "none" },
                          "& .Mui-selected": { color: "inherit" },
                        }}
                      >
                        <Tab
                          // sx={{
                          //  color: "tabValue === 0 ? '#fff' : '#000'",
                          // }}

                          className={`${modules["tab_css"]} ${
                            tabValue === 0 ? modules["tab_css_selected"] : ""
                          }`}
                          label="Department-Load Graph"
                        />
                        <Tab
                          className={`${modules["tab_css"]} ${
                            tabValue === 1 ? modules["tab_css_selected"] : ""
                          }`}
                          label="Department-Load Data"
                        />
                      </Tabs>
                    </Box>
                    {tabValue === 0 && (
                      <Box className={modules["tab-box"]}>
                        <Grid
                          container
                          columnSpacing={{ xs: 2 }}
                          sx={{ alignItems: "center", padding: "10px" }}
                        >
                          <div
                            style={{
                              backgroundColor: "#D3D3D3",
                              paddingLeft: "60px",
                              paddingRight: "60px",
                              paddingTop: "15px",
                              paddingBottom: "15px",
                              borderRadius: "8px",
                              width: "70vw",
                              height: "70vh",
                              color: "#D3D3D3",
                              border: "2px solid ",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "white",
                                // padding: "20px",
                                borderRadius: "8px",
                                width: "70vw",
                                height: "70vh",
                                color: "#",
                                border: "2px solid",
                              }}
                            >
                              <Bar data={data} options={options} />{" "}
                            </div>
                          </div>
                        </Grid>
                      </Box>
                    )}

                    {tabValue === 1 && (
                      <Box style={{ border: "1px solid #ccc" }}>
                        <div style={{ padding: "10px" }}>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              margin: "20px 0",
                            }}
                          >
                            <thead>
                              <tr>
                                <th className={modules["custom-text"]}>
                                  Month
                                </th>
                                <th className={modules["custom-text"]}>Load</th>
                                <th className={modules["custom-text"]}>
                                  Capacity
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {departmentalData?.length > 0 &&
                                departmentalData.map((item, index) => (
                                  <tr key={index}>
                                    <td className={modules["custom-cell"]}>
                                      {item.datamonth}
                                    </td>
                                    <td className={modules["custom-cell"]}>
                                      {item.dataload.toFixed(4)}
                                    </td>
                                    <td className={modules["custom-cell"]}>
                                      {item.datacapacity}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </Box>
                    )}
                  </>
                )}
              </div>

              <div style={{ marginTop: "1rem" }}></div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DepartmentLoadReport;
