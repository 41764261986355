import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import LTM1 from "../HelpPopups/images/LTM1.jpg";
import LTM2 from "../HelpPopups/images/LTM2.jpg";
import LTM3 from "../HelpPopups/images/LTM3.jpg";
import LTM4 from "../HelpPopups/images/LTM4.jpg";

import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const LoadOnTaskManagerhelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{fontSize: "14px"}} >
              <li className="no-bullets" style={{fontSize: "25px"}}>Load On Task Manager</li>
              <br />
              <li className="no-bullets">
                This report is generated for the checking of the load on the
                task manager. In this report one can see the load on the task
                manager. User can check from 1 week to 12 weeks. Report is
                available in summary and detail. In detail report it shows task
                wise load on task manager whereas in summary report it will show
                only no of task against the task manager.
              </li>
              <br />

              <li className="no-bullets">
                Session Path – CCPM {`>>`} Reports {`>>`} Load on Task Manager.
              </li>
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={LTM1} alt="LTM1" />
              </Stack>
              <br />
              <li className="no-bullets">
                Select instance from the dropdown provided for the instance
                filter.
              </li>
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={LTM2} alt="LTM2" />
              </Stack>
              <br />

              <li className="no-bullets">
                Attribute wise filters are provided for this report. Along with
                the attribute filters filter for project name and task manager
                is provided. Also there are provision of number of week. Where
                user can define the no of week he want to check the load.
              </li>
              <li className="no-bullets">Report is available in detail and summary type.</li>

              <br />

              <li
                className="no-bullets"
              >
                Detail Report –
              </li>
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={LTM3} alt="LTM3" />
              </Stack>
              <br />

              <ul>
                <li>Id -This shows the serial no of the tasks.</li>

                <li>
                  Project Name – This column is indicating the project name.
                  when a task is show against the task manager. The respective
                  project name is updated in this column.
                </li>

                <li>
                  Task Name – This column is indicating the task name which is
                  showing against the task manager.
                </li>
                <li>
                  Task Name – This column is indicating the task name which is
                  showing against the task manager.
                </li>
                <li>
                  Remining Duration – This shows the remaining duration of the
                  task.
                </li>
                <li>
                  After this as per selection the date columns are generated
                  starting from today. Like if we select 1 week then 7 columns
                  will be generated. No of tasks will be shown in top row.
                </li>
                <li>
                  The load for the task manager is shown in red as per remaining
                  duration. A blank row indicates there is no load on the
                  selected task manager.
                </li>
                <br />

                <br />

                <li className="no-bullets">Summary report –</li>
                <br />


                <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                  <img src={LTM4} alt="LTM4" />
                </Stack>
                <br />


                <li>Id – this is serial no.</li>
                <li>Username – This indicates the name of the task manager.</li>
                <li>
                  After this as per selection the date columns are generated
                  starting from today. Like if we select 1 week then 7 columns
                  will be generated.
                </li>
                <li>No of tasks will be shown against the dates.</li>
              </ul>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LoadOnTaskManagerhelpPopup;
