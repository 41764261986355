import React, { useEffect, useState } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Grid, Typography } from "@mui/material";
import Sidebar from "../../../Landing/Navbar";
import {
  RT_GetTb,
  RT_Insert,
  RT_Upd,
  RT_Del,
  RT_DelBulk,
} from "../../../Services/CCPM";
import modules from "../ccpm.module.css";
// import modules from "./RiskTypeMaster.module.css";

import SampleSidebar from "../../../Landing/SampleSidebar";

const RiskTypeMaster = () => {
  const [searchData, setSearchData] = useState([]);
  const [newRiskName, setNewRiskName] = useState("");
  const [isAddRowVisible, setIsAddRowVisible] = useState(false);
  const [editing, setEditing] = useState(null);
  const [editedTypeName, setEditedTypeName] = useState("");
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  useEffect(() => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
    getDetails();
  }, []);

  function getDetails() {
    RT_GetTb()
      .then((response) => {
        if (response.status === 200) {
          setSearchData(response?.data?.success?.data);
        } else {
          // alert(response?.error?.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const allChecked =
      searchData.length > 0 &&
      searchData.every((item) => checkedItems[item.id]);
    setIsAllChecked(allChecked);
  }, [checkedItems, searchData]);

  const handleMainCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsAllChecked(isChecked);
    const newCheckedItems = {};
    searchData.forEach((item) => {
      newCheckedItems[item.id] = isChecked;
    });
    setCheckedItems(newCheckedItems);
  };

  const handleIndividualCheckboxChange = (event, id) => {
    setCheckedItems((prev) => ({
      ...prev,
      [id]: event.target.checked,
    }));
  };

  //update current risk Type
  function handleUpdSave(item, element) {
    element.style.color = "red";
    setTimeout(() => {
    if (item.risktypename === editedTypeName) {
      alert("No Update :Risk Type Name Already present.");
      element.style.color = "#0000EE";
    } else {
      const createdBy = parseInt(sessionStorage.getItem("loginId"));
      const body = {
        id: item.id,
        riskType: editedTypeName,
        isActive: 0,
        lastModifiedBy: createdBy,
      };
      RT_Upd(body)
        .then((response) => {
          if (response.status === 200) {
            const successData = response.data.success?.data;
            if (
              Array.isArray(successData) &&
              successData.some((item) => item["?column?"] === 2)
            ) {
              alert("No Update :Risk Type Name Already present.");
              return;
            }
            setIsAddRowVisible(false);
            setEditedTypeName("");
            getDetails();
            setEditing(null);
          } else {
            // alert("Unexpected response status.");
            element.style.color = "#0000EE";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    element.style.color = "#0000EE";
  }, 100);
  }

  //add new risk Type
  function addRiskType(element) {
    element.style.color = "red";
    setTimeout(() => {
    if (newRiskName.trim() === "") {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
      const createdBy = parseInt(sessionStorage.getItem("loginId"));
      let body = {
        riskType: newRiskName,
        createdBy: createdBy,
      };
      RT_Insert(body)
        .then((response) => {
          if (response.status === 200) {
            const successData = response.data.success?.data;
            if (
              Array.isArray(successData) &&
              successData.some((item) => item["?column?"] === 2)
            ) {
              alert("Risk Type Name Already present.");
              element.style.color = "#0000EE";
              return;
            }
            setIsAddRowVisible(false);
            setNewRiskName("");
            getDetails();
            setAddOpen(false);
          } else {
            // alert("Unexpected response status.");
            element.style.color = "#0000EE";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    element.style.color = "#0000EE";
  }, 100);
  }

  //To delete a single risktype

  function checkDelete(roleid, element) {
    element.style.color = "red";

    setTimeout(() => {
      const confirmDelete = window.confirm(
        `Are you sure you want to delete this record?`
      );
      if (confirmDelete) {
        let body = {
          id: roleid,
        };
        RT_Del(body)
          .then((response) => {
            if (response.status === 200) {
              getDetails();
              setIsAddRowVisible(false);
              setEditing(null);
              setEditOpen(false);
              element.style.color = "#0000EE";
            } else {
              element.style.color = "#0000EE";
            }
          })
          .catch((error) => {
            console.log(error);
            element.style.color = "#0000EE";
          });
      } else {
        element.style.color = "#0000EE";
      }
    }, 100);
  }

  //To delete a issues in bulk

  function handleBulkDelete() {
    if (editOpen) {
      return;
    } else {
      const selectedIds = Object.keys(checkedItems).filter(
        (key) => checkedItems[key]
      );
      const selectedObjects = selectedIds.map((id) => ({ id: id }));

      if (selectedObjects.length === 0) {
        alert("Please select atleast one checkbox");
        return;
      }

      const confirmDelete = window.confirm(
        "Are you sure you want to delete this record?"
      );
      if (confirmDelete) {
      }
    }
  }

  //----------------------------------------------------------------------------------------

  function handleClose(element) {
    element.style.color = "red";
    setTimeout(() => {
    setIsAddRowVisible(false);
    setNewRiskName("");
    setIsEmpty(false);
    setAddOpen(false);
    element.style.color = "#0000EE";
  }, 100);
  }

  function handleInsert() {
    if (editOpen) {
      return;
    } else {
      setAddOpen(true);
      setIsEmpty(false);
      setIsAddRowVisible(true);
    }
  }

  function handleEditSingleEntry(id, name, element) {
    element.style.color = "red";
    setTimeout(() => {
    setEditOpen(true);
    if (isEmpty) {
      element.style.color = "#0000EE";
      return;
    } else if (addOpen && newRiskName.trim() === "") {
      setIsEmpty(true);
      element.style.color = "#0000EE";
      return;
    } else {
      setEditing(id);
      setEditedTypeName(name);
      setCheckedItems({});
      element.style.color = "#0000EE";
    }
    element.style.color = "#0000EE";
  }, 100);
  }

  function handleCancelEdit(element) {
    element.style.color = "red";
    setTimeout(() => {
    setEditing(null);
    setEditOpen(false);
    element.style.color = "#0000EE";
  }, 100);
  }

  //---------------------------------------------------------------------

  return (
    <Grid container>
      <Grid item lg={0.6} className={modules["main_cont"]}>
        <SampleSidebar />
      </Grid>
      <Grid item lg={12} sx={{ display: "block" }}>
        <Grid item lg={12} className={modules["home-cont"]}>
          <Home page={"RiskTypeMaster"} />
        </Grid>

        {/* <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className="home-cont">
            <Home page={"RiskTypeMaster"} />
          </Grid> */}

        <Grid item lg={12}>
          <div className={modules["instancemaster_div"]}>
            <div className={modules["pro-bread"]}>
              <a>Master</a>
              <img src={bread} alt="" />
              <a>Risk Type Master</a>
            </div>
          </div>
          <Grid
            container
            className={modules["table-grid"]}
            style={{ marginTop: "1.5rem" }}
          >
            <div
              className={modules["table-container"]}
              style={{ width: "50%" }}
            >
              <Table className={modules["custom-table"]}>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <input
                        type="checkbox"
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                        className={modules["custom-checkbox"]}
                        onChange={handleMainCheckboxChange}
                        checked={isAllChecked}
                      />
                    </TableCell>
                    <TableCell className={modules["table-cell"]}>
                      Sr. No.
                    </TableCell>
                    <TableCell className={modules["table-cell"]}>
                      Risk Type
                    </TableCell>
                    {/* <TableCell className={modules["table-cell"]}>Effective From </TableCell> */}
                    <TableCell className={modules["table-cell"]}>
                      Action
                    </TableCell>
                    {/* <TableCell className={modules["table-cell"]}>Remove</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchData?.map((item, index) => (
                    <TableRow key={index} className={modules["table-row"]}>
                      <TableCell padding="checkbox">
                        <input
                          type="checkbox"
                          className={modules["custom-checkbox"]}
                          onChange={(event) =>
                            handleIndividualCheckboxChange(event, item.id)
                          }
                          checked={!!checkedItems[item.id]}
                          // style={{ width: "13px" }}
                        />
                      </TableCell>
                      <TableCell className={modules["table-cell"]}>
                        {index + 1}
                      </TableCell>
                      <TableCell className={modules["table-cell"]}>
                        {editing === item.id ? (
                          <input
                            type="text"
                            value={editedTypeName}
                            className={modules["inputField1"]}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /[^a-zA-Z0-9 _-]/g;
                              const sanitizedValue = value.replace(regex, "");
                              setEditedTypeName(sanitizedValue);
                            }}
                          />
                        ) : (
                          <div>
                            <Typography className={modules["ccpm_label-style"]}>
                              {item.risktypename}
                            </Typography>
                          </div>
                        )}
                      </TableCell>

                      <TableCell className={modules["table-cell"]}>
                        {editing === item.id ? (
                          <>
                            <a
                              style={{
                                paddingRight: "1rem",
                                cursor: "pointer",
                              }}
                              onClick={(e) => handleUpdSave(item, e.currentTarget)}
                            >
                              Update
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                handleCancelEdit(e.currentTarget)
                              }
                            >
                              Cancel
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              style={{
                                paddingRight: "1rem",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                handleEditSingleEntry(
                                  item.id,
                                  item.risktypename,
                                  e.currentTarget

                                )
                              }
                            >
                              Edit
                            </a>

                            <a
                              style={{
                                paddingRight: "1rem",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                checkDelete(item.id, e.currentTarget)
                              }
                            >
                              Delete
                            </a>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                  {isAddRowVisible && (
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell scope="row"></TableCell>
                      <TableCell className={modules["table-cell"]}>
                        <Stack direction="row" spacing={2}>
                          <input
                            type="text"
                            value={newRiskName}
                            className={modules["inputField1"]}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /[^a-zA-Z0-9 _-]/g;
                              const sanitizedValue = value.replace(regex, "");
                              setNewRiskName(sanitizedValue);
                            }}
                            style={{ paddingLeft: "5px" }}
                          />
                          {isEmpty && (
                            <Typography
                              style={{ color: "red", fontSize: "10px" }}
                            >
                              *Enter Value
                            </Typography>
                          )}
                        </Stack>
                      </TableCell>

                      <TableCell className={modules["table-cell"]} align="left">
                        <a
                          style={{
                            color: "#0000EE",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={(e) => addRiskType(e.currentTarget)}
                        >
                          Save
                        </a>

                        <a
                          style={{
                            color: "#0000EE",
                            textDecoration: "underline",
                            marginLeft: "0.6rem",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleClose(e.currentTarget)}
                        >
                          Cancel
                        </a>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow></TableRow>
                </TableBody>
              </Table>

              {/* <Grid container className={modules["btn2-cont"]}>
                    <button >Add</button>
                  </Grid> */}
            </div>
          </Grid>

          {/* // : " "} */}
          {/* <TableContainer
              component={Paper}
              style={{ marginTop: "20px", marginLeft: "6rem" }}
            >
              <Table className={modules["customTbl"]}>
                <TableHead className="table_head">
                  <TableRow
                    sx={{
                      "& th": {
                        color: "#fff",
                      },
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onChange={handleMainCheckboxChange}
                        checked={isAllChecked}
                      />
                    </TableCell>
                    <TableCell>Sr.No.</TableCell>
                    <TableCell align="left">Risk Type</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchData?.map((item, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        key={index + 1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Checkbox
                            color="primary"
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            onChange={(event) =>
                              handleIndividualCheckboxChange(event, item.id)
                            }
                            checked={!!checkedItems[item.id]}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">
                          {editing === item.id ? (
                            <input
                              type="text"
                              value={editedTypeName}
                              className={modules["inputField1"]}
                              onChange={(e) => {
                                const value = e.target.value;
                                const regex = /[^a-zA-Z0-9 _-]/g;
                                const sanitizedValue = value.replace(regex, "");
                                setEditedTypeName(sanitizedValue);
                              }}
                            />
                          ) : (
                            <div>{item.risktypename}</div>
                          )}
                        </TableCell>
                        <TableCell
                          className="editlink"
                          align="left"
                          sx={{ color: "#0000EE" }}
                        >
                          {editing === item.id ? (
                            <>
                              <a
                                style={{
                                  paddingRight: "1rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleUpdSave(item)}
                              >
                                Update
                              </a>
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={handleCancelEdit}
                              >
                                Cancel
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                style={{
                                  paddingRight: "1rem",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleEditSingleEntry(
                                    item.id,
                                    item.risktypename
                                  )
                                }
                              >
                                Edit
                              </a>

                              <a
                                style={{
                                  paddingRight: "1rem",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  checkDelete(item.id, e.currentTarget)
                                }
                              >
                                Delete
                              </a>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {isAddRowVisible && (
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <input
                            type="text"
                            value={newRiskName}
                            className={modules["inputField1"]}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /[^a-zA-Z0-9 _-]/g;
                              const sanitizedValue = value.replace(regex, "");
                              setNewRiskName(sanitizedValue);
                            }}
                          />
                          {isEmpty && (
                            <Typography
                              style={{ color: "red", fontSize: "10px" }}
                            >
                              *Enter Value
                            </Typography>
                          )}
                        </Stack>
                      </TableCell>

                      <TableCell align="left">
                        <a
                          style={{
                            color: "#0000EE",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => addRiskType()}
                        >
                          Save
                        </a>

                        <a
                          style={{
                            color: "#0000EE",
                            textDecoration: "underline",
                            marginLeft: "0.6rem",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClose()}
                        >
                          Cancel
                        </a>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer> */}
          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginTop: "18px",
              marginBottom: "20px",
              marginLeft: "6rem",
            }}
          >
            <button
              style={{ marginBottom: "0.5rem !important" }}
              className={modules["btn1"]}
              onClick={handleInsert}
            >
              ADD
            </button>
            {/* <button type="button" className="btn_role" onClick={handleInsert}>
              Add
            </button> */}
            <button
              style={{ marginBottom: "0.5rem !important" }}
              className={modules["btn1"]}
              onClick={handleBulkDelete}
            >
              Delete
            </button>
            {/* <button
              type="button"
              className="btn_role"
              onClick={handleBulkDelete}
            >
              Delete
            </button> */}
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RiskTypeMaster;
