import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import RM1 from "../HelpPopups/images/RM1.jpg";
import RM2 from "../HelpPopups/images/RM2.jpg";
import RM3 from "../HelpPopups/images/RM3.jpg";
import RM4 from "../HelpPopups/images/RM4.jpg";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";


const RoleMasterhelpPopup = () => {

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline", fontWeight:"bold" }}>Role Master</h3>
              <br />
              <li>
                We can create different roles needed in CCPM using the Role Master Screen.
              </li>
              <li>PATH : Admin \ Role Master</li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={RM1} alt="RM1" />
              </Stack>
              <li>
                For creating a Roles, click on “ADD” button and enter the required details (Role name, Role description ) in the available text boxes and click on “Save” link. Before saving, to undo the changes done or to terminate the creating process, Click the “Cancel” hyperlink.
              </li>
              <li>
                For editing the created role, click on “Edit” hyperlink, do the required changes and click on the “Update” hyperlink.
              </li>
              <li>
                For deleting single role, click on the “Delete” hyperlink of respective record. And for deleting multiple records at once, select the check boxes of respective roles and click on the “Delete” button provided at the bottom of the page.
              </li>
              <li>
                Clicking on the role name hyperlinks under the field “Role Name”, will lead the user to the “Role Permission” screen where permissions for the roles can be assigned.
              </li>
              <br />

              <li style={{ listStyleType: "square", textDecoration: "underline" }}>Validations on the screen:-</li>
              <br />


              <ul>
                <li>
                  System does not allow creating multiple records with same role name i.e re-adding of the existing record again.
                </li>

                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={RM2} alt="RM2" />
                </Stack>

                <li>User should not leave any input field blank before saving the data, as click of “Save” link keeping the fields empty makes the error popup window to appear</li>

                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={RM3} alt="RM3" />
                </Stack>

                <li>
                  User should make selection of the data before the click of “Delete” button, as without selection of data the popup window appears
                </li>

                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={RM4} alt="RM4" />
                </Stack>
              </ul>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RoleMasterhelpPopup;
