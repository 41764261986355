import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
import modules from "./../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Calendar from "react-calendar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetIRExport,
  GetIRIssueOwner,
  GetIRSearchResult,
  GetTTMTFinancialYear,
  GetTTMTBindProjectName,
  GetTTMTSearchResult,
} from "../../../Services/CCPM.js";

// function ArrowLine() {
//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       <div
//         style={{
//           width: "1px",
//           height: "18px",
//           backgroundColor: "grey",
//           marginRight: "2px",
//         }}
//       />
//       <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
//     </div>
//   );
// }

const TaskTakingMoreTime = () => {
  const [selectedInstance, setSelectedInstance] = useState("0");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("1");
  const [selectedAssignedTo, setselectedAssignedTo] = useState("0");
  const [assignedOwner, setAssignedOwner] = useState([]);
  const [selectedissueStatus, setSelectedIssueStatus] = useState("0");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [instanceId, setInstanceId] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState(-1);
  const [selectedAttributeValue, setSelectedAttributeValue] = useState(-1);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarToOpen, setCalendarToOpen] = useState(false);
  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());

  const handleSelectChange = (attributenumber, id) => {
    // console.log(attributenumber, id, "attributenumber value");
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [instances, setInstances] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [displayfromdate, setdisplayfromdate] = useState("");
  const [displaytodate, setdisplaytodate] = useState("");
  const handleFromDateChange = (date) => {
    setFromdate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplayfromdate(`${day}/${month}/${year}`);
    setCalendarOpen(false);
  };

  const handleToDateChange = (date) => {
    setTodate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplaytodate(`${day}/${month}/${year}`);
    setCalendarToOpen(false);
  };

  const accessTypes = [
    { value: "null", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ];

  const periods = [
    { value: "Annual", label: "Annually" },
    { value: "Half-yearly", label: "Half-yearly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Monthly", label: "Monthly" },
  ];

  const projectStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Active" },
    { value: "5", label: "Hold" },
    { value: "7", label: "Completed" },
    { value: "10", label: "CDDCheckedOut" },
    { value: "11", label: "ModifiedCheckedOut" },
  ];

  const issueStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Pending" },
    { value: "5", label: "Closed" },
  ];

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getAssinedOwner();
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
      getFinancialYear();
    }
  }, [instances]);

  const toggleCalendar = () => {
    setCalendarOpen((prevOpen) => !prevOpen);
  };

  const toggleToCalendar = () => {
    setCalendarToOpen((prevOpen) => !prevOpen);
  };

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = () => {
    const loginId = sessionStorage.getItem("loginId");
    const dateRange = selectedFinancialYear;
    const formattedDateRange = dateRange.replace(/\s*-\s*/, "-");
    console.log(formattedDateRange); // Output: "1 April 2022-31 March 2023"
    const body = {
      userId: loginId,
      instanceId: parseInt(selectedInstance, 10),
      financialYear: formattedDateRange,
    };
    GetTTMTBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    GetPCBindProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFinancialYear = () => {
    GetTTMTFinancialYear()
      .then((response) => {
        // console.log(response, "FY");
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data, "FY");
          setFinancialYears(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssinedOwner = () => {
    const body = {
      userId: 0,
    };
    GetIRIssueOwner(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "Assigned Owner");
          setAssignedOwner(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "Attri val");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExcel = async (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      issueOwner: selectedAssignedTo,
      projectType: selectedProjectAccessType,
      accessType: "",
      issueStatusId: selectedissueStatus,
    };

    // if (selectedRoleId !== -1) {
    try {
      setIsLoading(true);
      const response = await GetIRExport(body);

      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // const contentDisposition = response.headers["content-disposition"];
        const filename = "IssueReport.xlsx";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoading(false);
        console.error("No Data found");
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error downloading file:", err);
    }
  };

  const handleSearch = () => {
    setShowResult(true);
    const loginId = sessionStorage.getItem("loginId");
    const dateRange = selectedFinancialYear;
    const formattedDateRange = dateRange.replace(/\s*-\s*/, "-");
    console.log(formattedDateRange); // Output: "1 April 2022-31 March 2023"
    const body = {
      instanceID: parseInt(selectedInstance),
      financialYear: formattedDateRange,
      period: selectedPeriod,
      projId: parseInt(selectedProjectName),
      userId: parseInt(loginId),
      attribute: parseInt(selectedAttributes),
      attributevalue: parseInt(selectedAttributeValue),
    };

    GetTTMTSearchResult(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "table data");
          setTableData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DownloadHandler = () => {
    setShowResult(true);
    console.log(selectedAttributes, "selectedAttributes");
    console.log(selectedProjectStatus, "status");
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getExcel(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const getFilterAttributeValues = (a_number) => {
    console.log(a_number, "attributenumber");

    const number = parseInt(a_number, 10);

    const allAttributesValue = filteredValueData.flat();
    console.log(allAttributesValue, "allAttributesValue");

    const filteredData = allAttributesValue.filter(
      (item) => item.attributenumber === number
    );
    console.log(filteredData, "filteredData");

    setAttributeValues(filteredData);
  };

  return (
    <>
      <div
      // style={{
      //   overflowX: showResult && tableData.length > 0 ? "auto" : "hidden",
      // }}
      >
        {isLoading && (
          <img
            src={loading}
            className={modules["loading_gif"]}
            alt="Loading..."
          />
        )}
        <Grid container>
          <Grid item lg={0.6} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>

          <Grid item lg={12} sx={{ display: "block" }}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"TaskTakingMoreTime"} />
            </Grid>

            <Grid item lg={12}>
              <div
                className={modules["instancemaster_div"]}
                style={{ marginBottom: "5px" }}
              >
                <div
                  className={modules["pro-bread"]}
                  style={{ marginLeft: "-2.5rem" }}
                >
                  <a>Reports</a>
                  <img src={bread} alt="" />
                  <a>Task Taking More Time</a>
                </div>
              </div>
              <div
                className={modules["border_cont"]}
                style={{ width: "90%", marginLeft: "7rem" }}
              >
                <table className={modules["PCtable"]}>
                  <tbody>
                    <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Instance:<label style={{ color: "Red" }}>*</label>
                        </span>

                        <select
                          value={selectedInstance}
                          onChange={(e) => {
                            setSelectedInstance(e.target.value);
                            getProjectDetails(e.target.value);
                            setShowResult(false);
                            setSelectedFinancialYear("");
                          }}
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          {instances?.map((instance) => (
                            <option key={instance.name} value={instance.id}>
                              {instance.name}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td
                        className={modules["table_data1"]}
                        style={{ width: "155px" }}
                      >
                        <span style={{ width: "135px", color: "#414141" }}>
                          Report with Attribute :
                        </span>
                        <Checkbox
                          checked={isChecked}
                          onChange={(e) => {
                            handleCheckboxChange(e);
                            setShowResult(false);
                            setSelectedAttributes(-1);
                            setSelectedAttributeValue(-1);
                          }}
                          sx={{
                            color: "grey.600",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: "13px",
                            },
                            margin: "3px",
                            padding: "0px",
                          }}
                        />
                      </td>

                      {isChecked && (
                        <td
                          className={modules["table_data1"]}
                          style={{ width: "555px", marginLeft: "10px" }}
                        >
                          <span style={{ width: "70px", color: "#414141" }}>
                            Attribute :<label style={{ color: "Red" }}>*</label>
                          </span>
                          <select
                            value={selectedAttributes}
                            onChange={(e) => {
                              setSelectedAttributes(e.target.value);
                              getFilterAttributeValues(e.target.value);
                            }}
                            style={{
                              width: "120px !important",
                              marginRight: "20px",
                            }}
                            className={modules["dropdownSelectnew"]}
                          >
                            <option value={-1}>SELECT</option>

                            {hierarchyOptions?.map((instance) => (
                              <option
                                key={instance.attributename}
                                value={instance.attributenumber}
                              >
                                {instance.attributename}
                              </option>
                            ))}
                          </select>

                          {selectedAttributes !== -1 && (
                            <>
                              <span
                                style={{ width: "105px", color: "#414141" }}
                              >
                                Attribute Value :
                                <label style={{ color: "Red" }}>*</label>
                              </span>

                              <select
                                value={selectedAttributeValue}
                                onChange={(e) => {
                                  setSelectedAttributeValue(e.target.value);
                                  // getProjectDetails(e.target.value);
                                }}
                                className={modules["dropdownSelect"]}
                                style={{ width: "120px" }}
                              >
                                <option value={-1}>SELECT</option>

                                {attributeValues?.map((idx) => (
                                  <option
                                    key={idx.attributevalue}
                                    value={idx.id}
                                  >
                                    {idx.attributevalue}
                                  </option>
                                ))}
                              </select>
                            </>
                          )}
                        </td>
                      )}
                    </tr>

                    <tr
                      style={{
                        paddingBottom: "10px",
                        paddingLeft: "0px",
                      }}
                    >
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Financial Year :
                          <label style={{ color: "Red" }}>*</label>
                        </span>
                        <select
                          value={selectedFinancialYear}
                          onChange={(e) => {
                            setSelectedFinancialYear(e.target.value);
                            setSelectedPeriod("");
                            setShowResult(false);
                          }}
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={0}>SELECT</option>
                          {financialYears?.map((fy) => (
                            <option
                              key={fy.financialyear}
                              value={fy.financialyear}
                            >
                              {fy.financialyear}
                            </option>
                          ))}
                        </select>
                      </td>

                      {selectedFinancialYear && (
                        <td className={modules["table_data1"]}>
                          <span style={{ width: "115px", color: "#414141" }}>
                            Period :<label style={{ color: "Red" }}>*</label>
                          </span>
                          <select
                            value={selectedPeriod}
                            onChange={(e) => {
                              setSelectedPeriod(e.target.value);
                              getProjectsname();
                            }}
                            className={modules["dropdownSelect"]}
                            style={{ width: "200px" }}
                          >
                            <option value={""}>SELECT</option>
                            {periods?.map((p_i) => (
                              <option key={p_i.label} value={p_i.value}>
                                {p_i.label}
                              </option>
                            ))}
                          </select>
                        </td>
                      )}

                      {selectedFinancialYear && selectedPeriod && (
                        <td className={modules["table_data1"]}>
                          <span style={{ width: "115px" }}>Project Name:</span>
                          <select
                            value={selectedProjectName}
                            onChange={(e) =>
                              setSelectedProjectName(e.target.value)
                            }
                            className={modules["dropdownSelect"]}
                            // style={{ width: "200px" }}
                          >
                            <option value={0}>SELECT</option>
                            {projects?.map((p_name) => (
                              <option
                                key={p_name.projectname}
                                value={p_name.id}
                              >
                                {p_name.projectname}
                              </option>
                            ))}
                          </select>
                        </td>
                      )}
                    </tr>
                    {(isChecked &&
                      selectedInstance &&
                      selectedFinancialYear &&
                      selectedPeriod &&
                      selectedAttributes !== -1 &&
                      selectedAttributeValue !== -1) ||
                    (!isChecked &&
                      selectedInstance &&
                      selectedFinancialYear &&
                      selectedPeriod) ? (
                      <tr
                        style={{
                          // justifyContent: "center",
                          gap: "10px",
                          paddingLeft: "470px",
                          paddingBottom: "10px",
                        }}
                      >
                        <button
                          type="submit"
                          className={modules["PCbtn"]}
                          onClick={handleSearch}
                        >
                          Search
                        </button>
                        <button
                          className={modules["PCbtn"]}
                          type="button"
                          onClick={() => {
                            // window.location.reload();
                            setSelectedInstance("0");
                            setSelectedFinancialYear("");
                            setSelectedPeriod("");
                            setSelectedProjectName(0);
                            setSelectedAttributes(-1);
                            setSelectedAttributeValue(-1);
                            setShowResult(false);
                          }}
                        >
                          Reset
                        </button>
                        {showResult && tableData.length > 0 && (
                          <button
                            type="submit"
                            className={modules["PCbtn"]}
                            onClick={DownloadHandler}
                          >
                            Export to Excel
                          </button>
                        )}
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>

              {showResult &&
                (tableData.length > 0 ? (
                  <>
                    <Grid
                      container
                      sx={{ marginLeft: "6.5rem", padding: "8px" }}
                    >
                      <Table
                        className={modules["TTMT_table"]}
                        sx={{ width: "68rem !important" }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={4}></TableCell>
                            <TableCell
                              colSpan={2}
                              sx={{
                                backgroundColor: "#75bf47 !important",
                                color: "white !important",
                              }}
                              className={modules["TTMT_customth"]}
                            >
                              Task Completed
                            </TableCell>
                            <TableCell
                              colSpan={2}
                              sx={{
                                backgroundColor: "#4f9028 !important",
                                color: "white !important",
                              }}
                              className={modules["TTMT_customth"]}
                            >
                              Task Delay
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "white !important",
                                textAlign: "left !important",
                              }}
                            >
                              Instance
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "white !important",
                                textAlign: "left !important",
                              }}
                            >
                              Project Name
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "white !important",
                                textAlign: "left !important",
                              }}
                            >
                              Period
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "white !important",
                                textAlign: "right !important",
                              }}
                            >
                              Total
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "white !important",
                                textAlign: "right !important",
                              }}
                            >
                              Count
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "white !important",
                                textAlign: "right !important",
                              }}
                            >
                              %
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "white !important",
                                textAlign: "right !important",
                              }}
                            >
                              Count
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "white !important",
                                textAlign: "right !important",
                              }}
                            >
                              %
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableData?.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell
                                className={modules["IRtable-row"]}
                                sx={{
                                  fontSize: "12px !important",
                                  textAlign: "left !important",
                                  padding: "8px",
                                }}
                              >
                                {item.instance}
                              </TableCell>
                              <TableCell
                                className={modules["IRtable-row"]}
                                sx={{
                                  fontSize: "12px !important",
                                  textAlign: "left !important",
                                  padding: "8px",
                                }}
                              >
                                {item.projectname}
                              </TableCell>
                              <TableCell
                                className={modules["IRtable-row"]}
                                sx={{
                                  fontSize: "12px !important",
                                  textAlign: "left !important",
                                  padding: "8px",
                                }}
                              >
                                {selectedPeriod}
                              </TableCell>
                              <TableCell
                                className={modules["IRtable-row"]}
                                sx={{
                                  fontSize: "12px !important",
                                  textAlign: "right !important",
                                  padding: "8px",
                                }}
                              >
                                {item.numberofcount + item.delaycount}
                              </TableCell>
                              <TableCell
                                className={modules["IRtable-row"]}
                                sx={
                                  item.numberofcount !== 0
                                    ? {
                                        color: "#0000EE !important",
                                        textDecoration: "underline",
                                        fontSize: "12px !important",
                                        textAlign: "right !important",
                                        padding: "8px",
                                        cursor: "pointer",
                                      }
                                    : {
                                        fontSize: "12px !important",
                                        textAlign: "right !important",
                                        padding: "8px",
                                      }
                                }
                                onClick={() => {
                                  if (item.numberofcount !== 0) {
                                    window.location.href = "/ccpm/viewtasks";
                                  }
                                }}
                              >
                                {item.numberofcount}
                              </TableCell>
                              <TableCell
                                className={modules["IRtable-row"]}
                                sx={{
                                  fontSize: "12px !important",
                                  textAlign: "right !important",
                                  padding: "8px",
                                }}
                              >
                                {item.ontime_percentage}
                              </TableCell>
                              <TableCell
                                className={modules["IRtable-row"]}
                                sx={
                                  item.delaycount !== 0
                                    ? {
                                        color: "#0000EE !important",
                                        textDecoration: "underline",
                                        fontSize: "12px !important",
                                        textAlign: "right !important",
                                        padding: "8px",
                                        cursor: "pointer",
                                      }
                                    : {
                                        fontSize: "12px !important",
                                        textAlign: "right !important",
                                        padding: "8px",
                                      }
                                }
                                onClick={(e) => {
                                  if (item.delaycount !== 0) {
                                    setTimeout(() => {
                                      e.target.style.color = "red";
                                      // window.location.href = "/ccpm/viewtasks";
                                    }, 50);
                                  }
                                }}
                              >
                                {item.delaycount}
                              </TableCell>
                              <TableCell
                                className={modules["IRtable-row"]}
                                sx={{
                                  fontSize: "12px !important",
                                  textAlign: "right !important",
                                  padding: "8px",
                                }}
                              >
                                {item.delay_precentage}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  </>
                ) : (
                  <TableRow>
                    <TableCell className={modules["NRF_row"]} align="left">
                      No Record Found
                    </TableCell>
                  </TableRow>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TaskTakingMoreTime;
