import React, { useState, useEffect } from "react";
import bread from "../../../../Assets/bread.png";
import Home from "../../../../home/Home";
import { Grid, Stack } from "@mui/material";
import modules from "../../ccpm.module.css";
import SampleSidebar from "../../../../Landing/SampleSidebar";
import "react-calendar/dist/Calendar.css";
import "../CalendarStyles.css";
import loading from "../../../../Assets/Loading_image.gif";
import save from "../../../../Assets/Save.jpg";
import back from "../../../../Assets/back.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate, useLocation } from "react-router-dom";
import {
  PBP_ERPData,
  PBP_GetTableData,
  PBP_UpdateTableData,
  PBP_ERPUpdData,
  PBP_ERPSearchData,
} from "../../../../Services/CCPM";

const PlannedBillingProjection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projId = parseInt(searchParams.get("projId"));
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [projName, setProjName] = useState("");
  const [erpFieldA, setErpFieldA] = useState("");
  const [erpFieldB, setErpFieldB] = useState("");
  const [totalPlannedBilling, setTotalPlannedBilling] = useState(0);
  const [totalActualBilling, setTotalActualBilling] = useState(0);
  const [remarks, setRemarks] = useState({});

  useEffect(() => {
    fetchData();
    fetchERPData();
  }, []);

  const fetchERPData = async () => {
    setIsLoading(true);
    try {
      let body = {
        projectId: projId,
      };
      const response = await PBP_ERPData(body);
      console.log(response);
      if (response.status === 200) {
        const check = response?.data?.success?.data[0];
        setProjName(check?.projectname);
        setErpFieldA(check?.erpprojectcompany);
        setErpFieldB(check?.erpprojectno);
      } else {
        console.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching ERP data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let body = {
        projectId: projId,
        plannedBillingValue: 0,
        taskId: 0,
        type: "GET",
      };
      const response = await PBP_GetTableData(body);
      if (response.status === 200) {
        setTableData(
          response?.data?.success?.data.map((item) => ({
            ...item,
            plannedBillingValue: item.plannedbillingvalue || "",
            actualBillingValue: item.totalactualbillingvalue || "",
          }))
        );
        setTotalPlannedBilling(calculateTotal("plannedBillingValue"));
        setTotalActualBilling(calculateTotal("actualBillingValue"));
      } else {
        console.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleRemarksChange = (taskId, value) => {
    setRemarks((prevRemarks) => ({
      ...prevRemarks,
      [taskId]: value,
    }));
  };

  const handleInputChange = (taskId, field, value) => {
    setTableData((prevData) =>
      prevData.map((item) =>
        item.taskid === taskId ? { ...item, [field]: value } : item
      )
    );
  };

  // Calculate totals
  const calculateTotal = (field) => {
    return tableData.reduce((acc, item) => {
      const value = parseFloat(item[field]) || 0;
      return acc + value;
    }, 0);
  };

  const handlePlannedBillingValBlur = (plannedBill, taskId) => {
    if (plannedBill) {
      setTableData((prevData) =>
        prevData.map((item) =>
          item.taskid === taskId
            ? {
              ...item,
              plannedBillingValue: parseFloat(
                item.plannedBillingValue
              ).toFixed(2),
            }
            : item
        )
      );
      setTotalPlannedBilling(calculateTotal("plannedBillingValue"));
    }
  };

  const handleActualBillingValBlur = (actualBill, taskId) => {
    if (actualBill) {
      setTableData((prevData) =>
        prevData.map((item) =>
          item.taskid === taskId
            ? {
              ...item,
              actualBillingValue: parseFloat(item.actualBillingValue).toFixed(
                2
              ),
            }
            : item
        )
      );
      setTotalActualBilling(calculateTotal("actualBillingValue"));
    }
  };

  const handleSave = () => {
    if (erpFieldA === "" && erpFieldB === "") {
      const confirmSave = window.confirm(
        "Are you sure you want to save changes?"
      );
      if (confirmSave) {
        setIsLoading(true);
        const multiTaskDetails = tableData.map((item) => ({
          plannedBillingValue: parseFloat(item.plannedBillingValue) || 0,
          taskId: item.taskid,
        }));

        const requestBody = {
          projectId: projId,
          multiTaskDetails: multiTaskDetails,
          type: "UPDATE",
        };

        PBP_UpdateTableData(requestBody)
          .then((response) => {
            if (response.status === 200) {
              alert("Records Saved Successfully. ");
              setIsLoading(false);
              fetchData();
            } else {
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      } else {
        return;
      }
    } else {
      if (!erpFieldB) {
        alert("Please Enter ERP Company Code.");
        return;
      }

      if (!erpFieldA) {
        alert("Please Enter ERP Project Number.");
        return;
      }

      const confirmSave = window.confirm(
        "Are you sure you want to save changes?"
      );
      if (confirmSave) {
        setIsLoading(true);
        let body = {
          erpProjectNo: erpFieldB,
          projectId: projId,
        };

        PBP_ERPSearchData(body)
          .then((response) => {
            if (response.status === 200) {
              PBP_ERPUpdData.then((response) => {
                if (response.status === 200) {
                  alert("Records Saved Successfully. ");
                  setIsLoading(false);
                  fetchERPData();
                  fetchData();
                } else {
                  setIsLoading(false);
                }
              }).catch((error) => {
                console.log(error);
                setIsLoading(false);
              });
            } else {
              alert("Invalid ERP Project Number.");
              setErpFieldB("");
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      } else {
        return;
      }
    }
  };

  return (
    <div>
      {isLoading && (
        <div className={modules["loading_gif"]}>
          <span style={{ textAlign: "center" }}>
            Please Wait....
          </span>
          <img src={loading} alt="Loading..." />
        </div>
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>View Billing Projection</a>
              </div>
            </div>
          </Grid>
          <Stack direction="row" className={modules["pbp_view_cont"]}>
            <div style={{ display: "flex", alignItems: 'center' }}>
              <p>
                <strong>
                  Project Name :
                </strong>
              </p>
              <input
                value={projName}
                title={projName}
                readOnly
                style={{ fontWeight: 'bold', backgroundColor: "#D0D0D0",overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '10rem' }}
                className={modules["pbp_view_input"]}
              />
            </div>
            <div style={{ display: "flex", alignItems: 'center' }}>
              <p>
                <strong>
                  ERP Company Code :
                </strong>
              </p>
              <input
                value={erpFieldA}
                title={erpFieldA}
                readOnly
                style={{ fontWeight: 'bold', backgroundColor: "#D0D0D0",overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '7rem' }}
                className={modules["pbp_view_input2"]}
              />
            </div>
            <div style={{ display: "flex", alignItems: 'center' }}>
              <p>
                <strong>
                  ERP Project No :
                </strong>
              </p>
              <input
                value={erpFieldB}
                title={erpFieldB}
                readOnly
                style={{ fontWeight: 'bold', backgroundColor: "#D0D0D0",overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '7rem' }}
                className={modules["pbp_view_input2"]}
              />
            </div>
            <div style={{ display: "flex", alignItems: 'center' }}>
              <input
                value='Invoice Actual Billing Total (Lacs):0.00'
                title='Invoice Actual Billing Total (Lacs):0.00'
                readOnly
                style={{ fontWeight: 'bold', backgroundColor: "#D0D0D0" ,overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '10.5rem'}}
                className={modules["pbp_view_input"]}
              />
            </div>
            <div className={modules["img_style"]}>
              <div className={modules["img_cont"]}>
                <img
                  className={modules["img_display"]}
                  src={back}
                  alt="back"
                  onClick={handleBackButton}
                ></img>
                Back
              </div>
            </div>
          </Stack>

          <div
            className={[
              modules["pbp_table-container"],
              modules["table-overflow"],
            ].join(" ")}
            style={{ marginLeft: "7rem" }}
          >
            <Table
              className={[
                modules["custom-table-mp"],
                modules["custom-table-mp-th"],
              ].join(" ")}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    Task Id
                  </TableCell>
                  <TableCell>Task Name</TableCell>
                  <TableCell>
                    Task Manager
                  </TableCell>
                  <TableCell>
                    Scheduled
                    <br />
                    Completion Date
                  </TableCell>
                  <TableCell>
                    Actual
                    <br />
                    Completion Date
                  </TableCell>
                  <TableCell>
                    Planned billing
                    <br />
                    value in Lacs
                    <br />
                  </TableCell>
                  <TableCell>
                    Projected billing
                    <br />
                    value in Lacs
                  </TableCell>
                  <TableCell>
                    Actual billing
                    <br />
                    value in Lacs
                  </TableCell>
                  <TableCell>Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((item) => (
                  <TableRow key={item.taskid} className={modules["table-row"]}>
                    <TableCell>{item.taskid}</TableCell>
                    <TableCell>{item.taskname}</TableCell>
                    <TableCell>{item.taskmanager}</TableCell>
                    <TableCell>{item.scheduledcompletiondate} </TableCell>
                    <TableCell>{item.actualcompletiondate}</TableCell>
                    <TableCell>
                      <input
                        readOnly
                        className={modules["input-fieldPB"]}
                        style={{ width: "6rem" }}
                        value={item.plannedBillingValue}
                        onBlur={() =>
                          handlePlannedBillingValBlur(
                            item.plannedBillingValue,
                            item.taskid
                          )
                        }
                      // onChange={(e) => {
                      //   const value = e.target.value;
                      //   if (/^\d{0,10}$/.test(value)) {
                      //     handleInputChange(
                      //       item.taskid,
                      //       "plannedBillingValue",
                      //       value
                      //     );
                      //   }
                      // }}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <input
                        readOnly
                        className={modules["input-fieldPB"]}
                        style={{ width: "6rem" }}
                        value={item.actualBillingValue}
                        onBlur={() =>
                          handleActualBillingValBlur(
                            item.actualBillingValue,
                            item.taskid
                          )
                        }
                      // onChange={(e) => {
                      //   const value = e.target.value;
                      //   if (/^\d{0,10}$/.test(value)) {
                      //     handleInputChange(
                      //       item.taskid,
                      //       "actualBillingValue",
                      //       value
                      //     );
                      //   }
                      // }}
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        readOnly
                        className={modules["input-fieldPB"]}
                        style={{ width: "6rem" }}
                        value={remarks[item.taskid] || ""}
                      // onChange={(e) =>
                      //   handleRemarksChange(item.taskid, e.target.value)
                      // }
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={modules["table-row"]}>
                  <TableCell sx={{ fontWeight: "bold", fontSize: "13px" }}>
                    Total
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontWeight: "bold", fontSize: "13px" }}>
                    {totalPlannedBilling !== 0 ? (
                      <>{totalPlannedBilling.toFixed(2)}</>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontWeight: "bold", fontSize: "13px" }}>
                    {totalActualBilling !== 0 ? (
                      <>{totalActualBilling.toFixed(2)}</>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", fontSize: "13px" }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <Stack className={modules["pbp_bottom_stack"]}>
            <p style={{ padding: "6px" }}>
              <strong>Invoice Details</strong>
            </p>
            <p className={modules["pbp_no_record"]}>No Record Found.</p>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default PlannedBillingProjection;
