import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/IC1.jpg";
import IC2 from "../HelpPopups/images/IC2.jpg";
import IC3 from "../HelpPopups/images/IC3.jpg";
import IC4 from "../HelpPopups/images/IC4.jpg";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";


const InstanceMasterhelpPopup = () => {

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem", fontFamily: "'Times New Roman', Times, serif" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline", fontWeight: "bold" }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
            <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>Hierarchy Master</h3>
            <br />
              <li>
                Hierarchy Masters screen is used for creating and maintaining
                records such as LOB’s & BU’s under each division. The levels
                which are displayed here are as maintained in the “Project
                Configuration settings” screen.
              </li>
              <li>PATH : CCPM \ Masters \ Hierarchy Masters</li>
              <Stack sx={{ paddingRight: "1rem", width: "45rem"  }}>
                <img src={IC1} alt="IC1" />
              </Stack>
              <li>
                for creating or inserting the data for the level wise, follow
                the below mentioned steps:-
              </li>
              <br/>
              <ol>
                <li>
                  Select the level in which data to be inserted from the
                  “Master” field.
                </li>
                <li>Click on the “Add” button.</li>
                <li>
                  Select the levels from drop down menu. Enter the name & code
                  for the level to be inserted. Click on the “Save” link, the
                  data will get saved.
                </li>
                <li>
                  For canceling the process of adding the data, click on the
                  Cancel link.
                </li>
                <li>
                  For making any updations to the existing records click on the
                  “Update” link, make required changes to the data and click on
                  the “Save” link
                </li>
              </ol>


              <li>
                For “Deleting” any record, select the record using the check
                box and click on the “Delete” button. Multiple records can be
                deleted at once. <span style={{color: "red"}}>If a project is already assigned to the
                selected bussiness level, record will not get deleted.</span> 
              </li>

              <li>
                For searching any data regarding any level, we have to first
                select the level in the “Master” field and then enter the data
                to search in the “level named field” text box and click on
                “Search” button. “Reset” button will be used for clearing the
                filter criteria and all the data will get displayed.
              </li>
              <br/>
              <li style={{ listStyleType: "square"}}>
                <span style={{ textDecoration: "underline" }}>NOTE:-</span>  Levels which are appearing the ”Masters” field are first
                defined in the “Product Configuration Settings” screen which
                appears as the hierarchical levels in the “Master” field.
              </li>
              <br/>
              <li style={{ listStyleType: "square", textDecoration: "underline" }}>Validations on the screen:-</li>
              <br/>
              <li>
                Duplication of existing record should not be allowed i.e
                re-adding of the existing record again.
              </li>

              
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC2} alt="IC2" />
              </Stack>


              <li>
                User should not leave any input field blank before saving the
                data, as click of “Save” link keeping the fields empty makes the
                error popup window to appear
              </li>

              <Stack sx={{ paddingRight: "1rem", width: "35rem"  }}>
                <img src={IC3} alt="IC3" />
              </Stack>

              <li>
                User should make selection of the data before the click of
                “Delete” button, as without selection of data the popup window
                appears
              </li>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC4} alt="IC4" />
              </Stack>

            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InstanceMasterhelpPopup;
