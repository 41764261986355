import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SPBD1 from "../HelpPopups/images/SPBD1.png";
import SPBD2 from "../HelpPopups/images/SPBD2.png";
import SPBD3 from "../HelpPopups/images/SPBD3.png";
import SPBD4 from "../HelpPopups/images/SPBD4.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointBufferDownloadhelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{ fontSize: "14px" }}>
              <h2 style={{ marginLeft: "3rem", textAlign: "left" }}>
                STOCK POINT BUFFER DOWNLOAD
              </h2>
              <br />
              <br />

              <li className="no-bullets">
                Session Path {">>"} MTA {">>"} MTA Parameter {">>"} Stock Point
                Buffer Download
              </li>
              <br />
              <li className="no-bullets">
                Stock point buffer download is used when user need to check
                buffer for item for any stock point. User can download the
                details to local machine as well he can down load report in
                excel or he can just display it.
              </li>
              <br />
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SPBD1} alt="SPBD1" />
              </Stack>
              <br />
              <li style={{ marginLeft: "1.5rem" }}>
                MTA Instance – Select your MTA Instance from dropdown provided.
              </li>
              <br />
              <li style={{ marginLeft: "1.5rem" }}>
                Stock Point – User can select from drop down available for the
                stock point. User can see the list of stock point for which he
                is authorized for.
              </li>
              <br />
              <li style={{ marginLeft: "1.5rem" }}>
                Company Code – User can select from the drop down for company
                code. Once stock point is selected then company code will be
                displayed where that stock point is maintained.
              </li>
              <br />
              <li style={{ marginLeft: "1.5rem" }}>
                Item code – System will show item related to the combination of
                stock point and company code. User can select the item code from
                drop down. If user have item code for the combination, he can
                directly enter the itemcode. System will verify the item code
                with the stock point and company code combination. This filter
                not mandatory. If item code not selected then system will show
                record of all item codes for the combination.
              </li>
              <br />
              <li style={{ marginLeft: "1.5rem" }}>
                Download Type – User can select the report type he wanted. There
                are two options provided.
                <li style={{ marginLeft: "2.5rem" }}>
                  Display – System will display the record.
                </li>
                <br />
                <li style={{ marginLeft: "2.5rem" }}>
                  Excel – A excel report will be downloaded to the local
                  machine.
                </li>
                <br />
              </li>
              <br />
              <li style={{ marginLeft: "1.5rem" }}>
                Download sample format – There is separate tab for downloading
                of sample format in excel. This can be used to modify and update
                buffer.
              </li>
              <br />
              <br />
              <br />
              <li className="no-bullets">Display Format</li>
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SPBD2} alt="SPBD2" />
              </Stack>
              <br />

              <br />

              <li className="no-bullets">Excel Format</li>
              <br />
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SPBD3} alt="SPBD3" />
              </Stack>
              <br />
              <li className="no-bullets">
                Excel will appear in following format
              </li>
              <br />
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SPBD4} alt="SPBD4" />
              </Stack>
              <br />

              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StockPointBufferDownloadhelpPopup;
