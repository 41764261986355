import React, { useEffect, useState } from "react";
import modules from "./../ccpm.module.css";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import bread from "../../../Assets/bread.png";
import SampleSidebar from "../../../Landing/SampleSidebar";
import { useLocation } from "react-router-dom";
import { GetIssueListData } from "../../../Services/CCPM";
import loading from "../../../Assets/Loading_image.gif";
import "react-calendar/dist/Calendar.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../ProjectPlan/CalendarStyles.css";

export default function IssueList() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projId = searchParams.get("projId");
  const [isLoading, setIsLoading] = useState(false);
  const [checkAdd, setCheckAdd] = useState(false);
  const [selectedRiskType, setSelectedRiskType] = useState("");
  const [dropDownRiskType, setDropDownRiskType] = useState([]);
  const [selectedRiskCat, setSelectedRiskCat] = useState("");
  const [dropDownRiskCat, setDropDownRiskCat] = useState([]);
  const [selectedTriggerStatus, setSelectedTriggerStatus] = useState("");
  const [dropDownTriggerStatus, setDropDownTriggerStatus] = useState([]);
  const [tableData, settableData] = useState([]);
  const [checkMult, setCheckMult] = useState(false);
  const [probValue, setProbValue] = useState("");
  const [impactValue, setImpactValue] = useState("");
  const [likelyDate, setLikelyDate] = useState("");
  const [displayData, setDisplaydate] = useState("");
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [riskDesc, setRiskDesc] = useState("");

  const tableDummyData = [
    {
      "Sr.No.": 1,
      "Project Name": "Nanma_Manoj_Project",
      Department: "asdfvcasdf",
      "Task Name": "Prashant_task",
      "Task Manager": "subrapwc",
      "Planned Start": "28-Aug-2024 16:05",
      "Planned End": "29-Aug-2024 16:30",
      "Actual Start": "13-Aug-2024 02:32",
      "Projected / Actual End": "08-Aug-2024",
      Status: "Completed",
      "Remaining Duration": "0.00",
      "% Buffer Penetration": "-1796.00",
      "Last Updated On": "13-Aug-2024",
    },
  ];

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetIssueData();
  }, []);

  const GetIssueData = () => {
    const loginId = parseInt(sessionStorage.getItem("loginId"), 10);
    const body = {
      assignedToId: loginId,
    };
    GetIssueListData(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "dwecd");
          settableData(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div style={{ overflowX: "auto", height: "100vh" }}>
        <Grid container>
          <Grid item lg={0.7} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>
          <Grid item lg={12}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"IssueList"} />
            </Grid>

            <Grid item lg={12}>
              <div className={modules["RM_div"]}>
                <div
                  className={modules["pro-bread"]}
                  style={{ marginLeft: "-3.5rem" }}
                >
                  <a>Task Management</a>
                  <img src={bread} alt="" />
                  <a>Issue List</a>
                </div>
              </div>

              <Grid container className={modules["mid-contRMP"]}>
                {/* {showResult && tableData.length > 0 && ( */}
                <>
                  <Grid
                    container
                    sx={{
                      marginLeft: "6.5rem",
                      padding: "8px",
                      width: "80%",
                      marginTop: "1rem",
                    }}
                  >
                    <Table
                      className={modules["IM_custom-table"]}
                      sx={{ textAlign: "left" }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: "60px" }}>Sr. No.</TableCell>
                          <TableCell sx={{ lineHeight: " normal !important" }}>
                            Project Name
                          </TableCell>
                          <TableCell sx={{ lineHeight: " normal !important" }}>
                            Task Name
                          </TableCell>
                          <TableCell sx={{ lineHeight: " normal !important" }}>
                            Issue Category
                          </TableCell>
                          <TableCell sx={{ lineHeight: " normal !important" }}>
                            Issue Description
                          </TableCell>
                          <TableCell>IssueRaisedBy</TableCell>
                          <TableCell>IssueRaisedOn</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.projectname}</TableCell>
                            <TableCell>{item.taskname}</TableCell>
                            <TableCell>{item.issuecategory}</TableCell>
                            <TableCell>
                              <textarea
                                value={item.issuedescription}
                                readOnly
                                style={{
                                  // height: "65px",
                                  // width: "295px",
                                  // outline: "none",
                                  color:"#545454",
                                  border: "1px solid #ccc",
                                  padding: "5px",
                                }}
                              />
                            </TableCell>
                            <TableCell>{item.issueraisedby}</TableCell>
                            <TableCell>
                              {formatDate(item.issueraisedon)}
                            </TableCell>{" "}
                            <TableCell>
                              <button
                                type="submit"
                                style={{
                                  backgroundColor: "#0B76BC",
                                  color: "white",
                                  padding: "4px 12px",
                                  cursor: "pointer",
                                  fontSize: "15px",
                                  border: "none",
                                  // width: "150px",
                                }}
                                // onClick={handleSearch}
                              >
                                ResolveIssue
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </>
                {/* )} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
