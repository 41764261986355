import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/CM1.jpg";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";

const BMReporthelpPopup = () => {
  return (
    <>
    <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "#0000EE",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight:'bold',
                  fontFamily:'TimesNewRoman'
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            
              <h3 style={{ textDecoration: "underline", fontFamily:'Cablibri,serif',fontSize:'26px'}}>BUFFER MANAGEMENT RUN REPORT</h3>
              <br />
              <p style={{fontSize:'18px',fontFamily:"sans-serif"}}> <strong>Session Path {'>>'}CCPM {'>>'} Reports {'>>'}  Buffer Management Run Report. </strong></p>
              <ul>
              <br></br>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC1} alt="IC1" />
              </Stack>
              <p>Instance – Select instance form the Drop Down.</p>
              
              <br></br>
              </ul>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC1} alt="IC1" />
              </Stack>
              <p>Attribute wise filters are given for the generating desired report. Also project wise filters of the Project name, Project manager and Projects access type ae given.</p>
              <br></br>
              <p>Also From Date  and To date filter is given so that user can generate report for the desired date range can be seen.</p>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC1} alt="IC1" />
              </Stack>
            <ul>
              <li>Date wise record is shown in the report. One date is one row.</li>
              <ul>
                <li>Sr.No – In this column record serial no is shown.</li>
                <li> Project Id – In this column project id linked to the project name is shown.</li>
                <li>  Project Name – In this column name of the project shown.</li>
                <li>BM Taken By – In this column it is shown that who has initiated the BM run. If it is done in system run, then it is updated as System. If it is taken manual, then it will sow name of the person in the run.</li>
                <li>  BM Start Date – In this column start date and time for the BM run is shown for that day.</li>
                <li>   BM End Date – In this column end date and time for the BM run is shown for that day.</li>
                </ul>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BMReporthelpPopup;
