
import React, { useEffect, useState } from "react";
import "../../../HelpPopups/HelpPopup.css";
import Stack from "@mui/material/Stack";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import modules from '../../ccpm.module.css'
import { Grid } from "@mui/material";
import bread from "../../../../Assets/bread.png";
import { PendingTaskPopup2, PendingTaskPopup2graph } from "../../../../Services/CCPM";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import loading from "../../../../Assets/Loading_image.gif";
import addImage from "../../../../Assets/add.png";

const PendingTask2 = () => {
    const [activeRow, setActiveRow] = useState(null);
    const [data, setData] = useState([])
    const [displayBox, setDisplayBox] = useState(false)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = parseInt(searchParams.get("projId"));
    const chainid = searchParams.get("chainId");
    const fid = parseInt(searchParams.get("fid"));
    const criticalchaincompletion = searchParams.get("criticalchaincompletion")
    const projectbufferconsumed = searchParams.get("projectbufferconsumed")
    const color = searchParams.get("color")
    const [isLoading, setIsLoading] = useState(false);
    const [label, setLabel] = useState('')
    const [remainingduration, setRemainingDuration] = useState('')
    useEffect(() => {
        setIsLoading(true)
        let body = {
            projectId: projectId,
            fid: fid,
            chainType: chainid
        }
        PendingTaskPopup2(body)
            .then((response) => {
                if (response?.status === 200) {
                    console.log(response)
                    setData(response?.data?.success?.data)
                    setIsLoading(false)
                }
                else if (response?.response?.data?.message !== undefined) {
                    setIsLoading(false);
                    alert(response?.response?.data?.message);
                } else if (response?.response?.data?.error?.message !== undefined) {
                    setIsLoading(false);
                    // alert(response?.response?.data?.error?.message);
                } else {
                    setIsLoading(false);
                    alert(response?.response?.data?.success?.message);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const handlePlusClick = (index, task) => {
        setIsLoading(true)
        setActiveRow(activeRow === index ? null : index)
        let body = {
            projectsId: projectId,
            taskId: task
        }
        PendingTaskPopup2graph(body)
            .then((response) => {
                if (response?.status === 200) {
                    console.log(response)
                    setLabel(response?.data?.success?.data[0].date)
                    setRemainingDuration(response?.data?.success?.data[0].remainingduration)
                    setIsLoading(false)
                }
                else if (response?.response?.data?.message !== undefined) {
                    setIsLoading(false);
                    alert(response?.response?.data?.message);
                } else if (response?.response?.data?.error?.message !== undefined) {
                    setIsLoading(false);
                    alert(response?.response?.data?.error?.message);
                } else {
                    setIsLoading(false);
                    alert(response?.response?.data?.success?.message);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const datan = {
        labels: [label],
        datasets: [
            {
                label: '',
                data: [remainingduration],
                fill: false,
                backgroundColor: '#000000',
                borderColor: '#000000',
                borderWidth: 1, // Thinner line
                pointBackgroundColor: 'blue', // Color of the points
                pointBorderColor: 'blue', // Border color of the points
            },
        ],
    };
    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date', // X-axis label
                    font: {
                        size: 12, // Set the font size
                    },
                    color: '#000000'
                },
                ticks: {
                    color: '#000000' // Set the label color to black
                },
                beginAtZero: true,
                grid: {
                    color: 'black', // Set the grid line color to black
                },
                offset: true,
            },
            y: {
                title: {
                    display: true,
                    text: 'Remaining Duration', // Y-axis label
                    font: {
                        size: 12, // Set the font size
                    },
                    color: '#000000'
                },
                ticks: {
                    color: '#000000' // Set the label color to black
                },
                beginAtZero: true,
                grid: {
                    color: 'black', // Set the grid line color to black
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            chartAreaBackground: {
                color: '#ffffff', // Set chart background to white
            },
        },
    };
    const chartAreaBackground = {
        id: 'chartAreaBackground',
        beforeDraw: (chart) => {
            const ctx = chart.ctx;
            const chartArea = chart.chartArea;
            ctx.save();
            ctx.fillStyle = options.plugins.chartAreaBackground.color;
            ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
            ctx.restore();
        },
    };

    return (
        <>
            <div>
                {isLoading && (
                    <img
                        src={loading}
                        className={modules["loading_gif"]}
                        alt="Loading..."
                    />
                )}
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                        <img src={logo} alt="Godrej" />
                    </Stack>
                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Grid item lg={12}>
                    <div style={{ marginLeft: '3rem' }}>
                        <div className={modules["pro-bread"]}>
                            <a className={modules["pending-txt"]}>Active Projects</a>
                            <img src={bread} alt="" />
                            <a className={modules["pending-txt"]}>Chain Details</a>
                            <img src={bread} alt="" />
                            <a className={modules["pending-txt"]}>{chainid}</a>
                        </div>
                    </div>
                    <div style={{
                        margin: '2rem 0rem 0.5rem 0.3rem',
                        border: '1px solid #000000',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '80%',
                        height: '30px',
                        padding: '2px'
                    }}>
                        <div className={modules["viewpending2"]}
                            style={{ flexBasis: '20%' }}>% Chain Completion:
                        </div>
                        <div className={modules["viewpending2"]}
                            style={{
                                flexBasis: '5%',
                            }}>{criticalchaincompletion}
                        </div>
                        <div className={modules["viewpending2"]}
                            style={{
                                flexBasis: '25%',
                            }}>% Buffer Consumption
                        </div>
                        <div className={modules["viewpending2"]}
                            style={{
                                flexBasis: '5%',
                            }}>{projectbufferconsumed}
                        </div>
                        <div className={modules["viewpending2"]}
                            style={{
                                flexBasis: '23%',
                            }}>Chain Color:
                        </div>
                        <div className={modules["viewpending2"]}
                            style={{
                                flexBasis: '16%',
                                backgroundColor: color
                            }}>{color}
                        </div>
                    </div>
                </Grid>
                <div
                    className={modules["table-overflow"]}
                    style={{ marginLeft: "0.3rem", width: '99%', overflowY: 'auto' }}
                >
                    <Table
                        className={[
                            modules["custom-table_viewpending"],
                            modules["custom-table-mp-th"],
                        ].join(" ")}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Sr.No</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Task<br />History</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>TaskName</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Projected<br />StartDate</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Projected<br />EndDate</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Status</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Remaining<br />Duration</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Task Manager</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length > 0 ? data?.map((item, index) => (
                                <TableRow className={modules["table-row"]} style={{ backgroundColor: '#DEDFDE' }}>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{index+1}</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }} onClick={() => handlePlusClick(index, item.taskid)}><img src={addImage}  alt="Godrej" />
                                        {activeRow === index && (
                                            <Line data={datan} options={options} plugins={[chartAreaBackground]} />
                                        )}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{item.taskname}</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{item.projectedstartdate}</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{item.projectedfinishdate}</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{item.status}</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{item.remainingduration}</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{item.taskmanager}</TableCell>
                                </TableRow>
                            )) : isLoading ? "" :
                                <TableRow>
                                    <TableCell colSpan={12} style={{ textAlign: 'center', border: '1px solid black', padding: '8px' }}>
                                        No Record found
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default PendingTask2;
