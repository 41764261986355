import React, { useState, useEffect, useLayoutEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  // TextField,
  // Button,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import SideBar from "../../../Landing/Navbar";
// import modules from "./UserInstanceLinking.module.css";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
// import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import { Tab, Tabs } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import loading from "../../../Assets/Loading_image.gif";
import {
  GetValidUser,
  GetInstances,
  UpdateInstanceAccess,
  DefaultInstance,
  GetInstanceAttribute,
  GetInstanceAttributeValue,
  giveAttributeValueAccess,
} from "../../../Services/CCPM.js";

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const UserInstanceLinking = () => {
  const [searchValue, setSearchValue] = useState("");
  const [userID, setUserID] = useState("");
  const [defaultInstance, setDefaultInstance] = useState("");
  const [tabDisplay, setTabDisplay] = useState(false);
  const [changeDone, setChangeDone] = useState(false);
  const [instancedata, setInstanceData] = useState([]);
  const [instances, setInstances] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAttributeRows, setSelectedAttributeRows] = useState([]);
  const [attributeNumber, setAttributeNumber] = useState(null);
  const [selectedNewRows, setSelectedNewRows] = useState([]);
  const [selectedAttributeNewRows, setSelectedAttributeNewRows] = useState([]);
  const [selectedInstanceId, setSelectedInstanceId] = useState(null);
  const [AttributeValues, setAttributeValues] = useState([]);
  const [filteredAttributeValues, setFilteredAttributeValues] = useState([]);
  const [InstanceWiseAttribute, setInstanceWiseAttribute] = useState([]);
  const [selectedIsDefault, setSelectedIsDefault] = useState(null);
  const [instanceDefault, setInstanceDefault] = useState(null);
  const [showPlus, setShowPlus] = useState(true);
  const [selectedItem, setSelectedItem] = useState([]); // State to track the selected item

  const [update, setUpdate] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [dropdownData, setDropdownData] = useState([]);
  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);
  const [isChecked, setIsChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [tabelUpdate, settabelUpdate] = useState(false);
  const [tabValue, setTabValue] = useState(0); // State to track the active tab
  const [isLoading, setIsLoading] = useState(false);
  const [inputUserId, setInputUserId] = useState(null);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
  }, []);

  useEffect(() => {
    if (!selectedRows.includes(selectedIsDefault)) {
      setSelectedIsDefault(0);
    }
  }, [selectedRows, selectedIsDefault, setSelectedIsDefault]);

  const GetInstanceTable = (use_id) => {
    const body = {
      id: use_id,
    };
    GetInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const instances_data = response?.data?.success?.data;
          // console.log(instances_data, "instances_data");

          const allAccessZero = instances_data.every(
            (item) => item.access === 0
          );
          if (allAccessZero) {
            alert("You do not have access to any Active instance in CCPM");
          }
          updateInstanceIds(response?.data?.success?.data, use_id);
          setInstanceData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ToSetSelectedRows = (data, element) => {
    // console.log(data, "defc");
    // Filter items where item.access is 1 and map to their ids
    element.style.color = "red";

    setTimeout(() => {
      const idsWithAccessOne = data
        ?.filter((item) => item.access === 1)
        .map((item) => item.id);

      // Update the state with these ids
      setSelectedAttributeRows(idsWithAccessOne);
      setSelectedAttributeNewRows(idsWithAccessOne);
      element.style.color = "#0000EE";
    }, 300);
  };

  const handleIconToggle = (index) => {
    const isSelected = selectedItem.includes(index);
    if (isSelected) {
      // Remove the index from selectedItems if it's already selected
      setSelectedItem(selectedItem?.filter((item) => item !== index));
    } else {
      // Add the index to selectedItems if it's not already selected
      setSelectedItem([...selectedItem, index]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearch = () => {
    setIsLoading(true);
    // console.log(searchValue, "searchValue");
    if (searchValue) {
      const body = {
        loginCode: `${searchValue.trim()}`,
      };
      GetValidUser(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setIsLoading(false);
            // console.log(response?.data?.success?.data[0]?.id);
            let use_id = response?.data?.success?.data[0]?.id;
            setUserID(use_id);
            setInputUserId(use_id);
            GetInstanceTable(use_id);
            // settabelUpdate(!tabelUpdate);
          } else if (response?.response?.data?.statusCode === 400) {
            setTabDisplay(false);
            setTimeout(() => {
              alert(
                response?.response?.data?.error?.message || " Please try again."
              );
            }, 100);
            // settabelUpdate(!tabelUpdate);
          } else {
            alert("An error occurred. Please try again.");
          }
        })
        .catch((error) => {
          // console.log(error?.message);
          alert(error?.message);
        });
    } else {
      // console.log("Cec");
      alert("User LoginId Cannot be blank.");
    }
  };

  const updateInstanceIds = (instanceData, use_id) => {
    // console.log("Instance Data:", instanceData);
    const ids = instanceData
      ?.filter((i) => i.access === 1)
      .map((i) => i.instanceid);
    setSelectedRows(ids);
    setTabDisplay(true);
    // settabelUpdate(!tabelUpdate);

    const defaultItems = instanceData
      ?.filter((i) => i.defaultselected === 1)
      .map((i) => ({ instanceid: i.instanceid, instancename: i.instancename }));

    if (defaultItems.length > 0) {
      const { instanceid, instancename } = defaultItems[0];
      setInstanceDefault(parseInt(instanceid, 10));
      setSelectedIsDefault(parseInt(instanceid, 10));
      setDefaultInstance(instancename);
      setDisplayValue(parseInt(instanceid, 10));
      // console.log("use_id", use_id);
      handleSelection(parseInt(instanceid, 10), use_id);
      // console.log(parseInt(instanceid, 10));
    } else {
      // console.log("No default selected item found.");
    }

    // console.log(ids[0], "firstInstanceIdWithAccess");
    if (defaultItems.length === 0) {
      // console.log("use_id", use_id);
      setDisplayValue(ids[0]);
      setSelectedInstanceId(ids[0]);
      handleSelection(ids[0], use_id);
    }
  };

  function handleCheckboxChange(event, instanceId) {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, instanceId]);
    } else {
      setSelectedRows(selectedRows?.filter((id) => id !== instanceId));
    }
    // console.log(selectedNewRows, "SNR");
  }

  function handleCheckbox2Change(event, attributeId) {
    // console.log(selectedAttributeNewRows, "all s");
    if (event.target.checked) {
      setSelectedAttributeRows([...selectedAttributeRows, attributeId]);
    } else {
      setSelectedAttributeRows(
        selectedAttributeRows?.filter((id) => id !== attributeId)
      );
    }
    // console.log(selectedAttributeRows);
  }

  // const handleIsDefaultChange = (e, instanceId) => {
  //   // console.log(e.target.checked, instanceId , "cec");
  //   if (e.target.checked) {
  //     setSelectedIsDefault(instanceId);
  //   }
  //   else {
  //     setSelectedIsDefault(null);
  //   }
  // };

  const SubmitInstanceAccess = () => {
    const loginId = sessionStorage.getItem("loginId");
    // console.log(selectedIsDefault, "selectedIsDefault");

    const body1 = {
      userId: userID,
      instanceId: selectedIsDefault,
      createdBy: loginId,
    };

    const body2 = {
      userId: userID,
      instanceId: selectedNewRows,
      createdBy: loginId,
    };

    // console.log(selectedIsDefault, "sid");

    if (
      window.confirm(
        "Are you sure you want to Save User Instance Attribute Linking Data?"
      )
    ) {
      if (selectedNewRows.length > 0) {
        UpdateInstanceAccess(body2)
          .then((response) => {
            // console.log(response?.data);
            // console.log(response?.data?.statusCode);
            if (response?.data?.statusCode === 200) {
              alert(response?.data?.success?.message);
              // alert("Default Instance Set Successfully");
              // if (selectedIsDefault === 0) {
              //   alert("Default Instance Set,No Change to Update for Linking");
              // }
              setSelectedNewRows([]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (selectedIsDefault || selectedIsDefault === 0) {
        DefaultInstance(body1)
          .then((response) => {
            if (response?.data?.statusCode === 200) {
              if (selectedNewRows.length === 0) {
                if (selectedIsDefault === 0) {
                  alert("Default Instance Set,No Change to Update for Linking");
                } else {
                  alert(
                    response?.data?.success?.message ||
                      "Default Instance Set Successfully"
                  );
                }
              }
              // setSelectedIsDefault(null);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    handleSearch();
  };

  const SubmitAttributeAccess = (index) => {
    const loginId = sessionStorage.getItem("loginId");
    // console.log(selectedInstanceId, "idi");
    // console.log(AttributeValues, index, "selected");
    // console.log(AttributeValues[index], "selected vl");

    let ids = [];

    AttributeValues[index]?.forEach((obj) => {
      if (obj.access === 1) {
        ids.push(obj.id);
      }
    });

    // console.log(ids, "ids");

    const body = {
      userId: userID,
      instanceId: selectedInstanceId,
      attributeNo: attributeNumber,
      attributeId: `${ids}`,
      createdBy: loginId,
    };

    if (AttributeValues[index].length > 0) {
      if (
        window.confirm(
          "Are you sure you want to Save User Instance Attribute Linking Data?"
        )
      ) {
        if (changeDone) {
          giveAttributeValueAccess(body).then((response) => {
            // console.log(response?.data);
            // console.log(response?.data?.statusCode);
            if (response?.data?.statusCode === 200) {
              alert("Details updated Successfully");
            }
            setChangeDone(false);
          });
        } else {
          alert("No Change to Update");
        }
      }
    }
  };

  const handleSelection = (selectedValue, use_id) => {
    // console.log(use_id, "user id input");
    // console.log(selectedValue, "selectedValue");
    setSelectedInstanceId(selectedValue);
    // Call the API with the selected value
    const body = {
      instanceId: selectedValue,
      activeOnly: 1,
    };

    if (selectedValue) {
      GetInstanceAttribute(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setInstanceWiseAttribute(response?.data?.success?.data);
            // console.log(response?.data?.success?.data, "InstanceWiseAttribute");
          } else if (response?.data?.statusCode === 404) {
            alert("No Attribute Defined against Selected Instance");
            setInstanceWiseAttribute([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      const body1 = {
        userId: use_id,
        instanceId: selectedValue,
      };

      GetInstanceAttributeValue(body1)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setAttributeValues(response?.data?.success?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const settabelUpdate = !tabelUpdate;
    // handleSearch();
  };

  const [selectedValue, setSelectedValue] = useState(defaultInstance || "");

  const getFilteredAttribute = (a_number) => {
    // console.log(a_number, "attributenumber");

    const number = parseInt(a_number, 10);
    // console.log(number, "Nattributenumber");

    const allAttributesValue = AttributeValues.flat();
    // console.log(allAttributesValue, "allAttributesValue");

    // const filteredData = allAttributesValue.filter(
    //   (item) => item.attributenumber === number
    // );
    // console.log(filteredData, "filteredData");

    setFilteredAttributeValues(allAttributesValue);
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          {/* <SideBar /> */}
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"UserInstanceLinking"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "20px" }}
            >
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>Masters</a>
                <img src={bread} alt="" />
                <a>User Instance Linking</a>
              </div>
            </div>

            <Grid sx={{ marginLeft: "1rem" }}>
              <Grid container className={modules["mid-cont"]}>
                <Grid item className={modules["UIL_main"]}>
                  <span className={modules["UIL_text"]}>User LoginId :</span>
                  <span style={{ color: "red" }}>*</span>
                  <span>
                    <input
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className={modules["UIL_input_css"]}
                    />
                  </span>
                </Grid>
                <Grid item lg={1} sx={{ marginTop: "1rem" }}>
                  <button className={modules["UIL_btn"]} onClick={handleSearch}>
                    Search
                  </button>
                </Grid>

                {tabDisplay && (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "2rem",
                        // marginLeft: "3rem",
                      }}
                    >
                      <Tabs
                        className={modules["tabs_css"]}
                        value={tabValue}
                        onChange={handleTabChange}
                        sx={{
                          "& .MuiTabs-indicator": { display: "none" },
                          "& .Mui-selected": { color: "inherit" },
                        }}
                      >
                        <Tab
                          // sx={{
                          //  color: "tabValue === 0 ? '#fff' : '#000'",
                          // }}

                          className={`${modules["tab_css"]} ${
                            tabValue === 0 ? modules["tab_css_selected"] : ""
                          }`}
                          label="User Instance"
                        />
                        <Tab
                          className={`${modules["tab_css"]} ${
                            tabValue === 1 ? modules["tab_css_selected"] : ""
                          }`}
                          label="User Instance Attribute"
                        />
                      </Tabs>
                    </Box>
                    {tabValue === 0 && (
                      <Box className={modules["tab-box"]}>
                        <TableContainer
                          sx={{ width: "55%", maxHeight: "25rem" }}
                        >
                          <Table style={{ width: "60%" }}>
                            <TableHead className={modules["drop-down-list"]}>
                              <TableRow
                                className={modules["header_text"]}
                                sx={{
                                  "& th": {
                                    color: "#fff",
                                  },
                                }}
                              >
                                <TableCell
                                  sx={{
                                    padding: "5px 10px !important",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                  }}
                                >
                                  Instance Name
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: "20% !important",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    padding: "5px 10px !important",
                                  }}
                                >
                                  IsLinked
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: "10% !important",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    padding: "5px 10px !important",
                                  }}
                                >
                                  IsDefault
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {instancedata?.map((i, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                  <TableRow
                                    className={modules["UIL_TR"]}
                                    key={i.id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "8px",
                                        color: "#414141",
                                      }}
                                      className={modules["row_text"]}
                                      align="left"
                                    >
                                      <span>{i.instancename}</span>
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      padding="checkbox"
                                    >
                                      <Checkbox
                                        sx={{
                                          color: "grey.600",
                                          "&.Mui-checked": {
                                            color: "grey.600",
                                          },
                                        }}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                        // checked={selectedRows.includes(
                                        //   i.instanceid
                                        // )}
                                        checked={selectedRows.includes(
                                          i.instanceid
                                        )}
                                        onChange={(e) => {
                                          handleCheckboxChange(e, i.instanceid);
                                          const isChecked = e.target.checked;
                                          const instanceId = i.instanceid;
                                          // if (isChecked) {
                                          if (
                                            !selectedNewRows.includes(
                                              instanceId
                                            )
                                          ) {
                                            setSelectedNewRows([
                                              ...selectedNewRows,
                                              instanceId,
                                            ]);
                                          }
                                          // }
                                          else if (
                                            selectedRows.includes(instanceId)
                                          ) {
                                            setSelectedNewRows([
                                              ...selectedNewRows,
                                              instanceId,
                                            ]);
                                          } else {
                                            // Remove instanceid if it's in the array
                                            setSelectedNewRows(
                                              selectedNewRows?.filter(
                                                (id) => id !== instanceId
                                              )
                                            );
                                          }
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      padding="checkbox"
                                    >
                                      <Checkbox
                                        sx={{
                                          color: "grey.600",
                                          "&.Mui-checked": {
                                            color: "grey.600",
                                          },
                                        }}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                        checked={
                                          selectedIsDefault === i.instanceid
                                        }
                                        onChange={
                                          (e) => {
                                            // setSelectedIsDefault(i.instanceid);
                                            if (e.target.checked) {
                                              setSelectedIsDefault(
                                                i.instanceid
                                              );
                                            } else if (
                                              !e.target.checked &&
                                              selectedIsDefault === i.instanceid
                                            ) {
                                              setSelectedIsDefault(0);
                                            }
                                          }
                                          // handleIsDefaultChange(e, i.instanceid)
                                        }
                                        disabled={
                                          !selectedRows.includes(i.instanceid)
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Grid item lg={1} sx={{ marginTop: "2rem" }}>
                          <button
                            className={modules["UIL_btn"]}
                            style={{ marginLeft: "0rem" }}
                            onClick={SubmitInstanceAccess}
                          >
                            Submit
                          </button>
                        </Grid>
                      </Box>
                    )}

                    {tabValue === 1 && (
                      <Box className={modules["tab-box"]}>
                        <Grid
                          container
                          columnSpacing={{ xs: 2 }}
                          sx={{ alignItems: "center", paddingBottom: "2.5rem" }}
                        >
                          <Grid
                            item
                            sx={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              width: "8rem",
                              color: "#414141",
                            }}
                          >
                            Instance :<span style={{ color: "red" }}>*</span>
                          </Grid>
                          <Grid item>
                            <FormControl className={modules["form-control"]}>
                              <select
                                value={displayvalue}
                                onChange={(e) => {
                                  setDisplayValue(e.target.value);
                                  setSelectedInstanceId(e.target.value);
                                  handleSelection(e.target.value, userID);
                                  setSelectedItem([]);
                                }}
                                className={modules["InstanceSelect"]}
                                // style={{ width: "300px", height: "40px" }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 200,
                                    },
                                  },
                                }}
                              >
                                {instancedata
                                  ?.filter((i) => i.access === 1)
                                  .sort((a, b) => {
                                    if (
                                      a.defaultselected === 1 &&
                                      b.defaultselected !== 1
                                    )
                                      return -1;
                                    if (
                                      a.defaultselected !== 1 &&
                                      b.defaultselected === 1
                                    )
                                      return 1;
                                    return a.instancename.localeCompare(
                                      b.instancename
                                    );
                                  })
                                  .map((i) => (
                                    <option
                                      value={i.instanceid}
                                      key={i.instancename}
                                    >
                                      {i.instancename}
                                    </option>
                                  ))}
                                <ArrowDropDownIcon
                                  sx={{ marginRight: "15px" }}
                                />
                              </select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        {InstanceWiseAttribute?.filter(
                          (i) => i.isactive === 1
                        ).map((i, index) => (
                          <div href="#" className={modules["div_style"]} item>
                            <span>
                              {selectedItem.includes(index) ? (
                                <RemoveIcon />
                              ) : (
                                <AddIcon />
                              )}
                              <span
                                style={{
                                  textDecoration: "underline",
                                  color: "#0000EE",
                                  cursor: "pointer",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  fontFamily: "verdana",
                                }}
                                onClick={(e) => {
                                  handleIconToggle(index);
                                  ToSetSelectedRows(
                                    AttributeValues[i.attributenumber - 1],
                                    e.currentTarget
                                  );
                                  getFilteredAttribute(i.attributenumber);
                                }}
                              >
                                {i.attributename}
                                {AttributeValues.flat().filter(
                                  (item) =>
                                    item.attributenumber === i.attributenumber
                                )?.length === 0 && (
                                  <span>:Attribute Data not Maintained</span>
                                )}
                                {/* {filteredAttributeValues.filter(
                                  (item) =>
                                    item.attributenumber === i.attributenumber
                                )?.length === 0 && (
                                  <span>:Attribute Data not Maintained</span>
                                )} */}
                              </span>
                            </span>

                            {selectedItem.includes(index) && (
                              <Table style={{ width: "40%" }}>
                                <TableHead>
                                  <TableRow
                                    className={modules["drop-down-list"]}
                                  >
                                    <TableCell
                                      className={modules["header_text"]}
                                    >
                                      Attribute Value
                                    </TableCell>
                                    <TableCell
                                      style={{ width: "50px" }}
                                      className={modules["header_text"]}
                                    >
                                      Access
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {/* {AttributeValues[i.attributenumber - 1] &&
                                  AttributeValues[i.attributenumber - 1]
                                    .length > 0 ? (
                                    AttributeValues[i.attributenumber - 1]?.map( */}
                                  {filteredAttributeValues.filter(
                                    (item) =>
                                      item.attributenumber === i.attributenumber
                                  ) &&
                                  filteredAttributeValues.filter(
                                    (item) =>
                                      item.attributenumber === i.attributenumber
                                  ).length > 0 ? (
                                    filteredAttributeValues
                                      .filter(
                                        (item) =>
                                          item.attributenumber ===
                                          i.attributenumber
                                      )
                                      ?.map((item, itemIndex) => (
                                        <TableRow
                                          key={item.id}
                                          className={modules["UIL_TR"]}
                                        >
                                          <TableCell
                                            className={modules["row_text"]}
                                          >
                                            {item.attributevalue}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            padding="checkbox"
                                          >
                                            <Checkbox
                                              sx={{
                                                color: "grey.600",
                                                "&.Mui-checked": {
                                                  color: "grey.600",
                                                },
                                              }}
                                              checked={item.access === 1}
                                              onChange={(e) => {
                                                const s_data = [
                                                  ...AttributeValues,
                                                ];
                                                s_data[i.attributenumber - 1][
                                                  itemIndex
                                                ].access =
                                                  s_data[i.attributenumber - 1][
                                                    itemIndex
                                                  ].access === 1
                                                    ? 0
                                                    : 1;

                                                setAttributeValues([...s_data]);
                                                // console.log(s_data);

                                                setAttributeNumber(
                                                  item.attributenumber
                                                );

                                                setChangeDone(true);
                                              }}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ))
                                  ) : (
                                    <TableRow>
                                      <TableCell align="left">
                                        No Record Found
                                      </TableCell>
                                    </TableRow>
                                  )}
                                  <button
                                    style={{
                                      marginLeft: "0rem",
                                      marginTop: "0rem",
                                    }}
                                    className={modules["btn1"]}
                                    onClick={() => {
                                      SubmitAttributeAccess(index);
                                    }}
                                  >
                                    Submit
                                  </button>
                                </TableBody>
                              </Table>
                            )}
                          </div>
                        ))}
                      </Box>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserInstanceLinking;
