import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import MUIL1 from "../HelpPopups/images/MUIL1.png";
import MUIL2 from "../HelpPopups/images/MUIL2.png";
import MUIL3 from "../HelpPopups/images/MUIL3.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const AdminControlhelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <div class="WordSection1" style={{fontFamily: "arial", fontSize:"12px"}}>
              <p>
                <b style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Admin Control: -
                </b>
              </p>

              <br />

              <p>
                This session is used to control various options related to file
                upload, download and file structure.
              </p>

              <br />

              <p>
                For each file the session will contain the following parameters:
                -
              </p>
              <br />

              <ul>
                <li style={{marginLeft:"3rem"}}>
                  Input File FIELDTERMINATOR- Separation indication for each
                  field in file to be uploaded.
                </li>
                <li style={{marginLeft:"3rem"}}>
                  Input File FIRSTROW – Row no. from which data is available.
                </li>
                <li style={{marginLeft:"3rem"}}>Input File Name – Input File name (to be uploaded).</li>
                <li style={{marginLeft:"3rem"}}>Input File Path – Input File path on server folder.</li>
                <li style={{marginLeft:"3rem"}}>
                  Input File ROWTERMINATOR - Separation indication for each row
                  in file to be uploaded.
                </li>
                <li style={{marginLeft:"3rem"}}>
                  Output File FIELDTERMINATOR- Separation indication for each
                  field in file to be downloaded.
                </li>
                <li style={{marginLeft:"3rem"}}>
                  Output File FIRSTROW – Row no. from which data is available.
                </li>
                <li style={{marginLeft:"3rem"}}>Output File Name- Output File name (to be downloaded).</li>
                <li style={{marginLeft:"3rem"}}>Output File Path - Output File path on server folder.</li>
                <li style={{marginLeft:"3rem"}}>
                  Output File ROWTERMINATOR - Separation indication for each row
                  in file to be downloaded.
                </li>
              </ul>
              <br />
              <br />
              <br />

              <p>
                Also, along with the above parameters, some parameters are
                required for determining the no. of days for pulling
                transactions from LN and to determine the CR server (generic)
              </p>
              <br />

              <ul>
                <li style={{marginLeft:"3rem"}}>Backdated Transactional Data Loading Days</li>
                <li style={{marginLeft:"3rem"}}>CR – Database</li>
                <li style={{marginLeft:"3rem"}}>CR - Server</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminControlhelpPopup;
