import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SP1 from "../HelpPopups/images/SP1.png";
import SP2 from "../HelpPopups/images/SP2.png";
import SP3 from "../HelpPopups/images/SP3.png";
import SP4 from "../HelpPopups/images/SP4.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointhelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h2 style={{ textAlign: "left", fontWeight: "bold" }}>
                                STOCK POINT
                            </h2>
                            <br />
                            <br />


                            <li className="no-bullets" style={{ textDecoration: "underline" }}>
                                Path `{">>"}` MTA `{">>"}` MTA Parameter `{">>"}`  Stock Point
                            </li>
                            <br />
                            <br />

                            <li className="no-bullets">
                                This option is used for maintaining the stock point for the MTA instances created. Here one can Add, Delete or Update records.
                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SP1} alt="SP1" />
                            </Stack>
                            <br />
                            <li className="no-bullets">
                                User needs to enter the Stock Point Type, Stock Point and Stock point description & Submit the record.
                            </li>
                            <li className="no-bullets">
                                Add New Record – Select this option to add stock point to specific MTA Instance.
                            </li>
                            <li>
                                Select MTA Instance
                            </li>
                            <li>
                                Tick Add New Record
                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Select Stock Point Type ( for Stock Point type selcection refer Help for Stock Point Type )
                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Input name of Stock Point
                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Input Description Of Stock Point
                            </li>
                            <li>
                                Click on Submit option for inserting record.
                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Use Reset Option for resetting filled information.
                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SP2} alt="SP2" />
                            </Stack>
                            <br />

                            <br />
                            <li>
                                Select MTA Instance

                            </li>
                            <li>
                                Tick Update Record
                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Select Stock Point Type ( for Stock Point type selcection refer Help for Stock Point Type)
                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Select Stock Point Name from the drop Down. Once you selcect stock point it will show stock point and Stock point description in respective fields.

                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Update name of Stock Point

                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Update Description Of Stock Point

                            </li>
                            <li>
                                Click on Submit option for Updating record.
                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Use Reset Option for resetting filled information.

                            </li>

                            <br />
                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SP3} alt="SP3" />
                            </Stack>
                            <br />

                            <li>
                                Select MTA Instance
                            </li>
                            <li>
                                Tick Delete Record
                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Select Stock Point Type ( for Stock Point type selcection refer Help for Stock Point)
                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Select Stock Point Name from the drop Down. Once you selcect stock point it will show stock point and Stock point description in respective fields.

                            </li>
                            <li>
                                Click on Delete option for Deleting record.
                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Use Reset Option for resetting filled information.

                            </li>

                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SP4} alt="SP4" />
                            </Stack>
                            <br />
                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StockPointhelpPopup;
