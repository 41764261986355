import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  TextField,
  Stack,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../../Landing/Navbar";
// import modules from "./ProcurementCat.module.css";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  DDL_fetch,
  Cat_tableData,
  Cat_Main_Update,
  Cat_Main_Delete,
  Cat_Main_Add,
  SubCat_tableData,
  SubCat_delete,
  SubCat_update,
  SubCat_Add,
  SubCat_BulkDelete,
  SubCat_ImgSave,
} from "../../../Services/Admin";
import save from "../../../Assets/save.png";
import loading from "../../../Assets/Loading_image.gif";

const ProcurementCategory = () => {
  const [searchCat, setSearchCat] = useState("");
  const [addCat, setAddCat] = useState("");
  const [dropdownData, setDropdownData] = useState([]);
  const [mainDropdownData, setMainDropdownData] = useState([]);
  const [displayvalue, setDisplayValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredSubData, setFilteredSubData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [editing, setEditing] = useState(null);
  const [editingSub, setEditingSub] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [showSubTable, setShowSubTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [editCategoryName, setEditCategoryName] = useState("");
  const [editSubCategoryName, setEditSubCategoryName] = useState("");
  const [checkBtn, setCheckBtn] = useState(false);
  const [currentCat, setCurrentCat] = useState("");
  const [currentCatId, setCurrentCatId] = useState("");
  const [addSubRow, setAddSubRow] = useState(false);
  const [addSubValue, setAddSubValue] = useState("");
  const [checkedItems, setCheckedItems] = useState({});
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [failedCheck, setFailedCheck] = useState(false);
  const [saveCheck, setSaveCheck] = useState(false);
  const [equalCheck, setEqualCheck] = useState(false);
  const [defaultDistributionChecked, setDefaultDistributionChecked] =
    useState(true);
  const [checkTog, setCheckTog] = useState(false);
  const [inputDurValues, setInputDurValues] = useState({});
  const [isEntered, setIsEntered] = useState(false);

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    checkLoginAndRedirect();
    let body = {
      userId: sessionStorage.getItem("loginId"),
    };
    setIsLoading(true);

    DDL_fetch(body)
      .then((response) => {
        if (response.status === 200) {
          const apiData = response.data;
          const data = apiData.success.data;
          const names = data.map((item) => item.name);
          setDropdownData(names);
          setMainDropdownData(data);
          setIsLoading(false);
          if (names.length > 0) {
            setDisplayValue(names[0]);
            setSelectedId(data[0].id);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const initialInputDurValues = {};
    filteredSubData.forEach((item) => {
      initialInputDurValues[item.sub_categoryid] =
        item.percentagedurationdistribution
          ? item.percentagedurationdistribution.toString()
          : "0";
    });
    setInputDurValues(initialInputDurValues);
    const check = filteredSubData[0]?.percentagedurationdistribution;
    if (check) {
      setDefaultDistributionChecked(false);
    }
  }, [filteredSubData]);

  useEffect(() => {
    const allChecked =
      filteredSubData.length > 0 &&
      filteredSubData.every((item) => checkedItems[item.sub_categoryid]);
    setIsAllChecked(allChecked);
  }, [checkedItems, filteredSubData]);

  const handleMainCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsAllChecked(isChecked);
    const newCheckedItems = {};
    filteredSubData.forEach((item) => {
      newCheckedItems[item.sub_categoryid] = isChecked;
    });
    setCheckedItems(newCheckedItems);
  };

  const handleIndividualCheckboxChange = (event, sub_categoryid) => {
    setCheckedItems((prev) => ({
      ...prev,
      [sub_categoryid]: event.target.checked,
    }));
  };

  const handleChange = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.name === selectedName
    );
    setShowTable(false);
    setFailedCheck(false);
    setDisplayValue(selectedName);
    setSelectedId(selectedItem.id);
    setCheckBtn(false);
    setAddSubRow(false);
  };

  const handleSearch = () => {
    setCheckBtn(false);
    setAddSubRow(false);
    setShowSubTable(false);
    let body = {
      instanceId: selectedId,
      categoryNm: searchCat !== "" ? searchCat : "",
    };

    Cat_tableData(body)
      .then((response) => {
        if (response.status === 200) {
          setFailedCheck(false);
          const apiData = response.data.value;
          const data = apiData.success.data;
          if (data.length === 0) {
            setShowTable(false);
            setShowSubTable(false);
            setFailedCheck(true);
          } else {
            setFilteredData(data);
            setShowTable(true);
          }
        } else {
          setFailedCheck(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCatAdd = () => {
    if (addCat === "") {
      alert("Please Enter Procurement Category to be Added.");
    } else {
      Cat_Main_Add(selectedId, addCat, sessionStorage.getItem("loginId"))
        .then((response) => {
          if (response.status === 200) {
            alert("Details Inserted Succesfully");
            handleSearch();
            setAddCat("");
            setShowSubTable(false);
            setCheckBtn(false);
            setAddSubRow(false);
          } else if (response?.response?.status === 400) {
            alert("Procurement Category already Exists for Selected Instance.");
          } else {
            alert("Error in Inserting Data");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleReset = () => {
    setSearchCat("");
    setFilteredData([]);
    setShowTable(false);
    setAddSubRow(false);
    setCheckTog(false);
    setFailedCheck(false);
    setIsAllChecked(false);
    setCheckBtn(false);
    setSaveCheck(false);
    setEqualCheck(false);
    setDefaultDistributionChecked(true);
  };

  const handleEdit = (item, element) => {
    element.style.color = "red";
    setTimeout(() => {
      setCheckBtn(false);
      setAddSubRow(false);
      setShowSubTable(false);
      setEditing(item.categoryname);
      setEditCategoryName(item.categoryname);
      element.style.color = "#0000EE";
    }, 50);
  };

  const handleUpdate = (item, element) => {
    element.style.color = "red";

    setTimeout(() => {
      element.style.color = "#0000EE";

      setTimeout(() => {
        element.style.color = "#0000EE";

        if (item.categoryname === editCategoryName) {
          alert(
            "No Update:Procurement Category already Exists for Selected Instance."
          );
          return;
        }
         else {
          Cat_Main_Update(selectedId, editCategoryName.trim(), item.id, selectedId)
            .then((response) => {
              if (response?.status === 200) {
                console.log(response, "corrent");
                alert("Details Updated Succesfully");
                handleSearch();
                element.style.color = "#0000EE";
              }else if(response?.response?.status === 400){
                alert("No Update:Procurement Category already Exists for Selected Instance.");
              } else {
                console.log("try again");
              }
            })
            .catch((error) => {
              console.log(error);
              console.log("corcdcrent");
              element.style.color = "#0000EE";
            });
          setEditing(null);
        }
      }, 50);
    }, 50);
  };

  const handleDelete = (item, element) => {
    element.style.color = "red";

    setTimeout(() => {
      const confirmDelete = window.confirm(`Are you sure you want to delete?`);
      if (confirmDelete) {
        let body = {
          id: item.id,
          lastModifiedBy: sessionStorage.getItem("loginId"),
        };
        Cat_Main_Delete(body)
          .then((response) => {
            if (response.status === 200) {
              handleSearch();
              element.style.color = "#0000EE";
            } else {
              element.style.color = "#0000EE";
            }
          })
          .catch((error) => {
            console.log(error);
            element.style.color = "#0000EE";
          });
      } else {
        element.style.color = "#0000EE";
      }
    }, 100);
  };

  const handleCancel = (element) => {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(null);
      setEditCategoryName("");
      element.style.color = "#0000EE";
    }, 100);
  };

  const handleSubCat = (item, element) => {
    element.style.color = "red";
    setTimeout(() => {
      setCheckBtn(false);
      setShowSubTable(true);
      setCurrentCat(item.categoryname);
      setCurrentCatId(item.id);
      let body = {
        id: item.id,
      };
      SubCat_tableData(body)
        .then((response) => {
          if (response.status === 200) {
            setShowSubTable(true);
            setFilteredSubData(response.data.success.data);
            const check = response.data.success.data.length;
            element.style.color = "#0000EE";
            if (check > 1) {
              setCheckBtn(true);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          element.style.color = "#0000EE";
        });
    }, 50);
  };

  const handleSubAdd = (item) => {
    if (addSubValue === "") {
      alert("Enter Non Blank Sub CategoryName.");
      return;
    }
    SubCat_Add(currentCatId, addSubValue, 0, sessionStorage.getItem("loginId"))
      .then((response) => {
        if (response.status === 200) {
          alert("Details Inserted Succesfully");
          reCallSubTable();
          setAddSubValue("");
          setAddSubRow(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubAddCheck = () => {
    const confirmAdd = window.confirm(
      "Are you sure you want to continue( Adding Subcategory will make Equal Duration Distribution)?"
    );
    if (confirmAdd) {
      setAddSubRow(true);
    }
  };

  const handleSubAddCancel = () => {
    setAddSubRow(false);
  };

  const handleEditSub = (item, element) => {
    element.style.color = "red";

    setTimeout(() => {
      setAddSubRow(false);
      setEditingSub(item.subcategoryname);
      setEditSubCategoryName(item.subcategoryname);
      element.style.color = "#0000EE";
    }, 50);
  };

  const handleCancelSub = (element) => {
    element.style.color = "red";

    setTimeout(() => {
      setEditingSub(null);
      setEditSubCategoryName("");
      element.style.color = "#0000EE";
    }, 50);
  };

  const reCallSubTable = () => {
    setEditingSub(null);
    let body = {
      id: currentCatId,
      //currentCatId
    };
    SubCat_tableData(body)
      .then((response) => {
        if (response.status === 200) {
          setShowSubTable(true);
          setFilteredSubData(response.data.success.data);
          const check = response.data.success.data.length;
          if (check > 1) {
            setCheckBtn(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubUpdate = (item, element) => {
    element.style.color = "red";

    setTimeout(() => {
      element.style.color = "#0000EE";

      setTimeout(() => {
        if (item.subcategoryname === editSubCategoryName) {
          alert("No Update :Sub CategoryName already Exists.");
          element.style.color = "#0000EE";
          return;
        } else {
          SubCat_update(
            currentCatId,
            editSubCategoryName,
            item.sub_categoryid,
            sessionStorage.getItem("loginId")
          )
            .then((response) => {
              if (response.status === 200) {
                alert("Details Updated Succesfully");
                setEditingSub(null);
                reCallSubTable();
                element.style.color = "#0000EE";
              }
            })
            .catch((error) => {
              console.log(error);
              element.style.color = "#0000EE";
            });
          setEditing(null);
        }
      }, 50);
    }, 50);
  };

  const handleDeleteSub = (item, element) => {
    let confirmDelete;
    element.style.color = "red";
    setTimeout(() => {
      if (filteredSubData.length === 1) {
        confirmDelete = window.confirm("Are you sure you want to delete ...?");
      } else {
        confirmDelete = window.confirm(
          `Are you sure you want to delete (deleting Subcategory will make Equal Duration Distribution)?`
        );
      }
      if (confirmDelete) {
        let body = {
          id: item.sub_categoryid,
        };
        SubCat_delete(body)
          .then((response) => {
            if (response.status === 200) {
              alert("Record(s) Deleted Successfully.");
              reCallSubTable();
              element.style.color = "#0000EE";
            } else {
              element.style.color = "#0000EE";
            }
          })
          .catch((error) => {
            console.log(error);
            element.style.color = "#0000EE";
          });
      } else {
        element.style.color = "#0000EE";
      }
    }, 100);
  };

  const handleSubBulkDel = () => {
    let confirmDelete;
    const selectedIds = Object.keys(checkedItems).filter(
      (key) => checkedItems[key]
    );
    const selectedObjects = selectedIds.map((id) => ({ id: id }));

    if (selectedObjects.length === 0) {
      alert("Please select atleast one checkbox");
      return;
    }

    if (filteredSubData.length === 1) {
      confirmDelete = window.confirm("Are you sure you want to delete ...?");
    } else {
      confirmDelete = window.confirm(
        `Are you sure you want to delete (deleting Subcategory will make Equal Duration Distribution)?`
      );
    }
    if (confirmDelete) {
      SubCat_BulkDelete(selectedObjects)
        .then((response) => {
          if (response.status === 200) {
            alert("Record(s) Deleted Successfully.");
            reCallSubTable();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDefaultDistributionToggle = () => {
    setDefaultDistributionChecked(!defaultDistributionChecked);
    if (!defaultDistributionChecked) {
      alert(
        "Please Click on Save Icon for Default(Equal) Duration Distribution(%)."
      );
      setCheckTog(false);
      setSaveCheck(true);
      setEqualCheck(true);
      // const initialInputDurValues = {};
      // filteredSubData.forEach((item) => {
      //   initialInputDurValues[item.sub_categoryid] = "0";
      // });
      // setInputDurValues(initialInputDurValues);
    } else {
      alert(
        "Please Edit Duration Distribution(%) Column, Enter Integer Value (greater than Zero) for all records (Sum of all the values should be Equal to 100) and Click on Save Icon."
      );
      setCheckTog(true);
      setSaveCheck(true);
    }
  };

  const handleInputChange = (event, itemId) => {
    const { value } = event.target;

    if (/^\d{1,2}$/.test(value) || value === "") {
      setInputDurValues((prevState) => ({
        ...prevState,
        [itemId]: value,
      }));
    }
  };

  const handleImgSave = () => {
    if (equalCheck) {
      let body = {
        categoryId: currentCatId,
        ischecked: 1,
        updatedBy: sessionStorage.getItem("loginId"),
        subCat: Object.entries(inputDurValues).map(
          ([subCategoryId, percentageDurationDistribution]) => ({
            subCategoryId,
            percentageDurationDistribution,
          })
        ),
      };

      SubCat_ImgSave(body)
        .then((response) => {
          if (response.status === 200) {
            alert("Details Updated Successfully");
            setCheckTog(false);
            setSaveCheck(false);
            setEqualCheck(false);
            reCallSubTable();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const totalSum = Object.values(inputDurValues).reduce(
        (acc, value) => acc + Number(value),
        0
      );

      if (totalSum === 100) {
        let body = {
          categoryId: currentCatId,
          ischecked: 0,
          updatedBy: sessionStorage.getItem("loginId"),
          subCat: Object.entries(inputDurValues).map(
            ([subCategoryId, percentageDurationDistribution]) => ({
              subCategoryId,
              percentageDurationDistribution,
            })
          ),
        };

        SubCat_ImgSave(body)
          .then((response) => {
            if (response.status === 200) {
              alert("Details Updated Successfully");
              setCheckTog(false);
              setSaveCheck(false);
              reCallSubTable();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (totalSum > 100) {
        alert(
          "Sum of all the Values(Duration Distribution(%) column) should be Equal to 100."
        );
      } else {
        alert(
          "Please Enter Integer Value(greater than Zero) for Duration Distribution(%) for all records(Sum of all the values should be Equal to 100)."
        );
      }
    }
  };

  const searchCatBlur = () => {
    if (isEntered) {
      const disallowedRegex = /[=+\[\]./]/;
      if (disallowedRegex.test(addCat)) {
        alert("No special characters allowed");
        setAddCat("");
      }
    }
  };

  function handleItemEnter(e) {
    if (e.key === "Enter") {
      setIsEntered(false);
      const disallowedRegex = /[=+\[\]./]/;
      if (disallowedRegex.test(addCat)) {
        alert("No special characters allowed");
        setAddCat("");
      }
    }
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"ProcurementCat"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "0px" }}
            >
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Category Master For Procurement</a>
              </div>
            </div>

            <Grid
              container
              className={modules["mid-cont"]}
              style={{ marginLeft: "7rem" }}
            >
              <Grid
                container
                columnSpacing={{ xs: 2 }}
                style={{ alignItems: "center", marginTop: "0px" }}
              >
                <Grid
                  item
                  className={modules["label-style"]}
                  style={{ fontWeight: "bold" }}
                >
                  <strong>Instance</strong> <span>:</span>{" "}
                  <span style={{ color: "red" }}>*</span>
                </Grid>
                <Grid item>
                  <FormControl className={modules["form-control"]}>
                    <select
                      IconComponent={ArrowDropDownIcon}
                      className={modules["dropdownSelect"]}
                      value={displayvalue}
                      onChange={handleChange}
                      style={{ width: "18rem", cursor: "default" }}
                    >
                      {dropdownData.map((i) => {
                        return (
                          <option
                            style={{ fontSize: "12px" }}
                            value={i}
                            key={i}
                          >
                            {i}
                          </option>
                        );
                      })}
                    </select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  className={modules["label-style"]}
                  style={{ fontWeight: "300", alignItems: "center" }}
                >
                  Category Name
                  <span>:</span>
                </Grid>
                <Grid item>
                  <span>
                    <input
                      value={searchCat}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /[^a-zA-Z0-9 _-]/g;
                        const sanitizedValue = value.replace(regex, "");
                        setSearchCat(sanitizedValue);
                      }}
                      className={modules["input_css"]}
                      style={{
                        marginLeft: "0rem",
                        height: "1.5rem",
                        width: "18rem",
                      }}
                    />
                  </span>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={modules["custMas_btn1-cont"]}>
              <Stack direction="row" spacing={1}>
                <button className={modules["btn1"]} onClick={handleSearch}>
                  Search
                </button>
                <button className={modules["btn2"]} onClick={handleReset}>
                  Reset
                </button>
              </Stack>
            </Grid>

            <Grid
              container
              className={modules["mid-cont"]}
              style={{ marginLeft: "7rem" }}
            >
              <Grid
                container
                columnSpacing={{ xs: 2 }}
                style={{ alignItems: "center" }}
              >
                <Grid
                  item
                  className={modules["label-style"]}
                  style={{ fontWeight: "bold" }}
                >
                  Procurement Category :<span style={{ color: "red" }}>*</span>
                </Grid>

                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <span>
                      <input
                        value={addCat}
                        onChange={(e) => {
                          const value = e.target.value;

                          const allowedRegex = /^[a-zA-Z0-9_-]*$/;
                          if (allowedRegex.test(value)) {
                            setAddCat(value);
                          } else {
                            const sanitizedValue = value.replace(
                              /[^a-zA-Z0-9_-]/g,
                              ""
                            );
                            setAddCat(sanitizedValue);
                          }
                        }}
                        className={modules["input_css"]}
                        style={{
                          marginLeft: "0rem",
                          height: "1.5rem",
                          width: "20rem",
                        }}
                        onBlur={searchCatBlur}
                        onKeyDown={handleItemEnter}
                        onFocus={() => setIsEntered(true)}
                      />
                    </span>
                    <button
                      className={modules["btn2"]}
                      onClick={handleCatAdd}
                      style={{ marginTop: "0rem" }}
                    >
                      Add Category
                    </button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showTable ? (
          <>
            <Stack
              direction="row"
              sx={{
                width: "75%",
                marginLeft: showSubTable === true ? "6rem" : "0rem",
                marginBottom: "4%",
              }}
            >
              <Grid
                container
                className={`${
                  showSubTable
                    ? modules["table-grid-squished"]
                    : modules["table-grid"]
                }`}
              >
                <div
                  className={modules["table-container"]}
                  style={{ marginLeft: "1.5rem", marginTop: "2rem" }}
                >
                  <Table
                    className={modules["custom-table"]}
                    style={{ border: "2px ridge white" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr. No.</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Sub Category</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData?.map((item, index) => (
                        <TableRow key={index} className={modules["table-row"]}>
                          <TableCell
                            className={modules["table-cell"]}
                            style={{ color: "#414141" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {editing === item.categoryname ? (
                              <input
                                type="text"
                                value={editCategoryName}
                                className={modules["dropdownSelect3"]}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /[^a-zA-Z0-9 _-]/g;
                                  const sanitizedValue = value.replace(
                                    regex,
                                    ""
                                  );
                                  setEditCategoryName(sanitizedValue);
                                }}
                              />
                            ) : (
                              <div>
                                <span style={{ color: "#414141" }}>
                                  {item.categoryname}
                                </span>
                              </div>
                            )}
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {editing === item.categoryname ? (
                              <></>
                            ) : (
                              <div>
                                <a
                                  style={{
                                    cursor: "pointer",
                                    color: "#0000EE",
                                  }}
                                  onClick={(e) =>
                                    handleSubCat(item, e.currentTarget)
                                  }
                                >
                                  Sub Category
                                </a>
                              </div>
                            )}
                          </TableCell>
                          <TableCell
                            className={modules["table-cell"]}
                            sx={{ color: "#0000EE" }}
                          >
                            {editing === item.categoryname ? (
                              <>
                                <a
                                  style={{
                                    paddingRight: "1rem",
                                    cursor: "pointer",
                                    color: "#0000EE",
                                  }}
                                  onClick={(e) =>
                                    handleUpdate(item, e.currentTarget)
                                  }
                                >
                                  Update
                                </a>
                                <a
                                  style={{
                                    cursor: "pointer",
                                    color: "#0000EE",
                                  }}
                                  onClick={(e) => handleCancel(e.currentTarget)}
                                >
                                  Cancel
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  style={{
                                    paddingRight: "1rem",
                                    cursor: "pointer",
                                    color: "#0000EE",
                                  }}
                                  onClick={(e) =>
                                    handleEdit(item, e.currentTarget)
                                  }
                                >
                                  Edit
                                </a>
                                <a
                                  style={{
                                    paddingRight: "1rem",
                                    cursor: "pointer",
                                    color: "#0000EE",
                                  }}
                                  onClick={(e) =>
                                    handleDelete(item, e.currentTarget)
                                  }
                                >
                                  Delete
                                </a>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
              {showSubTable && (
                <>
                  <div style={{ marginLeft: "1rem", marginTop: "2rem" }}>
                    <Stack direction="column" sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: "10px",
                          width: "100%",
                          // marginTop: "-1% !important",
                        }}
                      >
                        Any Change(Add/Edit/Delete) in Subcategory Template will
                        not have impact on already created Procurement Subtask
                        refering to this template.Add / Delete will make
                        Default(All Equal) Duration Distribution(%).Uncheck the
                        checkbox(Default Duration Distribution) ,Input all
                        Duration Distribution(%) and Save to link subcategory
                        with Duration Distribution(%) And vice versa for Default
                        Duration Distribution.
                      </Typography>
                      <Grid container className={modules["table-grid2"]}>
                        <div className={modules["table-container2"]}>
                          <Table
                            className={modules["custom-table2"]}
                            style={{ border: "2px ridge white" }}
                          >
                            <TableHead>
                              <TableRow>
                                <th>
                                  <input
                                    type="checkbox"
                                    className={modules["custom-checkbox"]}
                                    onChange={handleMainCheckboxChange}
                                    checked={isAllChecked}
                                  />
                                </th>
                                <th>Sr. No.</th>
                                <th>Category</th>
                                <th style={{ textAlign: "left" }}>
                                  Sub <br /> Category
                                </th>
                                <th>Duration Distribution(%)</th>
                                <th>Action</th>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredSubData.map((item, index) => (
                                <TableRow
                                  key={index}
                                  className={modules["table-row"]}
                                >
                                  <TableCell>
                                    {editingSub === item.subcategoryname ? (
                                      <></>
                                    ) : (
                                      <input
                                        type="checkbox"
                                        className={modules["custom-checkbox"]}
                                        onChange={(event) =>
                                          handleIndividualCheckboxChange(
                                            event,
                                            item.sub_categoryid
                                          )
                                        }
                                        checked={
                                          !!checkedItems[item.sub_categoryid]
                                        }
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell className={modules["table-cell"]}>
                                    {editingSub === item.subcategoryname ? (
                                      <>{index + 1}</>
                                    ) : (
                                      <>{index + 1}</>
                                    )}
                                  </TableCell>
                                  <TableCell className={modules["table-cell"]}>
                                    {item.categoryname}
                                  </TableCell>
                                  <TableCell className={modules["table-cell2"]}>
                                    {editingSub === item.subcategoryname ? (
                                      <input
                                        type="text"
                                        value={editSubCategoryName}
                                        className={modules["rowinput_css2"]}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const regex = /[^a-zA-Z0-9 _-]/g;
                                          const sanitizedValue = value.replace(
                                            regex,
                                            ""
                                          );
                                          setEditSubCategoryName(
                                            sanitizedValue
                                          );
                                        }}
                                      />
                                    ) : (
                                      <div>{item.subcategoryname}</div>
                                    )}
                                  </TableCell>
                                  <TableCell className={modules["table-cell"]}>
                                    {checkTog ? (
                                      <input
                                        type="text"
                                        className={modules["rowinput_css2"]}
                                        style={{ width: "3rem" }}
                                        value={
                                          inputDurValues[item.sub_categoryid]
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            item.sub_categoryid
                                          )
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        value={
                                          item.percentagedurationdistribution ===
                                          null
                                            ? "0"
                                            : item.percentagedurationdistribution
                                        }
                                        disabled
                                        style={{ width: "3rem" }}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell className={modules["table-cell"]}>
                                    {editingSub === item.subcategoryname ? (
                                      <>
                                        <a
                                          style={{
                                            paddingRight: "1rem",
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) =>
                                            handleSubUpdate(
                                              item,
                                              e.currentTarget
                                            )
                                          }
                                        >
                                          Update
                                        </a>
                                        <br />
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) =>
                                            handleCancelSub(e.currentTarget)
                                          }
                                        >
                                          Cancel
                                        </a>
                                      </>
                                    ) : (
                                      <>
                                        <a
                                          style={{
                                            paddingRight: "1rem",
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) =>
                                            handleEditSub(item, e.currentTarget)
                                          }
                                        >
                                          Edit
                                        </a>
                                        <br />
                                        <a
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) =>
                                            handleDeleteSub(
                                              item,
                                              e.currentTarget
                                            )
                                          }
                                        >
                                          Delete
                                        </a>
                                      </>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                              {addSubRow && (
                                <TableRow className={modules["table-row"]}>
                                  <TableCell
                                    className={modules["table-cell"]}
                                  ></TableCell>
                                  <TableCell
                                    className={modules["table-cell"]}
                                  ></TableCell>
                                  <TableCell className={modules["table-cell"]}>
                                    {currentCat}
                                  </TableCell>
                                  <TableCell className={modules["table-cell2"]}>
                                    <input
                                      type="text"
                                      // style={{ width: "70%" }}
                                      className={modules["rowinput_css2"]}
                                      value={addSubValue}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const regex = /[^a-zA-Z0-9 _-]/g;
                                        const sanitizedValue = value.replace(
                                          regex,
                                          ""
                                        );
                                        setAddSubValue(sanitizedValue);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={modules["table-cell"]}
                                  ></TableCell>
                                  <TableCell className={modules["table-cell"]}>
                                    <a
                                      style={{
                                        paddingRight: "1rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleSubAdd()}
                                    >
                                      Save
                                    </a>
                                    <br />
                                    <a
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleSubAddCancel()}
                                    >
                                      Cancel
                                    </a>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </div>
                      </Grid>
                      <Grid
                        container
                        style={{
                          alignItems: "center",
                          justifyContent: "left",
                          margin: "10px",
                        }}
                      >
                        <button
                          className={modules["btn2"]}
                          style={{
                            marginRight: "10px",
                            marginTop: "0px",
                            width: "80px",
                            fontFamily: "worksans-Regular, sans-serif",
                          }}
                          onClick={handleSubAddCheck}
                        >
                          ADD
                        </button>
                        <button
                          className={modules["btn2"]}
                          style={{
                            marginRight: "10px",
                            marginTop: "0px",

                            width: "80px",
                            fontFamily: "worksans-Regular, sans-serif",
                          }}
                          onClick={handleSubBulkDel}
                        >
                          DELETE
                        </button>
                        {checkBtn && (
                          <>
                            <input
                              type="checkbox"
                              className={modules["custom-checkbox"]}
                              onChange={handleDefaultDistributionToggle}
                              checked={defaultDistributionChecked}
                            />
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "grey",
                                margin: "4px",
                                fontFamily: "worksans-Regular, sans-serif",
                              }}
                            >
                              Default Duration Distribution
                            </Typography>
                          </>
                        )}
                        {saveCheck && (
                          <img
                            src={save}
                            alt=""
                            style={{
                              cursor: "pointer",
                              marginLeft: "1.5%",
                              width: "20px",
                              height: "20px",
                            }}
                            onClick={handleImgSave}
                          />
                        )}
                      </Grid>
                    </Stack>
                  </div>
                </>
              )}
            </Stack>
          </>
        ) : failedCheck ? (
          <>
            <div
              style={{
                color: "black",
                border: "1px solid black",
                padding: "8px",
                fontSize: "15px",
                marginLeft: "6rem",
              }}
            >
              No Records found
            </div>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default ProcurementCategory;
