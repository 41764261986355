import React, { useEffect, useState } from "react";
// import modules from "./RiskAndMitigationPlan.module.css";
import modules from "./../ccpm.module.css";
import Home from "../../../home/Home";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Stack,
  Typography,
  Popover,
  Paper,
} from "@mui/material";
import bread from "../../../Assets/bread.png";
import SampleSidebar from "../../../Landing/SampleSidebar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";
import {
  RMP_RiskTypeD,
  RMP_RiskCatD,
  RMP_TriggerStatusD,
  RMP_InsertRisk,
  GetPCInstances,
  GetPCBindProjectName,
  GetRiskReviewSearch,
  GetRiskReviewUpdate,
} from "../../../Services/CCPM";
import loading from "../../../Assets/Loading_image.gif";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import "../ProjectPlan/CalendarStyles.css";
import * as XLSX from "xlsx";
import ExcelJS from 'exceljs';




export default function RiskReview() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projId = searchParams.get("projId");
  const [isLoading, setIsLoading] = useState(false);
  const [checkAdd, setCheckAdd] = useState(false);
  const [selectedRiskType, setSelectedRiskType] = useState("");
  const [dropDownRiskType, setDropDownRiskType] = useState([]);
  const [editing, setEditing] = useState(null);

  const [selectedRiskCat, setSelectedRiskCat] = useState("");
  const [dropDownRiskCat, setDropDownRiskCat] = useState([]);
  const [selectedTriggerStatus, setSelectedTriggerStatus] = useState("");
  const [dropDownTriggerStatus, setDropDownTriggerStatus] = useState([]);
  const [checkMult, setCheckMult] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [probValue, setProbValue] = useState("");
  const [impactValue, setImpactValue] = useState("");
  const [likelyDate, setLikelyDate] = useState("");
  const [displayData, setDisplaydate] = useState("");
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [riskDesc, setRiskDesc] = useState("");
  const [selectedInstance, setSelectedInstance] = useState("");
  const [instances, setInstances] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [projects, setProjects] = useState([]);
  const [tableData, setTableData] = useState({});
  const [upRiskType, setUpRiskType] = useState("");
  const [upRiskDesc, setUpRiskDesc] = useState("");
  const [upProb, setUpProb] = useState("");
  const [upImpact, setUpImpact] = useState("");
  const [upStatus, setUpStatus] = useState("");
  const [upRiskCat, setUpRiskCat] = useState("");
  const [upRiskId, setUpRiskId] = useState("");

  const open2 = Boolean(anchorEl2);
  const addRow = () => {
    setCheckAdd(true);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const riskTypeResponse = await RMP_RiskTypeD();
      if (riskTypeResponse.status === 200) {
        setDropDownRiskType(riskTypeResponse?.data?.success?.data);
      }

      const riskCatResponse = await RMP_RiskCatD();
      if (riskCatResponse.status === 200) {
        setDropDownRiskCat(riskCatResponse?.data?.success?.data);
      }

      const triggerStatusResponse = await RMP_TriggerStatusD();
      if (triggerStatusResponse.status === 200) {
        setDropDownTriggerStatus(triggerStatusResponse?.data?.success?.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: "short" }).slice(0, 2);
  };
  const today = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedToday = today.toLocaleDateString("en-US", options);

  const handleCalendar2n = (date) => {
    setAnchorEl2(null);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    // .replace(/ /g, "-");
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setLikelyDate(date);
    setDisplaydate(formattedDate);
  };

  const handleInsertRisk = async () => {
    setIsLoading(true);
    try {
      let body = {
        projectsId: parseInt(projId),
        riskTypeId: parseInt(selectedRiskType),
        risk: riskDesc,
        riskCategory: parseInt(selectedRiskCat),
        probability: parseInt(probValue),
        impact: parseInt(impactValue),
        riskDate: likelyDate,
        uid: parseInt(sessionStorage.getItem("loginId")),
        status: parseInt(selectedTriggerStatus),
      };
      const response = await RMP_InsertRisk(body);
      console.log(response);
      if (response.status === 200) {
        console.log(response);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      projectId: selectedProjectName,
    };

    GetRiskReviewSearch(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "table data");
          setTableData(response?.data?.success?.data);
          setShowResult(true);
        } else if (response?.response?.data?.statusCode === 404) {
          setTableData({});
          setShowResult(true);
        } else {
          setTableData({});
          setShowResult(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const UpdateRisk = async () => {
    setIsLoading(true);
    console.log(upRiskDesc, "upRiskcwev");

    try {
      let body = {
        riskId: parseInt(upRiskId),
        revRiskCategory: parseInt(upRiskCat),
        revProbability: parseInt(upProb),
        revImpact: parseInt(upImpact),
        reviewRemark: upRiskDesc ? upRiskDesc : " ",
        status: parseInt(upStatus),
        reviewBy: parseInt(sessionStorage.getItem("loginId")),
      };
      const response = await GetRiskReviewUpdate(body);
      if (response.status === 200) {
        alert("Details Updated Successfully");
        setEditing(null);
        handleSearch();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setEditing(null);
    setUpRiskDesc("");
    setUpRiskCat("");
    setUpProb("");
    setUpImpact("");
    setUpStatus("");
  };

  const handleEdit = (item) => {
    const [N_riskcategory] = dropDownRiskCat
      .filter((category) => category.riskcatname === item.revisedriskcategory)
      .map((category) => category.riskcatid);

    // console.log(N_riskcategory, "N_riskcategory");
    // console.log(item.revisedriskcategory, "revisedriskcategory");

    const [N_status] = dropDownTriggerStatus
      .filter((status) => status.triggerstatus === item.triggerstatus)
      .map((status) => status.triggerid);

    console.log(item.reviewremark, "reviewremark");

    setEditing(item.riskid);
    setUpRiskId(item.riskid);
    setUpProb(item.revisedprobability);
    setUpImpact(item.revisedimpact);
    setUpStatus(N_status);
    setUpRiskDesc(item.reviewremark);
    setUpRiskCat(N_riskcategory);
  };

  const handlePopup = (item, element) => {
    // Change color to yellow
    element.style.color = "red";

    // Open the popup
    window.open(
      `/ccpm/RiskHistoryPopup?PrjRiskId=${item.riskid}`,
      "sharer",
      "toolbar=0,status=0,width=1050,height=600"
    );

    // Revert color back to original after a short delay
    setTimeout(() => {
      element.style.color = "#0000EE";
    }, 100); // Adjust the delay as needed
  };

  const exportToExcel = async (tableData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Risk Report');

    // Add main headings with merged cells
    worksheet.addRow([
        "", "", "", "", "",
        "Original Risk Rating", "", "", "",
        "Action Detail",
        "", "", "", "",
        "Revised Risk Rating", "", ""
    ]);

    worksheet.mergeCells('A1:E1'); // ABC
    worksheet.mergeCells('F1:I1'); // Original Risk Rating
    worksheet.mergeCells('J1:N1'); // Action Detail
    worksheet.mergeCells('O1:R1'); // Revised Risk Rating
    worksheet.mergeCells('S1:T1'); // BC

    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('A1').font = { bold: true };

    worksheet.getCell('F1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('F1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'b3d1ff' }
    };
    worksheet.getCell('F1').font = { bold: true };

    worksheet.getCell('J1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('J1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '70db70' }
    };
    worksheet.getCell('J1').font = { bold: true };

    worksheet.getCell('O1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('O1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffb3ff' }
    };
    worksheet.getCell('O1').font = { bold: true };

    // Add column headers
    const headerRow = worksheet.addRow([
        "Sr. No.", "Project Name", "Project Manager", "Risk", "Likely Date",
        "Risk Category", "Probability", "Impact", "Priority Ranking",
        "Mitigation Action Plan", "Action By Date", "Action Assigned To", "Action Taken Remark", "Action Taken On",
        "Revised Risk Category", "Revised Probability", "Revised Impact", "Revised Priority Ranking",
        "Status", "Review Remark"
    ]);

    // Set the width for columns to fit the content
    worksheet.columns = [
        { width: 7 },  // Sr. No.
        { width: 30 }, // Project Name
        { width: 25 }, // Project Manager
        { width: 30 }, // Risk
        { width: 15 }, // Likely Date
        { width: 15 }, // Risk Category
        { width: 13 }, // Probability
        { width: 13 }, // Impact
        { width: 13 }, // Priority Ranking
        { width: 30 }, // Mitigation Action Plan
        { width: 20 }, // Action By Date
        { width: 25 }, // Action Assigned To
        { width: 20 }, // Action Taken Remark
        { width: 15 }, // Action Taken On
        { width: 20 }, // Revised Risk Category
        { width: 20 }, // Revised Probability
        { width: 20 }, // Revised Impact
        { width: 20 }, // Revised Priority Ranking
        { width: 18 }, // Status
        { width: 35, style: { alignment: { wrapText: true } } }  // Review Remark
    ];

    // Make header row bold and apply bold borders
    headerRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.border = {
            top: { style: 'thick' },
            bottom: { style: 'thick' },
            left: { style: 'thick' },
            right: { style: 'thick' }
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    });

    // Add data rows
    tableData.RiskDetails.forEach((item, index) => {
        // Combine action details into single cell values
        const actionDetails = tableData[item.riskid] || [];
        const combinedMitigationActionPlan = actionDetails.map(action => action.mitigationactionplan).filter(Boolean).join('\n');
        const combinedActionByDate = actionDetails.map(action => action.actionbydate).filter(Boolean).join('\n');
        const combinedActionAssignedTo = actionDetails.map(action => action.actionassignedto).filter(Boolean).join('\n');
        const combinedActionTakenRemark = actionDetails.map(action => action.actiontakenremark).filter(Boolean).join('\n');
        const combinedActionTakenOn = actionDetails.map(action => action.actiontakenon).filter(Boolean).join('\n');

        const mainRow = [
            index + 1,                           // Sr. No.
            item.proname,                        // Project Name
            item.projectmanager,                 // Project Manager
            item.risks,                          // Risk
            item.likelydate,                     // Likely Date
            item.riskcategory,                   // Risk Category
            item.probability,                    // Probability
            item.impact,                         // Impact
            item.priorityranking,                // Priority Ranking
            combinedMitigationActionPlan,        // Mitigation Action Plan
            combinedActionByDate,                // Action By Date
            combinedActionAssignedTo,            // Action Assigned To
            combinedActionTakenRemark,           // Action Taken Remark
            combinedActionTakenOn,               // Action Taken On
            item.revisedriskcategory || "",      // Revised Risk Category
            item.revisedprobability || "",       // Revised Probability
            item.revisedimpact || "",            // Revised Impact
            item.revisedpriorityranking || "",   // Revised Priority Ranking
            item.triggerstatus,                  // Status
            item.reviewremark,                   // Review Remark
        ];

        // Add the main row and apply bold borders
        const row = worksheet.addRow(mainRow);

        // Apply bold borders and bold font to all cells
        row.eachCell({ includeEmpty: true }, (cell) => {
            cell.border = {
                top: { style: 'thick' },
                bottom: { style: 'thick' },
                left: { style: 'thick' },
                right: { style: 'thick' }
            };
            cell.font = { bold: true };
            // Wrap text in cells to fit multiline content
            cell.alignment = { wrapText: true };
        });

        // Adjust row height to fit the content if necessary
        row.height = 70;
    });

    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Risk_Report.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};




return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div style={{ overflowX: "auto", height: "100vh" }}>
        <Grid container>
          <Grid item lg={0.7} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>
          <Grid item lg={12}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"RiskReview"} />
            </Grid>

            <Grid item lg={12}>
              <div className={modules["RM_div"]}>
                <div
                  className={modules["pro-bread"]}
                  style={{ marginLeft: "-3.5rem" }}
                >
                  <a>Risk Management</a>
                  <img src={bread} alt="" />
                  <a>Risk Review</a>
                </div>
              </div>

              <Grid container className={modules["mid-contRMP"]}>
                <div className={modules["headingFontBoldRM"]}
                  style={{ width: "87%" }}>
                  Risk & Mitigation Review
                </div>

                <div
                  container
                  className={modules["border_contPSCD"]}
                  style={{ width: "85%" }}
                // ref={parentRef}
                >
                  <table style={{ width: '100%' }} className={modules["PCtable"]}>
                    <tbody style={{ width: '100%' }}  >
                      <tr
                        style={{
                          padding: "10px 55px",
                          gap: "15rem",
                          justifyContent: "center",
                        }}
                      >
                        <td className={modules["table_data1"]}>
                          <span style={{ width: "70px" }}>
                            Instance :<label style={{ color: "Red" }}>*</label>
                          </span>
                          <select
                            value={selectedInstance}
                            onChange={(e) => {
                              setSelectedInstance(e.target.value);
                              getProjectDetails(e.target.value);
                            }}
                            className={modules["RiskSelect"]}
                          >
                            {instances?.map((instance) => (
                              <option key={instance.name} value={instance.id}>
                                {instance.name}
                              </option>
                            ))}
                          </select>
                        </td>

                        <td className={modules["table_data1"]}>
                          <span style={{ width: "70px" }}>Project :</span>
                          <select
                            value={selectedProjectName}
                            onChange={(e) =>
                              setSelectedProjectName(e.target.value)
                            }
                            className={modules["RiskSelect"]}
                          // style={{ width: "200px" }}
                          >
                            {projects ? (
                              <>
                                <option value={0}>SELECT</option>
                                {projects.map((p_name) => (
                                  <option
                                    key={p_name.projectname}
                                    value={p_name.id}
                                  >
                                    {p_name.projectname}
                                  </option>
                                ))}
                              </>
                            ) : (
                              <option value={0}></option>
                            )}
                          </select>
                        </td>
                      </tr>

                      <tr
                        style={{
                          justifyContent: "center",
                          gap: "10px",

                          paddingBottom: "10px",
                        }}
                      >
                        <button
                          style={{
                            height: "26px",
                            width: "80",
                            padding: "0px 15px ",
                          }}
                          className={modules["PCbtn"]}
                          onClick={handleSearch}
                        >
                          Search
                        </button>
                        {/* <button className={modules["PCbtn"]}>Reset</button>
                        <button className={modules["PCbtn"]}>
                          Export To Excel
                        </button> */}
                      </tr>
                    </tbody>
                  </table>
                </div>

                {showResult &&
                  (tableData?.RiskDetails?.length > 0 ? (
                    <>
                      <Grid
                        container
                        sx={{ marginLeft: "6rem", padding: "8px",paddingTop:'1.5rem' }}
                      >
                        <div className={modules["ccpm_table-containerIAMRR"]}>
                          <Table className={modules["RR_custom-table"]}>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  colSpan={5}
                                  sx={{
                                    backgroundColor: "lightgray !important",
                                    fontWeight: "normal !important",
                                    color: "#414141 !important",
                                    fontSize: "12px !important",
                                    width: '815px !important'
                                  }}
                                ></TableCell>
                                <TableCell
                                  colSpan={4}
                                  sx={{
                                    backgroundColor: "LightSteelBlue !important",
                                    fontWeight: "normal !important",
                                    color: "#414141 !important",
                                    fontSize: "12px !important",
                                    textAlign: "center",
                                    width: '385px !important'
                                  }}
                                >
                                  Original Risk Rating
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  sx={{
                                    backgroundColor: "LightGreen !important",
                                    fontWeight: "normal !important",
                                    color: "#414141 !important",
                                    fontSize: "12px !important",
                                    width: '720px !important'
                                  }}
                                >
                                  Action Detail
                                </TableCell>
                                <TableCell
                                  colSpan={4}
                                  sx={{
                                    backgroundColor: "LightPink !important",
                                    fontWeight: "normal !important",
                                    color: "#414141 !important",
                                    fontSize: "12px !important",
                                    width: '399px !important'
                                  }}
                                >
                                  Revised Risk Rating
                                </TableCell>
                                <TableCell
                                  colSpan={4}
                                  sx={{
                                    backgroundColor: "lightgray !important",
                                    fontWeight: "normal !important",
                                    color: "#414141 !important",
                                    fontSize: "12px !important",
                                    width: '615px !important'
                                  }}
                                ></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell sx={{ width: "51px" }}>Sr. No.</TableCell>
                                <TableCell sx={{ width: "100px", lineHeight: "normal !important" }}>
                                  Project Name
                                </TableCell>
                                <TableCell sx={{ width: "60px", lineHeight: "normal !important" }}>
                                  Project Manager
                                </TableCell>
                                <TableCell sx={{ width: "100px" }}>Risk</TableCell>
                                <TableCell sx={{ lineHeight: "normal !important" }}>Likely Date</TableCell>
                                <TableCell sx={{ lineHeight: "normal !important" }}>Risk Category</TableCell>
                                <TableCell sx={{ width: "60px" }}>Probability</TableCell>
                                <TableCell sx={{ width: "160px" }}>Impact</TableCell>
                                <TableCell sx={{ lineHeight: "normal !important" }}>Priority Ranking</TableCell>
                                <TableCell sx={{ width: "60px" }}></TableCell>
                                <TableCell sx={{ lineHeight: "normal !important" }}>Risk Category</TableCell>
                                <TableCell sx={{ width: "60px" }}>Probability</TableCell>
                                <TableCell sx={{ width: "160px" }}>Impact</TableCell>
                                <TableCell sx={{ lineHeight: "normal !important" }}>Priority Ranking</TableCell>
                                <TableCell sx={{ width: "60px" }}>Status</TableCell>
                                <TableCell
                                  sx={{
                                    whiteSpace: "normal !important",
                                    wordWrap: "break-word",
                                    width: "160px",
                                    lineHeight: "normal !important",
                                    overflow: "hidden",
                                  }}
                                >
                                  Review Remark
                                </TableCell>
                                <TableCell sx={{ width: "60px" }}>Edit</TableCell>
                                <TableCell sx={{ lineHeight: "normal !important" }}>Risk History</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tableData?.RiskDetails?.map((item, index) => (
                                <TableRow key={index} sx={{
                                  verticalAlign: 'top !important',
                                }}>
                                  <TableCell >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell >
                                    {item.proname}
                                  </TableCell>
                                  <TableCell >
                                    {item.projectmanager}
                                  </TableCell>
                                  <TableCell >
                                    {item.risks}
                                  </TableCell>
                                  <TableCell >
                                    {item.likelydate}
                                  </TableCell>
                                  <TableCell >
                                    {item.riskcategory}
                                  </TableCell>
                                  <TableCell >
                                    {item.probability}
                                  </TableCell>
                                  <TableCell >
                                    {item.impact}
                                  </TableCell>
                                  <TableCell >
                                    {item.priorityranking}
                                  </TableCell>
                                  <TableCell >
                                    {tableData?.[item.riskid]?.length > 0 && (
                                      <Table
                                        className={modules["custom-table"]}
                                        style={{
                                          width: "60%",
                                          border: "1px solid #a4bf47",
                                        }}
                                      >
                                        <TableHead>
                                          <TableRow >
                                            <TableCell>
                                              MitigationActionPlan
                                            </TableCell>
                                            <TableCell>ActionByDate</TableCell>
                                            <TableCell>
                                              ActionAssignedTo
                                            </TableCell>
                                            <TableCell>
                                              ActionTakenRemark
                                            </TableCell>
                                            <TableCell>ActionTakenOn</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {tableData?.[item.riskid]?.map(
                                            (itm, idx) => (
                                              <TableRow key={idx} sx={{ color: "#414141" }}>
                                                <TableCell>
                                                  {itm.mitigationactionplan}
                                                </TableCell>
                                                <TableCell>
                                                  {itm.actionbydate}
                                                </TableCell>
                                                <TableCell>
                                                  {itm.actionassignedto}
                                                </TableCell>
                                                <TableCell>
                                                  {itm.actiontakenremark}
                                                </TableCell>
                                                <TableCell>
                                                  {itm.actiontakenon}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    )}
                                  </TableCell>
                                  <TableCell >
                                    {editing === item.riskid ? (
                                      <select
                                        name="cars"
                                        id="cars"
                                        style={{ width: "146px" }}
                                        value={upRiskCat}
                                        onChange={(e) =>
                                          setUpRiskCat(e.target.value)
                                        }
                                        className={modules["dropdownSelect_RR"]}
                                      >
                                        {dropDownRiskCat?.length > 0 &&
                                          dropDownRiskCat?.map((i) => (
                                            <option
                                              key={i.riskcatid}
                                              value={i.riskcatid}
                                              style={{ color: "#373736" }}
                                            >
                                              {i.riskcatname}
                                            </option>
                                          ))}
                                      </select>
                                    ) : (
                                      <>{item.revisedriskcategory}</>
                                    )}
                                  </TableCell>
                                  <TableCell >
                                    {editing === item.riskid ? (
                                      <input
                                        type="text"
                                        className={modules["txtboxRMP"]}
                                        value={upProb}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (/^[1-5]?$/.test(value)) {
                                            // Allows only digits from 1 to 5, or empty string
                                            setUpProb(value);
                                          }
                                        }}
                                        maxLength="1"
                                      />
                                    ) : (
                                      <> {item.revisedprobability} </>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {editing === item.riskid ? (
                                      <input
                                        type="text"
                                        value={upImpact}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (/^[1-5]?$/.test(value)) {
                                            setUpImpact(value);
                                          }
                                        }}
                                        className={modules["txtboxRMP"]}
                                        maxLength="1"
                                        pattern="\d*"
                                      />
                                    ) : (
                                      <>{item.revisedimpact}</>
                                    )}
                                  </TableCell>
                                  <TableCell >
                                    {item.revisedpriorityranking}
                                  </TableCell>
                                  <TableCell >
                                    {editing === item.riskid ? (
                                      <select
                                        name="cars"
                                        id="cars"
                                        style={{ width: "102px" }}
                                        value={upStatus}
                                        onChange={(e) => {
                                          // setCheckMult(true);
                                          setUpStatus(e.target.value);
                                        }}
                                        className={modules["dropdownSelect_RR"]}
                                      >
                                        {dropDownTriggerStatus?.length > 0 &&
                                          dropDownTriggerStatus?.map((i) => (
                                            <option
                                              key={i.triggerid}
                                              value={i.triggerid}
                                              style={{ color: "#373736" }}
                                            >
                                              {i.triggerstatus}
                                            </option>
                                          ))}
                                      </select>
                                    ) : (
                                      <>{item.triggerstatus}</>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {editing === item.riskid ? (
                                      <>
                                        <textarea
                                          rows="2"
                                          cols="20"
                                          value={upRiskDesc}
                                          onChange={(e) => setUpRiskDesc(e.target.value)}
                                        />
                                        <span
                                          style={{
                                            color: "#FF3300",
                                            visibility: "hidden",
                                          }}
                                        >
                                          *
                                        </span>
                                      </>
                                    ) : (
                                      <div style={{ overflowX: "hidden" }}>
                                        {item.reviewremark}
                                      </div>

                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {editing === item.riskid ? (
                                      <>
                                        <button
                                          style={{ width: "75px", height: '30px' }}
                                          className={modules["btn2_RMP"]}
                                          onClick={UpdateRisk}
                                        >
                                          Update
                                        </button>
                                        <button
                                           style={{ width: "75px", height: '30px' }}
                                          className={modules["btn2_RMP"]}
                                          onClick={handleCancel}
                                        >
                                          Cancel
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        style={{ width: "75px", height: '30px' }}
                                        className={modules["btn2_RMP"]}
                                        onClick={() => handleEdit(item)}
                                      >
                                        Edit
                                      </button>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <a
                                      className={modules["textRMP"]}
                                      onClick={(e) => {
                                        handlePopup(item, e.currentTarget);
                                      }}
                                    >
                                      History
                                    </a>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </div>
                        <button className={modules["ExportBtn"]}
                          onClick={() => exportToExcel(tableData)}
                        >
                          Export To Excel
                        </button>
                      </Grid>
                    </>
                  ) : (
                    <div
                      style={{
                        color: "#ff0000",
                        marginLeft: "7rem",
                        fontSize: "12px",
                        marginTop: "20px",
                      }}
                    >
                      No Records Found
                    </div>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
