import { useState, useLayoutEffect, useRef } from "react";
import {
  Network,
  Options,
  Data,
  Edge,
  Node
} from "vis-network/standalone/esm/vis-network";



export default (props) => {
  const { edges, nodes, options } = props;

  const [network, addNetwork] = useState(null);
  const ref = useRef(null);

  const data = { nodes, edges };
useLayoutEffect(() => {
  if (ref.current && data.nodes.length > 0 ) {
    console.log("data",data)
    const instance = new Network(ref.current, data, options);
    console.log("instance",instance)
    instance.fit({
      // Set the options to fit the network
      animation: {
        duration: 1000, // Animation duration in ms
        easingFunction: 'easeInOutQuad' // Animation easing function
      },
    });
    instance.moveTo({
      scale: 0.6             // Zooms out; 1 is no zoom
    });
    addNetwork(instance);
  }
  return () => network?.destroy();
}, [data?.nodes?.length]);  

  return {
    network,
    ref
  };
};
