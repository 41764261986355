import React from "react";
import { Diversity1, Grade } from "@mui/icons-material";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Sidebar from "../../../Landing/Navbar";
import bread from "../../../Assets/bread.png";
import modules from "./MAUCMaterialThreshold.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  MCW_GetThreholdBindGridHandler, MCW_OnRowUpdatingThresholdHandler,
  MCW_OnRowDeletionThresholdHandler, DS_GetAllInstanceHandler
} from "../../../Services/Dss";
import SampleSidebar from "../../../Landing/SampleSidebar";

export default function MaterialThreshold() {
  const [dropdownData, setDropdownData] = useState([]);

  const [displayvalue, setDisplayValue] = useState('');
  const [displayTable, setDisplayTable] = useState(false);



  const [pageStates, setpageStates] = useState({
    tableData: [],
    editStates: [],
    updateStates: []
  });



  const handleSubmit = () => {
    MCW_GetThreholdBindGridHandler(displayvalue).then(res => {
      if (res.status === 200) {
        //console.log(res?.data?.success?.data);
        setDisplayTable(true);
        setpageStates({
          tableData: res?.data?.success?.data,
          editStates: res?.data?.success?.data.map((i) => { return false }),
          updateStates: res?.data?.success?.data
        });
      }
    }).catch(err => {
      console.log('Error fetching data:', err);
    })
  };

  useEffect(() => {
    DS_GetAllInstanceHandler('pritpwc').then(res => {
      if (res.status === 200) {
        setDropdownData(res.data);
        setDisplayValue(res.data[0]?.instanceuid)
      } else if (res?.response?.status === 400) {
        alert(res?.response?.data);
      }
    })
  }, []);

  function UpdatedValuesHandler(index, key, e) {
    setpageStates((prevSubmitStates) => {
      const updatedUpdateStates = pageStates.updateStates.map((item, idx) => {
        return index === idx ? { ...item, [key]: e } : item;
      });

      return {
        ...prevSubmitStates,
        updateStates: updatedUpdateStates,
      };
    });
  }





  function handleDeleteSingleEntry(index) {
    const body = {
      instanceUID: displayvalue,
      categorycode: pageStates.tableData[index].categorycode
    }
    if (window.confirm('do you want to delete')) {
      MCW_OnRowDeletionThresholdHandler(body).then(res => {
        if (res.status === 200) {
          alert(res.data?.success?.message);
          handleSubmit();
        } else if (res.response.status === 400) {
          alert(res?.response?.data?.error?.message);
        }
      }).catch(err => {
        console.log('Error fetching data:', err);
      })
    }
  }


  function handleSaveEdit(index) {
    const body = {
      instanceUID: displayvalue,
      loginID: 'pwc',
      categorycode: pageStates.updateStates[index].categorycode,
      yellow_start: pageStates.updateStates[index].yellow_start,
      yellow_end: pageStates.updateStates[index].yellow_end,
      red_end: pageStates.updateStates[index].red_end
    }
    MCW_OnRowUpdatingThresholdHandler(body).then(res => {
      if (res.status === 200) {
        let status = res.data.statusCode;
        if (status === 200) {
           const msg = res.data?.success?.message;
          //  msg = msg.replace(/\\/g, '');  // Remove all backslashes
          //  msg = msg.replace(/n/g, '\n');  // Replace all occurrences of 'n' with a newline character
          
           alert(`${msg}`);
          handleSubmit();
        } else if (status === 500) {
          alert(res.data?.error?.message.split(':')[0]);
        }
      } else if (res?.response?.status === 400) {
        let msg=res.response?.data?.error?.message.replace(/\\n/g, '\n'); 
        alert(msg);
      }
    }).catch(err => {
      console.log('Error fetching data:', err);
    })
  }



  function ArrowLine() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "2px",
            height: "30px",
            backgroundColor: "grey",
            marginRight: "2px",
          }}
        />
        <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
      </div>
    );
  }



  return (
    <>
      <Grid container>
        <Grid
          item
          lg={0.6}
          sx={{ position: "fixed", zIndex: 2000 }}
          className={modules["main-cont"]}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12}>
            <Home />
          </Grid>
        </Grid>
        <Grid item lg={9} className={modules["datasource_div"]}>
          <div className="pro-bread" style={{ marginLeft: "5rem" }}>
            <p>
              <a>DSS MAUC</a>
              <img src={bread} alt="" />
              <a>Data Material Category Wise Threshold</a>
            </p>
          </div>
        </Grid>

        <Grid container sx={{ marginLeft: "5rem", marginTop: "2rem" }}>
          <table>
            <tbody>
              <tr>
                <td>
                  DSS Instance<span style={{ color: "red" }}>*</span>
                </td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={displayvalue}
                      onChange={(e) => setDisplayValue(e.target.value)}
                    >
                      {dropdownData.map((i) => {
                        return (
                          <MenuItem value={i.instanceuid} key={i.instanceuid}>
                            {i.instanceuid}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>

        <div>
          <button
            style={{
              marginLeft: "13.5rem",
              marginTop: "1.2rem",
              padding: "0.5cm",
              marginBottom: "2rem",
            }}
            type="button"
            className={modules["btn1"]}
            onClick={handleSubmit}
          >
            Populate
          </button>
        </div>
      </Grid>

      {displayTable && (
        <Grid sx={{ marginLeft: "5rem", marginTop: "2rem", marginBottom: '2rem' }}>
          {
            pageStates.tableData?.length > 0 ?
              <TableContainer component={Paper} style={{ marginTop: "20px", width: "80%" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className={modules['customTable']}>
                  <TableHead className="table_head">
                    <TableRow
                      sx={{
                        "& th": {
                          color: "#fff",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <TableCell align="center">Material Code</TableCell>
                      <TableCell align="center">Yellow Start</TableCell>
                      <TableCell align="center">Yellow End</TableCell>
                      <TableCell align="center"> Red End</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {pageStates.tableData?.map((i, index) => {
                      return (
                        <TableRow
                          key={i.id}
                          sx={{
                            textAlign: "center",
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell scope="row">
                            {i.categorycode}
                          </TableCell>

                          <TableCell align="center">
                            {pageStates.editStates[index] ? (
                              <input
                                type="text"
                                value={pageStates.updateStates[index].yellow_start}
                                onChange={(e) => UpdatedValuesHandler(index, 'yellow_start', e.target.value)}
                                className={modules['customInp']}
                              />
                            ) : (
                              <div
                                sx={{
                                  color: "#0000EE",
                                  textDecoration: "underline",
                                }}
                              >
                                {i.yellow_start}
                              </div>
                            )}
                          </TableCell>

                          <TableCell align="center">
                            {pageStates.editStates[index] ? (
                              <input
                                type="text"
                                value={pageStates.updateStates[index].yellow_end}
                                onChange={(e) => UpdatedValuesHandler(index, 'yellow_end', e.target.value)}
                                className={modules['customInp']}
                              />
                            ) : (
                              <div
                                sx={{
                                  color: "#0000EE",
                                  textDecoration: "underline",
                                }}
                              >
                                {i.yellow_end}
                              </div>
                            )}
                          </TableCell>

                          <TableCell align="center">
                            {pageStates.editStates[index] ? (
                              <input
                                type="text"
                                value={pageStates.updateStates[index].red_end}
                                onChange={(e) => UpdatedValuesHandler(index, 'red_end', e.target.value)}
                                className={modules['customInp']}
                              />
                            ) : (
                              <div
                                sx={{
                                  color: "#0000EE",
                                  textDecoration: "underline",
                                }}
                              >
                                {i.red_end}
                              </div>
                            )}
                          </TableCell>


                          <TableCell
                            className="editlink"
                            align="left"
                            sx={{ color: "#0000EE" }}
                          >
                            {pageStates.editStates[index] ? (
                              <>
                                <a onClick={() => handleSaveEdit(index)} style={{ marginRight: '1rem' }}>Update</a>
                                <a
                                  onClick={() => {
                                    const updatedEditState = [...pageStates.editStates];
                                    updatedEditState.splice(index, 1, !pageStates.editStates[index]);
                                    setpageStates({
                                      ...pageStates,
                                      editStates: updatedEditState
                                    })
                                  }
                                  }>Cancel</a>
                              </>
                            ) : (
                              <>
                                <a
                                  style={{ paddingRight: "1rem" }}
                                  onClick={() => {
                                    const updatedEditState = [...pageStates.editStates];
                                    updatedEditState.splice(index, 1, !pageStates.editStates[index]);
                                    setpageStates({
                                      ...pageStates,
                                      editStates: updatedEditState
                                    })
                                  }
                                  }
                                >
                                  Edit
                                </a>
                                <a onClick={() => handleDeleteSingleEntry(index)}>
                                  Delete
                                </a>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    {/* {isAddRowVisible && (
                 <TableRow>
                   <TableCell padding="checkbox"></TableCell>
                   <TableCell component="th" scope="row">
                     {data?.length + 1}
                   </TableCell>
                   <TableCell colSpan={2} align="center">
                     <input
                       className="newinput"
                       type="text"
                       value={newRoleName}
                       onChange={(e) => setNewRoleName(e.target.value)}
                     />
                   </TableCell>
                   <TableCell align="left">
                     <TableCell>
                       <a
                         style={{
                           color: "blue",
                           textDecoration: "underline",
                         }}
                         onClick={() => handleSave()}
                       >
                         Save
                       </a>
                     </TableCell>
                     <TableCell>
                       <a
                         style={{
                           color: "blue",
                           textDecoration: "underline",
                         }}
                         onClick={() => handleClose()}
                       >
                         Close
                       </a>
                     </TableCell>
                   </TableCell>
                 </TableRow>
               )} */}
                  </TableBody>
                </Table>
              </TableContainer> :
              <div style={{ borderBottom: '2px solid #a4bf47', width: 'fit-content', paddingBottom: '5px', fontSize: '14px', marginLeft: '2rem' }}>
                No records has been added
              </div>
          }
        </Grid>
      )}
    </>
  );
}
