import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import ActiveProjectTable from "../ActiveProjects/ActiveProjectTab";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import modules from "./ActiveProjects.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import loading from "../../../Assets/Loading_image.gif";
import { WL_GetDropdown } from "../../../Services/CCPM";
import {
  getProjectName,
  getProjectManagers,
  getAttributesDetailsBasedOnInstance,
  searchActiveProjectDetails,
} from "../../../Services/Activeproject";
import { MyProjGetAttributes } from "../../../Services/CCPM";
import { json } from "react-router-dom";

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}
const AccessType = [
  {
    value: "PM",
    name: "Where I am Project Manager",
  },
  {
    value: "APM",
    name: "Where I am Alternate PM",
  },
  {
    value: "LPM",
    name: "Where My L is Project Manager",
  },
  {
    value: "LAPM",
    name: "Where My L is Alternate PM",
  },
];

const ActiveProjects = () => {
  const [searchValue, setSearchValue] = useState("");
  const [dropdownData, setDropdownData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);
  const [isLoading, setIsLoading] = useState(false);

  //Instance dropdown
  const [Instancevalue, setInstanceValue] = useState([]);
  const [Instanaceoptions, setInstanceOptions] = useState([]);
  //project name dropdown
  const [projectnamevalue, setProjectNameValue] = useState([]);
  const [options, setOptions] = useState([]);
  //project manager dropdown
  const [projectManagervalue, setProjectManagerValue] = useState([]);
  const [projectManageroptions, setProjectManagerOptions] = useState([]);
  //access type
  const [accesstype, setAccessType] = useState([]);
  const [value, setValue] = useState({});

  const [headers, setHeaders] = useState([]);
  const [dropdownss, setDropdownss] = useState([]);
  const [showTable, setShowTable] = useState(false);
  //renderUI

  useEffect(() => {
    getInstanceDropdownDetails();
  }, []);
  const handleDropdownSelection = (e, i) => {
    const { value } = e.target;
    setValue((prevValue) => ({
      ...prevValue,
      [`attribute${i + 1}Id`]: value,
    }));
  };
  //on change function for intance dropdown
  const handleInstance = (i) => {
    setInstanceValue(i);
    setIsLoading(true);
    getAttributesBasedOnInstance(i);
    getProjectNameDropdownDetails(i);
    getProjectManagerDropdowndetails(i);
    setShowTable(false);
    setValue({});
    setDropdownss([]);
    setShowTable(false);
  };
  //api call to get instance details
  
  const getInstanceDropdownDetails = async () => {
    try {
      let body = {
        userId: sessionStorage.getItem("loginId"),
      };
      let response = await WL_GetDropdown(body);
      // .then((response) => {
      if (response.status === 200) {
        const apiData = response.data.success.data;
        console.log(apiData);
        const results = [];
        // Store results in the results array
        apiData.forEach((value) => {
          results.push({
            key: value.name,
            value: value.id,
          });
        });
        console.log(results);
        // Update the options state
        setInstanceOptions([
          // {key: 'Select a company', value: ''},
          ...results,
        ]);
        console.log("qqqq", results[0]);
        handleInstance(results[0].value);
      }
      // })
    } catch (error) {
      console.log(error);
    }
  };

  //api call to get projectname details
  const getProjectNameDropdownDetails = async (i) => {
    try {
      let body = {
        userId: sessionStorage.getItem("loginId"),
        instanceId: i,
      };
      let response = await getProjectName(body);
      if (response.statusCode == 200) {
        const apiData = response.success.data;
        const results = [];
        // Store results in the results array
        apiData.forEach((value) => {
          results.push({
            key: value.projectname,
            value: value.id,
          });
        });
        console.log(results);
        // Update the options state
        setOptions([
          // {key: 'Select a company', value: ''},
          ...results,
        ]);
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //api call to get projecvt manager details
  const getProjectManagerDropdowndetails = async (i) => {
    try {
      let body = {
        userId: sessionStorage.getItem("loginId"),
        instanceId: i,
      };
      let response = await getProjectManagers(body);
      if (response.statusCode == 200) {
        const apiData = response.success.data;
        const results = [];
        // Store results in the results array
        apiData.forEach((value) => {
          results.push({
            key: value.username,
            value: value.id,
          });
        });
        console.log(results);
        // Update the options state
        setProjectManagerOptions([
          // {key: 'Select a company', value: ''},
          ...results,
        ]);
      } else {
        setProjectManagerOptions([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAttributesBasedOnInstance = (i) => {
    setIsLoading(true);
    let body = {
      userId: sessionStorage.getItem("loginId"),
      instanceId: i,
    };
    MyProjGetAttributes(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          const dropdowns = response?.data?.success?.data;
          const activeLabels = dropdowns.labels?.filter(
            (label) => label.isactive === 1
          );
          setHeaders(activeLabels);
          const activeDropdowns = activeLabels?.map((label) => {
            const correspondingValues =
              dropdowns.values[label.attributenumber - 1];
            return {
              label: label.attributename,
              values: correspondingValues,
            };
          });
          setDropdownss(activeDropdowns);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let obj = {
    instanceId: Instancevalue,
    userId: sessionStorage.getItem("loginId"),
    attribute1Id:
      value.attribute1Id !== undefined || value.attribute1Id !== "Select"
        ? value.attribute1Id
        : 0,
    attribute2Id:
      value.attribute2Id !== undefined || value.attribute2Id !== "Select"
        ? value.attribute2Id
        : 0,
    attribute3Id:
      value.attribute3Id !== undefined || value.attribute3Id !== "Select"
        ? value.attribute3Id
        : 0,
    attribute4Id:
      value.attribute4Id !== undefined || value.attribute4Id !== "Select"
        ? value.attribute4Id
        : 0,
    attribute5Id:
      value.attribute5Id !== undefined || value.attribute5Id !== "Select"
        ? value.attribute5Id
        : 0,
    attribute6Id:
      value.attribute6Id !== undefined || value.attribute6Id !== "Select"
        ? value.attribute6Id
        : 0,
    attribute7Id:
      value.attribute7Id !== undefined || value.attribute7Id !== "Select"
        ? value.attribute7Id
        : 0,
    attribute8Id:
      value.attribute8Id !== undefined || value.attribute8Id !== "Select"
        ? value.attribute8Id
        : 0,
    attribute9Id:
      value.attribute9Id !== undefined || value.attribute9Id !== "Select"
        ? value.attribute9Id
        : 0,
    attribute10Id:
      value.attribute10Id !== undefined || value.attribute10Id !== "Select"
        ? value.attribute10Id
        : 0,
    projId: projectnamevalue === "" || [] ? 0 : projectnamevalue,
    projMgrId: projectManagervalue === "" || [] ? 0 : projectManagervalue,
    accessType: accesstype === "" || [] ? "" : accesstype,
  };

  let object = {
    instanceId: Instancevalue,
    userId: sessionStorage.getItem("loginId"),
    attribute1Id:
      value.attribute1Id !== undefined || value.attribute1Id !== "Select"
        ? value.attribute1Id
        : 0,
    attribute2Id:
      value.attribute2Id !== undefined || value.attribute2Id !== "Select"
        ? value.attribute2Id
        : 0,
    attribute3Id:
      value.attribute3Id !== undefined || value.attribute3Id !== "Select"
        ? value.attribute3Id
        : 0,
    attribute4Id:
      value.attribute4Id !== undefined || value.attribute4Id !== "Select"
        ? value.attribute4Id
        : 0,
    attribute5Id:
      value.attribute5Id !== undefined || value.attribute5Id !== "Select"
        ? value.attribute5Id
        : 0,
    attribute6Id:
      value.attribute6Id !== undefined || value.attribute6Id !== "Select"
        ? value.attribute6Id
        : 0,
    attribute7Id:
      value.attribute7Id !== undefined || value.attribute7Id !== "Select"
        ? value.attribute7Id
        : 0,
    attribute8Id:
      value.attribute8Id !== undefined || value.attribute8Id !== "Select"
        ? value.attribute8Id
        : 0,
    attribute9Id:
      value.attribute9Id !== undefined || value.attribute9Id !== "Select"
        ? value.attribute9Id
        : 0,
    attribute10Id:
      value.attribute10Id !== undefined || value.attribute10Id !== "Select"
        ? value.attribute10Id
        : 0,
    projId: projectnamevalue === "" || [] ? 0 : projectnamevalue,
    projMgrId: projectManagervalue === "" || [] ? 0 : projectManagervalue,
    accessType: accesstype === "" || [] ? "" : accesstype,
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      if (!Instancevalue || Instancevalue === "Select") {
        setIsLoading(true);
        setShowTable(false);
      } else {
        setShowTable(true);
        setIsLoading(true);

        let data = await searchActiveProjectDetails(object);
        let res = data.success.data;
        if (data.statusCode === 200) {
          setIsLoading(false);
          setFilteredData(res);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = () => {
    setInstanceOptions([]);
    setFilteredData([]);
    setProjectNameValue([]);
    setAccessType([]);
    setProjectManagerValue([]);
    setDropdownss([]);
    setHeaders([]);
    setShowTable(false);
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>Active Projects</a>
              </div>
            </div>
            <Grid
              container
              className={[
                modules["parent-container"],
                modules["parent-container-new"],
              ].join(" ")}
              style={{ border: "1px solid #000000" }}
            >
              <div className={modules["grid-container"]}>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Instance:<span className={modules["asterisk"]}>*</span>{" "}
                  </div>
                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={Instancevalue}
                    onChange={(e) => handleInstance(e.target.value)}
                    required
                  >
                    {/* <option value="Select">SELECT</option> */}
                    {Instanaceoptions.map((i) => (
                      <option value={i.value} key={i.value}>
                        {i.key}
                      </option>
                    ))}
                  </select>
                </Stack>

                {/* Attribute Dropdown part */}
                {dropdownss?.map((obj, i) => {
                  return (
                    <Stack
                      spacing={2}
                      direction="row"
                      className={modules["each-entry"]}
                    >
                      <div className={modules["input-labels"]}>
                        {obj.label}:
                      </div>
                      <select
                        className={modules["dropdownSelect1_vpm"]}
                        value={value[`attribute${i + 1}Id`] || "Select"}
                        onChange={(event) => handleDropdownSelection(event, i)}
                      >
                        <option value="Select">SELECT</option>
                        {obj?.values?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.attributevalue}
                          </option>
                        ))}
                      </select>
                    </Stack>
                  );
                })}
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>Project Name:</div>
                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={projectnamevalue}
                    onChange={(e) => setProjectNameValue(e.target.value)}
                  >
                    <option value="Select">SELECT</option>
                    {options.map((i) => (
                      <option value={i.value} key={i.value}>
                        {i.key}
                      </option>
                    ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Project Manager:
                  </div>
                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={projectManagervalue}
                    onChange={(e) => setProjectManagerValue(e.target.value)}
                  >
                    <option value="Select">SELECT</option>
                    {projectManageroptions.map((i) => (
                      <option value={i.value} key={i.value}>
                        {i.key}
                      </option>
                    ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry"]}
                >
                  <div className={modules["input-labels"]}>
                    Project Access Type:
                  </div>
                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={accesstype}
                    onChange={(e) => setAccessType(e.target.value)}
                  >
                    <option value="Select">SELECT</option>
                    {AccessType.map((i) => (
                      <option value={i.value} key={i.value}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </Stack>
              </div>

              <Grid container className={modules["btn1-cont"]}>
                <button
                  className={modules["btn1"]}
                  onClick={handleSearch}
                  style={{ marginTop: "0px" }}
                >
                  Search
                </button>
                <button
                  className={modules["btn2"]}
                  onClick={handleReset}
                  style={{ marginTop: "0px" }}
                >
                  Reset
                </button>
              </Grid>
            </Grid>
            {showTable && (
              <Grid
                container
                className={modules[("table-grid", "table-grid-margin")]}
              >
                <div className={modules[("table-container", "table-overflow")]}>
                  <ActiveProjectTable
                    instanceId={Instancevalue}
                    filteredData={filteredData}
                    setFilteredData={setFilteredData}
                    object={object}
                    isLoading={isLoading}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ActiveProjects;
