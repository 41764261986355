import { React, useEffect, useState } from "react";
import {
  Grid,
  SvgIcon,
  Stack,
  Checkbox,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";

const AddEditTaskError = () => {
  const [data2, setData2] = useState([]);

  useEffect(() => {
    const storedMessages = localStorage.getItem("validationMessages");
    if (storedMessages) {
      try {
        const parsedMessages = JSON.parse(storedMessages);
        setData2(parsedMessages);
        localStorage.removeItem("validationMessages");
      } catch (error) {
        console.error("Failed to parse validation messages:", error);
      }
    }
  }, []);

  return (
    <>
      <div
        style={{
          overflow: "auto",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Stack
          direction="row"
          spacing={4}
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ padding: "8px" }}
        >
          <Stack>
            <img
              style={{
                width: "120px",
                height: "58px !important",
                alignSelf: "end",
                paddingTop: "10px",
              }}
              src={logo}
              alt="Godrej"
            />
          </Stack>
          <Stack sx={{ paddingTop: "10px", paddingRight: "10px" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>

        <Box
          sx={{
            padding: "8px",
            color: "#000000",
            fontSize: "16px",
            fontFamily: "Times New Roman",
          }}
        >
          {data2.join(" ")}
        </Box>
      </div>
    </>
  );
};

export default AddEditTaskError;
