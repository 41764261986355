import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import bread from "../../../Assets/bread.png";
import window_popup from "../../../Assets/window_popup.png";
import { Set_NewInstance } from "../../../Services/Dss";
import {
  SP_MtaInstances,
  SP_MtaStockpointtype,
  SP_MTA_SPDeleteStockPoint,
  SP_MTA_SPUpdateStockPoint,
  SP_MTA_SPInsertStockpoint,
} from "../../../Services/MTA";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";

export default function StockPoint() {
  const [data, setData] = useState({
    instname: "",
    instdesc: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInstanceId, setSelectedInstanceId] = useState("");

  const [dropdownData, setDropdownData] = useState([]);
  const [stockPointTypes, setStockPointTypes] = useState([]);

  const [actionType, setActionType] = useState("");
  const [stockPoint, setStockPoint] = useState("");
  const [stockPointDesc, setstockPointDesc] = useState("");
  const [selectedStockPointType, setSelectedStockPointType] = useState("");
  const [diffCode, setDiffCode] = useState("");
  const [diffDes, setdiffDes] = useState("");
  const [popupData, setPopupData] = useState({});
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [Ndisable, setNdisable] = useState(false);

  const ChannelSp = new BroadcastChannel("stockPointChannel");

  ChannelSp.onmessage = (e) => {
    setPopupData({
      [e.data.mainrole]: e.data.selectedData,
      mainroleid: e.data.mainrole,
      roleidslist: e.data.selectedData.roleIds,
    });
    // console.log(e.data, "e.data");
    setstockPointDesc(e.data.selectedData.roleNames);
    setStockPoint(e.data.selectedData.roleIds);
    setdiffDes(e.data.selectedData.roleNames);
    setDiffCode(e.data.selectedData.Ctype);
  };

  const handleRadioChange = (event) => {
    setActionType(event.target.value);
    setstockPointDesc("");
    setStockPoint("");
  };

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getInstances();
    setHasRunOnce(false);
  }, []);

  const getInstances = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      instance_uid: "",
      muiuid_last_modified_by: sessionStorage
        .getItem("loginUsername")
        .toLowerCase(),
    };
    if (loginId) {
      SP_MtaInstances(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setDropdownData(response?.data?.success?.data);
            setIsLoading(false);
            // console.log(response?.data?.success?.data.length, "length");

            if (response?.data?.success?.data.length > 0) {
              const defaultInstanceId =
                response?.data?.success?.data[0]?.mta_instance_uid;
              // console.log(defaultInstanceId, "fcewf");
              setSelectedInstanceId(defaultInstanceId);
              getStockPointType(defaultInstanceId);
            } else if (response?.data?.success?.data.length === 0) {
              alert("You do not have access to any Active Instance in MTA");
              // console.log(response?.data?.success?.data.length, "WWWW");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const getStockPointType = (instance) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      spt_instance_uid: instance,
      userid: loginId,
    };
    if (loginId) {
      SP_MtaStockpointtype(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            // setDropdownData(response?.data?.success?.data);
            setIsLoading(false);
            // console.log(response?.data?.success?.data.length, "length");

            if (response?.data?.success?.data.length > 0) {
              setStockPointTypes(response?.data?.success?.data);
            } else if (response?.data?.success?.data.length === 0) {
              setStockPointTypes(response?.data?.success?.data);
              // alert("You do not have access to any Active Instance in MTA");
              // console.log(response?.data?.success?.data.length, "WWWW");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching Stock points:", error);
        });
    }
  };

  function submitHandler() {
    if (actionType === "") {
      alert(
        "Select Add Record/Update Record/Delete Record \nSelect Stock Point Type"
      );
      return;
    } else if (selectedStockPointType === "") {
      alert("Select Stock Point Type");
      return;
    } else if (stockPoint === "" && stockPointDesc === "") {
      alert("Enter Stock Point\nEnter Stock Point Description");
      return;
    } else if (stockPoint === "") {
      alert("Enter Stock Point");
      return;
    } else if (stockPointDesc === "") {
      alert("Enter Stock Point Description");
      return;
    } else if (!hasRunOnce && selectedStockPointType !== diffCode) {
      if (actionType === "2") {
        alert("Enter Stock Point Description");
        setstockPointDesc("");
        // setStockPoint("");
        // setSelectedStockPointType("");
      } else if (actionType === "3") {
        alert("Enter Stock Point Description");
        setstockPointDesc("");
        // setStockPoint("");
        // setSelectedStockPointType("");
      }
      setHasRunOnce(true);
      return;
    } else {
      setIsLoading(true);
      if (actionType === "1") {
        const body = {
          sp_instance_uid: selectedInstanceId,
          sp_stock_point_type_code: selectedStockPointType,
          sp_stock_point_code: stockPoint,
          sp_stock_point_desc: stockPointDesc,
          sp_last_modified_by: sessionStorage
            .getItem("loginUsername")
            .toLowerCase(),
        };
        SP_MTA_SPInsertStockpoint(body)
          .then((response) => {
            // ;
            if (response?.data?.statusCode === 200) {
              // console.log(response?.data?.success?.data);
              alert(response?.data?.success?.message);
              resetHandler();
              setIsLoading(false);
            } else if (response?.response?.data?.statusCode === 400) {
              alert(response?.response?.data?.error?.message || "please try again");
              resetHandler();
              setIsLoading(false);
            } else {
              resetHandler();
              setIsLoading(false);      
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      } else if (actionType === "2") {
        const body = {
          sp_instance_uid: selectedInstanceId,
          sp_stock_point_type_code: selectedStockPointType,
          sp_stock_point_code: stockPoint,
          sp_stock_point_desc: stockPointDesc,
          sp_last_modified_by: sessionStorage
            .getItem("loginUsername")
            .toLowerCase(),
        };
        SP_MTA_SPUpdateStockPoint(body)
          .then((response) => {
            // ;
            if (response?.data?.statusCode === 200) {
              console.log(response?.data?.success?.data);
              alert(response?.data?.success?.message);
              resetHandler();
              setIsLoading(false);
            } else if (response?.response?.data?.statusCode === 400) {
              alert(response?.response?.data?.error?.message);
              resetHandler();
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      } else if (actionType === "3") {
        const body = {
          sp_instance_uid: selectedInstanceId,
          sp_stock_point_type_code: selectedStockPointType,
          sp_stock_point_code: stockPoint,
          sp_stock_point_desc: stockPointDesc,
          sp_last_modified_by: sessionStorage
            .getItem("loginUsername")
            .toLowerCase(),
        };
        SP_MTA_SPDeleteStockPoint(body)
          .then((response) => {
            if (response?.data?.statusCode === 200) {
              console.log(response?.data?.success?.data);
              alert(response?.data?.success?.message);
              resetHandler();
              setIsLoading(false);
            } else if (response?.response?.data?.statusCode === 400) {
              alert(response?.response?.data?.error?.message);
              resetHandler();
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      } else {
        alert("Try again");
        setIsLoading(false);
      }
    }
  }

  function resetHandler() {
    setSelectedStockPointType("");
    setstockPointDesc("");
    setStockPoint("");
    getInstances();
    setActionType("");
    setHasRunOnce(false);
    setNdisable(false);
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"StockPoint"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>Stock Point</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table
                className={modules["customTable"]}
                style={{ marginLeft: "3rem" }}
              >
                <tbody>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "9rem", fontWeight: "bold" }}>
                      MTA Instance <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedInstanceId}
                          onChange={(e) => {
                            // const selectedId =
                            setSelectedInstanceId(e.target.value);
                            // setSelectedInstance(selectedId);
                            // getTableData(e.target.value);
                            getStockPointType(e.target.value);
                          }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          // disabled={editing}
                        >
                          {/* <option value={"SELECT"}>SELECT</option> */}
                          {dropdownData.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mta_instance_uid}
                                key={i.id}
                                data-id={i.id}
                              >
                                {i.mta_instance_uid}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "9rem", fontWeight: "bold" }}>
                      Action <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl
                        component="fieldset"
                        sx={{ marginLeft: "1rem" }}
                      >
                        <RadioGroup
                          row
                          aria-label="successorstartafterpredecessorstart"
                          name="successorstartafterpredecessorstart"
                          value={actionType}
                          onChange={handleRadioChange}
                          sx={{
                            "& svg": {
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        >
                          <FormControlLabel
                            value="1"
                            sx={{
                              "& .MuiTypography-root ": {
                                fontSize: "12px",
                                fontFamily: "'WorkSans', sans-serif",
                              },
                            }}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: "blue",
                                  },
                                }}
                              />
                            }
                            label="Add New Record"
                          />
                          <FormControlLabel
                            value="2"
                            sx={{
                              "& .MuiTypography-root ": {
                                fontSize: "12px",
                                fontFamily: "'WorkSans', sans-serif",
                              },
                            }}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: "blue",
                                  },
                                }}
                              />
                            }
                            label="Update Record"
                          />
                          <FormControlLabel
                            value="3"
                            sx={{
                              "& .MuiTypography-root ": {
                                fontSize: "12px",
                                fontFamily: "'WorkSans', sans-serif",
                              },
                            }}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: "blue",
                                  },
                                }}
                              />
                            }
                            label="Delete Record"
                          />
                        </RadioGroup>
                      </FormControl>
                    </td>
                  </tr>

                  {(actionType === "1" ||
                    actionType === "2" ||
                    actionType === "3") && (
                    <>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "9rem", fontWeight: "bold" }}>
                          Stock Point Type{" "}
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <FormControl sx={{ marginLeft: "20px" }}>
                            <select
                              value={selectedStockPointType}
                              onChange={(e) => {
                                // const selectedId =
                                //   e.target.options[
                                //     e.target.selectedIndex
                                //   ].getAttribute("data-id");
                                setSelectedStockPointType(e.target.value);
                                // setstockPointDesc("");
                                // setStockPoint("");
                                // setSelectedInstance(selectedId);
                                // getTableData(e.target.value);
                              }}
                              className={modules["d_InstanceSelect"]}
                              style={{ width: "268px" }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                              disabled={Ndisable}
                            >
                              <option value={""}>SELECT</option>
                              {stockPointTypes.map((i) => {
                                return (
                                  <option
                                    className={
                                      modules[
                                        "instanceAttMap_dropdownSelect_menu"
                                      ]
                                    }
                                    value={i.spt_stock_point_type_code}
                                    key={i.spt_stock_point_type_code}
                                    // data-id={i.id}
                                  >
                                    {i.spt_stock_point_type_code}
                                  </option>
                                );
                              })}
                            </select>
                          </FormControl>
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "9rem", fontWeight: "bold" }}>
                          Stock Point <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={stockPoint}
                            onChange={(e) => {
                              setStockPoint(e.target.value);
                              setNdisable(true);
                              if (e.target.value === "") {
                                setNdisable(false);
                              }
                            }}
                            onInput={(e) => {
                              const regex = /^[a-zA-Z0-9()&_\-+=\[\]/. ]*$/;
                              if (!regex.test(e.target.value)) {
                                e.target.value = e.target.value.replace(
                                  /[^a-zA-Z0-9()&_\-+=\[\]/. ]/g,
                                  ""
                                );
                                setStockPoint(e.target.value);
                              }
                            }}
                            onBlur={(e) => {
                              const regex2 = /^[a-zA-Z0-9_ &()-]*$/;
                              if (!regex2.test(e.target.value)) {
                                alert("No Special Characters Allowed.");
                                setStockPoint("");
                                setNdisable(false);
                              }
                            }}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "250px" }}
                          />

                          {(actionType === "2" || actionType === "3") && (
                            <img
                              src={window_popup}
                              alt=""
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                                height: "10px",
                              }} // Adjust spacing as needed
                              onClick={() => {
                                if (selectedStockPointType !== "") {
                                  console.log(selectedStockPointType, "dw");
                                  setNdisable(false);
                                  window.open(
                                    `/ccpm/StockPointPopup?instance=${selectedInstanceId}`,
                                    "mypopuptitle",
                                    "width=600,height=500"
                                  );
                                  setNdisable(false);
                                } else {
                                  alert("Please Select Stock Point Type");
                                }
                              }}
                            />
                          )}
                        </td>
                      </tr>
                      <tr
                        style={{
                          paddingLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <td style={{ width: "9rem", fontWeight: "bold" }}>
                          Stock Point Description
                          <label style={{ color: "Red" }}>*</label>
                        </td>
                        <td>:</td>
                        <td>
                          <input
                            value={stockPointDesc}
                            onChange={(e) => {
                              setstockPointDesc(e.target.value);
                              setNdisable(true);
                              if (e.target.value === "") {
                                setNdisable(false);
                              }
                            }}
                            onInput={(e) => {
                              const regex = /^[a-zA-Z0-9()&_\-+=\[\]/. ]*$/;
                              if (!regex.test(e.target.value)) {
                                e.target.value = e.target.value.replace(
                                  /[^a-zA-Z0-9()&_\-+=\[\]/. ]/g,
                                  ""
                                );
                                setstockPointDesc(e.target.value);
                              }
                            }}
                            onBlur={(e) => {
                              const regex2 = /^[a-zA-Z0-9_ &()-]*$/;
                              if (!regex2.test(e.target.value)) {
                                alert("No Special Characters Allowed.");
                                setstockPointDesc("");
                                setNdisable(false);
                              }
                            }}
                            className={modules["input_css"]}
                            style={{ marginLeft: "20px", width: "250px" }}
                          />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>

              {(actionType === "1" ||
                actionType === "2" ||
                actionType === "") && (
                <>
                  <Grid
                    container
                    className={modules["mid-cont"]}
                    style={{ marginLeft: "14rem", marginTop: "0rem" }}
                  >
                    <button className={modules["btn1"]} onClick={submitHandler}>
                      Submit
                    </button>
                    <button
                      className={modules["btn1"]}
                      style={{ marginLeft: "20px" }}
                      onClick={() => window.location.reload()}
                    >
                      Close
                    </button>
                    <button
                      className={modules["btn1"]}
                      style={{ marginLeft: "20px" }}
                      onClick={resetHandler}
                    >
                      Reset
                    </button>
                  </Grid>
                </>
              )}
              {actionType === "3" && (
                <>
                  <Grid
                    container
                    className={modules["mid-cont"]}
                    style={{ marginLeft: "14rem", marginTop: "0rem" }}
                  >
                    <button className={modules["btn1"]} onClick={submitHandler}>
                      Delete
                    </button>
                    <button
                      className={modules["btn1"]}
                      style={{ marginLeft: "20px" }}
                      onClick={() => window.location.reload()}
                    >
                      Close
                    </button>
                    <button
                      className={modules["btn1"]}
                      style={{ marginLeft: "20px" }}
                      onClick={resetHandler}
                    >
                      Reset
                    </button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
