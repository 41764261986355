
import React, { useEffect, useState } from "react";
import "../../../HelpPopups/HelpPopup.css";
import Stack from "@mui/material/Stack";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import modules from "../../ccpm.module.css";
import { Grid } from "@mui/material";
import bread from "../../../../Assets/bread.png";
import { PendingTaskPopup } from "../../../../Services/CCPM";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";
import loading from "../../../../Assets/Loading_image.gif";

const PendingTask = () => {
  const [criticalData, setCriticalData] = useState([]);
  const [feedingData, setFeedingData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = parseInt(searchParams.get("projId"));
  const [isLoading, setIsLoading] = useState(false);

  const handlePendingTask = () => {
    setIsLoading(true);
    let body = {
      projectId: projectId,
    };
    PendingTaskPopup(body)
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          setIsLoading(false);
          setCriticalData(response?.data?.success?.data?.CriticalChain);
          setFeedingData(response?.data?.success?.data?.FeedingChain);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    handlePendingTask();
  }, []);

  return (
    <>
      <div>
        {isLoading && (
          <img
            src={loading}
            className={modules["loading_gif"]}
            alt="Loading..."
          />
        )}
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              paddingLeft: "1rem",
              fontWeight: "bold",
              fontFamily: "Times New Roman",
            }}
          >
            <img src={logo} alt="Godrej" />
          </Stack>
          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Grid item lg={12}>
          <div style={{ marginLeft: "3rem" }}>
            <div className={modules["pro-bread"]}>
              <a className={modules["pending-txt"]}>Active Projects</a>
              <img src={bread} alt="" />
              <a className={modules["pending-txt"]}>Pending Tasks</a>
            </div>
          </div>
          <div
            className={modules["pending-txt"]}
            style={{ margin: "2rem 0rem 0.5rem 0.3rem" }}
          >
            Critical Chain
          </div>
        </Grid>
        <div
          className={modules["table-overflow"]}
          style={{
            marginLeft: "0.3rem",
            width: "99%",
            fontFamily: "'Work Sans', sans-serif",
          }}
        >
          <Table
            className={[
              modules["custom-table_viewpending"],
              modules["custom-table-mp-th"],
            ].join(" ")}
          >
            <TableHead>
              <TableRow>
                <TableCell>View Details</TableCell>
                <TableCell>Project</TableCell>
                <TableCell>Project buffer/Feeding buffer</TableCell>
                <TableCell>Chain Details</TableCell>
                <TableCell>Last Task On CC</TableCell>
                <TableCell>Original Buffer (in days)</TableCell>
                <TableCell>Remaining buffer (in days)</TableCell>
                <TableCell>% buffer consumed</TableCell>
                <TableCell>Original Chain (in days)</TableCell>
                <TableCell>Remaining Chain (in days)</TableCell>
                <TableCell>% critical chain completed</TableCell>
                <TableCell>Color</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {criticalData.length > 0 ? (
                criticalData.map((item, index) => (
                  <TableRow
                    className={modules["table-row"]}
                    style={{ backgroundColor: "#DEDFDE" }}
                  >
                    <TableCell
                      onClick={() => {
                        window.open(
                          `/ccpm/PendingTaskView2?projId=${item.projectsid}&chainId=${item.chaindetails}&fid=${item.fid}&criticalchaincompletion=${item.criticalchaincompletion}
                                                &projectbufferconsumed=${item.projectbufferconsumed}&color=${item.color}`,
                          // "sharer",
                          "_blank",
                          "toolbar=0,status=0,width=1050,height=600"
                        );
                      }}
                    >
                      <a
                        className={modules["pending-txt-link"]}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        View Details
                      </a>
                    </TableCell>
                    <TableCell>{item.projectname}</TableCell>
                    <TableCell>{item.buffer}</TableCell>
                    <TableCell>{item.chaindetails}</TableCell>
                    <TableCell>{item.lasttaskoncc}</TableCell>
                    <TableCell>{item.originalccduration.toFixed(2)}</TableCell>
                    <TableCell>
                      {item.remainingprojectbuffer.toFixed(2)}
                    </TableCell>
                    <TableCell>{item.projectbufferconsumed}</TableCell>
                    <TableCell>{item.originalccduration.toFixed(2)}</TableCell>
                    <TableCell>{item.remainingccduration.toFixed(2)}</TableCell>
                    <TableCell>{item.criticalchaincompletion}</TableCell>
                    <TableCell
                      style={{ backgroundColor: item.color }}
                    ></TableCell>
                  </TableRow>
                ))
              ) : isLoading ? (
                ""
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={12}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      padding: "8px",
                    }}
                  >
                    No Record found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {feedingData !== null && (
          <div>
            <div style={{ margin: "2rem 0rem 0.5rem 0.3rem" }}>
              Feeding Chain
            </div>
            <div
              className={modules["table-overflow"]}
              style={{
                marginLeft: "0.3rem",
                width: "99%",
                fontFamily: "'Work Sans', sans-serif",
              }}
            >
              <Table
                className={[
                  modules["custom-table-mp"],
                  modules["custom-table-mp-th"],
                ].join(" ")}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      View Details
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      Project
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      Project buffer/Feeding buffer
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      Chain Details
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      Last Task On CC
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      Original Buffer (in days)
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      Remaining buffer (in days)
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      % buffer consumed
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      Original Chain (in days)
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      Remaining Chain (in days)
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      % critical chain completed
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", padding: "8px" }}
                    >
                      Color
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {criticalData.map((item, index) => (
                    <TableRow className={modules["table-row"]}>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                        onClick={() => {
                          window.open(
                            `/ccpm/PendingTaskView2?projId=${item.projectsid}&chainId=${item.chaindetails}&fid=${item.fid}&criticalchaincompletion=${item.criticalchaincompletion}
                                                &projectbufferconsumed=${item.projectbufferconsumed}&color=${item.color}`,
                            "sharer",
                            "toolbar=0,status=0,width=1050,height=600"
                          );
                        }}
                      >
                        <a>View Details</a>
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                      >
                        {item.projectname}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                      >
                        {item.buffer}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                      >
                        {item.chaindetails}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                      >
                        {item.lasttaskoncc}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                      >
                        {item.originalccduration}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                      >
                        {item.remainingprojectbuffer}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                      >
                        {item.projectbufferconsumed}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                      >
                        {item.originalccduration}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                      >
                        {item.remainingccduration}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                      >
                        {item.criticalchaincompletion}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid black", padding: "8px" }}
                      ></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PendingTask;
