import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SPT1 from "../HelpPopups/images/SPT1.png";
import SPT2 from "../HelpPopups/images/SPT2.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const InboundOrderAdvicehelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{ fontSize: "14px" }}>
              <h2
                style={{
                //   marginLeft: "5rem",
                //   textAlign: "center",
                //   textDecoration: "underline",
                  fontWeight: "bold",
                //   fontStyle: "italic",
                  fontSize: "26px",
                }}
              >
                MTA STOCK POINT TYPE
              </h2>
              <br />
              <br />
              <li
                className="no-bullets"
                style={{
                  textDecoration: "underline",
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                Session Path `{">>"}` MTA `{">>"}` MTA Parameter `{">>"}` Stock
                Point Type.
              </li>
              <br />
              <li className="no-bullets">
                This option is used for maintaining the stock point type and
                stock point description for the MTA instances created. Since
                Stock Point Type is same across globe they are maintained
                onetime at table level. One can Update only description using
                Edit option.
              </li>
              <br />
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SPT1} alt="SPT1" />
              </Stack>
              <br />
              <li className="no-bullets">
                If you select edit option following screen will pop up. Here
                User can only edit the stock point description. Once you change
                the description then click on update to save the changes. If you
                don’t want to save the changes then click on cancel.
              </li>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SPT2} alt="SPT2" />
              </Stack>
              <br />
              <br />
              <br />
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InboundOrderAdvicehelpPopup;
