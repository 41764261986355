import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SPW1 from "../HelpPopups/images/SPW1.png";
import SPW2 from "../HelpPopups/images/SPW2.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointWarehousehelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h1 style={{ textAlign: "left", fontWeight: "bold" }}>
                                MTA STOCK POINT WAREHOUSE
                            </h1>
                            <br />
                            <br />


                            <li className="no-bullets" style={{ textDecoration: "underline", fontWeight: "bold" }}>
                            Session Path {">>"} MTA {">>"} MTA Parameter {">>"}  Stock Point Warehouse.
                            </li>
                            <br />
                            <br />
                            <li className="no-bullets">
                                This option is used for defining stock point warehouse where actual stock of the material is maintained for the MTA Instance.
                            </li>
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPW1} alt="SPW1" />
                            </Stack>
                            <br />
                            <br />
                            <br />

                            <li>
                                Select MTA Instance
                            </li>
                            <li>
                                Action to be taken is
                            </li>

                            <li style={{ marginLeft: "2rem" }}>
                                Add new Record – For addition of the new record

                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Update Record – User can update record.


                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                Delete Record – User Can delete stock point warehouse from here.


                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPW2} alt="SPW2" />
                            </Stack>
                            <br />

                            <br />


                            <li>
                                Stock Point – Select Stock point from the pop-up box provided for the Field.

                            </li>
                            <li>
                                Company code – Maintain Company code here for that stock point.


                            </li>
                            <li>
                                Warehouse Code – Maintain warehouse code for Sock point and Company Code combination.

                            </li>
                            <li>
                                Dispatch Priority – If there are more than one warehouses in one stock point the consumption priority will be followed as per priority mentioned in Despatch Priority.
                            </li>
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StockPointWarehousehelpPopup;
