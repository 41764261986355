
import React, { useEffect, useState } from "react";
import "../../../HelpPopups/HelpPopup.css";
import Stack from "@mui/material/Stack";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import modules from "../../ccpm.module.css";
import { Grid } from "@mui/material";
import bread from "../../../../Assets/bread.png";
import {
    GetAssignList,
  GetRaiseIssueList,
  PendingTaskPopup2,
  PendingTaskPopup2graph,
  RaiseIssueCategory,
  SaveTaskraise,
  ViewtaskDurationHistory,
} from "../../../../Services/CCPM";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";
// import { BoxPlot } from 'react-chartjs-2';
import { Line } from "react-chartjs-2";
import "chart.js/auto"; // This is required for Chart.js v3

const AddAssignIssue = () => {
  const [activeRow, setActiveRow] = useState(null);
  const [data, setData] = useState([]);
  const [displayBox, setDisplayBox] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = parseInt(searchParams.get("projId"));
  const chainid = searchParams.get("chainId");
  const fid = parseInt(searchParams.get("fid"));
  const criticalchaincompletion = searchParams.get("criticalchaincompletion");
  const projectbufferconsumed = searchParams.get("projectbufferconsumed");
  const color = searchParams.get("color");
  const [isLoading, setIsLoading] = useState(false);
  const [label, setLabel] = useState("");
  const [remainingduration, setRemainingDuration] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [assigneeList, setAssigneeList] = useState([])
  const [issueDescription, setIssueDescription] = useState("")
  const [singleRaise, setsingleRaise] = useState([])
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const [categoryValue, setCategoryValue] = useState("")
  const [headerData, setHeaderData] = useState([])
  const userId = sessionStorage.getItem("loginId");
  console.log(userId,"userId")


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
const query = useQuery();
const project_Id = query.get('projectId'); // Replace 'yourQueryKey' with your actual query key
const taskId = query.get('taskid')
const editable = query.get('id')
const viewOnly = query.get('view')
 let single =  []
 let singleselected = []
 let assignee 
 useEffect(() => {
  const fetchData = async () => {
      setIsLoading(true);
      
    
      
      const body2 = {
          userId,
      };
      
      try {

        const body3 = {
          projectId: project_Id,
          taskId: taskId
        }

       const header = await ViewtaskDurationHistory(body3)        
          // Fetch pending tasks
          setHeaderData(header?.data?.success?.data)
          
          // Fetch issue categories
          const categoryResponse = await RaiseIssueCategory();
           console.log(categoryResponse?.data?.success?.data,"dwdd")
           setCategoryValue(categoryResponse?.data?.success?.data?.[0]?.id)
          if (categoryResponse?.status === 200) {
              setCategoryData(categoryResponse?.data?.success?.data);
          }
          
          // Fetch assignees
          const assigneeResponse = await GetAssignList(body2);
          if (assigneeResponse?.status === 200) {
             setSelectedAssignee(assigneeResponse?.data?.success?.data?.[0]?.id)
              setAssigneeList(assigneeResponse?.data?.success?.data);
              assignee = [...assigneeResponse?.data?.success?.data]
          }
      } catch (error) {
          console.error(error);
      } finally {
          setIsLoading(false);
      }
  };

  fetchData();
}, []); // Empty dependency array to run once on mount

useEffect(() => {
  const fetchSingleRaiseIssue = async () => {
      if (!editable) return;

      const body3 = { projectId: project_Id, taskId };
      const body2 = { userId };

      try {
          const assigneeResponse = await GetAssignList(body2);
          const assignee = assigneeResponse?.data?.success?.data || [];

          const response = await GetRaiseIssueList(body3);
          const categoryResponse = await RaiseIssueCategory();

          if (response?.status === 200) {
              const filteredSingle = response.data.success.data.find(item => item.id === Number(editable));

              if (filteredSingle) {
                  const selectedAssignee = assignee.find(item => item.id === Number(filteredSingle.issueownerid));
                  const category = categoryResponse?.data?.success?.data || [];
                  const selectedCategory = category.find(item => item.id === filteredSingle.issuecatid);

                  setCategoryValue(selectedCategory?.id);
                  setSelectedAssignee(selectedAssignee?.id);
                  setsingleRaise(filteredSingle);
                  setIssueDescription(filteredSingle.issuedescription);
              } else {
                  console.warn("Filtered single issue not found for editable ID:", editable);
              }
          } else {
              const errorMessage = response?.response?.data?.message || response?.response?.data?.error?.message || 'An error occurred';
              console.error("Error fetching raise issue list:", errorMessage);
          }
      } catch (error) {
          console.error("Error in fetchSingleRaiseIssue:", error);
      } finally {
          setIsLoading(false);
      }
  };

  fetchSingleRaiseIssue();
}, [editable]);



  const handleSubmit = async(e) => {
    e.preventDefault()

    if ( !categoryValue || !issueDescription || !selectedAssignee) {
      alert("Please fill in all fields.");
      return;
  }
    const body = 
        {
            projectId: project_Id,
            taskid: taskId,
            issueCatId: categoryValue,
            issueDescription: issueDescription,
            issueOwnerId: selectedAssignee,
            issueId: 0,
            raisedBy: 0
          }


          try {
            const  response= await SaveTaskraise(body)
            alert("Submitted")
          } catch (error) {
             console.log(error)
          }
    
  };
;
 



  const handleChange = (e) => {
    setIssueDescription(e.target.value)
  }

  const handleAssignValue = (event) => {
    setSelectedAssignee(event.target.value);
  }

  const handleCategoryValue = (event) => {
   setCategoryValue(event.target.value)
  }
   console.log(headerData,"selected")


   
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              paddingLeft: "1rem",
              fontWeight: "bold",
              fontFamily: "Times New Roman",
            }}
          >
            <img src={logo} alt="Godrej" />
          </Stack>
          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        


    
       
        <Stack style={{ padding: "15px" }}>
          <div
            style={{
              width: "100%",
              height: "5%",
              background: "#a4bf47",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "20px",
              color: "white",
              paddingTop:'3px',
              paddingBottom:'3px'
            }}
          >
            Raise Issue
          </div>

          <form
         className={modules["TimesFont"]}
            style={{
              fontSize: "16px",
              marginTop: "20px",
              color:'#000000',
              fontFamily:'Times New Roman'
           
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
                  fontFamily:'Times New Roman'
              }}
            >
              <label style={{ marginRight: "1rem", width: "30%",  fontFamily:'Times New Roman' }}>
                Project Name:
              </label>
              <p style={{ width: "40%",  fontFamily:'Times New Roman' }}>{headerData?.[0]?.projectname}</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
                  fontFamily:'Times New Roman'
              }}
            >
              <label style={{ marginRight: "1rem", width: "30%",  fontFamily:'Times New Roman' }}>
                Task ID:
              </label>
              <p style={{ width: "40%",  fontFamily:'Times New Roman' }}>{taskId}</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <label style={{ marginRight: "1rem", width: "30%" ,  fontFamily:'Times New Roman'}}>
                Task Name:
              </label>
              <p style={{ width: "40%",  fontFamily:'Times New Roman' }}>{headerData?.[0]?.taskname}</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
                  fontFamily:'Times New Roman'
              }}
            >
              <label style={{ marginRight: "1rem", width: "30%" ,  fontFamily:'Times New Roman'}}>
                <span style={{ color: "red", marginRight: "2px" ,  fontFamily:'Times New Roman'}}>*</span>Issue
                Category:
              </label>
              <select
                style={{
                  fontSize: "13px",
                  width: "95px",
                  height:'22px',
                  color:'#000000',
                  border: "1px solid",
                  fontFamily:"Arial"
                }}
                value={categoryValue}
                onChange={handleCategoryValue}
              >
                {categoryData.length > 0 &&
                  categoryData?.map((item) => {
                    return <option value={item?.id}>{item?.issuecategory}</option>;
                  })}
              </select>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <label style={{ marginRight: "1rem", width: "30%" ,fontFamily:'Times New Roman'}}>
                <span style={{ color: "red", marginRight: "2px",fontFamily:'Times New Roman' }}>*</span>Issue
                Description:
              </label>
              <textarea
                style={{
                  fontSize: "15px",
                  width: "30%",
                fontFamily:'Times New Roman',
                  border: "1px solid",
                }}
                rows={3}
                value={issueDescription}
                onChange={handleChange}
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
                fontFamily:'Times New Roman'
              }}
            >
              <label style={{ marginRight: "1rem", width: "30%",fontFamily:'Times New Roman' }}>
                <span style={{ color: "red", marginRight: "2px",fontFamily:'Times New Roman' }}>*</span>
                Assigned To:
              </label>
              <select
                style={{
                    fontSize: "13px",
                    width: "145px",
                    height:'22px',
                    color:'#000000',
                    border: "1px solid",
                    fontFamily:"Arial"
                }}
                value={selectedAssignee}
                onChange={handleAssignValue}
              >
                {assigneeList.length > 0 &&
                    assigneeList?.map((item) => {
                    return <option  value={item?.id} >{item?.username}</option>;
                  })}
              </select>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <label style={{ marginRight: "1rem", width: "30%",fontFamily:'Times New Roman' }}>Date:</label>
              <p style={{ width: "40%",fontFamily:'Times New Roman' }}>{new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }).replace(/ /g, '-')}</p>
            </div>
            <div
              style={{ display: "flex", marginLeft: "31.2%", marginTop: "1.2rem" }}
            >
              <button
                style={{ marginRight: "1rem",padding:"15px" }}
                className={modules["btn1_vpm"]}
                 onClick={handleSubmit}
              >
                Submit
              </button>
              <button  type="button"   style={{ padding:"15px" }} className={modules["btn1_vpm"]}   onClick={() => {
                   window.open(
                                          `/ccpm/raiseissue?projId=${project_Id}&taskId=${taskId}&view=${true}`,
                                        "sharer",
                                        "toolbar=0,status=0,width=1050,height=600"
                                      );
                                    
                                   
                                    }}>Cancel</button>
            </div>
          </form>
        </Stack>
      </div>
    </>
  );
};

export default AddAssignIssue;
