import modules from "./../DSS.module.css";
import Home from "../../../home/Home";
import { FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";
import bread from "../../../Assets/bread.png";
import { useEffect, useState } from "react";
import SideBar from "../../../Landing/Navbar";
import {
  GetInstanceDetails,
  GetParameterNameDetails,
  GetSessionNameDetails,
  GetParameterValue,
  UpdateParamValue,
  DeleteParamValue,
} from "../../../Services/Dss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SampleSidebar from "../../../Landing/SampleSidebar";

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "2px",
          height: "30px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

export default function Settings() {
  const [instancedata, setInstancedata] = useState([]);
  const [instancedisplayvalue, setInstanceDisplayValue] = useState("");

  const [sessiondata, setSessiondata] = useState([]);
  const [sessiondisplayvalue, setSessionDisplayValue] = useState("Select");

  const [paramnamedata, setParamnamedata] = useState([]);
  const [paramnamedisplayvalue, setParamnameDisplayValue] = useState("Select");

  const [paramvalue, setParamvalue] = useState("");
  const [newparamvalue, setNewParamvalue] = useState("");

  const [editstate, setEditstate] = useState(false);

  useEffect(() => {
    GetInstanceDetails("pritpwc")
      .then((response) => {
        console.log(response);
        setInstancedata(response.data);
        sessionHandler(response.data[0]);
        setInstanceDisplayValue(response.data[0]);
      })
      .catch((error) => {
        console.log("Error fetching Data:", error);
      });
  }, []);

  useEffect(() => {
    if (sessiondisplayvalue !== "Select" && instancedisplayvalue !== "Select") {
      GetParameterNameDetails(instancedisplayvalue, sessiondisplayvalue, "Y")
        .then((response) => {
          console.log(response);
          setParamnamedata(response.data);
        })
        .catch((error) => {
          console.log("Error fetching Data:", error);
        });
    }
  }, [sessiondisplayvalue]);

  useEffect(() => {
    if (
      sessiondisplayvalue !== "Select" &&
      paramnamedisplayvalue !== "Select"
    ) {
      GetParameterValue(
        instancedisplayvalue,
        sessiondisplayvalue,
        paramnamedisplayvalue,
        "Y"
      )
        .then((response) => {
          console.log(response);
          setParamvalue(response.data);
          setNewParamvalue(response.data);
        })
        .catch((error) => {
          console.log("Error fetching Data:", error);
        });
    }
  }, [paramnamedisplayvalue]);

  useEffect(() => {
    if (instancedisplayvalue && instancedisplayvalue !== "Select") {
      sessionHandler(instancedisplayvalue);
    }
  }, [instancedisplayvalue]);

  function sessionHandler(instname) {
    GetSessionNameDetails(instname, "Y")
      .then((response) => {
        console.log(response);
        setSessiondata(response.data);
      })
      .catch((error) => {
        console.log("Error fetching Data:", error);
      });
  }

  function editHandler() {
    setEditstate(true);
  }

  function cancelHandler() {
    setEditstate(false);
  }

  function updateHandler() {
    if (newparamvalue) {
      let data = {
        dsS_instanceUID: instancedisplayvalue,
        dsS_userID: "pritpwc",
        dsS_recordType: "",
        dsS_sessionName: sessiondisplayvalue,
        dsS_parameterName: paramnamedisplayvalue,
        dsS_parameterValue: newparamvalue.toString(),
        dsS_hostName: "prostreamdb",
        dsS_IPAddress: "10.10.10.212",
      };
      UpdateParamValue(data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            alert("Settings updated");
            setEditstate(false);

            GetParameterValue(
              instancedisplayvalue,
              sessiondisplayvalue,
              paramnamedisplayvalue,
              "Y"
            )
              .then((response) => {
                console.log(response);
                setParamvalue(response.data);
                setNewParamvalue(response.data);
              })
              .catch((error) => {
                console.log("Error fetching Data:", error);
              });
          } else alert(response.data);
        })
        .catch((error) => {
          console.log("Error fetching Data:", error);
        });
    } else {
      alert("Enter all required inputs");
    }
  }

  function deleteHandler() {
    if (
      sessiondisplayvalue !== "Select" &&
      paramnamedisplayvalue !== "Select" &&
      instancedisplayvalue !== "Select"
    ) {
      DeleteParamValue(
        instancedisplayvalue,
        sessiondisplayvalue,
        paramnamedisplayvalue,
        paramvalue
      )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            alert(response.data);
          }
        })
        .catch((error) => {
          console.log("Error fetching Data:", error);
        });
    } else {
      alert("Select all inputs first");
    }
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          {/* <SideBar /> */}
          <SampleSidebar />
        </Grid>
        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>DSS Admin</a>
                <img src={bread} alt="" />
                <a>Settings</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table className={modules["customTable"]}>
                <tbody>
                  <tr>
                    <td>DSS Instance</td>
                    <td>:</td>
                    <td>
                      <FormControl className={modules["form-control"]}>
                        <Select
                          readOnly={editstate}
                          IconComponent={ArrowLine}
                          className={modules["dropdownSelect"]}
                          value={instancedisplayvalue}
                          onChange={(e) => {
                            setInstanceDisplayValue(e.target.value);
                            setParamnameDisplayValue("Select");
                            setSessionDisplayValue("Select");
                            setParamvalue("");
                            setNewParamvalue("");
                          }}
                        >
                          <MenuItem value={"Select"}>Select</MenuItem>
                          {/* <MenuItem value={instancedata[0]}>{instancedata[0]}</MenuItem> */}

                          {instancedata?.map((i) => {
                            return (
                              <MenuItem value={i} key={i}>
                                {i}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td>Session Name</td>
                    <td>:</td>
                    <td>
                      <FormControl className={modules["form-control"]}>
                        <Select
                          readOnly={editstate}
                          IconComponent={ArrowLine}
                          className={modules["dropdownSelect"]}
                          value={sessiondisplayvalue}
                          onChange={(e) =>
                            setSessionDisplayValue(e.target.value)
                          }
                        >
                          <MenuItem value={"Select"}>Select</MenuItem>
                          {sessiondata?.map((i) => {
                            return (
                              <MenuItem value={i} key={i}>
                                {i}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td>Parameter Name</td>
                    <td>:</td>
                    <td>
                      <FormControl className={modules["form-control"]}>
                        <Select
                          readOnly={editstate}
                          IconComponent={ArrowLine}
                          className={modules["dropdownSelect"]}
                          value={paramnamedisplayvalue}
                          onChange={(e) =>
                            setParamnameDisplayValue(e.target.value)
                          }
                        >
                          <MenuItem value={"Select"}>Select</MenuItem>
                          {paramnamedata?.map((i) => {
                            return (
                              <MenuItem value={i} key={i}>
                                {i}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td>Value</td>
                    <td>:</td>
                    <td>
                      <input
                        value={editstate ? newparamvalue : paramvalue}
                        readOnly={!editstate}
                        onChange={(e) =>
                          editstate
                            ? setNewParamvalue(e.target.value)
                            : setParamvalue(e.target.value)
                        }
                        className={modules["input_css"]}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {editstate === false && (
                <Grid container className={modules["btn1-cont"]}>
                  <button className={modules["btn1"]} onClick={editHandler}>
                    Edit
                  </button>
                  <button className={modules["btn2"]} onClick={deleteHandler}>
                    Delete
                  </button>
                </Grid>
              )}
              {editstate && (
                <Grid container className={modules["btn1-cont"]}>
                  <button
                    className={modules["btnupdate"]}
                    onClick={updateHandler}
                  >
                    Update
                  </button>
                  <button className={modules["btn2"]} onClick={cancelHandler}>
                    Cancel
                  </button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
