import React from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import AM1 from "../HelpPopups/images/AM1.jpg";
import AM2 from "../HelpPopups/images/AM2.jpg";
import AM3 from "../HelpPopups/images/AM3.jpg";
import AM4 from "../HelpPopups/images/AM4.jpg";
import AM5 from "../HelpPopups/images/AM5.jpg";

import { Link } from "react-router-dom";

const InstanceAttributeValuMaster = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "5rem" }}>Attribute Master</h3>
              <br />
              <p style={{}}>
                <p style={{}}>
                  In instance attribute linking user can add attributes to the instance. This Attributes are used for further drill down of the project for analysis. User can add up maximum 10 attribute to one instance. User need to add attributes. User can decide attributes depending on the business.
                </p>
              </p>
              <br />

              <li>Session Path – CCPM / Master/ Attribute Master (Instance Attribute Value Master)</li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={AM1} alt="AM1" />
              </Stack>
              <br />

              <li>Instance – Select Instance from the dropdown menu provided.</li>
              <li>Instance Attribute – Once user select instance then he can see the linked Attributes in this filter. User need to select one by one attribute and then</li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={AM2} alt="AM2" />
              </Stack>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={AM3} alt="AM3" />
              </Stack>
              <br />
              <p style={{}}>Once instance and Attribute is selected then following screen is appeared.</p>
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={AM4} alt="AM4" />
              </Stack>
              <br />

              <p style={{}}>Attribute Values – User can add values mean name of the attribute value for the selected instance and Attribute.</p>

              <br />
              <li>Sr. No – This indicates the attribute value no. which user has defined.</li>
              <li>Attribute Value – User can define Attribute Value name.</li>
              <li>Active – Active is indication that the attribute is in use means it is active or it is not in active. User can mark the status for the attributes.</li>
              <li>Edit – user can edit the name of attribute after adding it in the instance. This gives user feasibility for editing any errors occurred while adding the attribute.</li>

              <br />
              <p style={{}}>Once user defined the attribute value for 1st attribute then user can select the next attribute and add value to it.</p>
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={AM5} alt="AM5" />
              </Stack>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InstanceAttributeValuMaster;
