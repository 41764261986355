import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SPDW1 from "../HelpPopups/images/SPDW1.png";
import SPDW2 from "../HelpPopups/images/SPDW2.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointSourceDestinationWarehouseMappinghelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h2 style={{ marginLeft: "0rem", textAlign: "left" }}>
                                Stock Point Destination Warehouse Mapping                            </h2>
                            <br />
                            <br />


                            <li className="no-bullets">
                                Session  Path {">>"} MTA {">>"} MTA Parameter {">>"} Stock Point Destination Warehouse Mapping
                            </li>
                            <br />
                            <br />
                            <li className="no-bullets">
                                This field indicates Destination warehouse Mapping. This field indicate mapping between Destination warehouse and source warehouse                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPDW1} alt="SPDW1" />
                            </Stack>
                            <br />
                            <li >
                                Select MTA instance
                            </li>
                            <br />
                            <li >
                                When you select MTA Instance then following screen is appeared.                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPDW2} alt="SPDW2" />
                            </Stack>
                            <br />

                            <br />


                            <li >
                                Destination Stock Point – This field indicate Destination stockpoint. Select Stock point name from dropdown available for the field.

                            </li>
                            <li >
                                Destination Company code – This field indicates related Destination company code. Add company code in this field. This should be maintained in the input master field.
                            </li>
                            <li >
                                Destination Warehouse Code – This field indicates Destination warehouse code. Add warehouse code in this field. This should be mainlined in the input master file.
                            </li>
                            <li >
                                Source Stock Point – This field indicate Source stockpoint. Select Stock point name from dropdown available for the field.
                            </li>
                            <li >
                                Source Company code – This field indicates related Source company code. Add company code in this field. This should be maintained in the input master field.
                            </li>
                            <li >
                                Source Warehouse Code – This field indicates Source warehouse code. Add warehouse code in this field. This should be mainlined in the input master file.
                            </li>
                            <li >
                                Submit the details to save data.                            </li>
                            <br />
                            <br />
                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StockPointSourceDestinationWarehouseMappinghelpPopup;
