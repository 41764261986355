import {
  API_KEY1,
  GetUserInstance,
  SaveUserInstance,
  InsertInstance,
  GetInstance,
  GetSessionName,
  GetParameterName,
  GetValue,
  DeleteSetting,
  UpdateSetting,
  GetAllInstance,
  UpdateAllInstances,
  UpdateOneInstance,
  DS_GetInstances,
  DS_GetDataSourceType,
  DS_GetSourceName,
//   API_KEY,
  DS_SubmitClick,
  DS_UdpateOnClick,
  DS_AddNewRowDropdown,
  DS_InsertClick,
  DS_UpdateRow,
  DSS_CalculateThroughput,
} from "../Constants";
import {
  Hierachies_GetInstanceUID,
  Hierachies_GetMasterTables,
  Hierachies_GetOtherMasterTables,
  Hierachies_GetAttribute,
  Hierachies_SubmitClick,
  Hierachies_ShowMasterTablesOnRowDataBound,
  Hierachies_RowUpdating,
  Hierachies_RowDeletion,
  ML_GetSource,
  ML_GetLinkTo,
  ML_GetSourceColumn,
  ML_SubmitClick,
  ML_EditClick,
  ML_UpdateClick,
  PMTR_ProcessReport,
  MTR_GetDropdowns,
  MTR_SubmitReportTrack,
} from "../Constants";
import {
  MD_GetMaster,
  MD_GetDropdownValues,
  MD_GetOtherMaster,
  MD_GetTypeofAtt,
  MD_SubmitClick,
  MD_RowUpdating,
  MD_UpdateMasterName,
} from "../Constants";
//dss
import {
  MCW_GetThreholdBindGrid,
  MCW_OnRowUpdatingThreshold,
  MCW_OnRowDeletionThreshold,
} from "../Constants";
//trp loss
import {
  TMF_GetTRDLostFactor,
  TMF_BtnSaveClick,
  TMF_BtnUpdateClick,
  TMF_BtnDeleteClick,
} from "../Constants";


import {API_KEY, NI_NewInstance} from "../Constants/index.js";

import axios from "axios";

//TRF Loss factor

export async function TMF_GetTRDLostFactorHandler(instance, userid) {
  return axios
    .get(
      `${API_KEY}${TMF_GetTRDLostFactor}?instanceUId=${instance}&userID=${userid}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function TMF_BtnClickSaveClickHandler(body) {
  return axios
    .post(`${API_KEY}${TMF_BtnSaveClick}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function TMF_BtnClickUpdateHandler(body) {
  return axios
    .put(`${API_KEY}${TMF_BtnUpdateClick}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function TMF_BtnClickDeleteHandler(body) {
  return axios
    .delete(`${API_KEY}${TMF_BtnDeleteClick}`, {
      data: body,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MAUC Service

export async function MCW_GetThreholdBindGridHandler(instanceId) {
  return await axios
    .get(`${API_KEY}${MCW_GetThreholdBindGrid}${instanceId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MCW_OnRowUpdatingThresholdHandler(body) {
  return await axios
    .put(`${API_KEY}${MCW_OnRowUpdatingThreshold}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MCW_OnRowDeletionThresholdHandler(body) {
  return await axios
    .delete(`${API_KEY}${MCW_OnRowDeletionThreshold}`, {
      data: body,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetInstancesHandler(userId) {
  return axios
    .get(`${API_KEY1}${GetUserInstance}${userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SaveInstanceHandler(saveData) {
  return axios
    .post(`${API_KEY1}${SaveUserInstance}`, saveData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function InsertNewInstance(instancename, desc, userid) {
  return await axios
    .post(
      `${API_KEY1}${InsertInstance}${instancename}&desc=${desc}&userID=${userid}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//DSS SETTINGS
export async function GetInstanceDetails(userid) {
  return await axios
    .get(`${API_KEY1}${GetInstance}${userid}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetSessionNameDetails(instanceid, isAdmin) {
  return await axios
    .get(`${API_KEY1}${GetSessionName}${instanceid}&isAdmin=${isAdmin}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetParameterNameDetails(
  instanceid,
  sessionName,
  isAdmin
) {
  return await axios
    .get(
      `${API_KEY1}${GetParameterName}${instanceid}&sessionName=${sessionName}&isAdmin=${isAdmin}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function GetParameterValue(
  instanceid,
  sessionName,
  paramName,
  isAdmin
) {
  return await axios
    .get(
      `${API_KEY1}${GetValue}${instanceid}&sessionName=${sessionName}&paramName=${paramName}&isAdmin=${isAdmin}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function UpdateParamValue(data) {
  return await axios
    .post(`${API_KEY1}${UpdateSetting}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DeleteParamValue(
  instancedisplayvalue,
  sessiondisplayvalue,
  paramnamedisplayvalue,
  newparamvalue
) {
  return await axios
    .delete(`${API_KEY1}${DeleteSetting}`, {
      data: {
        dsS_instanceUID: instancedisplayvalue,
        dsS_userID: "pritpwc",
        dsS_recordType: "",
        dsS_sessionName: sessiondisplayvalue,
        dsS_parameterName: paramnamedisplayvalue,
        dsS_parameterValue: newparamvalue.toString(),
        dsS_hostName: "prostreamdb",
        dsS_IPAddress: "10.10.10.212",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//Instance Active/InActive

export async function GetAllInstancesHandler() {
  return await axios
    .get(`${API_KEY1}${GetAllInstance}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function UpdateAllInstanceHandler(state) {
  return await axios
    .put(`${API_KEY1}${UpdateAllInstances}${state}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function UpdateOneInstancesHandler(instanceId, state) {
  return await axios
    .put(`${API_KEY1}${UpdateOneInstance}${instanceId}&state=${state}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DS_GetAllInstanceHandler(userId) {
  return await axios
    .get(`${API_KEY}${DS_GetInstances}?UserId=${userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DS_GetDataSourceTypeHandler(SelectedInstance) {
  return await axios
    .get(
      `${API_KEY}${DS_GetDataSourceType}?SelectedInstance=${SelectedInstance}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DS_GetSourceNameHandler(SelectedInstance, SourceType) {
  return await axios
    .get(
      `${API_KEY}${DS_GetSourceName}?SelectedInstance=${SelectedInstance}&SourceType=${SourceType}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DS_SubmitHandler(selectedInstance, sourceType, recordId) {
  return await axios
    .get(
      `${API_KEY}${DS_SubmitClick}?selectedInstance=${selectedInstance}&sourceType=${sourceType}&recordId=${recordId}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DS_UpdateHandler(body) {
  return await axios
    .put(`${API_KEY}${DS_UdpateOnClick}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DS_AddnewRowHandler(instance, sourceName, masterName) {
  return await axios
    .get(
      `${API_KEY}${DS_AddNewRowDropdown}?instanceID=${instance}&sourceName=${sourceName}&MasterName=${masterName}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DS_AddNewRow(body) {
  return await axios
    .post(`${API_KEY}${DS_InsertClick}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DS_UpdateRowHandler(body) {
  return await axios
    .put(`${API_KEY}${DS_UpdateRow}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//Masters Hierachies

export async function HierachiesGetInstance(userId) {
  return await axios
    .get(`${API_KEY}${Hierachies_GetInstanceUID}${userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function HierachiesGetMasterTables(instanceId) {
  return await axios
    .get(`${API_KEY}${Hierachies_GetMasterTables}${instanceId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function HierachiesGetOtherMasterTables(instanceId) {
  return await axios
    .get(`${API_KEY}${Hierachies_GetOtherMasterTables}${instanceId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function HierachiesGetAttribute(
  instanceId,
  selectMaster,
  tableName
) {
  return await axios
    .get(
      `${API_KEY}${Hierachies_GetAttribute}${instanceId}&SelectMaster=${selectMaster}&tableName=${tableName}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function HierachiesSubmitClick(
  instanceId,
  selectMaster,
  tableName,
  attribute
) {
  return await axios
    .get(
      `${API_KEY}${Hierachies_SubmitClick}${instanceId}&SelectMaster=${selectMaster}&TableName=${tableName}&attribute=${attribute}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function HierachiesShowMasterTablesOnDataBound(
  instanceId,
  selectMaster,
  tableName,
  attribute
) {
  return await axios
    .get(
      `${API_KEY}${Hierachies_ShowMasterTablesOnRowDataBound}${instanceId}&SelectMaster=${selectMaster}&TableName=${tableName}&attribute=${attribute}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function HierachiesRowUpdating(body) {
  return await axios
    .put(`${API_KEY}${Hierachies_RowUpdating}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function HierachiesRowDelete(body) {
  try {
    const response = await axios.delete(`${API_KEY}${Hierachies_RowDeletion}`, {
      data: body,
    });
    return response;
  } catch (error) {
    return error;
  }
}

//Master Definition

export async function MD_GetMasterDropDownHandler(instanceId) {
  return await axios
    .get(`${API_KEY}${MD_GetMaster}?instanceUId=${instanceId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MD_GetOtherMasterDropDownHandler(instanceId) {
  return await axios
    .get(`${API_KEY}${MD_GetOtherMaster}?instanceUId=${instanceId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MD_GetAttributeHandler(instanceID) {
  return await axios
    .get(`${API_KEY}${MD_GetTypeofAtt}?instanceUId=${instanceID}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MD_SubmitHandler(instanceID, tableName, typeAttribute) {
  return await axios
    .get(
      `${API_KEY}${MD_SubmitClick}?instanceUID=${instanceID}&TableName=${tableName}&typeOfAttr=${typeAttribute}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MD_MasterNameUpdateHandler(body) {
  return await axios
    .put(`${API_KEY}${MD_UpdateMasterName}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MD_RowDropdownHandler(instanceId, tableName, typeOfAttr) {
  return await axios
    .get(
      `${API_KEY}${MD_GetDropdownValues}?instanceUID=${instanceId}&tableName=${tableName}&typeOfAttr=${typeOfAttr}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MD_UpdateRowHandler(body) {
  return await axios
    .put(`${API_KEY}${MD_RowUpdating}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//Master Linking

export async function MasterLinkingGetSource(instanceId) {
  return await axios
    .get(`${API_KEY}${ML_GetSource}${instanceId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MasterLinkingGetLinkTo(instanceId, sourceName) {
  return await axios
    .get(`${API_KEY}${ML_GetLinkTo}${instanceId}&sourceName=${sourceName}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MasterLinkingGetSourceColumn(instanceID, linkTo) {
  return await axios
    .get(`${API_KEY}${ML_GetSourceColumn}${instanceID}&linkTo=${linkTo}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MasterLinkingSubmitHandler(
  instanceID,
  sourceName,
  linkTo,
  sourceCol
) {
  return await axios
    .get(
      `${API_KEY}${ML_SubmitClick}${instanceID}&sourceName=${sourceName}&Linkto=${linkTo}&sourceCol=${sourceCol}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MasterLinkingEditClick(body) {
  return await axios
    .post(`${API_KEY}${ML_EditClick}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MasterLinkingUpdateClick(body) {
  return await axios
    .put(`${API_KEY}${ML_UpdateClick}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MAUC

//Process MAUC Report

export async function ProcessMaucReport(instanceID, userId) {
  return await axios
    .get(`${API_KEY}${PMTR_ProcessReport}${instanceID}&UserID=${userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MAUC TRACK RERPORT

export async function MaucReportTrackGetDropdowns(instanceID, userId) {
  return await axios
    .get(`${API_KEY}${MTR_GetDropdowns}${instanceID}&UserID=${userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MaucReportTrackSubmit(
  instanceID,
  userId,
  matCat,
  subSource,
  costCat,
  reportType,
  dataFreq,
  fileType,
  report,
  dateFrom,
  dateTo
) {
  return await axios
    .get(
      `${API_KEY}${MTR_SubmitReportTrack}${instanceID}&userID=${userId}&MaterialCategory=${matCat}&SubSource=${subSource}&CostCategory=${costCat}&ReportType=${reportType}&DataFrequency=${dataFreq}&FileType=${fileType}&Report=${report}&DateFrom=${dateFrom}&DateTo=${dateTo}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//DSS

//Calculate Throughput

export async function CalculateThroughputSubmitClick(
  instanceId,
  userId,
  baanCo,
  fromDate,
  toDate
) {
  return await axios
    .get(
      `${API_KEY}${DSS_CalculateThroughput}${instanceId}&userID=${userId}&baanCompany=${baanCo}&FromDate=${fromDate}&ToDate=${toDate}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//New Instance

export async function Set_NewInstance(body) {
  return await axios
    .post(`${API_KEY}${NI_NewInstance}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
