import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid, Stack
} from "@mui/material";
// import modules from "./InstanceDivisionDepartment.module.css";
import modules from "../ccpm.module.css";

import SampleSidebar from "../../../Landing/SampleSidebar";
import { UploadInstanceFile, DownloadInstanceFile, } from "../../../Services/CCPM.js";




const InstanceDivisionDepartment = () => {
  const [file, setFile] = useState(null);


  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };


  const toUpload = () => {
    const formData = new FormData();
    formData.append('file', file);
    if (!file) {
      alert('Please select a file first.');
      return;
    } else {
      UploadInstanceFile(formData)
        .then((response) => {
          alert(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
  }, []);

  const toDownload = () => {
    DownloadInstanceFile()
      .then((response) => {
        if (response) {
          console.log("Error:", response?.data);
          const blob = new Blob([response?.data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'InstanceDivisionDepartment.csv';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("No Data found");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  //       .then((response) => {
  //         console.log(response);
  //         alert(response);
  //         response.click();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  // };


  return (
    <>
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Instance Division Department</a>
              </div>
            </div>

            <Grid container className={modules["parent-container"]}>
              <Grid container className={modules["btn1-cont"]} style={{ marginLeft: "3rem" }}>
                <input type="file" onChange={handleFileChange} className={modules["choosefile_css"]} />
                <Stack direction="row" spacing={2} style={{ marginLeft: "5rem" }}>
                  <button className={modules["btn1"]} onClick={toUpload}>
                    Upload Master
                  </button>
                  <button className={modules["btn2"]} onClick={toDownload}>
                    Download Master
                  </button>
                </Stack>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </>
  );
};

export default InstanceDivisionDepartment;
