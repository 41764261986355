import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import UIL1 from "../HelpPopups/images/UIL1.jpg";
import UIL2 from "../HelpPopups/images/UIL2.jpg";
import UIL3 from "../HelpPopups/images/UIL3.jpg";
import UIL4 from "../HelpPopups/images/UIL4.jpg";
import UIL5 from "../HelpPopups/images/UIL5.jpg";
import UIL6 from "../HelpPopups/images/UIL6.jpg";
import { Link } from "react-router-dom";

const UserInstanceLinkinghelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link style={{ color: "blue", marginRight: "0.6rem" }}>
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <h3 style={{ marginLeft: "5rem" }}>USER INSTANCE LINKING</h3>
            <br />
            Main purpose of this session is to provide user a proper authorization for using CCPM. This session is used for two purpose.
            <br />
            <ol>
            <br/>
              <li>Part I – User Instance <br/>
                Here user is linked with the Instance. User can have access only for the instance linked to his login. He can not check/modify other instances.</li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={UIL1} alt="UIL1" />
              </Stack>
              <ul>
                <li>User loginid – Put the loginid to which you want to grant the access.</li>
                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={UIL2} alt="UIL2" />
                </Stack>
                <li> Instance Name – One can see all instances available in the Prostream. A single, Multiple and for all instance access can be given for the login id typed above.</li>
                <li>Access – Please tick against the instance for which access need to grant.</li>
                <li>Submit – Once user grants the access then submit to confirm the access.</li>
                <br />
                Once the instance linking is done then need to link loging Id with user instance attribute
                <br />
              </ul>
              <br/>
              <li> Part II – User Instance Attribute <br/>
                Here user is linked with the User Instance Attribute. User can have access only for the attributes linked to his login. He can not check/modify other instance attributes.</li>
              <br/>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={UIL3} alt="UIL3" />
              </Stack>
              <ul>
                <li>Once you clicked on User instance Attribute you need to select the Instance for which you want to give access for the attributes. Here user can find only instances which are linked to his loginid</li>
                <li>Once instance is selected the linked attributes will be shown below.</li>
                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={UIL4} alt="UIL4" />
                </Stack>
                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={UIL5} alt="UIL5" />
                </Stack>
                <li>Once attributes are shown down the instance click on the + mark, then attribute values will be shown for the attributes.</li>
                <Stack sx={{ paddingRight: "1rem" }}>
                  <img src={UIL6} alt="UIL6" />
                </Stack>
                <li>User can tick against the appropriate values where authorization need to be given to the user login id.</li>
                <li>Once appropriate values are ticked then press on submit button to save the values.</li>
                <li>When one attribute is complete then authorization need to be given for all instance attribute as per the requirement.</li>
              </ul>
              <br />
            </ol>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserInstanceLinkinghelpPopup;
