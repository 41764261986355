import React, { useState, useEffect, useRef } from "react";
import Home from "../../../../home/Home";
import modules from "../project.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Stack, Box, Typography } from "@mui/material";
import SampleSidebar from "../../../../Landing/SampleSidebar";
import bread from "../../../../Assets/bread.png";
import add from "../../../../Assets/add.png";
import Delete from "../../../../Assets/Delete.png";
import Save from "../../../../Assets/save.png";
import Chart from "../../../../Assets/Project_pro.png";
import Risk from "../../../../Assets/new_risk.png";
import Submit from "../../../../Assets/submit_plan.png";
import Back from "../../../../Assets/back.png";
import Plus from "../../../../Assets/plus.png";
import AddRowD from "../../../../Assets/insert_row.png";
import {
  AET_GetTable,
  AET_GetProjInfo,
  AET_GetTaskMgmt,
  AET_SaveTasks,
  AET_SubmitTasks,
  AET_DeleteTasks,
  AET_SearchTasks,
  AET_CheckSubType,
  AET_SaveAsTemp,
  AETPT_DeleteAllTasks,
} from "../../../../Services/CCPM";
import loading from "../../../../Assets/Loading_image.gif";

const ProjectTasks = () => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableDataTemp, setTableDataTemp] = useState([]);
  const [projInfo, setProjInfo] = useState([]);
  const [projStatus, setProjStatus] = useState("");
  const [managerNames, setManagerNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedRows, setCheckedRows] = useState({});
  const [sttType, setSttType] = useState([]);
  const textareaRefs = useRef({});

  // disable useStates
  const [rowStates, setRowStates] = useState({});
  const [ganttChart, setGanttChart] = useState(false);
  const [filters, setFilters] = useState(false);
  const [sureFilters, setSureFilters] = useState(true);
  const [saveAs, setSaveAs] = useState(false);
  const [saveAsText, setSaveAsText] = useState("");
  const [deleteTaskDis, setDeleteTaskDis] = useState(false);
  const [isImgIns, setIsImgIns] = useState(false);
  const [refreshEn, setRefreshEn] = useState(false);
  const [addRowOpened, setAddRowOpened] = useState(false);

  //search function
  const [taskManagerId, setTaskManagerId] = useState(-1);
  const [duration, setDuration] = useState("");
  const [durationCondition, setDurationCondition] = useState("S");
  const [taskName, setTaskName] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [noData, setNoData] = useState(false);
  const [dataNotFoundText, setDataNotFoundText] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projId = parseInt(searchParams.get("projId"));

  useEffect(() => {
    getProjInfo();
    getManagerNames();
  }, []);

  //use effect for focusing on textbox and expanding it on render
  useEffect(() => {
    if (tableDataTemp && tableDataTemp.length > 0) {
      const lastIndex = tableDataTemp.length - 1;
      const lastTaskId = tableDataTemp[lastIndex].taskid;
      if (textareaRefs.current[lastTaskId]) {
        textareaRefs.current[lastTaskId].focus();
      }
    }
  }, [tableDataTemp]);

  //use effect for subtask type fetching

  const checkSubTaskTypeID = async (taskID, projectID) => {
    try {
      const response = await AET_CheckSubType({ taskID, projectID });
      return response?.data?.success?.data[0]?.var_result;
    } catch (error) {
      console.error("Error checking subtask type ID:", error);
      return -1;
    }
  };

  useEffect(() => {
    const updateUIBasedOnAPI = async () => {
      const updatedRowStates = {};
      const typeTexts = [];

      for (let index = 0; index < tableDataTemp.length; index++) {  
        const item = tableDataTemp[index];
        const subTaskTypeExist = await checkSubTaskTypeID(item.taskid, projId);

        if (subTaskTypeExist !== -1 && subTaskTypeExist !== 0) {
          let typeText = "";
          switch (subTaskTypeExist) {
            case 1:
              typeText = "List";
              break;
            case 2:
              typeText = "Proc";
              break;
            case 4:
              typeText = "Vol";
              break;
            case 3:
              typeText = "Auto";
              break;
            default:
              typeText = "";
          }

          typeTexts[index] = typeText;

          const updateRowStateForTask = (taskid, subTaskTypeExist) => {
            setRowStates((prevRowStates) => ({
              ...prevRowStates,
              [taskid]: {
                ...prevRowStates[taskid],
                txtDurationDisabled:
                  subTaskTypeExist === 2 || subTaskTypeExist === 4,
              },
            }));
          };

          updateRowStateForTask(item.taskid, subTaskTypeExist);
        } else {
          // const projectStatus = await getProjectStatus(projectId);
          // if (projectStatus > 1 && projectStatus !== 11) {
          //   // Hide image button or disable it
          // }
        }
      }
      setSttType(typeTexts);
    };

    if (projStatus === "Created" || projStatus === "Submitted" || projStatus === "Planned") {
      updateUIBasedOnAPI();
    }
  }, [tableDataTemp, projId, projStatus]);

  //fetching api calls

  const fetchDetails = (status, startDate) => {
    setIsLoading(true);
    let body = {
      projectId: projId,
    };
    AET_GetTable(body)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          const data = response?.data?.success?.data;
          const modifiedData = data.map((item) => ({
            ...item,
            originaltaskduration: parseFloat(item.originaltaskduration).toFixed(
              2
            ),
          }));

          setTableData(modifiedData);
          setTableDataTemp(modifiedData);

          if (status === "Created") {
            if (data?.length === 0) {
              alert(
                "Blank Row will be added but will be saved only after clicking Save button else this added row/record will be lost. "
              );
              let newRow = {
                id: 0,
                projectsid: 0,
                taskid: generateNewTaskId(),
                taskname: "",
                originaltaskduration: 1,
                originaltaskstartdate: startDate,
                originaltaskenddate: "",
                taskmanagerid: 0,
                predecessor: "",
                successor: "",
                noncrushable: 0,
                setreleasecontrol: 0,
                allowtaktrate: 0,
                milestone: 0,
                subtasktype: 0,
                taskstatusid: 1,
                remainingduration: 0.0,
                expectedtaskstartdate: "",
                expectedtaskenddate: "",
                slackdays: 0,
                iscctask: 1,
                cctaskbufferconsumed: 0,
                delayreasonid: "",
                taskcolour: 0,
                isbuffer: 0,
                durationtype: "D",
                resources: "",
                taskprocessnotes: "",
                department: "",
                taskidbeforelastdeletetask: "",
                predbeforelastdeletetask: "",
                succbeforelastdeletetask: "",
              };

              const item = newRow;
              const newFieldStates = {
                [item.taskid]: {
                  txtDurationDisabled: false,
                  txtStartDateDisabled: true,
                  txtEndDateDisabled: true,
                  txtPredecessorDisabled: false,
                  txtSuccessorDisabled: false,
                },
              };
              setRowStates(newFieldStates);

              const modifiedNewRow = {
                ...newRow,
                originaltaskduration: parseFloat(
                  newRow.originaltaskduration
                ).toFixed(2),
              };

              setTableData((prev) => [...prev, modifiedNewRow]);
              setTableDataTemp((prev) => [...prev, modifiedNewRow]);
              setDeleteTaskDis(true);
              setIsImgIns(false);
            }
            // else if (data.length === 1) {
            //   const item = data[0];
            //   const newFieldStates = {
            //     [item.taskid]: {
            //       txtDurationDisabled: false,
            //       txtStartDateDisabled: true,
            //       txtEndDateDisabled: true,
            //       txtPredecessorDisabled: false,
            //       txtSuccessorDisabled: false,
            //     },
            //   };
            //   setRowStates(newFieldStates);
            // }
            else {
              setIsImgIns(true);
              setDeleteTaskDis(false);
              const newFieldStates = data.reduce((acc, item) => {
                acc[item.taskid] = {
                  txtDurationDisabled: false,
                  txtStartDateDisabled: true,
                  txtEndDateDisabled: true,
                  txtPredecessorDisabled: false,
                  txtSuccessorDisabled: false,
                };
                return acc;
              }, {});

              setRowStates(newFieldStates);
            }
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  const getManagerNames = () => {
    setIsLoading(true);
    AET_GetTaskMgmt()
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          const data = response?.data?.success?.data;
          setManagerNames(data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  const statusMapping = {
    1: "Created",
    2: "Submitted",
    3: "Planned",
    4: "Active",
    5: "Hold",
    6: "CheckOut",
    7: "Completed",
    9: "Scheduled",
    10: "CCDCheckedOut",
    11: "ModifiedCheckedOut",
  };

  const getStatusName = (statusNumber) => {
    return statusMapping[statusNumber] || "Unknown Status";
  };

  const getProjInfo = () => {
    setIsLoading(true);
    let body = {
      projectId: projId,
    };
    AET_GetProjInfo(body)
      .then((response) => {
        setIsLoading(false);
        const updatedRowStates = {};
        if (response.status === 200) {
          const data = response?.data?.success?.data;
          setProjInfo(data?.[0]);
          const status = getStatusName(data?.[0]?.projectstatusid);
          const startDate = data?.[0]?.projectstartdate;
          setProjStatus(status);
          const template = data?.[0]?.istemplate;
          if (template === 1) {
            setSaveAsText("Remove Template");
          } else {
            setSaveAsText("Save as Template");
          }
          if (status !== "ModifiedCheckedOut") {
            fetchDetails(status, startDate);
            setGanttChart(true);
          }
          if (status !== "Created") {
            setFilters(true);
            setDeleteTaskDis(true);
          }
          if (
            status === "Submitted" ||
            status === "Scheduled" ||
            status === "CCPMApplied" ||
            status === "Active" ||
            status === "Planned"
          ) {
            for (let item of tableData) {
              updatedRowStates[item.taskid] = {
                txtDurationDisabled: true,
                txtStartDateDisabled: true,
                txtEndDateDisabled: true,
                txtPredecessorDisabled: true,
                txtSuccessorDisabled: true,
              };
              setRowStates(updatedRowStates);
            }
          }
          if (
            status === "Created" ||
            status === "CheckOut" ||
            status === "ModifiedCheckedOut"
          ) {
            setSaveAs(false);
            setDeleteTaskDis(false);
            setFilters(false);
          } else if (status === "Submitted") {
            setFilters(true);
            setSaveAs(true);
          } else {
            setFilters(true);
            setSaveAs(false);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  //for table cells

  const getTaskValueById = (taskid, field) => {
    const task = tableData.find((item) => item.taskid === taskid);
    return task ? task[field] : "";
  };

  const getTaskValueById2 = (taskid, field) => {
    const task = tableData.find((item) => item.taskid === taskid);
    return task ? task[field] : 0;
  };

  const handleInputChange = (event, taskid, field) => {
    const { value } = event.target;
    setTableData((prevData) =>
      prevData.map((item) =>
        item.taskid === taskid ? { ...item, [field]: value } : item
      )
    );
  };
  const handleAddRow = () => {
    console.log("add row clicked");
  
    // Log the current state of addRowOpened
    console.log("Current addRowOpened:", addRowOpened);
  
    if (!addRowOpened) {
      setAddRowOpened(true);
      
      alert(
        "Blank Row will be added but will be saved only after clicking Save button else this added row/record will be lost."
      );
  
      let newRow = {
        id: 0,
        projectsid: 0,
        taskid: generateNewTaskId(),
        taskname: "",
        originaltaskduration: 1,
        originaltaskstartdate: projInfo.projectstartdate,
        originaltaskenddate: "",
        taskmanagerid: 0,
        predecessor: "",
        successor: "",
        noncrushable: 0,
        setreleasecontrol: 0,
        allowtaktrate: 0,
        milestone: 0,
        subtasktype: 0,
        taskstatusid: 1,
        remainingduration: 0.0,
        expectedtaskstartdate: "",
        expectedtaskenddate: "",
        slackdays: 0,
        iscctask: 1,
        cctaskbufferconsumed: 0,
        delayreasonid: "",
        taskcolour: 0,
        isbuffer: 0,
        durationtype: "D",
        resources: "",
        taskprocessnotes: "",
        department: "",
        taskidbeforelastdeletetask: "",
        predbeforelastdeletetask: "",
        succbeforelastdeletetask: "",
      };
  
      console.log("New Row Created:", newRow);
  
      const item = newRow;
      const newFieldStates = {
        [item.taskid]: {
          txtDurationDisabled: false,
          txtStartDateDisabled: true,
          txtEndDateDisabled: true,
          txtPredecessorDisabled: false,
          txtSuccessorDisabled: false,
        },
      };
  
      // Log previous row states
      console.log("Previous Row States:", rowStates);
      
      setRowStates((prevRowStates) => {
        const updatedRowStates = {
          ...prevRowStates,
          ...newFieldStates,
        };
        console.log("Updated Row States:", updatedRowStates);
        return updatedRowStates;
      });
  
      const modifiedNewRow = {
        ...newRow,
        originaltaskduration: parseFloat(newRow.originaltaskduration).toFixed(2),
      };
  
      // Log table data before adding new row
      console.log("Previous Table Data:", tableData);
  
      setTableData((prev) => {
        const updatedTableData = [...prev, modifiedNewRow];
        console.log("Updated Table Data:", updatedTableData);
        return updatedTableData;
      });
      setAddRowOpened(false); 
      setIsImgIns(false);
      setDeleteTaskDis(true);
    } else {
      console.log("Row addition is already opened.");
    }
  };
  
  const insertRowDown = (item) => {
    const index = tableData.findIndex((row) => row.taskid === item.taskid);
    let newRow = {
      id: 0,
      projectsid: 0,
      taskid: 0,
      taskname: "",
      originaltaskduration: parseFloat(1).toFixed(2),
      originaltaskstartdate: projInfo.projectstartdate,
      originaltaskenddate: item.originaltaskenddate,
      taskmanagerid: 0,
      predecessor: "",
      successor: "",
      noncrushable: 0,
      setreleasecontrol: 0,
      allowtaktrate: 0,
      milestone: 0,
      subtasktype: 0,
      taskstatusid: 1,
      remainingduration: 0.0,
      expectedtaskstartdate: "",
      expectedtaskenddate: "",
      slackdays: 0,
      iscctask: 1,
      cctaskbufferconsumed: 0,
      delayreasonid: "",
      taskcolour: 0,
      isbuffer: 0,
      durationtype: "D",
      resources: "",
      taskprocessnotes: "",
      department: "",
      taskidbeforelastdeletetask: "",
      predbeforelastdeletetask: "",
      succbeforelastdeletetask: "",
    };
    const item2 = newRow;
    const newFieldStates = {
      [item2.taskid]: {
        txtDurationDisabled: false,
        txtStartDateDisabled: true,
        txtEndDateDisabled: true,
        txtPredecessorDisabled: false,
        txtSuccessorDisabled: false,
      },
    };
    setRowStates((prevRowStates) => ({
      ...prevRowStates,
      ...newFieldStates,
    }));

    const updatedTableData = [
      ...tableData.slice(0, index + 1),
      newRow,
      ...tableData.slice(index + 1),
    ];

    const updatedTableDataWithIds = updatedTableData.map((row, idx) => ({
      ...row,
      taskid: idx + 1,
    }));

    setTableData(updatedTableDataWithIds);
    const transformedData = transformDataForApi(updatedTableDataWithIds, 1);
    saveTasksError(transformedData);
  };

  const generateNewTaskId = () => {
    return Math.max(...tableData.map((task) => task.taskid), 0) + 1;
  };

  //check boxes changes

  const handleCheckboxChange2 = (event, taskid, field) => {
    const isChecked = event.target.checked ? 1 : 0;
    setTableData((prev) =>
      prev.map((item) =>
        item.taskid === taskid ? { ...item, [field]: isChecked } : item
      )
    );
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    const newCheckedItems = {};
    tableData.forEach((item) => {
      newCheckedItems[item.taskid] = isChecked;
    });
    setCheckedRows(newCheckedItems);
  };

  const handleCheckboxChange = (event, taskid) => {
    setCheckedRows((prev) => ({
      ...prev,
      [taskid]: event.target.checked,
    }));
  };

  //other navigations

  const handleBackClick = () => {
    navigate("/ccpm/myprojects");
  };

  const handleNavRisk = () => {
    navigate("/ccpm/riskandmitigationplan");
  };

  //api calls for submit and save

  const confirmPredecessor = () => {
    let count = 0;
    let taskIdDisplay = "";

    tableData.forEach((item) => {
      if (!item.predecessor) {
        count++;
        taskIdDisplay += `${item.taskid}, `;
      }
    });

    if (count > 0) {
      const formattedTaskIds = taskIdDisplay.replace(/,\s*$/, "");
      const result = window.confirm(
        `Following task(s) (count: ${count}) from the project do not have predecessor (Task Id: ${formattedTaskIds}). Click on CANCEL to modify the plan or click on OK to submit the plan.`
      );
      return result;
    }

    return true;
  };

  const formatDate = (timestamp) => {
    if (!timestamp || isNaN(new Date(timestamp).getTime())) {
      return "";
    }

    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedTime = `${String(hours).padStart(
      2,
      "0"
    )}:${minutes}:${seconds} ${amPm}`;

    return `${month}/${day}/${year} ${formattedTime}`;
  };

  const formatDate2 = (timestamp) => {
    if (!timestamp || isNaN(new Date(timestamp).getTime())) {
      return "";
    }

    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const transformDataForApi = (tasks, error) => {
    return tasks.map((task) => ({
      id: task.id,
      projectsId: projId,
      taskId: task.taskid,
      taskName: task.taskname,
      originalTaskDuration: parseInt(task.originaltaskduration),
      originalTaskStartDate: formatDate2(task.originaltaskstartdate),
      originalTaskEndDate: formatDate2(task.originaltaskenddate),
      taskManagerId: parseInt(task.taskmanagerid),
      predecessor: task.predecessor,
      successor: task.successor,
      nonCrushable: task.noncrushable,
      setReleaseControl: task.setreleasecontrol,
      allowTAKTRate: task.allowtaktrate,
      milestone: task.milestone,
      taskStatusId: task.taskstatusid,
      slackDays: task.slackdays,
      resources: task.resources === null ? "" : task.resources,
      userId: parseInt(sessionStorage.getItem("loginId")),
      durationType: task.durationtype,
      taskProcessNotes:
        task.taskprocessnotes === null ? "" : task.taskprocessnotes,
      department: task.department,
      isError: error,
    }));
  };

  //other validations

  const moreThanOneSuccessor = (tasks) => {
    let count = 0;

    tasks.forEach((task) => {
      if (!task.successor) {
        count += 1;
      }
    });

    if (count > 1) {
      return "More than one task without successor is not allowed.";
    } else if (count === 0) {
      return "One task without successor (i.e., last task) is mandatory.";
    }

    return "";
  };

  const noPredecessorForSuccessor = (tasks) => {
    let message = "";

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      if (!task.successor && !task.predecessor) {
        message = "Last task should have at least one predecessor.";
        break;
      }
    }

    return message;
  };

  const predecessorExists = (tasks, rowid, predecessors, type) => {
    const taskIds = tasks.map((task) => task.taskid);
    if (predecessors === "") {
      return;
    }

    for (const predecessor of predecessors.split(",")) {
      const intPre = parseInt(predecessor.trim());

      if (intPre && !taskIds.includes(intPre)) {
        if (type === 1) {
          return `Predecessor Task Id ${predecessor} for Task Id ${
            rowid + 1
          } is not allowed.`;
        } else if (type === 2) {
          return `Successor Task Id ${predecessor} for Task Id ${
            rowid + 1
          } is not allowed.`;
        }
      } else if (rowid === 0 && intPre === 1) {
        return `Successor Task Id ${predecessor} for Task Id ${
          rowid + 1
        } is not allowed.`;
      }
    }

    return "";
  };

  const validateTaskDescriptions = (tasks) => {
    const messages = tasks
      .map((task) => {
        if (!task.taskname || task.taskname.trim() === "") {
          return `Task Description for Task Id = ${task.taskid} cannot be empty.`;
        }
        return "";
      })
      .filter((message) => message !== "");

    return messages.length > 0 ? messages : "";
  };

  const validateTaskManagers = (tasks) => {
    const messages = tasks
      .map((task) => {
        if (parseInt(task.taskmanagerid) === -1 || task.taskmanagerid === 0) {
          return `Task Manager for Task Id = ${task.taskid} is required.`;
        }
        return "";
      })
      .filter((message) => message !== "");

    return messages.length > 0 ? messages : "";
  };

  const validateUniqueTaskNames = (tasks) => {
    const nameToIdMap = new Map();
    const duplicateMessages = [];

    tasks.forEach((task) => {
      if (nameToIdMap.has(task.taskname)) {
        duplicateMessages.push(
          `Task Id ${task.taskid} has a duplicate Task Name.`
        );
      } else {
        nameToIdMap.set(task.taskname, task.taskid);
      }
    });

    return duplicateMessages.length > 0 ? duplicateMessages : "";
  };

  const networkValidationError = (tasks, index) => {
    let message = "";

    try {
      const task = tasks[index];

      if (task.status !== "Planned") {
        return "";
      }

      if (task.isBuffer) {
        return "";
      }

      if (
        !task.originaltaskduration.trim() ||
        parseInt(task.originaltaskduration.trim()) === 0
      ) {
        message += `Task Duration for Task Id = ${task.taskid} should be greater than Zero.`;
      }

      if (!task.taskManager || task.taskManager === "Select") {
        message += `Task Manager for Task Id = ${task.taskid} is required.`;
      }
    } catch (error) {
      console.error("Error in network validation:", error);
      alert("An error occurred during validation.");
    }

    return message;
  };

  const saveTasks2 = () => {
    let validationMessages = [];

    const successorMessage = moreThanOneSuccessor(tableData);
    if (successorMessage) validationMessages.push(successorMessage);

    const predecessorMessage = noPredecessorForSuccessor(tableData);
    if (predecessorMessage) validationMessages.push(predecessorMessage);

    tableData.forEach((task, index) => {
      const predecessors = task.predecessor;
      const predecessorMessage1 = predecessorExists(
        tableData,
        index,
        predecessors,
        1
      );
      if (predecessorMessage1) validationMessages.push(predecessorMessage1);

      const successorMessage = predecessorExists(
        tableData,
        index,
        task.successor,
        2
      );
      if (successorMessage) validationMessages.push(successorMessage);

      const networkErrorMessage = networkValidationError(tableData, index);
      if (networkErrorMessage) validationMessages.push(networkErrorMessage);
    });

    const descriptionMessage = validateTaskDescriptions(tableData);
    if (descriptionMessage) validationMessages.push(descriptionMessage);

    const managerMessage = validateTaskManagers(tableData);
    if (managerMessage) validationMessages.push(managerMessage);

    const uniqueNameMessage = validateUniqueTaskNames(tableData);
    if (uniqueNameMessage) validationMessages.push(uniqueNameMessage);

    if (validationMessages.length > 0) {
      localStorage.setItem(
        "validationMessages",
        JSON.stringify  (validationMessages)
      );
      window.open(
        `/ccpm/addEditTaskError`,
        "sharer",
        "toolbar=0,status=0,width=350,height=250"
      );

      const transformedData = transformDataForApi(tableData, 1);
      saveTasksError(transformedData);
    } else {
      const transformedData = transformDataForApi(tableData, 0);
      saveTasks(transformedData);
    }
  };

  const saveTasks = (transformedData) => {
    setIsLoading(true);
    AET_SaveTasks(transformedData)
      .then((response) => {
        if (response.status === 200) {
          alert("Plan Saved Successfully");
          setIsLoading(false);
          setIsImgIns(true);    
          setAddRowOpened(false);
          setDeleteTaskDis(false);
          setRefreshEn(true);
          fetchDetails();
          
        }
        else{
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const saveTasksError = (transformedData) => {
    AET_SaveTasks(transformedData)
      .then((response) => {
        setAddRowOpened(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleSubmit = () => {
    const transformedData = transformDataForApi(tableData, 0);
    let validationMessages = [];

    const successorMessage = moreThanOneSuccessor(tableData);
    if (successorMessage) validationMessages.push(successorMessage);

    const predecessorMessage = noPredecessorForSuccessor(tableData);
    if (predecessorMessage) validationMessages.push(predecessorMessage);

    tableData.forEach((task, index) => {
      const predecessors = task.predecessor;
      const predecessorMessage = predecessorExists(
        tableData,
        index,
        predecessors,
        1
      );
      if (predecessorMessage) validationMessages.push(predecessorMessage);

      const successorMessage = predecessorExists(
        tableData,
        index,
        task.successor,
        2
      );
      if (successorMessage) validationMessages.push(successorMessage);

      const networkErrorMessage = networkValidationError(tableData, index);
      if (networkErrorMessage) validationMessages.push(networkErrorMessage);
    });

    const descriptionMessage = validateTaskDescriptions(tableData);
    if (descriptionMessage) validationMessages.push(descriptionMessage);

    const managerMessage = validateTaskManagers(tableData);
    if (managerMessage) validationMessages.push(managerMessage);

    const uniqueNameMessage = validateUniqueTaskNames(tableData);
    if (uniqueNameMessage) validationMessages.push(uniqueNameMessage);

    if (validationMessages.length > 0) {
      localStorage.setItem(
        "validationMessages",
        JSON.stringify(validationMessages)
      );
      window.open(
        `/ccpm/addEditTaskError`,
        "sharer",
        "toolbar=0,status=0,width=350,height=250"
      );
    } else {
      // const canSubmit = confirmPredecessor();
      // if (canSubmit) {
      const result = window.confirm(
        `Click on CANCEL to modify the plan or click on OK to submit the plan.`
      );
      if (result) {
        
        setIsLoading(true);
        AET_SubmitTasks(transformedData)
          .then((response) => {
            if (response.status === 200) {
              setIsLoading(false);
              alert(
                "Plan is Submitted.To save the plan as template ,click Save as Template option ."
              );
              setSaveAs(true);
              setFilters(true);
              setSureFilters(false);
              setDeleteTaskDis(true);
              setRefreshEn(true);
              setSaveAsText("Save as Template");
            }
          })
          .catch((error) => {
            console.log("error", error);
            setIsLoading(false);
          });
      } else {
        return;
      }
      // } else {
      //   // alert("Please modify the plan.");
      // }
    }
  };

  //api call for delete task

  const removeTask = (id) => {
    setTableData((prevData) => {
      const updatedData = prevData.filter((task) => task.taskid !== id);

      return updatedData.map((task) => {
        const predecessors = task.predecessor.split(",").map(Number);
        const successors = task.successor.split(",").map(Number);

        // Remove the deleted task ID from predecessors
        const newPredecessors = predecessors
          .filter((p) => p !== id)
          .map((p) => (p > id ? p - 1 : p)) // Adjust IDs if necessary
          .join(",");

        // Remove the deleted task ID from successors
        const newSuccessors = successors
          .filter((s) => s !== id)
          .map((s) => (s > id ? s - 1 : s)) // Adjust IDs if necessary
          .join(",");

        return {
          ...task,
          predecessor: newPredecessors,
          successor: newSuccessors,
        };
      });
    });
  };

  const deleteTasks = () => {
    // Create the payload with `check` for each task
    const payload = tableData.map((task) => ({
      check: checkedRows[task.taskid] ? 1 : 0, // 1 if checked, 0 if unchecked
      id: task.id, // Set to 0 if not applicable, or provide a relevant value
      projectsId: projId,
      taskId: task.taskid,
      subtasktype: task.subtasktype || 0,
      taskName: task.taskname || "",
      originalTaskDuration: parseInt(task.originaltaskduration) || 0,
      originalTaskStartDate: task.originaltaskstartdate || "",
      originalTaskEndDate: task.originaltaskenddate || "",
      taskManagerId: parseInt(task.taskmanagerid) || 0,
      predecessor: task.predecessor || "",
      successor: task.successor || "",
      nonCrushable: task.noncrushable || 0,
      setReleaseControl: task.setreleasecontrol || 0,
      allowTAKTRate: task.allowtaktrate || 0,
      milestone: task.milestone || 0,
      taskStatusId: task.taskstatusid || 0,
      slackDays: task.slackdays || 0,
      resources: task.resources || "",
      userId: parseInt(sessionStorage.getItem("loginId")) || 0,
      durationType: task.durationtype || "",
      taskIdBeforeLastDeleteTask: task.taskidbeforelastdeletetask || "",
      predBeforeLastDeleteTask: task.predbeforelastdeletetask || "",
      succBeforeLastDeleteTask: task.succbeforelastdeletetask || "",
      taskProcessNotes: task.taskprocessnotes || "",
      department: task.department || "",
    }));
  
    // Here you can still check if at least one task is checked for deletion
    if (!payload.some((task) => task.check === 1)) {
      alert("Please select at least one task to delete.");
      return;
    }
  
    AETPT_DeleteAllTasks(payload)
      .then((response) => {
        if (response.status === 200) {
          alert("Tasks deleted successfully.");
          fetchDetails(); // Refresh the task list
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  

  //api call for save as template

  const handleSaveAsTempl = (element) => {
    element.style.color = "red";
    setIsLoading(true);
    setTimeout(() => {
      element.style.color = "#0000ee";
      let body = {
        projectId: projId,
        template: saveAsText === "Save as Template" ? 1 : 0,
        createdBy: parseInt(sessionStorage.getItem("loginId")),
        templatE_TYPE:
          saveAsText === "Save as Template"
            ? "Save as Template"
            : "Remove Template",
      };
      AET_SaveAsTemp(body)
        .then((response) => {
          if (response.status === 200) {
            getProjInfo();
            if (saveAsText === "Save as Template") {
              alert(response?.data?.message);
              setSaveAsText("Remove Template");
            } else {
              alert(response?.data?.message);
              setSaveAsText("Save as Template");
            }
          } else if (response?.response?.status === 400) {
            alert(response?.response?.data?.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 60);
  };

  const handleSearch = () => {
    const durationValue = duration ? parseInt(duration) : 0;
    const taskManager = taskManagerId !== -1 ? taskManagerId : 0;
    const isOriginalCC = filterValue === "1" ? 1 : 0;
    const isProjectB = filterValue === "2" ? 1 : 0;
    const isFeedingB = filterValue === "3" ? 1 : 0;
    const isCCTask = filterValue === "4" ? 1 : 0;

    let body = {
      projectId: projId,
      taskmanagerId: taskManager,
      duration: durationValue,
      originalCC: isOriginalCC,
      isProjectBuffer: isProjectB,
      isFeedingBuffer: isFeedingB,
      isCCTasks: isCCTask,
      mode: durationCondition,
      taskName: taskName,
    };
    setIsLoading(true);
    AET_SearchTasks(body)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          const data = response?.data?.success?.data;
          if (data.length === 0) {
            setNoData(true);
            if (
              isOriginalCC === 1 ||
              isProjectB === 1 ||
              isFeedingB === 1 ||
              isCCTask === 1 ||
              durationValue !== 0
            ) {
              setDataNotFoundText("No Tasks Assigned.");
            } else {
              setDataNotFoundText("No tasks found.");
            }
          } else {
            setNoData(false);
            const modifiedData = data.map((item) => ({
              ...item,
              originaltaskduration: parseFloat(
                item.originaltaskduration
              ).toFixed(2),
            }));
            setTableDataTemp(modifiedData);
            setTableData(modifiedData);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  const handleReset = () => {
    setNoData(false);
    setDuration("");
    setTaskManagerId(-1);
    setFilterValue("0");
    setDurationCondition("0");
    setTaskName("");
    fetchDetails();
  };

  const handleNoSaveClk = () => {
    alert("Save unsaved Task First, to avail Subtask Option.");
  };

  const handleOpenAdd = (taskId) => {
    navigate(`/ccpm/Tasks/subTasks?ProjectId=${projId}&TaskId=${taskId}`);
  };

  const refreshHandle = () => {
    fetchDetails();
  };

  // on blur functions

  const blurTaskDuration = (e, taskId) => {
    const inputValue = e.target.value;
    if (inputValue) {
      const value = parseFloat(inputValue).toFixed(2);
      handleInputChange({ target: { value } }, taskId, "originaltaskduration");
    }
  };

  const taskProcessBlur = (item) => {
    const disallowedCharPattern = /[&()_\+\-\=\[\]\.\/\?]/;
    if (disallowedCharPattern.test(item.taskprocessnotes)) {
      alert("No Special Characters Allowed.");
      let value = "";
      handleInputChange({ target: { value } }, item.taskid, "taskprocessnotes");
    }
  };

  const taskDepartBlur = (item) => {
    const disallowedCharPattern = /[+=\/.\[\]]/;
    if (disallowedCharPattern.test(item.department)) {
      alert("No Special Characters Allowed.");
      let value = "";
      handleInputChange({ target: { value } }, item.taskid, "department");
    }
  };

  const taskNameBlur = (item) => {
    const disallowedCharPattern = /[+=\/.\[\]]/;
    if (disallowedCharPattern.test(item.taskname)) {
      alert("No Special Characters Allowed.");
      let value = "";
      handleInputChange({ target: { value } }, item.taskid, "taskname");
    }
  };

  const isLastIndex = (index) => index === tableData?.length;

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div
        style={{
          overflowX: "auto",
          height: "100vh",
        }}
      >
        <Grid container>
          <Grid item lg={0.7} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>

          <Grid item lg={12}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={""} />
            </Grid>

            <Grid item xs={12}>
              <div className={modules["addEdit_div"]}>
                <div className={modules["pro-bread"]}>
                  <a>Project Plan</a>
                  <img src={bread} alt="" />
                  <a> Project Tasks </a>
                </div>
              </div>
              <Grid container className={modules["mid-contAET"]}>
                <Stack
                  sx={{ marginTop: "1%", width: filters ? "91%" : "87%" }}
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Typography className={modules["PJ_HeadText"]}>
                    Project Name :{" "}
                    <span style={{ backgroundColor: "yellow" }}>
                      {projInfo?.projectname}
                    </span>
                  </Typography>
                  <Box className={modules["AET_Holder"]}>
                    <Stack direction="row" spacing={2} alignItems="flex-end">
                      {filters && !saveAs && (
                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                      )}
                      {saveAs && (
                        <a
                          className={modules["PJ_LinkText2"]}
                          onClick={(e) => handleSaveAsTempl(e.currentTarget)}
                          style={{
                            paddingLeft: saveAs ? "0" : "1%",
                            cursor: "pointer",
                          }}
                        >
                          {saveAsText}
                        </a>
                      )}
                      {!filters && (
                        <img
                          src={add}
                          alt=""
                          title="Add New Task"
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={handleAddRow}
                        />
                      )}
                      {!deleteTaskDis && (
                        <img
                          src={Delete}
                          alt=""
                          title="Delete Task(s)"
                          style={{
                            width: "15px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={deleteTasks}
                        />
                      )}
                      {!filters && (
                        <img
                          src={Save}
                          alt=""
                          title="Save Tasks"
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={saveTasks2}
                        />
                      )}
                      <img
                        src={Chart}
                        alt=""
                        title="Project Properties"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(
                            `/ccpm/projectProperties?projId=${projId}`,
                            "sharer",
                            "toolbar=0,status=0,width=1050,height=600"
                          );
                        }}
                      />
                      <img
                        src={Risk}
                        style={{ cursor: "pointer" }}
                        alt="Risk"
                        title="Risks"
                        onClick={handleNavRisk}
                      />
                      {!filters && (
                        <img
                          src={Submit}
                          style={{ cursor: "pointer" }}
                          alt=""
                          title="Submit Plan"
                          onClick={handleSubmit}
                        />
                      )}
                      <img
                        src={Back}
                        title="Back to My Projects"
                        style={{ cursor: "pointer" }}
                        alt=""
                        onClick={handleBackClick}
                      />
                    </Stack>
                  </Box>
                </Stack>
                {filters && sureFilters && (
                  <Stack
                    direction="column"
                    sx={{ paddingLeft: "2%", paddingTop: "5%" }}
                    spacing={7}
                  >
                    <Stack direction="row" spacing={3} alignItems="center">
                      <Typography className={modules["inputCCPMSet"]}>
                        Task Manager{" "}
                      </Typography>
                      <Typography className={modules["inputCCPMSet"]}>
                        {" "}
                        :{" "}
                      </Typography>
                      <select
                        value={taskManagerId}
                        onChange={(e) =>
                          setTaskManagerId(parseInt(e.target.value))
                        }
                        className={modules["dropdownSelect"]}
                      >
                        <option value={-1}>SELECT</option>
                        <option value={0}>All</option>
                        {managerNames?.length > 0 &&
                          managerNames?.map((i) => (
                            <>
                              <option
                                key={i.id}
                                value={i.id}
                                style={{ color: "#373736" }}
                              >
                                {i.username}
                              </option>
                            </>
                          ))}
                      </select>
                      <Typography className={modules["inputCCPMSet"]}>
                        Duration{" "}
                      </Typography>
                      <Typography className={modules["inputCCPMSet"]}>
                        {" "}
                        :{" "}
                      </Typography>
                      <select
                        style={{ width: "150px" }}
                        className={modules["dropdownSelect"]}
                        value={durationCondition}
                        onChange={(e) => setDurationCondition(e.target.value)}
                      >
                        <option value="S" style={{ color: "#373736" }}>
                          Select
                        </option>
                        <option value="L" style={{ color: "#373736" }}>
                          Less than Equal
                        </option>
                        <option value="E" style={{ color: "#373736" }}>
                          Equals to
                        </option>
                        <option value="G" style={{ color: "#373736" }}>
                          Greater Than Equal
                        </option>
                      </select>
                      <input
                        type="text"
                        maxlength="4"
                        value={duration}
                        onChange={(e) => {
                          const value = e.target.value;
                          const filteredValue = value.replace(/[^0-9]/g, "");
                          if (filteredValue.length <= 4) {
                            setDuration(filteredValue);
                          }
                        }}
                        className={modules["txtboxPT"]}
                        style={{ width: "60px" }}
                      />
                      <Typography className={modules["inputCCPMSet"]}>
                        Task Name{" "}
                      </Typography>
                      <Typography className={modules["inputCCPMSet"]}>
                        {" "}
                        :{" "}
                      </Typography>
                      <input
                        type="text"
                        value={taskName}
                        onChange={(e) => {
                          const value = e.target.value;
                          const filteredValue = value.replace(
                            /[^a-zA-Z0-9-_ ]/g,
                            ""
                          );
                          setTaskName(filteredValue);
                        }}
                        className={modules["txtboxPT"]}
                        style={{ width: "60px" }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={4}
                      sx={{ paddingBottom: "4%", paddingLeft: "2px" }}
                    >
                      <Stack direction="row">
                        <input
                          type="radio"
                          value="1"
                          checked={filterValue === "1"}
                          onChange={() => setFilterValue("1")}
                          style={{ height: "13px" }}
                        />
                        <label
                          className={modules["inputCCPMSet"]}
                          style={{ marginLeft: "3px" }}
                        >
                          OriginalCC
                        </label>
                      </Stack>

                      <Stack direction="row">
                        <input
                          type="radio"
                          value="2"
                          checked={filterValue === "2"}
                          onChange={() => setFilterValue("2")}
                          style={{ height: "13px" }}
                        />
                        <label
                          className={modules["inputCCPMSet"]}
                          style={{ marginLeft: "3px" }}
                        >
                          ProjectBuffer
                        </label>
                      </Stack>

                      <Stack direction="row">
                        <input
                          type="radio"
                          value="3"
                          checked={filterValue === "3"}
                          onChange={() => setFilterValue("3")}
                          style={{ height: "13px" }}
                        />
                        <label
                          className={modules["inputCCPMSet"]}
                          style={{ marginLeft: "3px" }}
                        >
                          FeedingBuffer
                        </label>
                      </Stack>

                      <Stack direction="row">
                        <input
                          type="radio"
                          value="4"
                          checked={filterValue === "4"}
                          onChange={() => setFilterValue("4")}
                          style={{ height: "13px" }}
                        />
                        <label
                          className={modules["inputCCPMSet"]}
                          style={{ marginLeft: "3px" }}
                        >
                          CCTask
                        </label>
                      </Stack>
                    </Stack>
                  </Stack>
                )}
                <Stack
                  direction="row"
                  spacing={1}
                  justifyItems="center"
                  alignItems="center"
                  sx={{
                    paddingBottom: "3%",
                    paddingTop: filters ? "0%" : "3%",
                  }}
                >
                  {filters && sureFilters && (
                    <>
                      <button
                        className={modules["btnProjT"]}
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                      <button
                        className={modules["btnProjT"]}
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                    </>
                  )}
                  {refreshEn && (
                    <button
                      className={modules["btnProjT"]}
                      onClick={refreshHandle}
                    >
                      Refresh
                    </button>
                  )}

                  {ganttChart && (
                    <Typography
                      className={modules["PJ_LinkText2"]}
                      sx={{
                        paddingLeft: filters ? "0" : "1%",
                        cursor: "pointer",
                      }}
                    >
                      Show Grantt Chart
                    </Typography>
                  )}
                </Stack>
                <div
                  style={{
                    overflow: "hidden scroll",
                    marginRight: "1%",
                    width: noData ? "86%" : "",
                  }}
                >
                  <table className={modules["tableAET"]}>
                    <thead>
                      <tr>
                        <th style={{ width: "10px" }}>&nbsp;</th>
                        <th style={{ width: "20px" }}>
                          {" "}
                          <input
                            type="checkbox"
                            checked={selectAllChecked}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th style={{ width: "20px" }}>TaskId</th>
                        <th style={{ width: "40px" }}>SubTask</th>
                        <th>Department</th>
                        <th>Task&nbsp;Name</th>
                        <th>Duration Day(s)</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Predecessor</th>
                        <th>Successor</th>
                        <th>Task&nbsp;Manager</th>
                        <th style={{ width: "10px" }}>Non-Crushable</th>
                        <th>Task Process Notes</th>
                        <th style={{ width: "10px" }}>&nbsp;</th>
                      </tr>
                    </thead>
                    {noData ? (
                      <tbody>
                        <tr>
                          <td
                            colSpan="4"
                            style={{
                              borderBottom: "none",
                              paddingTop: "8px",
                              paddingLeft: "20px",
                              textAlign: "left",
                            }}
                          >
                            {dataNotFoundText}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {tableData?.map((item, index) => (
                          <tr key={item.taskid}>
                            <td style={{ width: "10px" }}>
                              {isImgIns ? (
                                <img
                                  src={AddRowD}
                                  alt=""
                                  title="Add Task Below"
                                  style={{
                                    cursor: "pointer",
                                    display: isLastIndex(item.taskid)
                                      ? "none"
                                      : "block",
                                  }}
                                  onClick={() => insertRowDown(item)}
                                />
                              ) : (
                                <>&nbsp;</>
                              )}
                            </td>
                            <td align="center" style={{ width: "10px" }}>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleCheckboxChange(e, item.taskid)
                                }
                                checked={!!checkedRows[item.taskid]}
                              />
                            </td>
                            <td align="left" style={{ width: "20px" }}>
                              {item.taskid}
                            </td>
                            <td align="left" style={{ width: "20px" }}>
                              {(projStatus === "Created" || projStatus === "Planned")  && (
                                <>
                                  <span
                                    style={{
                                      fontSize: "smaller",
                                      color: "blue",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    {sttType[index]}
                                  </span>
                                  <img
                                    src={Plus}
                                    alt=""
                                    title="Subtask"
                                    onClick={() =>
                                      addRowOpened === true
                                        ? handleNoSaveClk()
                                        : handleOpenAdd(item.taskid)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </>
                              )}
                              {projStatus === "Submitted" &&
                                sttType[index] &&
                                saveAsText === "Save as Template" && (
                                  <>
                                    <span
                                      style={{
                                        fontSize: "smaller",
                                        color: "blue",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      {sttType[index]}
                                    </span>
                                    <img
                                      src={Plus}
                                      alt=""
                                      onClick={() =>
                                        addRowOpened === true
                                          ? handleNoSaveClk()
                                          : handleOpenAdd(item.taskid)
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </>
                                )}
                            </td>
                            <td align="center">
                              <textarea
                                rows="2"
                                cols="20"
                                value={getTaskValueById(
                                  item.taskid,
                                  "department"
                                )}
                                onBlur={(e) => {
                                  taskDepartBlur(item);
                                }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^[a-zA-Z0-9&()_\-+=/ \[\].]*$/;
                                  if (regex.test(value)) {
                                    handleInputChange(
                                      e,
                                      item.taskid,
                                      "department"
                                    );
                                  }
                                }}
                                className={modules["txtboxPTExp"]}
                                style={{ width: "200px" }}
                              />
                            </td>
                            <td align="center">
                              <textarea
                                rows="2"
                                cols="20"
                                value={getTaskValueById(
                                  item.taskid,
                                  "taskname"
                                )}
                                onBlur={(e) => {
                                  taskNameBlur(item);
                                }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^[a-zA-Z0-9&()_\-+=/ \[\].]*$/;
                                  if (regex.test(value)) {
                                    handleInputChange(
                                      e,
                                      item.taskid,
                                      "taskname"
                                    );
                                  }
                                }}
                                className={modules["txtboxPTExp"]}
                                ref={(el) =>
                                  (textareaRefs.current[item.taskid] = el)
                                }
                                style={{ width: "200px" }}
                              />
                            </td>
                            <td align="center" style={{ width: "50px" }}>
                              <input
                                type="text"
                                disabled={
                                  rowStates[item.taskid]?.txtDurationDisabled
                                }
                                value={item.originaltaskduration}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d{0,10}$/.test(value)) {
                                    handleInputChange(
                                      e,
                                      item.taskid,
                                      "originaltaskduration"
                                    );
                                  }
                                }}
                                onBlur={(e) => blurTaskDuration(e, item.taskid)}
                                style={{ width: "50px" }}
                                className={modules["normalInput"]}
                              />
                            </td>
                            <td align="center">
                              <input
                                type="text"
                                disabled={
                                  rowStates[item.taskid]?.txtStartDateDisabled
                                }
                                value={formatDate(item.originaltaskstartdate)}
                                style={{ width: "155px" }}
                                className={modules["normalInput"]}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                disabled={
                                  rowStates[item.taskid]?.txtEndDateDisabled
                                }
                                value={formatDate(item.originaltaskenddate)}
                                style={{ width: "155px" }}
                                className={modules["normalInput"]}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={getTaskValueById(
                                  item.taskid,
                                  "predecessor"
                                )}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value) || value === "") { // Allow only numbers
                                    handleInputChange(e, item.taskid, "predecessor");
                                  }
                                }}
                                disabled={
                                  item.taskid === 1 ||
                                  rowStates[item.taskid]?.txtPredecessorDisabled
                                }
                                style={{ width: "70px" }}
                                className={modules["normalInput"]}
                              />
                            </td>
                            <td align="center">
                              <input
                                type="text"
                                value={getTaskValueById(
                                  item.taskid,
                                  "successor"
                                )}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value) || value === "") { // Allow only numbers
                                    handleInputChange(e, item.taskid, "successor");
                                  }
                                }}
                                disabled={
                                  rowStates[item.taskid]?.txtSuccessorDisabled
                                }
                                style={{ width: "70px" }}
                                className={modules["normalInput"]}
                              />
                            </td>
                            <td align="center">
                              <select
                                style={{ width: "115px" }}
                                className={modules["dropdownSelect1PT"]}
                                value={getTaskValueById(
                                  item.taskid,
                                  "taskmanagerid"
                                )}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    item.taskid,
                                    "taskmanagerid"
                                  )
                                }
                              >
                                <option value={-1}>SELECT</option>
                                <option
                                  value={sessionStorage.getItem("loginId")}
                                >
                                  {sessionStorage.getItem("Username")}
                                </option>
                                {managerNames?.length > 0 &&
                                  managerNames?.map((i) => (
                                    <option
                                      key={i.id}
                                      value={i.id}
                                      style={{ color: "#373736" }}
                                    >
                                      {i.username}
                                    </option>
                                  ))}
                              </select>
                            </td>
                            <td align="left" style={{ width: "10px" }}>
                              <input
                                type="checkbox"
                                checked={
                                  getTaskValueById2(
                                    item.taskid,
                                    "noncrushable"
                                  ) === 1
                                }
                                onChange={(event) =>
                                  handleCheckboxChange2(
                                    event,
                                    item.taskid,
                                    "noncrushable"
                                  )
                                }
                              />
                            </td>
                            <td align="center" style={{ width: "50px" }}>
                              <textarea
                                value={getTaskValueById(
                                  item.taskid,
                                  "taskprocessnotes"
                                )}
                                onBlur={(e) => {
                                  taskProcessBlur(item);
                                }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex =
                                    /^[a-zA-Z0-9&()_\-+=?/ \[\].]*$/;
                                  if (regex.test(value)) {
                                    handleInputChange(
                                      e,
                                      item.taskid,
                                      "taskprocessnotes"
                                    );
                                  }
                                }}
                                className={modules["txtboxPT2Exp"]}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ProjectTasks;
