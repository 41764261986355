import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import IAI1 from "../HelpPopups/images/IAI1.png";
import IAI2 from "../HelpPopups/images/IAI2.png";
import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const InstanceActiveInActivehelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h5 style={{ marginLeft: "5rem", textAlign: "center" }}>
                                User Instance Active/InActive
                            </h5>
                            <br />
                            <li className="no-bullets">
                                This session helps to mark instance active / inactive. In DSS module, only those instances will appear in instance dropdown which are defined active(ticked) in this session. Also, job execution can also be control using this session. i.e. DSS jobs will execute only for Active instances.
                            </li>
                            <br />

                            <br />


                            <li className="no-bullets">
                                This session will be used by User Admin.
                            </li>
                            <br />
                            <br />


                            <li className="no-bullets">
                                Path: DSS `{">>"}` Admin `{">>"}` User Instance Active/InActive
                            </li>
                            <br />


                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={IAI1} alt="IAI1" />
                            </Stack>
                            <br />

                            <li className="no-bullets">
                                Note: The instances ticked in this session are marked as “Active”.
                            </li>
                            <br />

                            <li className="no-bullets" style={{ textDecoration: "underline" }}>
                                For activating/deactivating the instance, the following steps needs to be done:
                            </li>
                            <br />
                            <li>
                                Tick the checkbox in front of the instance for activating the instance.
                            </li>
                            <li>
                                Untick the checkbox in front of the instance for deactivating the instance.
                            </li>
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={IAI2} alt="IAI2" />
                            </Stack>

                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default InstanceActiveInActivehelpPopup;
