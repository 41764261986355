import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
import modules from "./../ccpm.module.css";
import Box from "@mui/material/Box";
import { Tab, Tabs } from "@mui/material";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Calendar from "react-calendar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetPCSearchResult,
  GetPCProjectCount,
  GetPCallprojectdetails,
  GetTWBCProjectManager,
  GetTWBCSearchResult,
  STFGetProjects,
  DTFGetTaskManagers,
} from "../../../Services/CCPM.js";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

const DummyData = [
  {
    id: 1,
    col2: "Aerospace",
    col3: "Aerospace",
  },
  {
    id: 2,
    col2: "Innovation and Design Center",
    col3: "IDC",
  },
  {
    id: 3,
    col2: "GB Corporate Projects",
    col3: "GB_CORP",
  },
  {
    id: 4,
    col2: "PSS Gogrej Security Solutions",
    col3: "SSD_PSS",
  },
  {
    id: 5,
    col2: "G&B Finance",
    col3: "G&B Finance",
  },
];

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const TaskwiseBufferConsumption = () => {
  const [searchValue, setSearchValue] = useState("");
  // State for selected values
  const [selectedInstance, setSelectedInstance] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [greenTableData, setGreenTableData] = useState([]);
  const [yellowTableData, setYellowTableData] = useState([]);
  const [redTableData, setRedTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [selectedAccessType, setSelectedAccessType] = useState("SELECT");
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("1");
  const [selectedmaximumYAxis, setselectedmaximumYAxis] = useState("100");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [taskManagers, setTaskManagers] = useState([]);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");

  const [dropdownData, setDropdownData] = useState([
    "Select",
    "Instance 2",
    "Instance 3",
  ]);
  const [filteredData, setFilteredData] = useState(DummyData);
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedTaskManager, setSelectedTaskManager] = useState(0);
  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [dataChart, setDataChart] = useState({});
  const [dataOptions, setDataOptions] = useState({});
  const [displayfromdate, setdisplayfromdate] = useState("");
  const [displaytodate, setdisplaytodate] = useState("");
  const handleFromDateChange = (date) => {
    setFromdate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplayfromdate(`${day}/${month}/${year}`);
    setCalendarOpen(false);
  };

  const handleToDateChange = (date) => {
    setTodate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplaytodate(`${day}/${month}/${year}`);
    setCalendarToOpen(false);
  };

  const handleSelectChange = (attributenumber, id) => {
    // console.log(attributenumber, id, "attributenumber value");
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [radioValue, setRadioValue] = useState("yes");

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarToOpen, setCalendarToOpen] = useState(false);
  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());

  const [instances, setInstances] = useState([]);
  const [zones, setZones] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [accessTypes, setAccessTypes] = useState([
    { value: "null", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ]);
  const [projectStatuses, setProjectStatuses] = useState([
    { value: "0", label: "All" },
    { value: "1", label: "Active" },
    { value: "7", label: "Completed" },
    { value: "5", label: "Hold" },
  ]);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
      getTaskManagers();
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    STFGetProjects(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    GetTWBCProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTaskManagers = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    DTFGetTaskManagers(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "task managers");
          setTaskManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      accessType: selectedProjectAccessType,
      taskMgrId: selectedTaskManager,
      dateFrom: "",
      dateTo: "",
    };
    GetTWBCSearchResult(body)
      .then((response) => {
        setIsLoading(false);

        if (response?.data?.statusCode === 200) {
          setShowResult(true);
          setChartData(response?.data?.success?.data);
        } else {
          setShowResult(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setShowResult(true);
      });
  };

  const getProjectCount = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      accessType: selectedProjectAccessType,
      projStatus: selectedProjectStatus,
    };
    GetPCProjectCount(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project detail");
          const [greenData, yellowData, redData] =
            response?.data?.success?.data;
          // const flattenedData = response?.data?.success?.data.flat();
          setGreenTableData(greenData);
          setYellowTableData(yellowData);
          setRedTableData(redData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getallprojectdetails = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      accessType: selectedProjectAccessType,
      projStatus: selectedProjectStatus,
    };
    GetPCallprojectdetails(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project all detail");
          const tabsVal = response?.data?.success?.data;
          const greenCount = tabsVal[0][0]?.greenprjcnt || 0;
          const yellowCount = tabsVal[1][0]?.yellowprjcnt || 0;
          const redCount = tabsVal[2][0]?.redprjcnt || 0;
          setTabData([redCount, yellowCount, greenCount]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic
    console.log({
      selectedInstance,
      selectedZone,
      selectedProject,
      selectedManager,
      selectedAccessType,
      selectedProjectStatus,
    });
  };

  const handleSearch = () => {
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    getSearchResult(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  const handleReset = () => {
    setSearchValue("");
    setFilteredData(DummyData);
  };

  const toggleCalendar = () => {
    setCalendarOpen((prevOpen) => !prevOpen);
  };

  const toggleToCalendar = () => {
    setCalendarToOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"TaskwiseBufferConsumption"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "5px" }}
            >
              <div className={modules["pro-bread"]}>
                <a>Reports</a>
                <img src={bread} alt="" />
                <a>Task Wise Buffer Consumption Report</a>
              </div>
            </div>
            <div className={modules["border_cont"]}>
              <table className={modules["PCtable"]}>
                <tbody>
                  <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>
                        Instance :<label style={{ color: "Red" }}>*</label>
                      </span>

                      <select
                        value={selectedInstance}
                        onChange={(e) => {
                          setSelectedInstance(e.target.value);
                          getProjectDetails(e.target.value);
                          setShowResult(false);
                        }}
                        className={modules["dropdownSelect"]}
                        // style={{ width: "200px" }}
                      >
                        {instances?.map((instance) => (
                          <option key={instance.name} value={instance.id}>
                            {instance.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    {hierarchyOptions
                      ?.filter((option) => option.isactive === 1)
                      .map((option, index) => (
                        <React.Fragment key={option.attributename}>
                          <td className={modules["table_data1"]}>
                            <span style={{ width: "120px" }}>
                              {option.attributename} :
                            </span>
                            <select
                              value={
                                selectedAttributes[
                                  `attr${option.attributenumber}`
                                ] || ""
                              }
                              onChange={(e) =>
                                handleSelectChange(
                                  option.attributenumber,
                                  e.target.value
                                )
                              }
                              className={modules["dropdownSelect"]}
                              // style={{ width: "200px" }}
                            >
                              <option value="">SELECT</option>
                              {filteredValueData[index]?.map((item) => (
                                <option
                                  key={item.attributevalue}
                                  value={item.id}
                                >
                                  {item.attributevalue}
                                </option>
                              ))}
                            </select>
                          </td>
                        </React.Fragment>
                      ))}
                  </tr>
                  <tr
                    style={{
                      paddingBottom: "10px",
                      paddingLeft: "0px",
                    }}
                  >
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>Project Name :</span>
                      <select
                        value={selectedProjectName}
                        onChange={(e) => setSelectedProjectName(e.target.value)}
                        className={modules["dropdownSelect"]}
                        // style={{ width: "200px" }}
                      >
                        <option value={0}>SELECT</option>
                        {projects?.map((p_name) => (
                          <option key={p_name.projectname} value={p_name.id}>
                            {p_name.projectname}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>Project Manager :</span>
                      <select
                        value={selectedProjectManager}
                        onChange={(e) =>
                          setSelectedProjectManager(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        <option value={0}>SELECT</option>
                        {managers?.map((m_name) => (
                          <option key={m_name.username} value={m_name.id}>
                            {m_name.username}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>
                        Project AccessType :
                      </span>
                      <select
                        value={selectedProjectAccessType}
                        onChange={(e) =>
                          setSelectedProjectAccessType(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        {accessTypes?.map((access) => (
                          <option key={access.label} value={access.value}>
                            {access.label}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>Task Manager :</span>
                      <select
                        value={selectedTaskManager}
                        onChange={(e) => setSelectedTaskManager(e.target.value)}
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        <option>SELECT</option>
                        {taskManagers?.map((access) => (
                          <option key={access.taskmanager} value={access.id}>
                            {access.taskmanager}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px", marginRight: "0.5rem" }}>
                        Task Completion From Date :
                      </span>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "8px",
                        }}
                      >
                        <input
                          className={modules["datepicker2"]}
                          type="text"
                          readOnly
                          value={displayfromdate}
                          placeholder="dd/MM/yyyy"
                          onClick={toggleCalendar}
                        />
                        {calendarOpen && (
                          <div
                            className={modules["fromcalendar"]}
                            style={{ position: "absolute", zIndex: 2 }}
                          >
                            <Calendar
                              onChange={handleFromDateChange}
                              value={fromdate}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px", marginRight: "0.5rem" }}>
                        To Date :
                      </span>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "8px",
                        }}
                      >
                        <input
                          className={modules["datepicker2"]}
                          type="text"
                          readOnly
                          value={displaytodate}
                          placeholder="dd/MM/yyyy"
                          onClick={toggleToCalendar}
                        />
                        {calendarToOpen && (
                          <div
                            className={modules["fromcalendar"]}
                            style={{ position: "absolute", zIndex: 2 }}
                          >
                            <Calendar
                              onChange={handleToDateChange}
                              value={todate}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr
                    style={{
                      justifyContent: "center",
                      gap: "10px",
                      paddingLeft: "0px",
                      paddingBottom: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      className={modules["PCbtn"]}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["PCbtn"]}
                      type="button"
                      onClick={() => {
                        window.location.reload();
                        setShowResult(false);
                      }}
                      // onClick={() => {
                      //   setSelectedInstance("");
                      //   setSelectedZone("");
                      //   setSelectedProject("");
                      //   setSelectedManager("");
                      //   setSelectedAccessType("SELECT");
                      //   setSelectedProjectStatus("0");
                      // }}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>

            {showResult ? (
              chartData.length > 0 ? (
                <>
                  <Grid
                    container
                    sx={{ marginLeft: "5.5rem", padding: "8px", width: "90%" }}
                  >
                    <Table className={modules["IM_custom-table"]}>
                      <TableHead>
                        <TableRow sx={{ verticalAlign: "text-bottom" }}>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Sr.No.
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Project&nbsp;Name
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Project Manager
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Task Name
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Department
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Task Manager
                          </TableCell>
                          <TableCell
                            sx={{ lineHeight: "normal !important" }}
                            className={modules["IM_alignC"]}
                          >
                            Planned Duration With Safety(After CCPM)
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Actual Duration
                          </TableCell>
                          <TableCell
                            sx={{ lineHeight: "normal !important" }}
                            className={modules["IM_alignC"]}
                          >
                            Days Buffer Consumed More Than Safety
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Reason for Delay
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Secondary Reason for Delay
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {chartData.map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{ verticalAlign: "text-bottom" }}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.projectname}</TableCell>
                            <TableCell>{item.projectmanager}</TableCell>
                            <TableCell>{item.taskname}</TableCell>
                            <TableCell>{item.department}</TableCell>
                            <TableCell>{item.taskmanager}</TableCell>
                            <TableCell className={modules["IM_alignC"]}>
                              {item.planneddurationwithsafety?.toFixed(2)}
                            </TableCell>
                            <TableCell>{item.actualduration}</TableCell>
                            <TableCell className={modules["IM_alignC"]}>
                              {item.daysbufferconsumedmorethansafety?.toFixed(
                                2
                              )}
                            </TableCell>
                            <TableCell>{item.reasonfordelay}</TableCell>
                            <TableCell>
                              {item.secondaryreasonfordelay}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </>
              ) : (
                <TableRow
                  className={modules["table-cell"]}
                  sx={{
                    textAlign: "left !important",
                    display: "flex",
                    borderBottom: "1px solid #a4bf47",
                    marginLeft: "7rem",
                    marginTop: "2rem",
                    fontSize: "12px",
                    color: "#414141",
                    padding: "8px",
                    width: "112px",
                  }}
                >
                  No Records Found.
                </TableRow>
              )
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TaskwiseBufferConsumption;
