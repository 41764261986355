import React, { useEffect, useState } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
// import modules from "./IssueCategory.module.css";
import modules from "../ccpm.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";
import Sidebar from "../../../Landing/Navbar";
import {
  IC_tableRowDetails,
  IC_saveRow,
  IC_deleteRow,
  IC_UpdateRow,
  IC_BulkDeleteRole,
  GetRolesDetails,
  InseertNewRole,
  DeleteSingleRole,
  ChangeRole,
} from "../../../Services/Admin";
import SampleSidebar from "../../../Landing/SampleSidebar";

const IssueCategory = () => {
  const [data, setData] = useState([]);
  const [serialNumber, setSerialNumber] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [rolesData, setrolesData] = useState([]);
  const [tableData, settableData] = useState([]);

  const [newRoleName, setNewRoleName] = useState("");
  const [isAddRowVisible, setIsAddRowVisible] = useState(false);
  const [tabelUpdate, settabelUpdate] = useState(false);

  const [editing, setEditing] = useState(null);
  const [editedRoleName, setEditedRoleName] = useState("");

  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  // const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getDetails();
  }, [tabelUpdate]);

  //To get the table data

  function getDetails() {
    IC_tableRowDetails()
      .then((response) => {
        console.log(response);
        if (response.statusCode === 200) {
          settableData(response?.success?.data);
        } else {
          // alert(response?.error?.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //To save a new issue

  function handleSave(element) {
    element.style.color = "red";
    setTimeout(() => {
    const createdBy = sessionStorage.getItem("loginId");
    const body = {
      issueCategory: newRoleName,
      createdBy: createdBy,
    };
    IC_saveRow(body)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsAddRowVisible(false);
          setNewRoleName("");
          settabelUpdate(!tabelUpdate);
          element.style.color = "#0000EE";
        } else if (response?.response?.data?.statusCode === 400) {
          alert(
            response?.response?.data?.error?.message || " Please try again."
          );
          settabelUpdate(!tabelUpdate);
          element.style.color = "#0000EE";
        } else {
          alert("An error occurred. Please try again.");
          element.style.color = "#0000EE";
        }
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          alert("Role name already exists.");
        } else {
          console.error("Error inserting role:", error);
          alert("An error occurred. Please try again.");
        }
      });
      element.style.color = "#0000EE";
    }, 100);
    // } else {
    //   alert("Enter role name");
    // }
  }

  //To delete a single issue

  function handleDeleteSingleEntry(I_id, element) {
    element.style.color = "red";

    setTimeout(() => {
      if (window.confirm("Are you sure you want to delete this record?")) {
        let lastModifiedBy = sessionStorage.getItem("loginId");
        const body = {
          id: I_id,
        };
        IC_deleteRow(body)
          .then((response) => {
            // alert("Details Deleted successfully")
            settabelUpdate(!tabelUpdate);
            element.style.color = "#0000EE"; // Reset to original color
          })
          .catch((error) => {
            console.log(error);
            // Reset color if there's an error
            element.style.color = "#0000EE"; // Reset to original color
          });
      } else {
        // Reset color if the user cancels
        element.style.color = "#0000EE"; // Reset to original color
      }
    }, 100); // setTimeout with 0 delay to allow UI update before confirm
  }

  //To Update a issue

  function handleSaveEdit(roleid, element) {
    element.style.color = "red";
    setTimeout(() => {
      const createdBy = sessionStorage.getItem("loginId");

      const body = {
        id: roleid,
        issueCat: editedRoleName,
        isActive: 1,
        lastModifiedBy: createdBy,
      };

      if (editedRoleName) {
        IC_UpdateRow(body)
          .then((response) => {
            //console.log(response);
            console.log("Role updated successfully:", response);
            settabelUpdate(!tabelUpdate);
            setEditing(null);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      element.style.color = "#0000EE";
    }, 100);
  }

  //To delete a issues in bulk

  function handleBulkDelete() {
    console.log(selectedRows);
    if (selectedRows.length > 0) {
      const selectedData = selectedRows.map((IC_id) => {
        const instanceid = IC_id;
        // const instanceid = data.find((item) => item === IC_id);
        console.log(instanceid, "rr");
        return {
          id: `${instanceid}`,
        };
      });
      IC_BulkDeleteRole(selectedData)
        .then((response) => {
          settabelUpdate(!tabelUpdate);
          setSelectedRows([]);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Select rows to delete");
    }
  }

  //----------------------------------------------------------------------------------------

  function handleClose(element) {
    element.style.color = "red";
    setTimeout(() => {
      setIsAddRowVisible(false);
      setNewRoleName("");
      element.style.color = "#0000EE";
    }, 100);
  }

  function handleInsert() {
    setIsAddRowVisible(true);
  }

  function handleEditSingleEntry(roleid, rolename, element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(roleid);
      setEditedRoleName(rolename);
      element.style.color = "#0000EE";
    }, 50);
  }

  function handleCancelEdit(element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(null);
      element.style.color = "#0000EE";
    }, 100);
  }

  function handleCheckboxChange(event, roleId) {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, roleId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== roleId));
    }
    console.log(selectedRows);
  }

  const toggleSelectAll = () => {
    if (selectAll) {
      // Deselect all rows
      setSelectedRows([]);
    } else {
      // Select all rows from the searchData state
      //console.log(searchData);

      const allRowIds = tableData.map((item) => item.id);
      setSelectedRows(allRowIds, "defcecf");
      console.log(tableData);
    }
    // Toggle the selectAll state
    setSelectAll(!selectAll);
  };

  function searchHandler() {
    if (selectedRole) {
      //alert(selectedRole)
      getDetails(selectedRole);
      console.log(rolesData);
      const filteredData = rolesData.filter(
        (item) => item.roleName === selectedRole
      );
      setSearchData(filteredData);
    }
  }

  function resetHandler() {
    setSearchData(data);
    setSelectedRole("");
  }

  //---------------------------------------------------------------------

  return (
    <Grid container>
      <Grid item lg={0.6} className={modules["main_cont"]}>
        <SampleSidebar />
      </Grid>
      <Grid item lg={12} sx={{ display: "block" }}>
        <Grid item lg={12} className={modules["home-cont"]}>
          <Home page={"IssueCategory"} />

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Issue Category Master</a>
              </div>
            </div>

            <Grid sx={{ marginLeft: "1rem" }}>
              <Grid container className={modules["mid-cont"]}>
                <Grid container className={modules["table-container"]}>
                  <TableContainer>
                    <Table
                      className={modules["custom-table"]}
                      style={{ width: "50%" }}
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            "& th": {
                              color: "#fff",
                            },
                          }}
                        >
                          <TableCell padding="checkbox">
                            <input
                              type="checkbox"
                              inputProps={{
                                "aria-label": "select all desserts",
                              }}
                              checked={selectAll}
                              className={modules["custom-checkbox"]}
                              onChange={toggleSelectAll}
                            />
                          </TableCell>
                          <TableCell>Sr.No.</TableCell>
                          <TableCell align="left">
                            Issue Category Master
                          </TableCell>
                          <TableCell align="left">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData?.map((i, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              key={i.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell padding="checkbox">
                                <input
                                  type="checkbox"
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                  checked={selectedRows.includes(i.id)}
                                  className={modules["custom-checkbox"]}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, i.id)
                                  }
                                />
                              </TableCell>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell align="left">
                                {editing === i.id ? (
                                  <input
                                    type="text"
                                    value={editedRoleName}
                                    onChange={(e) => {
                                      setEditedRoleName(e.target.value);
                                      console.log(e.target.value);
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <span>{i.issuecategory}</span>
                                  </div>
                                )}
                              </TableCell>
                              <TableCell
                                // className={modules["editlink"]}
                                align="left"
                                // sx={{ color: "#0000EE !important" }}
                              >
                                {editing === i.id ? (
                                  <>
                                    <a
                                      style={{
                                        paddingRight: "1rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        handleSaveEdit(i.id, e.currentTarget)
                                      }
                                    >
                                      Update
                                    </a>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        handleCancelEdit(e.currentTarget)
                                      }
                                    >
                                      Cancel
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <a
                                      style={{
                                        paddingRight: "1rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        handleEditSingleEntry(
                                          i.id,
                                          i.issuecategory,
                                          e.currentTarget
                                        )
                                      }
                                    >
                                      Edit
                                    </a>
                                    <a
                                      style={{
                                        paddingRight: "1rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        handleDeleteSingleEntry(
                                          i.id,
                                          e.currentTarget
                                        )
                                      }
                                    >
                                      Delete
                                    </a>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}

                        {isAddRowVisible && (
                          <TableRow>
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell style={{background: "#fff"}} component="th" scope="row">
                              {/* {data?.length + 1} */}
                            </TableCell>
                            <TableCell style={{background: "#fff"}} component="th" scope="row">
                              <input
                                style={{ paddingLeft: "0.5rem" }}
                                type="text"
                                value={newRoleName}
                                onChange={(e) => setNewRoleName(e.target.value)}
                              />
                            </TableCell>

                            <TableCell
                              className="editlink"
                              align="left"
                              sx={{ color: "#0000EE" }}
                            >
                              <a
                                style={{
                                  color: "#0000EE",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handleSave(e.currentTarget)}
                              >
                                Save
                              </a>

                              <a
                                style={{
                                  color: "#0000EE",
                                  textDecoration: "underline",
                                  marginLeft: "0.6rem",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handleClose(e.currentTarget)}
                              >
                                Cancel
                              </a>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ marginTop: "18px", marginBottom: "20px" }}
                >
                  <button
                    type="button"
                    className={modules["IM_btn"]}
                    onClick={handleInsert}
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    className={modules["IM_btn"]}
                    onClick={handleBulkDelete}
                  >
                    Delete
                  </button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IssueCategory;
