import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import apm1 from "./images/APM1.jpg";
import apm2 from "./images/APM2.jpg";
import apm3 from "./images/APM3.jpg";
import apm4 from "./images/APM4.jpg";
import apm5 from "./images/APM5.jpg";
import apm6 from "./images/APM6.jpg";
import apm7 from "./images/APM7.jpg";
import apm8 from "./images/APM8.jpg";
import apm9 from "./images/APM9.jpg";
import { Link } from "react-router-dom";

const AlternatePMhelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              paddingLeft: "1rem",
              fontWeight: "bold",
              fontFamily: "Times New Roman",
            }}
          >
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid
        sx={{
          fontFamily: '"Calibri", sans-serif',
          fontSize: "14.6px",
          color: "#000000",
        }}
      >
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ fontSize:'26.667px',fontWeight: "bold" }}>ALTERNATE PROJECT MANAGER</h3>
              <br />
              <li style={{ listStyleType: "none" }}>
                This session is provided user the flexibility to maintain the
                alternate project manager. Here user can maintain what type of
                access to be given to the alternate project manager. Also user
                can define the time frame for the access.
              </li>
              <br />
              <li style={{ listStyleType: "none", fontWeight: "bold" }}>
                Session Path - CCPM &gt;&gt; Project Plan &gt;&gt; Alternate
                Project Manager
              </li>
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={apm1} alt="myproject1" />
              </Stack>
              <br />
              <li style={{ listStyleType: "none" }}>
                Once you select this option, you can see 2 options mentioned
                below.
                <br />
                <ul style={{ marginLeft: "3rem", listStyleType: "disc" }}>
                  <br />
                  <li>
                    Add Alternate Manager – User can add alternate project
                    manager from this option.
                  </li>
                  <li>
                    Edit Alternate Manager – User can edit access given to
                    alternate project manager in this option.
                  </li>
                  <br />
                </ul>
              </li>
              Add Alternate Manager -
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={apm2} alt="myproject2" />
              </Stack>
              <br />
              Instance – Select Instance code from the drop down provided.
              <br />
              Alternate Project Manager (To Be) – Here user need to input the
              user id of the person who is going to be maintained as alternate
              project manager.
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={apm3} alt="myproject2" />
              </Stack>
              <br />
              Once Alternate project manager is selected then the It will ask
              for access Type to be given. A drop down is provided for the
              access type. User need to check what type of access to given to
              the user.
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={apm4} alt="myproject2" />
              </Stack>
              <br />
              <ul style={{ marginLeft: "3rem", listStyleType: "disc" }}>
                <br />
                <li>
                  All Projects – This indicates that the access is given for all
                  projects for that instance.
                </li>
                <li>
                  Selected Projects – This indicates that the access will be
                  given only for the selected projects.
                </li>
                <li>
                  All projects of Selected PM – This indicates that the access
                  will be given for the project belong to be same project
                  manager.
                </li>
              </ul>
              A) All Projects –
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={apm5} alt="myproject2" />
              </Stack>
              <br />
              <ul style={{ marginLeft: "3rem", listStyleType: "disc" }}>
                <li>
                  Access From Date – User need to select date from which access
                  to be grant as alternate project manager.
                </li>
                <li>
                  Access To Date – User can define the date till which the
                  access to be granted as alternate project manager.
                </li>
                <li>
                  View Project Access Only – Here there is provision given for
                  the user to grant only view access for the alternate project
                  manager. It means user can not do any change / modify / delete
                  the project information. If this is not ticked, then alternate
                  manger has the all writes that the project manger is having.
                </li>
              </ul>
              <br />
              B) Selected Projects
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={apm6} alt="myproject2" />
              </Stack>
              <br />
              <ul style={{ marginLeft: "3rem", listStyleType: "disc" }}>
                <li>
                  Here user can select the single or multiple projects for which
                  he want to give access as alternate project manager.
                </li>
                <li>
                  Access From Date – User need to select date from which access
                  to be grant as alternate project manager.
                </li>
                <li>
                  Access To Date – User can define the date till which the
                  access to be granted as alternate project manager.
                </li>
                <li>
                  View Project Access Only – Here there is provision given for
                  the user to grant only view access for the alternate project
                  manager. It means user cannot do any change / modify / delete
                  the project information. If this is not ticked, then alternate
                  manger has the all writes that the project manager is having.
                </li>
              </ul>
              <br />
              C) All Projects of Selected PM –
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={apm7} alt="myproject2" />
              </Stack>
              <br />
              <ul style={{ marginLeft: "3rem", listStyleType: "disc" }}>
                <li>
                  Project Manager – Here user can select the project manager for
                  which he want to given an alternate project manager. Once this
                  is done then access will be given for all project where
                  project manager is the selected person.
                </li>
                <li>
                  Access From Date – User need to select date from which access
                  to be grant as alternate project manager.
                </li>
                <li>
                  Access To Date – User can define the date till which the
                  access to be granted as alternate project manager.
                </li>
                <li>
                  View Project Access Only – Here there is provision given for
                  the user to grant only view access for the alternate project
                  manager. It means user cannot do any change / modify / delete
                  the project information. If this is not ticked, then alternate
                  manger has the all writes that the project manager is having.
                </li>
              </ul>
              <br />
              Edit Alternate Manager – If this option is selected then following
              screen is opened.
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={apm8} alt="myproject2" />
              </Stack>
              <br />
              <ul style={{ marginLeft: "3rem", listStyleType: "disc" }}>
                <li>
                  Instance – Select Instance code from the drop down provided.
                </li>
                <li>
                  Alternate Project Manager – Select alternate project manager
                </li>
              </ul>
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={apm9} alt="myproject2" />
              </Stack>
              <br />
              Here user can edit the information provided for the alternate
              project manager. Also he can delete the alternate project manager.
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AlternatePMhelpPopup;
