import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import {
  Set_MtaActiveUpdateAll,
  Set_MtaActiveUpdateSingle,
  Set_MtaActiveData,
  DBM_GetDBMData,
  DBM_Update_ProposedAction,
  DBM_IgnoredAction,
  DBM_TOC_Item_Stock_Graph
} from "../../../Services/MTA.js";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation, useNavigate } from "react-router-dom";

export default function DBMdata() {
  const location = useLocation();
  const nData = location.state?.nData;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [changed, setChanged] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getTableData();
    console.log(nData);
  }, []);

  const handleCheckboxChange = (
    checked,
    instanceId,
    dbm_stock_point_code,
    dbm_company_code,
    dbm_proposed_action,
    dbm_item_code
  ) => {
    const newRow = {
      instanceUid: instanceId,
      stockPointCode: dbm_stock_point_code,
      companyCode: dbm_company_code,
      proposed_action: dbm_proposed_action + " - Take Action",
      itemCode: dbm_item_code,
    };

    if (checked) {
      // Add the object to the selected rows
      setSelectedRows((prevRows) => [...prevRows, newRow]);
    } else {
      // Remove the object if unchecked
      setSelectedRows((prevRows) =>
        prevRows.filter((row) => row.instanceUid !== instanceId)
      );
    }

    console.log(selectedRows, "sr");
  };

  const getTableData = () => {
    setIsLoading(true);
    const body = {
      instanceid: nData.instanceid,
      stock_point: nData.stock_point,
      company_code: nData.company_code,
      item_code: nData.item_code,
      stock_point_type: nData.stock_point_type,
      proposed_action: nData.proposed_action,
      statisticalGroup: nData.statisticalGroup,
      itemCategory: nData.itemCategory,
    };

    DBM_GetDBMData(body)
      .then((response) => {
        setTableData(response?.data?.success?.data);
        const allSelected = response?.data?.success?.data.every(
          (item) => item.isselected === 1
        );
        setAllSelected(false);
        console.log(response?.data?.success?.data);
        setIsLoading(false);
        setSelectedRows([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      const filteredRows = tableData.filter(
        (row) =>
          row.dbm_proposed_action === "Increase" ||
          row.dbm_proposed_action === "Decrease"
      );

      const allRows = filteredRows.map((row) => ({
        instanceUid: row.dbm_instance_uid,
        stockPointCode: row.dbm_stock_point_code,
        companyCode: row.dbm_company_code,
        proposed_action: row.dbm_proposed_action,
        itemCode: row.dbm_item_code,
      }));
      setSelectedRows(allRows);
      console.log(allRows, "allRows");
      setAllSelected(true);
    } else {
      setSelectedRows([]);
      setAllSelected(false);
      console.log(selectedRows);
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const navigate = useNavigate();

  const updateHandler = () => {
    setIsLoading(true);
    const body = selectedRows;

    console.log(body);

    if (body.length === 0) {
      alert("No Record Selected");
      setIsLoading(false);
      return;
    } else {
      DBM_Update_ProposedAction(body)
        .then((response) => {
          console.log(response?.data?.success?.data);
          setIsLoading(false);
          alert(response?.data?.success?.message);
          getTableData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // i.dbm_instance_uid,
  // i.dbm_stock_point_code,
  // i.dbm_company_code,
  // i.dbm_item_code,
  // i.i_sales_statistical_group,
  // i.dbm_buffer,
  // i.dbm_buffer_new,
  // i.dbm_proposed_action,
  // i.i_item_desc,
  // i.rundatefrom,
  // i.rundateto

  const handleViewDetails = (
    element,
    instanceid,
    stock_point,
    company_code,
    item_code,
    sales_statistical_group,
    buffer,
    buffer_new,
    proposed_action,
    item_desc,
    rundatefrom,
    rundateto
  ) => {
    setTimeout(() => {
      element.style.color = "red";

      setTimeout(() => {
        element.style.color = "#0000EE";
        const Tdata = {
          instanceid,
          stock_point,
          company_code,
          item_code,
          sales_statistical_group,
          buffer,
          buffer_new,
          proposed_action,
          item_desc,
          rundatefrom,
          rundateto,
        };

        const url = new URL(
          "/MTA/MTA_TOC_Item_Stock_Graph",
          window.location.origin
        );
        url.search = new URLSearchParams(Tdata).toString();
        window.open(url.toString(), "_blank", "width=700,height=500");
      }, 100);
    }, 100);
  };

  const backHandler = () => {
    navigate("/mtaparameter/DBM", { state: { nData } });
  };

  const handleIgnore = (
    element,
    instanceUid,
    stockPointCode,
    companyCode,
    itemCode
  ) => {
    setTimeout(() => {
      element.style.color = "red";

      setTimeout(() => {
        element.style.color = "#0000EE";
        setIsLoading(true);
        const body = {
          instance_uid: instanceUid,
          stock_point_code: stockPointCode,
          company_code: companyCode,
          item_code: itemCode,
        };

        console.log(body);
        DBM_IgnoredAction(body)
          .then((response) => {
            console.log(response?.data?.success?.data);
            setIsLoading(false);
            alert("Record Ignored Successfully");
            getTableData();
          })
          .catch((error) => {
            console.log(error);
          });
      }, 100);
    }, 100);
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid
        container
        style={{
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12}>
            <Grid>
              <Grid container className={modules["mid-cont"]}>
                <TableContainer>
                  <Table
                    className={modules["custom_table"]}
                    style={{ width: "80%" }}
                  >
                    <TableHead>
                      <TableRow style={{ borderRadius: "0px 5px" }}>
                        <TableCell
                          align="left"
                          colSpan={3}
                          style={{
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                          }}
                        >
                          MTA Parameter {">>"} DBM
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                          }}
                        >
                          <span
                            style={{ padding: "0px 4px", cursor: "pointer" }}
                            onClick={toggleExpand}
                          >
                            {isExpanded
                              ? "Collapse Filters [-]"
                              : "Expand Filters [+]"}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {isExpanded && (
                      <tbody
                        className={`${modules["expand-collapse"]} ${
                          isExpanded ? modules["expanded"] : ""
                        }`}
                        style={{ border: "1px solid #a4bf47", width: "200%" }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>MTA Instance</strong>
                          </td>
                          <td colSpan="3" style={{ textAlign: "left" }}>
                            <span id="ContentPlaceHolder1_lblFilterMTA">
                              {nData.instanceid}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Stock Point Type</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {nData.stock_point_type
                                ? nData.stock_point_type
                                : "-"}
                            </span>
                          </td>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Stock Point</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {nData.stock_point ? nData.stock_point : "-"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Company Code</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {nData.company_code ? nData.company_code : "-"}
                            </span>
                          </td>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Item Code</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {nData.item_code ? nData.item_code : "-"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>SSG</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {nData.statisticalGroup
                                ? nData.statisticalGroup
                                : "-"}
                            </span>
                          </td>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Item Category</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {nData.itemCategory ? nData.itemCategory : "-"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Proposed Action</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {nData.proposed_action
                                ? nData.proposed_action
                                : "-"}
                            </span>
                          </td>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong></strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </TableContainer>
                <Grid
                  container
                  align="left"
                  style={{
                    marginTop: "10px",
                    justifyContent: "left",
                    marginRight: "6rem",
                    width: "100px",
                  }}
                >
                  <button
                    className={modules["btn1"]}
                    style={{ width: "100px" }}
                    onClick={backHandler}
                  >
                    Back
                  </button>
                </Grid>
              </Grid>
              <Grid container className={modules["mid-cont"]}>
                {tableData.length > 0 ? (
                  <>
                    <TableContainer
                      // style={{
                      //   maxHeight: "90vh",
                      // }}
                    >
                      <Table
                        className={modules["custom_table"]}
                        style={{ width: "auto" }}
                      >
                        <TableHead
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            backgroundColor: "white",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{ width: "20px", padding: "12px" }}
                            >
                              <input
                                type="checkbox"
                                checked={allSelected}
                                className={modules["custom-checkbox"]}
                                onChange={(e) =>
                                  handleSelectAll(e.target.checked)
                                }
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ padding: "12px" }}
                            >
                              Sr.No.
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: "150px", padding: "12px" }}
                            >
                              Stock Point Code
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: "110px" }}
                            >
                              Company Code
                            </TableCell>
                            <TableCell align="left" style={{ width: "140px" }}>
                              Item Code
                            </TableCell>
                            <TableCell align="left" style={{ width: "160px" }}>
                              Item Desc
                            </TableCell>
                            <TableCell align="left" style={{ width: "60px" }}>
                              SSG
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: "130px" }}
                            >
                              Current Buffer
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: "110px" }}
                            >
                              Buffer New
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: "140px" }}
                            >
                              Proposed Action
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ width: "115px" }}
                            >
                              View Details
                            </TableCell>
                            <TableCell align="center" style={{ width: "65px" }}>
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody
                          style={{ overflowY: "hidden", overflowX: "hidden"}}
                        >
                          {tableData.map((i, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const isChecked = selectedRows.some(
                              (selectedRow) =>
                                selectedRow.itemCode === i.dbm_item_code
                            );

                            return (
                              <TableRow
                                key={i.instanceId}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  align="center"
                                  style={{ width: "10px" }}
                                >
                                  {i.dbm_proposed_action === "Increase" ||
                                  i.dbm_proposed_action === "Decrease" ? (
                                    <input
                                      type="checkbox"
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      checked={isChecked}
                                      className={modules["custom-checkbox"]}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e.target.checked,
                                          i.dbm_instance_uid,
                                          i.dbm_stock_point_code,
                                          i.dbm_company_code,
                                          i.dbm_proposed_action,
                                          i.dbm_item_code
                                        )
                                      }
                                    />
                                  ) : null}
                                </TableCell>
                                <TableCell align="center">
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ width: "125px" }}
                                >
                                  {i.dbm_stock_point_code}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ width: "140px" }}
                                >
                                  {i.dbm_company_code}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ width: "110px" }}
                                >
                                  {i.dbm_item_code}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ width: "160px" }}
                                >
                                  {i.i_item_desc}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ width: "60px" }}
                                >
                                  {i.i_sales_statistical_group}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ width: "130px" }}
                                >
                                  {i.dbm_buffer}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ width: "110px" }}
                                >
                                  {i.dbm_buffer_new}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ width: "140px" }}
                                >
                                  {i.dbm_proposed_action}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ width: "115px" }}
                                >
                                  <a
                                    style={{
                                      color: "#0000EE",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      handleViewDetails(
                                        e.currentTarget,
                                        i.dbm_instance_uid,
                                        i.dbm_stock_point_code,
                                        i.dbm_company_code,
                                        i.dbm_item_code,
                                        i.i_sales_statistical_group,
                                        i.dbm_buffer,
                                        i.dbm_buffer_new,
                                        i.dbm_proposed_action,
                                        i.i_item_desc,
                                        i.rundatefrom,
                                        i.rundateto
                                      );
                                    }}
                                  >
                                    View Details
                                  </a>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ width: "65px" }}
                                >
                                  {i.dbm_proposed_action === "Increase" ||
                                  i.dbm_proposed_action === "Decrease" ? (
                                    <a
                                      style={{
                                        color: "#0000EE",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        handleIgnore(
                                          e.currentTarget,
                                          i.dbm_instance_uid,
                                          i.dbm_stock_point_code,
                                          i.dbm_company_code,
                                          i.dbm_item_code
                                        )
                                      }
                                    >
                                      Ignore
                                    </a>
                                  ) : (
                                    <></>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br />
                    <button
                      className={modules["btn1"]}
                      style={{
                        width: "100px",
                        marginTop: "1rem",
                        marginLeft: "35rem",
                        alignSelf: "center",
                      }}
                      onClick={updateHandler}
                    >
                      Update
                    </button>
                    <br />
                  </>
                ) : (
                  <div style={{ height: "80vh" }}>
                    <div
                      style={{
                        color: "#414141",
                        // marginLeft: "7rem",
                        fontSize: "12px",
                        // marginTop: "20px",
                        padding: "8px",
                        borderBottom: "1px solid #a4bf47",
                      }}
                    >
                      No Records Found
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
