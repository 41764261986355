import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
import i1 from "../../../Assets/i1.jpg";
import i2 from "../../../Assets/i2.jpg";
import i3 from "../../../Assets/i3.jpg";
import i4 from "../../../Assets/i4.jpg";
import i5 from "../../../Assets/i5.jpg";
import i6 from "../../../Assets/i6.png";
import viewp1 from "../../../Assets/viewp1.png";
import viewp2 from "../../../Assets/viewp2.png";
import viewp3 from "../../../Assets/viewp3.png";
import modules from "./../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Calendar from "react-calendar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetIRExport,
  GetIRIssueOwner,
  GetCPR_SearchResult,
  GetTTMTFinancialYear,
  NewProjectPlanExcel,
  OldProjectPlanExcel,
} from "../../../Services/CCPM.js";

// function ArrowLine() {
//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       <div
//         style={{
//           width: "1px",
//           height: "18px",
//           backgroundColor: "grey",
//           marginRight: "2px",
//         }}
//       />
//       <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
//     </div>
//   );
// }

const ClosedProjectReport = () => {
  const [selectedInstance, setSelectedInstance] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("1");
  const [selectedAssignedTo, setselectedAssignedTo] = useState("0");
  const [assignedOwner, setAssignedOwner] = useState([]);
  const [selectedissueStatus, setSelectedIssueStatus] = useState("0");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarToOpen, setCalendarToOpen] = useState(false);
  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());
  const [selectedFinancialYear, setSelectedFinancialYear] = useState("");
  const [financialYears, setFinancialYears] = useState([]);
  const navigate = useNavigate();

  const handleSelectChange = (attributenumber, id) => {
    // console.log(attributenumber, id, "attributenumber value");
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [instances, setInstances] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [displayfromdate, setdisplayfromdate] = useState("");
  const [displaytodate, setdisplaytodate] = useState("");
  const handleFromDateChange = (date) => {
    setFromdate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplayfromdate(`${day}/${month}/${year}`);
    setCalendarOpen(false);
  };

  const handleToDateChange = (date) => {
    setTodate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplaytodate(`${day}/${month}/${year}`);
    setCalendarToOpen(false);
  };

  const accessTypes = [
    { value: "null", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ];

  const projectStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Active" },
    { value: "5", label: "Hold" },
    { value: "7", label: "Completed" },
    { value: "10", label: "CDDCheckedOut" },
    { value: "11", label: "ModifiedCheckedOut" },
  ];

  const issueStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Pending" },
    { value: "5", label: "Closed" },
  ];

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getAssinedOwner();
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
      getFinancialYear();
    }
  }, [instances]);

  const getFinancialYear = () => {
    GetTTMTFinancialYear()
      .then((response) => {
        // console.log(response, "FY");
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data, "FY");
          setFinancialYears(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleCalendar = () => {
    setCalendarOpen((prevOpen) => !prevOpen);
  };

  const toggleToCalendar = () => {
    setCalendarToOpen((prevOpen) => !prevOpen);
  };

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    GetPCBindProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssinedOwner = () => {
    const body = {
      userId: 0,
    };
    GetIRIssueOwner(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "Assigned Owner");
          setAssignedOwner(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const dateRange = selectedFinancialYear;
    const formattedDateRange = dateRange.replace(/\s*-\s*/, "-");
    console.log(formattedDateRange); // Output: "1 April 2022-31 March 2023"
    const body = {
      instanceId: parseInt(selectedInstance),
      userId: parseInt(loginId),
      financialYear: formattedDateRange,
    };

    GetCPR_SearchResult(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "table data");
          setTableData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExcel = async (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      issueOwner: selectedAssignedTo,
      projectType: selectedProjectAccessType,
      accessType: "",
      issueStatusId: selectedissueStatus,
    };

    // if (selectedRoleId !== -1) {
    try {
      setIsLoading(true);
      const response = await GetIRExport(body);

      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // const contentDisposition = response.headers["content-disposition"];
        const filename = "ViewProjects.xlsx";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoading(false);
        console.error("No Data found");
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error downloading file:", err);
    }
  };

  const handlePlannedBilling = (projId) => {
    navigate(`/ccpm/PlannedBillingProjectionview?projId=${projId}`);
  };

  const handleSearch = () => {
    setShowResult(true);
    console.log(selectedAttributes, "selectedAttributes");
    console.log(selectedProjectStatus, "status");
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getSearchResult(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  const DownloadHandler = (id) => {
    getExcel1(id);
  };

  const DownloadHandler2 = (id) => {
    getExcel2(id);
  };

  const getExcel1 = async (id) => {
    const body = {
      projectId: id,
    };

    try {
      setIsLoading(true);
      const response = await NewProjectPlanExcel(body);

      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // const contentDisposition = response.headers["content-disposition"];
        const filename = "ProjectPlan.xlsx";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoading(false);
        console.error("No Data found");
      }
    } catch (err) {
      setIsLoading(false);
      alert("Error While Exporting Or No Related Data Found");
      console.error("Error downloading file:", err);
    }
  };

  const getExcel2 = async (id) => {
    const body = {
      projectId: id,
      instance:
        selectedInstance === "" || selectedInstance === "Select"
          ? 0
          : parseInt(selectedInstance),
    };

    try {
      setIsLoading(true);
      const response = await OldProjectPlanExcel(body);

      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // const contentDisposition = response.headers["content-disposition"];
        const filename = "OldProjectPlan.xlsx";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoading(false);
        console.error("No Data found");
      }
    } catch (err) {
      setIsLoading(false);
      alert("Error While Exporting Or No Related Data Found");
      console.error("Error downloading file:", err);
    }
  };

  const DownloadOption = () => {
    if (tableData.length === 0) {
      alert("No Record Found");
      return;
    }

    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    getExcel(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  return (
    <>
      <div
        style={{
          overflowX: showResult && tableData.length > 0 ? "auto" : "hidden",
        }}
        // style={{
        //   overflowX: "auto",
        // }}
      >
        {isLoading && (
          <img
            src={loading}
            className={modules["loading_gif"]}
            alt="Loading..."
          />
        )}
        <Grid container>
          <Grid item lg={0.6} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>

          <Grid item lg={12} sx={{ display: "block" }}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"ClosedProjectReport"} />
            </Grid>

            <Grid item lg={12}>
              <div
                className={modules["instancemaster_div"]}
                style={{ marginBottom: "5px" }}
              >
                <div
                  className={modules["pro-bread"]}
                  style={{ marginLeft: "-2.5rem" }}
                >
                  <a>Reports</a>
                  <img src={bread} alt="" />
                  <a>Project Completion Status Report</a>
                </div>
              </div>
              <div className={modules["border_cont"]}>
                <table className={modules["PCtable"]}>
                  <tbody>
                    <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Instance:<label style={{ color: "Red" }}>*</label>
                        </span>

                        <select
                          value={selectedInstance}
                          onChange={(e) => {
                            setSelectedInstance(e.target.value);
                            getProjectDetails(e.target.value);
                          }}
                          className={modules["dropdownSelect"]}
                          // style={{ width: "200px" }}
                        >
                          {instances?.map((instance) => (
                            <option key={instance.name} value={instance.id}>
                              {instance.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>

                    <tr
                      style={{
                        paddingBottom: "10px",
                        paddingLeft: "0px",
                      }}
                    >
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Financial Year :
                          <label style={{ color: "Red" }}>*</label>
                        </span>
                        <select
                          value={selectedFinancialYear}
                          onChange={(e) => {
                            setSelectedFinancialYear(e.target.value);
                            setShowResult(false);
                          }}
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={""}>SELECT</option>
                          <option value={"All"}>All</option>
                          <option value={"-2"}>Date Range</option>
                          {financialYears?.map((fy) => (
                            <option
                              key={fy.financialyear}
                              value={fy.financialyear}
                            >
                              {fy.financialyear}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>

                    {selectedFinancialYear === "-2" && (
                      <tr
                        style={{
                          paddingBottom: "10px",
                          paddingLeft: "0px",
                        }}
                      >
                        <td className={modules["table_data1"]}>
                          <span
                            style={{
                              width: "110px",
                              marginRight: "0.5rem",
                              color: "#414141",
                            }}
                          >
                            From Date:
                            <label style={{ color: "Red" }}>*</label>
                          </span>
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                              marginLeft: "3px",
                            }}
                          >
                            <input
                              className={modules["datepicker"]}
                              style={{
                                borderRadius: "10px",
                                border: "1px solid #9d9d9c",
                                fontFamily: "WorkSans, sans-serif",
                                color: "#373736",
                                fontSize: "12px",
                                padding: "2px",
                              }}
                              type="text"
                              readOnly
                              value={displayfromdate}
                              placeholder="dd/MM/yyyy"
                              onClick={toggleCalendar}
                            />
                            {calendarOpen && (
                              <div
                                className={modules["fromcalendar"]}
                                style={{ position: "absolute", zIndex: 2 }}
                              >
                                <Calendar
                                  onChange={handleFromDateChange}
                                  value={fromdate}
                                />
                              </div>
                            )}
                          </div>
                        </td>
                        <td className={modules["table_data1"]}>
                          <span
                            style={{
                              width: "110px",
                              marginRight: "0.5rem",
                              color: "#414141",
                            }}
                          >
                            To Date:
                            <label style={{ color: "Red" }}>*</label>
                          </span>
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                              marginLeft: "3px",
                            }}
                          >
                            <input
                              className={modules["datepicker"]}
                              style={{
                                borderRadius: "10px",
                                border: "1px solid #9d9d9c",
                                fontFamily: "WorkSans, sans-serif",
                                color: "#373736",
                                fontSize: "12px",
                                padding: "2px",
                              }}
                              type="text"
                              readOnly
                              value={displaytodate}
                              placeholder="dd/MM/yyyy"
                              onClick={toggleToCalendar}
                            />
                            {calendarToOpen && (
                              <div
                                className={modules["fromcalendar"]}
                                style={{ position: "absolute", zIndex: 2 }}
                              >
                                <Calendar
                                  onChange={handleToDateChange}
                                  value={todate}
                                />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}

                    {/* <br /> */}

                    {selectedFinancialYear !== "" && (
                      <>
                        <tr
                          style={{
                            // justifyContent: "center",
                            gap: "10px",
                            paddingLeft: "550px",
                            paddingBottom: "10px",
                            // width: "100%",
                          }}
                        >
                          <button
                            type="submit"
                            className={modules["PCbtn"]}
                            onClick={handleSearch}
                          >
                            Search
                          </button>
                          <button
                            className={modules["PCbtn"]}
                            type="button"
                            onClick={() => {
                              window.location.reload();
                              setShowResult(false);
                            }}
                          >
                            Reset
                          </button>

                          {showResult && (
                            <button
                              type="submit"
                              className={modules["PCbtn"]}
                              onClick={DownloadOption}
                            >
                              Export to Excel
                            </button>
                          )}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              {showResult && tableData.length > 0 && (
                <>
                  <Grid container sx={{ marginLeft: "6.5rem", padding: "8px" }}>
                    <Table className={modules["IM_custom-table"]}>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Modif. No
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Project Name
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Project Manager
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Project Status
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Customer Name
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Calendar
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Erp Company
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Erp Project No
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Project Duration
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            % Buffer Consumed
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            % CC Completed
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Scheduled Start Date
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Scheduled End Date
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Actual Start Date
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Exp/Actual End Date
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Customer Due Date
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Completion Status
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Delay(days)
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Delay(%)
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Lead Time(days)
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Task Causing Maximum Delay
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Delay Caused By Task(Days)
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Last BM Run
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Customer Feedback
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Approved Execution Cost (In INR Lakhs)
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Actual Execution Cost (In INR Lakhs)
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Project value (In INR Lakhs)
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            View Tasks
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            View Billing Projection
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Risk
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Issues
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            View Histoy
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Fever Chart
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Buffer Recovery
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Project Properties
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Pending Tasks On CC
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Gantt Chart
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Network Daigram
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            New Project Plan File
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Old Project Plan File
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Attachments
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Instance
                          </TableCell>

                          {hierarchyOptions
                            ?.filter((option) => option.isactive === 1)
                            .map((item) => {
                              return (
                                <TableCell>{item.attributename}</TableCell>
                              );
                            })}

                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Project Creation Date
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Project Last Updated On
                          </TableCell>
                          <TableCell sx={{ lineHeight: "normal !important" }}>
                            Task Last Updated On
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData?.map((item, index) => (
                          <TableRow key={index} style={{ color: "#000000" }}>
                            <TableCell>{item["Modif Version"]}</TableCell>
                            <TableCell
                              sx={{
                                background: `${item.color}`,
                                color: "#ffffff",
                              }}
                            >
                              {item.projectname}
                            </TableCell>
                            <TableCell style={{ color: "#000000" }}>
                              {item.projectmanagername}
                            </TableCell>
                            <TableCell>{item.projectstatus}</TableCell>
                            <TableCell>{item.customername}</TableCell>
                            <TableCell>{item.calendarname}</TableCell>
                            <TableCell>{item.erpcompany}</TableCell>
                            <TableCell>{item.erpprojectno}</TableCell>
                            <TableCell>{item.projectduration}</TableCell>
                            <TableCell>{item.perbufferconsumed}</TableCell>
                            <TableCell>
                              {Number(item.percccompleted).toFixed(2)}
                            </TableCell>
                            <TableCell>
                              {new Date(
                                item.scheduledstartdate
                              ).toLocaleDateString("en-GB")}{" "}
                            </TableCell>
                            <TableCell>
                              {new Date(
                                item.scheduledenddate
                              ).toLocaleDateString("en-GB")}{" "}
                            </TableCell>
                            <TableCell>
                              {new Date(
                                item.actualstartdateinner
                              ).toLocaleDateString("en-GB")}{" "}
                            </TableCell>
                            <TableCell>
                              {new Date(
                                item.actualenddateinner
                              ).toLocaleDateString("en-GB")}{" "}
                            </TableCell>
                            <TableCell>
                              {new Date(
                                item.customerduedateinner
                              ).toLocaleDateString("en-GB")}{" "}
                            </TableCell>
                            <TableCell>{item.completionstatus}</TableCell>
                            <TableCell>{item.delaydays}</TableCell>
                            <TableCell>{item.delayper}</TableCell>
                            <TableCell>{item.leadtimedays}</TableCell>
                            <TableCell>{item.taskname}</TableCell>
                            <TableCell>{item.maxdelay}</TableCell>
                            <TableCell>{item.lastbmrundate}</TableCell>
                            <TableCell>{item.customerfeedback}</TableCell>
                            <TableCell>{item.approvedexecutioncost}</TableCell>
                            <TableCell>{item.actualexecutioncost}</TableCell>
                            <TableCell>{item.projectvalue}</TableCell>
                            <TableCell>
                              <a href="/ccpm/viewtasks">View Task</a>
                            </TableCell>
                            <TableCell
                              onClick={() =>
                                handlePlannedBilling(item.projectsid)
                              }
                            >
                              <a href="#">View Billing Projection</a>
                            </TableCell>
                            <TableCell>
                              <img
                                onClick={() => {
                                  window.open(
                                    `/ccpm/RiskPopup?projId=${item.projectsid}&selectedInstance=${selectedInstance}`,
                                    "sharer",
                                    "toolbar=0,status=0,width=1050,height=600"
                                  );
                                }}
                                src={i5}
                                alt=""
                                className={modules["custom-img-size"]}
                              />
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                window.open(
                                  `/ccpm/ViewIsuePopup?projId=${item.projectsid}`,
                                  "sharer",
                                  "toolbar=0,status=0,width=1050,height=600"
                                );
                              }}
                            >
                              <a href="#">Issues</a>
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                window.open(
                                  `/ccpm/ViewActiveProjectsHistory?projId=${item.projectsid}`,
                                  "sharer",
                                  "toolbar=0,status=0,width=1050,height=600"
                                );
                              }}
                            >
                              <a href="#">View History</a>
                            </TableCell>
                            <TableCell>
                              <img
                                src={viewp1}
                                alt=""
                                className={modules["custom-img-size"]}
                              />
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                window.open(
                                  `/ccpm/ViewBufferRecovery?projId=${item.projectsid}`,
                                  "sharer",
                                  "toolbar=0,status=0,width=1050,height=600"
                                );
                              }}
                            >
                              <a href="#">View Buffer Recovery</a>
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                window.open(
                                  `/ccpm/projectProperties?projId=${item.projectsid}`,
                                  "sharer",
                                  "toolbar=0,status=0,width=1050,height=600"
                                );
                              }}
                            >
                              <a href="#">View Project Properties</a>
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                window.open(
                                  `/ccpm/PendingTaskView?projId=${item.projectsid}`,
                                  "sharer",
                                  "toolbar=0,status=0,width=1050,height=600"
                                );
                              }}
                            >
                              <a href="#">Pending Tasks on CC</a>
                            </TableCell>
                            <TableCell>
                              <a href="#">Show Gantt Chart</a>
                            </TableCell>
                            <TableCell>
                              <img
                                src={viewp2}
                                alt=""
                                className={modules["custom-img-size"]}
                              />
                            </TableCell>
                            <TableCell>
                              <img
                                src={viewp3}
                                alt=""
                                className={modules["custom-img-size"]}
                                onClick={() => DownloadHandler(item.projectsid)}
                              />
                            </TableCell>
                            <TableCell>
                              <img
                                src={viewp3}
                                alt=""
                                className={modules["custom-img-size"]}
                                onClick={() =>
                                  DownloadHandler2(item.projectsid)
                                }
                              />
                            </TableCell>
                            <TableCell>{item.Attachments}</TableCell>
                            <TableCell>{item.instance}</TableCell>
                            <TableCell>{item.BusinessType}</TableCell>
                            <TableCell>{item.ProductType}</TableCell>
                            <TableCell>{item.PortfolioName}</TableCell>
                            <TableCell>{item.Phase}</TableCell>
                            <TableCell>{item.ProductCategory}</TableCell>
                            <TableCell>{item.ProjectType}</TableCell>
                            <TableCell>{item.ProjectCreationDate}</TableCell>
                            <TableCell>{item.ProjectLastUpdatedOn}</TableCell>
                            <TableCell>{item.TaskLastUpdatedOn}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </>
              )}

              {showResult && tableData.length === 0 && (
                <>
                  <Grid
                    container
                    sx={{
                      marginLeft: "6.5rem",
                      padding: "8px",
                      marginTop: "1rem",
                    }}
                  >
                    <Table className={modules["IM_custom-table"]}>
                      <span
                        style={{
                          color: "#414141",
                          padding: "8px",
                          fontSize: "12px",
                        }}
                      >
                        No Record Found
                      </span>
                    </Table>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ClosedProjectReport;
