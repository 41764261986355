import React, { useState } from "react";
import modules from "./ProjectClosureForm.module.css";
import {
  CP_Save_Click,
} from "../../../Services/CCPM.js";

const ProjectClosureForm = ({ projectName, projectId }) => {
  const [formData, setFormData] = useState({
    closureDate: "",
    customerFeedback: "",
    approvedExecutionCost: "",
    actualExecutionCost: "",
    projectValue: "",
    file1: null,
    file2: null,
    file3: null,
    learningDocumentFile: null,
    closureRemark: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;
    if (name === "closureDate") {
      const date = new Date(value);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      formattedValue = date
        .toLocaleDateString("en-GB", options)
        .replace(/ /g, "-");
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Add form submission logic here
  };

  return (
    <div className={modules["project-closure-form"]}>
      <div style={{ paddingLeft: "8px", paddingTop: "8px" }}>
        Project Id : 8742
      </div>
      {/* <br /> */}
      <div style={{ paddingLeft: "8px" }}>Project Name : zxcvbnm</div>
      <form onSubmit={handleSubmit}>
        <div className={modules["form-group"]}>
          <div style={{ width: "92px" }}>Closure Date:</div>
          <input
            type="date"
            name="closureDate"
            value={formData.closureDate}
            onChange={handleInputChange}
          />
          <span className={modules["info-text"]}>
            [Selection Range: Between last date of task(s) completion of this
            project AND Current Date]
          </span>
        </div>

        <div className={modules["form-group"]}>
          <div>Customer Feedback:</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "80px",
            }}
          >
            <div style={{ padding: "8px" }}>
              <input
                type="radio"
                name="customerFeedback"
                value="SE"
                onChange={handleInputChange}
                className={modules["radio-button"]}
              />
              SE
            </div>
            <div style={{ padding: "8px" }}>
              <input
                type="radio"
                name="customerFeedback"
                value="ME"
                onChange={handleInputChange}
                className={modules["radio-button"]}
              />{" "}
              ME
            </div>
            <div style={{ padding: "8px" }}>
              <input
                type="radio"
                name="customerFeedback"
                value="CB"
                onChange={handleInputChange}
                className={modules["radio-button"]}
              />{" "}
              CB
            </div>
            <div style={{ padding: "8px" }}>
              <input
                type="radio"
                name="customerFeedback"
                value="BE"
                onChange={handleInputChange}
                className={modules["radio-button"]}
              />{" "}
              BE
            </div>
          </div>
        </div>

        <div className={modules["form-group"]}>
          <div style={{ width: "180px" }}>Approved Execution Cost :</div>
          <input
            type="number"
            name="approvedExecutionCost"
            placeholder="Enter the full amount in INR only Eg:1000.50"
            value={formData.approvedExecutionCost}
            onChange={handleInputChange}
            className={modules["input-group"]}
          />
          <span className={modules["info-text"]}>
            [Enter the full amount in INR only Eg:1000.50]
          </span>
        </div>

        <div className={modules["form-group"]}>
          <div style={{ width: "180px" }}>Actual Execution Cost:</div>
          <input
            type="number"
            name="actualExecutionCost"
            placeholder="Enter the full amount in INR only Eg:1000.50"
            value={formData.actualExecutionCost}
            onChange={handleInputChange}
            className={modules["input-group"]}
          />
          <span className={modules["info-text"]}>
            [Enter the full amount in INR only Eg:1000.50]
          </span>
        </div>

        <div className={modules["form-group"]}>
          <div style={{ width: "180px" }}>Project Value:</div>
          <input
            type="number"
            name="projectValue"
            placeholder="Enter the full amount in INR only Eg:1000.50"
            value={formData.projectValue}
            onChange={handleInputChange}
            className={modules["input-group"]}
          />
          <span className={modules["info-text"]}>
            [Enter the full amount in INR only Eg:1000.50]
          </span>
        </div>

        <div className={modules["form-group2"]}>
          <div style={{ width: "180px" }}>Project Documents : </div>
          <div className={modules["info-text"]} style={{ fontWeight: "bold" }}>
            [xls/xlsx/doc/docx/pdf/ppt/pptx/txt files only of size not more than
            1mb]{" "}
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ width: "50px" }}>File1 :</span>{" "}
            <input
              type="file"
              name="file1"
              onChange={handleFileChange}
              style={{ border: "none" }}
            />
            <button type="button" className={modules["button_css"]}>
              Upload
            </button>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ width: "50px" }}>File2 :</span>{" "}
            <input
              type="file"
              name="file2"
              onChange={handleFileChange}
              style={{ border: "none" }}
            />
            <button type="button" className={modules["button_css"]}>
              Upload
            </button>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ width: "50px" }}>File3 :</span>{" "}
            <input
              type="file"
              name="file3"
              onChange={handleFileChange}
              style={{ border: "none" }}
            />
            <button type="button" className={modules["button_css"]}>
              Upload
            </button>
          </div>
        </div>

        <div className={modules["form-group2"]}>
          <div style={{ width: "180px" }}>Project Learning Document : </div>
          <div className={modules["info-text"]} style={{ fontWeight: "bold" }}>
            [xls/xlsx/doc/docx/pdf/ppt/pptx/txt files only of size not more than
            1mb]{" "}
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ width: "50px" }}>File1 :</span>{" "}
            <input
              type="file"
              name="file1"
              onChange={handleFileChange}
              style={{ border: "none" }}
            />
            <button type="button" className={modules["button_css"]}>
              Upload
            </button>
          </div>
        </div>

        <div className={modules["form-group"]}>
          <div style={{ width: "180px" }}>Closure Remark:</div>
          <textarea
            name="closureRemark"
            rows="4"
            value={formData.closureRemark}
            onChange={handleInputChange}
            style={{ width: "30%" }}
          ></textarea>
        </div>

        <div className={modules["form-group"]}>
          <button type="submit" className={modules["button_css"]}>
            Save
          </button>
          <button type="button" className={modules["button_css"]}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProjectClosureForm;
