import { React, useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Stack,
  Typography,
  Popover,
  Box,
} from "@mui/material";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import { useLocation } from "react-router-dom";
import { AET_GetProjBind, GetCCPMParams } from "../../../../Services/CCPM";
import modules from "../../ccpm.module.css";
import loading from "../../../../Assets/Loading_image.gif";

const ProjectProperties = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const projId = parseInt(searchParams.get("projId"));
  const [projectProperties, setProjectProperties] = useState([]);
  const [ccpmSetting, setCcpmSetting] = useState([]);

  useEffect(() => {
    fetchDetails();
    fetchParams();
  }, []);

  const fetchDetails = () => {
    setIsLoading(true);
    let body = {
      projectId: projId,
    };
    AET_GetProjBind(body)
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        if (response.status === 200) {
          const data = response?.data?.success?.data;
          setProjectProperties(data.ProjectProperties);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const fetchParams = () => {
    setIsLoading(true);
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      projectsId: projId,
    };
    GetCCPMParams(body)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          if (response?.data?.error?.message) {
            return;
          } else {
            setCcpmSetting(response?.data?.success?.data?.AttributeValues);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const formatDate = (dateStr) => {
    if (dateStr) {
      const [year, month, day] = dateStr?.split("/");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    }
  };

  const getPropertyValue = (property, formatter) => {
    if (
      projectProperties.length !== 0 &&
      projectProperties[0]?.[property] !== null
    ) {
      return formatter
        ? formatter(projectProperties[0][property])
        : projectProperties[0][property];
    }
    return "\u00A0";
  };

  const handleClose = () => {
    window.close();
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Box>
        <Stack
          direction="row"
          spacing={4}
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ paddingLeft: "1%", paddingTop: "1%" }}
        >
          <Stack>
            <img
              style={{
                width: "120px",
                height: "58px !important",
                alignSelf: "end",
                paddingTop: "10px",
              }}
              src={logo}
              alt="Godrej"
            />
          </Stack>
          <Stack sx={{ paddingTop: "10px", paddingRight: "10px" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
      </Box>
      <Stack sx={{ textAlign: "left", marginLeft: "1%" }} direction="column">
        <Typography className={modules["textPP"]}>
          Project Properties
        </Typography>
        <Stack
          direction="row"
          spacing={10}
          sx={{ paddingLeft: "10%", paddingTop: "1%" }}
        >
          <Stack direction="column" alignItems="center">
            <Typography className={modules["textPP"]}>
              Project Start Date:
            </Typography>
            <Typography className={modules["textPP"]}>
              Customer Due Date:
            </Typography>
            <Typography className={modules["textPP"]}>
              Project Duration:
            </Typography>
            <Typography className={modules["textPP"]}>
              Critical Chain:
            </Typography>
            <Typography className={modules["textPP"]}>
              Project Buffer:
            </Typography>
          </Stack>
          <Stack direction="column">
            <Typography className={modules["textPP"]}>
              {getPropertyValue("startdate", formatDate)}
            </Typography>
            <Typography className={modules["textPP"]}>
              {getPropertyValue("duedate", formatDate)}
            </Typography>
            <Typography className={modules["textPP"]}>
              {getPropertyValue("projduration")}
            </Typography>
            <Typography className={modules["textPP"]}>
              {getPropertyValue("ccdays")}
            </Typography>
            <Typography className={modules["textPP"]}>
              {getPropertyValue("projbuffer")}
            </Typography>
          </Stack>
        </Stack>
        <Typography className={modules["textPP"]}>CCPM Setting</Typography>
        <Stack
          direction="row"
          spacing={10}
          sx={{ paddingLeft: "9%", paddingTop: "1%" }}
        >
          <Stack direction="column" alignItems="center">
            <Typography className={modules["textPP"]}>Project Name:</Typography>
            <Typography className={modules["textPP"]}>
              % Reduction From Task Duration:
            </Typography>
            <Typography className={modules["textPP"]} sx={{ marginTop: "8%" }}>
              % Project Buffer:
            </Typography>
            <Typography className={modules["textPP"]}>
              % Feeding Buffer:
            </Typography>
          </Stack>
          <Stack direction="column">
            <Typography className={modules["textPP"]}>
              {getPropertyValue("projectname")}
            </Typography>
            <Typography className={modules["textPP"]}>
              <input
                type="text"
                disabled
                maxlength="7"
                className={modules["inputPP"]}
                value={ccpmSetting[0]?.taskreductionper}
              />
            </Typography>
            <Typography className={modules["textPP"]} sx={{ marginTop: "8%" }}>
              <input
                type="text"
                disabled
                maxlength="7"
                className={modules["inputPP"]}
                value={ccpmSetting[0]?.projectbufferreductionper}
              />
            </Typography>
            <Typography className={modules["textPP"]}>
              <input
                type="text"
                disabled
                maxlength="7"
                className={modules["inputPP"]}
                value={ccpmSetting[0]?.feedingbufferreductionper}
              />
            </Typography>
          </Stack>
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <button onClick={handleClose} className={modules["buttonPP"]}>
            Close
          </button>
        </Stack>
      </Stack>
    </>
  );
};

export default ProjectProperties;
