import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
// import modules from "./StockPointPopup.css";
import modules from "../../Admin/Admin.module.css";
import { SPWI_GetInTransitCode } from "../../../Services/MTA";
import { useLocation } from "react-router-dom";
import loading from "../../../Assets/Loading_image.gif";

const InTransitPopup = () => {
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const roleId = searchParams.get("instance");
  const stockPoint = searchParams.get("stock_point");

  const ChannelWc = new BroadcastChannel("warehouseCodeChannel");
  const [initialData, setInitialData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(initialData.length / pageSize);
  const [id, setId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [SpName, setSpName] = useState("");
  const [DpName, setDpName] = useState("");
  const [msg, setMsg] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    const body = {
      spwi_instance_uid: roleId,
      spwi_stock_point_code: stockPoint,
    };
    SPWI_GetInTransitCode(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setInitialData(response?.data?.success?.data);
          setFilterData(response?.data?.success?.data);
          if (response?.data?.success?.data.length === 0) {
            setMsg("No records found.");
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  function selectHandler(SPC, CC, WC, WC1) {
    const StockPointCode = SPC;
    const companyCode = CC;
    const WarehouseCode = WC;
    const WarehouseCode1 = WC1;

    ChannelWc.postMessage({
      mainrole: roleId,
      selectedData: {
        StockPointCode,
        companyCode,
        WarehouseCode,
        WarehouseCode1,
        empty: "false",
      },
    });
    ChannelWc.close();
    window.close();
    // } else {
    //   ChannelSp.postMessage({
    //     mainrole: roleId,
    //     selectedData: {
    //       roleIds: "",
    //       roleNames: "",
    //       empty: "true",
    //     },
    //   });
    //   ChannelSp.close();
    //   window.close();
    // }
  }

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleIdChange = (e) => {
    setId(e?.target?.value);
  };

  const handleRoleChange = (e) => {
    setRoleName(e?.target?.value);
  };

  const handleNameChange = (e) => {
    setSpName(e?.target?.value);
  };

  const handlePChange = (e) => {
    setDpName(e?.target?.value);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const endPage = Math.min(startPage + 3, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span key={i}>
          <a
            className={`${modules["page-link"]} ${
              currentPage === i ? modules["active-select"] : ""
            }`}
            onClick={(event) => {
              const element = event.target;
              element.style.color = "red";
              setTimeout(() => {
                handleClick(i);
                element.style.color = "#414141";
              }, 100);
            }}
          >
            {i}
          </a>
        </span>
      );
    }
    return pageNumbers;
  };

  function searchHandler(idcode, role, SpName, DpName) {
    setMsg("");
    let result = filterData?.filter((ele) => {
      const boolOne = idcode
        ? ele["Stock Point Code"]
            .toString()
            .toLowerCase()
            .split(",")
            .some((code) => code.includes(idcode.toString().toLowerCase()))
        : true;

      const boolTwo = role
        ? ele["Company Code"]
            .toString()
            .toLowerCase()
            .split(",")
            .some((code) => code.includes(role.toString().toLowerCase()))
        : true;

      const boolThree = SpName
        ? ele["Warehouse Code"]
            .toString()
            .toLowerCase()
            .split(",")
            .some((priority) =>
              priority.includes(SpName.toString().toLowerCase())
            )
        : true;

      const boolFour = DpName
        ? ele["Warehouse Code 1"]
            .toString()
            .toLowerCase()
            .split(",")
            .some((priority) =>
              priority.includes(DpName.toString().toLowerCase())
            )
        : true;
      return boolOne && boolTwo && boolThree && boolFour;
    });
    setInitialData(result);
    if (result.length === 0) setMsg("No records found.");
  }

  function resetHandler() {
    fetchData();
    setSearchData([]);
    setId("");
    setRoleName("");
    setDpName("");
    setSpName("");
    setMsg("");
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div className={modules["popup_container"]}>
        <Typography
          align="center"
          py={1}
          className={modules["headingFontBold"]}
        >
          Select Stock Point Warehouse Intransit
        </Typography>
        {msg ? (
          <Typography align="left" className={modules["error-msgBold"]}>
            {msg}
          </Typography>
        ) : (
          <>
            <table
              className={modules["popup_table"]}
              style={{ marginBottom: "2rem" }}
            >
              <thead className={modules["popup_head"]}>
                <tr>
                  <th style={{ align: "center", width: "12%" }}></th>
                  <th>Stock Point Code</th>
                  <th>Company Code</th>
                  <th>Warehouse Code</th>
                  <th>Warehouse Code1</th>
                </tr>
              </thead>
              <tbody className={modules["popup_body"]}>
                {initialData
                  ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
                  .map((ele, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <tr key={ele.id}>
                        <td>
                          <a
                            href="#"
                            style={{
                              textDecoration: "underline",
                              color: "#0000ee",
                            }}
                            onClick={() =>
                              selectHandler(
                                ele["Stock Point Code"],
                                ele["Company Code"],
                                ele["Warehouse Code"],
                                ele["Warehouse Code 1"]
                              )
                            }
                          >
                            Select
                          </a>
                        </td>
                        <td>{ele["Stock Point Code"]}</td>
                        <td>{ele["Company Code"]}</td>
                        <td>{ele["Warehouse Code"]}</td>
                        <td>{ele["Warehouse Code 1"]}</td>
                      </tr>
                    );
                  })}
                <tr style={{ width: "100%" }}>
                  <td colspan="1" className={modules["whiteBackgroundPR"]}>
                    <div>{renderPageNumbers()}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        <Typography className={modules["headingFontBold"]}>Search:</Typography>
        <table
          cellspacing="0"
          rules="all"
          border="1"
          className={modules["popup_table_search"]}
        >
          <tbody className={modules["popup_table_searchB"]}>
            <tr>
              <td style={{ color: "#414141" }}>Stock Point Code</td>
              <td>
                <input value={id} onChange={handleIdChange} />
              </td>
            </tr>
            <tr>
              <td style={{ color: "#414141" }}>Company Code</td>
              <td>
                <input value={roleName} onChange={handleRoleChange} />
              </td>
            </tr>
            <tr>
              <td style={{ color: "#414141" }}>Warehouse Code</td>
              <td>
                <input value={SpName} onChange={handleNameChange} />
              </td>
            </tr>
            <tr>
              <td style={{ color: "#414141" }}>Warehouse Code</td>
              <td>
                <input value={DpName} onChange={handlePChange} />
              </td>
            </tr>
          </tbody>
        </table>
        <Stack
          direction="row"
          spacing={2}
          sx={{ marginTop: "18px", marginBottom: "20px" }}
        >
          <button
            type="button"
            style={{ width: "70px", height: "25px", borderRadius: "4px" }}
            className={modules["btn_popupBtn"]}
            onClick={() => searchHandler(id, roleName, SpName, DpName)}
          >
            Search
          </button>
          <button
            type="button"
            style={{ width: "70px", height: "25px", borderRadius: "4px" }}
            className={modules["btn_popupBtn"]}
            onClick={resetHandler}
          >
            Reset
          </button>
        </Stack>
      </div>
    </>
  );
};

export default InTransitPopup;
