import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import NI1 from "../HelpPopups/images/NI1.png";
import NI2 from "../HelpPopups/images/NI2.png";
import NI3 from "../HelpPopups/images/NI3.png";
import NI4 from "../HelpPopups/images/NI4.png";
import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const NewInstancehelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{ fontSize: "14px" }} >
              <h5 style={{ marginLeft: "5rem", textAlign: "center" }}>
                New Instance
              </h5>
              <br />
              <li className="no-bullets">
                New Instance screen is to create and maintain instances for DSS module of ProStream. Definition is instance may vary from user to user. An instance can be company, a division of company, specific LOB or any other part of the organization.
              </li>
              <br />

              <br />


              <li className="no-bullets">
                This session will be used by User Admin.
              </li>
              <br />
              <br />


              <li className="no-bullets">
                Path: DSS `{">>"}` Admin `{">>"}` New Instance
              </li>
              <br />


              <br />

              <Stack sx={{ paddingRight: "1rem",width: "40rem" }}>
                <img src={NI1} alt="NI1" />
              </Stack>
              <br />

              <li className="no-bullets">
                Note: Once an instance is created, it cannot be deleted from the system. However, for the instance to appear in the system it needs to be made active using the session – User Instance Active/InActive.
              </li>
              <br />

              <li className="no-bullets" style={{ textDecoration: "underline" }}>
                For creating an instance, the following steps should be followed:
              </li>
              <br />
              <li>
                Enter the Instance and its description. The instance field will be displayed across ProStream. The description field provides more information about the instance.
              </li>
              <br />

              <li className="no-bullets" style={{ textDecoration: "underline" }}>
                Validations on the screen:
              </li>
              <br />
              <li>
                Instance field is mandatory
              </li>
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={NI2} alt="NI2" />
              </Stack>

              <br />
              <li>
                Instance name should contain only alphabets (A-Z and a-z) and numbers (0-9). No space is allowed.
              </li>
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={NI3} alt="NI3" />
              </Stack>

              <br />
              <li>
                Instance description should contain only alphabets (A-Z and a-z) and numbers (0-9). No space is allowed
              </li>
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={NI4} alt="NI4" />
              </Stack>
              <br />

              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NewInstancehelpPopup;
