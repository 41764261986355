import React from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import PT1 from "../HelpPopups/images/PT1.png";
import PT2 from "../HelpPopups/images/PT2.png";
import PT3 from "../HelpPopups/images/PT3.png";
import PT4 from "../HelpPopups/images/PT4.png";
import PT5 from "../HelpPopups/images/PT5.png";

import { Link } from "react-router-dom";

const ProjectTaskMgmtEmailConfigHelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "5rem" }}>PROJECT TASK EMAIL CONFIGURATION</h3>
              <br />
              <p style={{}}>
                <p style={{}}>
                  There is a session available in CCPM / Masters/ Project Task Email Configuration.
                </p>
              </p>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={PT1} alt="PT1" />
              </Stack>
              <br />
              <p>There are 6 parameters wherein configuration set up can be done at instance level.</p>
              <br />
              <br />
              <p>If these parameters are set as “Yes”, then email will get triggered to the ProStream users.</p>
              <br />
              <br />
              <p>The email triggering will happen in the following manner: -</p>
              <br />

              <p style={{ fontWeight: 'bold' }}>1) Predecessor task completion notification mail to be sent (to all Successor task managers):-</p>
              <p>Whenever a task gets completed in CCPM ProStream Project, an email notification will be sent to all the successor task managers for the task. The email body will be as follows: -</p>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={PT2} alt="PT2" />
              </Stack>
              <br />

              <p style={{ fontWeight: 'bold' }}>2) Project start notification mail to be sent (to first task's manager(s): - Whenever a project becomes active, email will be sent to the task manager of the first task.</p>

              <br />
              <p style={{ fontWeight: 'bold' }}>3) Project Manager should be kept in CC in notification mails to task managers: -</p>
              <p>The Project manager of the project will be marked as CC in the notification emails to successor task manager whenever the predecessor tasks are completed.</p>
              <br />
              <p>Emails will get triggered to the successor task manager if the predecessor tasks are completed. The above 3 parameters must be checked for this functionality.</p>
              <br />
              <p>Emails can also be scheduled to notify task managers, Project managers, L+1 of Task Manager and Instance coordinators whenever the respective tasks become over-due i.e. the scheduled end date for the tasks is surpassed.</p>
              <br />
              <p style={{ fontWeight: 'bold' }}>4) Notification of Overdue Tasks to L+1 of Task Manager: -</p>
              <p>Email containing details for all the tasks assigned to a task manager will be triggered daily. The email will contain all the open tasks for which the scheduled end date has passed.</p>
              <p>The format of the mail will be as shown below.</p>
              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={PT3} alt="PT3" />
              </Stack>
              <br />
              <p style={{ fontWeight: 'bold' }}>5) Notification of Overdue Tasks to L+1 of Task Manager: -</p>
              <p>The L+1 of the task manager will get a summarized mail for all the overdue tasks of the task managers with the details shown in the snap below.</p>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={PT4} alt="PT4" />
              </Stack>
              <br />
              <p style={{ fontWeight: 'bold' }}>6) Notification of Overdue Tasks to Project Manager: -</p>

              <p>The project manager will get notification mail for all the tasks that are overdue for the task manager. The details will be as shown below.</p>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={PT5} alt="PT5" />
              </Stack>
              <br />

              <p style={{ fontWeight: 'bold' }}>7) Notification of Overdue Tasks to Instance Coordinators: -</p>

              <p>There is a provision to send emails to certain persons even if they are not project managers or L+1 in ProStream system. The only requirement will be these users should be valid users in ProStream.</p>
              <p>The email ids of the employees must be added in the text box as shown below. There will be a mail scheduled for these persons for all the overdue tasks of the instance.</p>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={PT5} alt="PT5" />
              </Stack>
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectTaskMgmtEmailConfigHelpPopup;
