import React from "react";
import { Grade } from "@mui/icons-material";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useRef, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SampleSidebar from "../../../Landing/SampleSidebar";
import bread from "../../../Assets/bread.png";
import loading from "../../../Assets/loadingGreen.gif";
import loadingdots from "../../../Assets/LoadingDotsWhiteBlack.gif";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import modules from "./CalculateTRDLoss.module.css";
import { DS_GetAllInstanceHandler } from "../../../Services/Dss";
import { CalculateTrdLossSummary_CalculateHandler,CalculateTrdLossSummary_GetTocHandler,CalculateTrdLossSummary_GetYearHandler } from "../../../Services/TRDLoss";
// import { ClipLoader } from "react-spinners";

export default function CalculateTRDLoss() {

  const [dropOne, setDropOne] = useState({
    value: 'select',
    dropDown: []
})

const [dropTwo, setDropTwo] = useState({
  value: 'select',
  dropDown: []
})

const [dropThree, setDropThree] = useState({
  value: 'select',
  dropDown: []
})

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    (dropTwo.value === 'select') ? alert('Enter Financial Year') :
    (dropThree.value === 'select') ? alert('Enter TOC Week') :
    (() => {
      setIsLoading(true);

    CalculateTrdLossSummary_CalculateHandler(dropOne.value,'pritpwc',dropTwo.value,dropThree.value).then(res => {
      console.log(res)
      if(res.status === 200){
        alert(res?.data?.success?.message)
        setIsLoading(false)
      }

      if(res.status === 400){
        alert(res?.data?.success?.message)
        setIsLoading(false)
      }
        
       
      }).catch(err => {
        console.log('Error while fetching data:', err)
    })
     
  })()
  
  };


  useEffect(() => {
    DS_GetAllInstanceHandler('pritpwc').then(res => {
      if(res.status === 200){
        setDropOne({
          dropDown: res.data,
          value: res.data[0].instanceuid
      })
      }
        
       
      }).catch(err => {
        console.log('Error while fetching data:', err)
    })

    CalculateTrdLossSummary_GetYearHandler().then(res => {
      if(res.status === 200){
        setDropTwo({
          ...dropTwo,
          dropDown: res?.data?.success?.data,
      })
      }
        
       
      }).catch(err => {
        console.log('Error while fetching data:', err)
    })
  },[])


  function InstanceChangeHandler(value) {
    setDropOne({
        ...dropOne,
        value: value
    });

    setDropTwo({
      ...dropTwo,
      value: 'select'
  });
  setDropThree({
    value:'select',
    dropDown:[]
  })
  }

  function YearChangeHandler(value){
    setDropTwo({
      ...dropTwo,
      value: value
  });

  CalculateTrdLossSummary_GetTocHandler(dropOne.value,value).then(res => {
    if(res.status === 200){
      console.log(res)
      setDropThree({
        ...dropThree,
        dropDown: res?.data?.success?.data,
        value:'select'
    })
    }
    }).catch(err => {
      console.log('Error while fetching data:', err)
  })
  }

  function TocChangeHandler(value){
    setDropThree({
      ...dropThree,
      value: value
  });
  }

  function handleReset(){
    setDropTwo({
      ...dropTwo,
      value: 'select'
  });

  setDropThree({
    ...dropThree,
    value: 'select'
});

  }

  

  function ArrowLine() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "2px",
            height: "30px",
            backgroundColor: "grey",
            marginRight: "2px",
          }}
        />
        <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
      </div>
    );
  }
  return (
    <>
      <Grid container>
        <Grid
          item
          lg={0.6}
          sx={{ position: "fixed", zIndex: 2000 }}
          className="main-cont"
        >
          <SampleSidebar/>
        </Grid>
        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className="home-cont">
            <Home />
          </Grid>
        </Grid>
        <Grid
          item
          lg={9}
          className={modules["Calculate_div"]}
     
        >
          <div className={modules["pro-bread"]}>
            <p>
              <a>DSS TRD Loss </a>
              <img src={bread} alt="" />
              <a>Calculate TRD Loss</a>
            </p>
          </div>
        </Grid>

        <Grid container sx={{ marginLeft: "8rem", marginTop: "2rem", width:"100%" }}>
          <table className={modules["customTable"]}>
            <tbody>
              <tr>
                <td >DSS Instance</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={dropOne.value}
                      onChange={(e) => InstanceChangeHandler(e.target.value)}
                    >
                      {dropOne?.dropDown?.map((i) => {
                        return (
                          <MenuItem value={i.instanceuid} key={i.instanceuid}>
                            {i.instanceuid}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>{" "}
                </td>
              </tr>

              <tr>
                <td >Finance Year</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={dropTwo.value}
                      onChange={(e) => YearChangeHandler(e.target.value)}
                    >
                       <MenuItem value='select'>SELECT</MenuItem>
                       <MenuItem value='2023-2024'>2023-2024</MenuItem>
                       <MenuItem value='2024-2025'>2024-2025</MenuItem>
                      {dropTwo?.dropDown?.map((i) => {
                        return (
                          <MenuItem value={i.instanceuid} key={i.instanceuid}>
                            {i.instanceuid}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>{" "}
                </td>
              </tr>

              <tr>
                <td >TOC Week</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={dropThree.value}
                      onChange={(e) => TocChangeHandler(e.target.value)}
                    >
                       <MenuItem value='select'>SELECT</MenuItem>
                       {dropThree?.dropDown?.map((i) => {
                        return (
                          <MenuItem value={i.tocweekid} key={i.tocweekid}>
                            {i.tocweek}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>{" "}
                </td>
              </tr>

             
            </tbody>
          </table>
        </Grid>

        <div>
          <Stack direction='row'>
            <Stack>
            <button
            style={{
              marginLeft: "21rem",
              marginTop: "1.2rem",
              padding: "0.5cm",
              marginBottom: "1.6rem",
            }}
            type="button"
            className={modules["btn1"]}
            onClick={handleSubmit}
            Table
          >
            Calculate
          </button>

            </Stack>
            <Stack>
            <button
            style={{
              marginLeft: "2rem",
              marginTop: "1.2rem",
              padding: "0.5cm",
              marginBottom: "1.6rem",
            }}
            type="button"
            className={modules["btn1"]}
            onClick={handleReset}
            Table
          >
            Reset
          </button>

            </Stack>

          </Stack>
          
         

          {isLoading && (
            <div className={modules["loading-clipper"]}>
          
              <p>
                <img src={loading} alt="picture missing" />
                Please wait
                <img src={loadingdots} alt="dots missing" />
              </p>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
}
