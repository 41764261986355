import React from "react";
import { Grade } from "@mui/icons-material";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import SampleSidebar from "../../../Landing/SampleSidebar";
import bread from "../../../Assets/bread.png";
import modules from "./TRDLossReportSummary.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { DS_GetAllInstanceHandler } from "../../../Services/Dss";
import { DRTLS_GetFinancialYearHandler,DRTLS_GetTocWeekHandler } from "../../../Services/TRDLoss";
import { DRTLS_CalculateClickHandler } from "../../../Services/TRDLoss";
import loadingss from "../../../Assets/loadingGreen.gif";
import loadingdots from "../../../Assets/LoadingDotsWhiteBlack.gif";


export default function LossReportSummary() {
  const [dropdownData, setDropdownData] = useState([]);

  const [loading, setLoading] = useState(false);


  const[Instance,setInstance]=useState({
    dropDown:[],
    value:''
  })
  const[financialYear,setFinancialYear]=useState({
    dropDown:[],
    value:'select'
  })
  const[toc,setToc]=useState({
    dropDown:[],
    value:'select',
    displayname:''
  })


  useEffect(()=>{
 getInstances();
   

  },[]);

  function getInstances(){
    DS_GetAllInstanceHandler('pritpwc').then(res=>{
      if(res.status===200){
       setInstance({
         dropDown:res.data,
         value:res.data[0].instanceuid
       })
      }
   }).catch(err=>{
     console.log('error fetching data:',err);
   })
  }

  useEffect(()=>{
      DRTLS_GetFinancialYearHandler().then(res=>{
        // console.log(res);
         setFinancialYear({
          ...financialYear,
          dropDown:res?.data?.success?.data
         })
      }).catch(err=>{
        console.log('Error fetching response:',err);
      })
  },[]);


  


  function GetTocHandler(value){
    setFinancialYear({
      ...financialYear,
      value:value
    })
    DRTLS_GetTocWeekHandler(Instance.value,value).then(res=>{
         if(res.status===200){
            setToc({
              dropDown:res?.data?.success?.data,
              value:'select'
            })
         }
    }).catch(err=>{
      console.log('Error fetching data:',err);
    })
  }

  const [data, setData] = useState({
    reportDataBranch: [],
    reportDataPlant: []
  });


  const[dates,setDates]=useState({
    DataBranch:{},
    DataPlant:{}
  })

  function ObjectFilterHandler(location,mtaMto,particular){
   return data.reportDataBranch.filter(i=>{
      if(i.location===location && i.mta_mto===mtaMto && i.particular===particular){
        return true;
      }
      return false;
    })[0];

  }


  function resetHandler(){
   getInstances();
   setFinancialYear({
    ...financialYear,
    value:'select'
   });
   setToc({
    ...toc,
    value:'select'
   })
  }


 function SubmitHandler(){
    !Instance.value?
    alert('Please select Instance Id'):
    financialYear.value==='select'?
    alert('Please select Financial year'):
    toc.value==='select'?
    alert('Please Select Toc week'):
    (()=>{
      setLoading(true);
    DRTLS_CalculateClickHandler(Instance.value, 'pwc', financialYear.value, toc.value).then(res => {
      if (res.status === 200) {
        setData({
          reportDataBranch: res?.data?.success?.data?.Reportdata_Branch,
          reportDataPlant: res?.data?.success?.data?.Reportdata_Plant
        });
        setDates({
          DataBranch:res?.data?.success?.data?.Data_Branch[0],
          DataPlant:res?.data?.success?.data?.Data_Plant[0]
        });
        setLoading(false);
        setViewSummary(true);
      }else if(res?.response?.status===400){
          setLoading(false);
          alert(res?.response?.data?.error?.data?.message)
      }
    }).catch(err => {
      console.log('Error fetching data:', err);
    })
  })()
  }



  const[expand,setExpand]=useState(false);
 
 

 const[viewSummary,setViewSummary]=useState(false);
 


 

 

 
  function ArrowLine() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "2px",
            height: "30px",
            backgroundColor: "grey",
            marginRight: "2px",
          }}
        />
        <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
      </div>
    );
  }

  return (
    
     <>
     {
       !viewSummary?
       <>
       {loading && (
            <div className={modules["loading-clipper"]}>
          
              <p style={{display:'flex',alignItems:'center'}}>
                <img src={loadingss} alt="picture missing" style={{marginRight:'0.5rem'}}/>
                {/* Please wait
                <img src={loadingdots} alt="dots missing" /> */}
              </p>
            </div>
          )}
       <Grid container>
         <Grid
           item
           lg={0.6}
           sx={{ position: "fixed", zIndex: 2000 }}
           className={modules["main-cont"]}
         >
           <SampleSidebar />
         </Grid>
         <Grid item lg={11.4} sx={{ display: "block" }}>
           <Grid item lg={12}>
             <Home />
           </Grid>
         </Grid>
         <Grid item lg={9} className={modules["project_div"]}>
           <div className="pro-bread" style={{ marginLeft: "5rem" }}>
             <p>
               <a>DSS TRD Loss</a>
               <img src={bread} alt="" />
               <a>TRD Loss Summary Report</a>
             </p>
           </div>
         </Grid>
 
         <Grid container sx={{ marginLeft: "5rem", marginTop: "2rem" }}>
           <table className={modules['newTable']}> 
             <tbody>
               <tr>
                 <td>
                   DSS Instance
                 </td>
                 <td> : </td>
                 <td>
                   <FormControl className={modules["form-control"]}>
                     <Select
                       IconComponent={ArrowLine}
                       className={modules["dropdownSelect"]}
                       value={Instance.value}
                       onChange={(e) =>{
                        setFinancialYear({
                          ...financialYear,
                          value:'select'
                        });
                        setToc({
                          ...toc,
                          value:'select'
                        })
                        setInstance({
                         ...Instance,
                         value:e.target.value
                       })}}
                     >
                       {Instance.dropDown?.map((i) => {
                         return (
                           <MenuItem value={i.instanceuid} key={i.instanceuid}>
                             {i.instanceuid}
                           </MenuItem>
                         );
                       })}
                     </Select>
                   </FormControl>
                 </td>
               </tr>
 
               <tr>
                 <td>
                Finance Year
                 </td>
                 <td> : </td>
                 <td>
                   <FormControl className={modules["form-control"]}>
                     <Select
                       IconComponent={ArrowLine}
                       className={modules["dropdownSelect"]}
                       value={financialYear.value}
                       onChange={(e) => GetTocHandler(e.target.value)}
                     >
                      <MenuItem value='select' sx={{display:'none'}}>Select</MenuItem>
                       {financialYear.dropDown?.map((i) => {
                         return (
                           <MenuItem value={i} key={i}>
                             {i}
                           </MenuItem>
                         );
                       })}
                     </Select>
                   </FormControl>
                 </td>
               </tr>
 
               <tr>
                 <td>
              TOC Week
                 </td>
                 <td> : </td>
                 <td>
                   <FormControl className={modules["form-control"]}>
                     <Select
                       IconComponent={ArrowLine}
                       className={modules["dropdownSelect"]}
                       value={toc.value}
                       onChange={(e) => setToc({
                         ...toc,
                         value:e.target.value
                       })}
                     >
                      <MenuItem value='select' sx={{display:'none'}}>Select</MenuItem>
                       {toc?.dropDown?.map((i) => {
                         return (
                           <MenuItem value={i.tocweekid} key={i.tocweekid}>
                             {i.tocweek}
                           </MenuItem>
                         );
                       })}
                     </Select>
                   </FormControl>
                 </td>
               </tr>
 
          
             </tbody>
           </table>
         </Grid>
 
         <div style={{display:"flex"}}>
           <button
             style={{
               marginLeft: "13rem",
               marginTop: "1.2rem",
               padding: "0.5cm",
               marginBottom: "2rem",
             }}
             type="button"
             className={modules["btn1"]}
            onClick={SubmitHandler}
           >
             Submit
           </button>
         
           <button
             style={{
               marginLeft: "5.5rem",
               marginTop: "1.2rem",
               padding: "0.5cm",
               marginBottom: "2rem",
             }}
             type="button"
             className={modules["btn1"]}
            onClick={resetHandler}
           >
             Reset
           </button>
         </div>
       </Grid>
 
       {/* {displayTable && ( */}
     
      
     </>:
     <>
     <div style={{marginTop:'2rem'}}>
       <div style={{ backgroundColor: "#9dc81e", display: "flex", width: "65rem", borderRadius: "3px", marginLeft: "5rem" }}>
         <h5 style={{ color: "white", padding: "0.5%" }}> MTA Reports TRD Loss Summary Report</h5>
         <h5 style={{ color: "white", padding: "0.5%", marginLeft: "41rem" }}> Expand Filter <span onClick={()=>setExpand(!expand)} style={{cursor:'pointer'}}>[{!expand?'+':'-'}]</span></h5>
       </div>
      {
        expand && (
          <div style={{width: "65rem",marginLeft:'5rem'}}>
          <table className={modules['custTableOne']}>
            <tbody>
              <tr>
                <td>MTA Instance</td>
                <td>{Instance.value}</td>
              </tr>
              <tr>
                <td>Finance Year</td>
                <td>{financialYear.value}</td>
              </tr>
              <tr>
                <td>Toc Week</td>
                <td>{toc.value}</td>
              </tr>
            </tbody>
          </table>
          </div>
        )
      }
       <button style={{ marginLeft: "5rem", padding: "1.1%", marginTop: "1%",cursor:'pointer' }} className={modules['btn1']} onClick={()=>{setExpand(false);setViewSummary(!viewSummary)}}>
         Back
       </button>
       <div className={modules['customDiv']}>
         
         <div>Toc Week Id</div>
         <div>{toc.value}</div>
         <div>From:</div>
         <div>{dates?.DataBranch?.fromdate}</div>
         <div>To:</div>
         <div>{dates?.DataBranch?.todate}</div>
       </div>
 
       <h4 style={{ marginLeft: "5rem", marginTop: "1rem" }}>TRD Loss for Branch</h4>
 
       <Grid sx={{  marginTop: "2rem",mx:'auto' }}>
 
         <table className={modules['customTable']}>
           <thead>
             <tr>
               <th colSpan={3} style={{width:'30%'}}>Particular</th>
               <th>TRD Loss</th>
               <th>ThroughPut</th>
               <th >Plan TRD%</th>
               <th>Actual TRD%</th>
               <th >Status</th>
             </tr>
           </thead>
           <tbody>
             {
               ['Kudal','Madkai','Tivim','Traded','Vadodara','Internal','COMMON','Goa (Total)'].map((i)=>{
                 return (
                   <>
                   <tr>
                       <td rowspan='4' style={{backgroundColor:'#9dc81e',textAlign:'center',color:'white'}}>{i}</td>
                       <td style={{backgroundColor:'#9dc81e',color:'white'}}>MTA</td>
                       <td style={{backgroundColor:'#9dc81e',border:'none',color:'white',padding:'0.3rem'}}>NA</td>
                       <td>{ObjectFilterHandler(i,'MTA','NA')?.trdloss}</td>
                       <td>{ObjectFilterHandler(i,'MTA','NA')?.throughputvalue}</td>
                       <td>{ObjectFilterHandler(i,'MTA','NA')?.plantrdloss}</td>
                       <td>{ObjectFilterHandler(i,'MTA','NA')?.actualtrdlosspercentage}</td>
                       <td colSpan={2} style={ObjectFilterHandler(i,'MTA','NA')?.trdlossstatus===0?{backgroundColor:'red'}:{}} className={modules['customTd']}></td>
                     </tr>
       
                     <tr>
                       <td rowspan='3' style={{backgroundColor:'#9dc81e',textAlign:'center',color:'white'}}>MTO</td>
                       <td style={{backgroundColor:'#9dc81e',color:'white',padding:'0.3rem'}}>Export</td>
                       <td>{ObjectFilterHandler(i,'MTO','Export')?.trdloss}</td>
                       <td>{ObjectFilterHandler(i,'MTO','Export')?.throughputvalue}</td>
                       <td>{ObjectFilterHandler(i,'MTO','Export')?.plantrdloss}</td>
                       <td>{ObjectFilterHandler(i,'MTO','Export')?.actualtrdlosspercentage}</td>
                       <td colSpan={2} style={ObjectFilterHandler(i,'MTO','Export')?.trdlossstatus===0?{backgroundColor:'red'}:{}} className={modules['customTd']}></td>
                     </tr>
       
                     <tr>
                       <td style={{backgroundColor:'#9dc81e',color:'white',padding:'0.3rem'}}>Institution</td>
                       <td>{ObjectFilterHandler(i,'MTO','Institution')?.trdloss}</td>
                       <td>{ObjectFilterHandler(i,'MTO','Institution')?.throughputvalue}</td>
                       <td>{ObjectFilterHandler(i,'MTO','Institution')?.plantrdloss}</td>
                       <td>{ObjectFilterHandler(i,'MTO','Institution')?.actualtrdlosspercentage}</td>
                       <td colSpan={2}  style={ObjectFilterHandler(i,'MTO','Institution')?.trdlossstatus===0?{backgroundColor:'red'}:{}} className={modules['customTd']}></td>
                     </tr>
       
                     <tr>
                       <td style={{backgroundColor:'#9dc81e',color:'white',padding:'0.3rem'}}>Retail</td>
                       <td>{ObjectFilterHandler(i,'MTO','Retail')?.trdloss}</td>
                       <td>{ObjectFilterHandler(i,'MTO','Retail')?.throughputvalue}</td>
                       <td>{ObjectFilterHandler(i,'MTO','Retail')?.plantrdloss}</td>
                       <td>{ObjectFilterHandler(i,'MTO','Retail')?.actualtrdlosspercentage}</td>
                       <td colSpan={2}  style={ObjectFilterHandler(i,'MTO','Retail')?.trdlossstatus===0?{backgroundColor:'red'}:{}} className={modules['customTd']}></td>
                       
                     </tr>
 
                     <tr style={{border:'3px solid #9dc81e',marginTop:'10px'}}></tr>
       
                   </>
                 )
               })
             }
            
 
          
          
         </tbody>
       </table>
     
 
    
 
 
     </Grid>
 
     <Grid sx={{marginBottom:'2rem'}}>
     <div className={modules['customDiv']}>
         
         <div>Toc Week Id</div>
         <div>{toc.value}</div>
         <div>From:</div>
         <div>{dates?.DataPlant?.fromdate}</div>
         <div>To:</div>
         <div>{dates?.DataPlant?.todate}</div>
       </div>

       <h4 style={{ marginLeft: "5rem", marginTop: "1rem" }}>TRD Loss for Plant</h4>
       <table className={modules['customTableTwo']}>
         <thead>
         
           <th>Particular</th>
           <th>TRD Loss</th>
           <th>Throughput</th>
           <th>Plan TRD%</th>
           <th>Actual TRD%</th>
           <th>Status</th>
           
         </thead>
         <tbody>
           {
             data.reportDataPlant?.map((i)=>{
                  return (
                   <tr>
                   <td>{i.location}</td>
                   <td>{i.trdloss}</td>
                   <td>{i.throughputvalue}</td>
                   <td>{i.plantrdloss?i.plantrdloss:''}</td>
                   <td>{i.actualtrdlosspercentage}</td>
                   <td style={i.trdlossstatus===0 || i.trdlossstatus===null?{backgroundColor:'red'}:{}}></td>
                 </tr>
                  )
             })
           }
          
          
           </tbody>
       </table>
     </Grid>
   </div >
   </>
     }
     </>
    
   
  );
}
