import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import modules from "./../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetIRExport,
  GetIRIssueOwner,
  Get_PVASearchResult,
  Get_PVABindDepartment_pva,
  Get_PVABindTaskColors,
  Get_PVATaskStatus,
  Get_PVATaskManager,
} from "../../../Services/CCPM.js";

const PlannedVsActual = () => {
  const [selectedInstance, setSelectedInstance] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedAssignedTo, setselectedAssignedTo] = useState("0");
  const [assignedOwner, setAssignedOwner] = useState([]);
  const [selectedissueStatus, setSelectedIssueStatus] = useState("0");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  const handleSelectChange = (attributenumber, id) => {
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [instances, setInstances] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selecteddepartments, setSelectedDepartments] = useState("");
  const [SelectedAvailabilityStatus, setSelectedAvailabilityStatus] =
    useState(0);
  const [taskColors, setTaskColors] = useState([]);
  const [selectedtaskColors, setSelectedTaskColors] = useState("0");
  const [taskStatus, setTaskStatus] = useState([]);
  const [selectedtaskStatus, setselectedTaskStatus] = useState(0);
  const [taskManagers, setTaskManagers] = useState([]);
  const [selectedTaskManagers, setSelectedTaskManagers] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const RowsPerPage = 10;
  const MaxVisiblePages = 10;
  const startIndex = (currentPage - 1) * RowsPerPage;
  const endIndex = startIndex + RowsPerPage;
  const totalPages = Math.ceil(tableData.length / RowsPerPage);
  const visibleData = tableData.slice(startIndex, endIndex);

  const getPageNumbers = () => {
    const totalPages = Math.ceil(tableData.length / RowsPerPage);
    const pageNumbers = [];

    if (totalPages <= MaxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= MaxVisiblePages - 3) {
        for (let i = 1; i <= MaxVisiblePages - 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");

        const remainingPages = totalPages - currentPage;

        if (remainingPages < MaxVisiblePages - 3) {
          for (
            let i = currentPage - (MaxVisiblePages - 4);
            i <= totalPages;
            i++
          ) {
            pageNumbers.push(i);
          }
        } else {
          for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pageNumbers.push(i);
          }

          pageNumbers.push("...");
          pageNumbers.push(totalPages);
        }
      }
    }

    return pageNumbers;
  };

  console.log(visibleData);

  const handlePageChange = (page) => {
    if (page === "prev") {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    } else if (page === "next") {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    } else {
      setCurrentPage(page);
    }
  };

  const accessTypes = [
    { value: "", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ];

  const availabilityStatus = [
    { value: "1", label: "All" },
    { value: "2", label: "Only Available Tasks" },
  ];

  const colorMap = {
    1: "green",
    2: "yellow",
    3: "red",
    4: "lightgreen",
    5: "lightyellow",
    6: "lightpink",
  };

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getAssinedOwner();
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
      getDepartments();
      getTaskColor();
      getTaskStatus();
      getTasksManager();
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
    // getDepartments();
    // getTaskColor();
    // getTaskStatus();
    // getTasksManager();
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    GetPCBindProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTasksManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    Get_PVATaskManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTaskManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssinedOwner = () => {
    const body = {
      userId: 0,
    };
    GetIRIssueOwner(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setAssignedOwner(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDepartments = () => {
    // const body = {
    //   userId: 0,
    // };
    Get_PVABindDepartment_pva()
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setDepartments(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTaskStatus = () => {
    // const body = {
    //   userId: 0,
    // };
    Get_PVATaskStatus()
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTaskStatus(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTaskColor = () => {
    // const body = {
    //   userId: 0,
    // };
    Get_PVABindTaskColors()
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTaskColors(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: parseInt(selectedInstance),
      userId: parseInt(loginId),
      attribute1Id: parseInt(attr1),
      attribute2Id: parseInt(attr2),
      attribute3Id: parseInt(attr3),
      attribute4Id: parseInt(attr4),
      attribute5Id: parseInt(attr5),
      attribute6Id: parseInt(attr6),
      attribute7Id: parseInt(attr7),
      attribute8Id: parseInt(attr8),
      attribute9Id: parseInt(attr9),
      attribute10Id: parseInt(attr10),
      projId: parseInt(selectedProjectName),
      projMgrId: 0,
      taskMgrId: parseInt(selectedTaskManagers),
      accessType: selectedProjectAccessType,
      taskStatusId: parseInt(selectedtaskStatus),
      color: parseInt(selectedtaskColors),
      taskAvailStatus: parseInt(SelectedAvailabilityStatus),
      taskNotMovedSince: 0,
      nextNoOfDays: 0,
      isCCTasks: 0,
      department: parseInt(selecteddepartments),
    };
    setIsLoading(true);

    Get_PVASearchResult(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTableData(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function cleanDateString(dateString) {
    return dateString?.replace("M", "");
  }

  function formatDateTime(date) {
    const cleanedDate = cleanDateString(date);
    const [day, month, year, hours, minutes] = cleanedDate?.split(/[-\s:]/);
    const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}`;

    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(new Date(isoDateString));

    const formattedTime = new Intl.DateTimeFormat("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(new Date(isoDateString));

    const dateParts = formattedDate.split(" ");
    const formattedDateWithHyphens = `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
    return `${formattedDateWithHyphens} ${formattedTime}`;
  }

  function formatDate2(data) {
    const formattedDate = new Date(data).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate.replace(/ /g, "-");
  }

  const getExcel = async (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      issueOwner: selectedAssignedTo,
      projectType: selectedProjectAccessType,
      accessType: "",
      issueStatusId: selectedissueStatus,
    };

    // if (selectedRoleId !== -1) {
    try {
      setIsLoading(true);
      const response = await GetIRExport(body);

      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // const contentDisposition = response.headers["content-disposition"];
        const filename = "IssueReport.xlsx";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoading(false);
        console.error("No Data found");
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error downloading file:", err);
    }
  };

  const handleSearch = () => {
    setIsLoading(true);
    setShowResult(true);

    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    getSearchResult(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  const DownloadHandler = () => {
    setShowResult(true);
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    getExcel(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  return (
    <>
      <div
        style={{
          overflowX: showResult && tableData.length > 0 ? "auto" : "hidden",
          height: "100vh",
        }}
      >
        {isLoading && (
          <img
            src={loading}
            className={modules["loading_gif"]}
            alt="Loading..."
          />
        )}
        <Grid container>
          <Grid item lg={0.6} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>

          <Grid item lg={12} sx={{ display: "block" }}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"PlannedVsActual"} />
            </Grid>

            <Grid item lg={12}>
              <div
                className={modules["instancemaster_div"]}
                style={{ marginBottom: "5px" }}
              >
                <div
                  className={modules["pro-bread"]}
                  style={{ marginLeft: "-2.5rem" }}
                >
                  <a>Reports</a>
                  <img src={bread} alt="" />
                  <a>Planned Vs Actual</a>
                </div>
              </div>
              <div className={modules["border_cont"]}>
                <table className={modules["PCtable"]}>
                  <tbody>
                    <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Instance:<label style={{ color: "Red" }}>*</label>
                        </span>

                        <select
                          value={selectedInstance}
                          onChange={(e) => {
                            setSelectedInstance(e.target.value);
                            getProjectDetails(e.target.value);
                          }}
                          className={modules["dropdownSelect"]}
                          // style={{ width: "200px" }}
                        >
                          {instances?.map((instance) => (
                            <option key={instance.name} value={instance.id}>
                              {instance.name}
                            </option>
                          ))}
                        </select>
                      </td>

                      {hierarchyOptions
                        ?.filter((option) => option.isactive === 1)
                        .map((option, index) => (
                          <React.Fragment key={option.attributename}>
                            <td className={modules["table_data1"]}>
                              <span
                                style={{ width: "115px", color: "#414141" }}
                              >
                                {option.attributename} :
                              </span>
                              <select
                                value={
                                  selectedAttributes[
                                    `attr${option.attributenumber}`
                                  ] || ""
                                }
                                onChange={(e) =>
                                  handleSelectChange(
                                    option.attributenumber,
                                    e.target.value
                                  )
                                }
                                className={modules["dropdownSelect"]}
                                // style={{ width: "200px" }}
                              >
                                <option value="">SELECT</option>
                                {filteredValueData[index]?.map((item) => (
                                  <option
                                    key={item.attributevalue}
                                    value={item.id}
                                  >
                                    {item.attributevalue}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </React.Fragment>
                        ))}
                    </tr>

                    <tr
                      style={{
                        paddingBottom: "10px",
                        paddingLeft: "0px",
                      }}
                    >
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Project Name :
                        </span>
                        <select
                          value={selectedProjectName}
                          onChange={(e) =>
                            setSelectedProjectName(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={0}>SELECT</option>
                          {projects?.map((p_name) => (
                            <option key={p_name.projectname} value={p_name.id}>
                              {p_name.projectname}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Task Manager :
                        </span>
                        <select
                          value={selectedProjectManager}
                          onChange={(e) =>
                            setSelectedProjectManager(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={0}>SELECT</option>
                          {managers?.map((m_name) => (
                            <option key={m_name.username} value={m_name.id}>
                              {m_name.username}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Task AccessType:
                        </span>
                        <select
                          value={selectedProjectAccessType}
                          onChange={(e) => {
                            setSelectedProjectAccessType(e.target.value);
                          }}
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          {accessTypes?.map((access) => (
                            <option key={access.label} value={access.value}>
                              {access.label}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Task Status :
                        </span>
                        <select
                          value={selectedtaskStatus}
                          onChange={(e) =>
                            setselectedTaskStatus(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={0}>SELECT</option>
                          <option value={0}>All</option>
                          {taskStatus?.map((assign) => (
                            <option key={assign.taskstatus} value={assign.id}>
                              {assign.taskstatus}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Task Color :
                        </span>
                        <select
                          value={selectedtaskColors}
                          onChange={(e) =>
                            setSelectedTaskColors(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={"0"}>SELECT</option>
                          {taskColors?.map((item1) => (
                            <option key={item1.taskcolor} value={item1.id}>
                              {item1.taskcolor}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Task Availability Status :
                        </span>
                        <select
                          value={SelectedAvailabilityStatus}
                          onChange={(e) =>
                            setSelectedAvailabilityStatus(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={0}>-- Select --</option>
                          {availabilityStatus?.map((status) => (
                            <option key={status.label} value={status.value}>
                              {status.label}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Department :
                        </span>
                        <select
                          value={selecteddepartments}
                          onChange={(e) =>
                            setSelectedDepartments(e.target.value)
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={""}>SELECT</option>
                          {departments?.map((items) => (
                            <option
                              key={items.department}
                              value={items.department}
                            >
                              {items.department}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>

                    <tr
                      style={{
                        justifyContent: "center",
                        gap: "10px",
                        paddingLeft: "0px",
                        paddingBottom: "10px",
                      }}
                    >
                      <button
                        type="submit"
                        className={modules["PCbtn"]}
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                      <button
                        className={modules["PCbtn"]}
                        type="button"
                        onClick={() => {
                          window.location.reload();
                          setShowResult(false);
                        }}
                      >
                        Reset
                      </button>
                      {showResult && tableData.length > 0 && (
                        <button
                          type="submit"
                          className={modules["PCbtn"]}
                          onClick={DownloadHandler}
                        >
                          Export to Excel
                        </button>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>

              {showResult && visibleData.length > 0 && (
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      margin: "38px 0px 5% 6.5rem",
                      marginBottom: "2%",
                      overflow: "hidden scroll",
                      width: "105%",
                    }}
                  >
                    <div>
                      <table
                        cellspacing="1"
                        className={modules["IM_custom-table2"]}
                        style={{ marginRight: "20px" }}
                      >
                        <thead>
                          <tr>
                            <th>Sr.No.</th>
                            <th>Project Name</th>
                            <th>Department</th>
                            <th>Task Name</th>
                            <th>Task Manager</th>
                            <th>Planned Start</th>
                            <th>Planned End</th>
                            <th>Actual Start</th>
                            <th>Projected / Actual End</th>
                            <th>Status</th>
                            <th>Remaining Duration</th>
                            <th>% Buffer Penetration</th>
                            <th>Last Updated On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {visibleData?.map((item, index) => (
                            <tr key={index}>
                              <td
                                style={{ width: "10px" }}
                                className={modules["IM_alignL"]}
                              >
                                {(currentPage - 1) * RowsPerPage + index + 1}
                              </td>
                              <td style={{ width: "10%" }}>
                                {item.projectname}
                              </td>
                              <td style={{ width: "10%" }}>
                                {item.department}
                              </td>
                              <td style={{ width: "10%" }}>{item.taskname}</td>
                              <td
                                style={{
                                  width: "10%",
                                  backgroundColor:
                                    colorMap[item.color] || "white",
                                }}
                              >
                                {item.taskmanager}
                              </td>
                              <td style={{ width: "10%" }}>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "110px",
                                  }}
                                >
                                  {item["Expected/Planned TaskStartDate"]
                                    ? formatDateTime(
                                        item["Expected/Planned TaskStartDate"]
                                      )
                                    : ""}
                                </span>
                              </td>
                              <td style={{ width: "10%" }}>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "110px",
                                  }}
                                >
                                  {item["Expected/Planned TaskEndDate"]
                                    ? formatDateTime(
                                        item["Expected/Planned TaskEndDate"]
                                      )
                                    : ""}
                                </span>
                              </td>
                              <td style={{ width: "10%" }}>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "110px",
                                  }}
                                >
                                  {item["Expected/Actual TaskStartDate"]
                                    ? formatDateTime(
                                        item["Expected/Actual TaskStartDate"]
                                      )
                                    : ""}
                                </span>
                              </td>
                              <td style={{ width: "10%" }}>
                                {formatDate2(item.projectedfinishdate)}
                              </td>
                              <td>{item.status}</td>
                              <td style={{ width: "10%" }}>
                                {item.remainingduration?.toFixed(2)}
                              </td>
                              <td>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "50px",
                                  }}
                                >
                                  {item.bufferpenetration?.toFixed(2)}
                                </span>
                              </td>
                              <td style={{ width: "10%" }}>
                                {formatDate2(item.lastupdatedon)}
                              </td>
                            </tr>
                          ))}
                          {totalPages > 1 && (
                            <tr>
                              <td colSpan={16}>
                                <div
                                  style={{
                                    width: "fit-content",
                                    borderBottom: "1px solid rgb(164, 191, 71)",
                                    padding: "4px",
                                  }}
                                >
                                  {getPageNumbers().map((pageNumber, index) => (
                                    <span
                                      key={index}
                                      className={
                                        pageNumber === currentPage
                                          ? `${modules["active-page"]}`
                                          : `${modules["page-number"]}`
                                      }
                                      onClick={() =>
                                        typeof pageNumber === "number"
                                          ? handlePageChange(pageNumber)
                                          : null
                                      }
                                    >
                                      {pageNumber}
                                    </span>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PlannedVsActual;
