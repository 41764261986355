import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/CM1.jpg";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";

const CustomerMasterhelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>Customer Master</h3>
              <br />
              <li>
                Customer master screen is used for creating and maintaining the
                customers for the defined level of hierarchy for the Division.
              </li>
              <li>PATH : CCPM \ Masters \ Customer Masters.</li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC1} alt="IC1" />
              </Stack>
              <li>
                For adding the data for customer master screen, click on “Add”
                button then enter or select the data in to the available input
                fields and click on Save link the data will get saved.
              </li>
              <br />

              <li>
                For deleting any record from the grid, select the one or
                multiple records from the grid using check boxes and click on
                the “Delete” button, selected record will get deleted.
                <span style={{ color: "red" }}>
                  a customer can’t be deleted if is already attached to a
                  project.
                </span>
              </li>

              <li>
                For doing any updations to the data, click on Edit link and do
                the required changes and click on Save link to save the changes
                or click on Cancel link to cancel the changes done to the data.
              </li>
              <br />
              <li>
                For searching any customer name, we have to select the data in
                the level named field above the field “Customer Name” and enter
                the data to search in field “Customer Name” and click on
                “Search” button. The searched result will get displayed and
                “Reset” button can be used to remove the filter criteria and
                whole data can be seen in the grid
              </li>
              <br />
              <li style={{ listStyleType: "square" }}>
                <span style={{ textDecoration: "underline" }}>NOTE:-</span> The
                field “Department” shown in the above screenshot is actually the
                “Level” which is defined in the “Product Configuration Settings”
                screen for the “Customer Master”
              </li>
              <br/>
              <li
                style={{ listStyleType: "square", textDecoration: "underline" }}
              >
                Validations on the screen:-
              </li>
              <br />
              <li>
                Duplication of existing record should not be allowed i.e
                re-adding of the existing record again.
              </li>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC2} alt="IC2" />
              </Stack>

              <li>
                User should not leave any input field blank before saving the
                data, as click of “Save” link keeping the fields empty makes the
                error popup window to appear
              </li>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC3} alt="IC3" />
              </Stack>

              <li>
                User should make selection of the data before the click of
                “Delete” button, as without selection of data the popup window
                appears
              </li>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC4} alt="IC4" />
              </Stack>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerMasterhelpPopup;
