import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import PLT1 from "../HelpPopups/images/PLT1.jpg";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const ProjectLeadTimehelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul className="no-bullets">
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>
                PROJECT LEAD TIME REPORT
              </h3>
              <br />
              <li>
                In this report User can see the details like Planned Lead time,
                Actual Lead time ,Planned finish date and actual finish date.
              </li>
              <br />

              <li>PATH :CCPM\REPORTS\PROJECT LEAD TIME</li>
              <br />

              <li>
                this report includes the selection of Division,BU/SBU , Project
                manager and report show the desired output depending upon the
                selection.
              </li>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={PLT1} alt="PLT1" />
              </Stack>


              <br />

            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectLeadTimehelpPopup;
