import React, { useEffect, useState } from "react";
import "../../../HelpPopups/HelpPopup.css";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import modules from "../../ccpm.module.css";
import { Grid } from "@mui/material";
import bread from "../../../../Assets/bread.png";
import { PendingTaskPopup,Events_Get,Events_Save} from "../../../../Services/CCPM";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";
import loading from "../../../../Assets/Loading_image.gif";

const EventPopup = () => {
  const [criticalData, setCriticalData] = useState([]);
  const [feedingData, setFeedingData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = parseInt(searchParams.get("projId"));
  const [isLoading, setIsLoading] = useState(false);
  const [projectNotes, setProjectNotes] = useState('');
  const [TableData, setTableData] = useState([]);

  useEffect(() => {
    GetDetails();
    }, []);

  const handleSave =() =>{
    setIsLoading(true);
    let body = {
      projId: projectId,
      notes: projectNotes,
      userId: parseInt(sessionStorage.getItem("loginId"))
    }
    Events_Save(body)
    .then((response) => {
      console.log(response);
      if (response?.status === 200) {
        setIsLoading(false);
        alert(response?.data?.message);
        GetDetails(); // Call GetDetails to fetch updated data
      } else if (response?.response?.data?.message !== undefined) {
        setIsLoading(false);
        alert(response?.response?.data?.message);
      } else if (response?.response?.data?.error?.message !== undefined) {
        setIsLoading(false);
        alert(response?.response?.data?.error?.message);
      } else {
        setIsLoading(false);
        alert(response?.response?.data?.success?.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
    
  }

  const GetDetails = () => {
    setIsLoading(true);
    let body = {
      projectId: projectId,
    };
    Events_Get(body)
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          setIsLoading(false);
          setTableData(response?.data?.success?.data);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          if(response?.response?.data?.error?.message)
          alert(response.response.data.error.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlePendingTask = () => {
    setIsLoading(true);
    let body = {
      projectId: projectId,
    };
    PendingTaskPopup(body)
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          setIsLoading(false);
          setCriticalData(response?.data?.success?.data?.CriticalChain);
          setFeedingData(response?.data?.success?.data?.FeedingChain);
        } else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        } else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    handlePendingTask();
  }, []);

   
    const handleReset = () => {
        setProjectNotes('');
    };

    const RowsPerPage = 5;
    const MaxVisiblePages = 5;
  
    const [currentPage, setCurrentPage] = useState(1);
  
    // Calculate total pages based on chartData length
    const totalPages = Math.ceil(TableData.length / RowsPerPage);
    const startIndex = (currentPage - 1) * RowsPerPage;
    const endIndex = startIndex + RowsPerPage;
    const displayedData = TableData.slice(startIndex, endIndex);
  
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        if (totalPages <= MaxVisiblePages) {
          for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
          }
        } else {
          if (currentPage <= MaxVisiblePages - 3) {
            for (let i = 1; i <= MaxVisiblePages - 2; i++) {
              pageNumbers.push(i);
            }
            pageNumbers.push("...");
            pageNumbers.push(totalPages);
          } else {
            pageNumbers.push(1);
            pageNumbers.push("...");
    
            const remainingPages = totalPages - currentPage;

            if (remainingPages < MaxVisiblePages - 3) {
                for (let i = currentPage - (MaxVisiblePages - 4); i <= totalPages; i++) {
                  pageNumbers.push(i);
                }
              } else {
                for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                  pageNumbers.push(i);
                }
      
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
              }
            }
          }
          return pageNumbers;
        };
     

  return (
    <>
      <div>
        {isLoading && (
          <img
            src={loading}
            className={modules["loading_gif"]}
            alt="Loading..."
          />
        )}

        <div
          style={{
            marginLeft: "0.8rem",
            display: "flex",
            flexDirection: "row",
            gap: "70px",
            marginTop: "1rem",
          }}
        >
          <span className={modules["projectNotes"]}>Project Notes</span>
          <textarea
            style={{ height: "70px" }}
            cols="75"
            value={projectNotes}
            onChange={(e) => setProjectNotes(e.target.value)}
            placeholder="Enter your notes here"
          />
        </div>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={modules["btn1-cont_Events"]}
          style={{ marginLeft: "10rem" }}
        >
          <Grid item>
            <button className={modules["btn1_Events"]} onClick={handleSave}>
              Save
            </button>
          </Grid>
          <Grid item>
            <button className={modules["btn2_Events"]} onClick={handleReset}>
              Reset
            </button>
          </Grid>
        </Grid>
        {TableData.length > 0 && (
          <div
            className={modules["table-overflow_Events"]}
            style={{
              marginLeft: "0.3rem",
              width: "99%",
              fontFamily: "'Work Sans', sans-serif",
              border: "2px solid black",
            }}
          >
            <div
              className={modules["table-overflow_Events"]}
              style={{
                width: "100%",
                fontFamily: "'Work Sans', sans-serif",
              }}
            >
              <Table
                className={[
                  modules["custom-table_viewpending_Events"],
                  modules["custom-table-mp-th"],
                ].join(" ")}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.No</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Project Notes</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Created On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedData.map((item, index) => (
                    <TableRow
                      key={index} // Adding key for each row
                      className={modules["table-row"]}
                      style={{ backgroundColor: "#DEDFDE" }}
                    >
                      <TableCell>{startIndex + index + 1}</TableCell>
                      <TableCell>{item.projectname}</TableCell>
                      <TableCell>{item.projectnotes}</TableCell>
                      <TableCell>{item.username}</TableCell>
                      <TableCell>{item.createdon}</TableCell>
                    </TableRow>
                  ))}
                  {totalPages > 1 && (
                    <TableRow style={{ backgroundColor: "#C6C3C6 " }}>
                      <TableCell
                        colSpan={16}
                        style={{ backgroundColor: "#C6C3C6 " }}
                      >
                        <div
                          style={{
                            textAlign: "end",
                            backgroundColor: "#C6C3C6 ",
                          }}
                        >
                          {getPageNumbers().map((pageNumber, index) => (
                            <span
                              key={index}
                              className={
                                pageNumber === currentPage
                                  ? `${modules["active-page_Events"]}`
                                  : `${modules["page-number_Events"]}`
                              }
                              onMouseDown={(e) => e.preventDefault()} // Prevent focus on click
                              onClick={() =>
                                typeof pageNumber === "number"
                                  ? handlePageChange(pageNumber)
                                  : null
                              }
                            >
                              {pageNumber}
                            </span>
                          ))}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EventPopup;
