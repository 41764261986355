import { Grid } from "@mui/material";
import SideBar from "../../../Landing/Navbar";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import modules from "./../DSS.module.css";
import {
  GetAllInstancesHandler,
  UpdateAllInstanceHandler,
  UpdateOneInstancesHandler,
} from "../../../Services/Dss";
import { useEffect, useState } from "react";
import SampleSidebar from "../../../Landing/SampleSidebar";

export default function InstanceActiveInActive() {
  const [pageData, setPageData] = useState([]);

  const [rowStates, setRowStates] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    getHandler();
  }, []);

  function getHandler() {
    GetAllInstancesHandler()
      .then((res) => {
        if (res.status === 200) {
          setPageData(res.data);
          setRowStates(
            res.data.map((i) => {
              return i.activeInactive === "Y" ? true : false;
            })
          );
        } else if (res.status === 400) {
          alert(res.response.data.title);
        } else {
          alert(res.data);
        }
      })
      .catch((err) => {
        alert("Error fetching data:", err);
      });
  }

  function SingleUpdateHandler(index, state) {
    UpdateOneInstancesHandler(pageData[index].instanceuid, state ? 1 : 0)
      .then((res) => {
        if (res.status === 200) {
          alert(res.data);
          setSelectAll(false);
          getHandler();
        } else if (res.response.status === 400) {
          alert(res.response.data.title);
        } else {
          alert(res.data);
        }
      })
      .catch((err) => {
        alert("Error while Updating:", err);
      });
  }

  function linkHandler(idx) {
    setRowStates(
      rowStates?.map((i, index) => {
        if (idx === index) {
          SingleUpdateHandler(idx, !rowStates[index]);
          return !rowStates[index];
        } else {
          return i;
        }
      })
    );
  }

  function selectAllLinkHandler() {
    UpdateAllInstanceHandler(selectAll ? 0 : 1)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setSelectAll(!selectAll);
          getHandler();
          alert(res.data);
        } else if (res.response.status === 400) {
          alert(res.response.data.title);
        } else {
          alert(res.data);
        }
      })
      .catch((err) => {
        alert("Error while Updating data:", err);
      });
  }

  return (
    <>
      <>
        <Grid container>
          <Grid item lg={0.7} className={modules["main_cont"]}>
            {/* <SideBar /> */}
            <SampleSidebar />
          </Grid>
          <Grid item lg={11.3} sx={{}}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home />
            </Grid>

            <Grid item xs={12}>
              <div className={modules["usermaster_div"]}>
                <div className={modules["pro-bread"]}>
                  <a>DSS Admin</a>
                  <img src={bread} alt="" />
                  <a>Instance Active/InActive</a>
                </div>
              </div>
            </Grid>
            <Grid
              sx={{
                marginLeft: "6rem",
                marginTop: "1rem",
              }}
            >
              <Grid
                sx={{
                  marginTop: "1rem",
                }}
              >
                {pageData?.length > 0 ? (
                  <table className={modules["customTbl"]}>
                    <thead>
                      <th>DSS Instance</th>
                      <th>
                        <input
                          type="checkbox"
                          onClick={selectAllLinkHandler}
                          checked={selectAll}
                        />
                        Active/InActive
                      </th>
                    </thead>
                    <tbody>
                      {pageData?.map((ele, index) => {
                        return (
                          <tr>
                            <td>{ele.instanceuid}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={rowStates[index]}
                                onClick={() => linkHandler(index)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </>
  );
}
