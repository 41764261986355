import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SPPC1 from "../HelpPopups/images/SPPC1.png";
import SPPC2 from "../HelpPopups/images/SPPC2.png";
import SPPC3 from "../HelpPopups/images/SPPC3.png";
import SPPC4 from "../HelpPopups/images/SPPC4.png";
import SPPC5 from "../HelpPopups/images/SPPC5.png";


import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointProductClassConfigurationIncrementalhelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h2 style={{ marginLeft: "0rem", textAlign: "left" }}>
                                Stock Point Product Class Configuration
                            </h2>
                            <br />
                            <br />



                            <li className="no-bullets">
                                It is accessed from below path in Prostream.
                            </li>
                            <br />
                            <li className="no-bullets">
                                Path `{">>"}` MTA `{">>"}` MTA Parameter `{">>"}` Stock Point Product Class Configuration
                            </li>
                            <br />
                            <br />
                            <br />
                            <li className="no-bullets">
                                There are two functions in SPPCC which can be selected from the dropdown option as below.
                            </li>
                            <br />
                            <br />
                            <li className="no-bullets">
                                1. Upload
                            </li>
                            <li className="no-bullets">
                                2. Download
                            </li>
                            <br />
                            <br />
                            <br />
                            <li className="no-bullets" style={{ textDecoration: "underline" }}>
                                1. Upload
                            </li>
                            <br />
                            <li>
                                For incremental upload it is required to select required instance from the dropdown option given against MTA Instance*.

                            </li>
                            <br />
                            <li>
                                From the Action* menu selection can be made for Upload. On selecting ‘Upload’ below it, choose file option will reflect with provision to attach the file from local machine. Refer screenshot A for same.

                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPPC1} alt="SPPC1" />
                            </Stack>
                            <br />
                            <li>
                                Below is file format which is attached from the local machine for upload action.
                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPPC2} alt="SPPC2" />
                            </Stack>
                            <br />
                            <li> On successfully uploading the file from local machine it shows result with three different records as Records Inserted, Records Updated and Records deleted. Also result can be downloaded in excel file by clicking ‘Result File’ button. Refer Screenshot C.
                            </li>

                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPPC3} alt="SPPC3" />
                            </Stack>
                            <br />


                            <li className="no-bullets" style={{ textDecoration: "underline" }}>
                                2. Download:
                            </li>
                            <br />
                            <li className="no-bullets">
                                After the instance selection from dropdown option of ‘MTA Instance*’, further ‘Download’ option need to be select from action dropdown for downloading the ‘Stock point product class configuration’ report.

                            </li>
                            <li className="no-bullets">
                                It will get download in excel format once the Submit button is clicked. Below is screenshot D to refer.
                            </li>
                            <br />
                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPPC4} alt="SPPC4" />
                            </Stack>
                            <br />
                            <li>
                                Below is screenshot for downloaded excel format.
                            </li>

                            <br />
                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPPC5} alt="SPPC5" />
                            </Stack>
                            <br />
                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StockPointProductClassConfigurationIncrementalhelpPopup;
