import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IL1 from "../HelpPopups/images/IL1.png";
import IL3 from "../HelpPopups/images/IL2.png";
import IL2 from "../HelpPopups/images/IL3.png";
import IL4 from "../HelpPopups/images/IL4.png";
import IL5 from "../HelpPopups/images/IL5.png";
import IL6 from "../HelpPopups/images/IL6.png";
import IL7 from "../HelpPopups/images/IL7.png";
import IL8 from "../HelpPopups/images/IL8.png";
import IL9 from "../HelpPopups/images/IL9.png";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const IssueListhelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul className="no-bullets">
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>Issue List</h3>
              <br />
              <li>
                Issue List contains the list of issues which is to be resolved by the user (Task Manager).
              </li>
              <br />

              <li>Path :- CCPM \ Risk Management \ Risk Updation Report.
              </li>
              <br />

              <li>
                The issues in the Issue list appear from the task management screen, where the project manager raises the issue against the respective or any other task manager using the below screen.
              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IL1} alt="IL1" />
              </Stack>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IL2} alt="IL2" />
              </Stack>
              <li>
                On click of the Submit button, the issue is getting added. Here the project manager can reassign the assigned task to some other task manager as well until the “ResolveAction” is “Not Resolved”.
              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IL3} alt="IL3" />
              </Stack>
              <li>
                Once the issue is raised against the task manager, the issue appears in the task manager’s Issue list.
              </li>
              <li>
                Based on the task and project level color code will be displayed on Task name.
              </li>
              <li>
                Logic :-
              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IL4} alt="IL4" />
              </Stack>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IL5} alt="IL5" />
              </Stack>
              <li>
                When the user clicks the “Resolve Issue” button, Resolve Issue page appears where user has to enter the “Resolution” and select the action for the Issue and click on the “Submit” button.

              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IL6} alt="IL6" />
              </Stack>
              <li>
                After the above step, the issue from the issue list get disappears.
              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IL7} alt="IL7" />
              </Stack>
              <li>
                The Issue gets updated with the edited details in the project manager’s Task management(from where the Issue was raised).
              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IL8} alt="I87" />
              </Stack>

              <li>
                Here project manager should close the issue by clicking the “Close Issue” button. Also project manager can only close the issue with the “Resolution Action” as “Resolved”.
              </li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IL9} alt="IL9" />
              </Stack>
              <li>
                After closing the issue, the Issue status will change to “Closed”.


              </li>


              <br />
              <br />
              <br />
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default IssueListhelpPopup;
