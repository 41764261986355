import { React, useState, useEffect } from "react";
import { Grid, Stack, TextField, Button, Typography, Box } from "@mui/material";
import modules from "../project.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import SampleSidebar from "../../../../Landing/SampleSidebar";
import Home from "../../../../home/Home";
import {
  GetCCPMProjDetails,
  GetCCPMParams,
  ApplyBtnClick,
} from "../../../../Services/CCPM";
import loading from "../../../../Assets/Loading_image.gif";

const CCPMSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const projId = searchParams.get("projId");
  const [projectName, setProjectName] = useState("");
  const [inputValueRed, setInputValueRed] = useState("");
  const [inputValueProjB, setInputValueProjB] = useState("");
  const [inputValueFeedB, setInputValueFeedB] = useState("");

  const [isDisabled, setIsDisabled] = useState(false);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    fetchProjectDetails();
    fetchParams();
  }, []);

  const fetchProjectDetails = async () => {
    setIsLoading(true);
    let body = {
      projectId: parseInt(projId),
    };
    try {
      const response = await GetCCPMProjDetails(body);
      if (response.status === 200) {
        const projectDetails = response?.data?.success?.data[0];
        setProjectName(projectDetails?.projectname);
      } else {
        console.error("Unexpected response format or empty data");
      }
    } catch (error) {
      console.error("Error fetching project details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchParams = async () => {
    setIsLoading(true);
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      projectsId: projId,
    };
    try {
      const response = await GetCCPMParams(body);
      if (response.status === 200) {
        if (response?.data?.error?.message) {
          alert(response?.data?.error?.message);
          setIsDisabled(true);
          setApplyDisabled(true);
        } else {
          setApplyDisabled(false);
          const { CCPMSettingValue, AttributeValues } =
            response?.data?.success?.data;
          if (
            CCPMSettingValue?.[0]?.isinstancelevel === 1 ||
            CCPMSettingValue?.[0]?.isattributelevel === 1
          ) {
            setIsDisabled(true);
            if (AttributeValues?.[0]) {
              setInputValueRed(AttributeValues[0].taskreductionper);
              setInputValueProjB(AttributeValues[0].projectbufferreductionper);
              setInputValueFeedB(AttributeValues[0].feedingbufferreductionper);
            }
          } else if (CCPMSettingValue?.[0]?.isnotrequired === 1) {
            setIsDisabled(false);
            if (AttributeValues?.[0]) {
              setInputValueRed(AttributeValues[0].taskreductionper);
              setInputValueProjB(AttributeValues[0].projectbufferreductionper);
              setInputValueFeedB(AttributeValues[0].feedingbufferreductionper);
            }
          }
        }
      } else {
        console.error("Unexpected response status:", response.statusCode);
      }
    } catch (error) {
      console.error("Error fetching params:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeRed = (e) => {
    const value = e.target.value;
    if (
      /^\d*$/.test(value) &&
      (value === "" || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 99))
    ) {
      setInputValueRed(value);
    }
  };

  const handleChangeProjB = (e) => {
    const value = e.target.value;
    if (
      /^\d*$/.test(value) &&
      (value === "" || (parseInt(value, 10) >= 1 && parseInt(value, 10) <= 99))
    ) {
      setInputValueProjB(value);
    }
  };

  const handleChangeFeedB = (e) => {
    const value = e.target.value;
    if (
      /^\d*$/.test(value) &&
      (value === "" || (parseInt(value, 10) >= 1 && parseInt(value, 10) <= 99))
    ) {
      setInputValueFeedB(value);
    }
  };

  const closePopupAndNavigate = () => {
    // Remove any local storage items you need to clear
    localStorage.removeItem("instanceId"); // Example: Adjust as necessary
    window.opener.localStorage.setItem("cameFromSettings", "true");
    window.opener.location.href = "/ccpm/myprojects?reset=true";
    window.close();
};

  const handlePopupClose = () => {
    closePopupAndNavigate();
  };

  const handleApplyBtnClick = () => {
   setIsLoading(true);
    
      let requestBody = {
        projectId: parseInt(projId),
        reductionPercent: parseInt(inputValueRed),
        projectBufferPercent: parseInt(inputValueProjB),
        feedingBufferPercent: parseInt(inputValueFeedB),
        projectBufferDays: 0,
        feedingBufferDays: 0,
        userId: parseInt(sessionStorage.getItem("loginId")),
      };
      ApplyBtnClick(requestBody)
        .then((response) => {
          if (response.status === 200) {
            alert(response.data.message);
            setIsLoading(false);
            navigate(-1);
            closePopupAndNavigate();
          } else {
            alert(response.data.error.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error applying settings:", error);
          alert("Error applying settings");
          setIsLoading(false);
        });
   
  };

  return (
    <>
      {isLoading && (
        <div className={modules["loading_gif"]}>
          <span style={{ textAlign: "center" }}>Please Wait....</span>
          <img src={loading} alt="Loading..." />
        </div>
      )}
     
      <Box sx={{ width: "90%", marginLeft: '7%', marginTop: "1%" }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',

          }}
        >
          <tbody>
            {/* Header Row */}
            <tr>
              <td colSpan="2" style={{ textAlign: 'center', padding: '16px' }}>
                <Typography className={modules["inputCCPMSet"]}>
                  Apply CCPM Settings
                </Typography>
              </td>
            </tr>

            {/* Project Name */}
            <tr style={{ height: '55px' }}>
              <td style={{ padding: '8px', width: '25%' }}>
                <Typography className={modules["inputCCPMSet"]}>
                  Project Name :
                </Typography>
              </td>
              <td style={{ padding: '8px' }}>
                <Typography className={modules["inputCCPMSet"]}>
                  {projectName}
                </Typography>
              </td>
            </tr>

            {/* Reduction From Task Duration */}
            <tr style={{ height: '55px' }}>
              <td style={{ padding: '8px', width: '25%' }}>
                <Typography className={modules["inputCCPMSet"]}>
                  Reduction From Task Duration :<span style={{ color: 'red' }}> *</span>
                </Typography>
              </td>
              <td style={{ padding: '8px' }}>
                <input
                  type="text"
                  maxLength="2"
                  value={inputValueRed}
                  className={modules["inputPopup"]}
                  onChange={handleChangeRed}
                  disabled={isDisabled}
                  style={{
                    textAlign:'center',
                    width: '5%',
                    height: '25px',
                    backgroundColor: isDisabled ? '#EFEFEF4D' : 'white', // Grey background for disabled
                    border: `1px solid ${isDisabled ? 'rgba(118,118,118,0.3)' : '#000'}`, // Grey border for disabled
                    borderColor:'rgba(118,118,118,0.3) !important',
                    padding: ' 1px 2px',
                    cursor: isDisabled 
                  }} // Ensure full width within the cell

                />
                <span className={modules["inputCCPMSet"]}>
                  % (Range: Integer between 0 and 99)
                </span>
              </td>
            </tr>

            {/* Project Buffer */}
            <tr style={{ height: '55px' }}>
              <td style={{ padding: '8px', width: '25%' }}>
                <span className={modules["inputCCPMSet"]}>
                  Project Buffer :<span style={{ color: 'red' }}> *</span>
                </span>
              </td>
              <td style={{ padding: '8px' }}>
                <input
                  type="text"
                  maxLength="2"
                  value={inputValueProjB}
                  className={modules["inputPopup"]}
                  onChange={handleChangeProjB}
                  disabled={isDisabled}
                  style={{
                    textAlign:'center',
                    width: '5%',
                    height: '25px',
                    backgroundColor: isDisabled ? '#EFEFEF4D' : 'white', // Grey background for disabled
                    border: `1px solid ${isDisabled ? '#b0b0b0' : '#000'}`, // Grey border for disabled
                    padding: ' 1px 2px',
                    cursor: isDisabled 

                  }} // Ensure full width within the cell

                />
                <span className={modules["inputCCPMSet"]}>
                  % (Range: Integer between 1 and 99)
                </span>
              </td>
            </tr>

            {/* Feeding Buffer */}
            <tr style={{ height: '70px' }}>
              <td style={{ padding: '8px', width: '25%' }}>
                <Typography className={modules["inputCCPMSet"]}>
                  Feeding Buffer :<span style={{ color: 'red' }}> *</span>
                </Typography>
              </td>
              <td style={{ padding: '8px' }}>
                <input
                  type="text"
                  maxLength="2"
                  value={inputValueFeedB}
                  className={modules["inputPopup"]}
                  onChange={handleChangeFeedB}
                  disabled={isDisabled}
                  style={{
                    textAlign:'center',
                    justifyContent: 'center',
                    width: '5%',
                    height: '25px',
                    backgroundColor: isDisabled ? '#EFEFEF4D' : 'white', // Grey background for disabled
                    border: `1px solid ${isDisabled ? '#b0b0b0' : '#000'}`, // Grey border for disabled
                    padding: ' 1px 2px',
                    cursor: isDisabled 

                  }} // Ensure full width within the cell

                />
                <span className={modules["inputCCPMSet"]}>
                  % (Range: Integer between 1 and 99)
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <Typography className={modules["inputCCPMSet"]}>
          [<strong>Note:</strong> Project Buffer Value is Rounded to the
          nearest Integer. However if the calculated value is less than 1
        </Typography>
        <Typography
          className={modules["inputCCPMSet"]}
          sx={{ marginTop: "0px !important", paddingLeft: "28%" }}
        >
          then it will be set to 1]
        </Typography>

        <Stack
          direction="row"
          sx={{
            paddingLeft: "22%",
            marginTop: "20px !important",
            paddingBottom: "2%",
          }}
          justifyContent="left"
        >
          <button
            className={modules["btnSettingsPop"]}
            onClick={handleApplyBtnClick}
            disabled={applyDisabled}
          >
            Apply
          </button>
          <button
            style={{ marginLeft: "10px" }}
            className={modules["btnSettingsPop"]}
            onClick={handlePopupClose}
          >
            Close
          </button>
        </Stack>
      </Box>
    </>
  );
};

export default CCPMSettings;
