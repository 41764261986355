import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import PVA1 from "../HelpPopups/images/PVA1.jpg";
import PVA2 from "../HelpPopups/images/PVA2.jpg";
import PVA3 from "../HelpPopups/images/PVA3.jpg";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const PlannedVsActualhelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ textDecoration: "underline" }}>Task Management - Planned v/s Actual Report
              </h3>
              -
              <br />
              -
              <br />
              <br />
              <br />
              <li style={{ listStyleType: "none" }}>
                Task mgt. Planned v/s Actual Report- is created for reviewing the ongoing Project task progress in terms of  Planned Date V/s Actual Date.

                i.e    Instance Wise Report / Display session with excel download -  where Planned , projected and Actual dates are appearing for all the task under all the projects for that specific instance.
              </li>
              <br></br>
              <li style={{ listStyleType: "none" }}>l  Path :- CCPM \ Reports \ Planned V/s Actual.
              </li>
              <br></br>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "45rem" }}>
                <img src={PVA1} alt="PVA1" />
              </Stack>
              <br />

              <li style={{ listStyleType: "none" }}>
                v  Report Filters
              </li>

              <br></br>
              <li style={{ listStyleType: "none" }}>
                These filters and its logic are similar to Task Management - session Filters
              </li>
              <br />

              <li>Instance – is mandatory selection</li>
              <li>Attributes</li>
              <li> Project Name</li>
              <li>Task Manager</li>
              <li>  Task Access Type</li>
              <li>Task Status   </li>
              <li>Task color</li>
              <li> Task Availability status</li>
              <li>Department</li>
              <br />
              <br />
              <br />
              <br />
              <br />

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "45rem" }}>
                <img src={PVA2} alt="PVA2" />
              </Stack>
              <br />
              <br />

              <table border="1">
                <tr>
                  <td><strong>Project Name</strong></td>
                  <td>Display field logic (replica of Task Management Session)</td>
                </tr>
                <tr>
                  <td><strong>Department</strong></td>
                  <td>Display field logic (replica of Task Management Session)</td>
                </tr>
                <tr>
                  <td><strong>Task Name</strong></td>
                  <td>Display field logic (replica of Task Management Session)</td>
                </tr>
                <tr>
                  <td><strong>Task Manager</strong></td>
                  <td>Display field logic (replica of Task Management Session)</td>
                </tr>
                <tr>
                  <td><strong>Planned Start</strong></td>
                  <td>Date Display as displayed in Task Management session</td>
                </tr>
                <tr>
                  <td><strong>Planned End</strong></td>
                  <td>Date Display as displayed in Task Management session</td>
                </tr>
                <tr>
                  <td><strong>Actual Start</strong></td>
                  <td>Date Display as displayed in Task Management session</td>
                </tr>
                <tr>
                  <td><strong>Projected / Actual End</strong></td>
                  <td>Date Display as displayed in Task Management session</td>
                </tr>
                <tr>
                  <td><strong>Remaining Duration</strong></td>
                  <td>Display field logic (replica of Task Management Session) (Non-editable)</td>
                </tr>
                <tr>
                  <td><strong>% Buffer Penetration</strong></td>
                  <td>Display field logic (replica of Task Management Session)</td>
                </tr>
                <tr>
                  <td><strong>Last Updated On</strong></td>
                  <td>Display field logic (replica of Task Management Session)</td>
                </tr>
                <tr>
                  <td><strong>Instance</strong></td>
                  <td>Display field logic (replica of Task Management Session)</td>
                </tr>
                <tr>
                  <td><strong>Attribute 1</strong></td>
                  <td>Display field logic (replica of Task Management Session)</td>
                </tr>
              </table>

              <br />



              <li>Excel Download- Excel Download feature is also Available for user review.</li>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "45rem" }}>
                <img src={PVA3} alt="PVA3" />
              </Stack>
              <br />

            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PlannedVsActualhelpPopup;
