import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import RM1 from "../HelpPopups/images/RM1.jpg";
import RM2 from "../HelpPopups/images/RM2.jpg";
import RM3 from "../HelpPopups/images/RM3.jpg";
import RM4 from "../HelpPopups/images/RM4.jpg";
import myproject1 from '../HelpPopups/images/myprojects1.png'
import myproject2 from '../HelpPopups/images/myprojects2.png'
import myproject3 from '../HelpPopups/images/myprojects3.png'
import myproject4 from '../HelpPopups/images/myprojects4.png'
import myproject5 from '../HelpPopups/images/myprojects5.png'
import { Link } from "react-router-dom";

const MyProjectsHelpPopup = () => {
    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                        <img src={logo} alt="Godrej" style={{width:"120px"}} />
                        <span style={{ paddingTop: "2rem" }}>
                            Expand it...
                            <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul>
                            <h3 style={{
                                // marginLeft: "5rem", 
                                textDecoration: "underline", fontWeight: "bold"
                            }}>My Projects</h3>
                            <br />
                            <li>
                                In ”My Projects” page, respective users can find their created projects with the status. Other actions carried out on this page are “Applying CCPM” and “Staggering” to the project
                            </li>
                            <br/>
                            <li>PATH : CCPM \ Project Plan \ My Projects</li>
                            <br/>
                            <li>
                                The filters above the grid headers helps in filtering the projects with the selected status, and filter “Show All” retrieve all the projects irrespective of status. Also we can select the name of the project in the drop down available in the filter which will show only the selected project in the grid.
                            </li>
                            <br/>
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={myproject1} alt="myproject1" />
                            </Stack>
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={myproject2} alt="myproject2" />
                            </Stack>
                            <br />
                            <br />
                            <li>
                                New project can be created using the “Add New Project” button i.e. Click of button will redirect to the “Create New Plan” page.
                            </li>
                            <br />
                            <br />
                            <li>
                                Data in some columns is a static data which shows data that was given at the time of creating the project and data in some columns are dynamic which changes as the status changes also some of the buttons shown in the columns have their respective uses.
                            </li>
                            <li>
                                Modif No – This no is by default 0 for all project. If user do modified check out for some project then the modif no is generated and shows like 1. This will be increased if there are multiple modifications done.
                                If modif no &lt; 0 then a redirect link is provided for the same. This will take user to the staggering page where user can see all modification history.
                                If modif n0 = 0 then there will be no redirect link.
                            </li>
                            <br />
                            <li>
                                Start Date – In this column start date of the modification 0 is shown. This date will not change with the modifications.
                            </li>
                            <li>
                                Customer Due Date – This indicates that the customer due date maintained while booking the project. This date is captured here.
                            </li>
                            <br />
                            <br />
                            <li style={{ listStyleType: 'none', textDecoration: 'underline' }}>❖ Uses of the Dynamic columns are as follows:</li>
                            <br />
                            <br />
                            <li className="no-icon-list" style={{ marginLeft: '2rem' }}>
                                :-This button gets active only when the status of the project changes to the “Scheduled” until then no action can be performed on the click of “Active” button.
                            </li>
                            <br />
                            <br />
                            <li className="no-icon-list" style={{ marginLeft: '2rem' }}>
                                :- The click of the “Open” button will redirect to the “Project Tasks” screen, where user can create the network plan for the project
                            </li>
                            <img src={myproject3} alt="myproject2" />
                            <br />
                            <li style={{ listStyleType: 'none', textDecoration: 'underline' }}>❖ Description of the Project Tasks screen:-</li>
                            <br />
                            <li>
                                In Project task screen user creates the network plan on the created project i.e. User add tasks, Edit tasks, update tasks and duration and task managers for the respective tasks, adjust successor and predecessors in the plan, making task crushable and non-crushable, adding notes to the tasks, etc.
                            </li>
                            <br />
                            <li>
                                Once the network plan has created by the user, then the user has to save the plan and Submit it for the further process(Applying CCPM). The tools displayed in the above image(left corner) are used in  creating and submitting the network plan
                            </li>
                            <br />
                            <br />
                            <li className="no-icon-list">:- This icon used for adding task in to the network plan.</li>
                            <br />
                            <br />
                            <Stack direction="row">
                                <img src={myproject4} alt="myproject4" />
                                :- This icon is used for deleting single or multiple tasks from the project.
                            </Stack>
                            <br />
                            <Stack direction="row">
                                <img src={myproject5} alt="myproject4" />
                                :- This Icon is used to restore the Project plan to created stage again after plan
                                is submitted
                            </Stack>
                            <br />
                            <br />
                            <ul style={{ listStyleType: 'square', marginLeft: '5rem' }}>
                                <li>Restore project plan included in existing Screen for SRF 3 (Tab / press button)  provided on My projects screen</li>
                                <li>RESTORE option will be applied once the Project plan is SUBMITTED and Project manager wishes to edit    the Project Plan at <b>PLANNED / SUBMITTED/SCHEDULED</b>Status only.</li>
                                <li>Any change in Project plan should reverse the Project status from <b> planned / SUBMITTED/Scheduled </b> status to <b> CREATED </b> Status, at overall all project level and same should be updated at all dependent sessions and tables.</li>
                                <li>Once plan is resubmitted after restoring, Project status should change to <b>Submitted</b> as per existing logic.</li>
                                <li>Restore‘– option will not be applicable for Projects which are ACTIVE. Edit option to be greyed out in that  case as Non editable.</li>
                            </ul>
                            <br /><br /><br />
                            <li style={{ listStyleType: 'none' }}>:- This icon is used for saving (temporary save) the Network plan, once the network plan is created successful.</li>
                            <br />
                            <br />
                            <li style={{ listStyleType: 'none' }}>:- This icon is used for the checking the properties of the project.</li>
                            <br />
                            <br />
                            <li style={{ listStyleType: 'none' }}>:- This icon is used for creating the risk identification and mitigating plan.</li>
                            <br />
                            <br />
                            <li style={{ listStyleType: 'none' }}>:- This icon is used for submitting the network plan which can be used only after saving the plan</li>
                            <br />
                            <br />
                            <li style={{ listStyleType: 'none' }}>- This icon is used for Exit .i.e will take back to “My Projects”</li>
                            <br />
                            <br />
                            <li style={{ listStyleType: 'none' }}>Task Manager, Duration, Task Name are the filters provided to the user for the extraction of the needed data</li>
                            <br />
                            <li style={{ listStyleType: 'none', textDecoration: 'underline' }}>❖ The following are the buttons which are used only after applying the CCPM to the Network Plan:-</li>
                            <br />
                            <Stack sx={{ marginLeft: '5rem' }}>
                                <li style={{ listStyleType: 'none' }}>
                                    :- ”OriginalCC ”+ ”Submit” button can be used for checking the critical chain present in the network plan.
                                </li>
                                <br />
                                <li style={{ listStyleType: 'none' }}>
                                    :-”Project Buffer ”+ ”Submit” button is used for the displaying only the project buffer tasks in the network plan.
                                </li>
                                <br />
                                <li style={{ listStyleType: 'none' }}>
                                    :- ”Feeding Buffer ”+ ”Submit” button used for displaying only the Feeding Buffer tasks in the network plan.
                                </li>
                                <br />
                                <li style={{ listStyleType: 'none' }}>
                                    :- ”CCTask ”+ ”Submit” button used for displaying only the critical chain tasks in the network plan.
                                </li>
                                <br />
                                <li style={{ listStyleType: 'none' }}>
                                    :- Can be used to clear / Reset the filter applied to the network plan.
                                </li>
                                <br />
                                <li style={{ listStyleType: 'none' }}>
                                    :- As the name specify, This link will display the gantt chart of the network plan.
                                </li>
                            </Stack>
                            <ul style={{ marginTop: '30rem', listStyleType: 'none' }}>
                                “My Projects screen contd....”
                                <li></li>
                                <br />
                                <li>
                                    :- The “Delete” button used in this grid helps the user to delete the project from the grid irrespective of the status of the project.
                                </li>
                                <br /><br />
                                <li>:-This button will redirect to the “CCPM Settings window”, where the user will apply the CCPM settings to the project by entering the required parameters and click on the “Apply” button.</li>
                            </ul>
                            <br />
                            <li>After the CCPM setting is applied to the project, the user can see the Project buffer task, Feeding buffer tasks,CC tasks, critical chain in the network plan as mentioned above.</li>
                            <Stack sx={{ marginTop: '30rem', marginBottom: '5rem' }}>
                                <li style={{ listStyleType: 'none', textDecoration: 'underline' }}>❖ Validations on this screen:-</li>
                                <li style={{ listStyleType: 'none' }}>The text boxes in the above screen should not be left blank prior to clicking on “Apply” button.
                                    <br />Clicking on Apply button with invalid input in text boxes, will make error window pop up.</li>
                            </Stack>
                            <li style={{ listStyleType: 'none' }}>:- Using ”Risk” user will input the data into the “Risk Identification & Mitigation Plan” screen i.e the Risks regarding the selected project and also the steps to mitigate the same.</li>
                            <br />
                            <li>For Adding new records, click on “ADD” button and enter the required details in the available text                  boxes and click on “Insert” button.</li>
                            <li>For deleting any record, we must click in the check box of respective record and click on the Delete Button. And for deleting multiple records at once select the check boxes and click on the “Delete” button.</li>
                            <Stack sx={{marginTop:'20rem',marginBottom:'20rem'}}>
                                <li>For doing any updations to the data, click on Edit button and do the required changes and click on update button to save the changes or click on Cancel button to cancel the changes done to the data.</li>
                            </Stack>
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MyProjectsHelpPopup;
