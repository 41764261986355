import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import DBM1 from "../HelpPopups/images/DBM1.png";
import DBM2 from "../HelpPopups/images/DBM2.png";
import DBM3 from "../HelpPopups/images/DBM3.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const DBMhelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{ fontSize: "14px" }}>
              <h1 style={{ textAlign: "left" }}>
                DBM (Dynamic Buffer Management)
              </h1>
              <br />
              <br />

              <li className="no-bullets">
                Session Path {">>"} MTA {">>"} MTA Parameter {">>"} DBM
              </li>
              <br />
              <br />
              <li className="no-bullets">
                In DBM, system suggests change in buffer for an item after
                monitoring the stocks of the item for defined replenishment
                cycle. Suggestions will be displayed and user can take actions
                accordingly.
              </li>
              <br />
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={DBM1} alt="DBM1" />
              </Stack>
              <br />
              <br />
              <li>
                MTA Instance – This field indicates MTA Instance. Select MTA instance
              </li>
              <li>
                Stock Point Type – This field indicates Stock Point Type. Select Stock point from Dropdown available for the field.
              </li>
              <li>
                Stock Point – This field indicate stock point. Select Stock point name from dropdown available for the field.
              </li>
              <li>
                Company code – This field indicates related company code. Add company code in this field. This should be maintained in the input master field.
              </li>
              <li>
                Item Code – This field indicates the item code for which DBM to be set.
              </li>
              <li>
                SSG – This field indicates the sales statistical group. Select SSG from dropdown available for the field.
              </li>
              <li>
                Item Category - This field indicates Item Category. Select Item Category from Dropdown available for the field.
              </li>
              <br />
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={DBM2} alt="DBM2" />
              </Stack>
              <br />

              <br />

              <li>
                Proposed Action – Filters can be applied on proposed actions.
              </li>
              <ul>
                <li style={{ marginLeft: "2rem" }}>
                  Increase
                </li>
                <li style={{ marginLeft: "2rem" }}>
                  Decrease
                </li>
                <li style={{ marginLeft: "2rem" }}>
                  No Action
                </li>
                <li style={{ marginLeft: "2rem" }}>
                  DBM Suspension
                </li>
              </ul>

              <br />
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={DBM3} alt="DBM3" />
              </Stack>
              <br />

              <li
                className="no-bullets"
              >
                User can update buffer by checking in the box. This means that user has accepted the suggestion given by the system. Also user can ignore the suggestions.
              </li>
              <br />
              <li
                className="no-bullets"
              >
                View Details – Here user can check the inventory transaction in graphical mode.
              </li>
              <br />
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DBMhelpPopup;
