import React, { useState, useEffect } from "react";
import bread from "../../../../Assets/bread.png";
import Home from "../../../../home/Home";
import { Grid, Stack, Typography } from "@mui/material";
import modules from "../../ccpm.module.css";
import SampleSidebar from "../../../../Landing/SampleSidebar";
import "react-calendar/dist/Calendar.css";
import "../CalendarStyles.css";
import loading from "../../../../Assets/Loading_image.gif";
import save from "../../../../Assets/Save.jpg";
import back from "../../../../Assets/back.png";
import { useNavigate, useLocation } from "react-router-dom";
import {
  PBP_ERPData,
  PBP_GetTableData,
  PBP_UpdateTableData,
  PBP_ERPUpdData,
  PBP_ERPSearchData,
  PBP_GetInvoiceData,
} from "../../../../Services/CCPM";

const PlannedBillingProjection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projId = parseInt(searchParams.get("projId"));
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [projName, setProjName] = useState("");
  const [erpFieldA, setErpFieldA] = useState("");
  const [erpFieldB, setErpFieldB] = useState("");
  const [totalPlannedBilling, setTotalPlannedBilling] = useState(0);
  const [totalActualBilling, setTotalActualBilling] = useState(0);
  const [totalProjectedBilling, setTotalProjectedBilling] = useState(0);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    fetchData();
    fetchERPData();
    fetchInvoiceData();
  }, []);

  useEffect(() => {
    if (changed) {
      const total = tableData.reduce((acc, item) => {
        if (item.taskstatusid === 3) {
          const value = parseFloat(item.ProjectedBillingValue) || 0;
          return acc + value;
        }
        return acc;
      }, 0);
      setTotalProjectedBilling(total.toFixed(2));
    }
    setChanged(false);
  }, [changed, tableData]);

  const fetchERPData = async () => {
    setIsLoading(true);
    try {
      let body = {
        projectId: projId,
      };
      const response = await PBP_ERPData(body);
      if (response.status === 200) {
        const check = response?.data?.success?.data[0];
        setProjName(check?.projectname);
        setErpFieldA(check?.erpprojectcompany);
        setErpFieldB(check?.erpprojectno);
      } else {
        console.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching ERP data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let body = {
        projectId: projId,
      };
      const response = await PBP_GetTableData(body);
      const rawData = response?.data?.success?.data[0];
      if (response.status === 200) {
        let totalPlannedBilling = 0;
        let totalActualBilling = 0;
        let totalProjectedBilling = 0;

        const modifiedData = rawData.map((item) => {
          const plannedBillingValue = parseFloat(item.plannedbillingvalue) || 0;
          const actualBillingValue = parseFloat(item.actualbillingvalue) || 0;

          totalPlannedBilling += plannedBillingValue;
          totalActualBilling += actualBillingValue;

          if (item.taskstatusid === 3) {
            totalProjectedBilling += plannedBillingValue;
          }

          return {
            ...item,
            plannedBillingValue:
              plannedBillingValue === 0 ? "" : plannedBillingValue.toFixed(2),
            actualBillingValue:
              actualBillingValue === 0 ? "" : actualBillingValue.toFixed(2),
            billingProjectionRemarks: item.billingprojectionremarks || "",
            ProjectedBillingValue:
              item.taskstatusid === 3 ? plannedBillingValue.toFixed(2) : "",
          };
        });

        setTableData(modifiedData);
        setTotalPlannedBilling(parseFloat(totalPlannedBilling).toFixed(2));
        setTotalActualBilling(parseFloat(totalActualBilling).toFixed(2));
        setTotalProjectedBilling(parseFloat(totalProjectedBilling).toFixed(2));
      } else {
        console.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInvoiceData = async () => {
    setIsLoading(true);
    try {
      let body = {
        projectId: projId,
      };
      const response = await PBP_GetInvoiceData(body);
      if (response.status === 200) {
        setInvoiceData(response?.data?.success?.data[0]);
      } else {
        console.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching invoice data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleInputChange = (taskId, field, value) => {
    setTableData((prevData) =>
      prevData.map((item) =>
        item.taskid === taskId ? { ...item, [field]: value } : item
      )
    );
  };

  // Calculate totals
  const calculateTotal = (field) => {
    const total = tableData.reduce((acc, item) => {
      const value = parseFloat(item[field]) || 0;
      return acc + value;
    }, 0);
    return parseFloat(total).toFixed(2);
  };

  const handlePlannedBillingValBlur = (statusId, plannedBill, taskId) => {
    if (plannedBill) {
      setTableData((prevData) =>
        prevData.map((item) => {
          if (item.taskid === taskId) {
            const formattedValue = parseFloat(item.plannedBillingValue).toFixed(
              2
            );
            const updatedItem = {
              ...item,
              plannedBillingValue: formattedValue,
            };

            if (statusId === 3) {
              updatedItem.ProjectedBillingValue =
                parseFloat(formattedValue).toFixed(2);
            }
            return updatedItem;
          }
          return item;
        })
      );
      setChanged(true);
      setTotalPlannedBilling(calculateTotal("plannedBillingValue"));
    }
  };

  const handleActualBillingValBlur = (actualBill, taskId) => {
    if (actualBill) {
      setTableData((prevData) =>
        prevData.map((item) =>
          item.taskid === taskId
            ? {
                ...item,
                actualBillingValue: parseFloat(item.actualBillingValue).toFixed(
                  2
                ),
              }
            : item
        )
      );
      setTotalActualBilling(calculateTotal("actualBillingValue"));
    }
  };

  const handleSave = async () => {
    try {
      if (erpFieldA === "" && erpFieldB === "") {
        const confirmSave = window.confirm(
          "Are you sure you want to save changes?"
        );
        if (confirmSave) {
          setIsLoading(true);

          const multiTaskDetails = tableData.map((item) => ({
            projectId: projId,
            plannedBillingValue: parseFloat(item.plannedBillingValue) || 0,
            actualBillingValue: parseFloat(item.actualBillingValue) || 0,
            billingProjectionRemarks:
              item.billingProjectionRemarks === null
                ? ""
                : item.billingProjectionRemarks,
            taskId: item.taskid,
          }));

          const updateResponse = await PBP_UpdateTableData(multiTaskDetails);
          if (updateResponse.status === 200) {
            alert("Records Saved Successfully.");
            fetchData();
          } else {
            console.error("Error saving records:", updateResponse);
          }
        }
      } else {
        if (!erpFieldB) {
          alert("Please Enter ERP Project Number.");
          return;
        }

        if (!erpFieldA) {
          alert("Please Enter ERP Company Code.");
          return;
        }

        const confirmSave = window.confirm(
          "Are you sure you want to save changes?"
        );
        if (confirmSave) {
          setIsLoading(true);

          const searchBody = {
            erpProjectNo: erpFieldB,
            projectId: projId,
          };
          const searchResponse = await PBP_ERPSearchData(searchBody);

          if (searchResponse.status === 200) {
            const updateResponse = await PBP_ERPUpdData();
            if (updateResponse.status === 200) {
              const multiTaskDetails = tableData.map((item) => ({
                projectId: projId,
                plannedBillingValue: parseFloat(item.plannedBillingValue) || 0,
                actualBillingValue: parseFloat(item.actualBillingValue) || 0,
                billingProjectionRemarks:
                  item.billingProjectionRemarks === null
                    ? ""
                    : item.billingProjectionRemarks,
                taskId: item.taskid,
              }));

              const tableDataResponse = await PBP_UpdateTableData(
                multiTaskDetails
              );
              if (tableDataResponse.status === 200) {
                alert("Records Saved Successfully.");
                fetchData();
                fetchERPData();
              } else {
                console.error("Error saving table data:", tableDataResponse);
              }
            } else {
              console.error("Error updating ERP data:", updateResponse);
            }
          } else {
            alert("Invalid ERP Project Number.");
            setErpFieldB("");
          }
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <div className={modules["loading_gif"]}>
          <span style={{ textAlign: "center" }}>Please Wait....</span>
          <img src={loading} alt="Loading..." />
        </div>
      )}
      <Grid container>
        <Grid item lg={0.7} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"PlannedBillingProjection"}/>
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>Planned Billing Projection</a>
              </div>
            </div>
          </Grid>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{ width: "97%" }}
          >
            <div className={modules["img_style"]}>
              <div className={modules["img_cont"]}>
                <img
                  className={modules["img_display"]}
                  src={save}
                  alt="save"
                  onClick={handleSave}
                ></img>
                <Typography className={modules["ccpm_label-styles2"]}>
                  Save
                </Typography>
              </div>
              <div className={modules["img_cont"]}>
                <img
                  className={modules["img_display"]}
                  src={back}
                  alt="back"
                  onClick={handleBackButton}
                ></img>
                <Typography className={modules["ccpm_label-styles2"]}>
                  Back
                </Typography>
              </div>
            </div>
          </Stack>
          <Stack
            direction="row"
            className={modules["Header_style"]}
            spacing={2}
          >
            <div style={{ display: "flex" }}>
              <Typography
                sx={{ fontWeight: "bold" }}
                className={modules["ccpm_label-styles"]}
              >
                {" "}
                Project Name <br /> :{" "}
              </Typography>
              <Typography
                className={modules["ccpm_label-styles"]}
                sx={{ marginLeft: "5px" }}
              >
                {projName}
              </Typography>
            </div>
            <div style={{ display: "flex", marginLeft: "10%" }}>
              <Typography
                sx={{ fontWeight: "bold" }}
                className={modules["ccpm_label-styles"]}
              >
                ERP Company Code <br /> :
              </Typography>

              <input
                style={{ marginLeft: "5px" }}
                className={modules["input-field1"]}
                value={erpFieldA}
                onChange={(e) => setErpFieldA(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", marginLeft: "1%" }}>
              <Typography
                sx={{ fontWeight: "bold" }}
                className={modules["ccpm_label-styles"]}
              >
                ERP Project No <br /> :
              </Typography>
              <input
                style={{ marginLeft: "5px" }}
                className={modules["input-field2"]}
                value={erpFieldB}
                onChange={(e) => setErpFieldB(e.target.value)}
              />
            </div>
            <div style={{ width: "100px", marginLeft: "1%" }}>
              <Typography
                sx={{ fontWeight: "bold" }}
                className={modules["ccpm_label-styles"]}
              >
                Invoice Actual Billing Total (Lacs) :
                <span className={modules["ccpm_label-styles3"]}> 0.00</span>
              </Typography>
            </div>
          </Stack>

          <div
            className={modules["pbp_table-container"]}
            style={{ marginLeft: "7rem" }}
          >
            <table className={modules["custom-table-mp"]}>
              <thead>
                <tr>
                  <th style={{minWidth:'80px'}}>Task Id</th>
                  <th style={{minWidth:'110px'}}>Task Name</th>
                  <th style={{minWidth:'110px'}}>Task Manager</th>
                  <th style={{minWidth:'120px'}}>Scheduled<br/>Completion Date</th>
                  <th style={{minWidth:'120px'}}>Annual<br></br>Completion Date</th>
                  <th style={{minWidth:'110px'}}>Planned&nbsp;Billing Value&nbsp;in Lacs&nbsp;</th>
                  <th style={{minWidth:'110px'}}>Projected&nbsp;billing value&nbsp;in Lacs&nbsp;</th>
                  <th style={{minWidth:'110px'}}>Actual&nbsp;billing value&nbsp;in Lacs&nbsp;</th>
                  <th style={{minWidth:'110px'}}>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item) => (
                  <tr key={item.taskid} className={modules["table-row"]}>
                    <td>{item.taskid}</td>
                    <td>{item.taskname}</td>
                    <td>{item.taskmanager}</td>
                    <td>{item.scheduledcompletiondate} </td>
                    <td>{item.actualcompletiondate}</td>
                    <td>
                      <input
                        className={modules["input-fieldPB"]}
                        style={{ width: "6rem" }}
                        value={item.plannedBillingValue}
                        onBlur={() =>
                          handlePlannedBillingValBlur(
                            item.taskstatusid,
                            item.plannedBillingValue,
                            item.taskid
                          )
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d{0,10}$/.test(value)) {
                            handleInputChange(
                              item.taskid,
                              "plannedBillingValue",
                              value
                            );
                          }
                        }}
                      />
                    </td>
                    <td>{item.ProjectedBillingValue}</td>
                    <td>
                      <input
                        className={modules["input-fieldPB"]}
                        style={{ width: "6rem" }}
                        value={item.actualBillingValue}
                        onBlur={() =>
                          handleActualBillingValBlur(
                            item.actualBillingValue,
                            item.taskid
                          )
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d{0,10}$/.test(value)) {
                            handleInputChange(
                              item.taskid,
                              "actualBillingValue",
                              value
                            );
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        className={modules["input-fieldPB"]}
                        style={{ width: "6rem" }}
                        value={item.billingProjectionRemarks}
                        onChange={(e) => {
                          const value = e.target.value;
                          handleInputChange(
                            item.taskid,
                            "billingProjectionRemarks",
                            value
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))}
                <tr className={modules["table-row"]}>
                  <td style={{ fontWeight: "bold", fontSize: "13px" }}>
                    Total
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: "bold", fontSize: "13px" }}>
                    {totalPlannedBilling !== 0 ? (
                      <>{totalPlannedBilling}</>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td style={{ fontWeight: "bold", fontSize: "13px" }}>
                  {totalProjectedBilling !== 0 ? (
                      <>{totalProjectedBilling}</>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td style={{ fontWeight: "bold", fontSize: "13px" }}>
                    {totalActualBilling !== 0 ? (
                      <>{totalActualBilling}</>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td style={{ fontWeight: "bold", fontSize: "13px" }}></td>
                </tr>
              </tbody>
            </table>
          </div>
          <Stack className={modules["pbp_bottom_stack"]}>
            {invoiceData.length > 0 ? (
              <></>
            ) : (
              <>
                <p style={{ padding: "6px" }}>
                  <strong>Invoice Details</strong>
                </p>
                <p  className={modules["pbp_no_record"]}>No Record Found.</p>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default PlannedBillingProjection;
