import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material";
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import RM1 from "../HelpPopups/images/RPA1.jpg";
import { Link } from "react-router-dom";

const RolePermissionhelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight:"bold"
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3
                style={{
                  marginLeft: "5rem",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Restrict Role Permissions
              </h3>
              <br />

              <li>
                This functionality is used to assign roles to Prostream
                sessions.
              </li>
              <li>
                For user roles (ones which are maintained in the boxes) sessions
                will be checked & enabled in role permission module.
              </li>
              <li>
                For user roles (ones which are not maintained in the boxes)
                sessions will be unchecked & disabled in role permission module.
              </li>
              <li>
                If nothing is maintained against a module then by default all
                roles will have access to the module. (Sessions will be checked
                in role permission and also will be editable)
              </li>
              <br />
              <li>Path: Admin \ Restrict Role Permissions</li>

              <br />
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={RM1} alt="RM1" />
              </Stack>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RolePermissionhelpPopup;
