import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import FCM1 from "../HelpPopups/images/FCM1.PNG";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";

const data = [
  { sr: 1, module: "ADMIN", menu: "Role Master", menuItem: "Role Master" },
  {
    sr: 2,
    module: "ADMIN",
    menu: "Role Permission",
    menuItem: "Role Permission",
  },
  { sr: 3, module: "ADMIN", menu: "User Master", menuItem: "User Master" },
  {
    sr: 4,
    module: "ADMIN",
    menu: "Change Password",
    menuItem: "Change Password",
  },
  {
    sr: 5,
    module: "CCPM",
    menu: "Hierarchy Master",
    menuItem: "Hierarchy Master",
  },
  {
    sr: 6,
    module: "CCPM",
    menu: "Customer Master",
    menuItem: "Customer Master",
  },
  { sr: 7, module: "CCPM", menu: "Delay History", menuItem: "Delay History" },
  { sr: 8, module: "CCPM", menu: "Risk Type", menuItem: "Risk Type" },
  { sr: 9, module: "CCPM", menu: "Unit Master", menuItem: "Unit Master" },
  {
    sr: 10,
    module: "CCPM",
    menu: "Procurement Category",
    menuItem: "Procurement Category",
  },
  {
    sr: 11,
    module: "CCPM",
    menu: "Buffer Scheduler",
    menuItem: "Buffer Scheduler",
  },
  {
    sr: 12,
    module: "CCPM",
    menu: "Product Configuration Settings",
    menuItem: "Product Configuration Settings",
  },
  {
    sr: 13,
    module: "CCPM",
    menu: "Fever Chart Master",
    menuItem: "Fever Chart Master",
  },
  {
    sr: 14,
    module: "CCPM",
    menu: "WIP and Project Limit Master",
    menuItem: "WIP and Project Limit Master",
  },
  {
    sr: 15,
    module: "CCPM",
    menu: "Issue Category",
    menuItem: "Issue Category",
  },
  { sr: 16, module: "CCPM", menu: "My Projects", menuItem: "My Projects" },
  {
    sr: 17,
    module: "CCPM",
    menu: "Create New Plan",
    menuItem: "Create New Plan",
  },
  { sr: 18, module: "CCPM", menu: "Calendar", menuItem: "Calendar" },
  {
    sr: 19,
    module: "CCPM",
    menu: "Active Projects",
    menuItem: "Active Projects",
  },
  {
    sr: 20,
    module: "CCPM",
    menu: "Task Management",
    menuItem: "Task Management",
  },
  {
    sr: 21,
    module: "CCPM",
    menu: "Risk Identification",
    menuItem: "Risk Identification",
  },
  {
    sr: 22,
    module: "CCPM",
    menu: "Risk Mitigation Owner",
    menuItem: "Risk Mitigation Owner",
  },
  { sr: 23, module: "CCPM", menu: "Risk Review", menuItem: "Risk Review" },
  {
    sr: 24,
    module: "CCPM",
    menu: "Risk Monitor Report",
    menuItem: "Risk Monitor Report",
  },
  { sr: 25, module: "CCPM", menu: "Issue List", menuItem: "Issue List" },
  { sr: 26, module: "CCPM", menu: "Issue Report", menuItem: "Issue Report" },
];

const HelpIndex = () => {
  return (
    <>
      <table className="help-table">
        <thead>
          <tr>
            <th>Sr</th>
            <th>Module</th>
            <th>Menu</th>
            <th>Menu Item</th>
          </tr>
        </thead>
        <tbody>
          <tr className="even-row">
            <td>1</td>
            <td>ADMIN</td>
            <td>Role Master</td>
            <td
              className="menu-item"
              onClick={() =>
                window.open(
                  `/ccpm/RoleMasterhelpPopup`,
                  "mypopuptitle",
                  "width=800,height=600"
                )
              }
            >
              Role Master
            </td>
          </tr>
          <tr className="odd-row">
            <td>2</td>
            <td>ADMIN</td>
            <td>Role Permission</td>
            <td
              className="menu-item"
              onClick={() =>
                window.open(
                  `/ccpm/RolePermissionhelpPopup`,
                  "mypopuptitle",
                  "width=800,height=600"
                )
              }
            >
              Role Permission
            </td>
          </tr>
          <tr className="even-row">
            <td>3</td>
            <td>ADMIN</td>
            <td>User Master</td>
            <td
              className="menu-item"
              onClick={() =>
                window.open(
                  `/ccpm/UserMasterhelpPopup`,
                  "mypopuptitle",
                  "width=800,height=600"
                )
              }
            >
              User Master
            </td>
          </tr>
          <tr className="odd-row">
            <td>4</td>
            <td>ADMIN</td>
            <td>Change Password</td>
            <td className="menu-item">Change Password</td>
          </tr>
          <tr className="even-row">
            <td>5</td>
            <td>CCPM</td>
            <td>Hierarchy Master</td>
            <td className="menu-item">Hierarchy Master</td>
          </tr>
          <tr className="odd-row">
            <td>6</td>
            <td>CCPM</td>
            <td>Customer Master</td>
            <td className="menu-item">Customer Master</td>
          </tr>
          <tr className="even-row">
            <td>7</td>
            <td>CCPM</td>
            <td>Delay Master</td>
            <td className="menu-item">Delay Master</td>
          </tr>
          <tr className="odd-row">
            <td>8</td>
            <td>CCPM</td>
            <td>Risk Type</td>
            <td className="menu-item">Risk Type</td>
          </tr>
          <tr className="even-row">
            <td>9</td>
            <td>CCPM</td>
            <td>Unit Master</td>
            <td className="menu-item">Unit Master</td>
          </tr>
          <tr className="odd-row">
            <td>10</td>
            <td>CCPM</td>
            <td>Procurement Category</td>
            <td className="menu-item">Procurement Category</td>
          </tr>
          <tr className="even-row">
            <td>11</td>
            <td>CCPM</td>
            <td>Buffer Scheduler</td>
            <td className="menu-item">Buffer Scheduler</td>
          </tr>
          <tr className="odd-row">
            <td>12</td>
            <td>CCPM</td>
            <td>Product Configuration Settings</td>
            <td className="menu-item">Product Configuration Settings</td>
          </tr>
          <tr className="even-row">
            <td>13</td>
            <td>CCPM</td>
            <td>Fever Chart Master</td>
            <td className="menu-item">Fever Chart Master</td>
          </tr>
          <tr className="odd-row">
            <td>14</td>
            <td>CCPM</td>
            <td>WIP and Project Limit Master</td>
            <td className="menu-item">WIP and Project Limit Master</td>
          </tr>
          <tr className="even-row">
            <td>15</td>
            <td>CCPM</td>
            <td>Issue Category</td>
            <td className="menu-item">Issue Category</td>
          </tr>
          <tr className="odd-row">
            <td>16</td>
            <td>CCPM</td>
            <td>My Projects</td>
            <td className="menu-item">My Projects</td>
          </tr>
          <tr className="even-row">
            <td>17</td>
            <td>CCPM</td>
            <td>Create New Plan</td>
            <td className="menu-item">Create New Plan</td>
          </tr>
          <tr className="odd-row">
            <td>18</td>
            <td>CCPM</td>
            <td>Calendar</td>
            <td className="menu-item">Calendar</td>
          </tr>
          <tr className="even-row">
            <td>19</td>
            <td>CCPM</td>
            <td>Active Projects</td>
            <td className="menu-item">Active Projects</td>
          </tr>
          <tr className="odd-row">
            <td>20</td>
            <td>CCPM</td>
            <td>Task Management</td>
            <td className="menu-item">Task Management</td>
          </tr>
          <tr className="even-row">
            <td>21</td>
            <td>CCPM</td>
            <td>Risk Identification</td>
            <td className="menu-item"
            onClick={() =>
              window.open(
                `/ccpm/RiskAndMitigationPlanhelpPopup`,
                "mypopuptitle",
                "width=800,height=600"
              )
            }>Risk Identification</td>
          </tr>
          <tr className="odd-row">
            <td>22</td>
            <td>CCPM</td>
            <td>Risk Mitigation Owner</td>
            <td className="menu-item"
            onClick={() =>
              window.open(
                `/ccpm/RiskActionOwnerhelpPopup`,
                
                "mypopuptitle",
                "width=800,height=600"
              )
            }>Risk Mitigation Owner</td>
          </tr>
          <tr className="even-row">
            <td>23</td>
            <td>CCPM</td>
            <td>Risk Review</td>
            <td className="menu-item"
            onClick={() =>
              window.open(
                `/ccpm/RiskReviewhelpPopup`,
                "mypopuptitle",
                "width=800,height=600"
              )
            }>Risk Review</td>
          </tr>
          <tr className="odd-row">
            <td>24</td>
            <td>CCPM</td>
            <td>Risk Monitor Report</td>
            <td className="menu-item"
            onClick={() =>
              window.open(
                `/ccpm/RiskMonitorreporthelpPopup`,
                "mypopuptitle",
                "width=800,height=600"
              )
            }>Risk Monitor Report</td>
          </tr>
          <tr className="even-row">
            <td>25</td>
            <td>CCPM</td>
            <td>Issue List</td>
            <td className="menu-item">Issue List</td>
          </tr>
          <tr className="odd-row">
            <td>26</td>
            <td>CCPM</td>
            <td>Issue Report</td>
            <td className="menu-item">Issue Report</td>
          </tr>
          <tr className="even-row">
            <td>27</td>
            <td>CCPM</td>
            <td>Delay Reason Pareto</td>
            <td className="menu-item">Delay Reason Pareto</td>
          </tr>
          <tr className="odd-row">
            <td>28</td>
            <td>CCPM</td>
            <td>Project Wise Critical Chain Completion</td>
            <td className="menu-item">
              Project Wise Critical Chain Completion
            </td>
          </tr>
          <tr className="even-row">
            <td>29</td>
            <td>CCPM</td>
            <td>Portfolio Chart Report</td>
            <td className="menu-item">Portfolio Chart Report</td>
          </tr>
          <tr className="odd-row">
            <td>30</td>
            <td>CCPM</td>
            <td>Project Lead Time Report</td>
            <td className="menu-item">Project Lead Time Report</td>
          </tr>
          <tr className="even-row">
            <td>31</td>
            <td>CCPM</td>
            <td>Task Wise Buffer Consumption Report</td>
            <td className="menu-item">Task Wise Buffer Consumption Report</td>
          </tr>
          <tr className="odd-row">
            <td>32</td>
            <td>CCPM</td>
            <td>Buffer Recovery Report</td>
            <td className="menu-item">Buffer Recovery Report</td>
          </tr>
          <tr className="even-row">
            <td>33</td>
            <td>CCPM</td>
            <td>Subtask Management</td>
            <td className="menu-item">Subtask Management</td>
          </tr>
          <tr className="odd-row">
            <td>34</td>
            <td>CCPM</td>
            <td>Network Plan</td>
            <td className="menu-item">Network Plan</td>
          </tr>
          <tr className="even-row">
            <td>35</td>
            <td>CCPM</td>
            <td>View Resource Allocation</td>
            <td
              className="menu-item"
              onClick={() =>
                window.open(
                  `/ccpm/ViewResourceAllocationhelpPopup`,
                  "mypopuptitle",
                  "width=800,height=600"
                )
              }
            >
              View Resource Allocation
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default HelpIndex;
