import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import chainPopup from "../HelpPopups/images/chainPopup.jpg";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";
import Projectwisecriticalchain from "../CCPM/Reports/Projectwisecriticalchain";

 
const ProjectwisecriticalchainhelpPopup  = () => {
    return (
        <>
        <div>
            <Stack
              direction="row"
              spacing={4}
              sx={{ paddingTop: "1rem" }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack sx={{ paddingLeft: "1rem" }}>
                <img src={logo} alt="Godrej" style={{width:"120px"}} />
                <span style={{ paddingTop: "2rem" }}>
                  Expand it...
                  <Link
                    style={{
                      color: "#0000EE",
                      marginRight: "0.6rem",
                      textDecoration: "underline",
                      fontWeight:'bold',
                      fontFamily:'TimesNewRoman'
                    }}
                  >
                    User Manual Index
                  </Link>
                </span>
              </Stack>
    
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={prostream} alt="Prostream" />
              </Stack>
            </Stack>
            <Stack></Stack>
          </div>
    
          <Grid>
            <Grid container className="parent-container">
              <Grid item xs={12} className="child-container">
                
                  <h3 style={{ textDecoration: "underline", fontFamily:'Cablibri,serif',fontSize:'16px'}}>PROJECT WISE CRITICAL CHAIN COMPLETION</h3>
                  <br></br>
                  In this report User can the details of every project on project selection or for all projects altogether.
                  <br />
                  
                  <br></br>
                  <Stack sx={{ paddingRight: "1rem" }}>
                    <img src={chainPopup} alt="chainPopup" />
                  </Stack>
                  
                  
                  <p>the detail includes Original buffer days, remaining buffer days, original chain days ,% critical chain completed and also the project colour.</p>
                  <br></br>
                 
                 </Grid>
            </Grid>
          </Grid>
        </>
      );
};

export default ProjectwisecriticalchainhelpPopup;
