import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import poc1 from "../HelpPopups/images/poc1.jpg";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const PortfoliocharthelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{ listStyleType: "none", fontSize: "14px"}} >
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>PORTFOLIO CHART REPORT</h3>
              <br />
              <li>
                In this report User can see the its portfolio of all the projects where it indicates that in which zone currently the project is residing ie.Red ,Yellow or Green.
              </li>
              <br></br>
              <li>PATH :CCPM\REPORTS\PORTFOLIO CHART
              </li>
              <br></br>

              <li>
              User is having a selection of Division,BU/SBU and then Project manger then report will show the desired output depending on the selection.
              </li>
              <br></br>

              <Stack sx={{ paddingRight: "1rem", width:"45rem" }}>
                <img src={poc1} alt="poc1" />
              </Stack>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PortfoliocharthelpPopup;
