import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
import modules from "./../ccpm.module.css";
import Box from "@mui/material/Box";
import { Tab, Tabs } from "@mui/material";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetPCSearchResult,
  GetPCProjectCount,
  GetPCallprojectdetails,
} from "../../../Services/CCPM.js";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

const DummyData = [
  {
    id: 1,
    col2: "Aerospace",
    col3: "Aerospace",
  },
  {
    id: 2,
    col2: "Innovation and Design Center",
    col3: "IDC",
  },
  {
    id: 3,
    col2: "GB Corporate Projects",
    col3: "GB_CORP",
  },
  {
    id: 4,
    col2: "PSS Gogrej Security Solutions",
    col3: "SSD_PSS",
  },
  {
    id: 5,
    col2: "G&B Finance",
    col3: "G&B Finance",
  },
];

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const PortfolioChartReport = () => {
  const [searchValue, setSearchValue] = useState("");
  // State for selected values
  const [selectedInstance, setSelectedInstance] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [greenTableData, setGreenTableData] = useState([]);
  const [yellowTableData, setYellowTableData] = useState([]);
  const [redTableData, setRedTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [selectedAccessType, setSelectedAccessType] = useState("SELECT");
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("1");
  const [selectedmaximumYAxis, setselectedmaximumYAxis] = useState("100");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");

  const [dropdownData, setDropdownData] = useState([
    "Select",
    "Instance 2",
    "Instance 3",
  ]);
  const [filteredData, setFilteredData] = useState(DummyData);
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [dataChart, setDataChart] = useState({});
  const [dataOptions, setDataOptions] = useState({});

  const handleSelectChange = (attributenumber, id) => {
    // console.log(attributenumber, id, "attributenumber value");
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [radioValue, setRadioValue] = useState("yes");

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const [instances, setInstances] = useState([]);
  const [zones, setZones] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [accessTypes, setAccessTypes] = useState([
    { value: "null", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ]);
  const [projectStatuses, setProjectStatuses] = useState([
    { value: "0", label: "All" },
    { value: "1", label: "Active" },
    { value: "7", label: "Completed" },
    { value: "5", label: "Hold" },
  ]);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "project", headerName: "Project", width: 150 },
    { field: "projectName", headerName: "Project Name", width: 150 },
    { field: "completed", headerName: "% Completed", width: 150 },
    { field: "last7Days", headerName: "Last 7 Days", width: 150 },
    { field: "department", headerName: "Our Department", width: 150 },
    { field: "dueDate", headerName: "Due Date", width: 150 },
    { field: "etc", headerName: "ETC", width: 150 },
    { field: "issues", headerName: "Issues", width: 150 },
  ];

  const rows = [
    {
      id: 1,
      project: "Testing C",
      projectName: "Prashant Project",
      completed: 0,
      last7Days: 0,
      department: "IT",
      dueDate: "2023-03-21",
      etc: "12-04-2024",
      issues: "None",
    },
    {
      id: 2,
      project: "Plan Class GIB",
      projectName: "Pwc Team GNB",
      completed: 100,
      last7Days: 100,
      department: "Finance",
      dueDate: "2024-08-16",
      etc: "07-08-2024",
      issues: "None",
    },
  ];

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    GetPCBindProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      accessType: selectedProjectAccessType,
      projStatus: selectedProjectStatus,
    };
    GetPCSearchResult(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "search result");
          setChartData(response?.data?.success?.data);
          handlechart(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectCount = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      accessType: selectedProjectAccessType,
      projStatus: selectedProjectStatus,
    };
    GetPCProjectCount(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project detail");
          const [greenData, yellowData, redData] =
            response?.data?.success?.data;
          // const flattenedData = response?.data?.success?.data.flat();
          setGreenTableData(greenData);
          setYellowTableData(yellowData);
          setRedTableData(redData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getallprojectdetails = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      accessType: selectedProjectAccessType,
      projStatus: selectedProjectStatus,
    };
    GetPCallprojectdetails(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project all detail");
          const tabsVal = response?.data?.success?.data;
          const greenCount = tabsVal[0][0]?.greenprjcnt || 0;
          const yellowCount = tabsVal[1][0]?.yellowprjcnt || 0;
          const redCount = tabsVal[2][0]?.redprjcnt || 0;
          setTabData([redCount, yellowCount, greenCount]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic
    console.log({
      selectedInstance,
      selectedZone,
      selectedProject,
      selectedManager,
      selectedAccessType,
      selectedProjectStatus,
    });
  };

  const [tabValue, setTabValue] = useState(0); // State to track the active tab

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearch = () => {
    setShowResult(true);
    console.log(selectedAttributes, "selectedAttributes");
    console.log(selectedProjectStatus, "status");
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getSearchResult(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
    getallprojectdetails(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
    getProjectCount(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  const handleReset = () => {
    setSearchValue("");
    setFilteredData(DummyData);
  };

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement
  );

  const handlechart = (c_data) => {
    console.log(c_data, "c_data");
    const projectData = c_data[0];
    const rangeData = c_data[1][0];

    const chartting = {
      labels: projectData?.map((item) => item.label),
      datasets: [
        {
          // label: "Project Data",
          data: projectData?.map((item) => ({ x: item.label, y: item.value })),
          backgroundColor: "rgba(0, 204, 0, 0.2)",
          borderColor: "#00cc00",
          borderWidth: 1,
          fill: true,
          type: "line",
        },
        {
          // label: "Safety Range",
          data: [
            { x: rangeData.safestartpoint, y: 0 },
            { x: rangeData.safeendpoint, y: selectedmaximumYAxis },
          ],
          backgroundColor: "rgba(0, 255, 0, 0.2)",
          borderColor: "green",
          borderWidth: 1,
          type: "line",
        },
        {
          // label: "Warning Range",
          data: [
            { x: rangeData.warningstartpoint, y: 0 },
            { x: rangeData.warningendpoint, y: selectedmaximumYAxis },
          ],
          backgroundColor: "rgba(255, 255, 0, 0.2)",
          borderColor: "orange",
          borderWidth: 1,
          type: "line",
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "top",
        },
        title: {
          display: false,
          text: "Portfolio Status",
          font: {
            family: "Verdana, sans-serif",
            size: 12,
            weight: "bold",
          },
        },
        annotation: {
          annotations: {
            box1: {
              type: "box",
              xMin: 0,
              xMax: 100,
              yMin: 0,
              yMax: selectedmaximumYAxis,
              backgroundColor: "rgba(0, 255, 0, 0.2)",
            },
            box2: {
              type: "box",
              xMin: 0,
              xMax: 100,
              yMin: 0,
              yMax: selectedmaximumYAxis,
              backgroundColor: "rgba(255, 255, 0, 0.2)",
            },
            box3: {
              type: "box",
              xMin: 0,
              xMax: 100,
              yMin: 0,
              yMax: selectedmaximumYAxis,
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            },
          },
        },
      },
      scales: {
        x: {
          type: "linear",
          position: "bottom",
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
          },
          grid: {
            display: false,
          },
        },
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
          },
          grid: {
            display: false,
          },
        },
      },
    };

    setDataChart(chartting);
    setDataOptions(options);
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"PortfolioChartReport"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "5px" }}
            >
              <div className={modules["pro-bread"]}>
                <a>Report</a>
                <img src={bread} alt="" />
                <a>Portfolio Chart Report</a>
              </div>
            </div>
            <div className={modules["border_cont"]}>
              <table className={modules["PCtable"]}>
                <tbody>
                  <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>
                        Instance:<label style={{ color: "Red" }}>*</label>
                      </span>

                      <select
                        value={selectedInstance}
                        onChange={(e) => {
                          setSelectedInstance(e.target.value);
                          getProjectDetails(e.target.value);
                        }}
                        className={modules["dropdownSelect"]}
                        // style={{ width: "200px" }}
                      >
                        {instances?.map((instance) => (
                          <option key={instance.name} value={instance.id}>
                            {instance.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    {hierarchyOptions
                      ?.filter((option) => option.isactive === 1)
                      .map((option, index) => (
                        <React.Fragment key={option.attributename}>
                          <td className={modules["table_data1"]}>
                            <span style={{ width: "120px" }}>
                              {option.attributename} :
                            </span>
                            <select
                              value={
                                selectedAttributes[
                                  `attr${option.attributenumber}`
                                ] || ""
                              }
                              onChange={(e) =>
                                handleSelectChange(
                                  option.attributenumber,
                                  e.target.value
                                )
                              }
                              className={modules["dropdownSelect"]}
                              // style={{ width: "200px" }}
                            >
                              <option value="">SELECT</option>
                              {filteredValueData[index]?.map((item) => (
                                <option
                                  key={item.attributevalue}
                                  value={item.id}
                                >
                                  {item.attributevalue}
                                </option>
                              ))}
                            </select>
                          </td>
                        </React.Fragment>
                      ))}
                  </tr>
                  <tr
                    style={{
                      paddingBottom: "10px",
                      paddingLeft: "0px",
                    }}
                  >
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>Project Name :</span>
                      <select
                        value={selectedProjectName}
                        onChange={(e) => setSelectedProjectName(e.target.value)}
                        className={modules["dropdownSelect"]}
                        // style={{ width: "200px" }}
                      >
                        <option value={0}>SELECT</option>
                        {projects?.map((p_name) => (
                          <option key={p_name.projectname} value={p_name.id}>
                            {p_name.projectname}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>Project Manager :</span>
                      <select
                        value={selectedProjectManager}
                        onChange={(e) =>
                          setSelectedProjectManager(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        <option value={0}>SELECT</option>
                        {managers?.map((m_name) => (
                          <option key={m_name.username} value={m_name.id}>
                            {m_name.username}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>
                        Project AccessType :
                      </span>
                      <select
                        value={selectedProjectAccessType}
                        onChange={(e) =>
                          setSelectedProjectAccessType(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        // style={{ width: "200px" }}
                      >
                        {accessTypes?.map((access) => (
                          <option key={access.label} value={access.value}>
                            {access.label}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>Project Status :</span>
                      <select
                        value={selectedProjectStatus}
                        onChange={(e) =>
                          setSelectedProjectStatus(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        {projectStatuses?.map((status) => (
                          <option key={status.label} value={status.value}>
                            {status.label}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>Project Color :</span>
                      <select
                        value={selectedProjectStatus}
                        onChange={(e) =>
                          setSelectedProjectStatus(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        {projectStatuses?.map((status) => (
                          <option key={status.label} value={status.value}>
                            {status.label}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "120px" }}>Customer :</span>
                      <select
                        value={selectedProjectStatus}
                        onChange={(e) =>
                          setSelectedProjectStatus(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        {projectStatuses?.map((status) => (
                          <option key={status.label} value={status.value}>
                            {status.label}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr
                    style={{
                      justifyContent: "center",
                      gap: "10px",
                      paddingLeft: "0px",
                      paddingBottom: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      className={modules["PCbtn"]}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["PCbtn"]}
                      type="button"
                      onClick={() => {
                        setSelectedInstance("");
                        setSelectedZone("");
                        setSelectedProject("");
                        setSelectedManager("");
                        setSelectedAccessType("SELECT");
                        setSelectedProjectStatus("0");
                      }}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              style={{ width: "90%", marginLeft: "7rem", marginTop: "2rem" }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                // checkboxSelection
                sx={{
                  "& .MuiDataGrid-cell": {
                    color: "#414141",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "lightgray",
                  },
                }}
              />
            </div>

            {showResult && <></>}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PortfolioChartReport;
