import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import FCM1 from "../HelpPopups/images/FCM1.PNG";
import IC2 from "../HelpPopups/images/CM2.jpg";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";


const FeverChartMasterhelpPopup = () => {
  return (
    <>
    <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight:'bold'
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline"}}>Fever Chart Master</h3>
              <br />
              <li>
              Fever chart coordinate screen is the screen where the user can define the coordinates for “Safe Start and Safe end points” , “Warning start and Warning end points” for fever chart for the defined level.(level defined for this screen in the Product configuration screen)
              </li>
              <br></br>
              <li style={{ fontSize: "18px", fontFamily: "sans-serif" }}>
                {" "}
                <strong>PATH : CCPM \ Masters \ Fever Chart Masters.</strong>
              </li>
              <br>
              </br>
              <li>
              For setting the coordinates, user must first select the data in defined levels field (If available) and enter the values of the coordinates in the fields and click on “Submit” button, coordinates for the selected data will be saved.
              </li>
              <br></br>
              <li>
              “Reset” button helps user making all the fields clear.
              </li>
              <br></br>
              <li>
              While entering the values for the coordinates, the user should remember that the values for field “Warning start point” cannot be equal to or less than safe start point and also the values for “Warning End points” cannot be less than or equal to “Safe end points”.
              </li>
              <br></br>
              <li>
              For deleting any particular record click on “Delete” link and for deleting multiple records select the particular records and click on “Delete” button
              </li>
              <br></br>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={FCM1} alt="FCM1" />
              </Stack>
              <li>
              Values of the coordinates can be edited / changed using the “Edit” hyperlink, i.e. Click on the “Edit” hyperlink, change the values and click on “Save” link.
              </li>
              <br></br>
             
              <br></br>
                    <li style={{textDecoration:'underline'}}>
                    Validations on the screen:-
                    </li>
              <br></br>
             <p style={{backgroundColor:'yellow',display:'inline-block'}}>Validation part for this screen will be added later as the validating conditions are not getting performed on the screens.</p>
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FeverChartMasterhelpPopup;
