import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import APCR1 from "../HelpPopups/images/APCR1.PNG";
import APCR2 from "../HelpPopups/images/APCR2.PNG";
import IC3 from "../HelpPopups/images/CM3.jpg";
import IC4 from "../HelpPopups/images/CM4.jpg";
import { Link } from "react-router-dom";

const ActiveProjectStatusChangeReasonhelpPopup = () => {
  return (
    <>
    <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "#0000EE",
                  fontFamily:"TimesNewRoman",
                  fontSize:'18px',
                  fontFamily:"Bold",
                  fontWeight:'bold',
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            
              <h3 style={{  }}>ACTIVE PROJECT STATUS CHANGE REASON MASTER</h3>
              <br />
              <p>
              This master reasons maintained against the instance which are used if user want to change the status of the current project which is in Active state. This is standard common reasons. User can define and add reasons as per the business past experience.
              </p>
              <br></br>
              <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>
                {" "}
                <strong>Session Path {'–'} CCPM {'>>'} Master {'>>'} Active Project Status Change Reason.</strong>
              </p>
              <br></br>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={APCR1} alt="APCR1" />
              </Stack>
              <span>
              Instance – Select Instance from the dropdown provided
              </span>
              <p>
              Active Project Status Change Reason – User can add reason here.
              </p>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={APCR2} alt="APCR1" />
              </Stack>
             <br></br>
             <span>User can edit or delete the reason here</span>
             
              <br></br>
             
            
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ActiveProjectStatusChangeReasonhelpPopup;
