import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
import modules from "./../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ExcelJS from 'exceljs';
import Calendar from "react-calendar";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetIRExport,
  GetIRIssueOwner,
  GetIRSearchResult,
  GetTTMTFinancialYear,
  GetTTMTBindProjectName,
  GetTTMTSearchResult,
  PCS_SearchButton
} from "../../../Services/CCPM.js";

// function ArrowLine() {
//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       <div
//         style={{
//           width: "1px",
//           height: "18px",
//           backgroundColor: "grey",
//           marginRight: "2px",
//         }}
//       />
//       <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
//     </div>
//   );
// }

const ProjectCountWithStatus = () => {
 // Check if user came from `viewprojectplans`
 const cameFromViewProjectPlans = sessionStorage.getItem("cameFromViewProjectPlans") === "true";
   // Conditionally initialize state based on the flag
   const [selectedInstance, setSelectedInstance] = useState(() => 
    cameFromViewProjectPlans ? sessionStorage.getItem("selectedInstance") || "0" : "0"
  );
  const [selectedReportType, setSelectedReportType] = useState(() => 
    cameFromViewProjectPlans ? sessionStorage.getItem("selectedReportType") || "0" : "0"
  );
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(() => 
    cameFromViewProjectPlans ? sessionStorage.getItem("selectedFinancialYear") || "0" : "0"
  );
  const [isChecked, setIsChecked] = useState(() => 
    cameFromViewProjectPlans ? sessionStorage.getItem("isChecked") === "true" : false
  );
  const [selectedAttributes, setSelectedAttributes] = useState(() => 
    cameFromViewProjectPlans ? sessionStorage.getItem("selectedAttributes") || -1 : -1
  );
  const [selectedAttributeValue, setSelectedAttributeValue] = useState(() => 
    cameFromViewProjectPlans ? sessionStorage.getItem("selectedAttributeValue") || -1 : -1
  );
  const [showResult, setShowResult] = useState(false);


  useEffect(() => {
    if (cameFromViewProjectPlans) {
      // Run search logic automatically if we're returning from viewprojectplans
     
      handleSearch();
      
      sessionStorage.removeItem("cameFromViewProjectPlans");
      sessionStorage.removeItem("selectedInstance");
      sessionStorage.removeItem("selectedReportType");
      sessionStorage.removeItem("selectedFinancialYear");
      sessionStorage.removeItem("isChecked");
      sessionStorage.removeItem("selectedAttributes");
      sessionStorage.removeItem("selectedAttributeValue");
      sessionStorage.removeItem("showResult");
    }
  }, [cameFromViewProjectPlans]);
  


 


  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("1");
  const [selectedAssignedTo, setselectedAssignedTo] = useState("0");
  const [assignedOwner, setAssignedOwner] = useState([]);
  const [selectedissueStatus, setSelectedIssueStatus] = useState("0");
  const [selectedProjectName, setSelectedProjectName] = useState(0);

 
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [instanceId, setInstanceId] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("0");
  const [isLoading, setIsLoading] = useState(false);
 
 
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarToOpen, setCalendarToOpen] = useState(false);
  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());

  const handleSelectChange = (attributenumber, id) => {
    // console.log(attributenumber, id, "attributenumber value");
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [instances, setInstances] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [displayfromdate, setdisplayfromdate] = useState("");
  const [displaytodate, setdisplaytodate] = useState("");
  const handleFromDateChange = (date) => {
    setFromdate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplayfromdate(`${day}/${month}/${year}`);
    setCalendarOpen(false);
  };

  const handleToDateChange = (date) => {
    setTodate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplaytodate(`${day}/${month}/${year}`);
    setCalendarToOpen(false);
  };

  const accessTypes = [
    { value: "null", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ];

  const periods = [
    { value: "Annual", label: "Annually" },
    { value: "Half-yearly", label: "Half-yearly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Monthly", label: "Monthly" },
  ];

  const projectStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Active" },
    { value: "5", label: "Hold" },
    { value: "7", label: "Completed" },
    { value: "10", label: "CDDCheckedOut" },
    { value: "11", label: "ModifiedCheckedOut" },
  ];

  const issueStatuses = [
    { value: "0", label: "All" },
    { value: "4", label: "Pending" },
    { value: "5", label: "Closed" },
  ];

  const reportTypes = [
    { value: "1", label: "Snapshot As On Date" },
    { value: "2", label: "Financial Year Wise Usage" }
  ];

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
    getFinancialYear();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0 && selectedInstance === -1) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getAssinedOwner();
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
      getFinancialYear();
    }
  }, [instances]);

  const toggleCalendar = () => {
    setCalendarOpen((prevOpen) => !prevOpen);
  };

  const toggleToCalendar = () => {
    setCalendarToOpen((prevOpen) => !prevOpen);
  };

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = () => {
    const loginId = sessionStorage.getItem("loginId");
    const dateRange = selectedFinancialYear;
    const formattedDateRange = dateRange.replace(/\s*-\s*/, "-");
    console.log(formattedDateRange); // Output: "1 April 2022-31 March 2023"
    const body = {
      userId: loginId,
      instanceId: parseInt(selectedInstance, 10),
      financialYear: formattedDateRange,
    };
    GetTTMTBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    GetPCBindProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFinancialYear = () => {
    GetTTMTFinancialYear()
      .then((response) => {
        // console.log(response, "FY");
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data, "FY");
          setFinancialYears(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssinedOwner = () => {
    const body = {
      userId: 0,
    };
    GetIRIssueOwner(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "Assigned Owner");
          setAssignedOwner(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "Attri val");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExcel = async (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      issueOwner: selectedAssignedTo,
      projectType: selectedProjectAccessType,
      accessType: "",
      issueStatusId: selectedissueStatus,
    };

    // if (selectedRoleId !== -1) {
    try {
      setIsLoading(true);
      const response = await GetIRExport(body);

      if (response && response.data) {
        setIsLoading(false);
        let data = response.data;
        if (data instanceof ArrayBuffer) {
          data = new Uint8Array(data);
        }

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // const contentDisposition = response.headers["content-disposition"];
        const filename = "IssueReport.xlsx";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoading(false);
        console.error("No Data found");
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error downloading file:", err);
    }
  };

  const handleSearch = () => {

    if (selectedReportType === "0" || selectedReportType === null) {
      alert("Select Report Type.");
      return; // Exit the function if no valid report type is selected
    }


    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginId");
    const dateRange = selectedFinancialYear;
    const formattedDateRange = dateRange.replace(/\s*-\s*/, "-");
    console.log(formattedDateRange); // Output: "1 April 2022-31 March 2023"
    const body = {
      instance_id: parseInt(selectedInstance),
      financialYear: formattedDateRange,
      userId: parseInt(loginId),
      attribute: parseInt(selectedAttributes),
      attributevalue: parseInt(selectedAttributeValue),
      startDate: '',
      endDate: '',
      reportId: selectedReportType,

    };

    PCS_SearchButton(body)
      .then((response) => {
        setShowResult(true);
        setIsLoading(false);
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "table data");
          setTableData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
        setShowResult(true);
        setIsLoading(false);
      });
  };

  const DownloadHandler = () => {
    setShowResult(true);
    console.log(selectedAttributes, "selectedAttributes");
    console.log(selectedProjectStatus, "status");
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getExcel(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

 

  const handleCheckboxChange = (event) => {
    setSelectedAttributes(-1);
    setIsChecked(event.target.checked);
  };

  const getFilterAttributeValues = (a_number) => {
    console.log(a_number, "attributenumber");

    const number = parseInt(a_number, 10);

    const allAttributesValue = filteredValueData.flat();
    console.log(allAttributesValue, "allAttributesValue");

    const filteredData = allAttributesValue.filter(
      (item) => item.attributenumber === number
    );
    console.log(filteredData, "filteredData");

    setAttributeValues(filteredData);
  };

  console.log("isChecked:", isChecked);
  console.log("selectedInstance:", selectedInstance);
  console.log("selectedFinancialYear:", selectedFinancialYear);


  const formatDate = (dateString) => {
    if (!dateString) return '';
    const dateParts = dateString.split('-'); // Split the date string by '-'
    if (dateParts.length === 3) {
      return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`; // Rearrange to DD/MM/YYYY
    }
    return dateString; // Return the original string if format is unexpected
  };


  const shouldHighlight = (value) => {
    return value > 0; // Return true if the value is greater than zero
  };

  const shouldHighlightnew = (value) => {
    // Extract the numeric part before the parentheses
    const numericValue = parseInt(value.split(' ')[0], 10);
    return numericValue > 0; // Return true if the numeric value is greater than zero
  };

  const formatActiveValue = (activeValue) => {
    if (!activeValue) return '0'; // If activeValue is falsy, return '0'

    // Split the string to get numeric and parenthesis parts
    const [numericPart, parenthesisPart] = activeValue.split('(');
    const numericValue = parseInt(numericPart.trim(), 10) || 0; // Handle NaN as 0

    if (numericValue > 0 && parenthesisPart) {
        const valuesInside = parenthesisPart.replace(')', '').split('/').map(v => v.trim());
        
        // Replace empty values with '0' for both parts of the parentheses
        const formattedValues = valuesInside.map(val => (val === '' ? '0' : val));

        // Join the formatted values with '/' and return the result
        return `${numericValue} (${formattedValues.join('/')})`; 
    }

    // Default case if numericValue is not greater than 0 or no parentheses present
    return '0'; 
};


  
  
  console.log("Selected Attribute:",selectedAttributes);


  const handleExportClick = () => {
    exportToExcel(tableData, selectedReportType);
  };

  const exportToExcel = async (tableData, selectedReportType) => {
    const workbook = new ExcelJS.Workbook();
    const worksheetName = selectedReportType === '1' ? 'ProjectAsOnDateReport' : 'FinancialYrWiseProjectReport';

    // Create a new worksheet with the dynamically set name
    const worksheet = workbook.addWorksheet(worksheetName);

    // Define headers based on the selected report type
    const headers = [
        "Sr.No.",
        ...(selectedReportType === '1'
            ? [
                "Last Date of Project Creation",
                "Last Date of Task Update",
                "No. of Unique Users In Last 30 Days",
                "Total Projects",
            ]
            : [
                "Financial Year",
                "No. of Unique Users in the Year",
                "Total Projects",
            ]
        ),
        "Created",
        "Submitted",
        "Planned",
        "Scheduled",
        "Active(G/Y/R)",
        "Completed",
        "On Hold",
        "Checked Out - Change Due Date(CDD)",
        "Checked Out - Modified",
    ];

    const mergedHeaderRow = worksheet.getRow(1); // The row where we will add merged headers

    // Dynamically merge the "Project Details" columns based on the selected report type
    if (selectedReportType === '1') {
        worksheet.mergeCells(1, 1, 1, 5);
        mergedHeaderRow.getCell(1).value = '';  // Set the merged header name
        worksheet.mergeCells(1, 6, 1, 14);
        mergedHeaderRow.getCell(6).value = 'Status Count As On Date';  // Set the merged header name
    } else if (selectedReportType === '2') {
        worksheet.mergeCells(1, 1, 1, 4);
        mergedHeaderRow.getCell(1).value = '';
        worksheet.mergeCells(1, 5, 1, 13);
        mergedHeaderRow.getCell(5).value = 'Status Count As On Date';
    }

    // Style the merged headers
    mergedHeaderRow.eachCell((cell) => {
        cell.font = { bold: true, size: 12, name: 'Aptos Narrow' };
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        // Add borders to the merged header cells
        cell.border = {
            top: { style: 'thick' },
            bottom: { style: 'thick' },
            left: { style: 'thick' },
            right: { style: 'thick' }
        };
    });

    // Add the original header row (this is the column names row)
    const headerRow = worksheet.addRow(headers);

    // Apply style and borders to header row
    headerRow.eachCell({ includeEmpty: true }, (cell) => {
        cell.font = { bold: true, name: 'Aptos Narrow' };
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        // Add borders to each cell
        cell.border = {
            top: { style: 'thick' },
            bottom: { style: 'thick' },
            left: { style: 'thick' },
            right: { style: 'thick' }
        };
    });

    // Add data rows based on selected report type
    tableData?.map((item, index) => {
        const rowData = [
            index + 1, // Sr.No.
            ...(selectedReportType === '1'
                ? [
                    formatDate(item.projectcreated),
                    formatDate(item.taskupdated),
                    item.activeuser,
                    item.projectcount,
                ]
                : [
                    item.financialyr,
                    item.usercnt,
                    item.projectcount,
                ]
            ),
            item.created,
            item.submitted,
            item.planned,
            item.scheduled,
            formatActiveValue(item.active),
            item.completed,
            item.hold,
            item.cddcheckedout,
            item.modifiedcheckedout,
        ];

        // Add the row to the worksheet
        const dataRow = worksheet.addRow(rowData);

        // Apply style and borders to data row
        dataRow.eachCell({ includeEmpty: true }, (cell) => {
            cell.font = { name: 'Aptos Narrow', size: 11 };
            cell.alignment = { wrapText: true };
            // Add borders to each cell
            cell.border = {
                top: { style: 'thick' },
                bottom: { style: 'thick' },
                left: { style: 'thick' },
                right: { style: 'thick' }
            };
        });
    });

    // Adjust column widths based on the content
    worksheet.columns = [
        { width: 5 }, // Sr.No.
        { width: 25 }, // Date columns
        { width: 20 }, // Financial and other columns
        { width: 15 }, // Status columns
        ...headers.slice(8).map(() => ({ width: 20 })) // Remaining columns
    ];

    // Determine the file name based on the report type
    const fileName = selectedReportType === '1' ? 'ProjectAsOnDateReport.xlsx' : 'FinancialYrWiseProjectReport.xlsx';

    // Save workbook to Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName; // Dynamic file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};


const navigate = useNavigate();

const handleOpenAdd = (ProjStat) => {
  localStorage.removeItem("InstanceIdNew");
  localStorage.removeItem("ProjectStatusId");

  console.log("Selected Instance:", selectedInstance);
  console.log("Column Name:", ProjStat);
  localStorage.setItem("InstanceIdNew", selectedInstance);
  localStorage.setItem("ProjectStatusId", ProjStat);


  sessionStorage.setItem("selectedInstance", selectedInstance);
  sessionStorage.setItem("selectedReportType", selectedReportType);
  sessionStorage.setItem("selectedFinancialYear", selectedFinancialYear);
  sessionStorage.setItem("isChecked", isChecked);
  sessionStorage.setItem("selectedAttributes", selectedAttributes);
  sessionStorage.setItem("selectedAttributeValue", selectedAttributeValue);
  sessionStorage.setItem("showResult", showResult);



  navigate(`/ccpm/viewmyprojects?InstanceId=${selectedInstance}&ProjectStatusId=${ProjStat}`);
};
  




  return (
    <>
      <div
      // style={{
      //   overflowX: showResult && tableData.length > 0 ? "auto" : "hidden",
      // }}
      >
        {isLoading && (
          <img
            src={loading}
            className={modules["loading_gif"]}
            alt="Loading..."
          />
        )}
        <Grid container>
          <Grid item lg={0.6} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>

          <Grid item lg={12} sx={{ display: "block" }}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"ProjectCountWithStatus"} />
            </Grid>

            <Grid item lg={12}>
              <div
                className={modules["instancemaster_div"]}
                style={{ marginBottom: "5px" }}
              >
                <div
                  className={modules["pro-bread"]}
                  style={{ marginLeft: "-2.5rem" }}
                >
                  <a>Reports</a>
                  <img src={bread} alt="" />
                  <a>Project Count With Status</a>
                </div>
              </div>
              <div
                className={modules["border_cont"]}
                style={{ width: "85%", marginLeft: "7rem" }}
              >
                <table className={modules["PCtable"]}>
                  <tbody>
                    <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Instance:<label style={{ color: "Red" }}>*</label>
                        </span>

                        <select
                          value={selectedInstance}
                          onChange={(e) => {
                            setSelectedInstance(e.target.value);
                            getProjectDetails(e.target.value);
                            setShowResult(false);
                            setSelectedFinancialYear("0");
                            setSelectedReportType("0");
                            setIsChecked(false);
                          }}
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          {instances?.map((instance) => (
                            <option key={instance.name} value={instance.id}>
                              {instance.name}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141" }}>
                          Report Type :
                          <label style={{ color: "Red" }}>*</label>
                        </span>
                        <select
                          value={selectedReportType}
                          onChange={(e) => {
                            setSelectedReportType(e.target.value);
                            setSelectedPeriod("");
                            setShowResult(false);
                          }
                          }
                          className={modules["dropdownSelect"]}
                          style={{ width: "200px" }}
                        >
                          <option value={0}>SELECT</option>
                          {reportTypes.map((type) => (
                            <option key={type.value} value={type.value}>
                              {type.label}
                            </option>
                          ))}

                        </select>
                      </td>

                      <td
                        className={modules["table_data1"]}
                        style={{ width: "155px" }}
                      >
                        <span style={{ width: "135px", color: "#414141" }}>
                          Report with Attribute :
                        </span>
                        <Checkbox
                          checked={isChecked}
                          onChange={(e) => {
                            handleCheckboxChange(e);
                            setShowResult(false);
                          }}
                          sx={{
                            color: "grey.600",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: "13px",
                            },
                            margin: "3px",
                            padding: "0px",
                          }}
                        />
                      </td>
                    </tr>

                    <tr
                      style={{
                        paddingBottom: "10px",
                        paddingLeft: "0px",
                      }}
                    >
                      {selectedReportType === "2" && (
                        <td className={modules["table_data1"]}>
                          <span style={{ width: "115px", color: "#414141" }}>
                            Financial Year :
                            <label style={{ color: "Red" }}>*</label>
                          </span>
                          <select
                            value={selectedFinancialYear}
                            onChange={(e) => {
                              setSelectedFinancialYear(e.target.value);
                              setSelectedPeriod("");
                              setShowResult(false);
                            }
                            }
                            className={modules["dropdownSelect"]}
                            style={{ width: "200px" }}
                          >
                            <option value={0}>SELECT</option>
                            <option value={"All"}>All</option>
                            <option value={"-2"}>Date Range</option>
                            {financialYears?.map((fy) => (
                              <option
                                key={fy.financialyear}
                                value={fy.financialyear}
                              >
                                {fy.financialyear}
                              </option>
                            ))}
                          </select>
                        </td>

                      )}

                      {isChecked && (
                        <>
                          <td className={modules["table_data1"]}>
                            <span style={{ width: "115px", color: "#414141" }}>
                              Attribute :<label style={{ color: "Red" }}>*</label>
                            </span>
                            <select
                              value={selectedAttributes}
                              onChange={(e) => {
                                setSelectedAttributes(e.target.value);
                                getFilterAttributeValues(e.target.value);
                                setSelectedAttributeValue(-1);
                                
                              }}
                              style={{
                                width: "200px",

                              }}
                              className={modules["dropdownSelect"]}
                            >
                              <option value={-1}>SELECT</option>

                              {hierarchyOptions?.map((instance) => (
                                <option
                                  key={instance.attributename}
                                  value={instance.attributenumber}
                                >
                                  {instance.attributename}
                                </option>
                              ))}
                            </select>

                          </td>


                          {selectedAttributes !== -1 && (
                            <>
                              <td className={modules["table_data1"]}>


                                <span
                                  style={{ width: "115px", color: "#414141" }}
                                >
                                  Attribute Value :
                                  
                                </span>

                                <select
                                  value={selectedAttributeValue}
                                  onChange={(e) => {
                                    setSelectedAttributeValue(e.target.value);
                                    // getProjectDetails(e.target.value);
                                  }}
                                  className={modules["dropdownSelect"]}
                                  style={{ width: "120px" }}
                                >
                                  <option value={-1}>SELECT</option>

                                  {attributeValues?.map((idx) => (
                                    <option
                                      key={idx.attributevalue}
                                      value={idx.id}
                                    >
                                      {idx.attributevalue}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </>
                          )}
                        </>
                      )}



                    </tr>
                    {(isChecked &&
                      selectedInstance &&


                      selectedAttributes === -1
                    ) ||
                      (!isChecked &&
                        selectedInstance &&
                        selectedReportType == 2 &&
                        selectedFinancialYear == 0) || (
                        isChecked &&
                        selectedInstance &&
                        selectedReportType == 2 &&
                        selectedFinancialYear &&
                        selectedAttributes == -1

                      ) ? null : (
                      <tr
                        style={{
                          // justifyContent: "center",
                          gap: "10px",
                          paddingLeft: "470px",
                          paddingBottom: "10px",
                        }}
                      >
                        <button
                          type="submit"
                          className={modules["PCbtn"]}
                          onClick={handleSearch}
                        >
                          Search
                        </button>
                        <button
                          className={modules["PCbtn"]}
                          type="button"
                          onClick={() => {
                            // window.location.reload();
                            setSelectedInstance("0");
                            setSelectedFinancialYear("");
                            setSelectedPeriod("");
                            setSelectedProjectName(0);
                            setSelectedAttributes(-1);
                            setSelectedAttributeValue(-1);
                            setShowResult(false);
                            setSelectedReportType(0);
                            setIsChecked(false);
                          }}
                        >
                          Reset
                        </button>
                        {showResult && tableData.length > 0 && (
                          <button
                            type="submit"
                            className={modules["PCbtn"]}
                            onClick={handleExportClick}
                          >
                            Export to Excel
                          </button>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {showResult &&
                (tableData.length > 0 ? (
                  <>
                    <Grid
                      container
                      sx={{ marginLeft: "6.5rem", padding: "8px" }}
                    >
                      <Table
                        className={modules["PCS_table"]}
                        sx={{ width: "86% !important" }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={selectedReportType === '1' ? 5 : 4}></TableCell>
                            <TableCell
                              colSpan={9}
                              sx={{ backgroundColor: "#75bf47 !important" }}
                              className={modules["TTMT_customth"]}
                            >
                              Status Count As On Date
                            </TableCell>

                          </TableRow>
                        </TableHead>
                        <TableHead>
                          <TableRow>
                            <TableCell>Sr.No.</TableCell>
                            {selectedReportType === '1' ? (
                              <>
                                <TableCell>Last Date of Project Creation</TableCell>
                                <TableCell>Last Date of Task Update</TableCell>
                                <TableCell>No. of Unique Users In Last 30 Days</TableCell>
                                <TableCell>Total Projects</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Submitted</TableCell>
                                <TableCell>Planned</TableCell>
                                <TableCell>Scheduled</TableCell>
                                <TableCell>Active(G/Y/R)</TableCell>
                                <TableCell>Completed</TableCell>
                                <TableCell>On Hold</TableCell>
                                <TableCell>Checked Out - Change Due Date(CDD)</TableCell>
                                <TableCell>Checked Out - Modified</TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell>Financial Year</TableCell>
                                <TableCell>No. of Unique Users in the Year</TableCell>
                                <TableCell>Total Projects</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Submitted</TableCell>
                                <TableCell>Planned</TableCell>
                                <TableCell>Scheduled</TableCell>
                                <TableCell>Active(G/Y/R)</TableCell>
                                <TableCell>Completed</TableCell>
                                <TableCell>On Hold</TableCell>
                                <TableCell>Checked Out - Change Due Date(CDD)</TableCell>
                                <TableCell>Checked Out - Modified</TableCell>
                              </>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableData?.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell className={modules["IRtable-row"]}>
                                {index + 1}
                              </TableCell>

                              {selectedReportType === '1' ? (
                                <>
                                  <TableCell className={modules["IRtable-row"]}>
                                    {formatDate(item.projectcreated)}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]}>
                                    {formatDate(item.taskupdated)}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]}>
                                    {item.activeuser}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]}>
                                    {item.projectcount}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.created) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.created)) {
                                      handleOpenAdd('1');
                                    }
                                  }}
                                  >
                                    {item.created}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.submitted) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.submitted)) {
                                      handleOpenAdd('2');
                                    }
                                  }}
                                  >
                                    {item.submitted}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.planned) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.planned)) {
                                      handleOpenAdd('3');
                                    }
                                  }}
                                  >
                                    {item.planned}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.scheduled) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.scheduled)) {
                                      handleOpenAdd('6');
                                    }
                                  }}
                                  >
                                    {item.scheduled}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlightnew(item.active) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlightnew(item.active)) {
                                      handleOpenAdd('4');
                                    }
                                  }}
                                  >
                                   {formatActiveValue(item.active)}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.completed) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.completed)) {
                                      handleOpenAdd('7');
                                    }
                                  }}
                                  >
                                    {item.completed}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.hold) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.hold)) {
                                      handleOpenAdd('5');
                                    }
                                  }}
                                  >
                                    {item.hold}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.cddcheckedout) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.cddcheckedout)) {
                                      handleOpenAdd('10');
                                    }
                                  }}>
                                    {item.cddcheckedout}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.modifiedcheckedout) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.modifiedcheckedout)) {
                                      handleOpenAdd('11');
                                    }
                                  }}>
                                    {item.modifiedcheckedout}
                                  </TableCell>
                                </>
                              ) : (
                                <>
                                  <TableCell className={modules["IRtable-row"]}>
                                    {item.financialyr}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]}>
                                    {item.usercnt}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]}>
                                    {item.projectcount}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.created) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.created)) {
                                      handleOpenAdd('1');
                                    }
                                  }}
                                  >
                                    {item.created}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.submitted) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.submitted)) {
                                      handleOpenAdd('2');
                                    }
                                  }}
                                  >
                                    {item.submitted}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.planned) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.planned)) {
                                      handleOpenAdd('3');
                                    }
                                  }}
                                  >
                                    {item.planned}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.scheduled) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.scheduled)) {
                                      handleOpenAdd('6');
                                    }
                                  }}
                                  >
                                    {item.scheduled}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlightnew(item.active) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlightnew(item.active)) {
                                      handleOpenAdd('4');
                                    }
                                  }}
                                  >
                                   {formatActiveValue(item.active)}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.completed) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.completed)) {
                                      handleOpenAdd('7');
                                    }
                                  }}
                                  >
                                    {item.completed}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.hold) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.hold)) {
                                      handleOpenAdd('5');
                                    }
                                  }}
                                  >
                                    {item.hold}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.cddcheckedout) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.cddcheckedout)) {
                                      handleOpenAdd('10');
                                    }
                                  }}>
                                    {item.cddcheckedout}
                                  </TableCell>
                                  <TableCell className={modules["IRtable-row"]} sx={shouldHighlight(item.modifiedcheckedout) ? {
                                    color: "#0000EE !important",
                                    textDecoration: "underline",
                                    cursor:'pointer'
                                  } : {}}
                                  onClick={() => {
                                    if (shouldHighlight(item.modifiedcheckedout)) {
                                      handleOpenAdd('11');
                                    }
                                  }}>
                                    {item.modifiedcheckedout}
                                  </TableCell>
                                  </>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>

                      </Table>
                    </Grid>
                  </>
                ) : (
                  <TableRow>
                    <TableCell className={modules["PCTR_row"]} align="left">No Record Found</TableCell>
                  </TableRow>
                )
                )
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ProjectCountWithStatus;
