import React, { useState, useEffect, useRef } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import calender from "../../../Assets/calendar.png";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import modules from "./../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import { GetProjects, GetStatus, GetInstancesList, GetTableDetails, GetExportExcel } from "../../../Services/CCPM";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ExcelJS from 'exceljs';
import DatePicker from 'react-datepicker';




function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const ProjectScheduleCurrentdate = () => {

  // State management
  const [instance, setInstance] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [projectManager, setProjectManager] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState("");
  const [selectedProject, setSelectedProject] = useState({ id: "", name: "SELECT" });
  const [selectedProjectManager, setSelectedProjectManager] = useState("SELECT");
  const [temp, setTemp] = useState([]);
  const [failedCheck, setFailedCheck] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const parentRef = useRef(null);
  const tableRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (showTable) {
      document.body.classList.add(modules['overflow-auto']);
    } else {
      document.body.classList.remove(modules['overflow-auto']);
    }

    // Cleanup on unmount
    return () => {
      document.body.classList.remove(modules['overflow-auto']);
    };
  }, [showTable]);

  useEffect(() => {
    setIsLoading(true);
    checkLoginAndRedirect();
    let body = {
      userId: sessionStorage.getItem("loginId"),
    };
    GetInstancesList(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          const instanceData = response.data.success.data;
          setInstance(instanceData); // Set the instance data
          if (instanceData.length > 0) {
            setSelectedInstance(instanceData[0].id); // Set the first instance ID
          }

        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (showTable && tableRef.current && parentRef.current) {
      const tableWidth = tableRef.current.offsetWidth;
      parentRef.current.style.width = `${Math.max(tableWidth, 1700)}px`;
    }
  }, [showTable]);

  useEffect(() => {
    if (selectedInstance !== "SELECT"|| "") {
      setIsLoading(true);
      let body1 = {
        userId: sessionStorage.getItem("loginId"),
        instanceId: selectedInstance,
        includeViewAccess: 1,
        isSpecificProjStatus: selectedProjectManager === "SELECT" ? 0 : selectedProjectManager,
      };
      GetProjects(body1)
        .then((response) => {
          if (response.status === 200) {
            setProjectName(response.data.success.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [selectedInstance, selectedProjectManager]);

  const handleInstance = (i) => {
    setSelectedProjectManager("SELECT");
    setSelectedProject({ id: "", name: "SELECT" });
    setTemp([]);
    setShowTable(false);
    setSelectedInstance(i);
    setIsLoading(true);
    let body = {
      userId: sessionStorage.getItem("loginId"),
      instanceId: i
    };
    GetStatus()
      .then((response) => {
        if (response.status === 200) {
          setProjectManager(response.data.success.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleProjectManager = (i) => {
    setSelectedProjectManager(i);
    showTable && setShowTable(false);
    setFailedCheck(false);
    setSelectedProject({ id: "", name: "SELECT" });
  };

  const handleProject = (id, name) => {
    setSelectedProject({ id, name });
  };

  const handleSearch = () => {
    if (selectedInstance === "SELECT" || selectedProject.id === "") {
      alert("Please select both Instance and project.");
      return;
    }

    // Start loading only if search criteria are valid
    setIsLoading(true);

    let body = {
      projectId: selectedProject.id,
    };

    GetTableDetails(body)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.data?.success?.data;

          if (responseData && responseData.length > 0) {
            setFailedCheck(false);
            setTemp(responseData);
            setShowTable(true);  // Show the table only when data is fetched
          } else {
            setFailedCheck(true);
            setShowTable(false);  // Hide the table if data is null or empty
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // Stop loading after response or error
        setIsLoading(false);
      });
  };



  const handleReset = () => {
    if (instance && instance.length > 0) {
      const firstInstance = instance[0].id; // Use the first instance directly
      setSelectedInstance(firstInstance);
    }
    setSelectedProjectManager("SELECT");
    setSelectedProject({ id: "", name: "SELECT" });
    setTemp([]);
    setShowTable(false);
  };

  function getFilenameFromHeader(header) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(header);
    if (matches != null && matches[1]) {
      return decodeURIComponent(matches[1].replace(/['"]/g, ""));
    }
    return null;
  }

  const ExportToExcelHandler = async () => {
    setIsLoading(true);
    const body = {
      projectId: selectedProject.id,
    };

    try {
      // Making the API call with the correct responseType
      const response = await GetExportExcel(body, { responseType: 'arraybuffer' });

      if (response) {
        // Extracting the filename from the content-disposition header if it exists
        const contentDisposition = response.headers['content-disposition'];
        let filename = "CurrentProjectScheduleReport.xlsx"; // Default filename

        if (contentDisposition) {
          const matches = /filename="([^"]+)"/.exec(contentDisposition);
          if (matches && matches[1]) {
            filename = matches[1];
          }
        }

        // Creating a Blob from the response data
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Creating a link element to download the file
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        // Appending the link to the body and triggering the download
        document.body.appendChild(link);
        link.click();

        // Cleaning up
        document.body.removeChild(link);
      } else {
        console.error("No data found in the response.");
      }
    } catch (err) {
      console.error("Error during file download:", err);
    } finally {
      setIsLoading(false);
    }
  };




  const formatNumber = (value) => {
    if (value == null || isNaN(value)) {
      return '0.00';
    }
    return parseFloat(value).toFixed(2);
  };

  const formatDate = (dateString) => {
    if (!dateString) return null; // Return null if the date is null
  
    const date = new Date(dateString);
  
    // Date part (e.g., "18-Apr-2022")
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric"
    }).format(date).replace(/ /g, "-"); // Replace spaces with hyphens
  
    // Time part (e.g., "11:45")
    const formattedTime = new Intl.DateTimeFormat("en-GB", {
      hour: "2-digit",
      minute: "2-digit"
    }).format(date);
  
    return `${formattedDate} ${formattedTime}`;
  };


  const handleExportClick = () => {
    exportSimpleTableToExcel(temp);
  };


 const exportSimpleTableToExcel = async (temp) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('CurrentProjectScheduleReport ');

    // Add a row with merged cells for project info
  const projectInfoRow = worksheet.addRow([
    `Project Name : ${selectedProject.name !== "" ? selectedProject.name : "N/A"}  |  ` +
    `Project Status : ${temp.length > 0 ? temp[0].projectstatus : "N/A"}  |  ` +
    `Project Manager : ${temp.length > 0 ? temp[0].projectmanager : "N/A"}`
  ]);

  // Merge all cells in the row (A1:O1 for example)
  worksheet.mergeCells('A1:O1');

  // Style the project info row
  projectInfoRow.eachCell((cell) => {
    cell.font = { bold: true, name: 'Aptos Narrow', size: 12 };
    cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    cell.border = {
      top: { style: 'thick' },
      left: { style: 'thick' },
      bottom: { style: 'thick' },
      right: { style: 'thick' }
    };
  });


  // Define headers based on the table columns
  const headers = [
    "Task ID", "Task Name", "Department", "Planned Duration Day(s)", 
    "Remaining Duration Day(s)", "Planned StartDate", "Planned EndDate", 
    "Expected / Actual StartDate", "Expected / Actual EndDate", 
    "Predecessor", "Successor", "Task Status", "Task Manager", 
    "Process Note", "User Notes"
  ];

  // Add header row to the worksheet
  const headerRow = worksheet.addRow(headers);

  // Style headers (bold, center alignment, thick borders)
  headerRow.eachCell((cell) => {
    cell.font = { bold: true, name: 'Aptos Narrow', size: 12 };
    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    cell.border = {
      top: { style: 'thick' },
      left: { style: 'thick' },
      bottom: { style: 'thick' },
      right: { style: 'thick' }
    };
  });

  // Add data rows
  temp.forEach((row) => {
    const rowData = [
      row.taskid,                             
      row.taskname,                          
      row.department,                       
      formatNumber(row.originaltaskduration),              
      row.remainingduration,                 
      formatDate(row.expectedtaskstartdate),  
      formatDate(row.expectedtaskenddate),    
      formatDate(row["Expected/ActualTaskStartDate"]), 
      formatDate(row["Expected/ActualTaskEndDate"]),   
      row.predecessor,                        
      row.successor,                          
      row.taskstatus,                         
      row.taskmanager,                        
      row.taskprocessnotes,                   
      row.usernotes                           
    ];

    const dataRow = worksheet.addRow(rowData);
    
    // Style each cell in the data row with borders and custom alignment
    dataRow.eachCell((cell) => {
      cell.font = { name: 'Aptos Narrow', size: 11 };
      cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
      cell.border = {
        top: { style: 'thick' },
        left: { style: 'thick' },
        bottom: { style: 'thick' },
        right: { style: 'thick' }
      };
    });
  });
 // Apply border for empty cells up to the maximum header length
 const numRows = worksheet.rowCount;
 const numCols = headers.length;
 
 for (let i = 1; i <= numRows; i++) {
   const row = worksheet.getRow(i);
   for (let j = 1; j <= numCols; j++) {
     const cell = row.getCell(j);
     cell.border = {
       top: { style: 'thick' },
       left: { style: 'thick' },
       bottom: { style: 'thick' },
       right: { style: 'thick' }
     };
   }
 }
  // Set custom column widths for each column
  worksheet.columns = [
    { width: 10 },  // Task ID
    { width: 25 },  // Task Name
    { width: 20 },  // Department
    { width: 15 },  // Planned Duration Day(s)
    { width: 20 },  // Remaining Duration Day(s)
    { width: 15 },  // Planned StartDate
    { width: 15 },  // Planned EndDate
    { width: 20 },  // Expected / Actual StartDate
    { width: 20 },  // Expected / Actual EndDate
    { width: 15 },  // Predecessor
    { width: 15 },  // Successor
    { width: 15 },  // Task Status
    { width: 20 },  // Task Manager
    { width: 25 },  // Process Note
    { width: 25 }   // User Notes
  ];

  // Save workbook
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'CurrentProjectScheduleReport.xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

  


  return (
    <>
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"ProjectScheduleCurrentdate"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Reports</a>
                <img src={bread} alt="" />
                <a>Project Schedule As On Date</a>
              </div>
            </div>


            <div container className={modules['whole-box']}>
              <div container className={modules["border_contPSCD"]}
                style={{ width: showTable ? '1782px' : '85%' }}
                ref={parentRef}
              >
                <table className={modules["PCtable"]}>
                  <tbody style={{width:'100%'}}>
                    <tr style={{ paddingBottom: "15px", paddingLeft: "0px" ,width:'100%',justifyContent:'space-between'}}>
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141", fontWeight: 'bold' }}>
                          Instance :
                        </span>


                        <select
                          style={{ alignItems: 'center', justifyContent: 'center' }}
                          IconComponent={ArrowLine}
                          className={modules["dropdownSelect"]}
                          value={selectedInstance}
                          onChange={(e) => handleInstance(e.target.value)}
                          MenuProps={{
                            classes: { paper: modules.dropdownPaper },
                          }}
                        >
                          
                          {instance.map((i) => (
                            <option value={i.id} key={i} className={modules.dropdownItem}>
                              {i.name}
                            </option>
                          ))}
                        </select>

                      </td>
                      <td className={modules["table_data1"]}>
                        <span style={{ width: "115px", color: "#414141", fontWeight: 'bold' }}>
                          Project Status :
                        </span>
                        <select
                          IconComponent={ArrowLine}
                          className={modules["dropdownSelect"]}
                          value={selectedProjectManager}
                          onChange={(e) => handleProjectManager(e.target.value)}
                          MenuProps={{
                            classes: { paper: modules.dropdownPaper },
                          }}
                        >
                          <option value="SELECT" className={modules.dropdownItem}>
                            SELECT
                          </option>
                          {projectManager.map((i) => (
                            <option value={i.id} key={i} className={modules.dropdownItem}>
                              {i.projectstatus}
                            </option>
                          ))}
                        </select>

                      </td>
                      <td className={modules["table_data1"]}>

                        <span style={{ width: "115px", color: "#414141", fontWeight: 'bold' }}>
                          Project Name :
                        </span>
                        <select
                          IconComponent={ArrowLine}
                          className={modules["dropdownSelect"]}
                          value={selectedProject.name}
                          onChange={(e) => handleProject(e.target.options[e.target.selectedIndex].dataset.id, e.target.value)}
                          MenuProps={{
                            classes: { paper: modules.dropdownPaper },
                          }}
                        >
                          <option value="SELECT" className={modules.dropdownItem}>
                            SELECT
                          </option>
                          {projectName.map((i) => (
                            <option
                              value={i.projectname}
                              key={i.id}
                              data-id={i.id}
                              className={modules.dropdownItem}
                            >
                              {i.projectname}
                            </option>
                          ))}
                        </select>
                      </td>



                    </tr>




                    <tr
                      style={{
                        justifyContent: "center",
                        gap: "10px",
                        paddingLeft: "0px",
                        paddingBottom: "10px",
                      }}
                    >
                      <button style={{ marginLeft: showTable ? '12rem' : '12rem' }} className={modules["PCbtn"]} onClick={handleSearch}>
                        Search
                      </button>
                      <button className={modules["PCbtn"]} onClick={handleReset}>
                        Reset
                      </button>
                      <button className={modules["PCbtn"]} onClick={handleExportClick}>
                        Export To Excel
                      </button>
                    </tr>
                  </tbody>
                </table>
              </div>



              {failedCheck ?  (
                <TableRow
                  className={modules["table-cell"]}
                  sx={{
                    textAlign: "left !important",
                    display:"flex",
                    borderBottom: "1px solid #a4bf47",
                    marginLeft: "6rem",
                    marginTop: "1.2rem",
                    fontSize: "12px",
                    color: "#414141",
                    padding: "8px",
                    width: "110px",
                  }}
                >
                  No Record Found.
                </TableRow>
              ) : (
                showTable && (
                  <>
                    <Grid container className={modules["info-row"]} style={{ marginBottom: '1rem' }}>
                      <Grid item xs={12}>
                        <Grid container spacing={0.5} className={modules["info-containerPSCD"]}>
                          <Grid item xs={1} className={modules["label-style"]} style={{ fontWeight: 'bold', fontSize: '12px', color: '#414141' }}>
                            Project Name :
                          </Grid>
                          <Grid item xs={2} className={modules["value-style"]} style={{ fontSize: '12px' }}>
                            {selectedProject.name !== "" ? selectedProject.name : "N/A"}
                          </Grid>
                          <Grid item xs={1} className={modules["label-style"]} style={{ fontWeight: 'bold', fontSize: '12px', color: '#414141' }}>
                            Project Status :
                          </Grid>
                          <Grid item xs={1} className={modules["value-style"]} style={{ fontSize: '12px' }}>
                            {temp.length > 0 ? temp[0].projectstatus : "N/A"}
                          </Grid>
                          <Grid item xs={1.2} className={modules["label-style"]} style={{ fontWeight: 'bold', fontSize: '12px', color: '#414141' }}>
                            Project Manager :
                          </Grid>
                          <Grid item xs={1.8} className={modules["value-style"]} style={{ fontSize: '12px' }}>
                            {temp.length > 0 ? temp[0].projectmanager : "N/A"}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className={modules["instanceAttMap_table-gridPSCD"]}>
                      <div className={modules["ccpm_table-containerIAMPSCD"]} style={{ marginRight: '50px !important', paddingBottom: '3rem' }}>
                        <Table ref={tableRef} className={modules["custom-tablePSCD"]} style={{ width: '98% !important' }}>
                          <TableHead className="table_head">
                            <TableRow>
                              <TableCell className="fixed-width-cell" sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '70px' }}>
                                Task ID
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '200px', justifyContent: 'flex-start' }}>
                                Task Name
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '200px' }}>
                                Department
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                Planned <br />Duration<br /> Day(s)
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                Remaining<br /> Duration <br />Day(s)
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                Planned<br /> StartDate
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                Planned <br />EndDate
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                Expected /<br />Actual <br />StartDate
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                Expected / <br />Actual<br /> EndDate
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                Predecessor
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                Successor
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                Task Status
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                Task<br /> Manager
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                Process <br />Note
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '100px' }}>
                                User<br /> Notes
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {temp.map((row) => (
                              <TableRow key={row.id} className={modules["table-row"]}>
                                <TableCell className={modules["table-cell"]}>{row.taskid}</TableCell>
                                <TableCell className={modules["table-cell"]}>{row.taskname}</TableCell>
                                <TableCell className={modules["table-cell"]}>{row.department}</TableCell>
                                <TableCell className={modules["table-cell"]}> {formatNumber(row.originaltaskduration)}</TableCell>
                                <TableCell className={modules["table-cell"]}> {row.remainingduration}</TableCell>
                                <TableCell className={modules["table-cell"]}>
                                  {formatDate(row.expectedtaskstartdate)}
                                </TableCell>
                                <TableCell className={modules["table-cell"]}>
                                  {formatDate(row.expectedtaskenddate)}
                                </TableCell>
                                <TableCell className={modules["table-cell"]}>
                                  {formatDate(row["Expected/ActualTaskStartDate"])}
                                </TableCell>
                                <TableCell className={modules["table-cell"]}>
                                  {formatDate(row["Expected/ActualTaskEndDate"])}
                                </TableCell>
                                <TableCell className={modules["table-cell"]}>{row.predecessor}</TableCell>
                                <TableCell className={modules["table-cell"]}>{row.successor}</TableCell>
                                <TableCell className={modules["table-cell"]}>{row.taskstatus}</TableCell>
                                <TableCell className={modules["table-cell"]}>{row.taskmanager}</TableCell>
                                <TableCell className={modules["table-cell"]}>{row.taskprocessnotes}</TableCell>
                                <TableCell className={modules["table-cell"]}>{row.usernotes}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </Grid>
                    <span style={{ marginTop: '1rem' }}></span>
                  </>
                )
              )}

            </div>




          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectScheduleCurrentdate;
