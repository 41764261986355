import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import DM1 from "../HelpPopups/images/DM1.png";
import DM2 from "../HelpPopups/images/DM2.png";
import DM3 from "../HelpPopups/images/DM3.png";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";


const DepartmentMasterhelpPopup = () => {

    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem", fontFamily: "'Times New Roman', Times, serif" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline", fontWeight: "bold" }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul>
                            <h3 style={{ marginLeft: "5rem"}}>Department Master
                            </h3>
                            <br />
                            <li>
                                Department type Master – User needs to maintain instance wise Department and its capacity. Already maintained Department and capacity is displayed.
                            </li>
                            <br />
                            <li>PATH : CCPM \ Masters \ Department Masters</li>
                            <br />

                            <br />
                            <li>
                                For Searching any Department and its master data under that instance , select any instance and Department click on search tab. Valid records will be displayed
                            </li>
                            <Stack sx={{ paddingRight: "1rem", width: "35rem" }}>
                                <img src={DM1} alt="Dm1" />
                            </Stack>
                            <br />
                            <br />
                            <br />
                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "35rem" }}>
                                <img src={DM2} alt="Dm2" />
                            </Stack>
                            <br />
                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "35rem" }}>
                                <img src={DM3} alt="Dm3" />
                            </Stack>
                            <br />


                            <li>
                                For adding the new Department masters, Click on “Add” button and enter the text in the available input box and click on “Save” link for saving the record or we can use the “Cancel” link for Canceling the transaction.
                            </li>
                            <br />
                            <li>
                                For searching any data regarding any level, we have to first
                                select the level in the “Master” field and then enter the data
                                to search in the “level named field” text box and click on
                                “Search” button. “Reset” button will be used for clearing the
                                filter criteria and all the data will get displayed.
                            </li>
                            <br />
                            <li style={{ listStyleType: "square", marginLeft: "3rem" }}>
                                Active/Inactive check box is available for selected record of Department .
                            </li>
                            <br />
                            <li>
                                For deleting any individual record, we can use the “Delete” link of the particular record, or we can delete multiple records at once by selecting the records using the check boxes and click on the “Delete” button, selected records will get deleted.
                            </li>
                            <br />
                            <li>
                                For doing any updations to the data, click on Edit link and do the required changes and click on Save link to save the changes or click on Cancel link to cancel the changes done to the data.
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default DepartmentMasterhelpPopup;
