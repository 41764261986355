import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/RT1.jpg";
import IC2 from "../HelpPopups/images/RT2.jpg";
import IC3 from "../HelpPopups/images/RT3.jpg";
import IC4 from "../HelpPopups/images/RT4.jpg";
import { Link } from "react-router-dom";

const RiskTypeMasterHelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link style={{ color: "blue", marginRight: "0.6rem" }}>
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "5rem" }}>Risk Type</h3>
              <br />
              <li>
                Risk Type screen is used for creating and maintaining the
                different types of risks.
              </li>
              <li>PATH : CCPM \ Masters \ Risk Type</li>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC1} alt="IC1" />
              </Stack>
              <li>
                For adding the new Risk type, Click on “Add” button and enter
                the text in the available input box and click on “Save” link for
                saving the record or we can use the “Cancel” link for Canceling
                the transaction.
              </li>
              <br />

              <li>
                For deleting any individual record, we can use the “Delete” link
                of the particular record, or we can delete multiple records at
                once by selecting the records using the check boxes and click on
                the “Delete” button, selected records will get deleted.
              </li>

              <li>
                For doing any updations to the data, click on Edit link and do
                the required changes and click on Save link to save the changes
                or click on Cancel link to cancel the changes done to the data.
              </li>
              <br />
              <li
                style={{ listStyleType: "square", textDecoration: "underline" }}
              >
                Validations on the screen:-
              </li>
              <br />
              <li>
                Duplication of existing record should not be allowed i.e
                re-adding of the existing record again
              </li>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC2} alt="IC2" />
              </Stack>
              <br/>

              <li>
                User should not leave any input field blank before saving the
                data, as click of "Save" link keeping the fields empty makes the
                errror popup window to appear
              </li>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC3} alt="IC3" />
              </Stack>
              <br/>

              <li>
                User should make selection of the data before the click of
                “Delete” button, as without selection of data the popup window
                appears
              </li>

              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IC4} alt="IC4" />
              </Stack>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RiskTypeMasterHelpPopup;
