import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import { Grid, Typography, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../../Landing/Navbar";
import modules from "../ccpm.module.css";
import loading from "../../../Assets/Loading_image.gif";

import SampleSidebar from "../../../Landing/SampleSidebar";
import {
  getCompanyName,
  GetTableDetails,
  UpdateDetail,
  DeleteInstance,
  InseertNewInstance,
} from "../../../Services/Admin";

const InstanceMaster = () => {
  const [searchValue, setSearchValue] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [newsearchValue, setNewSearchValue] = useState(true);
  const [companyname, setCompanyname] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [editing, setEditing] = useState(null);
  const [editedInstanceName, setEditedInstanceName] = useState("");
  const [editedInstanceCode, setEditedInstanceCode] = useState("");
  const [isAddRowVisible, setIsAddRowVisible] = useState(false);
  const [newInstanceName, setNewInstanceName] = useState("");
  const [newInstanceCode, setNewInstanceCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    setIsLoading(true);
    checkLoginAndRedirect();
    getCompName();
    getTableData();
  }, [newsearchValue, deleted]);

  const getCompName = () => {
    getCompanyName()
      .then((response) => {
        // console.log(response[0].companyname);
        setCompanyname(response[0]?.companyname);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTableData = (searchValue) => {
    const body = {
      instanceName: searchValue,
    };
    GetTableDetails(body)
      .then((response) => {
        setFilteredData(response?.data?.success?.data);
        console.log(response?.data?.success?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleSaveEdit(I_id, element) {
    element.style.color = "red";
    console.log(changed, "changed");

    setTimeout(() => {
      element.style.color = "#0000EE";

      setTimeout(() => {
        if (changed === false) {
          alert("No change to Update.");
        } else if (changed === true) {
          let lastModifiedBy = sessionStorage.getItem("loginId");
          const body = {
            instanceId: I_id,
            instanceName: editedInstanceName.trim(),
            instanceCode: editedInstanceCode.trim(),
            modifiedBy: lastModifiedBy,
          };

          if (editedInstanceName) {
            UpdateDetail(body)
              .then((response) => {
                if (response?.data?.statusCode === 200) {
                  alert("Details Updated successfully");
                  setEditing(null);
                  setDeleted(!deleted);
                  setChanged(false);
                } else if (response?.response?.data?.statusCode === 400) {
                  alert(
                    response?.response?.data?.error?.message ||
                      " Please try again."
                  );
                  setChanged(false);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      }, 50);
    }, 50);
  }

  function handleCancelEdit(element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(null);
      element.style.color = "#0000EE";
    }, 50);
  }

  function handleEditSingleEntry(id, InstanceName, InstanceCode, element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(id);
      setEditedInstanceName(InstanceName);
      setEditedInstanceCode(InstanceCode);
      element.style.color = "#0000EE";
    }, 100);
  }

  function handleDeleteSingleEntry(I_id, element) {
    element.style.color = "red";

    setTimeout(() => {
      if (window.confirm("Are you sure you want to delete this record?")) {
        let lastModifiedBy = sessionStorage.getItem("loginId");
        const body = {
          instanceId: I_id,
          modifiedBy: lastModifiedBy,
        };
        DeleteInstance(body)
          .then((response) => {
            alert("Details Deleted successfully");
            setDeleted(!deleted);
            element.style.color = "#0000EE";
          })
          .catch((error) => {
            console.log(error);
            element.style.color = "#0000EE";
          });
      } else {
        setDeleted(!deleted);
        element.style.color = "#0000EE";
        // User clicked Cancel (No)
        // Handle the case where the user cancels the delete action
      }
    }, 100);
  }

  function handleNavigate(Id) {
    // window.location.href = "/admin/RolePermission";
    // window.sessionStorage.setItem("roleid", Id);
  }

  const handleSearch = () => {
    if (searchValue) {
      getTableData(searchValue);
    }
  };

  const handleReset = () => {
    setSearchValue("");
    setNewSearchValue(!newsearchValue);
  };

  function handleInsert() {
    setIsAddRowVisible(true);
  }

  function handleSave(element) {
    element.style.color = "red";

    setTimeout(() => {
      let lastModifiedBy = sessionStorage.getItem("loginId");
      const body = {
        instanceName: newInstanceName.trim(),
        instanceCode: newInstanceCode.trim(),
        createdBy: lastModifiedBy,
      };
      if (newInstanceName.trim() && newInstanceCode.trim()) {
        InseertNewInstance(body)
          .then((response) => {
            element.style.color = "#0000EE";
            setTimeout(() => {
              if (response?.status === 200) {
                alert("Details Inserted successfully");
                setIsAddRowVisible(false);
                setNewInstanceName("");
                setNewInstanceCode("");
                setDeleted(!deleted);
              } else if (response?.response?.data?.statusCode === 400) {
                alert(
                  response?.response?.data?.error?.message ||
                    " Please try again."
                );
              } else {
                alert("An error occurred. Please try again.");
              }
            }, 50);
          })
          .catch((error) => {
            // ;
            // Adjusted to correctly reference the error message based on the provided error object structure
          });
      } else if (
        newInstanceName.trim() === "" &&
        newInstanceCode.trim() === ""
      ) {
        setErrorMessage2("*");
        setErrorMessage1("*");
        element.style.color = "#0000EE";
      } else if (newInstanceName.trim() === "") {
        setErrorMessage1("*");
        element.style.color = "#0000EE";
      } else if (newInstanceCode.trim() === "") {
        setErrorMessage2("*");
        element.style.color = "#0000EE";
      }
    }, 100);
  }

  function handleClose(element) {
    element.style.color = "red";
    setTimeout(() => {
      setIsAddRowVisible(false);
      setNewInstanceName("");
      setNewInstanceCode("");
      element.style.color = "#0000EE";
    }, 50);
    setErrorMessage1("*");
    setErrorMessage2("*");
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          {/* <SideBar /> */}
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"InstanceMaster"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Instance Master</a>
              </div>
            </div>

            <Grid sx={{ marginLeft: "1rem" }}>
              <Grid container className={modules["mid-cont"]}>
                <Stack direction="row">
                  <Typography className={modules["label-style"]}>
                    Company Name:
                  </Typography>
                  <span className={modules["span-text"]}>Godrej</span>
                  {/* <span className={modules["span-text"]}>{companyname}</span> */}
                </Stack>
                <Grid item lg={12}></Grid>
                <br />
                <Grid item lg={6}>
                  <Stack style={{ alignItems: "center" }} direction="row">
                    <Typography className={modules["label-style"]}>
                      Instance Name:
                    </Typography>
                    <span>
                      <input
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        className={modules["input_css"]}
                      />
                    </span>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container className={modules["instance_btn1-cont"]}>
                <Stack direction="row" spacing={2}>
                  <button className={modules["btn1"]} onClick={handleSearch}>
                    Search
                  </button>
                  <button className={modules["btn1"]} onClick={handleReset}>
                    Reset
                  </button>
                </Stack>
              </Grid>

              {!isLoading &&
                (filteredData && filteredData.length > 0 ? (
                  <Grid container className={modules["table-grid"]}>
                    <div className={modules["instance_table-container"]}>
                      <Table className={modules["IM_custom-table"]}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Sr. No.</TableCell>
                            <TableCell>Instance Name</TableCell>
                            <TableCell>Instance Code</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredData.map((item, index) => (
                            <TableRow
                              key={index}
                              className={modules["table-row"]}
                            >
                              <TableCell className={modules["table-cell"]}>
                                <span
                                  style={{
                                    color: "#414141",
                                  }}
                                >
                                  {index + 1}
                                </span>
                              </TableCell>
                              <TableCell className={modules["table-cell"]}>
                                {editing === item.level2id ? (
                                  <input
                                    type="text"
                                    value={editedInstanceName}
                                    className={modules["rowinput_css"]}
                                    onChange={(e) => {
                                      setChanged(false);
                                      const newValue = e.target.value
                                        .toLowerCase()
                                        .trim();
                                      setEditedInstanceName(e.target.value);
                                      if (
                                        newValue !==
                                        item.levelname.toLowerCase().trim()
                                      ) {
                                        setChanged(true);
                                      }
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <span
                                      style={{
                                        cursor: "text",
                                        color: "#414141",
                                      }}
                                      onClick={() =>
                                        handleNavigate(item.level2id)
                                      }
                                    >
                                      {item.levelname}
                                    </span>
                                  </div>
                                )}
                              </TableCell>
                              <TableCell className={modules["table-cell"]}>
                                {editing === item.level2id ? (
                                  <input
                                    type="text"
                                    value={editedInstanceCode}
                                    className={modules["rowinput_css"]}
                                    onChange={(e) => {
                                      setChanged(false);
                                      const newValue = e.target.value
                                        .toLowerCase()
                                        .trim();
                                      setEditedInstanceCode(e.target.value);
                                      if (
                                        newValue !==
                                        item.levelcode.toLowerCase().trim()
                                      ) {
                                        setChanged(true);
                                      }
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <span
                                      style={{ cursor: "text", color: "#414141" }}
                                      onClick={() =>
                                        handleNavigate(item.level2id)
                                      }
                                    >
                                      {item.levelcode}
                                    </span>
                                  </div>
                                )}
                              </TableCell>
                              <TableCell className={modules["table-cell"]}>
                                {editing === item.level2id ? (
                                  <>
                                    <a
                                      style={{
                                        paddingRight: "1rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        handleSaveEdit(
                                          item.level2id,
                                          e.currentTarget
                                        )
                                      }
                                    >
                                      Update
                                    </a>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        handleCancelEdit(e.currentTarget)
                                      }
                                    >
                                      Cancel
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <a
                                      className={modules["actionCol"]}
                                      onClick={(e) =>
                                        handleEditSingleEntry(
                                          item.level2id,
                                          item.levelname,
                                          item.levelcode,
                                          e.currentTarget
                                        )
                                      }
                                    >
                                      Edit
                                    </a>
                                    <a
                                      className={modules["actionCol"]}
                                      onClick={(e) =>
                                        handleDeleteSingleEntry(
                                          item.level2id,
                                          e.currentTarget
                                        )
                                      }
                                    >
                                      Delete
                                    </a>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}

                          {isAddRowVisible && (
                            <TableRow>
                              <TableCell className={modules["table-cell"]}>
                                {/* {filteredData?.length + 1} */}
                              </TableCell>

                              <TableCell className={modules["table-cell"]}>
                                <input
                                  type="text"
                                  value={newInstanceName}
                                  onChange={(e) => {
                                    setNewInstanceName(e.target.value);
                                    setErrorMessage1("");
                                  }}
                                  className={modules["rowinput_css"]}
                                />
                                {errorMessage1 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      position: "relative",
                                      color: "red",
                                      height: "2px",
                                      marginLeft: "3.2rem",
                                      bottom: "3px",
                                    }}
                                  >
                                    {errorMessage1}
                                  </div>
                                )}
                              </TableCell>

                              <TableCell className={modules["table-cell"]}>
                                <input
                                  style={{ border: "1px solid light-dark" }}
                                  type="text"
                                  value={newInstanceCode}
                                  onChange={(e) => {
                                    setNewInstanceCode(e.target.value);
                                    setErrorMessage2("");
                                  }}
                                  className={modules["rowinput_css"]}
                                />
                                {errorMessage2 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      position: "relative",
                                      color: "red",
                                      height: "2px",
                                      marginLeft: "3.2rem",
                                      bottom: "3px",
                                    }}
                                  >
                                    {errorMessage2}
                                  </div>
                                )}
                              </TableCell>

                              <TableCell className={modules["table-cell"]}>
                                <a
                                  style={{
                                    color: "#0000EE",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => handleSave(e.currentTarget)}
                                >
                                  Save
                                </a>

                                <a
                                  style={{
                                    color: "#0000EE",
                                    textDecoration: "underline",
                                    marginLeft: "0.6rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => handleClose(e.currentTarget)}
                                >
                                  Cancel
                                </a>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      <Grid container>
                        <button
                          className={modules["IM_btn"]}
                          onClick={handleInsert}
                        >
                          Add
                        </button>
                      </Grid>
                    </div>
                  </Grid>
                ) : (
                  <>
                    <p style={{ marginLeft: "6rem", fontSize: "12px" }}>
                      No Data Found
                    </p>
                    <Grid container className={modules["mid-cont"]}>
                      <button
                        className={modules["IM_btn"]}
                        onClick={handleInsert}
                      >
                        Add
                      </button>
                    </Grid>
                  </>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InstanceMaster;
