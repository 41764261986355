import React, { useEffect } from "react";
import { Grade } from "@mui/icons-material";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import { useState } from "react";
import Sidebar from "../../../Landing/Navbar";
import bread from "../../../Assets/bread.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import modules from "./MasterLinking.module.css";
import {
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import {
    DS_GetAllInstanceHandler,
    MasterLinkingGetSource,
    MasterLinkingGetLinkTo,
    MasterLinkingGetSourceColumn,
    MasterLinkingSubmitHandler,
    MasterLinkingEditClick,
    MasterLinkingUpdateClick
} from '../../../Services/Dss';
import SampleSidebar from "../../../Landing/SampleSidebar";



const MasterLinking = () => {

    const [newMasterName, setNewMasterName] = useState('')

    const [submitRender, setSubmitRender] = useState(false);
    const [addRows, setAddRows] = useState(false);

    const [newSourceColumnName, setNewSourceColumnName] = useState('select');
    const [newOperator, setNewOperator] = useState('select');
    const [newLinktoColumn, setNewLinkToColumn] = useState('select');
    const [dropOne, setDropOne] = useState({
        value: 'select',
        dropDown: []
    })
    const [dropTwo, setDropTwo] = useState({
        value: 'select',
        dropDown: []
    })
    const [dropThree, setDropThree] = useState({
        value: 'select',
        dropDown: []
    })

    const [dropSourceCol, setDropSourceCol] = useState({
        value: 'select',
        dropDown: [],
        displayvalue:''
    })
    const [dropFour, setDropFour] = useState({
        value: 'select',
        dropDown: []
    })
    const [submitStates, setSubmitStates] = useState({
        tableData: [],
        editState: [],
        updateStates: []

    })
    const [editDropDowns, setEditDropDowns] = useState({
        dataTableOne: [],
        dataTableTwo: [],
        dataTableThree: []
    });
    const [tableData, setTableData] = useState([])

    


    useEffect(() => {
        DS_GetAllInstanceHandler('pritpwc').then(res => {
            setDropOne({
                dropDown: res.data,
                value: res.data[0].instanceuid
            })
            return res;
        })
            .then(res => {
                MasterLinkingGetSource(res.data[0].instanceuid).then(response => {
                    console.log(response)
                    setDropTwo({
                        ...dropTwo,
                        dropDown: response.data.success.data,
                    })
                }).catch(err => {
                    console.log('Error while fetching data:', err);
                })
                // GetMasterDropDownHandler(res);
            }).catch(err => {
                console.log('Error while fetching data:', err);
            })
    }, [])

    function GetSourceDropDownHandler(value) {
        if (value !== 'select') {
            MasterLinkingGetSource(value).then(res => {
                setDropTwo({
                    ...dropTwo,
                    dropDown: res.data.success.data
                })
            }).catch(err => {
                console.log('Error while fetching data:', err);
            })
        }
    }

    function GetLinkToDropdownHandler(value) {
        if (value !== 'select') {
            MasterLinkingGetLinkTo(dropOne.value, value).then(res => {
                console.log(res)
                setDropThree({
                    ...dropThree,
                    dropDown: res.data.success.data
                })
            }).catch(err => {
                console.log('Error while fetching data:', err);
            })
        }
    }

    function GetSourceColDropdownHandler(value) {
        if (value !== 'select') {
            MasterLinkingGetSourceColumn(dropOne.value, value).then(res => {
                console.log(res)
                setDropSourceCol({
                    ...dropSourceCol,
                    dropDown: res.data.success.data
                })
            }).catch(err => {
                console.log('Error while fetching data:', err);
            })
        }
    }

    function AddRows(){
        setAddRows(true)
    }

    function SubmitHandler() {
        dropOne.value === 'select' ?
            alert('Select Instance uid') :
            dropTwo.value === 'select' ?
                alert('Select Source') :
                dropThree.value === 'select' ?
                    alert('Select Link to') :
                    dropSourceCol.value === 'select' ?
                        alert('Select Source Column') :
                        (() => {
                            MasterLinkingSubmitHandler(dropOne.value, dropTwo.value, dropThree.value, dropSourceCol.value).then(res => {
                                console.log(res)
                                if (res.status === 200) {
                                    setSubmitRender(true);
                                    setSubmitStates({
                                        ...submitStates,
                                        tableData: res.data.success.data[0],
                                        editState: res.data.success.data[0].map(i => { return false }),
                                        updateStates: res.data.success.data[0].map((i) => {
                                            return {
                                                recordId: i.record_id,
                                                SourceTableName: i.mastertable,
                                                LinkToTableName: i.linkedtomastertable,
                                                SourceTableColumnNameValue: i.mastercolumn,
                                                SourceTableColumnNameDisplayValue: i.mastercolumndisplayname,
                                                OperatorValue: i.linkingoperator,
                                                OperatorDisplayValue: i.linkingoperatordisplayvalue,
                                                LinkToColumnValue: i.linkedtomastercolumn,
                                                LinkToColumnDsiplayValue: i.linkedtomastercolumndisplayname
                                            }
                                        })
                                    });
                                    GetTableDropDownHandler();
                                    setTableData(res.data.TableName)
                                }
                            }).catch(err => {
                                console.log('Error while fetching data:', err);
                            })
                        })()
    }

    function GetTableDropDownHandler() {

        let body = {
            instanceUId: dropOne.value.toString(),
            sourceName: dropTwo.value.toString(),
            linkto: dropThree.value.toString(),
            sourceCol: dropSourceCol.value.toString()
        }

        MasterLinkingEditClick(body).then(res => {
            console.log(res)

            if (res.status === 200) {
                setEditDropDowns({
                    ...editDropDowns,
                    dataTableOne: res.data.success.data['SourceColumn_attributeDisplayName'],
                    dataTableTwo: res.data.success.data['displayValue'],
                    dataTableThree: res.data.success.data['LinkToColumn_attributeDisplayName']
                })
            }
        }).catch(err => {
            console.log('Error while fetching data:', err);
        })
    }

    function UpdateRowHandler(index) {
                submitStates.updateStates[index].OperatorValue === 'select' ?
                    alert('Cannot insert blank data') :
                    (submitStates.updateStates[index].LinkToColumnValue === 'select') ?
                        alert('Cannot insert blank data') :
                            (() => {
                                const body = {        
                                        instanceUID: dropOne.value.toString(),
                                        recordId: submitStates.updateStates[index].recordId,
                                        sourceName: dropTwo.value.toString(),
                                        sourceColName: dropSourceCol.value.toString(),
                                        linkingoperator: submitStates.updateStates[index].OperatorValue,
                                        linktoName: dropThree.value.toString(),
                                        linkedtoMasterColumn: submitStates.updateStates[index].LinkToColumnValue,
                                        userId: "pritpwc",
                                        hostName: "Host",
                                        ipAddress: "IP"
                                }
                                MasterLinkingUpdateClick(body).then(res => {
                                    console.log(res)
                                    if (res.status === 200) {
                                        alert(res.data.success.message);
                                        SubmitHandler();
                                    }

                                    if(res?.response?.status===400){
                                        alert(res.response.data.error.message)
                                    }
                                }).catch(err => {
                                    console.log('Error while fetching data:', err)
                                })
                            })()


    }

    function AddRowHandler(){
        newMasterName==='select' ? alert('Cannot insert blank data') :
        newOperator==='select' ? alert('Cannot insert blank data') :
        newLinktoColumn==='select' ? alert('Cannot insert blank data'):
        (() => {
            const body = {        
                    instanceUID: dropOne.value.toString(),
                    recordId: '',
                    sourceName: dropTwo.value.toString(),
                    sourceColName: dropSourceCol.value.toString(),
                    linkingoperator: newOperator,
                    linktoName: dropThree.value.toString(),
                    linkedtoMasterColumn: newLinktoColumn,
                    userId: "pritpwc",
                    hostName: "Host",
                    ipAddress: "IP"
            }
            MasterLinkingUpdateClick(body).then(res => {
                console.log(res)
                if (res.status === 200) {
                    alert(res.data.success.message);
                    setNewLinkToColumn('select')
                    setNewOperator('select')
                    setNewMasterName('select')
                    setAddRows(false)
                    SubmitHandler();
                    
                }
                if(res?.response?.status===400){
                    alert(res.response.data.error.message)
                }
            }).catch(err => {
                console.log('Error while fetching data:', err)
            })
        })()
        

        

    }

    function CancelHandler(){
        setAddRows(false)
        setNewMasterName('select')
        setNewOperator('select')
        setNewLinkToColumn('select')
    }

    function InstanceChangeHandler(value) {
        setDropOne({
            ...dropOne,
            value: value
        });
        GetSourceDropDownHandler(value);
    }

    function SourceChangeHandler(value) {
        setDropTwo({
            ...dropTwo,
            value: [value]
        })
        GetLinkToDropdownHandler([value])
    }

    function LinkChangeHandler(value) {
        setDropThree({
            ...dropThree,
            value: [value]
        })
        GetSourceColDropdownHandler([value])
    }


    function ArrowLine() {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div
                    style={{
                        width: "2px",
                        height: "30px",
                        backgroundColor: "grey",
                        marginRight: "2px",
                    }}
                />
                <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
            </div>
        );
    }

    function UpdatedValuesHandler(index, key, e) {
        setSubmitStates((prevSubmitStates) => {
            const updatedUpdateStates = prevSubmitStates.updateStates.map((item, idx) => {
                return index === idx ? { ...item, [key]: e.target.value } : item;
            });

            return {
                ...prevSubmitStates,
                updateStates: updatedUpdateStates,
            };
        });
    }

    return (
        <>
            <Grid >
                <Grid
                    item
                    lg={0.6}
                    sx={{ position: "fixed", zIndex: 2000 }}
                    className="main-cont"
                >
                    {/* <Sidebar /> */}
                    <SampleSidebar/>
                </Grid>
                <Grid item lg={11.4} sx={{ display: "block" }}>
                    <Grid item lg={12} className="home-cont">
                        <Home />
                    </Grid>
                </Grid>

                <Grid item lg={9} className={modules["masterdefinition_div"]}>
                    <div className="pro-bread" style={{ marginLeft: "5rem" }}>
                        <p>
                            <a>DSS Masters</a>
                            <img src={bread} alt="" />
                            <a>Master Linking</a>
                        </p>
                    </div>
                </Grid>

                {/* Dropdown */}
                <Grid container sx={{ marginLeft: "5rem", marginTop: "2rem" }} >
                    <table className={modules["topTable"]}>
                        <tbody>
                            <tr>
                                <td>DSS Instance</td>
                                <td> : </td>
                                <td>
                                    <FormControl className={modules["form-control"]}>
                                        <Select
                                            IconComponent={ArrowLine}
                                            className={modules["dropdownSelect"]}
                                            value={dropOne.value}
                                            onChange={(e) => InstanceChangeHandler(e.target.value)}
                                        >
                                            <MenuItem value='select' sx={{ display: 'none' }}>Select</MenuItem>
                                            {dropOne?.dropDown?.map((i) => {
                                                return (
                                                    <MenuItem value={i.instanceuid} key={i.instanceuid}>
                                                        {i.instanceuid}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>{" "}
                                </td>
                            </tr>

                            <tr>
                                <td>Source Table</td>
                                <td> : </td>
                                <td>
                                    <FormControl className={modules["form-control"]}>
                                        <Select
                                            IconComponent={ArrowLine}
                                            className={modules["dropdownSelect"]}
                                            value={dropTwo.value}
                                            onChange={(e) => SourceChangeHandler(e.target.value)}
                                        >
                                            <MenuItem value='select' sx={{ display: 'none' }}>Select</MenuItem>
                                            {dropTwo?.dropDown?.map((i) => {
                                                return (
                                                    <MenuItem value={i.tablename} key={i.tablename}>
                                                        {i.masterdisplayname}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </td>

                            </tr>

                            <tr>
                                <td>Link To</td>
                                <td> : </td>
                                <td>
                                    <FormControl className={modules["form-control"]}>
                                        <Select
                                            IconComponent={ArrowLine}
                                            className={modules["dropdownSelect"]}
                                            value={dropThree.value}
                                            onChange={(e) => LinkChangeHandler(e.target.value)}
                                        >
                                            <MenuItem value='select' sx={{ display: 'none' }}>Select</MenuItem>
                                            {dropThree.dropDown.map((i) => {
                                                return (
                                                    <MenuItem value={i.tablename} key={i.tablename}>
                                                        {i.masterdisplayname}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr>
                                <td>Source Column</td>
                                <td> : </td>
                                <td>
                                    <FormControl className={modules["form-control"]}>
                                        <Select
                                            IconComponent={ArrowLine}
                                            className={modules["dropdownSelect"]}
                                            value={dropSourceCol.value}
                                            onChange={(e) => 

                                                setDropSourceCol({
                                                ...dropSourceCol,
                                                value: e.target.value,
                                               
                                            })}
                                        >
                                            <MenuItem value='select' sx={{ display: 'none' }}>Select</MenuItem>
                                            {dropSourceCol?.dropDown?.map((i) => {
                                                return (
                                                    <MenuItem value={i.attributecolumnname} key={i.attributecolumnname}>
                                                        {i.attributedisplayname}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
                <div>
                    <button style={{ marginLeft: "18rem", marginTop: "1rem", padding: "0.5cm" }} type="button" className={modules["btn1"]} onClick={() => SubmitHandler()}>
                        Submit
                    </button>
                </div>

                {(submitRender)&&(
                    <div>
                    <button style={{ marginLeft: "7rem", marginTop: "1rem", padding: "0.5cm" }} type="button" className={modules["btn1"]} onClick={() => AddRows()}>
                        Add Rows
                    </button>
                </div>

                )}

                

            </Grid>
            {
                submitRender && (
                    <Grid sx={{ marginLeft: "5rem", overflowX: 'scroll' }} >
                        <table className={modules['btmTable']}>
                            <thead>

                                <th>Source Table <br />{dropTwo.value}</th>
                                <th>Operator</th>
                                <th>Link To  <br />{dropThree.value}</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {
                                    submitStates?.tableData?.map((i, index) => {
                                        return (
                                            <tr>
                                                <td>{!submitStates.editState[index] ? i.mastercolumndisplayname :
                                                    <FormControl className={modules["form-control1"]}>
                                                        <Select
                                                            IconComponent={ArrowLine}
                                                            className={modules["dropdownSelect2"]}
                                                            value={submitStates.updateStates[index].SourceTableColumnNameValue}
                                                            onChange={(e) => UpdatedValuesHandler(index, 'SourceTableColumnNameValue', e)}
                                                        >
                                                            <MenuItem value={submitStates.updateStates[index].SourceTableColumnNameValue}>{submitStates.updateStates[index].SourceTableColumnNameDisplayValue}</MenuItem>
                                                          
                                                        </Select>
                                                    </FormControl>}
                                                </td>
                                                <td>{!submitStates.editState[index] ? i.linkingoperatordisplayvalue :
                                                    <FormControl className={modules["form-control1"]}>
                                                        <Select
                                                            IconComponent={ArrowLine}
                                                            className={modules["dropdownSelect2"]}
                                                            value={submitStates.updateStates[index].OperatorValue}
                                                            onChange={(e) => UpdatedValuesHandler(index, 'OperatorValue', e)}
                                                        >
                                                            <MenuItem value='select' >Select</MenuItem>
                                                            {editDropDowns?.dataTableTwo?.map((i) => {
                                                                return (
                                                                    <MenuItem value={i.columnvalue} key={i.columnvalue}>
                                                                        {i.displayvalue}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>}
                                                </td>
                                                <td>{!submitStates.editState[index] ? i.linkedtomastercolumndisplayname :
                                                    <FormControl className={modules["form-control1"]}>
                                                        <Select
                                                            IconComponent={ArrowLine}
                                                            className={modules["dropdownSelect2"]}
                                                            value={submitStates.updateStates[index].LinkToColumnValue}
                                                            onChange={(e) => UpdatedValuesHandler(index, 'LinkToColumnValue', e)}
                                                        >
                                                            
                                                            <MenuItem value='select' >Select</MenuItem>
                                                            {editDropDowns?.dataTableThree?.map((i) => {
                                                                return (
                                                                    <MenuItem value={i.attributecolumnname} key={i.attributecolumnname}>
                                                                        {i.attributedisplayname}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>}
                                                </td>
                                                <td>{
                                                    !submitStates.editState[index] ? <a onClick={() => {
                                                        const updatedEditState = [...submitStates.editState];
                                                        updatedEditState.splice(index, 1, !submitStates.editState[index]);
                                                        setSubmitStates({
                                                            ...submitStates,
                                                            editState: updatedEditState
                                                        })
                                                    }}>Edit</a> : <>
                                                        <a onClick={() => UpdateRowHandler(index)}>Update</a><br />
                                                        <a onClick={() => {
                                                            const updatedEditState = [...submitStates.editState];
                                                            updatedEditState.splice(index, 1, !submitStates.editState[index]);
                                                            setSubmitStates({
                                                                ...submitStates,
                                                                editState: updatedEditState
                                                            })
                                                        }}>Cancel</a>
                                                    </>
                                                }</td>
                                            </tr>
                                            
                                        )    
                                    }
                                    )
                                    
                                }
                                {
                                    (addRows)&&(
                                        <tr>
                                            <td>
                        
                                            <FormControl className={modules["form-control1"]}>
                                                        <Select
                                                            IconComponent={ArrowLine}
                                                            className={modules["dropdownSelect2"]}
                                                            value={dropSourceCol.value}
                                                            onChange={(e) => setNewSourceColumnName(e.target.value)}
                                                        >
                                                            <MenuItem value='select' >Select</MenuItem>
                                                            
                                                            {editDropDowns?.dataTableOne?.filter((i) => {
                                                                return(

                                                                    dropSourceCol.value===i.attributecolumnname?true:false
                                                                )
                                                                

                                                            }).map((i) => {
                                                                return (
                                                                    <MenuItem value={i.attributecolumnname} key={i.attributecolumnname}>
                                                                        {i.attributedisplayname}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                            </td>
                                            <td>
                                            <FormControl className={modules["form-control1"]}>
                                                        <Select
                                                            IconComponent={ArrowLine}
                                                            className={modules["dropdownSelect2"]}
                                                            value={newOperator}
                                                            onChange={(e) => setNewOperator(e.target.value)}
                                                        >
                                                            <MenuItem value='select' >Select</MenuItem>
                                                            {editDropDowns?.dataTableTwo?.map((i) => {
                                                                return (
                                                                    <MenuItem value={i.columnvalue} key={i.columnvalue}>
                                                                        {i.displayvalue}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                            </td>
                                            <td>
                                            <FormControl className={modules["form-control1"]}>
                                                        <Select
                                                            IconComponent={ArrowLine}
                                                            className={modules["dropdownSelect2"]}
                                                            value={newLinktoColumn}
                                                            onChange={(e) => setNewLinkToColumn(e.target.value)}
                                                        >
                                                            
                                                            <MenuItem value='select' >Select</MenuItem>
                                                            {editDropDowns?.dataTableThree?.map((i) => {
                                                                return (
                                                                    <MenuItem value={i.attributecolumnname} key={i.attributecolumnname}>
                                                                        {i.attributedisplayname}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                            </td>
                                            <td>
                                            {
                                            <>
                                                        <a onClick={() => AddRowHandler()}>Add</a><br />
                                                        <a onClick={() => CancelHandler()}>Cancel</a>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                                
                            </tbody>
                        </table>
                    </Grid>
                )
            }
        </>
    );
};

export default MasterLinking;
