import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SWC1 from "../HelpPopups/images/SWC1.png";
import SWC2 from "../HelpPopups/images/SWC2.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointWarehouseConsumptionhelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{ width: "120px" }} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{ fontSize: "14px" }}>
              <h3
                style={{
                  //   marginLeft: "5rem",
                  //   textAlign: "center",
                  //   textDecoration: "underline",
                  fontWeight: "bold",
                  //   fontStyle: "italic",
                  fontSize: "26px",
                }}
              >
                STOCK POINT WAREHOUSE CONSUMPTION
              </h3>
              <br />
              <br />
              <li
                className="no-bullets"
                style={{
                  textDecoration: "underline",
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                Session Path `{">>"}` MTA `{">>"}` MTA Parameter `{">>"}` Stock
                Point Warehouse Consumption
              </li>
              <br />
              <li className="no-bullets">
                This process is used to define from which warehouse inventory is
                to be consumed for a Stock point.
              </li>
              <br />
              <br />

              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SWC1} alt="SWC1" />
              </Stack>
              <br />
              <li>Select MTA Instance</li>
              <li>Action to be taken is</li>
              <ul>
                <li>Add new Record – For addition of the new record</li>
                <li>Update Record – User can update record.</li>
                <li>
                  Delete Record – User Can delete stock point warehouse from
                  here.
                </li>
              </ul>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={SWC2} alt="SWC2" />
              </Stack>
              <br />
              <li className="no-bullets">To add - select Add record</li>
              <li>
                Stock Point – This field indicate stock point. Select Stock
                point name from Pop-up available for the field.
              </li>
              <li>
                Company code – This field indicates related company code. Add
                company code in this field. This should be maintained in the
                input master field.
              </li>
              <li>
                Warehouse Code – This field indicates warehouse code. Add
                warehouse code in this field. This should be mainlined in the
                input master file.
              </li>
              <li>After inputting values click submit to add record.</li>
              <br/>
              <br/>
              <br/>
              <br/>
              <li
                className="no-bullets"
                style={{
                  fontSize: "16px",
                  textDecoration: "underline",
                  backgroundColor: "yellow",
                }}
              >
                {" "}
                This session in currently not used by any division.
              </li>
              <br />
              <br />
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StockPointWarehouseConsumptionhelpPopup;
