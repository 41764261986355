import React, { useEffect, useState } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import modules from "./FeverChartMaster.module.css";
// import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import loading from "../../../Assets/Loading_image.gif";

import {
  FCM_UpdData,
  FCM_DelData,
  FCM_BulkDelData,
  FCM_GetGrid,
  DM_InstanceNames,
  FCM_LevelNames,
  FCM_GetDependency,
  FCM_GetLevel3,
  FCM_GetLevel4,
  FCM_GetLevel5,
  FCM_GetLevel6,
  FCM_GetLevel7,
  FCM_GetLevel8,
  FCM_GetLevel9,
  FCM_GetLevel10,
  FCM_GetLevel11,

} from "../../../Services/CCPM";

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const FeverChartMaster = () => {
  const [dropdownData, setDropdownData] = useState([]);
  const [displayvalue, setDisplayValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [editingText, setEditingText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [originalData, setOriginalData] = useState(null);
  const [editingData, setEditingData] = useState({
    safeStartPoint: "",
    safeEndPoint: "",
    warningStartPoint: "",
    warningEndPoint: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [safeStart, setSafeStart] = useState();
  const [safeEnd, setSafeEnd] = useState();
  const [warningStart, setWarningStart] = useState();
  const [warningEnd, setWarningEnd] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [tableAppear, setTableAppear] = useState(false);
  const [levelNames, setLevelNames] = useState([]);
  const [formData, setFormData] = useState({});
  const [finalLevels, setFinalLevels] = useState([]);


  useEffect(() => {
    FCM_LevelNames().then((response) => {
      if (response.status === 200) {
        const apiData = response.data.success.data;
        // Filter out "Instance" from the levelNames array as it's handled separately
        const filteredLevelNames = apiData.filter((item) => item.levelno !== 2);
        setLevelNames(filteredLevelNames);
      }
    });
  }, []);

  useEffect(() => {
    const fetchDependencyData = async () => {
      try {
        const response = await FCM_GetDependency();
        if (response.status === 200) {
          const { levelno } = response.data.success.data[0];
          const filteredLevels = levelNames.filter((level) => level.levelno <= levelno);
          setFinalLevels(filteredLevels);
          console.log("Final Levels:", filteredLevels);
        }
      } catch (error) {
        console.error("Error fetching dependency data:", error);
      }
    };

    fetchDependencyData();
  }, [levelNames]);










  const handleInputChange = (value, setValue, fieldName) => {
    let newValue = Number(value);
    let newSValue = String(value);


    if (newValue > 100) {
      newValue = 100;
      setValue(newValue);
      return;
    }

    if (newSValue.startsWith("0") && newSValue.length > 1) {
      newSValue = newSValue.slice(1);
      setValue(newSValue);
      return;
    }

    setValue(value);

    if (newValue < 0) {
      setErrorMessage((prevState) => ({
        ...prevState,
        [fieldName]: "accept only numbers between  0 to 100",
      }));
    } else {
      setErrorMessage((prevState) => ({
        ...prevState,
        [fieldName]: "",
      }));
    }
    console.log("Input change:", newValue);
  };

  // const handleInputChange2 = (value, setState, fieldName) => {
  //   // Remove leading zero if present
  //   if (value.startsWith("0") && value.length > 1) {
  //     value = value.slice(1);
  //   }
  //   setState(value);
  // };

  // Reset function
  const handleReset = () => {
    setSafeStart("");
    setSafeEnd("");
    setWarningStart("");
    setWarningEnd("");
    setErrorMessage("");
    setDisplayValue(dropdownData[0]);
  };

  // Log the state changes
  useEffect(() => {
    setIsLoading(true);
    // console.log("Safe Start:", safeStart);
  }, []);

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    checkLoginAndRedirect();
    let body = {
      userId: sessionStorage.getItem("loginId"),
    };

    DM_InstanceNames(body)
      .then((response) => {
        if (response.status === 200) {
          const apiData = response.data;
          setIsLoading(false);
          const data = apiData.success.data;
          const names = data.map((item) => item.name);
          setDropdownData(names);
          setDisplayValue(names[0]);
        } else {
          console.log("Error fetching data:", response.statusText);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    FCM_GetGrid()
      .then((response) => {
        if (response.status === 200 && response.data.success.data>0) {
          
          setTableAppear(true);
          const { data } = response;
          if (data.success && Array.isArray(data.success.data)) {
            setFilteredData(

              data.success.data.map((item) => ({
                id: item.id,
                safeStartPoint: item.safestartpoint,
                safeEndPoint: item.safeendpoint,
                warningStartPoint: item.warningstartpoint,
                warningEndPoint: item.warningendpoint,
                levelName: item.levelname,
              }))
            );
          } else {
            console.error("Unexpected data format:", data);
          }
        } else {
          console.error("API Error:", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
      });
  }, []);

  const validateInputs = () => {
    const { safeStartPoint, safeEndPoint, warningStartPoint, warningEndPoint } =
      editingData;

    if (parseFloat(warningStartPoint) < parseFloat(safeStartPoint)) {
      alert("Warning Start Point cannot be less than Safe Start Point.");
      return false;
    }

    if (parseFloat(warningEndPoint) < parseFloat(safeEndPoint)) {
      alert("Warning End Point cannot be less than Safe End Point.");
      return false;
    }

    if (
      parseFloat(safeStartPoint) < 0 ||
      parseFloat(safeStartPoint) > 100 ||
      parseFloat(safeEndPoint) < 0 ||
      parseFloat(safeEndPoint) > 100 ||
      parseFloat(warningStartPoint) < 0 ||
      parseFloat(warningStartPoint) > 100 ||
      parseFloat(warningEndPoint) < 0 ||
      parseFloat(warningEndPoint) > 100
    ) {
      alert(
        "Please maintain!! Enter the points values 0 to 100 && Warning Start point 0 to 99."
      );
      return false;
    }

    return true;
  };

  const handleEdit = (item, element) => {
    element.style.color = "red";
    setTimeout(() => {
      setEditingRow(item.id);
      setEditingData({
        safeStartPoint: parseFloat(item.safeStartPoint).toFixed(2),
        safeEndPoint: parseFloat(item.safeEndPoint).toFixed(2),
        warningStartPoint: parseFloat(item.warningStartPoint).toFixed(2),
        warningEndPoint: parseFloat(item.warningEndPoint).toFixed(2),
      });

      setOriginalData({
        safeStartPoint: item.safeStartPoint,
        safeEndPoint: item.safeEndPoint,
        warningStartPoint: item.warningStartPoint,
        warningEndPoint: item.warningEndPoint,
      });
      element.style.color = "#0000EE";
    }, 100);
  };

  const handleUpdate = (id, element) => {
    element.style.color = "red";

    setTimeout(() => {
      element.style.color = "#0000EE";

      setTimeout(() => {
        // Validate if editingData values are between 0 and 99
        const isValidRange = (value) => value >= 0 && value <= 99;
        const { safeStartPoint, safeEndPoint, warningStartPoint, warningEndPoint } = editingData;

        if (
          !isValidRange(safeStartPoint) ||
          !isValidRange(safeEndPoint) ||
          !isValidRange(warningStartPoint) ||
          !isValidRange(warningEndPoint)
        ) {
          alert("Please maintain!! Enter the points values 0 to 100 && Warning Start point 0 to 99");
          return;
        }

        if (!validateInputs()) {
          return;
        }
        const isUpdated =
          safeStartPoint !== originalData.safeStartPoint ||
          safeEndPoint !== originalData.safeEndPoint ||
          warningStartPoint !== originalData.warningStartPoint ||
          warningEndPoint !== originalData.warningEndPoint;

        if (!isUpdated) {
          alert("No Change to Update.");
          element.style.color = "#0000EE";
          handleCancel();
          return; // Ensure the function exits here
        }

        const updatedItem = {
          id,
          safeStartPoint,
          safeEndPoint,
          warningStartPoint,
          warningEndPoint,
          createdBy: sessionStorage.getItem("loginId"), // Add createdBy to the payload
        };

        FCM_UpdData(updatedItem)
          .then((response) => {
            alert(
              "Record Updated Successfully. Change in Project Colour Status will be applicable on next BM Run."
            );

            const updatedData = filteredData.map((item) =>
              item.id === id ? { ...item, ...updatedItem } : item
            );
            setFilteredData(updatedData);
            setEditingRow(null);
            setEditingData({
              safeStartPoint: "",
              safeEndPoint: "",
              warningStartPoint: "",
              warningEndPoint: "",
            });
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });

        element.style.color = "#0000EE";
      }, 50);
    }, 100);
  };

  const handleCancel = (element) => {
    if (element) {
      element.style.color = "red";

      setTimeout(() => {
        setEditingRow(null);
        setEditingData({
          safeStartPoint: "",
          safeEndPoint: "",
          warningStartPoint: "",
          warningEndPoint: "",
        });
        element.style.color = "#0000EE";
      }, 100);
    } else {
      console.error("Element is undefined, cannot set style.");
    }
  };
  const handleDelete = (item, element) => {
    // Change color to yellow
    element.style.color = "red";

    // Force rendering before showing confirm dialog
    setTimeout(() => {
      const confirmDelete = window.confirm(`Are you sure you want to delete?`);
      checkLoginAndRedirect();
      const loginId = sessionStorage.getItem("loginId");

      if (confirmDelete) {
        const body = {
          id: item.id,
          createdBy: loginId,
        };
        FCM_DelData(body)
          .then(() => {
            const updatedData = filteredData.filter(
              (dataItem) => dataItem.id !== item.id
            );
            setFilteredData(updatedData);
            element.style.color = "#0000EE";
          })
          .catch((error) => {
            element.style.color = "#0000EE";
            console.error("Error deleting data:", error);
          });
      } else {
        element.style.color = "#0000EE";
      }
    }, 50); // Execute after current call stack is clear
  };

  const handleBulkDelete = () => {
    checkLoginAndRedirect();
    const loginId = sessionStorage.getItem("loginId");

    if (!loginId) {
      console.error("User not logged in.");
      return;
    }

    const body = selectedRows.map((id) => ({
      id,
      createdBy: loginId,
    }));

    FCM_BulkDelData(body)
      .then(() => {
        const updatedData = filteredData.filter(
          (item) => !selectedRows.includes(item.id)
        );
        setFilteredData(updatedData);
        setSelectedRows([]);
      })
      .catch((error) => {
        console.error("Error deleting bulk data:", error);
      });
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const errorStyle = {
    color: "darkorange",
    fontSize: "12px",
    margin: "2px",
    alignSelf: "center",
  };




  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"FeverChartMaster"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Fever Chart Co-Ordinate</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <Grid item lg={12}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "10px",
                    width: "40%",
                    display: "inline",
                    fontFamily: "worksans-regular,sans-serif",
                    marginLeft: "20px",
                  }}
                >
                  Note: Changing Fever Chart coordinates for a level will start
                  Buffer Management for all the projects in that level. This may
                  require longer time depending upon number of projects in that
                  level.
                </Typography>
              </Grid>

              <Grid
                container
                spacing={2} // Adds spacing between items
                sx={{ display: "flex", marginTop: "2rem", marginLeft: "20px" }}
              >
                 <Grid container lg={12} sx={{ marginTop: "16px" }}>
                {/* Instance Dropdown - Takes up 12 columns on small screens and 6 on larger screens */}
                <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center",paddingLeft:'0' }}>
                  <span style={{ minWidth: "80px" }}>Instance:</span>
                  <div style={{ paddingLeft: "5.2rem" }}>
                    <select
                      className={modules["dropdownSelect"]}
                      style={{ width: "140%", maxWidth: "20rem", cursor: "default" }}
                      value={displayvalue}
                      onChange={(e) => setDisplayValue(e.target.value)}
                      IconComponent={ArrowDropDownIcon}
                    >
                      {dropdownData.map((i) => (
                        <option value={i} key={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>

                {/* First Dynamic Dropdown in the same row as Instance */}
                {finalLevels.length > 0 && (
                  <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center",paddingLeft:'0' }}>
                    <span style={{ minWidth: "80px" }}>{levelNames[0].levelname}:</span>
                    <div style={{ paddingLeft: "5.2rem" }}>
                      <select
                        className={modules["dropdownSelect"]}
                        style={{ width: "100%", maxWidth: "10rem", cursor: "default" }}
                        value={displayvalue}
                        onChange={(e) => setDisplayValue(e.target.value)}
                        IconComponent={ArrowDropDownIcon}
                      >
                        {dropdownData.map((i) => (
                          <option value={i} key={i}>
                            {i}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                 
                )}
                 </Grid>

                {/* Render the remaining levelNames starting from the second item */}
                {finalLevels
                  .slice(1) // Start from the second item
                  .reduce((rows, item, index, filteredArray) => {
                    if (index % 2 === 0) {
                      rows.push(filteredArray.slice(index, index + 2));
                    }
                    return rows;
                  }, [])
                  .map((pair, rowIndex) => (
                    <Grid container key={rowIndex} lg={12} sx={{ marginTop: "16px" }}>
                      {/* First Dropdown in the row */}
                      <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                        <span style={{ minWidth: "80px" }}>{pair[0].levelname}:</span>
                        <div style={{ paddingLeft: "5.2rem" }}>
                          <select
                            className={modules["dropdownSelect"]}
                            style={{ width: "100%", maxWidth: "10rem", cursor: "default" }}
                            value={displayvalue}
                            onChange={(e) => setDisplayValue(e.target.value)}
                            IconComponent={ArrowDropDownIcon}
                          >
                            {dropdownData.map((i) => (
                              <option value={i} key={i}>
                                {i}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Grid>

                      {/* Second Dropdown in the row, if it exists */}
                      {pair[1] && (
                        <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                          <span style={{ minWidth: "80px" }}>{pair[1].levelname}:</span>
                          <div style={{ paddingLeft: "5.2rem" }}>
                            <select
                              className={modules["dropdownSelect"]}
                              style={{ width: "100%", maxWidth: "10rem", cursor: "default" }}
                              value={displayvalue}
                              onChange={(e) => setDisplayValue(e.target.value)}
                              IconComponent={ArrowDropDownIcon}
                            >
                              {dropdownData.map((i) => (
                                <option value={i} key={i}>
                                  {i}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  ))}
              </Grid>



              <Grid
                container
                lg={12}
                sx={{
                  marginTop: "16px",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              // className={modules["mid-cont"]}
              >
                <span style={{ minWidth: "80px" }}>Safe Start Point:</span>
                <FormControl
                  className={modules["form-control"]}
                  style={{
                    paddingLeft: "2rem",
                    position: "relative",
                    marginLeft: "1.8rem",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <input
                      className={modules["dropdownSelect"]}
                      style={{
                        borderRadius: "10px",
                        height: "23px",
                        paddingLeft: "0.4rem",
                        marginLeft: "12px",
                        cursor: "text",
                      }}
                      type="number"
                      value={safeStart}
                      onChange={(e) => {
                        handleInputChange(
                          e.target.value,
                          setSafeStart,
                          "safeStart"
                        );
                      }}
                    />
                  </span>
                </FormControl>
                {errorMessage.safeStart && (
                  <span style={errorStyle}>{errorMessage.safeStart}</span>
                )}
              </Grid>

              <Grid
                container
                lg={12}
                sx={{
                  marginTop: "16px",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              // className={modules["mid-cont"]}
              >
                <span style={{ minWidth: "80px" }}>Safe End Point:</span>
                <FormControl
                  className={modules["form-control"]}
                  style={{
                    paddingLeft: "2rem",
                    position: "relative",
                    marginLeft: "2.2rem",
                    cursor: "text",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <input
                      className={modules["dropdownSelect"]}
                      style={{
                        borderRadius: "10px",
                        height: "23px",
                        paddingLeft: "0.4rem",
                        marginLeft: "12px",
                        cursor: "text",
                      }}
                      type="number"
                      value={safeEnd}
                      onChange={(e) =>
                        handleInputChange(e.target.value, setSafeEnd, "safeEnd")
                      }
                    />
                  </span>
                </FormControl>
                {errorMessage.safeEnd && (
                  <span style={errorStyle}>{errorMessage.safeEnd}</span>
                )}
              </Grid>

              <Grid
                container
                lg={12}
                sx={{
                  marginTop: "16px",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              // className={modules["mid-cont"]}
              >
                <span style={{ minWidth: "80px" }}>Warning Start Point:</span>
                <FormControl
                  className={modules["form-control"]}
                  style={{
                    paddingLeft: "2rem",
                    position: "relative",
                    marginLeft: "0.40rem",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <input
                      className={modules["dropdownSelect"]}
                      style={{
                        borderRadius: "10px",
                        height: "23px",
                        paddingLeft: "0.4rem",
                        marginLeft: "12px",
                        cursor: "text",
                      }}
                      type="number"
                      value={warningStart}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          setWarningStart,
                          "warningStart"
                        )
                      }
                    />
                  </span>
                </FormControl>
                {errorMessage.warningStart && (
                  <span style={errorStyle}>{errorMessage.warningStart}</span>
                )}
              </Grid>

              <Grid
                container
                lg={12}
                sx={{
                  marginTop: "16px",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              // className={modules["mid-cont"]}
              >
                <span style={{ minWidth: "80px" }}> Warning End Point:</span>
                <FormControl
                  className={modules["form-control"]}
                  style={{
                    paddingLeft: "2rem",
                    position: "relative",
                    marginLeft: "0.80rem",
                  }}
                >
                  <span style={{ display: "flex" }}>
                    <input
                      className={modules["dropdownSelect"]}
                      style={{
                        borderRadius: "10px",
                        height: "23px",
                        paddingLeft: "0.4rem",
                        marginLeft: "12px",
                        cursor: "text",
                      }}
                      type="number"
                      value={warningEnd}
                      onChange={(e) => {
                        handleInputChange(
                          e.target.value,
                          setWarningEnd,
                          "warningEnd"
                        );
                      }}
                    />
                  </span>
                </FormControl>
                {errorMessage.warningEnd && (
                  <span style={errorStyle}>{errorMessage.warningEnd}</span>
                )}
              </Grid>
              <Grid container className={modules["btn1-cont"]}>
                <button
                  className={modules["btn2"]}
                  style={{ fontSize: "15px" }}
                  onClick={handleReset}
                >
                  Reset
                </button>
              </Grid>
            </Grid>


            {(tableAppear && (
              <Grid container className={modules["table-grid"]}>
                <div style={{ marginLeft: "2.6rem" }}>
                  <Table
                    className={modules["custom-table"]}
                    style={{ width: "60%" }}
                  >
                    <TableHead className="table_head">
                      <TableRow
                        sx={{
                          "& th": {
                            color: "#fff",
                          },
                        }}
                      >
                        <TableCell padding="checkbox">
                          <input
                            type="Checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedRows(
                                  filteredData.map((item) => item.id)
                                );
                              } else {
                                setSelectedRows([]);
                              }
                            }}
                            checked={selectedRows.length === filteredData.length}
                            className={modules["custom-checkbox"]}
                          />
                        </TableCell>

                        <TableCell
                          sx={{
                            fontFamily: "worksans-semibold,sans-serif",
                            fontSize: "15px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Sr. No.
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "sans-serif",
                            fontSize: "15px",
                            whiteSpace: "normal",
                          }}
                        >
                          Safe <br /> Start Point
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "sans-serif",
                            fontSize: "15px",
                            whiteSpace: "normal",
                          }}
                        >
                          Safe <br /> End Point
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "sans-serif",
                            fontSize: "15px",
                            whiteSpace: "normal",
                          }}
                        >
                          Warning <br /> Start Point
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "sans-serif",
                            fontSize: "15px",
                            whiteSpace: "normal",
                          }}
                        >
                          Warning <br /> End Point
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "sans-serif",
                            fontSize: "15px",
                            whiteSpace: "normal",
                          }}
                        >
                          Level Name
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "sans-serif",
                            fontSize: "15px",
                            whiteSpace: "normal",
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filteredData.map((item, index) => (
                        <TableRow key={item.id} className={modules["table-row"]}>
                          <TableCell padding="checkbox">
                            {editingRow === item.id ? null : (
                              <input
                                type="checkbox"
                                className={modules["custom-checkbox"]}
                                checked={selectedRows.includes(item.id)}
                                onChange={() => handleSelectRow(item.id)}
                              />
                            )}
                          </TableCell>

                          <TableCell className={modules["table-cell"]}>
                            {index + 1}
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {editingRow === item.id ? (
                              <span
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <input
                                  type="number"
                                  className={modules["rowinput_css"]}
                                  value={editingData.safeStartPoint}
                                  onChange={(e) =>
                                    setEditingData({
                                      ...editingData,
                                      safeStartPoint: e.target.value,
                                    })
                                  }
                                />
                              </span>
                            ) : (
                              parseFloat(item.safeStartPoint).toFixed(2)
                            )}
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {editingRow === item.id ? (
                              <input
                                type="number"
                                className={modules["rowinput_css"]}
                                value={editingData.safeEndPoint}
                                onChange={(e) =>
                                  setEditingData({
                                    ...editingData,
                                    safeEndPoint: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              parseFloat(item.safeEndPoint).toFixed(2)
                            )}
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {editingRow === item.id ? (
                              <input
                                type="number"
                                className={modules["rowinput_css"]}
                                value={editingData.warningStartPoint}
                                onChange={(e) =>
                                  setEditingData({
                                    ...editingData,
                                    warningStartPoint: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              parseFloat(item.warningStartPoint).toFixed(2)
                            )}
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {editingRow === item.id ? (
                              <input
                                type="number"
                                className={modules["rowinput_css"]}
                                value={editingData.warningEndPoint}
                                onChange={(e) =>
                                  setEditingData({
                                    ...editingData,
                                    warningEndPoint: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              parseFloat(item.warningEndPoint).toFixed(2)
                            )}
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {item.levelName}
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {editingRow === item.id ? (
                              <>
                                <a
                                  className={modules["btn4"]}
                                  onClick={(e) =>
                                    handleUpdate(item.id, e.currentTarget)
                                  }
                                >
                                  Update
                                </a>
                                <a
                                  className={modules["btn4"]}
                                  onClick={(e) => handleCancel(e.currentTarget)}
                                >
                                  Cancel
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  style={{ justifyContent: "flex-end" }}
                                  className={modules["btn4"]}
                                  onClick={(e) =>
                                    handleEdit(item, e.currentTarget)
                                  }
                                >
                                  Edit
                                </a>
                                <a
                                  className={modules["btn5"]}
                                  onClick={(e) =>
                                    handleDelete(item, e.currentTarget)
                                  }
                                >
                                  Delete
                                </a>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <br></br>
                  <button
                    variant="contained"
                    onClick={handleBulkDelete}
                    className={modules["btn9"]}
                    style={{ fontSize: "15px" }}
                  >
                    Delete
                  </button>
                </div>
              </Grid>
            ))}
          </Grid>

        </Grid>
      </Grid>
    </>
  );
};

export default FeverChartMaster;
