import modules from "./../DSS.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import bread from "../../../Assets/bread.png";
import { Set_NewInstance } from "../../../Services/Dss";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";

export default function NewInstance() {
  const [data, setData] = useState({
    instname: "",
    instdesc: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
  }, []);

  function submitHandler() {
    const body = {
      instanceUID: data.instname,
      userID: sessionStorage.getItem("loginId"),
      recordType: "Insert",
      instanceDescription: data.instdesc,
    };
    setIsLoading(true);
    if (data.instname) {
      Set_NewInstance(body)
        .then((response) => {
          console.log(response?.data?.success?.data);
          alert("Instance Inserted");
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Enter Instance");
    }
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"NewInstance"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>DSS Admin</a>
                <img src={bread} alt="" />
                <a>New Instance</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table
                className={modules["customTable"]}
                style={{ marginLeft: "3rem" }}
              >
                <tbody>
                  <tr style={{ paddingLeft: "5rem" }}>
                    <td style={{ width: "9rem", fontWeight: "bold" }}>
                      DSS Instance
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={data.instname}
                        onChange={(e) =>
                          setData({ ...data, instname: e.target.value })
                        }
                        onInput={(e) => {
                          const regex = /^[a-zA-Z0-9()&_\-+=\[\]/?. ]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9()&_\-+=\[\]/?. ]/g,
                              ""
                            );
                            setData({ ...data, instname: e.target.value });
                          }
                        }}
                        onBlur={(e) => {
                          const regex2 = /^[a-zA-Z0-9_-]*$/;
                          if (!regex2.test(e.target.value)) {
                            alert(
                              "Only alphanumeric characters are valid in this field."
                            );
                            setData({ ...data, instname: "" });
                          }
                        }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "9rem", fontWeight: "bold" }}>
                      Instance Description
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={data.instdesc}
                        onChange={(e) =>
                          setData({ ...data, instdesc: e.target.value })
                        }
                        onInput={(e) => {
                          const regex = /^[a-zA-Z0-9()&_\-+=\[\]/?. ]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9()&_\-+=\[\]/?. ]/g,
                              ""
                            );
                            setData({ ...data, instdesc: e.target.value });
                          }
                        }}
                        onBlur={(e) => {
                          const regex2 = /^[a-zA-Z0-9_-]*$/;
                          if (!regex2.test(e.target.value)) {
                            alert(
                              "Only alphanumeric characters are valid in this field."
                            );
                            setData({ ...data, instdesc: "" });
                          }
                        }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "9rem" }}></td>
                    <td></td>
                    <td style={{ paddingLeft: "20px" }}>
                      <button
                        className={modules["btn1"]}
                        onClick={submitHandler}
                      >
                        Submit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* <Grid container className={modules["btn1-cont"]}></Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
