import React from "react";
import { Grade } from "@mui/icons-material";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Sidebar from "../../../Landing/Navbar";
import bread from "../../../Assets/bread.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  DS_GetAllInstanceHandler, DS_GetDataSourceTypeHandler, DS_GetSourceNameHandler,
  DS_SubmitHandler, DS_AddnewRowHandler, DS_UpdateHandler, DS_UpdateRowHandler, DS_AddNewRow
} from "../../../Services/Dss";
import {
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  GetRolesList,
  InseertNewRole,
  DeleteSingleRole,
  ChangeRole,
} from "../../../Services/Admin";

import modules from "./DataSource.module.css";
import { tab } from "@testing-library/user-event/dist/tab";
import SampleSidebar from "../../../Landing/SampleSidebar";

export default function DataSource() {



  const [isAddRowVisible, setIsAddRowVisible] = useState(false);

  const [displayTable, setDisplayTable] = useState(false);



  const [pageStates, setPageStates] = useState({
    dropdownOne: [],
    dropdownTwo: [],
    dropdownThree: [],
    submitdata: [],
  });

  const [mainDropdowns, setMainDropdowns] = useState({
    valueOne: 'select',
    valueTwo: 'select',
    valueThree: 'select'
  })


  const [submitStates, setSubmitStates] = useState({
    mappingName: '',
    filePath: '',
    uploadFileName: '',
    rowFirstNumber: '',
    fieldTerminator: '',
    rowTerminator: '',
    uploadTableName: '',
    fieldDropDown: [],
    rowDropDown: [],
    MasterDropDown: [],

  });

  const [tabledropDown, setTableDropDown] = useState([]);

  const [tableData, setTableData] = useState({
    tabData: [],
    editStates: [],
    updateStates: []
  })
  const [newRoleName, setNewRoleName] = useState('');


  const [newStates, setNewStates] = useState({
    newMasterAttribute: 'select',
    newFilecolumnNumber: ''
  })

  const handleSubmit = () => {
    //  alert(mainDropdowns.valueTwo)
    mainDropdowns.valueTwo === 'select' ?
      alert('Select Data Source Type') :
      (mainDropdowns.valueThree === 'select' ?
        alert('Select Data Source Name') :
        (() => {
          DS_SubmitHandler(mainDropdowns.valueOne, mainDropdowns.valueTwo, mainDropdowns.valueThree).then(res => {
            if (res.status === 200) {
              console.log( res.data[0])
              const submitStates = res.data[0];
              const table = res.data[1];
              setSubmitStates({
                mappingName: submitStates?.DataSource[0]?.filemappingname,
                filePath: submitStates?.DataSource[0]?.filepath,
                uploadFileName: submitStates?.DataSource[0]?.uploadfilename,
                rowFirstNumber: submitStates?.DataSource[0]?.datarowfirstnumber,
                fieldTerminator: submitStates?.DataSource[0]?.fieldterminatorcharacter,
                rowTerminator: submitStates?.DataSource[0]?.rowterminatorcharacter,
                uploadTableName: submitStates?.DataSource[0]?.uploadtablename,
                fieldDropDown: submitStates?.FieldTerm,
                rowDropDown: submitStates?.RowTerm,
                MasterDropDown: submitStates?.MasterName,
              });

              setTableData({
                ...tableData,
                tabData: table,
                editStates: table.map(i => {
                  return false;
                }),
                updateStates: table
              });
              setDisplayTable(true);
              return submitStates?.DataSource[0]?.uploadtablename;
            }
          }).then(res => {
            GetDropDownRowHandler(res);
          }).catch(err => {
            console.log('Error fetching data:', err);
          })

        })())
  };

  function InsertRowHandler() {

    const body = {
      instanceId: mainDropdowns.valueOne,
      sourceType: mainDropdowns.valueTwo,
      sourceName: mainDropdowns.valueThree,
      masterName: submitStates.uploadTableName,
      userId: "pritpwc",
      masterTableColumn: newStates.newMasterAttribute,
      sourceTableColumn: newStates.newFilecolumnNumber,
      hostName: "HOST",
      iP_ADDRESS: "IP"
    }
    if (newStates.newMasterAttribute !== 'select' && newStates.newFilecolumnNumber) {
      DS_AddNewRow(body).then(res => {
        if (res.status === 200) {
          alert('Added row succesfully')
          handleSubmit();
        }
        else if (res?.response?.status === 400) {
          alert(res?.response?.data);

        }

        setIsAddRowVisible(false);
        setNewStates({
          newFilecolumnNumber: '',
          newMasterAttribute: ''
        })


      }).catch(err => {
        console.log('Error adding row:', err);
      })
    } else {
      alert('Fields Can not Be Keep Blank. Fill All Details');
    }
  }

  useEffect(() => {
    DS_GetAllInstanceHandler('pritpwc').then(res => {
      if (res.status === 200) {
        // setPageStates({
        //   ...pageStates,
        //   dropdownOne: res.data
        // });
        setMainDropdowns({
          ...mainDropdowns,
          valueOne: res.data[0]?.instanceuid
        })

        return res;
      }
      
    }).then(response=>{
      DS_GetDataSourceTypeHandler(response.data[0]?.instanceuid).then(res => {
        if (res.status === 200) {
          setPageStates({
            ...pageStates,
            dropdownOne:response.data,
            dropdownTwo: res.data
          })
        }
      })
      // GetDataSourceTypeHandler(response);
    }).catch(err => {
      alert('Error fetching data:', err);
    })
  }, []);



  function GetDataSourceTypeHandler(value) {
    if (value !== 'select') {
      DS_GetDataSourceTypeHandler(value).then(res => {
        if (res.status === 200) {
          setPageStates({
            ...pageStates,
            dropdownTwo: res.data
          })
        }
      }).catch(err => {
        alert('Error while fetching data:', err);
      })
    }
  }


  function GetDataSourceNameHandler(value) {
    if (value !== 'select') {
      DS_GetSourceNameHandler(mainDropdowns.valueOne, value).then(res => {
        if (res.status === 200) {
          setPageStates({
            ...pageStates,
            dropdownThree: res.data
          })
        }
      }).catch(err => {
        alert('Error while fetching data:', err);
      })
    }
  }


  function GetDropDownRowHandler(value) {
    DS_AddnewRowHandler(mainDropdowns.valueOne, mainDropdowns.valueThree, value).then(res => {
      if (res.status === 200) {
        setTableDropDown(res?.data?.AttributeData)
      }
    }).catch(err => {
      console.log('Error fetching data:', err);
    })
  }


  function EditHandler(idx) {
    setTableData({
      ...tableData,
      editStates: tableData.editStates.map((i, index) => {
        return idx === index ? !tableData.editStates[index] : i;
      })
    })
  }


  function UpdateRowHandler(ele, index) {
    const body = {
      userID: "pritpwc",
      record_id: mainDropdowns.valueThree,
      upload_record_id:ele.record_id ,
      uploadTableName: ele.uploadtablename,
      uploadColumnName: tableData.updateStates[index].uploadcolumnname ,
      filecolumnnumber: tableData.updateStates[index].filecolumnnumber
    }
    DS_UpdateRowHandler(body).then(res => {
      if (res.status === 200) {
        alert('Details Updated Succesfully');
        handleSubmit();
      }
    }).catch(err => {
      console.log('Error while updating data:', err);
    })
  }


  function updateUploadHandler() {
    const body =
    {
      instanceId: mainDropdowns.valueOne,
      sourceType: mainDropdowns.valueTwo,
      sourceName: mainDropdowns.valueThree,
      userID: "pritpwc",
      mappingName: submitStates.mappingName,
      filePath: submitStates.filePath,
      fileNamewithExtn: submitStates.uploadFileName,
      dataStartFrom: submitStates.rowFirstNumber,
      fieldTerminator: submitStates.fieldTerminator,
      rowTerminator: submitStates.rowTerminator,
      masterName: submitStates.uploadTableName,
      hostName: "HOST",
      ipAddress: "IP"
    }

    DS_UpdateHandler(body).then(res => {
      if (res.status === 200) {
        alert(res.data);
        handleSubmit();
      } else if (res?.response?.status === 400) {
        alert(res?.response?.data)
      }
    }).catch(err => {

    })
  }


  function ArrowLine() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "2px",
            height: "30px",
            backgroundColor: "grey",
            marginRight: "2px",
          }}
        />
        <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
      </div>
    );
  }
  return (
    <>
      <Grid container>
        <Grid
          item
          lg={0.6}
          sx={{ position: "fixed", zIndex: 2000 }}
          className="main-cont"
        >
          {/* <Sidebar /> */}
          <SampleSidebar/>
        </Grid>
        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className="home-cont">
            <Home />
          </Grid>
        </Grid>
        <Grid item lg={9} className={modules["datasource_div"]}>
          <div className="pro-bread" style={{ marginLeft: "5rem" }}>
            <p>
              <a>DSS Masters</a>
              <img src={bread} alt="" />
              <a>Data Source</a>
            </p>
          </div>
        </Grid>

        <Grid container sx={{ marginLeft: "7rem", marginTop: "2rem" }}>
          <table className={modules["customTable"]}>
            <tbody>
              <tr>
                <td>DSS Instance</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={mainDropdowns.valueOne}
                      //instance
                      onChange={(e) => {
                        setMainDropdowns({
                          ...mainDropdowns,
                          valueOne: e.target.value
                        });
                        GetDataSourceTypeHandler(e.target.value);
                      }}
                    >
                      <MenuItem value='select' sx={{ display: 'none' }}>select</MenuItem>
                      {pageStates.dropdownOne.map((i) => {
                        return (
                          <MenuItem value={i.instanceuid} key={i.instanceuid} >
                            {i.instanceuid}
                          </MenuItem>
                        );

                      })}
                    </Select>
                  </FormControl>
                </td>
              </tr>

              <tr>
                <td>DataSource Type</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={mainDropdowns.valueTwo}
                      onChange={(e) => {
                        setMainDropdowns({
                          ...mainDropdowns,
                          valueTwo: e.target.value
                        });
                        GetDataSourceNameHandler(e.target.value)
                      }}
                    >
                      <MenuItem value='select' sx={{ display: 'none' }}>select</MenuItem>
                      {pageStates.dropdownTwo.map((i) => {
                        return (
                          <MenuItem value={i.columnvalue} key={i.columnvalue}>
                            {i.displayvalue}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </td>
              </tr>

              <tr>
                <td>Name of DataSource</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={mainDropdowns.valueThree}
                      onChange={(e) => setMainDropdowns({
                        ...mainDropdowns,
                        valueThree: e.target.value
                      })}
                    >
                      <MenuItem value='select' sx={{ display: 'none' }}>select</MenuItem>
                      {pageStates.dropdownThree.map((i) => {
                        return (
                          <MenuItem value={i.recordid} key={i.recordid}>
                            {i.filemappingname}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>

        <div>
          <button
            style={{
              marginLeft: "15rem",
              marginTop: "1.2rem",
              padding: "0.5cm",
              marginBottom: "2rem",
            }}
            type="button"
            className={modules["btn1"]}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </Grid>

    <Grid sx={{marginLeft:'2.5rem',marginBottom:'2cm'}}>
    {displayTable && (
        <>
          <p style={{ marginLeft: "13.5rem", marginTop: "0.5cm" }}>
            {" "}
            File Upload Details{" "}
          </p>
          <Grid sx={{ marginLeft: "5rem", marginTop: "2rem" }}>
            <Grid>
              <table className={modules["customTable"]}>
                <tbody>
                  <tr>
                    <td>Name of the Mapping</td>
                    <td> : </td>
                    <td>
                      <input value={submitStates.mappingName} onChange={(e) => setSubmitStates({
                        ...submitStates,
                        mappingName: e.target.value
                      })} className={modules['customInp']} />
                    </td>
                  </tr>

                  <tr>
                    <td>File Path</td>
                    <td> : </td>
                    <td>
                      <input value={submitStates.filePath} className={modules['customInp']} />
                    </td>
                  </tr>

                  <tr>
                    <td>File Name with Extn</td>
                    <td> : </td>
                    <td>
                      <input value={submitStates.uploadFileName} className={modules['customInp']} />
                    </td>
                  </tr>

                  <tr>
                    <td>Data Start From(Row)</td>
                    <td> : </td>
                    <td>
                      <input value={submitStates.rowFirstNumber} className={modules['customInp']} />
                    </td>
                  </tr>

                  <tr>
                    <td>Field Terminator</td>
                    <td> : </td>
                    <td>
                      <FormControl className={modules["form-control"]}>
                        <Select
                          disabled={true}
                          IconComponent={ArrowLine}
                          className={modules["dropdownSelect"]}
                          value={submitStates.fieldTerminator}

                        >
                          {submitStates?.fieldDropDown?.map((i) => {
                            return (
                              <MenuItem value={i.columnvalue} key={i.columnvalue}>
                                {i.displayvalue}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>

                  <tr>
                    <td>Row Terminator</td>
                    <td> : </td>
                    <td>
                      <FormControl className={modules["form-control"]}>
                        <Select
                          disabled={true}
                          IconComponent={ArrowLine}
                          className={modules["dropdownSelect"]}
                          value={submitStates.rowTerminator}

                        >
                          {submitStates?.rowDropDown?.map((i) => {
                            return (
                              <MenuItem value={i.columnvalue}>
                                {i.displayvalue}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>

                  <tr>
                    <td>Master Name</td>
                    <td> : </td>
                    <td>
                      <FormControl className={modules["form-control"]}>
                        <Select
                          disabled={true}
                          IconComponent={ArrowLine}
                          className={modules["dropdownSelect"]}
                          value={submitStates.uploadTableName}

                        >
                          {submitStates?.MasterDropDown?.map((i) => {
                            return (
                              <MenuItem value={i.tablename} >
                                {i.masterdisplayname}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>

            <div>
              <button
                style={{
                  marginLeft: "12.2rem",
                  marginTop: "1.2rem",
                  padding: "0.5cm",
                  marginBottom: "1.6rem",
                }}
                type="button"
                className={modules["btn2"]}
                onClick={updateUploadHandler}
              >
                Update Uploading Details
              </button>
            </div>

            <p style={{ padding: "0.5cm", marginLeft: "11.5rem" }}>
              File Upload Mapping
            </p>
            <Grid>
              <button
                type="button"
                className={modules["btn1"]}
                style={{ marginLeft: "0rem", height: "1cm" }}
                onClick={() => setIsAddRowVisible(true)}
              >
                Add Row for Insertion
              </button>
            </Grid>

            {/* Usertable2 Usertable2 Usertable2 Usertable2 Usertable2 Usertable2 Usertable2 Usertable2 Usertable2 Usertable2 Usertable2*/}

            <TableContainer component={Paper} style={{ marginTop: "20px" }} className={modules['muiTable']}>
              <Table sx={{ minWidth: 750 }} aria-label="simple table" >
                <TableHead className="table_head">
                  <TableRow
                    sx={{
                      "& th": {
                        color: "#fff",
                      },
                    }}
                  >
                    <TableCell>Sr.No.</TableCell>
                    <TableCell align="left" >Master Attribute Name</TableCell>
                    <TableCell align="left">Input Column Number</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.tabData?.map((i, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>

                        <TableCell align="left">
                          {/* {editing ? ( */}
                          {tableData?.editStates[index] ? (
                            <FormControl className={modules["form-control"]}>
                              <Select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelect"]}
                                value={tableData.updateStates[index].uploadcolumnname}
                                onChange={(e) => setTableData({
                                  ...tableData,
                                  updateStates: tableData.updateStates.map((i, idx) => {
                                    return idx === index ? {
                                      ...i,
                                      attributedisplayname: e.target.value
                                    } : i
                                  })
                                })}
                              >
                                {tabledropDown?.map((i) => {
                                  return (
                                    <MenuItem value={i.attributecolumnname} key={i.attributecolumnname}>
                                      {i.attributedisplayname}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          )
                            : <>
                              {i.attributedisplayname}
                            </>

                          }
                        </TableCell>
                        {/* {console.log(tableData.updateStates)} */}
                        <TableCell align="left">
                          {tableData?.editStates[index] ? (
                            <input
                              type="text"
                              value={tableData?.updateStates[index].filecolumnnumber}
                              onChange={(e) =>
                                setTableData({
                                  ...tableData,
                                  updateStates: tableData?.updateStates?.map((i, idx) => {
                                    return index === idx ? {
                                      ...i,
                                      filecolumnnumber: e.target.value
                                    } : i;
                                  })
                                })
                              }
                            />
                          ) : (
                            <div
                              sx={{
                                color: "#0000EE",
                                textDecoration: "underline",
                              }}
                            >
                              {i.filecolumnnumber}
                            </div>
                          )}
                        </TableCell>

                        <TableCell
                          className="editlink"
                          align="left"
                          sx={{ color: "#0000EE" }}
                        >
                          {tableData.editStates[index] ? (
                            <>
                              <a onClick={() => UpdateRowHandler(i, index)}>Update</a>
                              <a onClick={() => EditHandler(index)}>Cancel</a>
                            </>
                          ) : (
                            <>
                              <a
                                style={{ paddingRight: "1rem" }}
                                onClick={() => {
                                  EditHandler(index);
                                  setIsAddRowVisible(false)
                                }}
                              >
                                Edit
                              </a>

                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {isAddRowVisible && (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>

                      <TableCell component="th" scope="row">
                        New
                      </TableCell>
                      <TableCell  >
                        <FormControl className={modules["form-control"]}>
                          <Select
                            IconComponent={ArrowLine}
                            className={modules["dropdownSelect"]}
                            value={newStates.newMasterAttribute}
                            onChange={(e) => setNewStates({
                              ...newStates,
                              newMasterAttribute: e.target.value
                            })}
                          >
                            {tabledropDown?.map((i) => {
                              return (
                                <MenuItem value={i.attributecolumnname} key={i.attributecolumnname}>
                                  {i.attributedisplayname}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell  >
                        <input
                          className="newinput"
                          type="text"
                          value={newStates.newFilecolumnNumber}
                          onChange={(e) => setNewStates({
                            ...newStates,
                            newFilecolumnNumber: e.target.value
                          })}
                        />
                      </TableCell>

                      <TableCell>
                        <a
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                          }} handleSave
                          onClick={() => InsertRowHandler()}
                        >
                          Insert
                        </a>
                        <a
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                          }}
                          onClick={() => setIsAddRowVisible(false)}
                        >
                          Close
                        </a>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
    </Grid>
      
    </>
  );
}
