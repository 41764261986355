import React, { useEffect, useState } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Stack,
} from "@mui/material";

import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import loading from "../../../Assets/Loading_image.gif";
import Plus from "../../../Assets/plus.png";
import {
  GetProjectss,
  GetProjectManagerss,
  GetFirstTaskManager,
  GetLastTaskManager,
  GetInstancesListt,
  GetTableDetailss,
  ChangePM,
  Save_Detail,
  AET_CheckSubType,
} from "../../../Services/CCPM";


function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const ModifySubTaskAttribute = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false); //loading gif
  const [checkNoRec, setCheckNoRec] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
    };
    GetInstancesListt(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setInstance(response.data.success.data);
          handleInstance(response.data.success.data[0].id); //select 1st instance by default
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //WHEN INSTANCE SELECTED
  const handleInstance = (i) => {
    setCheckNoRec(false);
    setIsLoading(true);
    setSelectedInstance(i);
    setSelectedProjectManager("Select");
    setSelectedTaskInitialManager("Select");
    setSelectedProjectName("Select");
    setSelectedPM("Select");
    setTemp([]);
    setShowTable(false);


    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: i,
    };
    let body1 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: i,
      includeViewAccess: 0,
      isSpecificProjStatus: 0,
    };


    let body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: i,
      projId: selectedProjectName === "" || selectedProjectName === "Select"
        ? 0
        : selectedProjectName,
    };

 

   
    //GET MANAGERS AND MANAGER TO BE LIST
    GetFirstTaskManager(body2)
      .then((response) => {
        setIsLoading(false); // Ensure loader is stopped in every case

        // Check if the response status is 200
        if (response.status === 200) {
          const successData = response?.data?.success?.data;
          if (successData) {
            setTaskInitialManager(successData); // Only set data if it's not null
          } else {

            setTaskInitialManager([]); // Set an empty array to avoid null references
          }
        }
        // Check if there is an error message in the response body
        else if (response?.response?.data?.error?.message) {
          alert(response.response.data.error.message);
          setTaskInitialManager([]);
        }
        // Handle cases where the message is in the "success" object but is `null`
        else if (response?.response?.data?.success?.message !== null) {
          alert(response.response.data.success.message);
          setTaskInitialManager([]);
        }
        // Handle cases where no message or data is available
        else {
          alert("Unknown error occurred.");
          setTaskInitialManager([]);
        }
      })
      .catch((error) => {
        setIsLoading(false); // Ensure loader is stopped in the error case
        console.log(error); // Log the actual error for debugging
        alert("An error occurred. Please try again later.");
      });


    GetProjectManagerss(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setProjectManager(response.data.success.data.ExistingProjectManager);
          setPM(response.data.success.data.ActiveUsre);
        }
        else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
          setProjectManager([])
          setPM([])
        }
        else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
          setProjectManager([])
          setPM([])
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
          setProjectManager([])
          setPM([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //GET PROJECT NAMES LIST
    GetProjectss(body1)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setProjectName(response.data.success.data);
        }
        else if (response?.response?.data?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.message);
          setProjectName([])
        }
        else if (response?.response?.data?.error?.message !== undefined) {
          setIsLoading(false);
          alert(response?.response?.data?.error?.message);
          setProjectName([])
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
          setProjectName([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleProject = (i) => {
    // Directly update selected project before making the API call
    setSelectedProjectName(i);
  
    // Call the fetchTaskManagerData function if a valid project is selected
    if (i !== "Select" && i !== "") {
      fetchTaskManagerData(i); // Call the new function to handle the API call
    }
  };

  const fetchTaskManagerData = (selectedProject) => {
    // Prepare the body for the API call
    const body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: selectedInstance, // Use the selected instance
      projId: selectedProject === "Select" ? 0 : selectedProject, // Handle default 'Select'
    };
  
    // Set loading state while the API is fetching data
    setIsLoading(true);
  
    // Fetch the updated Task Manager data
    GetFirstTaskManager(body2)
      .then((response) => {
        setIsLoading(false); // Stop loader after API response
  
        if (response.status === 200) {
          const successData = response?.data?.success?.data;
          if (successData) {
            setTaskInitialManager(successData); // Set the fetched Task Managers
          } else {
            setTaskInitialManager([]); // Reset Task Manager if no data
          }
        } else {
          // Handle non-200 status responses
          const errorMessage =
            response?.response?.data?.error?.message ||
            response?.response?.data?.message ||
            "Unknown error occurred.";
          alert(errorMessage);
          setTaskInitialManager([]); // Reset Task Manager in case of error
        }
      })
      .catch((error) => {
        setIsLoading(false); // Stop loader in case of error
        console.error(error); // Log the error
        alert("An error occurred. Please try again later.");
      });
  };


 

  const handleTaskInitialManager = (i) => {
    setSelectedTaskInitialManager(i);
  };


  const [temp, setTemp] = useState([]); //detail table data
  const [showTable, setShowTable] = useState(false);


  //DROP DOWN VALUES
  const [instance, setInstance] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [projectManager, setProjectManager] = useState([]);
  const [taskInitialManager, setTaskInitialManager] = useState([]);
  const [taskLastManager, setTaskLastManager] = useState([]);
  const [PM, setPM] = useState([]);


  //SELECTED VALUES
  const [selectedInstance, setSelectedInstance] = useState("Select");
  const [selectedProjectName, setSelectedProjectName] = useState("Select");
  const [selectedProjectManager, setSelectedProjectManager] = useState("Select");
  const [selectedTaskInitialManager, setSelectedTaskInitialManager] = useState("Select");
  const [selectedPM, setSelectedPM] = useState("Select");
  const [showReplaceManager, setShowReplaceManager] = useState(false);

  const handleReplacedCheckboxChange = () => {
    setShowReplaceManager((prev) => !prev);
  };

  const handleSearch = () => {
    setCheckNoRec(false);
    setIsLoading(true);
    setShowTable(true);

    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: selectedInstance,
      projId:
        selectedProjectName === "" || selectedProjectName === "Select"
          ? 0
          : selectedProjectName,
      TaskManagerId:
        selectedTaskInitialManager === "" || selectedTaskInitialManager === "Select"

          ? 0
          : selectedTaskInitialManager,
      isModifySubtaskAttribute: 1,
    };
    GetTableDetailss(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          const data = response?.data?.success?.data || []; // Default to empty array if data is null
          setTemp(data);
          if (data.length === 0) {
            setCheckNoRec(true); // Set to false if data is empty
          }
        }
        else {
          setIsLoading(false);
          alert(response?.response?.data?.success?.message);
          setTemp([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkSubTaskTypeID = async (taskID, projectID) => {
    try {
      const response = await AET_CheckSubType({ taskID, projectID });
      return response?.data?.success?.data[0]?.var_result;
    } catch (error) {
      console.error("Error checking subtask type ID:", error);
      return -1;
    }
  };
  const [sttType, setSttType] = useState([]);
  const [showImage, setShowImage] = useState([]); // New state to track image visibility

  useEffect(() => {
    const updateUIBasedOnAPI = async () => {
      const updatedRowStates = {};
      const typeTexts = [];
      const imageVisibility = []; // Array to track image visibility

      for (let index = 0; index < temp.length; index++) {
        const item = temp[index];
        const subTaskTypeExist = await checkSubTaskTypeID(item.taskid, item.projectsid);

        if (subTaskTypeExist !== -1 && subTaskTypeExist !== 0) {
          let typeText = "";
          switch (subTaskTypeExist) {
            case 1:
              typeText = "List";
              break;
            case 2:
              typeText = "Proc";
              break;
            case 4:
              typeText = "Vol";
              break;
            case 3:
              typeText = "Auto";
              break;
            default:
              typeText = "";
          }

          typeTexts[index] = typeText;
          imageVisibility[index] = true; // Show image
        } else {
          imageVisibility[index] = false; // Hide image
        }
      }

      setSttType(typeTexts);
      setShowImage(imageVisibility); // Update image visibility state
    };

    updateUIBasedOnAPI();
  }, [temp]);


  const handleOpenAdd = (taskId, projId) => {
    localStorage.setItem("instanceId", selectedInstance);
    navigate(`/ccpm/Tasks/subTasksAll?ProjectId=${projId}&TaskId=${taskId}`);
  };

  const handleReset = () => {
    handleInstance(instance[0].id);
    setCheckNoRec(false);
  };

  useEffect(() => {
    // Reset to the first page when chartData changes
    setCurrentPage(1);
  }, [temp]);


  const RowsPerPage = 10;
  const MaxVisiblePages = 10;

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total pages based on chartData length
  const totalPages = Math.ceil(temp.length / RowsPerPage);
  const startIndex = (currentPage - 1) * RowsPerPage;
  const endIndex = startIndex + RowsPerPage;
  const displayedData = temp.slice(startIndex, endIndex);


  const handlePageChange = (page) => {
    if (page === "prev") {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    } else if (page === "next") {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    } else {
      setCurrentPage(page);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= MaxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= MaxVisiblePages - 3) {
        for (let i = 1; i <= MaxVisiblePages - 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");

        const remainingPages = totalPages - currentPage;

        if (remainingPages < MaxVisiblePages - 3) {
          for (let i = currentPage - (MaxVisiblePages - 4); i <= totalPages; i++) {
            pageNumbers.push(i);
          }
        } else {
          for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pageNumbers.push(i);
          }

          pageNumbers.push("...");
          pageNumbers.push(totalPages);
        }
      }
    }
    return pageNumbers;
  };

  return (
    <div
      style={{ overflowX: showTable && temp.length > 0 ? "auto" : "hidden" }}
    >
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"ModifySubTaskAttribute"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>Project Plan</a>
                <img src={bread} alt="" />
                <a>Modify Sub Task Attribute</a>
              </div>
            </div>

            <div container className={modules["parent-container-mta"]}>
              <table className={modules["table-cpm"]}>
                <tbody style={{ width: "100%" }}>
                  <tr>
                    <td>
                      <span style={{ marginRight: "5px" }}>
                        <strong>Instance :</strong>
                      </span>
                      <select
                        className={modules["dropdownSelect1-apm"]}
                        value={selectedInstance}
                        onChange={(e) => handleInstance(e.target.value)}
                      >
                        {instance.length > 0 && instance?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <span style={{ marginRight: "5px" }}>
                        <strong>Project Name :</strong>
                      </span>
                      <select
                        IconComponent={ArrowLine}
                        className={modules["dropdownSelect1-apm"]}
                        value={selectedProjectName}
                        onChange={(e) => handleProject(e.target.value)}
                      >
                        {projectName.length > 0 &&
                          <option value="Select">SELECT</option>
                        }
                        {projectName.length > 0 && projectName?.map((i) => (
                          <option value={i.id} key={i}>
                            {i.projectname}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <span style={{ marginRight: "5px" }}>
                        <strong>Task Manager :</strong>
                      </span>
                      <select
                        IconComponent={ArrowLine}
                        className={modules["dropdownSelect1-apm"]}
                        value={selectedTaskInitialManager}
                        onChange={(e) => handleTaskInitialManager(e.target.value)}
                      >
                        {taskInitialManager.length > 0 &&
                          <option value="Select">SELECT</option>
                        }
                        {taskInitialManager.length > 0 && taskInitialManager?.map((i) => (
                          <option value={i.id} key={i.id}>
                            {i.username}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr
                    className={[
                      modules["btn1-cont-cpm"],
                      modules["button-container-cpm"],
                    ].join(" ")}
                  >
                    <button
                      style={{ marginLeft: '1rem' }}
                      className={[
                        modules["btn1-cpm"],

                      ].join(" ")}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["btn2-cpm"]}
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>
            {checkNoRec && (
              <Stack className={modules["pbp_bottom_stack"]}>
                <p className={modules["pbp_no_record"]}>No Record Found.</p>
              </Stack>
            )}




            {showTable && temp.length > 0 && (
              <Grid
                container
                className={modules[("table-grid-cpm", "table-grid-margin")]}
              >
                <div style={{ marginBottom: '2rem' }} className={modules["table-container-mta"]}>
                  <Table
                    className={[
                      modules["custom-table-cpm"],
                      modules["custom-table-th"],
                    ].join(" ")}
                  >
                    <TableHead>
                      <TableRow>

                        <TableCell>
                          Project

                          Name
                        </TableCell>
                        <TableCell>
                          Project

                          Manager
                        </TableCell>
                        <TableCell>
                          Task

                          Id
                        </TableCell>
                        <TableCell>Department</TableCell>
                        <TableCell>
                          SubTask

                        </TableCell>
                        <TableCell>
                          Task

                          Name
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {temp.length > 0 && displayedData?.map((item, index) => (
                        <TableRow key={index} className={modules["table-row"]}>



                          <TableCell className={modules["table-row"]}>
                            {item.projectname}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {item.projmanager}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {item.taskid}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            {item.department}
                          </TableCell>
                          <TableCell className={modules["table-row"]}>
                            <>
                              <span
                                style={{
                                  fontSize: "smaller",
                                  color: "blue",
                                  paddingRight: "5px",
                                }}
                              >
                                {sttType[index]}

                              </span>
                              {showImage[index] && ( // Conditionally render the image
                                <img
                                  src={Plus}
                                  alt=""
                                  title="Subtask"
                                  onClick={() => handleOpenAdd(item.taskid, item.projectsid)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}


                            </>
                          </TableCell>

                          <TableCell className={modules["table-row"]}>
                            <textarea
                              className={modules["text-area"]}
                              type="text"
                              disabled={showTable}
                              value={item.taskname}

                            />
                          </TableCell>






                        </TableRow>
                      ))}
                      {totalPages > 1 && (
                        <TableRow>
                          <TableCell colSpan={16}>
                            <div className={modules["pagination"]}>
                              <span
                                className={currentPage === 1 ? `${modules["disabled"]}` : ""}
                                onClick={() => handlePageChange("prev")}
                              >
                                Prev
                              </span>
                              {getPageNumbers().map((pageNumber, index) => (
                                <span
                                  key={index}
                                  className={
                                    pageNumber === currentPage
                                      ? `${modules["active-page"]}`
                                      : `${modules["page-number"]}`
                                  }
                                  onClick={() =>
                                    typeof pageNumber === "number"
                                      ? handlePageChange(pageNumber)
                                      : null
                                  }
                                >
                                  {pageNumber}
                                </span>
                              ))}
                              <span
                                className={currentPage === totalPages ? `${modules["disabled"]}` : ""}
                                onClick={() => handlePageChange("next")}
                              >
                                Next
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            )}

          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ModifySubTaskAttribute;
