import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import C1 from "../HelpPopups/images/C1.png";
import C2 from "../HelpPopups/images/C2.png";
import C3 from "../HelpPopups/images/C3.png";
import C4 from "../HelpPopups/images/C4.png";
import C5 from "../HelpPopups/images/C5.png";
import C6 from "../HelpPopups/images/C6.png";
import C7 from "../HelpPopups/images/C7.png";
import C8 from "../HelpPopups/images/C8.png";
import C9 from "../HelpPopups/images/C9.png";
import C10 from "../HelpPopups/images/C10.png";
import C11 from "../HelpPopups/images/C11.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const ControlhelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container" style={{fontFamily: "arial", fontSize:"12px"}}>
                        <ul style={{ fontSize: "14px" }} >
                            <h2 style={{ textAlign: "left", fontWeight: "bold" }}>
                                Control - User
                            </h2>
                            <br />
                            <br />


                            <li className="no-bullets" style={{ textDecoration: "underline" }}>
                                Path `{">>"}` MTA `{">>"}` MTA Parameter `{">>"}`  Control.
                            </li>
                            <br />
                            <br />

                            <li className="no-bullets">
                                The system session parameter can be set using the Control session:

                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={C1} alt="C1" />
                            </Stack>
                            <br />
                            <li className="no-bullets">
                                Select MTA Instance. Once you select MTA Instance the list of Control Parameter is displayed where user need to update the values.
                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={C2} alt="C2" />
                            </Stack>
                            <br />
                            <li>
                                Average-Daily-Consumption - Consumption Days
                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter will be used to calculate the average daily consumption. The average daily consumption will be used in DSS for calculating the TRD Loss of MTA items.

                                The default value is – 90
                            </li>
                            <li>
                                Buffer Penetration - BPP with Shipped and Committed – Enabled


                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter will be used in Buffer Penetration Processing. The control parameter can have the values as either “Yes” or “No”. If the parameter value is not maintained as “Yes” or “No”, then the same should be set as “Yes”. If the control parameter is set as “Yes” then committed quantity (Qty committed against Pending RPL Orders) will be part of intransit quantity. Intransit quantity will be calculated as –
                            </li>
                            <li style={{ marginLeft: "2rem" }}>

                                Intransit Qty

                                =

                                Shipped Qty + Committed Qty + Blocked Qty                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                If the control parameter is set as “No” then intransit quantity will be calculated as –

                            </li>
                            <li style={{ marginLeft: "2rem" }}>

                                Intransit Qty

                                =

                                Shipped Qty + Blocked Qty

                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                The default value is – Yes
                            </li>
                            <li>
                                Buffer Penetration - Project Items to be Considered in Stock and Pending Qty

                            </li>
                            <li style={{ marginLeft: "2rem" }}>
                                This control parameter will be used in Buffer Penetration Processing. The control parameter can have the values as either “Yes” or “No”. If the parameter value is not maintained as “Yes” or “No”, then the same should be set as “No”. If the control parameter is set as “Yes” then the project item data will be considered along with standard item data. If the control parameter is set as “No” then only the standard item data will be considered.
                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                The default value is – No
                            </li>
                            <li>Buffer Penetration - Virtual Buffer - Destination Stock to be added in Source – Enabled

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter will be used in Buffer Penetration Processing. The control parameter can have the values as either “Yes” or “No”. If the parameter value is not maintained as “Yes” or “No”, then the same should be set as “No”. If the control parameter is set as “Yes” then in case of virtual stock points, the stock, intransit, pending, shipped, committed and blocked qty of the destination stock point will be added in the same of source stock point. If the control parameter is set as “No” then the stock, intransit, pending, shipped, committed and blocked will not be added.

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                The default value is – No
                            </li>

                            <li>
                                Buffer Penetration - Virtual Buffer - Source Stock to be added in Destination – Enabled


                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter will be used in Buffer Penetration Processing. The control parameter can have the values as either “Yes” or “No”. If the parameter value is not maintained as “Yes” or “No”, then the same should be set as “No”. If the control parameter is set as “Yes” then in case of virtual stock points, the stock, intransit, pending, shipped, committed and blocked qty of the source stock point will be added in the same of destination stock point. If the control parameter is set as “No” then the stock, intransit, pending, shipped, committed and blocked will not be added.

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                The default value is – No
                            </li>
                            <li>Buffer Penetration Report - Sort By (BPP with InTransit / BPP)

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter will be used in generating the Buffer Penetration Report to decide the ordering of the data. If the parameter is BPP then the ordering will be based on BPP otherwise it will be BPP with Intransit.

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                The default value is – BPP with InTransit

                            </li>
                            <li>
                                Buffer-Spike-Threshold-Percentage


                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter will be used in following option:

                            </li>
                            <li style={{ marginLeft: "4rem" }} >
                                Buffer Spike Threshold Percentage Upload

                            </li>
                            <li style={{ marginLeft: "4rem" }} >
                                FOL Processing                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This parameter should be any number and will be used to set the threshold for buffer at the instance level. If no data is maintained, then the same will be set as “0”.

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                In case of Buffer Spike Threshold Percentage Upload, the parameter will be used to maintain the same wherever no data is maintained.


                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                In case of FOL processing, the parameter will be used to maintain the same wherever no data is maintained.

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This will be overwritten by the buffer threshold maintained in the master – Buffer-Spike-Threshold-Percentage.

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                The default value is – 0
                            </li>
                            <li>Buffer-Spike-Threshold-Percentage – Enabled

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter will be used in FOL Processing to control whether the parameter – Buffer-Spike-Threshold-Percentage should be applicable or not. If the parameter value is not maintained as “Yes” or “No”, then the same should be set as “No”. If the control parameter is set as “Yes”, then the Buffer Spike Threshold Percentage processing will be enabled. The processing is applicable only for records where the buffer is maintained. The buffer spike threshold will be calculated as follows:

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                Buffer Spike Threshold

                                =

                                Buffer * Buffer Spike Threshold Percentage

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                If the pending order quantity is more than the threshold value, then that record is not used in FOL processing. These records will be shown in Exception Report where the remarks will be –

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                Order Line Quantity (x) booked in TOC Warehouse is more than Threshold Quantity (y),

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                where, x is the Pending Order Qty and y is the Buffer Spike Threshold

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                The default value is – No
                            </li>
                            <li>DBM - No of Spike for suggesting Increase in Buffer

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter is used to in processing DBM. If value is maintained in this field then while processing DBM system will consider that much spikes in consumption and then suggest increase in buffer.

                            </li>
                            <li>DBM Suspension - Cap at Stock Point based on Value

                            </li>
                            <li style={{ marginLeft: "2rem", textDecoration: "highlight" }} className="no-bullets">
                                This control parameter is used to define the capping of increase in buffer on value base. It indicates that buffer at stock point can not increase beyond the max value set in this parameter. If value set YES then this will work.

                            </li>

                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={C3} alt="C3" />
                            </Stack>
                            <br />

                            <br />
                            <li> DBM Suspension - Decrease - Buffer is Changed in Last No_of_RPL_Cycles_for_Decrease_in_Buffer</li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter is used in DBM. If value is set as YES then this parameter will be followed. If Buffer is decreased in continuously for set no of RPL cycles the it will set indication as suspension. For example – If Monitoring RPL cycle is set as 3. If system has decreased buffer for 3RPL cycles continuously then this item is set as DBM suspension.

                            </li>
                            <li>DBM Suspension - Ignore Recommendation

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter is used in DBM. If value is set, then the ignore recommendation box is active. One you tick this box system will ignore the recommendation from DBM to increase or decrease in buffer for that item. The item with check in ignore recommendation will not appear in the list again till the suspension is removed for that item.

                            </li>
                            <li>DBM Suspension - Increase - Buffer is Changed in Last No_of_RPL_Cycles_for_Increase_in_Buffer

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter is used in DBM. If value is set as YES then this parameter will be followed. If Buffer is increased in continuously for set no of RPL cycles the it will set indication as suspension. For example – If Monitoring RPL cycle is set as 3. If system has increased buffer for 3RPL cycles continuously then this item is set as DBM suspension

                            </li>
                            <li>DBM Suspension - Increase - Consumption in a day is more than 1/3 of Buffer

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter will be used in DBM processing. If this parameter is maintained as Yes and if summation of order quantity for a particular day is more than one third the buffer in the last DBM Cycle, then the system will not give any advice for buffer change.

                            </li>

                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                DBM cycle for increasing buffer is calculated as follows –

                            </li>

                            <li style={{ marginLeft: "2rem" }} className="no-bullets">

                                DBM Cycle for Increasing Buffer

                                =

                                Replenishment Days * No of RPL Cycles for Increase in Buffer                            </li>

                            <li>DBM Suspension - Increase - Source Stock Point is in Black or Red</li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter will be used in DBM processing. If this parameter is maintained as Yes and if the Source Stock Point is in either Red or Black in the last DBM Cycle, then the system will not give any advice for buffer decrease.
                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                DBM cycle for increasing buffer is calculated as follows –

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                DBM Cycle for Increasing Buffer

                                =

                                Replenishment Days * No of RPL Cycles for Increase in Buffer                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                The default value is – Yes

                            </li>
                            <li>DBM Suspension - Insufficient Receipt of stock in last DBM cycle

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter is used in DBM processing. If this parameter is maintained as Yes System will consider the supply for the particular item for which DBM suggestion is increase Buffer. This mean if receipt of that particular item is less than the buffer value and due to that item is continuously going in black. So system will maintain status as Suspension. Suspension is removed when the supply for that item is increased.

                            </li>
                            <li>DBM Suspension - Item in White after Buffer change

                            </li>


                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This control parameter is used in DBM processing. If this parameter is maintained as Yes, Then the system will check the colour of the item for which buffer is increased. This means If buffer for one item is increase as per DBM suggestion and that item is going in white continuously. In this case the system will put that item is DBM suspension.

                            </li>

                            <li>DBM Suspension - Minimum Days for Suspension after Ignoring the recommendation

                            </li>

                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This parameter is used for processing DBM. This parameter indicates that for the defined days system will continue the DBM suspension for the item for which user set parameter as ignore suggestion. This item system will not consider for DBM . After that period system will again start considering that item for DBM.

                            </li>
                            <li>DBM Suspension - New Buffer greater than Maximum Buffer Qty

                            </li>
                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This parameter is used for DBM processing. If value is set as Yes and maximum buffer is set for stock point. In this case if the buffer suggest by DBM is greater than the set qty, then item is marked in DBM suspension.

                            </li>
                            <li>DBM Suspension - New Buffer greater than Maximum Buffer Value

                            </li>

                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This parameter is used for DBM processing. If value is set as Yes and maximum buffer value is set for stock point. In this case if the value of buffer suggest by DBM is greater than the set value for the stock point, then item is marked in DBM suspension.

                            </li>

                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={C4} alt="C4" />
                            </Stack>
                            <br />


                            <li>DBM Suspension - New Buffer less than Minimum Buffer Qty

                            </li>

                            <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                This parameter is used for DBM processing. If value is set as Yes and minimum buffer is set for stock point. In this case if decrease in buffer suggest by DBM is lesser than the set qty, then item is marked in DBM suspension.

                            </li>
                            <li>DBM Suspension - New Buffer less than Minimum Buffer Qty</li>
                            <p>This parameter is used for DBM processing. If value is set as Yes and minimum buffer is set for stock point. In this case if decrease in buffer suggested by DBM is lesser than the set qty, then item is marked in DBM suspension.</p>

                            <li>DBM Suspension - New Buffer less than Minimum Buffer Value</li>
                            <p>This parameter is used for DBM processing. If value is set as Yes and minimum buffer value is set for stock point. In this case if the value of buffer after decrease suggested by DBM is lesser than the set value for the stock point, then item is marked in DBM suspension.</p>

                            <li>DBM Suspension - Newly Introduced Item</li>
                            <p>This parameter is used for DBM processing. If an item is newly introduced, then the consumption pattern is not set. In this case, that item is set under DBM suspension. Default setting is yes in this case.</p>

                            <li>DBM Suspension - No of Spikes less than x</li>
                            <p>This parameter is used in DBM processing. In this field, x is parameterized, and if the number of spikes is less than the x value, then the system will mark this item under buffer suspension. If the number of spikes is more than the set x value, the system will consider this item in DBM, and the DBM suggestion will show for the item.</p>

                            <li>DBM Suspension - Stock in White</li>
                            <p>This control parameter is used for processing DBM. If this parameter is set as Yes and the buffer for that item is increased and the item goes even once in white, then the system will mark this item in DBM suspension.</p>

                            <li>DBM Suspension - Supply issue</li>
                            <p>This control parameter is used for processing DBM. If this parameter is set as Yes and the buffer is increased after DBM suggestion, then the system will check the receipt from the item. If the receipt for that particular item is less than 1/3rd of the total buffer of that item, then the system will mark this item as buffer suspension.</p>

                            <li>First-Time-Buffer-Setting - Consumption Days</li>
                            <p>This control parameter will be used in First Time Buffer Setting processing. The system will consider the orders based on the consumption days in order to get the consumption. If the consumption days is 90, then the system will take orders of the last 90 days to calculate the consumption.</p>
                            <p>The default value is – 30</p>

                            <li>First-Time-Buffer-Setting - Consumption Variability</li>
                            <p>This control parameter will be used in First Time Buffer Setting processing. This is a variable factor taken into consideration while setting the buffer.</p>
                            <p>The default value is – 1.2</p>

                            <li>First-Time-Buffer-Setting - Forecast Days</li>
                            <p>This control parameter will be used in First Time Buffer Setting processing. This factor is used to calculate per day volume for Forecast.</p>
                            <p>The default value is – 30</p>

                            <li>First-Time-Buffer-Setting - Forecast Variability</li>
                            <p>This control parameter will be used in First Time Buffer Setting processing. This is a variable factor taken into consideration while setting the buffer.</p>

                            <p>The default value is – 1.2</p>

                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={C5} alt="C5" />
                            </Stack>
                            <br />

                            <li>First-Time-Buffer-Setting - Parameter (Consumption/Forecast/Both)</li>
                            <p>This control parameter will be used in First Time Buffer Setting processing. Based on the selection, the consumption will be calculated based on either actual transactions, forecast, or both.</p>
                            <p>If the Parameter is set as “Consumption”, then the actual consumption is taken into consideration. The same is maintained against the Stock Point Configuration. If the Parameter is set as “Forecast”, then the forecast entered by the user will be taken into consideration. If the Parameter is set as “Both”, then both will be considered and buffer for the stock point and item will be calculated based on both actual as well as forecasted data.</p>
                            <p>The default value is – Both</p>

                            <li>Inventory drop indication in BPR enabled</li>
                            <p>This control parameter is used to indicate buffer drop for the item in BPR (Buffer Penetration Report). If the parameter is set as yes and there is a sudden drop in buffer, then the system will indicate it with pink color. If the parameter is set as No, then this will not be applicable for that item.</p>

                            <li>Items-To-be-Kept-as-MTA - Consumption Contribution Percentage</li>
                            <p>This control parameter will be used in Items to be kept as MTA processing. Based on this parameter, the top items will be advised to be kept as MTA.</p>
                            <p>The default value is – 80</p>

                            <li>Items-To-be-Kept-as-MTA - Consumption Days</li>
                            <p>This control parameter will be used in Items to be kept as MTA processing. The system will consider the orders based on the consumption days in order to get the consumption. If the Consumption days is 90, then the system will take orders of the last 90 days to calculate the consumption.</p>
                            <p>The default value is – 30</p>

                            <li>Items-To-be-Kept-as-MTA - Consumption Parameter (Value/Volume)</li>
                            <p>This control parameter will be used in Items to be kept as MTA processing. Based on this parameter, the items will be ordered based on either value or quantity.</p>
                            <p>The default value is – Value</p>

                            <li>Items-To-be-Kept-as-MTA - Forecast Contribution Percentage</li>
                            <p>This control parameter will be used in Items to be kept as MTA processing. Based on this parameter, the top items will be advised to be kept as MTA.</p>
                            <p>The default value is – 80</p>

                            <li>Items-To-be-Kept-as-MTA - Forecast Days</li>
                            <p>This control parameter will be used in Items to be kept as MTA processing. This factor is used to calculate per day volume for Forecast.</p>
                            <p>The default value is – 30</p>

                            <li>Items-To-be-Kept-as-MTA - Forecast Parameter (Value/Volume)</li>
                            <p>This control parameter will be used in Items to be kept as MTA processing. Based on this parameter, the items will be ordered based on either value or quantity.</p>
                            <p>The default value is – Value</p>

                            <li>Items-To-be-Kept-as-MTA - Parameter (Consumption/Forecast/Both)</li>
                            <p>This control parameter will be used in Items to be kept as MTA processing. Based on the selection, the consumption will be calculated based on either actual transactions, forecast, or both.</p>
                            <p>If the Parameter is set as “Consumption”, then the actual consumption is taken into consideration. The same is maintained against the Stock Point Configuration. If the Parameter is set as “Forecast”, then the forecast entered by the user will be taken into consideration. If the Parameter is set as “Both”, then both will be considered and buffer for the stock point and item will be calculated based on both actual as well as forecasted data.</p>
                            <p>The default value is – Both.</p>

                            <li>Maintenance Sales Order Load – Enabled</li>
                            <p>This control parameter will be used in Pending Sales Order upload. If the parameter value is not maintained as “Yes” or “No”, then the same should be set as “Yes”. If the control parameter is set as “Yes”, then the pending maintenance sales orders will also be considered in pending orders load.</p>
                            <p>The default value is – No.</p>

                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={C6} alt="C6" />
                            </Stack>
                            <br />


                            <li style={{ backgroundColor: "yellow" }}>Maximum Buffer - Consumption Days</li>
                            <li style={{ backgroundColor: "yellow" }}>Maximum Buffer - No of Days Consumption</li>
                            <li style={{ backgroundColor: "yellow" }}>This parameter indicates that maximum buffer for an item is set as the product of the number of days maintained and the average consumption for that item.</li>

                            <li style={{ backgroundColor: "yellow" }}>Minimum Buffer - Consumption Days</li>
                            <li>Minimum Buffer - No of Days Consumption</li>
                            <p style={{ backgroundColor: "yellow" }}>This parameter indicates that minimum buffer for an item is set as the product of the number of days maintained and the average consumption for that item.</p>

                            <li>Percentage of Buffer for Newly Introduced Items to start DBM</li>
                            <p>This parameter is indicated when the buffer for a new item reaches the defined percentage value of the total buffer. For example, if the value is set as 33%, then when the buffer for that item reaches 33% of the total buffer fixed for that item, the system will start DBM monitoring for that item.</p>

                            <li>Price List</li>
                            <p>This parameter indicates the price list for buffer items. Here, all three price lists are maintained (MRP, WDL & CPL).</p>

                            <li>Price Matrix</li>
                            <p>This parameter indicates the price matrix for buffer items. Prices are maintained differently for different dealers or regions.</p>

                            <li>Production Order - Auto Transfer – Blocked</li>
                            <p>This control parameter will be used while sending the production orders to LN for generation. This parameter will block the auto production generation from the system. This will create the advice, but these orders will not be sent to ERP for auto generation. Instead, the order status will be set as “Auto Transfer - Blocked”. These orders can be viewed either by downloading the Production Order Advice - GnB CR Report or using the session – Inbound Order Advice - GnB CR (MTA à MTA Parameter) by selecting Inbound Order as “Production”.</p>
                            <p>The default value is – Yes.</p>
                            <br />
                            <br />
                            <br />
                            <li>Production-Order-Color-Status - Planned Production Orders – Enabled</li>
                            <p>This control parameter will be used in Production Order Color Status Processing. If the control parameter is set as “No”, then the production orders which are still in “Planned” status in LN will be excluded from processing.</p>
                            <p>The default value is – No.</p>

                            <li>Production-Order-Color-Status-Report - Stock-Point-Type wise Output – Enabled</li>
                            <p>This control parameter will be used in Production Order Color Status Report. If the parameter value is not maintained as “Yes” or “No”, then the same should be set as “No”. If the control parameter is set as “Yes”, then stock point wise reports will be generated. The file name will be – Production-Order-Color-Status-report-[Name of the Stock Point].</p>
                            <p>If the control parameter is set as “No”, then a single report will be generated. The file name will be – Production-Order-Color-Status-report.</p>
                            <p>The default value is – No.</p>

                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={C7} alt="C7" />
                            </Stack>
                            <br />

                            <li>Production-Order-Operation-Color-Status (Production Order / Operation)</li>
                            <p>This control parameter will be used in the following sessions:</p>

                            <li style={{ marginLeft: "3rem" }}>Production Order Operation Color Status Processing</li>
                            <li style={{ marginLeft: "3rem" }}>Production Order Operation Work Center Summary Processing</li>
                            <li style={{ marginLeft: "3rem" }}>Production Order Operation Color Status Report</li>
                            <li style={{ marginLeft: "3rem" }}>Production Order Operation Work Center Summary Report</li>

                            <p>The control parameter can have the values defined as either “Production Order” or “Operation”. If any other value is maintained, then the value is set as “Production Order”.</p>

                            <p>If the control parameter is “Operation”, then the pending quantity will be:</p>

                            <table border="0" cellspacing="0" cellpadding="0" width="561">
                                <tbody>
                                    <tr>
                                        <td width="102" valign="top">
                                            <p>Pending Qty</p>
                                        </td>
                                        <td width="30" valign="top" >
                                            <p>=</p>
                                        </td>
                                        <td width="428" valign="top">
                                            <p>Qty reported by Previous Operation – Qty reported by Current Operation</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br />
                            <br />
                            <br />


                            <p>If the control parameter is “Production Order”, then the pending quantity will be:</p>

                            <br />

                            <table border="0" cellspacing="0" cellpadding="0" width="561">
                                <tbody>
                                    <tr>
                                        <td width="102" valign="top" >
                                            <p>Pending Qty</p>
                                        </td>
                                        <td width="30" valign="top">
                                            <p>=</p>
                                        </td>
                                        <td width="428" valign="top" >
                                            <p>Total Qty – Qty reported</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br />
                            <br />
                            <br />

                            <p>Also, based on the parameter, the report format will also differ.</p>

                            <li>Production-Order-Operation-Color-Status-Report - Stock-Point-Type wise Output – Enabled</li>
                            <p>This control parameter will be used in Production Order Operation Color Status Report. If the parameter value is not maintained as “Yes” or “No”, then the same should be set as “No”. If the control parameter is set as “Yes”, then stock point wise reports will be generated. The file name will be – Production-Order-Color-Status-report-[Name of the Stock Point].</p>
                            <p>If the control parameter is set as “No”, then a single report will be generated. The file name will be – Production-Order-Color-Status-report.</p>
                            <p>The default value is – No.</p>

                            <li>Purchase Order - Auto Transfer – Blocked</li>
                            <p>This control parameter will be used while sending the purchase orders to LN for generation. This parameter will block the auto Purchase generation from the system. This will create the advice, but these orders will not be sent to ERP for auto generation. Instead, the order status will be set as “Auto Transfer - Blocked”. These orders can be viewed either by downloading the Purchase Order Advice - GnB CR Report or using the session – Inbound Order Advice - GnB CR (MTA à MTA Parameter) by selecting Inbound Order as “Purchase”.</p>
                            <p>The default value is – Yes.</p>

                            <li>Purchase Order Advice - Blocked Stock Quantity – Enabled</li>
                            <p>This control parameter will be used in Purchase Order Advice processing. If the control parameter is set as “Yes”, the blocked quantity will be considered as in transit. This parameter is given in order to include the material received from the supplier but which is still lying in inspection. If this is not added, then there will be excess demand shown to the supplier.</p>


                            <p>The default value is – No</p>

                            <li>Purchase Order Advice - Pending RPL Order – Enabled</li>
                            <p>This control parameter will be used in Purchase Order Advice processing. If the parameter is set as “Yes”, then pending RPLs (Warehouse Transfer Orders) will be considered as demand and the pending quantity will be added in the “New Required Qty”.</p>
                            <p>The default value is – No</p>

                            <li>Purchase Order Advice - Pending Sales Order – Enabled</li>
                            <p>This control parameter will be used in Purchase Order Advice processing. If the parameter is set as “Yes”, then pending sales orders will be considered as demand and the pending quantity will be added in the “New Required Qty”.</p>
                            <p>The default value is – No</p>

                            <li>Rationing - BufferItem - Pending Order (All / No Stock At Branch)</li>
                            <p>This control parameter will be used in Rationing processing. The control parameter can be set as either “No Stock At Branch” or “All”. If the value is not set as either of these values, then the value will be set as “No Stock At Branch”.</p>

                            <p>If the value is set as “No Stock At Branch”, then the pending orders will be considered only in the following scenarios:</p>
                            <li style={{ marginLeft: "3rem" }}>If no buffer is present against the stock point – item</li>
                            <li style={{ marginLeft: "3rem" }}>If buffer is present and there is no stock of that item at that destination stock point</li>

                            <p>If the value is set as “All”, then all the pending orders will be considered.</p>

                            <p>This parameter is applicable only if either of the following parameters are enabled:</p>
                            <li>Rationing - Pending RPL Order – Enabled</li>
                            <li>Rationing - Pending Sales Order – Enabled</li>
                            <li>Rationing - Pending UpCountry RPL Order - Enabled</li>

                            <p>The default value is – No Stock At Branch</p>

                            <li>Rationing - Pending RPL Order – Enabled</li>
                            <p>This control parameter will be used in Rationing processing. If the control parameter is set as “Yes”, then all the RPL (Warehouse Transfer) orders which are not Upcountry (whose source warehouse is linked to a Branch Stock Point) will be considered as load for rationing.</p>
                            <p>The default value is No</p>

                            <li>Rationing - Pending Sales Order – Enabled</li>
                            <p>This control parameter will be used in Rationing processing. If the control parameter is set as “Yes”, then all the sales orders will be considered as load for rationing.</p>
                            <p>The default value is No</p>

                            <li>Rationing - Pending UpCountry RPL Order - Enabled</li>
                            <p>This control parameter will be used in Rationing processing. If the control parameter is set as “Yes”, then all the RPL (Warehouse Transfer) orders which are Upcountry (whose source warehouse is linked to a Branch Stock Point) will be considered as load for rationing.</p>
                            <p>The default value is – No</p>


                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={C8} alt="C8" />
                            </Stack>
                            <br />

                            <li>Rationing - Priority (Buffer / Pending Order)</li>
                            <p>This control parameter will be used in Rationing processing. If the control parameter is set as “Pending Order”, then the source will first replenish against the pending orders. If the control parameter is set as “Buffer”, then the source will first replenish against the buffers maintained at the stock point – item code.</p>
                            <p>The default value is – Buffer</p>


                            <li>RPL Order - Auto Transfer – Blocked</li>
                            <p>This control parameter will be used while sending the warehouse transfer orders to LN for generation. This parameter will block the auto Warehouse Transfer generation from the system. This will create the advice, but these orders will not be sent to ERP for auto generation. Instead, the order status will be set as “Auto Transfer - Blocked”. These orders can be viewed either by downloading the RPL Order Advice - GnB CR Report or using the session – Inbound Order Advice - GnB CR (MTA à MTA Parameter) by selecting Inbound Order as “RPL”.</p>
                            <p>The default value is – Yes</p>

                            <li style={{ backgroundColor: "yellow" }}>RPL Order Advice - Count of Lines per WTO Order (Max 10)</li>
                            <p style={{ backgroundColor: "yellow" }}>This control parameter will be used to...</p>
                            <p style={{ backgroundColor: "yellow" }}>The default value is – 10</p>

                            <li>RPL Order Advice - Truck Load Factor – Enabled</li>
                            <p>This control parameter will be used to generate RPL order considering Truck load. This means if the value is maintained as Yes, then while generating orders, the system will consider the defined truck load. The default value is – No.</p>

                            <li style={{ backgroundColor: "yellow" }}>RPL-BPP-and-Color - Auto Transfer – Blocked</li>

                            <li>Sales Order Allocation - Auto Transfer – Blocked</li>
                            <p>This control parameter will be used while sending the sales orders to LN for updating the Color Priority and BPP. This parameter will block the auto Sales generation from the system. This will create the advice, but these orders will not be sent to ERP for auto generation. Instead, the order status will be set as “Auto Transfer - Blocked”. These orders can be viewed either by downloading the Sales Order Advice - GnB CR Report or using the session – Inbound Order Advice - GnB CR (MTA à MTA Parameter) by selecting Inbound Order as “Sales Allocation”.</p>
                            <p>The default value is – Yes</p>

                            <li>Service Order Load – Enabled</li>
                            <p>This control parameter will be used while loading the Service Orders. If the parameter is Yes, then only the Service Orders will be uploaded in the system.</p>
                            <p>The default value is – No</p>

                            <li style={{ backgroundColor: "yellow" }}>Stock Point Item Configuration - Batch Quantity from Item Master – Enabled</li>
                            <p style={{ backgroundColor: "yellow" }}>This control parameter will be used for rationing purposes. If this parameter is Yes, the system will consider batch quantity for rationing logic for replenishment of the Buffer.</p>
                            <p style={{ backgroundColor: "yellow" }}>The default value is – No</p>

                            <li>Stock-Availability-Report-for-Top-SKUs - Consumption Days</li>
                            <p>This control parameter will be used to generate a stock report for top consumption items for the number of days defined in this parameter. If no value is maintained in the field, then the default value is set as 30 days.</p>
                            <p>The default value is – 30</p>

                            <li>Stock-Availability-Report-for-Top-SKUs - Top N SKUs</li>
                            <p>This control parameter will be used to generate stock for only the number of items defined in this field. This means if the parameter is set as 100, then the system will show stock availability for the top 100 items only. If the default value is not maintained, the default value is set as 100.</p>
                            <p>The default value is – 100</p>


                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={C9} alt="C9" />
                            </Stack>
                            <br />

                            <li>Stock-Point-Configuration - Default Value Display - Area Code</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (blank)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Buffer Penetration Parameter</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (QAPL)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Consumption to be taken from</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (blank)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Intransit to be taken from</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (Stock_Point_Warehouse)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Number of RPL Cycles for decrease in buffer</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (2)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Number of RPL Cycles for Increase in buffer</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (2)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Percentage decrease by DBM</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (33)</p>

                            <br />
                            <br />

                            <li>Stock-Point-Configuration - Default Value Display - Percentage increase by DBM</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (33)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Rationing Logic to be Used</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (NA for Branch, Batch_Quantity for Others)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Regular Warehouse</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (blank)</p>


                            <li>Stock Allocation for Branch Buffer consider Source Safety Stock - This control parameter is used to consider source safety stock for stock allocation for branch buffer.</li>
                            <p>The default value is – (NO). User can edit and put desired value.</p>

                            <li>Stock Allocation for Branch Buffer Set Source Safety Stock % Value - This control parameter is used to suggest source safety stock % for considering stock allocation for branch buffer.</li>
                            <p>The default value is – (33). User can edit and put desired value.</p>

                            <br />
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={C10} alt="C10" />
                            </Stack>
                            <br />

                            <li>Stock-Point-Configuration - Default Value Display - Replenishment Days</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration. This value can be updated in stock point configuration. If a value is set in this field, then it will be applicable for all stock points as the default value.</p>
                            <p>The default value is – (blank)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Source Stock Point Percentage</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration. This value can be updated in stock point configuration. If a value is set in this field, then it will be applicable for all stock points as the default value.</p>
                            <p>The default value is – (blank)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Spike Percentage</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration. This value can be updated in stock point configuration. If a value is set in this field, then it will be applicable for all stock points as the default value.</p>
                            <p>The default value is – (blank)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Spike Warehouse</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (blank)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Top of Red Percentage</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>


                            <p>The default value is – (33)</p>

                            <li>Stock-Point-Configuration - Default Value Display - Top of Yellow Percentage</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – (66)</p>
                            <br />
                            <br />
                            <li>Use Stock After MTO Allocation – Enabled</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – No</p>

                            <li>Virtual Production Orders – Enabled</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – No</p>

                            <li>Virtual Purchase Orders - Enabled</li>
                            <p>This control parameter will be used to display the suggestion while maintaining the Stock Point Configuration.</p>
                            <p>The default value is – No</p>


                            <br />
                            <br />
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={C11} alt="C11" />
                            </Stack>
                            <br />
                            <li>Target inventory with intransit for inventory calculation. (% of TOG) – This parameter is used for Target inventory report where the target is decided on the percentage of TOG. 70% is the default value for the same. This parameter decides inventory with intransit. User can edit and put the desired value.</li>

                            <li>Target inventory without intransit for inventory calculation. (% of TOG) – This parameter is used for Target inventory report where the target is decided on the percentage of TOG. 50% is the default value for the same. This parameter decides inventory without intransit. User can edit and put the desired value.</li>

                            <li>To generate consolidated line for RPL advices - This control parameter is used to generate consolidated lines in RPL Advice output. The default value is – (NO). User can edit and put the desired value.</li>


                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ControlhelpPopup;
