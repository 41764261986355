import React, { useEffect, useState } from "react";
import "../../../HelpPopups/HelpPopup.css";
import Stack from "@mui/material/Stack";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import modules from '../../ccpm.module.css'
import { Box, Grid, Typography } from "@mui/material";
import bread from "../../../../Assets/bread.png";
import { PendingTaskPopup } from "../../../../Services/CCPM";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const ViewProjProp = () => {
    const [data, setData] = useState([])
    useEffect(() => {
        let body = {
            projectId: 0
        }
        PendingTaskPopup(body)
            .then((response) => {
                console.log(response)
                setData(response?.data?.success?.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    return (
        <div style={{ overflowX: 'auto' }}>
            <Stack
                direction="row"
                spacing={4}
                sx={{ paddingTop: "1rem" }}
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                    <img src={logo} alt="Godrej" />
                </Stack>
                <Stack sx={{ paddingRight: "1rem" }}>
                    <img src={prostream} alt="Prostream" />
                </Stack>
            </Stack>
            <Box sx={{ padding: "5px" }}>
                <Typography sx={{ fontSize: "16px", fontFamily: "times new roman" }}>
                    Project Properties
                </Typography>
                <Box sx={{marginLeft:'5rem'}}>
                    <table className={modules["custom-tableRM"]}>
                        <tbody>
                            <tr>
                                <td style={{width:'12rem',textAlign:'center'}}><span>Project Start Date:</span></td>
                                <td style={{width:'12rem',textAlign:'center'}}><span>27/08/2024</span></td>
                            </tr>
                            <tr>
                                <td style={{width:'12rem',textAlign:'center'}}><span>Customer Due Date:</span></td>
                                <td style={{width:'12rem',textAlign:'center'}}><span>27/08/2024</span></td>
                            </tr>
                            <tr>
                                <td style={{width:'12rem',textAlign:'center'}}><span>Project Duration:</span></td>
                                <td style={{width:'12rem',textAlign:'center'}}><span>2.00</span></td>
                            </tr>
                            <tr>
                                <td style={{width:'12rem',textAlign:'center'}}><span>Critical Chain:</span></td>
                                <td style={{width:'12rem',textAlign:'center'}}><span>1.00</span></td>
                            </tr>
                            <tr>
                                <td style={{width:'12rem',textAlign:'center'}}><span>Project Buffer:</span></td>
                                <td style={{width:'12rem',textAlign:'center'}}><span>1.00</span></td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </Box>
            <Box sx={{ padding: "5px" }}>
                <Typography sx={{ fontSize: "16px", fontFamily: "times new roman" }}>
                    CCPM Setting
                </Typography>
                <Box sx={{marginLeft:'5rem'}}>
                    <table className={modules["custom-tableRM"]}>
                        <tbody>
                            <tr>
                                <td style={{width:'19rem',textAlign:'center'}}><span>Project Name:</span></td>
                                <td style={{width:'19rem',textAlign:'left'}}><span>PwC team2 GnB</span></td>
                            </tr>
                            <tr>
                                <td style={{width:'19rem',textAlign:'center'}}><span>%  Reduction From Task Duration:</span></td>
                                <td style={{width:'19rem',textAlign:'left'}}><input readOnly value="50.00"/></td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td style={{width:'19rem',textAlign:'center'}}><span>% Project Buffer:</span></td>
                                <td style={{width:'19rem',textAlign:'left'}}><input readOnly value="50.00"/></td>
                            </tr>
                            <tr>
                                <td style={{width:'19rem',textAlign:'center'}}><span>% Feeding Buffer:</span></td>
                                <td style={{width:'19rem',textAlign:'left'}}><input readOnly value="50.00"/></td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </Box>
        </div>
    );
};

export default ViewProjProp;

