import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  CM_InstanceData,
  CM_UpdRow,
  CM_AddNew,
  CM_DelRow,
  CM_GetTableData,
} from "../../../Services/CCPM";
import { IoOptions } from "react-icons/io5";

const CustomerMaster = () => {
  const [searchCat, setSearchCat] = useState("");
  const [addCat, setAddCat] = useState("");
  const [dropdownData, setDropdownData] = useState([]);
  const [mainDropdownData, setMainDropdownData] = useState([]);
  const [displayvalue, setDisplayValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [editing, setEditing] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [editCustomerName, setEditCustomerName] = useState("");
  const [failedCheck, setFailedCheck] = useState(false);
  const [isEntered, setIsEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    checkLoginAndRedirect();
    let body = {
      userId: sessionStorage.getItem("loginId"),
    };
    setIsLoading(true);

    CM_InstanceData(body)
      .then((response) => {
        if (response.status === 200) {
          const apiData = response.data;
          const data = apiData.success.data;
          const names = data.map((item) => item.name);
          setDropdownData(names);
          setIsLoading(false);
          setMainDropdownData(data);
          if (names.length > 0) {
            setDisplayValue(names[0]);
            setIsLoading(false);
            setSelectedId(data[0].id);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const handleChange = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.name === selectedName
    );
    setShowTable(false);
    setFailedCheck(false);
    setDisplayValue(selectedName);
    setSelectedId(selectedItem.id);
  };

  const handleSearch = () => {
    let body = {
      instanceId: selectedId,
      customerNm: searchCat !== "" ? searchCat : "",
    };

    CM_GetTableData(body)
      .then((response) => {
        if (response.status === 200) {
          setFailedCheck(false);
          const apiData = response.data;
          const data = apiData.success.data;
          if (data.length === 0) {
            setShowTable(false);
            setFailedCheck(true);
          } else {
            setFilteredData(data);
            setShowTable(true);
          }
        } else {
          setFailedCheck(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCatAdd = () => {
    const reasonExists = filteredData.some(
      (item) => item.customername.toLowerCase() === addCat.toLowerCase()
    );
    if (addCat === "") {
      alert("Please Enter Customer Name to be Added.");
    } else if (reasonExists) {
      alert("Customer Name already Exists for Selected Instance");
      return;
    } else {
      let body = {
        instanceId: selectedId,
        customerName: addCat,
        levelId: selectedId,
        createdBy: sessionStorage.getItem("loginId"),
      };
      CM_AddNew(body)
        .then((response) => {
          if (response.status === 200) {
            alert("Details Inserted Succesfully");
            handleSearch();
            setAddCat("");
          } else if (response?.response?.status === 400) {
            alert("Customer Name already Exists for Selected Instance.");
          } else {
            alert("Error in Inserting Data");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleReset = () => {
    setSearchCat("");
    setFilteredData([]);
    setShowTable(false);
    setFailedCheck(false);
    setDisplayValue(dropdownData[0]);
  };

  const handleEdit = (item, element) => {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(item.customername);
      setEditCustomerName(item.customername);
      element.style.color = "#0000EE";
    }, 50);
  };

  const handleUpdate = (item, element) => {
    element.style.color = "red";
    setTimeout(() => {
      element.style.color = "#0000EE";
      setTimeout(() => {
        const reasonExists = filteredData.some(
          (item) =>
            item.customername.toLowerCase() === editCustomerName.toLowerCase()
        );
        if (item.customername === editCustomerName) {
          alert(
            "No Update:Customer Name already Exists for Selected Instance."
          );
          element.style.color = "#0000EE";

          return;
        } else if (reasonExists) {
          alert("No Update:Customer Name already Exists for Selected Instance");
          element.style.color = "#0000EE";

          return;
        } else {
          let body = {
            id: item.id,
            customerName: editCustomerName,
            levelId: selectedId,
            lastModifiedBy: sessionStorage.getItem("loginId"),
            instanceId: selectedId,
          };
          CM_UpdRow(body)
            .then((response) => {
              if (response.status === 200) {
                handleSearch();
                setEditing(null);
                alert("Details Updated Successfully");
              } else if (response?.response?.status === 400) {
                alert(response?.response?.data?.message);
                element.style.color = "#0000EE";

                // alert("No Update:Customer Name already Exists for Selected Instance.");
              } else {
                setEditing(null);
                element.style.color = "#0000EE";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        element.style.color = "#0000EE";
      }, 50);
    }, 50);
  };

  const handleDelete = (item, element) => {
    element.style.color = "red";

    setTimeout(() => {
      const confirmDelete = window.confirm(`Are you sure you want to delete?`);

      if (confirmDelete) {
        let body = {
          id: item.id,
          lastModifiedBy: sessionStorage.getItem("loginId"),
        };
        CM_DelRow(body)
          .then((response) => {
            if (response.status === 200) {
              handleSearch();
              element.style.color = "#0000EE";
            } else {
              element.style.color = "#0000EE";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        element.style.color = "#0000EE";
      }
    }, 100);
  };

  const handleCancel = (element) => {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(null);
      setEditCustomerName("");
      element.style.color = "#0000EE";
    }, 50);
  };

  const searchCatBlur = () => {
    if (isEntered) {
      const disallowedRegex = /[=+\[\]./]/;
      if (disallowedRegex.test(searchCat)) {
        alert("No special characters allowed");
        setSearchCat("");
      }
    }
  };

  function handleItemEnter(e) {
    if (e.key === "Enter") {
      setIsEntered(false);
      const disallowedRegex = /[=+\[\]./]/;
      if (disallowedRegex.test(searchCat)) {
        alert("No special characters allowed");
        setSearchCat("");
      }
    }
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"CustomerMaster"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>Master</a>
                <img src={bread} alt="" />
                <a> Customer Master </a>
              </div>
            </div>

            <Grid sx={{ marginLeft: "2rem !important" }}>
              <Grid container className={modules["mid-cont"]}>
                <Grid container columnSpacing={{ xs: 2 }}>
                  <Grid item className={modules["label-style"]}>
                    <Typography
                      className={modules["label-style"]}
                      style={{ fontWeight: "bold" }}
                    >
                      Instance :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControl className={modules["form-control"]}>
                      <select
                        IconComponent={ArrowDropDownIcon}
                        className={modules["dropdownSelect"]}
                        style={{ width: "18rem" }}
                        value={displayvalue}
                        onChange={handleChange}
                      >
                        {dropdownData.map((i) => {
                          return (
                            <option
                              style={{ fontSize: "12px" }}
                              value={i}
                              key={i}
                            >
                              {i}
                            </option>
                          );
                        })}
                      </select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Typography className={modules["label-style"]}>
                      <span> Customer Name :</span>
                    </Typography>
                  </Grid>
                  {/* <Grid item>:</Grid> */}
                  <Grid item>
                    <span>
                      <input
                        value={searchCat}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /[^a-zA-Z0-9 &_()-]/g;
                          const disallowedRegex = /[=+\[\]./]/;
                          if (disallowedRegex.test(value)) {
                            setSearchCat(e.target.value);
                          } else {
                            const sanitizedValue = value.replace(regex, "");
                            setSearchCat(sanitizedValue);
                          }
                        }}
                        onBlur={searchCatBlur}
                        onKeyDown={handleItemEnter}
                        onFocus={() => setIsEntered(true)}
                        style={{
                          marginLeft: "0rem",
                          height: "1.5rem",
                          width: "18rem",
                        }}
                        className={modules["input_css"]}
                      />
                    </span>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                className={modules["custMas_btn1-cont"]}
                // style={{ paddingLeft: "12rem" }}
              >
                <Stack direction="row" spacing={1}>
                  <button className={modules["btn2"]} onClick={handleSearch}>
                    Search
                  </button>
                  <button className={modules["btn2"]} onClick={handleReset}>
                    Reset
                  </button>
                </Stack>
              </Grid>

              <Grid container className={modules["mid-cont"]}>
                <Grid container columnSpacing={{ xs: 2 }}>
                  <Grid item>
                    <Typography
                      className={modules["label-style"]}
                      style={{ fontWeight: "bold" }}
                    >
                      Customer Name :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Stack direction="row" spacing={2}>
                      <span>
                        <input
                          value={addCat}
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex = /[^a-zA-Z0-9 _-]/g;
                            const sanitizedValue = value.replace(regex, "");
                            setAddCat(sanitizedValue);
                          }}
                          className={modules["input_css"]}
                          style={{
                            marginLeft: "0rem",
                            height: "1.5rem",
                            width: "20rem",
                          }}
                        />
                      </span>
                      <button
                        className={modules["btn2"]}
                        onClick={handleCatAdd}
                        style={{ marginTop: "0rem" }}
                      >
                        Add Customer
                      </button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showTable ? (
          <>
            {/* <Stack
              direction="row"
              sx={{
                width: "70%",
                marginLeft: "6rem",
                marginBottom: "4%",
                marginTop: "2rem",
              }}
              spacing={2}
            > */}
            <Grid
              container
              style={{ width: "44%", marginLeft: "9rem", marginTop: "2.5rem" }}
            >
              {/* <div> */}
              <Table className={modules["custom-table"]}>
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No.</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className={modules["table-cellCM"]}>
                        <Typography className={modules["label-style"]}>
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell className={modules["table-cellCM"]}>
                        {editing === item.customername ? (
                          <input
                            type="text"
                            value={editCustomerName}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /[^a-zA-Z0-9 _-]/g;
                              const sanitizedValue = value.replace(regex, "");
                              setEditCustomerName(sanitizedValue);
                            }}
                          />
                        ) : (
                          <div>
                            <Typography className={modules["label-style"]}>
                              {item.customername}
                            </Typography>
                          </div>
                        )}
                      </TableCell>
                      <TableCell className={modules["table-cellCM"]}>
                        {editing === item.customername ? (
                          <>
                            <a
                              style={{
                                paddingRight: "1rem",
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                handleUpdate(item, e.currentTarget)
                              }
                            >
                              Update
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleCancel(e.currentTarget)}
                            >
                              Cancel
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              style={{
                                paddingRight: "1rem",
                                cursor: "pointer",
                              }}
                              onClick={(e) => handleEdit(item, e.currentTarget)}
                            >
                              Edit
                            </a>
                            <a
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                handleDelete(item, e.currentTarget)
                              }
                            >
                              Delete
                            </a>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* </div> */}
            </Grid>
          </>
        ) : failedCheck ? (
          <>
            <Stack alignItems="center" sx={{ width: "75%", marginTop:"3%" }}>
              <Typography className={modules["norecFound"]}>No Records found.</Typography>
            </Stack>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default CustomerMaster;
