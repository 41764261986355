import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import bread from "../../../Assets/bread.png";
import {
  Set_MtaActiveUpdateAll,
  Set_MtaActiveUpdateSingle,
  Set_MtaActiveData,
} from "../../../Services/MTA.js";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function MTAInstanceActive() {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [changed, setChanged] = useState(false);
  const [allSelected, setAllSelected] = useState("N");

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getTableData();
  }, []);

  const getTableData = () => {
    setIsLoading(true);
    const body = {
      userid: sessionStorage.getItem("loginUsername"),
    };
    Set_MtaActiveData(body)
      .then((response) => {
        setTableData(response?.data?.success?.data);
        const allSelected = response?.data?.success?.data.every(
          (item) => item.isselected === 1
        );
        setAllSelected(allSelected ? "Y" : "N");
        console.log(response?.data?.success?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleSelectAll = (newStatus) => {
    console.log(newStatus);
    const body = {
      active_InActive_Status: newStatus,
      modified_Userid: sessionStorage.getItem("loginUsername"),
    };
    setIsLoading(true);
    if (newStatus === "Y") {
      Set_MtaActiveUpdateAll(body)
        .then((response) => {
          // console.log(response?.data?.success?.data);
          setIsLoading(false);
          getTableData();
          setTimeout(() => {
            alert("All Instance are Successfully Active");
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (newStatus === "N") {
      if (window.confirm("All Instance will be inactive")) {
        Set_MtaActiveUpdateAll(body)
          .then((response) => {
            setIsLoading(false);
            getTableData();
            setTimeout(() => {
              alert("All Instance are Successfully Inactive");
            }, 100);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleChange = () => {
    if (allSelected === "Y") {
      const para = "N";
      toggleSelectAll(para);
      setAllSelected("N");
    } else if (allSelected === "N") {
      const para = "Y";
      toggleSelectAll(para);
      setAllSelected("Y");
    } else {
    }
  };

  const handleCheckboxChange = (e, id, change) => {
    const body = {
      mtO_InstanceId: id,
      active_InActive_Status: change,
      modified_Userid: sessionStorage.getItem("loginUsername"),
    };
    setIsLoading(true);
    if (change === "Y") {
      Set_MtaActiveUpdateSingle(body)
        .then((response) => {
          // console.log(response?.data?.success?.data);
          setIsLoading(false);
          getTableData();
          setTimeout(() => {
            alert(`${id} is Successfully Active`);
          }, 50);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (change === "N") {
      if (window.confirm(`Instance will be inactive`)) {
        Set_MtaActiveUpdateSingle(body)
          .then((response) => {
            // console.log(response?.data?.success?.data);
            setIsLoading(false);
            getTableData();
            setTimeout(() => {
              alert(`${id} is Successfully InActive`);
            }, 50);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
      }
    }
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block", overflowX:"hidden" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"MTAInstanceActive"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>Instance Active/InActive</a>
              </div>
            </div>

            <Grid sx={{ marginLeft: "3rem" }}>
              <Grid container className={modules["mid-cont"]}>
                <TableContainer>
                  <Table
                    className={modules["custom_table"]}
                    style={{ width: "35%" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">MTA Instance</TableCell>
                        <TableCell padding="checkbox">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "0px 30px",
                            }}
                          >
                            <input
                              type="checkbox"
                              inputProps={{
                                "aria-label": "select all desserts",
                              }}
                              checked={allSelected === "Y"}
                              className={modules["custom-checkbox"]}
                              onChange={handleChange}
                            />
                            <span style={{ padding: "0px 4px" }}>
                              Active/InActive
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData?.map((i, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            // key={i.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="center">{i.miuid_instance_uid}</TableCell>
                            <TableCell padding="checkbox" align="center">
                              <input
                                type="checkbox"
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                // checked={selectedRows.includes(i.id)}
                                checked={i.isselected === 1}
                                className={modules["custom-checkbox"]}
                                onChange={(e) => {
                                  if (i.isselected === 1) {
                                    const para = "N";
                                    handleCheckboxChange(
                                      e,
                                      i.miuid_instance_uid,
                                      para
                                    );
                                  } else if (i.isselected === 0) {
                                    const para = "Y";
                                    handleCheckboxChange(
                                      e,
                                      i.miuid_instance_uid,
                                      para
                                    );
                                  }
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
