import React, { useEffect, useState } from "react";
import bread from "../../../Assets/bread.png";
import i1 from "../../../Assets/i1.jpg";
import i2 from "../../../Assets/i2.jpg";
import i3 from "../../../Assets/i3.jpg";
import i4 from "../../../Assets/i4.jpg";
import i5 from "../../../Assets/i5.jpg";
import i6 from "../../../Assets/i6.png";
import viewp1 from "../../../Assets/viewp1.png";
import viewp2 from "../../../Assets/viewp2.png";
import viewp3 from "../../../Assets/viewp3.png";
import RaiseIssue from "../../../Assets/TM_RaiseIssue.jpg";
import SubTask from "../../../Assets/TM_SubTask.png";
import Predecessor from "../../../Assets/TM_Preceder.jpg";
import successor from "../../../Assets/TM_successor.jpg";
import UserNotes from "../../../Assets/TM_usernotes.jpg";
import notes from "../../../Assets/TM_notes.jpg";
import submit from "../../../Assets/TM_submit.jpg";
import LoadingPink from "../../../Assets/LoadingPink.gif";

import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Stack,
  Popover,
  Paper,
} from "@mui/material";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import loading from "../../../Assets/Loading_image.gif";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  MyProjGetInstance,
  MyProjGetAttributes,
  ViewProjSubmit,
  ViewProjGetStatus,
  ViewProjGetManager,
  ViewProjGetColor,
  ViewProjGetCustomer,
  ViewProjGetProject,
  GetTaskManagerDetails,
  GetTaskStatusDetails,
  GetTaskColorDetails,
  GetTaskNotesDetails,
  GetViewTaskTable,
} from "../../../Services/CCPM";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const ViewTasks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viewtaskinstance =
    searchParams.get("instance") && parseInt(searchParams.get("instance"));

  const [showTable, setShowTable] = useState(false);
  const [headers, setHeaders] = useState([]); //attribute labels

  const [displaydate1, setDisplaydate1] = useState("");
  const [displaydate2, setDisplaydate2] = useState("");

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);

  const id1 = open1 ? "simple-popover" : undefined;
  const id2 = open2 ? "simple-popover" : undefined;

  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: "short" }).slice(0, 2);
  };

  //loading gif
  const [isLoading, setIsLoading] = useState(false);

  //LIST OF DROPDOWN DATA
  const [instance, setInstance] = useState([]);
  const [ProjectName, setProjectName] = useState([]);
  const [ProjectManager, setProjectManager] = useState([]);
  const [ProjectAccessType, setProjectAccessType] = useState([]);
  const [ProjectStatus, setProjectStatus] = useState([]);
  const [ProjectColor, setProjectColor] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [Customer, setCustomer] = useState([
    {
      id: 1,
      customername: "All Tasks",
    },
    {
      id: 2,
      customername: "Only Available Tasks",
    },
  ]);
  const [dateType, setDateType] = useState([]);

  //SELECTED VALUES
  const [selectedInstance, setSelectedInstance] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedProjectManager, setSelectedProjectManager] = useState("");
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("");
  const [selectedProjectColor, setSelectedProjectColor] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("Select");
  const [selectedDateType, setSelectedDateType] = useState("");
  const [visibleExelButton, setVisibleExelButton] = useState(false);
  const [isInstanceClick, setIsInstanceClick] = useState(false)
  const today = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedToday = today.toLocaleDateString("en-US", options);

  const handleCheckboxChange1 = (event) => {
    const isChecked = event.target.checked;
    setIsCheckboxChecked(isChecked);
    if (isChecked) {
      // Automatically select "All Tasks"
      setSelectedCustomer("2"); // Assuming '1' is the id for "All Tasks"
    } else {
      // Reset to default value
      setSelectedCustomer("Select");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    let body = {
      userId: sessionStorage.getItem("loginId"),
      // userId: 2160
    };
    MyProjGetInstance(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setInstance(response.data.success.data);
          {
            viewtaskinstance
              ? handleInstance(viewtaskinstance)
              : handleInstance(response.data.success.data[0].id);
          }
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [passdate1, setPassdate1] = useState("");
  const [passdate2, setPassdate2] = useState("");
  const [calendarOpen1, setCalendarOpen1] = useState(false);
  const [calendarOpen2, setCalendarOpen2] = useState(false);

  const [passdate1submit, setPassdate1submit] = useState("");
  const [passdate2submit, setPassdate2submit] = useState("");
  const handleCalendar1n = (date) => {
    setAnchorEl1(null);
    console.log(date.toString());
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");
    console.log(formattedDate);
    setFromdate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    console.log(`${day}/${month}/${year}`);
    // setDisplaydate1(`${day}/${month}/${year}`)
    setDisplaydate1(formattedDate);
    setPassdate1(`${month}/${day}/${year}`);
    setPassdate1submit(`${year}-${month}-${day}`);
    setCalendarOpen1(false);

    if (passdate2 !== "") {
      let body = {
        instanceId:
          selectedInstance !== "" && selectedInstance !== "Select"
            ? parseInt(selectedInstance)
            : 0,
        userId: parseInt(sessionStorage.getItem("loginId")),
        includeViewAccess: 1,
        isSpecificProjStatus: -1,
        dateType: selectedDateType,
        // dateType: "",
        // fromDate: passdate1 !== "" ? passdate1 : "",
        fromDate: `${month}/${day}/${year}`,
        toDate: passdate2,
        // toDate: passdate2 !== "" ? passdate2 : ""
      };
      ViewProjGetProject(body)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            setProjectName(response.data.success.data);
          } else {
            alert(response?.response?.data?.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleCalendar2n = (date) => {
    setAnchorEl2(null);
    console.log(date.toString());
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");
    console.log(formattedDate);
    setTodate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    console.log(`${day}/${month}/${year}`);
    // setDisplaydate1(`${day}/${month}/${year}`)
    setDisplaydate2(formattedDate);
    setPassdate2(`${month}/${day}/${year}`);
    setPassdate2submit(`${year}-${month}-${day}`);
    setCalendarOpen2(false);

    if (passdate1 !== "") {
      let body = {
        instanceId:
          selectedInstance !== "" && selectedInstance !== "Select"
            ? parseInt(selectedInstance)
            : 0,
        userId: parseInt(sessionStorage.getItem("loginId")),
        includeViewAccess: 1,
        isSpecificProjStatus: -1,
        dateType: selectedDateType,
        // dateType: "",
        // fromDate: passdate1 !== "" ? passdate1 : "",
        fromDate: passdate1,
        toDate: `${month}/${day}/${year}`,
        // toDate: passdate2 !== "" ? passdate2 : ""
      };
      ViewProjGetProject(body)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            setProjectName(response.data.success.data);
          } else {
            alert(response?.response?.data?.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getattributes = (i) => {
    setIsLoading(true);
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      instanceId: parseInt(i),
    };
    MyProjGetAttributes(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          const dropdowns = response?.data?.success?.data;
          const activeLabels = dropdowns.labels?.filter(
            (label) => label.isactive === 1
          );
          setHeaders(activeLabels);
          console.log(activeLabels);
          const activeDropdowns = activeLabels?.map((label) => {
            const correspondingValues =
              dropdowns.values[label.attributenumber - 1];
            return {
              label: label.attributename,
              values: correspondingValues,
            };
          });
          setDropdownss(activeDropdowns);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDateType = (i) => {
    console.log(i);
    setSelectedDateType(i);
    if (i === "" || i === "Select") {
      setPassdate1("");
      setPassdate2("");
      setPassdate1submit("");
      setPassdate2submit("");
      let body = {
        instanceId:
          selectedInstance !== "" && selectedInstance !== "Select"
            ? parseInt(selectedInstance)
            : 0,
        userId: parseInt(sessionStorage.getItem("loginId")),
        includeViewAccess: 1,
        isSpecificProjStatus: -1,
        dateType: "",
        // dateType: "",
        // fromDate: passdate1 !== "" ? passdate1 : "",
        fromDate: "",
        toDate: "",
        // toDate: passdate2 !== "" ? passdate2 : ""
      };
      ViewProjGetProject(body)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            setProjectName(response.data.success.data);
          } else {
            alert(response?.response?.data?.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleProjectStatus = (i) => {
    setSelectedProjectStatus(i);
    let body1 = {
      instanceId:
        selectedInstance !== "" && selectedInstance !== "Select"
          ? parseInt(selectedInstance)
          : 0,
      userId: parseInt(sessionStorage.getItem("loginId")),
      // projStatusId: selectedProjectStatus === '' || selectedProjectStatus === 'Select' ? 0 : parseInt(selectedProjectStatus),
      projStatusId: i === "" || i === "Select" ? 0 : parseInt(i),
    };
    let body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      level2Id:
        selectedInstance !== "" && selectedInstance !== "Select"
          ? parseInt(selectedInstance)
          : 0,
      level3Id: 0,
      level4Id: 0,
      level5Id: 0,
      level6Id: 0,
      level7Id: 0,
      level8Id: 0,
      level9Id: 0,
      level10Id: 0,

      projectsId:
        selectedProjectName === "" || selectedProjectName === "Select"
          ? 0
          : parseInt(selectedProjectName),
      projectManagerId:
        selectedProjectManager === "" || selectedProjectManager === "Select"
          ? 0
          : parseInt(selectedProjectManager),
      // projectStatusId: selectedProjectStatus === '' || selectedProjectStatus === 'Select' ? 0 : parseInt(selectedProjectStatus),
      projectStatusId: i === "" || i === "Select" ? 0 : parseInt(i),
      projectColorId:
        selectedProjectColor === "" || selectedProjectColor === "Select"
          ? 0
          : parseInt(selectedProjectColor),
      customerId:
        selectedCustomer === "" || selectedCustomer === "Select"
          ? 0
          : parseInt(selectedCustomer),
    };
    ViewProjGetManager(body1)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setProjectManager(response.data.success.data);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleProjectName = (i) => {
    setSelectedProjectName(i);
    let body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      level2Id:
        selectedInstance !== "" && selectedInstance !== "Select"
          ? parseInt(selectedInstance)
          : 0,
      level3Id: 0,
      level4Id: 0,
      level5Id: 0,
      level6Id: 0,
      level7Id: 0,
      level8Id: 0,
      level9Id: 0,
      level10Id: 0,

      projectsId: i === "" || i === "Select" ? 0 : parseInt(i),
      projectManagerId:
        selectedProjectManager === "" || selectedProjectManager === "Select"
          ? 0
          : parseInt(selectedProjectManager),
      projectStatusId:
        selectedProjectStatus === "" || selectedProjectStatus === "Select"
          ? 0
          : parseInt(selectedProjectStatus),
      projectColorId:
        selectedProjectColor === "" || selectedProjectColor === "Select"
          ? 0
          : parseInt(selectedProjectColor),
      customerId:
        selectedCustomer === "" || selectedCustomer === "Select"
          ? 0
          : parseInt(selectedCustomer),
    };
  };

  const handleProManager = (i) => {
    setSelectedProjectManager(i);
    let body2 = {
      instanceId:
        selectedInstance !== "" && selectedInstance !== "Select"
          ? parseInt(selectedInstance)
          : 0,
      userId: parseInt(sessionStorage.getItem("loginId")),
    };
  };

  const handleColor = (i) => {
    setSelectedProjectColor(i);
    let body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      level2Id:
        selectedInstance !== "" && selectedInstance !== "Select"
          ? parseInt(selectedInstance)
          : 0,
      level3Id: 0,
      level4Id: 0,
      level5Id: 0,
      level6Id: 0,
      level7Id: 0,
      level8Id: 0,
      level9Id: 0,
      level10Id: 0,

      projectsId:
        selectedProjectName === "" || selectedProjectName === "Select"
          ? 0
          : parseInt(selectedProjectName),
      projectManagerId:
        selectedProjectManager === "" || selectedProjectManager === "Select"
          ? 0
          : parseInt(selectedProjectManager),
      projectStatusId:
        selectedProjectStatus === "" || selectedProjectStatus === "Select"
          ? 0
          : parseInt(selectedProjectStatus),
      projectColorId: i === "" || i === "Select" ? 0 : parseInt(i),
      customerId:
        selectedCustomer === "" || selectedCustomer === "Select"
          ? 0
          : parseInt(selectedCustomer),
    };
  };

  const handleInstance = (i,fromOnChange) => {
    console.log(isInstanceClick,"isInstanceClick")
    console.log(i,"isInstanceClick2")
    console.log()
    fromOnChange  &&setIsInstanceClick(true)
    console.log(i);
    setShowTable(false);
    setIsLoading(true);
    setSelectedInstance(i);
    getattributes(i);
    let body = {
      instanceId: parseInt(i),
      userId: parseInt(sessionStorage.getItem("loginId")),
      includeViewAccess: 1,
      isSpecificProjStatus: -1,
      // dateType: selectedDateType === '' || selectedDateType === 'Select' ? "" : selectedDateType,
      dateType: "",
      // fromDate: passdate1 !== "" ? passdate1 : "",
      fromDate: "",
      toDate: "",
      // toDate: passdate2 !== "" ? passdate2 : ""
    };
    let body1 = {
      instanceId: parseInt(i),
      userId: parseInt(sessionStorage.getItem("loginId")),
      // projStatusId: selectedProjectStatus === '' || selectedProjectStatus === 'Select' ? 0 : parseInt(selectedProjectStatus),
      projStatusId: 0,
    };
    let body2 = {
      userId: parseInt(sessionStorage.getItem("loginId")),
      level2Id: parseInt(i),
      level3Id: 0,
      level4Id: 0,
      level5Id: 0,
      level6Id: 0,
      level7Id: 0,
      level8Id: 0,
      level9Id: 0,
      level10Id: 0,
      projectsId: 0,
      projectManagerId: 0,
      projectStatusId: 0,
      projectColorId: 0,
      customerId: 0,
      // projectsId: selectedProjectName === '' || selectedProjectName === 'Select' ? 0 : parseInt(selectedProjectName),
    };
    let body4 = {
      instanceId: parseInt(i),
      userId: parseInt(sessionStorage.getItem("loginId")),
    };
    ViewProjGetProject(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setProjectName(response.data.success.data);
        } else {
          alert(response?.response?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setProjectAccessType([
      {
        id: "PM",
        name: "Where I am Project Manager",
      },
      {
        id: "APM",
        name: "Where I am Alternate PM",
      },
      {
        id: "LPM",
        name: "Where My L is  Project Manager",
      },
      {
        id: "LAPM",
        name: "Where My L is Alternate  PM",
      },
    ]);
    setDateType([
      {
        id: "1",
        name: "Exp/Actual End Date",
      },
      {
        id: "2",
        name: "Customer Due date",
      },
      {
        id: "3",
        name: "Scheduled End Date",
      },
    ]);
    GetTaskStatusDetails()
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setProjectStatus(response.data.success.data);
        } else {
          alert(response?.response?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    GetTaskColorDetails()
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setProjectColor(response.data.success.data);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    GetTaskManagerDetails(body4)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setProjectManager(response.data.success.data);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setSelectedDateType("Select");
    setSelectedProjectName("Select");
    setSelectedProjectManager("Select");
    setSelectedProjectAccessType("Select");
    setSelectedProjectStatus("Select");
    setSelectedProjectColor("Select");
    setSelectedCustomer("Select");
    // setValue({})
    // setDropdownss([])

    setDummy([]);
  };
  console.log(isInstanceClick ? "dw" : "dwdd","dwdd")
  //Reset button handle
  const handleReset = () => {
    handleInstance(instance[0].id);
  };

  const [dummy, setDummy] = useState([]); //table data usestate

  const [dropdownss, setDropdownss] = useState([]); //attributes dropdowns
  const [value, setValue] = useState({}); //selected attributes

  //attribute dropdown selection
  const handleDropdownSelection = (e, i) => {
    const { value } = e.target;
    setValue((prevValue) => ({
      ...prevValue,
      [`attribute${i + 1}Id`]: value,
    }));
  };

  //Submit button handle
  const handleSubmit = () => {
    setIsLoading(true);
    setShowTable(true);
    let body = {
      instanceId:
        selectedInstance === "" || selectedInstance === "Select"
          ? 0
          : parseInt(selectedInstance),
      userId: parseInt(sessionStorage.getItem("loginId")),
      attribute1Id:
        value.attribute1Id !== undefined && value.attribute1Id !== "Select"
          ? parseInt(value.attribute1Id)
          : 0,
      attribute2Id:
        value.attribute2Id !== undefined && value.attribute2Id !== "Select"
          ? parseInt(value.attribute2Id)
          : 0,
      attribute3Id:
        value.attribute3Id !== undefined && value.attribute3Id !== "Select"
          ? parseInt(value.attribute3Id)
          : 0,
      attribute4Id:
        value.attribute4Id !== undefined && value.attribute4Id !== "Select"
          ? parseInt(value.attribute4Id)
          : 0,
      attribute5Id:
        value.attribute5Id !== undefined && value.attribute5Id !== "Select"
          ? parseInt(value.attribute5Id)
          : 0,
      attribute6Id:
        value.attribute6Id !== undefined && value.attribute6Id !== "Select"
          ? parseInt(value.attribute6Id)
          : 0,
      attribute7Id:
        value.attribute7Id !== undefined && value.attribute7Id !== "Select"
          ? parseInt(value.attribute7Id)
          : 0,
      attribute8Id:
        value.attribute8Id !== undefined && value.attribute8Id !== "Select"
          ? parseInt(value.attribute8Id)
          : 0,
      attribute9Id:
        value.attribute9Id !== undefined && value.attribute9Id !== "Select"
          ? parseInt(value.attribute9Id)
          : 0,
      attribute10Id:
        value.attribute10Id !== undefined && value.attribute10Id !== "Select"
          ? parseInt(value.attribute10Id)
          : 0,
      projId:
        selectedProjectName === "" || selectedProjectName === "Select"
          ? 0
          : parseInt(selectedProjectName),
      accessType:
        selectedProjectAccessType === "" ||
        selectedProjectAccessType === "Select"
          ? ""
          : selectedProjectAccessType,
      taskId: 0,
      isCCTasks: 0,
      color: 0,
      taskMgrId: 0,
      action: 0,
      nextNoOfDays: 0,
      taskNotMovedSince: 0,
      department: "-1",
    };
    console.log(body);
    GetViewTaskTable(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setVisibleExelButton(true);
          console.log(response);
          setDummy(response.data.success.data);
        } else {
          setIsLoading(false);
          alert(response?.response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePlannedBilling = (projId) => {
    navigate(`/ccpm/PlannedBillingProjectionview?projId=${projId}`);
  };

  const handleAddEditNav = () => {
    navigate("/ccpm/addEditTasks");
  };

  const [sstate, setSstate] = useState({
    checkedRows: new Set(),
    allChecked: false,
  });
  const handleCheckboxChange = (index) => {
    setSstate((prevState) => {
      const checkedRows = new Set(prevState.checkedRows);
      if (checkedRows.has(index)) {
        checkedRows.delete(index);
      } else {
        checkedRows.add(index);
      }
      const allChecked = checkedRows.size === dummy.length;
      return { checkedRows, allChecked };
    });
  };
  const handleTableHeadCheckboxChange = () => {
    setSstate((prevState) => {
      const allChecked = !prevState.allChecked;
      const checkedRows = new Set();
      if (allChecked) {
        dummy.forEach((_, index) => checkedRows.add(index));
      }
      return { checkedRows, allChecked };
    });
  };
 console.log(dummy,"dummydwwd")
  return (
    <div
      style={{
        overflowX: dummy?.length > 0 ? "auto" : "hidden",
        height: "100vh",
      }}
    >
      {isLoading && (
        <img
          src={LoadingPink}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div_vpm"]}>
              <div className={modules["pro-bread"]}>
                <a>CCPM</a>
                <img src={bread} alt="" />
                <a>View Project Task</a>
              </div>
            </div>
            <Grid
              container
              className={[
                modules["parent-container_vpm"],
                modules["parent-container-new"],
              ].join(" ")}
            >
              <div className={modules["grid-container_vpm"]}>
                <Stack
                  spacing={2}
                  direction="row"
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                  className={modules["each-entry-mp"]}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Instance:<span className={modules["asterisk"]}>*</span>{" "}
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedInstance}
                    onChange={(e) => handleInstance(e.target.value,true)}
                  >
                    {instance &&
                      instance.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.name}
                        </option>
                      ))}
                  </select>
                </Stack>

                {/* Attribute Dropdown part */}
                {dropdownss &&
                  dropdownss?.map((obj, i) => {
                    return (
                      <Stack
                        spacing={2}
                        direction="row"
                        style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                        className={modules["each-entry-mp"]}
                      >
                        <div className={modules["input-labels_vpm"]}>
                          {obj.label}:
                        </div>

                        <select
                          className={modules["dropdownSelect1_vpm"]}
                          value={value[`attribute${i + 1}Id`] || "Select"}
                          onChange={(event) =>
                            handleDropdownSelection(event, i)
                          }
                        >
                          <option value="Select">Select</option>
                          {obj.values &&
                            obj.values.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.attributevalue}
                              </option>
                            ))}
                        </select>
                      </Stack>
                    );
                  })}
              </div>

              <div className={modules["grid-container2_vpm"]}>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Project Name:
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedProjectName}
                    // onChange={(e) => setSelectedProjectName(e.target.value)}
                    onChange={(e) => handleProjectName(e.target.value)}
                  >
                    <option value="Select">Select</option>
                    {ProjectName &&
                      ProjectName.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.projectname}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Task Manager:
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedProjectManager}
                    // onChange={(e) => setSelectedProjectManager(e.target.value)}
                    onChange={(e) => handleProManager(e.target.value)}
                  >
                    <option value="Select">Select</option>
                    {ProjectManager &&
                      ProjectManager.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.username}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Task Access Type:
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedProjectAccessType}
                    onChange={(e) =>
                      setSelectedProjectAccessType(e.target.value)
                    }
                  >
                    <option value="Select">Select</option>
                    {ProjectAccessType &&
                      ProjectAccessType.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.name}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Task Status:
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedProjectStatus}
                    // onChange={(e) => setSelectedProjectStatus(e.target.value)}
                    onChange={(e) => handleProjectStatus(e.target.value)}
                  >
                    <option value="Select">Select</option>
                    {ProjectStatus &&
                      ProjectStatus.map((i) => (
                        <option value={i.projectstatusid} key={i.id}>
                          {i.taskstatus}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>Task Color:</div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedProjectColor}
                    // onChange={(e) => setSelectedProjectColor(e.target.value)}
                    onChange={(e) => handleColor(e.target.value)}
                  >
                    <option value="Select">Select</option>
                    {ProjectColor &&
                      ProjectColor.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.taskcolor}
                        </option>
                      ))}
                  </select>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Task Availability Status:
                  </div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedCustomer}
                    onChange={(e) => setSelectedCustomer(e.target.value)}
                    disabled={isCheckboxChecked}
                  >
                    <option value="Select">Select</option>
                    {Customer &&
                      Customer.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.customername}
                        </option>
                      ))}
                  </select>
                </Stack>
              </div>

              <div className={modules["grid-container2_vpm"]}>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>Department:</div>

                  <select
                    className={modules["dropdownSelect1_vpm"]}
                    value={selectedProjectName}
                    // onChange={(e) => setSelectedProjectName(e.target.value)}
                    onChange={(e) => handleProjectName(e.target.value)}
                  >
                    <option value="Select">Select</option>
                    {ProjectName &&
                      ProjectName.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.projectname}
                        </option>
                      ))}
                  </select>
                </Stack>
              </div>

              <div className={modules["grid-container2_vpm"]}>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]} style={{position:"relative"}}>
                   <div style={{position:"absolute",top:"-1.2vh"}}>
                   <input
                      type="checkbox"
                      color="primary"
                      checked={isCheckboxChecked}
                      onChange={handleCheckboxChange1}
                      style={{ marginRight: "4px" }}
                    />
                    Task Not Moved Since Last : (Incomplete &
                    Available Task)
                   </div>
                  </div>
                  <input className={modules["dropdownSelect1_TS1"]} />
                  <span className={modules["Days"]}>Day(s)</span>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div className={modules["input-labels_vpm"]}>
                    Task View For Next :
                  </div>
                  <input className={modules["dropdownSelect1_TS1"]} />
                  <span className={modules["Days"]}>Day(s)</span>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  className={modules["each-entry-mp"]}
                  style={{ marginTop: "0.5rem", marginBottom: "0.3rem" }}
                >
                  <div
                    className={modules["input-labels_vpm"]}
                    style={{ width: "55%" }}
                  >
                    <input type="checkbox" color="primary" />
                       <span> </span> Original CC Tasks
                  </div>
                </Stack>
              </div>

              <Grid container className={modules["btn1-cont_vpm"]} style={{marginTop:"30px"}}>
                <button className={modules["btn1_vpm"]} onClick={handleSubmit}>
                  Search
                </button>
                <button className={modules["btn2_vpm"]} onClick={handleReset}>
                  Reset
                </button>
                {visibleExelButton && isInstanceClick && (
                  <button className={modules["btn2_vpm"]} onClick={handleReset}>
                    Export To Excel
                  </button>
                )}
                {/* <button
                  className={modules["btn2_vpm"]}
                  onClick={() => navigate(-1)}
                >
                  Back
                </button> */}
              </Grid>
            </Grid>

            {showTable && (
              <Grid
                container
                style={{ marginLeft: "6rem",  marginRight: "1rem" }}
                className={modules[("table-grid_vpm", "table-grid-margin")]}
              >
                {dummy?.length > 0 ? (
                  <div  style={{ paddingRight:"30px" }}>
                  <Table
                      className={[
                        modules["custom-table_vpm"],
                        modules["custom-table-th"],
                      ].join(" ")}
                     
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={sstate.allChecked}
                              onChange={handleTableHeadCheckboxChange}
                              className={modules["custom-checkbox"]}
                            />
                          </TableCell>
                          <TableCell>Sr.No.</TableCell>
                          <TableCell>Task Id</TableCell>
                          <TableCell>
                            Raise
                            <br />
                            Issue
                          </TableCell>
                          <TableCell>
                            Sub
                            <br />
                            Task
                          </TableCell>
                          <TableCell>Project Name</TableCell>
                          <TableCell>Department</TableCell>
                          <TableCell>Task Name</TableCell>
                          <TableCell>
                            Task
                            <br />
                            Manager
                          </TableCell>
                          <TableCell>
                            Original
                            <br />
                            Duration
                          </TableCell>
                          <TableCell>
                            Planned
                            <br />
                            Start
                          </TableCell>
                          <TableCell>
                            Planned
                            <br />
                            End
                          </TableCell>
                          <TableCell>
                            Actual
                            <br />
                            Start
                          </TableCell>
                          <TableCell>
                            Projected
                            <br />
                            Start
                          </TableCell>
                          <TableCell>
                            Projected
                            <br />/ Actual
                            <br />
                            End{" "}
                          </TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>
                            Task
                            <br />
                            Process
                            <br />
                            Notes
                          </TableCell>
                          <TableCell>
                            User
                            <br />
                            Notes
                          </TableCell>
                          <TableCell>Predecessor/Successor</TableCell>

                          <TableCell>
                            Task
                            <br />
                            Update
                            <br />
                            History
                          </TableCell>
                          <TableCell>
                            Remaining <br />
                            Duration
                            <br />
                            History
                          </TableCell>
                          <TableCell>
                            Remaining <br />
                            Duration
                          </TableCell>
                          <TableCell>
                            Elapsed
                            <br />
                            Days
                          </TableCell>
                          <TableCell>
                            % Task
                            <br />
                            Completion
                          </TableCell>
                          <TableCell>
                            PO
                            <br />
                            No
                          </TableCell>
                          <TableCell>
                            Availability
                            <br />
                            Status
                          </TableCell>
                          <TableCell>
                            % Buffer
                            <br />
                            Penetration
                          </TableCell>
                          <TableCell>
                            Reason For
                            <br />
                            Delay
                          </TableCell>
                          <TableCell>
                            Secondary
                            <br />
                            Reason For
                            <br />
                            Delay
                          </TableCell>
                          
                          <TableCell  style={{ padding: "10px" }}>
                            Last <br />
                            Updated
                            <br />
                            On
                          </TableCell>
                          <TableCell></TableCell>
                          {/* <TableCell></TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dummy &&
                          dummy?.map((item, index) => (
                            <TableRow
                              key={index}
                              className={modules["table-row"]}
                              style={{position:"relative"}}
                            >
                              <TableCell>
                                <input
                                  type="checkbox"
                                  checked={sstate.checkedRows.has(index)}
                                  onChange={() => handleCheckboxChange(index)}
                                  className={modules["custom-checkbox"]}
                                />
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {index + 1}
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {item.taskid}
                              </TableCell>
                              <TableCell>
                                <img
                                  src={RaiseIssue}
                                  alt=""
                                  className={modules["custom-img-size"]}
                                  onClick={() => {
                                      window.open(
                                        `/ccpm/raiseissue?projId=${item.projectsid}&taskId=${item.taskid}&view=${false}`,
                                        "sharer",
                                        "toolbar=0,status=0,width=1050,height=600"
                                      );
                                    }}
                                />
                              </TableCell>
                              <TableCell>{item.subtaskvalue}</TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {item.projectname}
                              </TableCell>
                              <TableCell>{item.department}</TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {item.taskname}
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {item.taskmanager}
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {item.originalduration.toFixed(2)}
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                              {item.startdate}
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {item.enddate}
                                
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {item.actualstart}
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {item.projectedstartdate}

                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {/* { new Date(item?.projectedfinishdate?.replace(/(\d{2})\/(\d{2})\/(\d{4})-(\d{2}:\d{2})/, '$3-$1-$2T$4')).toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })?.replace(',', '')} */}
                                {item?.projectedfinishdate}
                              </TableCell>
                              <TableCell>
                                <select
                                  className={modules["dd2"]}
                                  value={item.status}
                                  readOnly
                                >
                                  {/* <option value={item.status}>
                                  {item.status}
                                </option> */}
                                </select>
                              </TableCell>
                              <TableCell>
                                <img
                                  src={notes}
                                  className={modules["custom-img-size"]}
                                  alt=""
                                  onClick={() => {
                                      window.open(
                                        `/ccpm/usernotes?projId=${item.projectsid}&taskId=${item.taskid}&view=${true}`,
                                        "sharer",
                                        "toolbar=0,status=0,width=1050,height=600"
                                      );
                                    }}
                                />
                              </TableCell>
                              <TableCell>
                                <img
                                  src={UserNotes}
                                  className={modules["custom-img-size"]}
                                  alt=""
                                 
                                />
                              </TableCell>
                              <TableCell>
                                {" "}
                                <div className={modules["image-container"]}>
                                  <img
                                    src={Predecessor}
                                    className={modules["custom-img-size"]}
                                    alt="Predecessor"
                                    onClick={() => {
                                      window.open(
                                        `/ccpm/predecessor?projId=${item.projectsid}&taskId=${item.taskid}`,
                                        "sharer",
                                        "toolbar=0,status=0,width=1050,height=600"
                                      );
                                    }}
                                  />

                                  <img
                                    src={successor}
                                    className={`${modules["custom-img-size"]} ${modules["successor-img"]}`}
                                    alt="Successor"
                                    onClick={() => {
                                      window.open(
                                        `/ccpm/successor?projId=${item.projectsid}&taskId=${item.taskid}`,
                                        "sharer",
                                        "toolbar=0,status=0,width=1050,height=600"
                                      );
                                    }}
                                  />
                                </div>
                              </TableCell>

                              <TableCell
                                onClick={() => {
                                  window.open(
                                    `/ccpm/ViewActiveProjectsHistory?projId=${item.projectsid}&taskId=${item.taskid}`,
                                    "sharer",
                                    "toolbar=0,status=0,width=1050,height=600"
                                  );
                                }}
                              >
                                <a href="#">View History</a>
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  window.open(
                                    `/ccpm/ViewDurationProjectsHistory?projId=${item.projectsid}&taskId=${item.taskid}`,
                                    "sharer",
                                    "toolbar=0,status=0,width=1050,height=600"
                                  );
                                }}
                              >
                                <a href="#">Remaining Duration History</a>
                              </TableCell>
                              <TableCell>
                                <input
                                  className={modules["dd3"]}
                                  readOnly
                                  value={item.remainingduration.toFixed(2)}
                                />
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {/* {item.elapseddays} */}
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {/* {item.pertaskcompletion} */}
                              </TableCell>
                              <TableCell>
                                <input
                                  value={item.pono}
                                  className={modules["dd3"]}
                                  style={{ width: "100px" }}
                                  readOnly
                                />
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {/* {item.availabilitystatus} */}
                              </TableCell>
                              <TableCell style={{ color: "#414141" }}>
                                {item.bufferpenetration}
                              </TableCell>
                              <TableCell>
                                <select
                                  className={modules["dd2"]}
                                  value={item.reasonfordelay}
                                ></select>
                              </TableCell>
                              <TableCell>
                                <textarea
                                  readOnly
                                  value={item.secdelayreason}
                                ></textarea>
                              </TableCell>
                              {/* <TableCell>
                              <img
                                src={submit}
                                className={modules["custom-img-size"]}
                                alt=""
                              />
                            </TableCell> */}
                              {/* <TableCell style={{ color: "#414141" }}>
                                {item.lastupdatedon}
                              </TableCell> */}
                              <TableCell style={{ color: "#414141", }}>
                                {item.lastupdatedon}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                ) : isLoading ? (
                  ""
                ) : (
                  <div
                    style={{
                      marginLeft: "1rem",
                      marginTop: "1rem",
                      marginBottom: "3rem",
                    }}
                    className={modules["bodyFont"]}
                  >
                    No Record Found
                    <hr
                      style={{
                        border: "1px solid #a4bf47",
                        transform: "scaleY(0.5)",
                        marginTop: "0.5rem",
                      }}
                    />
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewTasks;
