import "gantt-task-react/dist/index.css";

import { Gantt, Task, ViewMode } from "gantt-task-react";
import React, { useState,useEffect } from "react";
import { getStartEndDateForProject, initTasks } from "./helper";
import { ViewSwitcher } from "../GanttChart/viewSwitcher";
import {LoadGanttChart,GanttChartGetTasksByProjectId} from "../../../../Services/CCPM";
import { useLocation } from "react-router-dom";
function GanttChart() {
  const [view, setView] = useState(ViewMode.Day);
   const [tasks, setTasks] = useState(initTasks());
   const [startDate,setStartDate]=useState([]);
   const [endDate,setEndDate]=useState([]);
 //  const [tasks, setTasks] = useState([]);
  //const [tasks, setTasks] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projId = parseInt(searchParams.get("projId"));
  console.log("projId",projId)
  let columnWidth = 65;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }
useEffect(()=>{
  handleGanttChartData();
  taskdata();
  console.log("initi",tasks)
})
const dateConversion=(startdate,endtaskdate)=>{
  let date = new Date(startdate);
  let year = date.getFullYear();
  let month = date.getMonth()+1;
  let dt = date.getDate();
  
  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }
  let taskstartdt=year+'-' + month + '-'+dt
  setStartDate(taskstartdt);
  let enddate = new Date(endtaskdate);
  let endyear = enddate.getFullYear();
  let endmonth = enddate.getMonth()+1;
  let enddt = enddate.getDate();
  
  if (enddt < 10) {
    enddt = '0' + enddt;
  }
  if (month < 10) {
    endmonth = '0' + endmonth;
  }
  let taskenddt=endyear+'-' + endmonth + '-'+enddt
  setEndDate(taskenddt)
}
const taskdata=async()=>{
let res=await GanttChartGetTasksByProjectId(projId);
let taskarr=[];
const currentDate = new Date();

if(res.status===200){
  let apidata=res.data.success.data;
  let tasklistdetails=apidata.map((item)=>{
    // const currentStartTaskDate = item.originaltaskstartdate;
    // const currentEndTaskDate=item.originaltaskenddate;
    dateConversion(item.originaltaskstartdate,item.originaltaskenddate);

    taskarr.push({
      start:item.originaltaskstartdate,
      end:item.originaltaskenddate,
      name:item.taskname,
      id:item.id,
      type:"task",
    })
  })
  console.log("taskarr",taskarr)
  //setTasks(tasklistdetails);
console.log("tasklistdata",res.data.success.data);
}

}
const handleGanttChartData=async()=>{
  let projectId=2;
  let bufferdata=[];
  let projectData=[];
  const currentDate = new Date()
let res=await LoadGanttChart(projId);
if(res.status===200){
  let responsedata=res.data.success.data
  responsedata.projectstatusid.map((item)=>{
  projectData.push({
  start:new Date(item.projectstartdate),
  end:new Date(item.customerduedate),
  project:item.projectname,
  type:"project"
  // styles: { progressColor:item.projectstatusid? "red":"blue"},
  // type:"task",
})
  })
 responsedata.bufferconsumed.map((item)=>{
    bufferdata.push({
       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
      //id:item.taskid,
      progress:item.bufferconsumed,
      type:"task",
    })  
  })
 // setTasks(bufferdetails)
  
  console.log("projectData",projectData)
  console.log("bufferdata",bufferdata)
  // let obj={
  //   start:new Date(projectstatusid.projectstartdate),
  //   end:new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
  // id:bufferconsumed.taskId,
  // progress:bufferconsumed.bufferconsumed,
  // type:"task",
  // project:projectstatusid.projectname,
  // styles: { progressColor:projectstatusid.projectstatusid? "red":"blue"}
  // //displayOrder:
  // }
 console.log(res.data.success.data);

 
}
console.log(res);
}
  // const handleTaskChange = (task) => {
  //   console.log("On date change Id:" + task.id);
  //   let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
  //   if (task.project) {
  //     const [start, end] = getStartEndDateForProject(newTasks, task.project);
  //     const project =
  //       newTasks[newTasks.findIndex((t) => t.id === task.project)];
  //     if (
  //       project.start.getTime() !== start.getTime() ||
  //       project.end.getTime() !== end.getTime()
  //     ) {
  //       const changedProject = { ...project, start, end };
  //       newTasks = newTasks.map((t) =>
  //         t.id === task.project ? changedProject : t
  //       );
  //     }
  //   }
  //   setTasks(newTasks);
  // };

  // const handleTaskDelete = (task) => {
  //   const conf = window.confirm("Are you sure about " + task.name + " ?");
  //   if (conf) {
  //     setTasks(tasks.filter((t) => t.id !== task.id));
  //   }
  //   return conf;
  // };

  // const handleProgressChange = async (task) => {
  //   setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  //   console.log("On progress change Id:" + task.id);
  // };

  // const handleDblClick = (task) => {
  //   alert("On Double Click event Id:" + task.id);
  // };

  // const handleSelect = (task, isSelected) => {
  //   console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  // };

  // const handleExpanderClick = (task) => {
  //   setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  //   console.log("On expander click Id:" + task.id);
  // };

  return (
    <div style={{marginLeft:"90px"}}>
      <ViewSwitcher
        onViewModeChange={(viewMode) => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
      />
      <Gantt
        tasks={tasks}
        viewMode={view}
        // onDateChange={handleTaskChange}
        // onDelete={handleTaskDelete}
        // onProgressChange={handleProgressChange}
        // onDoubleClick={handleDblClick}
        // onSelect={handleSelect}
        // onExpanderClick={handleExpanderClick}
        listCellWidth={isChecked ? "155px" : ""}
        columnWidth={columnWidth}
      />
    </div>
  );
}

export default GanttChart;
