import React, { useEffect, useState } from "react";
import "../../../HelpPopups/HelpPopup.css";
import Stack from "@mui/material/Stack";
import logo from "../../../../Assets/godrej_logo.gif";
import prostream from "../../../../Assets/prostream_logo.png";
import modules from '../../ccpm.module.css'
import { Grid } from "@mui/material";
import bread from "../../../../Assets/bread.png";
import { PendingTaskPopup, ViewRiskPopup } from "../../../../Services/CCPM";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";

const RiskPopup = () => {
    const [data, setData] = useState([])
    const [riskDetails, setRiskDetails] = useState([])
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = parseInt(searchParams.get("projId"));
    const selectedInstance = searchParams.get("selectedInstance");
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        // let body = {
        //     instanceId: 18,
        //     projectId: 4244,
        //     userId: 2160
        // }
        let body = {
            instanceId: selectedInstance === '' || selectedInstance === 'Select' ? 0 : parseInt(selectedInstance),
            projectId: projectId,
            userId: parseInt(sessionStorage.getItem("loginId"))
        }
        ViewRiskPopup(body)
            .then((response) => {
                console.log(response)
                if (response?.status === 200) {
                    setIsLoading(false)
                    setData(response?.data?.success?.data)
                    setRiskDetails(response?.data?.success?.data?.RiskDetails)
                }
                else if (response?.response?.data?.message !== undefined) {
                    setIsLoading(false);
                    alert(response?.response?.data?.message);
                } else if (response?.response?.data?.error?.message !== undefined) {
                    setIsLoading(false);
                    // alert(response?.response?.data?.error?.message);
                } else {
                    setIsLoading(false);
                    alert(response?.response?.data?.success?.message);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    return (
        <div style={{ overflowX: 'auto', height:'100vh' }}>
            <Stack
                direction="row"
                spacing={4}
                sx={{ paddingTop: "1rem" }}
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                    <img src={logo} alt="Godrej" />
                </Stack>
                <Stack sx={{ paddingRight: "1rem" }}>
                    <img src={prostream} alt="Prostream" />
                </Stack>
            </Stack>
            {riskDetails?.length > 0 ? (
                <table border="1" style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }} className={modules["custom-table-Viewriskpopup"]}>
                    <thead>
                        <tr>
                            <th colSpan="5" style={{ backgroundColor: "LightGray" }}></th>
                            <th colSpan="4" style={{ backgroundColor: "LightSteelBlue" }}>Original Risk Rating</th>
                            <th colSpan="1" style={{ backgroundColor: "LightGreen" }}>Action Detail</th>
                            <th colSpan="4" style={{ backgroundColor: "LightPink" }}>Revised Risk Rating</th>
                            <th colSpan="3" style={{ backgroundColor: "LightGray" }}></th>
                        </tr>
                        <tr>
                            <th>Sr.No</th>
                            <th>Project<br />Name</th>
                            <th>Project<br />Manager</th>
                            <th>Risk</th>
                            <th>Likely Date</th>
                            <th>Risk<br />Category</th>
                            <th>Probability</th>
                            <th>Impact</th>
                            <th>Priority<br />Ranking</th>
                            <th></th>
                            <th>Risk<br />Category</th>
                            <th>Probability</th>
                            <th>Impact</th>
                            <th>Priority<br />Ranking</th>
                            <th>Status</th>
                            <th>Review<br />Remark</th>
                            <th>Risk History</th>
                        </tr>
                    </thead>
                    <tbody>
                        {riskDetails?.length > 0 && riskDetails?.map((risk, index) => {
                            const outerDetails = data[risk.riskid] || [];
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{risk.proname}</td>
                                    <td>{risk.projectmanager}</td>
                                    <td>{risk.risks}</td>
                                    <td>{risk.likelydate}</td>
                                    <td>{risk.riskcategory}</td>
                                    <td>{risk.probability}</td>
                                    <td>{risk.impact}</td>
                                    <td>{risk.priorityranking}</td>
                                    <td style={{ width: '20rem' }}>
                                        {outerDetails?.length > 0 && (
                                            outerDetails?.map((detail, index) => (
                                                <table border="1" style={{ borderCollapse: 'collapse' }} className={modules["custom-table-Viewriskpopup"]}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ padding: '1px', verticalAlign: 'top' }}><strong>MitigationActionPlan</strong><br />{detail.mitigationactionplan}</td>
                                                            <td style={{ padding: '1px', verticalAlign: 'top' }}><strong>ActionByDate</strong><br />{detail.actionbydate}</td>
                                                            <td style={{ padding: '1px', verticalAlign: 'top' }}><strong>ActionAssignedTo</strong><br />{detail.actionassignedto}</td>
                                                            <td style={{ padding: '1px', verticalAlign: 'top' }}><strong>ActionTakenRemark</strong><br />{detail.actiontakenremark}</td>
                                                            <td style={{ padding: '1px', verticalAlign: 'top' }}><strong>ActionTakenOn</strong><br />{detail.actiontakenon}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ))
                                        )}
                                    </td>
                                    <td>{risk.revisedriskcategory}</td>
                                    <td>{risk.revisedprobability}</td>
                                    <td>{risk.revisedimpact}</td>
                                    <td>{risk.revisedpriorityranking}</td>
                                    <td></td>
                                    <td>{risk.reviewremark}</td>
                                    {/* {risk.reviewremark} */}
                                    <td
                                        onClick={() => {
                                            window.open(
                                                ` /ccpm/RiskHistoryPopup?PrjRiskId=${risk.riskid}`,
                                                "sharer",
                                                "toolbar=0,status=0,width=1050,height=600"
                                            );
                                        }}
                                    ><a style={{ color: 'blue', textDecoration: 'underline' }} href="#">History</a></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            ) :
                <div className={modules['viewrisknodatafound']}>No Records found</div>
            }
        </div>
    );
};

export default RiskPopup;
