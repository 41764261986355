import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SPIP1 from "../HelpPopups/images/SPIP1.png";
import SPIP2 from "../HelpPopups/images/SPIP2.png";
import SPIP3 from "../HelpPopups/images/SPIP3.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointItemPhaseInPhaseOutSeasonalityhelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h2 style={{ marginLeft: "0rem", textAlign: "left" }}>
                                Stock Point Item Phase In Phase Out Seasonality
                            </h2>
                            <br />
                            <br />


                            <li className="no-bullets">
                                Path {">>"} MTA {">>"} MTA Parameter {">>"} Stock Point Item Phase In Phase Out Seasonality
                            </li>
                            <br />
                            <li className="no-bullets">
                                This session is used to update the items for phase in, phase out and Seasonality. In this case normal DBM is not applicable. User need to input start date for above parameter and End date in case of seasonality.

                            </li>
                            <br />
                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPIP1} alt="SPIP1" />
                            </Stack>
                            <br />
                            <li >
                                MTA Instance – Select MTA Instance
                            </li>
                            <li >
                                Stock Point – This field indicates stock point. Select Stock point from dropdown available for the field.
                            </li>
                            <li >
                                Company Code – This field indicate company code. User need to maintain company code.
                            </li>
                            <li >
                                Phase out item code – This field indicates that this item to be phase out. Item description is appeared once item code is input.
                            </li>
                            <li >
                                Phase in item code – This field indicates that this item to be phase in against phase out item code. Item description is appeared once item code is input.
                            </li>
                            <li >
                                Click on search to continue.
                            </li>
                            <br />
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPIP2} alt="SPIP2" />
                            </Stack>
                            <br />

                            <br />

                            <li >
                                Company Code – This field indicates company code for the item
                            </li>
                            <li >
                                Phase in Item code – This field indicates phase in item. User need to input phase in item code in this field.
                            </li>
                            <li >
                                Phase In Item Description – This field indicate item description for the item Code inserted in phase in item code field. This description is pulled from system.
                            </li>
                            <li >
                                Phase out Item code – This field indicates phase out item. User need to input phase out item code in this field.
                            </li>
                            <li >
                                Phase Out Item Description – This field indicate item description for the item Code inserted in phase out item code field. This description is pulled from system.
                            </li>
                            <li >
                                Start Date – This date indicates from which date phase in – phase out t be started.
                            </li>
                            <li >
                                End Date – This date indicates end date of Phase in – Phase out to ended.
                            </li>
                            <li >
                                Target Buffer – This field indicates target buffer to be achieved. In phase in – Phase out this buffer is same as original buffer. And incase of seasonality user need to input the buffer qty.
                            </li>
                            <br />
                            <br />
                            <br />
                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPIP3} alt="SPIP3" />
                            </Stack>
                            <br />

                            <br />
                            <li>
                                Buildup Duration – This parameter indicated duration in which target buffer is to be achieved. System will adjust buffer as per the duration.
                            </li>
                            <li>
                                Auto Updation Enabled – If this parameter is maintained as Yes the system will increase or decrease buffer automatically as per buildup duration. If parameter is maintained as No then system will not change buffer. User need to control and monitor the buffer in this case.
                            </li>
                            <li>
                                Regular DBM Blocked – If this parameter is maintained as Yes then during period DBM will not consider those items. If maintained as No then System will consider the it for DBM. But as discussed default value is Yes for this parameter.
                            </li>
                            <li>
                                Buffer Processing type – There four parameters in this
                                <ul>
                                    <li style={{ marginLeft: "2rem" }}>
                                        Phase in – Phase out
                                    </li>
                                    <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                        This indicates one item is replaced with another item. Buffer for old item (Phase out item) is kept same for New item (Phase in item). In this case buffer for old item is decreased and new item is increased. This transaction started from start date maintained in the system. When item is reached 33% of buffer (Or as per parameter set in User Control) then DBM is started for that item. And buffer for phase out is set as the stock of that item code (Reducing stock which is gradually set to zero) After that the item phase out is completed.
                                    </li>
                                    <li style={{ marginLeft: "2rem" }}>
                                        Phase in –
                                    </li>
                                    <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                        This indicate introduction of new item. Target buffer for this item code is set and start date is maintained for the item. When Buffer reached 33% then item is considered for DBM.
                                    </li>
                                    <li style={{ marginLeft: "2rem" }}>
                                        Phase out -
                                    </li>
                                    <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                        This indicates that item to phase out. buffer for phase out is set as the stock of that item code (Reducing stock which is gradually set to zero) After that the item phase out is completed.
                                    </li>
                                    <li style={{ marginLeft: "2rem" }}>
                                        Seasonality -
                                    </li>
                                    <li style={{ marginLeft: "2rem" }} className="no-bullets">
                                        This parameter indicates that buffer is increased or reduced as per seasonality pattern. For this field user need to define Start date and End Date. So, buffer start moving from start date. If buffer need to increase, then it is gradually increased to target buffer but if buffer has to decreased then it is reduced with immediate effect. Once target buffer is achieved then Buffer is kept constant for the defined time. When end date is achieved then buffer set to original buffer. If buffer in increased during season, then after end date it suddenly reduced to original buffer but if buffer is reduced during season then it is gradually increased to original buffer considering the replenishment capacity.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Total Receipt percentage of Buffer – This field indicates the no of receipt in % of total buffer. This parameter is used to start DBM for that item.
                            </li>
                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StockPointItemPhaseInPhaseOutSeasonalityhelpPopup;
