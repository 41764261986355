import React from "react";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import loading from "../../../Assets/loadingGreen.gif";
import loadingdots from "../../../Assets/LoadingDotsWhiteBlack.gif";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import modules from "./MAUCMaterialThreshold.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import { ProcessMaucReport,DS_GetAllInstanceHandler } from "../../../Services/Dss";

export default function ProcessMAUCTrackerReport() {
  const [dropdownData, setDropdownData] = useState([
    "Tooling",
    "Instance 2",
    "Instance 3",
  ]);

  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);
  // const [displayTable, setDisplayTable] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [dropOne, setDropOne] = useState({
    value: 'select',
    dropDown: []
})

  const handleSubmit = () => {
    setIsLoading(true);

    if(dropOne.value){
      ProcessMaucReport(dropOne.value,'pritpwc').then(res => {
        if(res.status === 200){
          console.log(res)
          alert(res.data?.success?.message)
          setIsLoading(false)
        }

        if(res.response?.status === 400){
          alert(res?.response?.data)
          setIsLoading(false)
        }
        return res;
    }).catch(err => {
      console.log('Error while fetching data:', err)
      setIsLoading(false)
  })
    }
    
   
  };

  setTimeout(() => {
    setIsLoading(false);
  }, 3000);

  useEffect(() => {
    DS_GetAllInstanceHandler('pritpwc').then(res => {
        setDropOne({
            dropDown: res.data,
            value: res.data[0].instanceuid
        })
        return res;
    }).catch(err => {
      console.log('Error while fetching data:', err)
  })
}, [])

function InstanceChangeHandler(value) {
  setDropOne({
      ...dropOne,
      value: value
  });
}

  function ArrowLine() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "2px",
            height: "30px",
            backgroundColor: "grey",
            marginRight: "2px",
          }}
        />
        <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
      </div>
    );
  }
  return (
    <>
      <Grid container>
        <Grid
          item
          lg={0.6}
          sx={{ position: "fixed", zIndex: 2000 }}
          className="main-cont"
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className="home-cont">
            <Home />
          </Grid>
        </Grid>
        <Grid
          item
          lg={9}
          className={modules["ProcessMAUCTrackerReport_div"]}
          style={{ marginLeft: "5rem" }}
        >
          <div className="pro-bread">
            <p>
              <a>DSS MAUC </a>
              <img src={bread} alt="" />
              <a>Process MAUC Tracker Report</a>
            </p>
          </div>
          {isLoading && (
            <div className={modules["loading-clipper"]}>
          
              <p>
                <img src={loading} alt="picture missing" />
                Please wait
                <img src={loadingdots} alt="dots missing" />
              </p>
            </div>
          )}
        </Grid>

       

        <Grid container sx={{ marginLeft: "5rem", marginTop: "2rem" }}>
          <table className={modules["customTable2"]}>
            <tbody>
              <tr>
                <td>DSS Instance</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                    <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={dropOne.value}
                      onChange={(e) => InstanceChangeHandler(e.target.value)}
                    >
                      {dropOne?.dropDown?.map((i) => {
                                                return (
                                                    <MenuItem value={i.instanceuid} key={i.instanceuid}>
                                                        {i.instanceuid}
                                                    </MenuItem>
                                                );
                                            })}
                    </Select>
                  </FormControl>
                </td>
              </tr>

             
            </tbody>
          </table>
        </Grid>

        <div>
          <button
            style={{
              marginLeft: "13.5rem",
              marginTop: "1.2rem",
              padding: "0.5cm",
              marginBottom: "1.6rem",
            }}
            type="button"
            className={modules["btn1"]}
            onClick={handleSubmit}
            Table
          >
            Submit
          </button>

         
        </div>
      </Grid>
    </>
  );
}
