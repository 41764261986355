import React, { useEffect, useState } from "react";
// import modules from "./RiskAndMitigationPlan.module.css";
import modules from "./../ccpm.module.css";
import Home from "../../../home/Home";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Stack,
  Typography,
  Popover,
  Paper,
} from "@mui/material";
import bread from "../../../Assets/bread.png";
import SampleSidebar from "../../../Landing/SampleSidebar";
import { useNavigate, useLocation } from "react-router-dom";
import {
  RMP_RiskTypeD,
  RMP_RiskCatD,
  RMP_TriggerStatusD,
  RMP_InsertRisk,
  RMP_FetchDetailsPop,
  RMP_UpdateDetailsPop,
  RMP_GetMitDetails,
  RMP_Assign,
  RMP_InsertMit,
  RMP_DeleteRisk,
} from "../../../Services/CCPM";
import loading from "../../../Assets/Loading_image.gif";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import "../ProjectPlan/CalendarStyles.css";

export default function RiskAndMitigationPlanPop() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const projId = searchParams.get("projId");
  const [isLoading, setIsLoading] = useState(false);
  const [editing, setEditing] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkedRows, setCheckedRows] = useState({});
  const [checkAdd, setCheckAdd] = useState(false);
  const [selectedRiskType, setSelectedRiskType] = useState("");
  const [dropDownRiskType, setDropDownRiskType] = useState([]);
  const [selectedRiskCat, setSelectedRiskCat] = useState("");
  const [dropDownRiskCat, setDropDownRiskCat] = useState([]);
  const [selectedTriggerStatus, setSelectedTriggerStatus] = useState("");
  const [dropDownTriggerStatus, setDropDownTriggerStatus] = useState([]);
  const [checkMult, setCheckMult] = useState(false);
  const [probValue, setProbValue] = useState("");
  const [impactValue, setImpactValue] = useState("");
  const [likelyDate, setLikelyDate] = useState("");
  const [displayData, setDisplaydate] = useState("");
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [riskDesc, setRiskDesc] = useState("");
  const [allCheckM, setAllCheckM] = useState(false);
  const [showResult, setShowResult] = useState(false);

  //update useStates
  const [upRiskId, setUpRiskId] = useState("");
  const [upRiskType, setUpRiskType] = useState("");
  const [upRiskDesc, setUpRiskDesc] = useState("");
  const [displayData2, setDisplaydate2] = useState("");
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [upLikelyDate, setUpLikelyDate] = useState("");
  const [projectCreatedDate, setProjectCreatedDate] = useState("");
  const [upRiskCat, setUpRiskCat] = useState("");
  const [upProb, setUpProb] = useState("");
  const [upImpact, setUpImpact] = useState("");
  const [upStatus, setUpStatus] = useState("");

  const [checkMit, setCheckMit] = useState(false);
  const [mitData, setMitData] = useState([]);
  const [mitId, setMitId] = useState("");
  const [mitProjName, setMitProjName] = useState("");
  const [mitRiskDesc, setMitRiskDesc] = useState("");
  const [selectedAssign, setSelectedAssign] = useState("");
  const [dropDownAssign, setDropDownAssign] = useState([]);
  const [addMit, setAddMit] = useState(false);
  const [mitDesc, setMitDesc] = useState("");
  const [displayData3, setDisplaydate3] = useState("");
  const [anchorEl4, setAnchorEl4] = useState(null);
  const [mitDate, setMitDate] = useState("");

  const [isTouched, setIsTouched] = useState(false);
  const [isTouchedMit, setIsTouchedMit] = useState(false);

  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);

  const [currentPage, setCurrentPage] = useState(1);
  const RowsPerPage = 10;
  const MaxVisiblePages = 10;
  const startIndex = (currentPage - 1) * RowsPerPage;
  const endIndex = startIndex + RowsPerPage;
  const totalPages = Math.ceil(tableData.length / RowsPerPage);
  const visibleData = tableData.slice(startIndex, endIndex);

  const getPageNumbers = () => {
    const totalPages = Math.ceil(tableData.length / RowsPerPage);
    const pageNumbers = [];

    if (totalPages <= MaxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= MaxVisiblePages - 3) {
        for (let i = 1; i <= MaxVisiblePages - 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");

        const remainingPages = totalPages - currentPage;

        if (remainingPages < MaxVisiblePages - 3) {
          for (
            let i = currentPage - (MaxVisiblePages - 4);
            i <= totalPages;
            i++
          ) {
            pageNumbers.push(i);
          }
        } else {
          for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pageNumbers.push(i);
          }

          pageNumbers.push("...");
          pageNumbers.push(totalPages);
        }
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (page) => {
    setSelectAllChecked(false);
    setCheckedRows([]);
    if (page === "prev") {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    } else if (page === "next") {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    } else {
      setCurrentPage(page);
    }
  };

  const addRow = () => {
    setCheckAdd(true);
    setSelectedRiskType("");
    setRiskDesc("");
    setDisplaydate("");
    setSelectedRiskCat("");
    setProbValue("");
    setImpactValue("");
    setSelectedTriggerStatus("");
    setCheckMult(false);
  };

  const pageLoad = async () => {
    setIsLoading(true);
    let body = {
      projectId: parseInt(projId),
    };
    try {
      const response = await RMP_FetchDetailsPop(body);
      if (response.status === 200) {
        setTableData(response?.data?.success?.data);
        setShowResult(true);
        // console.log(response?.data?.success?.data[0]?.createdon);
        handleCalendar5n(response?.data?.success?.data[0]?.createdon);
      } else {
        setTableData([]);
        setShowResult(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCalendar5n = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      ?.toLocaleDateString("en-GB", options)
      .replace(/,/g, "");
    setProjectCreatedDate(formattedDate);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const riskTypeResponse = await RMP_RiskTypeD();
      if (riskTypeResponse.status === 200) {
        setDropDownRiskType(riskTypeResponse?.data?.success?.data);
      }

      const riskCatResponse = await RMP_RiskCatD();
      if (riskCatResponse.status === 200) {
        setDropDownRiskCat(riskCatResponse?.data?.success?.data);
      }

      const triggerStatusResponse = await RMP_TriggerStatusD();
      if (triggerStatusResponse.status === 200) {
        setDropDownTriggerStatus(triggerStatusResponse?.data?.success?.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    pageLoad();
  }, []);

  useEffect(() => {
    const allChecked =
      visibleData.length > 0 &&
      visibleData.every((item) => checkedRows[item.proid]);
    setSelectAllChecked(allChecked);
  }, [checkedRows, visibleData]);

  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: "short" }).slice(0, 2);
  };

  const today = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedToday = today.toLocaleDateString("en-US", options);

  function formatDateFet(dateString) {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = new Date(dateString?.replace(/\//g, "-")); // Convert to ISO format
    return date.toLocaleDateString("en-GB", options); // Use en-GB for "08 Feb 2019"
  }

  const handleCalendar2n = (date) => {
    setAnchorEl2(null);

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const options2 = { year: "numeric", month: "2-digit", day: "2-digit" };

    const formattedDate = date
      ?.toLocaleDateString("en-GB", options)
      .replace(/,/g, "");

    const formattedDate2 = date?.toLocaleDateString("en-GB", options2);

    const [day, month, year] = formattedDate2.split("/");
    const formattedDate3 = `${year}/${month}/${day}`;

    setLikelyDate(formattedDate);
    setDisplaydate(formattedDate);
  };

  const handleCalendar3n = (date) => {
    setAnchorEl3(null);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      ?.toLocaleDateString("en-GB", options)
      .replace(/,/g, "");
    setUpLikelyDate(date);
    setDisplaydate2(formattedDate);
  };

  const handleCalendar4n = (date) => {
    setAnchorEl4(null);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      ?.toLocaleDateString("en-GB", options)
      .replace(/,/g, "");
    setMitDate(formattedDate);
    setDisplaydate3(formattedDate);
  };

  // const [Ept_rsktype, setEpt_rsktype] = useState(false);

  const handleInsertRisk = async () => {
    setIsLoading(true);

    if (
      likelyDate === "" ||
      selectedRiskType === "" ||
      riskDesc === "" ||
      selectedRiskCat === "" ||
      probValue === "" ||
      impactValue === "" ||
      selectedTriggerStatus === ""
    ) {
      setIsTouched(true);
      setIsLoading(false);
      return;
    }

    if (new Date(likelyDate) < new Date(projectCreatedDate)) {
      alert(
        "Risk Action date should be greater than equal to Project Start Date."
      );
      setIsLoading(false);
      return;
    } else {
      try {
        let body = {
          projectsId: parseInt(projId),
          riskTypeId: parseInt(selectedRiskType),
          risk: riskDesc,
          riskCategory: parseInt(selectedRiskCat),
          probability: parseInt(probValue),
          impact: parseInt(impactValue),
          riskDate: likelyDate,
          uid: parseInt(sessionStorage.getItem("loginId")),
          status: parseInt(selectedTriggerStatus),
        };
        const response = await RMP_InsertRisk(body);
        if (response.status === 200) {
          if (response.data.message === "Details Inserted Succesfully.") {
            alert("Details Inserted Successfully");
            setIsTouched(false);
            setCheckAdd(false);
            setRiskDesc("");
            pageLoad();
          } else {
            alert(response.data.message);
            return;
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDeleteRisk = async () => {
    // console.log(Object.keys(checkedRows).length, "checkedRows");
    if (checkedRows.length === 0) {
      alert("Please select atleast one row to delete.");
      return;
    }
    const confirmDelete = window.confirm(
      `Do you want to delete ${Object.keys(checkedRows).length} records?`
    );
    if (confirmDelete) {
      setIsLoading(true);
      try {
        const requestBody = Object.keys(checkedRows).map((id) => ({
          riskId: parseInt(id, 10),
        }));
        const response = await RMP_DeleteRisk(requestBody);
        if (response.status === 200) {
          alert("Details Deleted Successfully");
          handleCancel();
          fetchData();
          pageLoad();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      return;
    }
  };

  const handleHistoryClick = (item, element) => {
    element.style.color = "red";
    window.open(
      ` /ccpm/RiskHistoryPopup?PrjRiskId=${item.proid}`,
      "sharer",
      "toolbar=0,status=0,width=1050,height=600"
    );
    setTimeout(() => {
      element.style.color = "#0000EE";
    }, 50);
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    setAllCheckM(isChecked);
    const newCheckedItems = {};
    visibleData.forEach((item) => {
      newCheckedItems[item.proid] = isChecked;
    });
    setCheckedRows(newCheckedItems);
  };

  const upRiskBlur = () => {
    const disallowedCharPattern = /[=+\[\]\/]/;
    if (disallowedCharPattern.test(upRiskDesc)) {
      alert("No Special Characters Allowed.");
      setUpRiskDesc("");
    }
  };

  const insertRiskBlur = () =>{
    if (riskDesc === "") {
      setIsTouched(true);
      return;
    }
    const disallowedCharPattern = /[=+\[\]\/]/;
    if (disallowedCharPattern.test(riskDesc)) {
      alert("No Special Characters Allowed.");
      setRiskDesc("");
    }
  }

  const handleCheckboxChange = (event, id, l1) => {
    setCheckedRows((prev) => ({
      ...prev,
      [id]: event.target.checked,
    }));
  };

  const handleEdit = (item) => {
    if (checkMit === false) {
      setEditing(item.proid);
      setUpRiskId(item.proid);
      setUpRiskType(riskTypeLookup[item.risktype] || firstRiskTypeId);
      setUpRiskDesc(item.risks);
      setDisplaydate2(formatDateFet(item.likelydate));
      const convert = new Date(item.likelydate);
      const isoDate = convert.toISOString();
      setUpLikelyDate(isoDate);
      setUpRiskCat(
        riskCategoryLookup[item.riskcategory] || firstRiskCategoryId
      );
      setUpProb(item.probability);
      setUpImpact(item.impact);
      setUpStatus(
        triggerStatusLookup[item.triggerstatus] || firstTriggerStatusId
      );
    }
  };

  const handleCancel = () => {
    setEditing(null);
    setUpRiskType("");
    setUpRiskDesc("");
    setDisplaydate2("");
    setUpLikelyDate("");
    setUpRiskCat("");
    setUpProb("");
    setUpImpact("");
    setUpStatus("");
  };

  const UpdateRisk = async () => {
    setIsLoading(true);
    try {
      let body = {
        projectsId: parseInt(projId),
        riskId: parseInt(upRiskId),
        riskTypeId: parseInt(upRiskType),
        risk: upRiskDesc,
        riskCategory: parseInt(upRiskCat),
        probability: parseInt(upProb),
        impact: parseInt(upImpact),
        riskDate: upLikelyDate,
        uid: parseInt(sessionStorage.getItem("loginId")),
        status: parseInt(upStatus),
      };
      const response = await RMP_UpdateDetailsPop(body);
      if (response.status === 200) {
        alert("Details Updated Successfully");
        handleCancel();
        fetchData();
        pageLoad();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMitAction = (item) => {
    setMitId(item.proid);
    fetchMitDetails(item.proid, item);
    setAddMit(false);
  };

  const fetchMitDetails = async (id, item) => {
    setIsLoading(true);
    try {
      let body = {
        riskId: parseInt(id),
        withOwnUpdDtl: 0,
      };
      const response = await RMP_GetMitDetails(body);
      if (response.status === 200) {
        setMitData(response?.data?.success?.data);
        setMitProjName(item.proname);
        setMitRiskDesc(item.risks);
        setCheckMit(true);
      } else {
        setMitData(response?.data?.success?.data);
        setMitProjName(item.proname);
        setMitRiskDesc(item.risks);
        setCheckMit(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssignDetails = async () => {
    setIsLoading(true);
    let body = {
      userId: parseInt(sessionStorage.getItem("loginId")),
    };
    try {
      const response = await RMP_Assign(body);
      if (response.status === 200) {
        setAddMit(true);
        setDropDownAssign(response?.data?.success?.data);
        // setSelectedAssign(response?.data?.success?.data[0]?.id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddMit = () => {
    fetchAssignDetails();
  };

  const handleCloseMit = () => {
    setCheckMit(false);
    setMitDesc("");
    setMitId("");
    setMitDate("");
    setSelectedAssign("");
    setDisplaydate3("");
  };

  const handleMitInsert = async () => {
    setIsTouchedMit(true);
    setIsLoading(true);

    if (mitId && mitDesc && mitDate && selectedAssign) {
      if (new Date(mitDate) < new Date(projectCreatedDate)) {
        alert(
          "Risk Action date should be greater than equal to Project Start Date."
        );
        setIsLoading(false);
        return;
      } else {
        try {
          let body = {
            riskId: mitId,
            actionPlan: mitDesc,
            actionByDate: mitDate,
            actionAssignedTo: selectedAssign,
            createdBy: parseInt(sessionStorage.getItem("loginId")),
          };
          const response = await RMP_InsertMit(body);
          if (response.status === 200) {
            alert("Details Inserted Succesfully.");
            fetchMitDetails(mitId);
            setIsTouchedMit(false);
            setAddMit(false);
            setMitDesc("");
            setMitId("");
            setMitDate("");
            setSelectedAssign("");
            setDisplaydate3("");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      // alert("Please fill all the fields.");
      setIsLoading(false);
    }
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  const mitDescBlur = () => {
    if (mitDesc === "") {
      setIsTouched(true);
    }
    const disallowedCharPattern = /[=+\[\]\/]/;
    if (disallowedCharPattern.test(mitDesc)) {
      alert("No Special Characters Allowed.");
      setMitDesc("");
    }
  };

  // lookup objects
  const riskCategoryLookup = dropDownRiskCat.reduce((acc, curr) => {
    acc[curr.riskcatname] = curr.riskcatid;
    return acc;
  }, {});

  const triggerStatusLookup = dropDownTriggerStatus.reduce((acc, curr) => {
    acc[curr.triggerstatus] = curr.triggerid;
    return acc;
  }, {});

  const riskTypeLookup = dropDownRiskType.reduce((acc, curr) => {
    acc[curr.risktypename] = curr.riskid;
    return acc;
  }, {});

  const firstRiskCategoryId = dropDownRiskCat[0]?.riskcatid;
  const firstTriggerStatusId = dropDownTriggerStatus[0]?.triggerid;
  const firstRiskTypeId = dropDownRiskType[0]?.riskid;

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div style={{ overflowX: "auto", height: "100vh" }}>
        <Grid container>
          <Grid item lg={0.7} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>
          <Grid item lg={12}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"RiskAndMitigationPlan"} />
            </Grid>

            <Grid item lg={12}>
              <div className={modules["RM_div"]}>
                <div className={modules["pro-bread"]}>
                  <a>Risk Management</a>
                  <img src={bread} alt="" />
                  <a>Risk & Mitigation Plan</a>
                </div>
              </div>

              <Grid
                container
                className={modules["mid-contRMP"]}
                sx={{ marginLeft: "6.5rem" }}
              >
                <Typography
                  sx={{ marginLeft: "33%" }}
                  className={modules["headingFontBoldRM"]}
                >
                  Risk Identification & Mitigation Plan
                </Typography>
                <table
                  style={{ width: "auto" }}
                  className={modules["custom-tableRM"]}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}></td>
                    </tr>
                    <tr>
                      <td style={{ width: "auto" }}>
                        <div>
                          <table
                            cellSpacing="0"
                            className={modules["custom-tableRM"]}
                            style={{
                              width: "100%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead className={modules["custom-tableRMH"]}>
                              <tr>
                                <td
                                  align="center"
                                  colSpan="4"
                                  style={{ backgroundColor: "LightGray" }}
                                ></td>
                                <td
                                  align="center"
                                  colSpan="3"
                                  style={{ backgroundColor: "LightGreen" }}
                                >
                                  Risk Identification
                                </td>
                                <td
                                  align="center"
                                  colSpan="4"
                                  style={{ backgroundColor: "LightSteelBlue" }}
                                >
                                  Risk Rating
                                </td>
                                <td
                                  align="center"
                                  colSpan="8"
                                  style={{ backgroundColor: "LightPink" }}
                                ></td>
                              </tr>
                              <tr>
                                <th scope="col">
                                  <input
                                    id="chkIsDeleteHeader"
                                    type="checkbox"
                                    checked={selectAllChecked}
                                    onChange={handleSelectAll}
                                  />
                                </th>
                                <th align="left" scope="col">
                                  Sr.&nbsp;No.
                                </th>
                                <th align="left" scope="col">
                                  Project <br /> Name
                                </th>
                                <th align="left" scope="col">
                                  Project&nbsp;Manager
                                </th>
                                <th align="left" scope="col">
                                  Risk&nbsp;Type
                                </th>
                                <th align="left" scope="col">
                                  Risk&nbsp;Desc
                                </th>
                                <th align="left" scope="col">
                                  Likely&nbsp;Date&nbsp;To&nbsp;Occur
                                </th>
                                <th align="left" scope="col">
                                  Risk&nbsp;Category
                                </th>
                                <th align="left" scope="col">
                                  Probability
                                </th>
                                <th align="left" scope="col">
                                  Impact
                                </th>
                                <th align="left" scope="col">
                                  Priority&nbsp;Ranking
                                </th>
                                <th align="left" scope="col">
                                  Status
                                </th>
                                <th align="left" scope="col">
                                  Edit
                                </th>
                                <th align="left" scope="col">
                                  Mitigation
                                </th>
                                <th scope="col">
                                  Risk <br /> History
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {visibleData?.map((item, index) => (
                                <tr
                                  className={
                                    selectAllChecked && allCheckM
                                      ? modules["selected-rowRMP"]
                                      : ""
                                  }
                                >
                                  <td>
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleCheckboxChange(e, item.proid)
                                      }
                                      checked={!!checkedRows[item.proid]}
                                    />
                                  </td>
                                  <td>{index + 1}</td>
                                  <td>
                                    <span>
                                      {item.proname}
                                      {/* {setProjectCreatedDate(
                                            item.createdon
                                          )} */}
                                    </span>
                                  </td>
                                  <td>
                                    <span>{item.projectmanager}</span>
                                  </td>
                                  <td>
                                    {editing === item.proid ? (
                                      <select
                                        name="cars"
                                        id="cars"
                                        style={{ width: "146px" }}
                                        value={upRiskType}
                                        onChange={(e) =>
                                          setUpRiskType(e.target.value)
                                        }
                                        className={modules["dropdownSelect1R"]}
                                      >
                                        {dropDownRiskType?.length > 0 &&
                                          dropDownRiskType?.map((i) => (
                                            <option
                                              key={i.riskid}
                                              value={i.riskid}
                                              style={{ color: "#373736" }}
                                            >
                                              {i.risktypename}
                                            </option>
                                          ))}
                                      </select>
                                    ) : (
                                      <>{item.risktype} </>
                                    )}
                                  </td>
                                  <td>
                                    {editing === item.proid ? (
                                      <>
                                        <textarea
                                          rows="2"
                                          cols="20"
                                          value={upRiskDesc}
                                          onBlur={upRiskBlur}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            const regex =
                                              /^[a-zA-Z0-9&()_\-+=?/ \[\].]*$/;
                                            if (regex.test(value)) {
                                              setUpRiskDesc(value);
                                            }
                                          }}
                                        />
                                        <span
                                          style={{
                                            color: "#FF3300",
                                            visibility: "hidden",
                                          }}
                                        >
                                          *
                                        </span>
                                      </>
                                    ) : (
                                      <> {item.risks} </>
                                    )}
                                  </td>
                                  <td>
                                    {editing === item.proid ? (
                                      <>
                                        <input
                                          style={{ width: "100px" }}
                                          maxLength="20"
                                          value={displayData2}
                                          onChange={(e) =>
                                            setDisplaydate2(e.target.value)
                                          }
                                          className={modules["input-fieldRMP"]}
                                          onClick={(event) =>
                                            setAnchorEl3(event.currentTarget)
                                          }
                                        />
                                        <Popover
                                          open={open3}
                                          anchorEl={anchorEl3}
                                          onClose={() => setAnchorEl3(null)}
                                          placement="bottom-start"
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Calendar
                                            onChange={handleCalendar3n}
                                            value={upLikelyDate}
                                            locale="en-US"
                                            formatShortWeekday={
                                              formatShortWeekday
                                            }
                                          />
                                          <div
                                            className={
                                              modules["calendar-footer"]
                                            }
                                          >
                                            Today: {formattedToday}
                                          </div>
                                        </Popover>
                                      </>
                                    ) : (
                                      <>{formatDateFet(item.likelydate)}</>
                                    )}
                                  </td>
                                  <td>
                                    {editing === item.proid ? (
                                      <select
                                        name="cars"
                                        id="cars"
                                        style={{ width: "102px" }}
                                        value={upRiskCat}
                                        onChange={(e) =>
                                          setUpRiskCat(e.target.value)
                                        }
                                        className={modules["dropdownSelect1R"]}
                                      >
                                        {dropDownRiskCat?.length > 0 &&
                                          dropDownRiskCat?.map((i) => (
                                            <option
                                              key={i.riskcatid}
                                              value={i.riskcatid}
                                              style={{ color: "#373736" }}
                                            >
                                              {i.riskcatname}
                                            </option>
                                          ))}
                                      </select>
                                    ) : (
                                      <> {item.riskcategory} </>
                                    )}
                                  </td>
                                  <td>
                                    {editing === item.proid ? (
                                      <input
                                        type="text"
                                        className={modules["txtboxRMP"]}
                                        value={upProb}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (
                                            value === "" ||
                                            /^[1-5]$/.test(value)
                                          ) {
                                            setUpProb(value);
                                          }
                                        }}
                                        maxLength="1"
                                        pattern="[1-5]"
                                      />
                                    ) : (
                                      <> {item.probability} </>
                                    )}
                                  </td>
                                  <td>
                                    {editing === item.proid ? (
                                      <input
                                        type="text"
                                        value={upImpact}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (
                                            value === "" ||
                                            /^[1-5]$/.test(value)
                                          ) {
                                            setUpImpact(value);
                                          }
                                        }}
                                        className={modules["txtboxRMP"]}
                                        maxLength="1"
                                        pattern="[1-5]"
                                      />
                                    ) : (
                                      <>{item.impact}</>
                                    )}
                                  </td>
                                  <td>
                                    <span>{item.priorityranking}</span>
                                    {/* {editing === item.proid ? (
                                          <>
                                            {checkMult ? (
                                              <span>
                                                {parseInt(probValue, 10) *
                                                  parseInt(impactValue, 10)}
                                              </span>
                                            ) : (
                                              <span>
                                                {item.priorityranking}
                                              </span>
                                            )}
                                          </>
                                        ) : (
                                          <span>{item.priorityranking}</span>
                                        )} */}
                                  </td>
                                  <td>
                                    {editing === item.proid ? (
                                      <select
                                        name="cars"
                                        id="cars"
                                        style={{ width: "102px" }}
                                        value={upStatus}
                                        onChange={(e) => {
                                          setCheckMult(true);
                                          setUpStatus(e.target.value);
                                        }}
                                        className={modules["dropdownSelect1R"]}
                                      >
                                        {dropDownTriggerStatus?.length > 0 &&
                                          dropDownTriggerStatus?.map((i) => (
                                            <option
                                              key={i.triggerid}
                                              value={i.triggerid}
                                              style={{ color: "#373736" }}
                                            >
                                              {i.triggerstatus}
                                            </option>
                                          ))}
                                      </select>
                                    ) : (
                                      <>{item.triggerstatus}</>
                                    )}
                                  </td>
                                  <td>
                                    {editing === item.proid ? (
                                      <>
                                        <button
                                          style={{ width: "100px" }}
                                          className={modules["btn2_RMP"]}
                                          onClick={UpdateRisk}
                                        >
                                          Update
                                        </button>
                                        <button
                                          style={{ width: "100px" }}
                                          className={modules["btn2_RMP"]}
                                          onClick={handleCancel}
                                        >
                                          Cancel
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        style={{ width: "100px" }}
                                        className={modules["btn2_RMP"]}
                                        onClick={() => handleEdit(item)}
                                      >
                                        Edit
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      style={{ width: "138px" }}
                                      className={modules["btn2_RMP"]}
                                      onClick={() => {
                                        handleMitAction(item);
                                      }}
                                    >
                                      Mitigation Action
                                    </button>
                                  </td>
                                  <td align="center" style={{ width: "10%" }}>
                                    <a
                                      className={modules["textRMP"]}
                                      onClick={(e) =>
                                        handleHistoryClick(
                                          item,
                                          e.currentTarget
                                        )
                                      }
                                    >
                                      History
                                    </a>
                                  </td>
                                </tr>
                              ))}
                              {checkAdd && (
                                <tr>
                                  <td>
                                    <input type="checkbox" />
                                  </td>
                                  <td></td>
                                  <td>
                                    <span></span>
                                  </td>
                                  <td>
                                    <span></span>
                                  </td>
                                  <td>
                                    <select
                                      name="cars"
                                      id="cars"
                                      style={{ width: "146px" }}
                                      value={selectedRiskType}
                                      onChange={(e) => {
                                        setSelectedRiskType(e.target.value);
                                      }}
                                      onBlur={() => {
                                        if (selectedRiskType === "") {
                                          setIsTouched(true);
                                        }
                                      }}
                                      className={modules["dropdownSelect1R"]}
                                    >
                                      <option value="select">Select</option>
                                      {dropDownRiskType?.length > 0 &&
                                        dropDownRiskType?.map((i) => (
                                          <option
                                            key={i.riskid}
                                            value={i.riskid}
                                            style={{ color: "#373736" }}
                                          >
                                            {i.risktypename}
                                          </option>
                                        ))}
                                    </select>
                                    {isTouched &&
                                      (selectedRiskType === "select" ||
                                        selectedRiskType === "") && (
                                        <div style={{ color: "red" }}>*</div>
                                      )}
                                  </td>
                                  <td>
                                    <textarea
                                      rows="2"
                                      cols="20"
                                      value={riskDesc}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const regex =
                                          /^[a-zA-Z0-9&()_\-+=?/ \[\].]*$/;
                                        if (regex.test(value)) {
                                          setRiskDesc(value);
                                        }
                                      }}
                                      onBlur={insertRiskBlur}
                                    />
                                    {isTouched && riskDesc === "" && (
                                      <div style={{ color: "red" }}>*</div>
                                    )}
                                    <span
                                      style={{
                                        color: "#FF3300",
                                        visibility: "hidden",
                                      }}
                                    >
                                      *
                                    </span>
                                  </td>
                                  <td>
                                    <input
                                      style={{ width: "100px" }}
                                      maxLength="20"
                                      value={displayData}
                                      onChange={(e) =>
                                        setDisplaydate(e.target.value)
                                      }
                                      className={modules["input-fieldRMP"]}
                                      onClick={(event) =>
                                        setAnchorEl2(event.currentTarget)
                                      }
                                    />
                                    <Popover
                                      open={open2}
                                      anchorEl={anchorEl2}
                                      onClose={() => setAnchorEl2(null)}
                                      placement="bottom-start"
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}
                                    >
                                      <Calendar
                                        onChange={handleCalendar2n}
                                        value={upLikelyDate}
                                        locale="en-US"
                                        formatShortWeekday={formatShortWeekday}
                                      />
                                      <div
                                        className={modules["calendar-footer"]}
                                      >
                                        Today: {formattedToday}
                                      </div>
                                    </Popover>
                                  </td>
                                  <td>
                                    <select
                                      name="cars"
                                      id="cars"
                                      style={{ width: "102px" }}
                                      value={selectedRiskCat}
                                      onChange={(e) => {
                                        setSelectedRiskCat(e.target.value);
                                      }}
                                      onBlur={() => {
                                        if (selectedRiskCat === "") {
                                          setIsTouched(true);
                                        }
                                      }}
                                      className={modules["dropdownSelect1R"]}
                                    >
                                      <option value="select">Select</option>
                                      {dropDownRiskCat?.length > 0 &&
                                        dropDownRiskCat?.map((i) => (
                                          <option
                                            key={i.riskcatid}
                                            value={i.riskcatid}
                                            style={{ color: "#373736" }}
                                          >
                                            {i.riskcatname}
                                          </option>
                                        ))}
                                    </select>
                                    {isTouched &&
                                      (selectedRiskCat === "select" ||
                                        selectedRiskCat === "") && (
                                        <div style={{ color: "red" }}>*</div>
                                      )}
                                  </td>
                                  <td>
                                    <Stack direction="row">
                                      <input
                                        type="text"
                                        className={modules["txtboxRMP"]}
                                        value={probValue}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (
                                            value === "" ||
                                            /^[1-5]$/.test(value)
                                          ) {
                                            setProbValue(value);
                                          }
                                        }}
                                        onBlur={() => {
                                          if (probValue === "") {
                                            setIsTouched(true);
                                          }
                                        }}
                                        maxLength="1"
                                        pattern="[1-5]"
                                      />
                                      {isTouched && probValue === "" && (
                                        <div style={{ color: "red" }}>*</div>
                                      )}
                                    </Stack>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={impactValue}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (
                                          value === "" ||
                                          /^[1-5]$/.test(value)
                                        ) {
                                          setImpactValue(value);
                                        }
                                      }}
                                      onBlur={() => {
                                        if (impactValue === "") {
                                          setIsTouched(true);
                                        }
                                      }}
                                      className={modules["txtboxRMP"]}
                                      maxLength="1"
                                      pattern="[1-5]"
                                    />
                                    {isTouched && impactValue === "" && (
                                      <div style={{ color: "red" }}>*</div>
                                    )}
                                  </td>
                                  <td>
                                    {checkMult ? (
                                      <span>
                                        {parseInt(probValue, 10) *
                                          parseInt(impactValue, 10)}
                                      </span>
                                    ) : (
                                      <span></span>
                                    )}
                                  </td>
                                  <td>
                                    <select
                                      name="cars"
                                      id="cars"
                                      style={{ width: "102px" }}
                                      value={selectedTriggerStatus}
                                      onBlur={() => {
                                        if (selectedTriggerStatus === "") {
                                          setIsTouched(true);
                                        }
                                      }}
                                      onChange={(e) => {
                                        setCheckMult(true);
                                        setSelectedTriggerStatus(
                                          e.target.value
                                        );
                                      }}
                                      className={modules["dropdownSelect1R"]}
                                    >
                                      <option value="select">Select</option>
                                      {dropDownTriggerStatus?.length > 0 &&
                                        dropDownTriggerStatus?.map((i) => (
                                          <option
                                            key={i.triggerid}
                                            value={i.triggerid}
                                            style={{ color: "#373736" }}
                                          >
                                            {i.triggerstatus}
                                          </option>
                                        ))}
                                    </select>
                                    {isTouched &&
                                      (selectedTriggerStatus === "select" ||
                                        selectedTriggerStatus === "") && (
                                        <div style={{ color: "red" }}>*</div>
                                      )}
                                  </td>
                                  <td>
                                    <button
                                      style={{ width: "100px" }}
                                      className={modules["btn2_RMP"]}
                                      onClick={handleInsertRisk}
                                    >
                                      Insert
                                    </button>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              )}
                              {totalPages <= 1 ? null : (
                                <tr>
                                  <td colSpan={16}>
                                    <div className={modules["pagination2"]}>
                                      {getPageNumbers().map(
                                        (pageNumber, index) => (
                                          <span
                                            key={index}
                                            className={
                                              pageNumber === currentPage
                                                ? `${modules["active-page"]}`
                                                : `${modules["page-number"]}`
                                            }
                                            onClick={() =>
                                              typeof pageNumber === "number"
                                                ? handlePageChange(pageNumber)
                                                : null
                                            }
                                          >
                                            {pageNumber}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td style={{ height: "10px" }}></td>
                    </tr>
                  </tbody>
                </table>
                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={4}
                  sx={{ width: "100%" }}
                >
                  {checkMit ? (
                    <>
                      {(editing !== null || checkMit === true) && (
                        <button
                          onClick={handleClickBack}
                          className={modules["btn_RMP"]}
                        >
                          Back
                        </button>
                      )}
                      <input type="hidden" />
                      &nbsp;&nbsp;
                    </>
                  ) : editing === null ? (
                    <>
                      <button className={modules["btn_RMP"]} onClick={addRow}>
                        Add Risk
                      </button>
                      {visibleData?.length > 0 && (
                        <button
                          className={modules["btn_RMP"]}
                          onClick={handleDeleteRisk}
                        >
                          Delete
                        </button>
                      )}
                      <button
                        onClick={handleClickBack}
                        className={modules["btn_RMP"]}
                      >
                        Back
                      </button>
                      <input type="hidden" />
                      &nbsp;&nbsp;
                    </>
                  ) : (
                    <>
                      {(editing !== null || checkMit === true) && (
                        <button
                          onClick={handleClickBack}
                          className={modules["btn_RMP"]}
                        >
                          Back
                        </button>
                      )}
                      <input type="hidden" />
                      &nbsp;&nbsp;
                    </>
                  )}
                </Stack>
                {checkMit && (
                  <div
                    style={{
                      borderColor: "Black",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      width: "80%",
                      padding: "1%",
                      margin: "1%",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        textAlign: "left",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ width: "auto", border: "None" }}>
                            <div>
                              <table
                                cellspacing="0"
                                rules="all"
                                border="1"
                                style={{}}
                                className={modules["mit-tableRM"]}
                              >
                                <tbody>
                                  <tr>
                                    <th align="left">Sr.&nbsp;No.</th>
                                    <th align="left">Project</th>
                                    <th align="left">Risk&nbsp;Desc</th>
                                    <th align="left">
                                      Mitigation&nbsp;Action&nbsp;Plan
                                    </th>
                                    <th align="left">
                                      Action&nbsp;By&nbsp;Date
                                    </th>
                                    <th align="left">Assign&nbsp;To</th>
                                    <th align="left">&nbsp;</th>
                                  </tr>
                                  {mitData?.map((item, index) => (
                                    <tr>
                                      <td align="left">
                                        <span>{index + 1}</span>
                                      </td>
                                      <td align="left">
                                        <span>{item.projectname}</span>
                                      </td>
                                      <td align="left">
                                        <span>{item.risk}</span>
                                      </td>
                                      <td align="left">
                                        <span>{item.mitigationactionplan}</span>
                                      </td>
                                      <td align="left">
                                        <span>
                                          {formatDateFet(item.actionbydate)}
                                        </span>
                                      </td>
                                      <td align="left">
                                        <span>{item.actionassignedto}</span>
                                      </td>
                                      <td align="left">&nbsp;</td>
                                    </tr>
                                  ))}
                                  {addMit && (
                                    <tr>
                                      <td></td>
                                      <td>
                                        <span>{mitProjName}</span>
                                      </td>
                                      <td>
                                        <span>{mitRiskDesc}</span>
                                      </td>
                                      <td>
                                        <textarea
                                          rows="2"
                                          cols="20"
                                          value={mitDesc}
                                          onChange={(e) =>
                                            setMitDesc(e.target.value)
                                          }
                                          onBlur={mitDescBlur}
                                          onInput={(e) => {
                                            const regex =
                                              /^[a-zA-Z0-9()&_\-+=\[\]/?.\s]*$/;
                                            if (!regex.test(e.target.value)) {
                                              e.target.value =
                                                e.target.value.replace(
                                                  /[^a-zA-Z0-9()&_\-+=\[\]/?.\s]/g,
                                                  ""
                                                );
                                            }
                                            setMitDesc(e.target.value);
                                          }}
                                        />
                                        {isTouchedMit && mitDesc === "" && (
                                          <div style={{ color: "red" }}>*</div>
                                        )}
                                        <span
                                          style={{
                                            color: "#FF3300",
                                            visibility: "hidden",
                                          }}
                                        >
                                          *
                                        </span>
                                      </td>
                                      <td>
                                        <input
                                          style={{ width: "100px" }}
                                          maxLength="20"
                                          value={displayData3}
                                          onChange={(e) =>
                                            setDisplaydate3(e.target.value)
                                          }
                                          className={modules["input-fieldRMP"]}
                                          onClick={(event) =>
                                            setAnchorEl4(event.currentTarget)
                                          }
                                        />
                                        <Popover
                                          open={open4}
                                          anchorEl={anchorEl4}
                                          onClose={() => setAnchorEl4(null)}
                                          placement="bottom-start"
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                        >
                                          <Calendar
                                            onChange={handleCalendar4n}
                                            value={mitDate}
                                            locale="en-US"
                                            formatShortWeekday={
                                              formatShortWeekday
                                            }
                                          />
                                          <div
                                            className={
                                              modules["calendar-footer"]
                                            }
                                          >
                                            Today: {formattedToday}
                                          </div>
                                        </Popover>
                                      </td>
                                      <td>
                                        <select
                                          name="cars"
                                          id="cars"
                                          style={{ width: "102px" }}
                                          value={selectedAssign}
                                          onChange={(e) => {
                                            setSelectedAssign(e.target.value);
                                          }}
                                          onBlur={() => {
                                            if (selectedAssign === "") {
                                              setIsTouched(true);
                                            }
                                          }}
                                          className={
                                            modules["dropdownSelect1R"]
                                          }
                                        >
                                          <option value="">Select</option>
                                          <option
                                            value={sessionStorage.getItem(
                                              "loginId"
                                            )}
                                          >
                                            {sessionStorage.getItem("Username")}
                                          </option>
                                          {dropDownAssign?.length > 0 &&
                                            dropDownAssign?.map((i) => (
                                              <option
                                                key={i.id}
                                                value={i.id}
                                                style={{ color: "#373736" }}
                                              >
                                                {i.username}
                                              </option>
                                            ))}
                                        </select>
                                        {isTouchedMit &&
                                          (selectedAssign === "select" ||
                                            selectedAssign === "") && (
                                            <div style={{ color: "red" }}>
                                              *
                                            </div>
                                          )}
                                      </td>
                                      <td></td>
                                      <td>
                                        <button
                                          style={{ width: "100px" }}
                                          className={modules["btn2_RMP"]}
                                          onClick={handleMitInsert}
                                        >
                                          Insert
                                        </button>
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                        <tr style={{ textAlign: "center" }}>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                            sx={{ width: "100%", marginTop: "2%" }}
                          >
                            <>
                              <button
                                className={modules["btn_RMP"]}
                                onClick={handleAddMit}
                              >
                                Add Action
                              </button>
                              <button
                                className={modules["btn_RMP"]}
                                onClick={handleCloseMit}
                              >
                                Done
                              </button>
                              <input type="hidden" />
                              &nbsp;&nbsp;
                            </>
                          </Stack>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
