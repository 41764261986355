import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  Popover,
} from "@mui/material";
import modules from "./../ccpm.module.css";
import Box from "@mui/material/Box";
import { Tab, Tabs } from "@mui/material";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Calendar from "react-calendar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetPCSearchResult,
  GetPCProjectCount,
  GetPCallprojectdetails,
  GetPLTdetails,
} from "../../../Services/CCPM.js";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

const ProjectLeadTime = () => {
  const [selectedInstance, setSelectedInstance] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");

  const [dropdownData, setDropdownData] = useState([
    "Select",
    "Instance 2",
    "Instance 3",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(true);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [displayfromdate, setdisplayfromdate] = useState("");
  const [displaytodate, setdisplaytodate] = useState("");
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleFromDateChange = (date) => {
    setAnchorEl2(null);
    setFromdate(date);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    setdisplayfromdate(formattedDate);
  };

  const handleToDateChange = (date) => {
    setAnchorEl(null);
    setTodate(date);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    setdisplaytodate(formattedDate);
  };

  const handleSelectChange = (attributenumber, id) => {
    // console.log(attributenumber, id, "attributenumber value");
    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());

  const [instances, setInstances] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [accessTypes, setAccessTypes] = useState([
    { value: "null", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ]);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsname = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    GetPCBindProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const date1 = new Date(displayfromdate);
    const date2 = new Date(displaytodate);

    // Extract the month, day, and year
    const month1 = (date1.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day1 = date1.getDate().toString().padStart(2, "0");
    const year1 = date1.getFullYear();

    const month2 = (date2.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day2 = date2.getDate().toString().padStart(2, "0");
    const year2 = date2.getFullYear();

    // Format the date into MM/DD/YYYY
    const NewFromDate = `${month1}/${day1}/${year1}`;
    const NewToDate = `${month2}/${day2}/${year2}`;

    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: parseInt(selectedInstance),
      userId: parseInt(loginId),
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      accessType: selectedProjectAccessType || "",
      fromDate:
        (isNaN(new Date(NewFromDate).getTime()) ? "" : NewFromDate) || "",
      toDate: (isNaN(new Date(NewToDate).getTime()) ? "" : NewToDate) || "",
    };
    GetPLTdetails(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "search result");
          setTableData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = () => {
    setShowResult(true);
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getSearchResult(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
  };

  const toggleCalendar = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const toggleToCalendar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return dateStr;

    dateStr = String(dateStr); // Ensure dateStr is a string

    let day, month, year;

    if (dateStr.includes("/")) {
      // Handle format YYYY/MM/DD
      [year, month, day] = dateStr.split("/");
    } else if (dateStr.includes("-")) {
      // Handle format YYYY-MM-DD
      [year, month, day] = dateStr.split("-");
    } else {
      // Handle unexpected format
      return dateStr;
    }

    return `${day}/${month}/${year}`;
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"ProjectLeadTime"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "5px" }}
            >
              <div className={modules["pro-bread"]}>
                <a>Reports</a>
                <img src={bread} alt="" />
                <a>Project Lead Time</a>
              </div>
            </div>
            <div className={modules["border_cont"]}>
              <table className={modules["PCtable"]}>
                <tbody>
                  <tr style={{ paddingBottom: "0px", paddingLeft: "0px" }}>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "115px", color: "#414141" }}>
                        Instance :<label style={{ color: "Red" }}>*</label>
                      </span>

                      <select
                        value={selectedInstance}
                        onChange={(e) => {
                          setSelectedInstance(e.target.value);
                          getProjectDetails(e.target.value);
                        }}
                        className={modules["dropdownSelect"]}
                        // style={{ width: "200px" }}
                      >
                        {instances?.map((instance) => (
                          <option key={instance.name} value={instance.id}>
                            {instance.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    {hierarchyOptions
                      ?.filter((option) => option.isactive === 1)
                      .map((option, index) => (
                        <React.Fragment key={option.attributename}>
                          <td className={modules["table_data1"]}>
                            <span style={{ width: "115px", color: "#414141" }}>
                              {option.attributename} :
                            </span>
                            <select
                              value={
                                selectedAttributes[
                                  `attr${option.attributenumber}`
                                ] || ""
                              }
                              onChange={(e) =>
                                handleSelectChange(
                                  option.attributenumber,
                                  e.target.value
                                )
                              }
                              className={modules["dropdownSelect"]}
                              // style={{ width: "200px" }}
                            >
                              <option value="">SELECT</option>
                              {filteredValueData[index]?.map((item) => (
                                <option
                                  key={item.attributevalue}
                                  value={item.id}
                                >
                                  {item.attributevalue}
                                </option>
                              ))}
                            </select>
                          </td>
                        </React.Fragment>
                      ))}
                  </tr>
                  <tr
                    style={{
                      paddingBottom: "10px",
                      paddingLeft: "0px",
                    }}
                  >
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "115px", color: "#414141" }}>
                        Project Name:
                      </span>
                      <select
                        value={selectedProjectName}
                        onChange={(e) => setSelectedProjectName(e.target.value)}
                        className={modules["dropdownSelect"]}
                        // style={{ width: "200px" }}
                      >
                        <option value={0}>SELECT</option>
                        {projects?.map((p_name) => (
                          <option key={p_name.projectname} value={p_name.id}>
                            {p_name.projectname}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "115px", color: "#414141" }}>
                        Project Manager:
                      </span>
                      <select
                        value={selectedProjectManager}
                        onChange={(e) =>
                          setSelectedProjectManager(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        <option value={0}>SELECT</option>
                        {managers?.map((m_name) => (
                          <option key={m_name.username} value={m_name.id}>
                            {m_name.username}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "115px", color: "#414141" }}>
                        Project AccessType:
                      </span>
                      <select
                        value={selectedProjectAccessType}
                        onChange={(e) =>
                          setSelectedProjectAccessType(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        {accessTypes?.map((access) => (
                          <option key={access.label} value={access.value}>
                            {access.label}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span
                        style={{
                          width: "110px",
                          marginRight: "0.5rem",
                          color: "#414141",
                        }}
                      >
                        Task Completion From Date:
                      </span>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "3px",
                        }}
                      >
                        <input
                          className={modules["datepicker"]}
                          style={{
                            borderRadius: "10px",
                            border: "1px solid #9d9d9c",
                            fontFamily: "WorkSans, sans-serif",
                            color: "#373736",
                            fontSize: "12px",
                            padding: "2px",
                          }}
                          type="text"
                          readOnly
                          value={displayfromdate}
                          placeholder="dd/MM/yyyy"
                          onClick={(event) => toggleCalendar(event)}
                        />
                        <Popover
                          open={open2}
                          anchorEl={anchorEl2}
                          onClose={() => setAnchorEl2(null)}
                          placement="bottom-start"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Calendar
                            onChange={handleFromDateChange}
                            value={fromdate}
                          />
                        </Popover>
                      </div>
                    </td>
                    <td className={modules["table_data1"]}>
                      <span
                        style={{
                          width: "110px",
                          marginRight: "0.5rem",
                          color: "#414141",
                        }}
                      >
                        To Date:
                      </span>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "3px",
                        }}
                      >
                        <input
                          className={modules["datepicker"]}
                          style={{
                            borderRadius: "10px",
                            border: "1px solid #9d9d9c",
                            fontFamily: "WorkSans, sans-serif",
                            color: "#373736",
                            fontSize: "12px",
                            padding: "2px",
                          }}
                          type="text"
                          readOnly
                          value={displaytodate}
                          placeholder="dd/MM/yyyy"
                          onClick={(event) => toggleToCalendar(event)}
                        />
                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={() => setAnchorEl(null)}
                          placement="bottom-start"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Calendar
                            onChange={handleToDateChange}
                            value={todate}
                          />
                        </Popover>
                      </div>
                    </td>
                  </tr>
                  <tr
                    style={{
                      justifyContent: "center",
                      gap: "10px",
                      paddingLeft: "0px",
                      paddingBottom: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      className={modules["PCbtn"]}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["PCbtn"]}
                      type="button"
                      onClick={() => {
                        window.location.reload();
                        setShowResult(false);
                      }}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>

            {showResult && tableData.length > 0 && (
              <>
                <Grid
                  container
                  sx={{ marginLeft: "6.5rem", paddingTop: "8px" }}
                >
                  <Table
                    className={modules["IM_custom-table"]}
                    sx={{ width: "90% !important" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ lineHeight: " normal !important" }}>
                          Sr No
                        </TableCell>
                        <TableCell sx={{ lineHeight: " normal !important" }}>
                          Project Name
                        </TableCell>
                        <TableCell sx={{ lineHeight: " normal !important" }}>
                          Project Manager
                        </TableCell>
                        <TableCell sx={{ lineHeight: " normal !important" }}>
                          Planned Lead Time
                        </TableCell>
                        <TableCell sx={{ lineHeight: " normal !important" }}>
                          Actual Lead Time
                        </TableCell>
                        <TableCell sx={{ lineHeight: " normal !important" }}>
                          Planned Start Date
                        </TableCell>
                        <TableCell sx={{ lineHeight: " normal !important" }}>
                          Planned Finish Date
                        </TableCell>
                        <TableCell sx={{ lineHeight: " normal !important" }}>
                          Actual Start Date
                        </TableCell>
                        <TableCell sx={{ lineHeight: " normal !important" }}>
                          Actual Finish Date
                        </TableCell>
                        <TableCell sx={{ lineHeight: " normal !important" }}>
                          Project Additional Details
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData?.map((item, index) => (
                        <TableRow className={modules["PLT_Tcell"]} key={index}>
                          <TableCell className={modules["PLT_Tcell"]}>
                            {index + 1}
                          </TableCell>
                          <TableCell className={modules["PLT_Tcell"]}>
                            {item.projectname}
                          </TableCell>
                          <TableCell>{item.projectmanagername}</TableCell>
                          <TableCell>
                            {item.plannedleadtime != null
                              ? item.plannedleadtime?.toFixed(2)
                              : "0.00"}
                          </TableCell>
                          <TableCell>
                            {item.actualleadtime?.toFixed(2)}
                          </TableCell>

                          <TableCell>
                            {formatDate(item.plannedstartdate)}
                          </TableCell>
                          <TableCell>
                            {formatDate(item.plannedfinishdate)}
                          </TableCell>
                          <TableCell>
                            {formatDate(item.actualstartdate)}
                          </TableCell>
                          <TableCell>
                            {formatDate(item.actualfinishdate)}
                          </TableCell>
                          <TableCell>{item.projectdetails}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectLeadTime;
