import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
// import modules from "./Portfoliochart.module.css";
import modules from "./../ccpm.module.css";
import Box from "@mui/material/Box";
import { Tab, Tabs, TextField } from "@mui/material";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetBMSearchResult,
  DTFGetTaskManagers,
  DTFSearchClick,
  DTFExportClick,
  GetSearchClick
} from "../../../Services/CCPM.js";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

import { styled } from '@mui/system';
import { enUS } from 'date-fns/locale';
import { Stack, Popover, Paper } from '@mui/material';

import { format } from 'date-fns';

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const Projectwisecriticalchain = () => {
  const [searchValue, setSearchValue] = useState("");
  // State for selected values
  const [selectedInstance, setSelectedInstance] = useState("");

  const [selectedZone, setSelectedZone] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [resultTableData, setResultTableData] = useState([]);
  const [selectedAccessType, setSelectedAccessType] = useState("SELECT");

  const [selectedmaximumYAxis, setselectedmaximumYAxis] = useState("100");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");

  const [dropdownData, setDropdownData] = useState([
    "Select",
    "Instance 2",
    "Instance 3",
  ]);


  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  const handleCalendarChangeFrom = (date) => {
    setFromDate(date);
    setAnchorElFrom(null);
  };
  const handleCalendarChangeTo = (date) => {
    setToDate(date);
    setAnchorElTo(null);
  };
  const formattedToday = new Date().toLocaleDateString();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [anchorElFrom, setAnchorElFrom] = useState(null);
  const [anchorElTo, setAnchorElTo] = useState(null);


  const HiddenCalendarInput = styled(TextField)({
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      display: 'none',
    },
    '& .MuiInputBase-input': {
      height: '25px',
      padding: '0px 0px 0px 5px'
    },
  });

  const handleSelectChange = (attributenumber, id) => {

    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [radioValue, setRadioValue] = useState("yes");

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const [instances, setInstances] = useState([]);
  const [taskManagers, setTaskManagers] = useState([]);
  const [selectedTaskManager, setSelectedTaskManager] = useState(0);
  const [zones, setZones] = useState([]);
  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [dateColumns, setDateColumns] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [failedCheck, setFailedCheck] = useState(false);
  const [accessTypes, setAccessTypes] = useState([
    { value: "", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ]);


  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
    getTaskManagers();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTaskManagers = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    DTFGetTaskManagers(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "task managers");
          setTaskManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (showTable) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = ''; // Reset to default overflow
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = ''; // Reset to default overflow
    };
  }, [showTable]);


  const getProjectsname = (I_id) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCBindProjectName(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(false);
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
      projStatusId: 1,
    };
    GetPCBindProjectManager(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(false);
          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    setIsLoading(true);
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(false);
          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(false);
          console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getSearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      accessType: selectedProjectAccessType,


    };
    GetSearchClick(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const responseData = response.data.success.data;
          console.log(responseData, "search result");

          if (!responseData || responseData.length === 0) {
            setFailedCheck(true);  // Set failedCheck to true when no data is available
            setIsLoading(false);
            alert("No data to display!!");
            return;
          }

          // Set the chart data
          setIsLoading(false);
          setChartData(responseData);
          setShowTable(true);
          setFailedCheck(false);
          // Extract columns
          if (responseData.length > 0) {
            const columns = Object.keys(responseData[0]);
            console.log("All columns: ", columns); // Log to verify columns

            // Regular expression to match date patterns like 'YYYY/MM/DD'
            const datePattern = /^\d{4}\/\d{2}\/\d{2}$/;
            const dateCols = columns.filter((col) => datePattern.test(col));
            console.log("Date columns: ", dateCols); // Log to verify date columns

            setDateColumns(dateCols);
          }

        }
        else {
          alert(response.data.message);
          setIsLoading(false);

        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };





  const handleSearch = () => {
    setIsLoading(true);
    setShowResult(true);
    console.log(selectedAttributes, "selectedAttributes");

    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getSearchResult(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );


  };

  const handleReset = () => {
    setSearchValue("");
    if (instances && instances.length > 0) {
      const firstInstance = instances[0].id; // Use the first instance directly
      setSelectedInstance(firstInstance);
      getProjectDetails(firstInstance);
    }
    setSelectedZone("");
    setSelectedProject("");
    setSelectedManager("");
    setSelectedAccessType("SELECT");
    setShowTable(false);
    setShowTable(false);
    setSelectedProjectName(0);
    setSelectedProjectManager(0);
    setSelectedProjectAccessType("");
    setSelectedAttributes({});


  };


  const formatNumber = (num) => {
    if (num === 0) {
      return '0.00';
    } else if (num > 0 && num % 1 !== 0) {
      return num.toFixed(2);
    } else {
      return num.toString();
    }
  };


  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"Projectwisecriticalchain"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "5px" }}
            >
              <div className={modules["pro-bread"]}>
                <a>Report</a>
                <img src={bread} alt="" />
                <a>Project wise % critical chain completed</a>
              </div>

            </div>
            <div className={modules["border_cont"]}>
              <table className={modules["PCtable"]}>
                <tbody>
                  <tr style={{ paddingBottom: "0px" }}>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>
                        Instance:<label style={{ color: "Red" }}>*</label>
                      </span>

                      <select
                        value={selectedInstance}
                        onChange={(e) => {
                          setSelectedInstance(e.target.value);
                          getProjectDetails(e.target.value);
                        }}
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        {instances?.map((instance) => (
                          <option key={instance.name} value={instance.id}>
                            {instance.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    {hierarchyOptions
                      ?.filter((option) => option.isactive === 1)
                      .map((option, index) => (
                        <React.Fragment key={option.attributename}>
                          <td className={modules["table_data1"]}>
                            <span style={{ width: "110px" }}>
                              {option.attributename} :
                            </span>
                            <select
                              value={
                                selectedAttributes[
                                `attr${option.attributenumber}`
                                ] || ""
                              }
                              onChange={(e) =>
                                handleSelectChange(
                                  option.attributenumber,
                                  e.target.value
                                )
                              }
                              className={modules["dropdownSelect"]}
                            >
                              <option value="">SELECT</option>
                              {filteredValueData[index]?.map((item) => (
                                <option
                                  key={item.attributevalue}
                                  value={item.id}
                                >
                                  {item.attributevalue}
                                </option>
                              ))}
                            </select>
                          </td>
                        </React.Fragment>
                      ))}
                  </tr>
                  <tr
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>Project Name:</span>
                      <select
                        value={selectedProjectName}
                        onChange={(e) => setSelectedProjectName(e.target.value)}
                        className={modules["dropdownSelect"]}
                      >
                        <option>SELECT</option>
                        {projects?.map((p_name) => (
                          <option key={p_name.projectname} value={p_name.id}>
                            {p_name.projectname}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>Project Manager:</span>
                      <select
                        value={selectedProjectManager}
                        onChange={(e) =>
                          setSelectedProjectManager(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                      >
                        <option>SELECT</option>
                        {managers?.map((m_name) => (
                          <option key={m_name.username} value={m_name.id}>
                            {m_name.username}
                          </option>
                        ))}
                      </select>
                    </td>



                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>
                        Project AccessType:
                      </span>
                      <select
                        value={selectedProjectAccessType}
                        onChange={(e) =>
                          setSelectedProjectAccessType(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                      >
                        {accessTypes.map((access) => (
                          <option key={access.label} value={access.value}>
                            {access.label}
                          </option>
                        ))}
                      </select>
                    </td>


                  </tr>

                  <tr
                    style={{
                      justifyContent: "center",
                      gap: "10px",
                      paddingLeft: "0px",
                      paddingBottom: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      className={modules["PCbtn"]}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["PCbtn"]}
                      type="button"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>
            {showTable && (
              <>

                <Grid container className={modules["instanceAttMap_table-grid"]} style={{ marginLeft: '6.5rem', marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                  <div className={modules["ccpm_table-containerCC"]}>
                    <Table style={{ width: '90%' }} className={modules["custom-tableCC"]}>
                      <TableHead className="table_head" style={{ backgroundColor: 'whitesmoke' }}>
                        <TableRow >
                          <TableCell className="fixed-width-cell" sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px', width: '70px', backgroundColor: 'whitesmoke', textAlign: 'center' }}>
                            Project
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '12px', width: '200px', justifyContent: 'flex-start', textAlign: 'center' }}>
                            Original Buffer (in days)
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '12px', width: '200px', textAlign: 'center' }}>
                            Remaining buffer (in days)
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '12px', width: '100px', textAlign: 'center' }}>
                            % buffer consumed
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '12px', width: '100px', textAlign: 'center' }}>
                            Original Chain (in days)
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '12px', width: '100px', textAlign: 'center' }}>
                            Remaining Chain (in days)
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '12px', width: '100px', textAlign: 'center' }}>
                            % critical chain completed
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '12px', width: '100px', textAlign: 'center' }}>
                            Color
                          </TableCell>



                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {chartData.map((row) => (
                          <TableRow key={row.id} className={modules["table-rowPSCD"]}>
                            <TableCell className={modules["table-cell"]}>{row.projectname}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.projectbuffer.toFixed(2)}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.remainingprojectbuffer.toFixed(2)}</TableCell>
                            <TableCell className={modules["table-cell"]}>{(row.projectbufferconsumed)}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.originalccduration.toFixed(2)}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.remainingccduration.toFixed(2)}</TableCell>
                            <TableCell className={modules["table-cell"]}>{(row.criticalchaincompletion)}</TableCell>
                            <TableCell
                              className={modules["table-cell"]}
                              style={{ backgroundColor: row.color }}
                            >

                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <br></br>

                  </div>
                </Grid>
              </>
            )}



          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Projectwisecriticalchain;