import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  TextField,
  Stack,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../../Landing/Navbar";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  CM_AddNew,
  DM_AddReason,
  DM_InstanceNames,
  DMUpdData,
  DMDelData,
  DM_GetResons,
} from "../../../Services/CCPM";
import save from "../../../Assets/save.png";
import loading from "../../../Assets/Loading_image.gif";

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
          cursor: "pointer",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px", cursor: "pointer" }} />
    </div>
  );
}

const DelayMaster = () => {
  const [searchCat, setSearchCat] = useState("");
  const [addCat, setAddCat] = useState("");
  const [dropdownData, setDropdownData] = useState([
    "Select",
    "Instance 2",
    "Instance 3",
  ]);
  const [mainDropdownData, setMainDropdownData] = useState([]);
  const [displayvalue, setDisplayValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [editing, setEditing] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [editReasonName, setEditReasonName] = useState("");
  const [failedCheck, setFailedCheck] = useState(false);
  const [isEntered, setIsEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    setIsLoading(true);
    checkLoginAndRedirect();
    let body = {
      userId: sessionStorage.getItem("loginId"),
    };

    DM_InstanceNames(body)
      .then((response) => {
        if (response.status === 200) {
          const apiData = response.data;
          setIsLoading(false);
          const data = apiData.success.data;
          const names = data.map((item) => item.name);
          setDropdownData(names);
          setMainDropdownData(data);
          if (names.length > 0) {
            setDisplayValue(names[0]);
            console.log(setDisplayValue);
            setSelectedId(data[0].id);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log(setDisplayValue);

  const handleChange = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.name === selectedName
    );
    setShowTable(false);
    setFailedCheck(false);
    setDisplayValue(selectedName);
    setSelectedId(selectedItem.id);
  };

  const handleSearch = () => {
    let body = {
      instanceId: selectedId,
      reason: searchCat !== "" ? searchCat : "",
    };

    DM_GetResons(body)
      .then((response) => {
        if (response.status === 200) {
          setFailedCheck(false);
          const apiData = response.data;
          const data = apiData.success.data;
          if (data.length === 0) {
            setShowTable(false);
            setFailedCheck(true);
          } else {
            setFilteredData(data);
            setShowTable(true);
          }
        } else {
          setFailedCheck(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCatAdd = () => {
    if (addCat.trim() === "") {
      alert("Please Enter Delay Reason to be Added.");
      return;
    }
    const reasonExists = filteredData.some(
      (item) => item.reason.toLowerCase() === addCat.toLowerCase().trim()
    );

    if (reasonExists) {
      alert("Delay Reason already exists for the selected Instance");
      return;
    } else {
      let body = {
        instanceId: selectedId,
        reason: addCat.trim(),
        levelId: selectedId,
        createdBy: sessionStorage.getItem("loginId"),
      };
      DM_AddReason(body)
        .then((response) => {
          if (response.status === 200) {
            alert("Details Inserted Succesfully");
            handleSearch();
            setAddCat("");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleReset = () => {
    setSearchCat("");
    setFilteredData([]);
    setShowTable(false);
    setFailedCheck(false);
    setDisplayValue(dropdownData[0]);
  };

  const handleEdit = (item, element) => {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(item.reason);
      setEditReasonName(item.reason);
      element.style.color = "#0000EE";
    }, 100);
  };

  const handleUpdate = (item, element) => {
    element.style.color = "red";
    setTimeout(() => {
      element.style.color = "#0000EE";
      setTimeout(() => {
        if (item.reason === editReasonName) {
          alert(
            "No Update:Delay Reason already Exists for Selected Instance.."
          );
          return;
        } else {
          let body = {
            id: item.id,
            reason: editReasonName,
            levelId: selectedId,
            lastModifiedBy: sessionStorage.getItem("loginId"),
            instanceId: selectedId,
          };
          DMUpdData(body)
            .then((response) => {
              if (response.status === 200) {
                handleSearch();
                setEditing(null);
                alert("Details Updated Successfully");
              } else if (response?.response?.status === 400) {
                alert(response?.response?.data?.message);
                // alert("Delay Reason already Exists for Selected Instance.");
              } else {
                element.style.color = "#0000EE";
                setEditing(null);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        element.style.color = "#0000EE";
      }, 50);
    }, 100);
  };

  const handleDelete = (item, element) => {
    element.style.color = "red";
    setTimeout(() => {
      const confirmDelete = window.confirm(`Are you sure you want to delete?`);
      if (confirmDelete) {
        let body = {
          id: item.id,
          lastModifiedBy: sessionStorage.getItem("loginId"),
        };
        DMDelData(body)
          .then((response) => {
            if (response.status === 200) {
              handleSearch();
              element.style.color = "#0000EE";
              alert("Details Deleted Successfully");
            } else {
              element.style.color = "#0000EE";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        element.style.color = "#0000EE";
      }
    }, 100);
  };

  const handleCancel = (element) => {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(null);
      setEditReasonName("");
      element.style.color = "#0000EE";
    }, 100);
  };

  const searchCatBlur = () => {
    if (isEntered) {
      const disallowedRegex = /[=+\[\]./]/;
      if (disallowedRegex.test(searchCat)) {
        alert("No special characters allowed");
        setSearchCat("");
      }
    }
  };

  function handleItemEnter(e) {
    if (e.key === "Enter") {
      setIsEntered(false);
      const disallowedRegex = /[=+\[\]./]/;
      if (disallowedRegex.test(searchCat)) {
        alert("No special characters allowed");
        setSearchCat("");
      }
    }
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"DelayMaster"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "0px" }}
            >
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>Master</a>
                <img src={bread} alt="" />
                <a> Delay Reason </a>
              </div>
            </div>

            <Grid
              container
              className={modules["mid-cont"]}
              style={{ marginLeft: "7rem" }}
            >
              <Grid
                container
                columnSpacing={{ xs: 2 }}
                style={{ alignItems: "center", marginTop: "0px" }}
              >
                <Grid
                  item
                  className={modules["label-style"]}
                  style={{ fontWeight: "bold" }}
                >
                  <strong>Instance</strong> <span>:</span>{" "}
                  <span style={{ color: "red" }}>*</span>
                </Grid>
                <Grid item>
                  <FormControl className={modules["form-control"]}>
                    <select
                      IconComponent={ArrowDropDownIcon}
                      className={modules["dropdownSelect"]}
                      style={{ width: "18rem" }}
                      value={displayvalue}
                      onChange={handleChange}
                    >
                      
                      {dropdownData.map((i) => {
                        return (
                          <option
                            className={modules["menuItem"]}
                            style={{ fontSize: "12px", color: "#373736" }}
                            value={i}
                            key={i}
                          >
                            {i}
                          </option>
                        );
                      })}
                    </select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  className={modules["label-style"]}
                  style={{ fontWeight: "300", alignItems: "center" }}
                >
                  <span>Delay Reason</span> <span>:</span>
                </Grid>
                <Grid item>
                  <span>
                    <input
                      value={searchCat}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /[^a-zA-Z0-9 &_()-]/g;
                        const disallowedRegex = /[=+\[\]./]/;

                        if (disallowedRegex.test(value)) {
                          setSearchCat(e.target.value);
                        } else {
                          const sanitizedValue = value.replace(regex, "");
                          setSearchCat(sanitizedValue);
                        }
                      }}
                      onBlur={searchCatBlur}
                      onKeyDown={handleItemEnter}
                      onFocus={() => setIsEntered(true)}
                      className={modules["input_css"]}
                      style={{
                        marginLeft: "0rem",
                        height: "1.5rem",
                        width: "18rem",
                        borderRadius: "13px",
                      }}
                    />
                  </span>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              className={modules["custMas_btn1-cont"]}
              style={{ marginBottom: "4px" }}
            >
              <Stack direction="row" spacing={1}>
                <button className={modules["btn1"]} onClick={handleSearch}>
                  Search
                </button>
                <button className={modules["btn2"]} onClick={handleReset}>
                  Reset
                </button>
              </Stack>
            </Grid>

            <Grid
              container
              className={modules["mid-cont"]}
              style={{ marginLeft: "7rem" }}
            >
              <Grid
                container
                columnSpacing={{ xs: 2 }}
                style={{ alignItems: "center" }}
              >
                <Grid
                  item
                  className={modules["label-style"]}
                  style={{ fontWeight: "bold" }}
                >
                  Delay Reason :<span style={{ color: "red" }}>*</span>
                </Grid>

                <Grid item>
                  <span>
                    <input
                      value={addCat}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /[^a-zA-Z0-9 _-]/g;
                        const sanitizedValue = value.replace(regex, "");
                        setAddCat(sanitizedValue);
                      }}
                      className={modules["input_css"]}
                      onBlur={searchCatBlur}
                      onKeyDown={handleItemEnter}
                      onFocus={() => setIsEntered(true)}
                      style={{
                        marginLeft: "0rem",
                        height: "1.5rem",
                        width: "20rem",
                        borderRadius: "13px",
                      }}
                    />
                  </span>
                </Grid>
                <button
                  className={modules["btn2"]}
                  onClick={handleCatAdd}
                  style={{ marginTop: "0rem", marginLeft: "16px" }}
                >
                  Add Delay Reason
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showTable ? (
          <>
            {/* <Stack
              direction="row"
              sx={{
                width: "70%",
                marginLeft: "6rem",
                marginBottom: "4%",
                marginTop: "2rem",
              }}
              spacing={2}
            > */}
            <Grid
              container
              style={{ width: "47%", marginLeft: "6rem", marginTop: "2rem" }}
            >
              {/* <div> */}
              <Table
                className={modules["custom-table"]}
                sx={{ marginLeft: "2rem" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No.</TableCell>
                    <TableCell>Reason For Delay</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData?.map((item, index) => (
                    <TableRow
                      key={index}
                      className={modules["table-row"]}
                      sx={{ color: "#414141" }}
                    >
                      <TableCell
                        className={modules["table-cell"]}
                        sx={{ color: "#414141" }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        className={modules["table-cell"]}
                        sx={{ color: "#414141" }}
                      >
                        {editing === item.reason ? (
                          <input
                            type="text"
                            value={editReasonName}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /[^a-zA-Z0-9 _-]/g;
                              const sanitizedValue = value.replace(regex, "");
                              setEditReasonName(sanitizedValue);
                            }}
                          />
                        ) : (
                          <div>
                            <span style={{}}>{item.reason}</span>
                          </div>
                        )}
                      </TableCell>
                      <TableCell
                        className={modules["table-cell"]}
                        sx={{ color: "#414141" }}
                      >
                        {editing === item.reason ? (
                          <>
                            <a
                              style={{
                                paddingRight: "1rem",
                                cursor: "pointer",
                                color: "#0000EE",
                              }}
                              onClick={(e) =>
                                handleUpdate(item, e.currentTarget)
                              }
                            >
                              Update
                            </a>
                            <a
                              style={{
                                cursor: "pointer",
                                color: "#0000EE",
                              }}
                              onClick={(e) => handleCancel(e.currentTarget)}
                            >
                              Cancel
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              style={{
                                paddingRight: "1rem",
                                cursor: "pointer",
                                color: "#0000EE",
                              }}
                              onClick={(e) => handleEdit(item, e.currentTarget)}
                            >
                              Edit
                            </a>
                            <a
                              style={{
                                cursor: "pointer",
                                color: "#0000EE",
                              }}
                              onClick={(e) =>
                                handleDelete(item, e.currentTarget)
                              }
                            >
                              Delete
                            </a>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* </div> */}
            </Grid>
            {/* </Stack> */}
          </>
        ) : failedCheck ? (
          <>
            <div
              style={{
                color: "black",
                border: "1px solid black",
                padding: "8px",
                fontSize: "15px",
                marginLeft: "6rem",
              }}
            >
              No Records found
            </div>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default DelayMaster;
