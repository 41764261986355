import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SPIC1 from "../HelpPopups/images/SPIC1.png";
import SPIC2 from "../HelpPopups/images/SPIC2.png";
import SPIC3 from "../HelpPopups/images/SPIC3.png";
import SPIC4 from "../HelpPopups/images/SPIC4.png";
import SPIC5 from "../HelpPopups/images/SPIC5.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const StockPointItemConfigurationIncrementalhelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h2 style={{ marginLeft: "0rem", textAlign: "left" }}>
                                Stock Point Item Configuration
                            </h2>
                            <br />
                            <br />


                            <li className="no-bullets">
                                Path `{">>"}` MTA `{">>"}` MTA Parameter `{">>"}` Stock Point Item Configuration Incremental
                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SPIC1} alt="SPIC1" />
                            </Stack>
                            <br />
                            <li className="no-bullets">
                                Here we link the user with instance to access the MTA module.

                            </li>
                            <br />
                            <li className="no-bullets">
                                Below is the UI for user instance linking
                            </li>
                            <br />


                            <br />


                            <li className="no-bullets">
                                Above session is used for linking instance with a user. User can work in instances which are linked to his login id.
                            </li>
                            <br />

                            <li className="no-bullets" style={{ textDecoration: "underline" }}>
                                · Search Login id for the user.

                                · A list of all instances will appear below it.

                                · Tick for related instances for the user.

                                · Then submit.

                                · User is linked for the Instance.
                            </li>
                            <br />
                            <li>
                                Default instance can be set. The selected instance will be available in all the drop down in all sessions as default instance.
                            </li>
                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StockPointItemConfigurationIncrementalhelpPopup;
