import React from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import BS1 from "../HelpPopups/images/BS1.png";
import { Link } from "react-router-dom";

const ScheduleBufferManagementRun = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link style={{ color: "blue", marginRight: "0.6rem" }}>
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul>
              <h3 style={{ marginLeft: "5rem" }}>Buffer Scheduler</h3>
              <br />
              <li>
              “Schedule Buffer Management Run” is used for the executing the Auto buffer run of all the projects at specified time              </li>
              <li>PATH : CCPM \ Masters \ Buffer Scheduler</li>

              <li>
              For specifying the time upon which the Auto buffer run should execute, define time in fields “Add Start Time” , select the date in field “Effective From” , and select the frequency in field “Frequency” and click on Add button.
              </li>
              <li>
              The entered values are stored as records, and if we want to remove the entered record click on the Remove link. And for changing the values click on the input fields and change the values and click on Add Button
              </li>
              <Stack sx={{ paddingRight: "1rem"}}>
                <img src={BS1} alt="ICM1" />
              </Stack>
              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ScheduleBufferManagementRun;
