import React, { useEffect, useState } from "react";
import modules from "./Admin.module.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography } from "@mui/material";
import { PR_GetRoleListHandler } from "../../Services/Admin";
import { useLocation } from "react-router-dom";
import loading from "../../Assets/Loading_image.gif";

const ParentRolePopUp = () => {
  const [rows, setRows] = useState([]);
  const [selectId, setSelectId] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [id, setId] = useState("");
  const [roleName, setRoleName] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const channelName = searchParams.get("menuName");
  const menuId = searchParams.get("menuId");
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const pageSize = 10;
  const totalPages = Math.ceil(searchData.length / pageSize);

  useEffect(() => {
    setIsLoading(true);
    PR_GetRoleListHandler()
      .then((res) => {
        if (res.data && res.data.success && res.data.success.data) {
          setIsLoading(false);
          const data = res.data.success.data;
          setRows(data);
          setSearchData(data);
        } else {
          setIsLoading(false);
          alert("Error while fetching data: Unexpected response structure");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error while fetching:", err);
        alert(`Error while fetching: ${err.message || err}`);
      });
  }, []);

  const channelOne = new BroadcastChannel("menuFirst");
  const channelTwo = new BroadcastChannel("menuSecond");

  function submitHandler() {
    const channel = channelName === "menu1" ? channelOne : channelTwo;
    channel.postMessage({
      id: menuId,
      parentname: selectedNames.length > 0 ? selectedNames.toString() : "",
      parentId: selectId.length > 0 ? selectId.toString() : "",
    });
    channel.close();
    window.close();
  }

  const handleIdChange = (e) => setId(e?.target?.value);
  const handleRoleChange = (e) => setRoleName(e?.target?.value);

  const handleReset = () => {
    setId("");
    setRoleName("");
    setSearchData(rows);
    setMsg("");
  };

  const handleSearch = () => {
    setMsg("");
    let result = rows.filter((ele) => {
      const bool1 = id
        ? ele.id.toString().toLowerCase().includes(id.toString().toLowerCase())
        : true;
      const bool2 = roleName
        ? ele.rolename
            .toString()
            .toLowerCase()
            .includes(roleName.toString().toLowerCase())
        : true;
      return bool1 && bool2;
    });
    setSearchData(result);
    setCurrentPage(1); 
    if(result.length === 0) {
      setMsg("No Records found");
    }
  };

  function idPresent(id) {
    return selectId.indexOf(id);
  }

  function removeId(idx) {
    return selectId.filter((_, index) => index !== idx);
  }

  function removeNames(idx) {
    return selectedNames.filter((_, index) => index !== idx);
  }

  function selectHandler(id, name) {
    const index = idPresent(id);
    if (index !== -1) {
      setSelectId(removeId(index));
      setSelectedNames(removeNames(index));
    } else {
      setSelectId([...selectId, id]);
      setSelectedNames([...selectedNames, name.trim()]);
    }
  }

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const endPage = Math.min(startPage + 3, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span key={i}>
          <a
            className={`${modules["page-link"]} ${
              currentPage === i ? modules["active-select"] : ""
            }`}
            onClick={() => handleClick(i)}
          >
            {i}
          </a>
        </span>
      );
    }
    return pageNumbers;
  };

  const paginatedData = searchData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div className={modules["popup_container"]}>
        <Typography
          align="center"
          py={1}
          className={modules["headingFontBold"]}
        >
          Parent Role Permission
        </Typography>
        {msg ? (
          <Typography align="left" className={modules["error-msgBold"]}>
            {msg}
          </Typography>
        ) : (
          <>
        <table className={modules["popup_table"]}>
          <thead className={modules["popup_head"]}>
            <tr className="popup_table_head">
              <th>
                <input
                  type="checkbox"
                  className={modules["custom-checkbox"]}
                  align="left"
                />
                <br />
                <label htmlFor="vehicle1">Select <br/> All</label>
              </th>
              <th>Id</th>
              <th>RoleName</th>
            </tr>
          </thead>
          <tbody className={modules["popup_body"]}>
            {Array.isArray(paginatedData) &&
              paginatedData.map((row, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      onClick={() => selectHandler(row.id, row.rolename)}
                      className={modules["custom-checkbox"]}
                    />
                  </td>
                  <td>{row.id}</td>
                  <td>{row.rolename}</td>
                </tr>
              ))}
            <tr style={{ width: "100%" }}>
              <td colSpan="1" className={modules["whiteBackgroundPR"]}>
                <div>{renderPageNumbers()}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <Stack
          direction="row"
          spacing={2}
          sx={{ marginTop: "18px", marginBottom: "20px" }}
        >
          <button
            type="button"
            style={{ width: "70px", height: "25px", borderRadius: "4px" }}
            className={modules["btn_popupBtn"]}
            onClick={submitHandler}
          >
            Submit
          </button>
        </Stack>
        </>
        )}
        <Typography className={modules["headingFontBold"]}>Search:</Typography>
        <table
          cellSpacing="0"
          rules="all"
          border="1"
          className={modules["popup_table_search"]}
        >
          <tbody className={modules["popup_table_searchB"]}>
            <tr>
              <td>id</td>
              <td>
                <input value={id} onChange={handleIdChange} />
              </td>
            </tr>
            <tr>
              <td>RoleName</td>
              <td>
                <input value={roleName} onChange={handleRoleChange} />
              </td>
            </tr>
          </tbody>
        </table>
        <Stack
          direction="row"
          spacing={2}
          sx={{ marginTop: "18px", marginBottom: "20px" }}
        >
          <button
            type="button"
            style={{ width: "70px", height: "25px", borderRadius: "4px" }}
            className={modules["btn_popupBtn"]}
            onClick={handleSearch}
          >
            Search
          </button>
          <button
            type="button"
            style={{ width: "70px", height: "25px", borderRadius: "4px" }}
            className={modules["btn_popupBtn"]}
            onClick={handleReset}
          >
            Reset
          </button>
        </Stack>
      </div>
    </>
  );
};

export default ParentRolePopUp;
