import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import CRP1 from "../HelpPopups/images/CPR1.jpg";
import CRP2 from "../HelpPopups/images/CPR2.jpg";
import CRP3 from "../HelpPopups/images/CPR3.jpg";
import CRP4 from "../HelpPopups/images/CPR4.jpg";
import CRP5 from "../HelpPopups/images/CPR5.jpg";
import CRP6 from "../HelpPopups/images/CPR6.jpg";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const ClosedProjectReporthelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul style={{fontSize: "14px"}} >
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>
                Closed Project report
              </h3>
              <br />
              <li className="no-bullets">
                This report is report is showing Project wise information as
                Closed Projects for specific instance.
              </li>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={CRP1} alt="CRP1" />
              </Stack>
              <br />

              <li>
                This report will show Financial year wise , All project and Date
                range filter type data as per user selection. Report can be
                accessed only if the user is project manager.
              </li>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={CRP2} alt="CRP2" />
              </Stack>
              <br />

              <li className="no-bullets">Prerequisites for this report –</li>
              <br />

              <li className="no-bullets" style={{ marginLeft: "2rem" }}>
                I. User must be Project manager or having access of Project
                manager role
                <br />
                II. Report will show only those records where user is project
                manager.
                <br />
                III. If user want to see all records then he must be alternate
                project manager for the all projects.
              </li>
              <br />

              <li> <span style={{fontWeight: "bold"}}>Session Path</span>  - CCPM `{">>"}` Reports Close project Report</li>
              <br />

              <li> <span style={{fontWeight: "bold"}}>Report Path</span>  - This is front end report.</li>
              <br />

              <li>Select Instance for which you want to check the report.</li>

              <br />
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={CRP3} alt="CRP3" />
              </Stack>
              <br />

              <li>
                Once selected- instance, it will ask for Financial Year
                selection
                <li className="no-bullets">
                  Note:. Drop down menu is available for the same for Date range
                  / All / FY. Select the desired option.
                </li>
              </li>
              <br />
              <br />

              <li style={{ marginLeft: "2rem" }}>
                Date Filter Range - It will show the closed project details ,
                which are closed / completed under that date range for the
                instance..
              </li>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={CRP4} alt="CRP4" />
              </Stack>
              <br />
              <li style={{ marginLeft: "2rem" }}>
                Financial Year Wise Usage - It will show report Financial year
                wise report.
              </li>
              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={CRP5} alt="CRP5" />
              </Stack>
              <br />
              <li style={{ marginLeft: "2rem" }}>
                All - It will show report as on data for closed project.
              </li>

              <br />
              <br />
              <br />

              <span style={{ textDecoration: "underline" }}>
                Displayed Columns -
              </span>
              <br />

              <table border="1">
                <tr>
                  <td>Modif. No</td>
                </tr>
                <tr>
                  <td>Project Name</td>
                </tr>
                <tr>
                  <td>Project Manager</td>
                </tr>
                <tr>
                  <td>Project Status</td>
                </tr>
                <tr>
                  <td>Customer Name</td>
                </tr>
                <tr>
                  <td>Calendar</td>
                </tr>
                <tr>
                  <td>Erp Company</td>
                </tr>
                <tr>
                  <td>Erp Project No</td>
                </tr>
                <tr>
                  <td>Project Duration</td>
                </tr>
                <tr>
                  <td>% Buffer Consumed</td>
                </tr>
                <tr>
                  <td>% CC Completed</td>
                </tr>
                <tr>
                  <td>Scheduled Start Date</td>
                </tr>
                <tr>
                  <td>Scheduled End Date</td>
                </tr>
                <tr>
                  <td>Actual Start Date</td>
                </tr>
                <tr>
                  <td>Exp/Actual End Date</td>
                </tr>
                <tr>
                  <td>Customer Due Date</td>
                </tr>
                <tr>
                  <td>Completion Status</td>
                </tr>
                <tr>
                  <td>Delay(days)</td>
                </tr>
                <tr>
                  <td>Delay(%)</td>
                </tr>
                <tr>
                  <td>Lead Time(days)</td>
                </tr>
                <tr>
                  <td>Task Causing Maximum Delay</td>
                </tr>
                <tr>
                  <td>Delay Caused By Task(Days)</td>
                </tr>
                <tr>
                  <td>Last BM Run</td>
                </tr>
                <tr>
                  <td>Customer Feedback</td>
                </tr>
                <tr>
                  <td>Approved Execution Cost (In INR Lakhs)</td>
                </tr>
                <tr>
                  <td>Actual Execution Cost (In INR Lakhs)</td>
                </tr>
                <tr>
                  <td>Project value (In INR Lakhs)</td>
                </tr>
                <tr>
                  <td>View Tasks</td>
                </tr>
                <tr>
                  <td>View Billing Projection</td>
                </tr>
                <tr>
                  <td>Risk</td>
                </tr>
                <tr>
                  <td>Issues</td>
                </tr>
                <tr>
                  <td>View History</td>
                </tr>
                <tr>
                  <td>Fever Chart</td>
                </tr>
                <tr>
                  <td>Buffer Recovery</td>
                </tr>
                <tr>
                  <td>Project Properties</td>
                </tr>
                <tr>
                  <td>Pending Tasks On CC</td>
                </tr>
                <tr>
                  <td>Gantt Chart</td>
                </tr>
                <tr>
                  <td>Network Diagram</td>
                </tr>
                <tr>
                  <td>New Project Plan File</td>
                </tr>
                <tr>
                  <td>Old Project Plan File</td>
                </tr>
                <tr>
                  <td>Attachments</td>
                </tr>
                <tr>
                  <td>Instance</td>
                </tr>
                <tr>
                  <td>Project Creation Date</td>
                </tr>
                <tr>
                  <td>Project Last Updated On</td>
                </tr>
                <tr>
                  <td>Task Last Updated On</td>
                </tr>
              </table>

              <br />
              <br />



              <table border="1">
                <tr>
                  <td>Search</td>
                  <td style={{fontWeight: "bold"}}>
                    For searching the project w.r.t. Report Filters selected
                  </td>
                </tr>
                <tr>
                  <td>Reset</td>
                  <td style={{fontWeight: "bold"}}>For resetting to defaults</td>
                </tr>
                <tr>
                  <td>Export to Excel</td>
                  <td style={{fontWeight: "bold"}}>Excel report download for the displayed data</td>
                </tr>
              </table>

                <br />
                <br />
                <br />

              <li>
                EXPORT TO EXCEL - Feature to download in excel - the closed
                project data
              </li>

              <br />
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={CRP6} alt="CRP6" />
              </Stack>
              <br />

              <span style={{ textDecoration: "underline" }}>
                Excel Report Columns -
              </span>

              <br />


              <table border="1">
                <tr>
                  <td>Modif. No</td>
                </tr>
                <tr>
                  <td>Project Name</td>
                </tr>
                <tr>
                  <td>Project Manager</td>
                </tr>
                <tr>
                  <td>Project Status</td>
                </tr>
                <tr>
                  <td>Customer Name</td>
                </tr>
                <tr>
                  <td>Calendar</td>
                </tr>
                <tr>
                  <td>Erp Company</td>
                </tr>
                <tr>
                  <td>Erp Project No</td>
                </tr>
                <tr>
                  <td>Project Duration</td>
                </tr>
                <tr>
                  <td>% Buffer Consumed</td>
                </tr>
                <tr>
                  <td>% CC Completed</td>
                </tr>
                <tr>
                  <td>Scheduled Start Date</td>
                </tr>
                <tr>
                  <td>Scheduled End Date</td>
                </tr>
                <tr>
                  <td>Actual Start Date</td>
                </tr>
                <tr>
                  <td>Exp/Actual End Date</td>
                </tr>
                <tr>
                  <td>Customer Due Date</td>
                </tr>
                <tr>
                  <td>Completion Status</td>
                </tr>
                <tr>
                  <td>Delay(days)</td>
                </tr>
                <tr>
                  <td>Delay(%)</td>
                </tr>
                <tr>
                  <td>Lead Time(days)</td>
                </tr>
                <tr>
                  <td>Task Causing Maximum Delay</td>
                </tr>
                <tr>
                  <td>Delay Caused By Task(Days)</td>
                </tr>
                <tr>
                  <td>Last BM Run</td>
                </tr>
                <tr>
                  <td>Customer Feedback</td>
                </tr>
                <tr>
                  <td>Approved Execution Cost (In INR Lakhs)</td>
                </tr>
                <tr>
                  <td>Actual Execution Cost (In INR Lakhs)</td>
                </tr>
                <tr>
                  <td>Project value (In INR Lakhs)</td>
                </tr>
                <tr>
                  <td>Instance</td>
                </tr>
                <tr>
                  <td>Project Details</td>
                </tr>
                <tr>
                  <td>Project Creation Date</td>
                </tr>
                <tr>
                  <td>Project Last Updated On</td>
                </tr>
                <tr>
                  <td>Task Last Updated On</td>
                </tr>
              </table>

              <br />
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClosedProjectReporthelpPopup;
