import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import { Grid, FormControl } from "@mui/material";
import bread from "../../../Assets/bread.png";
import {
  Get_MtaStockPoint_Type,
  MtaUpdate_StockPoint,
  Get_MtaInstances,
} from "../../../Services/MTA";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function StockPointType() {
  const [selectedInstance, setSelectedInstance] = useState("");
  const [selectedInstanceId, setSelectedInstanceId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [addClicked, setAddClicked] = useState(false);
  const [changed, setChanged] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [newInstanceName, setNewInstanceName] = useState("");
  const [newInstanceCode, setNewInstanceCode] = useState("");
  const [editedInstanceName, setEditedInstanceName] = useState("");
  const [editedSPdesc, setEditedSPdesc] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [editing, setEditing] = useState(null);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getInstances();
  }, []);

  const getInstances = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      miuid_instance_uid: "",
      miuid_last_modified_by: sessionStorage.getItem("loginUsername").toLowerCase(),
    };
    if (loginId) {
      Get_MtaInstances(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setDropdownData(response?.data?.success?.data);
            setIsLoading(false);
            // console.log(response?.data?.success?.data.length, "length");

            if (response?.data?.success?.data.length > 0) {
              const defaultInstanceId =
                response?.data?.success?.data[0]?.mta_instance_uid;
              // console.log(response?.data?.success?.data.length, "fcewf");
              setSelectedInstanceId(defaultInstanceId);
              getTableData(defaultInstanceId);
            } else if (response?.data?.success?.data.length === 0) {
              alert("You do not have access to any Active Instance in MTA");
              // console.log(response?.data?.success?.data.length, "WWWW");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const getTableData = (searchValue) => {
    const body = {
      miuid_instance_uid: searchValue,
      userid: sessionStorage.getItem("loginUsername").toLowerCase(),
    };
    Get_MtaStockPoint_Type(body)
      .then((response) => {
        setFilteredData(response?.data?.success?.data);
        console.log(response?.data?.success?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleEditSingleEntry(code, description, element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(code);
      setEditedSPdesc(description);
      element.style.color = "#0000EE";
    }, 100);
  }

  function handleSaveEdit(code, element) {
    element.style.color = "red";
    console.log(changed, "changed");

    setTimeout(() => {
      element.style.color = "#0000EE";
      setTimeout(() => {
        if (changed === false) {
          alert("No change to Update.");
        } else if (changed === true) {
          let lastModifiedBy = sessionStorage.getItem("loginUsername").toLowerCase();
          const body = {
            spt_instance_uid: selectedInstanceId,
            stockPointCode: code,
            stockPointDesc: editedSPdesc.trim(),
            spt_last_modified_by: lastModifiedBy,
          };
          if (changed) {
            MtaUpdate_StockPoint(body)
              .then((response) => {
                console.log(response?.data?.sucess, "rr");

                if (response?.data?.sucess === true) {
                  alert("Details Updated successfully");
                  setEditing(null);
                  // setDeleted(!deleted);
                  getTableData(selectedInstanceId);
                  setChanged(false);
                } else if (response?.response?.sucess === false) {
                  alert(
                    response?.response?.data?.error?.message ||
                      " Please try again."
                  );
                  setChanged(false);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      }, 50);
    }, 100);
  }

  function handleCancelEdit(element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(null);
      element.style.color = "#0000EE";
    }, 50);
  }

  function handleNavigate(Id) {
    // window.location.href = "/admin/RolePermission";
    // window.sessionStorage.setItem("roleid", Id);
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"StockPointType"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>Stock Point Type</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table
                className={modules["customTable"]}
                style={{ marginLeft: "3rem" }}
              >
                <tbody>
                  <tr style={{ paddingLeft: "5rem" }}>
                    <td style={{ width: "9rem", fontWeight: "bold" }}>
                      MTA Instance
                    </td>
                    <td>:</td>
                    <FormControl sx={{ marginLeft: "20px" }}>
                      <select
                        value={selectedInstanceId}
                        onChange={(e) => {
                          const selectedId =
                            e.target.options[
                              e.target.selectedIndex
                            ].getAttribute("data-id");
                          setSelectedInstanceId(e.target.value);
                          setSelectedInstance(selectedId);
                          getTableData(e.target.value);
                        }}
                        className={modules["d_InstanceSelect"]}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                        disabled={editing} // Add this line to conditionally disable the dropdown
                      >
                        {/* <option value={"SELECT"}>SELECT</option> */}
                        {dropdownData.map((i) => {
                          return (
                            <option
                              className={
                                modules["instanceAttMap_dropdownSelect_menu"]
                              }
                              value={i.mta_instance_uid}
                              key={i.id}
                              data-id={i.id}
                            >
                              {i.mta_instance_uid}
                            </option>
                          );
                        })}
                      </select>
                    </FormControl>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid container className={modules["mid-cont"]}>
              {!isLoading &&
                (filteredData && filteredData.length > 0 ? (
                  <Table
                    className={modules["custom_table"]}
                    style={{ marginLeft: "3rem", width: "60%" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Sr. No.</TableCell>
                        <TableCell align="left">Stock Point Type</TableCell>
                        <TableCell align="left">
                          Stock Point Type Description
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.map((item, index) => (
                        <TableRow key={index} className={modules["table-row"]}>
                          <TableCell
                            className={modules["table-cell"]}
                            align="left"
                          >
                            <span
                              style={{
                                color: "#414141",
                              }}
                            >
                              {index + 1}
                            </span>
                          </TableCell>
                          <TableCell
                            className={modules["table-cell"]}
                            align="left"
                          >
                            <div>
                              <span
                                style={{
                                  cursor: "text",
                                  color: "#414141",
                                }}
                                onClick={() => handleNavigate(item.level2id)}
                              >
                                {item.stockpointcode}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {editing === item.stockpointcode ? (
                              <input
                                type="text"
                                value={editedSPdesc}
                                className={modules["customBox_input"]}
                                style={{ paddingLeft: "5px" }}
                                maxLength={50}
                                onChange={(e) => {
                                  const allowedChars =
                                    /^[a-zA-Z0-9 &()\-_=\+\[\]\.\/]*$/;
                                  const inputValue = e.target.value;

                                  if (allowedChars.test(inputValue)) {
                                    setChanged(false);
                                    const newValue = inputValue
                                      .toLowerCase()
                                      .trim();
                                    setEditedSPdesc(inputValue);
                                    if (
                                      newValue !==
                                      item.stockpointdesc.toLowerCase().trim()
                                    ) {
                                      setChanged(true);
                                    }
                                  }
                                }}
                                onBlur={(e) => {
                                  const alphanumericRegex =
                                    /^[a-zA-Z0-9 &()_-]*$/;
                                  if (!e.target.value) {
                                    // setErrorMessage3("*Enter Value");
                                  } else if (
                                    !alphanumericRegex.test(e.target.value)
                                  ) {
                                    alert("No special characters Allowed.");
                                    setEditedSPdesc("");
                                  }
                                }}
                              />
                            ) : (
                              <div>
                                <span
                                  style={{ cursor: "text", color: "#414141" }}
                                  onClick={() => handleNavigate(item.level2id)}
                                >
                                  {item.stockpointdesc}
                                </span>
                              </div>
                            )}
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {editing === item.stockpointcode ? (
                              <>
                                <a
                                  style={{
                                    paddingRight: "1rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) =>
                                    handleSaveEdit(
                                      item.stockpointcode,
                                      e.currentTarget
                                    )
                                  }
                                >
                                  Update
                                </a>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    handleCancelEdit(e.currentTarget)
                                  }
                                >
                                  Cancel
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  className={modules["actionCol"]}
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    handleEditSingleEntry(
                                      item.stockpointcode,
                                      item.stockpointdesc,
                                      e.currentTarget
                                    )
                                  }
                                >
                                  Edit
                                </a>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <>
                    <p style={{ marginLeft: "6rem", fontSize: "12px" }}>
                      No Data Found
                    </p>
                  </>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
