import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import create1 from './images/create1.png'
import create2 from './images/create2.png'
import create3 from './images/create3.png'
import create4 from './images/create4.png'
import create5 from './images/create5.png'
import create6 from './images/create6.png'
import create7 from './images/create7png.png'
import create8 from './images/create8.png'
import create9 from './images/create9.png'
import { Link } from "react-router-dom";

const CreateNewhelpPopup = () => {
    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            Expand it...
                            <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>
            </div>

            <Grid sx={{ fontFamily: '"Calibri", sans-serif', fontSize: '14.6px', color: '#000000' }}>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul>
                            <h3 style={{textDecoration: "underline", fontWeight: "bold" }}>Create New Plan</h3>
                            <br />
                            ⚫  As the name depicts, “create new plan” is a page where new project is created using the 4 ways mentioned below<br />
                            ❖  Create an empty project<br />
                            ❖  Create a new project from template<br />
                            ❖  Create a new project from file<br />
                            ❖  Create a new project ERP LN.<br />
                            <br />
                            <br />
                            ⚫  PATH : CCPM \ Project Plan \ Create New plan<br /><br />
                            ⚫  Each method has its way of creating the new project, following is the description of the each method mentioned.<br /><br />
                            ❖  <span style={{ textDecoration: 'underline' }}>Create an Empty Project:-</span><br />
                            ⚫  The name of method “create an empty project” clearly explains that using this user can create an plain empty project and once the project has been created then the user can add tasks,sub-task, etc.<br />
                            ⚫  For creating the empty project, go to project plan \ create new project page and enter or select the details in the all empty and mandatory fields and click on the Submit button.<br />
                            ⚫  The created project can be viewed in the “My Project” screen.<br /><br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={create1} alt="myproject1" />
                            </Stack>
                            <br /><br /><br />
                            ❖  <span style={{ textDecoration: 'underline' }}>Create a New Project from Template:-</span><br />
                            ⚫  Using this method , we can create project using the template i.e. Project will be created with pre-loaded tasks in it using the selected template.<br /><br />
                            ⚫  Template for any project can be created using the following steps:-<br />
                            <ol style={{ marginLeft: '1rem' }}>
                                <li>
                                    Create a project
                                </li>
                                <li>
                                    Create the network plan
                                </li>
                                <li>
                                    Save the network plan
                                </li>
                                <li>
                                    Submit the network plan
                                    <div>Once the plan is submitted, link “Save as template” in the “Project Tasks” screen.</div>
                                    <Stack sx={{ paddingRight: "1rem" }}>
                                        <img src={create2} alt="myproject2" />
                                    </Stack>
                                </li>
                                <li>Click on the “Save as template” link, the network plan of the project will be saved as template.</li>
                            </ol>
                            <br /><br />
                            ⚫  When creating project using the template, enter the required details and select the template from the field “Select Template” and click on “Submit” button
                            <br /><br />
                            ⚫  Project will get created with the selected template tasks which can be editable.
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={create3} alt="myproject2" />
                            </Stack>
                            <br /><br />
                            <div style={{ textDecoration: 'underline' }}>
                                ❖ <span style={{ textDecoration: 'underline' }}>Create a New Project from File:-</span>
                            </div><br />
                            ⚫  Using this method, we can create the project using the Excel file i.e. Project will be created with the pre-loaded tasks using the Excel file.
                            <br />
                            <div style={{ textDecoration: 'underline' }}>
                                <span style={{ fontWeight: 'bold' }}>Note:</span> All * marked fields are mandatory to be filled for creating project initials.
                            </div>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={create4} alt="myproject2" />
                            </Stack>
                            <br /><br />
                            <li style={{ listStyleType: 'none' }}>
                                A parameterized new option on  ‘Project Plan’- session --&gt;
                                <br />
                                Path: - Create New Plan --&gt; Create New Project from File--&gt; Click here to download Excel format
                                <br />
                                Drop down Name – <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>‘ Input format file’</span>
                                <br />
                                <ul style={{ marginLeft: '4rem' }}>
                                    <li style={{ listStyleType: 'none' }}>
                                        Option 1 – Existing Project Task format.
                                    </li>
                                    <li>
                                        Option 2 – New Single sheet format.
                                    </li>
                                </ul>
                            </li>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                                Option 1 and 2 can be used download blank template and upload the same with data for creating the project.
                            </li>
                            <li style={{ listStyleType: 'none' }}>
                                <span style={{ textDecoration: 'underline' }}>Option 1 logic :- </span> If user selects ‘Existing Project Task format;- Existing project plan can be downloaded and uploaded once filled data,  as applicable. In this case ‘Department’ column values can be blank. User can maintain the department from My Projects – Project Task Page
                            </li>
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={create5} alt="myproject2" />
                            </Stack>
                            <br />
                            <li style={{ listStyleType: 'none' }}>
                                <span style={{ textDecoration: 'underline' }}>Option 2 logic :- </span>  If user selects ‘ New Single sheet Excel format’, New Excel  format can be downloaded and uploaded once filled data,  as applicable. Upload result file – Tab will be visible post upload. Download the result file from there, if any error, file will show the validation errors  generated on local machine once import to excel is executed .
                            </li>
                            <br /><br /><br />
                            <li style={{ listStyleType: 'none' }}>
                                ⚫   Steps for File  upload:-
                                <br /><br />
                                <ol>
                                    <li>
                                        Select Drop down Name –‘ <span style={{ textDecoration: 'underline' }}>Input format file’</span><br />
                                        Click on the button “Click here to download Excel Format” and an excel file containing the network plan format will get downloaded
                                    </li>
                                    <li>
                                        Open the downloaded excel file and enter the field related data and save the file.
                                    </li>
                                </ol>
                                <br /><br />
                            </li>
                            <li style={{ listStyleType: 'none' }}>
                                ⚫  After the file is created successfully, click on the “Choose file” button and select the file to upload.
                            </li>
                            <br /><br />
                            <li style={{ listStyleType: 'none' }}>
                                ⚫  Click on button “Import from Excel” which will display the task data as per uploaded excel sheet
                            </li>
                            <br /><br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={create6} alt="myproject2" />
                            </Stack>
                            <br /><br />
                            <li style={{ listStyleType: 'none' }}>
                                ⚫  Enter the remaining required details in the input fields and click on the Submit button, project with the tasks uploaded in the excel file will get created.
                            </li>
                            <br /><br />
                            <li style={{ listStyleType: 'none' }}>
                                Download the result file from there, if any error, file will show the validation errors  generated on local machine once import to excel is executed .
                            </li>
                            <div style={{ textDecoration: 'underline' }}>
                                ❖ <span style={{ textDecoration: 'underline' }}>Validations present on the screen:-</span>
                            </div><br /><br/>
                            <li style={{ listStyleType: 'none' }}>
                                ⚫  Projects with same name cant be created i.e. Re-adding of the existing project name
                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={create7} alt="myproject2" />
                            </Stack>
                            <br /><br />
                            <li style={{ listStyleType: 'none' }}>
                                ⚫  Customer Due Date and Project Due Date cannot be less than or equal to the project start date.
                            </li>
                            <br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={create8} alt="myproject2" />
                            </Stack>
                            <br /><br />
                            <li style={{ listStyleType: 'none' }}>
                                ⚫  Data in all the mandatory fields should be enter before hitting the Submit button to avoid the popup window to appear.
                            </li>
                            <br /><br/>
                            <li style={{ listStyleType: 'none' }}>
                                ⚫  Valid excel file(downloaded excel file) should be upload, if we are creating the project using the “create a new project from the project file” to avoid the error pop up window
                            </li>
                            <br /><br/>
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={create9} alt="myproject2" />
                            </Stack>
                            <br /><br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default CreateNewhelpPopup;
