import React, { useState, useEffect } from "react";
import {
  Grid,
  SvgIcon,
  Stack,
  Checkbox,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import modules from "../project.module.css";
import Home from "../../../../home/Home";
import bread from "../../../../Assets/bread.png";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ST_FetchProjStatus,
  ST_FetchERPDetails,
  ST_FetchUnitNames,
  AET_CheckSubType,
  ST_GetCategory,
  ST_GetTableData,
  ST_GetListTableData,
  ST_ListUpdate,
  ST_ListDelete,
  ST_AutoUpdCheck,
  ST_CommonSubmitBtn,
  ST_GetVolData,
  ST_StartClose,
  ST_StartTask,
  ST_AutoSave,
  ST_AutoStartTask,
  ST_AutoDeleteTask,
  ST_VolTaskAdd,
  ST_VolStartTask,
  ST_VolCloseTask,
  ST_VolTaskDelete,
  ST_VolSaveTask,
  ST_ProTaskClose,
  ST_ProStartTask,
  ST_ProSaveTask,
  ST_ProTaskDelete,
  ST_ProSaveAllTasks,
  ST_ProSaveChangedCategory,
  InsertListType,
} from "../../../../Services/CCPM";
import add from "../../../../Assets/add.png";
import Delete from "../../../../Assets/Delete.png";
import Save from "../../../../Assets/save.png";
import { ST_TaskClose } from "../../../../Constants";
import loading from "../../../../Assets/Loading_image.gif";

const SubTasksAll = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [mainPanelEn, setMainPanelEn] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [subTypeTask, setSubTypeTask] = useState(0);
  const [error, setError] = useState("");
  const [rdSelectAllDis, setRdSelectAllDis] = useState(false);
  const [projectStatus, setProjectStatus] = useState(null);
  const [isErpLN, setIsErpLN] = useState(false);
  const [erpProjectNo, setErpProjectNo] = useState("");
  const [unitNames, setUnitNames] = useState([]);
  const statusIdFromQuery = searchParams.get("statusid");
  const subTaskTypeFromQuery = searchParams.get("SubTaskType");
  const projectId = searchParams.get("ProjectId");
  const taskId = searchParams.get("TaskId");
  const [isRadioDisabled, setIsRadioDisabled] = useState(false); // New state to handle disabling

  const [mainTableData, setMainTableData] = useState([]);

  //list type
  const [listView, setListView] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [gridDataList, setGridDataList] = useState([]);
  const [editingList, setEditingList] = useState(null);
  const [updatedSubName, setUpdatedSubName] = useState("");
  const [selectAllCheckedL, setSelectAllCheckedL] = useState(false);
  const [checkedRowsL, setCheckedRowsL] = useState({});
  const [addListTask, setAddListTask] = useState(false);
  const [insertedSubName, setInsertedSubName] = useState("");
  const [isStartChecked, setIsStartChecked] = useState(false);
  const [isStartDisabled, setIsStartDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displaySequenceList, setDisplaySequenceList] = useState("");
  const [isCloseChecked, setIsCloseChecked] = useState(false);
  const [isCloseDisabled, setIsCloseDisabled] = useState(false);

  //procurement type
  const [dynamicHeaders, setDynamicHeaders] = useState([]);
  const [procView, setProcView] = useState(false);
  const [selectAllCheckedProc, setSelectAllCheckedProc] = useState(false);
  const [checkedRowsProc, setCheckedRowsProc] = useState({});
  const [category, setCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showImageButtons, setShowImageButtons] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showSubTaskPanel, setShowSubTaskPanel] = useState(true);
  const [isStartCheckedPro, setIsStartCheckedPro] = useState(false);
  const [isStartDisabledPro, setIsStartDisabledPro] = useState(false);
  const [isCloseCheckedPro, setIsCloseCheckedPro] = useState(false);
  const [isCloseDisabledPro, setIsCloseDisabledPro] = useState(false);
  const [procSubtaskName, setProcSubtaskName] = useState("");
  const [subTaskduration, setSubTaskduration] = useState("");
  const [categories, setCategories] = useState([]);
  const [dataProc, setDataProc] = useState([]);

  //auto update
  const [dataAuto, setDataAuto] = useState([]);
  const [autoUpdateView, setAutoUpdateView] = useState(false);
  const [deleteChkAuto, setDeleteChkAuto] = useState(false);
  const [autoupdateStopFrequency, setAutoupdateStopFrequency] = useState("");
  const [isStartCheckedAuto, setIsStartCheckedAuto] = useState(false);
  const [isStartDisabledAuto, setIsStartDisabledAuto] = useState(false);
  const [autoUpdateStopIn, setAutoUpdateStopIn] = useState("");
  const [remainingDays, setRemainingDays] = useState("");
  const [showManagementPanel, setShowManagementPanel] = useState(true);

  //vol type
  const [volView, setVolView] = useState(false);
  const [selectAllCheckedV, setSelectAllCheckedV] = useState(false);
  const [checkedRowsV, setCheckedRowsV] = useState({});
  const [isStartCheckedVol, setIsStartCheckedVol] = useState(false);
  const [isStartDisabledVol, setIsStartDisabledVol] = useState(false);
  const [isCloseCheckedVol, setIsCloseCheckedVol] = useState(false);
  const [isCloseDisabledVol, setIsCloseDisabledVol] = useState(false);
  const [volSubtaskName, setVolSubtaskName] = useState("");
  const [totalWork, setTotalWork] = useState("");
  const [workUnit, setWorkUnit] = useState("");
  const [duration, setDuration] = useState("");
  const [displaySequence, setDisplaySequence] = useState(1);
  const [dataVol, setDataVol] = useState([]);

  const [projStatus, setProjStatus] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading

      await handleSecondaryData(projectId); // Wait for this to finish
      await handleProjectData(projectId); // Then call this

      setIsLoading(false); // Stop loading
    };

    fetchData();
  }, [projectId]);

  useEffect(() => {
    const allChecked =
      gridDataList?.length > 0 &&
      gridDataList?.every((item) => checkedRowsL[item.subtaskid]);
    setSelectAllCheckedL(allChecked);
  }, [checkedRowsL, gridDataList]);

  useEffect(() => {
    if (dataVol?.length > 0) {
      setDisplaySequence(dataVol?.length + 1);
    }
  }, [dataVol]);

  useEffect(() => {
    if (dataProc?.length > 0) {
      setDisplaySequence(dataProc?.length + 1);
    }
  }, [dataProc]);

  const handleSecondaryData = async (projectId) => {
    try {
      const status = await ST_FetchProjStatus({ projectId });
      if (status?.data?.success?.data?.length > 0) {
        const returnstatus = status.data.success.data[0].projectstatusid;
        setProjStatus(returnstatus); // Set projStatus correctly
        console.log("projStatus:", returnstatus);
      } else {
        console.log("status Unknown Status");
        setProjStatus(null); // Handle unknown status
      }

      const units = await ST_FetchUnitNames();
      setUnitNames(units?.data?.success?.data || []);

      const ERPResponse = await ST_FetchERPDetails({ projectId });
      const data3 = ERPResponse?.data?.success?.data || {};
      setIsErpLN(data3.flag === 1);
      setErpProjectNo(data3.erpProjectNo || "");
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  const statusMapping = {
    1: "Created",
    2: "Submitted",
    3: "Planned",
    4: "Active",
    5: "Hold",
    6: "CheckOut",
    7: "Completed",
    9: "Scheduled",
    10: "CCDCheckedOut",
    11: "ModifiedCheckedOut",
  };

  const getStatusName = (statusNumber) => {
    return statusMapping[statusNumber] || "Unknown Status";
  };

  const handleProjectData = async (projectId) => {
    if (subTaskTypeFromQuery) {
      setSelectedValue(subTaskTypeFromQuery);
      // btnSubmit_Click();
    }
    setIsLoading(true);
    try {
      const SubTaskTypeExist = await AET_CheckSubType({ taskId, projectId });
      const data2 = SubTaskTypeExist?.data?.success?.data[0]?.var_result;
      const intData2 = parseInt(data2);
      if (intData2 !== -1 && intData2 !== 0) {
        setSelectedValue(intData2);
        setIsRadioDisabled(true);
        setSubTypeTask(data2);
        btnSubmit_Click(intData2, data2);
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMainTableData = async () => {
    setIsLoading(true);
    try {
      const tableData = await ST_GetTableData({ projectId, taskId });
      setMainTableData(tableData?.data?.success?.data[0]);
    } catch (error) {
      console.error("Error fetching table data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const btnSubmit_Click = (selectVal, subTypeId) => {
    let dynamicHeaders = [];

    if (selectVal !== null) {
      if (parseInt(subTypeId) === selectVal || subTypeId === 0) {
        setIsLoading(true);
        let body = {
          projectId: parseInt(projectId),
          taskId: parseInt(taskId),
          radioButton: selectVal,
        };

        ST_CommonSubmitBtn(body)
          .then((response) => {
            if (response.status === 200) {
              const resData = response.data;
              fetchMainTableData();

              setIsRadioDisabled(true);
              if (selectVal === 1) {
                fetchListTableData();
                setMainPanelEn(true);
                setListView(true);
                setProcView(false);
                setAutoUpdateView(false);
                setVolView(false);
              } else if (selectVal === 2) {
                setDataProc(resData.success.data.ProcurementList);
                setCategories(resData.success.data.Category);
                const procurementList = resData.success.data.ProcurementList;
                const dynamicKeys = new Set(); // Use a Set to avoid duplicates
                procurementList.forEach((item) => {
                  Object.keys(item).forEach((key) => {
                    if (
                      ![
                        "id",
                        "subtaskname",
                        "duration",
                        "remaining_duration",
                      ].includes(key)
                    ) {
                      dynamicKeys.add(key);
                    }
                  });
                });

                setDynamicHeaders(Array.from(dynamicKeys)); // Convert Set back to Array

                const procurementCatId =
                  resData.success.data.ProcurementCatId[0];
                if (procurementCatId) {
                  // Set the selected category to the procurementcatid
                  setSelectedCategory(procurementCatId.procurementcatid);
                }
                handleCatFetch();
                setMainPanelEn(true);
                setListView(false);
                setProcView(true);
                setAutoUpdateView(false);
                setVolView(false);
              } else if (selectVal === 3) {
                const autoUpdateSubtask =
                  resData.success.data.AutoupdateTypeSubtask;

                if (autoUpdateSubtask && autoUpdateSubtask.length > 0) {
                  const autoUpdateSubtask =
                    resData.success.data.AutoupdateTypeSubtask;

                  if (autoUpdateSubtask && autoUpdateSubtask.length > 0) {
                    const dataAuto = autoUpdateSubtask[0];

                    // Update frequency if it exists
                    if (dataAuto.autoupdatestopfrequencydays !== null) {
                      setAutoupdateStopFrequency(
                        String(dataAuto.autoupdatestopfrequencydays)
                      );
                      setDeleteChkAuto(true);
                    } else {
                      // Reset state if frequency days is null
                      setAutoupdateStopFrequency("");
                      setDeleteChkAuto(false);
                    }
                  } else {
                    // Reset state when AutoupdateTypeSubtask is absent
                    setAutoupdateStopFrequency("");
                    setDeleteChkAuto(false);
                  }
                }
                AutoCheckUpdate();
                setMainPanelEn(true);
                setListView(false);
                setProcView(false);
                setAutoUpdateView(true);
                setVolView(false);
              } else if (selectVal === 4) {
                fetchVolTableData();
                setDataVol(resData.success.data.VolumetricSubtask);
                setMainPanelEn(true);
                setListView(false);
                setProcView(false);
                setAutoUpdateView(false);
                setVolView(true);
              }
            }
          })
          .catch((error) => {
            console.error("Error submitting:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });

        // Handle visibility and state updates based on selected value
      } else {
        // Handle different cases
        if (subTypeId === 1) {
          alert("This Task Already Mapped with List Type");
        } else if (subTypeId === 2 || subTypeId === "C") {
          alert("This Task Already Mapped with Procurement Type");
        } else if (subTypeId === 3) {
          alert("This Task Already Mapped with Auto Update Type");
        } else {
          alert("This Task Already Mapped with Volumetric Type");
        }
      }
    } else {
      setError("Please select Type");
    }
  };

  const handleRadioChange = (event) => {
    setSelectedValue(Number(event.target.value));
    setIsRadioDisabled(false);
  };

  const disableComponents = () => {
    // Logic to disable components based on project status
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short" };
    const date = new Date(dateString?.replace(/\//g, "-"));
    const formattedDate = date.toLocaleDateString("en-GB", options);
    const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
    return `${formattedDate.replace(" ", "-")}-${year}`;
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  // List Type
  const [gridData, setGridData] = useState([]);

  const fetchListTableData = async () => {
    setIsLoading(true);
    try {
      const tableData = await ST_GetListTableData({ projectId, taskId });
      if (tableData?.data?.success?.data === null) {
        const dummyData = [{ subtaskname: "Default SubTask" }];
        setGridDataList(dummyData);
      } else {
        setGridDataList(tableData?.data?.success?.data);
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditL = (item) => {
    setAddListTask(false);
    setEditingList(item.subtaskid);
    setUpdatedSubName(item.subtaskname);
  };

  const cancelEditL = () => {
    setEditingList(null);
  };

  const handleCancelAddList = () => {
    setAddListTask(false);
    setInsertedSubName("");
  };

  const handleSelectAllL = (event) => {
    const isChecked = event.target.checked;
    setSelectAllCheckedL(isChecked);
    const newCheckedItems = {};
    gridDataList?.forEach((item) => {
      newCheckedItems[item.subtaskid] = isChecked;
    });
    setCheckedRowsL(newCheckedItems);
  };

  const handleCheckboxChangeL = (event, id) => {
    setCheckedRowsL((prev) => ({
      ...prev,
      [id]: event.target.checked,
    }));
  };

  const handleDeleteClick = () => {
    const selectedIds = Object.keys(checkedRowsL).filter(
      (key) => checkedRowsL[key]
    );
    if (selectedIds.length === 0) {
      alert("Please select items to delete");
      return;
    }
    let confirmDelete = window.confirm("Are you sure you want to delete?");
    if (!confirmDelete) {
      return;
    }
    setIsLoading(true);
    const body = selectedIds.map((item) => ({
      projectsId: projectId,
      taskId: taskId,
      subTaskId: item,
      userId: sessionStorage.getItem("loginId"),
    }));
    ST_ListDelete(body)
      .then((response) => {
        if (response.status === 200) {
          alert("Items deleted successfully");
          fetchListTableData();
        }
      })
      .catch((error) => {
        console.log("Error deleting items:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateClick = (item) => {
    if (updatedSubName.trim() === item.subtaskname.trim()) {
      alert("No changes made");
      return;
    }
    setIsLoading(true);
    let body = {
      projectsId: projectId,
      taskId: taskId,
      subTaskId: item.subtaskid,
      subTaskName: updatedSubName,
      parentSId: item.parentsid,
      completed: item.completed,
      uId: sessionStorage.getItem("loginId"),
      purchaseOrderNo: 0,
      duration: item.duration,
      remDuration: item.remduration,
      maxDuration: 0,
      maxRemDuration: 0,
    };
    ST_ListUpdate(body)
      .then((response) => {
        if (response.status === 200) {
          alert("List updated successfully");
          setEditingList(null);
          fetchListTableData();
        }
      })
      .catch((error) => {
        console.log("Error updating list:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUploadClick = () => setVisibleUpload(true);
  const handleCloseUpload = () => setVisibleUpload(false);

  const handleCheckboxChangeForStart = (event) => {
    const checked = event.target.checked;

    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to change the status?"
    );

    if (isConfirmed) {
      setIsStartChecked(checked);

      if (checked) {
        setIsLoading(true);
        ST_StartTask({ projectId, taskId })
          .then((response) => {
            if (response.status === 200) {
              alert(response.data.message);
              setIsStartDisabled(true);
            }
          })
          .catch((error) => {
            console.error("Error starting task:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      // If the user cancels, reset the checkbox to its previous state
      event.target.checked = !checked;
    }
  };

  const handleCheckboxChangeForClosed = (event) => {
    const checked = event.target.checked;

    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to close the Task?"
    );

    if (isConfirmed) {
      setIsCloseChecked(checked);

      if (checked) {
        setIsLoading(true);
        ST_StartClose({ projectId, taskId })
          .then((response) => {
            if (response.status === 200) {
              if (response?.data?.success === null) {
                alert("Please complete Sub Task to Close the Task.");
                setIsCloseChecked(false);
              } else {
                alert(response?.data?.success?.message);
                setIsCloseChecked(false);
                setIsCloseDisabled(true); // Disable the checkbox if success
              }
            } else if (response?.response?.status === 400) {
              alert("Please complete Sub Task to Close the Task.");
              setIsCloseChecked(false);
            } else {
              alert("Please complete Sub Task to Close the Task.");
              setIsCloseChecked(false);
            }
          })
          .catch((error) => {
            if (error.response) {
              // Check if the status code is 400
              if (error.response.status === 400) {
                alert("Please complete Sub Task to Close the Task.");
                setIsCloseChecked(false);
              } else {
                // For other error statuses, log and show a general message
                console.error("Error response:", error.response);
                alert(`Error: ${error.response.status}`);
                setIsCloseChecked(false);
              }
            } else if (error.request) {
              // No response was received from the server
              console.error("No response received:", error.request);
              setIsCloseChecked(false);
              alert(
                "No response received from the server. Please try again later."
              );
            } else {
              // Error in setting up the request
              console.error("Error:", error.message);
              alert("An error occurred: " + error.message);
              setIsCloseChecked(false);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      // If the user cancels, reset the checkbox to its previous state
      event.target.checked = !checked;
    }
  };

  const handleAddNewList = () => {
    setIsLoading(true);
    const body = {
      projectsId: projectId,
      taskId: taskId,
      subTaskName: insertedSubName,
      uId: sessionStorage.getItem("loginId"),
      displaySeq: displaySequenceList || 0,
    };

    InsertListType(body).then((response) => {
      setIsLoading(true);
      if (response.status === 200) {
        alert("Added Successfully");
        fetchListTableData();
        setIsLoading(false);
        setInsertedSubName("");
        setDisplaySequenceList("");
        setAddListTask(false);
      }
    });
  };

  const handleDisplaySequenceList = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "");
    setDisplaySequenceList(newValue); // Update state with the filtered value
  };

  // Procurement Type

  // Handle category change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSaveCategory = () => {
    if (!category && (selectedCategory === "-1" || selectedCategory === "")) {
      alert("Select Category to Update");
      return;
    }
    setIsLoading(true);
    const body = {
      projectId: projectId,
      taskId: taskId,
      categoryId: category || selectedCategory,
      userId: sessionStorage.getItem("loginId"),
    };

    ST_ProSaveChangedCategory(body)
      .then((response) => {
        if (response.status === 200) {
          alert("Category updated successfully");
          setShowSaveButton(false); // Hide save button

          const resData = response.data.success.data;
          setDataProc(resData.ProcurementList);
          const dynamicKeys = new Set(); // Use a Set to avoid duplicates
          const procurementList = resData.ProcurementList;
          procurementList.forEach((item) => {
            Object.keys(item).forEach((key) => {
              if (
                ![
                  "id",
                  "subtaskname",
                  "duration",
                  "remaining_duration",
                ].includes(key)
              ) {
                dynamicKeys.add(key);
              }
            });
          });

          setDynamicHeaders(Array.from(dynamicKeys)); // Convert Set back to Array

          const procurementCatId = resData.ProcurementID[0];
          if (procurementCatId) {
            setSelectedCategory(procurementCatId.procurementcatid);
          }
        }
      })
      .catch((error) => {
        console.error("Error saving category:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDoubleSave = () => {
    setIsLoading(true);
    if (!category && (selectedCategory === "-1" || selectedCategory === "")) {
      alert("Select Category to Update");
      return;
    }
    setIsLoading(true);
    const body = {
      projectId: projectId,
      taskId: taskId,
      categoryId: category || selectedCategory,
      userId: sessionStorage.getItem("loginId"),
    };

    ST_ProSaveChangedCategory(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);

          setShowSaveButton(false); // Hide save button

          const resData = response.data.success.data;
          setDataProc(resData.ProcurementList);
          const dynamicKeys = new Set(); // Use a Set to avoid duplicates
          const procurementList = resData.ProcurementList;
          procurementList.forEach((item) => {
            Object.keys(item).forEach((key) => {
              if (
                ![
                  "id",
                  "subtaskname",
                  "duration",
                  "remaining_duration",
                ].includes(key)
              ) {
                dynamicKeys.add(key);
              }
            });
          });

          setDynamicHeaders(Array.from(dynamicKeys)); // Convert Set back to Array

          const procurementCatId = resData.ProcurementID[0];
          if (procurementCatId) {
            setSelectedCategory(procurementCatId.procurementcatid);
          }
        }
      })
      .catch((error) => {
        console.error("Error saving category:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddCategory = () => {
    // Handle adding category
  };

  const handleCatFetch = () => {};

  const handleDeleteCategory = () => {
    const selectedIds = Object.keys(checkedRowsProc).filter(
      (key) => checkedRowsProc[key]
    );

    // Alert if no checkboxes are selected
    if (selectedIds.length === 0) {
      alert("Please select items to delete.");
      return;
    }

    // Only ask for confirmation if items are selected
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (!confirmDelete) {
      return;
    }

    setIsLoading(true);
    const body = selectedIds.map((item) => ({
      subTaskId: parseInt(item),
      userId: parseInt(sessionStorage.getItem("loginId")),
    }));

    ST_ProTaskDelete(body)
      .then((response) => {
        if (response.status === 200) {
          alert("Subtask deleted successfully");
          btnSubmit_Click(2, 2);
          const resData = response.data;
          setDataProc(resData.success.data.ProcurementList);
        }
      })

      .catch((error) => {
        console.error("Error deleting subtask:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveAll = () => {
    setIsLoading(true);
    const body = dataProc?.map((item) => ({
      projectsId: projectId,
      taskId: taskId,
      subTaskId: item.subtaskid,
      remainingWork: item.remainingWork,
      subTaskName: item.subtaskname,
      categoryId: selectedCategory,
      userId: sessionStorage.getItem("loginId"),
      duration: item.duration,
      radioButton: 2,
    }));

    ST_ProSaveAllTasks(body)
      .then((response) => {
        if (response.status === 200) {
          alert("Subtask updated successfully");
          fetchVolTableData();
        }
      })
      .catch((error) => {
        console.error("Error saving subtask:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddSubTask = () => {
    if (selectedCategory === "-1" || selectedCategory === "") {
      alert("Select Category First.");
      return;
    } 

    if (!procSubtaskName || !subTaskduration) {
      alert("Please Enter both Subtask Name and Duration(greater than Zero).");
      return;
    }

    if (parseInt(subTaskduration) <= 0) {
      alert("Please Enter both Subtask Name and Duration(greater than Zero).");
      return;
    }

    setIsLoading(true);

    const body = {
      projectId: projectId,
      taskId: taskId,
      taskName: procSubtaskName,
      duration: subTaskduration,
      catID: selectedCategory,
      userId: sessionStorage.getItem("loginId"),
      displaySeq: displaySequence,
    };

    ST_ProSaveTask(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          if (
            response.data.success.data.message ===
            "Sub Task added Successfully."
          ) {
            alert("Subtask added successfully");
            const resData = response.data;
            setGridDataList(resData.success.data);
            setDataProc(resData.success.data.ProcurementList);
            setProcSubtaskName("");
            setSubTaskduration("");
            setCategory("");
          } else if (
            response.data.success.data.message ===
            "Sub Task name already exists,please change subtask name and try again."
          ) {
            alert(
              "Sub Task name already exists, please change subtask name and try again."
            );
            setIsLoading(false);
          } else {
            // Handle other messages or cases if necessary
            alert("An unexpected response was received.");
            setIsLoading(false);
          }
        } else {
          // Handle non-200 status codes
          alert("An error occurred. Please try again.");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // Handle network or unexpected errors
        alert("An error occurred: " + error.message);
        setIsLoading(false);
      });
  };

  const handleCloseSubTask = () => {
    setShowSubTaskPanel(false);
  };

  const handleCheckboxChangeForStartPro = (event) => {
    const checked = event.target.checked;

    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to change the status?"
    );

    if (isConfirmed) {
      setIsStartCheckedPro(checked);

      if (checked) {
        setIsLoading(true);
        ST_ProStartTask({ projectId, taskId })
          .then((response) => {
            if (response.status === 200) {
              alert(response.data.message);
              setIsStartDisabledPro(true);
            }
          })
          .catch((error) => {
            console.error("Error starting task:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      // If the user cancels, reset the checkbox to its previous state
      event.target.checked = !checked;
    }
  };

  const handleCheckboxChangeForClosedPro = (event) => {
    const checked = event.target.checked;

    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to close the Task?"
    );

    if (isConfirmed) {
      setIsCloseCheckedPro(checked);

      if (checked) {
        setIsLoading(true);
        ST_ProTaskClose({ projectId, taskId })
          .then((response) => {
            if (response.status === 200) {
              if (response?.data?.success?.data === null) {
                alert("Please complete Sub Task to Close the Task.");
                setIsCloseCheckedPro(false);
              } else {
                alert(response?.data?.success?.message);
                setIsCloseCheckedPro(false);
                setIsCloseDisabledPro(true); // Disable the checkbox if success
              }
            } else if (response?.response?.status === 400) {
              alert("Please complete Sub Task to Close the Task.");
              setIsCloseCheckedPro(false);
            } else {
              alert("Please complete Sub Task to Close the Task.");
              setIsCloseCheckedPro(false);
            }
          })
          .catch((error) => {
            if (error.response) {
              // Check if the status code is 400
              if (error.response.status === 400) {
                alert("Please complete Sub Task to Close the Task.");
              } else {
                // For other error statuses, log and show a general message
                console.error("Error response:", error.response);
                alert(`Error: ${error.response.status}`);
              }
            } else if (error.request) {
              // No response was received from the server
              console.error("No response received:", error.request);
              alert(
                "No response received from the server. Please try again later."
              );
            } else {
              // Error in setting up the request
              console.error("Error:", error.message);
              alert("An error occurred: " + error.message);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      // If the user cancels, reset the checkbox to its previous state
      event.target.checked = !checked;
    }
  };

  useEffect(() => {
    const allChecked =
      dataProc?.length > 0 &&
      dataProc?.every((item) => checkedRowsProc[item.id]);
    setSelectAllCheckedProc(allChecked);
  }, [checkedRowsProc, dataProc]);

  const handleSelectAllProc = (event) => {
    const isChecked = event.target.checked;
    setSelectAllCheckedProc(isChecked);
    const newCheckedItems = {};
    dataProc?.forEach((item) => {
      newCheckedItems[item.id] = isChecked;
    });
    setCheckedRowsProc(newCheckedItems);
  };

  const handleCheckboxChangeProc = (event, id) => {
    setCheckedRowsProc((prev) => ({
      ...prev,
      [id]: event.target.checked,
    }));
  };

  //auto update

  const AutoCheckUpdate = async () => {
    setIsLoading(true);
    try {
      const response = await ST_AutoUpdCheck({ projectId, taskId });
      const data = response?.data?.success?.data[0];
      if (response.data === 200) {
        setAutoupdateStopFrequency(data.AutoUpdateStopFrequency);
        if (data.AutoUpdateStopFrequency) {
          setDeleteChkAuto(true);
        }
      }
    } catch (error) {
      console.error("Error checking auto update:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveFrequency = async () => {
    setIsLoading(true);
    try {
      const frequencyDays = parseInt(autoupdateStopFrequency);

      // Validation check
      if (frequencyDays <= 0 || isNaN(frequencyDays)) {
        alert("Auto Stop Frequency (Days) should be greater than zero.");
        return; // Exit the function if the validation fails
      }

      const body = {
        projectsId: parseInt(projectId),
        taskId: parseInt(taskId),
        autoStopFrequencyDays: frequencyDays,
        userId: parseInt(sessionStorage.getItem("loginId")),
        subTaskType: 3,
      };

      const response = await ST_AutoSave(body);
      if (response.data === 200 || response.data.success === true) {
        alert("Record Saved Successfully.");
      }
      btnSubmit_Click(3, 3);
    } catch (error) {
      console.error("Error saving frequency:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAuto = async () => {
    setIsLoading(true);
    try {
      const body = {
        projectsId: projectId,
        taskId: taskId,
        userId: sessionStorage.getItem("loginId"),
      };
      const response = await ST_AutoDeleteTask(body);

      // Corrected condition
      if (response.data === 200 || response.data.success === true) {
        alert(response.data.message);
        setAutoupdateStopFrequency(""); // Clear the frequency
        btnSubmit_Click(3, 3); // Refresh data
      } else {
        alert("Failed to delete the record."); // Handle failure case
      }
    } catch (error) {
      console.error("Error deleting auto update task:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartMainTask = (event) => {
    const checked = event.target.checked;
    const isConfirmed = window.confirm(
      "Are you sure you want to change the status?"
    );

    if (isConfirmed) {
      setIsStartCheckedAuto(checked);

      if (checked) {
        setIsLoading(true);
        ST_AutoStartTask({ projectId, taskId })
          .then((response) => {
            if (response.status === 200) {
              alert(response.data.message);
              setIsStartDisabledAuto(true);
            }
          })
          .catch((error) => {
            console.error("Error starting task:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      event.target.checked = !checked;
    }
  };

  const handleReviewAndClose = () => {
    // Handle reviewing and closing main task
  };

  // Vol Type

  useEffect(() => {
    const allChecked =
      dataVol?.length > 0 &&
      dataVol?.every((item) => checkedRowsV[item.subtaskid]);
    setSelectAllCheckedV(allChecked);
  }, [checkedRowsV, dataVol]);

  const handleSelectAllV = (event) => {
    const isChecked = event.target.checked;
    setSelectAllCheckedV(isChecked);
    const newCheckedItems = {};
    dataVol?.forEach((item) => {
      newCheckedItems[item.subtaskid] = isChecked;
    });
    setCheckedRowsV(newCheckedItems);
  };

  const handleCheckboxChangeV = (event, id) => {
    setCheckedRowsV((prev) => ({
      ...prev,
      [id]: event.target.checked,
    }));
  };

  const fetchVolTableData = () => {
    setIsLoading(true);
    ST_GetVolData({ projectId, taskId })
      .then((response) => {
        if (response.data === 200) {
          const data = response.data.success.data;
          setDataVol(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching vol data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddVolTask = () => {
    // const newSubtask = {
    //   SubTaskId: displaySequence - 1,
    //   SubTaskName: volSubtaskName,
    //   TotalWork: totalWork,
    //   UOMId: workUnit,
    //   Duration: parseFloat(duration).toFixed(2),
    // };

    // const newDataVol = [...dataVol];
    // const insertIndex = Math.max(
    //   0,
    //   Math.min(displaySequence - 1, newDataVol.length)
    // );
    // newDataVol.splice(insertIndex, 0, newSubtask);
    // setDataVol(newDataVol);

    if (!volSubtaskName || !totalWork || !workUnit || !duration) {
      alert(
        "SubTaskName/Total Work/Unit/Duration cannot be blank(mandatory).Both Total Work and Duration should be greater than Zero."
      );
      return;
    }

    if (parseInt(totalWork) <= 0) {
      alert(
        "SubTaskName/Total Work/Unit/Duration cannot be blank(mandatory).Both Total Work and Duration should be greater than Zero."
      );
      return;
    }

    if (parseInt(duration) <= 0) {
      alert(
        "SubTaskName/Total Work/Unit/Duration cannot be blank(mandatory).Both Total Work and Duration should be greater than Zero."
      );
      return;
    }

    setIsLoading(true);
    const body = {
      projectsId: projectId,
      taskId: taskId,
      stName: volSubtaskName,
      totalWork: parseInt(totalWork),
      workUOM: parseInt(workUnit),
      duration: duration,
      userId: sessionStorage.getItem("loginId"),
      displaySeq: displaySequence,
    };

    ST_VolTaskAdd(body)
      .then((response) => {
        setIsLoading(true);
        if (response.status === 200) {
          alert("Volumetric task added successfully");
          const resData = response.data;
          setDataVol(resData.success.data.VolumetricSubtask);
          setIsLoading(false);
          setVolSubtaskName("");
          setTotalWork("");
          setWorkUnit("");
          setDuration("");
        }
      })
      .catch((error) => {
        console.error("Error adding vol task:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleStartMainTaskVol = (event) => {
    const checked = event.target.checked;
    const isConfirmed = window.confirm(
      "Are you sure you want to change the status?"
    );

    if (isConfirmed) {
      setIsStartCheckedVol(checked);

      if (checked) {
        setIsLoading(true);
        ST_VolStartTask({ projectId, taskId })
          .then((response) => {
            if (response.status === 200) {
              alert(response.data.message);
              setIsStartDisabledVol(true);
            }
          })
          .catch((error) => {
            console.error("Error starting task:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      event.target.checked = !checked;
    }
  };

  const handleCheckboxChangeForClosedVol = (event) => {
    const checked = event.target.checked;

    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to close the Task?"
    );

    if (isConfirmed) {
      setIsCloseCheckedVol(checked);

      if (checked) {
        setIsLoading(true);
        ST_VolCloseTask({ projectId, taskId })
          .then((response) => {
            if (response.status === 200) {
              if (response?.data?.success?.data === null) {
                alert("Please complete Sub Task to Close the Task.");
                setIsCloseCheckedVol(false);
              } else {
                alert(response?.data?.success?.message);
                setIsCloseCheckedVol(false);
                setIsCloseDisabledVol(true); // Disable the checkbox if success
              }
            } else if (response?.response?.status === 400) {
              alert("Please complete Sub Task to Close the Task.");
              setIsCloseCheckedVol(false);
            } else {
              alert("Please complete Sub Task to Close the Task.");
              setIsCloseCheckedVol(false);
            }
          })
          .catch((error) => {
            if (error.response) {
              // Check if the status code is 400
              if (error.response.status === 400) {
                alert("Please complete Sub Task to Close the Task.");
              } else {
                // For other error statuses, log and show a general message
                console.error("Error response:", error.response);
                alert(`Error: ${error.response.status}`);
              }
            } else if (error.request) {
              // No response was received from the server
              console.error("No response received:", error.request);
              alert(
                "No response received from the server. Please try again later."
              );
            } else {
              // Error in setting up the request
              console.error("Error:", error.message);
              alert("An error occurred: " + error.message);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      // If the user cancels, reset the checkbox to its previous state
      event.target.checked = !checked;
    }
  };

  const handleDeleteVolSubtask = () => {
    const selectedIds = Object.keys(checkedRowsV).filter(
      (key) => checkedRowsV[key]
    );
    if (selectedIds.length === 0) {
      alert("Please select items to delete");
      return;
    }
    let confirmDelete = window.confirm("Are you sure you want to delete?");
    if (!confirmDelete) {
      return;
    }

    setIsLoading(true);
    const body = selectedIds.map((item) => ({
      subTaskId: parseInt(item),
      userId: parseInt(sessionStorage.getItem("loginId")),
      projectsId: parseInt(projectId),
      taskId: parseInt(taskId),
    }));

    ST_VolTaskDelete(body)
      .then((response) => {
        if (response.status === 200) {
          alert("Subtask deleted successfully");
          const resData = response.data;
          setDataVol(resData.success.data.VolumetricSubtask);
        }
      })
      .catch((error) => {
        console.error("Error deleting subtask:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveSubtaskUpdate = () => {
    setIsLoading(true);
    const body = dataVol?.map((item) => ({
      subTaskId: item.subtaskid,
      remainingWork: item.remainingWork,
      projectNo: "",
      subTaskName: item.subtaskname,
      totalWork: item.originaltotalwork,
      uomId: item.uomid,
      duration: item.originalduration,
      userId: sessionStorage.getItem("loginId"),
      querySubTaskType: 0,
      isErpLN: 0,
      projectsId: projectId,
      taskId: taskId,
    }));

    ST_VolSaveTask(body)
      .then((response) => {
        if (response.status === 200) {
          alert("Subtask updated successfully");
          fetchVolTableData();
        }
      })
      .catch((error) => {
        console.error("Error saving subtask:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleKeyDown = (e) => {
    // Allowed characters: digits 1-9
    const allowedChars = /^[0-9]$/;

    // If the key pressed does not match the allowed characters, prevent the input
    if (!allowedChars.test(e.key) && e.key.length === 1) {
      e.preventDefault(); // Block the keypress
    }
  };

  useEffect(() => {
    console.log("newProjStatus:", projStatus); // Log projStatus whenever it changes
  }, [projStatus]); // Log when projStatus updates

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={12}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"RoleMaster"} />
          </Grid>

          <Grid item xs={12}>
            <div className={modules["addEdit_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Project Plan</a>
                <img src={bread} alt="" />
                <a> My Projects </a>
                <img src={bread} alt="" />
                <a> Sub Task/Activity Type </a>
              </div>
            </div>
            <Grid container className={modules["mid-contAET"]}>
              <Stack sx={{ width: "55%", padding: "8px" }} alignItems="center">
                <Typography
                  className={modules["inputCCPMSet"]}
                  sx={{ fontWeight: "bold", backgroundColor: "red" }}
                >
                  {error}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                spacing={6}
                alignItems="center"
                sx={{ padding: "8px" }}
              >
                <Typography
                  className={modules["inputCCPMSet"]}
                  sx={{ width: "80px" }}
                >
                  Select Type
                </Typography>
                <Typography sx={{ marginLeft: "70px !important" }}>
                  :
                </Typography>
                <Stack direction="row" sx={{ width: "78%" }} spacing={3}>
                  <Stack direction="row">
                    <input
                      type="radio"
                      value={1}
                      id="listType"
                      checked={selectedValue === 1}
                      disabled={isRadioDisabled && selectedValue !== 1}
                      onChange={handleRadioChange}
                      style={{ marginRight: "2px" }}
                    />
                    <label
                      htmlFor="listType"
                      className={modules["inputCCPMSet"]}
                    >
                      List Type
                    </label>
                  </Stack>
                  <Stack direction="row">
                    <input
                      type="radio"
                      id="procType"
                      value={2}
                      checked={selectedValue === 2}
                      disabled={isRadioDisabled && selectedValue !== 2}
                      onChange={handleRadioChange}
                      style={{ marginRight: "2px" }}
                    />
                    <label
                      htmlFor="procType"
                      className={modules["inputCCPMSet"]}
                    >
                      Procurement Type
                    </label>
                  </Stack>
                  <Stack direction="row">
                    <input
                      type="radio"
                      id="autoType"
                      value={3}
                      checked={selectedValue === 3}
                      disabled={isRadioDisabled && selectedValue !== 3}
                      onChange={handleRadioChange}
                      style={{ marginRight: "2px" }}
                    />
                    <label
                      htmlFor="autoType"
                      className={modules["inputCCPMSet"]}
                    >
                      Auto Update Type
                    </label>
                  </Stack>
                  <Stack direction="row">
                    <input
                      type="radio"
                      id="volType"
                      value={4}
                      checked={selectedValue === 4}
                      disabled={isRadioDisabled && selectedValue !== 4}
                      onChange={handleRadioChange}
                      style={{ marginRight: "2px" }}
                    />
                    <label
                      htmlFor="volType"
                      className={modules["inputCCPMSet"]}
                    >
                      Volumetric Type
                    </label>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                justifyItems="center"
                alignItems="center"
                sx={{ marginTop: "1%", marginBottom: "1%" }}
              >
                <button
                  className={modules["btnProjT"]}
                  onClick={() => {
                    btnSubmit_Click(selectedValue, subTypeTask);
                  }}
                >
                  Submit
                </button>
                <button
                  className={modules["btnProjT"]}
                  onClick={handleBackClick}
                >
                  Back
                </button>
              </Stack>

              {mainPanelEn && (
                <div>
                  {/* list type box */}
                  {listView && (
                    <Box border={1} sx={{ width: "90%" }}>
                      <Typography className={modules["subTaskText"]}>
                        Subtask : List Type
                      </Typography>
                      <Stack direction="column" spacing={2} padding={1}>
                        <Stack direction="row">
                          <table className={modules["tableTaskListList"]}>
                            <tbody>
                              <tr>
                                <td>Project Name :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.projectname}
                                  </Typography>
                                </td>
                                <td>Main Task Name :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.taskname}
                                  </Typography>
                                </td>
                                <td>Duration :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.originaltaskduration?.toFixed(
                                      2
                                    )}
                                  </Typography>
                                </td>
                                <td>Start Date :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {formatDate(
                                      mainTableData?.originaltaskstartdate
                                    )}
                                  </Typography>
                                </td>
                                <td>Finish Date :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {formatDate(
                                      mainTableData?.originaltaskenddate
                                    )}
                                  </Typography>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Stack>

                        <Stack
                          direction="row"
                          sx={{ marginTop: "32px !important" }}
                        >
                          {projStatus === 2 ||
                          projStatus === 3 ||
                          projStatus === 4 ||
                          projStatus === 5 ? null : (
                            <Stack direction="row" spacing={2}>
                              <img
                                src={add}
                                alt=""
                                style={{ cursor: "pointer" }}
                                onClick={() => setAddListTask(true)}
                              />
                              <img
                                src={Delete}
                                alt=""
                                style={{ cursor: "pointer" }}
                                onClick={handleDeleteClick}
                              />
                            </Stack>
                          )}
                        </Stack>

                        <Stack direction="row">
                          <input
                            type="checkbox"
                            checked={isStartChecked}
                            onChange={handleCheckboxChangeForStart}
                            disabled={isStartDisabled}
                          />
                          <Typography
                            sx={{ marginLeft: "5px", fontWeight: "bold" }}
                            className={modules["inputCCPMSet"]}
                          >
                            Start Main Task
                          </Typography>
                        </Stack>

                        <Box>
                          <table
                            style={{ width: "80%" }}
                            className={modules["tableSTList"]}
                          >
                            <thead>
                              <tr>
                                <th>
                                  {editingList !== null ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        checked={selectAllCheckedL}
                                        onChange={handleSelectAllL}
                                        disabled={
                                          projStatus === 2 ||
                                          projStatus === 3 ||
                                          projStatus === 4 ||
                                          projStatus === 5
                                        }
                                      />{" "}
                                      Delete
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        checked={selectAllCheckedL}
                                        onChange={handleSelectAllL}
                                        disabled={
                                          projStatus === 2 ||
                                          projStatus === 3 ||
                                          projStatus === 4 ||
                                          projStatus === 5
                                        }
                                      />{" "}
                                      Delete
                                    </>
                                  )}
                                </th>
                                <th>SubTaskId</th>
                                <th>SubTaskName</th>

                                <th>Display Sequence</th>
                                <th>Edit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Render tasks if present */}
                              {gridDataList &&
                                gridDataList.length > 0 &&
                                gridDataList.map((row, index) => (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          handleCheckboxChangeL(
                                            e,
                                            row.subtaskid
                                          )
                                        }
                                        checked={!!checkedRowsL[row.subtaskid]}
                                        disabled={
                                          projStatus === 2 ||
                                          projStatus === 3 ||
                                          projStatus === 4 ||
                                          projStatus === 5
                                        }
                                      />
                                    </td>
                                    <td>{index + 1}</td>
                                    <td>
                                      {editingList === row.subtaskid ? (
                                        <textarea
                                          rows="2"
                                          cols="20"
                                          value={updatedSubName}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            const regex =
                                              /^[a-zA-Z0-9&()[\].\/\-_=+ ]*$/;
                                            if (
                                              regex.test(value) &&
                                              value.length <= 50
                                            ) {
                                              setUpdatedSubName(value);
                                            }
                                          }}
                                          onBlur={(e) => {
                                            const value = e.target.value;
                                            const invalidCharsRegex =
                                              /[[\]\/=+.]/;
                                            if (invalidCharsRegex.test(value)) {
                                              alert(
                                                "No Special Characters Allowed"
                                              );
                                              setUpdatedSubName("");
                                            }
                                          }}
                                          className={modules["projectTextArea"]}
                                        />
                                      ) : (
                                        <> {row.subtaskname} </>
                                      )}
                                    </td>
                                    <td></td>
                                    <td>
                                      {row.subtaskname !== "Default SubTask" &&
                                        (editingList === row.subtaskid ? (
                                          <>
                                            <Stack direction="row" spacing={1}>
                                              <button
                                                className={modules["btn2"]}
                                                onClick={() =>
                                                  handleUpdateClick(row)
                                                }
                                              >
                                                Update
                                              </button>
                                              <button
                                                className={modules["btn2"]}
                                                onClick={() => cancelEditL()}
                                              >
                                                Cancel
                                              </button>
                                            </Stack>
                                          </>
                                        ) : (
                                          <button
                                            className={modules["btn2"]}
                                            onClick={(e) => handleEditL(row)}
                                            disabled={
                                              projStatus === 2 ||
                                              projStatus === 3 ||
                                              projStatus === 4 ||
                                              projStatus === 5
                                            }
                                          >
                                            Edit
                                          </button>
                                        ))}
                                    </td>
                                  </tr>
                                ))}

                              {/* Insert new task row if addListTask is true */}
                              {addListTask && (
                                <>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <textarea
                                        rows="2"
                                        cols="20"
                                        value={insertedSubName}
                                        className={modules["projectTextArea"]}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const regex =
                                            /^[a-zA-Z0-9&()[\].\/\-_=+ ]*$/;
                                          if (
                                            regex.test(value) &&
                                            value.length <= 50
                                          ) {
                                            setInsertedSubName(value);
                                          }
                                        }}
                                        onBlur={(e) => {
                                          const value = e.target.value;
                                          const invalidCharsRegex =
                                            /[[\]\/=+.]/;
                                          if (invalidCharsRegex.test(value)) {
                                            alert(
                                              "No Special Characters Allowed"
                                            );
                                            setInsertedSubName("");
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className={modules["inputSubTasks"]}
                                        value={displaySequenceList}
                                        onChange={handleDisplaySequenceList}
                                      />
                                    </td>
                                    <td>
                                      <Stack className={modules["btnDirecSub"]}>
                                        <button
                                          onClick={handleAddNewList}
                                          className={modules["btn2"]}
                                        >
                                          Insert
                                        </button>
                                        <button
                                          className={modules["btn2Spec"]}
                                          onClick={handleCancelAddList}
                                        >
                                          Cancel
                                        </button>
                                      </Stack>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </Box>

                        <Stack direction="row">
                          <input
                            type="checkbox"
                            checked={isCloseChecked}
                            onChange={handleCheckboxChangeForClosed}
                            disabled={isCloseDisabled}
                          />
                          <Typography
                            sx={{ marginLeft: "5px", fontWeight: "bold" }}
                            className={modules["inputCCPMSet"]}
                          >
                            Review and Close Main Task
                          </Typography>
                        </Stack>

                        {/* <Stack direction="row" alignItems="center" spacing={2}>
                          <button
                            className={modules["btnProjT"]}
                            onClick={handleAddClick}
                          >
                            Add
                          </button>
                          <button
                            className={modules["btnProjT"]}
                            onClick={handleDeleteClick}
                          >
                            Delete
                          </button>
                        </Stack> */}
                      </Stack>
                      {/* Upload Panel */}
                      {visibleUpload && (
                        <Stack spacing={2} padding={1}>
                          <button>Click here to download Excel Format</button>
                          <Stack direction="row" spacing={2}>
                            <button>Import from Excel</button>
                            <button>Save</button>
                          </Stack>
                          <Stack direction="row" spacing={2}>
                            <button onClick={handleCloseUpload}>
                              Close Upload
                            </button>
                          </Stack>
                        </Stack>
                      )}
                    </Box>
                  )}

                  {/* Procurement type box */}
                  {procView && (
                    <Box border={1} sx={{ width: "90%" }}>
                      <Typography className={modules["subTaskText"]}>
                        Subtask : Procurement Type
                      </Typography>
                      <Stack direction="column" spacing={2} padding={1}>
                        <Stack direction="row">
                          <table className={modules["tableTaskList"]}>
                            <tbody>
                              <tr>
                                <td>Project Name :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.projectname}
                                  </Typography>
                                </td>
                                <td>Main Task Name :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.taskname}
                                  </Typography>
                                </td>
                                <td>Duration :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.originaltaskduration?.toFixed(
                                      2
                                    )}
                                  </Typography>
                                </td>
                                <td>Start Date :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {formatDate(
                                      mainTableData?.originaltaskstartdate
                                    )}
                                  </Typography>
                                </td>
                                <td>Finish Date :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {formatDate(
                                      mainTableData?.originaltaskenddate
                                    )}
                                  </Typography>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Stack
                            direction="row"
                            spacing={0}
                            alignItems="center"
                          >
                            <Typography
                              className={modules["inputCCPMSet"]}
                              sx={{ color: "red !important" }} // Reduced margin
                            >
                              *
                            </Typography>
                            <Typography className={modules["inputCCPMSet"]}>
                              Category :
                            </Typography>
                          </Stack>
                          <select
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            displayEmpty
                            className={modules["dropdownSelectSub"]}
                            disabled={
                              (dataProc.length > 0 && !showSaveButton) ||
                              projStatus === 2 ||
                              projStatus === 3 ||
                              projStatus === 4 ||
                              projStatus === 5
                            }
                          >
                            <option value="-1">SELECT</option>

                            {categories.map((cat) => (
                              <option key={cat.id} value={cat.id}>
                                {cat.categoryname}
                              </option>
                            ))}
                          </select>

                          {projStatus === 2 ||
                          projStatus === 3 ||
                          projStatus === 4 ||
                          projStatus === 5 ? null : (
                            <>
                              {dataProc && dataProc.length > 0 && (
                                <Stack direction="row" spacing={1}>
                                  <input
                                    type="checkbox"
                                    checked={showSaveButton}
                                    onChange={() => {
                                      setShowSaveButton(!showSaveButton);
                                      if (!showSaveButton) {
                                        setSelectedCategory(""); // Reset selected category when enabling dropdown
                                      }
                                    }}
                                  />
                                  <Typography
                                    className={modules["inputCCPMSet"]}
                                  >
                                    Change Category
                                  </Typography>
                                </Stack>
                              )}

                              {showSaveButton && (
                                <img
                                  src={Save}
                                  alt=""
                                  onClick={handleSaveCategory}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </>
                          )}
                        </Stack>

                        {!showSaveButton && (
                          <>
                            <Box>
                              {projStatus === 2 ||
                              projStatus === 3 ||
                              projStatus === 4 ||
                              projStatus === 5 ? null : (
                                <>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    mb={2}
                                    alignItems="flex-end"
                                  >
                                    <Stack
                                      direction="row"
                                      spacing={0}
                                      alignItems="center"
                                    >
                                      <Typography
                                        className={modules["inputCCPMSet"]}
                                        sx={{ color: "red !important" }}
                                      >
                                        *
                                      </Typography>
                                      <Typography
                                        className={modules["inputCCPMSet"]}
                                      >
                                        Sub Task Name :
                                      </Typography>
                                    </Stack>
                                    <textarea
                                      rows={2}
                                      cols={20}
                                      value={procSubtaskName}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const regex =
                                          /^[a-zA-Z0-9&()[\].\/\-_=+ ]*$/;
                                        if (
                                          regex.test(value) &&
                                          value.length <= 50
                                        ) {
                                          setProcSubtaskName(value);
                                        }
                                      }}
                                      onBlur={(e) => {
                                        const value = e.target.value;
                                        const invalidCharsRegex = /[[\]\/=+.]/;
                                        if (invalidCharsRegex.test(value)) {
                                          alert(
                                            "No Special Characters Allowed"
                                          );
                                          setProcSubtaskName("");
                                        }
                                      }}
                                      style={{ width: "300px" }}
                                    />
                                    <Stack
                                      direction="row"
                                      spacing={0}
                                      alignItems="center"
                                    >
                                      <Typography
                                        className={modules["inputCCPMSet"]}
                                        sx={{ color: "red !important" }}
                                      >
                                        *
                                      </Typography>
                                      <Typography
                                        className={modules["inputCCPMSet"]}
                                      >
                                        Sub Task Duration :
                                      </Typography>
                                    </Stack>
                                    <input
                                      value={subTaskduration}
                                      className={modules["inputSubTasksVol2"]}
                                      style={{
                                        width: "50px",
                                        padding: "1px 2px",
                                      }}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const filteredValue = value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        if (filteredValue.length <= 4) {
                                          setSubTaskduration(filteredValue);
                                        }
                                      }}
                                    />
                                    {dataProc && dataProc.length > 0 && (
                                      <>
                                        <Typography
                                          className={modules["inputCCPMSet"]}
                                        >
                                          Display Sequence :
                                        </Typography>
                                        <input
                                          value={displaySequence}
                                          onChange={(e) =>
                                            setDisplaySequence(e.target.value)
                                          }
                                          type="text"
                                          className={
                                            modules["inputSubTasksVol1"]
                                          }
                                          style={{
                                            width: "50px",
                                            height: "15px",
                                            padding: "1px 2px",
                                          }}
                                        />
                                      </>
                                    )}

                                    <Stack direction="row" spacing={2}>
                                      <img
                                        src={add}
                                        alt=""
                                        onClick={handleAddSubTask}
                                        style={{
                                          cursor: "pointer",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </Stack>
                                  </Stack>
                                </>
                              )}
                            </Box>
                            {dataProc && dataProc.length > 0 && (
                              <Stack direction="row" spacing={2} mb={2}>
                                {/* <img src={add} alt="" onClick={handleAddSubTask} /> */}

                                <img
                                  src={Delete}
                                  alt=""
                                  onClick={handleDeleteCategory}
                                  style={{
                                    cursor: "pointer",
                                    visibility:
                                      projStatus === 2 ||
                                      projStatus === 3 ||
                                      projStatus === 4 ||
                                      projStatus === 5
                                        ? "hidden"
                                        : "visible",
                                  }}
                                />
                                <img
                                  src={Save}
                                  alt=""
                                  onClick={handleSaveAll}
                                  style={{
                                    cursor: "pointer",
                                    visibility:
                                      projStatus === 2 ||
                                      projStatus === 3 ||
                                      projStatus === 4 ||
                                      projStatus === 5
                                        ? "hidden"
                                        : "visible",
                                  }}
                                />
                              </Stack>
                            )}

                            <Stack direction="row">
                              <input
                                type="checkbox"
                                checked={isStartCheckedPro}
                                onChange={handleCheckboxChangeForStartPro}
                                disabled={isStartDisabledPro}
                              />
                              <Typography
                                sx={{ marginLeft: "5px" }}
                                className={modules["inputCCPMSet"]}
                              >
                                Start Main Task
                              </Typography>
                            </Stack>
                            <Box>
                              {dataProc && dataProc.length > 0 && (
                                <table
                                  style={{ width: "80%" }}
                                  className={modules["tableST"]}
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        {projStatus === 2 ||
                                        projStatus === 3 ||
                                        projStatus === 4 ||
                                        projStatus === 5 ? null : (
                                          <>
                                            {" "}
                                            <input
                                              type="checkbox"
                                              checked={selectAllCheckedProc}
                                              onChange={handleSelectAllProc}
                                            />
                                          </>
                                        )}
                                      </th>
                                      <th>SubTaskId</th>
                                      <th>SubTaskName</th>
                                      <th>Duration</th>
                                      <th>Remaining Duration</th>
                                      {dynamicHeaders.map((header, index) => (
                                        <th key={index}>{header}</th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dataProc.map((item, index) => (
                                      <tr key={item.id}>
                                        <td>
                                          <input
                                            type="checkbox"
                                            onChange={(e) =>
                                              handleCheckboxChangeProc(
                                                e,
                                                item.id
                                              )
                                            }
                                            checked={!!checkedRowsProc[item.id]}
                                            disabled={
                                              projStatus === 2 ||
                                              projStatus === 3 ||
                                              projStatus === 4 ||
                                              projStatus === 5
                                            }
                                          />
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>
                                          <textarea
                                            type="text"
                                            style={{
                                              width: "164px",
                                              height: "36px",
                                            }}
                                            value={item.subtaskname}
                                            disabled={
                                              projStatus === 2 ||
                                              projStatus === 3 ||
                                              projStatus === 4 ||
                                              projStatus === 5
                                            }
                                            onChange={(e) => {
                                              const newData = [...dataProc];
                                              newData[index].subtaskname =
                                                e.target.value;
                                              setDataProc(newData);
                                            }}
                                            size="small"
                                          />
                                        </td>

                                        <td>
                                          <input
                                            type="text"
                                            style={{
                                              width: "171px",
                                              height: "21px",
                                            }}
                                            value={(
                                              parseFloat(item.duration) || 0
                                            ).toFixed(2)}
                                            disabled={
                                              projStatus === 2 ||
                                              projStatus === 3 ||
                                              projStatus === 4 ||
                                              projStatus === 5
                                            }
                                            onChange={(e) => {
                                              const newData = [...dataProc];
                                              newData[index].duration =
                                                e.target.value;
                                              setDataProc(newData);
                                            }}
                                            size="small"
                                          />
                                        </td>
                                        <td>
                                          <span />
                                          {item.remaining_duration.toFixed(2)}
                                        </td>
                                        {dynamicHeaders.map(
                                          (header, headerIndex) => (
                                            <td key={header}>
                                              <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                  // If checkboxes are disabled, you can omit this part
                                                  // const newData = [...dataProc];
                                                  // newData[index][header] = e.target.checked ? 1 : 0; // If you need to handle changes, though it's disabled.
                                                  // setDataProc(newData);
                                                }}
                                                checked={item[header] === 1} // Check if the value is 1 for checked
                                                disabled // Disable the checkbox
                                              />
                                            </td>
                                          )
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </Box>
                            <Stack direction="row">
                              <input
                                type="checkbox"
                                checked={isCloseCheckedPro}
                                onChange={handleCheckboxChangeForClosedPro}
                                disabled={isCloseDisabledPro}
                              />
                              <Typography
                                sx={{ marginLeft: "5px" }}
                                className={modules["inputCCPMSet"]}
                              >
                                Review and Close Main Task
                              </Typography>
                            </Stack>
                          </>
                        )}
                      </Stack>
                    </Box>
                  )}

                  {/* Auto Update type box */}
                  {autoUpdateView && (
                    <Box border={1} sx={{ width: "90%" }}>
                      <Typography className={modules["subTaskText"]}>
                        Subtask : Auto Update Type
                      </Typography>
                      <Stack direction="column" spacing={4} padding={1}>
                        <Stack direction="row">
                          <table className={modules["tableTaskList"]}>
                            <tbody>
                              <tr>
                                <td>Project Name :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.projectname}
                                  </Typography>
                                </td>
                                <td>Main Task Name :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.taskname}
                                  </Typography>
                                </td>
                                <td>Duration :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.originaltaskduration?.toFixed(
                                      2
                                    )}
                                  </Typography>
                                </td>
                                <td>Start Date :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {formatDate(
                                      mainTableData?.originaltaskstartdate
                                    )}
                                  </Typography>
                                </td>
                                <td>Finish Date :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {formatDate(
                                      mainTableData?.originaltaskenddate
                                    )}
                                  </Typography>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Stack>
                        <Stack direction="row">
                          <input
                            type="checkbox"
                            checked={isStartCheckedAuto}
                            onChange={handleStartMainTask}
                            disabled={isStartDisabledAuto}
                          />
                          <Typography
                            sx={{ marginLeft: "5px" }}
                            className={modules["inputCCPMSet"]}
                          >
                            Start Main Task
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography
                            fontWeight="bold"
                            className={modules["inputCCPMSet"]}
                          >
                            Autoupdate Stop Frequency(days) :
                          </Typography>
                          <input
                            type="text"
                            value={autoupdateStopFrequency}
                            disabled={
                              projStatus === 2 ||
                              projStatus === 3 ||
                              projStatus === 4 ||
                              projStatus === 5
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value)) {
                                setAutoupdateStopFrequency(value);
                              }
                            }}
                            className={modules["inputSubTasks"]}
                            style={{
                              paddingLeft: "1px",
                              paddingTop: "1px",
                              paddingBottom: "1px",
                            }}
                          />
                          {projStatus === 2 ||
                          projStatus === 3 ||
                          projStatus === 4 ||
                          projStatus === 5 ? null : (
                            <>
                              <img
                                src={Save}
                                alt=""
                                onClick={handleSaveFrequency}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                              />
                              {deleteChkAuto && (
                                <img
                                  src={Delete}
                                  alt=""
                                  onClick={handleDeleteAuto}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "8px",
                                    marginBottom: "0.18rem",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Stack>
                        {projStatus === 2 ||
                        projStatus === 3 ||
                        projStatus === 4 ||
                        projStatus === 5 ? (
                          <>
                            <Box>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={4}
                                mb={2}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography
                                    fontWeight="bold"
                                    className={modules["inputCCPMSet"]}
                                  >
                                    Auto Update will Stop in :
                                  </Typography>
                                  <span>{autoUpdateStopIn}</span> Days.
                                </Stack>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography
                                    fontWeight="bold"
                                    className={modules["inputCCPMSet"]}
                                  >
                                    Remaining Days
                                    <span style={{ color: "blue" }}>
                                      (manual change will be saved on click of
                                      Start):{" "}
                                    </span>
                                  </Typography>
                                  <input
                                    value={remainingDays}
                                    className={modules["inputSubTasks"]}
                                    onChange={(e) =>
                                      setRemainingDays(e.target.value)
                                    }
                                    type="number"
                                    disabled={
                                      projStatus === 2 ||
                                      projStatus === 3 ||
                                      projStatus === 4 ||
                                      projStatus === 5
                                    }
                                  />
                                </Stack>
                              </Stack>
                              <Stack direction="row" spacing={2}>
                                <input type="checkbox" />
                                <Typography className={modules["inputCCPMSet"]}>
                                  Review and Close Main Task
                                </Typography>
                              </Stack>
                            </Box>
                          </>
                        ) : null}
                      </Stack>
                    </Box>
                  )}

                  {/* Vol type box */}
                  {volView && (
                    <Box border={1} sx={{ width: "86%" }}>
                      <Typography className={modules["subTaskText"]}>
                        Subtask : Volumetric Type
                      </Typography>
                      <Stack direction="column" spacing={2} padding={1}>
                        <Stack direction="row">
                          <table className={modules["tableTaskList"]}>
                            <tbody>
                              <tr>
                                <td>Project Name :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.projectname}
                                  </Typography>
                                </td>
                                <td>Main Task Name :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.taskname}
                                  </Typography>
                                </td>
                                <td>Duration :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {mainTableData?.originaltaskduration?.toFixed(
                                      2
                                    )}
                                  </Typography>
                                </td>
                                <td>Start Date :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {formatDate(
                                      mainTableData?.originaltaskstartdate
                                    )}
                                  </Typography>
                                </td>
                                <td>Finish Date :</td>
                                <td>
                                  <Typography
                                    sx={{ backgroundColor: "skyblue" }}
                                  >
                                    {formatDate(
                                      mainTableData?.originaltaskenddate
                                    )}
                                  </Typography>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Stack>
                        <Box>
                          <Stack spacing={2} mb={2}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              style={{
                                alignItems: "flex-end",
                                marginTop: "1rem",
                                marginBottom: "0.5rem",
                                visibility:
                                  projStatus === 2 ||
                                  projStatus === 3 ||
                                  projStatus === 4 ||
                                  projStatus === 5
                                    ? "hidden"
                                    : "visible",
                              }}
                            >
                              <Typography
                                className={modules["inputCCPMSet"]}
                                sx={{ color: "red !important" }} // Reduced margin
                              >
                                *
                              </Typography>
                              <span
                                style={{ marginLeft: "2px", width: "100px" }}
                                className={modules["inputCCPMSet"]}
                              >
                                Sub Task Name :
                              </span>
                              <textarea
                                value={volSubtaskName}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^[a-zA-Z0-9&()[\].\/\-_=+ ]*$/;
                                  if (regex.test(value) && value.length <= 50) {
                                    setVolSubtaskName(value);
                                  }
                                }}
                                className={modules["inputSubTasks"]}
                                style={{
                                  width: "250px",
                                  height: "36px",
                                  marginRight: "8px",
                                  marginLeft: "2px",
                                }} // Adjust height as needed
                                onBlur={(e) => {
                                  const value = e.target.value;
                                  const invalidCharsRegex = /[[\]\/=+.]/;
                                  if (invalidCharsRegex.test(value)) {
                                    alert("No Special Characters Allowed");
                                    setVolSubtaskName("");
                                  }
                                }}
                                rows={4} // This will control the visible rows in the textarea
                                variant="outlined"
                                size="small"
                              />
                              <Typography
                                className={modules["inputCCPMSet"]}
                                sx={{ color: "red !important" }} // Reduced margin
                              >
                                *
                              </Typography>

                              <span
                                style={{ marginLeft: "2px" }}
                                className={modules["inputCCPMSet"]}
                              >
                                Total Work :
                              </span>
                              <input
                                value={totalWork}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const filteredValue = value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  if (filteredValue.length <= 4) {
                                    setTotalWork(filteredValue);
                                  }
                                }}
                                style={{
                                  marginRight: "8px",
                                  height: "20px",
                                  marginLeft: "3.5px",
                                }}
                                className={modules["inputSubTasksVol1"]}
                                type="text"
                              />
                              <Typography
                                className={modules["inputCCPMSet"]}
                                sx={{ color: "red !important" }} // Reduced margin
                              >
                                *
                              </Typography>
                              <Typography
                                style={{ marginLeft: "2px" }}
                                className={modules["inputCCPMSet"]}
                              >
                                Unit :
                              </Typography>
                              <select
                                value={workUnit}
                                style={{
                                  marginRight: "8px",
                                  width: "150px",
                                  marginLeft: "3.5px",
                                }}
                                onChange={(e) => setWorkUnit(e.target.value)}
                                select
                                variant="outlined"
                                size="small"
                                className={modules["dropdownSelectSub"]}
                              >
                                <option value={-1}>SELECT</option>
                                {unitNames?.map((i) => (
                                  <option value={i.id} key={i.id}>
                                    {i.unitname}
                                  </option>
                                ))}
                              </select>
                              <Typography
                                className={modules["inputCCPMSet"]}
                                sx={{ color: "red !important" }} // Reduced margin
                              >
                                *
                              </Typography>
                              <Typography
                                style={{ marginLeft: "2px" }}
                                className={modules["inputCCPMSet"]}
                              >
                                Duration (Days) :
                              </Typography>
                              <input
                                value={duration}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const filteredValue = value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  if (filteredValue.length <= 4) {
                                    setDuration(filteredValue);
                                  }
                                }}
                                className={modules["inputSubTasksVol1"]}
                                style={{
                                  marginRight: "8px",
                                  width: "55px",
                                  marginLeft: "3.5px",
                                }}
                                type="text"
                              />
                              {dataVol?.length > 0 && (
                                <>
                                  <span className={modules["inputCCPMSet"]}>
                                    Display Sequence :
                                  </span>
                                  <input
                                    style={{
                                      marginRight: "8px",
                                      width: "55px",
                                      marginLeft: "3.5px",
                                    }}
                                    value={displaySequence}
                                    onChange={(e) =>
                                      setDisplaySequence(e.target.value)
                                    }
                                    type="text"
                                    className={modules["inputSubTasksVol1"]}
                                  />
                                </>
                              )}

                              <img
                                src={add}
                                alt=""
                                onClick={handleAddVolTask}
                                style={{ cursor: "pointer" }}
                              />
                            </Stack>

                            {projStatus === 2 ||
                            projStatus === 3 ||
                            projStatus === 4 ||
                            projStatus === 5 ? null : (
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                style={{ minHeight: "1.2rem" }}
                              >
                                {dataVol && dataVol.length > 0 && (
                                  <>
                                    <img
                                      src={Delete}
                                      alt=""
                                      onClick={handleDeleteVolSubtask}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <img
                                      src={Save}
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      onClick={handleSaveSubtaskUpdate}
                                    />
                                  </>
                                )}
                              </Stack>
                            )}
                          </Stack>

                          <Stack direction="row">
                            <input
                              type="checkbox"
                              checked={isStartCheckedVol}
                              onChange={handleStartMainTaskVol}
                              disabled={isStartDisabledVol}
                            />
                            <Typography
                              sx={{ marginLeft: "5px" }}
                              className={modules["inputCCPMSet"]}
                            >
                              Start Main Task
                            </Typography>
                          </Stack>
                        </Box>

                        <div className="gridview">
                          {dataVol && dataVol.length > 0 && (
                            <table
                              style={{
                                width:
                                  projStatus === 2 ||
                                  projStatus === 3 ||
                                  projStatus === 4 ||
                                  projStatus === 5
                                    ? "95%"
                                    : "80%",
                              }}
                              className={modules["tableST"]}
                            >
                              <thead style={{ textAlign: "start" }}>
                                <tr style={{ textAlign: "start" }}>
                                  <th style={{ textAlign: "start" }}>
                                    <input
                                      type="checkbox"
                                      checked={selectAllCheckedV}
                                      onChange={handleSelectAllV}
                                      style={{ verticalAlign: "middle" }}
                                      disabled={
                                        projStatus === 2 ||
                                        projStatus === 3 ||
                                        projStatus === 4 ||
                                        projStatus === 5
                                      }
                                    />
                                    <span> Delete</span>
                                  </th>

                                  <th>Sr. No.</th>
                                  <th style={{ display: "none" }}>SubTaskId</th>
                                  <th>SubTaskName</th>
                                  <th>Total Work</th>
                                  <th
                                    style={{
                                      display:
                                        projStatus === 2 ||
                                        projStatus === 3 ||
                                        projStatus === 4 ||
                                        projStatus === 5
                                          ? "table-cell"
                                          : "none",
                                    }}
                                  >
                                    Remaining Work
                                  </th>
                                  <th>Unit</th>
                                  <th>Duration (Days)</th>
                                  <th
                                    style={{
                                      display:
                                        projStatus === 2 ||
                                        projStatus === 3 ||
                                        projStatus === 4 ||
                                        projStatus === 5
                                          ? "table-cell"
                                          : "none",
                                    }}
                                  >
                                    Remaining Duration (Days)
                                  </th>
                                  <th style={{ display: "none" }}>Edit</th>
                                </tr>
                              </thead>
                              <tbody style={{ textAlign: "start" }}>
                                {dataVol?.map((item, index) => (
                                  <tr key={item.SubTaskId}>
                                    <td style={{ textAlign: "start" }}>
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          handleCheckboxChangeV(
                                            e,
                                            item.subtaskid
                                          )
                                        }
                                        checked={!!checkedRowsV[item.subtaskid]}
                                        disabled={
                                          projStatus === 2 ||
                                          projStatus === 3 ||
                                          projStatus === 4 ||
                                          projStatus === 5
                                        }
                                      />
                                    </td>

                                    <td>{index + 1}</td>
                                    <td style={{ display: "none" }}>
                                      {item.SubTaskId}
                                    </td>
                                    <td>
                                      <textarea
                                        type="text"
                                        value={item.subtaskname}
                                        disabled={
                                          projStatus === 2 ||
                                          projStatus === 3 ||
                                          projStatus === 4 ||
                                          projStatus === 5
                                        }
                                        style={{
                                          width: "164px",
                                          height: "36px",
                                          paddingLeft: "3px",
                                        }} // Adjust height as needed
                                        onChange={(e) => {
                                          const newData = [...dataVol];
                                          newData[index].subtaskname =
                                            e.target.value;
                                          setDataVol(newData);
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={item.originaltotalwork.toFixed(
                                          2
                                        )}
                                        style={{
                                          width: "171px",
                                          height: "21px",
                                          paddingLeft: "3px",
                                        }}
                                        disabled={
                                          projStatus === 2 ||
                                          projStatus === 3 ||
                                          projStatus === 4 ||
                                          projStatus === 5
                                        }
                                        onChange={(e) => {
                                          const newData = [...dataVol];
                                          newData[index].originaltotalwork =
                                            e.target.value;
                                          setDataVol(newData);
                                        }}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        display:
                                          projStatus === 2 ||
                                          projStatus === 3 ||
                                          projStatus === 4 ||
                                          projStatus === 5
                                            ? "table-cell"
                                            : "none",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        value={item.remainingwork.toFixed(2)}
                                        disabled={
                                          projStatus === 2 ||
                                          projStatus === 3 ||
                                          projStatus === 4 ||
                                          projStatus === 5
                                        }
                                        style={{
                                          width: "171px",
                                          height: "21px",
                                          paddingLeft: "3px",
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <select
                                        value={item.uomid}
                                        disabled={
                                          projStatus === 2 ||
                                          projStatus === 3 ||
                                          projStatus === 4 ||
                                          projStatus === 5
                                        }
                                        onChange={(e) => {
                                          const newData = [...dataVol];
                                          newData[index].uomid = e.target.value;
                                          setDataVol(newData);
                                        }}
                                        style={{
                                          width: "138px",
                                          height: "32px",
                                          paddingLeft: "3px",
                                        }}
                                        select
                                        variant="outlined"
                                        size="small"
                                        className={modules["dropdownSelectSub"]}
                                      >
                                        {unitNames?.map((i) => (
                                          <option value={i.id} key={i.id}>
                                            {i.unitname}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={item.originalduration.toFixed(2)}
                                        disabled={
                                          projStatus === 2 ||
                                          projStatus === 3 ||
                                          projStatus === 4 ||
                                          projStatus === 5
                                        }
                                        style={{
                                          width: "50px",
                                          height: "21px",
                                          paddingLeft: "3px",
                                        }}
                                        onChange={(e) => {
                                          const newData = [...dataVol];
                                          newData[index].originalduration =
                                            e.target.value;
                                          setDataVol(newData);
                                        }}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        display:
                                          projStatus === 2 ||
                                          projStatus === 3 ||
                                          projStatus === 4 ||
                                          projStatus === 5
                                            ? "table-cell"
                                            : "none",
                                      }}
                                      disabled={
                                        projStatus === 2 ||
                                        projStatus === 3 ||
                                        projStatus === 4 ||
                                        projStatus === 5
                                      }
                                    >
                                      {item.remainingduration.toFixed(2)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>

                        <Stack
                          direction="row"
                          spacing={2}
                          style={{ marginBottom: "1rem" }}
                        >
                          <input
                            type="checkbox"
                            checked={isCloseCheckedVol}
                            onChange={handleCheckboxChangeForClosedVol}
                            disabled={isCloseDisabledVol}
                          />
                          <Typography
                            style={{ marginLeft: "2px" }}
                            className={modules["inputCCPMSet"]}
                          >
                            Review and Close Main Task
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SubTasksAll;
