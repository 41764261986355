import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import { DBM_TOC_Item_Stock_Graph } from "../../../Services/MTA.js";
import bread from "../../../Assets/bread.png";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation, useNavigate } from "react-router-dom";
import { Line } from "react-chartjs-2";

export default function MTA_TOC_Item_Stock_Graph() {
  const location = useLocation();
  const nData = location.state?.nData;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [CData, setCData] = useState({});
  const [changed, setChanged] = useState(false);
  const [allSelected, setAllSelected] = useState("N");
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    const params = new URLSearchParams(location.search);
    const instanceid = params.get("instanceid");
    const stock_point = params.get("stock_point");
    const company_code = params.get("company_code");
    const item_code = params.get("item_code");
    const sales_statistical_group = params.get("sales_statistical_group");
    const buffer = params.get("buffer");
    const buffer_new = params.get("buffer_new");
    const proposed_action = params.get("proposed_action");
    const item_desc = params.get("item_desc");
    const rundatefrom = params.get("rundatefrom");
    const rundateto = params.get("rundateto");

    const Tdata = {
      instanceid,
      stock_point,
      company_code,
      item_code,
      sales_statistical_group,
      buffer,
      buffer_new,
      proposed_action,
      item_desc,
      rundatefrom,
      rundateto,
    };

    setCData(Tdata);
    getReportData(
      instanceid,
      stock_point,
      company_code,
      item_code,
      rundatefrom,
      rundateto
    );

    console.log(Tdata);
  }, [location]);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getReportData = (
    instanceid,
    stock_point,
    company_code,
    item_code,
    rundatefrom,
    rundateto
  ) => {
    setIsLoading(true);
    const body = {
      instance_uid: instanceid,
      stock_point: stock_point,
      company_code: company_code,
      item_code: item_code,
      run_date_from: rundatefrom,
      run_date_to: rundateto,
      downloaded_by: sessionStorage.getItem("loginUsername"),
    };
    DBM_TOC_Item_Stock_Graph(body)
      .then((response) => {
        setReportData(response?.data?.success?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const dataSet = reportData;
  //   {
  //     bpl_instance_uid: "Aerospace_Aero",
  //     bpl_stock_point_code: "FG_AERO",
  //     bpl_item_code: "26101702SD00128",
  //     i_item_desc: "KH66768 BRACKET",
  //     bpl_last_modified_on: "2020-11-01T00:00:00",
  //     bpl_stock_qty: 63,
  //     bpl_buffer_red: 16,
  //     bpl_buffer_yellow: 26,
  //     bpl_buffer: 40,
  //     tor: 16,
  //     toy: 10,
  //     tog: 14,
  //     prodr_quantity: null,
  //     bs_quantity: null,
  //   },
  //   {
  //     bpl_instance_uid: "Aerospace_Aero",
  //     bpl_stock_point_code: "FG_AERO",
  //     bpl_item_code: "26101702SD00128",
  //     i_item_desc: "KH66768 BRACKET",
  //     bpl_last_modified_on: "2020-11-02T00:00:00",
  //     bpl_stock_qty: 63,
  //     bpl_buffer_red: 16,
  //     bpl_buffer_yellow: 26,
  //     bpl_buffer: 40,
  //     tor: 16,
  //     toy: 10,
  //     tog: 14,
  //     prodr_quantity: null,
  //     bs_quantity: null,
  //   },
  //   {
  //     bpl_instance_uid: "Aerospace_Aero",
  //     bpl_stock_point_code: "FG_AERO",
  //     bpl_item_code: "26101702SD00128",
  //     i_item_desc: "KH66768 BRACKET",
  //     bpl_last_modified_on: "2020-11-03T00:00:00",
  //     bpl_stock_qty: 63,
  //     bpl_buffer_red: 16,
  //     bpl_buffer_yellow: 26,
  //     bpl_buffer: 40,
  //     tor: 16,
  //     toy: 10,
  //     tog: 14,
  //     prodr_quantity: null,
  //     bs_quantity: null,
  //   },
  //   {
  //     bpl_instance_uid: "Aerospace_Aero",
  //     bpl_stock_point_code: "FG_AERO",
  //     bpl_item_code: "26101702SD00128",
  //     i_item_desc: "KH66768 BRACKET",
  //     bpl_last_modified_on: "2020-11-04T00:00:00",
  //     bpl_stock_qty: 63,
  //     bpl_buffer_red: 16,
  //     bpl_buffer_yellow: 26,
  //     bpl_buffer: 40,
  //     tor: 16,
  //     toy: 10,
  //     tog: 14,
  //     prodr_quantity: null,
  //     bs_quantity: null,
  //   },
  //   {
  //     bpl_instance_uid: "Aerospace_Aero",
  //     bpl_stock_point_code: "FG_AERO",
  //     bpl_item_code: "26101702SD00128",
  //     i_item_desc: "KH66768 BRACKET",
  //     bpl_last_modified_on: "2020-11-05T00:00:00",
  //     bpl_stock_qty: 63,
  //     bpl_buffer_red: 16,
  //     bpl_buffer_yellow: 26,
  //     bpl_buffer: 40,
  //     tor: 16,
  //     toy: 10,
  //     tog: 14,
  //     prodr_quantity: null,
  //     bs_quantity: null,
  //   },
  //   {
  //     bpl_instance_uid: "Aerospace_Aero",
  //     bpl_stock_point_code: "FG_AERO",
  //     bpl_item_code: "26101702SD00128",
  //     i_item_desc: "KH66768 BRACKET",
  //     bpl_last_modified_on: "2020-11-06T00:00:00",
  //     bpl_stock_qty: 63,
  //     bpl_buffer_red: 16,
  //     bpl_buffer_yellow: 26,
  //     bpl_buffer: 40,
  //     tor: 16,
  //     toy: 10,
  //     tog: 14,
  //     prodr_quantity: null,
  //     bs_quantity: null,
  //   },
  //   {
  //     bpl_instance_uid: "Aerospace_Aero",
  //     bpl_stock_point_code: "FG_AERO",
  //     bpl_item_code: "26101702SD00128",
  //     i_item_desc: "KH66768 BRACKET",
  //     bpl_last_modified_on: "2020-11-07T00:00:00",
  //     bpl_stock_qty: 63,
  //     bpl_buffer_red: 16,
  //     bpl_buffer_yellow: 26,
  //     bpl_buffer: 40,
  //     tor: 16,
  //     toy: 10,
  //     tog: 14,
  //     prodr_quantity: null,
  //     bs_quantity: null,
  //   },
  //   {
  //     bpl_instance_uid: "Aerospace_Aero",
  //     bpl_stock_point_code: "FG_AERO",
  //     bpl_item_code: "26101702SD00128",
  //     i_item_desc: "KH66768 BRACKET",
  //     bpl_last_modified_on: "2020-11-08T00:00:00",
  //     bpl_stock_qty: 63,
  //     bpl_buffer_red: 16,
  //     bpl_buffer_yellow: 26,
  //     bpl_buffer: 40,
  //     tor: 16,
  //     toy: 10,
  //     tog: 14,
  //     prodr_quantity: null,
  //     bs_quantity: null,
  //   },
  //   {
  //     bpl_instance_uid: "Aerospace_Aero",
  //     bpl_stock_point_code: "FG_AERO",
  //     bpl_item_code: "26101702SD00128",
  //     i_item_desc: "KH66768 BRACKET",
  //     bpl_last_modified_on: "2020-11-09T00:00:00",
  //     bpl_stock_qty: 63,
  //     bpl_buffer_red: 16,
  //     bpl_buffer_yellow: 26,
  //     bpl_buffer: 40,
  //     tor: 16,
  //     toy: 10,
  //     tog: 14,
  //     prodr_quantity: null,
  //     bs_quantity: null,
  //   },
  // ];

  const torData = reportData?.map((data) => data.tor);
  const toyData = reportData?.map((data) => data.tor + data.toy);
  const togData = reportData?.map((data) => data.tor + data.toy + data.tog);
  const stockData = reportData?.map((data) => data.bpl_stock_qty);
  const labels = reportData?.map(
    (data) => data.bpl_last_modified_on.split("T")[0]
  );

  // const getReportChart = (
  // ) => {}

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Stock",
        data: stockData,
        borderColor: "#000000",
        backgroundColor: "#000000",
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
      },
      {
        label: "consumption",
        backgroundColor: "lightblue",
        borderColor: "lightblue",
      },
      {
        label: "receipt",
        backgroundColor: "darkblue",
        borderColor: "darkblue",
      },
      {
        label: "Tor",
        data: torData,
        backgroundColor: "red",
        borderColor: "red",
        borderWidth: 1,
        fill: true,
        pointRadius: 0,
      },
      {
        label: "Toy",
        data: toyData,
        backgroundColor: "yellow",
        borderColor: "yellow",
        borderWidth: 1,
        fill: true,
        pointRadius: 0,
      },
      {
        label: "Tog",
        data: togData,
        backgroundColor: "green",
        borderColor: "green",
        borderWidth: 1,
        fill: true,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "right",
        align: "end",
      },
      title: {
        display: true,
        text: "Z2F13T/Allan Corner Table Black", // Replace with your desired title
        font: {
          size: 20, // Adjust the font size as needed
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12}>
            <Grid>
              <Grid container className={modules["mid-cont"]}>
                <TableContainer>
                  <Table
                    className={modules["custom_table"]}
                    style={{ width: "925px" }}
                  >
                    <TableHead>
                      <TableRow style={{ borderRadius: "0px 5px" }}>
                        <TableCell
                          align="left"
                          colSpan={3}
                          style={{
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                          }}
                        >
                          MTA Reports {">>"} TOC Item Stock Graph
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                          }}
                        >
                          <span
                            style={{ padding: "0px 4px", cursor: "pointer" }}
                            onClick={toggleExpand}
                          >
                            {isExpanded
                              ? "Collapse Filters [-]"
                              : "Expand Filters [+]"}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {isExpanded && (
                      <tbody
                        className={`${modules["expand-collapse"]} ${
                          isExpanded ? modules["expanded"] : ""
                        }`}
                        style={{ border: "1px solid #a4bf47", width: "200%" }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>MTA Instance</strong>
                          </td>
                          <td colSpan="3" style={{ textAlign: "left" }}>
                            <span id="ContentPlaceHolder1_lblFilterMTA">
                              {CData.instanceid}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Stock Point</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {CData.stock_point ? CData.stock_point : "-"}
                            </span>
                          </td>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          ></td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span></span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Company Code</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {CData.company_code ? CData.company_code : "-"}
                            </span>
                          </td>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Item Code</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {CData.item_code ? CData.item_code : "-"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Report Date From</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {CData.rundatefrom
                                ? CData.rundatefrom
                                    .split("/")
                                    .reverse()
                                    .join("/")
                                : "-"}{" "}
                            </span>
                          </td>
                          <td
                            style={{
                              width: "20%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <strong>Report Date To</strong>
                          </td>
                          <td
                            style={{
                              width: "30%",
                              textAlign: "left",
                              border: "1px solid #a4bf47",
                            }}
                          >
                            <span>
                              {CData.rundateto
                                ? CData.rundateto.split("/").reverse().join("/")
                                : "-"}{" "}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container className={modules["mid-cont"]}>
                <Table
                  className={modules["custom_table"]}
                  style={{ width: "925px" }}
                >
                  <TableHead>
                    <TableRow style={{ borderRadius: "0px 5px" }}>
                      <TableCell
                        align="left"
                        colSpan={3}
                        style={{
                          borderRadius: "5px",
                        }}
                      >
                        Proposed Action {">>"} {CData.proposed_action}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
                </Table>
              </Grid>
              <div>
                {reportData ? (
                  <div className={modules["black-box"]}>
                    <div>
                      <Line data={data} options={options} />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: "2rem",
                    }}
                  >
                    No Records Found
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

// export default CombinedChart;
