import modules from "./../MTA.module.css";
import React, { useState, useEffect } from "react";
import Home from "../../../home/Home";
import { Grid, FormControl, Popover } from "@mui/material";
import {
  SP_MtaInstances,
  IOA_GetImosData,
  IOA_GetECommerceData,
  IOA_GetBPPAndColorData,
  IOA_GetPurchaseData,
  IOA_GetSalesAllocationData,
  IOA_GetProductionData,
  IOA_GetRPLData,
  IOA_GetOrderStatus,
  IOA_GetInboundOrder,
} from "../../../Services/MTA";
import bread from "../../../Assets/bread.png";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Set_NewInstance } from "../../../Services/Dss";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import window_popup from "../../../Assets/window_popup.png";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { Today } from "@mui/icons-material";

export default function InboundOrderAdvice() {
  const [data, setData] = useState({
    instname: "",
    instdesc: "",
  });

  const location = useLocation();
  const [nData, setNData] = useState(location.state?.nData || "");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInstanceId, setSelectedInstanceId] = useState("");

  const [dropdownData, setDropdownData] = useState([]);
  const [displayData2, setDisplaydate2] = useState("");
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [upLikelyDate, setUpLikelyDate] = useState("");
  const [uniqueReference, setUniqueReference] = useState("");
  const [inboundOrders, setInboundOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [selectedInboundOrders, setSelectedInboundOrders] = useState("");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [selectedReportType, setSelectedReportType] = useState("");
  const [displayData, setDisplayData] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [changed, setChanged] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const open3 = Boolean(anchorEl3);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getInstances();
    setDisplaydate2(formatDate(formattedToday));
    getInboundOrder();
    console.log(nData);
    if (nData) {
      setSelectedInstanceId(nData.instanceid);
      setSelectedInboundOrders(nData.inboundOrder);
      setSelectedOrderStatus(nData.orderStatus);
      setSelectedReportType(nData.reportType);
      setDisplaydate2(nData.sentToERP);
      setUniqueReference(nData.uniqueReference);
      GetOrderStatus();
      setTimeout(() => {
        setNData("");
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (location.state?.nData) {
      setSelectedInstanceId(location.state.nData.instanceid);
      setSelectedInboundOrders(location.state.nData.inboundOrder);
      setSelectedOrderStatus(location.state.nData.orderStatus);
      setSelectedReportType(location.state.nData.reportType);
      setDisplaydate2(location.state.nData.sentToERP);
      setUniqueReference(location.state.nData.uniqueReference);
      GetOrderStatus();
      setTimeout(() => {
        setNData("");
      }, 100);
    }

    // Clear location.state.nData on component mount (page reload)
    navigate(location.pathname, {
      replace: true,
      state: {
        ...location.state,
        nData: "",
      },
    });
  }, []);

  //   {
  //     "instanceid": "Aerospace_Aero",
  //     "inboundOrder": "RPL",
  //     "orderStatus": "",
  //     "sentToERP": "12/10/2024",
  //     "uniqueReference": "",
  //     "reportType": "Display",
  //     "currentDate": "October 12, 2024"
  // }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const getInstances = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      instance_uid: "",
      muiuid_last_modified_by: sessionStorage
        .getItem("loginUsername")
        .toLowerCase(),
    };
    if (loginId) {
      SP_MtaInstances(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setDropdownData(response?.data?.success?.data);
            setIsLoading(false);

            if (response?.data?.success?.data.length > 0) {
              const defaultInstanceId =
                response?.data?.success?.data[0]?.mta_instance_uid;
              if (location.state?.nData) {
                setSelectedInstanceId(nData.instanceid);
              } else {
                setSelectedInstanceId(defaultInstanceId);
              }
            } else if (response?.data?.success?.data.length === 0) {
              alert("You do not have access to any Active Instance in MTA");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const GetOrderStatus = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      sessionName: "Inbound Order Advice - GnB CR",
      controlParameter: "Order Status",
    };
    if (loginId) {
      IOA_GetOrderStatus(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setOrderStatus(response?.data?.success?.data);
            setIsLoading(false);
            if (response?.data?.success?.data.length > 0) {
            } else if (response?.data?.success?.data.length === 0) {
              alert("You do not have access to any Active Instance in MTA");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const getInboundOrder = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginUsername");
    const body = {
      sessionName: "Inbound Order Advice - GnB CR",
      controlParameter: "Inbound Order",
    };
    if (loginId) {
      IOA_GetInboundOrder(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setInboundOrders(response?.data?.success?.data);
            setIsLoading(false);
            if (response?.data?.success?.data.length > 0) {
            } else if (response?.data?.success?.data.length === 0) {
              alert("error");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
        });
    }
  };

  const reportTypes = [
    { name: "Excel", value: "Excel" },
    { name: "Display", value: "Display" },
  ];

  const today = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedToday = today.toLocaleDateString("en-US", options);

  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, { weekday: "short" }).slice(0, 2);
  };

  function formatDateString(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const handleCalendar3n = (date) => {
    const today = new Date();
    if (date > today) {
      alert("You cannot select a day later than today!");
      setAnchorEl3(null);
      console.log("You cannot select a day later than today!", today);
      setDisplaydate2(formatDateString(today));
      return;
    }

    setAnchorEl3(null);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    setUpLikelyDate(date);
    setDisplaydate2(formattedDate);
  };

  function convertDateToISO(dateString) {
    const date = new Date(dateString);
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return utcDate.toISOString().split("T")[0];
  }

  function convertDateToISO2(dateString) {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  }

  const navigate = useNavigate();

  function submitHandler() {
    if (selectedInboundOrders === "") {
      alert("Please Select Inbound Order");
      return;
    } else if (selectedReportType === "") {
      alert("Please Select Report Type");
      return;
    } else {
      if (selectedReportType === "Excel") {
        if (selectedInboundOrders === "RPL") {
          DownloadRPLData();
        } else if (selectedInboundOrders === "Production") {
          DownloadProductionData();
        } else if (selectedInboundOrders === "Purchase") {
          DownloadPurchaseData();
        } else if (selectedInboundOrders === "Sales Allocation") {
          DownloadSalesData();
        } else if (selectedInboundOrders === "WH - BPP and Color") {
          DownloadColorData();
        } else if (selectedInboundOrders === "eCom") {
          DownloadeComData();
        } else if (selectedInboundOrders === "Imos") {
          DownloadImosData();
        }
      } else {
        const nData = {
          instanceid: selectedInstanceId,
          inboundOrder: selectedInboundOrders,
          orderStatus: selectedOrderStatus,
          sentToERP: displayData2,
          uniqueReference: uniqueReference,
          reportType: selectedReportType,
          currentDate: formattedToday,
        };
        navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
      }
    }
  }

  function DownloadRPLData() {
    const body = {
      currentDate: convertDateToISO(formattedToday),
      instance_uid: selectedInstanceId,
      orderStatus: selectedOrderStatus,
      rploal_sent_to_erp_on: convertDateToISO2(displayData2),
      uniqueReference: uniqueReference.trim(),
    };
    setIsLoading(true);

    IOA_GetRPLData(body, selectedReportType, 1)
      .then((response) => {
        if (response?.headers["content-type"] === "text/csv") {
          console.log("Error:", response?.data);
          const blob = new Blob([response?.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "LogOfInboundOrder.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (response?.data?.statusCode === 400) {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        } else {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const nData = {
          instanceid: selectedInstanceId,
          inboundOrder: selectedInboundOrders,
          orderStatus: selectedOrderStatus,
          sentToERP: displayData2,
          uniqueReference: uniqueReference,
          reportType: selectedReportType,
          currentDate: formattedToday,
        };
        navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
      });
  }

  function DownloadProductionData() {
    const body = {
      currentDate: convertDateToISO(formattedToday),
      instance_uid: selectedInstanceId,
      orderStatus: selectedOrderStatus,
      prodoal_sent_to_erp_on: convertDateToISO2(displayData2),
      uniqueReference: uniqueReference.trim(),
    };
    setIsLoading(true);
    IOA_GetProductionData(body, selectedReportType, 1)
      .then((response) => {
        if (response?.headers["content-type"] === "text/csv") {
          console.log("Error:", response?.data);
          const blob = new Blob([response?.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "LogOfInboundOrder.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (response?.data?.statusCode === 400) {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        } else {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const nData = {
          instanceid: selectedInstanceId,
          inboundOrder: selectedInboundOrders,
          orderStatus: selectedOrderStatus,
          sentToERP: displayData2,
          uniqueReference: uniqueReference,
          reportType: selectedReportType,
          currentDate: formattedToday,
        };
        navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
      });
  }

  function DownloadPurchaseData() {
    const body = {
      currentDate: convertDateToISO(formattedToday),
      instance_uid: selectedInstanceId,
      orderStatus: selectedOrderStatus,
      prodoal_sent_to_erp_on: convertDateToISO2(displayData2),
      uniqueReference: uniqueReference.trim(),
    };
    setIsLoading(true);
    IOA_GetPurchaseData(body, selectedReportType, 1)
      .then((response) => {
        if (response?.headers["content-type"] === "text/csv") {
          console.log("Error:", response?.data);
          const blob = new Blob([response?.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "LogOfInboundOrder.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (response?.data?.statusCode === 400) {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        } else {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const nData = {
          instanceid: selectedInstanceId,
          inboundOrder: selectedInboundOrders,
          orderStatus: selectedOrderStatus,
          sentToERP: displayData2,
          uniqueReference: uniqueReference,
          reportType: selectedReportType,
          currentDate: formattedToday,
        };
        navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
      });
  }

  function DownloadSalesData() {
    const body = {
      instance_uid: selectedInstanceId,
      soaa_sales_order_allocation_status: selectedOrderStatus,
    };
    setIsLoading(true);
    IOA_GetSalesAllocationData(body, selectedReportType, 1)
      .then((response) => {
        if (response?.headers["content-type"] === "text/csv") {
          console.log("Error:", response?.data);
          const blob = new Blob([response?.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "LogOfInboundOrder.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (response?.data?.statusCode === 400) {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        } else {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const nData = {
          instanceid: selectedInstanceId,
          inboundOrder: selectedInboundOrders,
          orderStatus: selectedOrderStatus,
          sentToERP: displayData2,
          uniqueReference: uniqueReference,
          reportType: selectedReportType,
          currentDate: formattedToday,
        };
        navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
      });
  }

  function DownloadColorData() {
    const body = {
      currentDate: convertDateToISO(formattedToday),
      instance_uid: selectedInstanceId,
      orderStatus: selectedOrderStatus,
      puroal_sent_to_erp_on: convertDateToISO2(displayData2),
      uniqueReference: uniqueReference.trim(),
    };
    setIsLoading(true);
    IOA_GetBPPAndColorData(body, selectedReportType, 1)
      .then((response) => {
        if (response?.headers["content-type"] === "text/csv") {
          console.log("Error:", response?.data);
          const blob = new Blob([response?.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "LogOfInboundOrder.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (response?.response?.data?.statusCode === 400) {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        } else {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const nData = {
          instanceid: selectedInstanceId,
          inboundOrder: selectedInboundOrders,
          orderStatus: selectedOrderStatus,
          sentToERP: displayData2,
          uniqueReference: uniqueReference,
          reportType: selectedReportType,
          currentDate: formattedToday,
        };
        navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
      });
  }

  function DownloadeComData() {
    const body = {
      currentDate: convertDateToISO(formattedToday),
      instance_uid: selectedInstanceId,
      orderStatus: selectedOrderStatus,
      sent_to_ecom_on: convertDateToISO2(displayData2),
      uniqueReference: uniqueReference.trim(),
    };
    setIsLoading(true);
    IOA_GetECommerceData(body, selectedReportType, 1)
      .then((response) => {
        if (response?.headers["content-type"] === "text/csv") {
          console.log("Error:", response?.data);
          const blob = new Blob([response?.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "LogOfInboundOrder.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (response?.response?.data?.statusCode === 400) {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        } else {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const nData = {
          instanceid: selectedInstanceId,
          inboundOrder: selectedInboundOrders,
          orderStatus: selectedOrderStatus,
          sentToERP: displayData2,
          uniqueReference: uniqueReference,
          reportType: selectedReportType,
          currentDate: formattedToday,
        };
        navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
      });
  }

  function DownloadImosData() {
    const body = {
      currentDate: convertDateToISO(formattedToday),
      instance_uid: selectedInstanceId,
      orderStatus: selectedOrderStatus,
      sent_to_imos_on: convertDateToISO2(displayData2),
      uniqueReference: uniqueReference.trim(),
    };
    setIsLoading(true);
    IOA_GetImosData(body, selectedReportType, 1)
      .then((response) => {
        if (response?.headers["content-type"] === "text/csv") {
          console.log("Error:", response?.data);
          const blob = new Blob([response?.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "LogOfInboundOrder.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (response?.data?.statusCode === 400) {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        } else {
          const nData = {
            instanceid: selectedInstanceId,
            inboundOrder: selectedInboundOrders,
            orderStatus: selectedOrderStatus,
            sentToERP: displayData2,
            uniqueReference: uniqueReference,
            reportType: selectedReportType,
            currentDate: formattedToday,
          };
          navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const nData = {
          instanceid: selectedInstanceId,
          inboundOrder: selectedInboundOrders,
          orderStatus: selectedOrderStatus,
          sentToERP: displayData2,
          uniqueReference: uniqueReference,
          reportType: selectedReportType,
          currentDate: formattedToday,
        };
        navigate("/ccpm/InboundOrderAdviceData", { state: { nData } });
      });
  }

  function resetHandler() {
    setSelectedInstanceId("");
    setSelectedInboundOrders("");
    setSelectedOrderStatus("");
    setSelectedReportType("");
    setDisplaydate2(formatDate(formattedToday));
    setUniqueReference("");
  }

  const handleCheckboxChange = (
    checked,
    instanceId,
    dbm_stock_point_code,
    dbm_company_code,
    dbm_proposed_action,
    dbm_item_code
  ) => {
    const newRow = {
      instanceUid: instanceId,
      stockPointCode: dbm_stock_point_code,
      companyCode: dbm_company_code,
      proposed_action: dbm_proposed_action + " - Take Action",
      itemCode: dbm_item_code,
    };

    if (checked) {
      // Add the object to the selected rows
      setSelectedRows((prevRows) => [...prevRows, newRow]);
    } else {
      // Remove the object if unchecked
      setSelectedRows((prevRows) =>
        prevRows.filter((row) => row.instanceUid !== instanceId)
      );
    }

    console.log(selectedRows, "sr");
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      const filteredRows = tableData.filter(
        (row) =>
          row.dbm_proposed_action === "Increase" ||
          row.dbm_proposed_action === "Decrease"
      );

      const allRows = filteredRows.map((row) => ({
        instanceUid: row.dbm_instance_uid,
        stockPointCode: row.dbm_stock_point_code,
        companyCode: row.dbm_company_code,
        proposed_action: row.dbm_proposed_action,
        itemCode: row.dbm_item_code,
      }));
      setSelectedRows(allRows);
      console.log(allRows, "allRows");
      setAllSelected(true);
    } else {
      setSelectedRows([]);
      setAllSelected(false);
      console.log(selectedRows);
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container className={modules["primary_cont"]}>
        <Grid
          item
          lg={0.6}
          className={modules["main_cont"]}
          sx={{ position: "absolute" }}
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"InboundOrderAdvice"} />
          </Grid>
          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div
                className={modules["pro-bread"]}
                style={{ marginLeft: "-2.5rem" }}
              >
                <a>MTA Parameters</a>
                <img src={bread} alt="" />
                <a>Inbound Order Advice - GnB CR</a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <table
                className={modules["customTable"]}
                style={{ marginLeft: "3rem" }}
              >
                <tbody>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "180px", fontWeight: "bold" }}>
                      MTA Instance <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedInstanceId}
                          onChange={(e) => {
                            setSelectedInstanceId(e.target.value);
                          }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          // disabled={editing}
                        >
                          {/* <option value={"SELECT"}>SELECT</option> */}
                          {dropdownData.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.mta_instance_uid}
                                key={i.id}
                                data-id={i.id}
                              >
                                {i.mta_instance_uid}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "180px", fontWeight: "bold" }}>
                      Inbound Order <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedInboundOrders}
                          onChange={(e) => {
                            setSelectedInboundOrders(e.target.value);
                            GetOrderStatus();
                          }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          // disabled={editing}
                        >
                          <option value={"SELECT"}>SELECT</option>
                          {inboundOrders.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.control_value}
                                key={i.control_value}
                              >
                                {i.control_value}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                    <td
                      style={{
                        width: "180px",
                        fontWeight: "bold",
                        marginLeft: "100px",
                      }}
                    >
                      Order Status
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedOrderStatus}
                          onChange={(e) => {
                            setSelectedOrderStatus(e.target.value);
                          }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          // disabled={editing}
                        >
                          <option value={"SELECT"}>SELECT</option>
                          {orderStatus.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.control_value}
                                key={i.control_value}
                              >
                                {i.control_value}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "180px", fontWeight: "bold" }}>
                      Sent to ERP/eCom On Date{" "}
                      <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <>
                        <input
                          style={{ marginLeft: "20px", width: "250px" }}
                          maxLength="20"
                          value={displayData2}
                          onChange={(e) => setDisplaydate2(e.target.value)}
                          className={modules["input_css"]}
                          onClick={(event) => setAnchorEl3(event.currentTarget)}
                          readOnly
                        />
                        <Popover
                          open={open3}
                          anchorEl={anchorEl3}
                          onClose={() => setAnchorEl3(null)}
                          placement="bottom-start"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Calendar
                            onChange={handleCalendar3n}
                            value={upLikelyDate}
                            locale="en-US"
                            formatShortWeekday={formatShortWeekday}
                          />
                          <div className={modules["calendar-footer"]}>
                            Today: {formattedToday}
                          </div>
                        </Popover>
                      </>
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "180px", fontWeight: "bold" }}>
                      Unique Reference
                    </td>
                    <td>:</td>
                    <td>
                      <input
                        value={uniqueReference}
                        onChange={(e) => setUniqueReference(e.target.value)}
                        onInput={(e) => {
                          setUniqueReference(e.target.value);
                        }}
                        // onBlur={(e) => {
                        //   const regex2 = /^[a-zA-Z0-9_-]*$/;
                        //   if (!regex2.test(e.target.value)) {
                        //     alert(
                        //       "Only alphanumeric characters are valid in this field."
                        //     );
                        //     setUniqueReference("");
                        //   }
                        // }}
                        className={modules["input_css"]}
                        style={{ marginLeft: "20px", width: "250px" }}
                        maxLength={50}
                      />
                    </td>
                  </tr>
                  <tr
                    style={{
                      paddingLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <td style={{ width: "180px", fontWeight: "bold" }}>
                      Report Type <label style={{ color: "Red" }}>*</label>
                    </td>
                    <td>:</td>
                    <td>
                      <FormControl sx={{ marginLeft: "20px" }}>
                        <select
                          value={selectedReportType}
                          onChange={(e) => {
                            setSelectedReportType(e.target.value);
                          }}
                          className={modules["d_InstanceSelect"]}
                          style={{ width: "268px" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          // disabled={editing}
                        >
                          <option value={"SELECT"}>SELECT</option>
                          {reportTypes.map((i) => {
                            return (
                              <option
                                className={
                                  modules["instanceAttMap_dropdownSelect_menu"]
                                }
                                value={i.value}
                                key={i.value}
                              >
                                {i.value}
                              </option>
                            );
                          })}
                        </select>
                      </FormControl>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Grid
                container
                align="center"
                style={{
                  marginTop: "0rem",
                  justifyContent: "center",
                  marginRight: "6rem",
                }}
              >
                <button className={modules["btn1"]} onClick={submitHandler}>
                  Submit
                </button>
                <button
                  className={modules["btn1"]}
                  style={{ marginLeft: "20px" }}
                  onClick={resetHandler}
                >
                  Reset
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
