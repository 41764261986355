import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import SD1 from "../HelpPopups/images/SD1.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const SettingsDownloadhelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h5 style={{ textAlign: "left", textDecoration: "underline" }}>
                                Settings Download
                            </h5>
                            <br />
                            <br />


                            <li className="no-bullets" style={{ textDecoration: "underline" }}>
                                Path `{">>"}` MTA `{">>"}` MTA Parameter `{">>"}` Settings Download
                            </li>
                            <br />

                            <li className="no-bullets">
                                Setting download is used when user need to create the master for in out to setting download. When we download the file then file format is downloaded to the serve path in .csv format. We can see file in MTA _Output in your instance as per following path
                            </li>
                            <br />
                            <li className="no-bullets">
                                Setting download is used by user to get the correct format of the parameter fields to upload in Prostream in .csv format. Along with the existing/current format it will also download the data available in Prostream. We can see downloaded file in MTA _Output for the selected instance.
                            </li>
                            <br />
                            <li className="no-bullets">
                                {/* {'\\prostprofilesrv\MTA_Output\<Instance Name>

'} */}

                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={SD1} alt="SD1" />
                            </Stack>
                            <br />
                            <li>
                                MTA Instance – Select your MTA Instance
                            </li>
                            <li>
                                Parameters – This field indicates that various masters. Select parameter from drop down available.
                            </li>
                            <li>
                                Download – File will be downloaded to server path. Path mentioned above.
                            </li>
                            <br />
                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SettingsDownloadhelpPopup;
