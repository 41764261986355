import axios from "axios";
import {
  API_KEY,
  getActiveProjectsProjectName,
  getActiveProjectsProjectManagers,
  searchActiveProject,
  ActiveProjectstatus,
  ActiveProjectstatusChange,
  ManualRunBm,
  ActiveSaveImgClick,
  AllRunBm,
  runBMURL,
  projectStatusChange,
  projectStatus,
  runBMAllURL
} from "../Constants/index.js";

export async function getProjectName(data) {
  try {
    const response = await axios.post(
      `${API_KEY}${getActiveProjectsProjectName}`,
      data,
      {}
    );
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}

export async function getProjectManagers(data) {
  try {
    const response = await axios.post(
      `${API_KEY}${getActiveProjectsProjectManagers}`,
      data,
      {}
    );
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}
export async function searchActiveProjectDetails(data) {
  try {
    const response = await axios.post(
      `${API_KEY}${searchActiveProject}`,
      data,
      {}
    );
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}
export async function ActiveProjectStatus(data) {
  try {
    const response = await axios.post(
      `${API_KEY}${ActiveProjectstatus}`,
      data,
      {}
    );
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}
export async function ActiveProjectStatusChange(data) {
  try {
    const response = await axios.post(
      `${API_KEY}${ActiveProjectstatusChange}`,
      data,
      {}
    );
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}
export async function ActiveProjectStatusChangeRsn(data) {
  try {
    const response = await axios.post(
      `${API_KEY}${ActiveProjectstatusChange}`,
      data,
      {}
    );
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}

export async function ManulRunmBtnClick(data) {
  try {
    const response = await axios.post(`${API_KEY}${ManualRunBm}`, data, {});
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}
// export async function AllRunmBtnClick(data) {
//   try {
//     const response = await axios.post(`${API_KEY}${AllRunBm}`, data, {
//     });
//     return response.data;
//   } catch (error) {
//     throw error; // Let the caller handle the error
//   }
// }
export async function AllRunmBtnClick(body) {
  return await axios
    .post(`${API_KEY}${AllRunBm}`, body)
    .then((res) => {
      console.log("gbnm,", res);
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SaveOnImgClick(data) {
  try {
    const response = await axios.post(
      `${API_KEY}${ActiveSaveImgClick}`,
      data,
      {}
    );
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}

export async function RunBm(data) {
  try {
    const response = await axios.post(`${API_KEY}${runBMURL}`, data, {});
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}

export async function GetProjectStatus(data) {
  try {
    const response = await axios.post(`${API_KEY}${projectStatus}`, data, {});
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}
export async function GetProjectStatusChange(data) {
  try {
    const response = await axios.post(
      `${API_KEY}${projectStatusChange}`,
      data,
      {}
    );
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}

export async function RunBmAll(data) {
  try {
    const response = await axios.post(`${API_KEY}${runBMAllURL}`, data, {
    });
    return response.data;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
}