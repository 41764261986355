import React, { useState, useRef, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";

import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  TextareaAutosize,

  Select,
  MenuItem,
} from "@mui/material";
import { Link } from 'react-router-dom';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../../Landing/Navbar";
// import modules from "./ProductConfig.module.css";
import modules from "./../ccpm.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SampleSidebar from "../../../Landing/SampleSidebar";
import {
  GetCompanyNameDS,
  GetRootLevel,
  GetSubRootLevel,
  GetHierarchyLevelDS,
  GetLevelNamesDS,
  GetDependentLevelDS,
  GetLevelDetails,
  PC_RowUpdate,
  PC_RowDelete,
  PC_InstanceNames,
  PC_SaveAll,
} from "../../../Services/CCPM.js";
import loading from "../../../Assets/Loading_image.gif";
import axios from "axios";

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "22px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}

const LevelComponent = ({
  level,
  expandedLevels,
  handleExpand,
  subLevels,
  levelDepth,
}) => {
  return (
    <li
      key={level.levelnos}
      style={{
        marginTop: "1.5rem",
        position: "relative",
        listStyle: "none",
        paddingLeft: "1.5rem", // Space between parent and child
      }}
    >
      {/* Expand/Collapse button */}
      {level.childnodecount > 0 && (
        <button
          onClick={() => handleExpand(level.levelnos)}
          style={{ marginRight: "8px" }}
        >
          {expandedLevels[level.levelnos] ? "-" : "+"}
        </button>
      )}

      {/* Node Name */}
      <span style={{ color: "#0000EE", fontSize: "12px", cursor: "pointer" }}>
        {level.levelname}
      </span>

      {/* Render sub-levels if expanded */}
      {expandedLevels[level.levelnos] && subLevels[level.levelnos] && (
        <ul
          style={{
            position: "relative",
            paddingLeft: "1rem", // Adjusted indent
            margin: "10px 6px",
            borderLeft: "1px solid black", // Vertical line connecting parent to children
          }}
        >
          {subLevels[level.levelnos].map((subLevel, index, array) => (
            <li
              key={subLevel.levelnos}
              style={{
                position: "relative",
                marginBottom: "10px",
                paddingLeft: "0.5rem",
              }}
            >
              {/* Horizontal line to siblings */}
              {index !== array.length - 1 && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%", // Adjusted to align horizontally
                    left: "-20px", // Shift to align with the parent
                    width: "20px",
                    borderBottom: "1px solid black",
                  }}
                />
              )}

              {/* Vertical line to child */}
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "-20px", // Align vertical line with the parent
                  height: "100%",
                  borderLeft: "1px solid black",
                }}
              />

              {/* Recursive call to render sub-levels */}
              <LevelComponent
                level={subLevel}
                expandedLevels={expandedLevels}
                handleExpand={handleExpand}
                subLevels={subLevels}
                levelDepth={levelDepth + 1}
              />
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};



const ProductConfig = () => {
  const [hierarchyLevel, setHierarchyLevel] = useState([]);
  const [levelName, setLevelName] = useState([]);
  const [drop1, setDrop1] = useState(false);
  const [levelDetails, setLevelDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getCompName();
    getLevelNames();
    getLevelDetails();
  }, []);

  const [compName, setCompName] = useState("Godrej");
  const [userAuth, setUserAuth] = useState(null);
  const [isReleaseControlReq, setIsReleaseControlReq] = useState(null);
  const [isTaktUnitReq, setIsTaktUnitReq] = useState(null);
  const [isMilestoneReq, setIsMilestoneReq] = useState(null);
  const [isErpIntegrationReq, setIsErpIntegrationReq] = useState(null);
  const [initialValues, setInitialValues] = useState({}); // To store initial values

  const getCompName = async () => {
    setIsLoading(true);
    GetCompanyNameDS().then((response) => {
      setIsLoading(false);
      if (response?.data?.success?.data[0]) {
        const companyData = response.data.success.data[0];

        setCompName("Godrej");
        setUserAuth(companyData.userauthentication);
        setIsReleaseControlReq(companyData.isreleasecontrolreq);
        setIsTaktUnitReq(companyData.istaktunitreq);
        setIsMilestoneReq(companyData.ismilestonereq);
        setIsErpIntegrationReq(companyData.iserpintegrationreq);

         // Set initial values
         const initialReleaseControl = companyData.isreleasecontrolreq;
         const initialTaktUnit = companyData.istaktunitreq;
         const initialMilestone = companyData.ismilestonereq;
         const initialErpIntegration = companyData.iserpintegrationreq;
         const initialUserAuth = companyData.userauthentication;
         const initialCompName = "Godrej";
 
         // Set both initial values and state
         setInitialValues({
           releaseControl: initialReleaseControl,
           taktUnit: initialTaktUnit,
           milestone: initialMilestone,
           erpIntegration: initialErpIntegration,
            userAuth: initialUserAuth,
            compName: initialCompName,
         });
 
         setIsReleaseControlReq(initialReleaseControl);
         setIsTaktUnitReq(initialTaktUnit);
         setIsMilestoneReq(initialMilestone);
         setIsErpIntegrationReq(initialErpIntegration);
          setUserAuth(initialUserAuth);
          setCompName(initialCompName);

        console.log(
          companyData.isreleasecontrolreq,
          "Release Control Required"
        );
        console.log(companyData.istaktunitreq, "Takt Unit Required");
        console.log(companyData.ismilestonereq, "Milestone Required");
        console.log(
          companyData.iserpintegrationreq,
          "ERP Integration Required"
        );
      }
    });
  };

  // const getHierarchyLevel = () => {
  //   GetHierarchyLevelDS().then((response) => {
  //     // console.log(response?.data?.success?.data, "hierarchy level name");
  //     setHierarchyLevel(response?.data?.success?.data);
  //   });
  // };

  const getLevelNames = () => {
    setIsLoading(true);
    GetLevelNamesDS().then((response) => {
      setIsLoading(false);
      let levelNames = response?.data?.success?.data;
      const levelNos = levelNames?.map((item) => item.levelnoid);
      setLevelName(levelNames);
    });
  };

  const getLevelDetails = () => {
    setIsLoading(true);
    GetLevelDetails().then((response) => {
      setIsLoading(false);
      setLevelDetails(response.data.success.data);
      console.log(response.data.success.data, "levelDetails");
    });
  };

  function opendrop() {
    setDrop1(!drop1);
  }

  const [selectedValue, setSelectedValue] = useState("Instance level");
  // Update the selected radio value based on user authentication
  useEffect(() => {
    if (userAuth === 1) {
      setSelectedValue("Instance level");
    } else if (userAuth === 2) {
      setSelectedValue("Attribute level");
    }
  }, [userAuth]);

  const [rootLevels, setRootLevels] = useState([]); // Root levels
  const [expandedLevels, setExpandedLevels] = useState({}); // Track expanded state
  const [subLevels, setSubLevels] = useState({}); // Store sub-level data separately

  useEffect(() => {
    // Fetch the root levels when the component mounts
    const fetchRootLevels = () => {
      setIsLoading(true);
      GetRootLevel()
        .then((response) => {
          setIsLoading(false);
          console.log(response.data.success?.data, "Fetched Root Levels");
          setRootLevels(response.data.success?.data);
        })
        .catch((error) => {
          console.error("Error fetching root levels:", error);
        });
    };

    fetchRootLevels();
  }, []);

  const handleExpand = (levelno) => {
    console.log(`Toggling expand/collapse for level ${levelno}`);

    setExpandedLevels((prev) => {
      const newState = {
        ...prev,
        [levelno]: !prev[levelno], // Toggle the expanded state
      };
      console.log("Expanded Levels after toggle:", newState);
      return newState;
    });

    // Fetch sub-levels only if the level is being expanded and sub-levels haven't been fetched yet
    if (!expandedLevels[levelno] && !subLevels[levelno]) {
      setIsLoading(true);
      console.log(`Fetching sub-levels for level ${levelno}`);

      GetSubRootLevel({ dependentHierarchyId: levelno })
        .then((response) => {
          setIsLoading(false);
          console.log(
            response.data.success.data,
            `Fetched Sub-Levels for level ${levelno}`
          );

          if (response.status === 200) {
            setSubLevels((prev) => ({
              ...prev,
              [levelno]: response.data.success.data,
            }));
            console.log("Sub-Levels after fetching:", {
              ...subLevels,
              [levelno]: response.data.success.data,
            });
          }
        })
        .catch((error) => {
          console.error(
            `Error fetching sub-levels for level ${levelno}:`,
            error
          );
        });
    }
  };

  const [editRowId, setEditRowId] = useState(null); // Track the currently editing row
  const [editLevelName, setEditLevelName] = useState("");
  const [editDependsOn, setEditDependsOn] = useState(0);
  const [selectedLevels, setSelectedLevels] = useState({});

  useEffect(() => {
    const initialSelectedLevels = {};
    levelDetails.forEach((item) => {
      if (item.dependson) {
        initialSelectedLevels[item.id1] = `Level ${item.dependson}`; // Set default based on dependson
      }
    });
    setSelectedLevels(initialSelectedLevels);
  }, [levelDetails]);

  // Handle level selection
  const handleSelectLevel = (id1, level) => {
    setSelectedLevels((prev) => ({
      ...prev,
      [id1]: level.levelName, // Update the selected level for the specific id1
    }));
  };

  // Your existing function to get levels for each item
  const getDependsOnLevels = (dependson, id1) => {
    if (id1 === 0 || id1 == null) return []; // In case id1 is invalid

    const levels = [];
    // Loop from 1 to id1 - 1 to include all previous levels
    for (let i = 1; i < id1; i++) {
      levels.push({
        levelName: `Level ${i}`,
      });
    }

    return levels;
  };

  const [originalLevels, setOriginalLevels] = useState({});

  // Handle Edit Click
  const handleEditClick = (item, element) => {
    element.style.color = "red"; // Change color to red on click
    setTimeout(() => {
      setExpandedLevels({}); // Collapse all expanded levels when entering edit mode
      setSubLevels({}); // Clear sub-levels when entering

      setEditRowId(item.id1); // Enable edit mode for the selected row
      setEditLevelName(item.levelname); // Set current level name in the input

      setEditRowId(item.id1); // Enter edit mode
      setOriginalLevels((prev) => ({
        ...prev,
        [item.id1]: selectedLevels[item.id1], // Store the current level for the item
      }));
      element.style.color = "#0000EE"; // Revert color to original after delay
    }, 100);
  };

  // Handle Cancel Click
  // Handle Cancel Click
  const handleCancel = (item, element) => {
    // Change the element's color to red
    element.style.color = "red";

    // Set a timeout to revert the color back to blue (#0000EE) after a brief delay
    setTimeout(() => {
      setEditRowId(null); // Exit edit mode
      setEditLevelName(""); // Reset the level name
      setSelectedLevels((prev) => ({
        ...prev,
        [item.id1]: originalLevels[item.id1], // Reset to the original level
      }));

      // Revert the element's color to the original blue
      element.style.color = "#0000EE";
    }, 100); // You can adjust the delay if needed
  };


  // Handle Update Click
  const handleUpdate = (item, element) => {
    setIsLoading(true);
    element.style.color = "red"; // Change color to red on click
    const updatedDependsOn = editDependsOn || item.dependson;
    setTimeout(() => {
      const payload = {
        levelName: editLevelName,
        id1: item.id1,
        id2: item.id2,
        dependentHierarchyId: [updatedDependsOn],
        createdBy: sessionStorage.getItem("loginId"), // Assuming this comes from session storage
      };

      // Call API to update the level
      PC_RowUpdate(payload)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            alert("Details Updated Successfully");

            // Only reset editing state and refresh the table after a successful update
            setEditRowId(null); // Exit edit mode
            setEditLevelName(""); // Reset the input field

            // Fetch updated data to refresh the table
            getLevelDetails(); // Refresh the data after update
          } else {
            alert("Failed to update the details.");
          }
        })
        .catch((error) => {
          console.error("Error updating the details:", error);
        })
        .finally(() => {
          element.style.color = "#0000EE"; // Revert color back after process finishes
        });
    }, 100);
  };

  const handleLevelClick = (levelName) => {
    // Extract the integer from the levelName string
    const levelId = parseInt(levelName.split(" ")[1]); // Split by space and take the second part
    setEditDependsOn(levelId); // Update the dependsOn value when a level is clicked
  };

  //handle Delete

  const [selectedRows, setSelectedRows] = useState([]); // Store selected row IDs

  // Handle checkbox selection
  const handleCheckboxChange = (id1) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.includes(id1)) {
        return prevSelected.filter((id) => id !== id1); // Deselect if already selected
      } else {
        return [...prevSelected, id1]; // Select the row
      }
    });
  };

  // Handle Delete Click
  // Handle Delete Click
  const handleDeleteClick = () => {
    if (selectedRows.length === 0) {
      alert("Please select records to delete..");
      return;
    }

    // Alert to confirm deletion with the number of records
    const confirmation = window.confirm(
      `Do you want to delete ${selectedRows.length} records?`
    );

    if (confirmation) {
      const highestLevel = Math.max(...levelDetails.map((item) => item.id1)); // Get the highest level present in the table

      // Check if any selected row is not the last level
      const hasHigherLevel = selectedRows.some((selectedRowId) => {
        return levelDetails.some(
          (row) => row.id1 === selectedRowId && row.id1 < highestLevel
        );
      });

      if (hasHigherLevel) {
        alert("Please Delete Lower level First.");
      } else {
        // Proceed with the API call as the selected rows are at the last level
        const payload = { id: selectedRows.length > 0 ? selectedRows : [0] }; // Prepare payload

        PC_RowDelete(payload)
          .then(() => {
            alert("Details deleted successfully");
            getLevelDetails(); // Refresh data
            console.log("Rows deleted:", payload);
            setSelectedRows([]); // Clear selected rows after deletion
            getLevelDetails();
          })
          .catch((error) => {
            console.error("Error deleting rows:", error);
          });
      }
    }
  };

  const [feverChartOptions, setFeverChartOptions] = useState([]);
  const [selectedFeverChart, setSelectedFeverChart] = useState(null);

  // State hooks for customer mapping
  const [customerMappingOptions, setCustomerMappingOptions] = useState([]);
  const [selectedCustomerMapping, setSelectedCustomerMapping] = useState(null);

  // State hooks for other mappings...
  const [procurementCategoryOptions, setProcurementCategoryOptions] = useState(
    []
  );
  const [selectedProcurementCategory, setSelectedProcurementCategory] =
    useState(null);

  const [delayMasterOptions, setDelayMasterOptions] = useState([]);
  const [selectedDelayMaster, setSelectedDelayMaster] = useState(null);

  const [resourceGroupOptions, setResourceGroupOptions] = useState([]);
  const [selectedResourceGroup, setSelectedResourceGroup] = useState(null);

  const [resourceMasterOptions, setResourceMasterOptions] = useState([]);
  const [selectedResourceMaster, setSelectedResourceMaster] = useState(null);

  const [calendarMappingsOptions, setCalendarMappingsOptions] = useState([]);
  const [selectedCalendarMapping, setSelectedCalendarMapping] = useState(null);

  const [wipLimitMappingsOptions, setWipLimitMappingsOptions] = useState([]);
  const [selectedWipLimitMapping, setSelectedWipLimitMapping] = useState(null);

  // Generic fetch function
  const fetchMappingData = (variable, setOptions, setSelected) => {
    const body = { variable };
    PC_InstanceNames(body)
      .then((response) => {
        console.log(`${setOptions.name} API Response:`, response);
        const fetchedData = response.data.success.data;
        setOptions(fetchedData);

        if (fetchedData.length > 0) {
          setSelected(fetchedData[0].levelnoid); // Set default selection
        }
      })
      .catch((error) => {
        console.error(`Error fetching ${setOptions.name} data:`, error);
      });
  };

  useEffect(() => {
    // Fetch data for each mapping
    fetchMappingData(1, setFeverChartOptions, setSelectedFeverChart); // Fever Chart
    fetchMappingData(2, setCustomerMappingOptions, setSelectedCustomerMapping); // Customer Mapping
    fetchMappingData(
      3,
      setProcurementCategoryOptions,
      setSelectedProcurementCategory
    ); // Procurement Category
    fetchMappingData(4, setDelayMasterOptions, setSelectedDelayMaster); // Delay Master Mapping
    fetchMappingData(5, setResourceGroupOptions, setSelectedResourceGroup); // Resource Group Mapping
    fetchMappingData(6, setResourceMasterOptions, setSelectedResourceMaster); // Resource Master Mapping
    fetchMappingData(7, setCalendarMappingsOptions, setSelectedCalendarMapping); // Calendar Mappings
    fetchMappingData(
      10,
      setWipLimitMappingsOptions,
      setSelectedWipLimitMapping
    ); // WIP Limit Mappings
  }, []);

  const handleSaveAll = () => {
    const isRowValid = newRow.newId1 && newRow.newLevelName; // Check if the new row has valid data
    setExpandedLevels({}); // Collapse all expanded levels when entering edit mode
    setSubLevels({}); // Clear sub-levels when entering

    // Confirmation alert before hitting the API
    const confirmation = window.confirm(
      "Are you sure you want to update details?"
    );


    if (confirmation) {
      setIsLoading(true);
      const body = {
        companyname: "Godrej",
        userauthentication: userAuth,
        dateFormatId: 0,
        isReleaseControlReq: isReleaseControlReq,
        isTAKTUnitReq: isTaktUnitReq,
        isMilestoneReq: isMilestoneReq,
        isERPIntegrationReq: isErpIntegrationReq,
        levelName: isRowValid ? newRow.newLevelName : "", // Set levelName based on row validity
        levelNo: isRowValid ? newRow.newId1 : 0, // Set levelNo based on row validity
        isFeverCordinatesMapping: selectedFeverChart,
        isCustMapping: selectedCustomerMapping,
        isProcurementCatMapping: selectedProcurementCategory,
        isDelayMasterMapping: selectedDelayMaster,
        isResourceGroupMapping: selectedResourceGroup,
        isCalendarMapping: selectedCalendarMapping,
        isResourceMasterMapping: selectedResourceMaster,
        isPipelineMapping: 0,
        isWithoutResource: 0,
        isWIPMapping: selectedWipLimitMapping,
        createdBy: sessionStorage.getItem("loginId"),
        hierarchyDependencies: isRowValid // Set hierarchyDependencies based on row validity
          ? [
            {
              companyName: "Godrej",
              hierarchyId: newRow.newId1,
              dependentHierarchyId: newRow.newDependson,
              levelName: newRow.newLevelName,
              createdBy: sessionStorage.getItem("loginId"),
            },
          ]
          : [],
      };

      PC_SaveAll(body)
        .then((response) => {
          console.log("Save All Response:", response);
          if (response.status === 200) {
            alert("Details Updated successfully");
            setIsLoading(false);
            setNewRow({ newId1: null, newLevelName: "", newDependson: [] });
            getLevelDetails();
            getLevelNames();
            getCompName();
          } else {
            alert("Failed to save details");
          }
        })
        .catch((error) => {
          console.error("Error saving details:", error);
        });
    } else {
      console.log("Update cancelled by user");
    }
  };

  //Adding a new row

  const [newRow, setNewRow] = useState({
    newId1: null,
    newLevelName: "",
    newDependson: [],
  });
  const [newRowSelectedLevel, setNewRowSelectedLevel] = useState({});
  const [isAddingRow, setIsAddingRow] = useState(false); // Track if a row is being added

  const handleAddClick = () => {
    const lastId =
      levelDetails?.length > 0
        ? Math.max(...levelDetails.map((item) => item.id1))
        : 0;
    setNewRow({ newId1: lastId + 1, newLevelName: "", newDependson: [] }); // Prepare a new row
    setIsAddingRow(true); // Indicate that a new row is being added
  };

  // Handle level selection for the new row (depends on logic)
  // Handle level selection for the new row (depends on logic)
  const handleSelectNewRowLevel = (id1, level) => {
    const levelNumber = parseInt(level.levelName.replace("Level ", ""), 10); // Extract the numeric part

    setNewRowSelectedLevel((prev) => ({
      ...prev,
      [id1]: level.levelName, // Store the selected level name for this id1
    }));

    // Update dependson value in newRow
    setNewRow((prevRow) => ({
      ...prevRow,
      newDependson: levelNumber, // Store the numeric level number in newDependson
    }));
  };

  // Get levels for the new row based on its id1
  const getNewRowDependsOnLevels = (id1) => {
    if (id1 === 0 || id1 == null) return []; // In case id1 is invalid

    const levels = [];
    // Loop from 1 to id1 - 1 to include all previous levels
    for (let i = 1; i < id1; i++) {
      levels.push({
        levelName: `Level ${i}`,
      });
    }

    return levels;
  };

  const handleCancelClick = () => {
    setNewRow({ newId1: null, newLevelName: "", newDependson: [] });
    setIsAddingRow(false); // Indicate that the new row is canceled

    if (editRowId !== null) {
    setEditRowId(null); // Exit edit mode
    setEditLevelName(""); // Clear the edited level name

    // Restore the original level name for the currently edited row
    setSelectedLevels((prev) => ({
      ...prev,
      [editRowId]: originalLevels[editRowId], // Reset to original name
    }));
  }
  };

  const handleReset = () => {
    setCompName("Godrej");
    setUserAuth(initialValues.userAuth);
    setIsReleaseControlReq(initialValues.releaseControl);
    setIsTaktUnitReq(initialValues.taktUnit);
    setIsMilestoneReq(initialValues.milestone);
    setIsErpIntegrationReq(initialValues.erpIntegration);
    // Reset all selected values to their default based on the options
  setSelectedFeverChart(feverChartOptions.length > 0 ? feverChartOptions[0].levelnoid : null);
  setSelectedCustomerMapping(customerMappingOptions.length > 0 ? customerMappingOptions[0].levelnoid : null);
  setSelectedProcurementCategory(procurementCategoryOptions.length > 0 ? procurementCategoryOptions[0].levelnoid : null);
  setSelectedDelayMaster(delayMasterOptions.length > 0 ? delayMasterOptions[0].levelnoid : null);
  setSelectedResourceGroup(resourceGroupOptions.length > 0 ? resourceGroupOptions[0].levelnoid : null);
  setSelectedResourceMaster(resourceMasterOptions.length > 0 ? resourceMasterOptions[0].levelnoid : null);
  setSelectedCalendarMapping(calendarMappingsOptions.length > 0 ? calendarMappingsOptions[0].levelnoid : null);
  setSelectedWipLimitMapping(wipLimitMappingsOptions.length > 0 ? wipLimitMappingsOptions[0].levelnoid : null);
  }



  const handleResetDuplicate = () => {
    setCompName("Godrej");
    setUserAuth(initialValues.userAuth);
  }
 
  const handleBlur = () => {
    // Define a regular expression for the special characters
    const specialChars = /[&()\[\]\-_=+./]/g;


    // Check if the input value contains any of the special characters
    if (specialChars.test(compName)) {
      alert("No special characters allowed");
    }
    setCompName(compName.replace(specialChars, "")); // Remove the special characters
   
  };

  const handleKeyDown = (e) => {
    
    // Allowed characters: letters, numbers, spaces, and specific special characters
    const allowedChars = /[a-zA-Z0-9 &()\[\]\-_=+./]/;
  
    // If the key pressed does not match the allowed characters, prevent the input
    if (!allowedChars.test(e.key)) {
      e.preventDefault(); // Block the keypress
     
    }
  };
  

  

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"ProductConfig"} />

            <Grid item lg={12}>
              <div className={modules["instancemaster_divPC"]}>
                <div className={modules["pro-bread"]}>
                  <a>Master</a>
                  <img src={bread} alt="" />
                  <a>Product Configuration Settings</a>
                </div>
              </div>

              <Grid
                container
                sx={{ marginLeft: "8.5rem" }}
                className={modules["maincontainer"]}
              >
                <Grid container>
                  <Grid container sx={{width:'85%'}} className={modules["Prodbtn_cont"]}>
                    <button
                      onClick={handleSaveAll}
                      style={{ cursor: "pointer" }}
                      className={modules["Prodbtn1"]}>Save All</button>
                   
                    <button 
                    className={modules["Prodbtn2"]}  
                    style={{ cursor: "pointer" }}
                    onClick={handleResetDuplicate}>Reset</button>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    sx={{ fontSize: "12px", color: "#414141" }}
                  >
                    <strong> Company Name: </strong>
                    <input
                      value={compName}
                      onChange={(e) => setCompName(e.target.value)}
                      onBlur={handleBlur} // Add the onBlur event
                      onKeyDown={handleKeyDown} // Add the onKeyDown event
                      className={modules["Prodinput_css"]}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ marginTop: "2rem", marginBottom: "1.5rem",letterSpacing:'0.3px' }}
                >
                  <Grid item sx={{ fontSize: "12px", color: "#414141" ,letterSpacing:'0.3px'}}>
                    <strong>User Authentications:</strong>
                  </Grid>
                  <Grid item sx={{ marginLeft: "5rem" }}>
                    <FormControl sx={{ marginTop: "-0.2rem" }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={
                          userAuth === 1
                            ? "With Company WebService (AD)"
                            : "Without Company WebService (SQL)"
                        }
                        onChange={(e) =>
                          setUserAuth(
                            e.target.value === "With Company WebService (AD)"
                              ? 1
                              : 0
                          )
                        }
                      >
                        <FormControlLabel
                          value="With Company WebService (AD)"
                          control={
                            <Radio
                              disableRipple
                              color="primary"
                              sx={{ transform: "scale(0.8)", padding: "0",marginLeft:'90px' }}
                            />
                          }
                          label="With Company WebService (AD)"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "12px",
                              marginRight: "5px",
                              
                              color: "#414141",
                            },
                          }}
                        />
                        <FormControlLabel
                          value="Without Company WebService (SQL)"
                          control={
                            <Radio
                              disableRipple
                              color="primary"
                              sx={{ transform: "scale(0.8)", padding: "0" }}
                            />
                          }
                          label="Without Company WebService (SQL)"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "12px",
                              color: "#414141",
                            },
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container>
                  <div className={modules["table-containerPC"]}>
                    <Table className={modules["custom-tablePC"]}>
                      <TableHead>
                        <TableRow>
                          <th></th>
                          <th>Sr. No.</th>
                          <th>Hierarchy Level</th>
                          <th>Levels Name</th>
                          <th>Depends On</th>
                          <th></th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {levelDetails
                          ?.sort((a, b) => a.id1 - b.id1) // Sort by id1 in ascending order
                          .map((item, index) => (
                            <TableRow key={item.id1}>
                              <td>
                                <input
                                  type="checkbox"
                                  className={modules["custom-checkbox"]}
                                  checked={selectedRows.includes(item.id1)} // Check if row is selected
                                  onChange={() =>
                                    handleCheckboxChange(item.id1)
                                  } // Handle selection change
                                  disabled={item.id1 === 1} // Disable checkbox if id1 is 1
                                />
                              </td>
                              <td>{index + 1}</td>
                              <td>{item.hierarchylevel}</td>
                              <td  className={modules["table-cell"]}>
                                {editRowId === item.id1 ? ( // Check if the row is being edited
                                  <input
                                    value={editLevelName}
                                    onChange={(e) => setEditLevelName(e.target.value)} // Update level name on change
                                    variant="outlined"
                                    size="small"
                                  />
                                ) : (
                                  item.id1 === 1 ? ( // Check if it's the row with id1
                                    item.levelname // Show level name normally for item.id1
                                  ) : (
                                    <a
                                      href="/ccpm/instancemaster" // Link to the target path for other rows
                                      style={{
                                        textDecoration: 'underline', // Keep underline for link
                                        color: '#551A8B', // Use the desired text color
                                        cursor: 'pointer', // Show pointer cursor on hover
                                      }}
                                    >
                                      {item.levelname} 
                                    </a>
                                  )
                                )}
                              </td>

                              <td>
                                <select
                                  size="2"
                                  className={modules["dropdownSelect_ProfC"]}
                                >
                                  {getDependsOnLevels(
                                    item.dependson,
                                    item.id1
                                  ).map((level, index) => (
                                    <option
                                      value={index}
                                      key={index}
                                      onClick={() => {
                                        if (editRowId === item.id1) {
                                          handleSelectLevel(item.id1, level); // Only allow selection when in edit mode
                                          handleLevelClick(level.levelName);
                                        }
                                      }}
                                      selected={
                                        selectedLevels[item.id1] ===
                                        level.levelName
                                      }
                                      style={{
                                        padding: "0 2px 1px 2px",
                                        backgroundColor:
                                          selectedLevels[item.id1] ===
                                            level.levelName
                                            ? "#CECECE" // Highlight the selected level
                                            : "transparent", // Default background
                                        color:
                                          selectedLevels[item.id1] ===
                                            level.levelName
                                            ? "#101010"
                                            : "#000000", // Change text color based on selection
                                        borderRadius: "2px",

                                      }}
                                    >
                                      {level.levelName}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              <td>
                                {editRowId === item.id1 && item.id1 !== 1 ? ( // Disable edit action for id1 = 1
                                  <>
                                    <a
                                      style={{
                                        paddingRight: "1rem",
                                        cursor: "pointer",
                                        color: "#0000EE",
                                      }}
                                      onClick={(e) =>
                                        handleUpdate(item, e.currentTarget)
                                      }
                                    >
                                      Update
                                    </a>
                                    <a
                                      style={{
                                        cursor: "pointer",
                                        color: "#0000EE",
                                      }}
                                      onClick={(e) => handleCancel(item, e.target)} // Pass the DOM element (e.target) to the function
                                    >
                                      Cancel
                                    </a>
                                  </>
                                ) : item.id1 === 1 ? ( // Show static "Edit" text when id1 is 1
                                  <span
                                    style={{ color: "grey", cursor: "default" }}
                                  >
                                    Edit
                                  </span>
                                ) : (
                                  <a
                                    style={{
                                      paddingRight: "1rem",
                                      cursor: "pointer",
                                      color: "#0000EE",
                                    }}
                                    onClick={(e) =>
                                      handleEditClick(item, e.currentTarget)
                                    } // Allow editing for other rows
                                  >
                                    Edit
                                  </a>
                                )}
                              </td>
                            </TableRow>
                          ))}

                        {isAddingRow && newRow.newId1 && (
                          <TableRow key={newRow.newId1}>
                            <td></td>
                            <td>{levelDetails.length + 1}</td>{" "}
                            {/* Adjust the index for the new row */}
                            <td></td>
                            <td className={modules["table-cell"]}>
                              <input
                                value={newRow.newLevelName}
                                onChange={(e) =>
                                  setNewRow({
                                    ...newRow,
                                    newLevelName: e.target.value,
                                  })
                                } // Update the new level name
                                variant="outlined"
                                size="small"
                              />
                            </td>
                            <td>
                              <select
                                size="2"
                                className={modules["dropdownSelect_ProfC"]}
                              >
                                {getNewRowDependsOnLevels(newRow.newId1).map(
                                  (level) => (
                                    <option
                                      value={level.levelName}
                                      key={level.levelName}
                                      onClick={() =>
                                        handleSelectNewRowLevel(
                                          newRow.newId1,
                                          level
                                        )
                                      } // Handle selection for new row
                                      style={{
                                        padding: "0 2px 1px 2px",
                                        backgroundColor:
                                          newRowSelectedLevel[newRow.newId1] ===
                                            level.levelName
                                            ? "#CECECE"
                                            : "transparent",
                                        color:
                                          newRowSelectedLevel[newRow.newId1] ===
                                            level.levelName
                                            ? "#101010"
                                            : "#000000  ",
                                        borderRadius: "2px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {level.levelName}
                                    </option>
                                  )
                                )}
                              </select>
                            </td>
                            <td> </td>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
               
                <Grid container className={modules["btn2-cont"]}>
                  <button
                    onClick={handleAddClick}
                    style={{ marginRight: "5px", cursor: "pointer",height:'0.75cm' }}
                  >
                    Add
                  </button>
                  <button
                    style={{ marginRight: "5px", cursor: "pointer",height:'0.75cm' }}
                    onClick={handleDeleteClick}
                  >
                    Delete
                  </button>
                  <button
                    style={{ cursor: "pointer" ,height:'0.75cm'}}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                </Grid>

                <div
                  style={{ marginTop: "1rem" }}
                  className={modules["hierarchy"]}
                >
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "#0000EE",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    Company Hierarchy
                  </a>
                  <ul
                    style={{
                      marginLeft: "2rem",
                      listStyle: "none",
                      padding: "0",
                    }}
                  >
                    {rootLevels.map((level) => (
                      <LevelComponent
                        key={level.levelnos}
                        level={level}
                        expandedLevels={expandedLevels}
                        handleExpand={handleExpand}
                        subLevels={subLevels}
                        levelDepth={0} // Start depth at 0 for root levels
                      />
                    ))}
                  </ul>
                </div>

                <Grid container sx={{ marginTop: "1.5rem" }}>
                  <div className={modules["table-containerPC2"]}>
                    <Table className={modules["custom-row-table"]}>
                      <TableHead>
                        <TableRow>
                          <th colSpan={3}>Dependencies and Mappings</th>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong>
                                Fever Chart Co-Ordinate Mapping With Levels
                              </strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl
                              className={modules["form-control"]}
                              onClick={opendrop}
                            >
                              
                              <select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelectPC"]}
                                value={selectedFeverChart}
                                onChange={(e) =>
                                  setSelectedFeverChart(e.target.value)
                                }
                              >
                                {levelName?.map((i) => {
                                  return (
                                      <option
                                      value={i.levelnoid}
                                      key={i.levelname}
                                    >
                                      {i.levelname}
                                    </option>
                                  );
                                })}
                              </select>
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong>Customer Mapping With Levels</strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl
                              className={modules["form-control"]}
                              onClick={opendrop}
                            >
                              <select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelectPC"]}
                                value={selectedCustomerMapping}
                                onChange={(e) =>
                                  setSelectedCustomerMapping(e.target.value)
                                }
                              >
                                {levelName?.map((i) => {
                                  return (
                                    <option
                                      value={i.levelnoid}
                                      key={i.levelname}
                                    >
                                      {i.levelname}
                                    </option>
                                  );
                                })}
                              </select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong>
                                Procurement Category With Mapping Levels
                              </strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl
                              className={modules["form-control"]}
                              onClick={opendrop}
                            >
                              <select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelectPC"]}
                                value={selectedProcurementCategory}
                                onChange={(e) =>
                                  setSelectedProcurementCategory(e.target.value)
                                }
                              >
                                {levelName?.map((i) => {
                                  return (
                                    <option
                                      value={i.levelnoid}
                                      key={i.levelname}
                                    >
                                      {i.levelname}
                                    </option>
                                  );
                                })}
                              </select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong> Delay Master Mapping With Levels</strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl
                              className={modules["form-control"]}
                              onClick={opendrop}
                            >
                              <select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelectPC"]}
                                value={selectedDelayMaster}
                                onChange={(e) =>
                                  setSelectedDelayMaster(e.target.value)
                                }
                              >
                                {levelName?.map((i) => {
                                  return (
                                    <option
                                      value={i.levelnoid}
                                      key={i.levelname}
                                    >
                                      {i.levelname}
                                    </option>
                                  );
                                })}
                              </select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong>
                                {" "}
                                Resource Group Mapping With Levels
                              </strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl
                              className={modules["form-control"]}
                              onClick={opendrop}
                            >
                              <select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelectPC"]}
                                value={selectedResourceGroup}
                                onChange={(e) =>
                                  setSelectedResourceGroup(e.target.value)
                                }
                              >
                                {levelName?.map((i) => {
                                  return (
                                    <option
                                      value={i.levelnoid}
                                      key={i.levelname}
                                    >
                                      {i.levelname}
                                    </option>
                                  );
                                })}
                              </select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong>
                                Resource Master Mapping With Levels
                              </strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl
                              className={modules["form-control"]}
                              onClick={opendrop}
                            >
                              <select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelectPC"]}
                                value={selectedResourceMaster}
                                onChange={(e) =>
                                  setSelectedResourceMaster(e.target.value)
                                }
                              >
                                {levelName?.map((i) => {
                                  return (
                                    <option
                                      value={i.levelnoid}
                                      key={i.levelname}
                                    >
                                      {i.levelname}
                                    </option>
                                  );
                                })}
                              </select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong>Calendar Mappings With Levels</strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl
                              className={modules["form-control"]}
                              onClick={opendrop}
                            >
                              <select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelectPC"]}
                                value={selectedCalendarMapping}
                                onChange={(e) =>
                                  setSelectedCalendarMapping(e.target.value)
                                }
                              >
                                {levelName?.map((i) => {
                                  return (
                                    <option
                                      value={i.levelnoid}
                                      key={i.levelname}
                                    >
                                      {i.levelname}
                                    </option>
                                  );
                                })}
                              </select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong>WIP Limit Mappings With Levels</strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl
                              className={modules["form-control"]}
                              onClick={opendrop}
                            >
                              <select
                                IconComponent={ArrowLine}
                                className={modules["dropdownSelectPC"]}
                                value={selectedWipLimitMapping}
                                onChange={(e) =>
                                  setSelectedWipLimitMapping(e.target.value)
                                }
                              >
                                {levelName?.map((i) => {
                                  return (
                                    <option
                                      value={i.levelnoid}
                                      key={i.levelname}
                                    >
                                      {i.levelname}
                                    </option>
                                  );
                                })}
                              </select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong>Release Control Required</strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl sx={{ marginLeft: "1rem" }}>
                              <RadioGroup
                                row
                                value={isReleaseControlReq === 1 ? "Yes" : "No"}
                                onChange={(e) =>
                                  setIsReleaseControlReq(
                                    e.target.value === "Yes" ? 1 : 0
                                  )
                                }
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={
                                    <Radio
                                      disableRipple
                                      color="primary"
                                      sx={{
                                        transform: "scale(0.8)",
                                        padding: "0",
                                      }}
                                    />
                                  }
                                  label="Yes"
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "12px",
                                    },
                                  }}
                                />
                                <FormControlLabel
                                  value="No"
                                  control={
                                    <Radio
                                      disableRipple
                                      color="primary"
                                      sx={{
                                        transform: "scale(0.8)",
                                        padding: "0",
                                      }}
                                    />
                                  }
                                  label="No"
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "12px",
                                    },
                                  }}
                                />
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong>Takt Unit Required</strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl sx={{ marginLeft: "1rem" }}>
                              <RadioGroup
                                row
                                value={isTaktUnitReq === 1 ? "Yes" : "No"}
                                onChange={(e) =>
                                  setIsTaktUnitReq(
                                    e.target.value === "Yes" ? 1 : 0
                                  )
                                }
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={
                                    <Radio
                                      disableRipple
                                      color="primary"
                                      sx={{
                                        transform: "scale(0.8)",
                                        padding: "0",
                                      }}
                                    />
                                  }
                                  label="Yes"
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "12px",
                                    },
                                  }}
                                />
                                <FormControlLabel
                                  value="No"
                                  control={
                                    <Radio
                                      disableRipple
                                      color="primary"
                                      sx={{
                                        transform: "scale(0.8)",
                                        padding: "0",
                                      }}
                                    />
                                  }
                                  label="No"
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "12px",
                                    },
                                  }}
                                />
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            {" "}
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong>Milestone Required</strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl sx={{ marginLeft: "1rem" }}>
                              <RadioGroup
                                row
                                value={isMilestoneReq === 1 ? "Yes" : "No"}
                                onChange={(e) =>
                                  setIsMilestoneReq(
                                    e.target.value === "Yes" ? 1 : 0
                                  )
                                }
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={
                                    <Radio
                                      disableRipple
                                      color="primary"
                                      sx={{
                                        transform: "scale(0.8)",
                                        padding: "0",
                                      }}
                                    />
                                  }
                                  label="Yes"
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "12px",
                                    },
                                  }}
                                />
                                <FormControlLabel
                                  value="No"
                                  control={
                                    <Radio
                                      disableRipple
                                      color="primary"
                                      sx={{
                                        transform: "scale(0.8)",
                                        padding: "0",
                                      }}
                                    />
                                  }
                                  label="No"
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "12px",
                                    },
                                  }}
                                />
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        <TableRow className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            {" "}
                            <span
                              style={{ fontSize: "12px", color: "#414141" }}
                            >
                              <strong>ERP Integration Required</strong>
                            </span>
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            :
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            <FormControl sx={{ marginLeft: "1rem" }}>
                              <RadioGroup
                                row
                                value={isErpIntegrationReq === 1 ? "Yes" : "No"}
                                onChange={(e) =>
                                  setIsErpIntegrationReq(
                                    e.target.value === "Yes" ? 1 : 0
                                  )
                                }
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={
                                    <Radio
                                      disableRipple
                                      color="primary"
                                      sx={{
                                        transform: "scale(0.8)",
                                        padding: "0",
                                      }}
                                    />
                                  }
                                  label="Yes"
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "12px",
                                    },
                                  }}
                                />
                                <FormControlLabel
                                  value="No"
                                  control={
                                    <Radio
                                      disableRipple
                                      color="primary"
                                      sx={{
                                        transform: "scale(0.8)",
                                        padding: "0",
                                      }}
                                    />
                                  }
                                  label="No"
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "12px",
                                    },
                                  }}
                                />
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
                <Grid
                  container
                  style={{ marginBottom: "0.5rem" }}
                  className={modules["btn2-cont"]}
                >
                  <button
                    onClick={handleSaveAll}
                    style={{ marginRight: "5px", cursor: "pointer",height:'0.75cm' }}
                  >
                    Save All
                  </button>
                  <button onClick= {handleReset}   style={{ cursor: "pointer",height:'0.75cm' }}>Reset</button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ProductConfig;
