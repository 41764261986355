import React from "react";

import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import calender from "../../../Assets/calendar.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import modules from "./MAUCReportTracker.module.css";

import 'react-calendar/dist/Calendar.css';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
//import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import DatePicker, { Calendar } from "react-modern-calendar-datepicker";
import Calendar from 'react-calendar';
import { MaucReportTrackGetDropdowns,MaucReportTrackSubmit ,DS_GetAllInstanceHandler} from "../../../Services/Dss";

export default function MAUCReportTracker() {

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarToOpen, setCalendarToOpen] = useState(false);

  const [fromdate,setFromdate] =useState(new Date());
  const [todate,setTodate] =useState(new Date());

  const [displayfromdate,setdisplayfromdate]=useState('')
  const [displaytodate,setdisplaytodate]=useState('')

  const [dropOne, setDropOne] = useState({
    value: 'select',
    dropDown: []
})

const [matcatDrop, setMatcatdrop] = useState({
  value: 'select',
  dropDown: []
})

const [subsourceDrop, setSubsourcedrop] = useState({
  value: 'select',
  dropDown: []
})

const [costcatDrop, setCostcatdrop] = useState({
  value: 'select',
  dropDown: []
})

const [filetypedrop, setFiletypedrop] = useState({
  value: 'select',
  dropDown: ['CSV','XLS']
})

const [reporttypedrop, setReporttypedrop] = useState({
  value: 'select',
  dropDown: []
})

const [datafreqdrop, setDatafreqdrop] = useState({
  value: 'select',
  dropDown: []
})


  const handleFromDateChange = date => {
    console.log(date.toString())
    setFromdate(date)
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    console.log(`${day}/${month}/${year}`)
    setdisplayfromdate(`${day}/${month}/${year}`)
    setCalendarOpen(false)
  };

  const handleToDateChange = date => {
    console.log(date.toString())
    setTodate(date)
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    console.log(`${day}/${month}/${year}`)
    setdisplaytodate(`${day}/${month}/${year}`)
    setCalendarToOpen(false)
  };

  const toggleCalendar = () => {
    setCalendarOpen((prevOpen) => !prevOpen);
  };

  const toggleToCalendar = () => {
    setCalendarToOpen((prevOpen) => !prevOpen);
  };




  useEffect(() => {
    DS_GetAllInstanceHandler('pritpwc').then(res => {
        setDropOne({
            dropDown: res.data,
            value: res.data[0].instanceuid
        })
        MaucReportTrackGetDropdowns(res.data[0].instanceuid,'pritpwc').then(response => {
          console.log(response)
          setMatcatdrop({
            ...matcatDrop,
            dropDown: response?.data?.success?.data['MaterialCategory DropDown'],
        })

        setSubsourcedrop({
          ...subsourceDrop,
          dropDown: response?.data?.success?.data['SubSource DropDown'],
      })

      setCostcatdrop({
        ...costcatDrop,
        dropDown: response?.data?.success?.data['CostCategory DropDown'],
    })

   

  setReporttypedrop({
    ...reporttypedrop,
    dropDown: response?.data?.success?.data['ReportType DropDown'],
})

setDatafreqdrop({
  ...datafreqdrop,
  dropDown: response?.data?.success?.data['DataFrequency DropDown'],
})
    
      }).catch(err => {
        console.log('Error while fetching data:', err)
    })
        
    }).catch(err => {
      console.log('Error while fetching data:', err)
  })
}, [])

function InstanceChangeHandler(value) {
  setDropOne({
      ...dropOne,
      value: value
  });
}

function MatcatChangeHandler(value) {
  setMatcatdrop({
      ...matcatDrop,
      value: value
  });
}

function SubSourceChangeHandler(value) {
  setSubsourcedrop({
      ...subsourceDrop,
      value: value
  });
}

function CostCatChangeHandler(value) {
  setCostcatdrop({
      ...costcatDrop,
      value: value
  });
}

function FileTypeChangeHandler(value) {
  setFiletypedrop({
      ...filetypedrop,
      value: value
  });
}

function ReportTypeChangeHandler(value) {
  setReporttypedrop({
      ...reporttypedrop,
      value: value
  });
}

function DataFreqChangeHandler(value) {
  setDatafreqdrop({
      ...datafreqdrop,
      value: value
  });
}

function SubmitHandler(){
  !dropOne.value ? alert('Select Instance uid') :
  !displayfromdate ? alert('Select Date From') :
  !displaytodate ? alert('Select Date To') :
  costcatDrop.value==='select' ? alert('Select Cost Category') :
  reporttypedrop.value==='select' ? alert('Select Report Type') :
  datafreqdrop.value==='select' ? alert('Select Data Frequency') :
  (() => {
  const fromparts = displayfromdate.split('/');
  const toparts= displaytodate.split('/')
  let convertedfromdate= `${fromparts[2]}-${fromparts[1]}-${fromparts[0]}`
  let convertedtodate= `${toparts[2]}-${toparts[1]}-${toparts[0]}`
    //instanceID,userId,matCat,subSource,costCat,reportType,dataFreq,fileType,report,dateFrom,dateTo
    MaucReportTrackSubmit(dropOne.value,'pritpwc', matcatDrop.value!=='select'?matcatDrop.value:''  , subsourceDrop.value!=='select'?subsourceDrop.value:'',costcatDrop.value,reporttypedrop.value,datafreqdrop.value,filetypedrop.value!=='select'?filetypedrop.value:'','-1',convertedfromdate,convertedtodate).then(res => {
        // MD_SubmitHandler('Lockss_LN', 'DSS_t_invoice_data', 'Index').then(res => {
       
        if (res?.status === 200) {
          console.log(res)
          alert(res?.data?.success?.message)
            
        }
        if (res?.response?.status === 400) {
          console.log(res)
          alert(res?.response?.data?.error?.message)
            
        }
    }).catch(err => {
        console.log('Error while fetching data:', err);
    })
})()


}




  function ArrowLine() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "2px",
            height: "30px",
            backgroundColor: "grey",
            marginRight: "2px",
          }}
        />
        <ArrowDropDownIcon sx={{ marginRight: "15px", color: "#9d9d9c" }} />
      </div>
    );
  }
  return (
    <>
      <Grid>
        <Grid
          item
          lg={0.6}
          sx={{ position: "fixed", zIndex: 2000 }}
          className="main-cont"
        >
          <SampleSidebar />
        </Grid>
        <Grid item lg={11.4} sx={{ display: "block" }}>
          <Grid item lg={12} className="home-cont">
            <Home />
          </Grid>
        </Grid>
        <Grid item lg={9} className={modules["MAUCreporttracker_div"]}>
          <div className="pro-bread" style={{ marginLeft: "5rem" }}>
            <p>
              <a>DSS MAUC</a>
              <img src={bread} alt="" />
              <a>MAUC Report Tracker</a>
            </p>
          </div>
        </Grid>

        <Grid sx={{ marginLeft: "5rem", marginTop: "2rem" }}>
          <table className={modules["custom-table"]}>
            <tbody>
              <tr>
                <td>
                  DSS Instance<span style={{ color: "red" }}>*</span>
                </td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                  <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={dropOne.value}
                      onChange={(e) => InstanceChangeHandler(e.target.value)}
                    >
                      {dropOne?.dropDown?.map((i) => {
                                                return (
                                                    <MenuItem value={i.instanceuid} key={i.instanceuid}>
                                                        {i.instanceuid}
                                                    </MenuItem>
                                                );
                                            })}
                    </Select>
                  </FormControl>
                </td>
              </tr>

              <tr>
                <td>
                  Date From<span style={{ color: "red" }}>*</span>
                </td>
                <td> : </td>
                <td>
                  <div>
                    <input
                      className={modules["datepicker"]}
                      type="text"
                      readOnly
                      value={displayfromdate}
                      placeholder="dd/MM/yyyy"
                      onChange={(e) => setdisplayfromdate(e.target.value)}
                    />
                    <img
                      className={modules["calender"]}
                      src={calender}
                      alt="calendar"
                      onClick={toggleCalendar}    
                    />
                    {calendarOpen && (
                      <div className={modules["fromcalendar"]}>
                      <Calendar
                     
                      onChange={handleFromDateChange}
                      value={fromdate}
                      />
                      </div>
            
            )}
                  </div>
                </td>

                <td>
                  Date To<span style={{ color: "red" }}>*</span>
                </td>
                <td> : </td>
                <td>
                  <div>
                    <input
                      className={modules["datepicker"]}
                      type="text"
                      value={displaytodate}
                      onChange={(e) => setdisplaytodate(e.target.value)}
                      placeholder="dd/MM/yyyy"
                      readOnly
                     
                    />
                    <img
                      className={modules["calender"]}
                      src={calender}
                      alt="calendar missing"
                      onClick={toggleToCalendar}   
                      
                    />
                    {calendarToOpen && (
                      <div className={modules["fromcalendar"]}>
                      <Calendar
                     
                      onChange={handleToDateChange}
                      value={todate}
                      />
                      </div>
            
            )}
                     
                  </div>
                </td>
              </tr>

              <tr>
                <td>Material Category</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                  <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={matcatDrop.value}
                      onChange={(e) => MatcatChangeHandler(e.target.value)}
                    >
                      <MenuItem value='select'>SELECT</MenuItem>
                      {matcatDrop?.dropDown?.map((i) => {
                                                return (
                                                    <MenuItem value={i.displayvalue} key={i.displayvalue}>
                                                        {i.columnvalue}
                                                    </MenuItem>
                                                );
                                            })}
                    </Select>
                  </FormControl>
                </td>

                <td>Sub Source</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                  <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={subsourceDrop.value}
                      onChange={(e) => SubSourceChangeHandler(e.target.value)}
                    >
                      <MenuItem value='select'>SELECT</MenuItem>
                      {subsourceDrop?.dropDown?.map((i) => {
                                                return (
                                                  <MenuItem value={i.displayvalue} key={i.displayvalue}>
                                                      {i.columnvalue}
                                                  </MenuItem>
                                              );
                                            })}
                    </Select>
                  </FormControl>
                </td>
              </tr>

              <tr>
                <td>
                  Cost Category<span style={{ color: "red" }}>*</span>
                </td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                  <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={costcatDrop.value}
                      onChange={(e) => CostCatChangeHandler(e.target.value)}
                    >
                      <MenuItem value='select'>SELECT</MenuItem>
                      {costcatDrop?.dropDown?.map((i) => {
                                                return (
                                                  <MenuItem value={i.displayvalue} key={i.displayvalue}>
                                                      {i.columnvalue}
                                                  </MenuItem>
                                              );
                                            })}
                    </Select>
                  </FormControl>
                </td>

                <td>File Type</td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                  <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={filetypedrop.value}
                      onChange={(e) => FileTypeChangeHandler(e.target.value)}
                    >
                      <MenuItem value='select'>SELECT</MenuItem>
                      {filetypedrop?.dropDown?.map((i) => {
                                                return (
                                                    <MenuItem value={i} key={i}>
                                                        {i}
                                                    </MenuItem>
                                                );
                                            })}
                    </Select>
                  </FormControl>
                </td>
              </tr>

              <tr>
                <td>
                  Report Type<span style={{ color: "red" }}>*</span>
                </td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                  <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={reporttypedrop.value}
                      onChange={(e) => ReportTypeChangeHandler(e.target.value)}
                    >
                      <MenuItem value='select'>SELECT</MenuItem>
                      {reporttypedrop?.dropDown?.map((i) => {
                                                return (
                                                  <MenuItem value={i.displayvalue} key={i.displayvalue}>
                                                      {i.columnvalue}
                                                  </MenuItem>
                                              );
                                            })}
                    </Select>
                  </FormControl>
                </td>

                <td>
                  Data Frequency<span style={{ color: "red" }}>*</span>
                </td>
                <td> : </td>
                <td>
                  <FormControl className={modules["form-control"]}>
                  <Select
                      IconComponent={ArrowLine}
                      className={modules["dropdownSelect"]}
                      value={datafreqdrop.value}
                      onChange={(e) => DataFreqChangeHandler(e.target.value)}
                    >
                      <MenuItem value='select'>SELECT</MenuItem>
                      {datafreqdrop?.dropDown?.map((i) => {
                                                return (
                                                  <MenuItem value={i.displayvalue} key={i.displayvalue}>
                                                      {i.columnvalue}
                                                  </MenuItem>
                                              );
                                            })}
                    </Select>
                  </FormControl>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>

        <div>
          <button
            style={{
              marginLeft: "17.2rem",
              marginTop: "1.2rem",
              padding: "0.5cm",
              marginBottom: "1.6rem",
            }}
            type="button"
            className={modules["btn1"]}
            onClick={SubmitHandler}
          >
            Submit
          </button>
        </div>
      </Grid>
    </>
  );
}
