import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  TextField,
  Stack,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../../Landing/Navbar";
// import modules from "./CustomerMaster.module.css";
import modules from "../ccpm.module.css";
import SampleSidebar from "../../../Landing/SampleSidebar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  APCR_AddReason,
  APCR_UpdData,
  APCR_GetResons,
  APCR_InstanceNames,
  APCR_DelData,

} from "../../../Services/CCPM";
import save from "../../../Assets/save.png";
import loading from "../../../Assets/Loading_image.gif";

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
          cursor: "pointer",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px", cursor: "pointer" }} />
    </div>
  );
}

const ActiveProjectStatusChange = () => {
  const [searchCat, setSearchCat] = useState("");
  const [addCat, setAddCat] = useState("");
  const [dropdownData, setDropdownData] = useState([]);
  const [mainDropdownData, setMainDropdownData] = useState([]);
  const [displayvalue, setDisplayValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [editing, setEditing] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [editReasonName, setEditReasonName] = useState("");
  const [failedCheck, setFailedCheck] = useState(false);
  const [isEntered, setIsEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const checkLoginAndRedirect = () => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      window.location.href = "/";
    }
  };
  const disallowedCharsRegex = /[&_()\-=+./]/;

  


  useEffect(() => {
    setIsLoading(true);
    checkLoginAndRedirect();
    let body = {
      id: sessionStorage.getItem("loginId"),
    };

    APCR_InstanceNames(body)
      .then((response) => {
        if (response.status === 200) {
          const apiData = response.data;
          setIsLoading(false);
          const data = apiData.success.data;
          const names = data.map((item) => item.name);
          setDropdownData(names);
          setMainDropdownData(data);
          if (names.length > 0) {
            setDisplayValue(names[0]);
            setSelectedId(data[0].id);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event) => {
    const selectedName = event.target.value;
    const selectedItem = mainDropdownData.find(
      (item) => item.name === selectedName
    );
    setShowTable(false);
    setFailedCheck(false);
    setDisplayValue(selectedName);
    setSelectedId(selectedItem.id);
  };

  const handleSearch = () => {
    setShowTable(false);
    let body = {
      instanceId: selectedId,
      reason: searchCat !== "" ? searchCat : "",
    };

    APCR_GetResons(body)
      .then((response) => {
        if (response.status === 200) {
          setFailedCheck(false);
          const apiData = response.data;
          const data = apiData.success.data;
          if (data.length === 0) {
            setShowTable(false);
            setFailedCheck(true);
          } else {
            setFilteredData(data);
            setShowTable(true);
          }
        } else {
          setFailedCheck(true);
        }
      })
      .catch((error) => {
        setShowTable(false);
        setFailedCheck(true);
        console.log(error);
      });
  };

  const handleCatAdd = () => {
    if (addCat === "") {
      alert("Please Enter Active Project Status Change Reason to be Added.");
    } else {
      let body = {
        instanceId: selectedId,
        reason: addCat,
        createdBy: sessionStorage.getItem("loginId"),
      };
      APCR_AddReason(body)
        .then((response) => {
          if (response.status === 200) {
            alert("Details Inserted Succesfully");
            handleSearch();
            setAddCat("");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleReset = () => {
    setSearchCat("");
    setFilteredData([]);
    setShowTable(false);
    setFailedCheck(false);
    setSelectedId(mainDropdownData[0]?.id || null);
    setDisplayValue(dropdownData[0] || '');
  };

  const handleEdit = (item, element) => {
    element.style.color = "red";
    setTimeout(() => {
    setEditing(item.reason);
    setEditReasonName(item.reason);
    element.style.color = "#0000EE";
    }, 100);
  };

  const handleUpdate =(item, element)=> {
    element.style.color = "red";
    setTimeout(() => {
      element.style.color = "#0000EE";
      setTimeout(() => {
    if (item.reason === editReasonName) {
      alert(
        "No Update: Active Project Status Change Reason already Exists for Selected Instance.."
      );
      return;
    } else {
      let body = {
        id: item.id,
        instanceId: selectedId,
        reason: editReasonName,
        lastModifiedBy: sessionStorage.getItem("loginId"),

      };
      APCR_UpdData(body)
        .then((response) => {
          if (response.status === 200) {
            handleSearch();
            setEditing(null);
            alert(response.data.success.message);
          } else if (response?.response?.status === 400) {
            alert(response?.response?.data?.message);
            // alert("Delay Reason already Exists for Selected Instance.");
          } else {
            setEditing(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    element.style.color = "#0000EE";
  }, 50);
}, 100);
  };

  const handleDelete = (item, element) => {
    element.style.color = "red";
    setTimeout(() => {
    const confirmDelete = window.confirm(`Are you sure you want to delete?`);

    if (confirmDelete) {
      let body = {
        id: item.id,
        lastModifiedBy: sessionStorage.getItem("loginId"),
      };
      APCR_DelData(body)
        .then((response) => {
          if (response.status === 200) {
            handleSearch();
            alert("Details Deleted Successfully");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }else {
      element.style.color = "#0000EE";
    }
  }, 100);
  };

  const handleCancel = (element) => {
    element.style.color = "red";
    setTimeout(() => {
    setEditing(null);
    setEditReasonName("");
    element.style.color = "#0000EE";
  }, 100);
  };

  const searchCatBlur = () => {
    if (isEntered) {
      if (disallowedCharsRegex.test(searchCat)) {
        alert("No special characters allowed");
        setSearchCat(""); // Clear the input if invalid
      }
    }
  };

  function handleItemEnter(e) {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior (e.g., form submission)
      setIsEntered(false);
      if (!/^[a-zA-Z0-9&()\-=+./]*$/.test(searchCat)) {
        alert("No special characters allowed");
        setSearchCat(""); // Clear the input if invalid
      }
    }
  }

  const handleKeyDown = (e) => {
    
    // Allowed characters: letters, numbers, spaces, and specific special characters
    const allowedChars =/^[a-zA-Z0-9&_()\-=+./]*$/;
  
    // If the key pressed does not match the allowed characters, prevent the input
    if (!allowedChars.test(e.key)) {
      e.preventDefault(); // Block the keypress
     
    }
  };

  // const handleInputChange = (e) => {
  //   const value = e.target.value;
  //   if (allowedCharsRegex.test(value)) {
  //     setSearchCat(value);
  //   } else {
  //     // Remove invalid characters from input
  //     setSearchCat(value.replace(/[^&()\-=+./]/g, ""));
  //   }
  // };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const sanitizedText = pastedText.replace(/[^&()\-=+./]/g, "");
    setSearchCat(searchCat + sanitizedText);
  };

  return (
    <>
     {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"ActiveProjectStatusChangeReason"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Master</a>
                <img src={bread} alt="" />
                <a> Active Project Status Change Reason </a>
              </div>
            </div>

            <Grid container className={modules["mid-cont"]}>
              <Grid container columnSpacing={{ xs: 2 }}
              style={{ alignItems: "center" }}>
              <Grid
                  item
                  className={modules["label-style"]}
                  style={{ alignItems: "center", marginTop: "0px" }}
                >
                  <strong>Instance</strong> <span>:</span>{" "}
                  <span style={{ color: "red" }}>*</span>
                </Grid>
                <Grid item>
                  <FormControl className={modules["form-control"]}>
                    <select
                      IconComponent={ArrowDropDownIcon}
                      className={modules["dropdownSelect"]}
                      style={{ width: "18rem" }}
                      value={displayvalue}
                      onChange={handleChange}
                    >
                      {dropdownData.map((i) => {
                        return (
                          <option 
                          className={modules["menuItem"]}
                          style={{ fontSize: "12px", color: "#373736" }} 
                          value={i} key={i}>
                            {i}
                          </option>
                        );
                      })}
                    </select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  className={modules["label-style"]}
                  style={{  alignItems: "center" }}
                  sx={{ width: "11rem" }}>
                  Active Project Status Change Reason <span >:</span>
                </Grid>
                <Grid item>
                  <span>
                    <input
                      value={searchCat}
                      onPaste={handlePaste}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /[^a-zA-Z0-9 &_()-]/g;
                        const disallowedRegex = /[=+\[\]./]/;

                        if (disallowedRegex.test(value)) {
                          setSearchCat(e.target.value);
                        } else {
                          const sanitizedValue = value.replace(regex, "");
                          setSearchCat(sanitizedValue);
                        }
                      }}
                      onBlur={searchCatBlur}
                      onKeyDown={handleKeyDown}
                      onFocus={() => setIsEntered(true)}
                      style={{
                        marginLeft: "0rem", height: "1.5rem", width: "18rem"
                      }}
                      className={modules["input_css"]} />
                  </span>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              className={modules["custMas_btn1-cont"]}
            >
              <Stack direction="row" spacing={1}>
                <button className={modules["btn1"]} onClick={handleSearch}>
                  Search
                </button>
                <button className={modules["btn2"]} onClick={handleReset}>
                  Reset
                </button>
              </Stack>

            </Grid>

            <Grid container className={modules["mid-cont"]}>
              <Grid container columnSpacing={{ xs: 2 }}
               style={{ alignItems: "center" }}
               >
                <Grid
                  item
                  className={modules["label-style"]}>
                  Active Project Status Change Reason<span >:</span>{" "}<span style={{ color: "red" }}>*</span>
                </Grid>

                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <span>
                      <input
                        value={addCat}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /[^a-zA-Z0-9 _-]/g;
                          const sanitizedValue = value.replace(regex, "");
                          setAddCat(sanitizedValue);
                        }}
                        style={{
                          marginLeft: "0rem", height: "1.5rem", width: "18rem"
                        }}
                        className={modules["input_css"]}
                      />
                    </span>
                    <button className={modules["btn2"]} onClick={handleCatAdd}>
                      Add New Reason
                    </button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showTable ? (
          <>
            <Stack
              direction="row"
              sx={{ width: "60%", marginLeft: "6rem", marginBottom: "4%",marginTop: "0.5rem" }}
              spacing={2}
            >
              <Grid container className={modules["table-grid"]}>
                <div className={modules["table-container"]}>
                  <Table className={modules["custom-table"]}>
                    <TableHead className="table_head">
                      <TableRow
                        sx={{
                          "& th": {
                            color: "#fff",
                          },
                        }}
                      >
                        <TableCell>Sr. No.</TableCell>
                        <TableCell>Reason For Active Project Status Change</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData?.map((item, index) => (
                        <TableRow key={index} className={modules["table-row"]}>
                          <TableCell className={modules["table-cell"]}>
                            {index + 1}
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {editing === item.reason ? (
                              <input
                                type="text"
                                value={editReasonName}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /[^a-zA-Z0-9 _-]/g;
                                  const sanitizedValue = value.replace(
                                    regex,
                                    ""
                                  );
                                  setEditReasonName(sanitizedValue);
                                }}
                              />
                            ) : (
                              <div>
                                <span style={{}}>{item.reason}</span>
                              </div>
                            )}
                          </TableCell>
                          <TableCell className={modules["table-cell"]}>
                            {editing === item.reason ? (
                              <>
                                <a
                                  style={{
                                    paddingRight: "1rem",
                                    cursor: "pointer",
                                    color:"#0000EE"
                                  }}
                                  onClick={(e) =>
                                    handleUpdate(item, e.currentTarget)
                                  }
                                >
                                  Update
                                </a>
                                <a
                                  style={{ cursor: "pointer" , color:"#0000EE"}}
                                  onClick={(e) => handleCancel(e.currentTarget)}
                                 
                                >
                                  Cancel
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  style={{
                                    paddingRight: "1rem",
                                    cursor: "pointer",
                                     color:"#0000EE"
                                  }}
                                  onClick={(e) => handleEdit(item, e.currentTarget)}
                                >
                                  Edit
                                </a>
                                <a
                                  style={{
                                    paddingRight: "1rem",
                                    cursor: "pointer",
                                     color:"#0000EE"
                                  }}
                                  onClick={(e) =>
                                    handleDelete(item, e.currentTarget)
                                  }
                                >
                                  Delete
                                </a>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Stack>
          </>
        ) : failedCheck ? (
          <>
            <div
              style={{
                color: "black",
                border: "1px solid black",
                padding: "8px",
                fontSize: "15px",
                marginLeft: "6rem",
              }}
            >
              No Records found
            </div>
          </>
        ) : (
          <></>
        )}
      </Grid >
    </>
  );
};

export default ActiveProjectStatusChange;
