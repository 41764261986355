import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Stack";
import modules from "./TRDLossReportSummary.module.css";
import { DRTLS_CalculateClickHandler } from "../../../Services/TRDLoss";
import { useState, useEffect } from "react";

export default function TRDLossSummaryTable() {
  const [data, setData] = useState({
    reportDataBranch: [],
    reportDataPlant: []
  });


  const[dates,setDates]=useState({
    DataBranch:{},
    DataPlant:{}
  })

  function ObjectFilterHandler(location,mtaMto,particular){
   return data.reportDataBranch.filter(i=>{
      if(i.location===location && i.mta_mto===mtaMto && i.particular===particular){
        return true;
      }
      return false;
    })[0];

  }
  useEffect(() => {
    DRTLS_CalculateClickHandler('Lockss_LN', 'pwc', '2023-2024', '99').then(res => {
      if (res.status === 200) {
        setData({
          reportDataBranch: res?.data?.success?.data?.Reportdata_Branch,
          reportDataPlant: res?.data?.success?.data?.Reportdata_Plant
        });
        setDates({
          DataBranch:res?.data?.success?.data?.Data_Branch[0],
          DataPlant:res?.data?.success?.data?.Data_Plant[0]
        })
      }
    }).catch(err => {
      console.log('Error fetching data:', err);
    })
  }, [])
  return (
    <>
      <div style={{marginTop:'2rem'}}>
        <div style={{ backgroundColor: "#9dc81e", display: "flex", width: "65rem", borderRadius: "3px", marginLeft: "5rem" }}>
          <h5 style={{ color: "white", padding: "0.5%" }}> MTA Reports TRD Loss Summary Report</h5>
          <h5 style={{ color: "white", padding: "0.5%", marginLeft: "41rem" }}> Expand Filter[+]</h5>
        </div>
        <button style={{ marginLeft: "5rem", padding: "1.1%", marginTop: "1%" }} className={modules['btn1']}>
          Back
        </button>
        <div className={modules['customDiv']}>
          
          <div>Toc Week Id</div>
          <div>NA</div>
          <div>From:</div>
          <div>{dates?.DataBranch?.todate}</div>
          <div>To:</div>
          <div>{dates?.DataBranch?.fromdate}</div>
        </div>

        <h4 style={{ marginLeft: "5rem", marginTop: "1rem" }}>TRD Loss for Branch</h4>

        <Grid sx={{  marginTop: "2rem",mx:'auto' }}>

          <table className={modules['customTable']}>
            <thead>
              <tr>
                <th colSpan={3} style={{width:'30%'}}>Particular</th>
                <th>TRD Loss</th>
                <th>ThroughPut</th>
                <th >Plan TRD%</th>
                <th>Actual TRD%</th>
                <th >Status</th>
              </tr>
            </thead>
            <tbody>
              {
                ['Kudal','Madkai','Tivim','Traded','Vadodara','Internal','COMMON','Goa (Total)'].map((i)=>{
                  return (
                    <>
                    <tr>
                        <td rowspan='4' style={{backgroundColor:'#9dc81e',textAlign:'center',color:'white'}}>{i}</td>
                        <td style={{backgroundColor:'#9dc81e',color:'white'}}>MTA</td>
                        <td style={{backgroundColor:'#9dc81e',border:'none',color:'white',padding:'0.3rem'}}>NA</td>
                        <td>{ObjectFilterHandler(i,'MTA','NA')?.trdloss}</td>
                        <td>{ObjectFilterHandler(i,'MTA','NA')?.throughputvalue}</td>
                        <td>{ObjectFilterHandler(i,'MTA','NA')?.plantrdloss}</td>
                        <td>{ObjectFilterHandler(i,'MTA','NA')?.actualtrdlosspercentage}</td>
                        <td colSpan={2} style={ObjectFilterHandler(i,'MTA','NA')?.trdlossstatus===0?{backgroundColor:'red'}:{}} className={modules['customTd']}></td>
                      </tr>
        
                      <tr>
                        <td rowspan='3' style={{backgroundColor:'#9dc81e',textAlign:'center',color:'white'}}>MTO</td>
                        <td style={{backgroundColor:'#9dc81e',color:'white',padding:'0.3rem'}}>Export</td>
                        <td>{ObjectFilterHandler(i,'MTO','Export')?.trdloss}</td>
                        <td>{ObjectFilterHandler(i,'MTO','Export')?.throughputvalue}</td>
                        <td>{ObjectFilterHandler(i,'MTO','Export')?.plantrdloss}</td>
                        <td>{ObjectFilterHandler(i,'MTO','Export')?.actualtrdlosspercentage}</td>
                        <td colSpan={2} style={ObjectFilterHandler(i,'MTO','Export')?.trdlossstatus===0?{backgroundColor:'red'}:{}} className={modules['customTd']}></td>
                      </tr>
        
                      <tr>
                        <td style={{backgroundColor:'#9dc81e',color:'white',padding:'0.3rem'}}>Institution</td>
                        <td>{ObjectFilterHandler(i,'MTO','Institution')?.trdloss}</td>
                        <td>{ObjectFilterHandler(i,'MTO','Institution')?.throughputvalue}</td>
                        <td>{ObjectFilterHandler(i,'MTO','Institution')?.plantrdloss}</td>
                        <td>{ObjectFilterHandler(i,'MTO','Institution')?.actualtrdlosspercentage}</td>
                        <td colSpan={2}  style={ObjectFilterHandler(i,'MTO','Institution')?.trdlossstatus===0?{backgroundColor:'red'}:{}} className={modules['customTd']}></td>
                      </tr>
        
                      <tr>
                        <td style={{backgroundColor:'#9dc81e',color:'white',padding:'0.3rem'}}>Retail</td>
                        <td>{ObjectFilterHandler(i,'MTO','Retail')?.trdloss}</td>
                        <td>{ObjectFilterHandler(i,'MTO','Retail')?.throughputvalue}</td>
                        <td>{ObjectFilterHandler(i,'MTO','Retail')?.plantrdloss}</td>
                        <td>{ObjectFilterHandler(i,'MTO','Retail')?.actualtrdlosspercentage}</td>
                        <td colSpan={2}  style={ObjectFilterHandler(i,'MTO','Retail')?.trdlossstatus===0?{backgroundColor:'red'}:{}} className={modules['customTd']}></td>
                        
                      </tr>

                      <tr style={{border:'3px solid #9dc81e',marginTop:'10px'}}></tr>
        
                    </>
                  )
                })
              }
             

           
           
          </tbody>
        </table>
      

     


      </Grid>

      <Grid sx={{marginBottom:'2rem'}}>
      <div className={modules['customDiv']}>
          
          <div>Toc Week Id</div>
          <div>NA</div>
          <div>From:</div>
          <div>{dates?.DataPlant?.todate}</div>
          <div>To:</div>
          <div>{dates?.DataPlant?.fromdate}</div>
        </div>
        <table className={modules['customTableTwo']}>
          <thead>
          
            <th>Particular</th>
            <th>TRD Loss</th>
            <th>Throughput</th>
            <th>Plan TRD%</th>
            <th>Actual TRD%</th>
            <th>Status</th>
            
          </thead>
          <tbody>
            {
              data.reportDataPlant?.map((i)=>{
                   return (
                    <tr>
                    <td>{i.location}</td>
                    <td>{i.trdloss}</td>
                    <td>{i.throughputvalue}</td>
                    <td>{i.plantrdloss?i.plantrdloss:''}</td>
                    <td>{i.actualtrdlosspercentage}</td>
                    <td style={i.trdlossstatus===0 || i.trdlossstatus===null?{backgroundColor:'red'}:{}}></td>
                  </tr>
                   )
              })
            }
           
           
            </tbody>
        </table>
      </Grid>
    </div >
    </>
  )
}