import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
// import modules from "./Portfoliochart.module.css";
import modules from "./../ccpm.module.css";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import { Tab, Tabs, TextField } from "@mui/material";
import SampleSidebar from "../../../Landing/SampleSidebar";
import loading from "../../../Assets/Loading_image.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GetPCInstances,
  GetPCBindProjectName,
  GetPCBindProjectManager,
  GetPCHierarchyItems,
  GetPCSearchFilter,
  GetBMSearchResult,
  DTFGetTaskManagers,
  DTFSearchClick,
  DTFExportClick,
  STFGetProjects,
  STFSearchClick,
  STFExportClick,
  STFGetProjectManagers
  
} from "../../../Services/CCPM.js";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

import { styled } from '@mui/system';
import { enUS } from 'date-fns/locale';
import { Stack, Popover, Paper } from '@mui/material';

import { format } from 'date-fns';
import ExcelJS from 'exceljs';

function ArrowLine() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "1px",
          height: "18px",
          backgroundColor: "grey",
          marginRight: "2px",
        }}
      />
      <ArrowDropDownIcon sx={{ marginRight: "15px" }} />
    </div>
  );
}
const DailySubTaskFlowTrend = () => {
  const [searchValue, setSearchValue] = useState("");
  // State for selected values
  const [selectedInstance, setSelectedInstance] = useState("");
 
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [filteredValueData, setfilteredValueData] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [resultTableData, setResultTableData] = useState([]);
  const [selectedAccessType, setSelectedAccessType] = useState("SELECT");
 
  const [selectedmaximumYAxis, setselectedmaximumYAxis] = useState("100");
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0);
  const [selectedProjectAccessType, setSelectedProjectAccessType] =
    useState("");

  const [dropdownData, setDropdownData] = useState([
    "Select",
    "Instance 2",
    "Instance 3",
  ]);
 
  
  const [isLoading, setIsLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [displayvalue, setDisplayValue] = useState(dropdownData[0]);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  const handleCalendarChangeFrom = (date) => {
    setFromDate(date);
    setAnchorElFrom(null);
  };
  const handleCalendarChangeTo = (date) => {
    setToDate(date);
    setAnchorElTo(null);
  };
 const formattedToday = new Date().toLocaleDateString();
 const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [anchorElFrom, setAnchorElFrom] = useState(null);
  const [anchorElTo, setAnchorElTo] = useState(null);

  
  const HiddenCalendarInput = styled(TextField)({
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      display: 'none',
    },
    '& .MuiInputBase-input': {
      height: '25px',
      padding: '0px 0px 0px 5px'
    },
  });

  const handleSelectChange = (attributenumber, id) => {

    setSelectedAttributes((prevSelectedAttributes) => ({
      ...prevSelectedAttributes,
      [`attr${attributenumber}`]: id || 0,
    }));
  };

  const [radioValue, setRadioValue] = useState("yes");

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const [instances, setInstances] = useState([]);
  const [taskManagers, setTaskManagers] = useState([]);
  const [selectedTaskManager, setSelectedTaskManager] = useState(0);
  const [zones, setZones] = useState([]);
  const [projects, setProjects] = useState([]);
  const [failedCheck, setFailedCheck] = useState(false);
  const [managers, setManagers] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [dateColumns, setDateColumns] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [accessTypes, setAccessTypes] = useState([
    { value: "", label: "SELECT" },
    { value: "PM", label: "Where I am Project Manager" },
    { value: "APM", label: "Where I am Alternate PM" },
    { value: "LPM", label: "Where My L is Project Manager" },
    { value: "LAPM", label: "Where My L is Alternate PM" },
  ]);
  
 
  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetInstances();
    getTaskManagers();
  }, []);

  useEffect(() => {
    if (instances && instances.length > 0) {
      const initialInstance = instances[0].id;
      setSelectedInstance(initialInstance);
      getProjectsname(initialInstance);
      getProjectsManager(initialInstance);
      getHierarchyOption(initialInstance);
      getValueFilterOption(initialInstance);
    }
  }, [instances]);

  const getProjectDetails = (I_id) => {
    console.log(I_id, "selectedInstance");
    getProjectsname(I_id);
    getProjectsManager(I_id);
    getHierarchyOption(I_id);
    getValueFilterOption(I_id);
  };

  const GetInstances = () => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    GetPCInstances(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // console.log(response?.data?.success?.data);
          setInstances(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
const getTaskManagers =() =>{
  const loginId = sessionStorage.getItem("loginId");
  const body = {
    userId: loginId,
  };
  DTFGetTaskManagers(body)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        console.log(response?.data?.success?.data, "task managers");
        setTaskManagers(response?.data?.success?.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
useEffect(() => {
  if (showTable) {
    document.body.style.overflow = 'auto';
  } else {
    document.body.style.overflow = ''; // Reset to default overflow
  }

  // Cleanup on unmount
  return () => {
    document.body.style.overflow = ''; // Reset to default overflow
  };
}, [showTable]);


  const getProjectsname = (I_id) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    STFGetProjects(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(false);
          console.log(response?.data?.success?.data, "project name");
          setProjects(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getProjectsManager = (I_id) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      
      instanceId: I_id,
      userId: loginId,
      projStatusId: 1,
    };
    STFGetProjectManagers(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(false);
          console.log(response?.data?.success?.data, "project manager");
          setManagers(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getHierarchyOption = (I_id) => {
    setIsLoading(true);
    const body = {
      instanceId: I_id,
      activeOnly: 1,
    };
    GetPCHierarchyItems(body)
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "options");
          setHierarchyOptions(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getValueFilterOption = (I_id) => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
      instanceId: I_id,
    };
    GetPCSearchFilter(body)
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.statusCode === 200) {
          console.log(response?.data?.success?.data, "project manager");
          setfilteredValueData(response?.data?.success?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getSearchResult = (
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    attr7,
    attr8,
    attr9,
    attr10
  ) => {
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      instanceId: selectedInstance,
      userId: loginId,
      attribute1Id: attr1,
      attribute2Id: attr2,
      attribute3Id: attr3,
      attribute4Id: attr4,
      attribute5Id: attr5,
      attribute6Id: attr6,
      attribute7Id: attr7,
      attribute8Id: attr8,
      attribute9Id: attr9,
      attribute10Id: attr10,
      projId: selectedProjectName,
      projMgrId: selectedProjectManager,
      taskMgrId: selectedTaskManager,
      accessType: selectedProjectAccessType,
     

    };
    STFSearchClick(body)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        const responseData = response.data.success.data;
        console.log(responseData, "search result");

        if (response.data.message === "No Data to display.") {
          setIsLoading(false);
          alert("No data to display!!");
          setFailedCheck(true);
          return;
        }
  
        // Set the chart data
        setChartData(responseData);
        setShowTable(true);
        setIsLoading(false);
        setFailedCheck(false);
        // Extract columns
        if (responseData.length > 0) {
          const columns = Object.keys(responseData[0]);
          console.log("All columns: ", columns); // Log to verify columns
          
          // Regular expression to match date patterns like 'YYYY/MM/DD'
          const datePattern = /^\d{4}\/\d{2}\/\d{2}$/;
          const dateCols = columns.filter((col) => datePattern.test(col));
          console.log("Date columns: ", dateCols); // Log to verify date columns
          
          setDateColumns(dateCols);
        }
      } else {
        alert("No data to display!!");
        setIsLoading(false);
      }
    })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };



  const [tabValue, setTabValue] = useState(0); // State to track the active tab

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearch = () => {
    showTable && setShowTable(false);
    setIsLoading(true); 
    setShowResult(true);
    console.log(selectedAttributes, "selectedAttributes");
   
    let attr1 = 0,
      attr2 = 0,
      attr3 = 0,
      attr4 = 0,
      attr5 = 0,
      attr6 = 0,
      attr7 = 0,
      attr8 = 0,
      attr9 = 0,
      attr10 = 0;

    // Update variables with values from selectedAttributes if they exist
    if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
    if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
    if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
    if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
    if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
    if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
    if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
    if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
    if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
    if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;

    console.log(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );

    getSearchResult(
      attr1,
      attr2,
      attr3,
      attr4,
      attr5,
      attr6,
      attr7,
      attr8,
      attr9,
      attr10
    );
   
  
  };

  const handleReset = () => {
    if (instances && instances.length > 0) {
      const firstInstance = instances[0].id; // Use the first instance directly
      setSelectedInstance(firstInstance);
      getProjectDetails(firstInstance);
    }
    setSearchValue("");
    setSelectedProjectName("SELECT");
    setSelectedProjectManager("SELECT");
    setSelectedTaskManager("SELECT");
    setSelectedProjectAccessType("SELECT");
    setSelectedAttributes({});
    setIsLoading(false);
    setShowTable(false);

   
  };

  const ExportToExcel = () => {
    let attr1 = 0,
        attr2 = 0,
        attr3 = 0,
        attr4 = 0,
        attr5 = 0,
        attr6 = 0,
        attr7 = 0,
        attr8 = 0,
        attr9 = 0,
        attr10 = 0;
  
      // Update variables with values from selectedAttributes if they exist
      if (selectedAttributes.attr1) attr1 = selectedAttributes.attr1;
      if (selectedAttributes.attr2) attr2 = selectedAttributes.attr2;
      if (selectedAttributes.attr3) attr3 = selectedAttributes.attr3;
      if (selectedAttributes.attr4) attr4 = selectedAttributes.attr4;
      if (selectedAttributes.attr5) attr5 = selectedAttributes.attr5;
      if (selectedAttributes.attr6) attr6 = selectedAttributes.attr6;
      if (selectedAttributes.attr7) attr7 = selectedAttributes.attr7;
      if (selectedAttributes.attr8) attr8 = selectedAttributes.attr8;
      if (selectedAttributes.attr9) attr9 = selectedAttributes.attr9;
      if (selectedAttributes.attr10) attr10 = selectedAttributes.attr10;
  
      console.log(
        attr1,
        attr2,
        attr3,
        attr4,
        attr5,
        attr6,
        attr7,
        attr8,
        attr9,
        attr10
      );
  
  
  
      ExportToExcelHandler(
        attr1,
        attr2,
        attr3,
        attr4,
        attr5,
        attr6,
        attr7,
        attr8,
        attr9,
        attr10
      );
    };

    const ExportToExcelHandler = async ( 
      attr1,
     attr2,
     attr3,
     attr4,
     attr5,
     attr6,
     attr7,
     attr8,
     attr9,
     attr10) => {
     setIsLoading(true);
     const loginId = sessionStorage.getItem("loginId");
     const body = {
       instanceId: selectedInstance,
       userId: loginId,
       attribute1Id: attr1,
       attribute2Id: attr2,
       attribute3Id: attr3,
       attribute4Id: attr4,
       attribute5Id: attr5,
       attribute6Id: attr6,
       attribute7Id: attr7,
       attribute8Id: attr8,
       attribute9Id: attr9,
       attribute10Id: attr10,
       projId: selectedProjectName,
       projMgrId: selectedProjectManager,
       taskMgrId: selectedTaskManager,
       accessType: selectedProjectAccessType,
      
   
     };
   
     try {
       // Making the API call with the correct responseType
       const response = await  STFExportClick(body, { responseType: 'arraybuffer' });
   
       if (response) {
         // Extracting the filename from the content-disposition header if it exists
         const contentDisposition = response.headers['content-disposition'];
         let filename = "DailySubTaskFlowTrend.xlsx"; // Default filename
   
         if (contentDisposition) {
           const matches = /filename="([^"]+)"/.exec(contentDisposition);
           if (matches && matches[1]) {
             filename = matches[1];
           }
         }
   
         const workbook = XLSX.read(response.data, { type: "arraybuffer" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
        const cell = worksheet[cellAddress];

        if (cell && cell.v) {
          cell.v = cell.v
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");

          // Apply bold formatting
          if (!cell.s) cell.s = {};
          cell.s.font = { bold: true };

          // Log for debugging
          console.log("Styled Cell:", cell);
        }
      }

      // Step 3: Write and download the modified workbook
      const updatedData = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([updatedData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
       } else {
         console.error("No data found in the response.");
       }
     } catch (err) {
       console.error("Error during file download:", err);
     } finally {
       setIsLoading(false);
     }
   };


  
   


  const RowsPerPage = 20;
  const MaxVisiblePages = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentGroup, setCurrentGroup] = useState(1); // Track the current page group

  // Reset to the first page and group when chartData changes
  useEffect(() => {
    setCurrentPage(1);
    setCurrentGroup(1);
  }, [chartData]);

  // Calculate total pages and page group range
  const totalPages = Math.ceil(chartData.length / RowsPerPage);
  const totalGroups = Math.ceil(totalPages / MaxVisiblePages);

  // Calculate the current page's displayed data range
  const startIndex = (currentPage - 1) * RowsPerPage;
  const endIndex = startIndex + RowsPerPage;
  const displayedData = chartData.slice(startIndex, endIndex);

  // Navigate to next or previous group of pages
  const handleGroupChange = (direction) => {
    if (direction === "prev" && currentGroup > 1) {
      setCurrentGroup(currentGroup - 1);
      setCurrentPage((currentGroup - 2) * MaxVisiblePages + 1);
    } else if (direction === "next" && currentGroup < totalGroups) {
      setCurrentGroup(currentGroup + 1);
      setCurrentPage(currentGroup * MaxVisiblePages + 1);
    }
  };

  // Update the current page within the current group
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the page numbers for the current group
  const getPageNumbers = () => {
    const pageNumbers = [];
    const startPage = (currentGroup - 1) * MaxVisiblePages + 1;
    const endPage = Math.min(currentGroup * MaxVisiblePages, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  
  
   // Helper function to format date
function formatDate(dateString) {
  const [year, month, day] = dateString.split("/"); // Split based on the "/" delimiter
  return `${day}/${month}/${year}`; // Rearrange to "dd/m/yyyy"
}

function formatDatenew(dateString) {
  // Split the string to get the date part
  const datePart = dateString.split(" ")[0]; // "2024-10-24"
  const [year, month, day] = datePart.split("-"); // Split the date into parts
  return `${day}/${month}/${year}`; // Rearrange to "dd/mm/yyyy"
}
 

const exportSimpleTableToExcel = async (displayedData, dateColumns) => {

  if (!Array.isArray(dateColumns)) {
    console.error("dateColumns is not iterable:", dateColumns);
    return;
}


  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('DailySubTaskFlowTrend');

  // Define headers based on the table columns
  const headers = [
      "ID", "Instance", "ProjectsId", "ProjectName", "ProjectManager", 
      "TaskId", "TaskName", "TaskManager", "TaskType", "SubTaskId", 
      "SubTaskName", "Status", "ClosedOnDate", ...dateColumns.map(date => formatDate(date))
  ];

  // Add header row to the worksheet
  const headerRow = worksheet.addRow(headers);

  // Style headers (bold, center alignment)
  headerRow.eachCell((cell) => {
    cell.font = { bold: true, name: 'Aptos Narrow' }; // Set font to Aptos Narrow
    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  });

  // Add data rows
  displayedData.forEach((row, index) => {
      const rowData = [
          index + 1,                       // ID
          row.instance,                    // Instance
          row.projectsid,                  // ProjectsId
          row.projectname,                 // ProjectName
          row.projectmanager,              // ProjectManager
          row.taskid,                      // TaskId
          row.taskname,                    // TaskName
          row.taskmanager,                 // TaskManager
          row.tasktype,                    // TaskType
          row.subtaskid,                   // SubTaskId
          row.subtaskname,                 // SubTaskName
          row.status,                      // Status
          formatDatenew(row.closedondate), // ClosedOnDate
          ...dateColumns.map(date =>row[date] || "N/A") // Date columns with fallback
      ];

      const dataRow = worksheet.addRow(rowData);
      
      // Set font for data rows
      dataRow.eachCell((cell) => {
        cell.font = { name: 'Aptos Narrow', size: 11 }; // Set font to Aptos Narrow for data rows
        cell.alignment = { wrapText: true }; // Center alignment
      });
  });

  // Adjust column widths to fit content
  worksheet.columns = [
    { width: 5, font: { name: 'Aptos Narrow', size: 11 } },  // ID column
    { width: 15, font: { name: 'Aptos Narrow', size: 11 } },  // Instance column
    { width: 15, font: { name: 'Aptos Narrow', size: 11 } },  // ProjectsId column
    { width: 25, font: { name: 'Aptos Narrow', size: 11 } },  // ProjectName column
    { width:15, font: { name: 'Aptos Narrow', size: 11 } },  // ProjectManager column
    { width: 10, font: { name: 'Aptos Narrow', size: 11 } },  // TaskId column
    { width: 35, font: { name: 'Aptos Narrow', size: 11 } },  // TaskName column
    { width: 15, font: { name: 'Aptos Narrow', size: 11 } },  // TaskManager column
    { width: 15, font: { name: 'Aptos Narrow', size: 11 } },  // TaskType column
    { width: 15, font: { name: 'Aptos Narrow', size: 11 } },  // SubTaskId column
    { width: 15, font: { name: 'Aptos Narrow', size: 11 } },  // SubTaskName column
    { width: 15, font: { name: 'Aptos Narrow', size: 11 } },  // Status column
    { width: 15, font: { name: 'Aptos Narrow', size: 11 } },  // ClosedOnDate column
    ...dateColumns.map(() => ({ width: 15, font: { name: 'Aptos Narrow', size: 11 } })) // Date columns with appropriate width and font
  ];

  // Save workbook
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'DailySubTaskFlowTrend.xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};


const handleExportClick = () => {
  exportSimpleTableToExcel(displayedData, dateColumns);
};


  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          <SampleSidebar />
        </Grid>

       
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"DailySubTaskFlowTrend"} />
          </Grid>

          <Grid item lg={12}>
            <div
              className={modules["instancemaster_div"]}
              style={{ marginBottom: "5px" }}
            >
              <div className={modules["pro-bread"]}>
                <a>Reports</a>
                <img src={bread} alt="" />
                <a>Daily Sub Task Flow Report</a>
              </div>
            </div>
            <div className={showTable ? modules["border_contnew"] : modules["border_cont"]}>
              <table className={modules["PCtable"]}>
                <tbody>
                  <tr style={{ paddingBottom: "0px" }}>
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>
                        Instance:<label style={{ color: "Red" }}>*</label>
                      </span>

                      <select
                        value={selectedInstance}
                        onChange={(e) => {
                          setSelectedInstance(e.target.value);
                          getProjectDetails(e.target.value);
                        }}
                        className={modules["dropdownSelect"]}
                        style={{ width: "200px" }}
                      >
                        {instances?.map((instance) => (
                          <option key={instance.name} value={instance.id}>
                            {instance.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    {hierarchyOptions
                      ?.filter((option) => option.isactive === 1)
                      .map((option, index) => (
                        <React.Fragment key={option.attributename}>
                          <td className={modules["table_data1"]}>
                            <span style={{ width: "110px" }}>
                              {option.attributename} :
                            </span>
                            <select
                              value={
                                selectedAttributes[
                                `attr${option.attributenumber}`
                                ] || ""
                              }
                              onChange={(e) =>
                                handleSelectChange(
                                  option.attributenumber,
                                  e.target.value
                                )
                              }
                              className={modules["dropdownSelect"]}
                            >
                              <option value="">SELECT</option>
                              {filteredValueData[index]?.map((item) => (
                                <option
                                  key={item.attributevalue}
                                  value={item.id}
                                >
                                  {item.attributevalue}
                                </option>
                              ))}
                            </select>
                          </td>
                        </React.Fragment>
                      ))}
                  </tr>
                  <tr
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>Project Name:</span>
                      <select
                        value={selectedProjectName}
                        onChange={(e) => setSelectedProjectName(e.target.value)}
                        className={modules["dropdownSelect"]}
                      >
                        <option>SELECT</option>
                        {projects?.map((p_name) => (
                          <option key={p_name.projectname} value={p_name.id}>
                            {p_name.projectname}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>Project Manager:</span>
                      <select
                        value={selectedProjectManager}
                        onChange={(e) =>
                          setSelectedProjectManager(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                      >
                        <option>SELECT</option>
                        {managers?.map((m_name) => (
                          <option key={m_name.username} value={m_name.id}>
                            {m_name.username}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>Task Manager:</span>
                      <select
                        value={selectedTaskManager}
                        onChange={(e) => setSelectedTaskManager(e.target.value)}
                        className={modules["dropdownSelect"]}
                      >
                         <option>SELECT</option>
        {taskManagers?.map((taskManager) => (
          <option key={taskManager.id} value={taskManager.id}>
            {taskManager.taskmanager}
          </option>
                        ))}
                      </select>
                    </td>

                    <td className={modules["table_data1"]}>
                      <span style={{ width: "110px" }}>
                        Project AccessType:
                      </span>
                      <select
                        value={selectedProjectAccessType}
                        onChange={(e) =>
                          setSelectedProjectAccessType(e.target.value)
                        }
                        className={modules["dropdownSelect"]}
                      >
                        {accessTypes.map((access) => (
                          <option key={access.label} value={access.value}>
                            {access.label}
                          </option>
                        ))}
                      </select>
                    </td>

                  
                  </tr>
                
                  <tr
                    style={{
                      justifyContent: "center",
                      gap: "10px",
                      paddingLeft: "0px",
                      paddingBottom: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      className={modules["PCbtn"]}
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className={modules["PCbtn"]}
                      type="button"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  </tr>
                </tbody>
              </table>
            </div>
            {showTable && (
              <>
          
                <Grid container className={modules["instanceAttMap_table-grid"]}  style={{ marginLeft:'6.5rem',marginTop:'1.8rem',marginBottom:'1.8rem',width:'100%'}}>
                <div style={{width:'max-content',paddingRight:'2rem'}} className={modules["ccpm_table-containerIAM"]}>
                    <Table  className={modules["custom-table"]}>
                      <TableHead className="table_head">
                        <TableRow >
                          <TableCell  className="fixed-width-cell" sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '70px' }}>
                            ID
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px' ,width: '200px',justifyContent:'flex-start',}}>
                            Instance
                          </TableCell>
                          <TableCell  sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '200px'}}>
                           ProjectsId
                          </TableCell>
                          <TableCell  sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '100px' }}>
                            ProjectName
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '100px' }}>
                           ProjectManager
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px' ,width: '100px'}}>
                           TaskId
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px' ,width: '100px'}}>
                         TaskName
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '100px'}}>
                           TaskManager
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '100px'}}>
                            TaskType
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '100px'}}>
                         SubTaskId
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '100px'}}>
                        SubTaskName
                          </TableCell>
                         
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '100px'}}>
                         Status
                          </TableCell>
                          <TableCell sx={{ fontFamily: 'worksans-semibold,sans-serif', fontSize: '15px',width: '100px'}}>
                        ClosedOnDate
                          </TableCell>

                          {dateColumns.map((date) => (
                    <TableCell key={date}>{formatDate(date)}</TableCell>
                  ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {displayedData.map((row,index) => (
                          <TableRow key={row.id} className={modules["table-row"]}>
                            <TableCell className={modules["table-cell"]}>{startIndex + index + 1}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.instance}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.projectsid}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.projectname}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.projectmanager}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.taskid}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.taskname}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.taskmanager}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.tasktype}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.subtaskid}</TableCell>
                            <TableCell className={modules["table-cell"]}>{row.subtaskname}</TableCell>
                             <TableCell className={modules["table-cell"]}>{row.status}</TableCell>
                            <TableCell className={modules["table-cell"]}>{formatDatenew(row.closedondate)}</TableCell>
                            
                           

                            {dateColumns.map((date) => (
                    <TableCell key={date} className={modules["table-cell"]}>
                    {row[date] || "N/A"} {/* Fallback if date column data is not present */}
                  </TableCell>
                  ))}

                          </TableRow>
                        ))}

{totalPages > 1 && (
  <TableRow>
    <TableCell colSpan={16}>
      <div className={modules["pagination"]}>
        {/* Group-based "Prev" button */}
        <span
          className={currentGroup === 1 ? modules["disabled"] : modules["page-number"]}
          onClick={() => handleGroupChange("prev")}
        >
          Prev
        </span>

        {/* Display page numbers for the current group */}
        {getPageNumbers().map((pageNumber, index) => (
          <span
            key={index}
            className={
              pageNumber === currentPage
                ? modules["active-page"]
                : modules["page-number"]
            }
            onClick={() =>
              typeof pageNumber === "number" ? handlePageChange(pageNumber) : null
            }
          >
            {pageNumber}
          </span>
        ))}

        {/* Group-based "Next" button */}
        <span
          className={currentGroup === totalGroups ? modules["disabled"] : modules["page-number"]}
          onClick={() => handleGroupChange("next")}
        >
          Next
        </span>
      </div>
    </TableCell>
  </TableRow>
)}

                      </TableBody>
                    </Table>
                    <br>
                    </br>
                    <button
                      type="button"
                      className={modules["PCbtn"]}
                      onClick={handleExportClick}
                    >
                     Export to Excel
                    </button>
                  </div>
                </Grid>
              </>
            )}
            

       
          </Grid>
      
      </Grid>
    </>
  );
};

export default DailySubTaskFlowTrend;