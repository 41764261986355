import axios from "axios";
import { API_KEY ,Login} from "../Constants/index";
 
export async function To_Login(id,pwd) {
  const payload = {
    loginCode: id,
    password: pwd,
  };
 
  try {
    const res = await axios.post(`${API_KEY}${Login}`, payload);
    return res;
  } catch (err) {
    return err;
  }
  }