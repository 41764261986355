import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import changepm1 from './images/changepm1.png'
import changepm2 from './images/changepm2.png'
import changepm3 from './images/changepm3.png'
import changepm4 from './images/changepm4.png'
import view6 from './images/view6.png'
import view7 from './images/view7.png'
import MTA1 from './images/MTA-1.jpg'
import MTA2 from './images/MTA-2.jpg'
import PNB1 from './images/PNB1.jpg'   
import PNB2 from './images/PNB2.jpg'
import PNB3 from './images/PNB3.jpg'
import PNB4 from './images/PNB4.jpg'
import PNB5 from './images/PNB5.jpg'
import PNB6 from './images/PNB6.jpg'
import PNB7 from './images/PNB7.jpg'
import PNB8 from './images/PNB8.png'
import PNB9 from './images/PNB9.jpg'
import PNB10 from './images/PNB10.png'
import PNB11 from './images/PNB11.png'
import PNB12 from './images/PNB12.png'



import { Link } from "react-router-dom";
import PlannedBillingProjection from "../CCPM/ProjectPlan/Popups/PlannedBillingProjection";

const PlannedBillingProjectionhelpPopup = () => {
    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem", fontWeight: "bold", fontFamily: 'Times New Roman' }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            Expand it...
                            <Link style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}>
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>
            </div>

            <Grid sx={{ fontFamily: '"Calibri", sans-serif', fontSize: '14.6px', color: '#000000' }}>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul>
                            <h3 style={{ fontWeight: "bold",textAlign:'center',textDecoration:'underline' }}>BILLING PROJECTIONS REQUIRED IN PROJECTS</h3>
                            <br />
                            <span style={{backgroundColor:'yellow'}}>My Projects:-</span>
                            <br /><br />
                            <span>The Billing projection option is available in “My Projects” screen between ‘Change status’ and ‘Risk’ as “Planned Billing Projection”
                            </span>
                            <br /><br />
                            <span>Billing projection -session is accessible from My Active project session also.</span>
                            <br /><br />


                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={PNB1} alt="PNB1" />
                            </Stack>
                            <br />
                            <span> On clicking an input form will open as seen below:-</span>
                            <br /><br /> 
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={PNB2} alt="PNB2" />
                            </Stack>
                            <br /><br />
                            <span>Here the Planned Billing Value can be entered (in lacs) task wise as seen below.</span>
                            <br /><br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={PNB3} alt="PNB3" />
                            </Stack>
                            <br /><br />

                            <span>Also there is provision to link the ProStream project with the ERP company and Project no. The details can be entered in the boxes. The entry fields ERP company code, project no and Planned Billing value will be always editable from “My projects” and “Active Projects”.</span>
                            <br /><br />
                            <span>Actual Billing Value in Lacs – is also open field for date fetch fro LN  or Manual Entry</span>
                            <br /><br />
                            <span>Remark colum is added for Project manager to input any remarks</span>
                            <br /><br />
                            <span style={{backgroundColor:'yellow'}} >Active Projects:-</span>
                            <br /><br />
                            <span>The Billing projection option is available in “Active Projects” screen between ‘Fever chart’ and ‘View GANTT chart’ as “Planned Billing Projection”</span>
                            <br /><br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={PNB4} alt="PNB5" />
                            </Stack>
                            <br /><br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={PNB5} alt="PNB5" />
                            </Stack>
                            <br /><br />
                            <span>Projected Billing value will be shown in “My projects” and “Active Projects” only for those tasks which are completed in CCPM. The value will be same as the projected billing value.</span>
                            <br /><br />
                            <span style={{backgroundColor:'yellow'}}>View Projects:-</span>
                            <br /><br />
                            <span>The Billing projection option is available in “My Projects” screen between ‘Last BM run’ and ‘Risk’ as “View Billing Projection”'</span>
                            <br /><br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={PNB6} alt="PNB6" />
                            </Stack>
                            <br /><br />
                            <span>Actual billing value (in lacs) will be shown in ‘View Projects’</span>
                            <br /><br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={PNB7} alt="PNB7" />
                            </Stack>
                            <br /><br />
                            <span>The value is taken from LN- CR server for the ERP Project and company code. Only the total value is shown below as task information is not linked with ERP LN transaction.</span>
                            <br /><br />
                            
                            <span>The column “Actual billing value in Lacs” is pulled from Central Repository (CR) server. The table name is “tbl_Invoice_line” and column name is “il_BasicLineAmount”</span>
                            <br /><br />
                            <span>The sum of the column “il_BasicLineAmount” is taken for “il_OrderNo” and “il_LogisticCompany” where “il_OrderNo” is the Project no and “il_LogisticCompany” is the Project company code. The relationship which is already maintained will be used to display the data.
                            </span>
                            <br /><br />
                            <span>The procedure for fetching this data is “CCPM_usp_Billed_Sales_Upload_GnB_CR” which is added in daily automated run “CCPM - Billing Sales Details Upload”</span>
                            <span>The data will be added in the table “CCPM_t_billed_sales”</span>
                            <br /><br />
                            <span>The table details are as follows:-</span>
                            <br /><br />
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={PNB8} alt="PNB8" />
                            </Stack>
                            <br></br>
                            <span>The invoicing details are displayed at the bottom of the session as seen below:-</span>
                            <br></br>
                            <Stack sx={{ paddingRight: "1rem" }}>
                                <img src={PNB9} alt="PNB9" />
                            </Stack>
                            <br></br>
                            <span>Currently only the TP projects can be linked so that the billing projections can be fetched</span>
                            <br></br>
                            <br></br>





                            <ul style={{ listStyleType: 'disc', marginLeft: '5rem' }}>
                                <br />
                                <li>
                                    Email Feature enabled for Planned Billing projection
                                </li>
                                <ul>
                                    <li>Planning Billing Projection -  For billing milestone, e-mail is sent to respective

                                        project manager and Alternate Project Manager.</li>
                                    <li>Remark colum added and Actual billing value in Rs – field made active</li>
                                </ul>


                            </ul>
                            <br />
                            <span>When Task is completed (Remaining duration =0), and the value is entered in ‘Planned Billing Value In Lacs’ field in ‘Active Projects’ session (‘Planned Billing projection’ hyperlink is clicked, planned billing projection - session is opened) , Mile stone is reached for the Task and once  ‘Save’ button is pressed, the value will be displayed in ‘ Project Billing Value in Lacs’ and same time EMAIL will be triggered to Project manager and Alternate project manager as  template as mentioned below-</span>
                            <br /><br />
                            <span>Email body  have Billing value available for billing from Sumed up ‘TOTAL value’</span>
                            <br /><br />
                            <Stack style={{ width: '498px', height: '242px' }} sx={{ paddingRight: "1rem" }}>
                                <img src={PNB10} alt="PNB10" />
                            </Stack>
                            <br /><br />
                            <span style={{fontSize:'18px'}}>EMAIL FORMAT :-</span>
                            <br /><br />
                            <Stack style={{ width: '698px', height: '442px' }} sx={{ paddingRight: "1rem" }}>
                                <img src={PNB11} alt="PNB11" />
                            </Stack>
                           
                           
                            
                            <span>Similarly, When
                                last
                                Task of any project is completed (Remaining duration =0), and the value is entered  in ‘Planned Billing Value In Lacs’ field and once  ‘Save’ button is pressed, the value will be displayed in ‘ Project Billing Value in Lacs’ and same time EMAIL will be triggered to Project manager, Alternate project manager as template as mentioned below-</span>
                            <br /><br />
                            <Stack style={{ width: '798px', height: '442px' }} sx={{ paddingRight: "1rem" }}>
                                <img src={PNB12} alt="PNB12" />
                            </Stack>
                            <br /><br />






                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PlannedBillingProjectionhelpPopup;
