import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import C0 from "../HelpPopups/images/c0.png";
import C1 from "../HelpPopups/images/C1.png";
import C2 from "../HelpPopups/images/c2.png";
import C3 from "../HelpPopups/images/c3.png";
import C4 from "../HelpPopups/images/c4.png";
import C5 from "../HelpPopups/images/c5.png";
import C6 from "../HelpPopups/images/c6.png";
import C7 from "../HelpPopups/images/c7.png";
import C8 from "../HelpPopups/images/c8.png";
import C9 from "../HelpPopups/images/c9.png";
import C10 from "../HelpPopups/images/c10.png";
import C11 from "../HelpPopups/images/c11.png";
import C12 from "../HelpPopups/images/c12.png";
import C13 from "../HelpPopups/images/c13.png";
import C14 from "../HelpPopups/images/c14.png";
import C15 from "../HelpPopups/images/c15.png";
import C16 from "../HelpPopups/images/c16.png";
import C17 from "../HelpPopups/images/c17.png";
import C18 from "../HelpPopups/images/c18.png";
import C19 from "../HelpPopups/images/c19.png";
import CA from "../HelpPopups/images/cA.jpg";
import CB from "../HelpPopups/images/cB.jpg";
import CC from "../HelpPopups/images/cC.jpg";
import CD from "../HelpPopups/images/cD.jpg";
import CE from "../HelpPopups/images/cE.jpg";
import CF from "../HelpPopups/images/cF.jpg";
import CG from "../HelpPopups/images/cG.jpg";
import CH from "../HelpPopups/images/cH.jpg";
import CI from "../HelpPopups/images/cI.jpg";

import { Link } from "react-router-dom";

const CalendarhelpPopup = () => {
  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}}/>
            <span className="arialFont" style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                }}
                className="arialFont"
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid >
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul className="arialFont">
              <h3 style={{ marginLeft: "25rem", textDecoration: "underline" }}>
                Calendar
              </h3>
              <br />
              <li>
                Calendar is the base for the planning of the project, and the
                calendar has to get selected while creating the project. Based
                on the calendar working days the network plan for a that
                particular project will be created.
              </li>
              <li>PATH : CCPM \ Project Plan \ My Projects.</li>
              <br />

              <Stack sx={{ paddingRight: "1rem" }} style={{ width: "35rem" }}>
                <img src={C0} alt="C0" />
              </Stack>
              <br />

              <li>
                Calendar here has the provision for maintaining 3 types of
                calendar i.e Resource calendar, Project calendar, Company
                calendar.
              </li>
              <li>
                User can set the dedicated working hours per day, can make any
                day as half working day, declare weekly off, etc.
              </li>
              <li>
                User can also create a template of any existing calendar
                including same working days.
                <ul>
                  <li sx={{ paddingTop: "2rem" }}>
                    Following is the list of buttons with their functionality
                    and uses .
                  </li>
                </ul>
              </li>
              <li>
                <img style={{ width: "3rem" }} src={CA} alt="CA" />
                :- This button is used for adding the new calendar. User has to
                type the name of calendar in field “Calendar Name” and click on
                the “Add” button.
              </li>
              <li>
                <img style={{ width: "6rem" }} src={CB} alt="CB" />
                :- This button is used for Removing or Deleting the calendar,
                User has to select the calendar in the field “Existing Calendar”
                and click on button “Remove calendar”.
              </li>
              <li>
                Note: Existing calendar cannot be removed if the calendar is
                been used in any project.
              </li>
              <li>
                <img style={{ width: "5rem" }} src={CC} alt="CC" />
                :- This button is used for creating the template of the existing
                calendar, User should select the calendar in the field “Existing
                Calendar” and enter the new calendar name in the field “Calendar
                Template Name” field and click the button “Create a copy”
                template of the calendar will be created.
              </li>
              <li>
                <img style={{ width: "4rem" }} src={CD} alt="CD" />
                :- This button is used for adding the hours to each day of the
                selected calendar using the grid “Working Hour”. The Working
                time set for the calendar can be seen at the bottom of the
                screen. For setting the working time, the user should first
                select the calendar in the field “Existing Calendar” and then
                enter the required time in the grid and click on the “Add Hrs”
                button.
              </li>
              <li>
                <img style={{ width: "3rem" }} src={CE} alt="CE" />
                :- This button is used to clear all the data in the grid.</li>
              <li>
                <img style={{ width: "3rem" }} src={CF} alt="CF" />
                :- The click of this link displays the user the list of actions
                performed on the selected calendar. For checking the history,
                the user must click on this “History” link and select the
                calendar and click on the “Show Report” button, the list of
                actions will appear.
              </li>

              <br></br>
              <br></br>

              <Stack style={{ width: "30rem" }}>
                <img src={C1} alt="C1" />
              </Stack>

              <br />

              <li>
                Calendar view displayed in the below of the screen helps user to
                edit or reset the time of any day with the click of a respective
                date links.
              </li>
              <br />

              <Stack style={{ width: "35rem" }}>
                <img src={C2} alt="C2" />
              </Stack>
              <br />


              <li>
                For editing the time of any date or day user have to click on
                the date link(except date colored pink) and the below shown
                screen will appears.
              </li>
              <br />

              <Stack style={{ width: "30rem" }}>
                <img src={C3} alt="C3" />
              </Stack>
              <br />

              <li>
                In this screen, user can enter the required time in the blank
                text boxes and click on “Submit” button, which will change the
                time for that particular selected day and the color of this date
                will get changed to grey color which can be edited again.



                <ul>
                  <li
                    style={{ marginLeft: "1rem", textDecoration: "underline" }}
                  >
                    Following are the description of color indications given to
                    the dates in the grid :-
                  </li>
                  <br />
                </ul>
              </li>

              <li>
                <img src={CG} alt="CG" />
                :- Date without any color indicates that, the date has got no
                special working time or event and it is still available for
                editing.
              </li>
              <li>
                <img src={C4} alt="C4" />

                :- Date with this color in the grid indicates that the working
                time for this day has been changed and this can also be edited
                again.
              </li>
              <li>
                <img src={C5} alt="C5" />
                :- Date with this color in the grid indicates that this day is
                declared as holiday which cannot be changed or edited further.
              </li>
              <li>
                <img src={CH} alt="CH" />
                :- This button helps the user to add an event or holiday to the
                calendar. On click of this button, new window opens up with 4
                rules of adding up the holidays or event to the Day or week or
                Month or Year of the calendar.
                <Stack sx={{ paddingRight: "1rem" }} style={{ width: "30rem" }}>
                  <img src={C6} alt="C6" />
                </Stack>
                <ul>
                  <li
                    style={{ marginLeft: "1rem", textDecoration: "underline" }}
                  >
                    Following is the description and use of the Types of rules:-
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <img style={{ width: "6rem" }} src={C7} alt="C7" />
                :- Using this rule, user can create a rule which cannot be used
                multiple times i.e. A rule which is not reusable again. Here the
                user has to describe the Rule description and select the from
                and to date till which the Event or holiday will be observed and
                click on “OK”. Rule will be set for the selected dates.
                <Stack sx={{ paddingRight: "1rem" }} style={{ width: "25rem" }}>
                  <img src={C8} alt="C8" />
                </Stack>
                <br></br>
              </li>
              <li>Click of close button will close the Rule screen</li>
              <br></br>
              <li>
                <img style={{ width: "6rem" }} src={C9} alt="C9" />
                :- Using this rule, user can create a rule which can be applied
                on a weekly basis i.e. The defined rule will work on particular
                selected day of a week which can occur once in two or three or
                any defined number of week. Also one more provision given to
                this rule i.e. the user can also declare as half working day to
                the days selected while applying the defined rule. For creating
                half days, the user should click the check box “For Half Day”
                and enter the required time in the grid available
                <Stack sx={{ paddingRight: "1rem" }} style={{ width: "30rem" }}>
                  <img src={C10} alt="C10" />
                </Stack>
                <ul>
                  <li
                    style={{ marginLeft: "1rem", textDecoration: "underline" }}
                  >
                    Range of occurences can be defined in two ways:-
                  </li>
                </ul>
              </li>
              <br />

              <li>
                Using “Start By” & “End After” : - for using this, user should
                select the date in field “Start By” and mention the number of
                occurences, after which the Rule will get expired or become
                invalid.
              </li>

              <Stack sx={{ paddingRight: "1rem" }} style={{width:"30rem"}}>
                <img src={C11} alt="C11" />
              </Stack>

              <li>
                Using “Start By” & “End By” :- for using this, user should
                select the date in field ”Start By” and select the date in field
                ”End By” which means the rule should execute only in the date
                range specified and after the range ends, the rule will get
                expire or invalid.
              </li>

              <Stack sx={{ paddingRight: "1rem" }} style={{width:"30rem"}}>
                <img src={C12} alt="C12" />
              </Stack>

              <li>
                After all the parameters has been defined, the user should click
                on “OK” button to create the rule.
              </li>

              <li>Click of close button will close the Rule screen</li>
              <br />
              <li>

                <img style={{width:"6rem"}} src={C13} alt="C13" />
                :-Using this type of rule, user can create a rule which can be
                applied on a monthly basis i.e. The defined rule will work on
                particular selected day of a month which can occur once in two
                or three or any defined number of month
                <Stack sx={{ paddingRight: "1rem" }} style={{width:"25rem"}}>
                  <img src={C14} alt="C14" />
                </Stack>
              </li>
              <br></br>
              <li>
              Range of occurences can be defined in two ways:-
              </li>
              <br></br>

              <li>  Using “Start By” & “End After” :- for using this user should select the date in field “Start By” and mention the number of occurences after which the Rule will get expired or become invalid.</li>
              <Stack sx={{ paddingRight: "1rem" }} style={{width:"30rem"}}>
                <img src={C15} alt="C15" />
              </Stack>
              <br></br>

              <li> Using “Start By” & “End By” : for using this, user should select the date in field ”Start By” and select the date in field ”End By” which means the rule should execute only in the range specified and after the range ends, the rule will get expire / invalid.</li>

              <Stack sx={{ paddingRight: "1rem" }} style={{width:"30rem"}}>
                <img src={C16} alt="C16" />
              </Stack>


              <li>
              Click of close button will close the Rule screen
              </li>
              <li>
              :-Using this type of rule, user can create a rule which can be applied on a yearly basis i.e. The defined rule will work on particular selected day of a year which will occur once in every year.</li>

              <li>
              <img src={C17} alt="C17" />
                After all the parameters has been defined, the user should click
                on “OK” button to create the rule.
              </li>
              <Stack sx={{ paddingRight: "1rem" }} style={{width:"25rem"}}>
                <img src={C18} alt="C18" />
              </Stack>

              <li>All the added rules will be displayed in this screen.</li>
              <Stack sx={{ paddingRight: "1rem" }} style={{width:"25rem"}}>
                <img src={C19} alt="C19" />
              </Stack>

              <li>
              <img src={CI} alt="CI" />
                :-Delete Rule button can be used by the user to delete the
                created rule. For deleting the rule, the user must click on the
                check box and hit the “Delete Rule” button
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CalendarhelpPopup;
