import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import DRP1 from "../HelpPopups/images/DRP1.jpg";
import { Link } from "react-router-dom";
import HelpIndex from "./HelpIndex";

const DelayReportforParetohelpPopup = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              {isExpanded ? "Collapse it..." : "Expand it..."}
              <Link
                style={{ color: "blue", marginRight: "0.6rem", textDecoration: "underline" }}
                onClick={handleToggle}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>

        {isExpanded && (
          <Stack sx={{ paddingLeft: "1rem" }}>
            <HelpIndex />
          </Stack>
        )}
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <ul className="no-bullets" style={{fontSize: "14px"}} >
              <h3 style={{ marginLeft: "5rem", textDecoration: "underline" }}>DELAY REASON PARETO</h3>
              <br />
              <li>
                Delay reason pareto is the report in which user can trace the reasons of delaying the project, number of occurrences of the delay reason, so that it can be traced and prevented.
              </li>
              <li>Path : CCPM\Reports\Delay Reason Pareto
              </li>
              <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                <img src={DRP1} alt="DRP1" />
              </Stack>
              <li>
                In this reports there is the selection of division ,BU/SBU and project manager selection is there,

                after proper selection result comes depending on the reason of delay, No of occurrences of delay and cumulative percentage.
              </li>

              <br />
              <br />
            </ul>
          </Grid>
        </Grid> 
      </Grid>
    </>
  );
};

export default DelayReportforParetohelpPopup;
