import React, { useEffect, useState } from "react";
// import modules from "./RiskAndMitigationPlan.module.css";
import modules from "./../ccpm.module.css";
import Home from "../../../home/Home";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Stack,
  Typography,
  Popover,
  Paper,
} from "@mui/material";
import bread from "../../../Assets/bread.png";
import SampleSidebar from "../../../Landing/SampleSidebar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";
import {

  RMR_GET,
  RMR_SEARCH
} from "../../../Services/CCPM";
import loading from "../../../Assets/Loading_image.gif";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import "../ProjectPlan/CalendarStyles.css";
import ExcelJS from 'exceljs';

export default function RiskMonitorreport() {

  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState(0);
  const [selectedProjectManager, setSelectedProjectManager] = useState(0); // State for selected project manager
  const [selectedTriggerStatus, setSelectedTriggerStatus] = useState(0); // State for trigger status
  const [selectedRiskCategory, setSelectedRiskCategory] = useState(0); // State for risk category
  const [tableData, setTableData] = useState([]);

  // Extracting data from responseData
  const projectManagers = responseData?.[0] || []; // Username data
  const projects = responseData?.[1] || []; // Project name data
  const triggerStatuses = responseData?.[2] || []; // Trigger status data
  const riskCategories = responseData?.[3] || []; // Risk category data

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);

    GetRiskData();
  }, []);

  const GetRiskData = () => {
    const loginId = sessionStorage.getItem("loginId");
    const RoleId = sessionStorage.getItem("roleid");
    const body = {
      userId: loginId,
      roleId: RoleId,
    };

    RMR_GET(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const responseData = response?.data?.success?.data;
          setResponseData(responseData);
          setTableData(responseData?.[4] || []); // Set initial table data
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const body = {
      managerID: selectedProjectManager || 0,
      projectID: selectedProjectName || 0,
      triggerStatus: selectedTriggerStatus || 0,
      riskCategory: selectedRiskCategory || 0,
    };

    RMR_SEARCH(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const tableData = response?.data?.success?.data;
          if (!tableData.length) {
            setTableData([]); // Set to empty array
            alert("No records found."); // Alert for no records
          } else {
            setTableData(tableData);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleExportToExcel = () => {
    exportToExcel(tableData);
  };

  const exportToExcel = async (tableData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Risk Report');

    // Add column headers
    const headerRow = worksheet.addRow([
      "Project", "Project Manager", "Risk", "Risk Owner", "Risk Category",
      "Priority Ranking", "Date on which action taken", "Trigger Status",
      "Mitigation Action To Control Risk", "Current Status/ Remarks"
    ]);

    // Set column widths
    worksheet.columns = [
      { width: 30 }, // Project
      { width: 25 }, // Project Manager
      { width: 30 }, // Risk
      { width: 25 }, // Risk Owner
      { width: 20 }, // Risk Category
      { width: 15 }, // Priority Ranking
      { width: 20 }, // Date on which action taken
      { width: 20 }, // Trigger Status
      { width: 40 }, // Mitigation Action To Control Risk
      { width: 25 }  // Current Status/ Remarks
    ];

    // Make header row bold and apply bold borders
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.border = {
        top: { style: 'thick' },
        bottom: { style: 'thick' },
        left: { style: 'thick' },
        right: { style: 'thick' }
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    });

    // Add data rows
    tableData.forEach((item, index) => {
      const mainRow = [
        item.project || item["Project"] || "",
        item.projectmanager || item["Project Manager"] || "",
        item.risk || item["Risk"] || "",
        item.riskowner || item["Risk Owner"] || "",
        item.riskcategory || item["Risk Category"] || "",
        item.priorityranking || item["Priority Ranking"] || "",
        item.date ? new Date(item.date).toLocaleDateString() : item["Date on which action taken"] || "",
        item.triggerstatus || item["Trigger status"] || item["Trigger Status"] || "",  // Fix the key here
        item.mitigation || item["Mitigation action to control Risk"] || "",
        item.status || item["Current status/ Remarks"] || ""
      ];
      

      // Add the main row and apply bold borders
      const row = worksheet.addRow(mainRow);

      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.alignment = { wrapText: true };
      });

      // Adjust row height to fit the content if necessary
      row.height = 30;
    });

    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'RiskDetailReport.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };



  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div style={{ overflowX: "auto", height: "100vh" }}>
        <Grid container>
          <Grid item lg={0.7} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>
          <Grid item lg={12}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"RiskMonitorreport"} />
            </Grid>

            <Grid item lg={12}>
              <div className={modules["RM_div"]}>
                <div
                  className={modules["pro-bread"]}
                  style={{ marginLeft: "-3.5rem" }}
                >
                  <a>Risk Management</a>
                  <img src={bread} alt="" />
                  <a>Risk Monitor Report</a>
                </div>
              </div>

              {responseData.length > 0 && (
                <Grid container style={{width:'100%'}}
                className={modules["mid-contRMP"]}>
                  <Grid
                    container
                    sx={{ marginLeft: "6.5rem", width: "80%", marginTop: "3rem", border: '2px solid black' }}

                  // ref={parentRef}
                  >
                    <table style={{ width: '100%' }} className={modules["PCtable"]}>
                      <tbody style={{ width: '100%' }}>
                        <tr
                          style={{
                            padding: "5px 15px",

                            justifyContent: "space-between",
                          }}
                        >
                          {/* Project Manager Dropdown */}
                          <td className={modules["table_data3"]}>
                            <span style={{ width: "45%" }}>Project Manager:</span>
                            <select
                              value={selectedProjectManager}
                              onChange={(e) => setSelectedProjectManager(e.target.value)}
                              className={modules["RiskSelect1"]}
                              style={{ width: "55%" }}
                            >
                              <option value={0}>Select Project Manager</option>
                              {projectManagers.map((manager) => (
                                <option key={manager.id} value={manager.id}>
                                  {manager.username}
                                </option>
                              ))}
                            </select>
                          </td>


                          {/* Project Dropdown */}
                          <td className={modules["table_data3"]}>
                            <span style={{ width: "45%" }}>Project:</span>
                            <select
                              value={selectedProjectName}
                              onChange={(e) => setSelectedProjectName(e.target.value)}
                              className={modules["RiskSelect1"]}
                              style={{ width: "55%" }}
                            >
                              <option value={0}>Select Project</option>
                              {projects.map((p_name) => (
                                <option key={p_name.id} value={p_name.id}>
                                  {p_name.projectname}
                                </option>
                              ))}
                            </select>
                          </td>

                        </tr>

                        <tr
                          style={{
                            padding: "5px 15px",

                            justifyContent: "space-between",
                          }}
                        >
                          {/* Trigger Status Dropdown */}
                          <td className={modules["table_data3"]}>
                            <span style={{ width: "45%" }}>Trigger Status:</span>
                            <select
                              value={selectedTriggerStatus}
                              onChange={(e) => setSelectedTriggerStatus(e.target.value)}
                              className={modules["RiskSelect1"]}
                              style={{ width: "55%" }}
                            >
                              <option value={0}>Select Trigger Status</option>
                              {triggerStatuses.map((status) => (
                                <option key={status.id} value={status.id}>
                                  {status.triggerstatus}
                                </option>
                              ))}
                            </select>
                          </td>

                          {/* Risk Category Dropdown */}
                          <td className={modules["table_data3"]}>
                            <span style={{ width: "45%" }}>Risk Category:</span>
                            <select
                              value={selectedRiskCategory}
                              onChange={(e) => setSelectedRiskCategory(e.target.value)}
                              className={modules["RiskSelect1"]}
                              style={{ width: "55%" }}
                            >
                              <option value={0}>Select Risk Category</option>
                              {riskCategories.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.riskcategory}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>

                        <tr
                          style={{
                            justifyContent: "center",
                            gap: "10px",
                            paddingLeft: "0px",
                            paddingBottom: "10px",
                          }}
                        >
                          <button
                            style={{
                              height: "26px",
                              width: "80",
                              padding: "0px 15px",
                            }}
                            className={modules["PCbtn"]}
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                          {/* <button className={modules["PCbtn"]}>Reset</button>
                        <button className={modules["PCbtn"]}>
                          Export To Excel
                        </button> */}
                        </tr>
                      </tbody>
                    </table>
                  </Grid>

                  <Grid container sx={{ marginLeft: "6.5rem", width: "80%", marginTop: "3rem" }}>
                    {tableData.length > 0 && (
                      <>
                        <Table className={modules["custom-tableRMR"]} style={{ border: "2px solid black" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox">Project</TableCell>
                              <TableCell>Project Manager</TableCell>
                              <TableCell>Risk</TableCell>
                              <TableCell>Risk Owner</TableCell>
                              <TableCell>Risk Category</TableCell>
                              <TableCell>Priority Ranking</TableCell>
                              <TableCell>Date on which action taken</TableCell>
                              <TableCell>Trigger Status</TableCell>
                              <TableCell>Mitigation Action To Control Risk</TableCell>
                              <TableCell>Current Status/Remarks</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
  {tableData?.map((item, index) => (
    <TableRow key={index}>
      <TableCell>{item.project || ""}</TableCell>
      <TableCell>{item.projectmanager || item["Project Manager"] || ""}</TableCell>
      <TableCell>{item.risk || ""}</TableCell>
      <TableCell>{item.riskowner || ""}</TableCell>
      <TableCell>{item.riskcategory || item["Risk Category"] || ""}</TableCell>
      <TableCell>{item.priorityranking || item["Priority Ranking"] || ""}</TableCell>
      <TableCell>{item.date ? new Date(item.date).toLocaleDateString() : item["Date on which action taken"] || ""}</TableCell>
      <TableCell>{item.triggerstatus || item["Trigger status"] || ""}</TableCell>
      <TableCell>{item.mitigation || item["Mitigation action to control Risk"] || ""}</TableCell>
      <TableCell>{item.status || item["Current status/ Remarks"] || ""}</TableCell>
    </TableRow>
  ))}
</TableBody>
                        </Table>
                        <Grid container style={{ width: '75%' }} className={modules["btn1-cont_export"]}>
                          <button className={modules["btn1"]} onClick={handleExportToExcel}>Export To Excel</button>
                        </Grid>
                      </>
                    )}
                  </Grid>  {/* <-- Closing tag for the outer Grid */}



                </Grid>


              )}
            </Grid>

          </Grid>
        </Grid>
      </div>
    </>
  );
}
