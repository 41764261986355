import React, { useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../../Assets/godrej_logo.gif";
import prostream from "../../../Assets/prostream_logo.png";
import DBMS1 from "../HelpPopups/images/DBMS1.png";
import DBMS2 from "../HelpPopups/images/DBMS2.png";

import { Link } from "react-router-dom";
import HelpIndex from "../../HelpPopups/HelpIndex";

const DBMSuspensionhelpPopup = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ paddingTop: "1rem" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <img src={logo} alt="Godrej" style={{ width: "120px" }} />
                        <span style={{ paddingTop: "2rem" }}>
                            {isExpanded ? "Collapse it..." : "Expand it..."}
                            <Link
                                style={{
                                    color: "blue",
                                    marginRight: "0.6rem",
                                    textDecoration: "underline",
                                }}
                                onClick={handleToggle}
                            >
                                User Manual Index
                            </Link>
                        </span>
                    </Stack>

                    <Stack sx={{ paddingRight: "1rem" }}>
                        <img src={prostream} alt="Prostream" />
                    </Stack>
                </Stack>
                <Stack></Stack>

                {isExpanded && (
                    <Stack sx={{ paddingLeft: "1rem" }}>
                        <HelpIndex />
                    </Stack>
                )}
            </div>

            <Grid>
                <Grid container className="parent-container">
                    <Grid item xs={12} className="child-container">
                        <ul style={{ fontSize: "14px" }} >
                            <h2 style={{ marginLeft: "0rem", textAlign: "left" }}>
                                DBM SUSPENSION
                            </h2>
                            <br />
                            <br />


                            <li className="no-bullets">
                                Session  Path {">>"} MTA {">>"} MTA Parameter {">>"} DBM Suspension
                            </li>
                            <br />
                            <li className="no-bullets">
                                DBM suspension is used for not considering that item during DBM run. So, there will not be any suggestions given for that item or stock point. This is applicable till the suspension for that item is removed.

                            </li>
                            <br />
                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={DBMS1} alt="DBMS1" />
                            </Stack>
                            <br />
                            <li >
                                ü  MTA Instance – This field indicate MTA instance. Select your Instance.


                            </li>
                            <br />
                            <br />
                            <li >
                                ü  Stock Point - This field indicate stock point. Select Stock point name from dropdown available for the field.


                            </li>
                            <br />
                            <br />
                            <li >
                                ü  Click search. Then following screen is opened where you need to maintain define values.
                            </li>
                            <br />

                            <br />

                            <Stack sx={{ paddingRight: "1rem", width: "40rem" }}>
                                <img src={DBMS2} alt="DBMS2" />
                            </Stack>
                            <br />

                            <li >
                                For existing data details can be inserted for instance, stock point and company code. Once clicked on search available data will be displayed as shown above.

                            </li>
                            <li >
                                User can edit, add or delete the existing data.
                            </li>
                            <li >
                                Logic will be if only product class is selected then all item for that product class will be suspended for the selected stock point. Else user can select item code individually and suspension will happen only for the selected item code for the selected stock point
                            </li>

                            <br />
                            <br />
                            <br />
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default DBMSuspensionhelpPopup;
