import React, { useState } from "react";
import { Box, Typography, TextField, Button, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [userId, setUserId] = useState("");
  const [emailId, setEmailId] = useState("");

  return (
    <>
      <Stack
        sx={{ width: "100%", paddingTop: "2%" }}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "25%",
            border: "2px solid #000000",
            padding: "1% 2% 2% 2%",
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ marginBottom: "1rem" }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontFamily: "serif" }}>
                User ID:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                variant="outlined"
                fullWidth
                size="small"
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  height: "1.4rem",
                  padding: "0px",
                  lineHeight: "14px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  width: "100%",
                  borderRadius: "0px",
                  marginBottom: "1.5vh",
                  color: " #00000080",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1px",
                    "& fieldset": {
                      borderColor: "#000000",
                    },
                    "&:hover fieldset": {
                      borderColor: "#000000",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#000000",
                    },
                    // Adjusting styles for the input element to prevent inner round box appearance
                    "& input": {
                      padding: "0px",
                      fontFamily: "serif", // Adjust padding as needed
                    },
                  },
                  // Additional style adjustments for focused state if necessary
                  "& .Mui-focused": {
                    "& .MuiOutlinedInput-input": {
                      borderRadius: "0px", // Ensuring the borderRadius is consistent
                      // Further style adjustments can be made here
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ marginBottom: "1rem" }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontFamily: "serif" }}>
                Email ID:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
                variant="outlined"
                fullWidth
                size="small"
                sx={{
                  fontSize: "12px",
                  height: "1.4rem",
                  fontWeight: "400",
                  lineHeight: "10px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  width: "100%",
                  borderRadius: "0px",
                  marginBottom: "1.5vh",
                  color: " #00000080",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1px",
                    "& fieldset": {
                      borderColor: "#000000",
                    },
                    "&:hover fieldset": {
                      borderColor: "#000000",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#000000",
                    },
                    // Adjusting styles for the input element to prevent inner round box appearance
                    "& input": {
                      padding: "0px", // Adjust padding as needed
                      fontFamily: "serif",
                    },
                  },
                  // Additional style adjustments for focused state if necessary
                  "& .Mui-focused": {
                    "& .MuiOutlinedInput-input": {
                      borderRadius: "0px", // Ensuring the borderRadius is consistent
                      // Further style adjustments can be made here
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <a
            href="/"
            style={{
              color: "blue",
              textDecoration: "underline",
              fontFamily: "serif",
            }}
          >
            Go back to Login Page
          </a>
        </Stack>
      </Stack>
    </>
  );
};

export default ForgotPassword;
