
import React, { useEffect, useState } from "react";
import "./HelpPopup.css";
import Stack from "@mui/material/Stack";
import { TextField, Typography, Grid } from "@mui/material"; // Import Grid from @mui/material
import logo from "../../Assets/godrej_logo.gif";
import prostream from "../../Assets/prostream_logo.png";
import IC1 from "../HelpPopups/images/IC1.jpg";
import IC2 from "../HelpPopups/images/IC2.jpg";
import IC3 from "../HelpPopups/images/IC3.jpg";
import IC4 from "../HelpPopups/images/IC4.jpg";
import IAL1 from "../HelpPopups/images/IAL1.jpg";
import IAL2 from "../HelpPopups/images/IAL2.jpg"

import { Link } from "react-router-dom";

const InstanceAttributeMappinghelpPopup= () => {
  return (
    <>
    <div>
        <Stack
          direction="row"
          spacing={4}
          sx={{ paddingTop: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ paddingLeft: "1rem" }}>
            <img src={logo} alt="Godrej" style={{width:"120px"}} />
            <span style={{ paddingTop: "2rem" }}>
              Expand it...
              <Link
                style={{
                  color: "blue",
                  marginRight: "0.6rem",
                  textDecoration: "underline",
                  fontWeight:'bold'
                }}
              >
                User Manual Index
              </Link>
            </span>
          </Stack>

          <Stack sx={{ paddingRight: "1rem" }}>
            <img src={prostream} alt="Prostream" />
          </Stack>
        </Stack>
        <Stack></Stack>
      </div>

      <Grid>
        <Grid container className="parent-container">
          <Grid item xs={12} className="child-container">
            <span style={{fontSize:'26px',fontFamily:"sans-serif"}}>
              INSTANCE ATTRIBUTE LINKING
            </span>
            <br></br>
            <br></br>
            <p style={{}}>
            In instance attribute linking user can add attributes to the instance. This Attributes are used for further drill down of the project for analysis. User can add up maximum 10 attribute to one instance. User need to add attributes. User can decide attributes depending on the business.
            </p>
            <br></br>
          
            <ul>
            <p style={{fontSize:'18px',fontFamily:"sans-serif"}}> <strong>Session Path – CCPM {'>>'} Master {'>>'} Instance Attribute Linking </strong></p>
              <br></br>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IAL1} alt="IC1" />
              </Stack>
              <li>Instance – Instance is mandatory field. User can select the instance from the dropdown filter provided.
              </li>
               <br></br>
              <Stack sx={{ paddingRight: "1rem" }}>
                <img src={IAL2} alt="IC1" />
              </Stack>
          

             
              <li>
              Sr. No – This indicates the attribute no. Maximum 10 attributes user can define.
              </li>
              <li>
              Attribute Name – User can define Attribute name.
              </li>

              <li>
              Active – Active is indication that the attribute is in use means it is active or it is not in active. User can mark the status for the attributes.
              </li>

              <li>
              Edit – user can edit the name of attribute after adding it in the instance. This gives user feasibility for editing any errors occurred while adding the attribute.
              </li>

            </ul>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InstanceAttributeMappinghelpPopup;
