import React, { useState, useEffect } from "react";
import bread from "../../../Assets/bread.png";
import Home from "../../../home/Home";
import { Grid, Typography, Stack, FormControl } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SideBar from "../../../Landing/Navbar";
import modules from "../ccpm.module.css";
import loading from "../../../Assets/Loading_image.gif";
import Checkbox from "@mui/material/Checkbox";

import SampleSidebar from "../../../Landing/SampleSidebar";
import {
  getCompanyName,
  GetTableDetails,
  UpdateDetail,
  DeleteInstance,
  InseertNewInstance,
} from "../../../Services/Admin";

import {
  DM_GetInstances,
  DM_Modify,
  DM_SearchButton,
} from "../../../Services/CCPM";

const DepartmentMaster = () => {
  const [searchValue, setSearchValue] = useState("");
  const [type, setType] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [newsearchValue, setNewSearchValue] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [editing, setEditing] = useState(null);
  const [editedDepartmentName, setEditedDepartmentName] = useState("");
  const [editedCapacity, setEditedCapacity] = useState("");
  const [isAddRowVisible, setIsAddRowVisible] = useState(false);
  const [NewDepartment, setNewDepartment] = useState("");
  const [newCapacity, setnewCapacity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [checked, setChecked] = useState(1);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorMessage4, setErrorMessage4] = useState("");
  const [selectedInstance, setSelectedInstance] = useState("");
  const [dropdownData, setDropdownData] = useState([]);

  const [isChecked, setIsChecked] = useState(1);
  const [showResult, setShowResult] = useState(false);
  const [addClicked, setAddClicked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(isChecked === 1 ? 0 : 1);
  };

  const handleCheckboxChange2 = (event, id) => {
    setChecked(checked === 1 ? 0 : 1);
  };

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    getInstances();
  }, []);

  const getInstances = () => {
    setIsLoading(true);
    const loginId = sessionStorage.getItem("loginId");
    const body = {
      userId: loginId,
    };
    if (loginId) {
      // console.log("Fetching data with loginId:", loginId);
      DM_GetInstances(body)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setDropdownData(response?.data?.success?.data);
            setIsLoading(false);
            if (response?.data?.success?.data.length > 0) {
              const defaultInstanceId = response?.data?.success?.data[0]?.id;
              // fetchAttributeHierarchyData(defaultInstanceId);
            }
          } else {
            console.log(
              `Unexpected response status: ${response?.data?.status}`
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching instance names:", error);
          //   setFilteredData(DummyData);
        });
    }
  };

  const getTableData = () => {
    // console.log(selectedInstance, "yvg");

    if (selectedInstance === "") {
      alert("Instance is not selected.");
      setShowResult(false);
      return;
    } else {
      setIsLoading(true);
      const body = {
        instanceId: parseInt(selectedInstance),
        searchDepartment: searchValue,
        userId: sessionStorage.getItem("loginId"),
      };
      DM_SearchButton(body)
        .then((response) => {
          setFilteredData(response?.data?.success?.data);
          setShowResult(true);
          // console.log(response?.data?.success?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function handleSaveEdit(I_id, element) {
    element.style.color = "red";
    setTimeout(() => {
      element.style.color = "#0000EE";

      setTimeout(() => {
        if (changed === false) {
          alert("No change to Update.");
        } else if (changed === true) {
          const body = {
            _instanceid: parseInt(selectedInstance),
            _department: editedDepartmentName,
            _capacity: editedCapacity.length > 10 ? 0 : parseInt(editedCapacity),
            _isactive: checked,
            _id: I_id,
            _userid: sessionStorage.getItem("loginId"),
            _action: "Update",
          };

          if (editedDepartmentName.trim() !== "" && editedCapacity !== "") {
            DM_Modify(body)
              .then((response) => {
                if (response?.data?.statusCode === 200) {
                  alert("Details Updated successfully");
                  setEditing(null);
                  getTableData();
                  // setDeleted(!deleted);
                  setChanged(false);
                  setAddClicked(false);
                } else if (response?.response?.data?.statusCode === 400) {
                  alert(
                    response?.response?.data?.error?.message ||
                      " Please try again."
                  );
                  setChanged(false);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else if (
            editedDepartmentName.trim() === "" &&
            editedCapacity === ""
          ) {
            setErrorMessage4("*Enter Value");
            setErrorMessage3("*Enter Value");
            element.style.color = "#0000EE";
          } else if (editedDepartmentName.trim() === "") {
            setErrorMessage3("*Enter Value");
            element.style.color = "#0000EE";
          } else if (editedCapacity.trim() === "") {
            setErrorMessage4("*Enter Value");
            element.style.color = "#0000EE";
          }
        }
      }, 50);
    }, 50);
  }

  function handleCancelEdit(element) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(null);
      element.style.color = "#0000EE";
      setErrorMessage3("");
      setErrorMessage4("");
      setAddClicked(false);
    }, 50);
  }

  function handleEditSingleEntry(
    id,
    DepartmentName,
    capacity,
    active,
    element
  ) {
    element.style.color = "red";
    setTimeout(() => {
      setEditing(id);
      setEditedDepartmentName(DepartmentName);
      setEditedCapacity(capacity);
      setChecked(active);
      setAddClicked(true);
      // setErrorMessage1("");
      // setErrorMessage2("");
      element.style.color = "#0000EE";
    }, 100);
  }

  function handleDeleteSingleEntry(
    D_id,
    D_name,
    D_capacity,
    D_Active,
    element
  ) {
    element.style.color = "red";

    setTimeout(() => {
      if (window.confirm("Are you sure you want to delete this record?")) {
        let lastModifiedBy = sessionStorage.getItem("loginId");
        const body = {
          _instanceid: parseInt(selectedInstance),
          _department: D_name,
          _capacity: parseInt(D_capacity),
          _isactive: D_Active,
          _id: D_id,
          _userid: sessionStorage.getItem("loginId"),
          _action: "Delete",
        };
        DM_Modify(body)
          .then((response) => {
            alert("Details Deleted successfully");
            getTableData();
            setAddClicked(false);
            element.style.color = "#0000EE";
          })
          .catch((error) => {
            console.log(error);
            element.style.color = "#0000EE";
          });
      } else {
        element.style.color = "#0000EE";
      }
    }, 100);
  }

  function handleNavigate(Id) {
    // window.location.href = "/admin/RolePermission";
    // window.sessionStorage.setItem("roleid", Id);
  }

  const handleReset = () => {
    setSelectedInstance("");
    setSearchValue("");
    setFilteredData([]);
    setShowResult(false);
    setIsAddRowVisible(false);
    setnewCapacity("");
    setNewDepartment("");
    setEditedCapacity("");
    setEditedDepartmentName("");
    setAddClicked(false);
    setErrorMessage1("");
    setErrorMessage2("");
    setErrorMessage3("");
    setErrorMessage4("");
  };

  function handleInsert() {
    setIsAddRowVisible(true);
    setIsChecked(1);
    setType("Insert");
    setAddClicked(true);
    setErrorMessage1("");
    setErrorMessage2("");
  }

  function handleSave(element) {
    element.style.color = "red";
    // console.log(changed, "changed");
    setIsLoading(true);

    setTimeout(() => {
      element.style.color = "#0000EE";
      setTimeout(() => {
        const body = {
          _instanceid: parseInt(selectedInstance),
          _department: NewDepartment,
          _capacity: newCapacity.length > 10 ? 0 : parseInt(newCapacity),
          _isactive: parseInt(isChecked),
          _id: 0,
          _userid: sessionStorage.getItem("loginId"),
          _action: type,
        };

        if (NewDepartment.trim() !== "" && newCapacity.trim() !== "") {
          DM_Modify(body)
            .then((response) => {
              if (response?.data?.statusCode === 200) {
                alert("Details Inserted successfully");
                setEditing(null);
                setDeleted(!deleted);
                setIsAddRowVisible(false);
                setNewDepartment("");
                setnewCapacity("");
                setIsChecked(1);
                setChanged(false);
                getTableData();
                setIsLoading(false);
                setAddClicked(false);
              } else if (response?.response?.data?.statusCode === 400) {
                getTableData();
                setIsLoading(false);
                alert(
                  response?.response?.data?.error?.message ||
                    " Please try again."
                );
                setChanged(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (NewDepartment.trim() === "" && newCapacity.trim() === "") {
          setErrorMessage2("*Enter Value");
          setErrorMessage1("*Enter Value");
          setIsLoading(false);
          element.style.color = "#0000EE";
        } else if (NewDepartment.trim() === "") {
          setErrorMessage1("*Enter Value");
          setIsLoading(false);
          element.style.color = "#0000EE";
        } else if (newCapacity.trim() === "") {
          setErrorMessage2("*Enter Value");
          setIsLoading(false);
          element.style.color = "#0000EE";
        }
      }, 50);
    }, 50);
  }

  function handleClose(element) {
    element.style.color = "red";
    setTimeout(() => {
      setIsAddRowVisible(false);
      setNewDepartment("");
      setnewCapacity("");
      setAddClicked(false);
      element.style.color = "#0000EE";
    }, 50);
    // setErrorMessage1("*");
    // setErrorMessage2("*");
  }

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <Grid container>
        <Grid item lg={0.6} className={modules["main_cont"]}>
          {/* <SideBar /> */}
          <SampleSidebar />
        </Grid>

        <Grid item lg={12} sx={{ display: "block" }}>
          <Grid item lg={12} className={modules["home-cont"]}>
            <Home page={"DepartmentMaster"} />
          </Grid>

          <Grid item lg={12}>
            <div className={modules["instancemaster_div"]}>
              <div className={modules["pro-bread"]}>
                <a>Master</a>
                <img src={bread} alt="" />
                <a>Department Master</a>
              </div>
            </div>

            <Grid sx={{ marginLeft: "2rem" }}>
              <Grid container className={modules["mid-cont"]}>
                <Stack
                  direction="row"
                  style={{ alignItems: "center", marginBottom: "1rem" }}
                >
                  <Typography
                    className={modules["label-style"]}
                    sx={{ fontWeight: "bold" }}
                  >
                    Instance :
                  </Typography>
                  <FormControl>
                    <select
                      value={selectedInstance}
                      onChange={(e) => {
                        setSelectedInstance(e.target.value);
                      }}
                      className={modules["d_InstanceSelect"]}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                      disabled={addClicked} // Add this line to conditionally disable the dropdown
                    >
                      <option value={"SELECT"}>SELECT</option>
                      {dropdownData.map((i) => {
                        return (
                          <option
                            className={
                              modules["instanceAttMap_dropdownSelect_menu"]
                            }
                            value={i.id}
                            key={i.name}
                          >
                            {i.name}
                          </option>
                        );
                      })}
                    </select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid container className={modules["mid-cont"]}>
                <Stack
                  style={{ alignItems: "center", marginBottom: "1rem" }}
                  direction="row"
                >
                  <Typography
                    className={modules["label-style"]}
                    sx={{ fontWeight: "bold" }}
                  >
                    Department :
                  </Typography>
                  <span>
                    <input
                      value={searchValue}
                      onChange={(e) => {
                        const regex = /^[a-zA-Z0-9 &()_\-+=\[\]\/.]*$/;
                        if (
                          regex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setSearchValue(e.target.value);
                        }
                      }}
                      className={modules["input_css"]}
                      style={{
                        border: "1px solid #9d9d9c !important",
                        marginLeft: "2rem",
                      }}
                      disabled={addClicked}
                    />
                  </span>
                </Stack>
              </Grid>

              <Grid
                container
                className={modules["instance_btn1-cont"]}
                sx={{
                  marginLeft: "6rem",
                  marginTop: "0rem",
                  paddingBottom: "10px",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <button
                    className={modules["btn1"]}
                    onClick={getTableData}
                    disabled={addClicked}
                  >
                    Search
                  </button>
                  <button
                    className={modules["btn1"]}
                    onClick={handleReset}
                    disabled={addClicked}
                  >
                    Reset
                  </button>
                </Stack>
              </Grid>

              {showResult &&
                !isLoading &&
                (isAddRowVisible ||
                (filteredData && filteredData.length > 0) ? (
                  <Grid container className={modules["table-grid"]} sx={{}}>
                    <div
                      className={modules["instance_table-container"]}
                      style={{ width: "70%" }}
                    >
                      <Table className={modules["DM_custom-table"]}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Sr.No.</TableCell>
                            <TableCell>Department</TableCell>
                            <TableCell>Capacity</TableCell>
                            <TableCell>IsActive</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredData.map((item, index) => (
                            <TableRow
                              key={index}
                              className={modules["table-row"]}
                            >
                              <TableCell className={modules["table-cell"]}>
                                <span
                                  style={{
                                    color: "#414141",
                                  }}
                                >
                                  {index + 1}
                                </span>
                              </TableCell>
                              <TableCell className={modules["table-cell"]}>
                                {editing === item.id ? (
                                  <>
                                    <input
                                      type="text"
                                      value={editedDepartmentName}
                                      className={modules["rowinput_css"]}
                                      onChange={(e) => {
                                        setChanged(false);
                                        const regex =
                                          /^[a-zA-Z0-9 &()_\-+=\[\]\/.]*$/;
                                        let newValue = e.target.value;
                                        if (regex.test(newValue)) {
                                          newValue = newValue
                                            .toLowerCase()
                                            .trim();
                                          setEditedDepartmentName(
                                            e.target.value
                                          );
                                          setErrorMessage3("");
                                          if (
                                            newValue !==
                                            item.departmentname
                                              .toLowerCase()
                                              .trim()
                                          ) {
                                            setChanged(true);
                                          }
                                        }
                                      }}
                                      onBlur={(e) => {
                                        const alphanumericRegex =
                                          /^[a-zA-Z0-9 &()_-]*$/;
                                        if (!e.target.value) {
                                          setErrorMessage3("*Enter Value");
                                        } else if (
                                          !alphanumericRegex.test(
                                            e.target.value
                                          )
                                        ) {
                                          alert(
                                            "No special characters Allowed."
                                          );
                                          setEditedDepartmentName(
                                            item.departmentname
                                          );
                                        }
                                      }}
                                    />
                                    {errorMessage3 && (
                                      <div
                                        style={{
                                          display: "flex",
                                          position: "relative",
                                          color: "red",
                                          height: "2px",
                                          marginLeft: "4rem",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {errorMessage3}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div>
                                    <span
                                      style={{
                                        cursor: "text",
                                        color: "#414141",
                                      }}
                                      onClick={() =>
                                        handleNavigate(item.level2id)
                                      }
                                    >
                                      {item.departmentname}
                                    </span>
                                  </div>
                                )}
                              </TableCell>
                              <TableCell className={modules["table-cell"]}>
                                {editing === item.id ? (
                                  <>
                                    <input
                                      type="text"
                                      value={editedCapacity}
                                      className={modules["rowinput_css"]}
                                      onChange={(e) => {
                                        setChanged(false);
                                        const regex = /^[0-9]*$/; // Regular expression to allow only numbers
                                        let newValue = e.target.value;

                                        // Filter the input value to allow only numeric characters
                                        if (regex.test(newValue)) {
                                          newValue = newValue.trim();
                                          setErrorMessage4("");
                                          setEditedCapacity(e.target.value);
                                          if (newValue !== item.capacity) {
                                            setChanged(true);
                                          }
                                        }
                                      }}
                                      onBlur={(e) => {
                                        if (!e.target.value) {
                                          setErrorMessage4("*Enter Value");
                                        }
                                      }}
                                    />
                                    {errorMessage4 && (
                                      <div
                                        style={{
                                          display: "flex",
                                          position: "relative",
                                          color: "red",
                                          height: "2px",
                                          marginLeft: "4rem",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {errorMessage4}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div>
                                    <span
                                      style={{
                                        cursor: "text",
                                        color: "#414141",
                                      }}
                                      onClick={() =>
                                        handleNavigate(item.level2id)
                                      }
                                    >
                                      {item.capacity}
                                    </span>
                                  </div>
                                )}
                              </TableCell>
                              <TableCell className={modules["table-cell"]}>
                                {editing === item.id ? (
                                  <Checkbox
                                    sx={{
                                      color: "grey.600",
                                      "&.Mui-checked": {
                                        color: "blue",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 17,
                                      },
                                      "& .MuiTouchRipple-root": {
                                        display: "none",
                                      },
                                    }}
                                    checked={checked === 1}
                                    onChange={(event) => {
                                      handleCheckboxChange2(event);
                                      setChanged(true);
                                    }}
                                  />
                                ) : (
                                  <Checkbox
                                    sx={{
                                      color: "grey.600",
                                      "&.Mui-checked": {
                                        color: "blue",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 17,
                                      },
                                      "& .MuiTouchRipple-root": {
                                        display: "none",
                                      },
                                      opacity: 0.5,
                                    }}
                                    checked={item.isactive === 1}
                                    disabled={true}
                                  />
                                )}
                              </TableCell>
                              <TableCell className={modules["table-cell"]}>
                                {editing === item.id ? (
                                  <>
                                    <a
                                      style={{
                                        paddingRight: "1rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        handleSaveEdit(item.id, e.currentTarget)
                                      }
                                    >
                                      Update
                                    </a>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        handleCancelEdit(e.currentTarget)
                                      }
                                    >
                                      Cancel
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <a
                                      className={modules["actionCol"]}
                                      onClick={(e) => {
                                        setErrorMessage4("");
                                        setErrorMessage3("");
                                        handleEditSingleEntry(
                                          item.id,
                                          item.departmentname,
                                          item.capacity,
                                          item.isactive,
                                          e.currentTarget
                                        );
                                      }}
                                    >
                                      Edit
                                    </a>
                                    <a
                                      className={modules["actionCol"]}
                                      onClick={(e) =>
                                        handleDeleteSingleEntry(
                                          item.id,
                                          item.departmentname,
                                          item.capacity,
                                          item.isactive,
                                          e.currentTarget
                                        )
                                      }
                                    >
                                      Delete
                                    </a>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}

                          {isAddRowVisible && (
                            <TableRow>
                              <TableCell
                                className={modules["table-cell"]}
                              ></TableCell>

                              <TableCell className={modules["table-cell"]}>
                                <input
                                  type="text"
                                  value={NewDepartment}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex =
                                      /^[a-zA-Z0-9 &()_\-+=\[\]\/.]*$/;
                                    if (regex.test(value)) {
                                      setNewDepartment(value);
                                      setErrorMessage1("");
                                    }
                                  }}
                                  onBlur={(e) => {
                                    const alphanumericRegex =
                                      /^[a-zA-Z0-9 &()_-]*$/;
                                    if (!e.target.value) {
                                      setErrorMessage1("*Enter Value");
                                    } else if (
                                      !alphanumericRegex.test(e.target.value)
                                    ) {
                                      alert("No special characters Allowed.");
                                      setNewDepartment("");
                                    }
                                  }}
                                  className={modules["rowinput_css"]}
                                />
                                {errorMessage1 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      position: "relative",
                                      color: "red",
                                      height: "2px",
                                      marginLeft: "4rem",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {errorMessage1}
                                  </div>
                                )}
                              </TableCell>

                              <TableCell className={modules["table-cell"]}>
                                <input
                                  style={{ border: "1px solid light-dark" }}
                                  type="text"
                                  value={newCapacity}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^[0-9]*$/;
                                    if (regex.test(value)) {
                                      setnewCapacity(value);
                                      setErrorMessage2("");
                                    }
                                  }}
                                  onBlur={(e) => {
                                    if (!e.target.value) {
                                      setErrorMessage2("*Enter Value");
                                    }
                                  }}
                                  className={modules["rowinput_css"]}
                                />
                                {errorMessage2 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      position: "relative",
                                      color: "red",
                                      height: "2px",
                                      marginLeft: "4rem",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {errorMessage2}
                                  </div>
                                )}
                              </TableCell>

                              <TableCell className={modules["table-cell"]}>
                                <Checkbox
                                  sx={{
                                    color: "grey.600",
                                    "&.Mui-checked": {
                                      color: "blue",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 17,
                                    },
                                    "& .MuiTouchRipple-root": {
                                      display: "none",
                                    },
                                  }}
                                  checked={isChecked === 1}
                                  onChange={handleCheckboxChange}
                                />
                              </TableCell>

                              <TableCell className={modules["table-cell"]}>
                                <a
                                  style={{
                                    color: "#0000EE",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => handleSave(e.currentTarget)}
                                >
                                  Save
                                </a>

                                <a
                                  style={{
                                    color: "#0000EE",
                                    textDecoration: "underline",
                                    marginLeft: "0.6rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => handleClose(e.currentTarget)}
                                >
                                  Cancel
                                </a>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>

                      {!editing && !isAddRowVisible && (
                        <Grid container>
                          <button
                            className={modules["IM_btn"]}
                            onClick={handleInsert}
                          >
                            Add
                          </button>
                        </Grid>
                      )}
                    </div>
                  </Grid>
                ) : (
                  <>
                    <TableRow
                      className={modules["table-cell"]}
                      sx={{
                        textAlign: "left !important",
                        display: "flex",
                        borderBottom: "1px solid #a4bf47",
                        marginLeft: "6rem",
                        marginTop: "2rem",
                        fontSize: "12px",
                        color: "#414141",
                        padding: "8px",
                        width: "70%",
                      }}
                    >
                      No Record Found.
                    </TableRow>
                    {!editing && !isAddRowVisible && (
                      <Grid container className={modules["mid-cont"]}>
                        <button
                          className={modules["IM_btn"]}
                          onClick={handleInsert}
                        >
                          Add
                        </button>
                      </Grid>
                    )}
                  </>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DepartmentMaster;
