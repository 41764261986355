import React, { useEffect, useState } from "react";
import modules from "./../ccpm.module.css";
import Home from "../../../home/Home";
import { Grid } from "@mui/material";
import bread from "../../../Assets/bread.png";
import SampleSidebar from "../../../Landing/SampleSidebar";
import { useLocation } from "react-router-dom";
import { ToSaveAndRelease, GetActionOwnerData } from "../../../Services/CCPM";
import loading from "../../../Assets/Loading_image.gif";
import "react-calendar/dist/Calendar.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../ProjectPlan/CalendarStyles.css";

export default function RiskActionOwner() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projId = searchParams.get("projId");
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [descriptions, setDescriptions] = useState({});

  useEffect(() => {
    const checkLoginAndRedirect = () => {
      const loginId = sessionStorage.getItem("loginId");
      if (!loginId) {
        window.location.href = "/";
      }
    };
    checkLoginAndRedirect();
    setIsLoading(true);
    GetOwnerData();
  }, []);

  const GetOwnerData = () => {
    const loginId = parseInt(sessionStorage.getItem("loginId"), 10);
    const body = {
      assignedToId: loginId,
    };
    GetActionOwnerData(body)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTableData(response?.data?.success?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSaveRelease = (a_id) => {
    console.log(descriptions[a_id]);
    
    const body = {
      actionid: parseInt(a_id),
      actionTakenRemark: descriptions[a_id] || "",
      lastModifiedBy: parseInt(sessionStorage.getItem("loginId")),
    };
    ToSaveAndRelease(body)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        console.log(response?.data?.success?.data);
        alert("Details Updated Successfully");
        GetOwnerData();
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  // const handleSave = (a_id) => {
  //   const body = {
  //     actionid: parseInt(a_id),
  //     actionTakenRemark: descriptions[a_id] || "",
  //     lastModifiedBy: parseInt(sessionStorage.getItem("loginId")),
  //   };
  //   ToSaveAndRelease(body)
  //     .then((response) => {
  //       if (response?.data?.statusCode === 200) {
  //         console.log(response?.data?.success?.data);
  //         alert("Details Updated Successfully");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const handleDescriptionChange = (a_id, value) => {
    const regex = /^[a-zA-Z0-9&()_\-+=?/ \[\].]*$/;
    if (regex.test(value)) {
      setDescriptions((prev) => ({
        ...prev,
        [a_id]: value,
      }));
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div style={{ overflowX: "auto", height: "100vh" }}>
        <Grid container>
          <Grid item lg={0.7} className={modules["main_cont"]}>
            <SampleSidebar />
          </Grid>
          <Grid item lg={12}>
            <Grid item lg={12} className={modules["home-cont"]}>
              <Home page={"RiskActionOwner"} />
            </Grid>

            <Grid item lg={12}>
              <div className={modules["RM_div"]}>
                <div
                  className={modules["pro-bread"]}
                  style={{ marginLeft: "-3.5rem" }}
                >
                  <a>Risk Management</a>
                  <img src={bread} alt="" />
                  <a>Risk Mitigation Action</a>
                </div>
              </div>

              <Grid container className={modules["mid-contRMP"]}>
                <>
                  <Grid
                    container
                    sx={{
                      marginLeft: "6.5rem",
                      padding: "8px",
                      width: "80%",
                      marginTop: "3rem",
                    }}
                  >
                  {tableData.length > 0 ? (
                    <Table className={modules["IM_custom-table"]}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr No</TableCell>
                          <TableCell>Project. Manager</TableCell>
                          <TableCell>Project</TableCell>
                          <TableCell>Risk Desc</TableCell>
                          <TableCell>Action By Date</TableCell>
                          <TableCell>Mitigation Action Plan</TableCell>
                          <TableCell>Action Taken Remark</TableCell>
                          <TableCell>Submit</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.projectmanager}</TableCell>
                            <TableCell>{item.projectname}</TableCell>
                            <TableCell>{item.risk}</TableCell>
                            <TableCell>
                              {formatDate(item.actionbydate)}
                            </TableCell>
                            <TableCell>{item.mitigationactionplan}</TableCell>
                            <TableCell>
                              <textarea
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "5px",
                                }}
                                value={descriptions[item.actionid] || ""}
                                onChange={(e) =>
                                  handleDescriptionChange(
                                    item.actionid,
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <button
                                type="submit"
                                style={{
                                  backgroundColor: "#0B76BC",
                                  color: "white",
                                  padding: "2px 6px",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  border: "none",
                                  width: "150px",
                                  fontFamily: "WorkSans, sans-serif",
                                }}
                                onClick={() => handleSaveRelease(item.actionid)}
                              >
                                Save And Release
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    ) : (
                      <div
                        style={{
                          color: "#414141",
                          // marginLeft: "7rem",
                          fontSize: "12px",
                          // marginTop: "20px",
                          padding: "8px",
                          borderBottom: "1px solid #a4bf47",
                        }}
                      >
                        No Records Found
                      </div>
                    )}
                  </Grid>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
