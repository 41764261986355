import axios from "axios";
import { MTANewInstance_CreateNewInstance, API_KEY } from "../Constants";
import {
  AI_ChangedAllClick,
  AI_GetBindInstances,
  AI_ChangedClick,
} from "../Constants";

import {
  MNI_MtaNewInstance,
  MNI_GetInstances,
  MNI_Get_StockPoint_Type,
  MNI_Update_StockPoint,
  MNI_MtaActiveUpdateAll,
  MNI_MtaActiveUpdateSingle,
  MNI_MtaActiveData,
  MNI_GetInstanceslinking,
  USPL_CheckUser,
  USPL_GetUserStockPoint,
  USPL_StockPointAccessSelection,
  CheckUser,
  UserStockPointAccessSelection,
  getUserStockPointSelection,
  userStockPointLinking,
  MNI_UpdateInstanceslinking,
  MTA_CheckStockPointExists,
  SP_Instances,
  GetMTA_SP_TypeDesc,
  MTA_SPInsertStockpoint,
  MTA_SPUpdateStockPoint,
  MTA_SPDeleteStockPoint,
  MTA_StockPointPopup,
  MTA_UserControl_update,
  MTA_UserControl,
  MTA_AdminControl_update,
  MTA_AdminControl,
  SPC_CheckStockPointExists,
  GetConfigValues,
  MTA_GetModeBasedParameter,
  MTA_DeleteStockPointConfiguration,
  MTA_UpdateStockPointConfiguration,
  MTA_InsertStockPointConfiguration,
  MTA_InsertStockPointWarehouse,
  MTA_UpdateStockPointWarehouse,
  MTA_DeleteStockPointWarehouse,
  MTA_CompanyCodePopup,
  MTA_GetWarehouseCode,
  MTA_IConsumption,
  MTA_UConsumption,
  MTA_DConsumption,
  MTA_GetInTransitCode,
  MTA_IInTransit,
  MTA_UInTransit,
  MTA_DInTransit,
  MTA_IgnoredAction,
  MTA_Update_ProposedAction,
  MTA_GetProposedAction,
  MTA_GetDBMData,
  MTA_GetItemCategoryPopup,
  MTA_GetSSGPopup,
  MTA_DBMLastRun,
  MTA_TOC_Item_Stock_Graph,
  GetInboundOrder,
  GetOrderStatus,
  GetRPLData,
  GetProductionData,
  GetSalesAllocationData,
  GetPurchaseData,
  GetBPPAndColorData,
  GetECommerceData,
  GetImosData,
  UploadMTAStockPointBuffer,
  Get_MTA_Stock_Point_Buffer,
  DownloadSampleFormat,
  Merge_MTA_Stock_Point_Buffer,
  Resend_Click,
} from "../Constants";

export async function MTANewInstance_SubmitHandler(newInstance, userId) {
  return axios
    .get(
      `${API_KEY}${MTANewInstance_CreateNewInstance}${newInstance}&UserId=${userId}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA Active /Inactive

export async function AI_ChangedAllClickHandler(userId, checked) {
  return await axios
    .get(`${API_KEY}${AI_ChangedAllClick}?UserId=${userId}&chkval=${checked}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AI_GetBindInstancesHandler(userId) {
  return await axios
    .get(`${API_KEY}${AI_GetBindInstances}${userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function AI_ChangedClickHandler(instance, userId, checked) {
  return await axios
    .get(
      `${API_KEY}${AI_ChangedClick}?instance=${instance}&UserId=${userId}&chkval=${checked}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA New Instance

export async function Set_MtaNewInstance(body) {
  return await axios
    .post(`${API_KEY}${MNI_MtaNewInstance}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA Instance Active/Inactive

export async function Set_MtaActiveData(body) {
  return await axios
    .post(`${API_KEY}${MNI_MtaActiveData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Set_MtaActiveUpdateSingle(body) {
  return await axios
    .post(`${API_KEY}${MNI_MtaActiveUpdateSingle}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Set_MtaActiveUpdateAll(body) {
  return await axios
    .post(`${API_KEY}${MNI_MtaActiveUpdateAll}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA User Stock Point Linking

export async function USPL_MtaCheckUser(body) {
  return await axios
    .post(`${API_KEY}${USPL_CheckUser}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function USPL_MtaGetUserStockPoint(body) {
  return await axios
    .post(`${API_KEY}${USPL_GetUserStockPoint}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function USPL_MtaAccessSelection(body) {
  return await axios
    .post(`${API_KEY}${USPL_StockPointAccessSelection}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA Stock Point Type

export async function Get_MtaInstances(body) {
  return await axios
    .post(`${API_KEY}${MNI_GetInstances}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MtaUpdate_StockPoint(body) {
  return await axios
    .post(`${API_KEY}${MNI_Update_StockPoint}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Get_MtaStockPoint_Type(body) {
  return await axios
    .post(`${API_KEY}${MNI_Get_StockPoint_Type}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA User Instance Linking

export async function Set_MtaIntancelinkingData(body) {
  return await axios
    .post(`${API_KEY}${MNI_GetInstanceslinking}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function CheckIsUser(body) {
  return await axios
    .post(`${API_KEY}${CheckUser}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function Update_MtaIntancelinkingData(body) {
  return await axios
    .post(`${API_KEY}${MNI_UpdateInstanceslinking}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function UserStockPointAccess(body) {
  return await axios
    .post(`${API_KEY}${UserStockPointAccessSelection}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA param --  StockPointConfig

export async function MTA_SPCCheckStockPointExists(body) {
  return await axios
    .post(`${API_KEY}${SPC_CheckStockPointExists}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SPC_GetConfigValues(body) {
  return await axios
    .post(`${API_KEY}${GetConfigValues}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MTA_SPCGetModeBased(body) {
  return await axios
    .post(`${API_KEY}${MTA_GetModeBasedParameter}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MTA_SPCDelete(body) {
  return await axios
    .post(`${API_KEY}${MTA_DeleteStockPointConfiguration}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MTA_SPCUpdate(body) {
  return await axios
    .post(`${API_KEY}${MTA_UpdateStockPointConfiguration}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MTA_SPCInsert(body) {
  return await axios
    .post(`${API_KEY}${MTA_InsertStockPointConfiguration}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

// --0-

export async function getUserStockPointSelectionData(body) {
  return await axios
    .post(`${API_KEY}${getUserStockPointSelection}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function userStockPointLinkingData(body) {
  return await axios
    .post(`${API_KEY}${userStockPointLinking}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA param Stock Point

export async function SP_MtaInstances(body) {
  return await axios
    .post(`${API_KEY}${SP_Instances}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SP_MtaStockpointtype(body) {
  return await axios
    .post(`${API_KEY}${GetMTA_SP_TypeDesc}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function SP_MTA_SPInsertStockpoint(body) {
  return await axios
    .post(`${API_KEY}${MTA_SPInsertStockpoint}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function SP_MTA_SPUpdateStockPoint(body) {
  return await axios
    .post(`${API_KEY}${MTA_SPUpdateStockPoint}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export async function SP_MTA_SPDeleteStockPoint(body) {
  return await axios
    .post(`${API_KEY}${MTA_SPDeleteStockPoint}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SP_MTA_eStockPointPOPUP(body) {
  return await axios
    .post(`${API_KEY}${MTA_StockPointPopup}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MTA_SSGPopup(body) {
  return await axios
    .post(`${API_KEY}${MTA_GetSSGPopup}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function MTA_ItemCategoryPopup(body) {
  return await axios
    .post(`${API_KEY}${MTA_GetItemCategoryPopup}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA param Control

export async function SP_MtaUserControl_update(body) {
  return await axios
    .post(`${API_KEY}${MTA_UserControl_update}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SP_MtaUserControl(body) {
  return await axios
    .post(`${API_KEY}${MTA_UserControl}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA param Admin Control

export async function SP_MtaAdminControl_update(body) {
  return await axios
    .post(`${API_KEY}${MTA_AdminControl_update}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SP_MtaAdminControl(body) {
  return await axios
    .post(`${API_KEY}${MTA_AdminControl}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA param StockPointWarehouse

export async function SP_MtaInsertSPW(body) {
  return await axios
    .post(`${API_KEY}${MTA_InsertStockPointWarehouse}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SP_MtaUpdateSPW(body) {
  return await axios
    .post(`${API_KEY}${MTA_UpdateStockPointWarehouse}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SP_MtaDeleteSPW(body) {
  return await axios
    .post(`${API_KEY}${MTA_DeleteStockPointWarehouse}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SP_MTA_CompanyCodePopup(body) {
  return await axios
    .post(`${API_KEY}${MTA_CompanyCodePopup}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA param StockPointWarehouse Consumption

export async function SPWC_MtaInsert(body) {
  return await axios
    .post(`${API_KEY}${MTA_IConsumption}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SPWC_MtaUpdate(body) {
  return await axios
    .post(`${API_KEY}${MTA_UConsumption}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SPWC_MtaDelete(body) {
  return await axios
    .post(`${API_KEY}${MTA_DConsumption}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SPWC_GetWarehouseCode(body) {
  return await axios
    .post(`${API_KEY}${MTA_GetWarehouseCode}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA param StockPointWarehouse InTransit

export async function SPWI_MtaInsert(body) {
  return await axios
    .post(`${API_KEY}${MTA_IInTransit}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SPWI_MtaUpdate(body) {
  return await axios
    .post(`${API_KEY}${MTA_UInTransit}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SPWI_MtaDelete(body) {
  return await axios
    .post(`${API_KEY}${MTA_DInTransit}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SPWI_GetInTransitCode(body) {
  return await axios
    .post(`${API_KEY}${MTA_GetInTransitCode}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA param DBM

export async function DBM_GetProposedAction(body) {
  return await axios
    .post(`${API_KEY}${MTA_GetProposedAction}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DBM_GetDBMData(body) {
  return await axios
    .post(`${API_KEY}${MTA_GetDBMData}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DBM_Update_ProposedAction(body) {
  return await axios
    .post(`${API_KEY}${MTA_Update_ProposedAction}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DBM_IgnoredAction(body) {
  return await axios
    .post(`${API_KEY}${MTA_IgnoredAction}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DBM_LastRunn(body) {
  return await axios
    .post(`${API_KEY}${MTA_DBMLastRun}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function DBM_TOC_Item_Stock_Graph(body) {
  return await axios
    .post(`${API_KEY}${MTA_TOC_Item_Stock_Graph}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA param Stock Point

export async function IOA_GetInboundOrder(body) {
  return await axios
    .post(`${API_KEY}${GetInboundOrder}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IOA_GetOrderStatus(body) {
  return await axios
    .post(`${API_KEY}${GetOrderStatus}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IOA_GetRPLData(body, type, page) {
  return await axios
    .post(`${API_KEY}${GetRPLData}?submit=${type}&pageNumber=${page}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IOA_GetProductionData(body, type, page) {
  return await axios
    .post(
      `${API_KEY}${GetProductionData}?submit=${type}&pageNumber=${page}`,
      body
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IOA_GetSalesAllocationData(body, type, page) {
  return await axios
    .post(
      `${API_KEY}${GetSalesAllocationData}?submit=${type}&pageNumber=${page}`,
      body
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IOA_GetPurchaseData(body, type, page) {
  return await axios
    .post(
      `${API_KEY}${GetPurchaseData}?submit=${type}&pageNumber=${page}`,
      body
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IOA_GetBPPAndColorData(body, type, page) {
  return await axios
    .post(
      `${API_KEY}${GetBPPAndColorData}?submit=${type}&pageNumber=${page}`,
      body
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IOA_GetECommerceData(body, type, page) {
  return await axios
    .post(
      `${API_KEY}${GetECommerceData}?submit=${type}&pageNumber=${page}`,
      body
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IOA_GetImosData(body, type, page) {
  return await axios
    .post(`${API_KEY}${GetImosData}?submit=${type}&pageNumber=${page}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function IOA_Resend(body) {
  return await axios
    .post(`${API_KEY}${Resend_Click}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

//MTA param Stock-Point-Buffer-Upload

export async function SPBU_Merge(body, type) {
  return await axios
    .post(`${API_KEY}${Merge_MTA_Stock_Point_Buffer}?submit=${type}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SPBU_DownloadSampleFormat() {
  return await axios
    .post(`${API_KEY}${DownloadSampleFormat}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SPBU_Get(body, type) {
  return await axios
    .post(`${API_KEY}${Get_MTA_Stock_Point_Buffer}?submit=${type}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function SPBU_UploadMTAStockPointBuffer(body, type) {
  return await axios
    .post(`${API_KEY}${UploadMTAStockPointBuffer}?submit=${type}`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
