import React, { useState, useRef, useEffect } from "react";
import Stack from "@mui/material/Stack";
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import modules from "./../../ccpm.module.css";
import bread from "../../../../Assets/bread.png";
import calender from "../../../../Assets/calendar.png";
import TM_Preceder from "../../../../Assets/TM_Preceder.jpg";
import TM_successor from "../../../../Assets/TM_successor.jpg";
import Calendar from "react-calendar";
import { AddRuleData, AddRule3Data } from "../../../../Services/CCPM";
import { Popper, Paper, Popover } from "@mui/material";
import { useLocation } from "react-router-dom";
import "../../ProjectPlan/CalendarStyles.css";
import loading from "../../../../Assets/Loading_image.gif";
import { useNavigate } from "react-router-dom";
const AddRulePopup = () => {
  const navigate = useNavigate();
  //loading gif
  const [isLoading, setIsLoading] = useState(false);

  const [selectedDayRecurs, setSelectedDayRecurs] = useState(1);
  const weekdayOptions = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const location = useLocation();
  const [selectedWeekDayRecurs, setSelectedWeekDayRecurs] = useState("Monday");
  const [inputValue, setInputValue] = useState("");
  const [selectedRadioValue, setSelectedRadioValue] = useState("no-recurrence");
  const [isChecked, setIsChecked] = useState(false);

  const [fromdate, setFromdate] = useState(null);
  const [todate, setTodate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [displayfromdate, setdisplayfromdate] = useState("");
  const [displaytodate, setdisplaytodate] = useState("");
  const [displaystartDate, setdisplaystartDate] = useState("");
  const [displayendDate, setdisplayendDate] = useState("1 / 1 / 0001");
  const [displayendDateD, setdisplayendDateD] = useState("");

  const [calendarToOpen, setCalendarToOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarStartDateOpen, setCalendarStartDateOpen] = useState(false);
  const [calendarEndDateOpen, setCalendarEndDateOpen] = useState(false);
  const [days, setDays] = useState(1);
  const searchParams = new URLSearchParams(location.search);
  //const statusIdFromQuery = parseInt(searchParams.get("status"));
  const calendarIdFromQuery = parseInt(searchParams.get("CalenderID"));
  const [inputErr, setInputErr] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [timeEntries, setTimeEntries] = useState(
    Array(4).fill({
      from: "",
      to: "",
      fromAMPM: "AM",
      toAMPM: "AM",
      from2: "",
      to2: "",
      from2AMPM: "AM",
      to2AMPM: "AM",
    })
  );

  const [isStartBySelected, setIsStartBySelected] = useState(false);
  const [occurences, setOccurences] = useState(0);
  const [startByDate, setStartByDate] = useState("");
  const [endByDate, setEndByDate] = useState("");
  const [selectedOption, setSelectedOption] = useState(""); //
  const [error, setError] = useState(""); // State to hold error messages
  const handleStartDateChange = (date) => {
    setStartDate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplaystartDate(`${month}/${day}/${year}`);
    setCalendarStartDateOpen(false);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplayendDate(`${month}/${day}/${year}`);
    setdisplayendDateD(`${month}/${day}/${year}`);
    setCalendarEndDateOpen(false);
  };
  // Handle the radio button change

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === "endBy") {
      setOccurences(0);
      setdisplayendDate("");
      setdisplayendDateD("");
    }
    if (e.target.value === "endAfter") {
      setdisplayendDate("1 / 1 / 0001");
      setdisplayendDateD("");
    }
  };
  const handleFromDateChange = (date) => {
    setFromdate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplayfromdate(`${month}/${day}/${year}`);
    setCalendarOpen(false);
    setError("");
  };
  const validDate = () => {
    const fromDate = new Date(displayfromdate);
    const toDate = new Date(displaytodate);

    if (toDate < fromDate) {
      setError("To Date Cannot be Less Than From Date.");
      return false;
    } else {
      setError("");
      return true;
      // Clear error if validation passes
    }
  };
  const handleToDateChange = (date) => {
    setTodate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    setdisplaytodate(`${month}/${day}/${year}`);
    setCalendarToOpen(false);
  };

  const fromCalendarRef = useRef(null);
  const toCalendarRef = useRef(null);
  const startDateCalendarRef = useRef(null);
  const endDateCalendarRef = useRef(null);

  // Toggle Calendar logic
  const toggleCalendar = () => {
    setCalendarOpen((prevOpen) => !prevOpen);
    setCalendarToOpen(false); // Close the 'To' calendar when opening 'From' calendar
  };

  const toggleToCalendar = () => {
    setCalendarToOpen((prevOpen) => !prevOpen);
    setCalendarOpen(false); // Close the 'From' calendar when opening 'To' calendar
  };

  const toggleStartDateCalendar = () => {
    setCalendarStartDateOpen((prevOpen) => !prevOpen);
    setCalendarEndDateOpen(false); // Close the 'End' calendar when opening 'Start' calendar
  };

  const toggleEndDateCalendar = () => {
    setCalendarEndDateOpen((prevOpen) => !prevOpen);
    setCalendarStartDateOpen(false); // Close the 'Start' calendar when opening 'End' calendar
  };

  // Close calendars on outside click
  const handleClickOutside = (event) => {
    if (
      fromCalendarRef.current &&
      !fromCalendarRef.current.contains(event.target)
    ) {
      setCalendarOpen(false);
    }
    if (
      toCalendarRef.current &&
      !toCalendarRef.current.contains(event.target)
    ) {
      setCalendarToOpen(false);
    }
    if (
      startDateCalendarRef.current &&
      !startDateCalendarRef.current.contains(event.target)
    ) {
      setCalendarStartDateOpen(false);
    }
    if (
      endDateCalendarRef.current &&
      !endDateCalendarRef.current.contains(event.target)
    ) {
      setCalendarEndDateOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setSelectedOption("endAfter");
  };

  // Function to generate day options
  const generateDayOptions = () => {
    return Array.from({ length: 31 }, (_, i) => {
      const day = i + 1;
      let suffix = "th";
      if (day === 1 || day === 21 || day === 31) suffix = "st";
      else if (day === 2 || day === 22) suffix = "nd";
      else if (day === 3 || day === 23) suffix = "rd";

      return `${day}${suffix}`;
    });
  };

  // Function to generate month options
  const generateMonthOptions = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.map((month, index) => ({
      value: index + 1, // Month number (1-12)
      label: month,
    }));
  };

  const [selectedMonth, setSelectedMonth] = useState(1);
  const [radioButtonThree, setRadioButtonThree] = useState("false");
  const [isTableEnabled, setIsTableEnabled] = useState(false);
  const [selectedWeekDayCount, setSelectedWeekDayCount] = useState("1");
  const handleRadioChange = (event) => {
    setOccurences(0);
    setSelectedRadioValue(event.target.value);
    if (selectedRadioValue === "recurs-monthly") {
      setRadioButtonThree(true);
    } else {
      setRadioButtonThree(false); // Reset if other values are selected
    }
  };

  const dayOptions = generateDayOptions();
  const monthOptions = generateMonthOptions();
  // useEffect(()=>{
  //   LoadInsertAPi()
  // },[selectedRadioValue])

  const LoadInsertAPi = async (obj) => {
    setIsLoading(true);
    if (
      selectedRadioValue === "recurs-monthly" ||
      selectedRadioValue === "recurs-weekly"
    ) {
      if (!displaystartDate) {
        alert("Please select a 'Start By' date."); // Show alert if 'Start By' is empty
        setIsLoading(false);
        return; // Stop function if the validation fails
      }
    }
    try {
      let res;
      // Call respective API based on the radio button selection
      if (
        selectedRadioValue === "no-recurrence" ||
        selectedRadioValue === "recurs-yearly"
      ) {
        res = await AddRuleData(obj);
        setIsLoading(false);
      } else {
        res = await AddRule3Data(obj);
        setIsLoading(false);
      }

      // Handle success response
      if (res.status === 200) {
        alert("Details Inserted Successfully");

        // Reset fields based on the selected radio value
        if (selectedRadioValue === "no-recurrence") {
          setInputValue("");
          setdisplaytodate("");
          setdisplayfromdate("");
          setSelectedRadioValue("no-recurrence");
        } else if (selectedRadioValue === "recurs-weekly") {
          setInputValue("");
          setOccurences(0);
          setSelectedDayRecurs(1);
          setSelectedMonth("");
          setdisplaystartDate("");
          setdisplayendDate("");
          setDays(1);
          setSelectedRadioValue("no-recurrence");
        } else if (selectedRadioValue === "recurs-yearly") {
          setInputValue("");
          setSelectedDayRecurs("");
          setSelectedMonth("");
          setInputValue("");
          setSelectedRadioValue("no-recurrence");
        } else if (selectedRadioValue === "recurs-monthly") {
          setInputValue("");
          setOccurences(0);
          setSelectedDayRecurs(1);
          setSelectedMonth("");
          setdisplaystartDate("");
          setdisplayendDate("");
          setdisplayendDateD("");
          setDays(1);
          setSelectedRadioValue("no-recurrence");
        }
      } else {
        console.error("Error inserting details:", res);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const InserRule = async () => {
    if (inputValue === "") {
      setInputErr(true);
      setErrorText("Please Enter Rule Description");
      return; // Early exit if the input is empty
    }

    if (selectedRadioValue === "no-recurrence") {
      if (fromdate === null) {
        setInputErr(true);
        setErrorText("From Date Cannot be Empty");
        return; // Early exit if from date is empty
      }
      if (todate === null) {
        setInputErr(true);
        setErrorText("To Date Cannot be Empty");
        return; // Early exit if to date is empty
      }

      // Validate that the to date is not less than from date
      const fromDate = new Date(displayfromdate);
      const toDate = new Date(displaytodate);
      if (isNaN(fromDate) || isNaN(toDate)) {
        setInputErr(true);
        setErrorText("Please enter valid dates.");
        return; // Early exit if the dates are invalid
      }

      if (toDate < fromDate) {
        setInputErr(true);
        setErrorText("To Date Cannot be Less Than From Date.");
        return; // Early exit if validation fails
      }
    }

    setInputErr(false); // Clear input error

    const formatDate = (date) => {
      let day = date.getDate();
      let month = date.getMonth() + 1; // Months are zero-based
      let year = date.getFullYear();

      // Add leading zeros to day and month if necessary
      day = day < 10 ? "0" + day : day;
      month = month < 10 ? "0" + month : month;

      return `${month}/${day}/${year}`;
    };

    const formatDate2 = (date) => {
      let day = date.getDate();
      let month = date.getMonth() + 1; // Months are zero-based
      let year = date.getFullYear();

      // Add leading zeros to day and month if necessary
      day = day < 10 ? "0" + day : day;
      month = month < 10 ? "0" + month : month;

      return `${year}-${month}-${day}`;
    };

    // Get the current date and format it
    let currentDate = formatDate(new Date());
    let currentDate2 = formatDate2(new Date());

    let obj = {};
    switch (selectedRadioValue) {
      case "no-recurrence":
        obj = {
          calendarId: calendarIdFromQuery,
          ruleDesc: inputValue,
          ruleDate: currentDate,
          ruleType: 2,
          recurrencePattern: 1,
          nR_FromDate: displayfromdate,
          nR_ToDate: displaytodate,
          rW_WeekDay: 1,
          rW_EveryWeek: 0,
          rM_PerDate: 1,
          rM_PerDay: 0,
          rM_PerDate_Days: 1,
          rM_PerDate_EveryMonth: 0,
          rM_PerDay_WeekCount: 0,
          rM_PerDay_WeekDay: 0,
          rM_PerDay_EveryMonth: 0,
          rY_PerDate: 1,
          rY_PerDay: 0,
          rY_PerDate_Days: 1,
          rY_PerDate_Month: 1,
          rY_PerDay_WeekCount: 0,
          rY_PerDay_WeekDay: 0,
          rY_PerDay_Month: 0,
          range_NoStartDate: 0,
          range_NoEndDate: 0,
          range_StartBy: "1 / 1 / 0001",
          range_EndAfter: 0,
          range_EndBy: "1 / 1 / 0001",
          fiscalWeekName: "",
          uId: parseInt(sessionStorage.getItem("loginId")),
          startTime: "",
          endTime: "",
        };
        break;

      case "recurs-weekly":
        obj = {
          calendarId: calendarIdFromQuery,
          ruleDesc: inputValue,
          ruleDate: currentDate2,
          ruleType: 2,
          recurrencePattern: 2,
          nR_FromDate: "1 / 1 / 0001",
          nR_ToDate: "1 / 1 / 0001",
          rW_WeekDay: selectedWeekDayCount, //dynamic (ddlweekly),
          rW_EveryWeek: 1,
          rM_PerDate: 1,
          rM_PerDay: 0,
          rM_PerDate_Days: 1,
          rM_PerDate_EveryMonth: 0,
          rM_PerDay_WeekCount: 0,
          rM_PerDay_WeekDay: 0,
          rM_PerDay_EveryMonth: 0,
          rY_PerDate: 1,
          rY_PerDay: 0,
          rY_PerDate_Days: 1,
          rY_PerDate_Month: 1,
          rY_PerDay_WeekCount: 0,
          rY_PerDay_WeekDay: 0,
          rY_PerDay_Month: 0,
          range_NoStartDate: 0,
          range_NoEndDate: 0,
          range_StartBy: displaystartDate, //dynamic from ui
          range_EndAfter: occurences, //dynamic from ui
          range_EndBy: displayendDate, //dynamic from ui
          fiscalWeekName: selectedWeekDayRecurs, //(dynamic)
          uId: parseInt(sessionStorage.getItem("loginId")),
          startTime: startTime,
          endTime: endTime,
          weekCountMonthly: days,
        };
        break;

      case "recurs-monthly":
        obj = {
          calendarId: calendarIdFromQuery,
          ruleDesc: inputValue,
          ruleDate: currentDate2,
          ruleType: 2,
          recurrencePattern: 3,
          nR_FromDate: "1 / 1 / 0001",
          nR_ToDate: "1 / 1 / 0001",
          rW_WeekDay: 1,
          rW_EveryWeek: 0,
          rM_PerDate: 1,
          rM_PerDay: 0,
          rM_PerDate_Days: selectedDayRecurs, // dynamic
          rM_PerDate_EveryMonth: 0,
          rM_PerDay_WeekCount: 0,
          rM_PerDay_WeekDay: 0,
          rM_PerDay_EveryMonth: 0,
          rY_PerDate: 1,
          rY_PerDay: 0,
          rY_PerDate_Days: 1,
          rY_PerDate_Month: 1,
          rY_PerDay_WeekCount: 0,
          rY_PerDay_WeekDay: 0,
          rY_PerDay_Month: 0,
          range_NoStartDate: 0,
          range_NoEndDate: 0,
          range_StartBy: displaystartDate, // mm/dd/yyyy
          range_EndAfter: occurences, // dynamic from UI
          range_EndBy: displayendDate, // dynamic from UI
          fiscalWeekName: "",
          uId: parseInt(sessionStorage.getItem("loginId")),
          startTime: "",
          endTime: "",
          weekCountMonthly: days, // dynamic
        };
        break;

      case "recurs-yearly":
        obj = {
          calendarId: calendarIdFromQuery,
          ruleDesc: inputValue,
          ruleDate: currentDate,
          ruleType: 2,
          recurrencePattern: 4,
          nR_FromDate: "1 / 1 / 0001",
          nR_ToDate: "1 / 1 / 0001",
          rW_WeekDay: 1,
          rW_EveryWeek: 0,
          rM_PerDate: 1,
          rM_PerDay: 0,
          rM_PerDate_Days: 1,
          rM_PerDate_EveryMonth: 0,
          rM_PerDay_WeekCount: 0,
          rM_PerDay_WeekDay: 0,
          rM_PerDay_EveryMonth: 0,
          rY_PerDate: 1,
          rY_PerDay: 0,
          rY_PerDate_Days: selectedDayRecurs,
          rY_PerDate_Month: selectedMonth,
          rY_PerDay_WeekCount: 0,
          rY_PerDay_WeekDay: 0,
          rY_PerDay_Month: 0,
          range_NoStartDate: 0,
          range_NoEndDate: 0,
          range_StartBy: "1 / 1 / 0001",
          range_EndAfter: 0,
          range_EndBy: "1 / 1 / 0001",
          fiscalWeekName: "",
          uId: parseInt(sessionStorage.getItem("loginId")),
          startTime: "",
          endTime: "",
        };
        break;

      default:
        alert("Invalid recurrence option selected.");
        return;
    }
    // Make the API call
    LoadInsertAPi(obj);
  };

  // Increment function
  const incrementDays = () => {
    setDays((prevDays) => (prevDays < 12 ? prevDays + 1 : prevDays));
  };

  // Decrement function (with optional check to prevent negative values)
  const decrementDays = () => {
    setDays((prevDays) => (prevDays > 0 ? prevDays - 1 : 0));
  };
  const incrementOccurence = () => {
    setOccurences((prevDays) => (prevDays < 12 ? prevDays + 1 : prevDays));
  };

  // Decrement function (with optional check to prevent negative values)
  const decrementOccurence = () => {
    setOccurences((prevDays) => (prevDays > 0 ? prevDays - 1 : 0));
  };
  const handleWeekDay = (e) => {
    if (e.target.value === "Monday") {
      setSelectedWeekDayRecurs(e.target.value);
      setSelectedWeekDayCount(1);
    } else if (e.target.value === "Tuesday") {
      setSelectedWeekDayRecurs(e.target.value);
      setSelectedWeekDayCount(2);
    } else if (e.target.value === "Wednesday") {
      setSelectedWeekDayRecurs(e.target.value);
      setSelectedWeekDayCount(3);
    } else if (e.target.value === "Thursday") {
      setSelectedWeekDayRecurs(e.target.value);
      setSelectedWeekDayCount(4);
    } else if (e.target.value === "Friday") {
      setSelectedWeekDayRecurs(e.target.value);
      setSelectedWeekDayCount(5);
    } else if (e.target.value === "Saturday") {
      setSelectedWeekDayRecurs(e.target.value);
      setSelectedWeekDayCount(6);
    } else if (e.target.value === "Sunday") {
      setSelectedWeekDayRecurs(e.target.value);
      setSelectedWeekDayCount(7);
    }
  };

  const NoRecurrenceUi = () => (
    <>
      <tr style={{ marginLeft: "4rem", marginTop: "1.3rem" }}>
        <td className={modules["dateTextHeaderC"]}>From Date :</td>
        <td>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "5px",
            }}
          >
            <input
              className={modules["datepicker"]}
              type="text"
              readOnly
              value={displayfromdate}
              placeholder="dd/MM/yyyy"
              onChange={(e) => setdisplayfromdate(e.target.value)}
              style={{
                marginRight: "1rem",
                width: "100px",
              }}
            />
            <img
              className={modules["calender"]}
              src={calender}
              alt="calendar"
              onClick={toggleCalendar}
              style={{
                cursor: "pointer",
                marginTop: "2px",
                width: "20px",
                height: "20px",
              }} // Added margin to position the icon below the input
            />
            {calendarOpen && (
              <div className={modules["fromcalendar"]} ref={fromCalendarRef}>
                <Calendar
                  onChange={handleFromDateChange}
                  value={null}
                  style={{ position: "absolute", zIndex: 1000 }}
                  className={modules["zindex"]}
                />
              </div>
            )}
          </div>
        </td>

        <td className={modules["dateTextHeaderC"]}>To Date :</td>
        <td>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "5px",
            }}
          >
            <input
              className={modules["datepicker"]}
              type="text"
              value={displaytodate}
              onChange={(e) => setdisplaytodate(e.target.value)}
              placeholder="dd/MM/yyyy"
              readOnly
              style={{
                marginRight: "1rem",
                width: "100px",
              }}
            />
            <img
              className={modules["calender"]}
              src={calender}
              alt="calendar"
              onClick={toggleToCalendar}
              style={{
                cursor: "pointer",
                marginTop: "2px",
                width: "20px",
                height: "20px",
              }} // Added margin to position the icon below the input
            />
            {calendarToOpen && (
              <div className={modules["fromcalendar"]} ref={toCalendarRef}>
                <Calendar
                  onChange={handleToDateChange}
                  value={null}
                  style={{ position: "absolute", zIndex: 1000 }}
                  className={modules["zindex"]}
                />
              </div>
            )}
          </div>
        </td>

        <tr>
          <td colSpan="4">
            <div
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: "3rem",
              }}
            >
              <button
                style={{
                  marginRight: "1rem",
                  padding: "1px 6px",
                  fontSize: "14px",
                }}
                className={modules["history-label"]}
                onClick={InserRule}
              >
                OK
              </button>
              <button
                style={{ padding: "1px 6px", fontSize: "14px" }}
                onClick={handleClose}
                className={modules["history-label"]}
              >
                Close
              </button>
            </div>
          </td>
        </tr>
      </tr>
    </>
  );

  const handleTimeChange = (index, field, value) => {
    const validValue = value.replace(/[^0-9:]/g, "");
    const newHours = [...timeEntries];
    if (
      field === "from" ||
      field === "to" ||
      field === "from2" ||
      field === "to2"
    ) {
      newHours[index] = { ...newHours[index], [field]: validValue };
    } else {
      newHours[index] = { ...newHours[index], [field]: value };
    }
    setTimeEntries(newHours);
  };

  const startTime = timeEntries
    .map((entry) => {
      const times = [];
      if (entry.from && entry.fromAMPM) {
        times.push(`${entry.from} ${entry.fromAMPM}`);
      }
      if (entry.from2 && entry.from2AMPM) {
        times.push(`${entry.from2} ${entry.from2AMPM}`);
      }
      return times.join(",");
    })
    .filter((time) => time !== "") // Remove empty results
    .join(",");
  const endTime = timeEntries
    .map((entry) => {
      const times = [];
      if (entry.to && entry.toAMPM) {
        times.push(`${entry.to} ${entry.toAMPM}`);
      }
      if (entry.to2 && entry.to2AMPM) {
        times.push(`${entry.to2} ${entry.to2AMPM}`);
      }
      return times.join(",");
    })
    .filter((time) => time !== "") // Remove empty results
    .join(",");

  const handleClose = () => {
    if (window.opener) {
      // Refresh the parent window before closing the current window
      window.opener.location.reload();

      // Close the current window (works if it was opened programmatically)
      window.close();
    } else {
      // Fallback: If the window was not opened programmatically, reload the current window itself
      window.location.reload();
    }
  };
  // UI for Recurs Weekly
  const RecursWeeklyUi = () => (
    <>
      <div style={{ marginTop: "12.5rem", position: "absolute", left: "49px" }}>
        <tr>
          <td>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className={modules["custom-checkbox"]}
            />
          </td>
          <td>
            <span
              style={{ marginLeft: "3px" }}
              className={modules["historyTable"]}
            >
              Start By
            </span>
          </td>
          <td>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <input
                  className={modules["datepicker"]}
                  type="text"
                  value={displaystartDate}
                  onClick={!isChecked ? toggleStartDateCalendar : undefined}
                  onChange={(e) => setdisplaystartDate(e.target.value)}
                  placeholder="dd/MM/yyyy"
                  readOnly
                  style={{
                    paddingLeft: "3px",
                    marginRight: "0.3rem",
                    width: "90px",
                    height: "21px",
                    cursor: isChecked ? "default" : "pointer", // Cursor depends on checkbox state
                  }}
                />
                {calendarStartDateOpen && (
                  <div
                    className={modules["fromcalendar"]}
                    ref={startDateCalendarRef}
                  >
                    <Calendar
                      onChange={handleStartDateChange}
                      value={null}
                      style={{ position: "absolute", zIndex: 1000 }}
                      className={modules["zindex"]}
                    />
                  </div>
                )}
              </div>
              <img
                className={modules["calender"]}
                src={calender}
                alt="calendar"
                onClick={isChecked ? toggleStartDateCalendar : undefined}
                style={{
                  cursor: isChecked ? "pointer" : "not-allowed", // Disable image click when unchecked

                  visibility: isChecked ? "visible" : "hidden", // Grey out the image when disabled
                }}
              />
            </div>
          </td>
        </tr>
      </div>

      <tr style={{ marginLeft: "5.6rem", marginTop: "0.4rem" }}>
        <td>
          <tr>
            <Stack direction="row">
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <select
                  className={modules["datepickerH"]}
                  value={selectedWeekDayRecurs}
                  onChange={handleWeekDay}
                  style={{ height: "20px" }}

                  // Adjust width as needed
                >
                  {weekdayOptions.map((day, index) => (
                    <option
                      className={modules["datepickerHOpt"]}
                      key={index}
                      value={day}
                    >
                      {day}
                    </option>
                  ))}
                </select>
                <span
                  style={{ marginRight: "2rem" }}
                  className={modules["historyTable"]}
                >
                  of every
                </span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <input
                  type="number"
                  onChange={(e) => {
                    setDays(e.target.value);
                  }}
                  value={days}
                  disabled
                  className={modules["addInpt"]}
                  style={{ width: "56px", height: "20px" }}
                />
                <img
                  src={TM_Preceder}
                  alt=""
                  style={{
                    width: "20px",
                    height: "20px",
                    paddingBottom: "13px",
                  }}
                  onClick={decrementDays}
                />
                <img
                  src={TM_successor}
                  alt=""
                  style={{
                    width: "20px",
                    height: "20px",
                    paddingBottom: "13px",
                  }}
                  onClick={incrementDays}
                />
                <span className={modules["historyTable"]}>Week</span>
              </div>
            </Stack>
          </tr>

          <tr style={{ marginTop: "5px" }}>
            <Stack direction="row">
              <Stack
                direction="column"
                sx={{ width: "95px" }}
                justifyContent="center"
              >
                <span
                  style={{ fontWeight: "bold" }}
                  className={modules["historyTable"]}
                >
                  For Half Day
                </span>
                <input
                  type="checkbox"
                  style={{ marginTop: "3px" }}
                  className={modules["custom-checkbox"]}
                  checked={isTableEnabled}
                  onChange={(e) => setIsTableEnabled(e.target.checked)} // Toggle table enable/disable
                />
              </Stack>

              <table>
                <tbody>
                  {timeEntries.map((entry, index) => (
                    <tr key={index}>
                      <td style={{ paddingRight: "5px" }}>
                        <input
                          type="text"
                          maxLength="5"
                          value={entry.from}
                          className={modules["inputCpop"]}
                          onChange={(e) => {
                            handleTimeChange(index, "from", e.target.value);
                          }}
                          style={{ width: "35px", marginRight: "5px" }}
                          disabled={!isTableEnabled} // Disable if table is not enabled
                        />
                        <select
                          value={entry.fromAMPM}
                          onChange={(e) =>
                            handleTimeChange(index, "fromAMPM", e.target.value)
                          }
                          disabled={!isTableEnabled} // Disable if table is not enabled
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </td>
                      <td style={{ paddingRight: "5px" }}>
                        <input
                          type="text"
                          className={modules["inputCpop"]}
                          maxLength="5"
                          value={entry.to}
                          onChange={(e) =>
                            handleTimeChange(index, "to", e.target.value)
                          }
                          disabled={!isTableEnabled} // Disable if table is not enabled
                          style={{ width: "35px", marginRight: "5px" }}
                        />
                        <select
                          value={entry.toAMPM}
                          onChange={(e) =>
                            handleTimeChange(index, "toAMPM", e.target.value)
                          }
                          disabled={!isTableEnabled} // Disable if table is not enabled
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </td>
                      <td style={{ paddingRight: "5px" }}>
                        <input
                          type="text"
                          maxLength="5"
                          value={entry.from2}
                          className={modules["inputCpop"]}
                          onChange={(e) =>
                            handleTimeChange(index, "from2", e.target.value)
                          }
                          style={{ width: "35px", marginRight: "5px" }}
                          disabled={!isTableEnabled} // Disable if table is not enabled
                        />
                        <select
                          value={entry.from2AMPM}
                          onChange={(e) =>
                            handleTimeChange(index, "from2AMPM", e.target.value)
                          }
                          disabled={!isTableEnabled} // Disable if table is not enabled
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className={modules["inputCpop"]}
                          maxLength="5"
                          value={entry.to2}
                          onChange={(e) =>
                            handleTimeChange(index, "to2", e.target.value)
                          }
                          style={{ width: "35px", marginRight: "5px" }}
                          disabled={!isTableEnabled} // Disable if table is not enabled
                        />
                        <select
                          value={entry.to2AMPM}
                          onChange={(e) =>
                            handleTimeChange(index, "to2AMPM", e.target.value)
                          }
                          disabled={!isTableEnabled} // Disable if table is not enabled
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Stack>
          </tr>

          <tr>
            <td
              colSpan="4"
              style={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
                paddingLeft: "5rem",
              }}
            >
              <span
                style={{ fontWeight: "bold" }}
                className={modules["historyTable"]}
              >
                Range of Occurrences
              </span>
            </td>
          </tr>

          <tr style={{ marginTop: "1rem" }}>
            <td>
              <Stack direction="row" spacing={4}>
                <div style={{ width: "100px" }}>
                  <Stack direction="column" alignItems="center" spacing={1}>
                    {/* <Stack direction="row">
                      <input
                        type="radio"
                        name="occurrences"
                        value="endAfter"
                        checked={selectedOption === "endAfter"} // Check if this is the selected option
                        onChange={() => setSelectedOption("endAfter")} // Set selected option
                      />
                      <span
                        style={{ marginLeft: "6px" }}
                        className={modules["historyTable"]}
                      >
                        End after
                      </span>
                    </Stack>
                    <Stack direction="row">
                      <input
                        type="radio"
                        name="occurrences"
                        value="endBy"
                        checked={selectedOption === "endBy"} // Check if this is the selected option
                        onChange={() => setSelectedOption("endBy")} // Set selected option
                      />
                      <span
                        style={{ marginLeft: "6px" }}
                        className={modules["historyTable"]}
                      >
                        End by
                      </span>
                    </Stack> */}
                    <FormControl sx={{ marginTop: "-0.2rem" }}>
                      <RadioGroup
                        column
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectedOption}
                        onChange={(event) =>
                          setSelectedOption(event.target.value)
                        }
                      >
                        <FormControlLabel
                          value="endAfter"
                          control={
                            <Radio
                              disableRipple
                              color="primary"
                              sx={{
                                transform: "scale(0.8)",
                                padding: "0",
                                // marginLeft: "90px",
                              }}
                            />
                          }
                          label="End after"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "16px",
                              fontFamily: "Times New Roman !important",
                              color: "#000000",
                            },
                          }}
                        />
                        <FormControlLabel
                          value="endBy"
                          control={
                            <Radio
                              disableRipple
                              color="primary"
                              sx={{ transform: "scale(0.8)", padding: "0" }}
                            />
                          }
                          label="End by"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "16px",
                              fontFamily: "Times New Roman !important",
                              color: "#000000",
                            },
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </div>
                <Stack direction="column" alignItems="center">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1.5rem",
                    }}
                  >
                    <input
                      type="number"
                      disabled
                      onChange={(e) => {
                        setOccurences(e.target.value);
                      }}
                      className={modules["addInpt"]}
                      value={occurences}
                      style={{ width: "56px", height: "20px" }}
                    />
                    <img
                      src={TM_Preceder}
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                        paddingBottom: "13px",
                        cursor: "pointer",
                        // opacity: displaystartDate ? 1 : 0.5,
                      }}
                      onClick={() => {
                        if (isChecked) {
                          decrementOccurence();
                        }
                      }}
                    />
                    <img
                      src={TM_successor}
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                        paddingBottom: "13px",
                        cursor: "pointer",

                        // cursor: displaystartDate ? "pointer" : "not-allowed",
                        // opacity: displaystartDate ? 1 : 0.5,
                      }}
                      onClick={() => {
                        if (isChecked) {
                          incrementOccurence();
                        }
                      }}
                    />
                    <span className={modules["historyTable"]}>Occurrences</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <input
                        className={modules["datepicker"]}
                        type="text"
                        value={displayendDateD}
                        onClick={!isChecked ? toggleEndDateCalendar : undefined}
                        onChange={(e) => setdisplayendDateD(e.target.value)}
                        placeholder="dd/MM/yyyy"
                        readOnly
                        style={{
                          paddingLeft: "3px",
                          marginRight: "0.3rem",
                          width: "90px",
                          height: "21px",
                          cursor: isChecked ? "default" : "pointer", // Cursor depends on checkbox state
                        }}
                        disabled={!isChecked} // Disable input if isChecked is false
                      />
                      {calendarEndDateOpen && (
                        <div
                          className={modules["fromcalendar"]}
                          ref={endDateCalendarRef}
                        >
                          <Calendar
                            onChange={handleEndDateChange}
                            value={null}
                            style={{ position: "absolute", zIndex: 1000 }}
                            className={modules["zindex"]}
                          />
                        </div>
                      )}
                    </div>
                    <img
                      className={modules["calender"]}
                      src={calender}
                      alt="calendar"
                      onClick={isChecked ? toggleEndDateCalendar : undefined}
                      style={{
                        cursor: isChecked ? "pointer" : "not-allowed", // Disable image click when unchecked

                        visibility: isChecked ? "visible" : "hidden", // Grey out the image when disabled
                      }}
                    />
                  </div>
                </Stack>
              </Stack>
            </td>
          </tr>

          {/* Buttons */}
          <tr>
            <td colSpan="4">
              <div
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginLeft: "3rem",
                }}
              >
                <button
                  style={{
                    marginRight: "1rem",
                    padding: "1px 6px",
                    fontSize: "14px",
                  }}
                  className={modules["history-label"]}
                  onClick={InserRule}
                >
                  OK
                </button>
                <button
                  style={{ padding: "1px 6px", fontSize: "14px" }}
                  onClick={handleClose}
                  className={modules["history-label"]}
                >
                  Close
                </button>
              </div>
            </td>
          </tr>
        </td>
      </tr>
    </>
  );

  // UI for Recurs Monthly
  const RecursMonthlyUi = () => (
    <>
      <div style={{ marginTop: "12.5rem", position: "absolute", left: "49px" }}>
        <tr>
          <td>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className={modules["custom-checkbox"]}
            />
          </td>
          <td>
            <span style={{ marginLeft: "3px" }}>Start By</span>
          </td>
          <td>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <input
                  className={modules["datepicker"]}
                  type="text"
                  value={displaystartDate}
                  onClick={!isChecked ? toggleStartDateCalendar : undefined}
                  onChange={(e) => setdisplaystartDate(e.target.value)}
                  placeholder="dd/MM/yyyy"
                  readOnly
                  style={{
                    paddingLeft: "3px",
                    marginRight: "0.3rem",
                    width: "90px",
                    height: "21px",
                    cursor: isChecked ? "default" : "pointer", // Cursor depends on checkbox state
                  }}
                />
                {calendarStartDateOpen && (
                  <div
                    className={modules["fromcalendar"]}
                    ref={startDateCalendarRef}
                  >
                    <Calendar
                      onChange={handleStartDateChange}
                      value={null}
                      style={{ position: "absolute", zIndex: 1000 }}
                      className={modules["zindex"]}
                    />
                  </div>
                )}
              </div>
              <img
                className={modules["calender"]}
                src={calender}
                alt="calendar"
                onClick={isChecked ? toggleStartDateCalendar : undefined}
                style={{
                  cursor: isChecked ? "pointer" : "not-allowed", // Disable image click when unchecked
                  visibility: isChecked ? "visible" : "hidden",
                }}
              />
            </div>
          </td>
        </tr>
      </div>

      <tr style={{ marginLeft: "5.6rem", marginTop: "2.4rem" }}>
        <td>
          <tr>
            <td>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <select
                  className={modules["datepicker"]}
                  value={selectedDayRecurs}
                  onChange={(e) => setSelectedDayRecurs(e.target.value)}
                  style={{ width: "60px", height: "20px" }} // Adjust width as needed
                >
                  {dayOptions.map((day, index) => (
                    <option key={index} value={index + 1}>
                      {day}
                    </option>
                  ))}
                </select>
                <span style={{ marginRight: "2rem" }}>of every</span>
              </div>
            </td>

            <td>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}
              >
                <input
                  type="number"
                  disabled
                  value={days}
                  onChange={(e) => setDays(Number(e.target.value))}
                  style={{ width: "2rem", height: "20px" }}
                />
                <img
                  src={TM_Preceder}
                  onClick={decrementDays}
                  style={{ width: "20px", height: "20px" }}
                  alt=""
                />
                <img
                  src={TM_successor}
                  onClick={incrementDays}
                  style={{ width: "20px", height: "20px" }}
                  alt=""
                />
                <span>Month</span>
              </div>
            </td>
          </tr>

          <tr>
            <td colSpan="4" style={{ paddingTop: "2rem" }}>
              <span
                style={{ fontWeight: "bold" }}
                className={modules["historyTable"]}
              >
                Range of Occurrences
              </span>
            </td>
          </tr>

          <tr style={{ marginTop: "1rem" }}>
            <FormControl sx={{ marginTop: "-0.2rem" }}>
              <RadioGroup
                column
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedOption}
                onChange={(event) => setSelectedOption(event.target.value)}
              >
                <FormControlLabel
                  value="endAfter"
                  control={
                    <Radio
                      disableRipple
                      color="primary"
                      sx={{
                        transform: "scale(0.8)",
                        padding: "0",
                        // marginLeft: "90px",
                      }}
                    />
                  }
                  label="End after"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "16px",
                      fontFamily: "Times New Roman !important",
                      color: "#000000",
                    },
                  }}
                />
                <FormControlLabel
                  value="endBy"
                  control={
                    <Radio
                      disableRipple
                      color="primary"
                      sx={{ transform: "scale(0.8)", padding: "0" }}
                    />
                  }
                  label="End by"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "16px",
                      fontFamily: "Times New Roman !important",
                      color: "#000000",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
            {/* <td>
              <input
                type="radio"
                name="occurrences"
                value="endAfter"
                id="endAfter"
                checked={selectedOption === "endAfter"}
                onChange={handleOptionChange}
              />
              <label
                className={modules["historyTable"]}
                style={{ marginLeft: "6px" }}
                htmlFor="endAfter"
              >
                End after
              </label>
            </td> */}
            <td style={{ paddingTop: "1rem" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}
              >
                <input
                  type="number"
                  disabled
                  value={occurences}
                  onChange={(e) => setOccurences(Number(e.target.value))}
                  style={{ width: "2rem", height: "20px" }}
                />
                <img
                  src={TM_Preceder}
                  style={{ width: "20px", height: "20px" }}
                  alt=""
                  onClick={() => {
                    if (isChecked) {
                      decrementOccurence();
                    }
                  }}
                />
                <img
                  src={TM_successor}
                  style={{ width: "20px", height: "20px" }}
                  alt=""
                  onClick={() => {
                    if (isChecked) {
                      incrementOccurence();
                    }
                  }}
                />
                <span className={modules["historyTable"]}>Occurrences</span>
              </div>
            </td>
          </tr>

          <tr>
            {/* <td style={{ paddingTop: "1rem" }}>
              <input
                type="radio"
                name="occurrences"
                value="endBy"
                id="endBy"
                checked={selectedOption === "endBy"}
                onChange={handleOptionChange}
              />
              <label
                htmlFor="endBy"
                className={modules["historyTable"]}
                style={{ marginLeft: "6px" }}
              >
                End by
              </label>
            </td> */}
            <td></td>
            <td>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <input
                    className={modules["datepicker"]}
                    type="text"
                    value={displayendDateD}
                    onClick={
                      selectedOption === "endBy"
                        ? toggleEndDateCalendar
                        : undefined
                    }
                    onChange={(e) => setdisplayendDateD(e.target.value)}
                    placeholder="dd/MM/yyyy"
                    readOnly
                    disabled={!isChecked} // Disable input if isChecked is false
                    style={{
                      paddingLeft: "3px",
                      marginRight: "0.3rem",
                      width: "90px",
                      height: "21px",
                      cursor: !isChecked ? "default" : "pointer", // Cursor depends on checkbox state
                    }}
                  />
                  {calendarEndDateOpen && (
                    <div
                      className={modules["fromcalendar"]}
                      ref={endDateCalendarRef}
                    >
                      <Calendar
                        onChange={handleEndDateChange}
                        value={null}
                        style={{ position: "absolute", zIndex: 1000 }}
                        className={modules["zindex"]}
                      />
                    </div>
                  )}
                </div>
                <img
                  className={modules["calender"]}
                  src={calender}
                  alt="calendar"
                  onClick={isChecked ? toggleEndDateCalendar : undefined}
                  style={{
                    cursor: isChecked ? "pointer" : "not-allowed", // Disable image click when unchecked
                    visibility: isChecked ? "visible" : "hidden",
                  }}
                />
              </div>
            </td>
          </tr>

          {/* Buttons */}
          <tr>
            <td colSpan="4">
              <div
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginLeft: "3rem",
                }}
              >
                <button
                  style={{
                    marginRight: "1rem",
                    padding: "1px 6px",
                    fontSize: "14px",
                  }}
                  className={modules["history-label"]}
                  onClick={InserRule}
                >
                  OK
                </button>
                <button
                  style={{ padding: "1px 6px", fontSize: "14px" }}
                  onClick={handleClose}
                  className={modules["history-label"]}
                >
                  Close
                </button>
              </div>
            </td>
          </tr>
        </td>
      </tr>
    </>
  );

  // UI for Recurs Yearly
  const RecursYearlyUi = () => (
    <>
      <tr style={{ marginLeft: "12px", marginTop: "2.3rem" }}>
        <td>
          <select
            className={modules["datepickerH"]}
            value={selectedDayRecurs}
            onChange={(e) => setSelectedDayRecurs(e.target.value)}
            style={{ width: "70px", height: "20px" }} // Adjust width as needed
          >
            {dayOptions.map((day, index) => (
              <option
                className={modules["datepickerHOpt"]}
                key={index}
                value={index + 1}
              >
                {day}
              </option>
            ))}
          </select>
          &nbsp;
        </td>
        <td>
          <Grid item>
            <FormControl>
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                style={{ width: "7.5rem", height: "20px" }}
                className={modules["datepickerH"]}
              >
                {monthOptions.map((month) => (
                  <option
                    className={modules["datepickerHOpt"]}
                    key={month.value}
                    value={month.value}
                  >
                    {month.label}
                  </option>
                ))}
              </select>
            </FormControl>
          </Grid>
        </td>
        <tr>
          <td colSpan="4">
            <div
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: "3rem",
              }}
            >
              <button
                style={{
                  marginRight: "1rem",
                  padding: "1px 6px",
                  fontSize: "14px",
                }}
                className={modules["history-label"]}
                onClick={InserRule}
              >
                OK
              </button>
              <button
                style={{ padding: "1px 6px", fontSize: "14px" }}
                onClick={handleClose}
                className={modules["history-label"]}
              >
                Close
              </button>
            </div>
          </td>
        </tr>
      </tr>
    </>
  );

  return (
    <>
      {isLoading && (
        <img
          src={loading}
          className={modules["loading_gif"]}
          alt="Loading..."
        />
      )}
      <div
        className={modules["pro-bread"]}
        style={{ marginTop: "3rem", marginLeft: "1rem" }}
      >
        <a>Calender</a>
        <img src={bread} alt="" />
        <a>Add Rule Calendar</a>
      </div>

      <Stack
        direction="column"
        sx={{
          margin:
            selectedRadioValue === "recurs-yearly"
              ? "3rem 0rem 5px 60px"
              : "3rem 0rem 5px 74px",
          alignItems: "flex-start",
        }}
      >
        {inputErr && (
          <Stack sx={{ margin: "0 0rem 0 9rem", alignItems: "center" }}>
            <Typography
              className={modules["historyTable"]}
              sx={{ fontSize: "20px", color: "red" }}
            >
              {errorText}
            </Typography>
          </Stack>
        )}
        {error && (
          <Stack sx={{ margin: "0 0rem 0 9rem", alignItems: "center" }}>
            <Typography
              className={modules["historyTable"]}
              sx={{ fontSize: "20px", color: "red" }}
            >
              {error}
            </Typography>
          </Stack>
        )}
        <Stack direction="row" alignItems="center">
          <Grid
            sx={{
              marginRight:
                selectedRadioValue === "recurs-yearly" ? "1rem" : "3rem",
              alignItems: "center",
            }}
            className={modules["historyTable"]}
          >
            Rule Description
          </Grid>
          <span
            style={{
              marginRight:
                selectedRadioValue === "recurs-weekly" ? "5rem" : "0.5rem",
              lineHeight: "2.5",
            }}
          >
            :
          </span>
          <textarea
            type="text"
            rows="2"
            cols="20"
            value={inputValue}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[a-zA-Z0-9&()[\].\/\-_=+? ]*$/;
              if (regex.test(value)) {
                setInputValue(value);
              }
            }}
            onBlur={(e) => {
              const value = e.target.value;
              const validReg = /[^a-zA-Z0-9 &()\-_?.]/;
              if (validReg.test(value)) {
                alert("No Special Characters Allowed");
                setInputValue("");
              }
            }}
            style={{
              width: "366px",
              marginLeft: "10px",
              fontFamily: "Times New Roman !important",
              paddingLeft: "3px",
            }}
          />
        </Stack>
      </Stack>

      <Grid
        container
        direction="row"
        alignItems="flex-start"
        sx={{ marginLeft: "3rem" }}
      >
        <table
          style={{
            marginTop: selectedRadioValue === "recurs-weekly" ? "1.3rem" : "0",
          }}
        >
          <tbody className={modules["radioBtnAddPop"]}>
            <FormControl sx={{ marginTop: "-0.2rem" }}>
              <RadioGroup
                coloumn
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedRadioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="no-recurrence"
                  control={
                    <Radio
                      disableRipple
                      color="primary"
                      sx={{
                        transform: "scale(0.8)",
                        padding: "0",
                        // marginLeft: "90px",
                      }}
                    />
                  }
                  label="No Recurrence"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "16px",
                      fontFamily: "Times New Roman !important",
                      color: "#000000",
                    },
                  }}
                />
                <FormControlLabel
                  value="recurs-weekly"
                  control={
                    <Radio
                      disableRipple
                      color="primary"
                      sx={{ transform: "scale(0.8)", padding: "0" }}
                    />
                  }
                  label="Recurs Weekly"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "16px",
                      fontFamily: "Times New Roman !important",
                      color: "#000000",
                    },
                  }}
                />
                <FormControlLabel
                  value="recurs-monthly"
                  control={
                    <Radio
                      disableRipple
                      color="primary"
                      sx={{ transform: "scale(0.8)", padding: "0" }}
                    />
                  }
                  label="Recurs Monthly"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "16px",
                      fontFamily: "Times New Roman !important",
                      color: "#000000",
                    },
                  }}
                />
                <FormControlLabel
                  value="recurs-yearly"
                  control={
                    <Radio
                      disableRipple
                      color="primary"
                      sx={{ transform: "scale(0.8)", padding: "0" }}
                    />
                  }
                  label="Recurs Yearly"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "16px",
                      fontFamily: "Times New Roman !important",
                      color: "#000000",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
            {/* <tr>
              <td>
                <input
                  type="radio"
                  id="no-recurrence"
                  value="no-recurrence"
                  checked={selectedRadioValue === "no-recurrence"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="no-recurrence">No Recurrence</label>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="radio"
                  id="recurs-weekly"
                  value="recurs-weekly"
                  checked={selectedRadioValue === "recurs-weekly"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="recurs-weekly">Recurs Weekly</label>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="radio"
                  id="recurs-monthly"
                  value="recurs-monthly"
                  checked={selectedRadioValue === "recurs-monthly"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="recurs-monthly">Recurs Monthly</label>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="radio"
                  id="recurs-yearly"
                  value="recurs-yearly"
                  checked={selectedRadioValue === "recurs-yearly"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="recurs-yearly">Recurs Yearly</label>
              </td>
            </tr> */}
          </tbody>
        </table>

        {/* Conditional Rendering of UI based on selected radio button */}
        {selectedRadioValue === "no-recurrence" && <NoRecurrenceUi />}
        {selectedRadioValue === "recurs-weekly" && <RecursWeeklyUi />}
        {selectedRadioValue === "recurs-monthly" && <RecursMonthlyUi />}
        {selectedRadioValue === "recurs-yearly" && <RecursYearlyUi />}
      </Grid>
    </>
  );
};

export default AddRulePopup;
